import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import Button from 'components/atomics/atoms/Button/Button';
import { TYPE_PRIMARY_MEDIUM } from 'components/atomics/atoms/Button/Button';
import { getWebinarInfo } from 'state-management/actions/webinar';
import { connect } from 'react-redux';
import { APPLICATION_URL_GOOGLE_PLAY, APPLICATION_URL_APPLE_STORE } from 'utils/globalConstants';
import {
    isAndroid, isMobile,
} from 'react-device-detect';
import {
    getContentSourcesImageUrl,
} from 'utils/getImageUrl';
import {
    TYPE_PILL,
    TYPE_GHOST,
} from 'components/atomics/atoms/Button/Button';
import {
    containerStyles,
    colorBarStyles,
    titleStyles,
    imageStyles,
    placeholderStyles,
    testFlightLearnMoreStyles,
    centerContentStyles,
    controlsContainerStyles,
} from 'components/atomics/molecules/NudgeCard/styles';
import labels from '../../../../lang/en.json';
import { FormattedMessage } from 'react-intl';
import {
    isMobileOnly,
    isIOS,
} from 'react-device-detect';
import ShareModal from 'components/ShareModal/ShareModal';

export const Placeholder = styled.div`
    ${placeholderStyles}
`;

export const CenterContent = styled.div`
    ${centerContentStyles}
`;

export const ControlsContainer = styled.div`
    ${controlsContainerStyles}
`;

export const Container = styled.div`
    ${containerStyles}
`;

export const ColorBar = styled.div`
    ${colorBarStyles}
`;

export const Title = styled.h4`
    ${titleStyles}
`;

export const Image = styled.img`
    ${imageStyles}
`;

export const TestFlightLearnMore = styled.div`
    ${testFlightLearnMoreStyles}
`;

export const TypeSrcTpcNotIntBtn = styled.div`
`;

export const TypeSrcTpcNotInt = styled.div`
`;

export const TYPE_SOURCE = 'source';
export const TYPE_TOPIC = 'topic';
export const TYPE_TESTFLIGHT = 'testflight';
export const TYPE_WEBINAR = 'webinar';
export const TYPE_PEER = 'refer';

/**
 * Creates a nudge content card to promote sources/topics/mobile app/webinars etc.
 */
class NudgeCard extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleDismiss = this.handleDismiss.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    /**
     * Open the share modal.
     */
    openModal() {
        this.setState({
            modalOpen: true,
        });
    }

    /**
     * Close the share modal.
     */
    closeModal() {
        this.setState({
            modalOpen: false,
        });
    }

    /**
     * Ensure latest webinar data is accessable when nudge card is used.
     */
    componentDidMount() {
        this.props.getWebinarInfo();
    }

    /**
     * Handle CTA clicks, follow/unfollow.
     */
    handleClick() {
        if (this.props.onClick && !this.props.isFollowed) {
            this.props.onClick(this.props.id, this.props.dataId);
        }
    }

    /**
     * Handle dismiss clicks.
     */
    handleDismiss() {
        if (this.props.onDismiss) {
            this.props.onDismiss(this.props.id, this.props.dataId);
        }
    }

    /**
     * Render response buttons for app download nudge based on user device.
     */
    renderButtonDeviceBased() {
        if (isIOS && isMobileOnly) {
            return (
                <Button
                    type={TYPE_PRIMARY_MEDIUM}
                    to={APPLICATION_URL_APPLE_STORE}
                >
                    <FormattedMessage id="nudgeCard.webinar.install" />
                </Button>
            );
        } else if (isAndroid && isMobileOnly) {
            return (
                <Button
                    type={TYPE_PRIMARY_MEDIUM}
                    to={APPLICATION_URL_GOOGLE_PLAY}
                >
                    <FormattedMessage id="nudgeCard.webinar.install" />
                </Button>
            );
        } else {
            return (
                <TestFlightLearnMore>
                    <Button
                        to="/download"
                        type={TYPE_PRIMARY_MEDIUM}
                    >
                        <FormattedMessage id="nudgeCard.testflight.buttonLabel" />
                    </Button>
                </TestFlightLearnMore>
            );
        }
    }

    /**
     * Render the nudge card and underlying components.
     */
    render() {
        let { forceMinWidth, title, type, description, showTestflightDismiss, appInstalled, url } = this.props;
        let altTitle = description;
        if (!title) {
            if (type === TYPE_SOURCE) {
                title = labels.translations['nudgeCard.source.default.title'];
            } else if (type === TYPE_TESTFLIGHT) {
                title = labels.translations['nudgeCard.testflight.title'];
                description = isAndroid ? labels.translations['nudgeCard.testflight.description.android']
                    : isMobile ? labels.translations['nudgeCard.testflight.description.iphone']
                    : labels.translations['nudgeCard.testflight.description'];
            } else if (type === TYPE_WEBINAR) {
                title = this.props.profileRefinementCard.title;
                if (title == undefined) {
                    title = labels.translations['nudgeCard.webinar.title'];
                }
                url = `/learn/webinar/${this.props.profileRefinementCard.code}`;
            } else if (type === TYPE_PEER) {
                title = labels.translations['nudegCard.default.peer'];
            } else {
                title = labels.translations['nudgeCard.default.topic.default.title'];
            }
        }

        const dataPage = "linkBtn";

        return (
            <React.Fragment>
                {(type === TYPE_PEER)
                    && <ShareModal
                        isOpen={this.state.modalOpen}
                        closeModal={this.closeModal}
                    />}
                <Container
                    data-rel="NudgeCard"
                    type={type}
                    forceMinWidth={forceMinWidth}
                >
                    <ColorBar />
                    <Title>
                        {title}
                    </Title>
                    <CenterContent type={type}>
                        {type === TYPE_PEER
                            && <FormattedMessage
                                id="nudgeCard.peer.description"
                            />}

                        {type === TYPE_WEBINAR
                            && <FormattedMessage
                                id="nudgeCard.webinar.description"
                                values={{ date: moment(this.props.profileRefinementCard.startTimestamp).format('MMMM DD') }}
                            />}

                        {type === TYPE_SOURCE
                            && <Image
                                src={`/api/${getContentSourcesImageUrl(this.props.imageUrl)}`}
                                alt={altTitle}
                            />}

                        {(type === TYPE_TOPIC || type === TYPE_TESTFLIGHT)
                            && description}
                    </CenterContent>
                    <ControlsContainer type={type}>
                        {type === TYPE_PEER
                            && <Button
                                type={TYPE_PRIMARY_MEDIUM}
                                noAnchor={true}
                                onClick={this.openModal}
                            >
                                <FormattedMessage id="nudgeCard.peer.buttonLabel" />
                            </Button>}

                        {type === TYPE_WEBINAR
                            && <Button
                                to={url}
                                type={TYPE_PRIMARY_MEDIUM}
                            >
                                <FormattedMessage id="nudgeCard.webinar.buttonLabel" />
                            </Button>}

                        {(type === TYPE_SOURCE || type === TYPE_TOPIC)
                            && <div
                                data-analytics-placement="Button : body"
                                data-analytics-label="trackLink : button"
                                data-analytics-id={"nudge:" + this.props.description + ":" + (!this.props.isFollowed ? "followed" : "unfollowed")}
                            >
                                <Button
                                    type={TYPE_PILL}
                                    to={this.props.url}
                                    onClick={this.handleClick}
                                    selected={this.props.isFollowed}
                                >
                                    {!this.props.isFollowed && this.props.buttonLabel}
                                    {this.props.isFollowed && this.props.followedButtonLabel}
                                </Button>
                            </div>}

                        {((!this.props.isFollowed && (type === TYPE_SOURCE || type === TYPE_TOPIC)) || type === TYPE_WEBINAR || type === TYPE_PEER)
                            && <TypeSrcTpcNotInt
                                data-analytics-placement="Button : body"
                                data-analytics-label="trackLink : button"
                                data-analytics-id={"nudge:dismiss:" + this.props.description}
                            >   <TypeSrcTpcNotIntBtn>
                                    <Button
                                        type={TYPE_GHOST}
                                        to={this.props.dismissUrl}
                                        onClick={this.handleDismiss}
                                        dataPage={dataPage}
                                    >
                                        {this.props.dismissButtonLabel}
                                    </Button>
                                </TypeSrcTpcNotIntBtn>
                            </TypeSrcTpcNotInt>}

                        {(type === TYPE_TESTFLIGHT && !appInstalled)
                            && this.renderButtonDeviceBased()}

                        {showTestflightDismiss === true
                            && <Button
                                type={TYPE_GHOST}
                                to="#"
                                onClick={this.handleClick}
                            >
                                <FormattedMessage id="nudgeCard.testflight.secondaryButtonLabel" />
                            </Button>}
                    </ControlsContainer>

                    {this.props.isFollowed
                        && <Placeholder />}
                </Container>
            </React.Fragment>
        );
    }
}

NudgeCard.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string,
    buttonLabel: PropTypes.string,
    dismissButtonLabel: PropTypes.string,
    dismissUrl: PropTypes.string,
    onClick: PropTypes.func,
    onDismiss: PropTypes.func,
    id: PropTypes.string,
    dataId: PropTypes.string,
    type: PropTypes.string,
    imageUrl: PropTypes.string,
    isFollowed: PropTypes.bool,
    followedButtonLabel: PropTypes.string,
    showTestflightDismiss: PropTypes.bool,
    appInstalled: PropTypes.bool,
    forceMinWidth: PropTypes.bool,
};

NudgeCard.defaultProps = {
    title: null,
    description: null,
    url: null,
    buttonLabel: labels.translations['nudgeCard.default.buttonLabel'],
    dismissButtonLabel: labels.translations['nudgeCard.default.dismissButtonLabel'],
    dismissUrl: null,
    onClick: null,
    onDismiss: null,
    id: null,
    dataId: null,
    type: TYPE_SOURCE,
    imageUrl: null,
    isFollowed: false,
    showTestflightDismiss: false,
    followedButtonLabel: labels.translations['nudgeCard.default.followButtonLabel'],
    appInstalled: false,
    forceMinWidth: false,
}

const mapStateToProps = state => ({
    webinarMeta: state.webinar.webinar,
    profileRefinementCard: state.profileRefinementCard,
});

const mapDispatchToProps = dispatch => ({
    getWebinarInfo: (id) => dispatch(getWebinarInfo()),
});


export default connect(mapStateToProps, mapDispatchToProps)(NudgeCard);