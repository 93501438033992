import React from 'react';

import {
    TOPIC_HEADING_TRANSLATION_ID,
    TOPIC_QUERY,
} from 'state-management/constants/pageableNews';
import LandingContainer from 'containers/PageableNews/LandingContainer';

/**
 * Topic landing page.
 * @param {*} props 
 */
const TopicLandingContainer = props => (
    <LandingContainer
        {...props}
        query={TOPIC_QUERY}
        headingTranslationId={TOPIC_HEADING_TRANSLATION_ID}
        type="topic"
    />
);

export default TopicLandingContainer;
