import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET, SMALL_MOBILE, FROM_MOBILE_TO_TABLET } from '../../utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS, RGB_COLORS } from '../../utils/variables';

export const stepperHolderStyles = css`
    background: ${COLORS.LIGHT_GRAY1};
    box-shadow: 0 -11px 20px 0 rgba(${RGB_COLORS.BLACK}, 0.05);
    height: 60px;
    width: 100%;

    @media screen and ${FROM_TABLET} {
        height: 80px;
    }
`;

export const stepperWrapperStyles = css`
    display: flex;
    height: 100%;

    /* stylelint-disable */

    ${props => props.variant && props.variant.isFinished && css`
        pointer-events: none;
    `};

    /* stylelint-enable */
`;

export const progressBarStyles = css`
    left: 0;
    margin-top: -3px;
    position: absolute;
`;

export const stepperStepsContainer = css`
    display: flex;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;

    @media screen and ${SMALL_MOBILE} {
        left: 0;
        width: 100%;
        position: absolute;
    }

    @media screen and ${FROM_MOBILE_TO_TABLET} {
        left: 0;
        width: 100%;
        position: relative;
    }

    @media screen and ${FROM_TABLET} {
        position: relative;
    }
`;

export const stepperStepWrapper = css`
    align-items: center;
    color: ${COLORS.SAPPHIRE};
    display: flex;
    flex: 1;
    font-weight: 600;
    justify-content: center;
    position: relative;

    &::before {
        border-radius: 50%;
        box-shadow: 0 2px 10px 0 rgba(${RGB_COLORS.BLACK}, 0.16);
        left: 50%;
        position: absolute;
        top: 0;
        transform: translate(-50%, -50%);        
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        content:"${props => props.variant && props.variant.stepNo}";
        text-align: center;       
        color: ${COLORS.DARK_GRAY1};
        background-color: ${COLORS.TRANSPARENT_OCEAN};
        //display: flex;
        //align-items: center;
        //justify-content: center;
        //

        @media screen and ${SMALL_MOBILE} {
            display:none;
        }

        @media screen and ${FROM_MOBILE_TO_TABLET} {
            display:none;
        }

        @media screen and ${FROM_TABLET} {
            width: 20px;
            height: 20px;
            font-size: 10px;
            line-height: 2;
        }

        @media screen and ${FROM_DESKTOP} {
            width: 31px;
            height: 31px;
            font-size: 15px;
            line-height: 2.2;
        }

        ${props => props.variant && props.variant.previous && css`
            background-color: ${COLORS.SAPPHIRE};
            color: ${COLORS.WHITE};
            font-weight: bold;
        `};

        ${props => props.variant && props.variant.current && css`
            background-color: ${COLORS.SAPPHIRE};
            color: ${COLORS.WHITE};
            font-weight: bold;
        `};
    }

    /* stylelint-disable */

    ${props => props.variant && props.variant.current && css`
        font-weight: 700;
        color: ${COLORS.SAPPHIRE};
    `}

    ${props => props.variant && props.variant.next && css`
        color: ${COLORS.LIGHT_GRAY1};
    `}

    /* stylelint-enable */
`;

export const stepperTitleStyles = css`
    display: none;
    text-transform: Capitalize;
    font-family: ${FONT_AVENIR_NEXT};  
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;   

    ${props => props.variant && props.variant.current && css`       
        color: ${COLORS.BLACK};
        font-weight: bold;
    `}

    ${props => props.variant && props.variant.previous && css`
        color: ${COLORS.BLACK};
        font-weight: bold;
    `}

    ${props => props.variant && props.variant.next && css`
        color: ${COLORS.MEDIUM_GRAY2};
    `}

    @media screen and ${SMALL_MOBILE} {
        font-size: 12px;
        line-height: 2.58;
        letter-spacing: -0.15px;

        ${props => props.variant && props.variant.previous && css`
            display:none;
        `}     

        ${props => props.variant && props.variant.current && css`          
            padding-top:0;   
            display: flex;
            color:${COLORS.BLACK};
        `}

       ${props => props.variant && props.variant.next && css`
            display:none;
        `}
    }

    @media screen and ${FROM_MOBILE_TO_TABLET} {
        font-size: 12px;
        line-height: 2.58;
        letter-spacing: -0.15px;
        
        ${props => props.variant && props.variant.previous && css`
            display:none;
        `}     

        ${props => props.variant && props.variant.current && css`          
            padding-top:0px;   
            display: flex;
            color:${COLORS.BLACK};
        `}

        ${props => props.variant && props.variant.next && css`
            display:none;
        `}
    }

    @media screen and ${FROM_TABLET} {
        display: flex;
        font-size: 12px;
        line-height: 2.58;
        letter-spacing: -0.15px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 15px;
        line-height: 2.07;
        letter-spacing: -0.19px;
    }
`;
export const titleNumberStyles = css`
    border-radius: 50%;
    box-shadow: 0 2px 10px 0 rgba(${RGB_COLORS.BLACK}, 0.16);
    position:relative;
    margin-top: 20px;
    transform: translate(-50%, -50%);        
    font-size: 10px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;       
    color:${COLORS.WHITE};
    width: 20px;
    height: 20px;
    background-color:${COLORS.SAPPHIRE};
`

export const buttonWrapperStyles = css`
    align-items: center;
    display: flex;
    flex: 1 0 auto;

    /* stylelint-disable */
    
    ${props => props.variant && props.variant.isNext && css`
        justify-content: flex-end;
    `};

    ${props => props.variant && props.variant.isHidden && css`
        visibility: hidden;
    `};

    /* stylelint-enable */
`;

export const buttonStyles = css`
    align-items: center;
    display: flex;  
    /* stylelint-disable */

    ${props => props.variant && props.variant.isDisabled && css`
        cursor: default;
    `};

    /* stylelint-enable */
`;

export const stepperIconWrapper = css`
    align-items: center;
    background-color: transperant;
    border-radius: 50%;
    display: flex;
    height: 34px;
    justify-content: center;
    width: 34px;
`;

export const iconStyles = css`
    fill: ${COLORS.SAPPHIRE};

    ${props => props.variant && props.variant.isDisabled && css`
        fill: ${COLORS.MEDIUM_GRAY1};
    `};

    @media screen and ${SMALL_MOBILE} {
        width: 18px;
        height: 32px;
    }

    @media screen and ${FROM_TABLET} {
        width: 18px;
        height: 32px;
    }

    @media screen and ${FROM_DESKTOP} {
        width: 28px;
        height: 48px;
    }
`;

export const stepperButtonTitleStyles = css`
    display: none;
    font:${FONT_AVENIR_NEXT};
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.07px;    
    text-transform: uppercase;
    text-align: center;
    color:${COLORS.WHITE};
    background-color:${COLORS.SAPPHIRE};
    padding: 15px 32px;
    /* stylelint-disable */

    &:hover{
        background-color:${COLORS.DARK_SAPPHIRE};
    }

    ${props => props.disabled && css`
        color:${COLORS.LIGHT_GRAY3};
    `};

    /* stylelint-enable */
    @media screen and ${SMALL_MOBILE} {
        padding: 12px 20px;
        letter-spacing: 1.5px;
        display: inline-flex;
    }  

    @media screen and ${FROM_MOBILE_TO_TABLET} {
        padding: 12px 20px;
        letter-spacing: 1.5px;
        display: inline-flex;
    }

    @media screen and ${FROM_TABLET} {
        display: inline-flex;
    }
    
    @media screen and ${FROM_DESKTOP} {
        display: inline-flex;
    }
`;
