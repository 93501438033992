import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import {
    FONT_AVENIR_NEXT,
    COLORS,
    ANIMATIONS,
} from 'utils/variables';

export const qaWrapperStyles = css`
    display: flex;
    flex-direction: column;
    margin: 0;
    position: relative;
    width: 100%;
`;

export const qaContainerStyles = css`
    border-bottom: solid 1px ${COLORS.LIGHT_GRAY3};
    margin: 0;
    padding: 30px 0;
    word-wrap: break-word;

    &:first-child {
        padding-top: 0;
    }

    @media screen and ${FROM_TABLET} {
        padding: 50px 0;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 55px 0;
    }
`;

export const questionWrapperStyles = css`
    line-height: 1.5;
    position: relative;
    word-wrap: break-word;
`;

export const topicStyles = css`
    background: transparent;
    border: 0;
    color: ${COLORS.DARK_GRAY2};
    display: block;
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 24px;
    font-weight: 600;
    height: auto;
    letter-spacing: -0.4px;
    line-height: 1.92;
    margin: 0;
    padding: 0 45px 0 0;
    position: relative;
    text-align: left;
    text-transform: none;
    width: 100%;

    &:hover,
    &:focus {
        background: transparent;
        border: 0;
        color: ${COLORS.DARK_GRAY2};
    }

    @media screen and ${FROM_TABLET} {
        font-size: 26px;
        letter-spacing: -0.5px;
        line-height: 1.77;
        padding: 0 75px 0 0;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 36px;
        letter-spacing: -0.6px;
        line-height: 1.28;
        padding: 0 100px 0 0;
    }
`;

export const answerWrapperStyles = css`
    display: ${props => (props['aria-expanded'] ? 'block' : 'none')};
    margin: 0;
    padding: 0;
`;

export const questionStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    margin-top: 30px;

    p {
        margin-top: 0;
    }

    @media screen and ${FROM_TABLET} {
        margin-top: 40px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 24px;
        line-height: 1.5;
    }
`;

export const answerStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 12px;
    line-height: 1.67;
    margin-top: 30px;

    p {
        margin-top: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and ${FROM_TABLET} {
        font-size: 14px;
        line-height: 1.43;
        margin-top: 25px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 18px;
        letter-spacing: -0.3px;
        line-height: 1.67;
        margin-top: 60px;
    }
`;

export const arrowIconStyles = css`
    height: 9px;
    margin-top: -4px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: ${props => (props['aria-expanded'] ? 'rotate(180deg)' : 'none')};
    width: 17px;

    path {
        transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;
        stroke: ${COLORS.SAPPHIRE};
        stroke-width: 6px;
    }

    @media screen and ${FROM_TABLET} {
        height: 19px;
        margin-top: -9px;
        right: 15px;
        width: 34px;

        path {
            stroke-width: 3px;
        }
    }

    @media screen and ${FROM_DESKTOP} {
        right: 20px;
    }

    &:hover {
        path {
            stroke: ${COLORS.DARK_SAPPHIRE};
        }
    }
`;
