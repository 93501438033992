import { css } from 'styled-components';
import { GUTTER, getSpanInPixels } from 'utils/grid';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import {
    FONT_AVENIR_NEXT,
    COLORS,
    RGB_COLORS,
} from 'utils/variables';

const tabletSpacing = (GUTTER.tablet / 2) + getSpanInPixels(1, false, 'tablet');
const desktopSpacing = GUTTER.desktop;

export const sourceTabs = css`
    margin-bottom: 10px;
    height: 50px;
    max-height: 50px;
    position: relative;
    
    a {
        height: 16px;

        span {
            height: 14px;
            display: inline-block;
            overflow: hidden;
        }
    }

    a:first-child {
        margin-right: 24px;
    }
`;

export const sectionStyles = css`
    background-color: rgba(${RGB_COLORS.DARK_GRAY1}, 0.03);
    box-sizing: border-box;
    flex: 1 0 auto;
    min-height: calc(100vh - 120px);
    padding: 30px 0;

    @media screen and ${FROM_TABLET} {
        min-height: calc(100vh - 180px);
        padding: 60px 0;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 80px 0;
    }
`;

export const stickyContainerStyles = css`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and ${FROM_TABLET} {
        flex-direction: row;
    }
`;

export const containerStyles = css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 30px;

    @media screen and ${FROM_TABLET} {
        padding: 0 ${tabletSpacing}px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 0 ${desktopSpacing}px;
    }
`;

export const onboardingSectionContainerStyles = css`
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    @media screen and ${FROM_TABLET} {
        flex-direction: row;
    }
`;

export const onboardingSectionTextStyles = css`
    color: ${COLORS.DARK_GRAY1};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 14px;
    font-weight: normal;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        font-size: 16px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.1px;
    }
`;

export const onboardingSectionTextSmallStyles = css`
    color: ${COLORS.DARK_GRAY1};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 12px;
    letter-spacing: 0.5px;
    font-weight: 500;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        font-size: 12px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 12px;
    }
`;

export const onboardingSectionErrorStyles = css`
    color: ${COLORS.ALERT_RED};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 14px;
    font-weight: 500;
    padding: 10px 0 0;
    word-wrap: break-word;
    outline: none;
`;

export const onboardingTabsContainerStyles = css`
    margin: -11px -14px 0;

    @media screen and ${FROM_TABLET} {
        margin: -18px 0 0;
    }
`;
