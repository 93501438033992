import { css } from 'styled-components';
import {
    FROM_TABLET, FROM_DESKTOP,
} from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS, ANIMATIONS } from 'utils/variables';

export const outerContainerStyles = css`
    padding-bottom: 80px;
    background: ${COLORS.LIGHT_GRAY1};
`;

export const stickyCategory = css`
    font-size: 12px;
    letter-spacing: 1.8px;
    color: ${COLORS.DARK_GRAY2};
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: ${COLORS.WHITE};
    position: fixed;
    padding-left: 16px;
    left: 0;
    top: 0;
    right: 0;
    z-index: 5;

    @media screen and ${FROM_TABLET} {
        padding-left: 38px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding-left: 0;
    }
`;

export const stickyContent = css`
    max-width: 1170px;
    margin: 0 auto;
`;

export const noTopicsStyles = css`
    font-size: 18px;
    padding-bottom: 140px;
    padding-top: 40px;
    text-align: center;
    letter-spacing: -0.07px;
    color: ${COLORS.BLACK};
    font-weight: 500;

    a {
        background: transparent;
        border: none;
        color: ${COLORS.SAPPHIRE};
        cursor: pointer;
        display: inline;
        font-family: ${FONT_AVENIR_NEXT};
        font-size: 18px;
        font-weight: 500;
        padding: none;
        transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;
        letter-spacing: -0.07px;
        text-decoration: none;

        &:hover {
            color: ${COLORS.SAPPHIRE};
        }
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 26px;
        line-height: 32px;
        letter-spacing: -0.1px;

        a {
            font-size: 26px;
            line-height: 32px;
            letter-spacing: -0.1px;
        }
    }
`;

export const categoryBlockStyles = css`
    display: block;
    margin-bottom: 40px;

    @media screen and ${FROM_TABLET} {
        margin-bottom: 80px;
    }
`;

export const categoryLeftColumnStyles = css`
    margin-bottom: 8px;

    h3 {
        font-size: 12px;
        letter-spacing: 1.8px;
        color: ${COLORS.DARK_GRAY2};
        font-weight: 600;
        margin-bottom: 7px;
        margin-top: 20px;
        text-transform: uppercase;
        white-space: nowrap;
    }

    p {
        display: none;
    }

    @media screen and ${FROM_TABLET} {
        margin-bottom: 0;
        margin-right: 80px;
        max-width: 320px;
        width: 26%;
    }

    @media screen and ${FROM_DESKTOP} {
    }
`;

export const categoryRightColumnStyles = css`
    flex: 1;
`;

export const navContainerStyles = css`
    margin-bottom: 30px;
    text-align: right;

    @media screen and ${FROM_TABLET} {
        border: none;
    }
`;

export const navWrapperStyles = css`
    display: block;
    text-align: left;
    padding-bottom: 10px;

    &>div {
        &>div {
            width: 100%;
        }
    }

    button {
        color: ${COLORS.MEDIUM_GRAY2} !important;

        svg {
            fill: ${COLORS.MEDIUM_GRAY2} !important;
            stroke: ${COLORS.MEDIUM_GRAY2} !important;
        }
    }

    a {
        padding-left: 4px;
        white-space: nowrap;
    }

    button {
        padding-left: 4px;
        width: 100%;
        padding-right: 4px;

        svg {
            right: 4px;
        }
    }

    @media screen and ${FROM_TABLET} {
        display: inline-block;
        padding-bottom: 0;

        &>div {
            &>div {
                width: 220px;
            }
        }

        a {
            padding-left: 24px;
        }

        button {
            padding: 10px 50px 10px 24px;
            width: auto;

            svg {
                right: 24px;
            }
        }
    }

    .Dropdown__StyledDropdownOptions-jyOoOL{
        &>li:first-child{
            border-bottom: 2px solid ${COLORS.SAPPHIRE};
        }
    }
`;

export const containerItemStyles = css`
    background: ${COLORS.LIGHT_GRAY1};
`;

export const headerStyles = css`
    background: ${COLORS.LIGHT_GRAY1};
    padding-bottom: 0;
`;

export const ctaBarStyles = css`
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 40px;
    text-align: center;

    @media screen and ${FROM_TABLET} {
        display: block;
        margin-bottom: 100px;
        text-align: right;
    }
`;

export const containerStyles = css`
    margin-bottom: 46px;

    @media screen and ${FROM_TABLET} {
        margin-top: 30px;
    }
`;

export const leftColumnStyles = css`
    font-size: 20px;
    font-weight: 600;

    @media screen and ${FROM_TABLET} {
        font-size: 28px;
        max-width: 270px;
        padding-top: 17px;
    }
`;

export const rightColumnStyles = css`
    flex: 1;
    flex-wrap: wrap;

    @media screen and ${FROM_TABLET} {
        display: flex;
    }
`;

export const topicLinkStyles = css`
    border-bottom: 2px solid ${COLORS.MEDIUM_GRAY1};
    color ${COLORS.SAPPHIRE};
    display: block;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 17px;
    padding-top: 17px;
    position: relative;
    text-decoration: none;
    transition: 0.4s all;
    width: auto;

    span {
        display: inline-block;
        overflow: hidden;
        position: relative;
        text-overflow: ellipsis;
        top: 2px;
        white-space: nowrap;
        width: calc(100% - 26px);
    }

    svg {
        height: 18px;
        position: absolute;
        right: 0;
        top: 20px;
        stroke: ${COLORS.OCEAN};
        stroke-width: 2px;
        width: 22px;
    }

    &:hover {
        color: ${COLORS.BLACK};
    }

    @media screen and ${FROM_TABLET} {
        font-size: 20px;
        margin-left: 30px;
        width: calc(50% - 30px);

        svg {
            top: 22px;
        }
    }

    @media screen and ${FROM_DESKTOP} {
        width: calc(33.3% - 30px);
    }
`;

export const preferencesCtaStyles = css`
    color: ${COLORS.BLACK};
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 2.4px;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.4s all;

    &:hover {
        color: ${COLORS.MEDIUM_GRAY2};
    }

    @media screen and ${FROM_TABLET} {
        margin-right: 30px;
    }
`;

export const viewCtaStyles = css`
    border: 2px solid ${COLORS.SAPPHIRE};
    color: ${COLORS.SAPPHIRE};
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2.4px;
    margin-bottom: 20px;
    padding: 14px 18px;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.4s all;

    &:hover {
        background: ${COLORS.SAPPHIRE};
        border-color: ${COLORS.WHITE};
        color: ${COLORS.WHITE};
    }

    @media screen and ${FROM_TABLET} {
        margin-bottom: 0;
    }
`;
