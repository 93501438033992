import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS, RGB_COLORS } from 'utils/variables';

export const quoteContainerStyles = css`
    background-color: ${COLORS.WHITE};
    box-shadow: 0 4px 20px rgba(${RGB_COLORS.BLACK}, 0.16);
    display: flex;
    flex-direction: column;
    font-family: ${FONT_AVENIR_NEXT};
    justify-content: space-around;
    padding: 35px;

    @media screen and ${FROM_TABLET} {
        padding: 60px 60px 65px;
    }

    @media screen and ${FROM_DESKTOP} {
        max-width: 543px;
    }
`;

export const quoteStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.3px;
    line-height: 1.33;
    margin-bottom: 25px;
    word-wrap: break-word;

    p {
        margin: 0;
    }

    @media screen and ${FROM_TABLET} {
        font-size: 24px;
        line-height: 1.25;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 60px;
    }
`;

export const signatureStyles = css`
    display: flex;
    flex-direction: column;
`;

export const authorStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-size: 15px;
    font-weight: bold;
    line-height: 1.47;
    word-wrap: break-word;
`;

export const authorDetailsStyles = css`
    color: ${COLORS.MEDIUM_GRAY2};
    font-size: 15px;
    letter-spacing: normal;
    line-height: 1.47;
    max-width: 170px;
    white-space: pre-wrap;
    word-wrap: break-word;
`;
