import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import LegalFDIC from 'components/LegalFDIC/LegalFDIC';
import { intlShape, injectIntl } from 'react-intl';
import { Button, Container, Heading } from 'components';
import { setScrollToTop } from 'state-management/actions/scroll';
import Form from 'containers/ResetPassword/ResetPasswordForm/ResetPasswordForm';
import storage from 'utils/store';
import {
    RESET_PASSWORD_EMAIL,
} from 'state-management/constants/forgotPassword';
import {
    changePassword,
    verifyToken,
} from 'state-management/actions/forgotPassword';
import Loader from 'components/Loader/Loader';
import isPasswordFormatValid from 'utils/validators/passwordValidator';
import { IconEmblem, IconError, CheckIconSmall } from 'components/atomics/atoms/Icons/Icons';
import {
    forgotPasswordSectionStyles,
    containerStyles,
    contentWrapperStyles,
    headingContainerStyles,
    headingIconStyles,
    headingStyles,
    contentContainerStyles,
    messageContainerStyles,
    messageIconContainer,
    successIconWrapperStyles,
    successIconStyles,
    errorIconStyles,
    alertMessageStyles,
    alertButtonStyles,
} from 'containers/ResetPassword/styles';

const StyledForgotPasswordSection = styled.section`
    ${forgotPasswordSectionStyles};
`;

const StyledContainer = styled(Container)`
    ${containerStyles};
`;

const StyledContentWrapper = styled.div`
    ${contentWrapperStyles};
`;

const StyledHeadingContainer = styled.div`
    ${headingContainerStyles};
`;

const StyledHeadingIcon = styled(IconEmblem)`
    ${headingIconStyles};
`;

const StyledHeading = styled(Heading)`
    ${headingStyles};
`;

const StyledContentContainer = styled.div`
    ${contentContainerStyles};
`;

export const StyledMessageContainer = styled.div`
    ${messageContainerStyles};
`;

const StyledMessageIconContainer = styled.div`
    ${messageIconContainer};
`;

const StyledSuccessIconWrapper = styled.div`
    ${successIconWrapperStyles};
`;

const StyledSuccessIcon = styled(CheckIconSmall)`
    ${successIconStyles};
`;

const StyledErrorIcon = styled(IconError)`
    ${errorIconStyles};
`;

const StyledAlertMessage = styled.p`
    ${alertMessageStyles};
`;

const StyledAlertButton = styled(Button)`
    ${alertButtonStyles};
`;

const initialValues = {
    password: '',
    passwordConfirm: '',
};

/**
 * Reset password page.
 */
class ResetPassword extends React.Component {
    /**
     * Default state.
     */
    state = {
        submitting: false,
        hasErrors: false,
        verifyRequested: false,
    };

    /**
     * Scroll to top and validate user token.
     */
    componentDidMount() {
        this.props.scrollToTop();
        setTimeout(() => {
            this.props.verifyToken(this.props.match.params.token);

            this.setState({
                verifyRequested: true,
            });
        }, 1000);
    }

    /**
     * Get localized text for a resource ID.
     * @param {*} id 
     * @param {*} values 
     */
    getTranslation(id, values = {}) {
        const { intl: { formatMessage } } = this.props;

        return formatMessage({
            id,
        }, values);
    }

    /**
     * Display success state.
     */
    getSuccessContent = () => (
        <StyledMessageContainer>
            <StyledAlertMessage>
                {this.getTranslation('forgotPassword.success.message')}
            </StyledAlertMessage>
            <StyledAlertButton
                id="forgot-password-success-link"
                href="/sign-in"
            >
                {this.getTranslation('forgotPassword.success.link')}
            </StyledAlertButton>
        </StyledMessageContainer>);

    /**
     * Display error state.
     */
    getErrorContent = () => (
        <StyledMessageContainer>
            <StyledAlertMessage isError={true}>
                {this.getTranslation('forgotPassword.error.token.message')}
            </StyledAlertMessage>
            <StyledAlertButton
                id="forgot-password-error-link"
                href="/sign-in"
            >
                {this.getTranslation('forgotPassword.success.link')}
            </StyledAlertButton>
        </StyledMessageContainer>);

    /**
     * Display the reset form itself.
     */
    getForm = () => (
        <Formik
            initialValues={initialValues}
            onSubmit={values => this.props.changePassword(
                values.password,
                this.props.match.params.token,
            )}
            validate={this.validate}
            validateOnChange={false}
            validateOnBlur={true}
            render={formikProps => (
                <Form
                    {...formikProps}
                    hasErrors={this.state.hasErrors}
                    setSubmitting={this.setSubmitting}
                    confirmBlur={this.handleConfirmBlur}
                    handleForgotPassword={this.handleForgotPassword}
                    curPasswordRef={(password) => { this.password = password; }}
                    passwordRef={(password) => { this.password = password; }}
                    verifyPasswordRef={(ref) => { this.passwordConfirm = ref; }}
                    authRef={(authRef) => { this.authentication = authRef; }}
                    serverError={this.props.changePasswordError}
                />)}
        />);

    /**
     * Handle valdiation on blur of confirm field.
     */
    handleConfirmBlur = () => {
        this.validate({
            password: document.getElementById('password').value,
            passwordConfirm: document.getElementById('passwordConfirm').value,
        });
    }

    /**
     * Validate form values.
     */
    validate = (values) => {
        const errors = {};

        if (!values.password) {
            errors.password = this.getTranslation('error.emptyField', {
                field: this.getTranslation('forgotPassword.field.password'),
            });
        }

        if (values.password !== values.passwordConfirm) {
            errors.passwordConfirm = this.getTranslation('validation.error.passwordMatch');
        }

        if (!values.passwordConfirm) {
            errors.passwordConfirm = this.getTranslation('error.emptyField', {
                field: this.getTranslation('forgotPassword.field.passwordConfirm'),
            });
        }
        
        if (values.password && !isPasswordFormatValid(values.password, storage.get(RESET_PASSWORD_EMAIL))) {
            errors.password = this.getTranslation('forgotPassword.input.passwordValidation.format');
        }

        if (Object.keys(errors).length > 0 && this.state.submitting) {
            const fieldName = Object.keys(errors)[0];

            if (this[fieldName]) {
                document.getElementById(fieldName).focus();
            }
        }
        
        this.setState({
            hasErrors: JSON.stringify(errors) != JSON.stringify({}),
        });

        return errors;
    };

    /**
     * Set form to submitting state.
     */
    setSubmitting = () => {
        this.setState({
            submitting: true,
        }, () => {
            this.validate({
                password: document.getElementById('password').value,
                passwordConfirm: document.getElementById('passwordConfirm').value,
            });

            setTimeout(() => {
                this.setState({
                    submitting: false,
                })
            }, 1000);
        });
    };

    /**
     * Render this and underlying components.
     */
    render() {
        const heading = this.getTranslation('forgotPassword.heading');

        let content = null;
        let isNotForm = false;

        if (this.props.error !== '') {
            content = this.getErrorContent();
            isNotForm = true;
        } else if (this.props.isLoading || !this.state.verifyRequested) {
            return <Loader />;
        } else if (this.props.changePasswordSuccess) {
            content = this.getSuccessContent();
            isNotForm = true;
        } else {
            content = this.getForm();
        }
        
        return (
            <React.Fragment>
                <StyledForgotPasswordSection>
                    <StyledContainer>
                        <StyledContentWrapper isNotForm={isNotForm}>
                            <StyledHeadingContainer isNotForm={isNotForm}>
                                <StyledHeadingIcon />
                                <StyledHeading
                                    id="forgot-password-recover"
                                    level={1}
                                >
                                    {heading}
                                </StyledHeading>
                            </StyledHeadingContainer>
                            <StyledContentContainer isNotForm={isNotForm}>
                                {content}
                            </StyledContentContainer>
                        </StyledContentWrapper>
                    </StyledContainer>
                </StyledForgotPasswordSection>
                <LegalFDIC />
            </React.Fragment>
        );
    }
}

ResetPassword.propTypes = {
    intl: intlShape.isRequired,
    scrollToTop: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            token: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    changePassword: PropTypes.func.isRequired,
    verifyToken: PropTypes.func.isRequired,
    changePasswordError: PropTypes.shape({}),
    error: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    changePasswordSuccess: PropTypes.bool,
};

ResetPassword.defaultProps = {
    changePasswordError: {},
    changePasswordSuccess: false,
};

const mapStateToProps = state => ({
    isLoading: state.forgotPassword.isLoading,
    error: state.forgotPassword.error,
    changePasswordError: state.forgotPassword.changePasswordError,
    changePasswordSuccess: state.forgotPassword.changePasswordSuccess,
});

const mapDispatchToProps = dispatch => ({
    changePassword: (password, token) => dispatch(changePassword(password, token)),
    scrollToTop: () => dispatch(setScrollToTop()),
    verifyToken: token => dispatch(verifyToken(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ResetPassword));
