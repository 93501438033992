import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router';
import { cloneDeep, xor, isEqual } from 'lodash';
import {
    Container, Sticky, StickyContainer,
} from 'components';
import AnimatedStepper from 'components/Stepper/AnimatedStepper';
import { BreakpointsContext, MOBILE } from 'components/Breakpoints/Breakpoints';
import ConnectedTopics from 'containers/Onboarding/Topics/Topics';
import Sources from 'containers/Onboarding/Sources/Sources';
import ConnectedAboutYou from 'containers/Onboarding/AboutYou/AboutYou';
import OnboardingAllDone from 'containers/Onboarding/OnboardingAllDone/OnboardingAllDone';
import {
    setOnBoardingStep as setOnBoardingStepAction,
    setOnboardingFinished,
} from 'state-management/actions/onboarding';
import {
    incrementProfileRefinementSessionCount,
} from 'state-management/actions/profileRefinementCard';
import Loader from 'components/Loader/Loader';
import { containerStyles, sectionStyles } from 'containers/Onboarding/styles';
import { STEP_NUMS, MINIMUM_SELECTED_ITEM_COUNT } from 'state-management/constants/onboarding';
import {
    saveBasicProfileAndRedirect as saveProfileAndRedirectAction,
    saveOnboardedAndSaveBasicProfileAndRedirect as
        saveOnboardedAndSaveBasicProfileAndRedirectAction,
} from 'state-management/actions/basicProfile';
import { clearErrors as clearErrorsAction } from 'state-management/actions/errors';
import { getAdvisorClassifications } from 'state-management/actions/advisorClassifications';
import { getOnboardingTopics } from 'state-management/actions/topics';
import { getSources } from 'state-management/actions/sources';
import { resetRequestSource } from './../../state-management/actions/requestSource';
import isZipCodeValid from 'utils/validators/zipCodeValidator';
import convertClassificationToDropdownOptions
    from 'utils/formatters/advisorClassificationFormatters';
import { ROUTES } from 'containers/App/constants';
import {
    ALL_DONE_PATH,
    SOURCES_PATH,
    TOPICS_PATH,
    ABOUT_YOU_PATH,
} from 'containers/Onboarding/constants';
import scrollHelper from 'utils/scrollToTop';
import { getStorage } from "../../utils/store";
import { setOnBoardingStep } from "../../state-management/actions/onboarding";
import storage from 'utils/store';

const StyledSection = styled.section`
    ${sectionStyles};
`;
const StyledContainer = styled(Container)`
    ${containerStyles};
`;

const getSteps = ({
    changeSourcesSelection,
    changeSelectedTopicIds,
    changeAboutYouState,
    profile,
    aums,
    durations,
    household,
    retirement,
    retirementPlans,
    serviceOfferings,
    showError,
    handleIsValid,
    errorRef,
    globalErrors,
} = {}) => [
        {
            titleId: 'onboarding.sources.title',
            component: Sources,
            props: {
                firstName: profile.firstName,
                selected: profile.sourceIds,
                changeStatus: changeSourcesSelection,
                showError,
                handleIsValid,
                errorRef,
                globalErrors,
            },
            to: SOURCES_PATH,
        }, {
            titleId: 'onboarding.topics.title',
            component: ConnectedTopics,
            props: {
                firstName: profile.firstName,
                selectedTopicIds: profile.topicIds,
                onSelectionChange: changeSelectedTopicIds,
                showError,
                handleIsValid,
                errorRef,
                globalErrors,
            },
            to: TOPICS_PATH,
        }, {
            titleId: 'onboarding.aboutYou.title',
            component: ConnectedAboutYou,
            props: {
                profile,
                aums,
                durations,
                household,
                retirementPlans,
                retirement,
                serviceOfferings,
                changeStatus: changeAboutYouState,
                isZipValid: !showError,
                errorRef,
                globalErrors,
            },
            to: ABOUT_YOU_PATH,
        },
    ];


/**
 * Parent container for all onboarding pages.
 */
class Onboarding extends React.Component {
    /**
     * Gather users onboarding step and state.
     * @param {*} props 
     * @param {*} state 
     */
    static getDerivedStateFromProps(props, state) {
        let stateChanges = null;
        if (state.isLoading !== props.isLoading) {
            stateChanges = {
                isLoading: props.isLoading,
            };
            if (state.isLoading && !props.isLoading) {
                stateChanges.profile = props.profile;
                stateChanges.initialProfile = props.profile;
            }
        }
        if (state.isUpdating !== props.isUpdating) {
            stateChanges = {
                isUpdating: props.isUpdating,
            };
            if (state.isUpdating && !props.isUpdating) {
                stateChanges.profile = props.profile;
                stateChanges.initialProfile = props.profile;
            }
        }
        return stateChanges;
    }

    /**
     * Default constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.store = getStorage();
        this.STORE_KEY = 'onboarding.step';
        this.MINIMAL_SOURCE = 3;

        this.state = {
            isLoading: props.isLoading,
            isUpdating: props.isUpdating,
            isStepsFinished: false,
            isOnboardingFinished: false,
            profile: props.profile,
            initialProfile: props.profile,
            validation: {
                isValid: true,
                showError: false,
            },
        };

        this.errorRef = React.createRef();
        this.initialState = cloneDeep(this.state);

        this.focusHelper = this.focusHelper.bind(this);

        window.addEventListener('keyup', this.focusHelper);
    }

    /**
     * Gather required data at mount.
     */
    componentDidMount() {
        if (this.props.profile.onboarded) {
            window.location = "/home";

            return;
        }

        const { setOnboardingIndex, saveProfileAndRedirect, profile } = this.props;

        this.handleCurrent();
        this.props.getSourcesData();
        this.props.getOnboardingTopicsData();
        this.props.getAdvisorClassificationsData();

        const savedItem = this.getSavedStep();

        if (savedItem != null) {
            const { lastStep } = savedItem;
            saveProfileAndRedirect(savedItem);
            setOnboardingIndex(lastStep);
        } else {
            const lastItem = {
                ...profile,
                ...this.generateRefreshObject(profile),
            };

            this.saveStepToLocal(lastItem);
            saveProfileAndRedirect(lastItem);
            setOnboardingIndex(lastItem.lastStep);
        }
    }

    /**
     * Handle redirection object for onboarding users who are in a step beyond defaults for step 1.
     */
    generateRefreshObject = ({ sourceIds, topicIds }) => {
        if (topicIds.length >= this.MINIMAL_SOURCE) {
            return {
                redirect: '/onboarding/about-you',

                lastStep: 2
            }
        } else if (sourceIds.length >= this.MINIMAL_SOURCE && topicIds.length < this.MINIMAL_SOURCE) {
            return {
                redirect: '/onboarding/pick-topics',
                lastStep: 1
            }
        } else {
            return {
                redirect: '/onboarding/pick-sources',
                lastStep: 0
            }
        }
    }

    /**
     * Handle error focusing on property changes, scroll to top on step change.
     * @param {*} prevProps 
     */
    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.globalErrors, this.props.globalErrors)
            && this.errorRef && this.props.globalErrors.length > 0) {
            this.setShowError(false);
            this.focusError();
        }

        if (prevProps.location.pathname !== this.props.location.pathname) {
            scrollHelper.scrollToTop();
        }
    }

    /**
     * Reset transient oboarding data and clear timer on destroy.
     */
    componentWillUnmount() {
        this.props.resetRequestSource();

        if (this.homeTimer) {
            clearInterval(this.homeTimer);
        }

        window.removeEventListener('keyup', this.focusHelper);
    }

    /**
     * Ensure next item focused by tab key is visible above the sticky action bar.
     * @param {*} event 
     */
    focusHelper(event) {
        if (event.keyCode === 9) {
            const element = document.activeElement;
            const scrollTop = document.body.scrollTop
                ? document.body.scrollTop : document.documentElement.scrollTop;
            const windowHeight = window.innerHeight;
            const stickyMargin = 80;
            const stickyBarHeight = document.getElementById('stepper').clientHeight + stickyMargin;

            let elementBottom = element.offsetTop
                + element.clientHeight;
            let tempElement = element;
            let additionalTop = 0;

            while (tempElement.offsetParent) {
                additionalTop += tempElement.offsetParent.offsetTop;

                tempElement = tempElement.offsetParent;
            }

            let isFormElement = false;

            tempElement = element;

            while (tempElement.parentNode) {
                if (tempElement.parentNode.getAttribute
                    && tempElement.parentNode.getAttribute('data-rel') === 'onboardingContent') {
                    isFormElement = true;

                    break;
                }

                tempElement = tempElement.parentNode;
            }

            elementBottom += additionalTop;

            if (elementBottom > (windowHeight - stickyBarHeight) && isFormElement) {
                const scrollDestination = elementBottom - (windowHeight - stickyBarHeight);

                window.scrollTo(0, scrollDestination);
            }
        }
    }

    /**
     * Get onboarding step IDs.
     */
    getStepperTitleIds = () => {
        const steps = getSteps(this.props);

        return steps.map(step => step.titleId);
    };

    /**
     * Set an error to show to the user.
     */
    setShowError = (shouldShowError) => {
        if (shouldShowError) {
            this.props.clearErrors();
        }

        this.setState(prevState => ({
            validation: {
                ...prevState.validation,
                showError: shouldShowError,
            },
        }));
    };

    /**
     * Focus errored field area.
     */
    focusError = () => this.errorRef.current.focus();

    /**
     * Reset onboarding validation.
     */
    resetValidation = () => {
        this.setState({ validation: this.initialState.validation });

        this.props.clearErrors();
    };

    /**
     * Validate onboarding data.
     */
    handleIsValid = (values) => {
        const { isValid } = this.state.validation;
        const isValueCountValid = values.length >= MINIMUM_SELECTED_ITEM_COUNT;

        if (isValid !== isValueCountValid) {
            this.setState(prevState => ({
                validation: {
                    ...prevState.validation,
                    isValid: isValueCountValid,
                },
            }));
        }
    };

    /**
     * Handle onboarding completion/finish.
     */
    handleComplete = () => {
        const { profile } = this.state;

        this.setState({
            isStepsFinished: true,
        });

        this.props.setOnboardingStep(STEP_NUMS.DONE);
        this.props.setOnboardingFinished(true);
        this.props.incremenetSessionCount();
        this.props.saveOnboardedAndSaveBasicProfileAndRedirect(
            { ...profile, redirect: ALL_DONE_PATH },
        );

        this.removeLocalStep()
        this.resetValidation();
    };

    /**
     * Handle current step changes.
     */
    handleCurrent = () => {
        const { currentPath, openUrl } = this.props;
        const steps = getSteps(this.props);

        let currentIdx = steps.findIndex(step => step.to === currentPath);

        if (currentIdx === -1) {
            currentIdx = STEP_NUMS.SOURCES;
        }

        if (currentIdx >= 0 && currentIdx <= steps.length - 1) {
            const currentStep = steps[currentIdx];
            const url = currentStep && currentStep.to ? currentStep.to : null;

            if (url && url !== currentPath) {
                openUrl(url, true);
            }
        }
    };

    /**
     * Handle 'next step' clicks.
     */
    handleNext = () => {
        const { currentIdx, saveProfileAndRedirect } = this.props;

        this.resetValidation();

        if (currentIdx < getSteps(this.props).length - 1) {
            const nextIdx = currentIdx + 1;
            const nextStep = getSteps(this.props)[nextIdx];
            const url = nextStep && nextStep.to ? nextStep.to : null;
            const savedSteps = this.getSavedStep();
            savedSteps == null || savedSteps.lastStep < nextIdx ? this.saveStepToLocal({
                ...this.state.profile,
                redirect: url,
                lastStep: nextIdx
            }) : null;

            if (url) {
                saveProfileAndRedirect({ ...this.state.profile, redirect: url });
            }
        }
    };

    /**
     * Handle 'previous step' clicks + save.
     */
    handlePreviousClickSave = (redirect) => {
        const { saveProfileAndRedirect, openUrl } = this.props;
        const { profile, initialProfile } = this.state;
        const isZipValid = isZipCodeValid(profile.zip);

        if (isZipValid) {
            saveProfileAndRedirect({ ...profile, redirect });
        } else {
            this.setState({ profile: initialProfile });
            openUrl(redirect);
        }
    };

    /**
     * Handle 'previous step' clicks.
     */
    handleOnPreviousClick = () => {
        const { currentIdx } = this.props;

        this.resetValidation();

        if (currentIdx > 0) {
            const previousIdx = currentIdx - 1;
            const previousStep = getSteps(this.props)[previousIdx];
            const url = previousStep && previousStep.to ? previousStep.to : null;

            if (url) {
                this.handlePreviousClickSave(url);
            }
        }
    };

    /**
     * Get the last saved step.
     */
    getSavedStep = () => {
        const hasStore = this.store.has(this.STORE_KEY);
        return hasStore ? this.store.get(this.STORE_KEY) : null;
    }

    /**
     * Save step data to local storage.
     */
    saveStepToLocal = (data) => {
        this.store.add(this.STORE_KEY, data);
    }

    /**
     * Clear steps data from local storage.
     */
    removeLocalStep = () => {
        this.store.remove(this.STORE_KEY);
    }

    /**
     * Handle next clicks.
     */
    handleOnNextClick = () => {
        const {
            validation: { isValid },
            profile,
        } = this.state;
        const isZipValid = isZipCodeValid(profile.zip);
        const isLast = this.props.currentIdx + 1 >= getSteps(this.props).length;
        if (storage.get('onBoarding')) {
            storage.set('onBoarding', false);
        };

        if (!isValid || (!isZipValid && isLast)) {
            this.setShowError(true);
            this.focusError();
            return;
        }

        if (isLast) {
            this.handleComplete();
        } else {
            this.handleNext();
        }
    };

    /**
     * Handle source select/deselect.
     */
    changeSourcesSelection = (id) => {
        // eslint-disable-next-line
        const profile = cloneDeep(this.state.profile);
        profile.sourceIds = xor(profile.sourceIds, [id]);

        this.handleIsValid(profile.sourceIds);
        this.setState({ profile });
    };

    /**
     * Handle topic select/deselect.
     */
    changeSelectedTopicIds = (selectedIds) => {
        // eslint-disable-next-line
        const profile = cloneDeep(this.state.profile);
        profile.topicIds = selectedIds;

        this.handleIsValid(profile.topicIds);
        this.setState({ profile });
    };

    /**
     * Update about you data step.
     */
    changeAboutYouState = (data) => {
        this.setState((prevState) => {
            const oldProfile = cloneDeep(prevState.profile);
            const profile = { ...oldProfile, ...data };

            return {
                profile,
                validation: {
                    ...prevState.validation,
                    showError: data.zip !== profile.zip && prevState.validation.showError,
                },
            };
        });
    };

    /**
     * Handle user finish completion.
     */
    handleFinish = () => {
        this.setState({
            isOnboardingFinished: true,
        }, () => {
            this.homeTimer = setTimeout(this.redirectToHome, 330);
        });
    };

    /**
     * Redirect the user to the home page.
     */
    redirectToHome = () => {
        this.props.openUrl(ROUTES.HOME);
    };

    /**
     * Render the steps bar.
     */
    renderStepper() {
        const { currentIdx } = this.props;
        const { validation, isUpdating } = this.state;

        return (
            <AnimatedStepper
                isFinished={this.state.isStepsFinished && this.state.validation.isValid}
                isVisible={!this.state.isOnboardingFinished}
                onPreviousClick={this.handleOnPreviousClick}
                onNextClick={this.handleOnNextClick}
                currentIdx={currentIdx}
                titleIds={this.getStepperTitleIds()}
                validation={validation}
                buttonsDisabled={isUpdating}
            />
        );
    }

    /**
     * Render this and underlying components.
     */
    render() {
        if (this.props.isLoading) {
            return (
                <StyledSection>
                    <StyledContainer>
                        <Loader />
                    </StyledContainer>
                </StyledSection>
            );
        }

        const {
            currentPath,
            location,
            aums,
            durations,
            household,
            retirement,
            retirementPlans,
            serviceOfferings,
            globalErrors,
        } = this.props;
        const {
            profile,
            validation,
        } = this.state;

        const steps = getSteps({
            changeSourcesSelection: this.changeSourcesSelection,
            handleIsValid: values => this.handleIsValid(values),
            changeAboutYouState: this.changeAboutYouState,
            changeSelectedTopicIds: this.changeSelectedTopicIds,
            profile,
            aums,
            durations,
            household,
            retirementPlans,
            retirement,
            serviceOfferings,
            showError: validation.showError,
            errorRef: this.errorRef,
            globalErrors,
        });

        return (
            <StickyContainer>
                <StyledSection data-rel="onboardingContent">
                    <BreakpointsContext.Consumer>
                        {(breakpoint) => {
                            const isMobile = breakpoint === MOBILE;
                            return (
                                <StyledContainer>
                                    {
                                        steps.map((step) => {
                                            const loc = Object.assign({}, location, {
                                                pathname: currentPath,
                                            });

                                            return (
                                                <Route
                                                    exact
                                                    key={step.to}
                                                    path={`${step.to}`}
                                                    location={loc}
                                                    render={props => (
                                                        <step.component
                                                            {...props}
                                                            {...step.props}
                                                            filters={this.props.filters}
                                                            isMobile={isMobile}
                                                        />
                                                    )}
                                                />
                                            );
                                        })
                                    }
                                    <Route
                                        exact
                                        path={ALL_DONE_PATH}
                                        render={() => (
                                            <OnboardingAllDone
                                                onAnimationEnd={this.handleFinish}
                                            />
                                        )}
                                    />
                                </StyledContainer>
                            );
                        }}
                    </BreakpointsContext.Consumer>
                </StyledSection>
                <Sticky
                    bottomOffset={0}
                    boundToContainer={Sticky.END}
                    hasPlaceholder
                    leftOffset={0}
                    rightOffset={0}
                    id="onboarding-stepper"
                >
                    {this.renderStepper()}
                </Sticky>
            </StickyContainer>
        );
    }
}

Onboarding.defaultProps = {
    openUrl: () => { },
    aums: [],
    durations: [],
    serviceOfferings: [],
    filters: null,
};

Onboarding.propTypes = {
    clearErrors: PropTypes.func.isRequired,
    currentIdx: PropTypes.number.isRequired,
    currentPath: PropTypes.string.isRequired,
    durations: PropTypes.arrayOf(PropTypes.object),
    getAdvisorClassificationsData: PropTypes.func.isRequired,
    getSourcesData: PropTypes.func.isRequired,
    getOnboardingTopicsData: PropTypes.func.isRequired,
    globalErrors: PropTypes.arrayOf(PropTypes.shape).isRequired,
    isLoading: PropTypes.bool.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    openUrl: PropTypes.func,
    profile: PropTypes.objectOf(PropTypes.any).isRequired,
    aums: PropTypes.arrayOf(PropTypes.object),
    household: PropTypes.arrayOf(PropTypes.object),
    saveProfileAndRedirect: PropTypes.func.isRequired,
    saveOnboardedAndSaveBasicProfileAndRedirect: PropTypes.func.isRequired,
    serviceOfferings: PropTypes.arrayOf(PropTypes.object),
    setOnboardingFinished: PropTypes.func.isRequired,
    setOnboardingStep: PropTypes.func.isRequired,
    resetRequestSource: PropTypes.func.isRequired,
    filters: PropTypes.shape({}),
};

const mapStateToProps = state => ({
    profile: state.basicProfile,
    isLoading: state.basicProfile.isLoading,
    isUpdating: state.basicProfile.isUpdating,
    currentPath: state.router.location.pathname,
    aums: state.advisorClassifications.wmAum,
    household: state.advisorClassifications.wmHouseHold,
    retirement: state.advisorClassifications.retirementAum,
    retirementPlans: state.advisorClassifications.retirementPlans,
    durations: state.advisorClassifications.durations,
    serviceOfferings:
        convertClassificationToDropdownOptions(state.advisorClassifications.serviceOfferings),
    currentIdx: state.onboarding.currentIdx,
    globalErrors: state.errors.errors,
    filters: state.trendingTopicNews.filters,
});

const mapDispatchToProps = dispatch => ({
    getOnboardingTopicsData: () => dispatch(getOnboardingTopics()),
    getSourcesData: () => dispatch(getSources()),
    getAdvisorClassificationsData: () => dispatch(getAdvisorClassifications()),
    clearErrors: () => dispatch(clearErrorsAction()),
    openUrl: (url, useReplace) => dispatch(useReplace ? replace(url) : push(url)),
    saveProfileAndRedirect: data => dispatch(saveProfileAndRedirectAction(data)),
    saveOnboardedAndSaveBasicProfileAndRedirect:
        data => dispatch(saveOnboardedAndSaveBasicProfileAndRedirectAction(data)),
    setOnboardingFinished: isFinished => dispatch(setOnboardingFinished(isFinished)),
    setOnboardingIndex: index => dispatch(setOnBoardingStep(index)),
    setOnboardingStep: step => dispatch(setOnBoardingStepAction(step)),
    resetRequestSource: () => dispatch(resetRequestSource()),
    incremenetSessionCount: () => dispatch(incrementProfileRefinementSessionCount()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
