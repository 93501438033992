import {
    GET_TOPICS,
    GET_TOPICS_SUCCESS,
    GET_TOPICS_FAIL,
    GET_ONBOARDING_TOPICS,
    SET_LOADING_TOPICS_LIST_STATUS,
    GET_ONBOARDING_TOPICS_SUCCESS,
    GET_ONBOARDING_TOPICS_FAIL,
} from 'state-management/constants/topics';

export function getTopics() {
    return {
        type: GET_TOPICS,
    };
}

export function setTopicsListLoadingStatus() {
    return {
        type: SET_LOADING_TOPICS_LIST_STATUS,
    };
}

export function getTopicsSuccess(response) {
    return {
        type: GET_TOPICS_SUCCESS,
        data: response.data,
    };
}

export function getTopicsFailure(error) {
    return {
        type: GET_TOPICS_FAIL,
        error,
    };
}

export function getOnboardingTopics() {
    return {
        type: GET_ONBOARDING_TOPICS,
    };
}

export function getOnboardingTopicsSuccess(response) {
    return {
        type: GET_ONBOARDING_TOPICS_SUCCESS,
        data: response.data,
    };
}

export function getOnboardingTopicsFailure(error) {
    return {
        type: GET_ONBOARDING_TOPICS_FAIL,
        error,
    };
}
