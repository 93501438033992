import React from 'react';
import {HistoryManager} from "../../utils/historyManager";

import {
    TOPIC_HEADING_TRANSLATION_ID,
    TOPIC_QUERY,
} from 'state-management/constants/pageableNews';
import LandingContainer from 'containers/PageableNews/LandingContainer';
import { defaultCipherList } from 'constants';
import storage from 'utils/store';
import { TRENDING_TOPIC_DATA } from 'components/atomics/templates/TrendingTopics/TrendingTopics';

/**
 * See more trending news page.
 * @param {*} props 
 */
const TopicLandingContainer = props => {
    let titleParam = "trendingTopics.page";

    const data = JSON.parse(storage.get(TRENDING_TOPIC_DATA));

    if (data) {
        switch (data.type) {
            case "wm":
                titleParam = "trendingTopics.page.wm";
                break;
            case "experience":
                titleParam = "trendingTopics.page.experience";
                break;
            case "retirement":
                titleParam = "trendingTopics.page.retirement";
                break;
            default:
                //nothing
        };
    }

    let excludedArticleIds = [];
    let previousUrl = "not News";
    const urlLink = store.getState().UrlHistoryReducer.history[0].currentUrl;
    let pathName = store.getState().router.location.pathname;
        if (pathName =="/trending"){
            if(urlLink == "/news?b=1" || urlLink == "/news"){
             previousUrl = "newsPage";                
            }
        }
        if (((data && !data.topicIds) || !data) && previousUrl !== 'newPage') {
            excludedArticleIds = [];

            HistoryManager.sentHistoryEvent('/news');
        } else {
            excludedArticleIds = data ? data.articleIds : [1, 2];
        }

    return (
        <LandingContainer
            {...props}
            topics={data ? data.topicIds : []}           
            excludeArticles={excludedArticleIds}
            trendingType={data ? data.type : null}
            trendingVariant={data ? data.variant : null}
            query={TOPIC_QUERY}
            headingTranslationId={titleParam}
            type="trending"
        />
    );
}

export default TopicLandingContainer;
