import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { createImageUrl } from 'utils/urlUtils';
import { setScrollToTop } from 'state-management/actions/scroll';
import LegalFDIC from 'components/LegalFDIC/LegalFDIC';
import { FRAGMENT_TERMS_AND_CONDITIONS }
    from 'state-management/constants/commonContentFragments';
import { getContentFragments } from 'state-management/actions/commonContentFragments';
import {
    ImageHeading,
    Loader,
    SanitizedHTML,
    TextBlock,
} from 'components';
import { PAGE_NAME_TERMS_AND_CONDITIONS, SITE_SECTION_TERMS_AND_CONDITIONS } from 'utils/analyticsConstants';
import * as analytics from 'utils/adobeAnalytics';
import labels from '../../lang/en.json';
import { StyleBackToTopButton } from 'components/MarketBriefing/page/MarketBriefingPage';
import {
    IconArrowDown,
} from 'components/atomics/atoms/Icons/Icons';

const COVER_IMAGE_URL = createImageUrl('hero-images', 'terms-and-conditions.jpg');
const MOBILE_COVER_IMAGE_URL = createImageUrl('hero-images', 'terms-and-conditions-mobile.jpg');

/**
 * Terms and conditions page.
 */
class TermsAndConditions extends React.Component {

    constructor(props) {
        super(props);        

        this.state = {           
            showBackToTop: false,
        };
        this.handleWindowScroll = this.handleWindowScroll.bind(this);
        this.backToTop = this.backToTop.bind(this);
    }

    /**
     * Render the back to top sticky button.
     */
    renderBackToTop() {
        return (
            <StyleBackToTopButton
                aria-label={labels.translations['marketBriefing.page.scrollToTop']}
                type="button"
                id="backToTopLink"
                onClick={this.backToTop}
                data-analytics-placement="Button : body"
                data-analytics-label="trackLink : button"
                data-analytics-id="backtotop:News Page"
            >
                <IconArrowDown />
            </StyleBackToTopButton>
        );
    }

    /**
     * Track window scrolling for back to top button usage.
     */
    handleWindowScroll() {
        const scrollTop = document.body.scrollTop
            ? document.body.scrollTop : document.documentElement.scrollTop;
        if (scrollTop > 0) {
            this.setState({
                showBackToTop: true,
            });
        } else {
            this.setState({
                showBackToTop: false,
            });
        }
    }

    /**
     * Scroll the user back to the top of page.
     */
    backToTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behaviour: 'smooth'
        });
    }

    /**
     * Gather needed data at mount.
     */
    componentDidMount() {
        const {
            termsAndConditions,
            getTermsAndConditions,
            scrollToTop,
        } = this.props;

        scrollToTop();
        if (!termsAndConditions) {
            getTermsAndConditions();
        }

        window.addEventListener('scroll', this.handleWindowScroll);
    }

    /**
     * Dispatch page view once page is loaded.
     * @param {*} prevProps
     */
    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && prevProps.isLoading) {
            analytics.dispatchPageViewEnd(PAGE_NAME_TERMS_AND_CONDITIONS,
                SITE_SECTION_TERMS_AND_CONDITIONS);
        }
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const { termsAndConditions } = this.props;

        if (!termsAndConditions) {
            return <Loader />;
        }

        let firstContent = termsAndConditions.contentList[0];

        let updateDate = firstContent.hasOwnProperty('date')
            ? moment.utc(new Date(firstContent.date)).format('MMMM D, YYYY')
            : null;

        const headingTitle = <FormattedMessage id="termsAndConditions.title" />;
        const headingSubtitle = updateDate && (
            <FormattedMessage id="termsAndConditions.lastUpdated" values={{ date: updateDate }} />
        );

        return (
            <Fragment>
                <ImageHeading
                    title={headingTitle}
                    subtitle={headingSubtitle}
                    image={COVER_IMAGE_URL}
                    smallImage={MOBILE_COVER_IMAGE_URL}
                />
                <TextBlock>
                    <SanitizedHTML htmlContent={firstContent.text} tag="div" />
                </TextBlock>
                <LegalFDIC />
                {this.state.showBackToTop
                    && this.renderBackToTop()}
            </Fragment>
        );
    }
}

TermsAndConditions.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    termsAndConditions: PropTypes.shape({
        contentList: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string.isRequired,
            code: PropTypes.string.isRequired,
        }))
    }),
    getTermsAndConditions: PropTypes.func.isRequired,
    scrollToTop: PropTypes.func.isRequired,
};

TermsAndConditions.defaultProps = {
    termsAndConditions: undefined,
};

const mapStateToProps = state => ({
    isLoading: state.commonContentFragments.isLoading,
    termsAndConditions: state.commonContentFragments.fragments[FRAGMENT_TERMS_AND_CONDITIONS],
});

const mapDispatchToProps = dispatch => ({
    getTermsAndConditions: () => dispatch(getContentFragments(FRAGMENT_TERMS_AND_CONDITIONS, true)),
    scrollToTop: () => dispatch(setScrollToTop()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
