import { css } from 'styled-components';
import { getSpanInPixels } from 'utils/grid';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS, ANIMATIONS } from 'utils/variables';

export const contentContainerStyles = css`
    box-sizing: border-box;
    margin-bottom: 28px;
    margin-top: 50px;
    width: 100%;

    @media screen and ${FROM_TABLET} {
        box-sizing: content-box;
        margin-bottom: 54px;
        margin-top: 60px;
        max-width: 436px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-top: 68px;
        max-width: ${getSpanInPixels(8)};
    }
`;

export const resetButtonContainer = css`
    margin-top: 24px;
    text-align: left;
`;

export const subHeadingStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    margin: 0;

    @media screen and ${FROM_TABLET} {
        line-height: 1.17;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 26px;
        line-height: 1.39;
    }
`;

export const confirmationSubHeadingStyles = css`
    min-height: 365px;

    @media screen and ${FROM_TABLET} {
        margin: 65px 0 130px;
        min-height: auto;
    }
`;

export const buttonContainerStyles = css`
    text-align: center;
`;

export const formStyles = css`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-family: ${FONT_AVENIR_NEXT};
    margin-top: 44px;

    @media screen and ${FROM_TABLET} {
        margin-top: 52px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-top: 80px;
    }

    textarea {
        outline: none !important;
    }
`;

export const paragraphStyles = css`
    color: ${COLORS.DARK_GRAY2};
    flex: 0 0 auto;
    font-size: 18px;
    margin: 0;
    max-width: 100%;

    @media screen and ${FROM_TABLET} {
        font-size: 18px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 20px;
    }
`;

export const infoParagraphStyles = css`
    line-height: 1.28;
    margin-bottom: 20px;
    margin-top: 56px;
    font-weight: 600;
   
    @media screen and ${FROM_TABLET} {
        line-height: 1.25;
        margin-bottom: 34px;

    }

    @media screen and ${FROM_DESKTOP} {
        line-height: 1.4;
        margin-top: 88px;
        font-weight: 600;
        
        br {
            display: none;
        }
    }
`;

export const topicWrapperStyles = css`
    text-align: left;
    max-width: 200px;
    margin-bottom: 40px;

    &>div {
        &>div:last-child {
            width: auto;
            font-weight: 500;
            font-size: 14px;
            color: ${COLORS.ALERT_RED};
        }
    }
`;

export const topicDropdownStyles = css`
    margin-bottom: 2px;
    min-width: 265px;
    text-align: left;

    @media screen and  (min-width: 414px) {
        min-width: 308px;
    }

    @media screen and ${FROM_TABLET} {
        margin-bottom: 0;
        min-width: 368px;
    }

    @media screen and ${FROM_DESKTOP} {
        min-width: 280px;
    }
`;

export const nameWrapperStyles = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    @media screen and ${FROM_TABLET} {
        margin-bottom: 50px;
    }
`;

export const nameInputStyles = css`
    display: inline-block;
    width: 100%;

    @media screen and ${FROM_TABLET} {
        padding-top: 23px;
    }

    @media screen and ${FROM_DESKTOP} {
        width: 40%;

        &:not(:last-child) {
            margin-right: 28px;
        }
    }
`;

export const contactInfoWrapperStyles = css`
    display: flex;
    flex-direction: column;
    margin-bottom: 56px;
    margin-top: 18px;
    width: 100%;

    @media screen and ${FROM_TABLET} {
        margin-bottom: 66px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 84px;
    }
`;

export const contactOptionsWrapperStyles = css`
    display: flex;
    justify-content: flex-start;
`;

export const radioInputStyles = css`
    margin-left: 16px;

    &:first-child {
        margin-left: 0;
    }

    @media screen and ${FROM_TABLET} {
        margin-left: 36px;
    }
`;

export const submitButtonStyles = css`
    color: ${COLORS.WHITE};
    background-color:${COLORS.OCEAN};
    font-size: 19px;
    font-weight: 700;
    letter-spacing: 0;
    padding: 19px 48px;
    text-transform: none;
    height: auto;
    transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;
    
    ${props => !props.isActive  && css `
        background-color: ${COLORS.LIGHT_GRAY3} !important;
        border-color: ${COLORS.LIGHT_GRAY3} !important;
    `}
`;

export const errorContainerStyles = css`
    margin-bottom: 10px;
    margin-top: 16px;
    width: 200px;
    &:focus{
        outline: none;
    }

    @media screen and ${FROM_TABLET} {
        width: 100%;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-top: 10px;   
    }
`;

export const errorStyles = css`
    color: ${COLORS.ALERT_RED};
    font-size: 14px;
    line-height: 1.4;
    margin: 0;
`;
