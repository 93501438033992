import { css } from 'styled-components';
import { 
    FROM_DESKTOP,
    FROM_TABLET,
} from 'utils/mediaQueries';
import {
    COLORS,
    FONTS,
    ANIMATIONS,
} from 'utils/variables';

export const container = css`
    background-color: ${COLORS.WHITE};
    width: calc(100% - 64px);
    padding: 52px 16px 32px 16px;
    margin: auto;
    text-align: center;

    @media screen and ${FROM_TABLET} {
        width: 50%;
        padding: 42px 66px 42px 66px;
        min-width: 558px;
        box-sizing: border-box;
    }
`;

export const title = css`
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    color: ${COLORS.BLACK};
    font-size: 24px;
    line-height: 33px;
    padding-bottom: 10px;
    font-weight: 600;
    white-space: normal;
    margin: auto;
`;

export const subTitle = css`
    color: ${COLORS.BLACK};
    font-size: 18px;
    padding-bottom: 32px;
    font-weight: normal;
    letter-spacing: 0.12px;
    margin: auto;
`;

export const callout = css`
    color: ${COLORS.BLACK};
    font-size: 15px;
    padding-top: 24px;
    padding-bottom: 16px;
    font-weight: normal;
    letter-spacing: 0.12px;
    margin: auto;
`;

export const buttonRow = css`
    margin: auto;
`;

export const form = css`
    padding-bottom: 34px;

    @media screen and ${FROM_TABLET} {
        padding-bottom: 22px;
    }
`;

export const errorRow = css`
    margin-top: 24px;
    margin-bottom: 16px;
    color: ${COLORS.ALERT_RED};
    a {
        color: ${COLORS.ALERT_RED};
    }
`;

export const fieldRow = css`
    text-align: left;
    max-width: 360px;
    margin: auto;
    padding-bottom: 14px;
    height: 116px;
    overflow: hidden;

    div[data-rel="Input"] div:first-child {
        height: 14px;
    }
`;

export const signUpLinkStyles = css`
    color: ${COLORS.SAPPHIRE};
    transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;

    &:active, &:hover {
        color: ${COLORS.DARK_SAPPHIRE};
    }
`;

