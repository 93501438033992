import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import Button from 'components/atomics/atoms/Button/Button';
import { TYPE_GHOST } from 'components/atomics/atoms/Button/Button';
import styled from 'styled-components';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Modal from 'components/atomics/templates/ClosableModal/ClosableModal';
import OktaMigrationModal from 'containers/SignIn/SignInForm/OktaMigrationModal/OktaMigrationModal';
import ForgotPasswordModal from 'containers/SignIn/SignInForm/ForgotPasswordModal/ForgotPasswordModal';
import ForgotUsernameModal from 'containers/SignIn/SignInForm/ForgotUsernameModal/ForgotUsernameModal';
import {
    Button as oldButton, FieldCheckBox, FieldText, Heading, Link,
} from 'components';
import {
    formContentContainerStyles,
    headingContainerStyles,
    headingStyles,
    formFieldsContainerStyles,
    alertStyles,
    actionsContainerStyles,
    descriptionContainerStyles,
    keepSignedInStyles,
    buttonStyles,
    controlsStyles,
    fieldsWrapperStyles,
    fieldWrapperStyles,
    remindControlsStyles,
    underlinedTextStyles,
} from 'containers/SignIn/SignInForm/styles';
import { ROUTES } from 'containers/App/constants';
import { isEmailValid } from 'utils/validators/emailValidator';
import { FORM_HASH } from 'components/SignInSection/SignInSection';

const StyledFormContentContainer = styled.div`
    ${formContentContainerStyles};
`;

const StyledHeadingContainer = styled.div`
    ${headingContainerStyles};
`;

const StyledHeading = styled(Heading)`
    ${headingStyles};
`;

const StyledFormFieldsContainer = styled.div`
    ${formFieldsContainerStyles};
`;

const StyledRemindControls = styled.div`
    ${remindControlsStyles};
`;

const StyledAlert = styled.p`
    ${alertStyles};
`;

const StyledActionsContainer = styled.div`
    ${actionsContainerStyles};
`;

const StyledDescriptionContainer = styled.p`
    ${descriptionContainerStyles};
`;

const StyledKeepSignedIn = styled(Field)`
    ${keepSignedInStyles};
`;

const StyledFieldsWrapper = styled.div`
    ${fieldsWrapperStyles};
`;

const StyledFieldWrapper = styled.div`
    ${fieldWrapperStyles};
`;

const StyledControls = styled.div`
    ${controlsStyles};
`;

const StyledUnderlinedText = styled(oldButton)`
    ${underlinedTextStyles};
`;

export const StyledButton = styled(oldButton)`
    ${buttonStyles};
`;


const ModalStyles = {
    content: {
        maxWidth: '700px',
        background: 'none',
        border: '0',
        borderRadius: '0',
        bottom: 'auto',
        left: '50%',
        marginRight: '-50%',
        padding: '0',
        right: 'auto',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        textAlign: 'center',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        zIndex: '1',
    },
};

/**
 * Sign in form for sign in page.
 */
export class SignInForm extends React.Component {
    /**
     * Default constructor.
     */
    constructor() {
        super();

        this.state = {
            showPasswordResetMessage: false,
            isSubmitted: false,
            isEdited: false,
            forgotPassword: false,
            disableFieldIcon: false,
            modalOpen: true,
            inputChanged: 0,
            forgotPasswordModalOpen: false,
            forgotUsernameModalOpen: false,
        };

        this.emailRef = React.createRef();
    }

    /**
     * Get localized string resource by ID and insert values.
     * @param {*} id 
     * @param {*} values 
     */
    getTranslation(id, values = {}) {
        const { intl: { formatMessage } } = this.props;

        return formatMessage({
            id,
        }, values);
    }

    /**
     * Handle form element changes.
     */
    handleChange = (e) => {
        const { name } = e.target;
        const { errors, setFieldError } = this.props;


        if (errors.authentication === 10) {
            this.setState({ inputChanged: 10 });
        }

        this.hideMessage();

        if (this.state.isEdited === false || this.state.forgotPassword === true) {
            this.setState({ isEdited: true, forgotPassword: false });
        }

        if (name && errors[name]) {
            setFieldError(name, null);
        }
    };

    /**
     * Handle forgot password link click.
     */
    handleForgotPassword = () => {
        this.setState({ forgotPasswordModalOpen: true });
    };

    /**
     * Handle forgot username link click.
     */
    handleForgotUsername = () => {
        this.setState({ forgotUsernameModalOpen: true });
    };
    
    /**
     * Handle form submission.
     */
    handleSubmit = (values, actions) => {
        this.setState({
            isSubmitted: true,
            isEdited: false,
            forgotPassword: false,
            disableFieldIcon: false,
            inputChanged: 0,
        });

        this.props.handleSubmit(values, actions);

        this.hideMessage();
    };

    /**
     * Hide previous errors.
     */
    hideMessage = () => (
        this.state.showPasswordResetMessage && this.setState({ showPasswordResetMessage: false })
    );

    /**
     * Validate email format.
     */
    validateEmail = (email) => {
        let error = '';

        if (!email) {
            error = this.props.intl.formatMessage({ id: 'validation.error.email.empty' });
        } else if (email.indexOf('@') > -1 && !isEmailValid(email)) {
            error = this.props.intl.formatMessage({ id: 'validation.error.email' });
        }

        if (error !== '') {
            this.props.setFieldError('email', error);
            this.emailRef.focus();
            
            return false;
        }

        return true;
    };

    /**
     * Render this and underlying components.
     */
    render() {
        const {
            errors,
            emailRef,
            passwordRef,
            authRef,
            values,
            isSubmitting,
        } = this.props;
        const {
            isSubmitted,
            isEdited,
            forgotPassword,
            disableFieldIcon,
            showPasswordResetMessage,
            inputChanged,
        } = this.state;
        const isGlobalErrorHidden = !isSubmitting
            && (errors.authentication === undefined || errors.authentication === null);
        const isFieldValid = isSubmitted && isGlobalErrorHidden && isEdited;
        const heading = this.getTranslation('signIn.title');
        const description = this.getTranslation('signIn.desc.new');
        const keepSignedIn = this.getTranslation('signIn.field.keepSignedIn');
        const signIn = this.getTranslation('signIn.signIn');

        return (
            <React.Fragment>
                <form
                    aria-labelledby="heading-welcome-back"
                    noValidate
                    onSubmit={this.handleSubmit}
                    onChange={this.handleChange}
                >
                    <StyledFormContentContainer>
                        <StyledHeadingContainer>
                            <StyledHeading
                                id="heading-welcome-back"
                                level={1}
                                theme="dark"
                            >
                                {heading}
                            </StyledHeading>
                            <StyledDescriptionContainer>
                                {description}
                            </StyledDescriptionContainer>
                        </StyledHeadingContainer>
                        <StyledFormFieldsContainer>
                            <StyledFieldsWrapper>
                                <StyledFieldWrapper>
                                    <Field
                                        id="email"
                                        component={FieldText}
                                        name="email"
                                        label="Email or Username"
                                        ref={emailRef('email')}
                                        isValidated={isFieldValid || forgotPassword}
                                        isSubmitted={(isGlobalErrorHidden && isSubmitted)
                                            || forgotPassword}
                                        hasGlobalError={
                                            (!forgotPassword) ? !isGlobalErrorHidden : false
                                        }
                                    />
                                </StyledFieldWrapper>

                                <StyledFieldWrapper>
                                    <Field
                                        component={FieldText}
                                        name="password"
                                        id="password"
                                        label="Password"
                                        type="password"
                                        ref={passwordRef('password')}
                                        isValidated={isFieldValid}
                                        isSubmitted={(!forgotPassword) ? isGlobalErrorHidden
                                            && isSubmitted : false}
                                        hasGlobalError={!isGlobalErrorHidden || disableFieldIcon}
                                    />
                                </StyledFieldWrapper>

                                {/* <StyledRemindControls>
                                    <Button
                                        type={TYPE_GHOST}
                                        to="#"
                                        onClick={this.handleForgotUsername}
                                        data-analytics-placement="Button : body"
                                        data-analytics-label="trackLink : button"
                                        data-analytics-id={ this.getTranslation('signIn.link.forgotUsername')}
                                    >
                                        <FormattedMessage id="signIn.link.forgotUsername" />
                                    </Button>
                                </StyledRemindControls> */}

                                <StyledRemindControls>
                                    <Button
                                        type={TYPE_GHOST}
                                        to="#"
                                        onClick={this.handleForgotPassword}
                                        data-analytics-placement="Button : body"
                                        data-analytics-label="trackLink : button"
                                        data-analytics-id={this.getTranslation('signIn.link.forgotPassword')}
                                    >
                                        <FormattedMessage id="signIn.link.forgotPassword" />
                                    </Button>
                                </StyledRemindControls>

                            </StyledFieldsWrapper>

                            {errors.authentication && (
                                <StyledAlert
                                    id="sign-in-errors"
                                    ref={authRef('sign-in-errors')}
                                    tabIndex="-1"
                                    error
                                >
                                    {errors.authentication === 10 && inputChanged !== 10
                                        && <FormattedMessage id="signIn.error.invalidCredentials" />}
                                    {(errors.authentication !== 10 && errors.authentication !== 11)
                                        && errors.authentication}
                                </StyledAlert>
                            )}
                            {showPasswordResetMessage && <StyledAlert>
                                {forgotPasswordLink}
                            </StyledAlert>}
                            <span
                                data-analytics-placement="Button : body"
                                data-analytics-label="trackLink : button"
                                data-analytics-id={keepSignedIn}
                            >
                                <StyledKeepSignedIn
                                    component={FieldCheckBox}
                                    id="keep-signed-in"
                                    name="keepSignedIn"
                                    label={keepSignedIn}
                                />
                            </span>
                        </StyledFormFieldsContainer>
                    </StyledFormContentContainer>
                    <StyledActionsContainer>
                        <div />
                        <StyledControls>
                            <StyledButton
                                appearance={oldButton.APPEARANCE.PRIMARY}
                                id="sign-in"
                                type={oldButton.TYPES.SUBMIT}

                                isDisabled={((values.email && values.password) === '').toString()}
                                isVisuallyDisabled={isSubmitting}
                                size={oldButton.SIZES.LARGE}
                            >
                                {signIn}
                            </StyledButton>
                        </StyledControls>
                    </StyledActionsContainer>
                </form>
                <Modal
                    htmlOpenClassName={null}
                    shouldFocusAfterRender={false}
                    ariaHideApp={false}
                    contentLabel={'OktaMigratoinModal'}
                    isOpen={errors.authentication === 11}
                    onRequestClose={() => { }}
                    style={ModalStyles}
                >
                    <OktaMigrationModal
                        email={this.emailRef.value}
                    />
                </Modal>
                <Modal
                    htmlOpenClassName={null}
                    shouldFocusAfterRender={false}
                    ariaHideApp={false}
                    isOpen={this.state.forgotPasswordModalOpen}
                    onRequestClose={() => this.setState({ forgotPasswordModalOpen: false })}
                    style={ModalStyles}
                >
                    <ForgotPasswordModal />
                </Modal>
                <Modal
                    htmlOpenClassName={null}
                    shouldFocusAfterRender={false}
                    ariaHideApp={false}
                    isOpen={this.state.forgotUsernameModalOpen}
                    onRequestClose={() => this.setState({ forgotUsernameModalOpen: false })}
                    style={ModalStyles}
                >
                    <ForgotUsernameModal />
                </Modal>
            </React.Fragment>
        );
    }
}

SignInForm.propTypes = {
    errors: PropTypes.oneOfType([
        PropTypes.objectOf(PropTypes.string),
        PropTypes.objectOf(PropTypes.number),
    ]).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    emailRef: PropTypes.func.isRequired,
    passwordRef: PropTypes.func.isRequired,
    authRef: PropTypes.func.isRequired,
    setFieldError: PropTypes.func.isRequired,
    sendForgotPasswordEmail: PropTypes.func.isRequired,
    sendForgotUsernameEmail: PropTypes.func.isRequired,
    values: PropTypes.shape({
        email: PropTypes.string,
        password: PropTypes.string,
        keepSignedIn: PropTypes.bool,
    }).isRequired,
    intl: intlShape.isRequired,
    setErrors: PropTypes.func.isRequired,
    setTouched: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
};

export default injectIntl(SignInForm);
