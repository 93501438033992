import { all, takeEvery } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';

/**
 * Save users location for previous URL usage.
 */
function saveLocation() {
    const pathname = window.location.href;

    if (window.currentUrl !== pathname) {
        window.previousUrl = window.currentUrl;
        window.currentUrl = pathname;
    }
}

function* previousUrlSaga() {
    yield all([
        takeEvery(LOCATION_CHANGE, saveLocation),
    ]);
}

export default previousUrlSaga;
