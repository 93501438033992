import * as locationTypes from 'state-management/constants/location';

const initialState = {
    query: {},
};

function locationReducer(state = initialState, action = {}) {
    switch (action.type) {
    case locationTypes.SET_QUERY_PARAMS:
        return {
            query: {
                ...action.query,
            },
        };
    default:
        return state;
    }
}

export default locationReducer;
