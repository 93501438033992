import { css } from 'styled-components';
import {
    COLORS,
    FONTS,
    ANIMATIONS,
} from 'utils/variables';
import {
    FROM_TABLET,
    SMALL_MOBILE,
} from '../../../../utils/mediaQueries';

export const containerStyles = css`
    z-index: 301;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 185px;
    overflow: hidden;
    background-color: ${COLORS.WHITE};
    border-radius: 0 0 6px 6px;
    border: none;
    text-align: center;

    ${props => props.showAlternate === 'true' && css`
        height: 216px;        
    `};

    @media screen and ${FROM_TABLET} {
        display: none;
    }

    p {
        margin: 0;
        font-size: 15px;
        line-height: 20px;
        color: ${COLORS.BLACK};
        margin-bottom: 30px;
    }

    &>div[data-rel="controls"]{
        margin: auto;
        display: inline-block;

        a {
            width: 136px;
        }

        &>a:first-child {
            margin-right: 16px;
            padding: 11px 5px;
            line-height: 20px;
        }

        &>a:last-child {
            padding: 11px 6px;
            line-height: 22px;
        }
    }

    &>div[data-rel="additional-controls"] {
        margin-top: 24px;
        font-size: 15px;
        line-height: 18px;

        @media screen and ${SMALL_MOBILE} {
            margin-top: 24px;
        }

        a {
            font-size: 15px;
            line-height: 18px;
            letter-spacing: -0.12px;
            text-decoration: none;
            font-weight: 500;
            color: ${COLORS.SAPPHIRE};
            transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;

            &:hover {
                color: ${COLORS.DARK_SAPPHIRE};
            }
        }
    }
`;

export const colorBarStyles = css`
    width: 100%;
    height: 4px;
    overflow: hidden;
    background-image: linear-gradient(to right, ${COLORS.LIGHT_OCEAN}, ${COLORS.OCEAN});
`;

export const titleStyles = css`
    color: ${COLORS.SAPPHIRE};
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.08px;
    padding: 0;
    margin: 21px 0 8px 0;
`;


