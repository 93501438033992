import React from 'react';
import { injectIntl } from 'react-intl';
import { withCommonCard } from 'components/atomics/hocs/CommonCard/CommonCard';
import styled from 'styled-components';
import Link from 'components/atomics/atoms/Link/Link';
import { TOPIC_LINK } from 'state-management/constants/topics';
import moment from 'moment';
import ImageAtom from 'components/atomics/atoms/Image/Image';
import { getArticleUrl, getArticleImage } from 'utils/getArticleAsset';
import {
    containerStyles,
    contentContainerStyles,
    dateAndTopicContainerStyles,
    dateContainerStyles,
    topicLinkStyles,
    titleStyles,
    imageContainerStyles,
} from './../../../../components/atomics/molecules/ListContentCard/styles';
import { contextHubRecordEvent } from 'utils/contextHub';
import {
    CARDS_EVENT_LIST,
} from 'utils/contextHubEventListConstants';
import ListContentCardFooter from "./ListContentCardFooter";
import { getTruncatedString } from 'utils/contentCardUtils';
import language from '../../../../lang/en.json';
import { IconExclamationSolid } from 'components/atomics/atoms/Icons/Icons';

export const Title = styled(Link)`
    ${titleStyles}
`;

export const DateAndTopic = styled.div`
    ${dateAndTopicContainerStyles}
`;

export const DateContainer = styled.div`
    ${dateContainerStyles}
`;

export const TopicLink = styled(Link)`
    ${topicLinkStyles}
`;

export const Container = styled.div`
    ${containerStyles}
`;

export const ImageContainer = styled(Link)`
    ${imageContainerStyles}
`;

export const ContentContainer = styled.div`
    ${contentContainerStyles}
`;

const MAX_ARTICLE_TITLE_CHARS = 75;

/**
 * Creates a list style content card for articles/guides.
 */
class ListContentCard extends React.Component {

    getTranslation(id, values = {}) {
        const { intl: { formatMessage } } = this.props;
        return formatMessage({ id }, values);
    }

    /**
     * Render the list content card and underlying components.
     */
    render() {
        const { profile, showCoachMark, onCoachMarkDismiss, activateDismissCoachMark } = this.props;
        const linkUrl = getArticleUrl(this.props.article);
        const imageUrl = getArticleImage(this.props.article, 2);

        const publishDate = this.props.article.date ? moment(this.props.article.date) : undefined;
        const expireDate = publishDate.clone().add(90, 'days');
        const hoursToExpire = moment.duration(expireDate.diff(moment())).asHours();    
        let dateTag = '';
        if (hoursToExpire <= 24) {
            dateTag = <><IconExclamationSolid />{this.getTranslation('articles.date.expiresToday')}</>;
        } else if (hoursToExpire <= 24 * 15) {
            dateTag = <><IconExclamationSolid />{this.getTranslation('articles.date.expiresIn', { days: Math.floor(hoursToExpire / 24 + 1) })}</>;
        } else {
            dateTag = publishDate.format('MMM D, YYYY') || '';
        }
    
        return (
            <Container
                simplelist={this.props.simpleList.toString()}
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this.props.onMouseLeave}
            >
                {!this.props.simpleList
                    && <ImageContainer
                        to={linkUrl}
                        className={`${this.props.article.visited ? (this.props.article.visited === true ? 'news-visited' : 'news-not-visited') : 'news-not-visited'}`}
                        onClick={() => {
                            this.props.recordClickAnalytics();
                            this.props.onArticleClick();
                        }}
                    >
                        <ImageAtom
                            src={imageUrl}
                            alt={language.translations['global.alt.image']}
                        />
                    </ImageContainer>}
                <ContentContainer simplelist={this.props.simpleList.toString()}>
                    <DateAndTopic>
                        <DateContainer>
                            {dateTag}
                        </DateContainer>
                        {!this.props.simpleList
                            && <TopicLink
                                className={`${this.props.article.visited ? (this.props.article.visited === true ? 'news-visited' : 'news-not-visited') : 'news-not-visited'}`}
                                data-analytics-placement="Button : body"
                                data-analytics-label="trackLink : button"
                                data-analytics-id={"topic:" + this.props.article.topicName}
                                to={TOPIC_LINK(`${this.props.article.topicName}/${this.props.article.topicId}`)}
                            >
                                <span>{this.props.article.topicName}</span>
                            </TopicLink>}
                    </DateAndTopic>
                    <Title
                        onClick={() => {
                            this.props.recordClickAnalytics();
                            this.props.onArticleClick();
                        }}
                        to={linkUrl}
                        className={`${this.props.article.visited ? (this.props.article.visited === true ? 'news-visited' : 'news-not-visited') : 'news-not-visited'}`}
                        simplelist={this.props.simpleList.toString()}>
                        {getTruncatedString(this.props.article.title, MAX_ARTICLE_TITLE_CHARS)}
                    </Title>
                    <ListContentCardFooter
                        onElement={this.props.onCardHover}
                        profile={profile}
                        sourceName={this.props.article.sourceName}
                        providerName={this.props.article.provider}
                        article={this.props.article}
                        topics={this.props.article.topics}
                        sourceId={this.props.article.sourceId}
                        kebabButtonClickListener={() => showCoachMark || activateDismissCoachMark ? onCoachMarkDismiss() : null}
                        topicName={this.props.article.topicName}
                        saved={this.props.article.saved}
                        articleId={parseInt(this.props.article.id)}
                        articleTitle={this.props.article.title}
                        type={this.props.article.type || this.props.article.contentType}
                        shouldShowToast={this.props.shouldShowToast}
                        onSaveArticle={(id, save, title, getContentAfterSave, article) => {
                            this.props.toggleSaveArticle(id, save, title, getContentAfterSave, article);
                        }}
                        onSaveGuide={(id, save, title, getContentAfterSave, article) => {
                            this.props.toggleSaveGuide(id, save, title, getContentAfterSave, article);
                        }}
                        onFollowTopic={(topicId, status) => {
                            this.props.toggleFollowTopic(topicId, status);
                        }}
                        onFollowSource={(sourceId, status) => {
                            this.props.toggleFollowSource(sourceId, status);
                        }}
                    />
                </ContentContainer>
            </Container>
        );
    }
}

export default withCommonCard(injectIntl(ListContentCard));
