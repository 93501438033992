export const CARDS_EVENT_LIST = (type = 'article', title = '', topic = null, source = null) => ({
    type,
    action: null,
    value: null,
    title,
    topic,
    source,
})

export const SHARE_EVENT_LIST = (valueType, value) => ({
    type: 'share',
    action: null,
    title: null,
    valueType,
    value,
})

export const CARDS_EVENT_LIST_Tool = (type = 'tool', title = '', topic = null, source = null) => ({
    type,
    action: null,
    value: null,
    title,
    topic,
    source,
})

export const KEBAB_EVENT_LIST = (action = null, value = null, title = '', topic = null, source = null, type = 'article') => ({
    type,
    action,
    value,
    title,
    topic,
    source,
    location: 'kebab'
})


