import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS, RGB_COLORS } from 'utils/variables';

export const clickableContainerStyles = css`
    background-color: ${COLORS.WHITE};
    box-shadow: 0 4px 20px 0 rgba(${RGB_COLORS.BLACK}, 0.16);
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 12px;
    position: relative;
    width: 100%;

    @media screen and ${FROM_TABLET} {
        min-height: 282px;
        padding: 14px;
    }

    @media screen and ${FROM_DESKTOP} {
        min-height: 468px;
        padding: 18px 22px;
    }
`;

export const toolsContentStyles = css`
    background: ${COLORS.WHITE};
    box-shadow: 0 4px 20px 0 rgba(${RGB_COLORS.BLACK}, 0.16);
    box-sizing: border-box;
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    height: 100%;
    max-width: 100%;
    padding: 15px 20px;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        /* stylelint-disable */
        ${props => props.horizontalCard && css`
            padding: 25px 30px 15px;
        `};
        /* stylelint-enable */
    }

    @media screen and ${FROM_DESKTOP} {
        /* stylelint-disable */
        ${props => props.horizontalCard && css`
            padding: 50px 45px 40px;
        `};
        /* stylelint-enable */
    }
`;

export const toolsHeadingContainer = css`
    align-items: center;
    display: flex;
    margin-bottom: 20px;

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 32px;
    }
`;

export const linkStyles = css`
    color: ${COLORS.SAPPHIRE};
    cursor: pointer;
    text-decoration: none;
`;

export const toolsHeadingStyles = css`
    color: ${COLORS.MEDIUM_GRAY2};
    font-size: 10px;
    font-weight: 600;
    padding-left: 5px;

    @media screen and ${FROM_DESKTOP} {
        font-size: 12px;
        padding-left: 10px;
    }
`;

export const toolsTitleStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 10px;
    word-wrap: break-word;

    /* stylelint-disable */
    ${props => props.variant && props.variant.clickableCard && css`
        font-size: 16px;
        line-height: 1.4;

        @media screen and ${FROM_DESKTOP} {
            font-size: 28px;
        }
    `}
    /* stylelint-enable */
`;

export const toolsDescriptionStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 24px;
    line-height: 1.4;
    margin: 0 0 auto;
    word-wrap: break-word;

    > p {
        margin-top: 0;
    }

    /* stylelint-disable */
    ${props => props.variant && props.variant.clickableCard && css`
        font-size: 13px;

        @media screen and ${FROM_DESKTOP} {
            font-size: 24px;
        }
    `}
    /* stylelint-enable */
`;

export const toolsLinkStyles = css`
    color: ${COLORS.SAPPHIRE};
    cursor: pointer;
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
        color: ${COLORS.SAPPHIRE};
    }
`;

export const toolsLinkWrapperStyles = css`
    margin: 80px 0 0;
    text-align: right;

    @media screen and ${FROM_TABLET} {
        /* stylelint-disable */
        ${props => props.horizontalCard && css`
            margin: 10px 0 0;
        `};
        /* stylelint-enable */
    }
`;

export const toolsLinkArrowStyles = css`
    fill: ${COLORS.SAPPHIRE};
    height: 12px;
    margin-left: 8px;
    width: 15px;
`;
