import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LottieWrapper } from 'components';
import markAnimation from 'assets/loader/onboarding.json';
import { markStyles } from 'components/AnimatedMark/styles';

const StyledMark = styled(LottieWrapper)`
    ${markStyles};
`;

class AnimatedMark extends React.Component {
    /**
     * Default constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.handleComplete = this.handleComplete.bind(this);
    }

    /**
     * Callback at end of animation if supplied.
     */
    handleComplete() {
        const { onAnimationEnd } = this.props;

        if (onAnimationEnd && typeof onAnimationEnd === 'function') {
            onAnimationEnd();
        }
    }

    /**
     * Render the animation artifacts.
     */
    render() {
        const { className } = this.props;
        const options = {
            animationData: markAnimation,
            autoplay: true,
            loop: false,
        };

        return (
            <StyledMark
                className={className}
                options={options}
                onComplete={this.handleComplete}
            />
        );
    }
}

AnimatedMark.propTypes = {
    className: PropTypes.string,
    onAnimationEnd: PropTypes.func,
};

AnimatedMark.defaultProps = {
    className: '',
    onAnimationEnd: null,
};

export default AnimatedMark;
