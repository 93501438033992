// Validator logic is based on hibernate-validator package's email validator
// https://github.com/hibernate/hibernate-validator/blob/master/engine/src/main/java/org/hibernate/validator/internal/constraintvalidators/AbstractEmailValidator.java
// https://github.com/hibernate/hibernate-validator/blob/master/engine/src/main/java/org/hibernate/validator/internal/util/DomainNameUtil.java

import { emailLocalPartRegex, emailDomainPartRegex } from 'utils/regex';

const MAX_LOCAL_PART_LENGTH = 64;
const MAX_DOMAIN_PART_LENGTH = 255;

const isLocalPartValid = localPart => localPart.length <= MAX_LOCAL_PART_LENGTH
    && emailLocalPartRegex.test(localPart);

const isDomainPartValid = domainPart => domainPart.length <= MAX_DOMAIN_PART_LENGTH
    && emailDomainPartRegex.test(domainPart) && !domainPart.includes("'");

const oktaRegex = /^([^0-9]*)$/;

export const isEmailValid = (email) => {
    if (!email) {
        return false;
    }

    const splitIndex = email.lastIndexOf('@');

    if (splitIndex < 0) {
        return false;
    }

    const localPart = email.substring(0, splitIndex);
    const domainPart = email.substring(splitIndex + 1);

    const dotIndex = domainPart.lastIndexOf('.');

    const topMostDomain = domainPart.substring(0, dotIndex);
    const lowestDomain = domainPart.substring(dotIndex + 1);

    if (dotIndex < 0) {
        return false;
    }
    
    return isLocalPartValid(localPart)
        && isDomainPartValid(domainPart)
        //&& oktaRegex.test(topMostDomain) //not needed if we allow numbers in domain
        && oktaRegex.test(lowestDomain);
};
