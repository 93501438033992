import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS } from 'utils/variables';

const Heading = styled((props) => {
    const {
        level,
        children,
        passedRef,
        ...other
    } = props;
    const tag = `h${level}`;
    const headingProps = Object.assign({}, other);

    if (passedRef) {
        headingProps.ref = passedRef;
    }

    return React.createElement(
        tag,
        headingProps,
        children,
    );
})`
    font-family: ${FONT_AVENIR_NEXT};
    margin: 0;
    max-width: 100%;

    /* stylelint-disable */

    ${props => props.theme === 'light' && css`
        color: ${COLORS.BLACK};
    `}

    ${props => props.theme === 'dark' && css`
        color: ${COLORS.WHITE};
    `}

    ${props => (props.appearance === 1 || (!props.appearance && props.level === 1)) && css`
        font-size: 40px;
        font-weight: 700;
        line-height: 1.13;

        @media screen and ${FROM_TABLET} {
            font-size: 80px;
        }
    `}

    ${props => (props.appearance === 2 || (!props.appearance && props.level === 2)) && css`
        font-size: 30px;
        font-weight: 700;
        line-height: 1.13;

        @media screen and ${FROM_TABLET} {
            font-size: 37px;
        }
    `}
`;

Heading.propTypes = {
    appearance: PropTypes.oneOf([
        1,
        2,
        3,
        4,
        5,
        6,
    ]),
    level: PropTypes.PropTypes.oneOf([
        1,
        2,
        3,
        4,
        5,
        6,
    ]).isRequired,
    theme: PropTypes.oneOf([
        'light',
        'dark',
    ]),
};

Heading.defaultProps = {
    appearance: null,
    theme: 'light',
};

export default Heading;
