import { css } from 'styled-components';
import {
    FROM_TABLET, FROM_DESKTOP, SMALL_MOBILE,
} from 'utils/mediaQueries';
import { COLORS } from 'utils/variables';

export const BloombergVideoComp = css`
    text-align: right;
`;

export const BloombergVideoCompSticky = css`
    bottom: 0;
    position: fixed;
    right: 0;
    z-index: 2;
`;

export const LiveTvButton = css`
    background-color: ${COLORS.BLACK};
    border: none;
    color: ${COLORS.WHITE};
    cursor: pointer;
    font-size: 14px;
    height: 54px;
    letter-spacing: 2.3px;
    width: 124px;

    &::before {
        background-color: ${COLORS.SAPPHIRE};
        border-radius: 50%;
        content: ""; /* Insert content that looks like bullets */
        display: inline-block;
        height: 10px;
        margin-right: 10px;
        width: 10px;
    }
`;

export const MinimizedWrapper = css`
    background-color: ${COLORS.BLACK};
    display: inline-block;
    height: 54px;
    text-align: left;
    width: 415px;

    @media screen and ${SMALL_MOBILE} {
        width: 375px;
    }
`;

export const ClosedButton = css`
    background-color: ${COLORS.BLACK};
    border: none;
    cursor: pointer;
    padding-left: 15px;
    padding-right: 10px;

    @media screen and ${SMALL_MOBILE} {
        padding-left: 20px;
        padding-right: 15px;
    }
`;

export const ClickableWrapper = css`
    cursor: pointer;
    display: inline-block;
    height: 100%;
    width: 360px;

    @media screen and ${SMALL_MOBILE} {
        width: 320px;
    }
`;

export const ClickableWrapperT = css`
    cursor: pointer;
    display: inline-block;
    height: 100%;
    width: 300px;

    @media screen and ${SMALL_MOBILE} {
        width: 262px;
    }
`;

export const BloombergText = css`
    color: ${COLORS.WHITE};
    display: inline-block;
    font-size: 24px;
    letter-spacing: 0.2;
    margin: 0;
    margin-left: 6px;
    margin-top: 9px;
    position: relative;
    top: 4px;
`;

export const LiveTV = css`
    color: ${COLORS.WHITE};
    display: inline-block;
    font-size: 12px;
    letter-spacing: 2.3px;
    margin-left: 80px;
    position: relative;
    top: 1px;

    &::before {
        background-color: ${COLORS.SAPPHIRE};
        border-radius: 50%;
        content: ""; /* Insert content that looks like bullets */
        display: inline-block;
        height: 10px;
        margin-right: 10px;
        width: 10px;
    }

    @media screen and ${SMALL_MOBILE} {
        margin-left: 45px;
    }
`;

export const PlayButton = css`
    background-color: ${COLORS.BLACK};
    border: none;
    padding-left: 17px;

    @media screen and ${SMALL_MOBILE} {
        padding-left: 5px;
    }
`;

export const FullScreenButton = css`
    background-color: ${COLORS.BLACK};
    border: none;
    cursor: pointer;
    padding-left: 23px;
`;

export const ThumbnailWrapper = css`
    display: inline-block;
    text-align: left;
    width: 415px;

    @media screen and ${SMALL_MOBILE} {
        width: 375px;
    }

    .video-js {
        @media screen and ${SMALL_MOBILE} {
            width: 375px;
        }
    }
`;

export const FullScreenViewWrapper = css`
    background: ${COLORS.BLACK};
    padding-bottom: 135px;
    padding-top: 135px;
    position: relative;
    text-align: center;
    width: 100%;

    h4 {
        color: ${COLORS.WHITE};
        font-size: 30px;
        left: 18px;
        letter-spacing: -1px;
        margin: 0;
        position: absolute;
        top: 16px;
    }

    div {
        margin: auto;

        .video-js {
            height: 211px;
            width: 375px;

            @media screen and ${FROM_TABLET} {
                height: 432px;
                width: 768px;
            }

            @media screen and ${FROM_DESKTOP} {
                height: 575px;
                width: 1024px;
            }

            .vjs-big-play-button {
                left: calc(50% - 43px);
                top: calc(50% - 20px);
            }
        }
    }
`;

export const closeIconStyles = css`
    fill: ${COLORS.WHITE};
    height: 16px;
    width: 16px;
`;

export const playerExpandIconStyles = css`
    fill: ${COLORS.WHITE};
    height: 20px;
    width: 20px;
`;

export const playerPlayIconStyles = css`
    fill: ${COLORS.WHITE};
`;
