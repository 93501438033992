import * as type from 'state-management/constants/trendingTopic.common';
import { TT_ARTICLE_WAS_VISITED  } from 'state-management/constants/articles.common';
import * as saveTypes from 'state-management/constants/save';

const initialState = {
    news: [],
    newsIds: [],
    isLoading: false,
    isLoaded: false,
    topic: null,
    seeMore: false,
    filters: {},
};

const formatNewsItem = item => ({
    ...item,
    isSaving: false,
});

function trendingTopicNewsReducer(state = initialState, action = {}) {
    switch (action.type) {
    case type.GET_TRENDING_TOPIC_NEWS:
        return {
            ...state,
            isLoading: true,
        };
    case type.GET_TRENDING_TOPIC_NEWS_SUCCESS:
        return {
            ...state,
            news: action.data.newsItems.map(formatNewsItem),
            newsIds: action.data.newsItems.map(item => item.id),
            topic: action.data.topic,
            isLoading: false,
            isLoaded: true,
            seeMore: action.seeMore,
        };
    case type.GET_TRENING_TOPIC_FILTERS_SUCCESS:
        return {
            ...state,
            filters: action.data,
        }
    case type.GET_TRENDING_TOPIC_NEWS_FAIL:
        return {
            ...state,
            isLoading: false,
            isLoaded: true,
        };
    case type.RESET_TRENDING_TOPIC_NEWS:
        return {
            ...initialState,
            topic: state.topic,
            newsIds: state.newsIds,
        };
    case saveTypes.TOGGLE_SAVE_ARTICLE: {
        const { id, save } = action;

        return {
            ...state,
            news: state.news.map(item => (item.id === id ? ({
                ...item,
                isSaving: true,
                saved: save,
            }) : item)),
        };
    }
    case saveTypes.TOGGLE_SAVE_ARTICLE_FAILURE: {
        const { id } = action;

        return {
            ...state,
            news: state.news.map(item => (item.id === id ? ({
                ...item,
                isSaving: false,
                saved: !item.saved,
            }) : item)),
        };
    }
    case saveTypes.TOGGLE_SAVE_ARTICLE_SUCCESS: {
        const { id } = action;

        return {
            ...state,
            news: state.news.map(item => (item.id === id
                ? { ...item, isSaving: false } : item)),
        };
    }
    // case TT_ARTICLE_WAS_VISITED: {
    //     const { id } = action;
    //     return {
    //         ...state,
    //         news: state.news.map(item => (item.id === id
    //             ? { ...item, visited: true } : item)),
    //     };
    // }
    default:
        return state;
    }
}

export default trendingTopicNewsReducer;
