import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CheckIconSmall } from 'components/atomics/atoms/Icons/Icons';
import FieldError from 'components/FieldError/FieldError';
import { SIZES } from 'components/FieldCheckBox/constants';
import {
    indicatorStyles,
    inputStyles,
    labelStyles,
    indicatorIconStyles,
} from 'components/FieldCheckBox/styles';

export const StyledInput = styled.input`
    ${inputStyles};
`;
export const StyledLabel = styled.label`
    ${labelStyles};
`;
export const StyledIndicator = styled.span`
    ${indicatorStyles};
`;
export const StyledIndicatorIcon = styled(CheckIconSmall)`
    ${indicatorIconStyles};
`;

class FieldCheckBox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isFocused: false,
        };

        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
    }

    handleBlur(event) {
        const { field } = this.props;

        this.setState({
            isFocused: false,
        });

        if (field && field.onBlur) {
            field.onBlur(event);
        }
    }

    handleChange(event, isChecked) {
        const { field, onChange } = this.props;

        onChange(event, isChecked);

        if (field && field.onChange) {
            field.onChange(event);
        }
    }

    handleFocus() {
        this.setState({
            isFocused: true,
        });
    }

    render() {
        const {
            ariaDescribedby,
            className,
            field,
            form,
            id,
            label,
            size,
            checked,
            disabled,
        } = this.props;
        const { isFocused } = this.state;
        const { ...other } = field;
        const fieldName = field.name;
        const fieldId = id || fieldName;
        const error = form.errors[fieldName];
        const isInvalid = error !== undefined && error !== null;
        const isTouched = !!form.touched[fieldName];
        const value = checked === undefined ? field.value : checked;
        const isChecked = !!value;

        return (
            <div className={className}>
                <StyledInput
                    {...other}
                    aria-describedby={`${fieldId}-errors ${ariaDescribedby}`}
                    checked={value}
                    id={fieldId}
                    type="checkbox"
                    onBlur={this.handleBlur}
                    onChange={event => this.handleChange(event, isChecked)}
                    onFocus={this.handleFocus}
                    value={value}
                    disabled={disabled}
                />
                <StyledLabel
                    htmlFor={fieldId}
                    variant={{ size }}
                >
                    <StyledIndicator
                        aria-hidden="true"
                        variant={{
                            isChecked,
                            isFocused,
                            size,
                        }}
                    >
                        {value ? (
                            <StyledIndicatorIcon variant={{ size }} />
                        ) : ''}
                    </StyledIndicator>
                    {label}
                </StyledLabel>
                <FieldError
                    id={fieldId}
                    error={error}
                    isVisible={isTouched && isInvalid}
                />
            </div>
        );
    }
}

FieldCheckBox.propTypes = {
    ariaDescribedby: PropTypes.string,
    className: PropTypes.string,
    checked: PropTypes.bool,
    field: PropTypes.shape({
        name: PropTypes.string,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        value: PropTypes.bool,
    }).isRequired,
    form: PropTypes.shape({
        errors: PropTypes.objectOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({}),
        ])),
        touched: PropTypes.objectOf(PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.array,
        ])),
    }).isRequired,
    id: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    onChange: PropTypes.func,
    size: PropTypes.oneOf(Object.values(SIZES)),
    disabled: PropTypes.bool,
};

FieldCheckBox.defaultProps = {
    ariaDescribedby: '',
    checked: undefined,
    className: '',
    id: '',
    size: SIZES.MEDIUM,
    onChange: () => {},
    disabled: false,
};

FieldCheckBox.SIZES = SIZES;

export default FieldCheckBox;
