import styled from 'styled-components';
import {
    componentsContainerStyles,
} from './styles';

export const StyledComponentsContainer = styled.div`
    ${componentsContainerStyles}
`;

export default styled.div`
    ${componentsContainerStyles}
`;
