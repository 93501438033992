import React from 'react';

import DowJonesArticleFooter from 'containers/Article/ArticleFooter/DowJonesArticleFooter';
import LexisNexisArticleFooter from 'containers/Article/ArticleFooter/LexisNexisArticleFooter';

import { PROVIDER_DOW_JONES, PROVIDER_LEXIS_NEXIS } from 'state-management/constants/providers';

/**
 * Article footer wrapper, decides on which footer to use.
 * @param {*} props 
 */
const ArticleFooter = (props) => {
    switch (props.provider) {
    case PROVIDER_DOW_JONES:
        return <DowJonesArticleFooter {...props} />;
    case PROVIDER_LEXIS_NEXIS:
        return <LexisNexisArticleFooter {...props} />;
    default:
        return null;
    }
};

export default ArticleFooter;
