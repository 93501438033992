const minPasswordLength = 8;
const numberOfRulesRequiredToMatch = 5;

/**
 * See if password contains 
 * @param {*} password 
 * @param {*} email 
 */
export function passwordContainsEmail(password, email) {
    const emailRegx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegx.test(String(email).toLowerCase())) {
        return false;
    }

    if (!email || email.match(/.{4}/g) === null) {
        return false;
    }

    const partsOfThreeLetters = email.match(/.{4}/g).concat(
        email.substr(1).match(/.{4}/g),
        email.substr(2).match(/.{4}/g));

    return new RegExp(partsOfThreeLetters.join("|"), "i").test(password);
}

export function getPasswordFormatRules(password, email) {
    const containsLowercaseLetter = !!password && password.toUpperCase() !== password;
    const containsUppercaseLetter = !!password && password.toLowerCase() !== password;
    const containsNumber = !!password && /\d/.test(password);
    const containsSpecialSymbol = !!password && /[!@#$%^&*]/.test(password);
    const isCharLength = !!password && password.length > 7;
    const containsRepeat = !!password && passwordContainsEmail(password, email);

    return {
        'containsLowercaseLetter': containsLowercaseLetter,
        'containsUppercaseLetter': containsUppercaseLetter,
        'containsNumber': containsNumber,
        'containsSpecialSymbol': containsSpecialSymbol,
        'isCharLength': isCharLength,
        'containsRepeat': containsRepeat,
    };
}

export function arePasswordRulesValid(password, email) {
    const {
        containsLowercaseLetter,
        containsUppercaseLetter,
        containsNumber,
        containsSpecialSymbol,
        isCharLength,
        containsRepeat,
    } = getPasswordFormatRules(password, email);

    const validRulesCount = containsLowercaseLetter
        + containsUppercaseLetter
        + containsNumber
        + isCharLength
        + containsSpecialSymbol;

    return (validRulesCount >= numberOfRulesRequiredToMatch) && !containsRepeat;
}

export default function isPasswordFormatValid(password, email) {
    if (password.length < minPasswordLength) {
        return false;
    }

    const {
        containsLowercaseLetter,
        containsUppercaseLetter,
        containsNumber,
        containsSpecialSymbol,
        isCharLength,
        containsRepeat,
    } = getPasswordFormatRules(password, email);

    const validRulesCount = containsLowercaseLetter
        + containsUppercaseLetter
        + containsNumber
        + isCharLength
        + containsSpecialSymbol;

    return (validRulesCount >= numberOfRulesRequiredToMatch) && !containsRepeat;
}
