import {
    all,
    call,
    put,
    takeEvery,
    takeLatest,
} from 'redux-saga/effects';
import {
    CAPITAL_IDEAS_TYPE,
    MARKET_NEWS_TYPE,
    NEWS_BY_AUM_TYPE,
    NEWS_BY_DURATION_TYPE,
    NEWS_BY_SERVICE_OFFERINGS_TYPE,
    GET_NEWS,
    GET_PAGEABLE_NEWS,
    HEADLINES_TYPE,
    HEADLINES_URL,
    LATEST_CAPITAL_IDEAS_URL,
    TRENDING_TOPIC_TYPE,
    SET_RANDOM_SERVICE_OFFERING,
    GET_TOP_NEWS_ITEMS,
    TOP_NEWS_URL,
} from 'state-management/constants/news';
import {
    VISIT_ARTICLE,
    ARTICLE_VISIT_URL,
    GET_VISIT_PRACTICE_LAB_URL,
} from 'state-management/constants/articles.common';

import {
    getLatestCapitalIdeasFailure,
    getLatestCapitalIdeasSuccess,
    getMarketNewsFailure,
    getPageableNewsFailure,
    getPageableNewsSuccess,
    getTopNewsFailure,
    setServiceOfferingId,
    getTopNewsItemsFail,
    getTopNewsItemsSuccess,
} from 'state-management/actions/news';
import { setError } from 'state-management/actions/errors';
import { handleArticleVisit, handlePLArticleVisit } from 'state-management/actions/articles.common';
import Api from 'utils/api';
import { TRENDING_TOPIC_NEWS_URL } from 'state-management/constants/trendingTopic.common';

const Types = {
    topHeadlines: 'your_news',
    capitalIdeas: 'latest_ideas',
    marketNews: 'market_news',
    byAum: 'by_aum',
    byDuration: 'by_duration',
    byServiceOfferings: 'by_offerings',
    byTrendingTopic: 'trending_topics',
};

/**
 * Get top news for the news page.
 */
function* getTopNews() {
    try {
        const newsData = yield call(Api.get, TOP_NEWS_URL);

        yield put(getTopNewsItemsSuccess(newsData.data.content.topHeadlines));
    } catch (e) {
        yield put(getTopNewsItemsFail(e));
    }
}

/**
 * Get general news for news page, cap ideas, etc.
 */
function* getNews() {
    try {
        const capitalIdeas = yield call(Api.get, LATEST_CAPITAL_IDEAS_URL);
        const {
            content: capitalIdeasContent,
            seeMore: seeMoreCapitalIdeas,
        } = capitalIdeas.data;

        yield put(getLatestCapitalIdeasSuccess(
            capitalIdeasContent,
            seeMoreCapitalIdeas.stories_latest_ideas,
        ));
    } catch (e) {
        yield put(getMarketNewsFailure(e));
        yield put(getTopNewsFailure(e));
        //yield put(setError('news.headlines.server.error', e));
        yield put(getLatestCapitalIdeasFailure(e));
    }
}

const newsSections = {
    [HEADLINES_TYPE]: {
        type: Types.topHeadlines,
    },
    [CAPITAL_IDEAS_TYPE]: {
        type: Types.capitalIdeas,
    },
    [MARKET_NEWS_TYPE]: {
        type: Types.marketNews,
    },
    [NEWS_BY_AUM_TYPE]: {
        type: Types.byAum,
    },
    [NEWS_BY_DURATION_TYPE]: {
        type: Types.byDuration,
    },
    [NEWS_BY_SERVICE_OFFERINGS_TYPE]: {
        type: Types.byServiceOfferings,
    },
    [TRENDING_TOPIC_TYPE]: {
        type: Types.byTrendingTopic,
    },
};

const getRandomServiceOffering = serviceOfferingIds => serviceOfferingIds[
    Math.floor(Math.random() * serviceOfferingIds.length)
];

function* setRandomServiceOffering({ advisorServiceOfferingIds }) {
    const randomServiceOfferingId = getRandomServiceOffering(advisorServiceOfferingIds);
    
    yield put(setServiceOfferingId(randomServiceOfferingId));
}

/**
 * Get pageable news for unique pagable endpoints, like see more trending.
 * @param {*} param0
 */
function* getPageableNews({
    section, excludedArticleIds, trendingTopic, basicProfile,
}) {
    let topic = trendingTopic;

    if (section === TRENDING_TOPIC_TYPE && topic === null) {
        try {
            ({ data: { content: { topic } } } = yield call(Api.get, TRENDING_TOPIC_NEWS_URL));
        } catch (e) {
            yield put(setError('global.errors.serverError', e));
        }
    }

    const { type } = newsSections[section];

    try {
        const headlinesResponse = yield call(
            Api.post,
            `${HEADLINES_URL}/${type}`, {
            data: {
                excludedArticleIds,
                trendingTopicId: topic && topic.id,
                advisor: {
                    aumId: basicProfile.aumId,
                    durationId: basicProfile.durationId,
                    serviceOfferingId: basicProfile.serviceOfferingId,
                },
            },
        },
        );
        yield put(getPageableNewsSuccess(headlinesResponse.data));
    } catch (e) {
        yield put(getPageableNewsFailure(e));
        yield put(setError('news.headlines.server.error', e));
    }
}

/**
 * Record article clicks.
 * @param {*} param0
 */
function* clickArticle({ article }) {
    if (article && article.sourceName === "Practice Lab") {
        const parcticeLabArticle = article;

        try {
            const dataObj = {
                data: {
                    id: parcticeLabArticle.id,
                    provider: parcticeLabArticle.provider,
                    publishDate: parcticeLabArticle.publishDate ? parcticeLabArticle.publishDate : parcticeLabArticle.date,
                    contentType: parcticeLabArticle.contentType ? parcticeLabArticle.contentType : null,
                    interactionId: parcticeLabArticle.interactionId ? parcticeLabArticle.interactionId : null,
                    interactionType: parcticeLabArticle.interactionType,

                }
            };
            yield call(Api.post, GET_VISIT_PRACTICE_LAB_URL, dataObj);
            yield put(handlePLArticleVisit(parcticeLabArticle.id));
        } catch (e) {
            yield put(getPracticeLabFailure(e));
            yield put(setError('guidePage.server.error', e));
        }
    } else {
        try {
            const topicId = article.topicId ? article.topicId : article.topics[0].id;

            const articleId = typeof (article.id) === 'string' ? btoa(article.id) : article.id;

            const dataObj = {
                data: {
                    id: articleId,
                    provider: article.provider,
                    publishDate: article.publishDate ? article.publishDate : article.date,
                    contentType: article.contentType ? article.contentType : null,
                    sourceId: article.sourceId,
                    interactionId: article.interactionId ? article.interactionId : null,
                    topicId: article.topicId,
                    sourceId: article.sourceId,
                    interactionType: article.interactionType,
                }
            };

            yield call(Api.post, ARTICLE_VISIT_URL(articleId, topicId), dataObj);
            yield put(handleArticleVisit(article.id));
        } catch (e) {
            // eslint-disable-next-line
        }
    }
}

function* newsSaga() {
    yield all([
        takeLatest(GET_TOP_NEWS_ITEMS, getTopNews),
        takeLatest(GET_NEWS, getNews),
        takeLatest(GET_PAGEABLE_NEWS, getPageableNews),
        takeLatest(SET_RANDOM_SERVICE_OFFERING, setRandomServiceOffering),
        takeEvery(VISIT_ARTICLE, clickArticle),
    ]);
}

export default newsSaga;
