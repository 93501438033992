import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { visuallyHiddenStyles } from 'components/VisuallyHidden/styles';
import { LIVE_TYPES } from 'components/AriaMessage/constants';

const StyledMessage = styled.div`
    ${visuallyHiddenStyles};
`;

const AriaMessage = ({ ariaLive, message }) => (
    <StyledMessage aria-live={ariaLive} role="log">
        {message}
    </StyledMessage>
);

AriaMessage.propTypes = {
    ariaLive: PropTypes.oneOf(Object.values(LIVE_TYPES)),
    message: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
    ]),
};

AriaMessage.defaultProps = {
    ariaLive: LIVE_TYPES.POLITE,
    message: '',
};

AriaMessage.LIVE_TYPES = LIVE_TYPES;

export default AriaMessage;
