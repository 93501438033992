import {
    SAVE_BASIC_PROFILE_FORM,
    SAVE_BASIC_PROFILE_FORM_SUCCESS,
    SAVE_BASIC_PROFILE_FORM_FAIL,
    SET_FORM_TOUCHED,
    SET_FORM_TO_EDIT_MODE,
    SET_FORM_TO_EDIT_DEFAULT_MODE,
    TRIGGER_FORM_SUBMIT,
    RESET_FORM_SUBMIT_TRIGGER,
    RESET_FORM_STATE,
    SET_FORM_UNTOUCHED,
    SET_FORM_RESET,
    SET_PREFERENCES_TO_DEFAULT,
    GET_BACK_TO_PREV_STATE,
    SET_FORM_TO_ONLY_EDIT_MODE
} from 'state-management/constants/basicProfileForm';

export const saveBasicProfileForm = data => (
    {
        type: SAVE_BASIC_PROFILE_FORM,
        data,
    }
);

export const getBackToPreviousState = {
    type: GET_BACK_TO_PREV_STATE
}

export const togglePreferncesToDefault = {
    type: SET_PREFERENCES_TO_DEFAULT,
};

export const saveBasicProfileFormSuccess = {
    type: SAVE_BASIC_PROFILE_FORM_SUCCESS,
};

export const saveBasicProfileFormFail = {
    type: SAVE_BASIC_PROFILE_FORM_FAIL,
};

export const setFormTouched = {
    type: SET_FORM_TOUCHED,
};

export const setFormReset = {
    type: SET_FORM_RESET,
};

export const setFormUntouched = () => ({
    type: SET_FORM_UNTOUCHED,
});

export const setFormToOnlyEditMode = {
    type: SET_FORM_TO_ONLY_EDIT_MODE,
};

export const setFormToEditMode = {
    type: SET_FORM_TO_EDIT_MODE,
};
export const  setFormToEditDefaultMode = {
    type: SET_FORM_TO_EDIT_DEFAULT_MODE,
};

export const triggerFormSubmit = {
    type: TRIGGER_FORM_SUBMIT,
};

export const resetFormSubmitTrigger = {
    type: RESET_FORM_SUBMIT_TRIGGER,
};

export const resetFormState = {
    type: RESET_FORM_STATE,
};
