import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { FormattedMessage } from "react-intl";
import { Container } from 'components';
import PropTypes from 'prop-types';
import {
    containerStyles,
} from './styles';

const StyledContainer = styled(Container)`
    ${containerStyles};
`;

/**
 * Legal FDIC notice text.
 */
const LegalFDIC = (props) => {
    return (
        <StyledContainer background={props.background}>
            <div>
                <FormattedMessage id="signUp.footer" />
            </div>
        </StyledContainer>
    );
};

LegalFDIC.propTypes = {
    background: PropTypes.string,
};

LegalFDIC.defaultProps = {
    background: null,
}

export default withRouter(LegalFDIC);
