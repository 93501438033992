import { css } from 'styled-components';
import {
    COLORS,
    FONTS,
    ANIMATIONS,
} from '../../../../utils/variables';
import {
    FROM_DESKTOP,
    FROM_TABLET,
    SMALL_MOBILE,
} from '../../../../utils/mediaQueries';
import {
    TYPE_INPRACTICE,
} from './GuideContentCard';

export const linkItem = css`
    text-decoration: none;
    display: block;
`;

export const topCard = css`
    position: relative;
`;

export const imageOverlayStyles = css`
    opacity: 0.5;
    background-image: linear-gradient(45deg, ${COLORS.SAPPHIRE}, ${COLORS.OCEAN}); /* NON standard colors per IAs request */
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
`;

export const imageContainerStyles = css`
    display: block;
    overflow: hidden;
    width: 100%;
    height: 157px;
    position: relative;

    @media screen and ${SMALL_MOBILE} {
        height: 157px;
        width: 100%
    }

    @media screen and ${FROM_TABLET} {
        height: 87.5px;
    }

    @media screen and ${FROM_DESKTOP} {
        height: 157px;
        width: 100%
    }
    
    img {
        margin-bottom: -4px;
        object-fit: cover;
        transition: ${ANIMATIONS.ARTICLES.IMAGE.TIME.SECONDS}s all;
        width: 100%;

        ${props => props.type === TYPE_INPRACTICE && css`
            filter: grayscale(100%);
        `};

        @media screen and ${SMALL_MOBILE} {
            height: 210px;
        }

        @media screen and ${FROM_TABLET} {
            height: 88px;
        }

        @media screen and ${FROM_DESKTOP} {
            height: 157px;
        }

        ${props => props.visited && css`
            opacity: 0.6;
        `};
    }
`;

export const sourceLinkStyles = css`
    color: ${COLORS.SAPPHIRE};
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 10px;
    font-weight: 600;
    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.SOURCE.TIME.SECONDS}s all;

    &:hover {
        opacity: 0.6;
    }
`;

export const poweredByStyles = css`
    color: ${COLORS.DARK_GRAY1};
    font-size: 10px;
    white-space: nowrap;
`;

export const titleStyles = css`
    color: ${COLORS.BLACK};
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.1px;
    margin: 0;
    margin-bottom: 8px;
    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.TITLE.TIME.SECONDS}s all;
    display: block;

    &:hover {
        color: ${COLORS.MEDIUM_GRAY2};
    }

    ${props => props.visited && css`
        color: ${COLORS.MEDIUM_GRAY2};

        &:hover {
            color: ${COLORS.BLACK};
        }
    `};

    @media screen and ${SMALL_MOBILE} {
        font-size: 15px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: -0.12px;
    }

    @media screen and ${FROM_TABLET} {
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: -0.06px;
        padding-top: 15px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 15px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: 0.1px;
        padding-top: 15px;
    }
`;

export const containerStyles = css`
    position: relative;
    background: ${COLORS.WHITE};
    border: 1px Solid ${COLORS.LIGHT_GRAY2};
    color: ${COLORS.BLACK};
    display: inline-block;
    max-width: 304px;
    overflow: hidden;
    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.CONTAINER.TIME.SECONDS}s all;

    @media screen and ${SMALL_MOBILE} {
        min-width: 299px;
        height: 330px;
    }

    @media screen and ${FROM_TABLET} {
        max-width: 223px;
        height: 274px;
        display: block;
    }

    @media screen and ${FROM_DESKTOP} {
        max-width: 274px;
        height: 331px;

        &:hover {
            img {
                transform: scale3d(1.05,1.05,1);
            }
        }
    }

    .kebab__trigger {
        right: 15px;
    }
`;

export const dateAndTopicContainerStyles = css`
    display: flex;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-bottom: 21px;
    text-transform: uppercase;

    @media screen and ${SMALL_MOBILE} {
        margin-bottom: 25px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 25px;
    }
`;

export const dateContainerStyles = css`
    color: ${COLORS.DARK_GRAY2};
    padding-right: 22px;
    white-space: nowrap;
`;

export const topicLinkStyles = css`
    color: ${COLORS.SAPPHIRE};
    flex: 1;
    text-align: right;
    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.TOPIC.TIME.SECONDS}s all;

    &:hover {
        opacity: 0.6;
    }
`;

export const contentContainerStyles = css`
    display:block;
    text-decoration:none;
    padding: 21px 16px 23px 16px;
    height: 157px;

    @media screen and ${SMALL_MOBILE} {
        padding: 21px 16px 23px 16px;
        height: 157px;
    }

    @media screen and ${FROM_TABLET} {
        padding: 14px 14px 14px 14px;
        height: 119px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 21px 16px 23px 16px;
    }
`;

export const infoContainerStyles = css`
    padding: 0;
    position: relative;
    font-style: normal;
    font-stretch: normal;
    text-transform: uppercase;
    color: ${COLORS.DARK_GRAY1};

    p {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.33;
        letter-spacing: 0.6px;
    }

    margin:-5px 0 21px 0;

    @media screen and ${SMALL_MOBILE} {
        p {
            font-size: 12px;
            font-weight: 500;
            line-height: 1.33;
            letter-spacing: 0.6px;
        }
        margin:-5px 0 21px 0;
    }

    @media screen and ${FROM_TABLET} {
        p {
            font-size: 10px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.5px;
            margin: 0;
        }
    }

    @media screen and ${FROM_DESKTOP} {
        p {
            font-size: 12px;
            font-weight: 500;
            line-height: 1.33;
            letter-spacing: 0.6px;
        }
        margin:-5px 0 0 0;
    }
`;

export const categoriesTitleContainerStyles = css`
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    color: ${COLORS.SAPPHIRE};
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    position:absolute;
    bottom: 0;
    z-index: 99;
    display: inline-block;
    text-decoration: none;
    padding-left: 16px;

    p {
        font-size: 12px;
        line-height: 1.33;
        letter-spacing: normal;
    }

    @media screen and ${SMALL_MOBILE} {
        p {
            font-size: 12px;
            line-height: 1.33;
            letter-spacing: normal;
        }
    }

    @media screen and ${FROM_TABLET} {
        p {
            font-size: 10px;
            line-height: 1.4;
            letter-spacing: 0.1px;
        }
    }

    @media screen and ${FROM_DESKTOP} {
        p {
            font-size: 12px;
            line-height: 1.33;
            letter-spacing: normal;
        }
    }
`;
