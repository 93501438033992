import React from 'react';
import styled from 'styled-components';
import Button from 'components/atomics/atoms/Button/Button';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { sendForgotPasswordEmail } from 'state-management/actions/forgotPassword';
import {
    TYPE_GHOST,
} from 'components/atomics/atoms/Button/Button';
import {
    container,
    buttonRow,
    title,
    subTitle,
    callout,
} from './styles';
import storage from 'utils/store';

const Container = styled.div`
    ${container}
`;

const Callout = styled.div`
    ${callout}
`;

const ButtonRow = styled.div`
    ${buttonRow}
`;

const Title = styled.div`
    ${title}
`;

const SubTitle = styled.div`
    ${subTitle}
`;

/**
 * Okta migration modal with email reset/resend flow.
 */
export class OktaMigrationModal extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.resendEmail = this.resendEmail.bind(this);
    }

    /**
     * Send forgot password at mount.
     */
    componentDidMount() {
        this.emailCheck();
    }

    /**
     * Resend forgot password email.
     */
    resendEmail() {
        this.emailCheck();
    }

    emailCheck() {
        if (this.props.email) {
            this.props.sendForgotPasswordEmail(this.props.email);
        } else {
            this.props.sendForgotPasswordEmail(storage.get('email'));
        }

    }

    /**
     * Render the modal and underlying components.
     */
    render() {
        return (
            <Container>
                <Title>
                    <FormattedMessage id="okta.modal.title" />
                </Title>
                <SubTitle>
                    <FormattedMessage id="okta.modal.description" />
                </SubTitle>
                <Callout>
                    <FormattedMessage id="Haven't received anything?" />
                </Callout>
                <ButtonRow>
                    <Button
                        type={TYPE_GHOST}
                        onClick={this.resendEmail}
                        dontScroll={true}
                        noAnchor
                    >
                        <FormattedMessage id="okta.modal.link" />
                    </Button>
                </ButtonRow>
            </Container>
        );
    }
}

OktaMigrationModal.propTypes = {
    email: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    sendForgotPasswordEmail: email => dispatch(sendForgotPasswordEmail(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OktaMigrationModal));
