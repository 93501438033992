import {
    SET_GLOBAL_LOGO, CLEAR_GLOBAL_LOGO,
} from 'state-management/constants/globalLogoLink';

function globalLogoLink(state = {}, action = {}) {
    switch (action.type) {
    case SET_GLOBAL_LOGO:
        return {
            logoLink: action.logoLink,
        };
    case CLEAR_GLOBAL_LOGO:
        return {};
    default:
        return state;
    }
}

export default globalLogoLink;
