import * as type from 'state-management/constants/optin.common';

export const getOptin = (id, email) => ({
    type: type.GET_OPTIN,
    id,
    email,
});

export const getOptinSuccess = data => ({
    type: type.GET_OPTIN_SUCCESS,
    data,
});

export const getOptinFailure = error => ({
    type: type.GET_OPTIN_FAIL,
    error,
});


export const saveAnswer = (id, optIn, email) => ({
    type: type.SAVE_ANSWER,
    id,
    optIn,
    email,
});

export const saveAnswerSuccess = res => ({
    type: type.SAVE_ANSWER_SUCCESS,
    res,
});

export const setReminder = () => ({
    type: type.SET_REMINDER,
});
