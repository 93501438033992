import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CollectionList from '../../../components/CollectionsList/CollectionList';
import {
    collectionsContainerStyles,
    collectionTitleStyles,
    collectionStyles,
} from './styles';
import Heading from 'components/Heading/Heading';
import {connect} from "react-redux";

const StyledTopicsContainer = styled.div`
    ${collectionsContainerStyles}
`;

const StyledTopicsTitle = styled(Heading)`
    ${collectionTitleStyles}
`;

export const StyledTopics = styled(CollectionList)`
    ${collectionStyles}
`;

/**
 * Displays related collections in the guide page.
 * @param {*} param0 
 */
const GuideCollections = ({
    collections,
}) => (
        <StyledTopicsContainer>
            <StyledTopicsTitle level={4}>
                <FormattedMessage id="guides.FeaturedCollections" />
            </StyledTopicsTitle>
            <CollectionList collections={collections} />
        </StyledTopicsContainer>
    );

GuideCollections.propTypes = {
    collections: PropTypes.arrayOf(PropTypes.shape())
};

const mapDispatchToProps = dispatch => ({
});

const mapState = state => ({
});

export default connect(mapState, mapDispatchToProps)(GuideCollections);
