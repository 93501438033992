import React from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import Link from 'components/atomics/atoms/Link/Link';
import { withCommonCard } from 'components/atomics/hocs/CommonCard/CommonCard';
import { TOPIC_LINK } from 'state-management/constants/topics';
import moment from 'moment';
import SourceTag, {
    PROVIDER_CAPITAL_GROUP,
} from '../../../../components/atomics/atoms/SourceTag/SourceTag';
import { getArticleUrl, getArticleImage } from 'utils/getArticleAsset';
import ImageAtom from 'components/atomics/atoms/Image/Image';
import {
    containerStyles,
    contentContainerStyles,
    dateAndTopicContainerStyles,
    dateContainerStyles,
    topicLinkStyles,
    titleStyles,
    imageContainerStyles,
    sourceContentContainerStyles,
} from '../../../../components/atomics/molecules/SkinnyListContentCard/styles';
import ListContentCardFooter from "../ListContentCard/ListContentCardFooter";
import { getTruncatedString } from 'utils/contentCardUtils';
import language from '../../../../lang/en.json';
import { IconExclamationSolid } from 'components/atomics/atoms/Icons/Icons';

export const Title = styled(Link)`
    ${titleStyles}
`;

export const DateAndTopic = styled.div`
    ${dateAndTopicContainerStyles}
`;

export const DateContainer = styled.div`
    ${dateContainerStyles}
`;

export const TopicLink = styled(Link)`
    ${topicLinkStyles}
`;

export const Container = styled.div`
    ${containerStyles}
`;

export const ImageContainer = styled(Link)`
    ${imageContainerStyles}
`;

export const ContentContainer = styled.div`
    ${contentContainerStyles}
`;

export const SourceContentContainer = styled.div`
    ${sourceContentContainerStyles}
`;

const MAX_ARTICLE_TITLE_CHARS = 120;

/**
 * Creates a skinny list content card.
 */
class SkinnyListContentCard extends React.Component {

    constructor(props) {
        super(props);
    }

    getTranslation(id, values = {}) {
        const { intl: { formatMessage } } = this.props;
        return formatMessage({ id }, values);
    }

    /**
     * Render the list content card and underlying components.
     */
    render() {
        const hasTopicId = (this.props.article.topicId !== 0) ? true : false;
        const isLastCard = (this.props.isLastCard) ? 'item-last' : '';
        const linkUrl = getArticleUrl(this.props.article);
        const imageUrl = getArticleImage(this.props.article, 2);
        const classNames = `TYPE-${this.props.article.type} ${this.props.alternativeBackground} ${isLastCard}`;

        let guideTxt, sourceContent;

        if (this.props.article.type === 'GUIDE_STANDARD' || this.props.article.type === 'GUIDE_MC_KINSEY') {
            guideTxt = "Guide";
            sourceContent = this.props.article.category;
        } else {
            const { article } = this.props;
            const expires = article.sourceName !== 'Capital Ideas' && article.provider !== 'CAPITAL_GROUP';
            const publishDate = article.date ? moment(article.date) : undefined;
            const expireDate = publishDate.clone().add(90, 'days');
            const hoursToExpire = moment.duration(expireDate.diff(moment())).asHours();    
            let dateTag = '';
            if (expires && hoursToExpire <= 24) {
                dateTag = <><IconExclamationSolid />{this.getTranslation('articles.date.expiresToday')}</>;
            } else if (expires && hoursToExpire <= 24 * 15) {
                dateTag = <><IconExclamationSolid />{this.getTranslation('articles.date.expiresIn', { days: Math.floor(hoursToExpire / 24 + 1) })}</>;
            } else {
                dateTag = publishDate.format('MMM D, YYYY') || '';
            }
    
            guideTxt = dateTag;
            sourceContent = <SourceTag
                type="list"
                onTopicClick={() => {
                }}
                sourceName={this.props.article.sourceName}
                provider={this.props.article.provider}
            />;
        }

        return (
            <Container
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this.props.onMouseLeave}
                className={classNames}
                className={`${this.props.article.visited ? (this.props.article.visited === true ? 'news-visited' : 'news-not-visited') : 'news-not-visited'}`}
            >
                <ImageContainer
                    to={linkUrl}
                    onClick={() => {
                        this.props.recordClickAnalytics();
                        this.props.onArticleClick();
                    }}
                    className={`${this.props.article.visited ? (this.props.article.visited === true ? 'news-visited' : 'news-not-visited') : 'news-not-visited'}`}
                >
                    <ImageAtom
                        src={imageUrl}
                        alt={language.translations['global.alt.image']}
                    />
                </ImageContainer>
                <ContentContainer>
                    <DateAndTopic className={`${this.props.article.visited ? (this.props.article.visited === true ? 'news-visited' : 'news-not-visited') : 'news-not-visited'}`} >
                        <DateContainer>
                            {guideTxt}
                        </DateContainer>
                        {hasTopicId
                            && (<TopicLink
                                data-analytics-placement="Button : body"
                                data-analytics-label="trackLink : button"
                                data-analytics-id={"topic:" + this.props.article.topicName}
                                to={TOPIC_LINK(`${this.props.article.topicName}/${this.props.article.topicId}`)}
                            >
                                <span>{this.props.article.topicName}</span>
                            </TopicLink>
                            )}
                    </DateAndTopic>
                    <Title
                        to={linkUrl}
                        onClick={() => {
                            this.props.recordClickAnalytics();
                            this.props.onArticleClick();
                        }}
                        className={`${this.props.article.visited ? (this.props.article.visited === true ? 'news-visited' : 'news-not-visited') : 'news-not-visited'}`}
                    >
                        {getTruncatedString(this.props.article.title, MAX_ARTICLE_TITLE_CHARS)}
                    </Title>

                    <ListContentCardFooter
                        onElement={this.props.onCardHover}
                        profile={this.props.profile}
                        standardDisplayOfSource={true}
                        setKebabMenuLimitSize={375}
                        hideSourceOption={this.props.article.provider === PROVIDER_CAPITAL_GROUP}
                        sourceName={this.props.article.sourceName}
                        providerName={this.props.article.provider}
                        article={this.props.article}
                        topics={this.props.article.topics}
                        sourceId={this.props.article.sourceId}
                        topicName={this.props.article.topicName}
                        saved={this.props.article.saved}
                        articleId={parseInt(this.props.article.id)}
                        articleTitle={this.props.article.title}
                        isListCard={true}
                        type={this.props.article.type || this.props.article.contentType}
                        shouldShowToast={this.props.shouldShowToast}
                        onSaveArticle={(id, save, title, getContentAfterSave, article) => {
                            this.props.toggleSaveArticle(id, save, title, getContentAfterSave, article);
                        }}
                        onSaveGuide={(id, save, title, getContentAfterSave, article) => {
                            this.props.toggleSaveGuide(id, save, title, getContentAfterSave, article);
                        }}
                        onFollowTopic={(topicId, status) => {
                            this.props.toggleFollowTopic(topicId, status);
                        }}
                        onFollowSource={(sourceId, status) => {
                            this.props.toggleFollowSource(sourceId, status);
                        }}
                        isVisitedClass={`${this.props.article.visited ? (this.props.article.visited === true ? 'news-visited' : 'news-not-visited') : 'news-not-visited'}`}
                    />
                </ContentContainer>
            </Container>
        );
    }
}

export default withCommonCard(injectIntl(SkinnyListContentCard));