import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'components/atomics/atoms/Link/Link';
import {
    sourceTagStyles,
    sourceLinkStyles,
    poweredByStyles,
    sourceLinkDisabled,
} from 'components/atomics/atoms/SourceTag/styles';
import labels from '../../../../lang/en.json';

export const SourceTagContainer = styled.div`
    ${sourceTagStyles}
`;

export const SourceLink = styled(Link)`
    ${sourceLinkStyles}
`;

export const SourceLinkDisabled = styled.div`
    ${sourceLinkDisabled}
`;

export const PoweredBy = styled.div`
    ${poweredByStyles}
`;

export const PROVIDER_LEXIS = 'LEXIS_NEXIS';
export const PROVIDER_CAPITAL_IDEAS = 'CAPITAL_IDEAS';
export const PROVIDER_CAPITAL_GROUP = 'CAPITAL_GROUP';

/**
 * Creates a visible source tag for use in content cards and skinny cards.
 */
class SourceTag extends React.Component {

    click(url) {
        this.props.onTopicClick(url)
    }
    /**
     * Render the source tag.
     */
    render() {
        let providerLabel = this.getProviderName();
        let source = this.props.sourceName;

        const { isDisabled, onTopicClick: onTopicClick, ceCard, isListCard } = this.props;

        let url;

        if (source == "CE Credits") {
            url = `/learn/ce-credits`;
        } else {
            url = `/sources/${source}`;
        }

        if (source == "Practice Lab") {
            url = `https://www.capitalgroup.com/advisor/practicelab.html`;
        }

        const linkSource = isDisabled
            ? (<SourceLinkDisabled>
                {source}
            </SourceLinkDisabled>)
            : (<SourceLink
                cecard={ceCard.toString()}
                standarddisplay={this.props.standardDisplay.toString()}
                type={this.props.type}
                variant={{ source, isListCard }}
                to={url}
            >
                {source}
            </SourceLink>);

        return (

            <SourceTagContainer
                onClick={() => this.click(url)}
            >
                {linkSource}
                {this.props.provider !== null ?
                    (<PoweredBy
                        standarddisplay={this.props.standardDisplay.toString()}
                        type={this.props.type}
                        variant={{ source, isListCard }}
                    >
                        {providerLabel}
                    </PoweredBy>)
                    : ' '}
            </SourceTagContainer>
        );
    }

    /**
     * Get the provider name string.
     */
    getProviderName() {
        let providerName;

        if (this.props.provider === PROVIDER_LEXIS) {
            providerName = labels.translations['article.provider.lexis'];
        } else if (this.props.provider === PROVIDER_CAPITAL_IDEAS || this.props.provider == PROVIDER_CAPITAL_GROUP) {
            providerName = providerName = labels.translations['article.provider.capitalGroup'];
        } else {
            providerName = labels.translations['article.provider.dj'];
        }

        return providerName;
    }
}

SourceTag.propTypes = {
    provider: PropTypes.string,
    sourceName: PropTypes.string,
    poweredBy: PropTypes.string,
    type: PropTypes.string,
    isDisabled: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
    ]),
    standardDisplay: PropTypes.bool,
    ceCard: PropTypes.bool,
    isListCard: PropTypes.bool,
}

SourceTag.defaultProps = {
    provider: null,
    poweredBy: null,
    type: null,
    isDisabled: false,
    onTopicClick: () => { },
    standardDisplay: false,
    ceCard: false,
    isListCard: false,
}

export default SourceTag;
