import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Link from 'components/atomics/atoms/Link/Link';
import Button from 'components/atomics/atoms/Button/Button';
import {
    TYPE_PRIMARY_MEDIUM,
} from 'components/atomics/atoms/Button/Button';
import { ALL_TOOLS_ROUTE } from 'state-management/constants/tools';
import { IconSeeMore } from 'components/atomics/atoms/Icons/Icons';
import language from '../../../lang/en.json'
import { StyledLoader, defaultOptions } from 'components/Loader/Loader';
import {
    Container
} from 'components';
import anime from 'animejs';
import { GLOBAL } from 'utils/variables';
import ToolsCardCollectionOfFour from '../../../components/atomics/organisms/ToolsCardCollectionOfFour/ToolsCardCollectionOfFour';
import { sendClickOnTools } from 'state-management/actions/tools';
import {
    featuredToolsContainerStyles,
    toolsHeaderStyles,
    linkStyles,
    linkContainerStyles,
    buttonContainerStyles,
    toolsCardStripStyles,
    headerStyles,
    loaderStyles,
    blankAreaStyles,
} from './styles';
import {
    BreakpointsContext,
    DESKTOP,
    MOBILE,
    TABLET,
} from 'components/Breakpoints/Breakpoints';

const GUIDES_PAGE = "Guides";
const COLLECTION_PAGE = "Collection";

const StyledLoaderContainer = styled.div`
    ${loaderStyles}
`;

const StyledBlankArea = styled.div`
    ${blankAreaStyles}
`;

const StyledToolsHeader = styled.div`
    ${toolsHeaderStyles}
`;

const FeaturedToolsContainer = styled.div`
    ${featuredToolsContainerStyles}
`;

const StyledLink = styled(Link)`
    ${linkStyles}
`;

const LinkContainer = styled.div`
    ${linkContainerStyles}
`;

const StyledHeader = styled.div`
    ${headerStyles}
`;

const ButtonContainer = styled.div`
    padding-bottom: 25px;
    ${buttonContainerStyles};
`;

const ToolsCardStrip = styled.div`
    ${toolsCardStripStyles}
`;



/**
 * Render the tools area for PM pages.
 */
class FeaturedTools extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.contentRef = React.createRef();
    }

    /**
     * Run the components close animation if the data failed to load.
     * @param {*} nextProps
     */
    componentWillUpdate(nextProps) {
        if (nextProps.loadingFailed === true) {
            this.animateClosed();
        }
    }


    /**
     * Collapse this component closed (and opacity to 0) from bottom to top.
     */
    animateClosed() {
        const contentNode = this.contentRef.current;

        this.animation = anime.timeline();

        this.animation
            .add({
                targets: contentNode,
                easing: [0.53, 0.05, 0.01, 0.97],
                opacity: [1, 0],
                duration: GLOBAL.ANIMATIONS.COLLAPSE_TIME.MILISECONDS,
                height: 0,
            });

        this.props.handleComponentFailure();
    }

    /**
     * Render the header text.
     */
    renderHeader = () => {
        return (
            <div>
                {this.props.parentPage === GUIDES_PAGE &&
                    <Container>
                        <StyledToolsHeader>
                            <h2>{this.props.toolsHeader}</h2>
                            <h4>{this.props.toolsSubHeader}</h4>
                        </StyledToolsHeader>
                    </Container>
                }
                {this.props.parentPage === COLLECTION_PAGE &&
                    <StyledHeader>
                        <div />
                        <div>
                            {this.props.toolsHeader}
                        </div>
                    </StyledHeader>
                }
            </div>
        )
    }

    /**
     * Render the lower control buttons.
     */
    renderButtons = () => {
        return (
            <div>
                {this.props.parentPage == GUIDES_PAGE &&
                    <BreakpointsContext.Consumer>
                        {(breakpoint) => {
                            if (breakpoint === MOBILE) {
                                return (
                                    <ButtonContainer>
                                        <Button
                                            type={TYPE_PRIMARY_MEDIUM}
                                            to={`${ALL_TOOLS_ROUTE}`}
                                            data-analytics-placement="Anchor : body"
                                            data-analytics-label="trackLink : anchor"
                                            data-analytics-id={language.translations['tools.more']}
                                        >
                                            <FormattedMessage id="tools.more" />
                                        </Button>
                                    </ButtonContainer>
                                );
                            } else {
                                return (
                                    <LinkContainer>
                                        <StyledLink
                                            to={`${ALL_TOOLS_ROUTE}`}
                                            data-analytics-placement="Anchor : body"
                                            data-analytics-label="trackLink : anchor"
                                            data-analytics-id={language.translations['tools.more']}
                                        >   <FormattedMessage id="tools.more" />
                                            <IconSeeMore />
                                        </StyledLink>
                                    </LinkContainer>
                                );
                            }
                        }}
                    </BreakpointsContext.Consumer>
                }
            </div>
        )
    }

    /**
     * Render the tool cards.
     */
    renderToolsStrip() {
        return (
            <BreakpointsContext.Consumer>
                {(breakpoint) => {
                    if (breakpoint === TABLET) {
                        return (
                            <div>
                                <ToolsCardStrip>
                                    <ToolsCardCollectionOfFour
                                        toolsCollection={this.props.toolsList.slice(0, 3)}
                                        sendClickOnTools={this.props.sendClickOnTools}
                                    />
                                </ToolsCardStrip>
                                {this.props.parentPage == COLLECTION_PAGE &&
                                    <div>
                                        <ToolsCardStrip>
                                            <ToolsCardCollectionOfFour
                                                toolsCollection={this.props.toolsList.slice(3, 6)}
                                                sendClickOnTools={this.props.sendClickOnTools}
                                            />
                                        </ToolsCardStrip>
                                        {this.props.toolsList.length > 3 &&
                                            <ToolsCardStrip>
                                                <ToolsCardCollectionOfFour
                                                    toolsCollection={this.props.toolsList.slice(6)}
                                                    sendClickOnTools={this.props.sendClickOnTools}
                                                />
                                            </ToolsCardStrip>}
                                    </div>
                                }
                            </div>
                        )
                    }

                    if (breakpoint === DESKTOP) {
                        return (
                            <div>
                                <ToolsCardStrip>
                                    <ToolsCardCollectionOfFour
                                        toolsCollection={this.props.toolsList.slice(0, 4)}
                                        sendClickOnTools={this.props.sendClickOnTools}
                                    />
                                </ToolsCardStrip>
                                {this.props.toolsList.length > 4 &&
                                    <ToolsCardStrip>
                                        <ToolsCardCollectionOfFour
                                            toolsCollection={this.props.toolsList.slice(4)}
                                            sendClickOnTools={this.props.sendClickOnTools}
                                        />
                                    </ToolsCardStrip>}
                            </div>
                        )
                    }

                    if (breakpoint === MOBILE) {
                        return (
                            <div>
                                <ToolsCardCollectionOfFour
                                    toolsCollection={this.props.toolsList}
                                    sendClickOnTools={this.props.sendClickOnTools}
                                />
                            </div>
                        )
                    }
                }
                }
            </BreakpointsContext.Consumer>
        );
    }

    /**
     * Render this and underlying components.
     */
    render() {
        return (
            <div>
                {this.renderHeader()}
                <Container noMobilePadding={true} ref={this.contentRef}>
                    <FeaturedToolsContainer sendClickOnTools={this.props.sendClickOnTools}>
                        {(!this.props.toolsList || this.props.toolsList.length < 1)
                            && <StyledBlankArea>
                                <StyledLoaderContainer>
                                    <StyledLoader
                                        options={defaultOptions}
                                    />
                                </StyledLoaderContainer>
                            </StyledBlankArea>}
                        {this.props.toolsList && this.props.toolsList.length > 0 && this.renderToolsStrip()}
                        {this.props.toolsList && this.props.toolsList.length > 0 && this.renderButtons()}
                    </FeaturedToolsContainer>
                </Container>
            </div>
        );
    }
}


FeaturedTools.propTypes = {
    toolsList: PropTypes.array,
    toolsHeader: PropTypes.string,
    toolsSubHeader: PropTypes.string,
    parentPage: PropTypes.string.isRequired,
    loadingFailed: PropTypes.bool,
    handleComponentFailure: PropTypes.func,
};

FeaturedTools.defaultProps = {
    loadingFailed: false,
    handleComponentFailure: () => { },
};

const mapDispatchToProps = dispatch => ({
    sendClickOnTools: (id) => dispatch(sendClickOnTools(id)),
});


export default connect(null, mapDispatchToProps)(FeaturedTools);

