import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'components/atomics/atoms/Link/Link';
import ImageAtom from 'components/atomics/atoms/Image/Image';
import {
    containerStyles,
    contentContainerStyles,
    typeStyles,
    typeContainerStyles,
    titleStyles,
    kebabAndSourceContainerStyles,
    kebabContainerStyles,
    imageContainerStyles,
    overlayStyles,
    toolsHeaderIconStyles,
    playContainerStyles,
} from 'components/atomics/organisms/WebinarCard/styles';
import {contextHubRecordEvent} from 'utils/contextHub';
import {
    CARDS_EVENT_LIST,
} from 'utils/contextHubEventListConstants';
import WebinarListContentCardFooter from "../../molecules/WebinarListContentCard/WebinarListContentCardFooter";
import { toggleSaveWebinar as toggleSaveWebinarAction} from "../../../../state-management/actions/save";
import { connect } from "react-redux";
import { CEIconWebinar , PLAYIcon} from 'components/atomics/atoms/Icons/Icons';
import { getTruncatedString } from 'utils/contentCardUtils';

export const ToolsHeaderIconContainer = styled.div`
    ${toolsHeaderIconStyles}
`;

export const Overlay = styled(Link)`
    ${overlayStyles}
`;

export const TypeContainer = styled.div`
    ${typeContainerStyles}
`;

export const ImageContainer = styled(Link)`
    ${imageContainerStyles}
`;

export const KebabAndSourceContainer = styled.div`
    ${kebabAndSourceContainerStyles}
`;

export const KebabContainer = styled.div`
    ${kebabContainerStyles}
`;

export const Title = styled(Link)`
    ${titleStyles}
`;

export const Type = styled.div`
    ${typeStyles}
`;

export const Container = styled.div`
    ${containerStyles}
`;

export const ContentContainer = styled.div`
    ${contentContainerStyles}
`;

export const PlayContainer = styled.div`
    ${playContainerStyles}
`;

const MAX_ARTICLE_TITLE_CHARS = 75;

/**
 * Creates a webinar content card.
 */
class WebinarCard extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.state = {
            isKebabOpen: false,
            onCardHover: false

        };

        this.onClick = this.onClick.bind(this);
        this.handleKebabOpenOutside = this.handleKebabOpenOutside.bind(this);
        this.CH_handleComponentEventList = this.CH_handleComponentEventList.bind(this);
    }

    /**
      * Handle clicks outside the kebab.
      * @param {W} bool
      */
    handleKebabOpenOutside(bool) {
        this.setState({
            isKebabOpen: bool,
        });
    }

    /**
     * Pass up the fact this webinar was clicked, so parent container can track that data.
     */
    onClick() {
        this.props.handleWebinarClick(this.props.article);
    }

    /**
     * Handle analytics click for capital card.
     * @param {*} arg
     */
    CH_handleComponentEventList() {
        contextHubRecordEvent(CARDS_EVENT_LIST(
            'webinar',
            this.props.article.title,
            // this.props.article.sourceName
        ));
    }

    /**
     * Handle hover state.
     */
    onMouseEnter = () => {
        this.setState({onCardHover: true});
    }

    /**
     * Handle remove hover state.
     */
    onMouseLeave = () => {
        this.setState({onCardHover: false});
    }

    /**
     * Handle save webinar action.
     */
    saveWebinar = (id, save, title, getContentAfterSave, article ) => {
        const {togglesaveWebinar} = this.props;

        togglesaveWebinar(id, save, title, getContentAfterSave, article);
    }


    /**
     * Handle follow source action.
     */
    followSource = (id, status) => {
        const {toggleFollowSource} = this.props;

        toggleFollowSource(id, status);
    }

    /**
     * Render the webinar ideas card and underlying components.
     */
    render() {
        if (this.props.article === undefined) {
            return null;
        }

        const { onCardHover } = this.state;
        const imageSource = this.props.article.image.includes('http') ? this.props.article.image : `/api${this.props.article.image}`;

        return (
            <Container
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            >
                <ImageContainer  to={`/learn/webinar/${this.props.article.code}`}
                    onClick={() => {
                        this.CH_handleComponentEventList();
                    }}
                >
                    {this.props.article.ceCredit
                    && <ToolsHeaderIconContainer>
                        <CEIconWebinar />
                    </ToolsHeaderIconContainer>}
                    <ImageAtom
                        src={imageSource}
                    />
                    <PlayContainer>
                        <PLAYIcon />
                    </PlayContainer>
                </ImageContainer>
                <ContentContainer>
                    <Type>
                        <TypeContainer>
                            {this.props.article.type}
                        </TypeContainer>
                    </Type>
                    <Title
                        to={`/learn/webinar/${this.props.article.code}`}
                        onClick={() => {
                            this.CH_handleComponentEventList();
                            this.onClick();
                        }}
                    >
                        {getTruncatedString(this.props.article.title, MAX_ARTICLE_TITLE_CHARS)}
                    </Title>
                    <WebinarListContentCardFooter
                        onElement={onCardHover}
                        profile={this.props.profile}
                        article={this.props.article}
                        saved={this.props.saved}
                        articleId={parseInt(this.props.article.id)}
                        articleTitle={this.props.article.title}
                        type={this.props.article.type}
                        shouldShowToast={this.props.shouldShowToast}
                        hideSourceOption={true}
                        onSaveArticle={(id, save, title, getContentAfterSave, article) => {
                            this.saveWebinar(id, save, title, getContentAfterSave, article );
                        }}
                        onsaveWebinar={(id, save, title, getContentAfterSave, article) => {
                            this.saveWebinar(id, save, title, getContentAfterSave, article );
                        }}
                    />
                </ContentContainer>
            </Container>
        );
    }
}

WebinarCard.propTypes = {
    article: PropTypes.shape({
        title: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        id: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        link: PropTypes.string.isRequired,
        saved: PropTypes.bool,
        type: PropTypes.string,
    }).isRequired,
    handleWebinarClick: PropTypes.func,
    handleKebabOpenOutside: PropTypes.func,
};

WebinarCard.defaultProps = {
    handleWebinarClick: () => { },
    handleKebabOpenOutside: () => { },
}

const mapDispatchToProps = dispatch => ({
    togglesaveWebinar: (eventId, save, title, shouldShowToast) => dispatch(toggleSaveWebinarAction(eventId, save, {
        title,
        shouldShowToast,
    })),
});

const mapState = state => ({
    profile: state.basicProfile,
    isActiveCoachMark: state.coachMark.display.KEBAB,
    saved: state.guide.guideRecent.webinarList ? state.guide.guideRecent.webinarList[0].saved : null,
});

export default connect(mapState, mapDispatchToProps)(WebinarCard);
