import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Modal from 'components/atomics/templates/ClosableModal/ClosableModal';
import { BasicButton } from 'components/atomics/atoms/Button/Button';
import { hidePreferencesModal } from 'state-management/actions/basicProfile';
import {
    TYPE_PRIMARY_LARGE,
    TYPE_GHOST,
} from 'components/atomics/atoms/Button/Button';
import { FormattedMessage } from 'react-intl';
import {
    Container,
    TitleText,
    Description,
} from 'components/atomics/molecules/NewsPreferences/NewsPreferences';
import {
    popupStyles,
} from './styles';

const StyledPopup = styled.div`
    ${popupStyles}
`;

const ModalStyles = {
    content: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        position: 'fixed',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        borderRadius: '0',
        boxSizing: 'border-box',
        width: '100%',
        outline: '0',
        background: 'transparent',
        border: '0',
    },
    overlay: {
        backgroundColor: 'rgba(${RGB_COLORS.DARK_SAPPHIRE}, 0.6)',
        zIndex: '1',
    },
};

/**
 * Preferences modal when user attempts to go to a page requiring personalization but has not fully onboarded.
 * (webinar signup user only)
 */
export class WebinarPreferencesModal extends React.Component {
    /**
     * Stuff to do on dismiss.
     */
    onConfirm = () => {
        window.location.href = "/onboarding";
    };

    /**
     * Stuff to do on modal close.
     */
    onClose = () => {
        this.props.hideModal();

        setTimeout(() => {
            this.props.onRequestClose(false);
        }, 400);
    };

    /**
     * Render the preferences modal and underlying components.
     */
    render() {
        if (!this.props.isOpen) {
            return null;
        }

        return (
            <Modal
                isOpen={this.props.isOpen}
                onRequestClose={this.onClose}
                style={ModalStyles}
                ariaHideApp={false}
                shouldFocusAfterRender={false}
            >
                <StyledPopup>
                    <Container>
                        <TitleText>
                            <FormattedMessage id="webinar.preferences.title" />
                        </TitleText>
                        <Description>
                        <FormattedMessage id="webinar.preferences.description" />
                            </Description>
                        <BasicButton
                            type={TYPE_PRIMARY_LARGE}
                            to="#_"
                            onClick={this.onConfirm}
                            dontScroll={true}
                        >
                        <FormattedMessage id="webinar.preferences.setPrefernces" />
                        </BasicButton>
                        <BasicButton
                            type={TYPE_GHOST}
                            to="#_"
                            onClick={this.onClose}
                            dontScroll={true}
                        >
                            <FormattedMessage id="webinar.preferences.dontSet" />
                        </BasicButton>
                    </Container>
                </StyledPopup>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    hideModal: () => dispatch(hidePreferencesModal()),
});

WebinarPreferencesModal.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(WebinarPreferencesModal);
