import React from 'react';
import BloombergLiveStream from 'components/BloombergLiveStream/BloombergLiveStream';

/**
 * Bloomberg video full screen page.
 */
export class BloombergFullScreen extends React.Component {
    render() {
        return (
            <React.Fragment>
                <BloombergLiveStream videoState="fullscreen" />
            </React.Fragment>
        );
    }
}

export default BloombergFullScreen;
