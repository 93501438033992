import { css } from 'styled-components';
import { 
    RGB_COLORS,
    COLORS,
    FONT_AVENIR_NEXT,
} from "../../../../utils/variables";
import { FROM_DESKTOP } from "../../../../utils/mediaQueries";

export const wrapperStyles = css`
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 20;
    bottom: 0;
    display: ${props => JSON.parse(props.display) ? 'block' : 'none'};
    background-color: ${COLORS.WHITE};
    box-shadow: 0 1px 3px rgba(${RGB_COLORS.BLACK},0.12), 0 1px 2px rgba(${RGB_COLORS.BLACK},0.12);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    border-top: 1px Solid ${COLORS.LIGHT_GRAY3};

    &:hover {
        box-shadow: 0 7px 14px rgba(${RGB_COLORS.BLACK},0.12), 0 5px 5px rgba(${RGB_COLORS.BLACK},0.12);
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        
        li {
            button {
                overflow: hidden;
                height: 38px;
                width: 100%;
                background: ${COLORS.WHITE};
                padding-left: 20px;
                border:0;
                text-align: left;
                position: relative;
                outline-style: none;
                font-family: ${FONT_AVENIR_NEXT};

                span {
                    font-size: 15px;
                    color: ${COLORS.SAPPHIRE};
                }

                @media screen and ${FROM_DESKTOP} {
                    height: 42px;
                }
            }

    
            button:hover {
                cursor:pointer;
                background-color: ${COLORS.TRANSPARENT_OCEAN};
            }

            &:last-child {
                border-top: 1px solid ${COLORS.LIGHT_GRAY1};
            }

            button:active {
                background: ${COLORS.PALE_OCEAN};
            }
        }
    }
`;
