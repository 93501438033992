import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Link from 'components/atomics/atoms/Link/Link';
import Button from 'components/atomics/atoms/Button/Button';
import { connect } from 'react-redux';
import {
    TYPE_GHOST_RIGHT,
    TYPE_PRIMARY_MEDIUM,
} from 'components/atomics/atoms/Button/Button';
import { IconSeeMore } from 'components/atomics/atoms/Icons/Icons';
import {
    Container
} from 'components';
import { MORE_COLLECTIONS_ROUTE } from 'state-management/constants/collections';
import {
    bodyStyles,
    sectionStyles,
    linkContainerStyles,
    linkStyles,
    buttonContainerStyles,
    collectionCardStripStyles
} from './styles';
import language from '../../../lang/en.json';
import CollectionCardCollectionOfFour from '../../../components/atomics/organisms/CollectionCardCollectionOfFour/CollectionCardCollectionOfFour';
import {
    BreakpointsContext,
    DESKTOP,
    MOBILE,
    TABLET,
} from 'components/Breakpoints/Breakpoints';
import { wrapBackButtonLabels } from "../../../utils/urlUtils";

export const StyledSection = styled.section`
    ${sectionStyles};
`;

const StyledBody = styled.div`
    ${bodyStyles};
`;

const StyledLink = styled(Link)`
    ${linkStyles}
`;

const LinkContainer = styled.div`
    ${linkContainerStyles}
`;

const CollectionCardStrip = styled.div`
    ${collectionCardStripStyles}
`;

const ButtonContainer = styled.div`
    ${buttonContainerStyles};
`;

/**
 * Render the all collections page.
 */
class CollectionsContainer extends React.Component {
    /**
     * Render the guide cards area within this section.
     */
    renderCollectionCards() {
        return (
            <BreakpointsContext.Consumer>
                {(breakpoint) => {
                    if (breakpoint === TABLET) {
                        return (
                            <div>
                                <CollectionCardStrip>
                                    <CollectionCardCollectionOfFour
                                        showCoachMark={true}
                                        showCollectionCoachMark={this.props.isCollectionCoachMarkEnabled}
                                        collectionList={this.props.collectionsList.slice(0, 3)}
                                    />
                                </CollectionCardStrip>
                                <CollectionCardStrip>
                                    <CollectionCardCollectionOfFour
                                        collectionList={this.props.collectionsList.slice(3, 6)}
                                    />
                                </CollectionCardStrip>
                            </div>
                        )
                    }
                    if (breakpoint !== TABLET) {
                        return (
                            <div>
                                <CollectionCardStrip>
                                    <CollectionCardCollectionOfFour
                                        showCoachMark={true}
                                        showCollectionCoachMark={this.props.isCollectionCoachMarkEnabled}
                                        collectionList={this.props.collectionsList.slice(0, 4)}
                                    />
                                </CollectionCardStrip> <CollectionCardStrip>
                                    <CollectionCardCollectionOfFour
                                        collectionList={this.props.collectionsList.slice(4)}
                                    />
                                </CollectionCardStrip>
                            </div>
                        )
                    }
                }
                }
            </BreakpointsContext.Consumer>
        );
    }

    /**
     * Render this and underlying components.
     */
    render() {
        return (
            <StyledSection>
                <Container>
                    <StyledBody>
                        {this.renderCollectionCards()}
                        <BreakpointsContext.Consumer>
                            {(breakpoint) => {
                                if (breakpoint === MOBILE) {
                                    return (
                                        <ButtonContainer>
                                            <Button
                                                type={TYPE_PRIMARY_MEDIUM}
                                                to={`${MORE_COLLECTIONS_ROUTE}`}
                                                data-analytics-placement="Anchor : body"
                                                data-analytics-label="trackLink : anchor"
                                                data-analytics-id={language.translations["collections.more"]}
                                            >
                                                <FormattedMessage id="collections.more" />
                                            </Button>
                                        </ButtonContainer>
                                    );
                                } else {
                                    return (
                                        <LinkContainer>
                                            <StyledLink
                                                to={`${MORE_COLLECTIONS_ROUTE}`}
                                                data-analytics-placement="Anchor : body"
                                                data-analytics-label="trackLink : anchor"
                                                data-analytics-id={language.translations["collections.more"]}
                                            >   <FormattedMessage id="collections.more" />
                                                <IconSeeMore />
                                            </StyledLink>
                                        </LinkContainer>
                                    );
                                }
                            }}
                        </BreakpointsContext.Consumer>
                    </StyledBody>
                </Container>
            </StyledSection>
        );
    }
};

CollectionsContainer.propTypes = {
    greetingHeader: PropTypes.string,
    collectionsList: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        ordering: PropTypes.number.isRequired,
        image: PropTypes.string.isRequired,
        latestPublishTimestamp: PropTypes.string.isRequired,
        group: PropTypes.string,
        guides: PropTypes.arrayOf(PropTypes.shape({})),
        tools: PropTypes.arrayOf(PropTypes.shape({})),
    })),
};

CollectionsContainer.defaultProps = {
    isCollectionCoachMarkEnabled: false,
};

const mapStateToProps = (state) => ({
    isCollectionCoachMarkEnabled: state.coachMark.display.PM_COLLECTIONS,
});

export default connect(mapStateToProps, null)(CollectionsContainer);
