import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BreakpointsContext, MOBILE } from 'components/Breakpoints/Breakpoints';
import { SanitizedHTML, Sticky, StickyContainer } from 'components';
import {
    descriptionContainerStyles,
    descriptionStyles,
    guideStyles,
    sageNoteStyles,
    titleStyles,
    wrapperStyles,
    spacerStyles
} from 'components/SageNoteWrapper/styles';

const StyledWrapper = styled.div`
    ${wrapperStyles}
`;

const StyledGuide = styled.div`
    ${guideStyles}
`;

const StyledTitle = styled.div`
    ${titleStyles}
`;

const StyledDescription = styled(SanitizedHTML)`
    ${descriptionStyles}
`;

const StyledSageNote = styled.div`
    ${sageNoteStyles}
`;
const StyledDescriptionContainer = styled.div`
    ${descriptionContainerStyles}
`;

const StyledSpacer = styled.div`
    ${spacerStyles}
`;

const SageNoteWrapper = ({
    component,
    title,
    description,
    id,
}) => (
        SageNoteWrapper.areAttributesValid(title, description) && (
            <BreakpointsContext.Consumer>
                {(breakpoint) => {
                    const isMobile = breakpoint === MOBILE;

                    return (
                        <StickyContainer>
                            <StyledWrapper id={id}>

                                {description.length > 1 &&
                                    <div>
                                        <Sticky
                                            enabled={!isMobile}
                                            id={`sticky-note-wrapper-${id}`}
                                            topOffset={40}
                                        >

                                            <StyledSageNote>
                                                <StyledTitle>
                                                    {title}
                                                </StyledTitle>
                                                <StyledDescriptionContainer>
                                                    <StyledDescription
                                                        htmlContent={description}
                                                        tag="div"
                                                    />
                                                </StyledDescriptionContainer>
                                            </StyledSageNote>
                                        </Sticky>

                                        <StyledSpacer />
                                    </div>
                                }
                                <StyledGuide>
                                    {component}
                                </StyledGuide>
                            </StyledWrapper>
                        </StickyContainer>
                    );
                }}
            </BreakpointsContext.Consumer>
        ));

SageNoteWrapper.areAttributesValid = (title, description) => title && description;

SageNoteWrapper.propTypes = {
    component: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    title: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.string.isRequired,
};

SageNoteWrapper.defaultProps = {
    component: null,
    title: null,
    description: null,
};

export default SageNoteWrapper;
