import { css } from 'styled-components';
import {
    COLORS,
} from '../../../../utils/variables';
import {
    FROM_DESKTOP,
    FROM_TABLET,
    SMALL_MOBILE,
} from '../../../../utils/mediaQueries';

export const containerStyles = css`
    display: flex;
    flex-direction: column;

    &>div {
        max-width: none;
        margin-bottom: 24px;
    }

    max-width: 304px;

    @media screen and ${SMALL_MOBILE} {
        max-width: 274px;
        height: 313px;
    }

    @media screen and ${FROM_TABLET} {
        max-width: 223px;
        height: 236px;
    }

    @media screen and ${FROM_DESKTOP} {
        max-width: 274px;
        height: 313px;
    }
`;

export const sliderCellStyles = css`
    padding-left: 6px;
    padding-right: 6px;
`;

export const sliderContainerStyles = css`
    overflow: hidden;

    *[data-rel="GuideCard"] {
        width: 299px;
    }

    *{
        outline: none;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-bottom: 90px;
    }

    .flickity-page-dots {
        height: 24px;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 0;
        text-align: center;

        li {
            display: inline-block;
            background: ${COLORS.LIGHT_GRAY2};
            border: none;
            border-radius: 50%;
            cursor: pointer;
            height: 10px;
            line-height: 200px;
            outline: none;
            overflow: hidden;
            padding: 0;
            width: 10px;
            margin-left: 5px;
            margin-right: 5px;

            &.is-selected {
                background: ${COLORS.SAPPHIRE};
            }
        }
    }
`;

export const singleCardHolderStyles = css`
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 25px;
    display: flex;
    justify-content: center;

    @media screen and ${FROM_TABLET} {
        padding-bottom: 0;
    }

    @media screen and ${FROM_DESKTOP} {
        padding-bottom: 60px;
    }
`;

