import {
    all,
    put,
    takeLatest,
    call,
    select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
    getWebinarInfoWithIDSuccess,
    getWebinarInfoWithIDFail,
    getWebinarInfoSuccess,
    getWebinarInfoFail,
    getWebinarMetaInfoSuccess,
    getWebinarMetaInfoFail,
    getWebinarIsUserRegisteredNo,
    getWebinarIsUserRegisteredYes,
    claimYourSpotSuccess,
    claimYourSpotFail,
    notInterstedSuccess,
    notInterstedFail,
    notInterested
} from 'state-management/actions/webinar';
import {
    WEBINAR_DATA_URL,
    WEBINAR_DATA_URL_WITH_ID,
    GET_WEBINAR_INFO,
    WEBINAR_INFO_URL,
    GET_WEBINAR_META_INFO,
    WEBINAR_META_DATA_URL,
    CLAIM_YOUR_SPOT,
    CLAIM_YOUR_SPOT_URL,
    WEBINAR_IS_USER_REGISTERD_URL,
    WEBINAR_IS_USER_REGISTERD,
    NOT_INTERESTED_URL,
    NOT_INTERESTED,
    GET_WEBINAR_INFO_WITH_ID 
} from 'state-management/constants/webinar';
import Api from '../bridge/api';
import {GET_WEBINAR_REGISTRATION_STATUS, WEBINAR_REGISTERED_STATUS} from "../constants/webinar";

/**
 * Get webinar data by ID.
 * @param{*} param0
 */
function* getWebinarIsUserRegistered() {
    try {
        const response = yield call(Api.get, WEBINAR_IS_USER_REGISTERD_URL);

        if (response.data.isRegistered) {
            yield put(getWebinarIsUserRegisteredYes());
        } else {
           yield put(getWebinarIsUserRegisteredNo());
        }
    } catch (e) {
        yield put(getWebinarIsUserRegisteredNo());
    }
}

/**
 * Get webinar data by ID.
 * @param{*} param0
 */
function* getWebinarInfo() {
    try {
        const response = yield call(Api.get, WEBINAR_DATA_URL); 
        yield put(getWebinarInfoSuccess(response.data));
    } catch (e) {
        yield put(getWebinarInfoFail());
    }
}

function* getWebinarInfoWithId({ code }){
    try {
        //const eventId = yield select(state => state.webinar.webinar.eventId);
        //const code = yield select(state => state.webinar.webinar.content.code);
        const response = yield call(Api.get, WEBINAR_DATA_URL_WITH_ID(code));
        yield put(getWebinarInfoWithIDSuccess(response.data));
    } catch (e) {
        yield put(getWebinarInfoWithIDFail());
    }
}

/**
 * Get webinar data by ID.
 * @param{*} param0
 */
function* getWebinarMeta({ webinarId }) {
    try {
        const response = yield call(Api.get, WEBINAR_META_DATA_URL(webinarId));

        yield put(getWebinarMetaInfoSuccess(response.data));
    } catch (e) {
        yield put(getWebinarMetaInfoFail());
    }
}

/**
 * Get webinar data by ID.
 * @param{*} param0
 */
function* claimYourSpot(decision) {
    try {
        const email = yield select(state => state.basicProfile.email);
        const eventId = yield select(state => state.webinar.webinar.eventId);
        const code = yield select(state => state.webinar.webinar.content.code);
        const answer = typeof(decision === 'object') ? decision.decision : decision;

        const response = yield call(Api.post, CLAIM_YOUR_SPOT_URL(eventId), {
            data: {
                email,
            }
        });

        yield put(claimYourSpotSuccess(response));
        yield put(notInterested(eventId, answer));
        yield put(push(`/learn/webinar/${code}`));
    } catch (e) {
        yield put(push(`/learn/webinar/error`));
        yield put(claimYourSpotFail());
    }
}
/**
 * Get webinar data by ID.
 * @param{*} param0
 */
function* notInterestedSaga({ eventId, decision }) {
    try {
        yield call(Api.put, NOT_INTERESTED_URL(eventId, decision));
        yield put(notInterstedSuccess(decision));
    } catch (e) {
        yield put(notInterstedFail());
    }
}

const reArrangeWebinarData = (code, data) => {
    let tmpData = data;
    tmpData.code = code;
    return tmpData;
}

const setNotInterseted = (deceision, data) => {
    let tmpData = data;
    tmpData.decision = deceision;
    return tmpData;

}

function* webinarSaga() {
    yield all([
        takeLatest(WEBINAR_IS_USER_REGISTERD, getWebinarIsUserRegistered),
        takeLatest(GET_WEBINAR_INFO, getWebinarInfo),
        takeLatest(GET_WEBINAR_INFO_WITH_ID, getWebinarInfoWithId),
        takeLatest(GET_WEBINAR_META_INFO, getWebinarMeta),
        takeLatest(CLAIM_YOUR_SPOT, claimYourSpot),
        takeLatest(NOT_INTERESTED, notInterestedSaga)
    ]);
}

export default webinarSaga;
