import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import containerStyles from 'components/Container/styles';

export const SMALL = 'small';
export const MEDIUM = 'medium';
export const LARGE = 'large';

const StyledContainer = styled.div`
    ${containerStyles};
`;

class Container extends React.PureComponent {
    render() {
        const {
            children,
            size,
            noMobilePadding,
            ...other
        } = this.props;

        return (
            <StyledContainer
                {...other}
                noMobilePadding={noMobilePadding.toString()}
                variant={{ size }}
            >
                {children}
            </StyledContainer>
        );
    }
}

Container.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    size: PropTypes.oneOf([
        SMALL,
        MEDIUM,
        LARGE,
    ]),
    noMobilePadding: PropTypes.bool,
};

Container.defaultProps = {
    children: null,
    size: SMALL,
    noMobilePadding: false,
};

Container.SMALL = SMALL;
Container.MEDIUM = MEDIUM;
Container.LARGE = LARGE;

export default Container;
