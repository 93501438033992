import { css } from 'styled-components';
import { FROM_TABLET } from 'utils/mediaQueries';
import { StyledAccordionWrapper, StyledAccordionHeader } from 'components/Accordion/Accordion';
import { COLORS, RGB_COLORS } from 'utils/variables';

export const wrapperStyles = css`
    background-color: ${COLORS.WHITE};
    display: flex;
    flex-flow: column nowrap;
    bottom: 0;
    left: 0;
    position: fixed;
    top: 60px;
    width: 100vw;

    @media screen and ${FROM_TABLET} {
        box-shadow: 0 4px 20px 0 rgba(${RGB_COLORS.BLACK}, 0.16);
        display: block;
        height: auto;
        position: absolute;
        top: 100%;
        width: 100%;
        bottom: auto;
        margin-top: -9px;
    }
`;

export const containerStyles = css`
    display: flex;
    padding: 10px 6px;

    ${props => props.height !== null && css`

    `}
`;

export const mobileContainerStyles = css`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow: auto;
    padding: 6px 14px 45px 14px;

    /* stylelint-disable */
    -webkit-overflow-scrolling: touch;

    ${StyledAccordionWrapper} {
        flex: 0 0 auto;
    }
    ${StyledAccordionHeader} {
        padding: 19px 14px 18px 0;
    }
    /* stylelint-enable */
`;

export const columnStyles = css`
    flex: 1;
    max-height: 490px;
    overflow-y: auto;
    /* stylelint-disable */
    overflow-y: overlay;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    /* stylelint-enable */

    &:not(:first-of-type) {
        box-shadow: -1px 0 ${COLORS.LIGHT_GRAY3};
    }
`;

export const footerStyles = css`
    background-color: ${COLORS.LIGHT_GRAY1};
    box-shadow: 0 -3px 34px 0 rgba(${RGB_COLORS.BLACK}, 0.1);
    display: flex;
    flex: 0 0 auto;
    justify-content: flex-end;
    padding: 20px 19px 19px;

    @media screen and ${FROM_TABLET} {
        background-color: ${COLORS.WHITE};
        border-top: 1px solid ${COLORS.LIGHT_GRAY3};
        box-shadow: none;
        padding: 16px 38px;
    }
`;

export const clearAllButtonStyles = css`
    border: 0;
    color: ${COLORS.SAPPHIRE};    
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1.5px;
    padding: 17px 15px 15px;
    &:hover{
        color: ${COLORS.DARK_SAPPHIRE};
    }

    @media screen and ${FROM_TABLET} {
        padding: 15px 30px 15px;
    }
`;

export const doneButtonStyles = css`    
    text-align: center;
    font-size: 12px;
    padding-left: 48px;
    padding-right: 48px;
    background-color: ${COLORS.SAPPHIRE};

    @media screen and ${FROM_TABLET} {
        padding: 15px 48px;
    }
`;

export const selectionCountStyles = css`
    font-weight: 700;
    margin-left: 4px;
`;
