import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Button, Heading } from 'components';

import {
    sectionHeadingStyles,
    clearButtonStyles,
    sectionHeaderStyles,
    filterSectionStyles,
} from 'containers/Search/SearchFilterSection/styles';
import DateSearchFilterItems from 'containers/Search/DateSearchFilterItems/DateSearchFilterItems';

const StyledSectionHeading = styled(Heading)`
    ${sectionHeadingStyles}
`;

const StyledClearButton = styled(Button)`
    ${clearButtonStyles}
`;

const StyledSectionHeader = styled.div`
    ${sectionHeaderStyles}
`;

const StyledFilterSection = styled.div`
    ${filterSectionStyles}
`;

/**
 * Date based search filters container for search page filters menu.
 */
class DateSearchFilterSection extends PureComponent {
    render() {
        const {
            isInitialState,
            onClear,
            onToggleItem,
            section,
        } = this.props;

        return (
            <StyledFilterSection>
                <StyledSectionHeader>
                    <StyledSectionHeading level={2}>
                        <FormattedMessage id={`search.filters.sections.${section.title}`} />
                    </StyledSectionHeading>
                    {!isInitialState && (
                        <StyledClearButton
                            id={`clear-${section.title}-section`}
                            onClick={() => onClear(section.title)}
                        >
                            <FormattedMessage id="search.filters.reset" />
                        </StyledClearButton>
                    )}
                </StyledSectionHeader>
                <DateSearchFilterItems section={section} onToggleItem={onToggleItem} />
            </StyledFilterSection>
        );
    }
}

DateSearchFilterSection.propTypes = {
    isInitialState: PropTypes.bool.isRequired,
    onClear: PropTypes.func.isRequired,
    onToggleItem: PropTypes.func.isRequired,
    section: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
};

export default DateSearchFilterSection;
