import styled, { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS } from 'utils/variables';

export const headerStyles = css`
    align-items: baseline;
    display: flex;
    justify-content: space-between;

    @media screen and ${FROM_TABLET} {
        justify-content: normal;
        padding: 0;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-top: 103px;
        padding: 0;
    }
`;

export const headerSubStyles = css`
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    margin: 10px 0 32px;   

    @media screen and ${FROM_TABLET} {
        margin: 25px 0 20px;  
    }

    @media screen and ${FROM_DESKTOP} {
        margin: 28px 0 18px;  
    }
`;

export const headingSubStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-size: 12px;
    font-weight: 700;
    line-height: 1.78;
    word-wrap: break-word; 
    text-transform: uppercase; 
    margin-bottom: 0; 
`;

export const editButtonStyles = css`
    background-color: transparent;
    border: 0;
    color: ${COLORS.SAPPHIRE};
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: -0.1px;
    line-height: 0.93;
    padding: 5px;
    word-wrap: break-word;
    text-transform: uppercase;
    margin-left: 15px;
    bottom: 22px;

    @media screen and ${FROM_TABLET} {
        font-size: 12px;
        line-height: 0.78;
        bottom: 23px;
    }

    @media screen and ${FROM_DESKTOP} {
        bottom: 22px;
    }

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) { 
        bottom: 18px;
    }

    /* stylelint-disable */

    ${({ isDisabled }) => isDisabled && css`
        color: ${COLORS.LIGHT_GRAY3};
        visibility: hidden;
    `}

    &:hover {
        color: ${COLORS.DARK_SAPPHIRE};
    }

    /* stylelint-enable */
`;

export const contentContainerStyles = css`
display: flex;
flex-direction: row;
flex-wrap: wrap;
width: 100%;
    margin: 10px 0 75px;

    @media screen and ${FROM_TABLET} {
        margin: 20px 0 110px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin: 0;
    }

    .EditProfileForm__StyledFieldContainer-kcbtOJ{

        @media screen and ${FROM_TABLET} {
            min-width: 346px;
            padding: 0 0 15px;
            margin: 0;
            width: 50%;
        }

        @media screen and ${FROM_DESKTOP} {
            width: 50%;
            min-width: 371px;
                       
        }
    
    }
`;

export const AccountHeaderWrapper = styled.div`
  width: 130px;
  position: relative;
  
     @media screen and ${FROM_DESKTOP} {
          width: 160px;
    }
`;

export const styledDivElemenntStyles = css `
    @media screen and ${FROM_TABLET} {

    }

    @media screen and ${FROM_DESKTOP} {
        margin-left: 250px;
        padding: 0 0 0 54px;
        max-width: 743px;
    }
`;
