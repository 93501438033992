import { css } from 'styled-components';
import {
    COLORS,
}from '../../../utils/variables';
import {
    FROM_TABLET,
    FROM_DESKTOP,SMALL_MOBILE 
}from '../../../utils/mediaQueries';

export const containerStyles = css`
    background: ${COLORS.WHITE};
`;

export const paddingContainerStyles = css`
    margin: auto;
    padding-left: 0x;
    padding-right: 0px;

    @media screen and ${SMALL_MOBILE} {
      
    }

    @media screen and ${FROM_TABLET} {        
        padding-left: 38px;
        padding-right: 38px;
    }
    
    @media screen and ${FROM_DESKTOP} {
        padding-left: 48px;
        padding-right: 48px;
        max-width:1172px;
    }
`;
