import { css } from 'styled-components';

import { outerFocusMixin } from 'components/FocusVisibility/styles';

export const errorsStyles = css`
    ${outerFocusMixin};

    /* stylelint-disable */

    ${props => props.variant && !props.variant.hasErrors && css`
        display: none;
    `};

    /* stylelint-enable */
`;

export const errorsContainerStyles = css`
    margin-bottom: 30px;
`;
