import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'containers/App/constants';
import {
    containerStyles,
    titleStyles,
    messageStyles,
    linkStyles,
} from 'containers/SignUp/SignUpForm/HaveWeMetBefore/styles';

const StyledContainer = styled.div`
    ${containerStyles}
`;

const StyledTitle = styled.div`
    ${titleStyles}
`;

const StyledMessage = styled.p`
    ${messageStyles}
`;

const StyledLink = styled(NavLink)`
    ${linkStyles}
`;

/**
 * Render have we met before error state for signup form.
 */
const HaveWeMetBefore = () => (
    <StyledContainer>
        <StyledTitle>
            <FormattedMessage id="signUp.haveWeMetBefore.title" />
        </StyledTitle>
        <StyledMessage>
            <FormattedMessage id="signUp.haveWeMetBefore.explanation" />
        </StyledMessage>
        <StyledMessage>
            <FormattedMessage
                id="signUp.haveWeMetBefore.contactUs"
                values={{
                    sendMessageLink: (
                        <StyledLink
                            key={ROUTES.CONTACT_US}
                            to={ROUTES.CONTACT_US}
                        >
                            <FormattedMessage id="signUp.haveWeMetBefore.sendMeAMessage" />
                        </StyledLink>
                    ),
                }}
            />
        </StyledMessage>
    </StyledContainer>
);

export default HaveWeMetBefore;
