import {
    all, call, cancel, fork, put, take,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import store from 'utils/store';
import { REAUTHENTICATE, SIGN_IN, SIGN_IN_FAIL } from 'state-management/constants/signIn';
import { SIGN_OUT } from 'state-management/constants/signOut';
import {
    refreshFail, refreshSuccess, signInFail, signInSuccess,
} from 'state-management/actions/signIn';
import { resendEmail } from 'state-management/actions/signUp';
import { setScrollToTop } from 'state-management/actions/scroll';
import {
    incrementProfileRefinementSessionCount,
} from './../../state-management/actions/profileRefinementCard';
import Api from 'utils/api';
import { getIdentityServiceBaseURL } from 'utils/urlUtils';
import authenticate from 'state-management/sagas/authentication';
import DEFAULT_TRANSLATIONS from 'app/lang/en.json';
import { setFocusOnError, clearErrors } from 'state-management/actions/errors';
import { setBasicProfile, getNotification } from 'state-management/actions/basicProfile';
import { GET_NOTIFICATION_SUCCESS, GET_NOTIFICATION_FAILURE } from 'state-management/constants/basicProfile';
import {setAEID,setCRD} from 'utils/contextHub';
import { BASIC_PROFILE_URL, SKIP_MIGRATION_STORAGE_KEY, MIGRATION_ENUM } from 'state-management/constants/basicProfile';
import storage from '../../utils/store';
import { SIGNUP_EMAIL } from './signUp';
import { MOBILE_APP_NOTIFICATION } from 'containers/MobileAppNotification/MobileAppNotification';
import { contextHubRecordEvent } from '../../utils/contextHub';
import { WEBINAR_REGISTERED_STATUS } from "../constants/webinar";
import { ROUTES } from "../../containers/App/constants";
import {
    REDIRECT_AFTER_LOGIN_URL,
    IDENTITY_PROFILE_URL,
} from "../constants/signIn";
import { WEBINAR_DATA_URL } from "../constants/webinar";
import { EMAIL_CHANGED } from 'containers/EmailVerification/ConfirmEmail/ConfirmEmail';
import { IS_LOGGED_OUT } from 'utils/api';
/**
 * Attempt user signin.
 * @param {*} values
 * @param {*} actions
 */
function* signIn(values, actions) {
    storage.remove(IS_LOGGED_OUT);
    storage.remove(SKIP_MIGRATION_STORAGE_KEY);
    localStorage.removeItem(SKIP_MIGRATION_STORAGE_KEY);

    const { setErrors, setSubmitting } = actions;

    try {
        const noteParsedData = storage.get(MOBILE_APP_NOTIFICATION) != null
            ? JSON.parse(storage.get(MOBILE_APP_NOTIFICATION))
            : null;

        yield call(authenticate, values);

        yield call(Api.updateCsrf);

        const response = yield call(Api.get, BASIC_PROFILE_URL);

        /* CRD sign-up user, they havent verified their email so.. */
        if (response.data.emailConfirm && response.data.emailConfirm === "NO") {
            storage.set(SIGNUP_EMAIL, values.email);

            yield put(resendEmail(values.email));
            yield put(setScrollToTop());

            if (response.data.emailUpdated) {
                storage.set(EMAIL_CHANGED, true);
            }

            //log user out as well
            yield call(Api.logout);
            //clear loading state for sign-in form
            yield put(signInFail());

            yield put(push('/confirm-email'));

            return;
        }

        const hasValidMobileNotificationStore = noteParsedData && noteParsedData.hasOwnProperty('user') && noteParsedData.user === values.email;

        if (hasValidMobileNotificationStore) {
            noteParsedData.loginCount += 1;
            noteParsedData.hideLumen = true;

            if (noteParsedData.loginCount > 3) {
                noteParsedData.loginCount = 0;
                noteParsedData.dismissed = false;
                noteParsedData.showAlternate = true;
            }

            storage.set(MOBILE_APP_NOTIFICATION, JSON.stringify(noteParsedData));
        } else {
            storage.set(MOBILE_APP_NOTIFICATION, JSON.stringify({
                loginCount: 0,
                dismissed: false,
                showAlternate: true,
                hideLumen: false,
                user: values.email
            }));
        }

        if (response.data.aeid) {
            setAEID(response.data.aeid.toString());
        }

        if (response.data.crd) {
            setCRD(response.data.crd.toString());
        }

        try {
            yield put(getNotification());
            const action = yield take([ GET_NOTIFICATION_SUCCESS, GET_NOTIFICATION_FAILURE ]);
            if (action.type === GET_NOTIFICATION_FAILURE) {
                throw new Error(action.error);
            } else {
                // fetch the migrated profile data if user is migrated, merge it with CG Pro profile data
                if (notification.data.migrationState === MIGRATION_ENUM.MIGRATED) {
                    let migratedProfileResponse = yield call(
                        Api.request,
                        IDENTITY_PROFILE_URL,
                        null,
                        null,
                        'GET',
                        null,
                        false,
                    );

                    if (response.data.migrationEmailConfirmed === false) {
                        storage.set(SIGNUP_EMAIL, migratedProfileResponse.data.email);
                        storage.set(EMAIL_CHANGED, true);
                        yield put(resendEmail(migratedProfileResponse.data.email));
                        yield put(setScrollToTop());
                        yield call(Api.logout);
                        yield put(signInFail());
                        yield put(push('/confirm-email'));
                        return;
                    }

                    yield put(setBasicProfile({
                        ...response.data,
                        ...migratedProfileResponse.data,
                        proEmail: response.data.email,
                    }));
                } else {
                    yield put(setBasicProfile(response.data));
                }
            }
        } catch (e) {
            yield put(setBasicProfile(response.data));
        }

        yield call(Api.setFirstName, response.data.firstName);

        localStorage.setItem("loggedIn", true);

        if (response.data.signUpType === 'WEBINAR' && response.data.firstLogin === "YES") {
            //yield put(signInSuccess());

            store.remove(REDIRECT_AFTER_LOGIN_URL);

            // yield call(getWebinarIsUserRegistered());
            const webinarStatus = yield call(Api.get, WEBINAR_REGISTERED_STATUS);

            const webinarURL = yield call(Api.get, WEBINAR_DATA_URL);

            if (webinarStatus.data.isRegistered) {
                yield put(push(`/learn/webinar/${webinarURL.data.content.code}`));

            } else {
                yield put(push(ROUTES.WEBINAR_ERROR));
            }
        } else {
            yield put(signInSuccess());
        }

        yield put(clearErrors());

        //increment session count IF onboarded.
        if (response.data.onboarded) {
            yield put(incrementProfileRefinementSessionCount());
        }
    } catch (error) {
        let analyticsLoginFailObj = {msg:"Lumin Login failed", type:"LoginFail"};

        switch (error.status) {
            case 428:
                yield call(setErrors, {
                    authentication: 11,
                });
                break;
            case 401:
                contextHubRecordEvent(analyticsLoginFailObj);
                yield call(setErrors, {
                    authentication: 10,
                });
                break;
            default:
                contextHubRecordEvent(analyticsLoginFailObj);
                yield call(setErrors, {
                    authentication: DEFAULT_TRANSLATIONS.translations['signIn.error.failed'],
                });
                break;
        }

        yield call(setSubmitting, false);
        yield put(signInFail());
        yield put(setFocusOnError(true));
    }
}

function* signInFlow() {
    while (true) {
        const { actions, values } = yield take(SIGN_IN);
        const task = yield fork(signIn, values, actions);
        const action = yield take([SIGN_OUT, SIGN_IN_FAIL]);

        if (action.type === SIGN_OUT) {
            yield cancel(task);
        }
    }
}

/**
 * Manage page refresh flow.
 */
function* refreshFlow() {
    while (true) {
        yield take(REAUTHENTICATE);
        try {
            yield call(Api.updateCsrf);
            yield call(Api.refreshToken);
            
            yield put(refreshSuccess());
        } catch (error) {
            yield call(Api.logout);
            yield put(refreshFail());
        }
    }
}

export default function* signInSaga() {
    yield all([
        fork(refreshFlow),
        fork(signInFlow),
    ]);
}
