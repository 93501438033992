import { css } from 'styled-components';
import { COLORS } from 'utils/variables';
import { FOCUS_CLASS } from 'components/FocusVisibility/constants';

export const innerFocusStyles = css`
    box-shadow: inset 0 0 0 2px ${COLORS.OCEAN};
`;

export const outerFocusStyles = css`
    box-shadow: 0 0 0 2px ${COLORS.OCEAN};
`;

export const outerCustomFocusMixin = () => css`
    .${FOCUS_CLASS} & {
        ${outerFocusStyles};
    }
`;

export const outerFocusMixin = () => css`
    &:focus {
        outline: 0;
    }

    .${FOCUS_CLASS} &:focus {
        ${outerFocusStyles};
    }
`;

export const innerFocusMixin = () => css`
    &:focus {
        outline: 0;
    }

    .${FOCUS_CLASS} &:focus {
        ${innerFocusStyles};
    }
`;
