import { css } from 'styled-components';
import {
    COLORS,
    FONTS,
} from 'utils/variables';
import {
    FROM_DESKTOP,
    FROM_TABLET,
    FROM_TABLET_TO_DESKTOP,
} from 'utils/mediaQueries';
import {
    TYPE_TESTFLIGHT,
    TYPE_TOPIC,
} from 'components/atomics/molecules/NudgeCard/NudgeCard';

export const containerStyles = css`
    background-color: ${COLORS.WHITE};
    border: 1px solid ${COLORS.LIGHT_GRAY2};
    max-width: 344px;
    overflow: hidden;
    position: relative;
    text-align: center;
    height: 331px;
    max-height: 331px;
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    margin-right: 16px;
    
    @media screen and ${FROM_TABLET} {
        max-width: 222px;
        height: auto;
        margin-left: 0;
        margin-right: 0;
    }

    @media screen and ${FROM_DESKTOP} {
        max-width: 278px;
        height: 331px;
    }

    *[data-rel="testflightDismiss"] a {
        letter-spacing: 0.5px;
    }

    ${props => props.forceMinWidth && css`
        max-width: 208px !important;
    `}
`;

export const colorBarStyles = css`
    width: 100%;
    height: 8px;
    overflow: hidden;
    background-image: linear-gradient(89deg, ${COLORS.SAPPHIRE}, ${COLORS.OCEAN});
`;

export const titleStyles = css`
    color: ${COLORS.SAPPHIRE};
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0;
    margin: 0;
    padding: 34px 24px 0 24px;

    @media screen and ${FROM_TABLET} {
        font-size: 22px;
        line-height: 26px;
        padding: 16px 24px 0 24px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 24px;
        line-height: 31px;
        padding: 34px 24px 0 24px;
    }
`;

export const centerContentStyles = css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-weight: normal;
    color: ${COLORS.BACK};
    font-size: 15px;
    flex: 1;
    align-content: center;
    padding-left: 16px;
    padding-right: 16px;

    ${props => props.type === TYPE_TOPIC && css`
        font-weight: 600;
    `}
`;

export const controlsContainerStyles = css`
    padding: 0 0 34px 0;

    a[type="type/ghostBright"] {
        margin-top: 16px;

        ${props => props.type === TYPE_TESTFLIGHT && css`
            letter-spacing: 0.5px;
        `}
    }

    a[type="type/secondayMedium"] {
        width: calc(100% - 100px);
    }

    
    @media screen and ${FROM_TABLET} {
        padding: 0 0 16px 0;

        ${props => props.type === TYPE_TESTFLIGHT && css`
            padding: 0 0 20px 0;
        `}
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 0 0 34px 0;
        
        ${props => props.type === TYPE_TESTFLIGHT && css`
            padding: 0 0 45px 0;
        `}
    }
`;

export const placeholderStyles = css`
    height: 12px;
    margin-bottom: 20px;
`;

export const imageStyles = css`
    display: block;
    max-width: 120px;
    max-height: 90px;
    margin: auto;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin: 0;
        }
`;

export const testFlightLearnMoreStyles = css`
    padding-bottom: 0px;

    @media screen and ${FROM_TABLET_TO_DESKTOP} {
        padding-top: 12px;
        padding-bottom: 7px;
    }
`;
