import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
    captionStyles,
    conclusionContainerStyles,
    conclusionStyles,
    sectionStyles,
} from 'components/Conclusion/styles';
import { Container, SanitizedHTML } from 'components';
import { IconLogoMark } from 'components/atomics/atoms/Icons/Icons';

const StyledSection = styled.div`
    ${sectionStyles};
`;
const StyledConclusionContainer = styled.div`
    ${conclusionContainerStyles}
`;
const StyledCaption = styled.div`
    ${captionStyles}
`;
const StyledConclusion = styled(SanitizedHTML)`
    ${conclusionStyles}
`;

const Conclusion = props => (
    Conclusion.areAttributesValid(props.data) && (
        <StyledSection>
            <Container
                size={Container.LARGE}
            >
                <StyledConclusionContainer id={`conclusion-${props.id}`}>
                    <StyledCaption>
                        {props.data.closingEyebrow}
                    </StyledCaption>
                    <StyledConclusion
                        htmlContent={props.data.closingCopy}
                        tag="div"
                    />
                    <IconLogoMark />
                </StyledConclusionContainer>
            </Container>
        </StyledSection>
    ));

Conclusion.areAttributesValid = ({ closingEyebrow, closingCopy }) => closingEyebrow && closingCopy;

Conclusion.propTypes = {
    data: PropTypes.shape({
        closingCopy: PropTypes.string,
        closingEyebrow: PropTypes.string,
    }).isRequired,
    id: PropTypes.string.isRequired,
};

export default Conclusion;