import { css } from 'styled-components';
import { outerFocusMixin } from 'components/FocusVisibility/styles';
import { FROM_TABLET, FROM_DESKTOP } from 'utils/mediaQueries';
import { COLORS, FONT_AVENIR_NEXT } from 'utils/variables';


const ERROR_COLOR = `${COLORS.ALERT_RED}`;

export const wrapperStyles = css`
    position: relative;
    width: 100%;
`;

export const textAreaStyles = ({ variant }) => css`
    border: 1px solid ${COLORS.MEDIUM_GRAY2};
    box-sizing: border-box;
    color: ${COLORS.DARK_GRAY1};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 16px;
    height: 100px;
    line-height: 1.6;
    margin-bottom: 16px;
    ${outerFocusMixin};
    overflow-y: auto;
    padding: 0 8px;
    resize: none;
    width: 100%;

    &::placeholder {
        color: ${COLORS.MEDIUM_GRAY2};
    }

    @media screen and ${FROM_TABLET} {
        font-size: 20px;
        height: 160px;

        &::placeholder {
            font-size: 20px;
        }
    }

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 10px;
    }

    /* stylelint-disable */

    ${variant.isInvalid && css`
        border-color: ${ERROR_COLOR};
    `}
    
    ${!variant.isInvalid && css`
        &:focus {
            border-color: ${COLORS.SAPPHIRE};
        }
    `}

    outline-color: red;
    outline: none !important;
    -webkit-appearance: none;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;

    /* stylelint-enable */
`;
