import {
    GET_ADVISOR_CLASSIFICATIONS,
    SET_ADVISOR_CLASSIFICATIONS,
    GET_ADVISOR_PROFILE_CLASSIFICATIONS,
} from 'state-management/constants/advisorClassifications';

export const getAdvisorClassifications = () => ({
    type: GET_ADVISOR_CLASSIFICATIONS,
});

export const getAdvisorProfileClassificationsData = {
    type: GET_ADVISOR_PROFILE_CLASSIFICATIONS,
};

export const setAdvisorClassifications = classifications => ({
    type: SET_ADVISOR_CLASSIFICATIONS,
    data: classifications,
});
