/*
* ACTIONS FOR URL_HISTORY REDUCER
* */

// updadete URL_HISTORY
export const UPDATE_URL_HISTORY = 'UPDATE_URL_HISTORY';

// load URL_HISTORY
export const LOAD_URL_HISTORY = 'LOAD_URL_HISTORY';

// delete URL_HISTORY
export const DELETE_URL_HISTORY = 'DELETE_URL_HISTORY';

// add URL_HISTORY
export const ADD_URL_HISTORY = 'ADD_URL_HISTORY';
export const RESET_URL_HISTORY = 'RESET_URL_HISTORY';
export const INCREMENT_URL_HISTORY_COUNTER = 'INCREMENT_URL_HISTORY_COUNTER';

export const removeItemFromHistory = () => {
    return {
        type: DELETE_URL_HISTORY,
    }
}

export const initDefaultUrlHistoryStore = () => {
    return {
        type: RESET_URL_HISTORY,
    }
}

export const addUrlHistoryItem = (data) => {
    return {
        type: ADD_URL_HISTORY,
        data: data,
    }
}

export const incrementUrlHistoryCounter = (data) => {
    return {
        type: INCREMENT_URL_HISTORY_COUNTER,
        data: data,
    }
}
