import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { camelCase } from 'lodash';
import moment from 'moment';

import Link from 'components/Link/Link';

import {
    footerStyles,
    linkStyles,
    footerSeparatorStyles,
} from 'containers/Article/ArticleFooter/styles';

const StyledExternalLink = styled(Link)`
    ${linkStyles}
`;

const StyledFooter = styled.ul`
    ${footerStyles}
`;

const StyledFooterSeparator = styled.span`
    ${footerSeparatorStyles}
`;

const year = moment().year();

/**
 * Article footer for lexis nexis articles.
 * @param {*} param0 
 */
const LexisNexisArticleFooter = ({ provider, intl }) => (
    <StyledFooter>
        <li>
            <StyledExternalLink to={intl.formatMessage({ id: 'articles.footer.lexisNexis.T&C.url' })}>
                {provider && <FormattedMessage id={`articles.footer.${camelCase(provider)}.T&C`} />}
            </StyledExternalLink>
            <StyledFooterSeparator>|</StyledFooterSeparator>
        </li>
        <li>
            <StyledExternalLink to={intl.formatMessage({ id: 'articles.footer.lexisNexis.privacyPolicy.url' })}>
                <FormattedMessage id="footer.title.privacy" />
            </StyledExternalLink>
            <StyledFooterSeparator>|</StyledFooterSeparator>
        </li>
        <li>
            <StyledExternalLink to={intl.formatMessage({ id: 'articles.footer.lexisNexis.copyright.url' })}>
                {provider && (
                    <FormattedMessage
                        id={`articles.footer.${camelCase(provider)}.copyright`}
                        values={{ year }}
                    />
                )}
            </StyledExternalLink>
        </li>
    </StyledFooter>
);

LexisNexisArticleFooter.propTypes = {
    provider: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
};

export default injectIntl(LexisNexisArticleFooter);
