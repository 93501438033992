import {
    DISABLE_COACH_MARKS,
    ENABLE_COACH_MARKS,
    GET_COACH_MARKS,
    GET_COACH_MARKS_SUCCESS,
    HIDE_COACH_MARK,
    DISMISS_COACH_MARK,
    DISMISS_COACH_MARK_FAILURE,
    DISMISS_COACH_MARK_SUCCESS,
    SET_COACH_MARK_AS_SAVED,
} from 'state-management/constants/coachMark';

export const getCoachMarkStatus = () => ({
    type: GET_COACH_MARKS,
});

export const getCoachMarkStatusSuccess = status => ({
    type: GET_COACH_MARKS_SUCCESS,
    data: status,
});

export const hideCoachMark = coachMarkType => ({
    type: HIDE_COACH_MARK,
    coachMarkType,
});

export const dismissCoachMark = (coachMarkType, hide = true) => ({
    coachMarkType,
    hide,
    type: DISMISS_COACH_MARK,
});

export const dismissCoachMarkFail = error => ({
    error,
    type: DISMISS_COACH_MARK_FAILURE,
});

export const dismissCoachMarkSuccess = coachMarkType => ({
    coachMarkType,
    type: DISMISS_COACH_MARK_SUCCESS,
});

export const disableCoachMarks = () => ({
    type: DISABLE_COACH_MARKS,
});

export const enableCoachMarks = () => ({
    type: ENABLE_COACH_MARKS,
});

export const setCoachMarkAsSaved = () => ({
    type: SET_COACH_MARK_AS_SAVED,
});
