import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import language from '../../../lang/en.json';
import { Button } from 'components';
import ViewAccountInformation from 'containers/Profile/AccountInformation/ViewAccountInformation/ViewAccountInformation';
import ViewMigratedAccountInformation from './ViewAccountInformation/ViewMigratedAccountInformation';
import EditProfileForm from 'containers/Profile/AccountInformation/EditProfileForm/EditProfileForm';
import {
    contentContainerStyles,
    editButtonStyles,
    headerStyles,
    styledDivElemenntStyles,
    headerSubStyles,
    headingSubStyles,
} from 'containers/Profile/AccountInformation/styles';
import Title from 'components/atomics/atoms/Title/Title';
import { AccountHeaderWrapper } from "./styles";
import {
    ANIMATIONS
} from 'utils/variables';
import storage from '../../../utils/store';
import { MIGRATION_ENUM } from '../../../state-management/constants/basicProfile';

export const StyledEditButton = styled(Button)`
    position: absolute;
    right: 0;
    bottom: 25px;
    height: 15px;
    transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;
    ${editButtonStyles};
`;

export const StyledContainerHeader = styled.div`
    ${headerStyles};
`;

export const StyledContainerSubHeader = styled.div`
    ${headerSubStyles};
`;

export const StyledSubHeading = styled.h3`
    ${headingSubStyles};
`;

export const StyledContentContainer = styled.div`
    ${contentContainerStyles};
`;

export const StyledDivElemennt = styled.div`
    ${styledDivElemenntStyles};
`;

/**
 * Account information screens for account pages.
 * @param {*} param0 
 */
const AccountInformation = ({
    formik,
    setInputRef,
    classifications,
    profile,
    onEditClick,
    isInEditMode,
    credentialUpdateFailed,
    newPasswordFailed,
    onEditDefaultClick,
}) => {
    const handleEditClick = () => {
        storage.set('profileEditClicked', true);
        
        if (!isInEditMode) {
            //onEditClick();
            onEditDefaultClick();
        }
    };
    
    // migrated users, new account view
    if (profile && profile.notification && profile.notification.data.migrationState === MIGRATION_ENUM.MIGRATED) return (
        <StyledDivElemennt>
            <ViewMigratedAccountInformation id="account-info-view" />
        </StyledDivElemennt>
    );

    // not migrated users, the old account view
    return (
        <React.Fragment>
            <StyledDivElemennt>
                <StyledContainerHeader>
                    <AccountHeaderWrapper>
                        <Title>
                            <FormattedMessage id="profilePage.accountInformation.title" />
                        </Title>
                        <StyledEditButton
                            id="edit-account-information"
                            isDisabled={isInEditMode}
                            aria-disabled={isInEditMode}
                            onClick={handleEditClick}
                            data-analytics-placement="Button : body"
                            data-analytics-label="trackLink : button"
                            data-analytics-id={language.translations["profilePage.accountInformation.edit"] + " account"}
                        >
                            <FormattedMessage id="profilePage.accountInformation.edit" />
                        </StyledEditButton>
                    </AccountHeaderWrapper>
                </StyledContainerHeader>

                <StyledContainerSubHeader>
                    <StyledSubHeading>
                        <FormattedMessage id="profilePage.accountInformation.subTitle" />
                    </StyledSubHeading>
                </StyledContainerSubHeader>
                <StyledContentContainer>
                    {
                        isInEditMode
                            ? (
                                <EditProfileForm
                                    credentialUpdateFailed={credentialUpdateFailed}
                                    newPasswordFailed={newPasswordFailed}
                                    id="account-info-edit"
                                    formik={formik}
                                    setInputRef={setInputRef}
                                    classifications={classifications}
                                    formPage="true"
                                    onEditDefaultClick={onEditDefaultClick}
                                    onEditClick={onEditClick}
                                />
                            )
                            : <ViewAccountInformation id="account-info-view" />
                    }
                </StyledContentContainer>
            </StyledDivElemennt>
        </React.Fragment>
    );
};

AccountInformation.propTypes = {
    formik: PropTypes.shape({}).isRequired,
    classifications: PropTypes.shape({}).isRequired,
    profile: PropTypes.shape({}).isRequired,
    setInputRef: PropTypes.func.isRequired,
    onEditClick: PropTypes.func.isRequired,
    isInEditMode: PropTypes.bool.isRequired,
    credentialUpdateFailed: PropTypes.bool,
    newPasswordFailed: PropTypes.bool,
};

AccountInformation.defaultProps = {
    credentialUpdateFailed: false,
    newPasswordFailed: false,
}

const mapStateToProps = state => ({
    profile: state.basicProfile,
});

export default connect(mapStateToProps)(AccountInformation);
