import { css } from 'styled-components';
import { outerCustomFocusMixin } from 'components/FocusVisibility/styles';
import { visuallyHiddenStyles } from 'components/VisuallyHidden/styles';
import { FROM_DESKTOP, FROM_TABLET, FROM_MOBILE_TO_TABLET } from 'utils/mediaQueries';
import {
    COLORS,
} from 'utils/variables';

export const sourceStyles = ({ variant }) => css`
    align-items: center;
    border-bottom: 1px solid ${COLORS.LIGHT_GRAY2};
    box-sizing: border-box;
    display: flex;
    font-size: 15px;
    color: ${COLORS.BLACK};
    line-height: 20px;
    font-weight: 600;
    letter-spacing: -0.1px;
    padding: 16px 0;
    position: relative;
    width: 100%;

    /* stylelint-disable */

    ${variant.checked && css`
    `}

    ${!variant.checked && css`
        color: ${COLORS.BLACK};
    `}

    /* stylelint-enable */

    @media screen and ${FROM_TABLET} {
        font-size: 10px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: normal;
        padding: 0 0 10px 10px;
        width: 33.33%;
        color: ${COLORS.DARK_GRAY1};

        ${props => props.noUnderline && props.noUnderline === true && css`
            border-bottom: 0;
        `};

        &::after {
            content: '';
            display: block;
            padding-bottom: 100%;
        }

        /* stylelint-disable */

        ${!variant.checked && css`
        `}

        ${variant.checked && css`
        `}

        /* stylelint-enable */
    }

    @media screen and ${FROM_DESKTOP} {
        width: 25%;
    }
`;

export const sourceContentStyles = ({ variant }) => css`
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    width: 100%;

    /* stylelint-disable */

    ${variant.isFocused && css`
        ${outerCustomFocusMixin};
    `};

    /* stylelint-enable */

    @media screen and ${FROM_TABLET} {
        background-color: ${COLORS.WHITE};
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        padding: 10px;

        &::before {
            content: '';
            display: flex;
            flex-grow: 1;
            flex-shrink: 1;
            width: 100%;
        }

        /* stylelint-disable */
        border: 1px solid ${COLORS.LIGHT_GRAY2};

        ${variant.checked && css`
            border: 2px solid ${COLORS.SAPPHIRE};
        `}

        /* stylelint-enable */
    }
`;

export const sourceImageStyles = css`
    display: none;

    @media screen and ${FROM_TABLET} {
        display: flex;
        flex-shrink: 0;
        height: 100%;
        max-height: 35px;
        max-width: 79px;
        overflow: hidden;
        position: relative;
        width: 100%;
    }

    @media screen and ${FROM_DESKTOP} {
        max-height: 42px;
        max-width: 96px;
    }

    img {
        left: 50%;
        max-height: 100%;
        max-width: 100%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }
`;

export const sourceInputStyles = css`
    ${visuallyHiddenStyles}
`;

export const sourceTitleStyles = css`
    box-sizing: border-box;
    padding-right: 20px;
    word-wrap: break-word;

    @media screen and ${FROM_MOBILE_TO_TABLET} {
        flex: 1 1 auto;
        min-width: 1px;
    }

    @media screen and ${FROM_TABLET} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 12px;
        min-height: 36px;
        padding: 0 10px;
        text-align: center;
        width: 100%;
    }
`;

export const indicatorStyles = css`
    align-items: center;
    border: 2px solid ${COLORS.MEDIUM_GRAY2};
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    height: 24px;
    justify-content: center;
    width: 24px;

    @media screen and ${FROM_TABLET} {
        display: none;
    }

    /* stylelint-disable */

    ${props => props.selected && css`
    border: 2px solid ${COLORS.SAPPHIRE};
    `};

    /* stylelint-enable */
`;

export const checkIconStyles = css`
    fill: ${COLORS.SAPPHIRE};
    height: 14px;
    width: 14px;
`;

export const plusIconStyles = ParentComponent => css`
    color: transparent;

    /* stylelint-disable */

    ${ParentComponent}:hover & {
        color: ${COLORS.SAPPHIRE};
    }

    /* stylelint-enable */
`;
