import { css } from 'styled-components';
import { getSpanInPercent } from 'utils/grid';
import { FROM_DESKTOP, FROM_TABLET, FROM_MOBILE_TO_TABLET } from 'utils/mediaQueries';
import {
    FONT_AVENIR_NEXT,
    COLORS,
    ANIMATIONS,
} from 'utils/variables';

export const formContentContainerStyles = css`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    @media screen and ${FROM_DESKTOP} {
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const headingContainerStyles = css`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-wrap: wrap;
    margin-bottom: 28px;
    position: relative;
    margin-left: 24px;
    padding-top: 92px;

    @media screen and ${FROM_TABLET} {
        margin-bottom: 40px;
        padding-top: 92px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: auto;
        width: ${getSpanInPercent(6)};
        padding-top: 0;
    }
`;

export const headingStyles = css`
    color: ${COLORS.SAPPHIRE};
    font-family: ${FONT_AVENIR_NEXT};
    letter-spacing: -0.85px;
    font-size: 26px;
    line-height: 32px;
    font-weight: 400;
    position: relative;
    text-align: left;
    word-wrap: break-word;

    @media screen and ${FROM_DESKTOP} {
        font-size: 42px;
        line-height: 52px;
        letter-spacing: 0;
    }
`;

export const formFieldsContainerStyles = css`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin-left: 24px;
    margin-right: 24px;

    @media screen and ${FROM_TABLET} {
        width: ${getSpanInPercent(6)};
        margin: auto;
        padding-top: 50px;
        min-width: 390px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin: initial;
        padding-top: 0;
        min-width: initial;
    }
`;

export const fieldsWrapperStyles = css`
    display: flex;
    flex-direction: column;
`;

export const fieldWrapperStyles = css`
    margin-bottom: 12px;
    width: 100%;

    input {
        margin-bottom: 10px;
    }

    @media screen and ${FROM_TABLET} {
        margin-bottom: 18px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 25px;
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

export const remindControlsStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 14px;
    font-weight: 500;
    margin-top: 20px;

    &:last-child {
        margin-bottom: 50px;
    }

    @media screen and ${FROM_TABLET} {
        margin-top: 25px;
    }
`;

export const actionsContainerStyles = css`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;
    }

    @media screen and ${FROM_TABLET} {
      }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active), ${FROM_TABLET} {
        display: flex;
    }

    @media screen and ${FROM_DESKTOP} {
        flex-direction: row;
        justify-content: space-between;

        &>div:first-child {
            flex: 1;
        }
    }
`;

export const descriptionContainerStyles = css`
    color: ${COLORS.DARK_GRAY1};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.13px;
    margin: 24px 0 0;

    @media screen and ${FROM_TABLET} {
        margin: 18px 0 0;
        font-size: 18px;
        line-height: 22px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin: 20px 0 0;
    }
`;

export const keepSignedInStyles = css`
    margin: 0;
    text-align: center;
    width: 100%;

    label {
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.1px;
        color: ${COLORS.BLACK};
    }

    @media screen and ${FROM_DESKTOP} {
        margin: 0 0 0 0;
        text-align: left;
    }
`;

export const controlsStyles = css`
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    margin-top: 29px;
    text-align: center;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        min-height: 200px;
        display: block;
        max-width: none;
        align-items: center;
    }

    @media screen and ${FROM_TABLET} {
        flex: 1 0 100%;
        margin: auto;
        margin-top: 29px;
        max-width: ${getSpanInPercent(4)};
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        max-width: none;
    }

    @media screen and ${FROM_DESKTOP} {
        align-items: normal;
        margin: intial;
        margin-top: 29px;
        max-width: ${getSpanInPercent(6)};
        display: flex;
        text-align: left;
        align-items: flex-start;
    }
`;

export const buttonStyles = css`
    margin: 0 0 14px;
    min-width: 120px;
    background-color: ${COLORS.OCEAN};
    color: ${COLORS.WHITE};
    font-size: 19px;
    font-weight: 700;
    letter-spacing: 0;
    padding: 17px 48px;
    text-transform: capitalize;
    border: none;
    height: 60px;
    transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;

    @media screen and ${FROM_MOBILE_TO_TABLET} {
        width: 100%;
      }

    ${props => props.isDisabled === 'false' && css`
        &:hover {
            background-color: ${COLORS.SAPPHIRE} !important;
        }
    `};

    ${props => props.isDisabled === 'true' && css`
        background-color:  ${COLORS.LIGHT_GRAY3} !important;
    `};

    &:hover{
        background-color: ${COLORS.SAPPHIRE} ;
    }
`;

export const alertStyles = css`
    color: ${COLORS.SAPPHIRE};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 15px;
    font-weight: normal;
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 24px;
    outline: 0;
    word-wrap: break-word;

    button {
        padding: 0;
        color: ${COLORS.SAPPHIRE};
        font-family: ${FONT_AVENIR_NEXT};
        font-size: 15px;
        font-weight: normal;
        line-height: 16px;
    }

    /* stylelint-disable */

    ${props => props.error && css`
        color: ${COLORS.ALERT_RED};

        a {
            color: ${COLORS.ALERT_RED};
        }
    `}

    /* stylelint-enable */
`;

export const underlinedTextStyles = css`
    color: ${COLORS.DARK_GRAY1};
    cursor: pointer;
    font-size: inherit;
    font-weight: 500;
    padding: 0;
    text-decoration: underline;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: -0.07px;
    transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;

    @media screen and ${FROM_TABLET} {
        font-size: 15px;
        line-height: 22px;
        letter-spacing: -0.08px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.1px;
    }
`;
