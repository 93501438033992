import {
    RESET_FORGOT_PASSWORD_EMAIL,
    SEND_FORGOT_PASSWORD_EMAIL_ERROR,
    SEND_FORGOT_PASSWORD_EMAIL_SUCCESS,
    CHANGE_PASSWORD_ERROR,
    CHANGE_PASSWORD_SUCCESS,
    VERIFY_PASSWORD_TOKEN,
    VERIFY_PASSWORD_TOKEN_ERROR,
    VERIFY_PASSWORD_TOKEN_SUCCESS,
    CURRENT_PASSWORD,
} from 'state-management/constants/forgotPassword';

const initialState = {
    isLoading: false,
    error: '',
    changePasswordError: {},
    changePasswordSuccess: false,
    forgotPasswordEmail: null,
    forgotPasswordError: null,
};

function forgotPasswordReducer(state = initialState, action = {}) {
    switch (action.type) {
    case VERIFY_PASSWORD_TOKEN:
        return {
            ...state,
            isLoading: true,
        };
    case VERIFY_PASSWORD_TOKEN_ERROR:
        return {
            isLoading: false,
            error: action.error,
        };
    case VERIFY_PASSWORD_TOKEN_SUCCESS:
        return {
            ...state,
            isLoading: false,
        };
    case RESET_FORGOT_PASSWORD_EMAIL:
        return {
            ...state,
            forgotPasswordEmail: null,
            forgotPasswordError: null,
        };
    case SEND_FORGOT_PASSWORD_EMAIL_SUCCESS:
        return {
            ...state,
            forgotPasswordEmail: action.email,
        };
    case SEND_FORGOT_PASSWORD_EMAIL_ERROR:
        return {
            ...state,
            forgotPasswordError: action.error,
        };
    case CHANGE_PASSWORD_ERROR:
        return {
            ...state,
            isLoading: false,
            changePasswordError: action.error,
        };
    case CHANGE_PASSWORD_SUCCESS:
        return {
            ...state,
            isLoading: false,
            changePasswordSuccess: true,
        };
    case  CURRENT_PASSWORD:
        return{
            ...state            
        }
    default:
        return state;
    }
}

export default forgotPasswordReducer;
