import {
    all, call, put, takeLatest, select,
} from 'redux-saga/effects';

import { setError } from 'state-management/actions/errors';
import {
    SAVED_CONTENT_DATA_URL,
    GET_SAVED_CONTENT_DATA,
} from 'state-management/constants/savedContent';
import { dismissExpiringBookmarksNotification } from 'state-management/actions/basicProfile';
import { DISMISS_EXPIRING_BOOKMARKS_DETAIL_BADGE_STORAGE_KEY } from 'state-management/constants/basicProfile';
import { setSavedContentData, getSavedContentFail } from 'state-management/actions/savedContent';
import Api from 'utils/api';
import { setScrollToTop } from 'state-management/actions/scroll';

/**
 * Get users saved content.
 * @param {*} param0
 */
function* getSavedContentData({ page, shouldScrollToTop }) {
    try {
        const savedContentData = yield call(Api.get, SAVED_CONTENT_DATA_URL, {
            params: {
                page: page ? page - 1 : page,
            },
        });
        yield put(setSavedContentData(savedContentData.data));

        // dismiss expiring notifications for articles loaded
        const notification = yield select(state => state.basicProfile.notification.data);
        const { expiringBookmarks } = notification;
        if (savedContentData.data.content && savedContentData.data.content.length > 0) {
            const dismissableExpiringBookmarks = savedContentData.data.content
                .filter((item) => item.type === 'ARTICLE' && expiringBookmarks.indexOf(item.id) >= 0)
                .map((item) => item.id);
            if (dismissableExpiringBookmarks.length > 0) {
                yield put(dismissExpiringBookmarksNotification(DISMISS_EXPIRING_BOOKMARKS_DETAIL_BADGE_STORAGE_KEY, dismissableExpiringBookmarks))
            }
        }
    
        if (shouldScrollToTop) {
            yield put(setScrollToTop());
        }
    } catch (e) {
        if (shouldScrollToTop) {
            yield put(setScrollToTop());
        }
        yield put(getSavedContentFail);
        yield put(setError('saves.server.error', e));
    }
}

function* savedContentSaga() {
    yield all([
        yield takeLatest(GET_SAVED_CONTENT_DATA, getSavedContentData),
    ]);
}

export default savedContentSaga;
