import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { debounce } from 'lodash';
import anime from 'animejs';
import Image from 'components/atomics/atoms/Image/Image';
import {
    containerStyles,
    HORIZONTAL_FIT,
    VERTICAL_FIT,
    filterBlockStyles,
} from 'components/CoverImage/styles';
import language from '../../lang/en.json';

const StyledContainer = styled.span`
    ${containerStyles};
`;

const FilterOverlay = styled.span`
    ${filterBlockStyles};
`;

const objectFitSupported = 'objectFit' in document.documentElement.style !== false;

/**
 * Used to create an image with colored overlay in guide pages.
 */
class CoverImage extends React.Component {
    /**
     * Default constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            fit: HORIZONTAL_FIT,
            imageHeight: 0,
            imageWidth: 0,
        };

        this.containerRef = React.createRef();

        this.animateImage = this.animateImage.bind(this);
        this.handleImageAnimation = this.handleImageAnimation.bind(this);
        this.handleLoadSuccess = this.handleLoadSuccess.bind(this);
        this.setFitPosition = this.setFitPosition.bind(this);
        this.debouncedFitSet = debounce(this.setFitPosition, 50);
    }

    /**
     * Set a window resize listener for layout changes, at mount.
     */
    componentDidMount() {
        if (!objectFitSupported) {
            window.addEventListener('resize', this.debouncedFitSet);

            this.clearTimer();
        }
    }

    /**
     * Remove resize listener at destruction.
     */
    componentWillUnmount() {
        if (!objectFitSupported) {
            window.removeEventListener('resize', this.debouncedFitSet);
        }
    }

    /**
     * Adjust image fit styling based on breakpoint.
     * @param {*} callback 
     */
    setFitPosition(callback) {
        const { imageHeight, imageWidth } = this.state;
        const containerNode = this.containerRef.current;

        if (objectFitSupported || !imageHeight || !imageWidth || !containerNode) {
            return;
        }

        const imageScale = imageHeight / imageWidth;
        const containerHeight = containerNode.clientHeight;
        const containerWidth = containerNode.clientWidth;

        if (containerHeight === 0 && containerWidth === 0) {
            this.clearTimer();
            this.visibilityTimer = setTimeout(this.setFitPosition, 500);
        }

        const containerScale = containerHeight / containerWidth;
        const fit = containerScale <= imageScale ? HORIZONTAL_FIT : VERTICAL_FIT;

        this.setState({
            fit,
        }, () => {
            if (typeof callback === 'function') {
                callback();
            }
        });
    }

    /**
     * Clear visibility timeout.
     */
    clearTimer() {
        if (this.visibilityTimer) {
            clearTimeout(this.visibilityTimer);
        }
    }

    /**
     * Handle successful image load and save the image height and width, then used in resizing response.
     * @param {*} imageNode 
     */
    handleLoadSuccess(imageNode) {
        this.setState({
            imageHeight: imageNode.naturalHeight,
            imageWidth: imageNode.naturalWidth,
        }, () => {
            this.setFitPosition(this.animateImage);
        });
    }

    /**
     * Animate the image into view.
     */
    animateImage() {
        anime({
            targets: this.node,
            easing: [0.99, 0.03, 0.47, 0.95],
            translateX: !objectFitSupported && this.state.fit === VERTICAL_FIT ? ['-50%', '-50%'] : [0, 0],
            translateY: !objectFitSupported && this.state.fit === HORIZONTAL_FIT ? ['-48%', '-50%'] : [10, 0],
            duration: 660,
            offset: 1000,
            complete: (anim) => {
                if (anim.animatables && anim.animatables.length) {
                    anim.animatables[0].target.removeAttribute('style');
                }
            },
        });
    }

    /**
     * Call image animation if browser supported.
     * @param {*} node 
     */
    handleImageAnimation(node) {
        this.node = node;

        if (objectFitSupported) {
            this.animateImage();
        }
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const { fit } = this.state;

        if (!this.props.src) {
            return null;
        }

        const src = this.props.src.indexOf('/') === 0
            ? this.props.src
            : this.props.src;

        return (
            <StyledContainer
                ref={this.containerRef}
                key={src}
                fit={fit}
                objectFitSupported={objectFitSupported}
                forLinkCard={this.props.forLinkCard}
                forGuidePage={this.props.forGuidePage}
            >
                {this.props.filterId &&
                    <div>
                        <FilterOverlay />
                        <Image
                            src={src}
                            alt={language.translations['global.alt.image']}
                        />
                    </div>
                }
                {this.props.filterId === '' &&
                    <Image
                        src={src}
                        alt={language.translations['global.alt.image']}
                    />}
            </StyledContainer>
        );
    }
}

CoverImage.propTypes = {
    alt: PropTypes.string,
    className: PropTypes.string,
    src: PropTypes.string,
    isExternal: PropTypes.bool,
    onLoadError: PropTypes.func,
    forLinkCard: PropTypes.bool,
    filterId: PropTypes.string,
    forGuidePage: PropTypes.bool,
};

CoverImage.defaultProps = {
    alt: '',
    className: '',
    src: '',
    isExternal: false,
    forLinkCard: false,
    onLoadError: () => { },
    filterId: '',
    forGuidePage: true,
};

export default CoverImage;
