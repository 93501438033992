import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';

import DEFAULT_TRANSLATIONS from 'app/lang/en.json';

/**
 * Handles providing localized string resource to wrapped component.
 */
class I18nProvider extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
    };

    state = DEFAULT_TRANSLATIONS;

    /**
     * Render this and underlying component.
     */
    render() {
        return (
            <IntlProvider
                locale={this.state.locale}
                messages={this.state.translations}
                formats={this.state.formats}
                textComponent={React.Fragment}
            >
                {this.props.children}
            </IntlProvider>
        );
    }
}

export default I18nProvider;
