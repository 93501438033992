import { css } from 'styled-components';
import {
    COLORS,
    FONT_AVENIR_NEXT,
    ANIMATIONS,
} from '../../../../utils/variables';
import {
    FROM_DESKTOP,
    FROM_TABLET,
    SMALL_MOBILE
} from '../../../../utils/mediaQueries';

export const containerStyles = css`   
    background-color: ${COLORS.WHITE};
    border: 1px solid ${COLORS.LIGHT_GRAY2};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0;

    a {
        min-width: 229px;
        margin: 12px !important;
        letter-spacing: 0.6px;
    }

    @media screen and ${SMALL_MOBILE} {
        width: 344px;
        height: 353px;
    }
    @media screen and ${FROM_TABLET} {
        width: 558px;
        height: 344px;
    }
    @media screen and ${FROM_DESKTOP} {
        width: 537px;
        height: 337px;
    }
`;

export const titleStyles = css`
    font-family: ${FONT_AVENIR_NEXT};   
    color: ${COLORS.BLACK};
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 33px;
    letter-spacing: normal; 
    text-align: center;     
    margin: 5px; 

    @media screen and ${SMALL_MOBILE} {
        padding-bottom: 15px;
    }

    @media screen and ${FROM_TABLET} {
        padding-bottom: 15px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding-bottom: 0;
    }

`;

export const descriptionStyles = css`
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.12px;
    text-align: center;
    color: ${COLORS.BLACK};   

    @media screen and ${SMALL_MOBILE} {
        width: 253px;
        height: 75px;
    }

    @media screen and ${FROM_TABLET} {
        width: 302px;
        height: 75px;
    }

    @media screen and ${FROM_DESKTOP} {
        width:312px;   
        margin:12px;
    }
`;

export const defaultLinkStyles = css`
   
`;
