import * as type from 'state-management/constants/news';

export const getNews = () => ({
    type: type.GET_NEWS,
});

export const resetNews = () => ({
    type: type.RESET_NEWS,
});

export const getTopNewsSuccess = (data, seeMore) => ({
    type: type.GET_TOP_NEWS_SUCCESS,
    data,
    seeMore,
});

export const getTopNewsItems = () => ({
    type: type.GET_TOP_NEWS_ITEMS,
});

export const getTopNewsItemsFail = error => ({
    type: type.GET_TOP_NEWS_ITEMS_FAIL,
    error,
});

export const getTopNewsItemsSuccess = data => ({
    type: type.GET_TOP_NEWS_ITEMS_SUCCESS,
    data,
});



export const setServiceOfferingId = serviceOfferingId => ({
    type: type.SET_SERVICE_OFFERING_ID,
    serviceOfferingId,
});

export const setRandomServiceOffering = advisorServiceOfferingIds => ({
    type: type.SET_RANDOM_SERVICE_OFFERING,
    advisorServiceOfferingIds,
});

export const getTopNewsFailure = error => ({
    type: type.GET_TOP_NEWS_FAIL,
    error,
});

export const getMarketNewsSuccess = (data, seeMore) => ({
    type: type.GET_MARKET_NEWS_SUCCESS,
    data,
    seeMore,
});

export const getMarketNewsFailure = error => ({
    type: type.GET_MARKET_NEWS_FAIL,
    error,
});

export const getPageableNews = (
    section, excludedArticleIds, trendingTopic, basicProfile,
) => ({
    type: type.GET_PAGEABLE_NEWS,
    section,
    excludedArticleIds,
    trendingTopic,
    basicProfile,
});

export const getPageableNewsSuccess = data => ({
    type: type.GET_PAGEABLE_NEWS_SUCCESS,
    data,
});

export const getPageableNewsFailure = error => ({
    type: type.GET_PAGEABLE_NEWS_FAIL,
    error,
});

export const getLatestCapitalIdeasSuccess = (data, seeMore) => ({
    type: type.GET_LATEST_CAPITAL_IDEAS_SUCCESS,
    data,
    seeMore,
});

export const getLatestCapitalIdeasFailure = error => ({
    type: type.GET_LATEST_CAPITAL_IDEAS_FAIL,
    error,
});
