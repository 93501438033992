import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ArticleHeaderContainer from 'containers/Article/ArticleHeader/ArticleHeader';
import ArticleContent from 'containers/Article/ArticleContent/ArticleContent';
import ArticleFooter from 'containers/Article/ArticleFooter/ArticleFooter';
import ArticleTopics from 'containers/Article/ArticleTopics/ArticleTopics';
import { getArticle } from 'state-management/actions/articles.common';
import { setScrollToTop } from 'state-management/actions/scroll';
import { Container } from 'components';
import {
    ArticleBodyStyles,
    ArticleStyles,
    ArticleWrapperInnerStyles,
    ArticleWrapperStyles,
} from 'containers/Article/styles';
import Loader from 'components/Loader/Loader';
import { getCoachMarkStatus } from 'state-management/actions/coachMark';

import labels from '../../lang/en.json';
import { StyleBackToTopButton } from 'components/MarketBriefing/page/MarketBriefingPage';
import {
    IconArrowDown,
} from 'components/atomics/atoms/Icons/Icons';

const StyledArticle = styled.div`
    ${ArticleStyles}
`;

const StyledArticleWrapper = styled.div`
    ${ArticleWrapperStyles}
`;

const StyledArticleWrapperInner = styled(Container)`
    ${ArticleWrapperInnerStyles}
`;

const StyledArticleBody = styled.div`
    ${ArticleBodyStyles}
`;

/**
 * Licensed article display page.
 */
class Article extends React.Component {

    constructor(props) {
        super(props);

        this.state = {          
            showBackToTop: false,
        };
        this.handleWindowScroll = this.handleWindowScroll.bind(this);
        this.backToTop = this.backToTop.bind(this);
    }
    
    /**
     * Get the article content, coach mark needs and scroll page to top.
     */
    componentDidMount() {
        this.props.getArticle(this.props.match.params.id);
        this.props.getCoachMarkStatus();
        this.props.scrollToTop();
        window.addEventListener('scroll', this.handleWindowScroll);
    }

    /**
     * Render the back to top sticky button.
     */
    renderBackToTop() {
        return (
            <StyleBackToTopButton
                aria-label={labels.translations['marketBriefing.page.scrollToTop']}
                type="button"
                id="backToTopLink"
                onClick={this.backToTop}
                data-analytics-placement="Button : body"
                data-analytics-label="trackLink : button"
                data-analytics-id="backtotop:News Page"
            >
                <IconArrowDown />
            </StyleBackToTopButton>
        );
    }

    /**
     * Track window scrolling for back to top button usage.
     */
    handleWindowScroll() {
        const scrollTop = document.body.scrollTop
            ? document.body.scrollTop : document.documentElement.scrollTop;
        if (scrollTop > 0) {
            this.setState({
                showBackToTop: true,
            });
        } else {
            this.setState({
                showBackToTop: false,
            });
        }
    }

    /**
     * Scroll the user back to the top of page.
     */
    backToTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behaviour: 'smooth'
        });
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const { article, isLoading } = this.props;
        const { id } = this.props.match.params;

        const articleReform = {
            trueId: this.props.match.params.id,
            id: this.props.match.params.id,
            title: article.title,
            saved: article.saved,
            source: article.source,
            provider: article.provider,
            publishDate: article.publishDate,
            interactionId: this.props.match.params.interactionId,
            contentType: this.props.match.params.contentType,
            interactionType: this.props.match.params.interactionType,
        }

        if (isLoading) {
            return <Loader />;
        }

        return (
            <StyledArticle>
                <ArticleHeaderContainer article={articleReform} articleId={id} />
                <StyledArticleWrapper>
                    <StyledArticleWrapperInner size={Container.SMALL}>
                        <StyledArticleBody>
                            <ArticleContent {...article} />
                            <ArticleFooter provider={article.provider} />
                            <ArticleTopics
                                onTopicSelect={()=>{}}
                                topics={article.topics}
                            />
                        </StyledArticleBody>
                    </StyledArticleWrapperInner>
                </StyledArticleWrapper>
                {this.state.showBackToTop
                    && this.renderBackToTop()}
            </StyledArticle>
        );
    }
}

Article.propTypes = {
    getArticle: PropTypes.func.isRequired,
    article: PropTypes.shape({
        saved: PropTypes.bool,
        authors: PropTypes.string,
        image: PropTypes.shape({}),
        source: PropTypes.shape({
            imageName: PropTypes.string,
            name: PropTypes.string,
        }),
        content: PropTypes.string,
        provider: PropTypes.string,
        title: PropTypes.string,
        sourceId: PropTypes.sting,
        publishDate: PropTypes.string,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
            interactionId: PropTypes.string,
            contentType: PropTypes.string,
            interactionType: PropTypes.string,
        }),
    }).isRequired,
    scrollToTop: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    article: state.articles.article,
    isLoading: state.articles.isLoading,
});

const mapDispatchToProps = dispatch => ({
    getArticle: id => dispatch(getArticle(id)),
    getCoachMarkStatus: () => dispatch(getCoachMarkStatus()),
    scrollToTop: () => dispatch(setScrollToTop()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Article);
