import {
    CREATE_SOURCE_REQUEST_SUCCESS,
    CREATE_SOURCE_REQUEST_ERROR,
    SET_SOURCE_NAME,
    RESET_REQUEST_SOURCE,
} from 'state-management/constants/requestSource';

const initialState = {
    sourceName: '',
    isRequested: false,
    error: '',
};

function requestSourceReducer(state = initialState, action = {}) {
    switch (action.type) {
    case SET_SOURCE_NAME:
        return {
            ...state,
            sourceName: action.sourceName,
        };
    case CREATE_SOURCE_REQUEST_SUCCESS:
        return {
            ...state,
            isRequested: true,
        };
    case CREATE_SOURCE_REQUEST_ERROR:
        return {
            ...state,
            error: action.error,
        };
    case RESET_REQUEST_SOURCE:
        return {
            ...initialState,
        };
    default:
        return state;
    }
}

export default requestSourceReducer;
