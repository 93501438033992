import { css } from 'styled-components';
import {
    COLORS,
    FONTS,
} from 'utils/variables';
import {
    FROM_DESKTOP,
    FROM_TABLET,SMALL_MOBILE,
} from '../../../../utils/mediaQueries';

export const containerStyles = css`
    background-color: ${COLORS.WHITE};
    padding-bottom: 32px;
    border-radius: 0 0 6px 6px;
    border: none;
    overflow: hidden;
    position: relative;
    text-align: center;

    @media screen and ${FROM_TABLET} {
        paddin-bottom: 45px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding-bottom: 45px;
    }

`;

export const colorBarStyles = css`
    width: 100%;
    height: 8px;
    overflow: hidden;
    background-image: linear-gradient(89deg, ${COLORS.SAPPHIRE}, ${COLORS.OCEAN});
`;

export const titleStyles = css`
    color: ${COLORS.SAPPHIRE};
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-weight: 400;
    font-size: 26px;
    line-height: 26px;
    letter-spacing: -0.08px;
    padding: 0;
    margin-bottom: 0;
    margin-top: 45px;
    white-space: normal;
    margin-left: 16px;
    margin-right: 16px;

    @media screen and  ${SMALL_MOBILE} {
        font-size: 26px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.19;
        letter-spacing: -0.11px;
        white-space: normal;
    }

    @media screen and ${FROM_TABLET} {
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.19;
        letter-spacing: -0.11px;
        margin-left: auto;
        margin-right: auto;
        white-space: pre;
    }

    @media screen and ${FROM_DESKTOP} {
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.2;
        letter-spacing: normal;
        padding: 0 150px 0 150px;
        margin-bottom: 0;
        margin-top: 45px;
    }
`;

export const defaultTxtStyles = css`
    padding: 0 20px;
    font-size: 18px;
    color: ${COLORS.BLACK};;
    margin-bottom: 25px;
`;
