import { css } from 'styled-components';

import { getSpanInPixels } from 'utils/grid';
import { FROM_TABLET, FROM_DESKTOP } from 'utils/mediaQueries';
import { COLORS } from 'utils/variables';

export const containerStyles = css`
    box-sizing: border-box;
    margin-bottom: 30px;
    margin-top: 30px;
    width: 100%;
    word-wrap: break-word;

    h2 {
        /*border-bottom: 2px solid #ddd;*/
        color: ${COLORS.BLACK};
        font-size: 20px;
        font-weight: 600;
        line-height: 1.3;
        margin: 0 0 25px 0;
        padding: 30px 0 10px 0;
        font-family: AvenirNext;



        @media screen and ${FROM_TABLET} {
            font-size: 22px;
        }

        @media screen and ${FROM_DESKTOP} {
            font-size: 26px;
        }

    }

    h3 {
        font-weight: 600;
        margin: 20px 0 12px;
    }

    b,
    p,
    ul,
    h3 {
        color: ${COLORS.DARK_GRAY2};
        font-size: 14px;
        line-height: 1.5;
    }

    b {
        font-weight: 500;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        padding: 0 0 8px 16px;
        position: relative;

        &::before {
            background-color: ${COLORS.DARK_GRAY2};
            border-radius: 50%;
            content: '';
            display: block;
            height: 5px;
            left: 0;
            position: absolute;
            top: 8px;
            width: 5px;
        }
    }

    @media screen and ${FROM_TABLET} {
        margin-bottom: 50px;
        margin-top: 50px;
        max-width: ${getSpanInPixels(8)};

        h2 {
            font-size: 22px;
            font-weight: 600;
            line-height: 1.61;
        }

    @media screen and ${FROM_DESKTOP} {

        h2{
            font-size: 26px;
        }

    }

        b,
        p,
        ul,
        h3 {
            font-size: 16px;
            line-height: 1.33;
            padding: 0;
        }

        li {
            padding: 0 0 8px 28px ;
            position: relative;

            &::before {
                height: 8px;
                width: 8px;
            }
        }
    }

    @media screen and ${FROM_DESKTOP} {
        b,
        p,
        ul,
        h3 { {
            font-size: 18px;
        }
    }

`;

export default containerStyles;
