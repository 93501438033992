import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withBaseLayout, withPublicLayout } from 'components';
import { ROUTES } from 'containers/App/constants';
import withPrivateLayout from 'containers/PrivateLayout/PrivateLayout';

/**
 * Handles deciding if a page should be displayed in authenticated or public wrapping.
 * @param {*} Component 
 * @param {*} componentProps 
 */
export default function LayoutSwitch(Component, componentProps = {}) {
    const ComponentWithPrivateLayout = withPrivateLayout(Component);
    const ComponentWithPublicLayout = withPublicLayout(Component);
    const ComponentWithBaseLayout = withBaseLayout(Component, ROUTES.ONBOARDING);

    const AuthenticationCheck = (props) => {
        const { isAuthenticated, isOnboarded, isWebinarUser } = props;

        if (!isAuthenticated) {
            return <ComponentWithPublicLayout {...props} {...componentProps} />;
        }

        return (isOnboarded || isWebinarUser === "WEBINAR")
            ? <ComponentWithPrivateLayout {...props} {...componentProps} />
            : <ComponentWithBaseLayout {...props} {...componentProps} />;
    };

    AuthenticationCheck.propTypes = {
        isAuthenticated: PropTypes.bool,
        isOnboarded: PropTypes.bool,
    };

    AuthenticationCheck.defaultProps = {
        isAuthenticated: false,
        isOnboarded: false,
    };

    const mapStateToProps = state => ({
        isAuthenticated: state.signIn.isAuthenticated,
        isOnboarded: state.basicProfile.onboarded,
        isWebinarUser: state.basicProfile.signUpType,
    });

    return connect(mapStateToProps, null)(AuthenticationCheck);
}
