import {
    all, call, put, select, takeLatest,
} from 'redux-saga/effects';

import {
    GET_PROFILE_REFINEMENT_CARD,
    NOT_INTERESTED_IN,
    REFINEMENT_CARD_URL,
    INCREMENET_SESSION_COUNT,
    REFINEMENT_SESSION_COUNT_URL,
} from 'state-management/constants/profileRefinementCard';
import { getProfileRefinementCardSuccess } from './../../state-management/actions/profileRefinementCard';
import Api from 'utils/api';
import { fetchSources } from 'state-management/sagas/sources';
import { fetchTopics } from 'state-management/sagas/topics';

export const getSources = state => state.sources.sources;
export const getTopics = state => state.topics.all.categories;

/**
 * Get a new profile refinement card from BE.
 */
function* getProfileRefinementCard() {
    try {
        const response = yield call(Api.get, REFINEMENT_CARD_URL);

        const sources = yield select(getSources);
        const topics = yield select(getTopics);

        // in case sources are not loaded yet (e.g. page refresh)
        if (sources.length === 0) {
            yield call(fetchSources);
        }

        // in case sources are not loaded yet (e.g. page refresh)
        if (topics.length === 0) {
            yield call(fetchTopics);
        }

        if (response.data === null) {
            yield put(getProfileRefinementCardSuccess({ hasSuggestion: false }));
        } else {
            response.data.hasSuggestion = true;
            yield put(getProfileRefinementCardSuccess(response.data));
        }
    } catch (e) {

    }
}

/**
 * Handle user not interested in profile card action.
 */
function* notInterestedIn({ data }) {
    const { type, id } = data;
    try {
        const response = yield call(Api.post, REFINEMENT_CARD_URL, {
            data: {
                type,
                id,
            },
        });

        if (response.data === null) {
            yield put(getProfileRefinementCardSuccess({ hasSuggestion: false }));
        } else {
            response.data.hasSuggestion = true;
            yield put(getProfileRefinementCardSuccess(response.data));
        }
    } catch (e) {

    }
}

/**
 * Incremenet users sessions count, for things like download app card.
 */
function* incremenetSessionCount() {
    try {
        yield call(Api.post, REFINEMENT_SESSION_COUNT_URL);
    } catch (e) {

    }
}

function* profileRefinementCard() {
    yield all([
        takeLatest(GET_PROFILE_REFINEMENT_CARD, getProfileRefinementCard),
        takeLatest(NOT_INTERESTED_IN, notInterestedIn),
        takeLatest(INCREMENET_SESSION_COUNT, incremenetSessionCount),
    ]);
}

export default profileRefinementCard;
