import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import Title from 'components/atomics/atoms/Title/Title';
import {
    DJLogo,
    IconArrowDown,
} from 'components/atomics/atoms/Icons/Icons';
import {
    StyledContainer,
    StyledPaddedContainer,
} from 'components/MarketBriefing/archive/MarketBriefingArchive';
import {
    outerContainerStyles,
    headerStyles,
    displayStyles,
    displayBackgroundStyles,
    ctaStyles,
    placeholderStyles,
    styledBackToTopButton,
} from '../../../components/MarketBriefing/page/styles';
import { TYPE_AM } from 'components/MarketBriefing/constants';
import labels from '../../../lang/en.json';
import { NEWS_ROUTE } from "../../../state-management/constants/news";
import { wrapBackButtonLabels } from "../../../utils/urlUtils";
import Link from 'components/atomics/atoms/Link/Link';
import { connect } from "react-redux";
import BackToLink from "../../BackToLink/BackToLink";
import { HistoryManager } from "../../../utils/historyManager";
import { TYPE_PRIMARY_MEDIUM } from 'components/atomics/atoms/Button/Button';

export const StyleBackToTopButton = styled.button`
    ${styledBackToTopButton}
`;

const StyledCTA = styled(Link)`
    ${ctaStyles}
`;

const StyledOuterContainer = styled.div`
    ${outerContainerStyles}
`;

const StyledHeader = styled.div`
    ${headerStyles}
`;

const StyledDisplay = styled.div`
    ${displayStyles}
`;

const StyledDisplayBackground = styled.div`
    ${displayBackgroundStyles}
`;

const StyledPlaceholder = styled.div`
    ${placeholderStyles}
`;

const Header = styled.div`
`;

const STICKY_TOP_PADDING = 120;

/**
 * Market briefing page component, displays a full single market briefing.
 */
class MarketBriefing extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        const prevousUrl = window.document.referrer;

        this.state = {
            isSticky: false,
            showBackToTop: false,
            isNewsPreviousUrl: prevousUrl.includes('news'),
        };

        this.handleWindowScroll = this.handleWindowScroll.bind(this);
        this.backToTop = this.backToTop.bind(this);
        this.handleBack = this.handleBack.bind(this);

        this.placeholderRef = React.createRef();
    }

    /**
     * Add event listeners to control sticky behavior.
     */
    componentDidMount() {
        window.addEventListener('scroll', this.handleWindowScroll);
        window.addEventListener('resize', this.handleWindowScroll);
    }

    /**
     * Remove event listeners that control sticky behavior.
     */
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleWindowScroll);
        window.removeEventListener('resize', this.handleWindowScroll);
    }

    /**
     * Scroll the user back to the top of page.
     */
    backToTop(e) {
        e.preventDefault();
        window.scrollTo({
            top: 0,
            left: 0,
            behaviour: 'smooth'
        });
    }

    /**
     * Handle if the player is sticky or not on scroll or resize of window.
     */
    handleWindowScroll() {
        const scrollTop = document.body.scrollTop
            ? document.body.scrollTop : document.documentElement.scrollTop;
        const stickyElement = this.placeholderRef.current;
        const rect = stickyElement.getBoundingClientRect();

        stickyElement.querySelector('a').setAttribute('style', `left:${rect.left}px;`);

        if (scrollTop > 0) {
            this.setState({
                showBackToTop: true,
            });
        } else {
            this.setState({
                showBackToTop: false,
            });
        }

        if (stickyElement) {
            if (scrollTop > (stickyElement.offsetTop - STICKY_TOP_PADDING)
                && !this.state.isSticky) {
                this.setState({
                    isSticky: true,
                });
            } else if (scrollTop <= (stickyElement.offsetTop - STICKY_TOP_PADDING)
                && this.state.isSticky) {
                this.setState({
                    isSticky: false,
                });
            }
        }
    }

    /**
     * Render the back to top sticky button.
     */
    renderBackToTop() {
        return (
            <StyleBackToTopButton
                aria-label={labels.translations['marketBriefing.page.scrollToTop']}
                type="button"
                id="backToTopLink"
                onClick={this.backToTop}
                data-analytics-placement="Button : body"
                data-analytics-label="trackLink : button"
                data-analytics-id="backtotop:Market Briefing"
            >
                <IconArrowDown />
            </StyleBackToTopButton>
        );
    }

    /**
     * Handle back button flow
     */
    handleBack() {
        const { urlHistoryList } = this.props;
        const historyItem = urlHistoryList.length - 2 >= 0
            ? urlHistoryList[urlHistoryList.length - 2]
            : null;

        if (historyItem == null) return;

        const url = historyItem.currentUrl.includes(window.location.origin)
            ? historyItem.currentUrl.replace(window.location.origin, '')
            : historyItem.currentUrl;

        HistoryManager.sentHistoryEvent(url);
    }

    /**
     * Render the page component.
     */
    render() {
        const {
            urlHistoryList,
        } = this.props;
        const label = this.props.type === TYPE_AM
            ? labels.translations['marketBriefing.page.title.am'].replace('{date}',
                moment.utc(this.props.date).format('dddd, MMMM D, YYYY'))
            : labels.translations['marketBriefing.page.title.pm'].replace('{date}',
                moment.utc(this.props.date).format('dddd, MMMM D, YYYY'));
        const backLabel = (urlHistoryList.length - 2) >= 0 ? urlHistoryList[urlHistoryList.length - 2] : null;

        return (
            <StyledContainer>
                <StyledHeader>
                    <StyledPaddedContainer>
                        <Header>
                            {
                                backLabel &&
                                <BackToLink
                                    to={backLabel !== null ? backLabel.currentUrl : NEWS_ROUTE}
                                    goBack={this.handleBack}
                                    customLabel={wrapBackButtonLabels(backLabel)}
                                    isDisable={backLabel == null}
                                />
                            }
                        </Header>
                        <Title>
                            {label}
                        </Title>
                        <p>
                            <DJLogo />
                        </p>
                        <StyledCTA
                            id="viewAllBriefingsButton"
                            to="/marketBriefings"
                        >
                            <FormattedMessage id="marketBriefing.page.viewAllBriefings" />
                        </StyledCTA>
                    </StyledPaddedContainer>
                </StyledHeader>
                <StyledDisplayBackground>
                    <StyledPaddedContainer>
                        <StyledDisplay>
                            <div>
                                <StyledPlaceholder
                                    id="stickyPlaceholder"
                                    isSticky={this.state.isSticky}
                                    ref={this.placeholderRef}
                                >
                                    <StyledCTA
                                        type={TYPE_PRIMARY_MEDIUM}
                                        id="viewBriefingsButton"
                                        to="/marketBriefings"
                                    >
                                        <FormattedMessage id="marketBriefing.page.allBriefings" />
                                    </StyledCTA>
                                </StyledPlaceholder>
                            </div>
                            <StyledOuterContainer
                                dangerouslySetInnerHTML={{ __html: this.props.content }}
                            />
                        </StyledDisplay>
                    </StyledPaddedContainer>
                </StyledDisplayBackground>
                {this.state.showBackToTop
                    && this.renderBackToTop()}
            </StyledContainer>
        );
    }
}

MarketBriefing.propTypes = {
    content: PropTypes.string,
    type: PropTypes.string,
    date: PropTypes.string,
};

MarketBriefing.defaultProps = {
    content: '',
    type: TYPE_AM,
    date: null,
};

const mapStateToProps = (state) => ({
    urlHistoryList: state.UrlHistoryReducer.history
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketBriefing)
