import { SHOW_POPUP, HIDE_POPUP } from 'state-management/constants/globalPopupContainer';

export function showPopup() {
    return {
        type: SHOW_POPUP,
    };
}

export function hidePopup(redirect) {
    return {
        type: HIDE_POPUP,
        redirect,
    };
}
