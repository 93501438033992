import {
    GET_PROFILE_REFINEMENT_CARD_SUCCESS,
} from 'state-management/constants/profileRefinementCard';

const initialState = {
    type: '',
    id: null,
    hasSuggestion: false,
};

function profileRefinementCardReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_PROFILE_REFINEMENT_CARD_SUCCESS:
            return { ...action.data };
        default:
            return state;
    }
}

export default profileRefinementCardReducer;
