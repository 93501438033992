import { css } from 'styled-components';
import {
    COLORS,
    ANIMATIONS,
} from 'utils/variables';
import {
    FROM_MOBILE_TO_TABLET,
} from 'utils/mediaQueries';

export const closeButtonStyles = css`
    position: absolute;
    z-index: 1;
    right: 80px;
    top: 10px;
    font-weight: bold;
    cursor: pointer;
    color: ${COLORS.SAPPHIRE};
    transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;
    &:hover {
        color: ${COLORS.DARK_SAPPHIRE};
    }
    
    @media screen and ${FROM_MOBILE_TO_TABLET} {
        right: 30px;
    }
`;