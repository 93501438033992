import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { connect } from 'react-redux';
import { visitArticle } from 'state-management/actions/articles.common';
import {
    toggleSaveGuide as toggleSaveGuideAction,
} from 'state-management/actions/save';
import {
    Container,
    PagedList,
    BackToLink,
    ResultsCount,
    FollowControl,
    Loader,
} from 'components';
import { ARTICLES_IN_PAGE } from 'state-management/constants/news';
import SkinnyListGuideCard from 'components/atomics/molecules/SkinnyListGuideCard/SkinnyListGuideCard';
import WebinarListCard from 'components/atomics/molecules/WebinarListCard/WebinarListCard';
import SkinnyListCeCredit from 'components/atomics/molecules/SkinnyListCeCredit/SkinnyListCeCredit';
import DropDown from 'components/atomics/molecules/Dropdown/Dropdown';
import Title from 'components/atomics/atoms/Title/Title';
import GuideContentCard from 'components/atomics/molecules/GuideContentCard/GuideContentCard';
import CollectionContentCard from 'components/atomics/molecules/CollectionContentCard/CollectionContentCard';
import CeCreditsContentCard from 'components/atomics/molecules/CeCreditsContentCard/CeCreditsContentCard';
import {
    listStyles,
    headerStyles,
    followControlStyles,
    listContentContainerStyles,
    subtitleStyles,
    containerStyles,
    resultsWrapper,
    sortAndFiltersContainerStyles,
    dropDownWrapperStyles,
    sectionStyles,
    noResultsContainer,
    noResultsDescription,
    noResultsTitle,
    noResultsCta,
} from '../../containers/PageableNews/styles';
import { getCoachMarkStatus } from 'state-management/actions/coachMark';
import { COACH_MARK_SAVE_CONFIRMATION } from 'state-management/constants/coachMark';
import { toggleTopicFollow } from 'state-management/actions/followTopic';
import { toggleSourceFollow } from 'state-management/actions/sources';
import Button from 'components/atomics/atoms/Button/Button';
import language from '../../lang/en.json';
import {
    TYPE_PILL,
    TYPE_PRIMARY_MEDIUM,
} from 'components/atomics/atoms/Button/Button';
import SkinnyListContentCard from 'components/atomics/molecules/SkinnyListContentCard/SkinnyListContentCard';
import WebinarCard from 'components/atomics/organisms/WebinarCard/WebinarCard';
import * as analytics from 'utils/adobeAnalytics';
import {
    SITE_SECTION_TOP_NEWS,
    PAGE_NAME_TOP_NEWS,
} from 'utils/analyticsConstants';
import { transformContentItem } from "utils/contentCardUtils";
import { contextHubRecordEvent } from '../../utils/contextHub';
import {
    KEBAB_EVENT_LIST,
} from 'utils/contextHubEventListConstants';
import { wrapBackButtonLabels } from "../../utils/urlUtils";
import scrollHelper from 'utils/scrollToTop';

const sortValues = [
    {
        key: 1,
        value: language.translations['practiceManagement.guidesAndFeatures.sort.ascending'],
    },
    {
        key: 2,
        value: language.translations['practiceManagement.guidesAndFeatures.sort.descending'],
    },
    {
        key: 3,
        value: language.translations['practiceManagement.guidesAndFeatures.sort.dateAscending'],
    },
    {
        key: 4,
        value: language.translations['practiceManagement.guidesAndFeatures.sort.dateDescending'],
    },
];

const guidesArticleCount = {
    "desktop": 20,
    "tablet": 12,
    "mobile": 6,
};

const NoResultsContainer = styled.div`
    ${noResultsContainer}
`;

const NoResultsTitle = styled.div`
    ${noResultsTitle}
`;

const NoResultsDescription = styled.div`
    ${noResultsDescription}
`;

const NoResultsCta = styled.div`
    ${noResultsCta}
`;

const ResultWrapper = styled.div`
    ${resultsWrapper}
`;

const StyledList = styled.ul`
    ${listStyles}
`;

const SortAndFilterContainer = styled.div`
    ${sortAndFiltersContainerStyles}
`;

const StyledFollowControlContainer = styled.li`
    ${followControlStyles}
`;

const StyledHeader = styled.div`
    ${headerStyles}
`;

const ListContainer = styled.div`
    ${listContentContainerStyles}
`;

const StyledContainer = styled(Container)`
    ${containerStyles}
`;

const DropDownWrapper = styled.div`
    ${dropDownWrapperStyles};
`;

const Section = styled.section`
    ${sectionStyles};
`;

const Subtitle = styled.div`
    ${subtitleStyles}
`;

const getUrlParameter = (name) => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);

    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const MAX_ARTICLES = 180;

/**
 * Render a pageable news page, including:
 * See more top news.
 * See more capital ideas.
 * See more guides page.
 * See more collections page.
 * CE credits list page 'center'.
 * 
 * NOTE: when changing ANYTHING in this container, check all the above pages before PRing your work.
 */
export class PageableNews extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.parseUrlTopics();

        this.getArticlePageCount = this.getArticlePageCount.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.sortDataAndSetState = this.sortDataAndSetState.bind(this);
        this.onLocationChange = this.onLocationChange.bind(this);
        this.dataSortedByTimeStamp = this.dataSortedByTimeStamp.bind(this);
    }

    state = {
        savedNewsId: null,
        page: 1,
        isTopic: false,
        topicId: null,
        isSource: false,
        sourceId: null,
        content: null,
        sortId: 3, // Newest default ordering
        cardPosition: 0,
        dateValidation: false,
        isNone: false
    };

    /**
     * Stuff to do when we get new props and page size information.
     * @param {*} nextProps
     */
    componentWillUpdate(nextProps) {
        if (JSON.stringify(nextProps.content) !== JSON.stringify(this.props.content)) {
            if (this.props.type === "guidesandfeatures" || this.props.type === "collections") {
                if (this.props.webinar && this.props.webinar[0]) {
                    const today = new Date().getTime() / 1000;

                    let startDate = new Date(this.props.webinar[0].startTimestamp).getTime() / 1000;
                    let finalIndex = 0;

                    let tempObj = Object.assign({}, this.props.webinar[0])
                    tempObj.publishTimestamp = tempObj.latestPublishTimestamp;
                    let newArr = [...nextProps.content, tempObj]
                    const timeKey = newArr[0].hasOwnProperty('publishTimestamp') ? 'publishTimestamp' : 'publishDate';

                    //newArr.sort((a, b) => (moment(a.publishTimestamp).unix() < moment(b.publishTimestamp).unix()) ? 1 : -1);
                    newArr = newArr.sort((a, b) => +new Date(a[timeKey]) - +new Date(b[timeKey]));
                    finalIndex = newArr.findIndex(v => v.id === tempObj.id)

                    // todo: refactor this. I don't like that we are setting props to state.
                    this.setState({
                        content: newArr.slice(0, MAX_ARTICLES),
                        cardPosition: finalIndex,
                    }, this.sortDataAndSetState);
                } else {
                    this.setState({
                        content: nextProps.content.slice(0, MAX_ARTICLES),
                        cardPosition: 0,
                    }, this.sortDataAndSetState);
                }
            } else {
                this.setState({
                    content: nextProps.content.slice(0, MAX_ARTICLES),
                }, this.sortDataAndSetState);
            }
        }

        if (nextProps.breakpoint != this.props.breakpoint) {
            this.setState({
                page: 1,
            });
        }
    }

    /**
     * Handle page changes with popstate listener and gather related webinar data.
     */
    componentDidMount() {
        scrollHelper.scrollToTop();

        if (this.props.webinar && this.props.webinar[0]) {
            const today = new Date().getTime() / 1000;
            let startDate = new Date(this.props.webinar[0].startTimestamp).getTime() / 1000;
            let finalIndex = 0;
            let webinarItemsList = [];

            this.props.webinar.map(webinarItem => {
                let promoteDate = new Date(webinarItem.dateToPromote).getTime() / 1000;
                let promteDateValidation = today >= promoteDate ? true : false;
                promteDateValidation ? webinarItemsList.push(webinarItem) : '';
            })


            let output = webinarItemsList.map(s => ({ publishTimestamp: s.latestPublishTimestamp, ...s }));
            //let tempObj = Object.assign({}, output);
            //tempObj.publishTimestamp = tempObj.latestPublishTimestamp;
            let tempObj = { ...output };

            let newArr = [...this.props.content, ...output];
            const timeKey = newArr[0].hasOwnProperty('publishTimestamp') ? 'publishTimestamp' : 'publishDate';

            newArr = newArr.sort((a, b) => +new Date(a[timeKey]) - +new Date(b[timeKey]));
            finalIndex = newArr.findIndex(v => v.id === tempObj.id);

            this.setState({
                cardPosition: finalIndex,
                content: newArr.slice(0, MAX_ARTICLES)
            }, this.sortDataAndSetState);
        } else {
            if (this.props.type === 'source') {
                const sourceContent = JSON.parse(JSON.stringify(this.props.content));

                sourceContent.splice(0, this.props.content.length - MAX_ARTICLES);

                this.setState({
                    cardPosition: 0,
                    content: sourceContent,
                }, this.sortDataAndSetState);
            } else {
                this.setState({
                    cardPosition: 0,
                    content: this.props.content.slice(0, MAX_ARTICLES),
                }, this.sortDataAndSetState);
            }
        }

        if (this.state.isSource && this.props.content.length) {
            this.setState({ sourceId: this.props.content[0].sourceId });
        }

        window.addEventListener('popstate', this.onLocationChange);
    }

    /**
     * Stuff to do on sorting type change.
     * @param {*} val
     */
    setSort(val) {
        this.setState({
            sortId: val,
        });
    }

    /**
     * What to do on page change click.
     */
    onPageChange = (page) => {
        this.setState({ page });
    };

    /**
     * Get the proper limit of items per page given the context.
     */
    getArticlePageCount() {
        return this.props.type === "guidesandfeatures" || this.props.type === "collections"
            ? guidesArticleCount[this.props.breakpoint]
            : ARTICLES_IN_PAGE;
    }

    /**
     * Get the items for a single pages display.
     */
    getPageItems = () => {
        const itemCount = this.getArticlePageCount();

        if (!this.state.content) {
            return [];
        }

        return this.state.content.slice((this.state.page - 1) * itemCount, this.state.page * itemCount);
    }

    /**
     * Stuff to do when a user saves/unsaves item.
     */
    handleToggleSaveItem = (item) => {
        if (this.props.isSavePending && !this.state.savedNewsId) {
            this.setState({ savedNewsId: item.id });
        }
    };

    /**
     * Handle the change to page when using back etc.
     */
    onLocationChange() {
        const newPage = getUrlParameter('p');

        if (newPage && newPage != this.state.page) {
            this.setState({
                page: newPage,
            })
        } else if (!newPage && this.state.page !== 1) {
            this.setState({
                page: 1,
            });
        }
    }

    /**
     * Render the page header.
     */
    renderHeadlinesHeader = () => {
        const {
            headingTranslationId,
            section,
            isLoading,
            type,
            urlHistoryList
        } = this.props;

        let forceSubtitle = null;

        if (type === "collections") {
            forceSubtitle = "practiceManagement.collections.subtitle";
        }

        const backLabel = urlHistoryList !== null && urlHistoryList.length
            ? urlHistoryList.length - 2 >= 0
                ? urlHistoryList[urlHistoryList.length - 2]
                : null
            : null;

        return (
            <StyledHeader type={type}>
                <Container>
                    <div data-rel="backTo">
                        <BackToLink
                            to={backLabel !== null ? backLabel.currentUrl : '#'}
                            customLabel={wrapBackButtonLabels(backLabel)}
                            isDisable={backLabel === null}
                        />
                    </div>
                    <Title
                        title={section || language.translations[`news.${headingTranslationId}.title`]}
                    />
                    {forceSubtitle !== null
                        && <Subtitle>
                            <FormattedMessage id={forceSubtitle} />
                        </Subtitle>}
                    {this.renderRecordCount()}
                    {this.renderFollowingButton(isLoading)}
                </Container>
            </StyledHeader>
        );
    };

    /**
     * Stuff to do when a user follows/unfollows topic.
     */
    handleToggleFollow = () => {
        if (this.props.isLoading) {
            return;
        }

        if (this.state.isTopic) {
            this.props.toggleFollowTopic(this.state.topicId, this.isFollowing(this.state.topicId));
        } else {
            this.props.toggleFollowSource(
                this.state.sourceId,
                this.isFollowing(this.state.sourceId),
            );
        }
    };

    /**
     * Determine if a topic is followed.
     */
    isFollowing() {
        return (this.state.isTopic)
            ? this.props.profile.topicIds.includes(this.state.topicId)
            : this.props.profile.sourceIds.includes(this.state.sourceId);
    }

    /**
     * Get topic ID from URL of page.
     */
    parseUrlTopics() {
        const url = window.location.href;
        this.state.isTopic = url.includes('topics');
        this.state.isSource = url.includes('sources');

        if (this.state.isTopic) {
            this.state.topicId = parseInt(url.substr(url.lastIndexOf('/') + 1), 10);
        }

        if (url.includes('news/top')) {
            analytics.dispatchPageViewStart(PAGE_NAME_TOP_NEWS, SITE_SECTION_TOP_NEWS);
        }
    }

    /**
     * Render a single non-guide card.
     */
    renderItem = (item, idx) => {
        return (
            <SkinnyListContentCard
                key={idx}
                article={transformContentItem(item)}
                topics={item.topics}
                onArticleClick={() => { this.props.onArticleClick(item) }}
                shouldAllowClickArticleTracking={true}
            />
        );
    };

    /**
     * Render a single guide card.
     */
    renderGuideItem = (item, idx) => {
        const article = transformContentItem(item);

        let newDateValidation = false;

        let startDate = this.props.webinar && this.props.webinar[0] &&
            new Date(this.props.webinar[0].startTimestamp).getTime() / 1000;
        let endDate = this.props.webinar && this.props.webinar[0] &&
            new Date(this.props.webinar[0].endTimestamp).getTime() / 1000;

        const today = new Date().getTime() / 1000;
        const nowDate = new Date().getTime() / 1000;
        const promoteDate = this.props.webinar && this.props.webinar[0] &&
            new Date(this.props.webinar[0].dateToPromote).getTime() / 1000;
        const promteDateValidation = nowDate >= promoteDate ? true : false;

        if ((today < endDate) && promteDateValidation) {
            newDateValidation = true;
        }

        let content = '';

        if (this.props.breakpoint !== "mobile") {
            if (article.type == "WEBINAR" && promteDateValidation &&
                this.props.webinar && this.props.webinar[0]) {

                content = <WebinarCard article={article} />;
            } else {
                if (article.type == 'WEBINAR') {
                    content = <WebinarCard article={article} />;
                } else {
                    content = <GuideContentCard
                        guideCardInfo={article}
                        onArticleClick={() => { this.props.onArticleClick(item) }}
                        shouldAllowClickArticleTracking={true}
                    />
                }
            }

        } else {
            if (article.type == "WEBINAR" && promteDateValidation &&
                this.props.webinar && this.props.webinar[0]) {

                content = <WebinarListCard
                    article={article}
                    alternativeBackground='WHITE'
                    shouldShowToast={true}
                    shouldAllowClickArticleTracking={true}
                    onUnsave={this.handleUnsave}
                />;
            } else {

                if (article.type == 'WEBINAR') {
                    content = <WebinarListCard
                        article={article}
                        alternativeBackground='WHITE'
                        shouldShowToast={true}
                        shouldAllowClickArticleTracking={true}
                        onUnsave={this.handleUnsave}
                    />;
                } else {
                    content = <SkinnyListGuideCard
                        article={article}
                        onArticleClick={() => { this.props.onArticleClick(item) }}
                        shouldAllowClickArticleTracking={true}
                    />
                }
            }
        }

        return (
            <React.Fragment key={idx}>
                {content}
            </React.Fragment>
        );
    };

    /**
     * Render a single collection card.
     */
    renderCollectionItem = (item, idx) => {
        const article = transformContentItem(item, null, null, null, true);

        return (
            <CollectionContentCard
                key={idx}
                collectionInfo={article}
                onArticleClick={() => { this.props.onArticleClick(item) }}
                shouldAllowClickArticleTracking={true}
            />
        );
    };

    /**
    * Render a single collection card.
    */
    renderCECreditsItem = (item, idx) => {
        const article = transformContentItem(item);

        return (
            <React.Fragment key={idx}>
                {this.props.breakpoint !== "mobile" &&
                    <CeCreditsContentCard
                        activateDismissCoachMark={false}
                        key={idx}
                        article={article}
                    />}
                {this.props.breakpoint === "mobile" &&
                    <SkinnyListCeCredit
                        article={article}
                        onArticleClick={() => { this.props.onArticleClick(item) }}
                        shouldAllowClickArticleTracking={true}
                    />}
            </React.Fragment>
        );
    };

    /**
     * Render the follow/unfollow controls.
     * @param {*} isLoading
     */
    renderFollowingButton(isLoading) {
        const { section } = this.props;
        const { content } = this.state;

        if (section !== 'Capital Ideas' && (this.state.isTopic || (this.state.isSource))) {
            return (
                <StyledFollowControlContainer>
                    <Button
                        type={TYPE_PILL}
                        onClick={() => {
                            contextHubRecordEvent(KEBAB_EVENT_LIST(
                                (this.state.isTopic ? "topic" : "source"),
                                !this.isFollowing(),
                                "",
                                (this.state.isTopic ? section : null),
                                (!this.state.isTopic ? section : null),
                            ));
                            this.handleToggleFollow()
                        }}
                        isfollowing={(this.isFollowing()).toString()}
                        selected={this.isFollowing()}
                    >
                        {!this.isFollowing()
                            && <FormattedMessage id="followControl.follow" />}
                        {this.isFollowing()
                            && <FormattedMessage id="followControl.following" />}
                    </Button>

                    {false && <FollowControl
                        onClick={() => this.handleToggleFollow()}
                        isfollowing={(this.isFollowing()).toString()}
                        isLoading={isLoading}
                        theme="dark"
                    />}
                </StyledFollowControlContainer>
            );
        }
        return null;
    }

    /**
     * Sort the data and set it to the state for display.
     */
    sortDataAndSetState() {
        const { content } = this.state;
        let sortedData = content.slice();

        if (!sortedData[0]) {
            return;
        }

        const timeKey = sortedData[0].hasOwnProperty('publishTimestamp') ? 'publishTimestamp' : 'publishDate';

        switch (this.state.sortId) {
            /* A-Z */
            case 1:
                sortedData = sortedData.sort((a, b) => (a.title > b.title) ? 1 : -1);
                break;
            /* Z-A */
            case 2:
                sortedData = sortedData.sort((a, b) => (a.title < b.title) ? 1 : -1);
                break;
            /* Newest first */
            case 3:
                if (this.props.type !== "collections") {
                    if (this.props.type === "guidesandfeatures") {
                        sortedData = sortedData.sort((a, b) => +new Date(b[timeKey]) - +new Date(a[timeKey]));
                    } else if (this.props.type === "cecredits") {
                        sortedData = sortedData.sort((a, b) => +new Date(b.latestPublishTimestamp) - +new Date(a.latestPublishTimestamp));
                    } else {
                        sortedData = sortedData.sort((a, b) => +new moment(b[timeKey]) - +new moment(a[timeKey]));
                    }
                } else {
                    sortedData = sortedData.sort((a, b) => +new Date(a.latestPublishTimestamp) - +new Date(b.latestPublishTimestamp));
                }
                break;
            /* Oldest first */
            case 4:
                if (this.props.type !== "collections") {
                    if (this.props.type === "guidesandfeatures") {
                        sortedData = sortedData.sort((a, b) => +new Date(a[timeKey]) - +new Date(b[timeKey]));
                    } else if (this.props.type === "cecredits") {
                        sortedData = sortedData.sort((a, b) => +new Date(a.latestPublishTimestamp) - +new Date(b.latestPublishTimestamp));
                    } else {
                        sortedData = sortedData.sort((a, b) => +new Date(a[timeKey]) - +new Date(b[timeKey]));
                    }
                } else {
                    sortedData = sortedData.sort((a, b) => +new Date(b.latestPublishTimestamp) - +new Date(a.latestPublishTimestamp));
                }
                break;
            default:
            //NON-OP
        };
        this.setState({
            content: sortedData,
        });
    }

    /**
     * Sort data by published timestamps.
     * @param {*} sortedData 
     * @param {*} isReverseSort 
     */
    dataSortedByTimeStamp(sortedData, isReverseSort) {
        const timeKey = sortedData[0].hasOwnProperty('latestPublishTimestamp') ? 'latestPublishTimestamp' : 'publishDate';
        const cardType = sortedData[0].type;

        if (isReverseSort) {
            if (cardType == 'CECREDIT') {
                sortedData.sort((a, b) =>
                    ((moment(b.latestPublishTimestamp) ? moment(b.latestPublishTimestamp).unix() : moment(b.publishDate).unix()) >
                        (moment(a.latestPublishTimestamp) ? moment(a.latestPublishTimestamp).unix() : moment(a.publishDate).unix())) ? 1 : -1);
                return sortedData;
            } else {
                sortedData.sort((a, b) =>
                    ((moment(b.publishDate) ? moment(b.publishDate).unix() : moment(b.latestPublishTimestamp).unix()) >
                        (moment(a.publishDate) ? moment(a.publishDate).unix() : moment(a.latestPublishTimestamp).unix())) ? 1 : -1);
                return sortedData;
            }
        } else {
            if (cardType == 'ARTICLE') {
                sortedData.sort((a, b) =>
                    ((moment(a.publishDate).unix() ? moment(a.publishDate).unix() : moment(a[timeKey]).unix()) <
                        (moment(b.publishDate).unix() ? moment(b.publishDate).unix() : moment(b[timeKey]).unix())) ? 1 : -1);
                return sortedData
            } else {
                sortedData.sort((a, b) =>
                    ((moment(a.publishTimestamp).unix() ? moment(a.publishTimestamp).unix() : moment(a[timeKey]).unix()) <
                        (moment(b.publishTimestamp).unix() ? moment(b.publishTimestamp).unix() : moment(b[timeKey]).unix())) ? 1 : -1);
                return sortedData
            }
        }


    }

    /**
     * Stuff to do on sorting change.
     * @param {*} val
     */
    onSortChange(val) {
        if (this.state.sortId !== val) {
            this.setState({
                sortId: val,
                page: 1,
            }, this.sortDataAndSetState);
        }
    }

    /**
     * Render any sort and/or filter controls.
     */
    renderSortAndFilters() {
        /* only needed for /ideas currently */
        if (this.props.type !== "guidesandfeatures" && this.props.type !== "collections") {
            return null;
        }

        return (
            <SortAndFilterContainer>
                <DropDownWrapper>
                    <DropDown
                        id="sort"
                        title={language.translations['practiceManagement.guidesAndFeatures.sort.label']}
                        ariaLabel={language.translations['practiceManagement.guidesAndFeatures.sort.label']}
                        emptyOptionLabel={language.translations['practiceManagement.guidesAndFeatures.sort.label']}
                        isEmptyOptionSelectable={false}
                        value={this.state.sortId}
                        options={sortValues}
                        onValueChanged={this.onSortChange}
                        isNone="no"
                    />
                </DropDownWrapper>
            </SortAndFilterContainer>
        );
    }

    /**
     * Render the main and underlying components.
     */

    renderRecordCount() {
        const { displayResultCount, type } = this.props;
        const { content } = this.state;

        return (
            <div>
                {displayResultCount && (
                    <ResultWrapper type={type}>
                        <ResultsCount>
                            <FormattedMessage
                                id="news.results.number"
                                values={{
                                    number: content ? content.length : 0,
                                }}
                            />
                        </ResultsCount>
                    </ResultWrapper>
                )}
            </div>
        )
    }
    /**
     * Show empty source or topic messaging.
     */
    renderEmptySourceTopicResults() {
        return (
            <NoResultsContainer>
                <NoResultsTitle>
                    <FormattedMessage
                        id="search.topicSource.noResults.title"
                        values={{
                            name: this.props.section,
                        }}
                    />
                </NoResultsTitle>
                <NoResultsDescription>
                    <FormattedMessage id="search.topicSource.noResults.description" />
                </NoResultsDescription>
                <NoResultsCta>
                    <Button
                        type={TYPE_PRIMARY_MEDIUM}
                        to="/news"
                    >
                        <FormattedMessage id="search.topicSource.noResults.cta" />
                    </Button>
                </NoResultsCta>
            </NoResultsContainer>

        );
    }

    /**
     * Render this and underlying components.
     */
    render() {
        if (this.props.showLoader === true) {
            return <Loader />;
        }

        const { type, showEmptyResults } = this.props;
        const { content } = this.state;

        if ((!content || !content.length) && !showEmptyResults) {
            return <Loader />;
        }

        let renderItem = this.renderItem;

        if (this.props.type === "guidesandfeatures") {
            renderItem = this.renderGuideItem;
        } else if (this.props.type === "collections") {
            renderItem = this.renderCollectionItem;
        } else if (this.props.type === "cecredits") {
            renderItem = this.renderCECreditsItem;
        }
        return (
            <React.Fragment>
                {this.renderHeadlinesHeader()}
                <Section type={type}>
                    <StyledContainer type={type}>
                        {this.renderSortAndFilters()}
                        <ListContainer type={type}>
                            {showEmptyResults
                                && this.renderEmptySourceTopicResults()}
                            <PagedList
                                itemsCount={content ? content.length : 0}
                                onPageChange={this.onPageChange}
                                page={this.state.page}
                                pageItems={this.getPageItems()}
                                renderItem={renderItem}
                                pageWrapperComponent={StyledList}
                                pageSize={this.getArticlePageCount()}
                                scrollToTop
                                noMaxWidth={this.props.noMaxWidth}
                                hideButtons={showEmptyResults}
                            />
                        </ListContainer>
                    </StyledContainer>
                </Section>
            </React.Fragment>
        );
    }
}

PageableNews.propTypes = {
    content: PropTypes.arrayOf(PropTypes.shape({})),
    isLoaded: PropTypes.bool,
    isSavePending: PropTypes.bool,
    getCoachMarkStatus: PropTypes.func.isRequired,
    goBack: PropTypes.func,
    headingTranslationId: PropTypes.string.isRequired,
    section: PropTypes.string,
    displayResultCount: PropTypes.bool,
    topic: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    }),
    isLoading: PropTypes.bool.isRequired,
    profile: PropTypes.shape({
        topicIds: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
    type: PropTypes.string,
    breakpoint: PropTypes.string,
    onSave: PropTypes.func,
    noMaxWidth: PropTypes.bool,
    showEmptyResults: PropTypes.bool,
    showLoader: PropTypes.bool,
};

PageableNews.defaultProps = {
    content: [],
    isLoaded: false,
    isSavePending: false,
    goBack: null,
    section: null,
    displayResultCount: true,
    topic: null,
    type: null,
    breakpoint: 'desktop',
    onSave: () => { },
    noMaxWidth: false,
    showEmptyResults: false,
    showLoader: false,
};

const mapStateToProps = state => ({
    isLoaded: state.pageableNews.isLoaded,
    isSavePending: state.coachMark.display[COACH_MARK_SAVE_CONFIRMATION],
    topic: state.trendingTopicNews.topic,
    profile: state.basicProfile,
    isLoading: state.followTopic.toggleInProgress,
    urlHistoryList: state.UrlHistoryReducer.history,
    showLoader: state.guide.showLoader
});

const mapDispatchToProps = dispatch => ({
    onSave: (item) => dispatch(toggleSaveGuideAction(item)),
    onArticleClick: article => dispatch(visitArticle(article)),
    getCoachMarkStatus: () => dispatch(getCoachMarkStatus()),
    toggleFollowTopic: (topicId, isFollowing) => dispatch(toggleTopicFollow(topicId, isFollowing)),
    toggleFollowSource: (sourceId, isFollowing) => (
        dispatch(toggleSourceFollow(sourceId, isFollowing))
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageableNews);
