export class HistoryManager {
    constructor(historyLink) {
        document.addEventListener("backinhistory", function(e) {
            historyLink.push(e.detail.url)
        });
    }

    static sentHistoryEvent (url = null) {
        return new Promise((resolve, reject) => {
            try {
                const event = new CustomEvent("backinhistory", {
                    detail: {
                        url: url
                    }
                });
                document.dispatchEvent(event);
                resolve();
            } catch(e) {
                reject(e)
            }
        })
    }
}
