import React from 'react';
import PropTypes from 'prop-types';

import { ConnectedBaseLayout } from 'components/BaseLayout/BaseLayout';

export const LandingLayout = props => (
    <ConnectedBaseLayout
        hideHeader
        mainContent={props.children}
    />
);

LandingLayout.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

LandingLayout.defaultProps = {
    children: null,
};

function withLandingLayout(Component) {
    return props => (
        <LandingLayout>
            <Component {...props} />
        </LandingLayout>
    );
}

export default withLandingLayout;
