import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { Error } from 'components';
import { errorsContainerStyles, errorsStyles } from 'containers/Errors/styles';

export const StyledErrors = styled.div`
    ${errorsStyles};
`;
const StyledErrorsContainer = styled.div`
    ${errorsContainerStyles};
`;

/**
 * Generic application error page display.
 * @param {*} props 
 */
export const Errors = (props) => {
    const { errorRef, errors } = props;
    const hasErrors = errors && errors.length > 0;

    return (
        <StyledErrors
            ref={errorRef}
            tabIndex="-1"
            variant={{ hasErrors }}
        >
            {
                hasErrors && (
                    <StyledErrorsContainer>
                        {
                            errors.map(error => (
                                <Error
                                    key={error.errorMessage}
                                    message={error.errorMessage}
                                    translateMessage={error.translateMessage}
                                />
                            ))
                        }
                    </StyledErrorsContainer>
                )
            }
        </StyledErrors>
    );
};

Errors.defaultProps = {
    errors: [],
    errorRef: React.createRef(),
};

Errors.propTypes = {
    errors: PropTypes.arrayOf(PropTypes.shape),
    errorRef: PropTypes.shape({}),
};

const mapStateToProps = state => ({
    errors: state.errors.errors,
});

export default connect(mapStateToProps)(Errors);
