import { css } from 'styled-components';
import {
    COLORS,
    ANIMATIONS,
} from '../../../../utils/variables';
import {
    FROM_DESKTOP,
    FROM_TABLET,
    SMALL_MOBILE,
} from '../../../../utils/mediaQueries';

export const LARGE_CARD = 'large';
export const SMALL_CARD = 'small';

export const sloganStyles = css`
    font-size: 15px;
    font-weight: 400;
    line-height: 1.07;
    letter-spacing: 0;

    @media screen and ${FROM_TABLET} {
        width:300px;
    }

    @media screen and ${FROM_DESKTOP} {
        width: 400px;
    }
`;

export const collectionContainerStyles = css`
    padding-left: 0;
    padding-right:0;
    margin-bottom: 8px;
    padding-bottom: 0;
    padding-top: 10px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: ${ANIMATIONS.ARTICLES.IMAGE.TIME.SECONDS}s all;
    }

    @media screen and ${SMALL_MOBILE} {
        margin-bottom:8px;
    }
`;

export const collectionTopContainerStyles = css`
    display: flex;
    flex-direction: row;
    max-width: calc(100% - 16px);
`;

export const collectionImageContainerStyles = css`
    flex: 1 0 0;
    overflow: hidden;
    padding-top:10px;
    min-width: 46px;
    min-height: 46px;
    width: auto;
    height: 100%;
    max-height: 46px;
    max-width: 46px;

    @media screen and ${FROM_DESKTOP} {
        min-width: 64px;
        min-height: 64px;
        max-height: 64px;
        max-width: 64px;
    }
`;

export const collectionContentStyles = css`
    flex: 2 0 0;
    padding-top: 7px;
    padding-bottom: 12px;
    padding-left: 20px;

    h1 {
        margin-top: 0;
        margin-bottom: 6px;

        @media screen and ${FROM_TABLET} {
            width: 423px;
        }

        @media screen and ${FROM_DESKTOP} {
            margin-top: 8px;
            margin-bottom: 10px;
        }
    }
`;
