import moment from 'moment';
import { intersection } from 'lodash';
import { TYPE } from 'components/ContentCard/constants';
import {
    ANY_TIME,
    ARTICLE,
    GUIDE,
    LAST_24_HOURS,
    PAST_5_DAYS,
    PAST_MONTH,
    SOURCE_CAPITAL_GROUP,
    SOURCE_PRACTICE_LAB,
    SOURCE_HBR,
    SOURCE_MC_KINSEY,
} from 'state-management/constants/searchFilter';

import { isSubtypeOfGuide, isPracticeLab } from 'utils/contentCardUtils';

const isGuide = item => { 
    return isSubtypeOfGuide(item.type);
};

const isPL = item => {
    return isPracticeLab(item.sourceTitle);
};

const isArticle = item => {
    return item.type === TYPE.ARTICLE && !isPL(item);
};

const articleHasCapitalGroupSource = item => item.provider === 'CAPITAL_IDEAS';
const guideHasCapitalGroupSource = item => item.type === TYPE.GUIDE_STANDARD ||  item.type === TYPE.TOOL;

export const itemHasPracticeLabSource = item => item.sourceTitle === 'Practice Lab';

export const itemHasCapitalGroupSource = item => (isSubtypeOfGuide(item.type)
    ? guideHasCapitalGroupSource(item)
    : articleHasCapitalGroupSource(item));

const ifAnyTrue = (item, filters) => filters.map(filter => filter(item)).includes(true);

const shouldFilterBySection = section => section.items.map(item => item.checked).includes(true);

export const filterByType = (items, type) => {
    if (!shouldFilterBySection(type)) {
        return items;
    }

    const activeFilters = [];

    const typeFilters = type
        .items
        .filter(item => item.checked)
        .map(item => item.id);

    if (typeFilters.includes(ARTICLE)) {
        activeFilters.push(isArticle);
    }

    if (typeFilters.includes(GUIDE)) {
        activeFilters.push(isGuide);
        activeFilters.push(isPL);
    }

    return items.filter(item => ifAnyTrue(item, activeFilters));
};

export const filterBySources = (items, sources) => {
    if (!shouldFilterBySection(sources)) {
        return items;
    }

    const sourceIds = sources.items
        .filter(item => item.checked)
        .map(item => item.id);

    const capitalGroupSelected = sourceIds.includes(SOURCE_CAPITAL_GROUP);
    const practiceLabSelected = sourceIds.includes(SOURCE_PRACTICE_LAB);
    const hbrSelected = sourceIds.includes(SOURCE_HBR);
    const mcKinseySelected = sourceIds.includes(SOURCE_MC_KINSEY);

    const includesSourceFilter = item => sourceIds.includes(item.sourceId);
    const hasCapitalGroupSourceFilter = item => capitalGroupSelected
        && itemHasCapitalGroupSource(item);
    const hasPracticeLabSourceFilter = item => practiceLabSelected
        && itemHasPracticeLabSource(item);
    const isHbrGuideFilter = item => hbrSelected && item.type === TYPE.GUIDE_HBR;
    const isMcKinseyGuideFilter = item => mcKinseySelected && item.type === TYPE.GUIDE_MC_KINSEY;

    const filters = [
        includesSourceFilter,
        hasCapitalGroupSourceFilter,
        hasPracticeLabSourceFilter,
        isHbrGuideFilter,
        isMcKinseyGuideFilter,
    ];

    return items.filter(item => filters.map(filter => filter(item)).includes(true));
};

export const filterByTopics = (items, topics) => {
    if (!shouldFilterBySection(topics)) {
        return items;
    }

    const topicIds = topics.items
        .filter(item => item.checked)
        .map(item => item.id);

    return items.filter(item => item.topics
        && intersection(topicIds, item.topics.map(t => t.id)).length);
};

export const filterByDate = (items, date) => {
    const selectedItem = date.items.find(item => item.checked);

    if (selectedItem.id === ANY_TIME) {
        return items;
    }

    let filterDate;

    if (selectedItem.id === LAST_24_HOURS) {
        filterDate = moment.utc().subtract(24, 'hours');
    }
    if (selectedItem.id === PAST_5_DAYS) {
        filterDate = moment.utc().subtract(5, 'days');
    }
    if (selectedItem.id === PAST_MONTH) {
        filterDate = moment.utc().subtract(1, 'months');
    }

    return items.filter(item => filterDate <= moment.utc(item.publishDate));
};
