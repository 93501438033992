import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ToolCard  from '../../components/ToolCard/ToolCard';
import { Carousel } from 'components/atomics/molecules/CarouselMolecule/Carousel';
import {
    toolsContainerStyles,
    toolsItemStyles,
    wrapperStyles,
} from 'containers/Tools/styles';
import { createIdsForObjectsByField } from 'utils/idCreatorUtils';
import { BreakpointsContext, MOBILE } from 'components/Breakpoints/Breakpoints';

const StyledToolsContainer = styled.div`
    ${toolsContainerStyles}
`;

export const StyledToolsItem = styled.div`
    ${toolsItemStyles}
`;

const Wrapper = styled.div`
    ${wrapperStyles}
`;

/**
 * Tools area component for PM landing page.
 * @param {*} param0 
 */
const Tools = ({ data }) => (
    <BreakpointsContext.Consumer>
        {(breakpoint) => {
            const TopWrapper = data.tools.length >= 2 && breakpoint === MOBILE
                ? Carousel
                : StyledToolsContainer;

            const horizontalCard = data.tools.length < 2;
            const toolItems = [];

            createIdsForObjectsByField(data.tools, 'name')
                .map(tool => {
                    toolItems.push({
                        component: (<StyledToolsItem
                            key={tool.createdId}
                            horizontalCard={horizontalCard}
                        >
                            <ToolCard
                                tool={tool}
                                horizontalCard={horizontalCard}
                                clickableCard={false}
                            />
                        </StyledToolsItem>)});
                });

            return (
                <Wrapper>
                    <TopWrapper items={toolItems}>
                        {createIdsForObjectsByField(data.tools, 'name')
                            .map(tool => (
                                <StyledToolsItem
                                    key={tool.createdId}
                                    horizontalCard={horizontalCard}
                                >
                                    <ToolCard
                                        tool={tool}
                                        horizontalCard={horizontalCard}
                                        clickableCard={false}
                                    />
                                </StyledToolsItem>
                            ))}
                    </TopWrapper>
                </Wrapper>
            );
        }}
    </BreakpointsContext.Consumer>
);

Tools.areAttributesValid = ({ tools }) => tools && tools.length;

Tools.propTypes = {
    data: PropTypes.shape({
        tools: PropTypes.arrayOf(PropTypes.shape({
            description: PropTypes.string.isRequired,
            link: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })),
    }),
};

Tools.defaultProps = {
    data: {},
};

export default Tools;
