import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, SanitizedHTML } from 'components';
import { IconArrowDown } from 'components/atomics/atoms/Icons';
import {
    qaWrapperStyles,
    qaContainerStyles,
    questionWrapperStyles,
    topicStyles,
    answerWrapperStyles,
    questionStyles,
    answerStyles,
    arrowIconStyles,
} from 'containers/Guide/QuestionsAnswers/styles';

const StyledQAWrapper = styled.div`
    ${qaWrapperStyles};
`;
const StyledQAContainer = styled.dl`
    ${qaContainerStyles};
`;
const StyledQuestionWrapper = styled.dt`
    ${questionWrapperStyles};
`;
export const StyledTopic = styled(({ passedRef, ...other }) => <Button innerRef={passedRef} {...other} />)`
    ${topicStyles};
`;
export const StyledAnswerWrapper = styled.dd`
    ${answerWrapperStyles};
`;
const StyledArrowsIcon = styled(IconArrowDown)`
    ${arrowIconStyles};
`;
const StyledQuestion = styled(SanitizedHTML)`
    ${questionStyles};
`;
const StyledAnswer = styled(SanitizedHTML)`
    ${answerStyles};
`;

const maxElements = 5;

const minElements = 2;

/**
 * Render guide Q&A section.
 */
class QuestionsAnswers extends React.Component {
    /**
     * Default constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.itemsNodesMap = new Map();

        this.state = {
            activeId: props.elements[0] ? props.elements[0].id : -1,
        };
    }

    /**
     * Handle element to control namespace alignment.
     * @param {*} id 
     * @param {*} ref 
     */
    setItemNodeRef(id, ref) {
        this.itemsNodesMap.set(id, ref);
    }

    /**
     * Scroll content area for this QA into view on expansion.
     */
    scrollIntoView() {
        const { activeId } = this.state;
        const node = this.itemsNodesMap.get(activeId);
        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;

        if (node) {
            const rect = node.getBoundingClientRect();
            const isInViewport = rect.top >= 0 && rect.left >= 0 && rect.bottom <= windowHeight
                && rect.right <= windowWidth;

            if (!(isInViewport)) {
                node.scrollIntoView();
            }
        }
    }

    /**
     * Handle QA topic selection.
     * @param {*} topicId 
     */
    selectTopic(topicId) {
        this.setState(prevState => ({
            activeId: prevState.activeId === topicId ? -1 : topicId,
        }), () => {
            this.scrollIntoView();
        });
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const { id, elements } = this.props;

        return (
            QuestionsAnswers.areAttributesValid(this.props) && (
                <StyledQAWrapper>
                    {elements.map((element, index) => {
                        const isActive = this.state.activeId === element.id;

                        return (
                            <React.Fragment key={element.id}>
                                <StyledQAContainer>
                                    <StyledQuestionWrapper>
                                        <StyledTopic
                                            id={`qa-title-${id}-${element.id}`}
                                            aria-controls={`qa-content-${id}-${element.id}`}
                                            aria-expanded={isActive}
                                            passedRef={ref => this.setItemNodeRef(element.id, ref)}
                                            order={index + 1}
                                            onClick={() => this.selectTopic(element.id)}
                                        >
                                            {element.topicTitle}
                                            <StyledArrowsIcon
                                                aria-expanded={isActive}
                                            />
                                        </StyledTopic>
                                    </StyledQuestionWrapper>
                                    <StyledAnswerWrapper
                                        id={`qa-content-${id}-${element.id}`}
                                        aria-labelledby={`qa-title-${id}-${element.id}`}
                                        aria-expanded={isActive}
                                    >
                                        {/* TODO: set correct heading depending on context. */}
                                        <StyledQuestion
                                            htmlContent={element.topicSubTitle}
                                            tag="div"
                                        />
                                        <StyledAnswer
                                            htmlContent={element.topicBodyCopy}
                                            tag="div"
                                        />
                                    </StyledAnswerWrapper>
                                </StyledQAContainer>
                            </React.Fragment>
                        );
                    })}
                </StyledQAWrapper>
            ));
    }
}

QuestionsAnswers.areAttributesValid = ({ elements }) => (
    elements.length >= minElements && elements.length <= maxElements
);

QuestionsAnswers.propTypes = {
    id: PropTypes.string.isRequired,
    elements: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        topicSubTitle: PropTypes.string,
        topicBodyCopy: PropTypes.string,
    })),
};

QuestionsAnswers.defaultProps = {
    elements: [],
};

export default QuestionsAnswers;