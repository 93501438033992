import React from 'react';

import {
    TOPIC_QUERY,
} from 'state-management/constants/pageableNews';

import LandingContainer from 'containers/PageableNews/LandingContainer';

/**
 * All collections page.
 * @param {*} props 
 */
const CollectionsContainer = props => {
    return (
        <LandingContainer
            {...props}
            displayResultCount={false}
            query={TOPIC_QUERY}
            noMaxWidth={true}
            headingTranslationId="practiceManagement.collections"
            type="collections"
        />
    );
}

export default CollectionsContainer;
