import { css } from 'styled-components';
import {
    COLORS,
    FONTS,
    ANIMATIONS,
} from '../../../../utils/variables';
import {
    FROM_DESKTOP, SMALL_MOBILE, FROM_TABLET
} from '../../../../utils/mediaQueries';

export const containerStyles = css`
    position: relative;
    background: ${COLORS.WHITE};
    border: 1px solid ${COLORS.LIGHT_GRAY2};
    color: ${COLORS.BLACK};
    display: inline-block;
    max-width: 304px;
    overflow: hidden;
    text-decoration: none;

    @media screen and ${SMALL_MOBILE} {
        width: 299px;
        min-height: 177px;
    }

    @media screen and ${FROM_TABLET} {        
        min-height: 192px;
    }
    
    @media screen and ${FROM_DESKTOP} {
        max-width: 375px;
        min-height: 212px;
    }
`;

export const contentContainerStyles = css`
    padding: 20px 0;
    text-decoration: none;
    color: ${COLORS.BLACK};

    &>div:last-child {
        font-size: 12px;
        letter-spacing: -0.06px;

        p {
            margin: 0;
            margin-bottom: 10px;
        }
    }
`;

export const topicTagStyles = css`
    background: ${COLORS.DARK_SAPPHIRE};
    color: ${COLORS.WHITE};
    display: flex;
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 12px;
    font-weight: 600;
    height: 32px;
    letter-spacing: 0.5px;
    line-height: 13px;
    width: 100%;
    padding-right: 13px;
    position absolute;
    right: 0;
    text-decoration: none;
    text-transform: uppercase;
    top: 0;
    vertical-align: middle;
    z-index: 2;

    &:hover {
        cursor: pointer;
    }

    div:last-child {
        display: flex;
        flex-direction: column;
        flex: 1;
        text-align: right;

        >div:first-child {
            flex: 1;
        }
        >div:last-child {
            flex: 1;
        }
    }

    @media screen and ${FROM_DESKTOP} {
    }
`;

export const iconContainerStyles = css`
    background: ${COLORS.SAPPHIRE};
    display: inline-block;
    height: 32px;
    margin-left: 13px;
    max-height: 32px;
    width: 32px;

    svg {
        fill: ${COLORS.WHITE};
        stroke: ${COLORS.WHITE};
        width: 24px;
        height: 24px;
        margin-top: 1px;
        margin-left: 1px;
    }

    svg.icon-wrench {
        margin-top: 8px;
        margin-left: 8px;
        width: 16px;
        height: 16px;
        stroke: transparent;
    }
`;

export const titleContainerStyles = css`
    padding: 32px 14px 25px 14px;
    text-decoration: none;

    p {
        margin:0;
        font-size: 12px;
    }

    @media screen and ${FROM_DESKTOP} {
        p {
            font-size: 12px;
        }
    }
`;

export const titleStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    display: inline-block;
    font-size: 15px;;
    font-weight: 600;
    letter-spacing: -0.06px;
    line-height: 20px;
    margin: -4px 0 13px 0;
    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.TITLE.TIME.SECONDS}s all;

    div:last-child {
        font-size: 12px;
    }

    &:hover {
        color: ${COLORS.MEDIUM_GRAY2};
        cursor: pointer;
        opacity:0.6;
    }

    @media screen and ${FROM_TABLET} {
        font-size: 15px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 18px;
        letter-spacing: -0.1px;
        line-height: 24px;
    }
`;

export const footerLinksContainerStyles = css`
    color: ${COLORS.DARK_GRAY1};
    font-size: 12px;
    padding: 0 14px;
`;

export const footerLinksStyles = css`
    color: ${COLORS.SAPPHIRE};
    font-weight: 700;
    margin-bottom: 0;

    &:hover {
        color: ${COLORS.DARK_SAPPHIRE};
    }
`;

export const contentLinkContainerStyles = css `
    text-decoration: none;
    display: block;
    color: ${COLORS.BLACK};
    margin-left: 1px;
    margin-right: 1px;

    &:active {
        color: ${COLORS.BLACK};
    }

    ${props => props.isfooter && css`
        position: absolute;
        bottom: 5px;
    `}
`;

export const footerBytxtStyles = css `
    margin: 0 0 5px 0;
`;

export const toolCategoryStyles = css `
    color: ${COLORS.WHITE};
    text-decoration: none;
    font-weight: 500;

    &:hover {
      opacity:0.60;
    }
`;
