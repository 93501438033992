export default function isDesktop() {

    // return undefined if there is no touch support
    const supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

    // https://coderwall.com/p/i817wa/one-line-function-to-detect-mobile-devices-with-javascript
    const hasOrientation = (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
    
    const hasDeviceName = (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase()));

    return !supportsTouch || (hasDeviceName === false) || (hasOrientation === false);
}