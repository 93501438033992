import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Toast from 'components/atomics/molecules/Toast/Toast';
import { TYPE_RIGHT_CONTROLS } from 'components/atomics/molecules/Toast/Toast';
import styled from 'styled-components';

/**
 * Toast shown when user preforms an action resulting in non-fatal API failure.
 */
class FailedActionToast extends React.Component {
    render() {
        return (
            <Toast type={TYPE_RIGHT_CONTROLS}>
                <Toast.Title>
                    <span>
                        <FormattedMessage id="toast.failedAction.1" />
                        <br/>
                        <FormattedMessage id="toast.failedAction.2" />
                    </span>
                    <span>
                        <FormattedMessage id="toast.failedAction.1" />
                        <br/>
                        <FormattedMessage id="toast.failedAction.2" />
                    </span>
                </Toast.Title>
            </Toast>
        );
    }
}

FailedActionToast.propTypes = {
    actionType: PropTypes.string,
    id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    analyticsData: PropTypes.objectOf(PropTypes.any),
};

FailedActionToast.defaultProps = {
    actionType: '',
    id: 'failed-action-toast',
    analyticsData: {},
};

export default FailedActionToast;
