import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import styled from 'styled-components';
import SliderButton from 'components/SliderButton/SliderButton';
import { StepperLeftArrowIcon, StepperRightArrowIcon } from 'components/atomics/atoms/Icons';
import Dot from 'containers/Guide/ImageMetaphor/Dot';
import Slide from 'containers/Guide/ImageMetaphor/Slide';
import { buttonStyles, sliderStyles } from 'containers/Guide/ImageMetaphor/styles';

const StyledButton = styled(SliderButton)`
    ${buttonStyles};
`;

const StyledSlider = styled.div`
    ${sliderStyles}
`;

/**
 * Render a guide image carousel.
 * @param {*} props 
 */
const ImageMetaphor = (props) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: dots => <div>{dots}</div>,
        customPaging: i => <Dot id={i} />,
        nextArrow: (
            <StyledButton id="image-metaphor-next-button">
                <StepperRightArrowIcon />
            </StyledButton>
        ),
        adaptiveHeight: true,
        prevArrow: (
            <StyledButton id="image-metaphor-previous-button">
                <StepperLeftArrowIcon />
            </StyledButton>
        ),
    };

    return (
        <StyledSlider>
            <Slider {...settings}>
                {props.imageStories.map((title, i) => (
                    <Slide id={i} key={`key_${title.description}`} title={title} />
                ))}
            </Slider>
        </StyledSlider>
    );
};

ImageMetaphor.defaultProps = {
    imageStories: [],
};

ImageMetaphor.propTypes = {
    id: PropTypes.string.isRequired,
    imageStories: PropTypes.arrayOf(PropTypes.shape({
        image: PropTypes.string,
        metaphor: PropTypes.string,
        description: PropTypes.string,
    })),
};

export default ImageMetaphor;