import { css } from 'styled-components';
import { FROM_TABLET, FROM_MOBILE_TO_TABLET, FROM_DESKTOP } from 'utils/mediaQueries';
import { COLORS, RGB_COLORS } from 'utils/variables';

export const wrapperStyles = css`
    align-items: center;
    background-color: rgba(${RGB_COLORS.DARK_SAPPHIRE}, 0.03);
    color: ${COLORS.WHITE};
    display: flex;
    flex-direction: column;
    height: 160px;
    justify-content: center;
    position: relative;

    @media screen and ${FROM_TABLET} {
        height: 228px;
    }

    @media screen and ${FROM_DESKTOP} {
        height: 427px;
    }
`;

export const imageWrapperStyles = css`
    height: 100%;
    left: 0;
    margin: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &::before {
        background-color: rgba(${RGB_COLORS.BLACK}, 0.4);
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
    }

    @media screen\0 and ${FROM_DESKTOP} {
        img {
            transform: translate(0%, -50%) !important;
        }
    }
`;

export const titleContainerStyles = css`
    max-width: 1170px;
    width: 100%;
    margin: auto;
    position: relative;
    z-index: 2;
    text-align: center;
    padding: 0;


    @media screen\0 {
       top:-40%;
    }
`;

export const titleStyles = css`
    font-size: 32px;
    font-weight: 600;
    letter-spacing: -0.4px;

    @media screen and ${FROM_TABLET} {
        font-size: 48px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 56px;
    }
`;

export const subtitleStyles = css`
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    margin-top: 10px;
    text-transform: uppercase;

    @media screen and ${FROM_TABLET} {
        margin-top: 20px;
        font-size: 12px;
    }
    @media screen and ${FROM_DESKTOP} {
        font-size: 18px;
    }

`;
