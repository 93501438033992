import * as type from 'state-management/constants/followTopic';

export const toggleTopicFollow = (id, isFollowing, typeValue, showToast = true) => ({
    type: type.TOGGLE_FOLLOW_TOPIC,
    id,
    isFollowing,
    typeValue,
    showToast,
});

export const toggleTopicFollowSuccess = data => ({
    data,
    type: type.TOGGLE_FOLLOW_TOPIC_SUCCESS,
});

export const toggleTopicFollowFailure = error => ({
    error,
    type: type.TOGGLE_FOLLOW_TOPIC_FAIL,
});
