import { css } from 'styled-components';
import { COLORS} from '../../utils/variables';
import { SMALL_MOBILE, FROM_TABLET, FROM_DESKTOP, FROM_MOBILE_TO_TABLET} from '../../utils/mediaQueries';

export const progressBarWrapperStyles = css`
    height: 5px;
    position: relative;
    width: 100%;
    /* New Color code(TRANSPARENT_PURPLE) is intruduced to overcome technical limitation 
       Opacity of parent DIV gets applied to child DIV       
     */
    background-color: ${COLORS.TRANSPARENT_OCEAN};
    @media screen and ${SMALL_MOBILE} {
        height: 6px;
    }
    @media screen and ${FROM_TABLET} {
        height: 6px;
    }
    @media screen and ${FROM_DESKTOP} {
        height: 10px;
    }
`;

export const progressBarStyles = css`
    background-color: ${COLORS.SAPPHIRE};
    left: 0;
    position: absolute;    
    opacity: 1;
    /* stylelint-disable */

    ${props => props.variant && props.variant.offset !== null && !Number.isNaN(props.variant.offset) && css`
        width: ${props.variant.offset}px;
    `};
    
    ${props => props.variant && props.variant.offset === null && css`
        width: ${(props.variant.valueNow * 100) / props.valueMax}%;
    `};

    ${props => props.variant && props.variant.isAnimated && css`
        transition: width 0.5s;
    `};
    /* stylelint-enable */
    @media screen and ${SMALL_MOBILE} {
        height: 6px;
    }
    @media screen and ${FROM_MOBILE_TO_TABLET} {
        height: 6px;
    }
    @media screen and ${FROM_TABLET} {
        height: 6px;
    }
    @media screen and ${FROM_DESKTOP} {
        height: 10px;
    }
`;
