import { css } from 'styled-components';
import { FROM_TABLET, SMALL_MOBILE } from '../../utils/mediaQueries';
import {
    COLORS,
    FONT_AVENIR_NEXT,
} from 'utils/variables';

export const tabsStyles = css`
    margin: 0;
    width: 100%;
`;

export const tabListStyles = css`
    /* stylelint-disable */

    ${props => props.variant && props.variant.fullWidth && css`
        margin: 0 -16px;
        display: flex;
        margin-left: 15px;
    `};

    ${props => props.variant && props.variant.appearance === 'default' && css`

    `};

    ${props => props.variant && props.variant.appearance === 'primary' && css`

    `};

    /* stylelint-enable */

    @media screen and ${FROM_TABLET} {
        margin: 0;
    }
`;

export const tabItemStyles = css`
    background-color: transparent;
    border: 0;
    color: ${COLORS.BLACK};
    cursor: pointer;
    flex: 1;
    font-family: ${FONT_AVENIR_NEXT};
    text-transform: uppercase;
    font-size: 13px;
    line-height: 31px;
    letter-spacing: 1.08px;
    font-weight: 600;
    padding: 10px;
    padding-bottom: 0;
    word-wrap: break-word;
    text-decoration:none;
    /* stylelint-disable */

    ${props => props.variant && props.variant.appearance === 'default' && css`
        padding: 8px 0 10px 0;
        margin-bottom: -2px;

        ${props => !props.noSpace && css`
            margin-right: 24px;
        `};

        @media screen and ${SMALL_MOBILE} {
            ${props => !props.noSpace && css`
                margin-right: 15px;
            `};
            
            font-size:12px;
            padding-right:0px;
        }

        ${props => props.variant && !props.variant.isActive && css`
            padding-bottom: 14px;

            &:hover {
                background-color:transparent;
                border-bottom: 4px Solid ${COLORS.TRANSPARENT_OCEAN}; /* bright purple + opacity setting */
                padding-bottom: 10px;
            }
        `};
    `};

    ${props => props.variant && props.variant.appearance === 'primary' && css`
        background-color: ${COLORS.WHITE};
        font-size: 14px;
        width: 100%;
        &:not(:last-child) {
            border-right: 1px solid ${COLORS.LIGHT_GRAY3};
        }
    `};

    ${props => props.variant && props.variant.isActive && css`
        font-weight: 700;
        z-index: 1;
    `};

    ${props => props.variant && props.variant.theme === 'light-blue' && props.variant.isActive && css`
        border-bottom: 4px solid ${COLORS.OCEAN};
    `};

    ${props => props.variant && props.variant.theme === 'dark-blue' && props.variant.isActive && css`
        border-bottom: 4px solid ${COLORS.SAPPHIRE};
    `};
`;

export const tabPanelStyles = css`
    margin-top:10px;
    width: 100%;
`;
