import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Title from 'components/atomics/atoms/Title/Title';
import { IconExclamation } from 'components/atomics/atoms/Icons/Icons';
import {
    keyContainerStyles,
    rowStyles,
    valueContainerStyles,
    containerStyles,
    linkStyles,
    headerStyles,
    footerStyles,
    notificationStyles,
    notificationIconStyles,
    notificationTextStyles
} from './styles';
import { getExternalEditProfileURL } from 'utils/urlUtils';

export const StyledInfoRow = styled.dl`
    ${rowStyles};
`;

export const StyledRowKey = styled.div`
    ${keyContainerStyles};
`;

export const StyledRowValue = styled.div`
    ${valueContainerStyles};
`;

export const StyledContainer = styled.div`
    ${containerStyles};
`;

export const StyledLink = styled.a`
    ${linkStyles};
`;

export const StyledHeader = styled.div`
    ${headerStyles};
`;

export const StyledFooter = styled.div`
    ${footerStyles};
`;

export const StyledNotificationContainer = styled.div`
    ${notificationStyles};
`;

export const StyledNotificationIcon = styled(IconExclamation)`
    ${notificationIconStyles};
`;

export const StyledNotificationText = styled.span`
    ${notificationTextStyles};
`;

const keys = [
    'firstName',
    'lastName',
    'suffix',
    'nickName',
    'userName',
    'email',
    'crd',
    'iard'
];

/**
 * View (not edit) version of account information.
 * @param {*} props 
 */
const ViewMigratedAccountInformation = (props) => {
    const { profile } = props;

    if (!profile) return null;

    // for migrated users, "Edit on capitalgroup.com" link
    const renderExternalEditLink = () => (
        <StyledLink href={getExternalEditProfileURL()} target="_blank">
            <FormattedMessage id="profilePage.migrated.link" />
        </StyledLink>
    );
    
    return (
        <>
            <StyledHeader>
                <Title>
                    <FormattedMessage id="profilePage.migrated.title" />
                </Title>
                <FormattedMessage id="profilePage.migrated.description" values={{ link: renderExternalEditLink() }} />
                {profile.infoIncorrect === true && (
                    <StyledNotificationContainer>
                        <StyledNotificationIcon />
                        <StyledNotificationText>
                            <FormattedMessage id="profilePage.migrated.pending" />
                        </StyledNotificationText>
                    </StyledNotificationContainer>
                )}
            </StyledHeader>

            <StyledContainer>
                {keys.map((key) => profile[key] && (
                    <StyledInfoRow key={key} data-value={key}>
                        <StyledRowKey>
                            <FormattedMessage id={`profilePage.accountInformation.${key}`} />
                            <StyledRowValue>{profile[key]}</StyledRowValue>
                        </StyledRowKey>
                    </StyledInfoRow>
                ))}
            </StyledContainer>

            {(profile.proEmail && profile.proEmail !== profile.email) && (
                <>
                    <StyledFooter>
                        <Title>
                            <FormattedMessage id="profilePage.migrated.pro.title" />
                        </Title>
                        <FormattedMessage id="profilePage.migrated.pro.description" />
                    </StyledFooter>
                    <StyledInfoRow>
                        <StyledRowKey>
                            <FormattedMessage id={`profilePage.accountInformation.email`} />
                            <StyledRowValue>{profile.proEmail}</StyledRowValue>
                        </StyledRowKey>
                    </StyledInfoRow>
                </>
            )}
        </>
    );
};

ViewMigratedAccountInformation.propTypes = {
    profile: PropTypes.shape({}).isRequired,
    aums: PropTypes.arrayOf(PropTypes.object).isRequired,
    amounts: PropTypes.arrayOf(PropTypes.object).isRequired,
    durations: PropTypes.arrayOf(PropTypes.object).isRequired,
    serviceOfferings: PropTypes.arrayOf(PropTypes.object).isRequired,
    isClassificationsLoaded: PropTypes.bool.isRequired,
    wmAum: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => ({
    profile: state.basicProfile,
    amounts: state.advisorClassifications.amounts,
    aums: state.advisorClassifications.aums,
    durations: state.advisorClassifications.durations,
    serviceOfferings: state.advisorClassifications.serviceOfferings,
    isClassificationsLoaded: state.advisorClassifications.isLoaded,
    wmAum: state.advisorClassifications.wmAum,
});

export default connect(mapStateToProps)(ViewMigratedAccountInformation);
