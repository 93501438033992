import React from 'react';
import { notInterestedIn as notInterestedInAction } from 'state-management/actions/profileRefinementCard';
import MobileAppNotificationMolecule from 'components/atomics/molecules/MobileAppNotification/MobileAppNotification';
import storage from 'utils/store';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getIsAppInstalled, setIsAppInstalled } from 'state-management/actions/basicProfile';
import labels from '../../lang/en.json';
import { TYPE_TESTFLIGHT } from 'components/atomics/molecules/NudgeCard/NudgeCard';
import { APPLICATION_URL_GOOGLE_PLAY, APPLICATION_URL_APPLE_STORE } from 'utils/globalConstants';
import {
    isAndroid,
} from 'react-device-detect';

const isIOSMobile = navigator.userAgent.indexOf('(iPhone') > -1;

export const MOBILE_APP_NOTIFICATION = 'mobileAppNotificationData';
export const ONBOARDING_PAGES = 'onboarding';

/**
 * Market briefing page component, displays a full single market briefing.
 */
class MobileAppNotification extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        const noteData = storage.get(MOBILE_APP_NOTIFICATION);

        let noteParsedData = { dismissed: false };

        if (noteData) {
            noteParsedData = JSON.parse(noteData);
        }

        this.state = {
            isDismissed: noteParsedData.dismissed,
        }

        this.handleDismiss = this.handleDismiss.bind(this);
        this.handleFinalDismiss = this.handleFinalDismiss.bind(this);
        this.dismissRefinementCard = this.dismissRefinementCard.bind(this);
    }

    /**
     * Check app installed state from backend, if logged in.
     */
    componentDidMount() {
        if (this.props.userName !== null && this.props.userName.length
            && (isIOSMobile || isAndroid)) {
            this.props.getAppInstalled();
        }
    }

    /**
     * At login the footer is already loaded, so we have to recheck storage post login by knowing when username updates.
     * @param {*} prevProps
     */
    componentDidUpdate(prevProps) {
        const { userName } = this.props;

        if (prevProps.userName !== userName) {
            const noteData = storage.get(MOBILE_APP_NOTIFICATION);

            if (noteData) {
                const noteParsedData = JSON.parse(noteData);

                this.setState({
                    isDismissed: noteParsedData.dismissed,
                })
            }
        }
    }

    /**
     * Dismiss the mobile app refinement card if present.
     */
    dismissRefinementCard() {
        //this.props.setAppInstalled();
        this.props.notInterestedIn({
            "type": TYPE_TESTFLIGHT,
            "id": "1",
        });

        //if (this.props.profileRefinementCard.type === TYPE_TESTFLIGHT) {
        //    this.props.notInterestedIn(this.props.profileRefinementCard);
        //}
    }

    //handle final dismiss action
    handleFinalDismiss() {
        this.props.setAppInstalled();

        this.dismissRefinementCard();
    }

    /**
     * Handle dismissal and set storage (other end of the storage is touched at signin saga).
     */
    handleDismiss() {
        //this.dismissRefinementCard();

        this.setState({
            isDismissed: true,
        });

        const storageValue = storage.get(MOBILE_APP_NOTIFICATION) != null
            ? JSON.parse(storage.get(MOBILE_APP_NOTIFICATION))
            : null;

        if (storageValue != null) {
            storage.set(MOBILE_APP_NOTIFICATION,  JSON.stringify(
                {
                    ...storageValue,
                    loginCount: 1,
                    dismissed: true,
                }
            ));
        }
    }

    /**
     * Render the notification molecule.
     */
    render() {
        const url = window.location.href;

        /* dont show things if related API is down */
        if (this.props.isAppProposeDeclined === null) {
            return null;
        }

        /* dont show banner on specific pages */
        const location = window.location.href;

        if (location.indexOf("download") > -1 || location.indexOf("profile") > -1 || location.indexOf("onboarding") > -1) {
            return null;
        }

        const noteData = storage.get(MOBILE_APP_NOTIFICATION);
        const noteParsedData = noteData != null ? JSON.parse(noteData) : null;

        let showAlternate = false;

        if (noteParsedData) {
            if (noteParsedData.showAlternate) {
                showAlternate = true;
            }
        }

        const isLoggedIn = (this.props.userName !== null && this.props.userName.length) ? true : false;
        const isValidPAgeForNotification = url.includes(ONBOARDING_PAGES) ? false : true;
        const downloadUrl = isAndroid ? APPLICATION_URL_GOOGLE_PLAY
            : APPLICATION_URL_APPLE_STORE;

        if (isValidPAgeForNotification === true) {
            return (
                <MobileAppNotificationMolecule
                    url={downloadUrl}
                    title={labels.translations["mobileAppNotification.title"]}
                    description={labels.translations["mobileAppNotification.description"]}
                    acceptLabel={labels.translations["mobileAppNotification.accept"]}
                    dismissLabel={labels.translations["mobileAppNotification.dismiss"]}
                    isDismissed={this.state.isDismissed || this.props.isAppProposeDeclined || this.props.isiOSAppInstalled}
                    showAlternate={noteParsedData && noteParsedData.hasOwnProperty('hideLumen') && noteParsedData.hideLumen }
                    dismissAction={this.handleDismiss}
                    dismissFinalAction={this.handleFinalDismiss}
                    isLoggedIn={isLoggedIn}
                    isIOSMobile={isIOSMobile || isAndroid}
                />
            );
        } else {
            return null;
        }
    }
}

MobileAppNotification.propTypes = {
    userName: PropTypes.string,
    isAppProposeDeclined: PropTypes.bool,
}

MobileAppNotification.defaultProps = {
    userName: null,
    isAppProposeDeclined: true,
}

const mapStateToProps = state => ({
    userName: state.basicProfile.firstName,
    isAppProposeDeclined: state.basicProfile.isAppProposeDeclined,
    isiOSAppInstalled: state.basicProfile.isiOSAppInstalled,
    profileRefinementCard: state.profileRefinementCard,
});

const mapDispatchToProps = dispatch => ({
    getAppInstalled: () => dispatch(getIsAppInstalled()),
    setAppInstalled: () => dispatch(setIsAppInstalled()),
    notInterestedIn: data => dispatch(notInterestedInAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileAppNotification);
