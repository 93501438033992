import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { createImageUrl } from 'utils/urlUtils';
import { setScrollToTop } from 'state-management/actions/scroll';
import { FRAGMENT_PRIVACY_POLICY } from 'state-management/constants/commonContentFragments';
import { getContentFragments } from 'state-management/actions/commonContentFragments';
import {
    ImageHeading,
    Loader,
    SanitizedHTML,
    TextBlock,
} from 'components';
import { PAGE_NAME_PRIVACY_POLICY, SITE_SECTION_PRIVACY_POLICY } from 'utils/analyticsConstants';

const COVER_IMAGE_URL = createImageUrl('hero-images', 'privacy-policy.jpg');
const MOBILE_COVER_IMAGE_URL = createImageUrl('hero-images', 'privacy-policy-mobile.jpg');

/**
 * Privacy policy pge.
 */
class PrivacyPolicy extends React.Component {
    /**
     * Gather needed data at mount.
     */
    componentDidMount() {
        const {
            privacyPolicy,
            getPrivacyPolicy,
            scrollToTop,
        } = this.props;

        scrollToTop();
        if (!privacyPolicy) {
            getPrivacyPolicy();
        }
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const { privacyPolicy } = this.props;

        if (!privacyPolicy) {
            return <Loader />;
        }

        let updateDate = null;
        updateDate = privacyPolicy.hasOwnProperty('date')
            ? moment.utc(new Date(privacyPolicy.date)).format('MMMM D, YYYY')
            : updateDate;

        const headingTitle = <FormattedMessage id="privacyPolicy.title" />;
        const headingSubtitle = updateDate && (
            <FormattedMessage id="privacyPolicy.lastUpdated" values={{ date: updateDate }} />
        );

        return (
            <Fragment>
                <ImageHeading
                    title={headingTitle}
                    subtitle={headingSubtitle}
                    image={COVER_IMAGE_URL}
                    smallImage={MOBILE_COVER_IMAGE_URL}
                />
                <TextBlock>
                    <SanitizedHTML htmlContent={privacyPolicy.text} tag="div" />
                </TextBlock>
            </Fragment>
        );
    }
}

PrivacyPolicy.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    privacyPolicy: PropTypes.shape({
        text: PropTypes.string.isRequired,
        date: PropTypes.string,
    }),
    getPrivacyPolicy: PropTypes.func.isRequired,
    scrollToTop: PropTypes.func.isRequired,
};

PrivacyPolicy.defaultProps = {
    privacyPolicy: undefined,
};

const mapStateToProps = state => ({
    isLoading: state.commonContentFragments.isLoading,
    privacyPolicy: state.commonContentFragments.fragments[FRAGMENT_PRIVACY_POLICY],
});

const mapDispatchToProps = dispatch => ({
    getPrivacyPolicy: () => dispatch(getContentFragments(FRAGMENT_PRIVACY_POLICY, true)),
    scrollToTop: () => dispatch(setScrollToTop()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
