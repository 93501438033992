import React from 'react';
import PropTypes from 'prop-types';

import { getFormattedDate } from 'utils/formatters/getFormattedDate';
import { ARTICLE_PUBLISH_DATE_FORMAT } from 'state-management/constants/articles.common';

const FormattedDate = ({ date, dateFormat, ...other }) => (
    <span {...other}>{getFormattedDate(date, dateFormat)}</span>
);

FormattedDate.propTypes = {
    date: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]).isRequired,
    dateFormat: PropTypes.string,
};

FormattedDate.defaultProps = {
    dateFormat: ARTICLE_PUBLISH_DATE_FORMAT,
};

export default FormattedDate;
