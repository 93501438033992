export const SAVED_CONTENT_DATA_URL = '/content-service/global-saved-content';

export const GET_SAVED_CONTENT_DATA = 'savedContent/GET_SAVED_CONTENT_DATA';
export const GET_SAVED_CONTENT_FAIL = 'savedContent/GET_SAVED_CONTENT_FAIL';
export const SET_SAVED_CONTENT_DATA = 'savedContent/SET_SAVED_CONTENT_DATA';
export const REMOVE_SAVED_ITEM = 'REMOVE_SAVED_ITEM';

export const TOGGLE_SAVE_ARTICLE = 'savedContent/TOGGLE_SAVE_ARTICLE';
export const TOGGLE_SAVE_ARTICLE_SUCCESS = 'savedContent/TOGGLE_SAVE_ARTICLE_SUCCESS';
export const TOGGLE_SAVE_ARTICLE_FAIL = 'savedContent/TOGGLE_SAVE_ARTICLE_FAIL';

export const TOGGLE_SAVE_CECREDIT = 'savedContent/TOGGLE_SAVE_CECREDIT';
export const TOGGLE_SAVE_CECREDIT_SUCCESS = 'savedContent/TOGGLE_SAVE_CECREDIT_SUCCESS';
export const TOGGLE_SAVE_CECREDIT_FAILURE = 'savedContent/TOGGLE_SAVE_CECREDIT_FAILURE';

export const PAGE_SIZE = 15;
export const TYPE_ARTICLE = 'ARTICLE';
export const TYPE_GUIDE = 'GUIDE';
export const TYPE_CECREDIT = 'CECREDIT';

export const TOGGLE_SAVE_PL_ARTICLE = 'save/TOGGLE_SAVE_PL_ARTICLE';
export const TOGGLE_SAVE_PL_ARTICLE_SUCCESS = 'save/TOGGLE_SAVE_PL_ARTICLE_SUCCESS';
export const TOGGLE_SAVE_PL_ARTICLE_FAILURE = 'save/TOGGLE_SAVE_PL_ARTICLE_FAILURE';