import { FONT_AVENIR_NEXT } from 'utils/variables';

export default `
    html {
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        scroll-behavior: smooth;
    }

    body {
        font-family: ${FONT_AVENIR_NEXT};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 0;
        min-width: 320px;
        padding: 0;
        background-color: #fbfbfb;
    }
    
    /* added to the body element when shown; prevent scrolling */
    .ReactModal__Body--open {
        overflow: hidden;
    }

    .ReactModalPortal .ReactModal__Overlay--after-open{
        z-index: 9999 !important;
    }
`;
