import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import storage from 'utils/store';
import { GLOBAL } from 'utils/variables';
import { HEADLINES_ROUTE } from './../../../state-management/constants/news';
import { getTopNewsItems } from 'state-management/actions/news';
import { SIZE as CARD_SIZE } from 'components/ContentCard/constants';
import { dismissCoachMark } from 'state-management/actions/coachMark';
import language from '../../../lang/en.json';
import anime from 'animejs';
import { StyledLoader, defaultOptions } from 'components/Loader/Loader';
import {
    BreakpointsContext,
    DESKTOP,
    MOBILE,
    TABLET,
} from 'components/Breakpoints/Breakpoints';
import Row from 'components/atomics/atoms/Row/Row';
import Button from 'components/atomics/atoms/Button/Button';
import Column from 'components/atomics/atoms/Column/Column';
import {
    TYPE_GHOST_RIGHT,
    TYPE_PRIMARY_MEDIUM,
} from 'components/atomics/atoms/Button/Button';
import {
    TYPE_4COLUMN,
    TYPE_3COLUMN,
} from 'components/atomics/atoms/Row/Row';
import {
    TYPE_3ROW,
    TYPE_4ROW,
} from 'components/atomics/atoms/Column/Column';
import ListCard from 'components/atomics/molecules/ListContentCard/ListContentCard';
import StandardContentCard from '../../../components/atomics/molecules/StandardContentCard/StandardContentCard';
import HeroContentCardCollection from '../../../components/atomics/organisms/HeroContentCardCollection/HeroContentCardCollection';
import {
    Container,
    Heading,
} from 'components';
import {
    headerStyles,
    sectionStyles,
    cardsArea,
    listArea,
    buttonContainer,
    listAreaCard,
    listAreaCardWrapper,
    loaderStyles,
    blankAreaStyles,
} from 'containers/News/TopNews/styles';
import {
    headingStyles,
} from 'containers/Guides/GuidesContainer/styles';
import { getCoachMarkStatus } from 'state-management/actions/coachMark';
import { COACH_MARK_SAVE_CONFIRMATION } from 'state-management/constants/coachMark';
import ProfileRefinementCardContainer from './../../../containers/News/ProfileRefinementCardContainer/ProfileRefinementCardContainer';
import { getProfileRefinementCard as getProfileRefinementCardAction } from 'state-management/actions/profileRefinementCard';
import { transformContentItem } from "utils/contentCardUtils";

export const TOP_NEWS_DATA = 'topNewsData';
export const ENV_FOR_INTERACTION = 'qa';

export const ListAreaCard = styled.div`
    ${listAreaCard};
`;

export const ListAreaCardWrapper = styled.div`
    ${listAreaCardWrapper}
`;

export const ButtonContainer = styled.div`
    ${buttonContainer};
`;

export const StyledSection = styled.section`
    ${sectionStyles};
`;

export const StyledHeader = styled.div`
    ${headerStyles};
`;

export const StyledHeading = styled(Heading)`
    ${headingStyles};
`;

export const ListArea = styled.div`
    ${listArea}
`;

export const CardsArea = styled.div`
    ${cardsArea};
`;

const StyledLoaderContainer = styled.div`
    ${loaderStyles}
`;

const StyledBlankArea = styled.div`
    ${blankAreaStyles}
`;

export const getSizeProps = (idx, internal) => {
    if (idx < 2 || internal) {
        return {
            size: CARD_SIZE.STANDARD,
        };
    }

    return {
        size: CARD_SIZE.STANDARD,
        mobile: CARD_SIZE.SMALL,
    };
};

let replacedIndex = null;

/**
 * Top news section of news page.
 */
class TopNews extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.state = {
            savedNewsId: null,
            cardPosition: 9,
        };

        this.renderListItem = this.renderListItem.bind(this);
        this.animateClosed = this.animateClosed.bind(this);

        this.contentRef = React.createRef();
    }

    /**
     * Get required data at mount.
     */
    componentDidMount() {
        this.props.getProfileRefinementCard();
        this.props.getCoachMarkStatus();
        this.props.getTopNews();

        this.setState({
            cardPosition: Math.floor(Math.random() * Math.floor(12)) + 1,
        });
    }

    /**
     * Run the components close animation if the data failed to load.
     * @param {*} nextProps 
     */
    componentWillUpdate(nextProps) {
        if (nextProps.loadingFailed === true) {
            this.animateClosed();
        }
    }

    /**
     * Collapse this component closed (and opacity to 0) from bottom to top.
     * Also inform the parent container this component has failed.
     */
    animateClosed() {
        const contentNode = this.contentRef.current;

        this.animation = anime.timeline();

        this.animation
            .add({
                targets: contentNode,
                easing: [0.53, 0.05, 0.01, 0.97],
                opacity: [1, 0],
                duration: GLOBAL.ANIMATIONS.COLLAPSE_TIME.MILISECONDS,
                height: 0,
            });

        this.props.handleComponentFailure();
    }

    /**
     * Gather related refinement card data.
     */
    getNewsWithRefinementCard = () => {
        const {
            news,
            hasRefinementCard,
        } = this.props;

        const refinementCardPosition = 6;
        const topNewsLength = 9;

        if (hasRefinementCard && news.length === topNewsLength) {
            return [
                ...news.slice(0, refinementCardPosition),
                { isRefinementCard: true },
                ...news.slice(refinementCardPosition, 8),
            ];
        }

        return news;
    };

    /**
     * Transform article data to be in line with molecule property requirements.
     * @param {*} start 
     * @param {*} end 
     */
    getArticles(start, end) {
        const articles = [];
        const firstItems = this.props.news.slice(start, end);

        Object.keys(firstItems).forEach(function (key, index) {
            articles.push(transformContentItem(firstItems[index]));
        });

        return articles;
    };

    /**
     * Render interaction ID for debugging.
     * @param {*} newsItems 
     */
    renderInteractionId(newsItems) {
        if (window.envParsedFromUrl === ENV_FOR_INTERACTION) {
            if (newsItems.length > 0) {
                return (<span>{newsItems[0].interactionId}</span>);
            }
        }
    }

    /**
     * Render a news item for mobile view list.
     * @param {*} item
     */
    renderListItem = (item, idx) => {
        const { showCoachMark } = this.props;
        if (this.props.hasRefinementCard && idx + 1 === this.state.cardPosition) {
            return (
                <ListAreaCardWrapper>
                    <ListAreaCard>
                        <ProfileRefinementCardContainer
                            index={idx}
                            key={idx}
                            isLoaded={true}
                        />
                    </ListAreaCard>
                </ListAreaCardWrapper>
            );
        }

        return (
            <ListCard
                activateDismissCoachMark={showCoachMark}
                key={idx}
                article={item}
            />
        );
    }

    /**
     * Render a standard content card.
     */
    renderStandardCard = (articleIndex, index, articles) => {
        const { showCoachMark, hasRefinementCard } = this.props;
        const { cardPosition } = this.state;

        if (hasRefinementCard && index === cardPosition) {
            replacedIndex = articleIndex;

            return (
                <ProfileRefinementCardContainer
                    key={99}
                    index={99}
                    isLoaded={true}
                />
            );
        } else {
            let article = articles[articleIndex];

            if (article == null && replacedIndex != null) {
                article = articles[replacedIndex];
                replacedIndex = null;
            }

            if (article == null) {
                return null;
            }

            return (
                <StandardContentCard
                    activateDismissCoachMark={showCoachMark}
                    article={article}
                />
            );
        }
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const news = this.getNewsWithRefinementCard();
        const heroArticles = this.getArticles(0, 4);
        const bodyArticles = this.getArticles(4, 16);
        const { isLoaded } = this.props;

        storage.set(TOP_NEWS_DATA, JSON.stringify(news.filter(item => !!item.id).map(item => item.id)));

        if (typeof (this.props.hasRefinementCard) === 'undefined') {
            return null;
        }

        return (
            <StyledSection ref={this.contentRef}>
                <Container noMobilePadding={true}>
                    <StyledHeader>
                        <StyledHeading
                            level={2}
                        >
                            <FormattedMessage id="topNews.title" /> {this.renderInteractionId(news)}
                        </StyledHeading>
                    </StyledHeader>

                    {!isLoaded
                        && <StyledBlankArea>
                            <StyledLoaderContainer>
                                <StyledLoader
                                    options={defaultOptions}
                                />
                            </StyledLoaderContainer>
                        </StyledBlankArea>}

                    {isLoaded
                        && <HeroContentCardCollection
                            dismissCoachMark={this.props.dismissCoachMark}
                            showCoachMark={this.props.showCoachMark}
                            articles={heroArticles}
                        />}

                    {isLoaded
                        && <CardsArea>
                            <BreakpointsContext.Consumer>
                                {(breakpoint) => {
                                    if (breakpoint === DESKTOP) {
                                        return (
                                            <Column type={TYPE_3ROW}>
                                                <Row type={TYPE_4COLUMN}>
                                                    {this.renderStandardCard(0, 1, bodyArticles)}
                                                    {this.renderStandardCard(1, 2, bodyArticles)}
                                                    {this.renderStandardCard(2, 3, bodyArticles)}
                                                    {this.renderStandardCard(3, 4, bodyArticles)}
                                                </Row>
                                                <Row type={TYPE_4COLUMN}>
                                                    {this.renderStandardCard(4, 5, bodyArticles)}
                                                    {this.renderStandardCard(5, 6, bodyArticles)}
                                                    {this.renderStandardCard(6, 7, bodyArticles)}
                                                    {this.renderStandardCard(7, 8, bodyArticles)}
                                                </Row>
                                                <Row type={TYPE_4COLUMN}>
                                                    {this.renderStandardCard(8, 9, bodyArticles)}
                                                    {this.renderStandardCard(9, 10, bodyArticles)}
                                                    {this.renderStandardCard(10, 11, bodyArticles)}
                                                    {this.renderStandardCard(11, 12, bodyArticles)}
                                                </Row>
                                            </Column>
                                        );
                                    }

                                    if (breakpoint === TABLET) {
                                        return (
                                            <Column type={TYPE_4ROW}>
                                                <Row type={TYPE_3COLUMN}>
                                                    {this.renderStandardCard(0, 1, bodyArticles)}
                                                    {this.renderStandardCard(1, 2, bodyArticles)}
                                                    {this.renderStandardCard(2, 3, bodyArticles)}
                                                </Row>
                                                <Row type={TYPE_3COLUMN}>
                                                    {this.renderStandardCard(3, 4, bodyArticles)}
                                                    {this.renderStandardCard(4, 5, bodyArticles)}
                                                    {this.renderStandardCard(5, 6, bodyArticles)}
                                                </Row>
                                                <Row type={TYPE_3COLUMN}>
                                                    {this.renderStandardCard(6, 7, bodyArticles)}
                                                    {this.renderStandardCard(7, 8, bodyArticles)}
                                                    {this.renderStandardCard(8, 9, bodyArticles)}
                                                </Row>
                                                <Row type={TYPE_3COLUMN}>
                                                    {this.renderStandardCard(9, 10, bodyArticles)}
                                                    {this.renderStandardCard(10, 11, bodyArticles)}
                                                    {this.renderStandardCard(11, 12, bodyArticles)}
                                                </Row>
                                            </Column>
                                        );
                                    }

                                    if (breakpoint === MOBILE) {
                                        return (
                                            <ListArea>
                                                {bodyArticles.map(this.renderListItem)}
                                            </ListArea>
                                        );
                                    }
                                }}
                            </BreakpointsContext.Consumer>
                        </CardsArea>}

                    {isLoaded
                        && <BreakpointsContext.Consumer>
                            {(breakpoint) => {
                                if (breakpoint === MOBILE) {
                                    return (
                                        <ButtonContainer>
                                            <Button
                                                type={TYPE_PRIMARY_MEDIUM}
                                                to={`${HEADLINES_ROUTE}`}
                                            >
                                                <FormattedMessage id="topNews.more" />
                                            </Button>
                                        </ButtonContainer>
                                    );
                                } else {
                                    return (
                                        <ButtonContainer>
                                            <Button
                                                type={TYPE_GHOST_RIGHT}
                                                to={`${HEADLINES_ROUTE}`}
                                                data-analytics-placement="Anchor : body"
                                                data-analytics-label="trackLink : anchor"
                                                data-analytics-id={language.translations["topNews.more"]}
                                            >
                                                <FormattedMessage id="topNews.more" />
                                            </Button>
                                        </ButtonContainer>
                                    );
                                }
                            }}
                        </BreakpointsContext.Consumer>}
                </Container>
            </StyledSection>
        );
    }
}

TopNews.propTypes = {
    isLoaded: PropTypes.bool,
    loadingFailed: PropTypes.bool,
    isSavePending: PropTypes.bool,
    news: PropTypes.arrayOf(PropTypes.shape({})),
    getCoachMarkStatus: PropTypes.func.isRequired,
    getProfileRefinementCard: PropTypes.func.isRequired,
    hasRefinementCard: PropTypes.bool.isRequired,
    isSeeMoreLinkVisible: PropTypes.bool,
    handleComponentFailure: PropTypes.func,
};

TopNews.defaultProps = {
    isLoaded: false,
    loadingFailed: false,
    isSavePending: false,
    news: [],
    isSeeMoreLinkVisible: true,
    handleComponentFailure: () => { },
};

const mapStateToProps = state => ({
    isLoaded: state.topNews.isLoaded && state.topNews.news.length > 0,
    loadingFailed: state.topNews.topNewsLoadingFailed,
    isSavePending: state.coachMark.display[COACH_MARK_SAVE_CONFIRMATION],
    news: state.topNews.news,
    hasRefinementCard: state.profileRefinementCard.hasSuggestion,
    showCoachMark: state.coachMark.display.KEBAB && !state.coachMark.display.INFO_BAR,
});

const mapDispatchToProps = dispatch => ({
    getCoachMarkStatus: () => dispatch(getCoachMarkStatus()),
    getProfileRefinementCard: () => dispatch(getProfileRefinementCardAction()),
    getTopNews: () => dispatch(getTopNewsItems()),
    dismissCoachMark: () => { dispatch(dismissCoachMark('KEBAB', true)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(TopNews);

