import store from 'store2';

const storageNameSpace = 'digital_prospecting';

export function getStorage() {
    const storage = store.namespace(storageNameSpace);

    return storage;
}

export default getStorage();
