import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';

export const componentsContainerStyles = css`
    margin-top: 0;

    @media screen and ${FROM_TABLET} {
        margin-top: 0;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-top: 0;
    }

    &:first-child {
        margin-top: 0;
    }
`;

