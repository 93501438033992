import { css } from 'styled-components';
import {
    COLORS,
    FONTS,
} from '../../../../utils/variables';
import {
    FROM_DESKTOP,
    FROM_TABLET,
} from '../../../../utils/mediaQueries';

export const sliderCellStyles = css`
    padding-left: 6px;
    padding-right: 6px;
`;

export const containerStyles = css`
    h3 {
        color: ${COLORS.SAPPHIRE};
        font-family: ${FONTS.AVENIR_NEXT.FONT};
        font-weight: 400;
        font-size: 20px;
        letter-spacing: -0.08px;
        line-height 26px;
        margin-bottom: 23px;
        margin-top: 0;

        @media screen and ${FROM_DESKTOP} {
            font-size: 26px;
            line-height 32px;
        }
    }
`;

export const mobileContainerStyles = css`
    h3 {
        color: ${COLORS.SAPPHIRE};
        font-family: ${FONTS.AVENIR_NEXT.FONT};
        font-weight: 400;
        font-size: 20px;
        letter-spacing: -0.08px;
        line-height 26px;
        margin-bottom: 23px;
        margin-top: 0;
    }

    &>div {
        margin-bottom: 24px;
        max-width: none;
    }
`;

export const gridOfFourStyles = css`
    display: flex;

    &>div {
        max-width: none;
        width: calc(25% - 10px);
    }

    &>div:nth-child(2) {
        margin-left: 16px;
        margin-right: 16px;
    }
`;

export const capitalIdeasRowTitleStyle = css`
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${COLORS.BLACK};
    margin-bottom: 30px;
`;

export const sliderContainerStyles = css`
    overflow: hidden;
    margin-bottom: 10px !important;

    *[data-rel="CapitalCardCollectionOfFour"] {
        width: 299px;
    }

    *{
        outline: none;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-bottom: 90px;
    }

    .flickity-page-dots {
        height: 24px;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 0;
        text-align: center;

        li {
            display: inline-block;
            background: ${COLORS.LIGHT_GRAY2};
            border: none;
            border-radius: 50%;
            cursor: pointer;
            height: 10px;
            line-height: 200px;
            outline: none;
            overflow: hidden;
            padding: 0;
            width: 10px;
            margin-left: 5px;
            margin-right: 5px;

            &.is-selected {
                background: ${COLORS.SAPPHIRE};
            }
        }
    }
`;
