import { css } from 'styled-components';
import {
    COLORS,
    FONTS,
    ANIMATIONS,
} from 'utils/variables';
import {
    FROM_DESKTOP,
    FROM_TABLET,SMALL_MOBILE
} from 'utils/mediaQueries';

export const overlayStyles = css`
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    top: 0;
`;

export const imageContainerStyles = css`
    cursor: pointer;
    overflow: hidden;
    display:block;
    position:relative;
    @media screen and ${SMALL_MOBILE} {
        height: 157px;
        width: 100%
    }

    @media screen and ${FROM_TABLET} {
        height: 87.5px;
    }

    @media screen and ${FROM_DESKTOP} {
        height: 157px;
        width: 100%
    }

    img {
        height: 157px;
        margin-bottom: -4px;
        object-fit: cover;
        transition: ${ANIMATIONS.ARTICLES.IMAGE.TIME.SECONDS}s all;
        width: 100%;

        @media screen and ${FROM_TABLET} {
            height: 88px;
        }

        @media screen and ${FROM_DESKTOP} {
            height: 158px;
        }
        
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            min-height: 157px;
            max-height: 157px;
            min-width: inherit;
            height: auto;
            width: auto;
            max-width: 400%;
        }

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active), ${FROM_TABLET} {
            min-height: 88px;
            max-width: 302px;
            min-width: inherit;
            height: auto;
            width: auto;
            max-width: 400%;
        }

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active), ${FROM_DESKTOP} {
            min-height: 158px;
            max-height: 158px;
            min-width: inherit;
            height: auto;
            width: auto;
            max-width: 400%;
        }

        ${props => props.visited && css`
            opacity: 0.6;
        `};
    }
`;

export const titleStyles = css`
    color: ${COLORS.WHITE};
    display: inline-block;
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.1px;
    margin: 0;
    margin-bottom: 8px;
    text-decoration: none;
    min-height:60px;
    overflow:hidden;
    transition: ${ANIMATIONS.ARTICLES.TITLE.TIME.SECONDS}s all;

    @media screen and ${FROM_TABLET} {
        min-height: 52px;
        margin-bottom: 7px;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: -0.1px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-top: 10px;
        font-size: 15px;
        line-height: 20px;
        min-height: 58px;
    }

    &:hover {
        opacity: 0.75;
    }

    ${props => props.visited && css`
        opacity: 0.75;
        &:active {
            color: ${COLORS.WHITE};
            opacity: 1;
        }
        @media screen and ${FROM_DESKTOP} {
            &:hover {
                opacity: 1;
            }
        }
    `};
`;

export const containerStyles = css`
    position: relative;
    background-color:${COLORS.SAPPHIRE};
    color: ${COLORS.BLACK};
    display: inline-block;
    width: 296px;
    height:332px;
    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.IMAGE.TIME.SECONDS}s all;
    overflow: hidden;

    &:hover {
        img {
            transform: scale3d(1.05,1.05,1);
        }
    }

    @media screen and ${FROM_TABLET} {
        max-width: 225px;
        height: 276px;
    }

    @media screen and ${FROM_DESKTOP} {
        max-width: 276px;
        height:333px;
    }

    .kebab__trigger {
        right: 15px !important;
        bottom: 18px;
    }
`;

export const dateAndTopicContainerStyles = css`
    display: flex;
    font-size:12px;
    line-height:16px;
    letter-spacing:0.6px
    font-weight: 500;
    margin-bottom: 14px;
    text-transform: uppercase;
    height: 38px;

    @media screen and ${FROM_TABLET} {
        margin-bottom: 17px;
        font-size: 10px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 0px;
        font-size: 12px;
    }
`;

export const dateContainerStyles = css`
    padding-right: 20px;
    white-space: nowrap;
    color: ${COLORS.WHITE};

    @media screen and ${FROM_DESKTOP} {
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: 0.6px;
    }
`;

export const topicLinkStyles = css`
    color: ${COLORS.WHITE};
    flex: 1;
    text-align: right;
    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.TOPIC.TIME.SECONDS}s all;

    &:hover {
        opacity: 0.6;
    }
`;

export const contentContainerStyles = css`
    padding: 12px 14px 11px 14px;

    @media screen and ${FROM_TABLET} {
        padding: 12px 13px 12px 13px;
        height: 88px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 12px 16px 12px 16px;
    }
`;
