import {
    call, put, takeLatest, all,
} from 'redux-saga/effects';
import { TYPES } from 'containers/Toasts/constants';
import { addToasts } from 'state-management/actions/toasts';
import {
    getSourcesSuccess,
    getSourcesFailure,
    toggleSourceFollowFailure,
    toggleSourceFollowSuccess,
    getProfileSourcesSuccess,
    getProfileSourcesFailure,
} from 'state-management/actions/sources';
import {
    FOLLOW_SOURCE_URL,
    GET_PROFILE_SOURCES,
    GET_SOURCES,
    PROFILE_SOURCES_URL,
    SOURCES_URL,
    TOGGLE_FOLLOW_SOURCE,
} from 'state-management/constants/sources';
import Api from 'utils/api';
import { followSource, unfollowSource } from 'state-management/actions/basicProfile';
import { setError } from 'state-management/actions/errors';
import { REFINEMENT_CARD_URL } from "../constants/profileRefinementCard";
import { getProfileRefinementCardSuccess } from "../actions/profileRefinementCard";
import { delay } from 'redux-saga/effects';

/**
 * Get all sources.
 */
export function* fetchSources() {
    try {
        const { data } = yield call(Api.get, SOURCES_URL);
        yield put(getSourcesSuccess(data));
    } catch (e) {
        yield put(setError('global.errors.loadSources', e));
        yield put(getSourcesFailure(e));
    }
}

/**
 * Get all sources applicable to users profile.
 */
function* fetchProfileSources() {
    try {
        const { data } = yield call(Api.get, PROFILE_SOURCES_URL);
        yield put(getProfileSourcesSuccess(data));
    } catch (e) {
        yield put(setError('global.errors.loadSources', e));
        yield put(getProfileSourcesFailure(e));
    }
}

/**
 * Handle limit reached toast display.
 * @param {*} toastType
 */
function* handleShowToast(toastType) {
    yield put(addToasts([{
        toastType,
    }]));
}

/**
 * Follow/unfollow a source.
 * @param {*} param0
 */
function* toggleSourceFollow({ id, isFollowing, typeValue, showToast }) {
    try {
        const { data } = yield call(isFollowing ? Api.delete : Api.post, FOLLOW_SOURCE_URL(id));

        const response = typeValue != null ? yield call(Api.post, REFINEMENT_CARD_URL, {
            data: {
                type:typeValue,
                id,
            },
        }) : null;

        if (isFollowing) {
            if (!data.userUnfollowLimitReached) {
                if (showToast) {
                    yield call(handleShowToast, TYPES.UNFOLLOW_SOURCE);
                }

                yield put(unfollowSource(id));
            } else {
                yield call(handleShowToast, TYPES.SOURCE_LIMIT_REACHED);
            }
        } else {
            yield put(followSource(id));

            if (showToast) {
                yield call(handleShowToast, TYPES.FOLLOW_SOURCE);
            }

            if (response != null) {
                yield delay(2500);
                yield put(getProfileRefinementCardSuccess({
                    ...response.data,
                    hasSuggestion: response.data != null
                }));
            }
        }
        yield put(toggleSourceFollowSuccess());
    } catch (e) {
        yield put(toggleSourceFollowFailure());
    }
}

function* sourcesSaga() {
    yield all([
        takeLatest(GET_SOURCES, fetchSources),
        takeLatest(GET_PROFILE_SOURCES, fetchProfileSources),
        takeLatest(TOGGLE_FOLLOW_SOURCE, toggleSourceFollow),
    ]);
}

export default sourcesSaga;
