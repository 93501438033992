import React from 'react';
import styled from 'styled-components';
import Link from 'components/atomics/atoms/Link/Link';
import moment from 'moment';
import { getArticleUrl } from 'utils/getArticleAsset';
import { withCommonCard } from 'components/atomics/hocs/CommonCard/CommonCard';
import {
    containerStyles,
    contentContainerStyles,
    dateAndTopicContainerStyles,
    dateContainerStyles,
    topicLinkStyles,
    titleStyles,
    imageContainerStyles,
    toolsHeaderIconStyles,
} from './../../../../components/atomics/molecules/CeListContentCard/styles';
import ListContentCardFooter from "components/atomics/molecules/ListContentCard/ListContentCardFooter";
import { CEIcon, CESkinnyMobileIcon } from 'components/atomics/atoms/Icons/Icons';
import { getTruncatedString } from 'utils/contentCardUtils';
import SourceTag, {
    PROVIDER_CAPITAL_GROUP,
} from '../../../../components/atomics/atoms/SourceTag/SourceTag';

export const KebabAndSourceContainer = styled.div``;

export const Title = styled(Link)`
    ${titleStyles}
`;

export const DateAndTopic = styled.div`
    ${dateAndTopicContainerStyles}
`;

export const DateContainer = styled.div`
    ${dateContainerStyles}
`;

export const TopicLink = styled(Link)`
    ${topicLinkStyles}
`;

export const Container = styled.div`
    ${containerStyles}
`;

export const ImageContainer = styled(Link)`
    ${imageContainerStyles}
`;

export const ContentContainer = styled.div`
    ${contentContainerStyles}
`;

export const ToolsHeaderIconContainer = styled.div`
    ${toolsHeaderIconStyles}
`;

const MAX_ARTICLE_TITLE_CHARS = 60;

/**
 * Creates a CE credit list content card.
 */
class CeListContentCard extends React.Component {
    /**
     * Render the list content card and underlying components.
     */
    render() {
        const { profile, mobileList } = this.props;
        const linkUrl = getArticleUrl(this.props.article);

        return (
            <Container
                simplelist={this.props.simpleList.toString()}
                className={`${this.props.article.readStatus ? (this.props.article.readStatus === true ? 'ce-visited' : 'ce-not-visited') : 'ce-not-visited'}`}
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this.props.onMouseLeave}
                type="mobileList"
            >
                {!this.props.simpleList
                && <ImageContainer type="mobileList"
                        to={linkUrl}
                        onClick={() => {
                            this.props.recordClickAnalytics('cecredits');
                            this.props.onArticleClick();
                        }}
                    >
                    {mobileList
                    && <ToolsHeaderIconContainer type="mobileList">
                        <CESkinnyMobileIcon />
                    </ToolsHeaderIconContainer>}
                    {!mobileList
                    && <ToolsHeaderIconContainer>
                        <CEIcon />
                    </ToolsHeaderIconContainer>}
                </ImageContainer>}
                <ContentContainer simplelist={this.props.simpleList.toString()}>
                    {mobileList
                    && <DateAndTopic>
                        <DateContainer>
                            {moment.utc(this.props.article.date).format('MMM D, YYYY')}
                        </DateContainer>
                    </DateAndTopic>}
                    <Title
                        onClick={() => {
                            this.props.recordClickAnalytics('cecredits');
                            this.props.onArticleClick();
                        }}
                        to={linkUrl}
                        visited={this.props.article && this.props.article.visited}
                        simplelist={this.props.simpleList.toString()}>
                        {getTruncatedString(this.props.article.title, MAX_ARTICLE_TITLE_CHARS)}
                    </Title>
                    <ListContentCardFooter
                        onElement={this.props.onCardHover}
                        profile={profile}
                        sourceName={this.props.article.sourceName}
                        providerName={this.props.article.provider}
                        article={this.props.article}
                        topics={this.props.article.topics}
                        sourceId={this.props.article.sourceId}
                        topicName={this.props.article.topicName}
                        saved={this.props.article.saved}
                        articleId={parseInt(this.props.article.id)}
                        articleTitle={this.props.article.title}
                        type={this.props.article.type || this.props.article.contentType}
                        shouldShowToast={this.props.shouldShowToast}
                        hideSourceOption={this.props.article.provider === PROVIDER_CAPITAL_GROUP}
                        onSaveArticle={(id, save, title, getContentAfterSave, article) => {
                            this.props.toggleSaveCecredit(parseInt(this.props.article.id), save, title, getContentAfterSave, article );
                        }}
                        onSaveGuide={(id, save, title, getContentAfterSave, article) => {
                            this.props.toggleSaveGuide(id, save, title, getContentAfterSave, article);
                        }}
                        onFollowTopic={(topicId, status) => {
                            this.props.toggleFollowTopic(topicId, status);
                        }}
                        onFollowSource={(sourceId, status) => {
                            this.props.toggleFollowSource(sourceId, status);
                        }}
                    />
                </ContentContainer>
            </Container>
        );
    }
}

export default withCommonCard(CeListContentCard);
