import * as type from 'state-management/constants/articles.common';

export const visitArticle = article => ({
    type: type.VISIT_ARTICLE,
    article,
});

export const handleArticleVisit = id => ({
    type: type.ARTICLE_WAS_VISITED,
    id,
});

export const getVisitPracticeLab = (article) => ({
    type: type.PL_VISIT_ARTICLE,
    article,
});

export const handlePLArticleVisit = id => ({
    type: type.PL_WAS_VISITED,
    id,
});

// export const handleTrendingTopicArticleVisit = id => ({
//     type: type.TRENDING_TOPIC_ARTICLE_WAS_VISITED,
//     id,
// });

export const getArticle = id => ({
    type: type.GET_ARTICLE,
    id,
});

export const getArticleSuccess = article => ({
    type: type.GET_ARTICLE_SUCCESS,
    article,
});

export const getArticleFailure = () => ({
    type: type.GET_ARTICLE_FAILURE,
});
export const visitTTArticle = article => ({
    type: type.VISIT_TT_ARTICLE,
    article,
});

export const handleTTArticleVisit = id => ({
    type: type.TT_ARTICLE_WAS_VISITED,
    id,
});
