import { css } from 'styled-components';
import { getSpanInPercent } from 'utils/grid';
import { FROM_DESKTOP, FROM_MOBILE_TO_DESKTOP } from 'utils/mediaQueries';
import { RGB_COLORS } from 'utils/variables';

export const ArticleStyles = css`
    padding-bottom: 50px;

    @media screen and ${FROM_DESKTOP} {
        padding-bottom: 70px;
    }
`;

export const ArticleWrapperStyles = css`
    @media screen and ${FROM_DESKTOP} {
        background: linear-gradient(to bottom, rgba(${RGB_COLORS.LIGHT_GRAY2}, 0.5), rgba(${RGB_COLORS.LIGHT_GRAY1}, 0)) no-repeat;
        background-size: 100% 150px;
        padding-top: 53px;
    }
`;

export const ArticleWrapperInnerStyles = css`
    @media screen and ${FROM_MOBILE_TO_DESKTOP} {
        padding: 0;
    }

    @media screen and ${FROM_DESKTOP} {
        align-items: flex-start;
        display: flex;
        justify-content: flex-end;
    }
`;

export const ArticleBodyStyles = css`
    @media screen and ${FROM_DESKTOP} {
        margin-right: 8.5%;
        width: ${getSpanInPercent(8)};
    }
`;
