import { css } from 'styled-components';
import {
    COLORS,
    FONTS,
    ANIMATIONS,
} from 'utils/variables';
import {
    FROM_DESKTOP,
    FROM_TABLET,
    FROM_MOBILE_TO_TABLET,
    SMALL_MOBILE,
} from 'utils/mediaQueries';

export const wrapper = css`
`;

export const buttonContainer = css`
    transition: 0.3s;
    display: ${props => JSON.parse(props.display) ? 'block' : 'none'};
    opacity: ${props => !props.isMobile 
    ? JSON.parse(props.display) 
        ? 1 : 0 
    : 0 };
`;

export const contentContainer = css`
    @media screen and ${FROM_DESKTOP} {
        opacity: ${props => JSON.parse(props.display) ? '1' : '0'};
    }

    transition: 0.3s;

    display: ${props => JSON.parse(props.display) ? 'block' : 'none'};

    ${props => props.hasLimit && css`
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        max-width: ${props.limit}px;
    `}
`;

export const sourceItem = css`
    position: absolute;
    bottom: 16px;

    &>div {
        &>div {
            font-size: 10px;
        }
    }
`;

export const categoryTitle = css`
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    color: ${COLORS.SAPPHIRE};
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    position:absolute;
    bottom: 6px;
    display: inline-block;
    text-decoration: none;

    p {
        font-size: 12px;
        line-height: 1.33;
        letter-spacing: normal;
    }

    @media screen and ${SMALL_MOBILE} {
        p {
            font-size: 12px;
            line-height: 1.33;
            letter-spacing: normal;
        }
    }

    @media screen and ${FROM_TABLET} {
        p {
            font-size: 10px;
            line-height: 1.4;
            letter-spacing: 0.1px;
        }
    }

    @media screen and ${FROM_DESKTOP} {
        p {
            font-size: 12px;
            line-height: 1.33;
            letter-spacing: normal;
        }
    }
`;

export const titleStyles = css`
    display: inline-block;
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.1px;
    line-height: 20px;
    margin: 0;
    margin-bottom: 8px;
    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.TITLE.TIME.SECONDS}s all;
    max-height: 40px;
    overflow: hidden;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        min-height: 62px;
    }

    ${props => props.simplelist === 'true' && css`
        display: inline-block;
        margin-bottom: 13px;
    `};

    ${props => props.visited
        ? css`
            color: ${COLORS.MEDIUM_GRAY2};
            &:hover {
                color: ${COLORS.BLACK};
            };
        `
        : css`
            color: ${COLORS.BLACK};
            &:hover {
                color: ${COLORS.MEDIUM_GRAY2};
            };
        `};
`;

export const containerStyles = css`
    position: relative;
    border-bottom: 1px solid ${COLORS.LIGHT_GRAY2};
    color: ${COLORS.BLACK};
    display: block;

    &.ce-not-visited {
        opacity:1;
    }

    &.ce-visited {
        opacity:0.6;
    }

    ${props => props.simplelist === 'false' && css`
        max-width: 230px;
    `};

    padding: 8px 0 12px 0;

    &:first-child {
        padding-top: 9px;
    }

    &:nth-child(2){
        margin: 16px 0;
    }
    ${props => props.simplelist === 'true' && css`
        padding: 19px 17px 19px 17px;
    `};

    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.CONTAINER.TIME.SECONDS}s all;

    &:hover {
        img {
            transform: scale3d(1.05,1.05,1);
        }
    }

    ${props => props.simplelist === 'true' && css`
        @media screen and ${FROM_TABLET} {
            padding: 19px 23px 19px 23px;
        }
    `};

    ${props => props.simplelist === 'false' && css`
        @media screen and ${FROM_MOBILE_TO_TABLET} {
            display: flex;
            max-width: 305px;
            padding: 22px 0px 19px 10px;

            &[type~=mobileList]{
                max-width: 100%;
                padding: 22px 0 19px 0;
            }

            .CeListContentCard__ImageContainer-blMMek{
                width: 65px;
            }
        }

        @media screen and ${FROM_DESKTOP} {
            display: flex;
            max-width: 305px;
        }
    `};

    .kebab__trigger {
        bottom: 18px;
    }
`;

export const dateAndTopicContainerStyles = css`
    display: flex;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-bottom: 8px;
    text-transform: uppercase;

    &:hover {
        opacity: 0.75;
    }
`;

export const dateContainerStyles = css`
    color: ${COLORS.DARK_GRAY1}
    padding-right: 10px;
    white-space: nowrap;
`;

export const topicLinkStyles = css`
    color: ${COLORS.SAPPHIRE};
    flex: 1;
    text-align: right;
    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.TOPIC.TIME.SECONDS}s all;

    &:hover {
        color: ${COLORS.DARK_SAPPHIRE};
    }
`;

export const imageContainerStyles = css`
    height: 81px;
    max-height: 81px;
    width: 24px;
    overflow: hidden;

    &:hover{
      opacity:0.75;
    }

    img {
        display: none;
        height: 100%;
        object-fit: cover;
        transition: ${ANIMATIONS.ARTICLES.IMAGE.TIME.SECONDS}s all;

        @media screen and ${FROM_MOBILE_TO_TABLET} {
            display block;
        }

        @media screen and ${FROM_DESKTOP} {
            display: block;
        }
    }
`;

export const contentContainerStyles = css`
    display: flex;
    flex-direction: column;
    margin-left: 14px;
    width: 100%;

    &>a {
        flex: 1;
    }

    ${props => props.simplelist === 'false' && css`
        @media screen and ${FROM_MOBILE_TO_TABLET} {
            max-width: calc(100% - 113px);
            width: calc(100% -30px);
            padding-left: 0px;
            padding-bottom: 30px;
            margin-left: 22px;
        }

        
    `};
`;


export const toolsHeaderIconStyles = css`
    text-align: right;
    position: absolute;
    top: 12px;
    z-index: 1;

    &[type~=mobileList]{
        top: 30px;
    }

    svg {
        position: relative;
        top: -5px;
        text-align: right;
        
    }
`;