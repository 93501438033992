import { css } from 'styled-components';
import {
    COLORS,
} from 'utils/variables';
import {
    FROM_DESKTOP,
} from 'utils/mediaQueries';

export const containerStyles = css`
    margin: 0;
    text-align: left;
    background: ${COLORS.LIGHT_GRAY1};
    margin-bottom: 0 !important;

    ${props => props.background !== null && css`
        background: ${props.background};
    `};

    div {
        font-weight: bold;
        font-size: 11px;
        color: ${COLORS.DARK_GRAY1};
        padding-top: 22px;
        padding-bottom: 22px;
        border-top: 1px solid ${COLORS.LIGHT_GRAY2};
    }

    @media screen and ${FROM_DESKTOP} {
        margin: auto;
        width: calc(100% - 96px);
    }
`;