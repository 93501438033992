import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import store from 'utils/store';
import { ROUTES } from 'containers/App/constants';
import PrivateRoute from 'containers/PrivateRoute/PrivateRoute';
import { HOMEPAGE_NEWS } from 'state-management/constants/basicProfile';
import { getWebinarInfo } from 'state-management/actions/webinar';

const FIRST_LOGIN_HANDLED = "firstLoginHandled";

/**
 * Handles deciding to route user to true home page, or to news page.
 */
class HomepageRoute extends React.Component {
    getHomepage(homepageType, signUpType, firstLogin) {
        const alreadyDone = store.get(FIRST_LOGIN_HANDLED);

        if (signUpType === "WEBINAR" && firstLogin === "YES" && !alreadyDone) {
            store.set(FIRST_LOGIN_HANDLED, true);

            return `/learn/webinar/${this.props.webinarMeta.content.code}`;

        }

        switch (homepageType) {
            case HOMEPAGE_NEWS:
                return ROUTES.NEWS;
            default:
                return ROUTES.HOME;
        }
    }

    render() {
        const { isAuthenticated, homepage, component: Component, signUpType, firstLogin } = this.props;

        return (
            <Route
                exact
                path="/"
                render={() => (isAuthenticated ? (
                    <PrivateRoute render={() => (
                        <Redirect to={this.getHomepage(homepage, signUpType, firstLogin)} />
                    )}
                    />
                ) : <Component />)}
            />
        );
    }
}

HomepageRoute.propTypes = {
    component: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    homepage: PropTypes.string,
};

HomepageRoute.defaultProps = {
    isAuthenticated: false,
    homepage: null,
};

const mapStateToProps = state => ({
    isAuthenticated: state.signIn.isAuthenticated,
    homepage: state.basicProfile.homepage,
    signUpType: state.basicProfile.signUpType,
    firstLogin: state.basicProfile.firstLogin,
    webinarMeta: state.webinar.webinar,
});

const mapDispatchToProps = dispatch => ({   
    getWebinarInfo: () => dispatch(getWebinarInfo())

});

export default connect(mapStateToProps, mapDispatchToProps)(HomepageRoute);
