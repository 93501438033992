import { css } from 'styled-components';
import {
    COLORS,
    FONTS,
} from 'utils/variables';
import {
    FROM_DESKTOP,
    FROM_TABLET,
    FROM_TABLET_TO_DESKTOP,
    SMALL_MOBILE
} from 'utils/mediaQueries';

export const pageContainerStyles = css`
    background: ${COLORS.LIGHT_GRAY1};
    padding-left: 16px;
    padding-right: 16px;
    padding: 0 16px 56px 16px;

    @media screen and ${FROM_TABLET} {
        padding: 0 38px 110px 38px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 0 48px 110px 48px;
    }
`;

export const contentContainerStyles = css`
    width: 100%;
    max-width: 1170px;
    margin: auto;
`;

export const gridStyles = css`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    &>div {
        width: 100%;
        margin-bottom: 16px;
        max-width: initial;
    }

    @media screen and ${FROM_TABLET_TO_DESKTOP} {
        flex-direction: row;

        &>div {
            width: calc(33.3% - 20px);
            margin-right: 24px;
        }

        &>div:nth-child(3),
        &>div:nth-child(6),
        &>div:nth-child(9),
        &>div:nth-child(12),
        &>div:nth-child(15),
        &>div:nth-child(18),
        &>div:nth-child(21),
        &>div:nth-child(24),
        &>div:last-child {
            margin-right: 0;
        }
    }

    @media screen and ${FROM_DESKTOP} {
        flex-direction: row;

        &>div {
            width: calc(25% - 22px);
            margin-right: 24px;
        }

        &>div:nth-child(4),
        &>div:nth-child(8),
        &>div:nth-child(12),
        &>div:nth-child(16),
        &>div:nth-child(20),
        &>div:last-child {
            margin-right: 0;
        }

        @supports (-ms-ime-align:auto) {
            &>div {
                width: calc(25% - 20px);
                margin-right: 23px;
            }
        }
    }
`;

export const headerStyles = css`
`;

export const backToLinkContainerStyles = css`
    color: ${COLORS.BLACK} !important;
    background: transparent;
    padding: 0;
`;
