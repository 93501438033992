export const ADD_GUIDE_TO_SAVES_URL = guideId => `/content-service/cards/guide/${guideId}`;
export const REMOVE_GUIDE_FROM_SAVES = guideId => `/content-service/cards/guide/${guideId}`;
export const ADD_ARTICLE_TO_SAVES_URL = articleId => `/personalization-service/articles/${articleId}/save`;
export const ADD_WEBINAR_TO_SAVES_URL = webinarId => `/content-service/cards/WEBINAR/${webinarId}`;

export const TOGGLE_SAVE_ARTICLE = 'save/TOGGLE_SAVE_ARTICLE';
export const TOGGLE_SAVE_ARTICLE_NEW = 'save/TOGGLE_SAVE_ARTICLE_NEW';
export const TOGGLE_SAVE_ARTICLE_SUCCESS = 'save/TOGGLE_SAVE_ARTICLE_SUCCESS';
export const TOGGLE_SAVE_ARTICLE_FAILURE = 'save/TOGGLE_SAVE_ARTICLE_FAILURE';

export const TOGGLE_SAVE_GUIDE = 'save/TOGGLE_SAVE_GUIDE';
export const TOGGLE_SAVE_GUIDE_SUCCESS = 'save/TOGGLE_SAVE_GUIDE_SUCCESS';
export const TOGGLE_SAVE_GUIDE_FAILURE = 'save/TOGGLE_SAVE_GUIDE_FAILURE';

export const TOGGLE_SAVE_WEBINAR = 'save/TOGGLE_SAVE_WEBINAR';
export const TOGGLE_SAVE_WEBINAR_SUCCESS = 'save/TOGGLE_SAVE_WEBINAR_SUCCESS';
export const TOGGLE_SAVE_WEBINAR_FAILURE = 'save/TOGGLE_SAVE_WEBINAR_FAILURE'


export const ADD_CECREDIT_TO_SAVES_URL = (articleId, cardName) => `/content-service/cards/${cardName}/${articleId}`;

export const TOGGLE_SAVE_CECREDIT = 'save/TOGGLE_SAVE_CECREDIT';
export const TOGGLE_SAVE_CECREDIT_NEW = 'save/TOGGLE_SAVE_CECREDIT_NEW';
export const TOGGLE_SAVE_CECREDIT_SUCCESS = 'save/TOGGLE_SAVE_CECREDIT_SUCCESS';
export const TOGGLE_SAVE_CECREDIT_FAILURE = 'save/TOGGLE_SAVE_CECREDIT_FAILURE';

export const TOGGLE_SAVE_PL_ARTICLE = 'save/TOGGLE_SAVE_PL_ARTICLE';
export const TOGGLE_SAVE_PL_ARTICLE_SUCCESS = 'save/TOGGLE_SAVE_PL_ARTICLE_SUCCESS';
export const TOGGLE_SAVE_PL_ARTICLE_FAILURE = 'save/TOGGLE_SAVE_PL_ARTICLE_FAILURE';
