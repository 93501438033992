import { css } from 'styled-components';
import {
    FROM_DESKTOP,
    FROM_TABLET,
} from 'utils/mediaQueries';
import {
    COLORS,
    FONT_AVENIR_NEXT,
} from 'utils/variables';

export const blankAreaStyles = css`
    width: 100%;
    height: 489px;
    position: relative;

    @media screen and ${FROM_TABLET} {
        height: 443px;
    }

    @media screen and ${FROM_DESKTOP} {
        height: 502px;
    }
`;

export const loaderStyles = css`
    position: absolute;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
    height: 50px;
    width: 50px;

    @media screen and ${FROM_TABLET} {
        width: 80px;
        height: 80px;
        left: calc(50% - 40px);
        top: calc(50% - 40px);
    }

    @media screen and ${FROM_DESKTOP} {
        width: 100px;
        height: 100px;
        left: calc(50% - 50px);
        top: calc(50% - 50px);
    }
`;

export const sectionStyles = css`
    position: relative;
    background: ${COLORS.WHITE};
`;

export const buttonContainer = css`
    text-align: center;
    padding-top: 14px;
    padding-bottom: 32px;
    background-color: ${COLORS.WHITE};
    border-bottom: 1px solid ${COLORS.LIGHT_GRAY2};
    padding-left: 16px;
    padding-right: 16px;

    &>a {
        display: block;
    }

    @media screen and ${FROM_TABLET} {
        padding-left: 0;
        padding-right: 0;
        padding-top: 32px;
        padding-bottom: 40px;
        text-align: right;
        background-color: transparent;
        border-bottom: none;

        &>a {
            display: inline-block;
        }
    }
`;

export const listAreaCard = css`
    border-top: 16px solid ${COLORS.LIGHT_GRAY1};
    border-bottom: 16px solid ${COLORS.LIGHT_GRAY1};
    background: ${COLORS.LIGHT_GRAY1};

    &>div {
        max-width: inherit;
    }
`;

export const listAreaCardWrapper = css`
    border-bottom: 1px solid ${COLORS.LIGHT_GRAY2};
`;

export const listArea = css`
    display: flex;
    flex-direction: column;
    border-top: 1px solid ${COLORS.LIGHT_GRAY2};
    margin-top: -56px;

    *[data-rel="SplashNotification"] {
        margin-left: 16px;
        margin-right: 16px;
    }

    &>div {
        max-width: inherit;
        background-color: ${COLORS.WHITE};
    }

    @media screen and ${FROM_TABLET} {
        *[data-rel="SplashNotification"] {
            margin-left: initial;
            margin-right: initial;
        }
    }
`;

export const cardsArea = css`
`;

export const controlsContainerStyles = css`
    display: flex;
    justify-content: center;
    margin-bottom: 83px;
    margin-top: 24px;

    @media screen and ${FROM_TABLET} {
        justify-content: flex-end;
        margin-bottom: 81px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 66px;
    }
`;

export const headerStyles = css`
    padding-bottom: 24px;
    padding-top: 32px;
    padding-left: 16px;
    padding-right: 16px;

    h2 {
        color: ${COLORS.SAPPHIRE}; ;
        font-weight: 400;
        font-family: ${FONT_AVENIR_NEXT};
        margin-bottom: 0;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: -0.08px;

        @media screen and ${FROM_TABLET} {
            font-size: 20px;
            line-height: 31px;
        }

        @media screen and ${FROM_DESKTOP} {
            font-size: 26px;
            line-height: 36px;
            letter-spacing: 0;
        }

        span {
            color:${COLORS.ALERT_RED};
            font-size:15px;
        }
    }

    @media screen and ${FROM_TABLET} {
        padding-left: 0;
        padding-right: 0;
        padding-top: 50px;
        padding-bottom: 28px;
    }

    @media screen and ${FROM_DESKTOP} {
        h2 {
            font-size: 26px;
            line-height: 32px;
            letter-spacing: -0.1px;
        }
    }
`;

export const sliderContainerStyles = css`
    overflow: hidden;

    *[data-rel="GuideCard"] {
        width: 299px;
    }

    *{
        outline: none;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-bottom: 90px;
    }

    .flickity-page-dots {
        height: 24px;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 0;
        text-align: center;

        li {
            display: inline-block;
            background: ${COLORS.LIGHT_GRAY2};
            border: none;
            border-radius: 50%;
            cursor: pointer;
            height: 10px;
            line-height: 200px;
            outline: none;
            overflow: hidden;
            padding: 0;
            width: 10px;
            margin-left: 5px;
            margin-right: 5px;

            &.is-selected {
                background: ${COLORS.SAPPHIRE};
            }
        }
    }
`;
