import { css } from 'styled-components';
import { GUTTER, getSpanInPixels } from 'utils/grid';
import {
    FROM_DESKTOP,
    FROM_MOBILE_TO_TABLET,
    FROM_TABLET,
    FROM_TABLET_TO_DESKTOP,
    ABOVE_MAX_WIDTH,
} from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS, RGB_COLORS } from 'utils/variables';

const tabletSpacing = (GUTTER.tablet / 2) + getSpanInPixels(1, false, 'tablet');

export const outerContainerStyles = css`
    padding: 75px 0;
    background-image: linear-gradient(to bottom, ${COLORS.LIGHT_GRAY1}, rgba(${RGB_COLORS.WHITE}, 0));

    @media screen and ${FROM_TABLET} {
        padding: 80px 0 80px 0;
    }
    
    margin: auto;
`;

export const innerContainerStyles = css`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    @media screen and ${FROM_DESKTOP} {
        flex-wrap: wrap;
        flex-direction: row;
    }
`;

export const headingContainerStyles = css`
    box-sizing: border-box;

    @media screen and ${FROM_TABLET} {
        width: 45%;
    }
`;

export const headingStyles = css`
    color: ${COLORS.SAPPHIRE};
    font-family: ${FONT_AVENIR_NEXT};
    font-weight: 400;
    line-height: 1.2;
    max-width: 400px;

    @media screen and ${FROM_TABLET} {
        padding-bottom: 40px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding-bottom: 0;
        font-size: 36px;
        line-height: 1.24;
        max-width: 340px;
        text-align: left;

        &::before {
            background-image: linear-gradient(to left, ${COLORS.OCEAN}, ${COLORS.SAPPHIRE});
            content: '';
            height: 4px;
            note17px;
            position: absolute;
            transform: translateX(-100%) translateX(-18px);
            width: 80px;
            top: 38%;

            @media screen and ${ABOVE_MAX_WIDTH} {
                background-image: linear-gradient(to left, ${COLORS.OCEAN}, ${COLORS.SAPPHIRE} 40px, ${COLORS.SAPPHIRE} 60px, transparent 80px);
            }
        }
    }
`;

export const articlesContainerStyles = css`
    display: flex;

    @media screen and ${FROM_MOBILE_TO_TABLET} {
        flex-direction: column;
        max-width: 300px;
    }

    @media screen and ${FROM_TABLET} {
        flex-direction: row;
        width: 75%;
    }

    @media screen and ${FROM_DESKTOP} {
        width: 55%;
    }

    @media screen and ${FROM_TABLET_TO_DESKTOP} {
        article:first-child {
            padding-left: 0 !important;
        }

        article:last-child {
            padding-left: 100px !important;
        }
    }
`;

export const articleStyles = css`
    box-sizing: border-box;
    padding-top: 55px;

    @media screen and ${FROM_TABLET} {
        padding-left: ${tabletSpacing}px;
        padding-top: 0;
        width: 50%;
    }

    @media screen and ${FROM_DESKTOP} {
        padding-left: ${GUTTER.desktop}px;
    }
`;

export const articleHeadingStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-size: 20px;
    font-weight: 600;
    line-height: 1.56;
    margin: 15px 0 10px;

    @media screen and ${FROM_TABLET} {
        font-size: 24px;
        line-height: 1.3;
        margin: 15px 0 10px;
        max-width: 240px;
    }

    @media screen and ${FROM_TABLET_TO_DESKTOP} {
        margin-left: auto;
        margin-right: auto;
    }
`;

export const articleDescriptionStyles = css`
    color: ${COLORS.MEDIUM_GRAY2};
    font-size: 16px;
    font-weight: 500;
    line-height: 1.46;
    margin: 0;

    @media screen and ${FROM_TABLET} {
        font-size: 18px;
        line-height: 1.3;
    }
`;
