import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { KEY_SPACE, KEY_ENTER } from 'utils/keyCodes';
import { CheckIconSmall, IconPlus } from 'components/atomics/atoms/Icons/Icons';
import {
    dropdownOptionStyles,
    dropdownItemLabelStyles,
    indicatorStyles,
    checkIconStyles,
    plusIconStyles,
} from 'components/atomics/molecules/Dropdown/styles';
import { contextHubRecordEvent } from 'utils/contextHub';
import storage from 'utils/store';

const StyledDropdownOption = styled.li`
    ${dropdownOptionStyles};
`;

const StyledDropdownItemValue = styled.div`
    ${dropdownItemLabelStyles}
`;

export const StyledIndicator = styled.div`
    ${indicatorStyles}
`;

export const StyledCheckIcon = styled(CheckIconSmall)`
    ${checkIconStyles}
`;

export const StyledPlusIcon = styled(IconPlus)`
    ${plusIconStyles(StyledDropdownOption)}
`;

/**
 * Creates a drop-down option for the associated drop-down molecule.
 * @param {*} props 
 */
const DropdownOption = (props) => {
    const {
        item,
        ref,
        onKeyDown,
        isSelected,
        onSelect,
        multiSelect,
        title,
    } = props;
    const analyticsLabel = (typeof title === 'string') ? title : props.title != undefined ? props.title.props.children : 'Topic ';

    /**
     * Handle click events.
     */
    const handleClick = () => {
        if (props.title === "Reason for contacting") {
            storage.set('dropdownValue', props.item.value);
        } else {
            CH_handleComponentEventList();
        }
        onSelect(item.key);
    };

    /**
     * Handle key events.
     * @param {*} event 
     */
    const handleKeyPress = (event) => {
        const keyCode = event.keyCode || event.which || 0;

        if (keyCode === KEY_ENTER || keyCode === KEY_SPACE) {
            event.preventDefault();
            handleClick();
        }
    };

    //analytics 
    const CH_handleComponentEventList = () => {
        let currentTabName = props.currentTabName ? props.currentTabName : '';
        if (!currentTabName) {
            var tracking =
            {
                "type": "dropDown",
                "action": analyticsLabel + ' : ' + props.item.value,
                "value": props.item.value,
            };
        } else {
            var tracking =
            {
                "type": "dropDown",
                "action": currentTabName + ' : ' + analyticsLabel + ' : ' + props.item.value,
                "value": props.item.value,
            };
        }
        contextHubRecordEvent(tracking);
    };

    /**
     * Render indicator icon.
     */
    const renderIndicator = () => {
        const Icon = isSelected ? StyledCheckIcon : StyledPlusIcon;

        if (multiSelect) {
            return (
                <StyledIndicator
                    aria-hidden="true"
                    variant={{ isSelected }}
                >
                    <Icon />
                </StyledIndicator>
            );
        }

        return null;
    };

    /**
     * Render this and underlying elements.
     */
    return (
        <StyledDropdownOption
            ref={ref}
            onClick={handleClick}
            onKeyDown={onKeyDown}
            onKeyPress={handleKeyPress}
            role="option"
            aria-selected={isSelected}
            tabIndex="0"
            variant={{ isSelected }}
            data-val={item.value}
        >
            <StyledDropdownItemValue
                variant={{ isSelected }}>{item.value}</StyledDropdownItemValue>
            {renderIndicator()}
        </StyledDropdownOption>
    );
};

DropdownOption.propTypes = {
    item: PropTypes.shape({
        key: '',
        value: '',
    }).isRequired,
    isSelected: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func,
    ref: PropTypes.func,
    multiSelect: PropTypes.bool.isRequired,
};

DropdownOption.defaultProps = {
    isSelected: false,
    onKeyDown: () => { },
    ref: () => { },
};

export default DropdownOption;
