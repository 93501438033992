import React from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import { xor } from 'lodash';
import {
    FormattedMessage,
    injectIntl,
} from 'react-intl';
import Button from 'components/atomics/atoms/Button/Button';
import { TYPE_PRIMARY_MEDIUM, TYPE_GHOST } from 'components/atomics/atoms/Button/Button';
import { BreakpointsContext, MOBILE } from 'components/Breakpoints/Breakpoints';
import styled from 'styled-components';
import { Container, Loader } from 'components';
import {
    contentContainerStyles,
    actionBarStyles,
    placeholderStyles,
} from './styles';
import Sources from 'containers/Onboarding/Sources/Sources';
import { getSources } from 'state-management/actions/sources';
import {
    saveBasicProfileForm,
} from 'state-management/actions/basicProfileForm';
import {
    goToPage,
} from 'state-management/actions/basicProfile';
import storage from '../../utils/store';
import { showPreferencesModal, hidePreferencesModal } from 'state-management/actions/basicProfile';

const StyledContentContainer = styled(Container)`
    ${contentContainerStyles}
`;

const ActionBar = styled.div`
    ${actionBarStyles}
`;

const Placeholder = styled.div`
    ${placeholderStyles}
`;

/**
 * Choose sources page, used when we delete a source and a user is left below the minimum of 3.
 */
export class ChooseSources extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.state = {
            isSticky: false,
            selectedSources: null,
            sourceConfirmationModal: false,
            showError: false,
            isTouched: false,
            error: false,
        }

        this.handleWindowScroll = this.handleWindowScroll.bind(this);

        this.placeholderRef = React.createRef();

        //this.showModalOnClick = this.showModalOnClick.bind(this);
    }

    /**
     * Get sources and needed data at mount, setup window listeners.
     */
    componentDidMount() {
        this.props.getSourcesData();

        window.addEventListener('scroll', this.handleWindowScroll);
        window.addEventListener('resize', this.handleWindowScroll);
    }

    /**
     * Remove event listeners that control sticky behavior.
     */
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleWindowScroll);
        window.removeEventListener('resize', this.handleWindowScroll);
    }

    /**
     * Update temporary selected sources state with basicProfile props.
     */
    componentWillUpdate(nextProps) {
        if (nextProps.selectedSources && this.state.selectedSources === null) {
            this.setState({
                selectedSources: nextProps.selectedSources,
            });
        }
    }

    /**
     * Set sticky bar once we have data.
     */
    componentDidUpdate() {
        this.handleWindowScroll();
    }

    /**
     * Handle if the player is sticky or not on scroll or resize of window.
     */
    handleWindowScroll() {
        const scrollTop = document.body.scrollTop
            ? document.body.scrollTop : document.documentElement.scrollTop;
        const stickyElement = this.placeholderRef.current;

        if (!stickyElement) {
            return;
        }

        const bottomMostView = scrollTop + window.innerHeight;
        const bottomOfSticky = stickyElement.offsetTop + stickyElement.offsetHeight;

        if (stickyElement) {
            if (bottomMostView < bottomOfSticky
                && !this.state.isSticky) {
                this.setState({
                    isSticky: true,
                });
            } else if (bottomMostView >= bottomOfSticky
                && this.state.isSticky) {
                this.setState({
                    isSticky: false,
                });
            }
        }
    }
    /**
     * Handle save sources click.
     */
    handleSaveOnClick = (eve) => {
        this.setState({ isSaving: true },
            () => {
                this.handleSaveAction()
            });
    };

    handleSaveAction = () => {
        const { saveProfile } = this.props;
        let userProfile = this.props.userProfile;
        userProfile.sourceIds = this.state.selectedSources;
        saveProfile(userProfile);
        storage.set('onSaveSources', true);
        const lastPage = storage.get('referrer');
        this.props.goToPage(lastPage ? lastPage : '/home');
        storage.remove('referrer');
    };

    /**
     * Handle source select/deselect.
     */
    changeSourcesSelection = (id) => {
        let currentSelected = xor(this.state.selectedSources, [id]);
        this.setState({
            selectedSources: currentSelected,
            isTouched: true,
            error: (currentSelected.length < 3),
        });
    };

    /**
     * Render this and underlying components.
     */
    render() {
        if (storage.get('modalSave')) {
            this.handleSaveAction()
            storage.set('modalSave', false)
            return null
        }
        let modalMessage = "profilePage.confirmationModal";
        if (this.state.error) {
            modalMessage = "profilePage.confirmationSourceModal";
        }
        if (this.state.selectedSources === null || !this.props.sources || this.props.sources.length < 1) {
            return <Loader />;
        }
        return (
            <React.Fragment>
                <StyledContentContainer>
                    <BreakpointsContext.Consumer>
                        {(breakpoint) => {
                            const isMobile = breakpoint === MOBILE;

                            return (
                                <Sources
                                    isChoose={true}
                                    handleIsValid={() => { }}
                                    changeStatus={this.changeSourcesSelection}
                                    isMobile={isMobile}
                                    firstName={this.props.firstName}
                                    showError={this.state.showError}
                                    selected={this.state.selectedSources}
                                />
                            );
                        }}
                    </BreakpointsContext.Consumer>
                </StyledContentContainer>
                <Placeholder ref={this.placeholderRef}>
                    <ActionBar
                        isSticky={this.state.isSticky}
                    >
                         <BreakpointsContext.Consumer>
                            {(breakpoint) => {
                                if (breakpoint === MOBILE) {
                                    return (
                                        <React.Fragment>
                                            <Button
                                                to={null}
                                                className='chooseSourcesSave'
                                                disabled={this.state.selectedSources === null
                                                    || this.state.selectedSources.length < 3}
                                                type={TYPE_PRIMARY_MEDIUM}
                                                onClick={(e) => {
                                                    if (this.state.selectedSources && this.state.selectedSources.length >= 3) {
                                                        this.handleSaveOnClick(e);
                                                    }
                                                    else {
                                                        this.setState({ showError: true });
                                                    }
                                                }}
                                            >
                                                <FormattedMessage id="chooseSources.submit" />
                                            </Button>
                                            <Button
                                                to=''
                                                type={TYPE_GHOST}
                                                onClick={(e) => {
                                                    if (!this.state.isTouched) {
                                                        this.props.history.goBack();
                                                    }
                                                }}
                                            >
                                                <Prompt when={this.state.isTouched && !this.state.isSaving}
                                                    message={modalMessage} />
                                                <FormattedMessage id="chooseSources.defer" />
                                            </Button>
                                        </React.Fragment>
                                    );
                                } else {
                                    return (
                                        <React.Fragment>
                                            <Button
                                                to=''
                                                type={TYPE_GHOST}
                                                onClick={(e) => {
                                                    if (!this.state.isTouched) {
                                                        this.props.history.goBack();
                                                    }
                                                }}
                                            >
                                                <Prompt when={this.state.isTouched && !this.state.isSaving}
                                                    message={modalMessage} />
                                                <FormattedMessage id="chooseSources.defer" />
                                            </Button>
                                            <Button
                                                to={null}
                                                className='chooseSourcesSave'
                                                disabled={this.state.selectedSources === null
                                                    || this.state.selectedSources.length < 3}
                                                type={TYPE_PRIMARY_MEDIUM}
                                                onClick={(e) => {
                                                    if (this.state.selectedSources && this.state.selectedSources.length >= 3) {
                                                        this.handleSaveOnClick(e);
                                                    }
                                                    else {
                                                        this.setState({ showError: true });
                                                    }
                                                }}
                                            >
                                                <FormattedMessage id="chooseSources.submit" />
                                            </Button>
                                        </React.Fragment>
                                    );
                                }
                            }}
                        </BreakpointsContext.Consumer>
                    </ActionBar>
                </Placeholder>
            </React.Fragment >
        );
    }
}

ChooseSources.propTypes = {
    firstName: PropTypes.string,
    userProfile: PropTypes.object,
    selectedSources: PropTypes.arrayOf(PropTypes.number),
    saveProfile: PropTypes.func.isRequired,
};

ChooseSources.defaultProps = {
    firstName: null,
    userProfile: null,
    selectedSources: [],
};

const mapStateToProps = state => ({
    firstName: state.basicProfile.firstName,
    userProfile: state.basicProfile,
    selectedSources: state.basicProfile.sourceIds,
    sources: state.sources.sources,
    globalErrors: state.errors.errors,
    showPreferencesModal: state.basicProfile.showPreferencesModal,
    hidePreferenceModal: state.basicProfile.hidePreferenceModal,

});

const mapDispatchToProps = dispatch => ({
    getSourcesData: () => dispatch(getSources()),
    saveProfile: data => { dispatch(saveBasicProfileForm(data)) },
    goToPage: page => { dispatch(goToPage(page)) },
    showPreferencesModalAction: () => dispatch(showPreferencesModal()),
    hidePreferenceModalAction: () => dispatch(hidePreferencesModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ChooseSources));