import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import {
    FONT_AVENIR_NEXT,
    COLORS,
    ANIMATIONS,
} from 'utils/variables';

export const rulesContainer = css`
    flex: 1;
`;

export const mockLinkStyles = css`
    cursor: pointer;
    color: ${COLORS.SAPPHIRE};
    text-decoration: underline;
`;

export const errorStyles = css`
    color: ${COLORS.ALERT_RED};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 14px;
    line-height: 1;
    margin-top: 15px;
    text-align: center;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        margin-top: 20px;
        text-align: right;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-top: 25px;
    }

    a {
        color: ${COLORS.SAPPHIRE};
        transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;

        &:hover {
            color: ${COLORS.DARK_SAPPHIRE};
        }
    }
`;

export const fieldsRowStyles = css`
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;

    &>div:first-child {
        margin-right: 16px;
    }

    @media screen and ${FROM_TABLET} {
        &>div:first-child {
            margin-right: 24px;
        }
    }

    ${props => props.fullWidth === "true" && css`
        &>div:first-child {
            margin-right: 0;
        }
    `};
`;

export const fieldWrapperStyles = css`
    flex: 1 1 auto;

    ${props => props.forceWidth && css`
        max-width: calc(50% - 12px);
    `};

    @media screen and ${FROM_TABLET} {
        margin-bottom: 20px;
    }

    @media screen and ${FROM_DESKTOP} {
        flex: 1 1 100%;
        margin-bottom: 0;
    }
`;

export const controlsStyles = css`
    align-items: center;
    justify-content: center;
    margin: 40px auto 10px;

    &>button {
        width: 100%;
        margin-top: 26px;
    }

    @media screen and ${FROM_TABLET} {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0;

        &>button {
            margin-top: 0;
            width: 216px;
            position: relative;
            top: 6px;
            min-width: 120px;
        }
    }

    @media screen and ${FROM_DESKTOP} {
        &>button {
            min-width: 120px;
        }
    }
`;

export const buttonStyles = css`
    min-width: 120px;
    transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;
    cursor: pointer;
    background-color: ${COLORS.OCEAN};
    color: ${COLORS.WHITE};
    font-size: 19px;
    font-weight: 700;
    letter-spacing: 0;
    padding: 17px 48px;
    text-transform: capitalize;
    border: 0;
    height: 60px;

    ${props => props.isDisabled === 'false' && css`
        &:hover {
            background-color: ${COLORS.SAPPHIRE};
        }
    `};

    ${props => props.isDisabled === 'true' && css`
        background-color: ${COLORS.LIGHT_GRAY3} !important;
        cursor: default;
    `};
`;

export const checkboxStyles = css`
    display: inline-flex;
    margin-top: 15px;
    padding: 4px 0;

    @media screen and ${FROM_TABLET} {
        margin-top: 10px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-top: 4px;
    }

    label {
        color: ${COLORS.BLACK};
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.1px;
    }
`;

export const agreementErrorStyles = css`
    color: ${COLORS.ALERT_RED};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 14px;
    font-weight: 500;
    word-wrap: break-word;
`;

export const uhOhStyles = css`
    font-weight: 700;
`;
