import React from 'react';
import PropTypes from 'prop-types';

import { FOCUS_CLASS } from 'components/FocusVisibility/constants';
import { KEY_TAB } from 'utils/keyCodes';

class FocusVisibility extends React.PureComponent {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClick, true);
        document.addEventListener('keydown', this.handleKeyDown, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick, true);
        document.removeEventListener('keydown', this.handleKeyDown, true);
    }

    handleClick(event) {
        if (
            event.screenX !== 0
            && event.screenY !== 0
            && event.width !== 0
            && event.mozInputSource !== 0
        ) {
            this.removeVisibilityClass();
        }
    }

    handleKeyDown(event) {
        const eventKeyCode = event.which || event.keyCode || 0;

        if (eventKeyCode === KEY_TAB) {
            this.addVisibilityClass();
        }
    }

    addVisibilityClass() {
        const { target } = this.props;
        const node = document.querySelector(`${target}`);

        if (node) {
            node.classList.add(FOCUS_CLASS);
        }
    }

    removeVisibilityClass() {
        const { target } = this.props;
        const node = document.querySelector(`${target}`);

        if (node) {
            node.classList.remove(FOCUS_CLASS);
        }
    }

    render() {
        return null;
    }
}

FocusVisibility.propTypes = {
    target: PropTypes.string,
};

FocusVisibility.defaultProps = {
    target: 'body',
};

export default FocusVisibility;
