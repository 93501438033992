import * as type from 'state-management/constants/featureToggle.common';

export const getFeatureToggle = featureId => ({
    type: type.GET_FEATURE_TOGGLE,
    featureId,
});

export const getFeatureToggleSuccess = data => ({
    type: type.GET_FEATURE_TOGGLE_SUCCESS,
    data,
});

export const getFeatureToggleFail = error => ({
    type: type.GET_FEATURE_TOGGLE_FAIL,
    error,
});
