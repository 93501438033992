import { css } from 'styled-components';
import {
    COLORS,
    FONTS,
} from 'utils/variables';
import {
    FROM_DESKTOP,
    FROM_TABLET,
} from 'utils/mediaQueries';

export const sliderCellStyles = css`
    padding-left: 6px;
    padding-right: 6px;
`;

export const sliderContainerStyles = css`
    margin-bottom: 60px;
    overflow: hidden;
    
    .slick-slide {
        padding: 6px;
    }

    *[data-rel="StandardContentCard"] {
        width: 296px;
    }

    *{
        outline: none;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-bottom: 90px;
    }

    .flickity-page-dots {
        height: 24px;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 0;
        text-align: center;

        li {
            display: inline-block;
            background: ${COLORS.LIGHT_GRAY2};
            border: none;
            border-radius: 50%;
            cursor: pointer;
            height: 10px;
            line-height: 200px;
            outline: none;
            overflow: hidden;
            padding: 0;
            width: 10px;
            margin-left: 5px;
            margin-right: 5px;

            &.is-selected {
                background: ${COLORS.SAPPHIRE};
            }
        }
    }
`;

export const desktopContainerStyles = css`
    background: ${COLORS.WHITE};
`;

export const containerStyles = css`
    background: ${COLORS.WHITE};
    border: 1px solid ${COLORS.LIGHT_GRAY2};
    display: flex;
    position: relative;
    z-index: 2;
`;

export const paddingContainerStyles = css`
`;

export const leftContainerStyles = css`
    border-right: 1px solid ${COLORS.LIGHT_GRAY2};
    flex: 1;
    max-width: calc(100% - 231px);

    &>div {
        width: 100%;
    }

    @media screen and ${FROM_DESKTOP} {
        max-width: calc(100% - 376px);
    }
`;

export const rightContainerStyles = css`
    display: flex;
    flex-direction: column;

    &>div {
        height: calc(36.3% - 41px);
    }

    div:last-child {
        border-bottom: none;
    }
`;
