import {
    GET_TOPICS,
    GET_TOPICS_SUCCESS,
    GET_TOPICS_FAIL,
    SUBMIT_FEEDBACK,
    SUBMIT_FEEDBACK_SUCCESS,
    SUBMIT_FEEDBACK_FAIL,
    RESET_CONTACT_FORM,
} from 'state-management/constants/contactUs';
import { LOCATION_CHANGE } from 'connected-react-router';

const initialState = {
    topicsLoading: false,
    topics: [],
    formSubmitting: false,
    submitSucceeded: false,
};

function contactUsReducer(state = initialState, action = {}) {
    switch (action.type) {
    case GET_TOPICS:
        return {
            ...state,
            topicsLoading: true,
        };
    case GET_TOPICS_SUCCESS:
        const updatedTopics =  action.topics.map(topic => ({
            key: topic.id,
            value: topic.name,
        }))
        return {
            ...state,
            topicsLoading: false,
            topics:[...updatedTopics],
        };
    case GET_TOPICS_FAIL:
        return {
            ...state,
            topicsLoading: false,
        };
    case SUBMIT_FEEDBACK:
        return {
            ...state,
            formSubmitting: true,
        };
    case SUBMIT_FEEDBACK_SUCCESS:
        return {
            ...state,
            formSubmitting: false,
            submitSucceeded: true,
        };
    case SUBMIT_FEEDBACK_FAIL:
        return {
            ...state,
            formSubmitting: false,
            submitSucceeded: false,
        };
    case RESET_CONTACT_FORM:
        return { ...initialState };
    case LOCATION_CHANGE:
        return {
            ...initialState,
            topics: state.topics,
        };
    default:
        return state;
    }
}

export default contactUsReducer;
