import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS, RGB_COLORS } from 'utils/variables';

export const styledSlideStyles = css`
    background: ${COLORS.WHITE};
    box-shadow: 0 4px 20px 0 rgba(${RGB_COLORS.BLACK}, 0.16);
    box-sizing: border-box;
    padding-bottom: 25px;
    width: 100%;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        min-height: 520px;
    }
    @media screen\0 {
        img {
            display:block !important;
            transform: translate(0, -50%) !important;
            width: 100% !important;
        }
    }
`;

export const styledSlideImageStyles = css`
    height: 132px;
    margin: 0;
    position: relative;

    @media screen and ${FROM_TABLET} {
        height: 228px;
    }

    @media screen and ${FROM_DESKTOP} {
        height: 347px;
    }
`;

export const styledSlideHeadingStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.4px;
    line-height: 1.25;
    padding: 40px 15px 24px;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        font-size: 26px;
        letter-spacing: -0.5px;
        line-height: 1.23;
        padding: 50px 30px 26px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 36px;
        letter-spacing: -0.7px;
        line-height: 1.36;
        padding: 50px 60px 26px;
    }
`;

export const sliderStyles = css`
    .slick-dots .slick-active {
        background: ${COLORS.SAPPHIRE};
    }

    .slick-arrow svg {
        fill: ${COLORS.SAPPHIRE};
    }

    button.slick-arrow {
        opacity: 1 !important;

        &:hover {
            svg {
                fill: ${COLORS.SAPPHIRE};
            }
        }
    }
`;

export const styledSlideContentStyles = css`
    p {
        color: ${COLORS.DARK_GRAY2};
        font-family: ${FONT_AVENIR_NEXT};
        font-size: 14px;
        font-weight: normal;
        line-height: 1.43;
        margin-top: 0;
        padding: 0 15px;
        word-wrap: break-word;

        @media screen and ${FROM_TABLET} {
            font-size: 18px;
            font-weight: 500;
            line-height: 1.33;
            padding: 0 30px;
        }

        @media screen and ${FROM_DESKTOP} {
            letter-spacing: -0.3px;
            line-height: 1.67;
            padding: 0 60px;
        }
    }
`;

export const buttonStyles = css`
    &.slick-arrow {
        transform: translateY(-50%) translateY(-23px);
    }
`;

export const styledDotStyles = css;
