import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { IconClock } from 'components/atomics/atoms/Icons/Icons';
import { containerStyles, iconStyles } from 'components/TimeToRead/styles';

const StyledContainer = styled.div`
    ${containerStyles};
`;
const StyledIcon = styled(IconClock)`
    ${iconStyles};
`;

const TimeToRead = ({ className, minutes, showIcon }) => {
    if (!minutes) {
        return null;
    }

    const translationValues = {
        minutes,
    };
    const containerClass = `time-to-read ${className}`;

    return (
        <StyledContainer className={containerClass}>
            {
                showIcon && <StyledIcon />

            }
            <FormattedMessage
                id="readTime.desc"
                values={translationValues}
            />
        </StyledContainer>
    );
};

TimeToRead.propTypes = {
    className: PropTypes.string,
    minutes: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    showIcon: PropTypes.bool,
};

TimeToRead.defaultProps = {
    className: '',
    minutes: 0,
    showIcon: false,
};

export default TimeToRead;
