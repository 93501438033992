export const OPTIN_URL = (id, email) => `/advisor-service/opt-in/${id}/${email}`;
export const SAVE_URL = '/advisor-service/opt-in';

export const GET_OPTIN = 'optin/GET';
export const GET_OPTIN_SUCCESS = 'optin/GET_SUCCESS';
export const GET_OPTIN_FAIL = 'optin/GET_FAIL';

export const SAVE_ANSWER = 'optin/SAVE_ANSWER';
export const SAVE_ANSWER_SUCCESS = 'optin/SAVE_ANSWER_SUCCESS';

export const SET_REMINDER = 'optin/SET_REMINDER';

export const STAY_ENGAGED_OPTIN_ID = '0658b017-0373-4a16-b896-6d8d707bda79';
export const DJ_BRIEFING_OPTIN_ID = '82a24cdd-29e1-436c-8084-f8d07c1c4e90';
