import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { kebabCase } from 'lodash';
import { KEY_ENTER } from 'utils/keyCodes';
import Image from 'components/atomics/atoms/Image/Image';
import {
    sourceImageStyles,
    sourceInputStyles,
    sourceStyles,
    sourceTitleStyles,
    sourceContentStyles,
    indicatorStyles,
    checkIconStyles,
    plusIconStyles,
} from 'components/SourceSelector/styles';
import { CheckIconSmall, IconPlus } from 'components/atomics/atoms/Icons/Icons';
import { getContentSourcesImageUrl } from 'utils/getImageUrl';

const StyledSourceSelector = styled.div`
    ${sourceStyles};
`;
const StyledContentWrapper = styled.label`
    ${sourceContentStyles};
`;
const StyledSourceImage = styled.div`
    ${sourceImageStyles};
`;
const StyledInput = styled.input`
    ${sourceInputStyles};
`;
const StyledText = styled.span`
    ${sourceTitleStyles};
`;
const StyledIndicator = styled.span`
    ${indicatorStyles};
`;
const StyledCheckIcon = styled(CheckIconSmall)`
    ${checkIconStyles}
`;
const StyledPlusICon = styled(IconPlus)`
    ${plusIconStyles(StyledContentWrapper)}
`;

class SourceSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isFocused: false,
        };

        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    handleChange(event) {
        const { onChange } = this.props;

        if (onChange && typeof onChange === 'function') {
            onChange(event);
        }
    }

    handleBlur() {
        this.setState({
            isFocused: false,
        });
    }

    handleFocus() {
        this.setState({
            isFocused: true,
        });
    }

    handleKeyPress(event) {
        if (event.which === KEY_ENTER) {
            this.handleChange(event);
        }
    }

    renderSourceImage() {
        const {
            imageName,
            name,
        } = this.props;
        const sourceImageProps = {};

        if (!imageName) {
            sourceImageProps['aria-hidden'] = true;
        }

        const sourceImage = (
            <Image
                alt={name}
                format="png"
                src={`/api/${getContentSourcesImageUrl(imageName)}`}
            />
        );

        return (
            <StyledSourceImage {...sourceImageProps}>
                {imageName ? sourceImage : null}
            </StyledSourceImage>
        );
    }

    render() {
        const {
            checked,
            name,
            value,
            noUnderline,
        } = this.props;
        const { isFocused } = this.state;
        const id = `source-${kebabCase(name)}`;
        const Icon = checked ? StyledCheckIcon : StyledPlusICon;
        return (
            <StyledSourceSelector
                variant={{ checked }}
                noUnderline={noUnderline}
            >
                <StyledContentWrapper
                    aria-selected={checked}
                    htmlFor={id}
                    variant={{
                        isFocused,
                        checked,
                    }}
                >
                    {this.renderSourceImage()}
                    <StyledText>{name}</StyledText>
                    <StyledInput
                        checked={checked}
                        id={id}
                        onBlur={this.handleBlur}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        onKeyPress={this.handleKeyPress}
                        type="checkbox"
                        value={value}
                    />
                    <StyledIndicator selected={checked}>
                        <Icon />
                    </StyledIndicator>
                </StyledContentWrapper>
            </StyledSourceSelector>
        );
    }
}

SourceSelector.propTypes = {
    checked: PropTypes.bool.isRequired,
    imageName: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]).isRequired,
    noUnderline: PropTypes.bool,
};

SourceSelector.defaultProps = {
    imageName: '',
    noUnderline: false,
};

export default SourceSelector;
