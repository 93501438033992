import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RequestSource, UnderlinedCheckbox } from 'components';
import { itemStyles } from 'containers/Onboarding/Sources/AllSourcesTabPanel/styles';
import { formatSourceName } from "../../../../utils/sourceUtils";

export const StyledSourceCheckbox = styled(UnderlinedCheckbox)`
    ${itemStyles}
`;

/**
 * Render the all sources tab for onboarding source page.
 * @param {*} param0 
 */
const AllSourcesTabPanel = ({ sources, selected, changeStatus, isChoose }) => (
    <React.Fragment>
        {sources.map(source => (
            <StyledSourceCheckbox
                key={source.id}
                label={formatSourceName(source.name)}
                checked={selected.includes(source.id)}
                onChange={() => changeStatus(source.id)}
                id={`all-sources-${source.id}`}
            />
        ))}
        {!isChoose
        && <RequestSource onboarding />}
    </React.Fragment>
);

AllSourcesTabPanel.propTypes = {
    sources: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    selected: PropTypes.arrayOf(PropTypes.number),
    changeStatus: PropTypes.func.isRequired,
    isChoose: PropTypes.bool,
};

AllSourcesTabPanel.defaultProps = {
    selected: [],
    isChoose: false,
};

export default AllSourcesTabPanel;
