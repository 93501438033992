import * as type from 'state-management/constants/trendingTopic.common';

export const getTrendingTopicNews = () => ({
    type: type.GET_TRENDING_TOPIC_NEWS,
});

export const getTrendingTopicNewsSuccess = (data, seeMore) => ({
    data,
    seeMore,
    type: type.GET_TRENDING_TOPIC_NEWS_SUCCESS,
});

export const getTrendingTopicNewsFailure = error => ({
    error,
    type: type.GET_TRENDING_TOPIC_NEWS_FAIL,
});

export const resetTrendingTopicNews = () => ({
    type: type.RESET_TRENDING_TOPIC_NEWS,
});

export const getTrendingTopicFilters = (variant) => ({
    type: type.GET_TRENING_TOPIC_FILTERS,
    variant,
});

export const getTrendingTopicFiltersSuccess = (data, seeMore) => ({
    data,
    seeMore,
    type: type.GET_TRENING_TOPIC_FILTERS_SUCCESS,
});

export const getTrendingTopicFiltersFailure = error => ({
    error,
    type: type.GET_TRENING_TOPIC_FILTERS_FAIL,
});
