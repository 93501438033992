import React from 'react';
import styled from 'styled-components';
import {
    panelStyles,
    iconContainerStyles,
    kebabTextStyles,
    iconSave,
    iconEye,
    iconEyeInactive,
    iconClose,
} from './styles';
import {
    IconCross,
    IconEyeActive,
    IconEyeInactive,
    IconSave,
} from "../Icons/Icons";

const StyledPanel = styled.div`
    ${panelStyles}
`;

const KebabIconContainer = styled.span`
    ${iconContainerStyles}
`;

export const KebabText = styled.span`
    ${kebabTextStyles}
`;

export const KebabIconSave = styled(IconSave)`
    ${iconSave}
`;

export const KebabIconEyeActive = styled(IconEyeActive)`
    ${iconEye}
`;

export const KebabIconEyeInactive = styled(IconEyeInactive)`
    ${iconEyeInactive}
`;

export const KebabIconClose = styled(IconCross)`
    ${iconClose}
`;

/**
 * Handles building kebab menu items with wiring.
 * @param {*} param0 
 */
export default function KebabMenuItemBuilder({menuConfig, fRef}) {
    return (
        <StyledPanel
            data-rel="kebabOpenPanel"
            onClick={() => { }}>

            <ul ref={fRef}>
                {menuConfig.reduce((accum, {itemClick, icon, message, isHidden}, index) => {
                    !isHidden && accum.push((<li key={index}>
                        <button
                            type="button"
                            onClick={() => itemClick()}>
                            <KebabIconContainer>{icon}</KebabIconContainer>
                            <KebabText>{message}</KebabText>
                        </button>
                    </li>));
                    return accum;
                }, [])}
            </ul>
        </StyledPanel>
    );
}
