import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT } from 'utils/variables';
import { COLORS, RGB_COLORS } from 'utils/variables';

export const sidekickImageStyles = css`
    height: 100%;
    left: 0;
    margin: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
`;

export const sidekickWrapperStyles = css`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 0 0 -72px;
    padding: 0;
    position: relative;
    width: 100%;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        background-color: ${COLORS.SAPPHIRE};
        margin: 0;
        padding: 0 40px;
    }

    @media screen and ${FROM_DESKTOP} {
        flex-direction: row;
        min-height: 600px;
        padding: 0;
    }
`;

export const sidekickSectionContainerStyles = css`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0;
    width: 100%;

    @media screen and ${FROM_DESKTOP} {
        flex-direction: row;
        min-height: 600px;
    }
`;

export const sidekickContainerStyles = css`
    background-color: ${COLORS.SAPPHIRE};
    display: flex;
    position: relative;
    width: 100%;

    @media screen and ${FROM_TABLET} {
        background: transparent;
        position: static;
    }

    /* stylelint-disable */

    ${props => !props.imageAsset && css`
        @media screen and ${FROM_DESKTOP} {
            flex-shrink: 0;
            width: 50%;
        }

        @media screen and (min-width: 1460px) {
            flex-grow: 1;
        }
    `}

    /* stylelint-enable */
`;

export const sidekickContentWrapperStyles = css`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px 35px 20px;
    width: 100%;
    z-index: 2;

    @media screen and ${FROM_TABLET} {
        padding: 70px 0 0;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 105px 100px 35px 48px;
    }

    @media screen and (min-width: 1460px) {
        padding: 105px 100px 35px 0;
    }

    /* stylelint-disable */

    ${props => !props.imageAsset && css`
        padding: 50px 25px 20px;

        @media screen and ${FROM_TABLET} {
            padding: 70px 30px 0;
        }

        @media screen and ${FROM_DESKTOP} {
            padding: 84px 60px 35px 48px;
        }

        @media screen and (min-width: 1460px) {
            padding: 84px 60px 35px 0;
        }
    `}

    /* stylelint-enable */
`;

export const sidekickContentContainerStyles = css`
    display: flex;
    flex: auto;
    flex-direction: column;
`;

export const sidekickContentHeadingStyles = css`
    color: ${COLORS.WHITE};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 45px;
    font-weight: bold;
    letter-spacing: -1.6px;
    margin-bottom: 20px;
    white-space: nowrap;

    @media screen and ${FROM_TABLET} {
        font-size: 75px;
        letter-spacing: -3px;
        margin-bottom: 10px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 16px;
        font-size: 144px;
    }
`;

export const sidekickContentStyles = css`
    color: ${COLORS.WHITE};
    display: flex;
    flex-direction: column;
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    word-wrap: break-word;

    /* stylelint-disable */

    ${props => !props.footer && css`
        margin-bottom: 40px;
    `}

    /* stylelint-enable */

    @media screen and ${FROM_TABLET} {
        font-size: 30px;
        line-height: 1.33;
        margin-bottom: 20px;
    }

    * {
        margin-top: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    /* stylelint-disable */

    ${props => !props.title && css`
        @media screen and ${FROM_TABLET} {
            font-size: 48px;
            letter-spacing: -1px;
            line-height: 1.29;
        }

        @media screen and ${FROM_DESKTOP} {
            font-size: 60px;
            letter-spacing: -1.3px;
            line-height: 1.2;
        }
    `};

    /* stylelint-enable */
`;

export const sidekickFooterStyles = css`
    color: ${COLORS.WHITE};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 2.2;
    margin: 30px 0 35px;

    @media screen and ${FROM_TABLET} {
        font-size: 12px;
        line-height: 1.83;
        margin: 50px 0 -20px; /* Negative margin-bottom due to cardContainerStyles translateY option */
    }

    @media screen and ${FROM_DESKTOP} {
        margin: 55px 0 0;
    }

    /* stylelint-disable */

    ${props => !props.imageAsset && css`
        @media screen and ${FROM_TABLET} {
            margin: 30px 0 -20px; /* Negative margin-bottom due to cardContainerStyles translateY option */
        }

        @media screen and ${FROM_DESKTOP} {
            margin: 55px 0 0;
        }
    `};

    /* stylelint-enable */
`;

export const cardContainerStyles = css`
    align-self: flex-end;
    background-color: ${COLORS.WHITE};
    box-shadow: 0 4px 20px 0 rgba(${RGB_COLORS.BLACK}, 0.16);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 24px 60px 32px 24px;
    position: relative;
    transform: translateY(-40px);

    /* 24px is the sum of sidekickWrapperStyles padding-left and padding-right */

    width: calc(100% - 24px);
    z-index: 2;

    @media screen and ${FROM_TABLET} {
        margin: 0;
        padding: 45px 140px 55px 65px;
        transform: translateY(50px);
        width: 100%;
    }

    @media screen and ${FROM_DESKTOP} {
        min-width: 50vw;
        padding: 55px 110px 55px 60px;
        transform: translate(-11px, 40px);
        width: 50vw;
    }

    @media screen and (min-width: 1460px) {
        box-sizing: content-box;
        flex: 1 0 auto;
        min-width: auto;
        padding: 0 115px 0 0;
        transform: translate(20px, 40px);
        width: 50%;
    }
`;

export const cardWrapperContainerStyles = css`
    @media screen and (min-width: 1460px) {
        padding: 55px 20px 55px 60px;
    }
`;

export const cardHeaderStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.3px;
    line-height: 1.44;
    margin: 0 0 15px;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        font-size: 36px;
        letter-spacing: -0.6px;
        line-height: 1.28;
        margin: 0 0 20px;
    }
`;

export const cardBodyStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 14px;
    font-weight: normal;
    letter-spacing: -0.2px;
    line-height: 1.57;
    word-wrap: break-word;

    p {
        margin-top: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and ${FROM_TABLET} {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.3px;
        line-height: 1.67;
    }
`;
