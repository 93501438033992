import { css } from 'styled-components';
import { SIZES } from 'components/FieldCheckBox/constants';
import { outerCustomFocusMixin } from 'components/FocusVisibility/styles';
import { visuallyHiddenStyles } from 'components/VisuallyHidden/styles';
import { FROM_TABLET } from 'utils/mediaQueries';
import {
    COLORS
} from 'utils/variables';

export const labelStyles = ({ variant }) => css`
    align-items: center;
    color: ${COLORS.DARK_GRAY2};
    cursor: pointer;
    display: inline-flex;

    /* stylelint-disable */

    ${variant.size === SIZES.MEDIUM && css`
        font-size: 14px;
        font-weight: 500;
        min-height: 20px;
    `}

    ${variant.size === SIZES.LARGE && css`
        font-size: 15px;
        font-weight: 400;
        min-height: 30px;

        @media screen and ${FROM_TABLET} {
            font-size: 24px;
        }
    `}

    /* stylelint-enable */
`;

export const indicatorStyles = ({ variant }) => css`
    border: 2px solid ${COLORS.SAPPHIRE};
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    flex-shrink: 0;
    margin-right: 12px;
    position: relative;

    /* stylelint-disable */

    ${variant.size === SIZES.MEDIUM && css`
        height: 20px;
        width: 20px;
    `}

    ${variant.size === SIZES.LARGE && css`
        height: 30px;
        width: 30px;
    `}

    ${variant.isChecked && css`
        background-color: ${COLORS.SAPPHIRE};
    `}
    
    ${variant.isFocused && css`
        ${outerCustomFocusMixin};
    `};

    /* stylelint-enable */
`;

export const indicatorIconStyles = ({ variant }) => css`
    fill: ${COLORS.WHITE};
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    /* stylelint-disable */

    ${variant.size !== SIZES.LARGE && css`
        height: 8px;
        width: 10px;
    `}

    /* stylelint-enable */
`;

export const inputStyles = css`
    ${visuallyHiddenStyles}
`;
