import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Formik, Field } from 'formik';
import { KEY_ENTER } from 'utils/keyCodes';
import { Button, FieldText } from 'components';
import {
    containerStyles,
    textContainerStyles,
    textQuestionStyles,
    textAnswerStyles,
    inputContainerStyles,
    textInputStyles,
    buttonStyles,
} from 'components/RequestSource/styles';
import { setSourceName, createSourceRequest } from 'state-management/actions/requestSource';
import { clearErrors as clearErrorsAction } from 'state-management/actions/errors';

const StyledContainer = styled.div`
    ${containerStyles};
`;

export const StyledTextContainer = styled.dl`
    ${textContainerStyles};
`;

export const StyledTextQuestion = styled.dt`
    ${textQuestionStyles};
`;

const StyledTextAnswer = styled.dd`
    ${textAnswerStyles};
`;

const StyledInputContainer = styled.div`
    ${inputContainerStyles};
`;

const StyledFieldText = styled(FieldText)`
    ${textInputStyles};
`;

const StyledButton = styled(Button)`
    ${buttonStyles};
`;

class RequestSource extends React.Component {
    /**
     * Get the label for the form input.
     */
    getInputLabel = () => {
        const { intl } = this.props;
        return intl.formatMessage({ id: 'requestSource.inputLabel' });
    };

    /**
     * Get the form title.
     */
    getTitle = () => {
        const { intl, isRequested } = this.props;
        const { sourceName } = this.props.initialValues;
        return intl.formatMessage({
            id: `requestSource.${isRequested ? 'success' : 'initial'}.title`,
        }, { sourceName });
    };

    /**
     * Get the button label text based on submitting state.
     */
    getButtonText = () => {
        const { intl, isRequested } = this.props;
        return intl.formatMessage({
            id: `requestSource.${isRequested ? 'requested' : 'request'}`,
        });
    };

    /**
     * Get the main component text.
     */
    getText = () => {
        const { intl, isRequested, onboarding } = this.props;
        return intl.formatMessage({
            id: `requestSource${isRequested && onboarding ? '.onboarding' : ''}.text`,
        });
    };

    /**
     * Handle enter=submit.
     */
    handleKeyDown = (e, formikProps) => {
        if (e.keyCode === KEY_ENTER) {
            formikProps.handleSubmit(e);
        }
    };

    /**
     * Handle value changes.
     */
    handleChange = ({ errors, setFieldError }) => ({ target: { name, value } }) => {
        this.props.setSourceName(value);
        if (name && errors[name]) {
            setFieldError(name, null);
        }
    };

    /**
     * Handle form submission.
     */
    handleSubmit = () => {
        const { isRequested, onRequestSourceSubmit } = this.props;
        if (!isRequested) {
            onRequestSourceSubmit();
        }
    };

    /**
     * Validate form values or produce visible error.
     */
    validate = (values) => {
        this.props.clearErrors();
        const errors = {};
        if (values.sourceName.trim().length < 2) {
            errors.sourceName = this.props.intl.formatMessage({ id: 'requestSource.error.length' });
        }
        if (errors.sourceName) {
            this.componentRef.querySelector('#sourceName').focus();
        }
        return errors;
    };

    /**
     * Render form and underlying components.
     */
    render() {
        return (
            <StyledContainer
                ref={(input) => { this.componentRef = input; }}
                onboarding={this.props.onboarding}
                profile={this.props.profile ? 'true' : 'false'}
            >
                <StyledTextContainer onboarding={this.props.onboarding}>
                    <StyledTextQuestion>
                        {this.getTitle()}
                    </StyledTextQuestion>
                    <StyledTextAnswer profile={this.props.profile.toString()}>
                        {this.getText()}
                    </StyledTextAnswer>
                </StyledTextContainer>
                <Formik
                    onSubmit={this.handleSubmit}
                    initialValues={this.props.initialValues}
                    validate={this.validate}
                    validateOnBlur={false}
                    validateOnChange={false}
                    render={formikProps => (
                        <StyledInputContainer
                            aria-labelledby="request-source"
                            noValidate
                            onboarding={this.props.onboarding}
                        >
                            <div>
                                {!this.props.isRequested && (
                                    <Field
                                        component={StyledFieldText}
                                        onChange={this.handleChange(formikProps)}
                                        onKeyDown={e => this.handleKeyDown(e, formikProps)}
                                        id="sourceName"
                                        name="sourceName"
                                        maxLength="50"
                                        label={this.getInputLabel()}
                                    />
                                )}
                            </div>
                            <StyledButton
                                appearance={Button.APPEARANCE.PRIMARY}
                                aria-disabled={this.props.isRequested}
                                isVisuallyDisabled={this.props.isRequested}
                                size={Button.SIZES.MEDIUM}
                                id="request-source"
                                onClick={formikProps.handleSubmit}
                                data-analytics-placement="Button : body"
                                data-analytics-label="trackLink : button"
                                data-analytics-id={this.getButtonText() + " source"}
                            >
                                {this.getButtonText()}
                            </StyledButton>
                        </StyledInputContainer>
                    )}
                />
            </StyledContainer>
        );
    }
}

RequestSource.propTypes = {
    intl: intlShape.isRequired,
    initialValues: PropTypes.shape({
        sourceName: PropTypes.string,
    }).isRequired,
    isRequested: PropTypes.bool.isRequired,
    setSourceName: PropTypes.func.isRequired,
    onRequestSourceSubmit: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    onboarding: PropTypes.bool,
    profile: PropTypes.bool,
};

RequestSource.defaultProps = {
    onboarding: false,
    profile: false,
};

const mapStateToProps = state => ({
    initialValues: {
        sourceName: state.requestSource.sourceName,
    },
    isRequested: state.requestSource.isRequested,
});

const mapDispatchToProps = dispatch => ({
    setSourceName: sourceName => dispatch(setSourceName(sourceName)),
    clearErrors: () => dispatch(clearErrorsAction()),
    onRequestSourceSubmit: sourceName => dispatch(createSourceRequest(sourceName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RequestSource));
