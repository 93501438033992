import { css } from 'styled-components';
import {
    FROM_DESKTOP,
    FROM_TABLET,
} from 'utils/mediaQueries';
import {
    FONTS,
    COLORS,
} from 'utils/variables';

export const formContentStyles = css`
    margin-left: 16px;
    margin-right: 16px;

    @media screen and ${FROM_TABLET} {
        width: 100%;
        max-width: 574px;
        margin: auto;
    }
`;

export const formRowStyles = css`
    display: flex;
    text-align: left;
    
    &>div {
        width: 100%;
    }

    &>div:nth-child(2) {
        margin-left: 16px;
    }

    ${props => props.margin && css`
        margin-bottom: 20px;
    `}

    ${props => props.rightAlign && css`
        display: block;
        text-align: center;
        
        a:nth-child(2) {
            margin-left: 16px;
        }

        @media screen and ${FROM_TABLET} {
            text-align: right;
        }
    `}

    ${props => props.setHeight && css`
        height: 108px;
        min-height: 108px;
        max-height: 108px;
        overflow: hidden;
    `}
`;

export const formOuterContainerStyles = css`
    padding-top: 20px;
    display: none;
    overflow: hidden;
`;

export const formContainerStyles = css`
    background: ${COLORS.WHITE};
    padding-top: 35px;
    padding-bottom: 35px;
    text-align: center;

    h3 {
        margin: 0;
        margin-bottom: 35px;
        font-size: 26px;
        line-height: 32px;
        font-weight: normal;
        color: ${COLORS.SAPPHIRE};
    }

    @media screen and ${FROM_TABLET} {
        padding-bottom: 56px;
    }
`;

export const contentContainerStyles = css`
    text-align: center;

    ${props => props.isTop && css`
        svg {
            height: 53px;
            width: 100px;
            margin-bottom: 50px;
        }
    `}
`;

export const messageStyles = css`
    font-size: 15px;
    line-height: 20px;
    color: ${COLORS.BLACK};
    
    ${props => !props.isTop && css`
        margin-bottom: 98px;
    `}

    a {
        color: ${COLORS.SAPPHIRE};
    }

    a[type="type/ghostBright"] {
        margin-top: 20px;
    }

    span[id="message"] {
        font-size: 18px;
        line-height: 20px;
    }

    b[id="disclosure"] {
        font-size: 12px;
    }

    ${props => !props.isTop && css`
        @media screen and ${FROM_TABLET} {
            margin-bottom: 220px;
        }

        @media screen and ${FROM_DESKTOP} {
            margin-bottom: 170px;
        }
    `}
`;

export const mockLinkStyles = css`
    color: ${COLORS.SAPPHIRE};
    text-decoration: underline;
    cursor: pointer;
`;

export const titleStyles = css`
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    color: ${COLORS.SAPPHIRE};
    font-weight: 400;
    max-width: 262px;
    font-size: 26px;
    line-height: 36px;
    padding-top: 98px;
    padding-bottom: 38px;
    margin: auto;

    @media screen and ${FROM_TABLET} {
        font-size: 42px;
        line-height: 52px;
        padding-top: 216px;
        padding-bottom: 40px;
        max-width: 424px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding-top: 170px;
    }
`;
