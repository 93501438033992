import {
    all, call, put, select, takeLatest,
} from 'redux-saga/effects';

import {
    visitedDownloadSuccess,
    visitedDownloadFail
} from 'state-management/actions/download';
import { setError } from 'state-management/actions/errors';
import {
    VISITED_DOWNLOAD_URL,
    VISITED_DOWNLOAD
} from 'state-management/constants/download';
import Api from 'utils/api';

function* visitedDownload() {
    try {
        yield call(Api.post, VISITED_DOWNLOAD_URL);
        yield put(visitedDownloadSuccess);
    } catch (e) {
        yield put(visitedDownloadFail);
        yield put(setError('download.errors.server.submit'));
    }
}

function* downloadSaga() {
    yield all([
        takeLatest(VISITED_DOWNLOAD, visitedDownload),
    ]);
}
export default downloadSaga;