import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { injectIntl, intlShape } from 'react-intl';

import { UnderlinedRadio } from 'components';
import { itemStyles, listStyles } from 'containers/Search/SearchFilterItems/styles';

const StyledList = styled.ul`
    ${listStyles}
`;
export const StyledUnderlinedRadio = styled(UnderlinedRadio)`
    ${itemStyles}
`;

/**
 * Date based search filters (items only, used by container) for search page filter menu.
 * @param {*} param0 
 */
const DateSearchFilterItems = ({ intl, section, onToggleItem }) => {
    const getLabel = item => (item.translationId
        ? intl.formatMessage({ id: item.translationId })
        : item.name);

    return (
        <StyledList>
            {section.items.map((item, idx) => (
                <li key={item.id || item.name}>
                    <StyledUnderlinedRadio
                        checked={item.checked}
                        label={getLabel(item)}
                        onChange={() => onToggleItem(idx)}
                    />
                </li>))}
        </StyledList>
    );
};

DateSearchFilterItems.propTypes = {
    intl: intlShape.isRequired,
    section: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
    onToggleItem: PropTypes.func.isRequired,
};

export default injectIntl(DateSearchFilterItems);
