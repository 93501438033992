import React from 'react';

import ConnectedHomepageGreeting from 'containers/Home/HomepageGreeting/HomepageGreeting';
import Announcements from 'containers/Home/Announcements/Announcements';
import LuminPost from 'components/Posts/LuminPost/LuminPost';
import QuizComponent from 'containers/Guide/Quiz/Quiz';
import {
    Container,
    SageNote,
} from 'components';

/**
 * Component switch to choose proper homepage component and pass it the needed data.
 * @param {*} param0 
 */
const HomepageComponent = ({ type, component, id }) => {
    switch (type) {
    case 'GREETING':
        return (
            <ConnectedHomepageGreeting {...component} />
        );
    case 'ANNOUNCEMENTS':
        return (
            <Announcements {...component} />
        );
    case 'LUMIN_POST':
        return (
            <LuminPost {...component} />
        );
    case 'POLL':
    case 'QUIZ': {
        if (!QuizComponent.areAttributesValid(component)
            || (!SageNote.areAttributesValid(component.noteHeader, component.note) && type === 'QUIZ')) {
            return null;
        }

        component.id = id;
        const quizComponent = (
            <QuizComponent
                id={component.id}
                data={component}
                useHomepage
            />
        );
        return (
            type === 'POLL' && !component.note && !component.noteHeader ? (
                quizComponent
            ) : (
                <Container>
                    <SageNote
                        id={component.id}
                        component={quizComponent}
                        title={component.noteHeader}
                        description={component.note}
                    />
                </Container>
            )
        );
    }
    default:
        return null;
    }
};

export default HomepageComponent;
