import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from 'components/Loader/Loader';
import LegalFDIC from 'components/LegalFDIC/LegalFDIC';
import storage from "../../../utils/store";
import { Link } from 'react-router-dom';
import {
    SIGNUP_EMAIL,
} from 'state-management/sagas/signUp';
import {
    FormattedMessage,
    injectIntl,
} from 'react-intl';
import styled from 'styled-components';
import {
    contentStyles,
    markStyles,
    textStyles,
    wrapperStyles,
} from 'containers/Onboarding/OnboardingAllDone/styles';
import { Container } from 'components';
import {
    containerStyles,
    failedContainerStyles,
    failedTitleStyles,
    failedDescriptionStyles,
    FailedTextStyles,
} from 'containers/EmailVerification/VerifyEmail/styles';
import anime from 'animejs';
import Input from 'components/atomics/atoms/Input/Input';
import { TYPE_XLARGE } from 'components/atomics/atoms/Input/Input';
import Button from 'components/atomics/atoms/Button/Button';
import { ROUTES } from '../../App/constants';
import AnimatedMark from 'components/AnimatedMark/AnimatedMark';
import { confirmEmail, resendEmail } from 'state-management/actions/signUp';
import { isEmailValid } from '../../../utils/validators/emailValidator';

const StyledContainer = styled(Container)`
    ${containerStyles}
`;

const StyledWrapper = styled.div`
    ${wrapperStyles}
`;

const StyledContent = styled.div`
    ${contentStyles}
`;

const StyledMark = styled(AnimatedMark)`
    ${markStyles}
`;

const StyledText = styled.p`
    ${textStyles}
`;

const FailedContainer = styled.div`
    ${failedContainerStyles}
`;

const FailedTitle = styled.h1`
    ${failedTitleStyles}
`;

const FailedDescription = styled.p`
    ${failedDescriptionStyles}
`;

const FailedText = styled.div`
    ${FailedTextStyles}
`;

/**
 * Verify email page (shown when a user clicks the verify link in an email).
 */
export class VerifyEmail extends React.Component {
    /**
     * Default constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            isAnimated: false,
            isAnimating: false,
            confirmationSent: false,
            confirmed: false,
            emailInvalid: false,
            email: storage.get(SIGNUP_EMAIL),
            showErrorText: false,
        };


        this.contentRef = React.createRef();
        this.textRef = React.createRef();

        this.animate = this.animate.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.handleResendSubmit = this.handleResendSubmit.bind(this);
    }

    /**
     * Animate logo at mount.
     */
    componentDidMount() {
        this.props.confirm(this.props.match.params.token, this.props.match.params.email || null);
    }

    /**
     * Kill animation if they manually leave page early.
     */
    componentWillUnmount() {
        if (this.state.isAnimating) {
            this.animation.pause();
        }
    }

    /**
     * Monitor confirmation process.
     * @param {*} nextProps 
     */
    componentWillUpdate(nextProps) {
        if (nextProps.isConfirming === true && !this.state.confirmationSent) {
            this.setState({
                confirmationSent: true,
            });
        } else if (nextProps.isConfirmed === true && this.state.confirmationSent && !this.state.confirmed) {
            this.setState({
                confirmed: true,
            }, () => {
                setTimeout(() => { this.animate() }, 100);
            });
        } else if (nextProps.resendComplete === true) {
            window.location.href = '/confirm-email';
        } else if (nextProps.resendFail && !this.state.showErrorText) {
            this.setState({
                showErrorText: true,
            });
        }
    }

    /**
     * Animate the mark logo.
     */
    animate() {
        if (this.state.isAnimated === true) {
            return;
        }

        const contentNode = this.contentRef.current;
        const textNode = this.textRef.current;

        this.setState({
            isAnimated: true,
        });

        if (textNode && contentNode) {
            this.setState({
                isAnimating: true,
            });
            this.animation = anime.timeline();
            this.animation
                .add({
                    targets: textNode,
                    easing: [0.53, 0.05, 0.01, 0.97],
                    translateY: [40, 0],
                    opacity: [0, 1],
                    duration: 660,
                    offset: 330,
                    complete: () => {
                        textNode.removeAttribute('style');
                    },
                })
                .add({
                    targets: contentNode,
                    easing: [0.53, 0.05, 0.01, 0.97],
                    translateY: [0, 80],
                    opacity: [1, 0],
                    duration: 660,
                    offset: 2330,
                    complete: () => {
                        this.setState({
                            isAnimating: false,
                        }, () => {
                            window.location.href = ROUTES.SIGN_IN;
                        });
                    },
                });
        }
    }

    /**
     * Validate email input value.
     * @param {*} value 
     */
    validateEmail(event) {
        const value = event.target.value;

        this.setState({
            email: value,
            emailInvalid: !isEmailValid(value),
        });
    }

    /**
     * Render a form at token validation failure to resend confirmation email.
     */
    renderFailureForm() {
        const showResendFailure = this.state.showErrorText;

        return (
            <React.Fragment>
                <Container>
                    <FailedContainer showResendFailure={showResendFailure}>
                        <FailedTitle>
                            <FormattedMessage id="signup.vefification.failed.title" />
                        </FailedTitle>
                        {!this.state.email && <Button
                            to="/sign-in"
                        >
                            <FormattedMessage id="signInSection.button.signIn" />
                        </Button>}
                        {this.state.email
                            && <Button
                                to="#"
                                onClick={this.handleResendSubmit}
                            >
                                <FormattedMessage id="resend.button.label" />
                            </Button>}
                        {showResendFailure && <FailedDescription>
                            <FormattedMessage
                                id="signup.verification.subtext"
                                values={{
                                    link: (
                                        <Link to="/contact-us">
                                            <FormattedMessage id="signup.verification.subtext.link" />
                                        </Link>
                                    ),
                                }}
                            />
                        </FailedDescription>}
                    </FailedContainer>
                </Container>
                <LegalFDIC />
            </React.Fragment>
        );
    }

    /**
     * Handle sending up the email address for resend of validation email.
     */
    handleResendSubmit() {
        // if (!this.state.email.length || this.state.emailInvalid) {
        //     return;
        // }

        setTimeout(() => {
            this.props.requestEmail(this.state.email);
        }, 1000);
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const { isAnimatable } = this.props;
        const { isAnimated } = this.state;

        if (!this.state.confirmationSent || this.props.isConfirming) {
            return (<Loader />);
        }

        if (!this.state.confirmed) {
            return this.renderFailureForm();
        }

        return (
            <React.Fragment>
                <StyledContainer>
                    <StyledWrapper>
                        <StyledContent
                            ref={this.contentRef}
                        >
                            <StyledMark />
                            <StyledText
                                ref={this.textRef}
                                variant={{ isAnimatable, isAnimated }}
                            >
                                <FormattedMessage id="signup.verification.verify" />
                            </StyledText>
                        </StyledContent>
                    </StyledWrapper>
                </StyledContainer>
                <LegalFDIC />
            </React.Fragment>
        );
    }
}

VerifyEmail.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            token: PropTypes.string,
        }),
    }).isRequired,
};

const mapStateToProps = state => ({
    isConfirming: state.signUp.isConfirming,
    isConfirmed: state.signUp.isConfirmed,
    resendComplete: state.signUp.resendEmailComplete,
    resendFail: state.signUp.resendEmailFail,
});

const mapDispatchToProps = dispatch => ({
    confirm: (token, email) => dispatch(confirmEmail(token, email)),
    requestEmail: (email) => dispatch(resendEmail(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(VerifyEmail));
