import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'components/atomics/molecules/Dropdown/Dropdown';
import FieldError from 'components/FieldError/FieldError';
import labels from '../../lang/en.json';

const FieldDropdown = (props) => {
    const {
        ariaLabel,
        id,
        field,
        form,
        onChange,
        ignoreErrors,
        useLabel,
        onBlur,
        ...otherProps
    } = props;

    const fieldName = field.name;
    const error = form.errors[fieldName];
    const isInvalid = error !== undefined && error !== null;
    const isTouched = !!form.touched[fieldName];

    const handleChange = (value) => {
        onChange(field.name, value);

        if (form.setFieldValue) {
            form.setFieldValue(field.name, value);
        }
    };

    const getLabel = () => {
        const options = props.options;
        const { removeFieldNameAsEmptyOtion } = props;

        let result;

        if (options) {
            const selectedItem = options.find(x => x.key === field.value);

            result = selectedItem ? selectedItem.value : !removeFieldNameAsEmptyOtion ? fieldName : labels.translations[useLabel];
        }

        return result;
    }

    return (
        <React.Fragment>
            <Dropdown
                {...otherProps}
                ariaLabel={ariaLabel}
                title={useLabel ? labels.translations[useLabel] : null}
                emptyOptionLabel={useLabel ? labels.translations[useLabel] : null}
                id={id}
                emptyOptionLabel={getLabel()}
                value={field.value}
                onValueChanged={handleChange}
                isEmptyOptionSelectable={false}
                onBlur={onBlur}
            />
            <FieldError
                id={id}
                error={ignoreErrors ? null : error}
                isVisible={isTouched && isInvalid}
            />
        </React.Fragment>
    );
};

FieldDropdown.propTypes = {
    ariaLabel: PropTypes.string,
    ignoreErrors: PropTypes.bool,
    id: PropTypes.string.isRequired,
    field: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.arrayOf(PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ])),
        ]),
    }).isRequired,
    form: PropTypes.shape({
        errors: PropTypes.shape({}),
        touched: PropTypes.shape({}),
        setFieldValue: PropTypes.func,
    }).isRequired,
    onChange: PropTypes.func,
    removeFieldNameAsEmptyOtion: PropTypes.bool,
    useLabel: PropTypes.string,
    onBlur: PropTypes.func,
};

FieldDropdown.defaultProps = {
    ariaLabel: '',
    ignoreErrors: false,
    onChange: () => { },
    removeFieldNameAsEmptyOtion: false,
    useLabel: null,
    onBlur: () => { },
};

export default FieldDropdown;
