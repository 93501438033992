import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setScrollToTop } from 'state-management/actions/scroll';
import { getHomepage as getHomepageData } from 'state-management/actions/homepage.common';
import HomepageComponent from 'containers/Home/HomepageComponent';
import GotSuggestions from 'containers/Home/GotSuggestions/GotSuggestions';
import Loader from 'components/Loader/Loader';

import { StyleBackToTopButton } from 'components/MarketBriefing/page/MarketBriefingPage';
import {
    IconArrowDown,
} from 'components/atomics/atoms/Icons/Icons';
import labels from '../../lang/en.json';

/**
 * Home page.
 */
export class Home extends React.Component {

    constructor(props) {
        super(props);       

        this.state = {           
            showBackToTop: false,
        };
        this.handleWindowScroll = this.handleWindowScroll.bind(this);
        this.backToTop = this.backToTop.bind(this);
    }

    /**
     * Gather needed data for display and scroll to top.
     */
    componentDidMount() {
        const { scrollToTop, getHomepage } = this.props;
        getHomepage();
        scrollToTop();
        window.addEventListener('scroll', this.handleWindowScroll);
    }

    /**
     * Render the back to top sticky button.
     */
    renderBackToTop() {
        return (
            <StyleBackToTopButton
                aria-label={labels.translations['marketBriefing.page.scrollToTop']}
                type="button"
                id="backToTopLink"
                onClick={this.backToTop}
                data-analytics-placement="Button : body"
                data-analytics-label="trackLink : button"
                data-analytics-id="backtotop:News Page"
            >
                <IconArrowDown />
            </StyleBackToTopButton>
        );
    }

    /**
     * Track window scrolling for back to top button usage.
     */
    handleWindowScroll() {
        const scrollTop = document.body.scrollTop
            ? document.body.scrollTop : document.documentElement.scrollTop;
        if (scrollTop > 0) {
            this.setState({
                showBackToTop: true,
            });
        } else {
            this.setState({
                showBackToTop: false,
            });
        }
    }

    /**
     * Scroll the user back to the top of page.
     */
    backToTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behaviour: 'smooth'
        });
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const { isLoading } = this.props;

        if (isLoading) {
            return <Loader />;
        }

        return (
            <React.Fragment>
                {
                    this.props.components
                        .map(({ type, id, ...component }) => (
                            <HomepageComponent
                                key={id}
                                type={type}
                                component={component}
                                id={id}
                            />
                        ))
                }
                <GotSuggestions />
                {this.state.showBackToTop
                    && this.renderBackToTop()}
            </React.Fragment>
        );
    }
}

Home.propTypes = {
    scrollToTop: PropTypes.func.isRequired,
    getHomepage: PropTypes.func.isRequired,
    components: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
    })).isRequired,
    isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    components: state.homepage.components,
    isLoading: state.homepage.isLoading,
});

const mapDispatchToProps = dispatch => ({
    scrollToTop: () => dispatch(setScrollToTop()),
    getHomepage: () => dispatch(getHomepageData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
