import React from 'react';
import styled from 'styled-components';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { CheckIconSmall, IconCross } from 'components/atomics/atoms/Icons/Icons';
import {
    arePasswordRulesValid,
    getPasswordFormatRules,
} from 'utils/validators/passwordValidator';
import {
    errorIconStyles,
    ruleIconStyles,
    rulesContainerStyles,
    rulesDescStyles,
    rulesListStyles,
    ruleStyles,
    successIconStyles,
    disclaimerStyles,
} from 'components/PasswordRules/styles';
/*import { FormattedMessage } from 'react-intl';*/

/*const StyledDisclaimer = styled.div`
    ${disclaimerStyles}
`;*/

const StyledRulesContainer = styled.div`
    ${rulesContainerStyles};
`;

const StyledRulesDesc = styled.p`
    ${rulesDescStyles};
`;

const StyledRulesList = styled.ul`
    ${rulesListStyles};
`;

const StyledRule = styled.li`
    ${ruleStyles};
`;

const StyledRuleIcon = styled.span`
    ${ruleIconStyles};
`;

const StyledSuccessIcon = styled(CheckIconSmall)`
    ${successIconStyles};
`;

const StyledErrorIcon = styled(IconCross)`
    ${errorIconStyles};
`;

export const handlePasswordChange = (e, passwordFormatRules, passwordHasBeenBlurred, email) => {
    const newRules = getPasswordFormatRules(e.target.value, email);
    const updatedPasswordFormatRules = {};

    Object.entries(newRules).forEach(([key, value]) => {
        const falseValue = passwordFormatRules[key] === undefined ? undefined : false;
        updatedPasswordFormatRules[key] = value || falseValue;
    });

    if (!passwordHasBeenBlurred) {
        Object.entries(updatedPasswordFormatRules).forEach(([key]) => {
            if (updatedPasswordFormatRules[key] === false) {
                updatedPasswordFormatRules[key] = undefined;
            }
        });
    }

    return updatedPasswordFormatRules;
};

export const handlePasswordBlurByEmail = (element, passwordFormatRules, blurred, email) => {
    let updatedPasswordFormatRules = passwordFormatRules;

    if (!arePasswordRulesValid(element.value, email)) {
        updatedPasswordFormatRules = getPasswordFormatRules(element.value, email);

        return updatedPasswordFormatRules;
    }

    return getPasswordFormatRules(element.value, email);
}

export const handlePasswordBlur = (e, passwordFormatRules, blurred, email) => {
    let updatedPasswordFormatRules = passwordFormatRules;

    if (!arePasswordRulesValid(e.target.value, email)) {
        updatedPasswordFormatRules = getPasswordFormatRules(e.target.value, email);

        return updatedPasswordFormatRules;
    }

    return getPasswordFormatRules(e.target.value, email);

    /*Object.entries(updatedPasswordFormatRules).forEach(([key]) => {
        if (updatedPasswordFormatRules[key] === false) {
            updatedPasswordFormatRules[key] = undefined;
        }
    });*/

    //return updatedPasswordFormatRules;
};

class PasswordRules extends React.Component {
    /**
     * Get transaltion for a resource id.
     * @param {*} id
     * @param {*} values
     */
    getTranslation(id, values = {}) {
        const { intl: { formatMessage } } = this.props;

        return formatMessage({
            id,
        }, values);
    }

    /**
     * See if password rules are met.
     */
    getHasAllRules(rules) {
        let trueCount = 0;

        for (let index in rules) {
            if (rules[index]) {
                trueCount++;
            }
        }

        return trueCount > 4;
    }

    /**
     * Render password rules display.
     */
    render() {
        let {
            containsUppercaseLetter,
            containsLowercaseLetter,
            containsNumber,
            containsSpecialSymbol,
            isCharLength,
            containsRepeat,
        } = this.props.passwordRules;
        const { theme } = this.props;
        const descText = this.getTranslation('signUp.rules.desc');
        const charLengthText = this.getTranslation('signUp.rules.charLength');
        const uppercaseRuleText = this.getTranslation('signUp.rules.uppercase');
        const lowercaseRuleText = this.getTranslation('signUp.rules.lowercase');
        const numberRuleText = this.getTranslation('signUp.rules.number');
        const specialCharacterRuleText = this.getTranslation('signUp.rules.special');
        const noRepeatText = this.getTranslation('signUp.rules.norepeat');
        const meetsRules = this.getHasAllRules(this.props.passwordRules);

        if (!containsUppercaseLetter && meetsRules) {
            containsUppercaseLetter = undefined;
        }

        if (!containsLowercaseLetter && meetsRules) {
            containsLowercaseLetter = undefined;
        }

        if (!containsNumber && meetsRules) {
            containsNumber = undefined;
        }

        if (!containsSpecialSymbol && meetsRules) {
            containsSpecialSymbol = undefined;
        }
        
        return (
            <StyledRulesContainer>
                <StyledRulesDesc>{descText}</StyledRulesDesc>
                <StyledRulesList>
                    <StyledRule
                        ruleState={isCharLength}
                        theme={theme}
                    >
                        {charLengthText}
                        {isCharLength !== undefined ? (
                            <StyledRuleIcon>
                                {isCharLength
                                    ? <StyledSuccessIcon />
                                    : <StyledErrorIcon />
                                }
                            </StyledRuleIcon>
                        ) : '' }
                    </StyledRule>
                    <StyledRule
                        ruleState={containsUppercaseLetter}
                        theme={theme}
                    >
                        {uppercaseRuleText}
                        {containsUppercaseLetter !== undefined ? (
                            <StyledRuleIcon>
                                {containsUppercaseLetter
                                    ? <StyledSuccessIcon />
                                    : <StyledErrorIcon />
                                }
                            </StyledRuleIcon>
                        ) : '' }
                    </StyledRule>
                    <StyledRule
                        ruleState={containsLowercaseLetter}
                        theme={theme}
                    >
                        {lowercaseRuleText}
                        {containsLowercaseLetter !== undefined ? (
                            <StyledRuleIcon>
                                {containsLowercaseLetter
                                    ? <StyledSuccessIcon />
                                    : <StyledErrorIcon />
                                }
                            </StyledRuleIcon>
                        ) : ''}
                    </StyledRule>
                    <StyledRule
                        ruleState={containsNumber}
                        theme={theme}
                    >
                        {numberRuleText}
                        {containsNumber !== undefined ? (
                            <StyledRuleIcon>
                                {containsNumber
                                    ? <StyledSuccessIcon />
                                    : <StyledErrorIcon />
                                }
                            </StyledRuleIcon>
                        ) : ''
                        }
                    </StyledRule>
                    <StyledRule
                        ruleState={containsSpecialSymbol}
                        theme={theme}
                    >
                        {specialCharacterRuleText}
                        {containsSpecialSymbol !== undefined ? (
                            <StyledRuleIcon>
                                {containsSpecialSymbol
                                    ? <StyledSuccessIcon />
                                    : <StyledErrorIcon />
                                }
                            </StyledRuleIcon>
                        ) : ''
                        }
                    </StyledRule>
                    <StyledRule
                        ruleState={containsRepeat !== undefined ? containsRepeat !== true : undefined}
                        theme={theme}
                    >
                        {noRepeatText}
                        {containsRepeat !== undefined ? (
                            <StyledRuleIcon>
                                {!containsRepeat
                                    ? <StyledSuccessIcon />
                                    : <StyledErrorIcon />
                                }
                            </StyledRuleIcon>
                        ) : ''
                        }
                    </StyledRule>
                </StyledRulesList>
                {/*<StyledDisclaimer>
                    <FormattedMessage id="signUp.rules.disclaimer" />
                </StyledDisclaimer>*/}
            </StyledRulesContainer>
        );
    }
}

PasswordRules.propTypes = {
    intl: intlShape.isRequired,
    passwordRules: PropTypes.shape({
        containsUppercaseLetter: PropTypes.bool,
        containsLowercaseLetter: PropTypes.bool,
        containsNumber: PropTypes.bool,
        containsSpecialSymbol: PropTypes.bool,
        isCharLength: PropTypes.bool,
        containsRepeat: PropTypes.bool,
    }).isRequired,
    theme: PropTypes.string,
};

PasswordRules.defaultProps = {
    theme: '',
};

export default injectIntl(PasswordRules);
