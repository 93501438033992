import * as type from 'state-management/constants/tools';

const initialState = {
    isLoading: false,
};

function toolsReducer(state = initialState, action = {}) {
    switch (action.type) {
    case type.GET_TOOLS_DATA:
        return {
            ...state,
            isLoading: true,
        };
    case type.GET_TOOLS_DATA_SUCCESS:
        return {
            ...state,
            tools: action.data,
            isLoading: false,
        };
    case type.GET_TOOLS_DATA_FAIL:
        return {
            ...state,
            isLoading: false,
        };
    default:
        return state;
    }
}

export default toolsReducer;
