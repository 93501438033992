import { css } from 'styled-components';
import { FROM_DESKTOP } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS } from 'utils/variables';

export const topicsContainerStyles = css`
    font-size: 13px;
    line-height: 1.65;
    margin: 16px 0;
    padding: 8px 16px 0;

    @media (min-width: 480px) {
        padding: 8px 13% 0;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 14px;
        padding: 32px 0 0;
    }
`;

export const topicsTitleStyles = css`
    border-bottom: ${COLORS.LIGHT_GRAY3} solid 1px;
    color: ${COLORS.MEDIUM_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 10px;
    margin-top: 0;
    padding-bottom: 7px;

    @media screen and ${FROM_DESKTOP} {
        font-size: 16px;
    }
`;

export const topicsStyles = css`
    font-size: 13px;
`;
