import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'containers/App/constants';
import {
    activeClassName,
    navLinkStyles,
    navListStyles,
    navStyles,
} from './styles';
import { SourceModal } from '../NewsSource/SourceModal';
import { connect } from "react-redux";
import storage from 'utils/store';
import { SourceConfirmationModal } from 'components/atomics/molecules/NewsSourceConformation/SourceConfirmationModal';

export const StyledNav = styled.nav`
    ${navStyles}
`;

export const StyledNavList = styled.ul`
    ${navListStyles};
`;

/**
 * Determin if a page should be considered child to news
 * @param {*} match 
 * @param {*} location 
 */
const isActive = (match, location) => {
    const path = window.location.pathname;

    if (
        path.startsWith(ROUTES.MARKET_BRIEFING_ARCHIVE)
        || path.startsWith(ROUTES.TRENDING_TOPICS_PAGE)
        || path.startsWith(ROUTES.NEWS)
        || path.startsWith('/marketBriefing') //can't use route with patch params involved
        || path.startsWith(ROUTES.ALL_TOPICS_PAGE)
        || path.startsWith('/source') //can't use route with patch params involved
        || path.startsWith('/topic') //can't use route with patch params involved
    ) {
        return true;
    }

    return false;
};

export const StyledNavLink = styled(NavLink).attrs({
    activeClassName,
})`
    ${navLinkStyles}
`;

class PrimaryNavigation extends React.Component {
    /**
     * Default constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            sourceModal: false,
            sourceConfirmationModal: false,
            currentPath: null,
        }
    }

    /**
     * Run modal logic.
     */
    componentDidMount() {
        this.checkConfirmationModal();
    }
    componentDidUpdate() {
        this.checkConfirmationModal(true);
    }
    /**
     * Watch props for path changes and session timeout values.
     * @param {*} nextProps
     */
    componentWillReceiveProps(nextProps) {
        if (nextProps.computedMatch && nextProps.computedMatch.url !== this.state.currentPath) {
            clearTimeout(this.state.sessionTimeoutHandler);

            this.setState({
                currentPath: nextProps.computedMatch ? nextProps.computedMatch.url : null,
            });

            this.checkConfirmationModal();
        }
    }

    inValidSourceLength() {
        const sourceLength = this.props.selectedSources.length;
        return (sourceLength !== undefined && sourceLength < 3);
    }

    /**
     * Check if we need to show confirm source save modal.
     */
    checkConfirmationModal(editMode = false) {
        if (storage.get('onSaveSources')) {
            this.setState({ sourceConfirmationModal: true });
        }
        if (editMode && storage.get('profileEditClicked') != null && storage.get('profileEditClicked') == true) {
            if (window.location.href.indexOf("profile/preferences") > -1 && this.inValidSourceLength()) {
                this.setState({ sourceModal: true });
            }
            storage.set('profileEditClicked', false);
        }
        storage.set('onSaveSources', false);
    }

    /**
    * Stuff to do on modal close.
    */
    closeSourceModal = () => {
        this.setState({ sourceModal: false });
    }

    /**
     * Close the source confirmaion modal.
     */
    closeSourceConfirmationModal = () => {
        this.setState({ sourceConfirmationModal: false });
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const {
            id,
            routes,
            ariaLabel,
        } = this.props;

        return (
            <>
                <SourceModal isOpen={this.state.sourceModal} closeSourceModal={this.closeSourceModal} />
                <SourceConfirmationModal isOpen={this.state.sourceConfirmationModal} closeSourceConfirmationModal={this.closeSourceConfirmationModal} />
                <StyledNav
                    aria-label={ariaLabel}
                    id={id}
                >
                    <StyledNavList
                        id={`${id}-list`}
                    >
                        {
                            routes.map((item) => {
                                const { appearance, title, ...other } = item;

                                return (
                                    <li key={item.id}>
                                        <StyledNavLink
                                            isActive={other.to === ROUTES.NEWS ? isActive : null}
                                            {...other}
                                            variant={{ appearance }}
                                            onClick={(e) => {
                                                if (item.id === "news" && this.inValidSourceLength()) {
                                                    e.preventDefault();
                                                    this.setState({ sourceModal: true });
                                                }
                                            }}
                                        >
                                            {title}
                                        </StyledNavLink>
                                    </li>
                                );
                            })
                        }
                    </StyledNavList>
                </StyledNav>
            </>
        );
    }
}

PrimaryNavigation.propTypes = {
    ariaLabel: PropTypes.string.isRequired,
    id: PropTypes.string,
    selectedSources: PropTypes.arrayOf(PropTypes.number),
    routes: PropTypes.arrayOf(PropTypes.shape({
        appearance: PropTypes.oneOf([
            'blue',
            'cyan',
            'navy-blue',
            'sky-blue',
        ]).isRequired,
        id: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]).isRequired,
        title: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.string,
        ]).isRequired,
        to: PropTypes.string.isRequired,
    })),
};

PrimaryNavigation.defaultProps = {
    id: 'primary-nav',
    routes: [],
    selectedSources: [],
};


const mapStateToProps = (state) => ({
    urlHistoryList: state.UrlHistoryReducer.history,
    selectedSources: state.basicProfile.sourceIds,
});

const mapDispatchToProps = dispatch => ({
    removeItemFromHistory: () => dispatch(removeItemFromHistory()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryNavigation);
