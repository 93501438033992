export const GET_ALLTOPICS_SUCCESS = 'allTopics/GET_TOPICS_SUCCESS';
export const GET_ALLTOPICS_FAIL = 'allTopics/GET_TOPICS_FAIL';
export const GET_ALLTOPICS = 'allTopics/GET_TOPICS';

export const GET_TOP_ALLTOPICS_SUCCESS = 'allTopics/GET_TOP_TOPICS_SUCCESS';
export const GET_TOP_ALLTOPICS_FAIL = 'allTopics/GET_TOP_TOPICS_FAIL';
export const GET_TOP_ALLTOPICS = 'allTopics/GET_TOP_TOPICS';

export const GET_TRENDING_ALLTOPICS_SUCCESS = 'allTopics/GET_TRENDING_TOPICS_SUCCESS';
export const GET_TRENDING_ALLTOPICS_FAIL = 'allTopics/GET_TRENDING_TOPICS_FAIL';
export const GET_TRENDING_ALLTOPICS = 'allTopics/GET_TRENDING_TOPICS';
export const TOGGLE_SAVE_TRENDING_ALLTOPICS = 'allTopics/TOGGLE_SAVE';
export const TOGGLE_SAVE_TRENDING_TOPIC = 'allTopics/TOGGLE_SAVE_TRENDING_TOPIC';

export const SEEMORE_TRENDING = 'allTopics/SEEMORE_TRENDING';
export const SEEMORE_TRENDING_FAIL = 'allTopics/SEEMORE_TRENDING_FAIL';
export const SEEMORE_TRENDING_SUCCESS = 'allTopics/SEEMORE_TRENDING_SUCCESS';

export const ALLTOPICS_URL = '/advisor-service/topics';
export const SUBSCRIBED_ALLTOPICS_URL = '/advisor-service/advisors/me';
export const TOP_ALLTOPICS_URL = omitIds => `/personalization-service/topics/favorites?excluded=${omitIds}`;
export const TRENDING_ALLTOPICS_URL = '/content-service/news/trending-topics-articles';
export const TRENDING_ALLTOPICS_URL_AUM = aum => `/api/content-service/news/trending-topics-articles/by-bob?id=${aum}`;
export const TRENDING_ALLTOPICS_URL_EXPERIENCE = experience => `/api/content-service/news/trending-topics-articles/by-experience?id=${experience}`;
export const TRENDING_ALLTOPICS_URL_SERVICE = service => `/api/content-service/news/trending-topics-articles/by-offering?id=${service}`;
export const SEEMORE_TRENDING_URL = '/api/content-service/news/see-more/trending_topics';

export const TRENDING_ALLTOPICS_URL_WM = wmAUMID => `/api/content-service/news/trending-topics-articles/by-wmAUM?id=${wmAUMID}`;
export const TRENDING_ALLTOPICS_URL_RETIREMENT = rpAUMID => `/api/content-service/news/trending-topics-articles/by-rpAUM?id=${rpAUMID}`;
