import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Heading, SanitizedHTML } from 'components';
import {
    styledSlideStyles, styledSlideImageStyles, styledSlideHeadingStyles, styledSlideContentStyles,
} from 'containers/Guide/ImageMetaphor/styles';
import CoverImage from 'components/CoverImage/CoverImage';

const StyledSlide = styled.article`
    ${styledSlideStyles}
`;


const StyledSlideImage = styled.figure`
    ${styledSlideImageStyles}
`;

const StyledSlideHeading = styled(Heading)`
    ${styledSlideHeadingStyles}
`;

const StyledSlideContent = styled(SanitizedHTML)`
    ${styledSlideContentStyles}
`;

const Slide = ({ id, title }) => (
    areAttributesValid(title) && (
        <StyledSlide key={id} id={`slide-${id}`}>
            {title.image && (
                <StyledSlideImage>
                    <CoverImage src={`/api${title.image}`} />
                </StyledSlideImage>
            )}
            <StyledSlideHeading level={2}>{title.title}</StyledSlideHeading>
            <StyledSlideContent
                htmlContent={title.description}
                tag="div"
            />
        </StyledSlide>
    ));

const areAttributesValid = ({ title, description }) => title && description;

Slide.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.shape({
        image: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
    }).isRequired,
};

export default Slide;