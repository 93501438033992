import { css } from 'styled-components';
import { FROM_TABLET } from 'utils/mediaQueries';
import {
    FONT_AVENIR_NEXT,
    COLORS,
    ANIMATIONS,
} from 'utils/variables';

export const containerStyles = css`
    margin-bottom: 30px;
    margin-top: 30px;
`;

export const headerStyles = css`
    border-bottom: ${COLORS.LIGHT_GRAY3} solid 2px;
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 1.6px;
    line-height: 1;
    margin: 0 0 20px;
    padding: 2px 0 10px;
    text-transform: uppercase;
    word-wrap: break-word;
`;

export const citationStyles = css`
    color: ${COLORS.MEDIUM_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 10px;
    letter-spacing: -0.2px;
    line-height: 1.25;
    word-wrap: break-word;

    ol,
    ul {
        margin: 20px 0 30px;
        padding: 0 0 0 14px;
    }

    li {
        margin: 10px 0;
        padding-left: 10px;
    }

    p {
        margin: 1.6em 0;
    }

    sup {
        font-size: 12px;
    }

    @media screen and ${FROM_TABLET} {
        font-size: 12px;
    }

    a {
        color: ${COLORS.SAPPHIRE};
        transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;

        &:hover {
            color: ${COLORS.DARK_SAPPHIRE};
        }
    }
`;
