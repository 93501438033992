import React from 'react';
import MarketBriefing from 'containers/MarketBriefing/componentContainers/MarketBriefing';
import PropTypes from 'prop-types';
import {
    SITE_SECTION_DJBRIEFING,
    PAGE_NAME_DJBRIEFING,
} from 'utils/analyticsConstants';
import scrollHelper from "../../../utils/scrollToTop";


/**
 * Page container for displaying a single full market briefing.
 */
export class MarketBriefingPage extends React.Component {
    /**
     * Scroll to top.
     */
    componentDidMount() {
        scrollHelper.scrollToTop();
    }

    /**
     * Render this and underlying components.
     */
    render() {
        return (
            <React.Fragment>
                <MarketBriefing id={this.props.match.params.id} />
            </React.Fragment>
        );
    }
}

MarketBriefingPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }).isRequired,
};

export default MarketBriefingPage;
