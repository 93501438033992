import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { camelCase } from 'lodash';
import moment from 'moment';

import { dowJonesCopyrightStyles } from 'containers/Article/ArticleFooter/styles';

const StyledDowJonesCopyright = styled.p`
    ${dowJonesCopyrightStyles}
`;

const year = moment().year();

/**
 * Article footer for dow jones articles.
 * @param {*} param0 
 */
const DowJonesArticleFooter = ({ provider }) => (
    <StyledDowJonesCopyright>
        {provider && (
            <FormattedMessage
                id={`articles.footer.${camelCase(provider)}.copyright`}
                values={{ year }}
            />
        )}
    </StyledDowJonesCopyright>
);

DowJonesArticleFooter.propTypes = {
    provider: PropTypes.string.isRequired,
};

export default DowJonesArticleFooter;
