import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Button } from 'components';
import { IconArrowDown } from 'components/atomics/atoms/Icons/Icons';
import DesktopSearchFilters from 'containers/Search/SearchFilters/DesktopSearchFilters';
import MobileSearchFilters from 'containers/Search/SearchFilters/MobileSearchFilters';
import { MOBILE } from 'components/Breakpoints/Breakpoints';
import {
    isMobileOnly,
    isIOS,
} from 'react-device-detect';
import {
    ANY_TIME,
    DATE_SECTION,
    SOURCES_SECTION,
    TOPICS_SECTION,
    TYPE_SECTION,
} from 'state-management/constants/searchFilter';
import {
    clearAll as clearAllAction,
    clearSection as clearSectionAction,
    resetSection as resetSectionAction,
    toggleDateItem as toggleDateItemAction,
    toggleItem as toggleItemAction,
    toggleOpen as toggleOpenAction,
} from 'state-management/actions/searchFilter';
import {
    searchToolBarFilterActionStyles,
    searchToolBarFilterActionLabelStyles,
    searchToolBarFilterActionLabelInnerStyles,
} from 'components/SearchToolbar/styles';
import { selectionCountStyles } from 'containers/Search/SearchFilters/styles';

const StyledSearchToolBarFilterAction = styled(Button)`
    ${searchToolBarFilterActionStyles};
`;

const StyledSearchToolBarFilterActionLabel = styled.div`
    ${searchToolBarFilterActionLabelStyles};
`;

const StyledSearchToolBarFilterActionLabelInner = styled.div`
    ${searchToolBarFilterActionLabelInnerStyles};
`;

const StyledSelectionCount = styled.strong`
    ${selectionCountStyles}
`;

/**
 * Render the search results filter menu.
 */
class SearchFilters extends React.Component {
    /**
     * Default constructor.
     */
    constructor(props) {
        super(props);

        this.buttonRef = React.createRef();
    }

    /**
     * Get numeric count of selected filters.
     * @param {*} sections 
     * @param {*} sectionName 
     */
    static getSectionSelectedFiltersCount(sections, sectionName) {
        return sections[sectionName]
            .items
            .filter(item => item.checked)
            .length;
    }

    /**
     * Determine if there was date range filter changes.
     * @param {*} dateSection 
     */
    static isDateSectionStateInitial(dateSection) {
        return !!dateSection
            .items
            .find(item => item.checked && item.id === ANY_TIME);
    }

    /**
     * Get all sections selected as objects under search filter menu.
     * @param {*} sections 
     */
    static getAllSectionsSelectedFiltersCount(sections) {
        return Object
            .keys(sections)
            .filter(sectionName => sectionName !== DATE_SECTION
                || !SearchFilters.isDateSectionStateInitial(sections[DATE_SECTION]))
            .map(sectionName => SearchFilters.getSectionSelectedFiltersCount(sections, sectionName))
            .reduce((total, item) => total + item, 0);
    }

    /**
     * Handle done/apply click from filter menu.
     */
    handleDone = () => {
        const {
            isOpen, onDoneClick, sections, toggleOpen, breakpoint,
        } = this.props;

        onDoneClick(sections);
        toggleOpen(!isOpen);

        if (breakpoint === MOBILE) {
            document.body.style.overflow = isOpen ? null : 'hidden';
            document.body.style.position = isOpen ? null : 'fixed';
        }
    };

    /**
     * Remove the body scroll limit while menu is open.
     */
    clearBodyScrollLimitation = () => {
        document.body.style.overflow = null;
        document.body.style.position = null;
    };

    /**
     * Render all search filters accounting for display breakpoint.
     */
    renderSearchFilters = () => {
        const {
            clearAll,
            clearSection,
            resetSection,
            sections,
            toggleDateItem,
            toggleItem,
            isOpen,
        } = this.props;

        switch (this.props.breakpoint) {
            case MOBILE:
                return (
                    <MobileSearchFilters
                        clearAll={clearAll}
                        clearSection={clearSection}
                        handleDone={this.handleDone}
                        isDateSectionStateInitial={
                            SearchFilters.isDateSectionStateInitial(sections[DATE_SECTION])
                        }
                        resetSection={resetSection}
                        sections={sections}
                        selectedFiltersCount={
                            SearchFilters.getAllSectionsSelectedFiltersCount(sections)
                        }
                        toggleDateItem={toggleDateItem}
                        toggleItem={toggleItem}
                        isOpen={isOpen}
                    />
                );
            default: {
                this.clearBodyScrollLimitation();
                
                return (
                    <DesktopSearchFilters
                        buttonRef={this.buttonRef}
                        clearAll={clearAll}
                        clearSection={clearSection}
                        handleDone={this.handleDone}
                        isDateSectionStateInitial={
                            SearchFilters.isDateSectionStateInitial(sections[DATE_SECTION])
                        }
                        resetSection={resetSection}
                        sections={sections}
                        selectedFiltersCount={
                            SearchFilters.getAllSectionsSelectedFiltersCount(sections)
                        }
                        toggleDateItem={toggleDateItem}
                        toggleItem={toggleItem}
                    />
                );
            }
        }
    };

    /**
     * Handle changes to drop-down selections.
     */
    handleDropdownToggle = () => {
        const { isOpen, toggleOpen, breakpoint } = this.props;

        toggleOpen(!isOpen, { shouldClearUnappliedFilters: true });

        if (breakpoint === MOBILE) {
            document.body.style.overflow = !isOpen ? 'hidden' : null;
            document.body.style.position = !isOpen ? 'fixed' : null;
        }
    };

    /**
     * Render this and underlying components.
     */
    render() {
        const { filtersApplied, isOpen } = this.props;
        const appliedFiltersCount = filtersApplied
            ? SearchFilters.getAllSectionsSelectedFiltersCount(filtersApplied)
            : 0;

        return (
            <React.Fragment>
                <StyledSearchToolBarFilterAction
                    id="filter"
                    onClick={this.handleDropdownToggle}
                    isOpen={isOpen}
                >
                    <StyledSearchToolBarFilterActionLabel
                        ref={this.buttonRef}
                        data-rel="search-action-label"
                        isMobile={isIOS && isMobileOnly}
                    >
                        <StyledSearchToolBarFilterActionLabelInner isOpen={isOpen}>
                            <FormattedMessage id="search.filters.filter" />
                            {!isOpen && !!appliedFiltersCount && (
                                <StyledSelectionCount>
                                    (
                                    {appliedFiltersCount}
                                    )
                                </StyledSelectionCount>
                            )}
                            <IconArrowDown />
                        </StyledSearchToolBarFilterActionLabelInner>
                    </StyledSearchToolBarFilterActionLabel>
                </StyledSearchToolBarFilterAction>
                {isOpen && this.renderSearchFilters()}
            </React.Fragment>
        );
    }
}

SearchFilters.defaultProps = {
    filtersApplied: null,
};

SearchFilters.propTypes = {
    breakpoint: PropTypes.string.isRequired,
    clearAll: PropTypes.func.isRequired,
    clearSection: PropTypes.func.isRequired,
    filtersApplied: PropTypes.shape({}),
    isOpen: PropTypes.bool.isRequired,
    onDoneClick: PropTypes.func.isRequired,
    resetSection: PropTypes.func.isRequired,
    sections: PropTypes.shape({
        [DATE_SECTION]: PropTypes.shape({}),
        [SOURCES_SECTION]: PropTypes.shape({}),
        [TOPICS_SECTION]: PropTypes.shape({}),
        [TYPE_SECTION]: PropTypes.shape({}),
    }).isRequired,
    toggleDateItem: PropTypes.func.isRequired,
    toggleItem: PropTypes.func.isRequired,
    toggleOpen: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    filtersApplied: state.searchFilter.filtersApplied,
    isOpen: state.searchFilter.isOpen,
    sections: state.searchFilter.sections,
});

const mapDispatchToProps = dispatch => ({
    clearAll: () => dispatch(clearAllAction()),
    clearSection: sectionName => dispatch(clearSectionAction(sectionName)),
    resetSection: sectionName => dispatch(resetSectionAction(sectionName)),
    toggleDateItem: idx => dispatch(toggleDateItemAction(idx)),
    toggleItem: (sectionName, idx) => dispatch(toggleItemAction(sectionName, idx)),
    toggleOpen: (open, params) => dispatch(toggleOpenAction(open, params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchFilters);
