import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'formik';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import styled from 'styled-components';
import {
    CONTACT_METHOD_EMAIL,
    CONTACT_METHOD_PHONE,
} from 'state-management/constants/contactUs';
import {
    Button,
    FieldDropdown,
    FieldRadio,
    FieldText,
    FieldTextArea,
} from './../../components';
import FormErrors from 'containers/ContactUs/FormErrors';
import {
    formStyles,
    paragraphStyles,
    infoParagraphStyles,
    topicWrapperStyles,
    topicDropdownStyles,
    nameWrapperStyles,
    nameInputStyles,
    contactInfoWrapperStyles,
    contactOptionsWrapperStyles,
    radioInputStyles,
    submitButtonStyles,
    buttonContainerStyles,
} from 'containers/ContactUs/styles';
import { contextHubRecordEvent } from 'utils/contextHub';
import storage from 'utils/store';

const ButtonContainer = styled.div`
    ${buttonContainerStyles}
`;

const StyledForm = styled(Form)`
    ${formStyles}
`;

const StyledParagraph = styled.p`
    ${paragraphStyles}
`;

const StyledInfoParagraph = styled(StyledParagraph)`
    ${infoParagraphStyles}
`;

const StyledTopicWrapper = styled.div`
    ${topicWrapperStyles}
`;

const StyledTopicsDropdown = styled(FieldDropdown)`
    ${topicDropdownStyles}
`;

const StyledNameWrapper = styled.div`
    ${nameWrapperStyles}
`;

const StyledNameInput = styled(FieldText)`
    ${nameInputStyles}
`;

const StyledContactInfoWrapper = styled.div`
    ${contactInfoWrapperStyles}
`;

const StyledContactOptionsWrapper = styled.div`
    ${contactOptionsWrapperStyles}
`;

const StyledRadioInput = styled(FieldRadio)`
    ${radioInputStyles}
`;

export const StyledSubmitButton = styled(Button)`
    ${submitButtonStyles}
`;

/**
 * Contact us form component.
 */
class ContactUsForm extends React.Component {
    /**
     * Default constructor.
     */
    constructor() {
        super();

        this.state = {
            isEdited: false,
        };
    }

    /**
     * Handle field value changes.
     */
    handleFieldChange = (e) => {
        this.props.formik.setFieldError(e.target.name, undefined);

        this.setState({ isEdited: true });
    };

    /**
     * Determine if form has been completed by field entries.
     */
    isFormFilled = () => {
        const { isAuthenticated } = this.props;
        const {
            details,
            firstName,
            lastName,
            contactMethod,
            email,
            phone,
        } = this.props.formik.values;

        return details
            && (isAuthenticated || (firstName && lastName))
            && (contactMethod === CONTACT_METHOD_EMAIL ? email : phone);
    };

    handleClick = () => {
        if (this.props.isSubmitting) {
            this.CH_handleComponentEventList();
        }
    };

    //analytics 
    CH_handleComponentEventList = () => {
        const value = storage.get('dropdownValue')
        const analyticsLabel = this.props.formik.values
        const tracking =
        {
            "type": "contactUs",
            "action": value,
            "value": "contact us:button",
        };

        contextHubRecordEvent(tracking);

        storage.set('dropdownValue', null);
    };

    /**
     * Render this and underlying components.
     */
    render() {
        const {
            isAuthenticated,
            formik,
            intl,
            topics,
            isSubmitting,
            isSubmitted,
            setFieldRef,
            handleDetailsBlur,
            handleEmailBlur,
            globalErrors,
            handlePhoneBlur,
            handleTopicBlur,
            handleNameBlur,
        } = this.props;
        const { values } = formik;
        const { formatMessage } = intl;
        const isFieldValid = !isSubmitting && isSubmitted && !this.state.isEdited;
        const errorKeyDefault = [{ errorMessage: "contactUs.errors.server.submit" }];
        const { contactMethod } = values;

        const validateByKey = (key) => values[key] != null && values[key] !== '';
        const isValidForm = Object.keys(values).reduce((accum, key) => {
            if (accum) {
                return key === 'email' || key === 'phone'
                    ? key === contactMethod ? validateByKey(key) : true
                    : isAuthenticated && (key === 'firstName' || key === 'lastName')
                        ? true
                        : validateByKey(key);
            } else {
                return false;
            }
        }, true);

        const contactInfoField = values.contactMethod === CONTACT_METHOD_EMAIL
            ? (
                <Field
                    component={FieldText}
                    id="email"
                    name="email"
                    maxLength={255}
                    onChange={this.handleFieldChange}
                    ref={setFieldRef('email')}
                    isSubmitted={(!isSubmitting && isSubmitted)}
                    isValidated={isFieldValid && values.contactMethod === CONTACT_METHOD_EMAIL}
                    aria-label={formatMessage({ id: 'contactUs.email.label' })}
                    disabled={isAuthenticated}
                    onBlur={(e) => {
                        handleEmailBlur(e, this.props.formik);
                    }}
                />
            )
            : (
                <Field
                    component={FieldText}
                    id="phone"
                    name="phone"
                    maxLength={20}
                    onChange={this.handleFieldChange}
                    ref={setFieldRef('phone')}
                    isSubmitted={(!isSubmitting && isSubmitted)}
                    isValidated={isFieldValid && values.contactMethod !== CONTACT_METHOD_EMAIL}
                    aria-label={formatMessage({ id: 'contactUs.phone.label' })}
                    onBlur={(e) => {
                        handlePhoneBlur(e, this.props.formik);
                    }}
                />
            );

        return (
            <StyledForm id="contact-us-form" noValidate>
                <StyledTopicWrapper>
                    <div>
                        <Field
                            ariaLabel={formatMessage({ id: 'contactUs.field.topic' })}
                            component={StyledTopicsDropdown}
                            id="topic"
                            ignoreErrors={true}
                            useLabel="contactUs.field.topic.emptyLabel"
                            name="topic"
                            options={topics}
                            onBlur={()=> { handleTopicBlur(this.props.formik) }}
                            size="medium"
                            isNone="no"
                            removeFieldNameAsEmptyOtion={true}
                            isEmptyOptionSelectable={false}
                        />
                        <FormErrors globalErrors={values.topic == null && isSubmitted
                            ? errorKeyDefault
                            : []} />
                    </div>
                </StyledTopicWrapper>
                <Field
                    component={FieldTextArea}
                    id="details"
                    name="details"
                    placeholder={formatMessage({ id: 'contactUs.field.details' })}
                    maxLength={2000}
                    onBlur={(e)=>{
                        handleDetailsBlur(e, this.props.formik);
                    }}
                    onChange={this.handleFieldChange}
                    ref={setFieldRef('details')}
                />
                <StyledInfoParagraph>
                    <FormattedMessage
                        id="contactUs.contactMethod.heading"
                        values={{ break: <br /> }}
                    />
                </StyledInfoParagraph>
                {
                    !isAuthenticated && (
                        <StyledNameWrapper>
                            <Field
                                component={StyledNameInput}
                                id="firstName"
                                name="firstName"
                                label={formatMessage({ id: 'contactUs.field.firstName' })}
                                maxLength={50}
                                onChange={this.handleFieldChange}
                                fieldRef={setFieldRef('firstName')}
                                isSubmitted={(!isSubmitting && isSubmitted)}
                                isValidated={isFieldValid}
                                onBlur={(e) => {
                                    handleNameBlur(e, this.props.formik);
                                }}
                            />
                            <Field
                                component={StyledNameInput}
                                id="lastName"
                                name="lastName"
                                label={formatMessage({ id: 'contactUs.field.lastName' })}
                                maxLength={50}
                                onChange={this.handleFieldChange}
                                fieldRef={setFieldRef('lastName')}
                                isSubmitted={(!isSubmitting && isSubmitted)}
                                isValidated={isFieldValid}
                                onBlur={(e) => {
                                    handleNameBlur(e, this.props.formik);
                                }}
                            />
                        </StyledNameWrapper>
                    )
                }
                <StyledContactInfoWrapper>
                    <StyledContactOptionsWrapper>
                        <Field
                            component={StyledRadioInput}
                            id="radio-email"
                            name="contactMethod"
                            value={CONTACT_METHOD_EMAIL}
                            label={formatMessage({ id: 'contactUs.contactMethod.email' })}
                            isSubmitted={(!isSubmitting && isSubmitted)}
                            isValidated={isFieldValid}
                        />
                        <Field
                            component={StyledRadioInput}
                            id="radio-phone"
                            name="contactMethod"
                            value={CONTACT_METHOD_PHONE}
                            label={formatMessage({ id: 'contactUs.contactMethod.phone' })}
                            isSubmitted={(!isSubmitting && isSubmitted)}
                            isValidated={isFieldValid}
                        />
                    </StyledContactOptionsWrapper>
                    {contactInfoField}
                </StyledContactInfoWrapper>
                <ButtonContainer>
                    <StyledSubmitButton
                        onClick={this.handleClick()}
                        appearance={Button.APPEARANCE.PRIMARY}
                        size={Button.SIZES.MEDIUM}
                        id="contact-us-form-submit"
                        type={Button.TYPES.SUBMIT}
                        disabled={!isValidForm}
                        isActive={isValidForm}
                    >
                        <FormattedMessage id="contactUs.submit" />
                    </StyledSubmitButton>
                </ButtonContainer>
            </StyledForm>
        );
    }
}

ContactUsForm.propTypes = {
    formik: PropTypes.shape({
        values: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            topic: PropTypes.number,
            details: PropTypes.string,
            contactMethod: PropTypes.oneOf([CONTACT_METHOD_EMAIL, CONTACT_METHOD_PHONE]),
            email: PropTypes.string,
            phone: PropTypes.string,
        }),
        setFieldError: PropTypes.func,
    }).isRequired,
    setFieldRef: PropTypes.func.isRequired,
    topics: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.number,
        value: PropTypes.string,
    })).isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isSubmitted: PropTypes.bool,
    globalErrors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    intl: intlShape.isRequired,
    handleDetailsBlur: PropTypes.func,
    handleEmailBlur: PropTypes.func,
    handlePhoneBlur: PropTypes.func,
    handleNameBlur: PropTypes.func,
    handleTopicBlur: PropTypes.func,
};

ContactUsForm.defaultProps = {
    isSubmitted: false,
};

export default injectIntl(ContactUsForm);