import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ROUTES } from 'containers/App/constants';
import { Container, Heading } from 'components';
import Link from 'components/Link/Link';
import { IconLogoMark2 } from 'components/atomics/atoms/Icons/Icons';
import {
    contentContainerStyles,
    contentStyles,
    headingStyles,
    linkStyles,
    iconStyles,
} from 'containers/Home/GotSuggestions/styles';

const StyledContentContainer = styled.div`
    ${contentContainerStyles}
`;

const StyledContent = styled.div`
    ${contentStyles}
`;

const StyledHeading = styled(Heading)`
    ${headingStyles}
`;

const StyledLink = styled(Link)`
    ${linkStyles}
`;

const StyledIcon = styled(IconLogoMark2)`
    ${iconStyles}
`;

const renderLink = () => (
    <StyledLink to={ROUTES.CONTACT_US}>
        <FormattedMessage id="homepage.gotSuggestions.quick.email" />
    </StyledLink>
);

/**
 * Render the 'got suggestions?' area for home page.
 */
const GotSuggestions = () => (
    <section>
        <Container>
            <StyledContentContainer>
                <StyledContent>
                    <StyledHeading level={2}>
                        <FormattedMessage
                            id="homepage.gotSuggestions"
                            values={{ link: renderLink() }}
                        />
                    </StyledHeading>
                    <StyledIcon />
                </StyledContent>
            </StyledContentContainer>
        </Container>
    </section>
);

export default GotSuggestions;
