import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container, Heading } from 'components';
import SmallPost from 'components/Posts/SmallPost/SmallPost';
import { createIdsForObjectsByField } from 'utils/idCreatorUtils';
import {
    containerStyles,
    headingContainerStyles,
    headingStyles,
    descriptionStyles,
} from 'containers/Home/Announcements/styles';

const StyledContainer = styled(Container)`
    ${containerStyles}
`;

const StyledHeadingContainer = styled.div`
    ${headingContainerStyles}
`;

const StyledHeading = styled(Heading)`
    ${headingStyles}
`;

const StyledDescription = styled.p`
    ${descriptionStyles}
`;

/**
 * Render the anouncement area for home page.
 * @param {*} props 
 */
const Announcements = (props) => {
    const { title, description, posts } = props;

    return (
        Announcements.areAttributesValid(props) && (
            <section>
                <StyledContainer>
                    <StyledHeadingContainer>
                        <StyledHeading level={2}>
                            {title}
                        </StyledHeading>
                        <StyledDescription>
                            {description}
                        </StyledDescription>
                    </StyledHeadingContainer>
                    <React.Fragment>
                        {
                            createIdsForObjectsByField(posts, 'title')
                                .map(({ createdId, ...post }) => (
                                    <SmallPost
                                        key={createdId}
                                        {...post}
                                    />
                                ))
                        }
                    </React.Fragment>
                </StyledContainer>
            </section>
        )
    );
};

Announcements.areAttributesValid = ({ title }) => title;

Announcements.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    posts: PropTypes.arrayOf(PropTypes.shape({
        category: PropTypes.string.isRequired,
        publishTimestamp: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        link: PropTypes.string,
        linkText: PropTypes.string,
        imageAsset: PropTypes.string,
    })).isRequired,
};

export default Announcements;
