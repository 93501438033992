import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Link from 'components/Link/Link';

import { navigationStyles, linkStyles } from 'components/Navigation/styles';

const StyledNav = styled.nav`
    ${navigationStyles}
`;
const StyledLink = styled(Link)`
    ${linkStyles}
`;

const Navigation = (props) => {
    const { routes, title, ...other } = props;
    const navAttrs = {};

    if (title) {
        navAttrs['aria-label'] = title;
    }

    return (
        <StyledNav {...navAttrs} {...other}>
            {
                routes.map((route, idx) => {
                    const key = route.id || idx;

                    return (
                        <StyledLink
                            key={key}
                            to={route.to}
                        >
                            {route.title}
                        </StyledLink>
                    );
                })
            }
        </StyledNav>
    );
};

Navigation.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    routes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
    })).isRequired,
    title: PropTypes.string.isRequired,
};

Navigation.defaultProps = {
    className: '',
};

export default Navigation;
