import { css } from 'styled-components';
import { 
    FROM_DESKTOP,
    FROM_TABLET,
} from 'utils/mediaQueries';
import {
    COLORS,
    FONTS,
} from 'utils/variables';

export const container = css`
    background-color: ${COLORS.WHITE};
    width: calc(100% - 64px);
    padding: 52px 16px 32px 16px;
    margin: auto;
    text-align: center;

    @media screen and ${FROM_TABLET} {
        width: 50%;
        padding: 42px 66px 42px 66px;
        min-width: 558px;
        box-sizing: border-box;
    }
`;

export const title = css`
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    color: ${COLORS.SAPPHIRE};
    font-size: 26px;
    line-height: 30px;
    padding-bottom: 28px;
    font-weight: 400;
    white-space: normal;
    margin: auto;
    
    @media screen and ${FROM_TABLET} {
        line-height: 36px;
        padding-bottom: 22px;
    }
`;

export const subTitle = css`
    color: ${COLORS.BLACK};
    font-size: 15px;
    line-height: 20px;
    padding-bottom: 28px;
    font-weight: 600;
    margin: auto;
    max-width: 262px;

    @media screen and ${FROM_TABLET} {
        max-width: initial;
    }
`;

export const form = css`
    padding-bottom: 34px;

    @media screen and ${FROM_TABLET} {
        padding-bottom: 22px;
    }
`;

export const fieldRow = css`
    text-align: left;
    max-width: 360px;
    margin: auto;
    padding-bottom: 14px;
    height: 116px;
    overflow: hidden;

    div[data-rel="Input"] div:first-child {
        height: 14px;
    }
`;

export const mobileButtonRow = css`
    display: flex;
    flex-direction: column;

    div {
        width: 100%;
        text-align: center;
    }

    a:first-child {
        display: inline;
        display: inline-block;
        margin: auto;
        margin-bottom: 20px;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        a:first-child {
            max-width: 80px;
        }
    }
`;

export const buttonRow = css`
    margin: auto;

    a:first-child {
        margin-right: 16px;

        @media screen and ${FROM_TABLET} {
            margin-right: 19px;
        } 
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        a {
            max-width: 80px;
        }
    }
`;