import {
    all, put, takeLatest, select, call, takeEvery,
} from 'redux-saga/effects';
import { setError } from 'state-management/actions/errors';
import {
    GET_GUIDE_DATA,
    GUIDE_DATA_URL,
    GET_GUIDE_COLLECTIONS_DATA,
    GUIDE_COLLECTIONS_DATA_URL,
    GET_GUIDE_RECENT_DATA,
    GUIDE_RECENT_DATA_URL,
    GET_GUIDE_TOOLS_DATA,
    GUIDE_TOOLS_DATA_URL,
    SAVE_POLL_URL,
    SAVE_POLL_ANSWER,
    GET_TOOLS_URL,
    GET_TOOLS,
    GET_COLLECTION_DATA,
    COLLECTION_DATA_URL,
    CE_CREDITS_URL,
    GET_CE_CREDITS_LIST,
    GET_PRACTICE_LAB_URL,
    GET_PRACTICE_LAB,
    VISIT_CE_CREDIT_URL,
    VISIT_CE_CREDIT,
    // GET_VISIT_PRACTICE_LAB_URL
} from 'state-management/constants/guide';
import {
    getGuideFailure,
    getGuideSuccess,
    getGuideCollectionsFailure,
    getGuideCollectionsSuccess,
    getGuideRecentSuccess,
    getGuideRecentFailure,
    getGuideToolsSuccess,
    getGuideToolsFailure,
    savePollAnswerSuccess,
    getToolsSuccess,
    getToolsFailure,
    getCollectionSuccess,
    getCollectionFailure,
    getCeCreditsSuccess,
    getCeCreditsFail,
    getPracticeLabSuccess,
    getPracticeLabFailure,
} from 'state-management/actions/guide';
import { setScrollToTop } from 'state-management/actions/scroll';
import Api from 'utils/api';
import {
    PL_VISIT_ARTICLE,
    GET_VISIT_PRACTICE_LAB_URL,
} from 'state-management/constants/articles.common';
import { handlePLArticleVisit } from 'state-management/actions/articles.common';

/**
 * Get guide tools.
 */
function* getTools() {
    try {
        const toolsData = yield call(Api.get, GET_TOOLS_URL);
        yield put(getToolsSuccess(toolsData.data));
    } catch (e) {
        yield put(getToolsFailure(e));
        yield put(setError('guidePage.server.error', e));
    }
}

/**
 * Get guide practiceLab.
 */
function* getPracticeLab() {
    try {
        const practiceLabData = yield call(Api.get, GET_PRACTICE_LAB_URL);

        if (practiceLabData.data.content
            && practiceLabData.data.content.contentItems
            && practiceLabData.data.content.contentItems.length) {
            yield put(getPracticeLabSuccess(practiceLabData.data));
        } else {
            yield put(getPracticeLabFailure({}));
        }
    } catch (e) {
        yield put(getPracticeLabFailure(e));
        //yield put(setError('guidePage.server.error', e));
    }
}

function* getVisitPracticeLab(article) {
    const parcticeLabArticle = article.article;

    try {
        const dataObj = {
            data: {
                id: parcticeLabArticle.id,
                provider: parcticeLabArticle.provider,
                publishDate: parcticeLabArticle.publishDate ? parcticeLabArticle.publishDate : parcticeLabArticle.date,
                contentType: parcticeLabArticle.contentType ? parcticeLabArticle.contentType : null,
                interactionId: parcticeLabArticle.interactionId ? parcticeLabArticle.interactionId : null,
                interactionType: parcticeLabArticle.interactionType,

            }
        };
        yield call(Api.post, GET_VISIT_PRACTICE_LAB_URL, dataObj);
        yield put(handlePLArticleVisit(parcticeLabArticle.id));
    }
    catch (e) {
        yield put(getPracticeLabFailure(e));
        yield put(setError('guidePage.server.error', e));
    }
}

/**
 * Get general guide data.
 */
function* getGuideData({ id }) {
    try {
        const guideData = yield call(Api.get, GUIDE_DATA_URL, {
            params: {
                id,
                name: 'guide'
            },
        });
        yield put(getGuideSuccess(id, guideData.data));
        yield put(setScrollToTop());
    } catch (e) {
        yield put(getGuideFailure(e));
        yield put(setError('guidePage.server.error', e));
    }
}

/**
 * Get guide collection data.
 * @param {*} param0
 */
function* getGuideCollectionsData({ group }) {
    const query = (!group) ? '' : `?group=${group}`;

    try {
        const guideCollectionsData = yield call(Api.get, GUIDE_COLLECTIONS_DATA_URL + query);

        yield put(getGuideCollectionsSuccess(guideCollectionsData.data));
    } catch (e) {
        yield put(getGuideCollectionsFailure(e));
        //yield put(setError('guideCollectionsPage.server.error', e));
    }
}

/**
 * Get CE Credits for PM page.
 */
function* getCeCredits({ no }) {
    const query = (!no) ? '' : `?n=${no}`;

    try {
        const ceCreditsData = yield call(Api.get, CE_CREDITS_URL + query);

        yield put(getCeCreditsSuccess(ceCreditsData.data.ceCreditList));
    } catch (e) {
        yield put(getCeCreditsFail(e));
    }
}


function* visitCeCredit({ id }) {
    try {
        yield call(Api.get, VISIT_CE_CREDIT_URL(id));
    } catch {}
}

/**
 * Get recent guides.
 * @param {*} param0
 */
function* getGuideRecentData({ no }) {
    const query = (!no) ? '' : `?n=${no}`;

    try {
        const guideRecentData = yield call(Api.get, GUIDE_RECENT_DATA_URL + query);

        yield put(getGuideRecentSuccess(guideRecentData.data));
    } catch (e) {
        yield put(getGuideRecentFailure(e));
        //commented out to let PM page load even if this fails
        //yield put(setError('guideRecentPage.server.error', e));
    }
}

function* getGuideToolsData({ no }) {
    const query = (!no) ? '' : `?n=${no}`;

    try {
        const guideToolsData = yield call(Api.get, GUIDE_TOOLS_DATA_URL + query);
        yield put(getGuideToolsSuccess(guideToolsData.data));
    } catch (e) {
        yield put(getGuideToolsFailure(e));
        yield put(setError('guideToolsPage.server.error', e));
    }
}

/**
 * Save a guides poll result click.
 * @param {*} param0
 */
function* savePollAnswer({ pollId, optionId }) {
    try {
        let guideCode = yield select(state => state.guide.code);

        const response = yield call(Api.post, SAVE_POLL_URL, {
            data: {
                guideCode,
                pollId,
                optionId,
            },
        });

        if (response.data) {
            yield put(savePollAnswerSuccess(pollId, response.data));
        }
    } catch (e) {
        // do nothing
    }
}

/**
 * Get collection data by title.
 * @param {*} param0
 */
function* getCollectionData({ title }) {
    const query = `?code=${title}`;

    try {
        const collectionData = yield call(Api.get, COLLECTION_DATA_URL(title));

        yield put(getCollectionSuccess(collectionData.data));
    } catch (e) {
        yield put(getCollectionFailure(e));
        yield put(setError('global.errors.notFound', e));
    }
}

function* guideSaga() {
    yield all([
        takeLatest(GET_GUIDE_DATA, getGuideData),
        takeLatest(GET_GUIDE_COLLECTIONS_DATA, getGuideCollectionsData),
        takeLatest(GET_GUIDE_RECENT_DATA, getGuideRecentData),
        takeLatest(GET_GUIDE_TOOLS_DATA, getGuideToolsData),
        takeLatest(GET_CE_CREDITS_LIST, getCeCredits),
        takeLatest(SAVE_POLL_ANSWER, savePollAnswer),
        takeLatest(GET_TOOLS, getTools),
        takeLatest(GET_PRACTICE_LAB, getPracticeLab),
        takeLatest(GET_COLLECTION_DATA, getCollectionData),
        takeEvery(PL_VISIT_ARTICLE, getVisitPracticeLab),
        takeEvery(VISIT_CE_CREDIT, visitCeCredit),
    ]);
}

export default guideSaga;
