import React, { Component } from 'react';
import styled from 'styled-components';
import {
    touchStyles,
    triggerStyles,
} from './styles';

const StyledTriggerTouchArea = styled.a`
    ${touchStyles}
`;

const Wrapper = styled.div``;

const StyledTrigger = styled.div`
    ${triggerStyles}
`;

/**
 * Creates the kebab open/close trigger button.
 */
class KebabButton extends Component {
    /**
     * Default constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            focused: false,
        }

        this.handleKebabKeyPress = this.handleKebabKeyPress.bind(this);
        this.toggleKebabOpen = this.toggleKebabOpen.bind(this);

        this.contentRef = React.createRef();
    }

    /**
     * Handle kebab open/close from key press (enter).
     * @param {*} event 
     */
    handleKebabKeyPress(event) {
        if (event.keyCode === 13) {
            this.toggleKebabOpen();

            //focus first menu item.
            setTimeout(() => {
                this.contentRef.current.parentNode.parentNode
                    .querySelectorAll('[data-rel="kebabAnimation"]')[0]
                    .getElementsByTagName("button")[0].focus();
            }, 300);
        }
    }

    /**
     * Toggle the kebab open/closed state.
     */
    toggleKebabOpen() {
        this.setState({isOpen: !this.state.isOpen},
        () => this.props.onClick({status: this.state.isOpen}));
    }

    /**
     * Render the kebab open/close button.
     */
    render() {
        return (
            <Wrapper ref={this.contentRef}>
                <StyledTrigger
                    className="kebab__trigger"
                    variant={{ isOpen: this.state.isOpen }}
                >
                    <em data-state={this.state.focused.toString()} /><em data-state={this.state.focused.toString()} /><em data-state={this.state.focused.toString()} />
                    <section>
                        <StyledTriggerTouchArea
                            onFocus={()=>{this.setState({focused: true})}}
                            onBlur={()=>{this.setState({focused: false})}}
                            tabIndex={0}
                            onKeyUp={this.handleKebabKeyPress}
                            onClick={this.toggleKebabOpen}
                        />
                    </section>
                </StyledTrigger>
            </Wrapper>
        );
    }
}

KebabButton.propTypes = {};

export default KebabButton;
