import React from 'react';
import PropTypes from 'prop-types';
import { getStickyValue, isSupported } from 'utils/stickyUtils';
import { ScrollPositionContext } from 'components/ScrollPosition/ScrollPosition';
import { StickyContainerContext } from 'components/StickyContainer/StickyContainer';

export const START = 'start';
export const INSIDE = 'inside';
export const END = 'end';

const Sticky = props => (
    <ScrollPositionContext>
        {scrollProps => (
            <StickyContainerContext.Consumer>
                {containerProps => (
                    <StickyItem
                        {...props}
                        {...scrollProps}
                        {...containerProps}
                    />
                )}
            </StickyContainerContext.Consumer>
        )}
    </ScrollPositionContext>
);

class StickyItem extends React.PureComponent {
    static getDerivedStateFromProps({ bottomOffset, topOffset }) {
        return {
            bottomOffset,
            topOffset: (topOffset === null && bottomOffset === null ? 0 : topOffset),
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            bottomOffset: props.bottomOffset,
            heightAfter: 0,
            heightBefore: 0,
            isSticky: false,
            isFixed: false,
            nodePosition: {},
            style: {},
            windowHeight: window.innerHeight,
            topOffset: props.topOffset,
        };

        this.stickyRef = React.createRef();

        this.getStickyPosition = this.getStickyPosition.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleWindowResize = this.handleWindowResize.bind(this);

        window.addEventListener("resize", this.handleWindowResize);
    }

    componentDidMount() {
        const { id, subscribe } = this.props;

        subscribe({
            id,
            scrollHandler: this.handleScroll,
            stickyPositionGetter: this.getStickyPosition,
        });
    }

    componentWillUnmount() {
        const { id, unsubscribe } = this.props;

        unsubscribe(id);

        window.removeEventListener('resize', this.handleWindowResize);
    }

    /**
     * Ensure sticky aspects are recalculated on window size changes.
     */
    handleWindowResize() {
        const { minimumWindowHeightForSticky } = this.props;
    
        if (this.state.windowHeight !== window.innerHeight) {
            this.setState({
                windowHeight: window.innerHeight,
            }, () => {
                const style = JSON.parse(JSON.stringify(this.state.style));

                if (minimumWindowHeightForSticky !== null
                    && window.innerHeight >= minimumWindowHeightForSticky ) {
                    style.position = 'fixed';
                    
                } else {
                    style.position = '';
                }

                this.setState({
                    style,
                });
            });
        }
    }

    getNodeHeight() {
        const node = this.stickyRef.current;
        let height = 0;

        if (node) {
            const nodeHeight = node.clientHeight;

            if (nodeHeight) {
                height = nodeHeight;
            } else if (node.childNodes.length) {
                const children = [...node.childNodes];
                children.forEach((childNode) => {
                    height += childNode.clientHeight || 0;
                });
            }
        }

        return height;
    }

    getStickyPosition() {
        const {
            bottomOffset,
            heightAfter,
            nodePosition,
            topOffset,
            isFixed,
        } = this.state;

        return {
            bottom: nodePosition.bottomOffset,
            bottomOffset,
            height: heightAfter,
            top: nodePosition.topOffset,
            topOffset,
            isFixed,
        };
    }

    getStyles(isSticky, nodePosition, containerPosition) {
        const {
            enabled,
            leftOffset,
            rightOffset,
            minimumWindowHeightForSticky,
        } = this.props;
        const { bottomOffset, topOffset } = this.state;
        const node = this.stickyRef.current;
        const height = node ? node.offsetHeight : 0;
        const style = {};

        if (enabled) {
            if (isSupported) {
                style.position = getStickyValue();
                style.transform = 'translateZ(0)';

                if (bottomOffset !== null) {
                    style.bottom = nodePosition.bottomOffset;
                }

                if (topOffset !== null) {
                    style.top = nodePosition.topOffset;
                }
            } else if (isSticky) {
                if (leftOffset !== null) {
                    style.left = leftOffset;
                }

                if (bottomOffset !== null) {
                    const diff = containerPosition.bottom < height
                        ? height - containerPosition.top
                        : 0;
                    style.bottom = nodePosition.bottomOffset - diff;
                }

                if (rightOffset !== null) {
                    style.right = rightOffset;
                }

                if (topOffset !== null) {
                    const diff = (containerPosition.bottom - height) < height
                        ? 2 * height - containerPosition.bottom
                        : 0;
                    style.top = nodePosition.topOffset - diff;
                }

                if (minimumWindowHeightForSticky === null
                    || window.innerHeight >= minimumWindowHeightForSticky) {
                    style.position = 'fixed';
                }
            }
        }

        return style;
    }

    isSticky(nodePosition, containerPosition, scrollPosition, stickyPosition) {
        const { boundToContainer, enabled } = this.props;
        const { bottomOffset, topOffset } = this.state;
        const bottom = nodePosition.bottomOffset || 0;
        const top = nodePosition.topOffset || 0;
        const windowHeight = scrollPosition.height || window.innerHeight;
        const unStickPoint = isSupported ? containerPosition.top
            : containerPosition.bottom - (nodePosition.height + stickyPosition.before);

        switch (boundToContainer) {
        case START:
            return (
                enabled && (
                    (topOffset !== null && containerPosition.top < top)
                    || (
                        bottomOffset !== null
                        && (windowHeight - containerPosition.top) > (bottom + nodePosition.height)
                    )
                )
            );
        case INSIDE:
            if (bottomOffset !== null) {
                return (
                    enabled
                    && containerPosition.top < top
                    && (containerPosition.bottom - windowHeight) > bottom
                );
            }

            return enabled && containerPosition.top < top && unStickPoint > bottom;
        case END:
            return enabled && (
                (topOffset !== null && containerPosition.bottom < bottom)
                || (bottomOffset !== null && (containerPosition.bottom - windowHeight) > bottom)
            );
        default:
            return false;
        }
    }

    handleScroll(containerPosition, scrollPosition, stickyPosition) {
        const { isFixed } = this.props;
        const { bottomOffset, topOffset } = this.state;
        const height = this.getNodeHeight();
        const nodePosition = {
            bottomOffset: bottomOffset + stickyPosition.after,
            height,
            topOffset: topOffset + stickyPosition.before,
        };
        const isSticky = this
            .isSticky(nodePosition, containerPosition, scrollPosition, stickyPosition);

        this.setState(prevState => ({
            heightAfter: prevState.isSticky && isSticky ? height : 0,
            heightBefore: !prevState.isSticky && isSticky ? height : prevState.heightBefore,
            isSticky,
            isFixed,
            nodePosition,
            style: this.getStyles(isSticky, nodePosition, containerPosition),
        }));
    }

    renderPlaceholder() {
        const { hasPlaceholder, isFixed } = this.props;
        const { heightBefore, isSticky } = this.state;
        const height = isSticky ? heightBefore : 0;

        if (!hasPlaceholder || (isSupported && !isFixed)) {
            return null;
        }
        const style = { height };

        return (
            <div style={style} className="emptyDiv" />
        );
    }

    render() {
        const { className, children, render } = this.props;
        const { isSticky, style } = this.state;

        if (render && typeof render === 'function') {
            return (
                <React.Fragment>
                    <div
                        data-height={this.state.windowHeight}
                        className={className}
                        ref={this.stickyRef}
                    >
                        {render({
                            isSticky,
                            style,
                        })}
                    </div>
                    {this.renderPlaceholder()}
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <div
                    data-height={this.state.windowHeight}
                    className={className}
                    ref={this.stickyRef}
                    style={style}
                >
                    {children}
                </div>
                {this.renderPlaceholder()}
            </React.Fragment>
        );
    }
}

StickyItem.propTypes = {
    bottomOffset: PropTypes.number,
    boundToContainer: PropTypes.oneOf([
        START,
        INSIDE,
        END,
    ]).isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    className: PropTypes.string,
    enabled: PropTypes.bool.isRequired,
    hasPlaceholder: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    leftOffset: PropTypes.number,
    render: PropTypes.func,
    subscribe: PropTypes.func.isRequired,
    unsubscribe: PropTypes.func.isRequired,
    rightOffset: PropTypes.number,
    topOffset: PropTypes.number,
    isFixed: PropTypes.bool,
};

StickyItem.defaultProps = {
    bottomOffset: null,
    children: null,
    className: '',
    leftOffset: null,
    render: null,
    rightOffset: null,
    topOffset: null,
    isFixed: false,
};

Sticky.propTypes = {
    bottomOffset: PropTypes.number,
    boundToContainer: PropTypes.oneOf([
        START,
        INSIDE,
        END,
    ]),
    className: PropTypes.string,
    hasPlaceholder: PropTypes.bool,
    id: PropTypes.string.isRequired,
    enabled: PropTypes.bool,
    leftOffset: PropTypes.number,
    render: PropTypes.func,
    rightOffset: PropTypes.number,
    topOffset: PropTypes.number,
    minimumWindowHeightForSticky: PropTypes.number,
};

Sticky.defaultProps = {
    bottomOffset: null,
    boundToContainer: INSIDE,
    className: '',
    hasPlaceholder: false,
    enabled: true,
    leftOffset: null,
    render: null,
    rightOffset: null,
    topOffset: null,
    minimumWindowHeightForSticky: null,
};

Sticky.START = START;
Sticky.INSIDE = INSIDE;
Sticky.END = END;

export default Sticky;
