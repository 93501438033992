import {
    all, put, takeEvery, call,
} from 'redux-saga/effects';
import { zipObject } from 'lodash';
import { setContentFragments } from 'state-management/actions/commonContentFragments';
import { setError } from 'state-management/actions/errors';
import {
    GET_CONTENT_FRAGMENTS, CONTENT_COMMON_PUBLIC_URL,
} from 'state-management/constants/commonContentFragments';
import Api from 'utils/api';

/**
 * Get content fragments from AEM via BE.
 * @param {*} param0
 */
export function* fetchContentFragments({ fragments, showErrorPageOnFail }) {
    try {
        const effects = fragments.map(fragment => call(
            Api.get,
            CONTENT_COMMON_PUBLIC_URL, { params: { fragment }, isPublic: true },
        ));
        const responses = yield all(effects);
        const contentFragments = zipObject(fragments, responses.map(r => r.data));

        yield put(setContentFragments(contentFragments));
    } catch (e) {
        // It makes more sense to show 500 page even if server responds with 404
        // because the page itself was found, only its content failed to load
        const error = showErrorPageOnFail ? { ...e, status: 500 } : undefined;

        yield put(setError('errors.commonContent', showErrorPageOnFail && error));
    }
}

export default function* commonContentFragmentsSaga() {
    yield takeEvery(GET_CONTENT_FRAGMENTS, fetchContentFragments);
}
