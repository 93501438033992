import { css } from 'styled-components';

import { FROM_TABLET } from 'utils/mediaQueries';
import {  COLORS, } from 'utils/variables';

export const buttonStyles = css`
    line-height:15px;
    background-color: ${COLORS.SAPPHIRE};
    color: ${COLORS.WHITE};
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1.5px;
    padding: 13px 24px;
    text-transform: uppercase;

    &:hover, &:not(:disabled):hover{
        color: ${COLORS.WHITE};
        background: ${COLORS.DARK_SAPPHIRE};
    }
`;
