import * as type from 'state-management/constants/profileRefinementCard';

export const getProfileRefinementCard = () => ({
    type: type.GET_PROFILE_REFINEMENT_CARD,
});

export const getProfileRefinementCardSuccess = data => ({
    type: type.GET_PROFILE_REFINEMENT_CARD_SUCCESS,
    data,
});

export const notInterestedIn = data => ({
    type: type.NOT_INTERESTED_IN,
    data,
});

export const incrementProfileRefinementSessionCount = () => ({
    type: type.INCREMENET_SESSION_COUNT,
});
