import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setScrollToTop } from 'state-management/actions/scroll';
import SignInSection from 'components/SignInSection/SignInSection';
import MeetLuminSection from 'components/MeetLuminSection/MeetLuminSection';
import SignUpSection from 'components/SignUpSection/SignUpSection';
import SignUpSecondarySection from 'components/SignUpSecondarySection/SignUpSecondarySection';
import LegalFDIC from 'components/LegalFDIC/LegalFDIC';
import { USER_SHARE_TOKEN } from 'state-management/constants/basicProfile';
import store from 'utils/store';
import { IS_LOGGED_OUT } from 'utils/api';

const getUrlParameter = (name) => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');

    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);

    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

/**
 * The default landing (logged out) home page with splash content.
 */
export class Landing extends React.Component {
    /**
     * Stuff to do at mount, scroll up and look for a share token.
     */
    componentDidMount() {
        store.remove(IS_LOGGED_OUT);

        this.props.scrollToTop();

        const shareToken = getUrlParameter('token');

        if (shareToken) {
            store.set(USER_SHARE_TOKEN, shareToken);
        }
    }

    /**
     * Render this and underlying components.
     */
    render() {
        return (
            <Fragment>
                <SignInSection />
                <MeetLuminSection />
                <SignUpSection />
                <SignUpSecondarySection />
                {/* <JoinNowSection /> */}
                <LegalFDIC />
            </Fragment>
        );
    }
}

Landing.propTypes = {
    scrollToTop: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
    scrollToTop: () => dispatch(setScrollToTop()),
});

export default connect(null, mapDispatchToProps)(Landing);
