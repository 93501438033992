import { css } from 'styled-components';
import { outerCustomFocusMixin } from 'components/FocusVisibility/styles';
import { visuallyHiddenStyles } from 'components/VisuallyHidden/styles';
import { FROM_TABLET } from 'utils/mediaQueries';
import { COLORS } from 'utils/variables';

export const labelStyles = ({ variant }) => css`
    align-items: center;
    border-bottom: 1px solid ${COLORS.LIGHT_GRAY3};
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    font-size: 15px;
    justify-content: space-between;
    padding: 16px 0;
    position: relative;

    @media screen and ${FROM_TABLET} {
        border-bottom-width: 2px;
        font-size: 20px;
        padding: 24px 0;
    }

    /* stylelint-disable */

    ${variant.isChecked && css`
        color: ${COLORS.DARK_SAPPHIRE};
        font-weight: 700;
    `}

    ${!variant.isChecked && css`
        color: ${COLORS.BLACK};
    `}

    /* stylelint-enable */
`;

export const labelTextStyles = css`
    flex: 1;
    word-wrap: break-word;
`;

export const radioStyles = css`
    ${visuallyHiddenStyles}
`;

export const indicatorStyles = ({ variant }) => css`
    border: 2px solid #ccc;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    flex-shrink: 0;
    height: 20px;
    margin-right: 14px;
    position: relative;
    width: 20px;

    /* stylelint-disable */

    ${variant && variant.isChecked && css`
        border-color: ${COLORS.SAPPHIRE};

        &::before {
            background-color: ${COLORS.SAPPHIRE};
            border-radius: 50%;
            content: '';
            height: 10px;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 10px;
        }
    `}
    
    ${variant && variant.isFocused && css`
        ${outerCustomFocusMixin};
    `}

    /* stylelint-enable */
`;
