import React from 'react';
import ShareModal from 'components/ShareModal/ShareModal';
import styled from 'styled-components';
import Button from 'components/atomics/atoms/Button/Button';
import { TYPE_SECONDARY_SMALL } from 'components/atomics/atoms/Button/Button';
import { FormattedMessage } from 'react-intl';
import { APPLICATION_URL_GOOGLE_PLAY, APPLICATION_URL_APPLE_STORE } from 'utils/globalConstants';
import {
    BreakpointsContext,
    MOBILE,
} from 'components/Breakpoints/Breakpoints';
import {
    Container,
} from 'components';
import {
    footerStyles,
    containerStyles,
    AppStoreLogoStyles,
    playLogoStyles,
    logoContainerStyles,
} from 'components/FooterAppStoreAndShare/styles';
import {
    isMobile,
    isAndroid,
} from 'react-device-detect';

const StyledFooter = styled.footer`
    ${footerStyles}
`;

const StyledContainer = styled(Container)`
    ${containerStyles}
`;

const AppStoreLogo = styled.img`
     ${AppStoreLogoStyles}
`;

const PlayLogo = styled.img`
     ${AppStoreLogoStyles}
     ${playLogoStyles}
`;

const LogoContainer = styled.div`
    ${logoContainerStyles}
`;

const appStoreImg = '/images/app-store.png';
const playImg = '/images/googleplayx2.png';

class FooterAppStoreAndShare extends React.Component {
     /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    /**
     * Open the share modal.
     */
    openModal() {
        this.setState({
            modalOpen: true,
        });
    }

    /**
     * Close the share modal.
     */
    closeModal() {
        this.setState({
            modalOpen: false,
        });
    }

    /**
     * Render this and underlying components. 
     */
    render() {
        return (
            <BreakpointsContext.Consumer>
                    {(breakpoint) => {
                        return (
                            <React.Fragment>
                                <ShareModal
                                    isOpen={this.state.modalOpen}
                                    closeModal={this.closeModal}
                                />
                                <StyledFooter>
                                    <StyledContainer isDesktop={!isMobile}>
                                        <div>
                                            <div>
                                                <div>
                                                    <FormattedMessage id="footer.appStore.label" />
                                                </div>
                                                <LogoContainer>
                                                    {(!isMobile || !isAndroid || breakpoint !== MOBILE)
                                                    && <a
                                                        target="_blank"
                                                        href={APPLICATION_URL_APPLE_STORE}
                                                    >
                                                        <AppStoreLogo  src={appStoreImg} alt="Download on the App Store" />
                                                    </a>}
                                                    {(!isMobile || isAndroid || breakpoint !== MOBILE)
                                                    && <a
                                                        target="_blank"
                                                        href={APPLICATION_URL_GOOGLE_PLAY}
                                                    >
                                                        <PlayLogo src={playImg} alt="Download on Google Play" />
                                                    </a>}
                                                </LogoContainer>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <div>
                                                    <FormattedMessage id="footer.share.label" />
                                                </div>
                                                <div>
                                                    
                                                    <Button
                                                        to="#share"
                                                        tabIndex="0"
                                                        type={TYPE_SECONDARY_SMALL}
                                                        onClick={this.openModal}
                                                    >
                                                        <FormattedMessage id="footer.share.cta" />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </StyledContainer>
                                </StyledFooter>
                            </React.Fragment>
                        )
                    }
                }
            </BreakpointsContext.Consumer>
        );
    }
};

export default FooterAppStoreAndShare;
