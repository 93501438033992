import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import {
    containerStyles,
    contentContainerStyles,
    backgroundStyles,
} from 'components/atomics/atoms/SplashBanner/styles';
import labels from '../../../../lang/en.json';

export const NEWS_PAGE = "News";
export const GUIDES_PAGE = "Guides";

export const Container = styled.div`
    ${containerStyles}
`;

export const Background = styled.section`
    ${backgroundStyles}
`;

export const ContentContainer = styled.div`
    ${contentContainerStyles}
`;

/**
 * Creates a splash/greeting banner for news and guides pages.
 */
class SplashBanner extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render the banner.
     */
    render() {
        if (this.props.pageType === NEWS_PAGE) {
           return  this.renderNewsPageBanner();
        } else  if (this.props.pageType === GUIDES_PAGE) {
            return this.renderGuidesPageBanner();
        }
    }

    /**
     * Renders a news specific banner.
     */
    renderNewsPageBanner() {
        const hour = new Number(moment().format('HH'));
        
        let title = labels.translations['splashBanner.greating1'];

        if (hour >= 12) {
            title = labels.translations['splashBanner.greating2'];
        }

        if (hour >= 16) {
            title = labels.translations['splashBanner.greating3'];
        }

        if (hour <= 5) {
            title = labels.translations['splashBanner.greating4'];
        }

        title = title.replace('{name}', this.props.name);

        if (this.props.message) {
            title = this.props.message;
        }

        return (
            <Background data-rel="SplashBanner">
                <Container>
                    <ContentContainer>
                        <div />
                        <h1>
                            {title}
                        </h1>
                    </ContentContainer>
                </Container>
            </Background>
        );
    }

    /**
     * Renders a guides page specific banner.
     */
    renderGuidesPageBanner() {
        let title = this.props.message;

        title = title.replace('[First Name]', this.props.name);

        return (
            <Background data-rel="SplashBanner">
                <Container>
                    <ContentContainer>
                        <div />
                        <h1>
                            {title}
                        </h1>
                    </ContentContainer>
                </Container>
            </Background>
        );
    }
}

SplashBanner.propTypes = {
    name: PropTypes.string.isRequired,
    pageType: PropTypes.string,
    message: PropTypes.string,
}

SplashBanner.defaultProps = {
    pageType: NEWS_PAGE,
    message: null,
}

export default SplashBanner;
