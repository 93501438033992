import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Button } from 'components';
import { ConnectedBaseLayout } from 'components/BaseLayout/BaseLayout';
import { buttonStyles } from 'components/PublicLayout/styles';
import {
    TYPE_SECONDARY_MEDIUM 
} from '../../components/atomics/atoms/Button/Button';

const StyledButton = styled(Button)`
    ${buttonStyles}
`;

const HeaderContent = (
    <StyledButton
        appearance={Button.APPEARANCE.PRIMARY}
        href="/sign-in"
        id="sign-in-link"
        type={TYPE_SECONDARY_MEDIUM}
    >
        <FormattedMessage id="signIn.signIn" />
    </StyledButton>
);

export const PublicLayout = props => (
    <ConnectedBaseLayout
        headerContent={HeaderContent}
        mainContent={props.children}
        withClickableLogo
    />
);

PublicLayout.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

PublicLayout.defaultProps = {
    children: null,
};

function withPublicLayout(Component) {
    return props => (
        <PublicLayout>
            <Component {...props} />
        </PublicLayout>
    );
}

export default withPublicLayout;
