import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'components/atomics/atoms/Link/Link';
import { TOPIC_LINK } from 'state-management/constants/topics';
import moment from 'moment';
import { connect } from 'react-redux';
import ImageAtom from 'components/atomics/atoms/Image/Image';
import { visitArticle } from 'state-management/actions/articles.common';
import { visitCeCredit } from 'state-management/actions/guide';
import { getArticleUrl, getArticleImage } from '../../../../utils/getArticleAsset';
import CoachMarks from 'containers/CoachMarks/CoachMarks';
import { TYPES } from 'containers/CoachMarks/CoachMarks';
import {
    containerStyles,
    imageStyles,
    contentContainerStyles,
    dateAndTopicContainerStyles,
    dateContainerStyles,
    topicLinkStyles,
    titleStyles,
    imageContainerStyles,
    toolsHeaderIconStyles,
} from 'components/atomics/molecules/CeCreditsContentCard/styles';
import { contextHubRecordEvent } from 'utils/contextHub';
import {
    CARDS_EVENT_LIST,
} from 'utils/contextHubEventListConstants';
import ListContentCardFooter from "components/atomics/molecules/ListContentCard/ListContentCardFooter"
import {
    toggleSaveCecredit,
    toggleSaveGuide as toggleSaveGuideAction,
} from "../../../../state-management/actions/save";
import { toggleTopicFollow } from "../../../../state-management/actions/followTopic";
import { toggleSourceFollow } from "../../../../state-management/actions/sources";
import { dismissCoachMark } from "../../../../state-management/actions/coachMark";
import { CEIcon } from 'components/atomics/atoms/Icons/Icons';
import { getTruncatedString } from 'utils/contentCardUtils';
import SourceTag, {
    PROVIDER_CAPITAL_GROUP,
} from '../../../../components/atomics/atoms/SourceTag/SourceTag';

export const ImageContainer = styled(Link)`
    ${imageContainerStyles}
`;

export const Title = styled(Link)`
    ${titleStyles}
`;

export const DateAndTopic = styled.div`
    ${dateAndTopicContainerStyles}
`;

export const DateContainer = styled.div`
    ${dateContainerStyles}
`;

export const TopicLink = styled(Link)`
    ${topicLinkStyles}
`;

export const Container = styled.div`
    ${containerStyles}
`;

export const Image = styled(ImageAtom)`
    ${imageStyles}
`;

export const ContentContainer = styled.div`
    ${contentContainerStyles}
`;

const CardFooterContainer = styled.div`
    margin-top: 33px;

    .kebab__trigger {
        right: 15px;
        bottom: 18px;
    }
`;

export const ToolsHeaderIconContainer = styled.div`
    ${toolsHeaderIconStyles}
`;

const MAX_ARTICLE_TITLE_CHARS = 75;

class CeCreditsContentCard extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.onArticleClick = this.onArticleClick.bind(this);
        this.CH_handleComponentEventList = this.CH_handleComponentEventList.bind(this);
    }

    /**
     * Default state.
     */
    state = {
        onCardHover: false
    }

    /**
     * Handle article clicks.
     */
    onArticleClick() {
        const { onArticleClick, onGuideClick, shouldAllowClickArticleTracking } = this.props;
        const { id, licensed, sourceName, title, topics } = this.props.article;
        onGuideClick(id);
        if (shouldAllowClickArticleTracking) {
            onArticleClick(this.props.article);
            return;
        }
    }

    /**
     * Handle analytics.
     */
    CH_handleComponentEventList() {

        contextHubRecordEvent(CARDS_EVENT_LIST(
            'cecredits',
            this.props.article.title,
            this.props.article.topicName,
            this.props.article.sourceName
        ));
    }

    /**
     * Handle hover state.
     */
    onMouseEnter = () => {
        this.setState({ onCardHover: true });
    }

    /**
     * Handle remove hover state.
     */
    onMouseLeave = () => {
        this.setState({ onCardHover: false });
    }

    /**
     * Handle save guide action.
     */
    saveGuide = (id, save, title, getContentAfterSave, article) => {
        const { toggleSaveGuide } = this.props;

        toggleSaveGuide(id, save, title, getContentAfterSave, article);
    }

    /**
     * Handle follow topic action.
     */
    followTopic = (id, status) => {
        const { toggleFollowTopic } = this.props;

        toggleFollowTopic(id, status);
    }

    /**
     * Handle follow source action.
     */
    followSource = (id, status) => {
        const { toggleFollowSource } = this.props;

        toggleFollowSource(id, status);
    }

    /**
     * Handle save CE item action.
     */
    saveCecredit = (id, save, title, getContentAfterSave, article) => {
        const { toggleSaveCecredit } = this.props;

        toggleSaveCecredit(id, save, title, getContentAfterSave, article);
    }

    /**
     * Create kebab with coachmark.
     */
    buildCoachMarkKebab = (coachMark) => {
        const { onCardHover } = this.state;
        const { showCoachMark, onCoachMarkDismiss, activateDismissCoachMark } = this.props;

        return (
            <ListContentCardFooter onElement={onCardHover}
                profile={this.props.profile}
                sourceName={this.props.article.sourceName}
                providerName={this.props.article.provider}
                article={this.props.article}
                topics={this.props.article.topics}
                kebabButtonClickListener={() => showCoachMark || activateDismissCoachMark ? onCoachMarkDismiss() : null}
                sourceId={this.props.article.sourceId}
                topicName={this.props.article.topicName}
                saved={this.props.article.saved}
                articleId={parseInt(this.props.article.id)}
                articleTitle={this.props.article.title}
                standardDisplayOfSource={true}
                type={this.props.article.type || this.props.article.contentType}
                shouldShowToast={this.props.shouldShowToast}
                hideSourceOption={this.props.article.provider === PROVIDER_CAPITAL_GROUP}
                onSaveArticle={(id, save, title, getContentAfterSave, article) => {
                    this.saveCecredit(parseInt(this.props.article.id), save, title, getContentAfterSave, article);
                }}
                onSaveGuide={(id, save, title, getContentAfterSave, article) => {
                    this.saveGuide(id, save, title, getContentAfterSave, article);
                }}
                onFollowTopic={(topicId, status) => {
                    this.followTopic(topicId, status);
                }}
                onFollowSource={(sourceId, status) => {
                    this.followSource(sourceId, status);
                }}>
                <CoachMarks
                    showCoachMark={showCoachMark}
                    type={TYPES.KEBAB}
                >
                    <div data-rel="cmPlaceholder" />
                </CoachMarks>
            </ListContentCardFooter>
        );
    }

    /**
     * Render the content card and underlying components.
     */
    render() {
        const linkUrl = getArticleUrl(this.props.article);
        const imageUrl = getArticleImage(this.props.article);

        return (
            <Container data-rel="CeCreditsContentCard"
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            >
                <ImageContainer
                    visited={this.props.article && this.props.article.visited}
                    to={linkUrl}
                    onClick={() => {
                        this.CH_handleComponentEventList();
                        this.onArticleClick()
                    }}

                >
                    <ToolsHeaderIconContainer>
                        <CEIcon />
                    </ToolsHeaderIconContainer>

                    <Image
                        src={imageUrl}
                        alt="Decorative image"
                    />
                </ImageContainer>
                <ContentContainer>
                    <DateAndTopic>
                        <DateContainer>
                            {moment.utc(this.props.article.date).format('MMM D, YYYY')}
                        </DateContainer>
                        <TopicLink
                            to={TOPIC_LINK(`${this.props.article.topicName}/${this.props.article.topicId}`)}
                            data-analytics-placement="Button : body"
                            data-analytics-label="trackLink : button"
                            data-analytics-id={"topic:" + this.props.article.topicName}
                        >
                            <span>{this.props.article.topicName}</span>
                        </TopicLink>
                    </DateAndTopic>
                    <Title
                        visited={this.props.article && this.props.article.visited}
                        to={linkUrl}
                        onClick={() => {
                            this.CH_handleComponentEventList();
                            this.onArticleClick()
                        }}
                    >
                        {getTruncatedString(this.props.article.title, MAX_ARTICLE_TITLE_CHARS)}
                    </Title>

                    <CardFooterContainer>
                        {this.buildCoachMarkKebab(this.props.showCoachMark)}
                    </CardFooterContainer>
                </ContentContainer>
            </Container>
        );
    }
}

CeCreditsContentCard.propTypes = {
    article: PropTypes.shape({
        contentId: PropTypes.string,
        topicName: PropTypes.string.isRequired,
        topicId: PropTypes.number,
        title: PropTypes.string.isRequired,
        image: PropTypes.string,
        sourceName: PropTypes.string.isRequired,
        sourceId: PropTypes.number,
        provider: PropTypes.string.isRequired,
        id: PropTypes.any.isRequired,
        url: PropTypes.string,
        licensed: PropTypes.bool,
        topics: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string,
            ]).isRequired,
            followed: PropTypes.bool.isRequired,
            name: PropTypes.string.isRequired,
        })),
        type: PropTypes.string,
        saved: PropTypes.bool,
        contentType: PropTypes.string,
        interactionId: PropTypes.string,
        interactionType: PropTypes.string,
        visited: PropTypes.bool
    }).isRequired,
    shouldAllowClickArticleTracking: PropTypes.bool,
    showCoachMark: PropTypes.bool,

};

CeCreditsContentCard.defaultProps = {
    shouldAllowClickArticleTracking: true,
    showCoachMark: false,
    activateDismissCoachMark: false
}

const mapDispatchToProps = dispatch => ({
    onArticleClick: article => dispatch(visitArticle(article)),
    onGuideClick: id => dispatch(visitCeCredit(id)),
    toggleSaveCecredit: (id, save, title, getContentAfterSave, article) => dispatch(toggleSaveCecredit(id, save, {
        title,
        getContentAfterSave,
        article
    })),
    toggleSaveGuide: (id, save, title, shouldShowToast) => dispatch(toggleSaveGuideAction(id, save, {
        title,
        shouldShowToast
    })),
    toggleFollowTopic: (topicId, isFollowing) => dispatch(toggleTopicFollow(topicId, isFollowing)),
    toggleFollowSource: (sourceId, isFollowing) => (
        dispatch(toggleSourceFollow(sourceId, isFollowing))
    ),
    onCoachMarkDismiss: () => {
        dispatch(dismissCoachMark('KEBAB', true));
    },
});

const mapState = state => ({
    isLoading: state.followTopic.toggleInProgress,
    profile: state.basicProfile,
});

export default connect(mapState, mapDispatchToProps)(CeCreditsContentCard);
