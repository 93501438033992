import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import anime from 'animejs';
import { FormattedMessage } from 'react-intl';
import AnimatedMark from 'components/AnimatedMark/AnimatedMark';
import {
    contentStyles,
    markStyles,
    textStyles,
    wrapperStyles,
} from 'containers/Onboarding/OnboardingAllDone/styles';
import { ROUTES } from 'containers/App/constants';
import {
    clearGlobalLogo as clearGlobalLogoAction,
    setGlobalLogo as setGlobalLogoAction,
} from 'state-management/actions/globalLogoLink';

const StyledWrapper = styled.div`
    ${wrapperStyles};
`;

const StyledContent = styled.div`
    ${contentStyles};
`;

const StyledMark = styled(AnimatedMark)`
    ${markStyles};
`;

const StyledText = styled.p`
    ${textStyles};
`;

/**
 * Creates the all done page under onboarding.
 */
class OnboardingAllDone extends React.Component {
    /**
     * Default constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            isAnimated: false,
            isAnimating: false,
        };

        this.contentRef = React.createRef();
        this.textRef = React.createRef();
    }

    /**
     * Setup logo and animate on mount.
     */
    componentDidMount() {
        this.props.setGlobalLogo(ROUTES.HOME);
        this.animate();
    }

    /**
     * Animate on prop change.
     */
    componentDidUpdate() {
        this.animate();
    }

    /**
     * Clear global logo and cancel animations on destroy.
     */
    componentWillUnmount() {
        this.props.clearGlobalLogo();
        if (this.state.isAnimating) {
            this.animation.pause();
        }
    }

    /**
     * Handle animation.
     */
    animate() {
        const { isAnimatable, isLoaded, onAnimationEnd } = this.props;

        if (!isAnimatable || !isLoaded || this.state.isAnimated) {
            return;
        }

        const contentNode = this.contentRef.current;
        const textNode = this.textRef.current;

        this.setState({
            isAnimated: true,
        });

        if (textNode && contentNode) {
            this.setState({
                isAnimating: true,
            });
            this.animation = anime.timeline();
            this.animation
                .add({
                    targets: textNode,
                    easing: [0.53, 0.05, 0.01, 0.97],
                    translateY: [40, 0],
                    opacity: [0, 1],
                    duration: 660,
                    offset: 330,
                    complete: () => {
                        textNode.removeAttribute('style');
                    },
                })
                .add({
                    targets: contentNode,
                    easing: [0.53, 0.05, 0.01, 0.97],
                    translateY: [0, 80],
                    opacity: [1, 0],
                    duration: 660,
                    offset: 2330,
                    complete: () => {
                        this.setState({
                            isAnimating: false,
                        }, () => {
                            if (onAnimationEnd && typeof onAnimationEnd === 'function') {
                                onAnimationEnd();
                            }
                        });
                    },
                });
        }
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const { isAnimatable } = this.props;
        const { isAnimated } = this.state;

        return (
            <StyledWrapper>
                <StyledContent
                    ref={this.contentRef}
                >
                    <StyledMark />
                    <StyledText
                        ref={this.textRef}
                        variant={{ isAnimatable, isAnimated }}
                    >
                        <FormattedMessage id="onboarding.allDone.title" />
                    </StyledText>
                </StyledContent>
            </StyledWrapper>
        );
    }
}

OnboardingAllDone.propTypes = {
    isAnimatable: PropTypes.bool,
    isLoaded: PropTypes.bool,
    onAnimationEnd: PropTypes.func,
    clearGlobalLogo: PropTypes.func.isRequired,
    setGlobalLogo: PropTypes.func.isRequired,
};

OnboardingAllDone.defaultProps = {
    isAnimatable: true,
    isLoaded: true,
    onAnimationEnd: () => {},
};

const mapDispatchToProps = dispatch => ({
    clearGlobalLogo: () => dispatch(clearGlobalLogoAction()),
    setGlobalLogo: logoLink => dispatch(setGlobalLogoAction(logoLink)),
});

export default connect(null, mapDispatchToProps)(OnboardingAllDone);
