import {
    ADD_TOASTS, SHOW_TOASTS, HIDE_TOASTS, REMOVE_TOASTS,
} from 'state-management/constants/toasts';

export const addToasts = (toasts = []) => ({
    toasts,
    type: ADD_TOASTS,
});

export const removeToasts = () => ({
    type: REMOVE_TOASTS,
});

export const showToasts = () => ({
    type: SHOW_TOASTS,
});

export const hideToasts = () => ({
    type: HIDE_TOASTS,
});
