import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CollectionContentCard from '../../../../components/atomics/molecules/CollectionContentCard/CollectionContentCard';
import Row from 'components/atomics/atoms/Row/Row';
import {
    BreakpointsContext,
    MOBILE,
    TABLET
} from 'components/Breakpoints/Breakpoints';
import {
    containerStyles,
} from 'components/atomics/organisms/CollectionCardCollectionOfFour/styles';
import { POSITIONS } from "components/atomics/molecules/CoachMark/constants";

export const Container = styled.div`
    ${containerStyles}
`;

export const TYPE_4COLUMN = "type/4column";
export const TYPE_3COLUMN = "type/3column";

/**
 * Creates a collection card set of 4.
 */
class CollectionCardCollectionOfFour extends React.Component {
    /**
     * Render the container and underlying components.
     */
    render() {
        const { showCoachMark, showCollectionCoachMark } = this.props;

        const showCoachMark1 = (showCoachMark && this.props.collectionList[0] && this.props.collectionList[0].tools && this.props.collectionList[0].tools.length) || showCollectionCoachMark;
        const showCoachMark2 = showCoachMark && this.props.collectionList[1] && this.props.collectionList[1].tools && this.props.collectionList[1].tools.length
            && !showCoachMark1;
        const showCoachMark3 = showCoachMark && this.props.collectionList[2] && this.props.collectionList[2].tools && this.props.collectionList[2].tools.length
            && !showCoachMark1 && !showCoachMark2;
        const showCoachMark4 = this.props.collectionList[3] && showCoachMark && this.props.collectionList[3].tools && this.props.collectionList[3].tools.length
            && !showCoachMark1 && !showCoachMark2 && !showCoachMark3;
        return (
            <BreakpointsContext.Consumer>
                {(breakpoint) => {
                    if (breakpoint === MOBILE) {
                        return (
                            <Container>
                                {this.props.collectionList[0] &&
                                    <CollectionContentCard
                                        showCoachMark={showCoachMark1}
                                        collectionInfo={this.props.collectionList[0]}
                                        icon="education"
                                    />}
                                {this.props.collectionList[1] &&
                                    <CollectionContentCard
                                        showCoachMark={showCoachMark2}
                                        collectionInfo={this.props.collectionList[1]}
                                    />}
                                {this.props.collectionList[2] &&
                                    <CollectionContentCard
                                        showCoachMark={showCoachMark3}
                                        collectionInfo={this.props.collectionList[2]}
                                        icon="education"
                                    />}
                                {this.props.collectionList[3] &&
                                    <CollectionContentCard
                                        showCoachMark={showCoachMark4}
                                        collectionInfo={this.props.collectionList[3]}
                                    />}
                            </Container>
                        );
                    }
                    if (breakpoint === TABLET) {
                        return (
                            <Row data-rel="CollectionCardCollectionOfFour" type={TYPE_3COLUMN}>
                                {this.props.collectionList[0] &&
                                    <CollectionContentCard
                                        isCustomCoachMarkPosition={true}
                                        customCoachMarkPositionOption={{
                                            placement: POSITIONS.RIGHT_TOP
                                        }}
                                        showCoachMark={showCoachMark1}
                                        collectionInfo={this.props.collectionList[0]}
                                        icon="education"
                                        toolsCardSize="small"
                                    />
                                }
                                {this.props.collectionList[1] &&
                                    <CollectionContentCard
                                        showCoachMark={showCoachMark2}
                                        collectionInfo={this.props.collectionList[1]}
                                        toolsCardSize="small"
                                    />
                                }
                                {this.props.collectionList[2] &&
                                    <CollectionContentCard
                                        showCoachMark={showCoachMark3}
                                        collectionInfo={this.props.collectionList[2]}
                                        icon="education"
                                        toolsCardSize="small"
                                    />
                                }
                            </Row>
                        );
                    }

                    return (
                        <Row data-rel="CollectionCardCollectionOfFour" type={TYPE_4COLUMN}>
                            {this.props.collectionList[0] &&
                                <CollectionContentCard
                                    showCoachMark={showCoachMark1}
                                    collectionInfo={this.props.collectionList[0]}
                                    icon="education"
                                    toolsCardSize="small"
                                />
                            }
                            {this.props.collectionList[1] &&
                                <CollectionContentCard
                                    showCoachMark={showCoachMark2}
                                    collectionInfo={this.props.collectionList[1]}
                                    icon="education"
                                    toolsCardSize="small"
                                />
                            }
                            {this.props.collectionList[2] &&
                                <CollectionContentCard
                                    showCoachMark={showCoachMark3}
                                    collectionInfo={this.props.collectionList[2]}
                                    icon="education"
                                    toolsCardSize="small"
                                />
                            }
                            {this.props.collectionList[3] &&
                                <CollectionContentCard
                                    showCoachMark={showCoachMark4}
                                    collectionInfo={this.props.collectionList[3]}
                                    icon="education"
                                    toolsCardSize="small"
                                />
                            }
                        </Row>
                    );
                }}
            </BreakpointsContext.Consumer>
        );
    }
}

CollectionCardCollectionOfFour.propTypes = {
    showCoachMark: PropTypes.bool,
    showCollectionCoachMark: PropTypes.bool,
    collectionList: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        ordering: PropTypes.number.isRequired,
        image: PropTypes.string.isRequired,
        latestPublishTimestamp: PropTypes.string.isRequired,
        guides: PropTypes.arrayOf(PropTypes.shape({})),
        tools: PropTypes.arrayOf(PropTypes.shape({})),
    })),
};

CollectionCardCollectionOfFour.defaultProps = {
    showCoachMark: false,
    showCollectionCoachMark: false,
};

export default CollectionCardCollectionOfFour;
