import { css } from 'styled-components';
import { COLORS } from '../../utils/variables';

export const containerStyles = css`
    align-items: center;
    display: inline-flex;
    line-height: 1;
    padding: 3px 0;
    white-space: nowrap;
`;

export const iconStyles = css`
    margin-right: 16px;
    stroke: ${COLORS.LIGHT_GRAY3};
`;
