import {
    call,
    put,
    takeLatest,
    all,
} from 'redux-saga/effects';
import {
    changePasswordError,
    changePasswordSuccess,
    setVerifyError,
    setVerifySuccess,
    sendForgotPasswordEmailError,
    sendForgotPasswordEmailSuccess,
} from 'state-management/actions/forgotPassword';
import Api from 'utils/api';
import {
    CHANGE_FORGOT_PASSWORD_URL, CHANGE_PASSWORD,
    SEND_FORGOT_PASSWORD_EMAIL,
    SEND_FORGOT_PASSWORD_EMAIL_URL,
    VERIFY_PASSWORD_TOKEN,
    VERIFY_PASSWORD_TOKEN_URL,
    CURRENT_PASSWORD_URL,
    CURRENT_PASSWORD,
    RESET_PASSWORD_EMAIL,
} from 'state-management/constants/forgotPassword';
import { TYPES } from 'containers/Toasts/constants';
import { addToasts } from 'state-management/actions/toasts';
import { setFocusOnError } from 'state-management/actions/errors';
import storage from "../../utils/store";

/**
 * Handle save page toast display.
 * @param {*} action
 * @param {*} toastType
 * @param {*} analyticsData
 */
function* handleShowToast(toastType) {
    yield put(addToasts([{
        toastProps: {
            actionType: null,
        },
        toastType,
    }]));
}

/**
 * Update current password.
 * @param {*} param0
 */
function* curPassword({ curPassword, password, verifyPassword }) {
    try {
        yield call(Api.post, CURRENT_PASSWORD_URL, {
            data: { curPassword, password, verifyPassword },
        });
    } catch (error) {
        // do nothing

    }
}

/**
 * Request current password reset email.
 * @param {*} param0
 */
function* sendForgotPasswordEmail({ email }) {
    try {
        yield call(Api.post, SEND_FORGOT_PASSWORD_EMAIL_URL, {
            data: { email },
        });
        yield put(sendForgotPasswordEmailSuccess(email));
        yield call(handleShowToast, TYPES.RESEND_EMAIL);
    } catch (e) {
        yield put(sendForgotPasswordEmailError(e));
    }
}

/**
 * Verify users password token.
 */
function* verifyPasswordToken({ token }) {
    try {
        const data = yield call(Api.post, VERIFY_PASSWORD_TOKEN_URL, {
            data: {
                "token" : token,
            },
        });

        storage.set(RESET_PASSWORD_EMAIL, data.data.email);
        yield put(setVerifySuccess());
    } catch (e) {
        yield put(setVerifyError(e.data[0].code));
    }
}

/**
 * Change password at forgot password screen.
 * @param {*} param0
 */
function* changeForgotPassword({ password, token }) {
    try {
        yield call(Api.post, CHANGE_FORGOT_PASSWORD_URL, {
            data: {
                newPassword: password,
                token,
            },
        });
        yield put(changePasswordSuccess());
    } catch (e) {
        yield put(setFocusOnError(true));
        yield put(changePasswordError(e));
    }
}

function* forgotPasswordSaga() {
    yield all([
        takeLatest(SEND_FORGOT_PASSWORD_EMAIL, sendForgotPasswordEmail),
        takeLatest(VERIFY_PASSWORD_TOKEN, verifyPasswordToken),
        takeLatest(CHANGE_PASSWORD, changeForgotPassword),
        takeLatest(CURRENT_PASSWORD, curPassword),
    ]);
}

export default forgotPasswordSaga;
