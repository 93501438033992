
export const TOOLS_GET_URL = '/content-service/tool-categories';

export const GET_TOOLS_DATA = 'guide/GET_TOOLS_DATA';
export const GET_TOOLS_DATA_FAIL = 'guide/GET_TOOLS_DATA_FAIL';
export const GET_TOOLS_DATA_SUCCESS = 'guide/GET_TOOLS_DATA_SUCCESS';

export const ALL_TOOLS_ROUTE = '/learn/tools';

export const SEND_CLICK_TOOLS = 'SEND_CLICK_TOOLS';
export const SEND_CLICK_TOOLS_URL = '/content-service/cards';
