import {
    GET_CONTENT_FRAGMENTS,
    SET_CONTENT_FRAGMENTS,
} from 'state-management/constants/commonContentFragments';

const initialState = {
    fragments: {},
    isLoading: false,
};

function contentCommonReducer(state = initialState, action = {}) {
    switch (action.type) {
    case GET_CONTENT_FRAGMENTS:
        return {
            ...state,
            isLoading: true,
        };
    case SET_CONTENT_FRAGMENTS:
        return {
            ...state,
            fragments: {
                ...state.fragments,
                ...action.contentFragments,
            },
            isLoading: false,
        };
    default:
        return state;
    }
}

export default contentCommonReducer;
