export const FRAGMENT_PRIVACY_POLICY = 'privacy-policy';
export const FRAGMENT_TERMS_AND_CONDITIONS = 'terms-and-conditions';
export const FRAGMENT_ABOUT_LUMIN_WHAT_IS_LUMIN = 'about-lumin-what-is-lumin';
export const FRAGMENT_ABOUT_LUMIN_HOW_DOES_IT_WORK = 'about-lumin-how-does-it-work';
export const FRAGMENT_ABOUT_LUMIN_IS_THERE_A_CATCH = 'about-lumin-is-there-a-catch';
export const FRAGMENT_ABOUT_LUMIN_SOURCES = 'about-lumin-sources';
export const FRAGMENT_ABOUT_LUMIN_TOPICS = 'about-lumin-topics';
export const FRAGMENT_ABOUT_LUMIN_ONE_SPOT = 'about-lumin-one-spot';
export const FRAGMENT_ABOUT_LUMIN = 'about-lumin';

export const SET_CONTENT_FRAGMENTS = 'commonContentFragments/SET_CONTENT_FRAGMENTS';
export const GET_CONTENT_FRAGMENTS = 'commonContentFragments/GET_CONTENT_FRAGMENTS';

export const CONTENT_COMMON_PUBLIC_URL = 'content-service/content/common/public/v2';
