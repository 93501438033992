import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import {
    sectionStyles,
    containerStyles,
    introStyles,
    headingStyles,
    descriptionStyles,
    cardsContainerStyles,
} from 'containers/Home/HomepageGreeting/styles';
import { Container, Heading, SanitizedHTML } from 'components';
import { setOnboardingFinished as setOnboardingFinishedAction } from 'state-management/actions/onboarding';
import LinkCard from 'containers/Home/HomepageGreeting/LinkCard/LinkCard';
import { createIdsForObjectsByField } from 'utils/idCreatorUtils';
import getDaytimeType from 'utils/getGreeting';

const StyledSection = styled.section`
    ${sectionStyles}
`;

const StyledContainer = styled(Container)`
    ${containerStyles}
`;

const StyledIntro = styled.div`
    ${introStyles}
`;
const StyledHeading = styled(Heading)`
    ${headingStyles}
`;

const StyledDescription = styled(SanitizedHTML)`
    ${descriptionStyles};
`;

const StyledCardsContainer = styled.div`
    ${cardsContainerStyles}
`;

const FIRST_NAME = '[FirstName]';
const GREETING = '[Greeting]';

/**
 * Display component for homepage greeting area.
 */
export class HomepageGreeting extends React.Component {
    /**
     * Update users onboarding state and destruction.
     */
    componentWillUnmount() {
        const { isOnboardingFinished, setOnboardingFinished } = this.props;
        if (isOnboardingFinished) {
            setOnboardingFinished(false);
        }
    }

    /**
     * Gather the proper greeting text.
     */
    getGreetingMessage() {
        const {
            isOnboardingFinished,
            title, description,
            onboardingTitle, onboardingDescription,
        } = this.props;
        if (isOnboardingFinished && onboardingTitle && onboardingDescription) {
            return {
                text: this.addGreetingParameters(onboardingTitle),
                secondary: onboardingDescription,
            };
        }
        return {
            text: this.addGreetingParameters(title),
            secondary: description,
        };
    }

    /**
     * Assign needed parameters for proper homepage greeting.
     * @param {*} text 
     */
    addGreetingParameters(text) {
        const { firstName: name, intl } = this.props;
        return text
            .replace(FIRST_NAME, name)
            .replace(GREETING, intl.formatMessage(
                { id: `homepage.greeting.${getDaytimeType()}.title` },
            ));
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const { linkCards } = this.props;
        const { text, secondary } = this.getGreetingMessage();

        return (
            <StyledSection>
                <StyledContainer>
                    <StyledIntro>
                        <StyledHeading level={1}>{text}</StyledHeading>
                        <StyledDescription
                            tag="div"
                            htmlContent={secondary}
                        />
                    </StyledIntro>
                    <StyledCardsContainer>
                        {
                            createIdsForObjectsByField(linkCards, 'title')
                                .map(({ createdId, ...card }) => (
                                    <LinkCard {...card} key={createdId} />
                                ))
                        }
                    </StyledCardsContainer>
                </StyledContainer>
            </StyledSection>
        );
    }
}

HomepageGreeting.defaultProps = {
    onboardingTitle: null,
    onboardingDescription: null,
};

HomepageGreeting.propTypes = {
    title: PropTypes.string.isRequired,
    onboardingTitle: PropTypes.string,
    description: PropTypes.string.isRequired,
    onboardingDescription: PropTypes.string,
    linkCards: PropTypes.arrayOf(PropTypes.shape({
        imageAsset: PropTypes.string,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
    })).isRequired,
    firstName: PropTypes.string.isRequired,
    isOnboardingFinished: PropTypes.bool.isRequired,
    setOnboardingFinished: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
};

const mapStateToProps = state => ({
    firstName: state.basicProfile.firstName,
    isOnboardingFinished: state.onboarding.onboardingFinished,
});

const mapDispatchToProps = dispatch => ({
    setOnboardingFinished: newState => dispatch(setOnboardingFinishedAction(newState)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(HomepageGreeting));
