import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Toast from 'components/atomics/molecules/Toast/Toast';
import {
    BreakpointsContext,
    MOBILE,
} from 'components/Breakpoints/Breakpoints';

/**
 * Toast shown when user unfollows a topic.
 */
class UnfollowTopicToast extends React.Component {
    render() {
        return (
            <BreakpointsContext.Consumer>
                {(breakpoint) => {
                    let titleStringId = 'toast.unfollowTopic';

                    if (breakpoint === MOBILE) {
                        titleStringId = 'toast.unfollowTopic.short';
                    }

                    return (
                        <Toast>
                            <Toast.Title>
                                <div>
                                    <FormattedMessage id={titleStringId} />
                                </div>
                            </Toast.Title>
                        </Toast>
                    );
                }}
            </BreakpointsContext.Consumer>
        );
    }
}

UnfollowTopicToast.propTypes = {
    actionType: PropTypes.string,
    id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    analyticsData: PropTypes.objectOf(PropTypes.any),
};

UnfollowTopicToast.defaultProps = {
    actionType: '',
    id: 'topic-unfollow-toast',
    analyticsData: {},
};

export default UnfollowTopicToast;
