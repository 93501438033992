import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from '../../utils/mediaQueries';
import { COLORS } from '../../utils/variables';
import {
    StyledLogoContainer,
    StyledLogoIcon,
    StyledEmblemIcon,
    StyledPoweredByIcon,
} from 'components/Logo/Logo';

export const headerContainerStyles = css`
    display: flex;
`;

export const headerNavContainerStyles = css`
    bottom: 0;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    width: 100%;

    @media screen and ${FROM_DESKTOP} {
        flex-direction: row;
        position: static;
    }
`;

export const headerNavLogoWrapperStyles = css`
    padding: 74px 0 44px 50px;

    @media screen and ${FROM_DESKTOP} {
        padding: 74px 0 44px 54px;
        display: none;
    }

    /* stylelint-disable */
    ${StyledLogoContainer} {
        height: 37px;
        width: 210px;

        @media screen and ${FROM_TABLET} {
            width: 270px;
            height: 48px;
        }
    }

    ${StyledLogoIcon} {
        height: 30px;
        width: 103px;

        @media screen and ${FROM_TABLET} {
            height: 39px;
            width: 133px;
        }
    }

    ${StyledEmblemIcon} {
        max-height: 37px;
        max-width: 37px;

        @media screen and ${FROM_TABLET} {
            max-height: 48px;
            max-width: 48px;
        }
    }

    ${StyledPoweredByIcon} {
        @media screen and ${FROM_TABLET} {
            height: 38px;
            width: 60px;
        }
    }

    ${StyledPoweredByIcon},
    ${StyledEmblemIcon} {
        @media screen and ${FROM_TABLET} {
            margin-left: 14px;
        }
    }
    /* stylelint-enable */
`;

export const headerNavPrimaryNavStyles = css`
    @media screen and ${FROM_DESKTOP} {
        padding-left: 24px;
    }
`;

export const headerNavSecondaryNavStyles = css`
    display: flex;
    margin-top: auto;
    position: relative;

    @media screen and ${FROM_DESKTOP} {
        &::before {
            background: ${COLORS.LIGHT_GRAY3};
            bottom: 0;
            content: '';
            left: 0;
            position: absolute;
            top: 5px;
            width: 2px;
            height: 30px;
        }
    }

    @media screen and ${FROM_DESKTOP} {
        margin: 0 51px 0 24px;
        padding-left: 48px;
    }
`;

export const mainContainerStyles = css`
    background-color:${COLORS.LIGHT_GRAY1};
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: flex-start;
`;

export const logoLinkStyles = css`
    
`;
