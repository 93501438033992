import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    getTrendingAllTopics,
    toggleSaveTrendingAllTopics,
} from 'state-management/actions/allTopics.common';
import {
    getTrendingTopicFilters,
} from 'state-management/actions/trendingTopic.common';
import { dismissCoachMark } from 'state-management/actions/coachMark';
import TrendingTopicsDisplay from '../../../components/atomics/templates/TrendingTopics/TrendingTopics';

/**
 * Trending topics and top topics container for news page.
 */
class TrendingTopics extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.requestUpdate = this.requestUpdate.bind(this);
        this.handleSaveArticleKebabClick = this.handleSaveArticleKebabClick.bind(this);
        this.checkArticleIntegrity = this.checkArticleIntegrity.bind(this);
    }

    /**
     * Handle underlying state changes needing API requests.
     * @param {*} typeId
     * @param {*} filterId
     */
    requestUpdate(typeId, filterId) {
        this.props.getTrendingAllTopics(typeId, filterId);
    }

    /**
     * Fetch the top topics at mount.
     */
    componentDidMount() {
        this.props.getTrendingAllTopics(0, null);
        this.props.getFilters();
    }

    /**
     * To assist in setting redux state of saved article in trending topics.
     * @param {*} id
     * @param {*} save
     * @param {*} topic
     */
    handleSaveArticleKebabClick(id, save, topic) {
        this.props.toggleSaveTrendingAllTopics(id, save, topic);
    }

    /**
     * Check articles have URLs.
     */
    checkArticleIntegrity() {
        const self = this;
        let areValid = true;

        if (this.props.topics.length) {
            //make sure first two topics have at least 3 articles.
            if (!this.props.topics[0].newsItems ||
                this.props.topics[0].newsItems.length < 3) {
                areValid = false;
            }

            if (!this.props.topics[1].newsItems ||
                this.props.topics[1].newsItems.length < 3) {
                areValid = false;
            }
        }

        return areValid;
    }

    /**
     * Render the container and child components.
     */
    render() {
        const lastSixTopics = this.props.topics.slice(-6);

        return (
            <TrendingTopicsDisplay
                onTabClick={this.props.dismissCoachMark}
                topics={this.props.topics}
                topTopics={lastSixTopics}
                requestUpdate={this.requestUpdate}
                experienceId={this.props.experienceId}
                bobId={this.props.bobId}
                wmAUMId={this.props.wmAUMId}
                loadingFailed={this.props.loadingFailed}
                retirementPlanAUMId={this.props.retirementPlanAUMId}
                serviceId={this.props.serviceIds.length ? this.props.serviceIds[0] : null}
                filters={this.props.filters}
                isTrendingLoading={this.props.isLoading}
                handleComponentFailure={this.props.handleComponentFailure}
            />
        );
    }
}

TrendingTopics.propTypes = {
    topics: PropTypes.arrayOf(PropTypes.shape({})),
    subscribedTopics: PropTypes.arrayOf(PropTypes.number),
    subscribedSources: PropTypes.arrayOf(PropTypes.number),
    getTrendingAllTopics: PropTypes.func.isRequired,
    toggleSaveTrendingAllTopics: PropTypes.func.isRequired,
    experienceId: PropTypes.number,
    bobId: PropTypes.number,
    wmAUMId: PropTypes.number,
    retirementPlanAUMId: PropTypes.number,
    serviceIds: PropTypes.arrayOf(PropTypes.number),
    getFilters: PropTypes.func.isRequired,
    dismissCoachMark: PropTypes.func,
    isLoaded: PropTypes.bool,
    loadingFailed: PropTypes.bool,
    handleComponentFailure: PropTypes.func,
};

TrendingTopics.defaultProps = {
    topics: [],
    subscribedTopics: [],
    subscribedSources: [],
    experienceId: null,
    bobId: null,
    wmAUMId: null,
    retirementPlanAUMId: null,
    serviceIds: [],
    filters: null,
    dismissCoachMark: () => { },
    isLoaded: false,
    loadingFailed: false,
    handleComponentFailure: () => { },
};

const mapStateToProps = state => ({
    topics: state.allTopics.trending,
    isLoading: state.allTopics.isLoading,
    loadingFailed: state.allTopics.loadingFailed,
    subscribedTopics: state.basicProfile.topicIds,
    subscribedSources: state.basicProfile.sourceIds,
    experienceId: state.basicProfile.durationId,
    bobId: state.basicProfile.amountId,
    serviceIds: state.basicProfile.serviceOfferingIds,
    retirementPlanAUMId: state.basicProfile.retirementPlanAUMId,
    wmAUMId: state.basicProfile.wmAUMId,
    filters: state.trendingTopicNews.filters,
});

const mapDispatchToProps = dispatch => ({
    getTrendingAllTopics: (typeId, filterId) => dispatch(getTrendingAllTopics(typeId, filterId)),
    getFilters: () => dispatch(getTrendingTopicFilters(true)),
    toggleSaveTrendingAllTopics: (articleId, isSaved, topicId) => dispatch(
        toggleSaveTrendingAllTopics(articleId, isSaved, topicId),
    ),
    dismissCoachMark: () => { dispatch(dismissCoachMark('TRENDING_TOPICS', true)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(TrendingTopics);
