import {
    AGREEMENT_UPDATE,
    GET_LEGAL_AGREEMENT,
    GET_LEGAL_AGREEMENT_FAILURE,
    GET_LEGAL_AGREEMENT_SUCCESS,
} from 'state-management/constants/legalAgreement.common';

const initialState = {
    accepted: false,
    text: '',
    isLoading: false,
};

function legalAgreementReducer(state = initialState, action = {}) {
    switch (action.type) {
    case AGREEMENT_UPDATE:
        return {
            ...state,
            accepted: action.accepted,
        };
    case GET_LEGAL_AGREEMENT:
        return {
            ...state,
            isLoading: true,
        };
    case GET_LEGAL_AGREEMENT_SUCCESS:
        return {
            ...state,
            isLoading: false,
            text: action.legalAgreement,
        };
    case GET_LEGAL_AGREEMENT_FAILURE:
        return {
            ...state,
            isLoading: false,
        };
    default:
        return state;
    }
}

export default legalAgreementReducer;
