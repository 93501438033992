import { SIZE as CARD_SIZE, TYPE } from 'components/ContentCard/constants';
import { HBR, MC_KINSEY } from 'containers/Guide/guideTypes';

/**
 * Get a resized content image from AEM (for guides, PM, CE).
 * @param url
 */
export const getResizedImage = (url, width, height) => {
    return `/api${url}&im=Resize,width=${width},height=${height},aspect=fill`;
};

export const getSizeProps = (internal) => {
    if (internal) {
        return {
            size: CARD_SIZE.STANDARD,
            mobile: CARD_SIZE.STANDARD,
        };
    }

    return {
        size: CARD_SIZE.STANDARD,
        mobile: CARD_SIZE.SMALL,
    };
};

export const isSubtypeOfGuide = type => [TYPE.GUIDE_IN_PRACTICE, TYPE.IN_PRACTICE, TYPE.GUIDE_STANDARD, TYPE.GUIDE_HBR, TYPE.GUIDE_MC_KINSEY, TYPE.TOOL]
    .includes(type);
export const isPracticeLab = sourceTitle => sourceTitle === "Practice Lab";
export const formatFullGuideType = guideType => `GUIDE_${guideType}`;
export const isGuideFeatured = type => [HBR, MC_KINSEY].includes(type);


//string trunctation constants and heler functions below..
const TRUNCATION_APPEND_CHARS = '...';

/**
 * Truncates string by max length parameter and appends above chars constant as needed.
 * @param {string} title 
 * @param {number} maxLength 
 */
export const getTruncatedString = (title, maxLength) => {
    return title && title.length > maxLength
        ? `${title.slice(0, maxLength - TRUNCATION_APPEND_CHARS.length).split(/[\s, ,;,:,-]+/).slice(0, -1).join(' ')}${TRUNCATION_APPEND_CHARS}` : title;
};


/**
 * Transforms content and list card discrepencies into uniform data.
 * @param {object} data (article, guide, capital ideas, webinar, cecredit, tool)
 * @param {integer} forcedTopicId (optionally force the topic ID)
 * @param {string} forcedTopicName (optionally force the topic name)
 * @param {string} defaultImage (optinally set an alternate image default)
 * @param {boolean} isCollection (optionally toggle to collection tranformation)
 */
export const transformContentItem = (data, forcedTopicId = null, forcedTopicName = null, defaultImage = null, isCollection = false) => {
    if (isCollection) {
        return {
            ordering: data.ordering,
            code: data.code,
            title: data.title,
            image: data.image,
            latestPublishTimestamp: data.latestPublishTimestamp,
            description: data.description,
            guides: data.guides,
            tools: data.tools,
        };
    }

    return {
        date: data.latestPublishTimestamp ? data.latestPublishTimestamp : data.publishDate,
        modelDisplayName: data.modelDisplayName ? data.modelDisplayName : "",
        publishDate: data.latestPublishTimestamp ? data.latestPublishTimestamp : data.publishDate,
        topicName: forcedTopicName !== null ? forcedTopicName : data.topics && data.topics.length ? data.topics[0].name : '',
        topicId: forcedTopicId !== null ? forcedTopicId : data.topics && data.topics.length ? data.topics[0].id : 0,
        topics: data.topics,
        title: data.title,
        image: defaultImage && !data.image ? defaultImage : data.image,
        sourceName: data.sourceTitle,
        sourceId: data.sourceId,
        provider: data.provider,
        id: data.id,
        url: data.link ? data.link : '',
        saved: data.saved,
        type: data.type,
        licensed: data.licensed,
        link: data.link,
        category: data.category,
        contentType: data.contentType,
        interactionId: data.interactionId,
        interactionType: data.interactionType,
        categoryGroup: data.categoryGroup,
        categoryCode: data.categoryCode,
        visited: data.visited,
        cardId: data.cardId,
        code: data.code,
        eventId: data.eventId,
        mediaLinks: data.mediaLinks,
    };
}