import { css } from 'styled-components';
import {
    FONT_AVENIR_NEXT,
    COLORS,
    FONTS,
    ANIMATIONS,
} from 'utils/variables';

export const errorStyles = css`
    color: ${COLORS.ALERT_RED};
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    word-wrap: break-word;

    .SignUpForm__StyledLink-gwsRWm{
        color: ${COLORS.SAPPHIRE};
    }
`;
