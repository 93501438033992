import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Link from 'components/Link/Link';
import { IconSeeMore } from 'components/atomics/atoms/Icons/Icons';
import { backToIconStyles, backToLinkStyles } from '../../components/BackToLink/styles';
import Button from 'components/Button/Button';
import { removeItemFromHistory } from 'state-management/actions/UrlHistoryActions';
import storage from 'utils/store';

const StyledLink = styled(Link)`
    ${backToLinkStyles};
`;

const StyledGoBack = styled(Button)`
    ${backToLinkStyles}
`;

const StyledLinkIcon = styled(IconSeeMore)`
    ${backToIconStyles};
`;

const BACK_INDICATOR = 'b=1';

class BackToLink extends React.Component {
    /**
     * Default constructor.
     */
    constructor(props) {
        super(props);

        this.handleBackClick = this.handleBackClick.bind(this);
    }

    /**
     * Handle removing history items on back link/button click.
     */
    handleBackClick() {
        this.props.removeHistoryItem();

        if (this.props.onClick) {
            this.props.onClick();
        }

        if (this.props.goBack) {
            this.props.goBack();
        }
    }

    /**
     * Render the back button.
     */
    render() {
        const {
            className,
            children,
            theme,
            to,
            isDisable,
            goBack,
            onClick,
            customLabel,
        } = this.props;
        const analyticsLabel = customLabel ? customLabel : 'to News ';

        if (!to) {
            return null;
        }

        if (to === null) {
            return ''
        }

        const isMacOs = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

        let backTo = to.includes(BACK_INDICATOR) ? to
            : to.indexOf('?') > -1 ? `${to}&${BACK_INDICATOR}` : `${to}?${BACK_INDICATOR}`;

        return (
            goBack
                ? (
                    <StyledGoBack
                        data-analytics-placement="Anchor : body"
                        data-analytics-label="trackLink : anchor"
                        data-analytics-id={"backToLink : " + analyticsLabel}
                        ismacos={isMacOs}
                        id="back-button"
                        isVisuallyDisabled={isDisable}
                        onClick={this.handleBackClick}
                        appearance={Button.APPEARANCE.ICON}
                        theme={theme}
                    >
                        <StyledLinkIcon />
                        <FormattedMessage id="template.go.back" values={{
                            value: customLabel != null || customLabel !== '' ? customLabel : ''
                        }} />
                    </StyledGoBack>
                )
                : (
                    <StyledLink
                        data-analytics-placement="Anchor : body"
                        data-analytics-label="trackLink : anchor"
                        data-analytics-id={"backToLink : " + analyticsLabel}
                        ismacos={isMacOs}
                        className={className}
                        to={!isDisable ? backTo : '#'}
                        isdisable={isDisable.toString()}
                        theme={theme}
                        onClick={!isDisable ? this.handleBackClick : null}
                    >
                        <StyledLinkIcon />
                        {customLabel === null
                            && children}
                        {customLabel !== null
                            && <FormattedMessage id="template.go.back" values={{
                                value: customLabel != null || customLabel !== '' ? customLabel : ''
                            }} />}
                    </StyledLink>
                )
        );
    }
};

BackToLink.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    className: PropTypes.string,
    theme: PropTypes.oneOf([
        'light',
        'dark',
    ]),
    to: PropTypes.string,
    goBack: PropTypes.func,
    onClick: PropTypes.func,
    customLabel: PropTypes.string
};

BackToLink.defaultProps = {
    children: null,
    className: '',
    theme: 'light',
    to: '',
    goBack: null,
    onClick: null,
    isDisable: false,
    customLabel: null,
};

const mapDispatchToProps = dispatch => ({
    removeHistoryItem: () => dispatch(removeItemFromHistory()),
});

export default connect(null, mapDispatchToProps)(BackToLink);
