import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setScrollToTop as setScrollToTopAction } from 'state-management/actions/scroll';

import scrollHelper from 'utils/scrollToTop';

/**
 * Appears to be unused..
 * TODO: remove
 */
export class Scroll extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.shouldScrollToTop
            && this.props.shouldScrollToTop !== prevProps.shouldScrollToTop) {
            scrollHelper.scrollToTop();
            this.props.setScrollToTop(false);
        }
    }

    render() {
        return null;
    }
}

Scroll.propTypes = {
    shouldScrollToTop: PropTypes.bool.isRequired,
    setScrollToTop: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    shouldScrollToTop: state.scroll.shouldScrollToTop,
});

const mapDispatchToProps = dispatch => ({
    setScrollToTop: shouldSetScrollToTop => dispatch(setScrollToTopAction(shouldSetScrollToTop)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Scroll);
