import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

/**
 * Handle application components routing for non authenticated users.
 */
class GuestsOnlyRoute extends React.Component {
    /**
     * Render a single component based on the passed type.
     * @param {*} props 
     */
    renderComponent(props) {
        const { component: Component, render } = this.props;

        if (typeof render === 'function') {
            return render(props);
        }

        return (
            <Component
                {...props}
            />
        );
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const {
            component, isAuthenticated, render, ...other
        } = this.props;

        return (
            <Route
                {...other}
                render={(props) => {
                    const redirectTo = {
                        pathname: '/',
                        state: { from: props.location },
                    };

                    return (
                        isAuthenticated
                            ? <Redirect to={redirectTo} />
                            : this.renderComponent(props)
                    );
                }}
            />
        );
    }
}

GuestsOnlyRoute.propTypes = {
    component: PropTypes.func,
    isAuthenticated: PropTypes.bool.isRequired,
    render: PropTypes.func,
};

GuestsOnlyRoute.defaultProps = {
    component: null,
    render: null,
};

const mapStateToProps = state => ({
    isAuthenticated: state.signIn.isAuthenticated,
});

export default connect(mapStateToProps)(GuestsOnlyRoute);
