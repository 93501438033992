import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { intlShape, injectIntl } from 'react-intl';
import { Container } from 'components';
import { signIn } from 'state-management/actions/signIn';
import Form from 'containers/SignIn/SignInForm/SignInForm';
import { setFocusOnError } from 'state-management/actions/errors';
import { setScrollToTop } from 'state-management/actions/scroll';
import { sendForgotPasswordEmail } from 'state-management/actions/forgotPassword';
import { isEmailValid } from 'utils/validators/emailValidator';
import * as analytics from 'utils/adobeAnalytics';
import LegalFDIC from 'components/LegalFDIC/LegalFDIC';
import {
    loginSectionStyles,
    containerStyles,
    contentWrapperStyles,
} from 'containers/SignIn/styles';
import Loader from 'components/Loader/Loader';
import storage from 'utils/store';

const StyledLoginSection = styled.section`
    ${loginSectionStyles};
`;

const StyledContainer = styled(Container)`
    ${containerStyles};
`;

const StyledContentWrapper = styled.div`
    ${contentWrapperStyles}
`;

const initialValues = {
    email: '',
    keepSignedIn: false,
    password: '',
};

/**
 * Sign in page.
 */
class SignIn extends React.Component {
    /**
     * Default constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        analytics.dispatchViewStart(analytics.getSignInPageData());
    }

    /**
     * Dispatch analytics and scroll to top at mound.
     */
    componentDidMount() {
        this.props.scrollToTop();
        analytics.dispatchViewEnd(analytics.getSignInPageData());
    }

    /**
     * Focus errors when present.
     */
    componentDidUpdate() {
        if (this.props.isFocused) {
            if (this.authentication) {
                this.authentication.focus();
                this.props.focusOnError(false);
            }
        }
    }

    /**
     * Get a localized string resource by ID.
     * @param {*} id 
     */
    getTranslation(id) {
        const { intl } = this.props;

        return intl.formatMessage({
            id,
        });
    }

    /**
     * Validate sign in form values.
     */
    validate = (values) => {
        const email = values.email;
        const errors = {};

        const userNameReg = new RegExp(/^[a-z0-9]+$/i);

        storage.set('email', email)

        if (!email) {
            errors.email = this.getTranslation('validation.error.email.empty');
        } else if (email.indexOf('@') > -1 && !isEmailValid(email)) {
            errors.email = this.getTranslation('validation.error.email');
        } else if (email.indexOf('@') === -1 && !userNameReg.test(email)) {
            errors.email = this.getTranslation('validation.error.email');
        }

        if (!values.password) {
            errors.password = this.getTranslation('validation.error.password.empty');
        }

        if (Object.keys(errors).length > 0) {
            const fieldName = Object.keys(errors)[0];

            if (this[fieldName]) {
                this[fieldName].focus();
            }
        }

        return errors;
    };

    /**
     * Render this and underlying components.
     */
    render() {
        const { isLoading } = this.props;

        return (
            <React.Fragment>
                <StyledLoginSection>
                    <div data-rel="splash" />
                    <StyledContainer>
                        <StyledContentWrapper>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={(values, actions) => this.props.onSignIn(values, actions)}
                                validate={this.validate}
                                validateOnChange={false}
                                validateOnBlur={false}
                                render={formikProps => (
                                    <Form
                                        {...formikProps}
                                        authRef={(authRef) => { this.authentication = document.getElementById(authRef); }}
                                        emailRef={(email) => { this.email = document.getElementById(email); }}
                                        passwordRef={(password) => { this.password = document.getElementById(password); }}
                                        sendForgotPasswordEmail={this.props.sendForgotPasswordEmail}
                                    />
                                )}
                            />
                        </StyledContentWrapper>
                    </StyledContainer>
                </StyledLoginSection>
                <LegalFDIC />
                {isLoading ? (<Loader />) : null}
            </React.Fragment>
        );
    }
}

SignIn.propTypes = {
    onSignIn: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    isFocused: PropTypes.bool.isRequired,
    focusOnError: PropTypes.func.isRequired,
    scrollToTop: PropTypes.func.isRequired,
    sendForgotPasswordEmail: PropTypes.func.isRequired,
    history: PropTypes.shape({
        location: PropTypes.shape({
            state: PropTypes.shape(),
        }),
    }).isRequired,
    isLoading: PropTypes.bool.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            webinarID: PropTypes.string,
            eventID: PropTypes.number,
        }),
    }),
};

SignIn.defaultProps = {
    match: {
        params: {
            sectionCode: null,
            eventID: null,
        },
    },
}

const mapStateToProps = state => ({
    isFocused: state.errors.isFocused,
    isLoading: state.signIn.isLoading,
});

const mapDispatchToProps = dispatch => ({
    onSignIn: (values, actions) => dispatch(signIn(values, actions)),
    focusOnError: isFocused => dispatch(setFocusOnError(isFocused)),
    scrollToTop: () => dispatch(setScrollToTop()),
    sendForgotPasswordEmail: email => dispatch(sendForgotPasswordEmail(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SignIn));
