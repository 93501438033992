import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
    changePage as changePageAction,
} from 'state-management/actions/search';
import { CardsGrid, PagedList } from '../../../components';
import { SEARCH_PAGE_SIZE } from 'state-management/constants/search';
import { pagedListWrapperStyles } from 'containers/Search/View/styles';
import ItemView from './ItemView';

const StyledPagedListWrapper = styled.div`
    ${pagedListWrapperStyles}
`;

/**
 * Default search results wrapper used in search page list area, when NO filter by type is used.
 */
class IndexView extends React.Component {
    getPageItems = () => this.props.items
        .slice((this.props.page - 1) * SEARCH_PAGE_SIZE, this.props.page * SEARCH_PAGE_SIZE);

    renderItem = (item, idx) => {
        const { page } = this.props;

        return <ItemView item={item} idx={idx} page={page} key={item.id + '' + idx} pageItems={this.getPageItems()} />;
    };

    render() {
        const { items, changePage, page } = this.props;

        return (
            <StyledPagedListWrapper>
                {items.length > 0
                && <PagedList
                    itemsCount={items.length}
                    onPageChange={changePage}
                    page={page}
                    pageItems={this.getPageItems()}
                    pageWrapperComponent={CardsGrid}
                    pageSize={SEARCH_PAGE_SIZE}
                    renderItem={this.renderItem}
                    scrollToTop
                />}
            </StyledPagedListWrapper>
        );
    }
}

IndexView.propTypes = {
    changePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
        category: PropTypes.string,
        id: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        contentType: PropTypes.string,
        interactionType: PropTypes.string,
        interactionId: PropTypes.string,
        licensed: PropTypes.bool,
        image: PropTypes.string,
        internal: PropTypes.bool,
        link: PropTypes.string,
        publishDate: PropTypes.string,
        saved: PropTypes.bool,
        sourceLogo: PropTypes.string,
        sourceTitle: PropTypes.string,
        title: PropTypes.string,
        topics: PropTypes.array,
        type: PropTypes.string,
    })),
};

IndexView.defaultProps = {
    items: [],
};

const mapDispatchToProps = dispatch => ({
    changePage: page => dispatch(changePageAction(page)),
});

const mapStateToProps = state => ({
    page: state.search.page,
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexView);
