import React from 'react';
import MarketBriefingArchive from 'containers/MarketBriefing/componentContainers/MarketBriefingArchive';
import {
    SITE_SECTION_DJBRIEFING,
    PAGE_NAME_DJBRIEFING_ARCHIVE,
} from 'utils/analyticsConstants';


/**
 * Page container for market briefings archive page.
 */
export class MarketBriefingArchivePage extends React.Component {
    /**
     * Render this and underlying components.
     */
    render() {
        return (
            <MarketBriefingArchive />
        );
    }
}

export default MarketBriefingArchivePage;
