import {
    GET_WEBINAR_INFO_WITH_ID,
    GET_WEBINAR_INFO_WITH_ID_SUCCESS,
    GET_WEBINAR_INFO_WITH_ID_FAIL,
    GET_WEBINAR_INFO_SUCCESS,
    GET_WEBINAR_META_INFO_SUCCESS,
    GET_WEBINAR_META_INFO_FAIL,
    GET_WEBINAR_DATA,
    GET_WEBINAR_DATA_SUCCESS,
    GET_WEBINAR_DATA_FAIL,
    CLAIM_YOUR_SPOT,
    CLAIM_YOUR_SPOT_SUCCESS,
    CLAIM_YOUR_SPOT_FAIL,
    WEBINAR_IS_USER_REGISTERD_NO,
    WEBINAR_IS_USER_REGISTERD_YES,
    WEBINAR_IS_USER_REGISTERD,
    NOT_INTERESTED,
    NOT_INTERESTED_SUCCESS,
    NOT_INTERESTED_FAIL,
} from 'state-management/constants/webinar';
import {
    TOGGLE_SAVE_WEBINAR,
    TOGGLE_SAVE_WEBINAR_FAILURE,
} from 'state-management/constants/save';
import { RESET_WEBINAR_ERROR } from "../constants/webinar";

const initialState = {
    webinar: null,
    content:null,
    webinarMeta: null,
    userRegistered: false,
    requestingUserRegister: false,
    userDecision: null,
    hasErrors: false,
    webinarFailedToLoad: false,
    saved: false,
    postWebinarDataLoaded: false,
};

function webinarReducer(state = initialState, action = {}) {
    switch (action.type) {
        case TOGGLE_SAVE_WEBINAR:
            return {
                ...state,
                saved: !state.saved,
            }
        case TOGGLE_SAVE_WEBINAR_FAILURE:
            return {
                ...state,
                saved: false,
            }
        case GET_WEBINAR_INFO_SUCCESS:
            return {
                ...state,
                webinar: action.webinar,
                content: action.webinar !== null ? action.webinar.content : null,
                webinarFailedToLoad: action.webinar === null,
            };
        case GET_WEBINAR_INFO_WITH_ID:
            return {
                ...state,
                loading:true,
                webinarDataError: false,
            }
        case GET_WEBINAR_INFO_WITH_ID_SUCCESS:
            return {
                ...state,
                webinar: action.webinar,
                content: action.webinar !== null ? action.webinar.content : null,
                webinarFailedToLoad: action.webinar === null,
                postWebinarDataLoaded: true,
                loading:false,
                webinarDataError: action.webinar !== null ? false : true,
            };
        case GET_WEBINAR_INFO_WITH_ID_FAIL:
            return {
            ...state,
            postWebinarDataLoaded: true,
            webinarDataError: true,
            loading:false,
            };
        case GET_WEBINAR_META_INFO_SUCCESS:
            return {
                ...state,
                webinarMeta: action.webinar,
            }
        case GET_WEBINAR_DATA:
            return {
                ...state,
                webinar: action.webinar,
            };
        case GET_WEBINAR_DATA_SUCCESS:
            return {
                ...state,
                webinar: action.webinar,
            };
        case GET_WEBINAR_DATA_FAIL:
            return {
                ...state,
                webinar: action.webinar,
            };
        case CLAIM_YOUR_SPOT:
            return {
                ...state,
            };
        case CLAIM_YOUR_SPOT_SUCCESS:
            return {
                ...state,
                userRegistered: true,
            };
        case RESET_WEBINAR_ERROR:
            return {
                hasErrors: false,
                userRegistered: true,
                requestingUserRegister: false
            }
        case CLAIM_YOUR_SPOT_FAIL:
            return {
                ...state,
                userRegistered: false,
                hasErrors: action.data
            };
        case WEBINAR_IS_USER_REGISTERD:
            return {
                ...state,
                requestingUserRegister: true,
            }
        case WEBINAR_IS_USER_REGISTERD_YES:
            return {
                ...state,
                userRegistered: true,
                requestingUserRegister: false,
            };
        case WEBINAR_IS_USER_REGISTERD_NO:
            return {
                ...state,
                userRegistered: false,
                requestingUserRegister: false,
            };
        case NOT_INTERESTED:
            return {
                ...state,
            };
        case NOT_INTERESTED_SUCCESS:
            return {
                ...state,
                webinar:{
                    ...state.webinar,
                    decision: action.decision
                }
            };
        case NOT_INTERESTED_FAIL:
            return {
                ...state,
                userDecision: 'ND'
            };
        default:
            return state;
    }
}

export default webinarReducer;
