export const NEWS_ROUTE = '/news';
export const HEADLINES_ROUTE = 'news/top';
export const HEADLINES_TYPE = 'top';
export const TRENDING = 'trending';
export const IDEAS_COLLECTION = 'ideas/collections';
export const IDEAS_GUIDES = 'ideas/guides';
export const IDEAS_TOOLS = 'ideas/tools';
export const SOURCE = '/sources/';
export const TOPICS = '/topics/';
export const CAPITAL_IDEAS_ROUTE = 'news/capital-ideas';
export const CAPITAL_IDEAS_TYPE = 'capital-ideas';
export const TRENDING_TOPIC_ROUTE = 'news/trending-topic';
export const TRENDING_TOPIC_TYPE = 'trending-topic';
export const MARKET_NEWS_ROUTE = 'news/market-news';
export const MARKET_NEWS_TYPE = 'market-news';

export const NEWS_BY_AUM_ROUTE = 'news/aum';
export const NEWS_BY_AUM_TYPE = 'aum';
export const NEWS_BY_DURATION_ROUTE = 'news/experience';
export const NEWS_BY_DURATION_TYPE = 'experience';
export const NEWS_BY_SERVICE_OFFERINGS_ROUTE = 'news/offerings';
export const NEWS_BY_SERVICE_OFFERINGS_TYPE = 'offerings';

export const NEWS_URL = '/content-service/news';
export const LATEST_CAPITAL_IDEAS_URL = '/content-service/news/capital-ideas';
export const HEADLINES_URL = '/content-service/news/see-more';

export const GET_NEWS = 'news/GET_NEWS';
export const RESET_NEWS = 'news/RESET_NEWS';
export const ARTICLES_IN_PAGE = 18;

export const SET_SERVICE_OFFERING_ID = 'news/SET_SERVICE_OFFERING_ID';
export const SET_RANDOM_SERVICE_OFFERING = 'news/SET_RANDOM_SERVICE_OFFERING';

export const GET_TOP_NEWS = 'news/GET_TOP_NEWS';
export const GET_TOP_NEWS_FAIL = 'news/GET_TOP_NEWS_FAIL';
export const GET_TOP_NEWS_SUCCESS = 'news/GET_TOP_NEWS_SUCCESS';

export const GET_MARKET_NEWS = 'news/GET_MARKET_NEWS';
export const GET_MARKET_NEWS_FAIL = 'news/GET_MARKET_NEWS_FAIL';
export const GET_MARKET_NEWS_SUCCESS = 'news/GET_MARKET_NEWS_SUCCESS';

export const GET_PAGEABLE_NEWS = 'news/GET_PAGEABLE_NEWS';
export const GET_PAGEABLE_NEWS_FAIL = 'news/GET_PAGEABLE_NEWS_FAIL';
export const GET_PAGEABLE_NEWS_SUCCESS = 'news/GET_PAGEABLE_NEWS_SUCCESS';

export const GET_LATEST_CAPITAL_IDEAS = 'news/GET_LATEST_CAPITAL_IDEAS';
export const GET_LATEST_CAPITAL_IDEAS_FAIL = 'news/GET_LATEST_CAPITAL_IDEAS_FAIL';
export const GET_LATEST_CAPITAL_IDEAS_SUCCESS = 'news/GET_LATEST_CAPITAL_IDEAS_SUCCESS';

export const INDICES_URL = '/content-service/market-indices';
export const GET_MARKET_INDICES = 'news/GET_MARKET_INDICES';
export const GET_MARKET_INDICES_SUCCESS = 'news/GET_MARKET_INDICES_SUCCESS';
export const GET_MARKET_INDICES_FAIL = 'news/GET_MARKET_INDICES_FAIL';

export const AUM = 'aumId';
export const DURATION = 'durationId';
export const SERVICE_OFFERING = 'serviceOfferingId';

export const GET_TOP_NEWS_ITEMS = 'getTopNewsitem';
export const GET_TOP_NEWS_ITEMS_SUCCESS = 'getTopNewsitemSuccess';
export const GET_TOP_NEWS_ITEMS_FAIL = 'getTopNewsitemFail';

export const TOP_NEWS_URL = '/content-service/news?n=16';


