import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'components/Link/Link';
import { KEY_ENTER, KEY_SPACE } from 'utils/keyCodes';
import { APPEARANCE, SIZES, TYPES } from 'components/Button/constants';
import { buttonStyles, linkStyles } from './../../components/Button/styles';

const StyledButton = styled.button`
    ${buttonStyles}
`;

const StyledLink = styled(Link)`
    ${linkStyles}
`;

const StyledBasicLink = styled.a`
    ${linkStyles}
`;

const Button = (props, context) => {
    const {
        appearance,
        children,
        href,
        basic,
        isVisuallyDisabled = false,
        size,
        sizeOnDesktop,
        sizeOnMobile,
        sizeOnTablet,
        onClick,
        type,
        ...other
    } = props;

    const variant = {
        appearance,
        size,
        sizeOnDesktop,
        sizeOnMobile,
        sizeOnTablet,
    };

    const handleLinkKeyDown = (e) => {
        if (e.keyCode === KEY_ENTER || e.keyCode === KEY_SPACE) {
            // Not checking if context contains router
            // because Link cannot be used outside of Router
            context.router.history.push(href);
        }
    };

    if (basic) {
        return (
            <StyledBasicLink
                {...other}
                href={href}
                onKeyDown={handleLinkKeyDown}
                variant={variant}
            >
                {children}
            </StyledBasicLink>
        );
    }

    return (
        href !== null ? (
            <StyledLink
                {...other}
                to={href}
                onKeyDown={handleLinkKeyDown}
                variant={variant}
            >
                {children}
            </StyledLink>
        ) : (
            <StyledButton
                {...other}
                type={type}
                disabled={isVisuallyDisabled}
                variant={variant}
                onMouseDown={onClick}
            >
                {children}
            </StyledButton>
        ));
};

Button.propTypes = {
    appearance: PropTypes.oneOf(Object.values(APPEARANCE)),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    href: PropTypes.string,
    id: PropTypes.string,
    isVisuallyDisabled: PropTypes.bool,
    size: PropTypes.oneOf(Object.values(SIZES)),
    sizeOnDesktop: PropTypes.oneOf(Object.values(SIZES)),
    sizeOnMobile: PropTypes.oneOf(Object.values(SIZES)),
    sizeOnTablet: PropTypes.oneOf(Object.values(SIZES)),
    type: PropTypes.oneOf(Object.values(TYPES)),
    basic: PropTypes.bool,
    onClick: PropTypes.func,
};

Button.contextTypes = {
    router: PropTypes.shape({
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }).isRequired,
    }),
};

Button.defaultProps = {
    appearance: APPEARANCE.DEFAULT,
    href: null,
    isVisuallyDisabled: false,
    size: SIZES.MEDIUM,
    sizeOnDesktop: null,
    sizeOnMobile: null,
    sizeOnTablet: null,
    type: TYPES.BUTTON,
    basic: false,
};

Button.APPEARANCE = APPEARANCE;
Button.SIZES = SIZES;
Button.TYPES = TYPES;

export default Button;
