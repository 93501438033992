import { css } from 'styled-components';
import {
    FROM_TABLET,
    FROM_DESKTOP,
} from '../../../utils/mediaQueries';
import {
    COLORS,
    FONT_AVENIR_NEXT,
    ANIMATIONS,
} from '../../../utils/variables';

export const styledBackToTopButton = css`
    background: ${COLORS.WHITE};
    border: 1px solid ${COLORS.LIGHT_GRAY2};
    border-radius: 23px;
    bottom: 29px;
    cursor: pointer;
    height: 47px;
    overflow: hidden;
    padding: 0;
    position: fixed;
    right: 26px;
    width: 47px;
    z-index: 300;
    color: ${COLORS.SAPPHIRE};
    outline: none;

    &:hover{
        color: ${COLORS.DARK_SAPPHIRE};
    }

    svg {
        position: relative;
        top: -2px;
        transform: rotate(180deg);
    }

    @media screen and ${FROM_TABLET} {
        bottom: 75px;
        right: 134px;
    }
`;

export const placeholderStyles = css`
    min-width: 204px;
    padding-left: 0 !important;

    a {
        display: none;
    }

    ${props => props.isSticky && css`
        a {
            display: block;
            left: 118px;
            position: fixed;
            top: 120px;
        }

        @media screen and ${FROM_DESKTOP} {
            a {
                left: 128px;
            }
        }
    `};
`;

export const displayBackgroundStyles = css`
    background-image: linear-gradient(${COLORS.LIGHT_GRAY2} 0, ${COLORS.LIGHT_GRAY1} 60px);
    padding-top: 40px;

    @media screen and ${FROM_TABLET} {
        background-image: linear-gradient(${COLORS.LIGHT_GRAY2} 0, ${COLORS.LIGHT_GRAY1} 120px);
        padding-top: 80px;
    }
`;

export const ctaStyles = css`
    border: 2px solid ${COLORS.SAPPHIRE};
    color: ${COLORS.SAPPHIRE};
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    padding: 16px 24px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.4s all;
    white-space: nowrap;
    transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;

    @media (hover) {
        &:hover {
            color: ${COLORS.DARK_SAPPHIRE};
            border: 2px solid ${COLORS.DARK_SAPPHIRE};
        }
    }
`;

export const displayStyles = css`
    display: block;

    div:first-child {
        display: none;
    }

    div:last-child {
        padding-left: 0;
        width: auto;
    }

    @media screen and ${FROM_TABLET} {
        display: flex;

        div:first-child {
            display: block;
            width: 25%;
        }

        div:last-child {
            padding-left: 80px;
            width: 75%;
        }
    }
`;

export const headerStyles = css`
    background: ${COLORS.LIGHT_GRAY1};
    margin-bottom: 20px;
    padding-bottom: 30px;

    p {
        font-size: 12px;
        margin: 0;

        svg {
            position: relative;
            left: -4px;
        }
    }

    a:last-child {
        margin-top: 36px;
    }

    @media screen and ${FROM_TABLET} {
        margin-bottom: 50px;
        text-align: left;

        p {
            font-size: 14px;

            svg {
                left: -8px;
            }
        }
    }
`;

export const outerContainerStyles = css`
    overflow: hidden;

    .mobile-hide {
        display: none !important;
    }

    table {
        background: transparent !important;
        font-family: ${FONT_AVENIR_NEXT} !important;
        max-width: 100% !important;
    }

    td {
        background: transparent !important;
        color: ${COLORS.DARK_GRAY2} !important;
        font-family: ${FONT_AVENIR_NEXT} !important;
        padding: 0 !important;
    }

    #dj-disclaimer td {
        padding-top: 30px !important;
    }

    #dj-header,
    #dj-footer {
        display: none;
    }

    a {
        color: ${COLORS.SAPPHIRE} !important;
        font-weight: bold !important;
        transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;

        &:hover {
            color: ${COLORS.DARK_SAPPHIRE} !important;
        }
    }

    p:empty {
        display: none;
    }

    .dj-footnote {
        color: ${COLORS.DARK_GRAY1} !important;
        font-size: 12px !important;
        padding-bottom: 20px !important;

        p {
            font-size: 12px !important;
        }
    }

    .mobile-padding {
        padding-left: 0 !important;
        padding-right: 0 !important;s
    }

    .dj-header {
        color: ${COLORS.DARK_GRAY2} !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        padding-bottom: 20px !important;
        padding-top: 20px !important;
        text-align: left !important;
    }

    .responsive-table {
        border-top: none !important;
    }

    hr {
        display: none !important;
    }

    #dj-the-numbers {
        border-top: none !important;
    }

    #dj-market-wraps,
    #dj-headlines,
    #dj-talking-points,
    #dj-wealth-management,
    #dj-calendar,
    #dj-earnings,
    #dj-disclaimer {
        border-top: 1px solid ${COLORS.LIGHT_GRAY3} !important;
    }

    #dj-the-numbers .dj-header {
        padding-top: 0 !important;
    }

    p,
    strong,
    #dj-disclaimer td {
        font-size: 14px !important;
        line-height: 20px !important;
    }

    .dj-areahead {
        font-weight: 500;
    }

    #dj-the-numbers strong {
        font-weight: normal;
    }

    @media screen and ${FROM_TABLET} {
        p,
        strong,
        #dj-disclaimer td,
        p span {
            font-size: 18px !important;
            line-height: 24px !important;
        }

        .dj-header {
            font-size: 24px !important;
        }
    }
`;
