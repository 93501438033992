import { css } from 'styled-components';
import {
    FROM_DESKTOP,
    FROM_MOBILE_TO_TABLET,
    FROM_TABLET,
    FROM_TABLET_TO_DESKTOP,
} from 'utils/mediaQueries';
import {
    FONT_AVENIR_NEXT,
    COLORS,
    FONTS,
    RGB_COLORS,
    ANIMATIONS,
} from 'utils/variables';

export const backToPlaceholder = css`
    max-height: 15px !important;
    height: 15px !important;
`;

export const errorMessageStyles = css`
    display: block;
    color: ${COLORS.ALERT_RED};
    font-size: 15px;
    line-height: 20px;
    padding-bottom: 14px;

    @media screen and ${FROM_DESKTOP} {
        display: inline-block;
        padding-right: 30px;
        padding-top: 0;
        padding-bottom: 0;
        max-width: 400px;

        ${props => props.errorType === 3 && css`
            position: relative;
            top: 10px;
            margin-top: -10px;
        `}
    }
`;

export const profileSectionStyles = css`
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    min-height: 783px;

    * {
        outline-color: ${COLORS.OCEAN};
    }

    .savesStyles {
        nav {
            display: none;

            @media screen and ${FROM_DESKTOP} {
                display: block;
            }
        }
    }

    ${props => props.isPracticeInfo && css`

        @media screen and ${FROM_DESKTOP} {
            margin-bottom: 0px !important;
        }
    `}

    .fixedBar {
        width: 100%;
        z-index: 1;
        /*position: fixed;*/
        bottom: 0;
    }

    .emptyDiv {
        @media screen and ${FROM_TABLET_TO_DESKTOP} {
            height: 0 !important;
        }
    }
`;

export const containerStyles = css`
    display: flex;
    flex-direction: column;

    
   
       
   ${props => props.newContainer == 'practiceRightContainer' && css`
        padding-left: 0;
        @media screen and ${FROM_DESKTOP} {           
            padding-left: 54px;            
        }
    `};

    @media screen and (min-width: 1024px) {
        max-width: 1280px;
        margin: 0 auto;
        padding-left: 54px;
        padding-right: 70px;
   }


   @media screen and (min-width: 1340px){
        padding-right: 70px;
   }

    @media screen and (min-width: 2525px){
        margin-bottom: 117px;
    }

   @media screen and (min-width: 3789px){
        margin-bottom: 500px;
    }

    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) 
    and (-webkit-min-device-pixel-ratio: 2){
        margin-bottom: 200px;
    }

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) { 
        margin-bottom: 10px;
    }

    

`;

export const closeIconStyles = css`
    height: 18px;
    width: 18px;
`;

export const divContainerStyles = css`
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    font-family: ${FONTS.AVENIR_NEXT.FONT};

    &.accountsTab {
        display: none;

        @media screen and ${FROM_DESKTOP} {
            width: 243px;
            display: inline-block;
            float: left;
        }
    }


    nav {
        display: block;
        width: 100%;
        float: none;

        @media screen and ${FROM_DESKTOP} {
            display: inline-block;
            width: 243px;
            float: left;
        }
    }

    .Saves__StyledIntro-bRbzu {
        display: none;
    }

    .Saves__StyledSavedContent-bpoHLv {
        margin: 23px 0 30px;

        @media screen and ${FROM_DESKTOP} {
            margin: 96px 0 30px;
        }
    }
`;

export const divContainerSavesStyles = css`
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    display: flex;

    div[data-rel="profile-form"] {
        flex: 1;
    }

    .Container__StyledContainer-cWlgSi{
        background: transparent !important;
    }

    &.accountsTab{
        display: none;
            &.show{
                display: block;
            }

        @media screen and ${FROM_DESKTOP} {
            width: 243px;
            display: inline-block;
            float: left;
        }
    }

    nav {
        display: block;
        width: 100%;
        float: none;

        @media screen and ${FROM_DESKTOP} {
            display: inline-block;
            width: 243px;
            min-width: 243px;
            float: left;
        }
    }

    .Saves__StyledIntro-bRbzu {
        display: none;
    }

    .Saves__StyledSavedContent-bpoHLv{
        margin: 23px 0 30px;

        @media screen and ${FROM_DESKTOP} {
            margin: 96px 0 30px;
        }
    }

`;

export const popupStyles = css`
    background-color: ${COLORS.WHITE};
    box-sizing: border-box;
    margin: 0 16px;
    max-height: 90%;
    max-width: 683px;
    min-width: 280px;
    overflow: hidden;
    padding: 15px;

    @media screen and ${FROM_TABLET} {
        margin: 0 45px;
        padding: 20px;
        width: 100%;
    }
`;

export const popupTitleStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.1px;
    line-height: 1;
    margin: 5px 0 10px;
    padding: 0 10px;
    text-align: center;

    @media screen and ${FROM_TABLET} {
        font-size: 24px;
        letter-spacing: -0.2px;
        margin: 20px 0 10px;
        padding: 0 24px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 24px;
        letter-spacing: -0.3px;
        margin: 20px 0 15px;
    }
`;

export const popupContentStyles = css`
    margin-bottom: 16px;

    @media screen and ${FROM_TABLET} {
        margin-bottom: 26px;
        padding: 0 24px;
    }
`;

export const confirmationTextStyles = css`
    color: ${COLORS.BLACK};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 15px;
    font-weight: normal;
    letter-spacing: -0.1px;
    margin: 0;
    padding: 0 10px;
    text-align: center;
`;

export const popupActionsContainerStyles = css`

    display: none;
    justify-content: flex-end;
`;

export const buttonsContainerStyles = css`
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    margin-bottom: 10px;
    padding: 0 10px;

    @media screen and ${FROM_TABLET} {
        margin-bottom: 24px;
        flex-direction: row;
    }

    ${props => props.type === "new" && css`
        a:first-child {
            margin-top: 12px;
        }

        @media screen and ${FROM_TABLET} {
            a:first-child {
                margin-right: 24px;
                margin-top: 0;
            }
        }
    `}
`;

export const cancelPopupButtonStyles = css`
    
    width: 100%;
    margin: 10px 0;
    border-color: ${COLORS.OCEAN};
    color: ${COLORS.OCEAN};
    transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;
    letter-spacing: 0;

    @media screen and ${FROM_MOBILE_TO_TABLET} {
        padding-left: 10px;
        padding-right: 10px;
    }

    @media screen and ${FROM_TABLET} {
        margin-right: 24px;
        width: 240px;
    }

    &:hover {
        border-color: ${COLORS.SAPPHIRE} !important;
        color: ${COLORS.SAPPHIRE};
    }
`;

export const savePopupButtonStyles = css`
    transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;
    width: 100%;
    letter-spacing: 0;

    @media screen and ${FROM_TABLET} {
        width: 240px;
    }

    &:hover {
        border-color: ${COLORS.SAPPHIRE} !important;
        background: ${COLORS.SAPPHIRE} !important;
    }
`;

export const buttonStyles = css`
    min-width: 170px;
    margin-bottom: 10px;

    @media screen and ${FROM_MOBILE_TO_TABLET} {
        height: 40px;
    }
`;

export const actionsBarContainerStyles = css`
    align-items: center;
    background: ${COLORS.LIGHT_GRAY1};
    box-shadow: 0 -3px 34px 0 rgba(${RGB_COLORS.BLACK}, 0.1);
    box-sizing: border-box;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    width: 100%;
`;

export const buttonsWrapperStyles = css`
    text-align: center;
    width: 100%;

    a[type="type/secondary"] {
        margin-right: 8px;
    }

    @media screen and ${FROM_DESKTOP} {
        text-align: right;
    }
`;

export const saveButtonStyles = css`
    font-size: 12px !important;
    transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;
    padding: 15px 26px !important;
    font-weight: 700 !important;
    letter-spacing: 1.5px !important;
    border: none !important;
    height: auto;
    text-transform: uppercase;
    background-color: ${COLORS.SAPPHIRE};
    color: ${COLORS.WHITE};
    min-width: 112px;
    
    &:hover:not([disabled]) {
        background: ${COLORS.DARK_SAPPHIRE} !important;
    }
`;

export const fieldStyles = css`
    padding: 10px 0;

    @media screen and ${FROM_TABLET} {
        padding: 20px 0;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 25px 0;
    }
`;


export const practiceContainerStyles = css`
    margin: 0 0 30px;
    display: block;
    width: 100%;
    float: left;

    @media screen and ${FROM_TABLET_TO_DESKTOP} {
        margin: 0 0 68px 0 !important;
    }

    @media screen and ${FROM_DESKTOP} {
        width: calc(100% - 245px);
        margin: 88px 0 30px 0 !important;
        display: inline-block;
        h1 {
            padding-left: 54px;
        }

    }

    .ProfileForm__StyledContainer-fgJuIK{
        padding-left: 54px;
        padding-right: 54px;

    }

    .EditProfileForm__StyledRowKey-gATZSr{
        display: none;
    }

    .EditProfileForm__StyledRowValue-gLWgbh{
        padding-left: 0;
        padding-top: 20px;
    }

    .EditProfileForm__StyledServicesOfferedListItem-eiKqSM{
        width: 100%;

        @media screen and ${FROM_TABLET} {
            width: 49.598291%;
        }
    }

    .EditProfileForm__StyledServicesOfferedList-kvthqv{
        padding: 0;
    }

    .FieldText__StyledLabel-MxipH {
        font-size: 12px;
    }

    .Container__StyledContainer-cWlgSi {
        padding-left: 0;
        padding-right: 0;

        @media screen and ${FROM_TABLET} {
            padding-left: 0px;
            padding-right: 24px;
        }

        @media screen and ${FROM_DESKTOP} {
            padding-left: 54px;
        }
    }

    .EditProfileForm__StyledRowValue-gLWgbh {
        width: 100%;
    }

    .EditProfileForm__StyledInfoRow-foUITm {
        padding: 0 0 24px;
    }

    .EditProfileForm__StyledRowValue-gLWgbh {
        padding-top: 0;
    }

    .FieldText__StyledInput-bDQjQU {
        border-bottom: 2px solid ${COLORS.SAPPHIRE};
    }

    .Dropdown__StyledDropdownValue-jVztbA{
        font-size: 15px;
    }

    .EditProfileForm__StyledForm-cTHyRQ .EditProfileForm__StyledInfoRow-foUITm:first-child{
        margin-bottom: 42px;
        width: 100%;

        @media screen and ${FROM_DESKTOP} {
            margin-bottom: 66px;
        }

    }

    .BackToLink__StyledGoBack-dgbtcB{
        
            padding-left: 0;
       
            @media screen and ${FROM_DESKTOP} {
                margin-bottom: 66px;
            }
    }
`;

export const practiceHeadingStyles = css`
    font-size: 15px;
    font-weight: 500;  
    margin-bottom: 23px;
    height: 20px;  
    height: 45px;
    margin-top: 53px;

    @media screen and ${FROM_TABLET} {
        margin-bottom: 0;
        height: 35px;
        padding: 0 54px;
        margin-top: 46px;
    }

    @media screen and ${FROM_DESKTOP} {
        display: block;
        font-size: 20px;
        padding-left: 54px;
        padding-right: 54px;
        margin-bottom: 0;
        margin-top: 0;
        height: auto;
    }
`;

export const practiceSubHeadingStyles = css`
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 26px;
`;

export const styledBack = css`
    @media screen and ${FROM_DESKTOP} {
        display: none;
    }
`;

export const elementDisplayStyles = css`
    .ActionsBar__StyledButtonsWrapper-edLcKS {
        text-align: right;
    }

    .ActionsBar__StyledCancelButton-bRLdhE {
        @media screen and ${FROM_MOBILE_TO_TABLET} {
            width: 45%;
            margin-right:28px;
        }
    }

    .ActionsBar__StyledSaveButton-jbscYw {
        background-color: ${COLORS.SAPPHIRE};

        &:hover {
            background-color: ${COLORS.DARK_SAPPHIRE};
        }

        @media screen and ${FROM_MOBILE_TO_TABLET} {
            width: 45%;
        }
    }
`;

export const editButtonStyles = css`
    background-color: transparent;
    border: 0;
    color: ${COLORS.SAPPHIRE};
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: -0.1px;
    line-height: 0.93;
    padding: 5px;
    word-wrap: break-word;
    text-transform: uppercase;
    margin-left: 15px;
    transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;

    @media screen and ${FROM_TABLET} {
        font-size: 12px;
        line-height: 0.78;
    }
    
    &:hover {
        color: ${COLORS.DARK_SAPPHIRE};
    }

    /* stylelint-disable */
    ${({ isDisabled }) => isDisabled && css`
        color: ${COLORS.LIGHT_GRAY3};
        visibility: hidden;
    `}
    /* stylelint-enable */
`;

export const subtitleStyles = css`
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    color: ${COLORS.BLACK};
    font-size: 20px;
    font-weight: 600;
    line-height: 33px;
    margin-top: 76.5px;
    @media screen and ${FROM_TABLET} {
        margin-top: 87.5px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 26px;
        line-height: 24px;
        letter-spacing: -0.22px;
        margin-top: 35px;
    }
`;