import {
    GET_DISCLAIMER_FRAGMENT,
    GET_DISCLAIMER_FRAGMENT_FAIL,
    GET_DISCLAIMER_FRAGMENT_SUCCESS,
    SET_DISCLAIMER_TYPE,
} from 'state-management/constants/globalDisclaimer';

export const getDisclaimerFragment = disclaimerType => ({
    disclaimerType,
    type: GET_DISCLAIMER_FRAGMENT,
});

export const getDisclaimerFragmentSuccess = (disclaimerType, fragment) => ({
    disclaimerType,
    fragment,
    type: GET_DISCLAIMER_FRAGMENT_SUCCESS,
});

export const getDisclaimerFragmentFailure = (disclaimerType, error) => ({
    disclaimerType,
    error,
    type: GET_DISCLAIMER_FRAGMENT_FAIL,
});

export const setDisclaimerType = disclaimerType => ({
    disclaimerType,
    type: SET_DISCLAIMER_TYPE,
});
