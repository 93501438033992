import {
    GET_ALLTOPICS_SUCCESS,
    GET_TOP_ALLTOPICS_SUCCESS,
    GET_TRENDING_ALLTOPICS_FAIL,
    GET_TRENDING_ALLTOPICS_SUCCESS,
    TOGGLE_SAVE_TRENDING_ALLTOPICS,
    GET_TRENDING_ALLTOPICS,
} from '../../state-management/constants/allTopics.common';
import { TOGGLE_SAVE_TRENDING_TOPIC } from "../constants/allTopics.common";
import * as saveTypes from 'state-management/constants/save';
import { TT_ARTICLE_WAS_VISITED } from 'state-management/constants/articles.common';

const initialState = {
    all: {
        categories: [],
    },
    top: [],
    trending: [],
    isLoading: false,
    loadingFailed: false,
};

function updateTrandingTopicData({ trending }, save, topicId, id) {
    return trending.map((item) => {
        const { newsItems, topic } = item;
        if (topic.id === topicId) {
            return topic.id === topicId
                ? {
                    ...item,
                    newsItems: newsItems.map((news) => {
                        return {
                            ...news,
                            saved: news.id === id ? save : news.saved
                        }
                    })
                }
                : item;
        } else {
            return item;
        }
    })
}

function allTopicsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_ALLTOPICS_SUCCESS:
            return {
                ...state,
                all: {
                    categories: action.data,
                },
            };
        case GET_TOP_ALLTOPICS_SUCCESS:
            return {
                ...state,
                top: action.data,
            };
        case TOGGLE_SAVE_TRENDING_TOPIC:
            const { id, article, save } = action;
            const { topicId } = article;
            return {
                ...state,
                trending: updateTrandingTopicData(state, save, topicId, id),
            };
        case GET_TRENDING_ALLTOPICS:
            return {
                ...state,
                isLoading: true,
                loadingFailed: false,
            };
        case GET_TRENDING_ALLTOPICS_SUCCESS:
            return {
                ...state,
                trending: action.data,
                isLoading: false,
                loadingFailed: false,
            };
        case GET_TRENDING_ALLTOPICS_FAIL:
            return {
                ...state,
                isLoading: false,
                loadingFailed: true,
            };
        case saveTypes.TOGGLE_SAVE_ARTICLE_SUCCESS: {
            const { id, save } = action;

            const iterateNewsItems = (item) => {
                return item.id === id ? { ...item, saved: save }
                    : item;
            };

            const iterateTopics = (topic) => {
                const topicItem = topic;

                topicItem.newsItems = topicItem.newsItems.map(iterateNewsItems);

                return topicItem;
            };

            const trending = state.trending.map(iterateTopics);

            return {
                ...state,
                trending,
            };
        }
        case TOGGLE_SAVE_TRENDING_ALLTOPICS: {
            const { articleId, isSaved, topicId } = action;
            const topic = state.trending.filter(topicItem => (topicItem.topic.id === topicId));
            const newsItems = topic[0].newsItems.map(article => (article.id === articleId
                ? { ...article, saved: isSaved } : article));
            topic[0].newsItems = newsItems;
            const trending = state.trending.map(topicItem => (
                topicItem.topic.id === topicId ? topic[0] : topicItem));

            return {
                ...state,
                trending,
            };
        }
        case TT_ARTICLE_WAS_VISITED: {
            const { id } = action;
            const trendingArry = [];
            state.trending.forEach(item => {
                const newsItm = item.newsItems.map(article => (article.id === id
                    ? { ...article, visited: true } : article));
                item.newsItems = newsItm;
                trendingArry.push(item);
            })
            return {
                ...state,
                trending: trendingArry,
            };
        }

        default:
            return state;
    }
}

export default allTopicsReducer;
