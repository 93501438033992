import { css } from 'styled-components';
import { getSpanInPixels } from 'utils/grid';
import { visuallyHiddenStyles } from 'components/VisuallyHidden/styles';
import { outerFocusMixin } from 'components/FocusVisibility/styles';
import { FROM_DESKTOP, FROM_MOBILE_TO_TABLET, FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS, RGB_COLORS } from 'utils/variables';

export const sectionStyles = css`
    background-image: linear-gradient(to bottom, rgba(${RGB_COLORS.LIGHT_GRAY2}, 0.5), rgba(${RGB_COLORS.LIGHT_GRAY1}, 0));
`;

export const outerContainerStyles = css`
    display: flex;
    position: relative;
    width: 100%;

    /* stylelint-disable */

    ${props => props.variant && !props.variant.isSticky && css`
        &::before {
            width: 80px;
            content: '';
            flex: 1 1 auto;
            height: 4px;
            transform: translateY(16px);
            margin-left: -40px;
            position: relative;
            top: 3px;
        }

        &::after {
            content: '';
            flex: 1 1 auto;
        }

        @media screen and ${FROM_DESKTOP} {
            &::before {
                margin-right: -${getSpanInPixels(1)};
                padding-right: ${getSpanInPixels(1)};
                transform: translateY(76px);
                background-image: linear-gradient(to left, ${COLORS.OCEAN}, ${COLORS.SAPPHIRE} 40px, ${COLORS.SAPPHIRE} 60px, transparent 80px);
            }
        }
    `};

    ${props => props.variant && props.variant.isSticky && css`
        background: ${COLORS.WHITE};
        box-shadow: 0 11px 20px 0 rgba(${RGB_COLORS.BLACK}, 0.05);
        left: 0;
        max-width: 100%;
        min-height: auto;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 5;
    `};

    /* stylelint-enable */
`;

export const innerContainerStyles = css`
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    width: 100%;

    @media screen and ${FROM_DESKTOP} {
        padding-left: 0;
        padding-right: 0;
    }

    /* stylelint-disable */

    ${props => props.variant && !props.variant.isSticky && css`
         min-height: 100px;
    `};
    
    ${props => props.variant && props.variant.isSticky && css`
         max-width: 1440px;
    `};

    /* stylelint-enable */
`;

export const triggerStyles = css`
    background-color: transparent;
    border: 1px solid transparent;
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 14px;
    font-weight: 700;
    height: 46px;
    padding: 0 48px;
    position: relative;
    text-align: center;
    width: 100%;

    /* stylelint-disable */

    ${props => props.variant && !props.variant.isSticky && css`
        display: none;
    `};

    ${props => props.variant && props.variant.isExpanded && css`
        padding: 0 24px;
        position: absolute;
        right: 16px;
        width: auto;
    `};

    /* stylelint-enable */

    @media screen and ${FROM_TABLET} {
        display: none;
    }
`;

export const triggerTextStyles = css`
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    /* stylelint-disable */

    ${props => props.variant && props.variant.isExpanded && css`
        ${visuallyHiddenStyles};
    `};

    /* stylelint-enable */
`;

export const triggerIconStyles = css`
    fill: ${COLORS.DARK_GRAY2};
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);

    /* stylelint-disable */

    ${props => props.variant && props.variant.isExpanded && css`
        transform: translateY(-50%) rotate(180deg);
    `};

    /* stylelint-enable */
`;

export const contentWrapperStyles = css`
    /* stylelint-disable */

    ${props => props.variant && !props.variant.isSticky && css`
        margin: 64px 0 56px 74px;
        padding-left: 30px;

        @media screen and ${FROM_TABLET} {
            margin: 64px 0 56px;
        }

        @media screen and ${FROM_DESKTOP} {
            margin: 64px 0 56px 74px;
        }
    `};

    /* stylelint-enable */

    @media screen and ${FROM_TABLET} {
        padding-left: 0;
    }
`;

export const titleContainerStyles = css`
    /* stylelint-disable */

    ${props => props.variant && props.variant.isSticky && css`
        display: none;
    `};

    &::before {
        background-image: linear-gradient(89deg, ${COLORS.SAPPHIRE}, ${COLORS.OCEAN});
        content: '';
        height: 2px;
        left: -35px;
        position: absolute;
        transform: translateY(14px);
        width: 138px;
    }

    /* stylelint-enable */

    @media screen and ${FROM_TABLET} {
        margin: 0 0 0 87px;

        &::before {
            margin-left: -38px;
            height: 4px;
            transform: translateY(16px);
            width: 150px;
        }
    }

    @media screen and ${FROM_DESKTOP} {
        margin: 0 auto;

        &::before {
            display: none;
        }
    }
`;

export const titleStyles = css`
    color: ${COLORS.SAPPHIRE};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 30px;
    font-weight: 400;
    letter-spacing: -0.2px;
    line-height: 1.12;
    margin: 0;
    position: relative;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        font-size: 36px;
    }
`;

export const linksContainerStyles = css`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 40px 0 0 0;
    padding: 0;

    /* stylelint-disable */

    ${props => props.variant && props.variant.isSticky && css`
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        min-height: 74px;
        padding: 18px 48px;
        width: 100%;
    `};

    @media screen and ${FROM_MOBILE_TO_TABLET} {
        ${props => props.variant && props.variant.isSticky && !props.variant.isExpanded && css`
            display: none;
        `};
    }

    /* stylelint-enable */

    @media screen and ${FROM_TABLET} {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 42px 0 0;

        /* stylelint-disable */

        ${props => props.variant && props.variant.isSticky && css`
            justify-content: center;
            margin: 0 auto;
            min-height: 74px;
        `};

        /* stylelint-enable */
    }

    @media screen and ${FROM_DESKTOP} {
        margin: 80px 0 0;

        /* stylelint-disable */

        ${props => props.variant && props.variant.isSticky && css`
            margin: 0 auto;
            min-height: 74px;
        `};

        /* stylelint-enable */
    }
`;

export const itemStyles = css`
    box-sizing: border-box;
    margin-bottom: 32px;

    &:last-child {
        margin-bottom: 0;
    }

    /* stylelint-disable */

    ${props => props.variant && props.variant.isSticky && css`
        margin: 0 18px 12px;
        min-width: 248px;
        text-align: center;

        @media screen and ${FROM_TABLET} {
            margin: 0 18px;
        }
    `};

    /* stylelint-enable */

    @media screen and ${FROM_TABLET} {
        padding: 0 20px;
        width: ${props => ((props.variant && props.variant.isSticky) ? 'auto' : '33%')};
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 0 40px;
    }
`;

export const linkStyles = css`
    background-color: transparent;
    color: ${COLORS.MEDIUM_GRAY2};
    cursor: pointer;
    display: inline-block;
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.3px;
    line-height: 1.57;
    max-width: 100%;
    ${outerFocusMixin};
    text-decoration: none;
    transition: color 0.3s ease;
    word-wrap: break-word;

    /* stylelint-disable */

    ${props => props.variant && props.variant.isActive && css`
        color: ${COLORS.DARK_GRAY2};
        font-weight: 700;
    `};
    
    ${props => props.selectedlist ==  'nav-active' && css`
        color: ${COLORS.DARK_GRAY2};
        font-weight: 700;
    `};
    
    /* stylelint-enable */

    &:hover {
        color: ${COLORS.DARK_GRAY1};
    }

    @media screen and ${FROM_TABLET} {
        font-size: 24px;
        letter-spacing: normal;
        line-height: 1.38;
        text-align: left;

        /* stylelint-disable */

        ${props => props.variant && props.variant.isSticky && css`
            font-size: 18px;
        `};

        /* stylelint-enable */
    }

    @media screen and ${FROM_DESKTOP} {
        line-height: 1.25;
    }
`;
