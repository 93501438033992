import { css } from 'styled-components';
import {
    COLORS,
    FONTS,
    ANIMATIONS,
} from 'utils/variables';
import { outerFocusMixin } from 'components/FocusVisibility/styles';
import {
    FROM_TABLET,
    FROM_DESKTOP,
    FROM_TABLET_TO_DESKTOP,
} from '../../../../utils/mediaQueries';

import {
    TYPE_PRIMARY_LARGE,
    TYPE_SECONDARY_MEDIUM_LARGE,
    TYPE_PRIMARY_LARGE_LARGE,
    TYPE_PRIMARY_LARGE_MINI,
    TYPE_SECONDARY_MEDIUM,
    TYPE_PRIMARY_MEDIUM,
    TYPE_SECONDARY_SMALL,
    TYPE_PILL,
    TYPE_DOWNLOAD_LEFT,
    TYPE_LIST,
    TYPE_TAB,
    TYPE_GHOST,
    TYPE_GHOST_RIGHT,
    TYPE_GHOST_LEFT,
    TYPE_DOWNLOAD_LEFT_BRIGHT,
    TYPE_PDF_LEFT_BRIGHT,
    TYPE_GHOST_LEFT_WHITE,
    TYPE_SECONDARY_SMALL_WHITE,
    TYPE_MULTIADD,
    TYPE_SHARECG_NAVIGATIONAL,
} from 'components/atomics/atoms/Button/Button';

export const defaultLinkStyles = css`
    ${outerFocusMixin};
    display: inline-block;
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;

    @media screen and ${FROM_TABLET} {
        margin-bottom:0;
    }

    ${props => props.datapage === "linkBtn" && css`
        margin-bottom:0;
    `}

    ${props => props.datapage === "promoBanner" && css`
        width: 100%;
        margin-bottom: 20px;
        box-sizing: border-box;
        max-width: 224px;

        @media screen and ${FROM_TABLET} {
            max-width: 100%;
        }

        &:last-child{
            margin-bottom: 0;
        }
    `}

    span,
    svg {
        transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;
    }

    ${props => props.disabled === 'true' && css`
        cursor: default;
    `};

    ${props => props.type === TYPE_MULTIADD && css`
        background-color: ${COLORS.WHITE};
        width: 22px;
        height: 22px;
        border-radius: 50%;
        border: 2px solid ${COLORS.SAPPHIRE};
        position: relative;
        color: ${COLORS.SAPPHIRE};

        svg {
            position: relative;
            stroke-width: 2;
        }

        &:hover {
            color: ${COLORS.WHITE};
            background: ${COLORS.SAPPHIRE};
        }

        ${props => props.disabled === 'true' && css`
            border-color: ${COLORS.LIGHT_GRAY3} !important;
            color: ${COLORS.LIGHT_GRAY3} !important;
            background: ${COLORS.WHITE} !important;
        `};
    `};

    ${props => props.type === TYPE_SECONDARY_SMALL_WHITE && css`
        background-color: transparent;
        color: ${COLORS.WHITE};
        font-size: 12px;
        line-height: 10px;
        font-weight: 700;
        letter-spacing: 1.8px;
        padding: 12px  16px;
        text-transform: uppercase;
        border: 1px solid ${COLORS.WHITE};

        ${props => props.disabled === 'false' && css`
            &:hover {
            }
        `};

        ${props => props.disabled === 'true' && css`
            color:  ${COLORS.LIGHT_GRAY3};
        `};
    `};


    ${props => props.type === TYPE_PRIMARY_LARGE && css`
        background-color: ${COLORS.OCEAN};
        color: ${COLORS.WHITE};
        font-size: 19px;
        font-weight: 700;
        letter-spacing: 0;
        padding: 19px 48px;
        text-transform: none;

        &:hover {
            background-color: ${COLORS.SAPPHIRE};
        }

        ${props => props.disabled && css`
            background-color:  ${COLORS.LIGHT_GRAY3};
            
            &:hover {
                background-color: ${COLORS.LIGHT_GRAY3};
            }
        `};

    `};

    ${props => props.type === TYPE_SECONDARY_MEDIUM_LARGE && css`
        background-color: ${COLORS.WHITE};
        color: ${COLORS.OCEAN};
        font-size: 19px;
        font-weight: 700;
        letter-spacing: 0;
        padding: 17px 46px;
        border: 2px solid ${COLORS.OCEAN};
        text-transform: none;

        &:hover {
            border-color: ${COLORS.SAPPHIRE};
            color: ${COLORS.SAPPHIRE};
        }

        ${props => props.disabled && css`
            border-color: ${COLORS.LIGHT_GRAY3} !important;
            color: ${COLORS.LIGHT_GRAY3} !important;
        `};

    `};

    ${props => props.type === TYPE_PRIMARY_LARGE_LARGE && css`
        background-color: ${COLORS.SAPPHIRE};
        color: ${COLORS.WHITE};
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1.5px;
        padding: 20px 57px;
        text-transform: uppercase;

        ${props => props.disabled === 'false' && css`
            &:hover {
                background-color: ${COLORS.SAPPHIRE};
            }
        `};

        ${props => props.disabled === 'true' && css`
            background-color:  ${COLORS.LIGHT_GRAY3};
        `};
    `};

${props => props.type === TYPE_PRIMARY_LARGE_MINI && css`
    background-color: ${COLORS.SAPPHIRE};
    color: ${COLORS.WHITE};
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1.5px;
    padding: 20px 17px;
    text-transform: uppercase;


    @media screen and ${FROM_TABLET} {
        padding: 20px 19px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 20px 43px;
    }

    ${props => props.disabled === 'false' && css`
        &:hover {
            background-color: ${COLORS.SAPPHIRE};
        }
    `};

    ${props => props.disabled === 'true' && css`
        background-color:  ${COLORS.LIGHT_GRAY3};
    `};
`};

    ${props => props.type === TYPE_SECONDARY_MEDIUM && css`
        border: 2px solid ${COLORS.SAPPHIRE};
        color: ${COLORS.SAPPHIRE};
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1.5px;
        padding: 13px 24px;
        text-transform: uppercase;
        min-width: 60px;
        &:hover {
            border-color: ${COLORS.DARK_SAPPHIRE};
            color: ${COLORS.DARK_SAPPHIRE};
        }

        ${props => props.disabled === 'false' && css`
            &:hover {
                border-color: ${COLORS.SAPPHIRE};
                color: ${COLORS.SAPPHIRE};
            }
        `};

        ${props => props.disabled === 'true' && css`
            border-color:  ${COLORS.LIGHT_GRAY3};
            color: ${COLORS.LIGHT_GRAY3};
        `};
    `};


    ${props => props.type === TYPE_PRIMARY_MEDIUM && css`
        background-color: ${COLORS.SAPPHIRE};
        color: ${COLORS.WHITE};
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1.5px;
        padding: 15px 26px;
        text-transform: uppercase;
        min-width: 60px;

        &:hover {
            background-color: ${COLORS.DARK_SAPPHIRE};
        }

        ${props => !props.disabled && css`
            &:hover {
                background-color: ${COLORS.DARK_SAPPHIRE};
            }
        `};

        ${props => props.disabled && css`
            background-color:  ${COLORS.LIGHT_GRAY3};

            &:hover {
                background-color: ${COLORS.LIGHT_GRAY3};
            }
        `};
    `};

    ${props => props.type === TYPE_SECONDARY_SMALL && css`
        border: 1px solid ${COLORS.SAPPHIRE};
        color: ${COLORS.SAPPHIRE};
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1.5px;
        padding: 4px 16px;
        text-transform: uppercase;
        line-height: 26px;

        &:hover {
            color: ${COLORS.DARK_SAPPHIRE};
            border-color: ${COLORS.DARK_SAPPHIRE};
        }

        ${props => props.disabled === 'false' && css`
            &:hover {
                border-color: ${COLORS.SAPPHIRE};
                color: ${COLORS.SAPPHIRE};
            }
        `};

        ${props => props.disabled === 'true' && css`
            border-color:  ${COLORS.LIGHT_GRAY3};
            color: ${COLORS.LIGHT_GRAY3};
        `};
    `};

    ${props => props.type === TYPE_SHARECG_NAVIGATIONAL && css`
        border: 1px solid ${COLORS.SAPPHIRE};
        color: ${COLORS.SAPPHIRE};
        font-weight: 700;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        width: 140px;
        height: 36px;
        line-height: 3em;
        font-size: 12px;
        box-sizing: border-box;

        &:hover{
          border-color: ${COLORS.DARK_SAPPHIRE};
          color: ${COLORS.DARK_SAPPHIRE};
        }

        ${props => props.disabled === 'false' && css`
            &:hover {
                border-color: ${COLORS.SAPPHIRE};
                color: ${COLORS.SAPPHIRE};
            }
        `};

        ${props => props.disabled === 'true' && css`
            border-color:  ${COLORS.LIGHT_GRAY3};
            color: ${COLORS.LIGHT_GRAY3};
        `};
    `};

    ${props => props.type === TYPE_GHOST && css`
        color: ${COLORS.SAPPHIRE};
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 2.4px;
        text-transform: uppercase;

        &:hover {
            color: ${COLORS.DARK_SAPPHIRE};
        }

        ${props => props.disabled === 'false' && css`
            &:hover {
                color: ${COLORS.SAPPHIRE};
            }
        `};
    `};

    ${props => props.type === TYPE_GHOST_RIGHT && css`
        color: ${COLORS.DARK_GRAY2};
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1.5px;
        text-transform: uppercase;

        svg {
            margin-left: 6px;
            position: relative;
            stroke: ${COLORS.DARK_GRAY2};
            top: -1px;
            width: 14px;
        }

        &:hover{
            color: ${COLORS.MEDIUM_GRAY2};

            svg {
                stroke: ${COLORS.MEDIUM_GRAY2};
            }
        }

        ${props => props.disabled === 'false' && css`
            &:hover {
                color: ${COLORS.MEDIUM_GRAY3};

                svg {
                    stroke:${COLORS.MEDIUM_GRAY3};
                }
            }
        `};
    `};

    ${props => props.type === TYPE_GHOST_LEFT && css`
        color: ${COLORS.SAPPHIRE};
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1.5px;
        text-transform: uppercase;

        svg {
            height: 10px;
            margin-right: 10px;
            position: relative;
            stroke: ${COLORS.SAPPHIRE};
            top: -1px;
            width: 13px;
            transform: rotate(180deg);
        }

        ${props => props.disabled === 'false' && css`
            &:hover {
                color: ${COLORS.SAPPHIRE};

                svg {
                    stroke: ${COLORS.SAPPHIRE};
                }
            }
        `};
    `};

    ${props => (props.type === TYPE_DOWNLOAD_LEFT_BRIGHT || props.type === TYPE_PDF_LEFT_BRIGHT) && css`
    color: ${COLORS.SAPPHIRE};
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1.5px;
    text-transform: uppercase;

    svg {
        height: 13px;
        ${props => (props.type === TYPE_PDF_LEFT_BRIGHT) && css`
            height: 16px;
        `};
        margin-right: 10px;
        position: relative;
        stroke: ${COLORS.SAPPHIRE};
        top: -1px;
        width: 13px;
    }

    ${props => props.disabled === 'false' && css`
        &:hover {
            color: ${COLORS.SAPPHIRE};

            svg {
                stroke: ${COLORS.SAPPHIRE};
            }
        }
    `};
    `};

    ${props => props.type === TYPE_GHOST_LEFT_WHITE && css`
        color: ${COLORS.WHITE};
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1.5px;
        text-transform: uppercase;

        svg {
            height: 10px;
            margin-right: 10px;
            position: relative;
            stroke: ${COLORS.SAPPHIRE};
            top: -1px;
            width: 13px;
            transform: rotate(180deg);
        }

        ${props => props.disabled === 'false' && css`

                svg {
                    stroke: ${COLORS.SAPPHIRE};
                }
        `};
    `};

    ${props => props.type === TYPE_DOWNLOAD_LEFT && css`
        color: ${COLORS.BLACK};
        font-size: 10px;
        font-weight: 700;
        letter-spacing: 1.71px;
        text-transform: uppercase;

        svg {
            height: 13px;
            margin-right: 10px;
            position: relative;
            stroke: ${COLORS.SAPPHIRE};
            top: -1px;
            width: 13px;
        }

        ${props => props.disabled === 'false' && css`
            &:hover {
                color: ${COLORS.DARK_GRAY2};
            }
        `};
    `};

    ${props => props.type === TYPE_LIST && css`
        color: ${COLORS.SAPPHIRE};
        font-size: 15px;
        font-weight: 600;
        padding: 8px 0 8px 0;
        position: relative;
        white-space: nowrap;

        @media screen and ${FROM_TABLET} {
            padding: 10px 0 10px 0;
        }

        @media screen and ${FROM_DESKTOP} {
            padding: 16px 0 16px 0;
        }

        svg {
            fill: ${COLORS.DARK_SAPPHIRE};
            height: 14px;
            width: 14px;
            display: inline-block;
            margin-right: 18px;
            position: relative;
            top: -1px;
        }

        span:last-child {
            background: ${COLORS.LIGHT_GRAY2};
            bottom: 0;
            height: 1px;
            left: 0;
            position: absolute;

            width: 100%;
        }

        &:hover {
            color: ${COLORS.DARK_SAPPHIRE};

            svg {
                fill:  ${COLORS.DARK_SAPPHIRE};
            }
        }

        ${props => props.disabled === 'false' && css`
            &:hover {
                span:last-child {
                    opacity: 0.4;
                }
            }
        `};
    `};


    ${props => props.type === TYPE_TAB && css`
        color: ${COLORS.BLACK};
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 1px;
        padding: 8px 0 20px 0;
        position: relative;
        text-transform: uppercase;
        -webkit-transition-property: none;
        -moz-transition-property: none;
        -o-transition-property: none;
        transition-property: none;

        span:first-child {
            font-weight: 700;
            visibility: hidden;
        }

        span:nth-child(2) {
            position: absolute;
            left: 0;
            top: 7px;
            visibility: hidden;
        }

        span:last-child {
            background: ${COLORS.SAPPHIRE};
            bottom: 0;
            height: 4px;
            left: 0;
            position: absolute;
            width: 100%;
            opacity: 0;
        }

        span[data-aspect="trans"] {
            color: transparent !important;
        }

        ${props => props.selected && css`
            font-weight: 700;
            
            span:first-child {
                visibility: visible;
            }

            span:nth-child(2) {
                visibility: hidden;
            }
            
            span:last-child {
                opacity: 1;
            }
        `};

        ${props => !props.selected && css`
            span:nth-child(2) {
                visibility: visible;
            }
        `};

        ${props => props.disabled === 'false' &&
            props.selected && css`
            &:hover {
                span:first-child {
                    opacity: 1;
                }

                span:last-child {
                    opacity: 0.4;
                }
            }
        `};
        ${props => props.disabled !== 'false' &&
            props.selected && css`
            &:hover { 
                span:last-child {
                    opacity: 0.4;
                }
            }
        `};
    `};


    ${props => props.type === TYPE_PILL && css`
        background-color: transparent;
        border-radius: 24px;
        color: ${COLORS.SAPPHIRE};
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1.5px;
        padding: 16px 54px;
        position: relative;
        text-transform: uppercase;
        border: 2px solid ${COLORS.SAPPHIRE};
        min-width: 95px;

        @media screen and ${FROM_TABLET_TO_DESKTOP} {
            padding: 16px 39px 16px 51px;
            min-width: 88px;
        }
       
        @media screen and (min-width: 1024px)  and (max-width: 1200px) {
            padding: 16px 40px;
        }

        @media (hover) {
            &:hover {
                border-color: ${COLORS.DARK_SAPPHIRE};
                color: ${COLORS.DARK_SAPPHIRE};
                
                span:first-child {
                    border-color: ${COLORS.DARK_SAPPHIRE};
                    color: ${COLORS.DARK_SAPPHIRE};
                }
            }
        }

        span:first-child {
            background-color: transparent;
            border-radius: 15px;
            display: inline-block;
            height: 30px;
            left: 7px;
            overflow: hidden;
            position: absolute;
            top: 8px;
            width: 30px;
            border: 2px solid ${COLORS.SAPPHIRE};
            box-sizing: border-box;

            svg {
                fill: ${COLORS.SAPPHIRE};
                position: relative;
                top:4px;
            }
        }

        *[data-rel="check"] {
            display: none;
        }

        ${props => props.disabled === 'false' &&
            props.selected && css`

            @media (hover) {
                &:hover {
                    border-color: ${COLORS.DARK_SAPPHIRE};
                    color: ${COLORS.DARK_SAPPHIRE};
                    background-color: ${COLORS.DARK_SAPPHIRE};

                    span:first-child {
                        border-color: ${COLORS.DARK_SAPPHIRE};
                        color: ${COLORS.WHITE};

                        svg {
                            fill: ${COLORS.WHITE};
                        }
                    }
                }
            }
        `};

        ${props => props.disabled === 'false' &&
            props.selected && css`
            @media (hover) {
                &:hover {
                    border-color: ${COLORS.DARK_SAPPHIRE};
                    color: ${COLORS.WHITE};
                    background-color: ${COLORS.DARK_SAPPHIRE};

                    span:first-child {
                        border-color: ${COLORS.DARK_SAPPHIRE};
                        color: ${COLORS.WHITE};

                        svg {
                            fill: ${COLORS.WHITE};
                        }
                    }
                }
            }

            span:first-child {
                background: ${COLORS.DARK_SAPPHIRE};
                border: 2px solid ${COLORS.SAPPHIRE};
                border-radius: 16px;
                height: 30px;
                left: 7px;
                top: 8px;
                width: 30px;

                svg {
                    fill: ${COLORS.WHITE};
                    left: 4px;

                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        left: 7px;
                    }
                }
            }

            *[data-rel="check"] {
                display: block;
            }

            *[data-rel="plus"] {
                display: none;
            }
        `};
        
        ${props => props.selected === false && css`
            min-width: 90px;
            @media screen and ${FROM_TABLET_TO_DESKTOP} {
                min-width: 88px;
            }
        `};

        ${props => props.selected === true && css`
            background-color:  ${COLORS.SAPPHIRE};
            border: 2px solid ${COLORS.SAPPHIRE};
            color: ${COLORS.WHITE};
            padding: 16px 54px 16px 54px;

            @media screen and ${FROM_TABLET_TO_DESKTOP} {
                padding: 16px 39px 16px 51px;
            
            }
            @media screen and (min-width: 1024px)  and (max-width: 1200px) {
                padding: 16px 40px;
            }
    
            @media (hover) {
                &:hover {
                    border-color: ${COLORS.DARK_SAPPHIRE};
                    color: ${COLORS.WHITE};
                    background-color: ${COLORS.DARK_SAPPHIRE};        
                }
            }

            span:first-child {
                background-color: ${COLORS.DARK_SAPPHIRE};;
                border: 2px solid ${COLORS.DARK_SAPPHIRE};
                border-radius: 16px;
                height: 30px;
                left: 7px;
                top: 8px;

                svg {
                    fill: ${COLORS.WHITE};
                    left: 4px;

                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        left: 7px;
                    }
                }
            }

            *[data-rel="check"] {
                display: block;
            }

            *[data-rel="plus"] {
                display: none;
            }
        `};
    `};

    ${props => props.datapage === "promoBanner" && css`
        width: 100%;
        margin-bottom: 20px;
        box-sizing: border-box;
        max-width: 224px;

        @media screen and ${FROM_TABLET} {
            max-width: 100%;
        }

        &:last-child{
            margin-bottom: 0;
        }
    `}

`;
