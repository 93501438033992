import {
    all,
    put,
    call,
    takeLatest,
} from 'redux-saga/effects';
import {
    GET_LATEST_MARKET_BRIEFING_URL,
    GET_MARKET_BRIEFING_URL,
    GET_MARKET_BRIEFINGS_URL,
    GET_MARKET_BRIEFING,
    GET_MARKET_BRIEFINGS,
    GET_LATEST_MARKET_BRIEFING,
} from 'state-management/constants/marketBriefing.common';
import {
    getMarketBriefingSuccess,
    getMarketBriefingFailure,
    getMarketBriefingsSuccess,
    getMarketBriefingsFailure,
    getLatestMarketBriefingSuccess,
    getLatestMarketBriefingFailure,
} from 'state-management/actions/marketBriefing.common';

import Api from '../bridge/api';

/**
 * Fetch a market briefing by ID.
 * @param {*} action
 */
function* getMarketBriefing(action) {
    try {
        const marketBriefingData = yield call(Api.get, GET_MARKET_BRIEFING_URL(action.id));

        yield put(getMarketBriefingSuccess(marketBriefingData.data));
    } catch (e) {
        yield put(getMarketBriefingFailure(e));
    }
}

/**
 * Get a full list of market briefing archives (no content, just dates and ids).
 */
function* getMarketBriefings() {
    try {
        const marketBriefingData = yield call(Api.get, GET_MARKET_BRIEFINGS_URL);
       
        yield put(getMarketBriefingsSuccess(marketBriefingData.data.marketBriefingSnapshotList));
    } catch (e) {
        yield put(getMarketBriefingsFailure(e));
    }
}

/**
 * Get the latest market briefing or summary.
 */
function* getLatestMarketBriefing() {
    try {
        const marketBriefingData = yield call(Api.get, GET_LATEST_MARKET_BRIEFING_URL);

        yield put(getLatestMarketBriefingSuccess(marketBriefingData.data));
    } catch (e) {
        yield put(getLatestMarketBriefingFailure(e));
    }
}

function* marketBriefingSaga() {
    yield all([
        takeLatest(GET_MARKET_BRIEFING, getMarketBriefing),
        takeLatest(GET_MARKET_BRIEFINGS, getMarketBriefings),
        takeLatest(GET_LATEST_MARKET_BRIEFING, getLatestMarketBriefing),
    ]);
}

export default marketBriefingSaga;
