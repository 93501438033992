import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from 'components/atomics/atoms/Button/Button';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import styled from 'styled-components';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import anime from 'animejs';
import LegalFDIC from 'components/LegalFDIC/LegalFDIC';
import { Container, ImageHeading, Loader } from 'components';
import ContactUsForm from 'containers/ContactUs/ContactUsForm';
import { setScrollToTop } from 'state-management/actions/scroll';
import { createImageUrl } from 'utils/urlUtils';
import { isEmailValid } from 'utils/validators/emailValidator';
import { isPhoneNumberValid } from 'utils/validators/phoneNumberValidator';
import isNameInvalid from 'utils/validators/tagInNameValidator';
import {
    CONTACT_METHOD_EMAIL,
    CONTACT_METHOD_PHONE,
} from 'state-management/constants/contactUs';
import {
    getTopics as getTopicsAction,
    submitFeedback as submitFeedbackAction,
    resetContactForm as resetContactFormAction,
} from 'state-management/actions/contactUs';
import { clearErrors } from 'state-management/actions/errors';
import {
    confirmationSubHeadingStyles,
    contentContainerStyles,
    subHeadingStyles,
    resetButtonContainer,
} from 'containers/ContactUs/styles';

const COVER_IMAGE_URL = createImageUrl('hero-images', 'contact-us.jpg');
const MOBILE_COVER_IMAGE_URL = createImageUrl('hero-images', 'contact-us-mobile.jpg');

const ResendButtonContainer = styled.div`
    ${resetButtonContainer}
`;

const StyledContentContainer = styled(Container)`
    ${contentContainerStyles}
`;

const StyledSubHeading = styled.div`
    ${subHeadingStyles}
`;

const StyledConfirmationSubHeading = styled(StyledSubHeading)`
    ${confirmationSubHeadingStyles}
`;

/**
 * Contact us page display.
 */
export class ContactUs extends React.Component {
    /**
     * Default constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            isSubmitAttempted: false,
        };

        this.fieldRefs = {};
    }

    /**
     * Get required form data at mount, remove previous errors.
     */
    componentDidMount() {
        const {
            topics,
            getTopics,
        } = this.props;

        if (!topics.length) {
            getTopics();
        }

        this.props.scrollToTop();
    }

    /**
     * Reset state data at destruction.
     */
    componentWillUnmount() {
        this.props.resetContactForm();
    }

    /**
     * Handle binding namespaces to form inputs.
     */
    setFieldRef = fieldName => (ref) => {
        this.fieldRefs[fieldName] = document.getElementById(fieldName);
    };

    /**
     * Gather the intial form values.
     */
    getInitialValues = () => ({
        firstName: '',
        lastName: '',
        topic: undefined,
        details: '',
        contactMethod: CONTACT_METHOD_EMAIL,
        email: this.props.email,
        phone: '',
    });

    /**
     * Handle validate onblur of details area.
     * @param {*} event 
     * @param {*} formik 
     */
    handleDetailsBlur = (event, formik) => {
        const value = event.target.value;

        if (!value.length) {
            formik.setFieldTouched('details', true);
            formik.setFieldError('details', this.formatFieldErrorMessage(
                'error.emptyField',
                'contactUs.field.details',
            ));
        }
    };

    /**
     * Handle topic validation after blur or topic dropdown.
     */
    handleTopicBlur = (formik) => {
        this.setState({
            isSubmitAttempted: true,
        });
    }

    /**
     * Validate email on blur.
     * @param {*} event 
     * @param {*} formik 
     */
    handleEmailBlur = (event, formik) => {
        const value = event.target.value;

        if (!value) {
            formik.setFieldTouched('email', true);
            formik.setFieldError('email', this.formatFieldErrorMessage(
                'error.emailFormat',
                'contactUs.field.email',
            ));
        } else if (!isEmailValid(value)) {
            formik.setFieldTouched('email', true);
            formik.setFieldError('email', this.formatFieldErrorMessage(
                'error.badFormat',
                'contactUs.field.email',
            ));
        }
    }

    /**
     * Validate phone on blur.
     * @param {*} event 
     * @param {*} formik 
     */
    handlePhoneBlur = (event, formik) => {
        const value = event.target.value;

        if (!value) {
            formik.setFieldTouched('phone', true);
            formik.setFieldError('phone', this.formatFieldErrorMessage(
                'error.PhoneFormat',
                'contactUs.field.phone',
            ));
        } else if (!isPhoneNumberValid(value)) {
            formik.setFieldTouched('phone', true);
            formik.setFieldError('phone', this.props.intl.formatMessage({ id: 'contactUs.errors.badPhone' }));
        }
    }

    /**
     * Handle blur of name fields.
     * @param {*} event 
     * @param {*} formik 
     */
    handleNameBlur = (event, formik) => {
        const value = event.target.value;

        if (event.target.name === 'firstName') {
            if (!value) {
                formik.setFieldTouched('firstName', true);
                formik.setFieldError('firstName', this.formatFieldErrorMessage(
                    'error.firstNameFormat',
                    'contactUs.field.firstName',
                ));
            } else if (isNameInvalid(value)) {
                formik.setFieldTouched('firstName', true);
                formik.setFieldError('firstName', this.formatFieldErrorMessage(
                    'error.specialCharacter',
                    'contactUs.field.firstName',
                ));
            }
        } else {
            if (!value) {
                formik.setFieldTouched('lastName', true);
                formik.setFieldError('lastName', this.formatFieldErrorMessage(
                    'error.lastNameFormat',
                    'contactUs.field.lastName',
                ));
            } else if (isNameInvalid(value)) {
                formik.setFieldTouched('lastName', true);
                formik.setFieldError('lastName', this.formatFieldErrorMessage(
                    'error.specialCharacter',
                    'contactUs.field.lastName',
                ));
            }
        }
    }

    /**
     * Validate contact form data.
     */
    validate = (values) => {
        const errors = {};

        if (!this.props.isAuthenticated) {
            if (!values.firstName || !values.firstName.trim()) {
                errors.firstName = this.formatFieldErrorMessage(
                    'error.firstNameFormat',
                    'contactUs.field.firstName',
                );
            } else if (isNameInvalid(values.firstName)) {
                errors.firstName = this.formatFieldErrorMessage(
                    'error.specialCharacter',
                    'contactUs.field.firstName',
                );
            }

            if (!values.lastName || !values.lastName.trim()) {
                errors.lastName = this.formatFieldErrorMessage(
                    'error.lastNameFormat',
                    'contactUs.field.lastName',
                );
            } else if (isNameInvalid(values.lastName)) {
                errors.lastName = this.formatFieldErrorMessage(
                    'error.specialCharacter',
                    'contactUs.field.lastName',
                );
            }
        }

        if (values.contactMethod === CONTACT_METHOD_EMAIL) {
            if (!values.email || !values.email.trim()) {
                errors.email = this.formatFieldErrorMessage(
                    'error.emailFormat',
                    'contactUs.field.email',
                );
            } else if (!isEmailValid(values.email)) {
                errors.email = this.formatFieldErrorMessage(
                    'error.badFormat',
                    'contactUs.field.email',
                    true,
                );
            }
        } else if (values.contactMethod === CONTACT_METHOD_PHONE) {
            if (!values.phone || !values.phone.trim()) {
                errors.phone = this.formatFieldErrorMessage(
                    'error.PhoneFormat',
                    'contactUs.field.phone',
                );
            } else if (!isPhoneNumberValid(values.phone)) {
                errors.phone = this.props.intl.formatMessage({ id: 'contactUs.errors.badPhone' });
            }
        }

        if (values.topic == null || values.topic === '') {
            errors.topic = this.formatFieldErrorMessage(
                'error.emptyField',
                'contactUs.field.email',
            );
        }

        if (!values.details || !values.details.trim()) {
            errors.details = this.formatFieldErrorMessage(
                'error.emptyField',
                'contactUs.field.details',
            );
        }

        /*if (!isEmpty(errors)) {
            this.focusError(errors);
        }*/

        this.setState({ isSubmitAttempted: true });

        return errors;
    };

    /**
     * Formats errors for form display.
     */
    formatFieldErrorMessage = (id, fieldKey, lowercaseField) => {
        const { formatMessage } = this.props.intl;
        let field = formatMessage({ id: fieldKey });

        if (lowercaseField) {
            field = field.toLowerCase();
        }

        return formatMessage({ id }, { field });
    };

    /**
     * Focus an errored field.
     */
    focusError = (errors) => {
        const fieldName = Object.keys(errors)[0];

        this.fieldRefs[fieldName === 'topic' ? 'details' : fieldName].focus();
    };

    /**
     * Handle user submit action.
     */
    handleSubmit = (data) => {
        const { formSubmitting, clearGlobalErrors, submitFeedback } = this.props;

        if (!formSubmitting) {
            clearGlobalErrors();
            submitFeedback(data);

            this.scrollToTop();
        }
    };

    /**
     * Animated scroll to top.
     */
    scrollToTop = () => {
        const scrollElement = window.document.scrollingElement
            || window.document.body || window.document.documentElement;
            
        anime({
            targets: scrollElement,
            scrollTop: 0,
            duration: 500,
            easing: 'easeInOutQuad',
        });
    }

    /**
     * Render the contact form.
     */
    renderForm = () => {
        const {
            isAuthenticated,
            firstName,
            topics,
            formSubmitting,
            globalErrors,
        } = this.props;

        const subHeading = firstName
            ? (<FormattedMessage id="contactUs.subHeading.authenticated" values={{ firstName }} />)
            : (<FormattedMessage id="contactUs.subHeading.anonymous" />);

        return (
            <Fragment>
                <StyledSubHeading>
                    {subHeading}
                </StyledSubHeading>
                <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={this.getInitialValues()}
                    validate={this.validate}
                    onSubmit={this.handleSubmit}
                >
                    {formik => (
                        <ContactUsForm
                            handleDetailsBlur={this.handleDetailsBlur}
                            formik={formik}
                            isAuthenticated={isAuthenticated}
                            topics={topics}
                            isSubmitting={formSubmitting}
                            setFieldRef={this.setFieldRef}
                            globalErrors={globalErrors}
                            isSubmitted={this.state.isSubmitAttempted}
                            handleDetailsBlur={this.handleDetailsBlur}
                            handleEmailBlur={this.handleEmailBlur}
                            handlePhoneBlur={this.handlePhoneBlur}
                            handleNameBlur={this.handleNameBlur}
                            handleTopicBlur={this.handleTopicBlur}
                        />
                    )}
                </Formik>
            </Fragment>
        );
    };

    /**
     * Render confirmation of submission on successful submit.
     */
    renderConfirmation = () => (
        <StyledConfirmationSubHeading>
            <FormattedMessage
                id="contactUs.confirmationMessage"
                values={{ break: <br /> }}
            />
            <ResendButtonContainer>
                <Button
                    to="#"
                    noAnchor={true}
                    onClick={()=>{
                        window.location.reload();
                    }}
                >
                    <FormattedMessage
                        id="contactUs.resend"
                    />
                </Button>
            </ResendButtonContainer>
        </StyledConfirmationSubHeading>
    );

    /**
     * Render this and underlying components.
     */
    render() {
        const {
            isAuthenticated,
            isProfileLoading,
            topicsLoading,
            formSubmitting,
            submitSucceeded,
        } = this.props;
        const headingTitle = <FormattedMessage id="contactUs.heading" />;

        if ((isAuthenticated && isProfileLoading) || topicsLoading) {
            return <Loader />;
        }

        return (
            <Fragment>
                <ImageHeading
                    title={headingTitle}
                    image={COVER_IMAGE_URL}
                    smallImage={MOBILE_COVER_IMAGE_URL}
                />
                <StyledContentContainer>
                    {
                        !formSubmitting && submitSucceeded
                            ? this.renderConfirmation()
                            : this.renderForm()
                    }
                </StyledContentContainer>
                <LegalFDIC />
                {formSubmitting ? (<Loader />) : null }
            </Fragment>
        );
    }
}

ContactUs.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    getTopics: PropTypes.func.isRequired,
    submitFeedback: PropTypes.func.isRequired,
    resetContactForm: PropTypes.func.isRequired,
    clearGlobalErrors: PropTypes.func.isRequired,
    email: PropTypes.string,
    firstName: PropTypes.string,
    isProfileLoading: PropTypes.bool.isRequired,
    topicsLoading: PropTypes.bool.isRequired,
    formSubmitting: PropTypes.bool.isRequired,
    submitSucceeded: PropTypes.bool.isRequired,
    topics: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.number,
        value: PropTypes.string,
    })).isRequired,
    globalErrors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    intl: intlShape.isRequired,
};

ContactUs.defaultProps = {
    email: undefined,
    firstName: undefined,
};

const mapStateToProps = state => ({
    isAuthenticated: state.signIn.isAuthenticated,
    email: state.basicProfile.email,
    firstName: state.basicProfile.firstName,
    isProfileLoading: state.basicProfile.isLoading,
    topicsLoading: state.contactUs.topicsLoading,
    formSubmitting: state.contactUs.formSubmitting,
    submitSucceeded: state.contactUs.submitSucceeded,
    topics: state.contactUs.topics,
    globalErrors: state.errors.errors,
});

const mapDispatchToProps = dispatch => ({
    getTopics: () => dispatch(getTopicsAction),
    submitFeedback: data => dispatch(submitFeedbackAction(data)),
    resetContactForm: () => dispatch(resetContactFormAction),
    clearGlobalErrors: () => dispatch(clearErrors()),
    scrollToTop: () => dispatch(setScrollToTop()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ContactUs));
