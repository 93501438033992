import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'components/atomics/atoms/Button/Button';
import {
    containerStyles,
    colorBarStyles,
    titleStyles,
    defaultTxtStyles,
} from 'components/atomics/molecules/SplashNotification/styles';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { SourceModal } from 'components/atomics/molecules/NewsSource/SourceModal';

export const Container = styled.div`
    ${containerStyles}
`;

export const ColorBar = styled.div`
    ${colorBarStyles}
`;

export const Title = styled.h4`
    ${titleStyles}
`;

export const DefaultTxt = styled.p`
    ${defaultTxtStyles}
`;

/**
 * Creates a splash notification banner with CTA.
 */
class SplashNotification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sourceModal: false,
        }
    }
    closeSourceModal = () => {
        this.setState({ sourceModal: false });
    }

    /**
     * Render the splash notification and underlying components.
     */
    render() {
        return (
            <>
                <SourceModal isOpen={this.state.sourceModal} closeSourceModal={this.closeSourceModal} />
                <Container data-rel="SplashNotification">
                    <ColorBar />
                    <Title>
                        {this.props.title}
                    </Title>
                    <DefaultTxt>{this.props.textDef}</DefaultTxt>
                    <Button
                        dataAnalyticsPlacement="Button : body"
                        dataAnalyticsLabel="trackLink : button"
                        dataAnalyticsId={this.props.buttonLabel}
                        onClick={(e) => {
                            const sourceLength = this.props.selectedSources.length;
                            if (sourceLength != undefined && sourceLength < 3) {
                                e.preventDefault();
                                this.setState({ sourceModal: true });
                            }
                            // else {
                            //    window.location = this.props.url;
                            // }
                        }}
                        to = {this.props.url}
                    >
                        {this.props.buttonLabel}
                    </Button>
                </Container>
            </>
        );
    }
}

SplashNotification.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
    buttonLabel: PropTypes.string,
    selectedSources: PropTypes.arrayOf(PropTypes.number),
};

SplashNotification.defaultProps = {
    title: null,
    url: null,
    buttonLabel: null,
    selectedSources: [],
};

const mapStateToProps = (state) => ({
    selectedSources: state.basicProfile.sourceIds,
});

export default connect(mapStateToProps)(SplashNotification);
