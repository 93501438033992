import { css } from 'styled-components';
import {
    SMALL_MOBILE
} from '../../utils/mediaQueries';

export const wrapperStyles = css`
    @media screen and ${SMALL_MOBILE} {
        div div{
            width: 310px;

            a {
                margin-right: 48px !important;
            }
        }
    }
`;
