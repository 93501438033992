import { css } from 'styled-components';
import { COLORS } from '../../utils/variables';
import { outerFocusMixin } from 'components/FocusVisibility/styles';

export const linkStyles = css`
    border: 0;
    clip: rect(0 0 0 0);
    color: ${COLORS.BLACK};
    height: 1px;
    left: 50%;
    margin: -1px;
    ${outerFocusMixin};
    overflow: hidden;
    position: absolute;
    text-decoration: none;
    top: 0;
    transform: translateX(-50%);
    width: 1px;

    &:focus,
    &:active {
        background-color: ${COLORS.WHITE};
        border-color: ${COLORS.SAPPHIRE};
        border-style: solid;
        border-width: 0 2px 2px;
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        padding: 5px 10px;
        width: auto;
    }
`;
