import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS } from 'utils/variables';

export const wrapperStyles = css`
    .slick-dots .slick-active {
        background: ${COLORS.SAPPHIRE};
    }
`;

export const toolsContainerStyles = css`
    display: flex;
    width: 100%;

    @media screen and ${FROM_TABLET} {
        margin: 0 0 0 auto;
        width: 70%;
    }
`;

export const toolsItemStyles = css`
    box-sizing: border-box;
    display: flex;
    width: 100%;
    padding: 16px;

    @media screen and ${FROM_TABLET} {
        padding: 0 24px;
        width: 50%;

        /* stylelint-disable */
        ${props => props.horizontalCard && css`
            width: 100%;
        `};
        /* stylelint-enable */
    }
`;

export const styledDotStyles = css;
