import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import labels from '../../../../lang/en.json';
import { titleStyles } from 'components/atomics/atoms/Title/styles';

const StyledTitle = styled.h1`
    ${titleStyles}
`;

/**
 * Creates the standard top most page title artifact, H1.
 * @param {*} props 
 */
const Title = (props) => {
    const {
        title,
        resourceId,
        children,
    } = props;

    const finalTitle = resourceId ? labels.translations[resourceId] : title ? title : children;

    return (
        <StyledTitle data-rel="Title">
            {finalTitle}
        </StyledTitle>
    );
};

Title.propTypes = {
    resourceId: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

Title.defaultProps = {
    resourceId: null,
    title: null,
    children: null,
};

export default Title;
