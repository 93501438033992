import styled from 'styled-components';
import { COLORS } from "../../../../utils/variables";

const POINT_SIZE = 12;

export const Wrapper = styled.div`
 
`;

export const PointsWrapper = styled.div`
    margin: 10px 0px;
    padding: 10px 0 20px 0;
`;

export const PointsContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const Point = styled.button`
    border: none;
    background: ${props => props.isActive ? COLORS.SAPPHIRE : COLORS.LIGHT_GRAY2};
    border-radius: 50%;
    cursor: pointer;
    height: ${POINT_SIZE}px;
    width: ${POINT_SIZE}px;
    padding: 0;
    max-width: ${POINT_SIZE}px;
    margin: 0 5px;

    &:focus {
        outline-style: solid;
    }
`;

export const CarouselWrapper = styled.div`
    overflow: hidden;
    width: 100%;
`;

export const CarouselContent = styled.div`
    display: flex;
    width: 100%; 
    
    transition: ${props => props.isAnimated ? 'transform 0.4s ease' : 'none'} ;
    transform: ${props => `translateX(${props.animationPosition}px)`};
      
    &:nth-child(1){
        padding-left: 10px;
    }
`;

export const ContentItem = styled.div`
    flex: 1 0 34%;
    padding: 5px;
    min-width: 300px;
`;

export const SingleComponentWrapper = styled.div`
    display: flex;
    justify-content: center;
`;
