import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { SanitizedHTML } from 'components';
import {
    citationStyles,
    containerStyles,
    headerStyles,
} from 'components/Citations/styles';

const StyledCitationsContainer = styled.div`
    ${containerStyles}
`;
const StyledCitations = styled(SanitizedHTML)`
    ${citationStyles}
`;
const StyledHeader = styled.div`
    ${headerStyles}
`;

const Citations = props => (
    Citations.areAttributesValid(props.data) && (
        <StyledCitationsContainer id={`citations-${props.id}`}>
            <StyledHeader>
                {props.data.citationHeader}
            </StyledHeader>
            <StyledCitations
                htmlContent={props.data.citationContent}
                tag="div"
            />
        </StyledCitationsContainer>
    ));

Citations.areAttributesValid = ({ citationHeader, citationContent }) => citationHeader && citationContent;

Citations.propTypes = {
    data: PropTypes.shape({
        citationContent: PropTypes.string,
        citationHeader: PropTypes.string,
    }).isRequired,
    id: PropTypes.string.isRequired,
};

export default Citations;