import * as type from 'state-management/constants/search';
import * as saveTypes from 'state-management/constants/save';
import { SEEMORE_TRENDING_SUCCESS } from 'state-management/constants/allTopics.common';
import { ARTICLE_WAS_VISITED, PL_WAS_VISITED } from 'state-management/constants/articles.common';
import { GUIDE_WAS_VISITED, VISIT_CE_CREDIT } from 'state-management/constants/guide';

const initialState = {
    error: false,
    items: [],
    isOpen: false,
    loading: false,
    page: 1,
    searchValue: '',
};

const setGuideSaved = (state, guideId, isSaving, saved) => {
    return state.items.map((item) => {
        if (+item.id === +guideId) {
            return {
                ...item,
                saved: saved,
                isSaving,
            };
        }
        return item;
    })
};

const setArticleSaved = (state, articleId, isSaving, saved) => (
    state.items.map((item) => {
        if (item.id === articleId) {
            return {
                ...item,
                saved: (saved !== undefined ? saved : item.saved),
                isSaving,
            };
        }
        return item;
    })
);

function searchReducer(state = initialState, action = {}) {
    switch (action.type) {
        case type.CHANGE_PAGE:
            return {
                ...state,
                page: action.page,
            };
        case type.OPEN:
            return {
                ...state,
                isOpen: true,
                searchValue: '',
            };
        case type.CLOSE:
            return {
                ...state,
                isOpen: false,
                searchValue: '',
            };
        case type.SEARCH_LOADING:
            return {
                ...state,
                loading: true,
            };
        case type.SEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                items: action.items.map(item => ({ ...item, isSaving: false })),
            };
        case SEEMORE_TRENDING_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                items: action.data,
            };
        case type.SEARCH_ERROR:
            return {
                ...state,
                loading: action.togggleLoading ? false : state.loading,
                error: true,
                items: [],
            };
        case type.SEARCH_START:
            return {
                ...state,
                searchValue: action.value,
                loading: true,
            };
        case saveTypes.TOGGLE_SAVE_GUIDE: {
            const {
                id,
                save,
            } = action;
            const isSaving = true;
            const newItems = setGuideSaved(state, id, isSaving, save);
            return {
                ...state,
                items: newItems,
            };
        }
        /* use same logic, at guides recent */
        case saveTypes.TOGGLE_SAVE_GUIDE_SUCCESS: {
            const {
                id,
                save
            } = action;
            const isSaving = false;
            const newItems = setGuideSaved(state, id, isSaving, save);
            return {
                ...state,
                items: newItems,
            };
        }
        case saveTypes.TOGGLE_SAVE_GUIDE_FAILURE: {
            const {
                id,
                save,
            } = action;
            const isSaving = false;
            const newItems = setGuideSaved(state, id, isSaving, !save);
            return {
                ...state,
                items: newItems,
            };
        }
        case saveTypes.TOGGLE_SAVE_ARTICLE: {
            const {
                id,
                save,
            } = action;
            const isSaving = true;

            return {
                ...state,
                items: setArticleSaved(state, id, isSaving, save),
            };
        }
        case saveTypes.TOGGLE_SAVE_ARTICLE_SUCCESS: {
            const {
                id,
            } = action;
            const isSaving = false;

            return {
                ...state,
                items: setArticleSaved(state, id, isSaving),
            };
        }
        case saveTypes.TOGGLE_SAVE_ARTICLE_FAILURE: {
            const {
                id,
                save,
            } = action;
            const isSaving = false;

            return {
                ...state,
                items: setArticleSaved(state, id, isSaving, !save),
            };
        }
        case ARTICLE_WAS_VISITED: {
            const { id } = action;
            return {
                ...state,
                items: state.items.map(item => (item.id === id
                    ? { ...item, visited: true } : item)),
            };
        }
        case GUIDE_WAS_VISITED: {
            const { id } = action;
            return {
                ...state,
                items: state.items.map(item => (item.id === id
                    ? { ...item, visited: true } : item)),
            };
        }
        case VISIT_CE_CREDIT: {
            const { id } = action;
            return {
                ...state,
                items: state.items.map(item => (item.id === id
                    ? { ...item, visited: true } : item)),
            };
        }
        case PL_WAS_VISITED: {
            const { id } = action;
            if (state == null || state.items == undefined || state.items.length == 0) {
                return {
                    ...state,
                    isLoading: false,
                };
            }
            return {
                ...state,
                items: state.items.map(item => (item.id === id
                    ? { ...item, visited: true } : item)),
            };
        }
        default:
            return state;
    }
}

export default searchReducer;
