import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS, ANIMATIONS } from 'utils/variables';

export const wrapperOverrideStyles = css`
    padding: 0 !important;

    >div >a, >div, >a {
        display: block;
        width: 100%;
        text-decoration: none;
        color: ${COLORS.SAPPHIRE};
        transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.1px;
        font-weight: 600;
        border-bottom: 1px solid ${COLORS.LIGHT_GRAY2};
        padding-bottom: 22px;
        padding-top: 22px;

        &:hover {
            color: ${COLORS.DARK_SAPPHIRE};
        }
    }

    >div >a {
        border-bottom: 0;
        padding: 0;
    }

    >a:nth-child(3n), >div:nth-child(3n) {
        margin-right: 0;
    }

    @media screen and ${FROM_TABLET} {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        >a, >div {
            width: calc(33% - 16px);
            margin-right: 24px;
        }
    }
`;

export const listStyles = css`
    display: flex;
    flex-direction: column;
    margin: 5px 0 0;

    @media screen and ${FROM_DESKTOP} {
        margin: 10px 0 0;
    }
`;

export const categorySectionWrapperStyles = css`
    margin-bottom: 24px;

    @media screen and ${FROM_TABLET} {
        margin-bottom: 46px;
    }
`;

export const categoryActionButtonStyles = css`
    background: transparent;
    border: none;
    color: ${COLORS.OCEAN};
    cursor: pointer;
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 15px;
    font-weight: 700;
    letter-spacing: -0.1px;
    line-height: 0.93;
    margin-bottom: 20px;
    padding: 0;

    @media screen and ${FROM_TABLET} {
        font-size: 18px;
        line-height: 0.78;
    }
`;
