import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Link from 'components/atomics/atoms/Link/Link';
import ImageAtom from 'components/atomics/atoms/Image/Image';
import { dismissCoachMark } from 'state-management/actions/coachMark';
import CoachMarks from 'containers/CoachMarks/CoachMarks';
import { TYPES } from 'containers/CoachMarks/CoachMarks';
import { IconPlus, IconWrench } from 'components/atomics/atoms/Icons/Icons';
import {
    BreakpointsContext,
    MOBILE, TABLET
} from 'components/Breakpoints/Breakpoints';
import language from '../../../../lang/en.json';
import CollectionCardTools from './CollectionCardTools';
import {
    containerStyles,
    cardContainerStyles,
    contentContainerStyles,
    titleStyles,
    sloganStyles,
    iconContainerStyles,
    imageContainerStyles,
    upperContainerStyles,
    footerContainerStyles,
    footerButtonStyles,
    footerIconContainerStyles,
    footerToolsContainerStyles,
    footerToolsHeaderStyles,
    toolsHeaderIconStyles,
    footerToolsButtonStyles,
    footerToolsContentStyles,
    footerToolsItemStyles,
    footerToolsImageStyles,
    footerToolsTitleStyles,
    mobileContainerStyles,
    mobileImageContainerStyles,
    mobileContentStyles,
    mobileFooterStyles,
    mobileFooterButtonStyles,
    mobileTopContainerStyles,
    toolsContainerStyles,
} from 'components/atomics/molecules/CollectionContentCard/styles';
import { FormattedMessage } from 'react-intl';
import { getTruncatedString } from 'utils/contentCardUtils';

export const Title = styled.div`
    ${titleStyles}
`;

export const Container = styled.div`
    ${containerStyles}
`;

export const CardContainer = styled.div`
    ${cardContainerStyles}
`;

export const ImageContainer = styled.div`
    ${imageContainerStyles}
`;

export const ContentContainer = styled(Link)`
    ${contentContainerStyles}
`;

export const Slogan = styled.div`
    ${sloganStyles}
`;

export const IconContainer = styled.div`
    ${iconContainerStyles}
`;

export const UpperContainer = styled(Link)`
    ${upperContainerStyles}
`;

export const FooterContainer = styled.a`
    ${footerContainerStyles}
`;

export const FooterIconContainer = styled.div`
    ${footerIconContainerStyles}
`;

export const FooterButton = styled.div`
    ${footerButtonStyles}
`;

export const FooterToolsContainer = styled.div`
    ${footerToolsContainerStyles}
`;

export const FooterToolsHeader = styled.div`
    ${footerToolsHeaderStyles}
`;

export const FooterToolsButton = styled.div`
    ${footerToolsButtonStyles}
`;

export const ToolsHeaderIconContainer = styled.div`
    ${toolsHeaderIconStyles}
`;

export const FooterToolsContent = styled.div`
    ${footerToolsContentStyles}
`;

export const FooterToolsItem = styled.div`
    ${footerToolsItemStyles}
`;

export const FooterToolsImage = styled.div`
    ${footerToolsImageStyles}
`;

export const FooterToolsTitle = styled.div`
    ${footerToolsTitleStyles}
`;

export const MobileContainer = styled.div`
    ${mobileContainerStyles}
`;

export const MobileImageContainer = styled.div`
    ${mobileImageContainerStyles}
`;

export const MobileContentContainer = styled.div`
    ${mobileContentStyles}
`;

export const MobileFooterContainer = styled(Link)`
    ${mobileFooterStyles}
`;

export const MobileFooterButton = styled.div`
    ${mobileFooterButtonStyles}
`;

export const MobileTopContainer = styled(Link)`
    ${mobileTopContainerStyles}
`;

export const CollectionCardContainer = styled.div`
    ${toolsContainerStyles}
`;

const MAX_SLOGAN_CHARS = 75;

/**
 * Creates a collection content card.
 */
class CollectionContentCard extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.keyCount = 0;

        this.getKey = this.getKey.bind(this);
        this.openMenu = this.openMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);

        this.state = {
            hover: false,
            isToolsCardOpen: false
        };
    }

    /**
     * Handle open/close from key press (enter).
     * @param {*} event 
     */
    handleKeyPress(event) {
        if (event.keyCode === 13) {
            this.openMenu(event);
        }
    }

    /**
     * Get incrementing iterator key.
     */
    getKey() {
        return this.keyCount++;
    }

    /**
     * Handle PM collections coach mark dismissal by action.
     */
    handleCoachMarks = (url) => {
        if (this.props.pmCollectionsCoachMark) {
            this.props.onDismissCollection();
        } else if (this.props.pmToolsCoachMark) {
            this.props.onDismissTool();
        }
    }

    /**
     * Open hover menu.
     */
    openMenu(event) {
        event.persist()
        this.setState({
            isToolsCardOpen: true,
        }, () => {
            if (window.currentCollection && event.type !== 'mouseover') {
                window.currentCollection.closeMenu();
            }

            window.currentCollection = this;
        });
    }

    /**
     * Close hover menu.
     */
    closeMenu() {
        this.setState({
            isToolsCardOpen: false,
        });
    }

    /**
     * Render the collection content card and underlying components.
     */
    render() {
        const { showCoachMark, isCustomCoachMarkPosition, customCoachMarkPositionOption } = this.props;
        const route = this.props.collectionInfo.group !== 'PRACTICE_MILESTONES' ? 'client-life-events' : 'practice-milestones'
        const imageSource = this.props.collectionInfo.image.includes('http') ? this.props.collectionInfo.image : `/api${this.props.collectionInfo.image}`;
        
        return (
            <BreakpointsContext.Consumer>
                {(breakpoint) => {
                    if (breakpoint === MOBILE) {
                        return (
                            <MobileContainer data-rel="collectionsMobileCard">
                                <CoachMarks
                                    show={showCoachMark}
                                    type={TYPES.PMCOLLECTION}
                                >
                                    <MobileTopContainer onClick={() => {
                                        this.handleCoachMarks(`/learn/collections/${route}/${this.props.collectionInfo.code}`);
                                        }} to={`/learn/collections/${route}/${this.props.collectionInfo.code}`}>
                                        <MobileImageContainer>
                                            <ImageAtom
                                                 src={imageSource}
                                                 alt={language.translations['global.alt.image']}
                                            />

                                        </MobileImageContainer>
                                        <MobileContentContainer>
                                            <Title >
                                                {this.props.collectionInfo.title}
                                            </Title>
                                            <Slogan>
                                                {getTruncatedString(this.props.collectionInfo.description, MAX_SLOGAN_CHARS)}
                                            </Slogan>
                                        </MobileContentContainer>
                                    </MobileTopContainer>
                                </CoachMarks>
                                <MobileFooterContainer
                                    to={`/learn/collections/${route}/${this.props.collectionInfo.code}`}
                                >
                                    <MobileFooterButton>
                                        <FormattedMessage id="collections.view" />
                                    </MobileFooterButton>
                                    <CoachMarks
                                        show={showCoachMark}
                                        type={TYPES.PMTOOL}
                                    >
                                        <IconContainer>
                                            {this.props.collectionInfo.tools && this.props.collectionInfo.tools.length > 0
                                                && <span><IconWrench /></span>}
                                        </IconContainer>
                                    </CoachMarks>
                                </MobileFooterContainer>
                            </MobileContainer>
                        );
                    }
                    else if (breakpoint === TABLET) {
                        return (
                            <Container onMouseLeave={this.closeMenu}>
                                <CardContainer key={this.props.collectionInfo.code}>
                                    <CoachMarks
                                        show={showCoachMark}
                                        type={TYPES.PMCOLLECTION}
                                    >
                                        <CoachMarks
                                            type={TYPES.PMTOOL}
                                            show={showCoachMark}
                                            isCustomCoachMarkPosition={isCustomCoachMarkPosition}
                                            customCoachMarkPositionOption={customCoachMarkPositionOption}
                                        >
                                            <UpperContainer onClick={() => {
                                                this.handleCoachMarks(`/learn/collections/${route}/${this.props.collectionInfo.code}`)
                                            }} to={`/learn/collections/${route}/${this.props.collectionInfo.code}`}>
                                                <ImageContainer >
                                                    <ImageAtom
                                                         src={imageSource}
                                                         alt={language.translations['global.alt.image']}
                                                    />

                                                </ImageContainer>

                                                <IconContainer>
                                                    {this.props.collectionInfo.tools && this.props.collectionInfo.tools.length > 0
                                                        && <span><IconWrench /></span>}
                                                </IconContainer>

                                            </UpperContainer>
                                        </CoachMarks>
                                    </CoachMarks>
                                    <ContentContainer onClick={() => {
                                        this.handleCoachMarks(`/learn/collections/${route}/${this.props.collectionInfo.code}`)
                                    }} to={`/learn/collections/${route}/${this.props.collectionInfo.code}`}>
                                        <Title >
                                            {this.props.collectionInfo.title}
                                        </Title>
                                        <Slogan>
                                            {getTruncatedString(this.props.collectionInfo.description, MAX_SLOGAN_CHARS)}
                                        </Slogan>
                                    </ContentContainer>
                                    <FooterContainer
                                        tabIndex="0"
                                        onKeyDown={this.handleKeyPress}
                                        onClick={(e) => this.openMenu(e)}
                                    >
                                        <FooterButton>Preview Collection</FooterButton>
                                        <FooterIconContainer>
                                            <IconPlus />
                                        </FooterIconContainer>
                                    </FooterContainer>
                                </CardContainer>
                                <CollectionCardContainer
                                    className={`${this.state.isToolsCardOpen === true ? 'showTools' : 'hideTools'}`}
                                >
                                    <CollectionCardTools
                                        route={route}
                                        code={this.props.collectionInfo.code}
                                        guidesList={this.props.collectionInfo.guides}
                                        toolsList={this.props.collectionInfo.tools}
                                        cardSize={this.props.toolsCardSize}
                                        onMouseLeave={this.closeMenu}
                                    />
                                </CollectionCardContainer>
                            </Container>
                        );
                    }
                    else {
                        return (
                            <Container onMouseLeave={this.closeMenu}>
                                <CardContainer key={this.props.collectionInfo.code}>
                                    <CoachMarks
                                        show={showCoachMark}
                                        type={TYPES.PMCOLLECTION}
                                    >
                                        <CoachMarks
                                            type={TYPES.PMTOOL}
                                            show={showCoachMark}
                                        >
                                            <UpperContainer
                                                onClick={() => {
                                                    this.handleCoachMarks(`/learn/collections/${route}/${this.props.collectionInfo.code}`);
                                                }}
                                                to={`/learn/collections/${route}/${this.props.collectionInfo.code}`}
                                            >
                                                <ImageContainer >
                                                    <ImageAtom
                                                        src={imageSource}
                                                        alt={language.translations['global.alt.image']}
                                                    />

                                                </ImageContainer>
                                                <IconContainer>
                                                    {this.props.collectionInfo.tools && this.props.collectionInfo.tools.length > 0
                                                        && <span><IconWrench /></span>}
                                                </IconContainer>
                                            </UpperContainer>
                                        </CoachMarks>
                                    </CoachMarks>
                                    <ContentContainer
                                        onClick={() => {
                                            this.handleCoachMarks(`/learn/collections/${route}/${this.props.collectionInfo.code}`)
                                        }}
                                        to={`/learn/collections/${route}/${this.props.collectionInfo.code}`}
                                    >
                                        <Title >
                                            {this.props.collectionInfo.title}
                                        </Title>
                                        <Slogan>
                                            {getTruncatedString(this.props.collectionInfo.description, MAX_SLOGAN_CHARS)}
                                        </Slogan>
                                    </ContentContainer>
                                    <FooterContainer
                                        tabIndex="0"
                                        onKeyDown={this.handleKeyPress}
                                        onMouseOver={(e) => this.openMenu(e)}
                                    >
                                        <FooterButton>Preview Collection</FooterButton>
                                        <FooterIconContainer>
                                            <IconPlus />
                                        </FooterIconContainer>
                                    </FooterContainer>

                                </CardContainer>
                                <CollectionCardContainer
                                    className={`${this.state.isToolsCardOpen === true ? 'showTools' : 'hideTools'}`}
                                >
                                    <CollectionCardTools
                                        route={route}
                                        code={this.props.collectionInfo.code}
                                        guidesList={this.props.collectionInfo.guides}
                                        toolsList={this.props.collectionInfo.tools}
                                        cardSize={this.props.toolsCardSize}
                                        onMouseLeave={this.closeMenu}
                                    />
                                </CollectionCardContainer>
                            </Container>
                        );
                    }
                }}
            </BreakpointsContext.Consumer>

        );
    }

}

CollectionContentCard.propTypes = {
    showCoachMark: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.bool,
    ]),
    isCustomCoachMarkPosition: PropTypes.bool,
    customCoachMarkPositionOption: PropTypes.object,
    route: PropTypes.string,
    collectionInfo: PropTypes.shape({
        code: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        ordering: PropTypes.number.isRequired,
        image: PropTypes.string.isRequired,
        latestPublishTimestamp: PropTypes.string.isRequired,
        group: PropTypes.string,
        guides: PropTypes.arrayOf(PropTypes.shape({})),
        tools: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
};

CollectionContentCard.defaultProps = {
    route: 'CE',
    showCoachMark: false,
    onMouseOver: () => { },
    onMouseOut: () => { },
    toolsCardSize: 'large',
    isCustomCoachMarkPosition: false
}

const mapStateToProps = state => ({
    pmCollectionsCoachMark: state.coachMark.display.PM_COLLECTIONS,
    pmToolsCoachMark: state.coachMark.display.PM_TOOLS,
});

const mapDispatchToProps = dispatch => ({
    onDismissCollection: () => { dispatch(dismissCoachMark('PM_COLLECTIONS', true)); },
    onDismissTool: () => { dispatch(dismissCoachMark('PM_TOOLS', true)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionContentCard);
