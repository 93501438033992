import { css } from 'styled-components';
import {
    FROM_TABLET,
    FROM_DESKTOP,
} from 'utils/mediaQueries';
import { COLORS } from 'utils/variables';

export const overlayStyles = css`
    align-items: center;
    background-color: ${COLORS.WHITE};
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 8;
`;

export const loaderStyles = css`
    height: 50px;
    width: 50px;

    @media screen and ${FROM_TABLET} {
        width: 80px;
        height: 80px;
    }

    @media screen and ${FROM_DESKTOP} {
        width: 100px;
        height: 100px;
    }
`;
