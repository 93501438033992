import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_MOBILE_TO_TABLET, FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS } from 'utils/variables';

export const wrapperStyles = css`
    margin-top: 6px;
    align-items: center;
    display: flex;
`;

export const formStyles = css`
    display: none;

    /* stylelint-disable */

    ${props => props.variant && props.variant.isOpen && css`
        align-items: center;
        background-color: ${COLORS.WHITE};
        bottom: 0;
        display: flex;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 11;

        @media screen and ${FROM_TABLET} {
            padding-left: 23px;
        }
        @media screen and ${FROM_DESKTOP} {
            padding-left: 0;
            padding-right: 0;
        }



    `};

    /* stylelint-enable */
`;

export const formContainerStyles = css`
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;
    background: ${COLORS.WHITE};

    @media screen and ${FROM_DESKTOP} {
        margin-left: auto;
        margin-right: auto;
    }
`;

export const labelStyles = css`
    color: ${COLORS.MEDIUM_GRAY2};
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 15px;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: -0.08px;

    @media screen and ${FROM_DESKTOP} {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.09px;
    }
`;

export const inputStyles = css`
    background: ${({ value }) => (value ? COLORS.WHITE : 'transparent')};
    border: 1px solid transparent;
    box-sizing: border-box;
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 15px;
    line-height: 16px;
    font-weight: 500;
    height: 33px;
    letter-spacing: -0.08px;
    outline: 0;
    padding: 0;
    width: calc(100% - 100px);
    z-index: 1;

    &::-ms-clear {
        display: none;
    }

    @media screen and ${FROM_TABLET} {
        width: calc(100% - 150px);
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.09px;
    }
`;

export const buttonStyles = css`
    background-color: transparent;
    border: 1px solid transparent;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0;
`;

export const toggleButtonStyles = css`
    background-color: transparent;
    border: 0px solid transparent;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0;
    position: relative;
    z-index: 12;
    margin-bottom: 0;
    height: 20px;
    width: 20px;


    @media screen and ${FROM_DESKTOP} {
        top: 1px;
        margin-bottom: 10px;
    }

    @media screen and ${FROM_TABLET} {
        top: 2px;
    }

    /* stylelint-disable */

    ${props => props.variant && props.variant.isOpen && css`
        @media screen and ${FROM_MOBILE_TO_TABLET} {
            padding: 16px 12px;
            position: absolute;
            right: 2px;
            top: 35%;
            transform: translateY(-50%);

        }
    `};

    /* stylelint-enable */
`;

export const submitButtonStyles = css`
    ${buttonStyles};
    position: absolute;
    right: 45px;
    top: 55%;
    transform: translateY(-50%);
    padding-right: 20px;

    @media screen and ${FROM_TABLET} {
        right: 75px;
        top: 55%;
    }
    @media screen and ${FROM_DESKTOP} {
        right: 50px;
        top: 42%;
    }
`;


export const closeIconStyles = css`
    width: 20px;
    height: 20px;
    margin-bottom: 0;
    fill: ${COLORS.DARK_GRAY2};


    @media screen and ${FROM_DESKTOP} {
        width: 16px;
        height: 16px;
        padding-right: 35px;
    }

    @media screen and ${FROM_MOBILE_TO_TABLET} {
        width: 20px;
        height: 20px;
        position: relative;
        left: -6px;
    }

    @media screen and ${FROM_TABLET} {
        left: 0;
    }
`;

export const searchIconStyles = css`
    width: 20px;
    height: 20px;
    fill: ${COLORS.DARK_GRAY2};


    @media screen and ${FROM_DESKTOP} {
        width: 16px;
        height: 16px;
        //padding-left: 35px;
    }

      @media screen and ${FROM_MOBILE_TO_TABLET} {
        width: 20px;
        height: 20px;
        right: 30px;
        top: 17px;
    }
`;

export const borderStyles = css`
    width: 10px;
    position: relative;
    left: 65px;

    @media screen and ${FROM_TABLET} {
        left: 90px;
    }

    @media screen and ${FROM_DESKTOP} {
        left: 114px;
    }

    &:before {
        background: ${COLORS.LIGHT_GRAY3};
        bottom: 0;
        content: '';
        left: -10px;
        position: absolute;
        top: -12px;
        width:2px;
        height: 27px;

        @media screen and ${FROM_DESKTOP} {
            top: -15px;
        }
    }
`;
