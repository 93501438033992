import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { getVisiblePagesInfo } from '../../utils/paginationUtils';
import { IconArrowLeft, IconArrowRight } from 'components/atomics/atoms/Icons';
import Icon from 'components/atomics/atoms/Icons/Icon/Icon';
import {
    pagerWrapperStyles,
    pageButtonsWrapperStyles,
    pageButtonStyles,
    arrowButtonStyles,
    ellipsisStyles,
} from 'components/PagedList/styles';
import { Button, VisuallyHidden } from 'components';

export const StyledPagerWrapper = styled.div`
    ${pagerWrapperStyles}
`;

const StyledPageButtonsWrapper = styled.div`
    ${pageButtonsWrapperStyles}
`;

export const StyledPageButton = styled(Button)`
    ${pageButtonStyles}
`;

export const StyledArrowButton = styled(Button)`
    ${arrowButtonStyles}
`;

const StyledEllipsis = styled.span`
    ${ellipsisStyles}
`;

const arrowButtonDimensions = {
    width: 24,
    height: 24,
};

const LeftArrowIcon = Icon(IconArrowLeft, arrowButtonDimensions);
const RightArrowIcon = Icon(IconArrowRight, arrowButtonDimensions);

const Pager = ({
    currentPage,
    pageSize,
    itemsCount,
    onPageChange,
    isLoading,
    totalItems,
    totalPages
}) => {

    const pTotalPages = totalPages == null
        ? Math.ceil(itemsCount / pageSize)
        : totalPages;

    if (totalPages < 2) {
        return null;
    }

    const isFirst = currentPage === 1;
    const isLast = currentPage === pTotalPages;
    const {
        visiblePages,
        showLeftPlaceholder,
        showRightPlaceholder,
    } = getVisiblePagesInfo(currentPage, pTotalPages);

    const pageButtons = visiblePages.map(page => (
        <StyledPageButton
            aria-current={currentPage === page}
            id={`btn-pager-page-${page}`}
            isCurrent={currentPage === page}
            onClick={() => currentPage !== page && onPageChange(page)}
            key={`pager-page-${page}`}
        >
            {page}
        </StyledPageButton>
    ));
    // Show the section after all card load
    var sectionObj = document.querySelectorAll('#content section');
    if (sectionObj != undefined && sectionObj[0] != undefined) {
        sectionObj[0].style.display = "block";
    }
    //end of change
    if (showLeftPlaceholder) {
        pageButtons.splice(
            1,
            0,
            <StyledEllipsis key="pager-placeholder-left">⋯</StyledEllipsis>,
        );
    }
    if (showRightPlaceholder) {
        pageButtons.splice(
            pageButtons.length - 1,
            0,
            <StyledEllipsis key="pager-placeholder-right">⋯</StyledEllipsis>,
        );
    }

    function targetView(pgNo) {
        // Validate if the Target Libraries are available on your website
        let viewName = 'pgNo' + pgNo;
        if (typeof adobe != 'undefined' && adobe.target && typeof adobe.target.triggerView === 'function') {
            adobe.target.triggerView(viewName);
            //console.log(viewName);
        }
        onPageChange(currentPage - 1)
    }

    return (
        <StyledPagerWrapper>
            <StyledArrowButton
                onClick={() => !isFirst && targetView(currentPage - 1)}
                className="previous"
                disabled={isFirst || isLoading}
                id="btn-pager-previous"
            >
                <LeftArrowIcon />
                <VisuallyHidden>
                    <FormattedMessage id="profilePage.tab.pager.previous" />
                </VisuallyHidden>
            </StyledArrowButton>
            <StyledPageButtonsWrapper>
                {pageButtons}
            </StyledPageButtonsWrapper>
            <StyledArrowButton
                onClick={() => !isLast && onPageChange(currentPage + 1)}
                className="next"
                disabled={isLast || isLoading}
                id="btn-pager-next"
            >
                <VisuallyHidden>
                    <FormattedMessage id="profilePage.tab.pager.next" />
                </VisuallyHidden>
                <RightArrowIcon />
            </StyledArrowButton>
        </StyledPagerWrapper>
    );
};

Pager.propTypes = {
    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    itemsCount: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
};

Pager.defaultProps = {
    isLoading: false,
};

export default Pager;