import React, { Component } from 'react';
import styled, { css } from "styled-components";
import KebabButton from "../../atoms/KebabButton/KebabButton";
import KebabContentHolder from "../../atoms/KebabContentHolder/KebabContentHolder";
import onClickOutside from 'react-onclickoutside';
import SourceTag from "../../atoms/SourceTag/SourceTag";
import { FROM_DESKTOP, FROM_TABLET, SMALL_MOBILE } from "../../../../utils/mediaQueries";
import { KEY_ESCAPE } from "../../../../utils/keyCodes";
import KebabMenuItemBuilder, {
    KebabIconClose,
    KebabIconEyeActive,
    KebabIconEyeInactive,
    KebabIconSave,
} from "../../atoms/KebabMenuItemBuilder/KebabMenuItemBuilder";
import { FormattedMessage } from "react-intl";
import { isNil } from "lodash";
import { TYPE_GUIDE } from "../../../../state-management/constants/savedContent";
import { contextHubRecordEvent } from "../../../../utils/contextHub";
import { KEBAB_EVENT_LIST } from "../../../../utils/contextHubEventListConstants";
import Link from "../../../Link/Link";
import { COLORS, FONTS } from "../../../../utils/variables";
import browser from 'browser-detect';
import storage from 'utils/store';

const Wrapper = styled.div`

`;

const IsVisited = styled.span`
    opacity: 1;
`;

const KebabButtonAnimationContainer = styled.div`
    opacity: 1;
`;

const STORAGE_PREFERENCES_TAB = "storage/profile/preferences";

const KebabContentAnimationContainer = styled.div`
    ${props => props.hasLimit && css`
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        max-width: 375px;
    `}
`;

const SourceBlockContainer = styled.div`
    position: absolute;
    bottom: 20px;
`;

const CategoriesTitleContainer = styled(Link)`
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    color: ${COLORS.SAPPHIRE};
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    position:absolute;
    bottom: 6px;
    display: inline-block;
    text-decoration: none;

    &:hover {
        color: ${COLORS.DARK_SAPPHIRE};
    }

    p {
        font-size: 12px;
        line-height: 1.33;
        letter-spacing: normal;
    }

    @media screen and ${SMALL_MOBILE} {
        p {
            font-size: 12px;
            line-height: 1.33;
            letter-spacing: normal;
        }
    }

    @media screen and ${FROM_TABLET} {
        width: 60%;
        p {
            font-size: 10px;
            line-height: 1.4;
            letter-spacing: 0.1px;
        }
    }

    @media screen and ${FROM_DESKTOP} {
        p {
            font-size: 12px;
            line-height: 1.33;
            letter-spacing: normal;
        }
    }
`;

/**
 * Creates the footer area for list and other content cards.
 */
class ListContentCardFooter extends Component {
    /**
     * Default constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            showKebabContent: false,
            clickChange: false
        }

        this.close = this.close.bind(this);
    }

    /**
     * Set key listener at mount.
     */
    componentDidMount() {
        // add global event listener
        document.addEventListener('keydown', (event) => {
            this.handleCustomKeyEvents(event)
        });

        this.myRef = React.createRef();
    }

    /**
     * Handle clicks outside kebab.
     */
    handleClickOutside = () => {
        this.setState({ showKebabContent: false });
    };

    /**
     * Remove key listener at destroy.
     */
    componentWillUnmount() {
        // remove global event listener
        document.removeEventListener('keydown', (event) => {
            this.handleCustomKeyEvents(event);
        });
    }

    /**
     * Determine if source is followed.
     */
    setCurrentSource = ({ sourceId, profile }) => {
        return (sourceId != null && profile != null) ? profile.sourceIds.includes(sourceId) : false;
    }

    /**
     * Determine if topic is followed.
     */
    setCurrentTopic = ({ topics, profile }) => {
        const hasTopics = topics != null && topics.length > 0;
        const hasProfile = profile != null && profile.topicIds.length > 0;
        return hasTopics ? hasProfile
            ? profile.topicIds.includes(topics[0].id)
            : topics[0].followed : false;
    }

    /**
     * Handle specific key events for kebab.
     */
    handleCustomKeyEvents = (event) => {
        const { keyCode, which } = event;
        const key = keyCode || which || 0;

        switch (key) {
            case KEY_ESCAPE:
                this.setState({ showKebabContent: false });
                break;
        }
    }

    /**
     * Handle save button action.
     */
    handleSaveBtnClick() {
        const { article, type, articleOrGuideId, saved, articleTitle, shouldShowToast, onSaveArticle, onSaveGuide } = this.props;

        if (type === TYPE_GUIDE) {
            onSaveGuide(articleOrGuideId, !saved, articleTitle, shouldShowToast);
        } else {
            const id = isNaN(article) || isNil(articleOrGuideId) ? article.id : articleOrGuideId;
            onSaveArticle(id, !saved, articleTitle, null, article);
        }
        this.CH_handleComponentEventList('save');
    }

    /**
     * Handle follow topic action.
     * @param {*} status
     */
    handleFollowTopicBtnClick(status) {
        const { onFollowTopic, topics } = this.props;
        const topicId = (!isNil(topics) && topics.length > 0) && topics[0].id;

        onFollowTopic(topicId, status);

        this.CH_handleComponentEventList('topic');
    }

    /**
     * Handle source follow action.
     * @param {*} status
     */
    handleSourceBtnClick(status) {
        const { onFollowSource, sourceId } = this.props;

        onFollowSource(sourceId, status);

        this.CH_handleComponentEventList('source')
    }

    /**
     * Handle analytics action events.
     * @param {*} action
     */
    CH_handleComponentEventList(action) {
        const { saved, type } = this.props;
        const isTopicFollowed = this.setCurrentTopic(this.props);
        const isSourceFollowed = this.setCurrentSource(this.props);

        storage.set(STORAGE_PREFERENCES_TAB, action);

        let val = '';

        if (action === 'source') {
            if (isSourceFollowed === false) {
                val = !isSourceFollowed;
            } else if (this.props.profile.sourceIds.length && this.props.profile.sourceIds.length > 3) {
                val = !isSourceFollowed;
            }
        }
        else if (action === 'topic') {
            if (isTopicFollowed === false) {
                val = !isTopicFollowed;
            } else if (this.props.profile.topicIds.length && this.props.profile.topicIds.length > 3) {
                val = !isTopicFollowed;
            }
        }
        else {
            val = !saved;
        }

        let title = this.props.articleTitle;
        let topic = (this.props.topicName) ? this.props.topicName : null;
        let source = (this.props.sourceName) ? this.props.sourceName : null;
        let cardType = type === "CECREDIT" ? "cecredits" : type;

        if (cardType === undefined) {
            cardType = 'cecredits';
        }

        contextHubRecordEvent(KEBAB_EVENT_LIST(
            action,
            val,
            title,
            topic,
            source,
            cardType.toLowerCase()
        ));
    }

    /**
     * Function generate setup config for menu items
     * @param isSaved
     * @param isTopicFollowed
     * @param isSourceFollowed
     * @param hideFollowSource
     * @returns {*[]}
     */
    menuConfigBuilder = (isSaved, isTopicFollowed, isSourceFollowed, hideFollowSource = false) => {
        try {
            const { topics } = this.props;
            const hasTopic = topics && topics.length > 0;

            return [
                {
                    itemClick: () => {
                        this.handleSaveBtnClick();
                    },
                    icon: <KebabIconSave saved={JSON.stringify(isSaved)} />,
                    message: <FormattedMessage id={isSaved ? 'kebab.saved' : 'kebab.save'} />
                }, {
                    isHidden: !hasTopic && hasTopic !== 'hideItem',
                    itemClick: () => {
                        this.handleFollowTopicBtnClick(isTopicFollowed);
                    },
                    icon: isTopicFollowed ? <KebabIconEyeActive /> : <KebabIconEyeInactive />,
                    message: <FormattedMessage id={isTopicFollowed ? 'kebab.unfollowTopic' : 'kebab.followTopic'} />
                }, {
                    isHidden: hideFollowSource && isSourceFollowed !== 'hideItem',
                    itemClick: () => {
                        this.handleSourceBtnClick(isSourceFollowed);
                    },
                    icon: isSourceFollowed ? <KebabIconEyeActive /> : <KebabIconEyeInactive />,
                    message: <FormattedMessage id={isSourceFollowed ? 'kebab.unfollowSource' : 'kebab.followSource'} />
                }, {
                    itemClick: () => {
                        this.setState({ showKebabContent: false })
                    },
                    icon: <KebabIconClose />,
                    message: <FormattedMessage id="kebab.cancel" />
                }
            ];
        } catch (e) {
            console.warn('LOG:', e)
        }
    }

    /**
     * Build source block for display.
     */
    buildSourceBlock = (sourceName, providerName, onSourceClick, standardDisplay, ceCard, isListCard) => (
        <SourceBlockContainer
            data-analytics-placement="Anchor : anchor"
            data-analytics-label="trackLink : anchor"
            data-analytics-id={"source:" + sourceName}
        >
            <SourceTag onTopicClick={(url) => { if (onSourceClick) { onSourceClick(url) } }}
                sourceName={sourceName}
                provider={providerName}
                ceCard={true}
                isListCard={isListCard}
                standardDisplay={standardDisplay}
            />
        </SourceBlockContainer>
    );

    /**
     * Build category block for didsplay.
     */
    buildGuideCategoryBlock = (category, url, onSourceClick) => url != null ? (
        <CategoriesTitleContainer to={url}>
            <p onClick={() => {
                // this.setState({
                //     clickChange: true,
                // });
                if (onSourceClick) { onSourceClick(url) }
            }} >{category}</p>
        </CategoriesTitleContainer>
    ) : null;

    /**
     * Determine if mobile viewport.
     */
    isMobile = () => {
        const { mobile } = browser();

        return mobile;
    }

    /**
     * Determine if within the browser viewport.
     * @param {*} element
     */
    isInView(element) {
        const rect = element.getBoundingClientRect();
        const elemTop = rect.top;
        const elemBottom = rect.bottom;

        return (elemTop >= 0) && (elemBottom <= window.innerHeight);
    }

    /**
     * Keep kebab scrolled into view.
     */
    holdInView = (ref) => {
        if (!this.isInView(ref.current)) {
            ref.current.scrollIntoView();
        }
    }

    /**
     * Close the kebab (for exterior class use).
     */
    close() {
        this.setState({
            showKebabContent: false,
        });
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const { showKebabContent } = this.state;

        const { onElement,
            children,
            onSourceClick,
            ceCard = false,
            sourceName,
            providerName,
            saved,
            hideSourceOption,
            guideCardCategory = null,
            guideCardCategoryLink = null,
            isGuide = false,
            setKebabMenuLimitSize = null,
            standardDisplayOfSource = false,
            isListCard = false,
            kebabButtonClickListener = () => { }
        } = this.props;

        return (
            <Wrapper>
                <IsVisited className={this.props.isVisitedClass}>
                    {
                        !isGuide
                            ? this.buildSourceBlock(sourceName, providerName, onSourceClick, standardDisplayOfSource, ceCard, isListCard)
                            : this.buildGuideCategoryBlock(guideCardCategory, guideCardCategoryLink, onSourceClick)
                    }

                    {children}
                </IsVisited>
                <KebabButtonAnimationContainer display={onElement.toString()}
                    isMobile={this.isMobile()}
                    data-role="kebab">
                    <KebabButton
                        onClick={() => {
                            kebabButtonClickListener();

                            this.setState({ showKebabContent: !showKebabContent }, () => {
                                this.holdInView(this.myRef)

                                if (this.state.showKebabContent) {
                                    if (window.currentKebab && window.currentKebab !== this) {
                                        window.currentKebab.close();
                                    }
                                    window.currentKebab = this;
                                }
                            });
                        }} />
                </KebabButtonAnimationContainer>
                <KebabContentAnimationContainer
                    data-rel="kebabAnimation"
                    hasLimit={setKebabMenuLimitSize !== null}
                    limit={setKebabMenuLimitSize}
                    display={showKebabContent.toString()}>
                    <KebabContentHolder
                        showKebab={showKebabContent}>
                        <KebabMenuItemBuilder
                            fRef={this.myRef}
                            menuConfig={this.menuConfigBuilder(saved, this.setCurrentTopic(this.props), this.setCurrentSource(this.props), hideSourceOption)} />
                    </KebabContentHolder>
                </KebabContentAnimationContainer>
            </Wrapper>
        );
    }
}

export default onClickOutside(ListContentCardFooter);
