import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import storage from 'utils/store';
import { connect } from 'react-redux';
import Title from 'components/atomics/atoms/Title/Title';
import { Link as InternalLink } from 'react-router-dom';
import { addUrlHistoryItem } from "../../../../state-management/actions/UrlHistoryActions";
import { IconStepperNext } from 'components/atomics/atoms/Icons/Icons';
import Preferences from '../../../../containers/Profile/Preferences/Preferences';
import language from '../../../../lang/en.json';
import {
    BreakpointsContext,
    DESKTOP,
} from 'components/Breakpoints/Breakpoints';
import styled from 'styled-components';
import {
    containerStyles,
    contentContainerStyles,
    itemContainerStyles,
    itemStyles,
    styledBack,
    subtitleStyles,
} from './../../../../containers/Profile/Preferences/PreferencesNew/styles';
import BackToLink from "../../../../components/BackToLink/BackToLink";
import { wrapBackButtonLabels } from "../../../../utils/urlUtils";
import { FROM_DESKTOP } from "../../../../utils/mediaQueries";
import { COLORS } from "../../../../utils/variables";

const StyledContainer = styled.div`
    ${containerStyles}
`;

const StyledItem = styled(InternalLink)`
    ${itemStyles}
`;

const Subtitle = styled.div`
    ${subtitleStyles}
`;

const StyledContentContainer = styled.div`
    ${contentContainerStyles}
`;

const ItemContainer = styled.div`
    ${itemContainerStyles}
`;

const BackToLinkContainer = styled.div`
    color: ${COLORS.BLACK};
    background: transparent;
    padding: 0;

    @media screen and ${FROM_DESKTOP} {
        display: none;
    }
`;

const STORAGE_PREFERENCES_TAB = "storage/profile/preferences";

/**
 * Main preferences page wrapper.
 */
export class ProfileLanding extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.state = {
            tab: null,
            navigatingBack: false,
        };

        this.setTab = this.setTab.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }

    /**
     * Detect subsection at mount, set tab if found.
     */
    componentDidMount() {
        const { section } = this.props;

       if(storage.get(STORAGE_PREFERENCES_TAB) === 'topic' && section !== 'preferences'){
            return  this.setTab('topics');
       }

       if (section && section.length && section !== 'preferences') {
        this.setTab(section);
        }
    }

    /**
     * Handle tab reset on back navigation after prompt scenario
     * @param {*} nextProps 
     */
    componentWillReceiveProps(nextProps) {
        if (this.props.formTouched === true
            && nextProps.formTouched === false
            && this.state.navigatingBack) {

            this.props.formik.handleReset();

            this.setState({
                tab: null,
                navigatingBack: false,
            });
        }
        if (nextProps.goBacktoDefault === true) {
            this.setState({
                tab: null
            })
            this.props.getBackToPreviousState()
        }
    }

    /**
     * Handle adding history items for tabs.
     * @param {*} section
     */
    addHistoryItem(section) {
        const { setUrlHistoryItem } = this.props;

        setUrlHistoryItem({
            key: '',
            currentUrl: `${window.location.pathname}`,
            currentTitle: '',
        });
    }

    /**
     * Handle back button logic for tabs.
     */
    handleBack() {
        if (!this.props.formTouched) {
            this.setState({ tab: null });
        } else {
            this.setState({
                navigatingBack: true,
            });
        }

        /**
         * Catch for formik edge case where it fails to update state properly.
         */
        setTimeout(() => {
            if (!this.props.popupOpen) {
                this.setState({ tab: null });
                this.props.formik.handleReset();
            }
        }, 300);
    }

    /**
     * Set the selected tab.
     * @param {*} tabId
     */
    setTab(tabId) {
        this.setState({
            tab: tabId,
        });
    }

    /**
     * Get the total count of all topics.
     */
    getTopicCount() {
        let count = 0;
        const self = this;

        if (this.props.topics.length) {
            Object.keys(this.props.topics).forEach(function (key, index) {
                count += self.props.topics[index].topics.length;
            });
        }

        return count;
    }

    /**
     * Render default view, just summary lines.
     */
    renderDefault() {
        const { urlHistoryList } = this.props;
        const backLabel = (urlHistoryList.length - 2) >= 0 ? urlHistoryList[urlHistoryList.length - 2] : null;
        let backLink = '/profile';
        
        let backCustomLabel ='to profile' ;

        if (urlHistoryList.length >= 2){
            let buttonCheck = urlHistoryList[1].currentUrl.includes("preferences");           

            if(buttonCheck){
                backLink = '/news';
                backCustomLabel = 'to news';
            }else{
                backLink = '/profile';
                backCustomLabel = 'to profile';
            }
        }
       
        return (
            <React.Fragment>
                <BackToLinkContainer>
                    <BackToLink
                        to={backLink}
                        onClick={this.handleBack}
                        customLabel={backCustomLabel}
                        isDisable={backLabel === null}
                    >
                    </BackToLink>
                </BackToLinkContainer>
                <StyledContentContainer>
                    <Title>
                        <FormattedMessage id="preferences.title" />
                    </Title>
                    <ItemContainer>
                        <StyledItem
                            to="#"
                            onClick={() => {
                                this.addHistoryItem('sources');
                                this.setTab('sources');
                            }}
                            data-analytics-placement="Button : body"
                            data-analytics-label="trackLink : button"
                            data-analytics-id={language.translations["preferences.sources"]}
                        >
                            <div>
                                <FormattedMessage id="preferences.sources" />
                            </div>
                            <div>
                                <FormattedMessage
                                    id="preferences.count"
                                    values={{
                                        count: this.props.subscribedSources.length,
                                        total: this.props.sources.length,
                                    }}
                                />
                            </div>
                            <div>
                                <div>
                                    <span>
                                        <FormattedMessage id="preferences.view" />
                                    </span>
                                    <IconStepperNext />
                                </div>
                            </div>
                        </StyledItem>
                        <StyledItem
                            to="#"
                            onClick={() => {
                                this.addHistoryItem('topics');
                                this.setTab('topics');
                            }}
                            data-analytics-placement="Button : body"
                            data-analytics-label="trackLink : button"
                            data-analytics-id={language.translations["preferences.topics"]}
                        >
                            <div>
                                <FormattedMessage id="preferences.topics" />
                            </div>
                            <div>
                                <FormattedMessage
                                    id="preferences.count"
                                    values={{
                                        count: this.props.subscribedTopics.length,
                                        total: this.getTopicCount(),
                                    }}
                                />
                            </div>
                            <div>
                                <div>
                                    <span>
                                        <FormattedMessage id="preferences.view" />
                                    </span>
                                    <IconStepperNext />
                                </div>
                            </div>
                        </StyledItem>
                    </ItemContainer>
                </StyledContentContainer>
            </React.Fragment>
        );
    }

    /**
     * Render source/topic tabs.
     */
    renderTabs(breakpoint) {
        const { urlHistoryList } = this.props;

        let label = null;

        if (breakpoint !== DESKTOP) {
            if (this.state.tab === "sources") {
                label = "preferences.sources";
            } else {
                label = "preferences.topics";
            }
        }

        const backLabel = (urlHistoryList.length - 2) >= 0 ? urlHistoryList[urlHistoryList.length - 2] : null;

        return (
            <React.Fragment>

                <StyledContentContainer>
                    {label !== null
                        && <Subtitle>
                            <FormattedMessage id={label} />
                        </Subtitle>}
                    <Preferences
                        section={this.state.tab}
                        formik={this.props.formik}
                        sources={this.props.sourcesData}
                        topicCategories={this.props.topicCategories}
                        onTouch={() => { this.props.handlePreferencesChange(); this.props.setFormTouched(); }}
                        onEditDefaultClick={this.props.onEditDefaultClick}
                        isInEditMode={this.props.isInEditMode}
                    />
                </StyledContentContainer>
            </React.Fragment>
        )
    }

    /**
     * Render this and underlying components.
     */
    render() {
        return (
            <BreakpointsContext.Consumer>
                {(breakpoint) => {
                    return (
                        <StyledContainer>
                            {this.state.tab === null
                                && this.renderDefault()}
                            {this.state.tab !== null
                                && this.renderTabs(breakpoint)}
                        </StyledContainer>
                    );
                }}
            </BreakpointsContext.Consumer>
        );
    }
}

ProfileLanding.propTypes = {
    subscribedTopics: PropTypes.any,
    subscribedSources: PropTypes.any,
    topics: PropTypes.arrayOf(PropTypes.shape({})),
    sources: PropTypes.arrayOf(PropTypes.shape({})),
    section: PropTypes.string,
    formik: PropTypes.shape({}).isRequired,
    sourcesData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    topicCategories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onTouch: PropTypes.func,
    section: PropTypes.string,
    setFormTouched: PropTypes.func,
    handlePreferencesChange: PropTypes.func,
    formTouched: PropTypes.bool,
};

ProfileLanding.defaultProps = {
    subscribedSources: [],
    subscribedTopics: [],
    topics: [],
    sources: [],
    section: null,
    setFormTouched: () => { },
    handlePreferencesChange: () => { },
    formTouched: false,
};

const mapStateToProps = state => ({
    subscribedTopics: state.basicProfile.topicIds,
    subscribedSources: state.basicProfile.sourceIds,
    topics: state.topics.all.categories,
    sources: state.sources.profileSources,
    urlHistoryList: state.UrlHistoryReducer.history,
    popupOpen: state.globalPopupContainer.isOpen,
});

const mapDispatchToProps = dispatch => ({
    setUrlHistoryItem: (value) => dispatch(addUrlHistoryItem(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileLanding);
