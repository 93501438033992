import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Slider from 'react-slick';
import Link from 'components/atomics/atoms/Link/Link';
import SliderButton from 'components/SliderButton/SliderButton';
import CoachMarks from 'containers/CoachMarks/CoachMarks';
import { TYPES } from 'containers/CoachMarks/CoachMarks';
import {
    DowJonesgLogo,
    BloombergLogo,
    IconPlayerPlay,
    IconStepperNext,
    IconStepperPrevious,
} from 'components/atomics/atoms/Icons/Icons';
import {
    BreakpointsContext,
    MOBILE,
} from 'components/Breakpoints/Breakpoints';
import {
    containerStyles,
    mobileContainerStyles,
    indicesContainerStyles,
    briefingContainerStyles,
    bloombergContainerStyles,
    indiceStyles,
    directionContainerStyles,
    briefingMobileContainerStyles,
    bloombergMobileContainerStyles,
    mobileIndiceContainerStyles,
    chipsContainerStyles,
    topChipContainerStyles,
    topChipBBContainerStyles,
    outerWrapperStyles,
    mobileContainer,
} from 'components/atomics/organisms/InfoBar/styles';
import labels from '../../../../lang/en.json';
import language from '../../../../lang/en.json';

const MobileContainer = styled.div`
    ${mobileContainer}
`;

export const MobileIndiceContainer = styled.div`
    ${mobileIndiceContainerStyles}
`;

export const BriefingMobileContainer = styled(Link)`
    ${briefingMobileContainerStyles}
`;

export const BloombergMobileContainer = styled(Link)`
    ${bloombergMobileContainerStyles}
`;

export const Container = styled.div`
    ${containerStyles}
`;

export const IndicesContainer = styled.div`
    ${indicesContainerStyles}
`;

export const BriefingContainer = styled(Link)`
    ${briefingContainerStyles}
`;

export const BloombergContainer = styled(Link)`
    ${bloombergContainerStyles}
`;

export const DirectionContainer = styled.div`
    ${directionContainerStyles}
`;

export const Indice = styled.div`
    ${indiceStyles}
`;

export const ChipsContainer = styled.div`
    ${chipsContainerStyles}
`;

export const TopChipContainer = styled.div`
    ${topChipContainerStyles}
`;

export const TopChipBBContainer = styled.div`
    ${topChipBBContainerStyles}
`;

export const OuterWrapper = styled.div`
    ${outerWrapperStyles};
`;

/*const sliderSettings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    arrows: true,
    variableWidth: true,
    nextArrow: <SliderButton id="btn-tools-next" aria-label={labels.translations['news.marketIndices.nextIndice']}
        data-analytics-placement="anchor : center"
        data-analytics-label="trackLink : anchor"
        data-analytics-id={labels.translations['news.marketIndices.nextIndice']}><IconStepperNext /></SliderButton>,
    prevArrow: <SliderButton id="btn-tools-prev" aria-label={labels.translations['news.marketIndices.prevIndice']}
        data-analytics-placement="anchor : center"
        data-analytics-label="trackLink : anchor"
        data-analytics-id={labels.translations['news.marketIndices.prevIndice']}><IconStepperPrevious /></SliderButton>,
};*/

/**
 * Creates the top infobar for news page, includes
 * market indicdes, bloomberg and market briefings linkage.
 */
class InfoBar extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.state = {
            showCoachMark: false,
        };
    }

    /**
     * Show coachmark on update if needed.
     */
    componentDidUpdate() {
        if (this.state.showCoachMark === false) {
            var self = this;

            setTimeout(function () {
                try {
                    self.setState({
                        showCoachMark: true,
                    });
                } catch (e) {
                    //do nothing
                }
            }, 2000);
        }
    }

    /**
     * Render a single indice.
     * @param {*} item
     */
    renderIndice(item) {
        const append = item.valueChange < 0 ? '' : '+';
        const direction = item.valueChange < 0 ? 'negative' : 'positive';

        return (
            <Indice key={item.indexName}>
                <DirectionContainer direction={direction}>
                    <span>
                        {item.indexName}
                    </span>
                    <span>
                        {append}{item.percentageChange}%
                    </span>
                    <em />
                </DirectionContainer>
            </Indice>
        );
    }

    /**
     * Render the market briefing title string.
     */
    getBriefingTitle() {
        const { type, dynamicBriefingMessageFlag } = this.props.marketBriefing;

        let translationBriefingTitle;

        if (dynamicBriefingMessageFlag) {
            translationBriefingTitle = 'infoBar.marketBriefing.labelLatest';
        } else {
            translationBriefingTitle = type === 'am' ? 'infoBar.marketBriefing.labelAM' : 'infoBar.marketBriefing.labelPM';
        }

        return translationBriefingTitle;
    }

    /**
     * Render the info bar and underlying components.
     */
    render() {
        //const settings = sliderSettings;
        const { onBriefingClick } = this.props;

        /*if (!this.props.showBriefing) {
            settings.slidesToShow = 3;
        } else {
            settings.slidesToShow = 4;
        }*/

        let backDJImg = '/images/dowjones-chip-background@3x.png';
        let backBloombergImg = '/images/bloomberg-chip-background@3x.png';

        return (
            <OuterWrapper>
                <CoachMarks
                    showCoachMark={this.state.showCoachMark}
                    type={TYPES.INFOBAR}
                >
                    <BreakpointsContext.Consumer>
                        {(breakpoint) => {
                            if (!this.props.marketBriefing || !this.props.showBriefing) return null;

                            if (breakpoint === MOBILE) {
                                return (
                                    <MobileContainer
                                        hasBriefing={this.props.marketBriefing && this.props.showBriefing}
                                    >
                                        <div>
                                            <div>
                                                <BriefingMobileContainer
                                                        onClick={() => onBriefingClick(`/marketBriefing/${this.props.marketBriefing.id}`)}
                                                        to={`/marketBriefing/${this.props.marketBriefing.id}`}
                                                    >
                                                        <span>
                                                            <FormattedMessage id={this.getBriefingTitle()} />
                                                        </span>
                                                        <DowJonesgLogo />
                                                </BriefingMobileContainer>
                                            </div>
                                        </div>
                                    </MobileContainer>
                                );
                            }

                            return (
                                <Container>
                                    {/*<div>
                                        <IndicesContainer>
                                            {this.props.marketIndices.marketQuotes
                                                && this.props.marketIndices.marketQuotes.length > 0
                                                && this.props.marketIndices.marketQuotes.map(item => this.renderIndice(item))}
                                        </IndicesContainer>
                                    </div>*/}
                                    <div>
                                        <ChipsContainer>
                                            <BriefingContainer
                                                data-analytics-placement="anchor : center"
                                                data-analytics-label="trackLink : anchor"
                                                data-analytics-id={language.translations[this.getBriefingTitle()]}
                                                to={`/marketBriefing/${this.props.marketBriefing.id}`}
                                            >
                                                <TopChipContainer
                                                    variant={{ backDJImg }}
                                                >
                                                    <FormattedMessage id={this.getBriefingTitle()} />
                                                </TopChipContainer>
                                                <div>
                                                    <DowJonesgLogo />
                                                </div>
                                            </BriefingContainer>
                                        </ChipsContainer>
                                    </div>
                                </Container>
                            );
                        }}
                    </BreakpointsContext.Consumer>
                </CoachMarks>
            </OuterWrapper>
        );
    }
}

InfoBar.propTypes = {
    marketIndices: PropTypes.shape({
        marketQuotes: PropTypes.array,
        lastUpdatedDate: PropTypes.string,
    }),
    marketBriefing: PropTypes.shape({
        id: PropTypes.string,
        published_at: PropTypes.string,
        type: PropTypes.string,
        dynamicBriefingMessageFlag: PropTypes.bool,
    }),
    showBriefing: PropTypes.bool,
};

InfoBar.defaultProps = {
    marketIndices: {},
    onBriefingClick: () => { },
    marketBriefing: {
        id: null,
        published_at: null,
        type: null,
        dynamicBriefingMessageFlag: false,

    },
    showBriefing: true,
}

export default InfoBar;
