import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconSeeMore } from 'components/atomics/atoms/Icons/Icons';
import { seeMoreIconStyles, seeMoreLinkStyles } from 'components/SeeMoreLink/styles';
import Button from 'components/Button/Button';

const StyledButton = styled(Button)`
    ${seeMoreLinkStyles};
`;
const StyledLinkIcon = styled(IconSeeMore)`
    ${seeMoreIconStyles};
`;

const SeeMoreButton = (props) => {
    const {
        className,
        children,
        onClick,
        theme,
    } = props;

    return (
        <StyledButton
            id={`see-more-${className}-button`}
            className={className}
            onClick={onClick}
            variant={{ theme }}
        >
            {children}
            <StyledLinkIcon />
        </StyledButton>
    );
};

SeeMoreButton.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    className: PropTypes.string,
    onClick: PropTypes.func,
    theme: PropTypes.oneOf([
        'light',
        'dark',
    ]),
};

SeeMoreButton.defaultProps = {
    children: null,
    className: '',
    onClick: () => {},
    theme: 'light',
};

export default SeeMoreButton;
