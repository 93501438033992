import {
    TOGGLE_SAVE_ARTICLE,
    TOGGLE_SAVE_ARTICLE_FAILURE,
    TOGGLE_SAVE_ARTICLE_SUCCESS,
    TOGGLE_SAVE_GUIDE,
    TOGGLE_SAVE_GUIDE_FAILURE,
    TOGGLE_SAVE_GUIDE_SUCCESS,
    TOGGLE_SAVE_WEBINAR,
    TOGGLE_SAVE_WEBINAR_FAILURE,
    TOGGLE_SAVE_WEBINAR_SUCCESS,
    TOGGLE_SAVE_CECREDIT,
    TOGGLE_SAVE_CECREDIT_FAILURE,
    TOGGLE_SAVE_CECREDIT_SUCCESS,
    TOGGLE_SAVE_PL_ARTICLE,
    TOGGLE_SAVE_PL_ARTICLE_FAILURE,
    TOGGLE_SAVE_PL_ARTICLE_SUCCESS,
} from 'state-management/constants/save';
import { TOGGLE_SAVE_TRENDING_TOPIC } from "../constants/allTopics.common";

export const toggleSaveArticle = (id, save, params = {}) => ({
    type: TOGGLE_SAVE_ARTICLE,
    id,
    save,
    ...params,
});

export const toggleSaveCecredit = (id, save, params = {}) => ({
    type: TOGGLE_SAVE_CECREDIT,
    id,
    save,
    ...params,
});

export const toggleSaveTrendingArticle = (id, save, params = {}) => ({
    type: TOGGLE_SAVE_TRENDING_TOPIC,
    id,
    save,
    ...params,
});


export const toggleSaveArticleFailure = (error, id) => ({
    type: TOGGLE_SAVE_ARTICLE_FAILURE,
    id,
    error,
});
export const toggleSaveArticleSuccess = (id, save) => ({
    type: TOGGLE_SAVE_ARTICLE_SUCCESS,
    id,
    save,
});

export const toggleSaveCecreditFailure = (error, id) => ({
    type: TOGGLE_SAVE_CECREDIT_FAILURE,
    id,
    error,
});

export const toggleSaveCecreditSuccess = (id, save) => ({
    type: TOGGLE_SAVE_CECREDIT_SUCCESS,
    id,
    save,
});

export const toggleSaveGuide = (id, save, params = {}) => ({
    type: TOGGLE_SAVE_GUIDE,
    id,
    save,
    ...params,
});

export const toggleSaveGuideSuccess = (id, save) => ({
    type: TOGGLE_SAVE_GUIDE_SUCCESS,
    id,
    save,
});

export const toggleSaveGuideFailure = (error, id, save) => ({
    type: TOGGLE_SAVE_GUIDE_FAILURE,
    id,
    error,
    save,
});

export const toggleSaveWebinar = (id, save, params = {}) => ({
    type: TOGGLE_SAVE_WEBINAR,
    id,
    save,
    ...params,
});

export const toggleSaveWebinarSuccess = (id, save) => ({
    type: TOGGLE_SAVE_WEBINAR_SUCCESS,
    id,
    save,
});

export const toggleSaveWebinarFailure = (error, id, save) => ({
    type: TOGGLE_SAVE_WEBINAR_FAILURE,
    id,
    error,
    save,
});

export const toggleSavePlArticle = (id, save, params = {}) => ({
    type: TOGGLE_SAVE_PL_ARTICLE,
    id,
    save,
    ...params,
});
export const toggleSavePlArticleFailure = (error, id) => ({
    type: TOGGLE_SAVE_PL_ARTICLE_FAILURE,
    id,
    error,
});

export const toggleSavePlArticleSuccess = (id, save) => ({
    type: TOGGLE_SAVE_PL_ARTICLE_SUCCESS,
    id,
    save,
});
