import { css } from 'styled-components';

import { FROM_DESKTOP, FROM_TABLET, FROM_TABLET_TO_DESKTOP } from 'utils/mediaQueries';

import {
    FONT_AVENIR_NEXT,
    COLORS,
} from 'utils/variables';

export const sidebarStyles = css`
    box-sizing: border-box;
    width: 100%;
    position: relative;

    p {
        margin-top: 0;
    }

    /* stylelint-disable */

    ${props => props.isSticky && css`
        max-width: 305px;

        @media screen and ${FROM_DESKTOP} {
            max-width: 450px;
        }
    `};

    /* stylelint-enable */

    @media screen and ${FROM_TABLET} {
    }

    @media screen and ${FROM_DESKTOP} {
    }

    div[data-rel="splash"] {
        position: absolute;
        left: -30px;
        width: 25px;
        top: 38px;
        height: 4px;
        background-image: linear-gradient(to left, ${COLORS.OCEAN}, ${COLORS.SAPPHIRE});

        @media screen and ${FROM_DESKTOP} {
            top: 69px;
            left: calc(((100vw - 1170px) / 2) * -1);
            width: calc(((100vw - 1170px) / 2) - 11px);
            min-width: 40px;
        }

        @media screen and (max-width: 1265px) and ${FROM_DESKTOP} {
            left: -48px;
        }

        @media screen and ${FROM_TABLET_TO_DESKTOP} {
            left: -38px;
            top: 58px;
            width: 30px;
        }

        /* add max width to hard set left at lower desktops */
`;

export const titleStyles = css`
    color: ${COLORS.BLACK};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 2px;
    margin: 0 0 16px;
    text-transform: uppercase;
    word-wrap: break-word;

    /* stylelint-disable */

    ${props => props.variant && props.variant.isAnimatable && !props.variant.isAnimated && css`
        opacity: 0;
    `};

    /* stylelint-enable */

    @media screen and ${FROM_TABLET} {
        font-size: 18px;
        line-height: 31px;
        letter-spacing: 1.38px;
        margin: 0 0 20px;
    }
`;

export const featuredTextStyles = css`
    color: ${COLORS.SAPPHIRE};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 24px;
    font-weight: 400;
    letter-spacing: -0.6px;
    line-height: 1.13;
    margin: 0 0 20px;
    word-wrap: break-word;

    /* stylelint-disable */

    ${props => props.variant && props.variant.isAnimatable && !props.variant.isAnimated && css`
        opacity: 0;
    `};

    /* stylelint-enable */

    @media screen and ${FROM_DESKTOP} {
        font-size: 42px;
        line-height: 52px;
        font-weight: 300;
        margin: 0 0 25px;
    }
`;

export const contentWrapperStyles = css`
    /* stylelint-disable */

    ${props => props.variant && props.variant.isAnimatable && !props.variant.isAnimated && css`
        opacity: 0;
    `};

    /* stylelint-enable */
`;
