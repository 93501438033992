import {
    fork, put, take, select, call,
} from 'redux-saga/effects';

import {
    CREATE_SOURCE_REQUEST, REQUEST_SOURCE_URL,
} from 'state-management/constants/requestSource';
import { createSourceRequestSuccess } from 'state-management/actions/requestSource';
import { setError } from 'state-management/actions/errors';
import Api from 'utils/api';

/**
 * Handle a user request for a new article source.
 */
function* requestSource() {
    while (true) {
        yield take(CREATE_SOURCE_REQUEST);
        const sourceName = yield select(state => state.requestSource.sourceName);

        try {
            yield call(Api.post, REQUEST_SOURCE_URL, {
                data: { name: sourceName.trim() },
            });
            yield put(createSourceRequestSuccess());
        } catch (e) {
            yield put(setError('requestSource.requestError'));
        }
    }
}

function* requestSourceSaga() {
    yield fork(requestSource);
}

export default requestSourceSaga;
