import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import { COLORS } from 'utils/variables';

export const imageTextContainer = css`
    display: flex;
    flex-direction: row;

    &>p {
        flex: 1;

        @media screen and ${FROM_DESKTOP} {
            margin-right: 104px;
        }
    }

    &>img {
        max-width: 300px;
        display: none;

        @media screen and ${FROM_DESKTOP} {
            display: block;
        }
    }
`;

export const mainContentContainer = css`
    background-color: ${COLORS.LIGHT_GRAY1};
    margin: 0;
    padding: 41px 0 0;

    @media screen and ${FROM_TABLET} {
        margin: 0;
        padding: 50px 0 0;
    }

    @media screen and ${FROM_DESKTOP} {
        background-color: ${COLORS.WHITE};
        margin: 0;
        padding: 0;
    }

    ${props => props.theme === 'dark' && css`
        background-color: ${COLORS.LIGHT_GRAY1} !important;

        @media screen and ${FROM_DESKTOP} {
            margin-top: 0;
        }
    `};

    ${props => props.variant === 'banner' && css`
        @media screen and ${FROM_DESKTOP} {
            height: 410px;
            overflow: hidden;
            padding-bottom: 0;
            margin-bottom: -17px;
        }
    `};
`;

export const descriptionStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-weight: 600;
    font-size: 24px;
    line-height: 28.8px;
    letter-spacing: -0.36px;
    margin: 0 auto;
    max-width: 1170px;

    @media screen and ${FROM_DESKTOP} {
        font-size: 36px;
        line-height: 50px;
        letter-spacing: -0.64px;
        padding-top: 100px;
    }
`;

export const contentContainerStyles = css`
    align-self: stretch;
    display: flex;
    flex-wrap: wrap;
    padding: 22px 0 48px;

    @media screen and ${FROM_TABLET} {
        margin-left: -24px;
        padding: 0;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-left: -48px;
        padding: 80px 0;
    }

    ${props => props.variant === 'lower' && css`
        margin-top: 0 !important;

        @media screen and ${FROM_DESKTOP} {
            &>article {
                margin-top: 20px;
            }
        }
    `};
`;

export const contentCardStyles = css`
    box-sizing: border-box;
    color: ${COLORS.BLACK};
    width: 100%;

    h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: -0.35px;
        margin: 32px 0 10px;
        margin-top: 30px;

        ${props => props.variant === 'first' && css`
            margin-top: 0;
        `};

        @media screen and ${FROM_TABLET} {
            margin-top: 0;
        }

        @media screen and ${FROM_DESKTOP} {
            font-size: 24px;
            line-height: 31.2px;
        }
    }

    p {
        font-size: 14px;
        line-height: 21px;
        margin: 0;

        @media screen and ${FROM_DESKTOP} {
            font-size: 18px;
            line-height: 24px;
        }
    }

    @media screen and ${FROM_TABLET} {
        padding-left: 24px;
        width: 33.33%;
    }

    @media screen and ${FROM_DESKTOP} {
        padding-left: 48px;
    }
`;

export const questionsSectionContainer = css`
    text-align: center;
    padding-bottom: 60px;

    @media screen and ${FROM_TABLET} {
        padding-bottom: 70px;
        padding-top: 26px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding-bottom: 120px;
        padding-top: 40px;
    }
`;

export const questionsHeadingStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-size: 24px;
    font-weight: 600;
    line-height: 28.8px;
    letter-spacing: -0.36px;
    margin: 0 auto 23px;

    @media screen and ${FROM_TABLET} {
        margin: 0 auto 29px;
        max-width: 100%;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 36px;
        line-height: 50px;
        letter-spacing: -0.64px;
    }
`;

export const metricsContainerStyles = css`
    background: ${COLORS.SAPPHIRE};
    color: ${COLORS.WHITE};
`;

export const metricsContentStyles = css`
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 70px;

    @media screen and ${FROM_TABLET} {
        padding-bottom: 0;
    }
`;

export const metricStyles = css`
    padding-top: 50px;
    text-align: center;
    width: 100%;

    p + p {
        color: ${COLORS.WHITE};
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.21px;
        font-weight: 500;
        margin: 0 auto;
        max-width: 236px;

        @media screen and ${FROM_DESKTOP} {
            font-size: 24px;
            line-height: 30px;
        }
    }

    p:first-child,
    p:first-child b {
        font-size: 36px;
        letter-spacing: -2.5px;
        font-weight: normal;
        margin: 0;

        @media screen and ${FROM_DESKTOP} {
            font-size: 64px;
            letter-spacing: -4.44px;
        }
    }

    @media screen and ${FROM_TABLET} {
        padding: 54px 0 66px;
        width: 33.33%;
        font-weight: 500;

        p + p {
            font-size: 18px;
            line-height: 29px;
        }

        p:first-child {
            font-size: 48px;
        }
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 68px 0 63px;

        p + p {
            font-size: 24px;
            line-height: 30px;
            font-weight: 500;
            letter-spacing: -0.21px;
        }
    }
`;
