import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import {
    COLORS,
    FONT_AVENIR_NEXT,
} from 'utils/variables';

export const listWrapperStyles = css`
    clear: both;
    display: flex;
    flex-direction: column;
    max-width: 800px;

    ${props => props.noMaxWidth && css`
        max-width: initial;
    `}
`;

export const defaultPageWrapperStyles = css`
    display: flex;
    flex-direction: column;
`;

export const pagerWrapperStyles = css`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: -10px 0 54px 0;
    width: 100%;

    @media screen and ${FROM_TABLET} {
        margin-bottom: 70px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 110px;
    }
`;

export const pageButtonsWrapperStyles = css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin: 0 30px;
`;

export const pageButtonStyles = css`
    background: transparent;
    border: none;
    color: ${COLORS.MEDIUM_GRAY2};
    cursor: pointer;
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.2px;
    padding: 4px 6px;

    /* stylelint-disable */

    ${({ isCurrent }) => isCurrent && css`
        color: ${COLORS.SAPPHIRE};
        font-weight: 700;
        letter-spacing: -0.1px;
    `}

    /* stylelint-enable */
`;

export const arrowButtonStyles = css`
    background: transparent;
    border: none;
    cursor: pointer;
    fill: ${COLORS.SAPPHIRE};

    /* stylelint-disable */

    ${props => props.disabled && css`
        fill: ${COLORS.LIGHT_GRAY3};
        cursor: default;
    `}
`;

export const ellipsisStyles = css`
    padding: 0 6px;
`;
