import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { resultHeaderContainerStyles } from 'containers/Search/styles';
import { Container } from 'components';
import Title from 'components/atomics/atoms/Title/Title';

export const StyledResultsHeaderContainer = styled.div`
    ${resultHeaderContainerStyles}
`;

/**
 * Search results header (when results are present).
 * @param {*} props 
 */
const ResultsHeader = props => (
    <StyledResultsHeaderContainer>
        <Container>
            <Title>
                <FormattedMessage
                    id="search.results.query"
                    values={{
                        value: props.searchValue,
                    }}
                />
            </Title>
            <p>
                <FormattedMessage
                    id="search.resultsCount"
                    values={{
                        count: props.resultsCount,
                    }}
                />
            </p>
        </Container>
    </StyledResultsHeaderContainer>
);

ResultsHeader.propTypes = {
    searchValue: PropTypes.string.isRequired,
    resultsCount: PropTypes.number.isRequired,
};

export default ResultsHeader;
