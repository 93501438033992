import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import DropDownMolecule from 'components/atomics/molecules/Dropdown/Dropdown';
import OnboardingContent from 'containers/Onboarding/OnboardingContent/OnboardingContent';
import OnboardingSidebar from 'containers/Onboarding/OnboardingSidebar/OnboardingSidebar';
import { setOnBoardingStep as setOnBoardingStepAction }
    from 'state-management/actions/onboarding';
import convertClassificationToDropdownOptions
    from 'utils/formatters/advisorClassificationFormatters';
import { addMessage as addAriaNotificationAction } from 'state-management/actions/ariaNotification';
import {
    FieldText,
    Sticky,
    StickyContainer,
} from 'components';
import { STEP_NUMS } from 'state-management/constants/onboarding';
import { KEY_NUMBERS_AND_SERVICE } from 'utils/keyCodes';
import {
    onboardingSectionContainerStyles,
    onboardingSectionTextStyles,
    onboardingSectionTextSmallStyles,
    onboardingSectionErrorStyles,
    stickyContainerStyles,
} from 'containers/Onboarding/styles';
import {
    dropdownWrapperStyles,
    zipInputWrapperStyles,
    fieldTextStyles,
    labelStyles,
    sectionStyles,
} from 'containers/Onboarding/AboutYou/styles';
import OnboardingErrors from 'containers/Onboarding/OnboardingErrors/OnboardingErrors';

const StyledStickyContainer = styled(StickyContainer)`
    ${stickyContainerStyles}
`;

const StyledSectionContainer = styled.div`
    ${onboardingSectionContainerStyles}
`;

const StyledSidebarText = styled.p`
    ${onboardingSectionTextStyles}
`;

const StyledLabel = styled.div`
    ${labelStyles}
`;

const StyledSection = styled.div`
    ${sectionStyles}
`;

const StyledSidebarTextSmall = styled.p`
    ${onboardingSectionTextSmallStyles}
`;

export const StyledDropdownWrapper = styled.div`
    ${dropdownWrapperStyles}
`;

export const StyledErrorMessage = styled.div`
    ${onboardingSectionErrorStyles}
`;

export const StyledZipInputWrapper = styled.div`
    ${zipInputWrapperStyles}
`;

export const StyledFieldText = styled(FieldText)`
    ${fieldTextStyles}
`;

/**
 * Creates the about you page for onboarding.
 */
export class AboutYou extends React.Component {
    /**
     * Handle zip code purification.
     * @param {*} e 
     */
    static filterZipInput(e) {
        if (KEY_NUMBERS_AND_SERVICE.indexOf(e.keyCode) === -1 && !e.ctrlKey) {
            e.preventDefault();
        }
    }

    /**
     * Set onboarding step and aria text at mount.
     */
    componentDidMount() {
        this.props.setOnBoardingStep(STEP_NUMS.ABOUT_YOU);
        this.props.addAriaNotification('onboarding.aboutYou.loaded', {
            translated: false,
        });
    }

    /**
     * Handle value changes in form.
     * @param {*} key 
     * @param {*} value 
     * @param {*} arrayIds 
     * @param {*} checked 
     */
    onChange(key, value, arrayIds, checked) {
        this.props.changeStatus({
            [key]: value,
        });
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const { formatMessage } = this.props.intl;
        const {
            aums,
            household,
            durations,
            retirement,
            retirementPlans,
            isMobile,
            serviceOfferings,
            isZipValid,
            filters,
        } = this.props;
        const {
            serviceOfferingIds,
            amountId,
            durationId,
            zip,
            wmAUMId,
            wmHouseholdId,
            retirementPlanAUMId,
            retirementPlanPlansId,
        } = this.props.profile;

        if (!filters) {
            return null;
        }

        return (
            <StyledSectionContainer>
                <StyledStickyContainer>
                    <Sticky
                        enabled={!isMobile}
                        id="sticky-about-desc"
                        topOffset={40}
                    >
                        <OnboardingSidebar
                            isAnimatable
                            isLoaded
                            featuredText={(
                                <FormattedMessage id="onboarding.aboutYou.heading" />
                            )}
                            sectionTitle={(
                                <FormattedMessage id="onboarding.aboutYou.title" />
                            )}
                        >
                            <div data-rel="splash" />
                            <StyledSidebarText>
                                <FormattedMessage id="onboarding.aboutYou.text" />
                            </StyledSidebarText>
                            <StyledSidebarTextSmall>
                                <FormattedMessage id="onboarding.aboutYou.secondaryText" />
                            </StyledSidebarTextSmall>
                            <OnboardingErrors
                                validationMessageId="onboarding.aboutYou.zipValidationMessage"
                                showValidationError={!this.props.isZipValid}
                                errorRef={this.props.errorRef}
                                globalErrors={this.props.globalErrors}
                            />
                        </OnboardingSidebar>
                    </Sticky>
                    <OnboardingContent
                        key="about-us-onboarding-content"
                    >
                        <div>
                            <StyledLabel>
                                <FormattedMessage id="onboarding.aboutYou.wealth" />
                            </StyledLabel>

                            <StyledSection>
                                <DropDownMolecule
                                    id="dropdown-aum"
                                    title={formatMessage({ id: 'onboarding.aboutYou.wealth.aum' })}
                                    ariaLabel={formatMessage({ id: 'onboarding.aboutYou.wealth.aum' })}
                                    emptyOptionLabel={formatMessage({ id: 'onboarding.aboutYou.wealth.aum' })}
                                    isEmptyOptionSelectable={true}
                                    value={wmAUMId}
                                    options={convertClassificationToDropdownOptions(aums)}
                                    onValueChanged={val => this.onChange('wmAUMId', val)}
                                />

                                <DropDownMolecule
                                    id="dropdown-household"
                                    title={formatMessage({ id: 'onboarding.aboutYou.wealth.household' })}
                                    ariaLabel={formatMessage({ id: 'onboarding.aboutYou.wealth.household' })}
                                    emptyOptionLabel={formatMessage({ id: 'onboarding.aboutYou.wealth.household' })}
                                    isEmptyOptionSelectable={true}
                                    value={wmHouseholdId}
                                    options={convertClassificationToDropdownOptions(household)}
                                    onValueChanged={val => this.onChange('wmHouseholdId', val)}
                                />
                            </StyledSection>

                            <StyledLabel>
                                <FormattedMessage id="onboarding.aboutYou.retirement" />
                            </StyledLabel>

                            <StyledSection>
                                <DropDownMolecule
                                    id="dropdown-aumRetirement"
                                    title={formatMessage({ id: 'onboarding.aboutYou.retirement.aum' })}
                                    ariaLabel={formatMessage({ id: 'onboarding.aboutYou.retirement.aum' })}
                                    emptyOptionLabel={formatMessage({ id: 'onboarding.aboutYou.retirement.aum' })}
                                    isEmptyOptionSelectable={true}
                                    value={retirementPlanAUMId}
                                    options={convertClassificationToDropdownOptions(retirement)}
                                    onValueChanged={val => this.onChange('retirementPlanAUMId', val)}
                                />

                                <DropDownMolecule
                                    id="dropdown-plans"
                                    title={formatMessage({ id: 'onboarding.aboutYou.retirement.plans' })}
                                    ariaLabel={formatMessage({ id: 'onboarding.aboutYou.retirement.plans' })}
                                    emptyOptionLabel={formatMessage({ id: 'onboarding.aboutYou.retirement.plans' })}
                                    isEmptyOptionSelectable={true}
                                    value={retirementPlanPlansId}
                                    options={convertClassificationToDropdownOptions(retirementPlans)}
                                    onValueChanged={val => this.onChange('retirementPlanPlansId', val)}
                                />
                            </StyledSection>

                            <StyledLabel>
                                <FormattedMessage id="onboarding.aboutYou.about" />
                            </StyledLabel>

                            <StyledSection>
                                <DropDownMolecule
                                    id="dropdown-experience"
                                    title={formatMessage({ id: 'onboarding.aboutYou.about.years' })}
                                    ariaLabel={formatMessage({ id: 'onboarding.aboutYou.about.years' })}
                                    emptyOptionLabel={formatMessage({ id: 'onboarding.aboutYou.about.years' })}
                                    isEmptyOptionSelectable={true}
                                    value={durationId}
                                    options={convertClassificationToDropdownOptions(durations)}
                                    onValueChanged={val => this.onChange('durationId', val)}
                                />
                            </StyledSection>
                        </div>
                    </OnboardingContent>
                </StyledStickyContainer>
            </StyledSectionContainer>
        );
    }
}

AboutYou.propTypes = {
    addAriaNotification: PropTypes.func.isRequired,
    changeStatus: PropTypes.func.isRequired,
    aums: PropTypes.arrayOf(PropTypes.object).isRequired,
    durations: PropTypes.arrayOf(PropTypes.object).isRequired,
    isMobile: PropTypes.bool.isRequired,
    serviceOfferings: PropTypes.arrayOf(PropTypes.object).isRequired,
    profile: PropTypes.shape({
        serviceOfferingIds: PropTypes.arrayOf(PropTypes.number),
        zip: PropTypes.string,
        durationId: PropTypes.number,
        amountId: PropTypes.number,
    }),
    isZipValid: PropTypes.bool.isRequired,
    setOnBoardingStep: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    errorRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    globalErrors: PropTypes.arrayOf(PropTypes.shape),
    filters: PropTypes.shape({}),
};

AboutYou.defaultProps = {
    profile: {
        serviceOfferingIds: [],
        zip: '',
        durationId: undefined,
        amountId: undefined,
    },
    errorRef: React.createRef(),
    globalErrors: [],
    filters: null,
};

const mapDispatchToProps = dispatch => ({
    addAriaNotification: (message, options) => (
        dispatch(addAriaNotificationAction(message, options))
    ),
    setOnBoardingStep: idx => dispatch(setOnBoardingStepAction(idx)),
});

export default connect(null, mapDispatchToProps)(injectIntl(AboutYou));
