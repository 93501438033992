import {
    ANY_TIME,
    APPLY_FILTERS,
    ARTICLE,
    CLEAR_ALL,
    CLEAR_SECTION,
    DATE_SECTION,
    GUIDE,
    LAST_24_HOURS,
    PAST_5_DAYS,
    PAST_MONTH,
    RESET_SEARCH_FILTER,
    RESET_SECTION,
    SET_SECTION,
    SOURCES_SECTION,
    TOGGLE_ITEM,
    TOGGLE_DATE_ITEM,
    TOGGLE_OPEN,
    TOPICS_SECTION,
    TYPE_SECTION,
} from 'state-management/constants/searchFilter';

export const articleSelection = {
    id: ARTICLE,
    checked: false,
    translationId: 'search.filters.sections.type.news',
};

export const guideSelection = {
    id: GUIDE,
    checked: false,
    translationId: 'search.filters.sections.type.practiceManagement',
};

export const initialTypeState = {
    title: TYPE_SECTION,
    items: [],
};

export const initialDateState = {
    title: DATE_SECTION,
    items: [
        {
            id: LAST_24_HOURS,
            checked: false,
            translationId: 'search.filters.sections.date.last24Hours',
        },
        {
            id: PAST_5_DAYS,
            checked: false,
            translationId: 'search.filters.sections.date.past5Days',
        },
        {
            id: PAST_MONTH,
            checked: false,
            translationId: 'search.filters.sections.date.pastMonth',
        },
        {
            id: ANY_TIME,
            checked: true,
            translationId: 'search.filters.sections.date.anyTime',
        },
    ],
};

export const initialTopicsState = {
    items: [],
    title: TOPICS_SECTION,
};

export const initialSourcesState = {
    items: [],
    title: SOURCES_SECTION,
};

const initialState = {
    filtersApplied: null,
    isOpen: false,
    sections: {
        [DATE_SECTION]: { ...initialDateState },
        [TYPE_SECTION]: { ...initialTypeState },
        [TOPICS_SECTION]: { ...initialTopicsState },
        [SOURCES_SECTION]: { ...initialSourcesState },
    },
};

const clearItem = item => ({ ...item, checked: false });

const toggleItem = item => ({ ...item, checked: !item.checked });

const clearSection = section => ({
    ...section,
    items: section.items.map(clearItem),
});

const clearAllSections = sections => Object
    .keys(sections)
    .reduce((total, section) => ({
        ...total,
        [section]: (section !== DATE_SECTION
            ? clearSection(sections[section])
            : ({ ...initialState.sections[section] })),
    }), {});

const clearUnappliedFilters = ({ filtersApplied, sections }) => (filtersApplied
    ? { ...filtersApplied }
    : clearAllSections(sections));

function searchFilterReducer(state = initialState, action = {}) {
    switch (action.type) {
    case APPLY_FILTERS: {
        return {
            ...state,
            filtersApplied: { ...state.sections },
        };
    }
    case CLEAR_ALL: {
        return {
            ...state,
            sections: clearAllSections(state.sections),
        };
    }
    case CLEAR_SECTION: {
        const { section } = action;

        return {
            ...state,
            sections: {
                ...state.sections,
                [section]: clearSection(state.sections[section]),
            },
        };
    }
    case RESET_SEARCH_FILTER:
        return {
            ...initialState,
            sections: clearAllSections(state.sections),
        };
    case RESET_SECTION: {
        const { section } = action;

        return {
            ...state,
            sections: {
                ...state.sections,
                [section]: { ...initialState.sections[section] },
            },
        };
    }
    case SET_SECTION: {
        const { section, name } = action;

        return {
            ...state,
            sections: {
                ...state.sections,
                [name]: { ...section },
            },
        };
    }
    case TOGGLE_DATE_ITEM: {
        const { idx } = action;

        return {
            ...state,
            sections: {
                ...state.sections,
                [DATE_SECTION]: {
                    ...state.sections[DATE_SECTION],
                    items: state.sections[DATE_SECTION].items
                        .map((item, i) => ({ ...item, checked: idx === i })),
                },
            },
        };
    }
    case TOGGLE_ITEM: {
        const { section, idx } = action;
        return {
            ...state,
            sections: {
                ...state.sections,
                [section]: {
                    ...state.sections[section],
                    items: state.sections[section].items
                        .map((item, i) => (idx === i ? toggleItem(item) : item)),
                },
            },
        };
    }
    case TOGGLE_OPEN: {
        return {
            ...state,
            isOpen: action.open,
            sections: !action.open && action.shouldClearUnappliedFilters
                ? clearUnappliedFilters(state)
                : state.sections,
        };
    }
    default:
        return state;
    }
}

export default searchFilterReducer;
