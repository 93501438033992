import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getMarketBriefings } from 'state-management/actions/marketBriefing.common';
import MarketBriefingArchiveDisplay from 'components/MarketBriefing/archive/MarketBriefingArchive';
import scrollHelper from "../../../utils/scrollToTop";

/**
 * Container for displaying the market briefing archive component.
 */
export class MarketBriefingArchive extends React.Component {
    /**
     * Get the needed data at mount.
     */
    componentDidMount() {
        scrollHelper.scrollToTop();

        this.props.getMarketBriefings();
    }

    /**
     * Render the components.
     */
    render() {
        if (this.props.marketBriefings) {
            return (
                <MarketBriefingArchiveDisplay marketBriefings={this.props.marketBriefings} />
            );
        }
        return null;
    }
}

MarketBriefingArchive.propTypes = {
    marketBriefings: PropTypes.arrayOf(PropTypes.shape({})),
    getMarketBriefings: PropTypes.func,
};

MarketBriefingArchive.defaultProps = {
    marketBriefings: [],
    getMarketBriefings: () => {},
};

const mapStateToProps = state => ({
    marketBriefings: state.marketBriefing.briefingsArchive,
});

const mapDispatchToProps = dispatch => ({
    getMarketBriefings: () => dispatch(getMarketBriefings()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketBriefingArchive);
