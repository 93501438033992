import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ImageAtom from 'components/atomics/atoms/Image/Image';
import {
    containerStyles,
    contentContainerStyles,
    descriptionStyles,
    imageContainerStyles,
    mainContainerStyles,
    speakerNameStyles,
    sectionHeadingStyles,
    speakerDesignationStyles,
} from 'components/atomics/organisms/Speaker/styles';

export const ImageContainer =  styled.div`
    ${imageContainerStyles}
`;

export const MainContainer =  styled.div`
    ${mainContainerStyles}
`;

export const Container = styled.div`
    ${containerStyles}
`;

export const ContentContainer = styled.div`
    ${contentContainerStyles}
`;

export const SpeakerName = styled.h3`
    ${speakerNameStyles}
`;

export const SectionHeading = styled.div`
    ${sectionHeadingStyles}
`;

export const Descriptions = styled.div`
    ${descriptionStyles}
`;

export const SpeakerDesignation = styled.div`
    ${speakerDesignationStyles}
`;

/**
 * Creates a webinar content card.
 */
class Speaker extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);
    }

    render() {
        const { dataPage } = this.props;

        if (this.props.item === undefined) {
            return null;
        }   

        const imageSource = this.props.item.image.includes('http') ? this.props.item.image : '/api/content-service/assets/content-image?filename=/' + this.props.item.image;

        return (
            <Container dataPage={dataPage}>
                <MainContainer dataPage={dataPage}>
                    <ImageContainer >
                        <ImageAtom
                            src={imageSource}
                        />
                    </ImageContainer>
                    <ContentContainer dataPage={dataPage}>
                        <SpeakerName>
                            {this.props.item.name}
                        </SpeakerName>
                        <SpeakerDesignation>
                            {this.props.item.designation}                           
                        </SpeakerDesignation>
                        <Descriptions
                            dangerouslySetInnerHTML={{ __html: this.props.item.description}}
                        />
                    </ContentContainer>
                </MainContainer>
            </Container>
        );
    }
}

Speaker.propTypes = {
    item: PropTypes.shape({
        name: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        designation: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,       
    }).isRequired,
};

export default Speaker;
