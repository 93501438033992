import { css } from 'styled-components';
import { COLORS } from '../../utils/variables';

export const logoContainerStyles = css`
    align-items: left;

    svg{
        fill: ${COLORS.BLACK} !important;
        display: block;
        padding-bottom:5px;
        margin: 0;
        height: 41px;
        width: 185px;
    }
`;

export const logoIconStyles = css`
`;

export const emblemIconStyles = css`
    margin-left: 14px;
`;

export const poweredByIconStyles = css`
    fill: ${COLORS.MEDIUM_GRAY1};
    margin-left: 14px;
    width: 105px;
`;
