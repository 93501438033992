import { NOT_FOUND_ERROR, SERVER_ERROR, FORBIDDEN_ERROR  } from 'state-management/constants/errors';

export const notFoundErrorMessages = {
    errorType: NOT_FOUND_ERROR,
    title: 'global.errors.notFound',
    text: 'global.errors.notFound.description',
    code: '404',
};
export const internalServerErrorMessages = {
    errorType: SERVER_ERROR,
    title: 'global.errors.serverError',
    text: 'global.errors.serverError.description',
    code: '500',
};
export const forbiddenErrorMessages = {
    errorType: FORBIDDEN_ERROR,
    title: 'global.errors.forbiddenError',
    text: 'global.errors.serverError.description',
    code: '403',
};

