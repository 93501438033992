
import React from 'react';
import { IntlProvider, intlShape } from 'react-intl';
import Enzyme from 'enzyme';
import Adapter from '@wojtekmaj/enzyme-adapter-react-17';
import { mount, shallow } from 'enzyme';
import lang from '../../app/lang/en.json';

//required to use 'mount' on react 17+
Enzyme.configure({ adapter: new Adapter() });

// Create the IntlProvider to retrieve context for wrapping around.
const intlProvider = new IntlProvider({ locale: 'en', messages: lang.translations }, {});


export const { intl } = intlProvider.getChildContext();

function nodeWithIntlProp(node) {
    return React.cloneElement(node, { intl });
}

export function shallowWithIntl(node, { context, ...additionalOptions } = {}) {
    return shallow(
        nodeWithIntlProp(node),
        {
            context: Object.assign({}, context, { intl }),
            ...additionalOptions,
        },
    );
}

export function mountWithIntl(node, { context, childContextTypes, ...additionalOptions } = {}) {
    return mount(
        nodeWithIntlProp(node),
        {
            context: Object.assign({}, context, { intl }),
            childContextTypes: Object.assign({}, { intl: intlShape }, childContextTypes),
            ...additionalOptions,
        },
    );
}
