import { css } from 'styled-components';
import { COLORS } from 'utils/variables';

export const logoContainerStyles = css`
    align-items: center;
    display: inline-flex;
`;

export const logoIconStyles = css`
    fill: ${COLORS.DARK_SAPPHIRE};
`;

export const emblemIconStyles = css`
    margin-left: 11px;
`;

export const poweredByIconStyles = css`
    fill: ${COLORS.MEDIUM_GRAY};
    margin-left: 11px;
`;

export const luminIconStyles = css`
    width: 185px;
    height: 41px;
`;
