import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container, CoverImage, Heading } from 'components';
import { BreakpointsContext, MOBILE } from 'components/Breakpoints/Breakpoints';
import {
    wrapperStyles,
    imageWrapperStyles,
    titleContainerStyles,
    titleStyles,
    subtitleStyles,
} from 'components/ImageHeading/styles';
import language from '../../lang/en.json';

const StyledWrapper = styled.div`
    ${wrapperStyles}
`;
const StyledImageWrapper = styled.div`
    ${imageWrapperStyles}
`;
const StyledTitleContainer = styled(Container)`
    ${titleContainerStyles}
`;
const StyledTitle = styled(Heading)`
    ${titleStyles}
`;
export const StyledSubtitle = styled.span`
    ${subtitleStyles}
`;

const ImageHeadingWrapper = props => (
    <BreakpointsContext.Consumer>
        {breakpoint => (
            <ImageHeading {...props} breakpoint={breakpoint} />
        )}
    </BreakpointsContext.Consumer>
);

export const ImageHeading = ({
    title, subtitle, image, smallImage, breakpoint,
}) => {
    const imageSource = breakpoint === MOBILE ? smallImage : image;

    return (
        <StyledWrapper>
            {imageSource && (
                <StyledImageWrapper>
                    <CoverImage
                        src={imageSource} forGuidePage = {false} alt={language.translations['global.alt.image']} />
                </StyledImageWrapper>
            )}
            <StyledTitleContainer>
                <StyledTitle
                    level={1}
                    theme="dark"
                >
                    {title}
                </StyledTitle>
                {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
            </StyledTitleContainer>
        </StyledWrapper>
    );
};

ImageHeading.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    image: PropTypes.string,
    smallImage: PropTypes.string,
    breakpoint: PropTypes.string.isRequired,
};

ImageHeading.defaultProps = {
    subtitle: undefined,
    image: undefined,
    smallImage: undefined,
};

export default ImageHeadingWrapper;
