import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import ClickableContainer from 'components/ClickableContainer/ClickableContainer';
import { ToolIcon } from 'components/atomics/atoms/Icons/Icons';
import { ArrowPaginationIcon } from 'components/atomics/atoms/Icons';
import {
    clickableContainerStyles,
    toolsContentStyles,
    toolsHeadingContainer,
    toolsDescriptionStyles,
    toolsHeadingStyles,
    linkStyles,
    toolsTitleStyles,
    toolsLinkWrapperStyles,
    toolsLinkArrowStyles,
    toolsLinkStyles,
} from 'components/ToolCard/styles';
import { Heading, Link, SanitizedHTML } from 'components';

const StyledClickableContainer = styled(ClickableContainer)`
    ${clickableContainerStyles}
`;
const StyledToolsContent = styled.div`
    ${toolsContentStyles}
`;
const StyledToolsHeadingContainer = styled.div`
    ${toolsHeadingContainer}
`;
const StyledLink = styled(Link)`
    ${linkStyles}
`;
const StyledToolsHeading = styled.div`
    ${toolsHeadingStyles}
`;
const StyledToolsTitle = styled(Heading)`
    ${toolsTitleStyles}
`;
const StyledToolsDescription = styled(SanitizedHTML)`
    ${toolsDescriptionStyles}
`;
const StyledToolsLinkWrapper = styled.div`
    ${toolsLinkWrapperStyles}
`;
const StyledArrowIcon = styled(ArrowPaginationIcon)`
    ${toolsLinkArrowStyles}
`;
const StyledToolsLink = styled.a`
    ${toolsLinkStyles}
`;

const ToolCard = (props) => {
    const { tool, clickableCard, horizontalCard } = props;

    const renderTitle = () => {
        if (clickableCard) {
            return (
                <StyledToolsTitle
                    level={2}
                    variant={{ clickableCard }}
                >
                    <StyledLink to={tool.link} target="_blank">
                        {tool.name}
                    </StyledLink>
                </StyledToolsTitle>
            );
        }

        return (
            <StyledToolsTitle
                level={2}
                variant={{ clickableCard }}
            >
                {tool.name}
            </StyledToolsTitle>
        );
    };

    const renderBottomLink = () => {
        if (!clickableCard) {
            return (
                <StyledToolsLinkWrapper
                    horizontalCard={horizontalCard}
                >
                    <StyledToolsLink
                        href={tool.link}
                        target="_blank"
                    >
                        <FormattedMessage id="guidePage.tools.link" />
                        <StyledArrowIcon
                            height={14}
                            width={17}
                        />
                    </StyledToolsLink>
                </StyledToolsLinkWrapper>
            );
        }

        return null;
    };

    const renderCardContent = () => (
        <React.Fragment>
            <StyledToolsHeadingContainer>
                <ToolIcon />
                <StyledToolsHeading>
                    <FormattedMessage
                        id="guidePage.tool"
                    />
                </StyledToolsHeading>
            </StyledToolsHeadingContainer>
            {renderTitle()}
            <StyledToolsDescription
                htmlContent={tool.description}
                tag="div"
                variant={{ clickableCard }}
            />
            {renderBottomLink()}
        </React.Fragment>
    );

    const StyledToolCard = () => {
        if (clickableCard) {
            return (
                <StyledClickableContainer
                    url={tool.link}
                >
                    {renderCardContent()}
                </StyledClickableContainer>
            );
        }

        return (
            <StyledToolsContent
                horizontalCard={horizontalCard}
            >
                {renderCardContent()}
            </StyledToolsContent>
        );
    };

    return (
        <StyledToolCard />
    );
};

ToolCard.propTypes = {
    tool: PropTypes.shape({
        name: PropTypes.string.isRequired,
        code: PropTypes.string,
        link: PropTypes.string,
        description: PropTypes.string,
    }).isRequired,
    horizontalCard: PropTypes.bool,
    clickableCard: PropTypes.bool,
};

ToolCard.defaultProps = {
    horizontalCard: false,
    clickableCard: true,
};

export default ToolCard;
