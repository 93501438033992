import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
    jargonContainerStyles,
    jargonStyles,
    translationContainerStyles,
    translationHeadingStyles,
    translationStyles,
} from 'components/Jargon/styles';
import { SanitizedHTML } from 'components';

const StyledJargonContainer = styled.div`
    ${jargonContainerStyles}
`;
const StyledJargon = styled.div`
    ${jargonStyles}
`;
const StyledTranslationContainer = styled.div`
    ${translationContainerStyles}
`;
const StyledTranslationHeading = styled.div`
    ${translationHeadingStyles}
`;
export const StyledTranslation = styled(SanitizedHTML)`
    ${translationStyles}
`;

const areAttributesValid = ({ data: { term, definition } }) => term && definition;

const JargonItem = props => (
    areAttributesValid(props) && (
        <StyledJargonContainer id={props.id}>
            <StyledJargon>
                {props.data.term}
            </StyledJargon>
            <StyledTranslationContainer>
                <StyledTranslationHeading>
                    <FormattedMessage id="jargon.definition" />
                </StyledTranslationHeading>
                <StyledTranslation
                    htmlContent={props.data.definition}
                    tag="div"
                />
            </StyledTranslationContainer>
        </StyledJargonContainer>
    ));

JargonItem.propTypes = {
    data: PropTypes.shape({
        term: PropTypes.string,
        definition: PropTypes.string,
    }).isRequired,
    id: PropTypes.string.isRequired,
};
export default JargonItem;