import {
    GET_TOPICS_SUCCESS,
    GET_ONBOARDING_TOPICS,
    SET_LOADING_TOPICS_LIST_STATUS,
    GET_ONBOARDING_TOPICS_SUCCESS,
    GET_ONBOARDING_TOPICS_FAIL,
    GET_TOPICS,
} from 'state-management/constants/topics';

const initialState = {
    all: {
        categories: [],
        isLoaded: false,
    },
    onboarding: {
        categories: [],
    },
    isLoaded: false,
    isLoading: false,
    isLoadingTopicsList: false,
};

function topicsReducer(state = initialState, action = {}) {
    switch (action.type) {
    case GET_TOPICS:
    case GET_ONBOARDING_TOPICS:
        return {
            ...state,
            isLoading: true,
        };
    case SET_LOADING_TOPICS_LIST_STATUS:
        return {
            ...state,
            isLoadingTopicsList: true,
        };
    case GET_TOPICS_SUCCESS:
        return {
            ...state,
            all: {
                categories: action.data,
                isLoaded: true,
            },
            isLoaded: true,
            isLoadingTopicsList: false,
            isLoading: false,
        };
    case GET_ONBOARDING_TOPICS_SUCCESS:
        return {
            ...state,
            onboarding: {
                categories: action.data,
            },
            isLoading: false,
            isLoaded: true,
        };
    case GET_ONBOARDING_TOPICS_FAIL:
        return {
            ...state,
            isLoading: false,
            isLoaded: true,
        };
    default:
        return state;
    }
}

export default topicsReducer;
