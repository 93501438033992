import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Link from 'components/Link/Link';

import {
    itemStyles, linkStyles, listStyles,
} from 'components/TopicsList/styles';
import { SIZE } from 'components/ContentCard/constants';
import { TOPIC_LINK } from 'state-management/constants/topics';

const StyledList = styled.ul`
    ${listStyles}
`;
const StyledListItem = styled.li`
    ${itemStyles}
`;
const StyledLink = styled(Link)`
    ${linkStyles}
`;

const TopicsList = (props) => {
    const {
        className,
        internal,
        limit,
        size,
        topics,
        onTopicClick
    } = props;
    const limitedTopics = limit ? topics.slice(0, limit) : topics;

    return (
        <StyledList
            className={className}
        >
            {
                limitedTopics.map((topic) => {
                    const { followed } = topic;
                    return (
                        <StyledListItem
                            key={topic.id}
                            variant={{ followed, internal, size }}
                            onClick={() => onTopicClick(topic.name)}
                        >
                            <StyledLink
                                to={TOPIC_LINK(`${topic.name}/${topic.id}`)}
                            >
                                {topic.name}
                            </StyledLink>
                        </StyledListItem>
                    );
                })
            }
        </StyledList>
    );
};

TopicsList.propTypes = {
    className: PropTypes.string,
    internal: PropTypes.bool,
    limit: PropTypes.number,
    size: PropTypes.oneOf([
        SIZE.MICRO,
        SIZE.SMALL,
        SIZE.STANDARD,
    ]),
    topics: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]).isRequired,
        followed: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
    })),
};

TopicsList.defaultProps = {
    className: '',
    internal: false,
    limit: null,
    size: SIZE.STANDARD,
    topics: [],
};

export default TopicsList;
