
export const WEBINAR_DATA_URL = '/advisor-service/webinars/v2';
export const WEBINAR_DATA_URL_WITH_ID = eventId => `/advisor-service/webinars/v2?code=${eventId}`;
export const CLAIM_YOUR_SPOT_URL = eventId =>`/advisor-service/webinars/${eventId}`;
export const WEBINAR_INFO_URL = eventId => `/advisor-service/webinars/banners/${eventId}`;
export const NOT_INTERESTED_URL = (eventId, descision) => `/advisor-service/webinars/banners/${eventId}/${descision}`;
export const WEBINAR_META_DATA_URL = eventId => `/content-service/webinars/preauth/${eventId}`;
export const WEBINAR_REGISTERED_STATUS = `/advisor-service/webinars/registered`;

export const WEBINAR_IS_USER_REGISTERD_URL = `/advisor-service/webinars/registered`

export const WEBINAR_IS_USER_REGISTERD = 'webinar/IS_USER_REGISTERED';
export const WEBINAR_IS_USER_REGISTERD_NO = 'webinar/IS_USER_REGISTERED_NO';
export const WEBINAR_IS_USER_REGISTERD_YES = 'webinar/IS_USER_REGISTERED_YES';

export const GET_WEBINAR_INFO = 'webinar/GET_WEBINAR_INFO';
export const GET_WEBINAR_INFO_SUCCESS = 'webinar/GET_WEBINAR_INFO_SUCCESS';
export const GET_WEBINAR_INFO_FAIL = 'webinar/GET_WEBINAR_INFO_FAIL';

export const GET_WEBINAR_INFO_WITH_ID = 'webinar/GET_WEBINAR_INFO_WITH_ID';
export const GET_WEBINAR_INFO_WITH_ID_SUCCESS = 'webinar/GET_WEBINAR_INFO_WITH_ID_SUCCESS';
export const GET_WEBINAR_INFO_WITH_ID_FAIL = 'webinar/GET_WEBINAR_INFO_WITH_ID_FAIL';

export const GET_WEBINAR_META_INFO = 'webinar/GET_WEBINAR_META_INFO';

export const GET_WEBINAR_META_WITH_ID_INFO = 'webinar/GET_WEBINAR_META_INFO';
export const GET_WEBINAR_META_INFO_SUCCESS = 'webinar/GET_WEBINAR_META_INFO_SUCCESS';
export const GET_WEBINAR_META_INFO_FAIL = 'webinar/GET_WEBINAR_META_INFO_FAIL';

export const GET_WEBINAR_DATA = 'webinar/GET_WEBINAR_DATA';
export const GET_WEBINAR_DATA_SUCCESS = 'webinar/GET_WEBINAR_DATA_SUCCESS';
export const GET_WEBINAR_DATA_FAIL = 'webinar/GET_WEBINAR_DATA_FAIL';

export const CLAIM_YOUR_SPOT = 'webinar/CLAIM_YOUR_SPOT';
export const CLAIM_YOUR_SPOT_SUCCESS = 'webinar/CLAIM_YOUR_SPOT_SUCCESS';
export const CLAIM_YOUR_SPOT_FAIL = 'webinar/CLAIM_YOUR_SPOT_FAIL';

export const NOT_INTERESTED = 'webinar/NOT_INTERESTED';
export const NOT_INTERESTED_SUCCESS = 'webinar/NOT_INTERESTED_SUCCESS';
export const NOT_INTERESTED_FAIL = 'webinar/NOT_INTERESTED_FAIL';

export const RESET_WEBINAR_ERROR = 'webinar/RESET_WEBINAR_ERROR';
export const GET_WEBINAR_REGISTRATION_STATUS = 'webinar/GET_WEBINAR_REGISTRATION_STATUS';


