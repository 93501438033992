import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ROUTES } from 'containers/App/constants';
import { Container, Heading } from 'components';
import { IconLogoMark } from 'components/atomics/atoms/Icons/Icons';
import {
    noResultsContactUsMessageStyles,
    noResultsContainerStyles,
    noResultsHeadingStyles,
    noResultsIconStyles,
    noResultsWrapperStyles,
} from 'containers/Search/styles';

export const StyledContactUsMessage = styled.p`
    ${noResultsContactUsMessageStyles}
`;
export const StyledNoResultsContainer = styled.div`
    ${noResultsContainerStyles}
`;
const StyledNoResultsWrapper = styled.div`
    ${noResultsWrapperStyles}
`;
const StyledNoResultsHeading = styled(Heading)`
    ${noResultsHeadingStyles}
`;
const StyledLogo = styled(IconLogoMark)`
    ${noResultsIconStyles};
`;

/**
 * Search results header when there are no results.
 * @param {*} props 
 */
const NoResultsHeader = props => (
    <StyledNoResultsContainer>
        <Container>
            <StyledNoResultsWrapper>
                <StyledNoResultsHeading
                    id="noResultsHeading"
                    level={1}
                >
                    <FormattedMessage
                        id="search.noResults"
                        values={{
                            value: props.searchValue,
                        }}
                    />
                </StyledNoResultsHeading>
                <StyledLogo />
                <StyledContactUsMessage
                    id="contactUsMessage"
                >
                    <FormattedMessage
                        id="search.contactUs"
                        values={{
                            link: (
                                <Link to={ROUTES.CONTACT_US}>
                                    <FormattedMessage id="search.contactUs.link" />
                                </Link>
                            ),
                        }}
                    />
                </StyledContactUsMessage>
            </StyledNoResultsWrapper>
        </Container>
    </StyledNoResultsContainer>
);

NoResultsHeader.propTypes = {
    searchValue: PropTypes.string.isRequired,
};

export default NoResultsHeader;
