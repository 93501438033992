import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import { COLORS } from 'utils/variables';

const resultsCountStyles = css`
    border-bottom: 2px solid ${COLORS.LIGHT_GRAY3};
    color: ${COLORS.MEDIUM_GRAY2};
    font-size: 16px;
    font-weight: 500;
    line-height: 1.46;
    margin: 0;
    padding-bottom: 12px;
    padding-top: 24px;
 
    @media screen and ${FROM_TABLET} {
        padding-bottom: 18px;
        padding-top: 33px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 24px;
        font-weight: 600;
        line-height: 0.58;
        padding-bottom: 20px;
        padding-top: 42px;
    }
`;

export default resultsCountStyles;
