import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ImageAtom from 'components/atomics/atoms/Image/Image';
import { IconSeeMore, IconWrench } from 'components/atomics/atoms/Icons/Icons';
import Link from 'components/atomics/atoms/Link/Link';
import { GuidesTabContext }  from 'containers/Guides/Guides';
import { contextHubRecordEvent } from 'utils/contextHub';
import {
    CARDS_EVENT_LIST,
} from 'utils/contextHubEventListConstants';
import {
    footerToolsContainerStyles,
    footerToolsHeaderStyles,
    toolsHeaderIconStyles,
    footerToolsButtonStyles,
    footerToolsContentStyles,
    footerToolsItemStyles,
    footerToolsImageStyles,
    footerToolsTitleStyles,
    toolsContainerStyles,
    imageOverlayStyles,
} from 'components/atomics/molecules/CollectionContentCard/styles';
import isDesktop from 'utils/isDesktop';
import { FormattedMessage } from 'react-intl';
import { TYPE_INPRACTICE } from '../GuideContentCard/GuideContentCard';
import { getTruncatedString, getResizedImage } from 'utils/contentCardUtils';
import { handleGuideVisit } from '../../../../state-management/actions/guide';
import { connect } from 'react-redux';

export const ToolsContainer = styled.div`
    ${toolsContainerStyles}
`;

export const FooterToolsContainer = styled.div`
    ${footerToolsContainerStyles}
`;

export const FooterToolsHeader =styled(Link)`
    ${footerToolsHeaderStyles}
`;

export const FooterToolsButton = styled.div`
    ${footerToolsButtonStyles}
`;

export const ToolsHeaderIconContainer = styled.div`
    ${toolsHeaderIconStyles}
`;

export const FooterToolsContent = styled.div`
    ${footerToolsContentStyles}
`;

export const FooterToolsItem = styled(Link)`
    ${footerToolsItemStyles}
`;

export const FooterToolsImage = styled.div`
    ${footerToolsImageStyles}
`;

export const FooterToolsTitle = styled.div`
    ${footerToolsTitleStyles}
`;

export const ImageOverlay = styled.div`
    ${imageOverlayStyles}
`;

const MAX_TOOLS_TITLE_TABLATE_CHARS = 50;

/**
 * Creates the collection content card tools area.
 */
class CollectionCardTools extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);
        this.state = {
            isDesktop: isDesktop()
        };

        this.keyCount = 0;
        this.getKey = this.getKey.bind(this);
    }

    /**
     * Get incrementing iterator key.
     */
    getKey() {
        return this.keyCount++;
    }

    handleGuideClick(article) {        
        this.props.onGuideClick(article.id);       
    }

    /**
     * Render guide items.
     */
    renderItems = (img, text, item) => {
        const imageSource = img.includes('http') ? img : getResizedImage(img, 40, 40);
        
        return (
            <FooterToolsItem
                key={this.getKey()}
                to={`/learn/ideas/${item.link}`}
                onClick={ () => {
                    this.handleGuideClick(item)
                } }
                visited={item.visited ? "true" : undefined}
            >
                <FooterToolsImage type={item.type}>
                    <ImageAtom
                        src={imageSource}
                    />
                    {item.type === TYPE_INPRACTICE
                    && <ImageOverlay />}
                </FooterToolsImage>
                <FooterToolsTitle visited={item.visited ? "true" : undefined}>
                    {getTruncatedString(text, MAX_TOOLS_TITLE_TABLATE_CHARS)}
                </FooterToolsTitle>
            </FooterToolsItem>
        );
    }

    /**
     * Render a tools item.
     */
    renderToolsItem = (fItem, activeTab) => {
        const {onClickListener} = this.props;

        return (
            <div
                key={this.getKey()}
            >
                <FooterToolsItem
                    noBorder={true}
                    onClick={ () => {
                        //onClickListener(fItem.link);
                        contextHubRecordEvent(CARDS_EVENT_LIST(
                            'tool',
                            fItem.name
                        ));
                    } }
                    to={fItem.link}
                >
                    <FooterToolsImage>
                        <div>
                            {this.props.toolsList.length > 0
                            && <IconWrench />}
                        </div>
                    </FooterToolsImage>
                    <FooterToolsTitle>
                        {getTruncatedString(fItem.name, MAX_TOOLS_TITLE_TABLATE_CHARS)}
                    </FooterToolsTitle>
                </FooterToolsItem>
            </div>
        );
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const { icon, onClickListener } = this.props;
        const slogan = 'Discover tips, tools and tricks to create successful retirement plans for your clients.';
        const cardSize = this.state.isDesktop === true ? "large" : this.props.cardSize;

        return (
            <GuidesTabContext.Consumer>
                {(activeTab) => {
                    return (
                    <FooterToolsContainer
                        onMouseEnter={this.props.onMouseEnter}
                        onMouseLeave={this.props.onMouseLeave}
                        variant={{ cardSize }}
                        onClick={() => {
                           // onClickListener(`/learn/collections/${this.props.route}/${this.props.code}`)
                        }}
                    >
                        <FooterToolsHeader to={`/learn/collections/${this.props.route}/${this.props.code}`}>
                            <FooterToolsButton >
                                <FormattedMessage id="collections.view" />
                            </FooterToolsButton>
                            <ToolsHeaderIconContainer>
                                <IconSeeMore />
                            </ToolsHeaderIconContainer>
                        </FooterToolsHeader>

                        <FooterToolsContent>

                            {this.props.guidesList && this.props.guidesList.map((e, i, activeTab) => {
                                return (this.renderItems(e.image, e.title, e, activeTab));
                            })}
                            {this.props.toolsList && this.props.toolsList.map((e, i, activeTab) => {
                                return (this.renderToolsItem(e, activeTab));
                            })}
                        </FooterToolsContent>
                    </FooterToolsContainer>
                    )
                }}
            </GuidesTabContext.Consumer>
        );


    }
}

CollectionCardTools.propTypes = {
    route:PropTypes.string,
    code: PropTypes.string,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    guidesList: PropTypes.arrayOf(PropTypes.shape({})),
    toolsList: PropTypes.arrayOf(PropTypes.shape({})),
    icon: PropTypes.string,
    cardSize: PropTypes.string,
};

CollectionCardTools.defaultProps = {
    code: null,
    onMouseOver: () => { },
    onMouseOut: () => { },
    article: null,
    cardSize: 'large'
}

const mapDispatchToProps = dispatch => ({
    onGuideClick: id => dispatch(handleGuideVisit(id)),   
});

export default connect(null, mapDispatchToProps)(CollectionCardTools);
