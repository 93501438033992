export const TYPES = {
    SUCCESSFULLY_SAVED_ARTICLE: 'SUCCESSFULLY_SAVED_ARTICLE',
    SUCCESSFULLY_SAVED_GUIDE: 'SUCCESSFULLY_SAVED_GUIDE',
    SUCCESSFULLY_UNSAVED_ARTICLE: 'SUCCESSFULLY_UNSAVED_ARTICLE',
    SUCCESSFULLY_UNSAVED_GUIDE: 'SUCCESSFULLY_UNSAVED_GUIDE',
    SOURCE_LIMIT_REACHED: 'SOURCE_LIMIT_REACHED',
    TOPIC_LIMIT_REACHED: 'TOPIC_LIMIT_REACHED',
    FOLLOW_TOPIC: 'FOLLOW_TOPIC',
    UNFOLLOW_TOPIC: 'UNFOLLOW_TOPIC',
    FOLLOW_SOURCE: 'FOLLOW_SOURCE',
    UNFOLLOW_SOURCE: 'UNFOLLOW_SOURCE',
    SUCCESSFULLY_SAVED_CECREDIT: 'SUCCESSFULLY_SAVED_CECREDIT',
    SUCCESSFULLY_UNSAVED_CECREDIT: 'SUCCESSFULLY_UNSAVED_CECREDIT',
    COPY: 'COPY',
    FAILED_ACTION: 'FAILED_ACTION',
    RESEND_EMAIL: 'RESEND_EMAIL',
};
