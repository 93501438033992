import Icon from 'components/atomics/atoms/Icons/Icon/Icon';
import crdenvelopeIcon from 'components/atomics/atoms/Icons/icons/crdenvelope.svg';
import caratDownIcon from 'components/atomics/atoms/Icons/icons/carat-down.svg';
import clockIcon from 'components/atomics/atoms/Icons/icons/clock.svg';
import closeIcon from 'components/atomics/atoms/Icons/icons/close.svg';
import emblemIcon from 'components/atomics/atoms/Icons/icons/emblem.svg';
import greetingEmblem from 'components/atomics/atoms/Icons/icons/emblem-temp.svg'; /* temporary solution, to be removed in a near future */
import greetingEmblem2 from 'components/atomics/atoms/Icons/icons/emblem-temp-2.svg'; /* temporary solution, to be removed in a near future */
import logoIcon from 'components/atomics/atoms/Icons/icons/lumin-logo.svg';
import fullLogoIcon from 'components/atomics/atoms/Icons/icons/lumin-logo-full.svg';
import poweredByIcon from 'components/atomics/atoms/Icons/icons/powered-by.svg';
import saveIcon from 'components/atomics/atoms/Icons/icons/save.svg';
import searchIcon from 'components/atomics/atoms/Icons/icons/search.svg';
import seeMoreIcon from 'components/atomics/atoms/Icons/icons/arrow-see-more.svg';
import errorIcon from 'components/atomics/atoms/Icons/icons/icon-error.svg';
import cancelIcon from 'components/atomics/atoms/Icons/icons/cancel-button.svg';
import toolIcon from 'components/atomics/atoms/Icons/icons/tool.svg';
import checkIcon from 'components/atomics/atoms/Icons/icons/icon-check-2.svg';
import arrowPaginationIcon from 'components/atomics/atoms/Icons/icons/arrow-pagination-next.svg';
import undoIcon from 'components/atomics/atoms/Icons/icons/icon-undo.svg';
import slimCrossIcon from 'components/atomics/atoms/Icons/icons/icon-cross-slim.svg';
import plusIcon from 'components/atomics/atoms/Icons/icons/icon-add.svg';
import newsICon from 'components/atomics/atoms/Icons/icons/news.svg';
import undoIconBack from 'components/atomics/atoms/Icons/icons/undo.svg';
import arrowDownIcon from 'components/atomics/atoms/Icons/icons/arrow-down.svg';
import stepperLeftArrowIcon from 'components/atomics/atoms/Icons/icons/icon-arrow-left.svg';
import stepperRightArrowIcon from 'components/atomics/atoms/Icons/icons/icon-arrow-right.svg';
import playerExpand from 'components/atomics/atoms/Icons/icons/player_expand.svg';
import playerPlay from 'components/atomics/atoms/Icons/icons/player_play.svg';
import djlogo from 'components/atomics/atoms/Icons/icons/djlogo.svg';
import graph from 'components/atomics/atoms/Icons/icons/graph.svg';
import eyeActive from 'components/atomics/atoms/Icons/icons/icon-eye-active.svg';
import eyeInactive from 'components/atomics/atoms/Icons/icons/icon-eye-inactive.svg';
import kebab from 'components/atomics/atoms/Icons/icons/kebab.svg';
import capitalIdeasSmall from 'components/atomics/atoms/Icons/icons/capital-ideas.svg';
import lightning from 'components/atomics/atoms/Icons/icons/lightning.svg';
import exclamation from 'components/atomics/atoms/Icons/icons/exclamation.svg';
import exclamationSolid from 'components/atomics/atoms/Icons/icons/exclamation-solid.svg';
import educationIcon from 'components/atomics/atoms/Icons/icons/icon-education.svg';
import wrenchIcon from 'components/atomics/atoms/Icons/icons/icon-wrench.svg';
import animatedCheckIcon from 'components/atomics/atoms/Icons/icons/animated-check.svg';
import luminLogoMarkIcon from 'components/atomics/atoms/Icons/icons/lumin-logomark.svg';
import luminLogoMarkIcon2 from 'components/atomics/atoms/Icons/icons/lumin-logomark2.svg';
import bloombergLogo from 'components/atomics/atoms/Icons/icons/bloomberg-chip-logo.svg';
import dowJonesgLogo from 'components/atomics/atoms/Icons/icons/dowjones-chip-logo.svg';
import ceIcon from 'components/atomics/atoms/Icons/icons/cap-blue.svg';
import ceIconWebinar from 'components/atomics/atoms/Icons/icons/cap-purple.svg';
import playIcon from 'components/atomics/atoms/Icons/icons/Icon_PlayButton.svg';
import ceSkinnyIcon from 'components/atomics/atoms/Icons/icons/ce-icon.svg';
import ceSkinnyMobileIcon from 'components/atomics/atoms/Icons/icons/ce-icon-mobile.svg';
import testFlightIcon from 'components/atomics/atoms/Icons/icons/testflight_logo.svg';
import luminLogoIcon from 'components/atomics/atoms/Icons/icons/lumin_logo.svg';
import qrCodeIcon from 'components/atomics/atoms/Icons/icons/qr-code.svg';
import schedule from 'components/atomics/atoms/Icons/icons/icon_schedule.svg';
import news2 from 'components/atomics/atoms/Icons/icons/icon_news_2.svg';
import webinarIcon  from 'components/atomics/atoms/Icons/icons/webinar-icon.svg';
import webinarMobileIcon  from 'components/atomics/atoms/Icons/icons/webinar-mobile-icon.svg';
import ceWebinarIcon from 'components/atomics/atoms/Icons/icons/ce-webinar.svg';
import download from 'components/atomics/atoms/Icons/icons/download.svg';
import pdf from 'components/atomics/atoms/Icons/icons/pdf.svg';

import toolsBlueIcon from 'components/atomics/atoms/Icons/icons/tools-icon-desktop-tablet.svg';
import toolsBlueMobileIcon from 'components/atomics/atoms/Icons/icons/tools-icon-mobile.svg';

export const IconSchedule = Icon(schedule, {
    height: 60,
    width: 58,
});

export const IconNews2 = Icon(news2, {
    height: 60,
    width: 58,
});

export const IconPDF = Icon(pdf, {
    height: 24,
    width: 24,
});

export const IconCRDEnvelope = Icon(crdenvelopeIcon, {
    height: 24,
    width: 24,
});

export const IconExclamation = Icon(exclamation, {
    height: 24,
    width: 24,
});

export const IconExclamationSolid = Icon(exclamationSolid, {
    height: 16,
    width: 16,
});

export const IconAnimatedCheck = Icon(animatedCheckIcon, {
    height: 52,
    width: 52,
});

export const IconWrench = Icon(wrenchIcon, {
    height: 16,
    width: 16,
    title: "Tools",
    role: "img",
});

export const IconUndoBack = Icon(undoIconBack, {
    height: 16,
    width: 16,
});

export const IconLightning = Icon(lightning, {
    height: 32,
    width: 32,
});

export const IconCapitalIdeasSmall = Icon(capitalIdeasSmall, {
    height: 32,
    width: 32,
});

export const IconKebab = Icon(kebab, {
    height: 20,
    width: 40,
});
export const IconEducation = Icon(educationIcon, {
    height: 24,
    width: 24,
});
export const IconEyeActive = Icon(eyeActive, {
    height: 22,
    width: 16,
});
export const IconEyeInactive = Icon(eyeInactive, {
    height: 22,
    width: 16,
});
export const GraphIcon = Icon(graph, {
    height: 24,
    width: 24,
});
export const DJLogo = Icon(djlogo, {
    height: 20,
    width: 200,
    title: "Powered by Dow Jones",
    role: "img",
});
export const ToolIcon = Icon(toolIcon, {
    height: 36,
    width: 40,
});
export const CancelIcon = Icon(cancelIcon, {
    height: 24,
    width: 24,
});
export const IconClock = Icon(clockIcon, {
    height: 18,
    strokeWidth: 1.5,
    width: 18,
});
export const IconClose = Icon(closeIcon, {
    height: 22,
    width: 22,
});
export const IconEmblem = Icon(emblemIcon, {
    height: 50,
    width: 50,
});
export const IconLogoEmblem = Icon(greetingEmblem, {
    height: 48,
    strokeWidth: 0,
    width: 48,
});
export const IconLogoEmblem2 = Icon(greetingEmblem2, {
    height: 48,
    width: 48,
});
export const IconLogoMark= Icon(luminLogoMarkIcon, {
    height: 51,
    width: 51,
    title: "Capital Group Pro",
    role: "img",
});
export const IconLogoMark2= Icon(luminLogoMarkIcon2, {
    height: 51,
    width: 51,
    title: "Capital Group Pro",
    role: "img",
});
export const IconSave = Icon(saveIcon, {
    height: 24,
    strokeWidth: 2,
    width: 17,
});
export const IconEmptySave = Icon(saveIcon, {
    height: 36,
    stroke: 0,
    strokeWidth: 0,
    width: 26,
});
export const IconEmptySaveSmall = Icon(saveIcon, {
    height: 16,
    strokeWidth: 3,
    width: 11,
});
export const IconLogoText = Icon(logoIcon, {
    height: 31,
    strokeWidth: 0,
    width: 109,
    title: "Capital Group Pro",
    role: "img",
});
export const IconLogo = Icon(logoIcon, {
    height: 35,
    strokeWidth: 0,
    width: 197,
});
export const IconPoweredBy = Icon(poweredByIcon, {
    height: 30,
    strokeWidth: 0,
    width: 46,
});
export const IconCaratDown = Icon(caratDownIcon, {
    height: 8,
    stroke: 0,
    width: 13,
});
export const IconSearch = Icon(searchIcon, {
    height: 27,
    width: 28,
});
export const IconSeeMore = Icon(seeMoreIcon, {
    height: 18,
    strokeWidth: 2,
    width: 15,
});
export const IconError = Icon(errorIcon, {
    height: 30,
    width: 30,
});
export const CheckIconSmall = Icon(checkIcon, {
    height: 18,
    width: 18,
});
export const IconPaginationArrow = Icon(arrowPaginationIcon, {
    height: 14,
    width: 16,
});
export const IconUndo = Icon(undoIcon, {
    height: 14,
    width: 18,
});
export const IconCross = Icon(slimCrossIcon, {
    height: 11,
    width: 18,
});
export const IconPlus = Icon(plusIcon, {
    height: 18,
    width: 18,
});
export const IconNews = Icon(newsICon, {
    height: 50,
    width: 64,
});
export const IconArrowDown = Icon(arrowDownIcon, {
    height: 9,
    width: 14,
});
export const IconStepperPrevious = Icon(stepperLeftArrowIcon, {
    height: 16,
    width: 16,
});
export const IconStepperNext = Icon(stepperRightArrowIcon, {
    height: 16,
    width: 16,
});
export const IconPlayerExpand = Icon(playerExpand, {
    height: 16,
    width: 16,
});
export const IconPlayerPlay = Icon(playerPlay, {
    height: 18,
    width: 18,
    title: "Bloomberg Live TV",
    role: "img",
});

export const BloombergLogo  = Icon(bloombergLogo, {
    height: 28,
    width: 28,
    title: "Bloomberg Live TV",
    role: "img",
});
export const DowJonesgLogo  = Icon(dowJonesgLogo, {
    height: 28,
    width: 28,
    title: "Market Briefing Powered by Dow Jones",
    role: "img",
});

export const CEIcon = Icon(ceIcon, {
    height: 26,
    width: 26,
    title: "CE Credit",
    role: "img",
});

export const CEIconWebinar = Icon(ceIconWebinar, {
    height: 26,
    width: 26,
    title: "CE Credit",
    role: "img",
});

export const PLAYIcon = Icon(playIcon, {
    width: 69,
    height: 69,
});

export const CESkinnyIcon = Icon(ceSkinnyIcon, {
    height: 129,
    width: 129,
    title: 'CE Credits',
});

export const WEBINARIcon = Icon(webinarIcon, {
    height: 129,
    width: 129,
});

export const WEBINRMOBILEIcon = Icon(webinarMobileIcon, {
    height: 61,
    width: 61,
});

export const CESkinnyMobileIcon = Icon(ceSkinnyMobileIcon, {
    height: 61,
    width: 61,
    title: 'CE Credits',
});

export const LuminLogoIcon = Icon(luminLogoIcon, {
    height: 40,
    strokeWidth: 0,
    width: 146,
    title: "Capital Group Pro home",
    role: "img",
});
export const IconTestFlight = Icon(testFlightIcon, {

});
export const IconQRCode = Icon(qrCodeIcon, {

});
export const CEWebinarIcon = Icon(ceWebinarIcon, {
    height: 26,
    width: 26,
});

export const Download = Icon(download, {
    height: 13,
    width: 13,
});

export const ToolsBlueIcon = Icon(toolsBlueIcon, {
    title: "tools"
});

export const ToolsBlueMobileIcon = Icon(toolsBlueMobileIcon, {
    title: "tools"
});