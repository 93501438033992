import { css } from 'styled-components';
import {
    COLORS,
    ANIMATIONS,
} from '../../../../utils/variables';
import {
    FROM_DESKTOP,
    FROM_TABLET,
    SMALL_MOBILE,
} from '../../../../utils/mediaQueries';
import { TYPE_INPRACTICE } from '../GuideContentCard/GuideContentCard';

export const LARGE_CARD = 'large';
export const SMALL_CARD = 'small';

export const containerStyles = css`
    position: relative;

    img {
        object-fit: cover;
        transition: ${ANIMATIONS.ARTICLES.IMAGE.TIME.SECONDS}s all;
        /*width: auto;*/
        height: 100%;
    }

    @media screen and ${FROM_DESKTOP} {
        min-height: 268px;
    }

    @media screen and ${FROM_TABLET} {
        min-height: 254px;
        border: 1px Solid ${COLORS.LIGHT_GRAY2};
    }
`;

export const cardContainerStyles = css`
    background-color:${COLORS.WHITE};
    padding:18px 17px 10px 17px;

    @media screen and ${FROM_DESKTOP} {
        min-height: 268px;
    }

    @media screen and ${FROM_TABLET} {
        height: calc(100% - 28px);
        display: flex;
        flex-direction: column;
    }
`;

export const imageContainerStyles = css`
    flex: 2 0 0;
    height: 61px;
    overflow: hidden;
    width: 63px;

    @media screen and ${SMALL_MOBILE} {
        max-height:70px;
        max-width: 68px;
    }

    @media screen and ${FROM_TABLET} {
      max-height:70px;
      max-width: 68px;
    }

    @media screen and ${FROM_DESKTOP} {
        max-height:70px;
        max-width: 68px;
    }
`;

export const contentContainerStyles = css`
    flex: 1;
    text-decoration: none;
    color: ${COLORS.BLACK};
`;

export const titleStyles = css`
    color:${COLORS.BLACK};
    text-decoration:none;
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.09px;
    transition: ${ANIMATIONS.ARTICLES.TITLE.TIME.SECONDS}s all;
    display: block;

    &:hover{
        opacity: 0.6;
    }

    @media screen and ${FROM_TABLET} {
        margin-top:15px;
        margin-bottom: 15px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 18px;
        margin-top:15px;
        margin-bottom: 15px;
    }
`;

export const sloganStyles = css`
    font-size: 15px;

    @media screen and ${FROM_TABLET} {
        min-height:58px;
        padding-bottom: 24px;
    }
`;

export const iconContainerStyles = css`
    flex: 1;
    text-align: right;

    @media screen and ${SMALL_MOBILE} {
        margin-top: -5px;
        margin-right: 5px;
    }

    span {
        @media screen and ${SMALL_MOBILE} {
            width: 25px;
            height: 25px;
        }

        background-color: ${COLORS.LIGHT_GRAY1};
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: inline-flex;
        justify-content: center;

        svg {
            display:inline-block;
            width: 30px;
            height: 30px;

            @media screen and ${SMALL_MOBILE} {
               height: 25px;
            }

            fill: ${COLORS.DARK_GRAY2};
            stroke: ${COLORS.DARK_GRAY2};
            }
        }

        span:first-child {
            margin-right: -5px;

            svg {
                width: 13px;
            }
            @media screen and ${FROM_DESKTOP} {
                height: 30px;
              }
            @media screen and ${SMALL_MOBILE} {
                height: 26px;
            }

        }
    }
`;

export const upperContainerStyles = css`
    display: flex;
    flex-direction: row;
    text-decoration:none;
`;

export const footerContainerStyles = css`
    display: flex;
    flex-direction: row;
    color: ${COLORS.DARK_GRAY2};
    margin-top: 45px;
    text-decoration: none;
    outline-color: ${COLORS.OCEAN};

    &:hover {
        color: ${COLORS.SAPPHIRE};
    }
`;

export const footerButtonStyles = css`
    flex: 1;
    font-size: 12px;
    font-weight: bold;
    padding:0;
    text-align: left;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.08;
    letter-spacing: 1px;
    text-transform: uppercase;
`;

export const footerIconContainerStyles = css`
    text-align:right;

    svg {
        padding: 0px 0px;
        text-align: right;
    }
`;
export const toolsContainerStyles = css`
    margin-top: -72px;
    min-height: 310px;
    width: 273px;
    z-index: 4;
    position: absolute;
    padding: 20px;
    right: 0;
    left: -20px;
    width: 100%;

    &.showTools {
        display: block;
    }

    &.hideTools {
        display: none;
    }

`;

export const footerToolsContainerStyles = ({ variant }) => css`
    min-height: 140px;
    background-color:${COLORS.WHITE};
    box-shadow: 0 0 5px 0 ${COLORS.LIGHT_GRAY2};
    width: 100%;
    height: 100%;
`;

export const imageOverlayStyles = css`
    opacity: 0.5;
    background-image: linear-gradient(45deg, ${COLORS.OCEAN}, ${COLORS.SAPPHIRE}); /* NON standard colors per IAs request */
    width: 40px !important;
    height: 100% !important;
    border-radius: 0 !important;
    position: absolute;
    left: 0;
    top: 0;
`;

export const footerToolsHeaderStyles = css`
    display: flex;
    flex-direction: row;
    color: ${COLORS.SAPPHIRE};
    background-color:${COLORS.WHITE};
    padding:24px 17px;
    text-decoration: none;
    cursor: pointer;

    a:visited {
        color: ${COLORS.SAPPHIRE};
    }
`;

export const toolsHeaderIconStyles = css`
    text-align: right;

    svg {
        position: relative;
        top: -5px;
        text-align: right;
        stroke: ${COLORS.SAPPHIRE};
    }
`;

export const footerToolsButtonStyles = css`
    flex: 1;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;

    &:hover{
        color: ${COLORS.DARK_SAPPHIRE};
    }
`;

export const footerToolsItemStyles = css`
    display: flex;
    height:58px;
    padding:15px 5px 7px 5px;
    border-bottom:1px Solid ${COLORS.LIGHT_GRAY3};
    text-decoration: none;

    ${props => props.noBorder && css`
        border-bottom: none;
    `};
   
`;

export const footerToolsContentStyles = css`
    padding-left:5px;
    padding-right:5px;
    
    >div:last-child {
        border-bottom:none;
        height: 70px;
        padding-bottom: 0px;
    }
`;


export const footerToolsImageStyles = css`
    flex: 1 0 0;
    margin-left: 10px;
    margin-right: 16px;
    position: relative;
    overflow: hidden;

    img {
        width: 100%;
        height: auto;
    }

    ${props => props.type === TYPE_INPRACTICE && css`
        img {
            filter: grayscale(100%);
        }
    `};

    @media screen and ${SMALL_MOBILE} {
        height: 89px;
        width: 89px;
    }

    @media screen and ${FROM_TABLET} {
        max-height: 40px;
        max-width: 40px;
        min-width: 40px;
        min-height: 40px;
    }

    div {
       background-color: ${COLORS.SAPPHIRE};
       border-radius: 50%;
       height: 39px;
       width: 39px;
       text-align: center;
       vertical-align: middle;
       margin-left: 0;
    }

    svg {
        fill: ${COLORS.WHITE};
        stroke: ${COLORS.WHITE};
        height: 40px;
        width: 16px;
    }
`;

export const footerToolsTitleStyles = css`
    flex: 1;
    font-size: 12px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.54;
    letter-spacing: -0.06px;
    color:${COLORS.BLACK};
    &:hover{
        opacity: 0.6;
    }

    ${props => props.visited && css`
    opacity: 0.6;
        &:hover{
            color:${COLORS.BLACK};
            opacity: 1;
        }   
    `};
`;

export const mobileContainerStyles = css`
    width: auto;
    border: 1px Solid ${COLORS.LIGHT_GRAY2};
    background-color: ${COLORS.WHITE} !important;
    padding: 16px;
    margin-bottom: 8px;
    padding-bottom: 0;
    padding-top: 10px;
    text-decoration:none;
    color:${COLORS.BLACK};

    img {
        height: 100%;
        width: auto;
        object-fit: cover;
        transition: ${ANIMATIONS.ARTICLES.IMAGE.TIME.SECONDS}s all;
    }

    @media screen and ${SMALL_MOBILE} {
       margin-bottom: 16px;
       padding-bottom: 0;
    }
`;

export const mobileTopContainerStyles = css`
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: ${COLORS.BLACK};
`;

export const mobileImageContainerStyles = css`
    flex: 1 0 0;
    height: 61px;
    overflow: hidden;
    max-height:70px;
    max-width: 68px;
    padding-top:10px;
`;

export const mobileContentStyles = css`
    flex: 1;
    padding-top:7px;
    padding-bottom:12px;
    padding-left:12px;
`;

export const mobileFooterStyles = css`
    display: flex;
    flex-direction: row;
    padding-top:10px;
    border-top:1px Solid ${COLORS.LIGHT_GRAY3};
    margin-top: 10px;
    color: ${COLORS.MEDIUM_GRAY2};
    min-height: 32px;
    text-decoration:none;
`;

export const mobileFooterButtonStyles = css`
    flex: 1;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    padding-top: 2px;
    color: ${COLORS.DARK_GRAY2};
    text-decoration: none;
    text-transform: uppercase;
`;
