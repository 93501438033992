import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Modal from 'components/atomics/templates/ClosableModal/ClosableModal';
import { FormattedMessage } from 'react-intl';
import { triggerFormSubmit as triggerFormSubmitAction, setFormUntouched, setFormToEditDefaultMode }
    from 'state-management/actions/basicProfileForm';
import {
    BasicButton as Button,
    TYPE_SECONDARY_MEDIUM_LARGE,
} from 'components/atomics/atoms/Button/Button';
import { RGB_COLORS } from 'utils/variables';
import {
    popupStyles,
    popupContentStyles,
    popupTitleStyles,
    popupActionsContainerStyles,
    confirmationTextStyles,
    buttonsContainerStyles,
} from 'containers/Profile/styles';
import { hidePreferencesModal } from 'state-management/actions/basicProfile';
import storage from 'utils/store';
import {
    FIRST_NAME,
} from 'state-management/constants/signIn';


const StyledPopup = styled.div`
    ${popupStyles}
`;

const StyledHeaderActionsContainer = styled.div`
    ${popupActionsContainerStyles}
`;

const StyledPopupContent = styled.div`
    ${popupContentStyles}
`;

const StyledPopupTitle = styled.h2`
    ${popupTitleStyles}
`;

const StyledConfirmationText = styled.p`
    ${confirmationTextStyles}
`;

const StyledButtonsContainer = styled.div`
    ${buttonsContainerStyles}
`;

const ModalStyles = {
    content: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        position: 'fixed',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        borderRadius: '0',
        boxSizing: 'border-box',
        width: '100%',
        outline: '0',
        background: 'transparent',
        border: '0',
    },
    overlay: {
        backgroundColor: `rgba(${RGB_COLORS.DARK_SAPPHIRE}, 0.6)`,
        zIndex: '1',
    },
};

/**
 * Creates the profile modal save/dismiss popup (for source and topic changes).
 */
export class ProfileConfirmationSourcePopup extends React.Component {
    /**
     * Handle discard changes.
     */
    onDiscard = (e) => {
        if (window.location.pathname === "/choose-sources") {
            history.go(-1);
            return;
        }
        this.props.onRequestClose(true);
    };

    /**
     * Handle visible closure.
     */
    onClose = (e) => {
        if (window.location.pathname === "/choose-sources") {
            e.preventDefault();
            this.props.hideModal();

            setTimeout(() => {
                this.props.onRequestClose(false);
            }, 400);
        }
        else {
            e.preventDefault();
            this.props.hideModal();
            this.props.onRequestClose(false);
        }
    };

    /**
     * Render this and underlying components.
     */
    render() {
        if (!this.props.isOpen) {
            return null;
        }

        //TODO need to get redux-saga to cancel this task.
        if (this.props.isOpen && this.props.isSaving) {
            return null;
        }
        else if (this.props.isOpen && storage.get(FIRST_NAME) == null) {
            return null;
        }

        return (
            <Modal
                isOpen={this.props.isOpen}
                style={ModalStyles}
                ariaHideApp={false}
            >
                <StyledPopup>
                    <StyledHeaderActionsContainer />
                    <StyledPopupTitle>
                        <FormattedMessage id="profilePage.confirmationModal.source.title" />
                    </StyledPopupTitle>
                    <StyledPopupContent>
                        <StyledConfirmationText>
                            <FormattedMessage id="profilePage.confirmationModal.source.description" />
                        </StyledConfirmationText>
                    </StyledPopupContent>
                    <StyledButtonsContainer type="new">
                        <Button
                            type={TYPE_SECONDARY_MEDIUM_LARGE}
                            id="discard-button"
                            onClick={(e) => { this.onDiscard(e); this.props.untouchForm(); }}
                            disabled={this.props.isSaving}
                            dataAnalyticsPlacement="Button : body"
                            dataAnalyticsLabel="trackLink : button"
                            dataAnalyticsId="save profile popup cancel"
                        >
                            <FormattedMessage id="profilePage.confirmationModal.source.discard" />
                        </Button>
                        <Button
                            id="save-button"
                            onClick={(e) => { this.onClose(e) }}
                            dataAnalyticsPlacement="Button : body"
                            dataAnalyticsLabel="trackLink : button"
                            dataAnalyticsId="save profile popup"
                        >
                            <FormattedMessage id="profilePage.confirmationModal.source.confirm" />
                        </Button>
                    </StyledButtonsContainer>
                </StyledPopup>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    isSaving: state.basicProfileForm.isSaving,
    hidePreferencesModal: state.basicProfile.hidePreferencesModal,
});

const mapDispatchToProps = dispatch => ({
    triggerFormSubmit: () => dispatch(triggerFormSubmitAction),
    untouchForm: () => dispatch(setFormUntouched),
    hideModal: () => dispatch(hidePreferencesModal()),
    editForm: () => dispatch(setFormToEditDefaultMode)
});

ProfileConfirmationSourcePopup.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    triggerFormSubmit: PropTypes.func.isRequired,
    untouchForm: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileConfirmationSourcePopup);
