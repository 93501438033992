import { zipCodeRegex } from 'utils/regex';

const isZipCodeValid = (zip, required = false) => {
    let isValid;
    if (required) {
        isValid = zip != null && zipCodeRegex.test(zip);
    } else {
        isValid = zip == null || zip === '' || zipCodeRegex.test(zip);
    }

    return isValid;
};

export default isZipCodeValid;
