import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { createImageUrl } from 'utils/urlUtils';
import Image from 'components/atomics/atoms/Image/Image';
import Button from 'components/atomics/atoms/Button/Button';
import { TYPE_GHOST_RIGHT } from 'components/atomics/atoms/Button/Button';
import { BreakpointsContext, MOBILE } from 'components/Breakpoints/Breakpoints';
import {
    Container,
    Heading,
} from 'components';
import {
    outerContainerStyles,
    imageContainerStyles,
    contentContainerStyles,
    headingStyles,
    descriptionStyles,
    wrapperContainerStyles,
} from 'components/SignUpSecondarySection/styles';

const renderCoverImage = (breakpoint) => {
    const COVER_IMAGE_URL = breakpoint === MOBILE
        ? '/images/tablet-tablet.png'
        : '/images/tablet-desktop.png';

    return <Image src={COVER_IMAGE_URL} alt="" isExternal />;
};

const Wrapper = styled.div`
    ${wrapperContainerStyles}
`;

const StyledOuterContainer = styled.section`
    ${outerContainerStyles}
`;

const StyledImageContainer = styled.div`
    ${imageContainerStyles}
`;

const StyledContentContainer = styled.div`
    ${contentContainerStyles}
`;

const StyledHeading = styled(Heading)`
    ${headingStyles}
`;

const StyledDescription = styled.p`
    ${descriptionStyles}
`;

const SignUpSecondarySection = (props) => (
    <Wrapper>
        <StyledOuterContainer>
            <Container>
                <StyledContentContainer isWebinar={props.isWebinar}>
                    <StyledHeading level={2}>
                        <FormattedMessage id="signUpSecondarySection.heading" />
                    </StyledHeading>
                    <StyledDescription isWebinar={props.isWebinar}>
                        <FormattedMessage id="signUpSecondarySection.description" />
                    </StyledDescription>
                    {props.isWebinar
                    && <Button
                        to="/learn"
                        type={TYPE_GHOST_RIGHT}
                    >
                        <FormattedMessage id="webinar.page.pmLinkLabel" />
                    </Button>}
                </StyledContentContainer>
            </Container>
            <StyledImageContainer>
                <BreakpointsContext.Consumer>
                    {renderCoverImage}
                </BreakpointsContext.Consumer>
            </StyledImageContainer>
        </StyledOuterContainer>
    </Wrapper>
);

export default SignUpSecondarySection;
