import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components';

const SliderButton = ({
    currentSlide,
    slideCount,
    children,
    ...props
}) => (
    <Button {...props}>{children}</Button>
);

SliderButton.propTypes = {
    currentSlide: PropTypes.number,
    slideCount: PropTypes.number,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

SliderButton.defaultProps = {
    currentSlide: null,
    slideCount: null,
    children: null,
};

export default SliderButton;
