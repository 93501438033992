import { COLLAPSE, EXPAND, TOGGLE } from 'state-management/constants/navigation';

const initialState = {
    isExpanded: false,
};

function navigationReducer(state = initialState, action = {}) {
    switch (action.type) {
    case EXPAND:
        return {
            ...state,
            isExpanded: true,
        };
    case COLLAPSE:
        return {
            ...state,
            isExpanded: false,
        };
    case TOGGLE:
        return {
            ...state,
            isExpanded: !state.isExpanded,
        };
    default:
        return state;
    }
}

export default navigationReducer;
