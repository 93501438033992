import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { DESKTOP, MOBILE, TABLET } from 'components/Breakpoints/Breakpoints';
import Toast from 'components/atomics/molecules/Toast/Toast';
import { getTruncatedString } from 'utils/contentCardUtils';

const MAX_ARTICLE_TITLE_CHARS = 110;

/**
 * Toast shown when user unsaves an item.
 */
class SuccessfulUnsaveToast extends React.Component {
    render() {
        const {
            title,
        } = this.props;

        return (
            <Toast>
                <Toast.Title>
                    <span>"{getTruncatedString(title, MAX_ARTICLE_TITLE_CHARS)}"</span>
                    <span><FormattedMessage id="saveControlNew.remove" /></span>
                    <span><FormattedMessage id="saveControlNew.remove.short" /></span>
                </Toast.Title>
            </Toast>
        );
    }
}

SuccessfulUnsaveToast.propTypes = {
    actionType: PropTypes.string,
    article: PropTypes.shape({}),
    breakpoint: PropTypes.oneOf([
        DESKTOP,
        MOBILE,
        TABLET,
    ]).isRequired,
    className: PropTypes.string,
    id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    article: PropTypes.shape({}),
    title: PropTypes.string.isRequired,
    analyticsData: PropTypes.objectOf(PropTypes.any),
};

SuccessfulUnsaveToast.defaultProps = {
    actionType: '',
    className: '',
    id: 'successful-unsave-toast',
    analyticsData: {},
    article: {},
};

export default SuccessfulUnsaveToast;
