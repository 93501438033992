import React from 'react';
import { FormattedMessage } from 'react-intl';
import Toast from 'components/atomics/molecules/Toast/Toast';

/**
 * Toast shown when user clicks resend confirmation email.
 */
class EmailSentToast extends React.Component {
    render() {
        return (
            <Toast>
                <Toast.Title>
                    <span><FormattedMessage id="toast.emailSent" /></span>
                    <span><FormattedMessage id="toast.emailSent" /></span>
                </Toast.Title>
            </Toast>
        );
    }
}

export default EmailSentToast;
