import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import { outerFocusMixin } from 'components/FocusVisibility/styles';
import { COLORS, ANIMATIONS } from 'utils/variables';

export const postContainerStyles = css`
    display: flex;
    flex-direction: column;
    padding-bottom: 48px;

    @media screen and ${FROM_TABLET} {
        flex-direction: row;
        flex-wrap: wrap;
        padding-bottom: 64px;
    }
`;

export const postDetailsStyles = css`
    box-sizing: border-box;
    color: ${COLORS.DARK_GRAY1};
    font-size: 12px;
    font-weight: 600;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        padding-right: 32px;
        padding-top: 8px;
        width: 18%;
    }

    @media screen and ${FROM_DESKTOP} {
        padding-right: 52px;
    }
`;

export const separatorStyles = css`
    padding: 0 6px;

    @media screen and ${FROM_TABLET} {
        display: none;
    }
`;

export const categoryTitleStyles = css`
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        display: block;
        font-size: 13px;
        line-height: 1;
        padding-bottom: 5px;
    }
`;

export const postContentStyles = css`
    @media screen and ${FROM_TABLET} {
        box-sizing: border-box;
        min-height: 201px;
        padding-right: 254px;
        position: relative;
        width: 82%;
    }
`;

export const headingStyles = css`
    color: ${COLORS.DARK_GRAY1};
    font-size: 24px;
    font-weight: 600;
    line-height: 1.08;
    margin: 11px 0 24px;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        font-size: 36px;
        line-height: 1.28;
        margin: 0 0 26px;
    }
`;

export const descriptionStyles = css`
    color: ${COLORS.DARK_GRAY1};
    font-size: 14px;
    font-weight: 400;
    line-height: 1.43;
    margin-top: 16px;
    word-wrap: break-word;

    a {
        color: ${COLORS.SAPPHIRE};
        font-weight: 600;
        ${outerFocusMixin}
        text-decoration: none;

        transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;

        &:hover {
            color: ${COLORS.DARK_SAPPHIRE};
        }
    }

    > p {
        margin-top: 0;
    }

    @media screen and ${FROM_TABLET} {
        font-size: 18px;
        line-height: 1.33;
        margin-top: 0;
    }
`;

export const linkContainerStyles = css`
    margin-top: 32px;
    text-align: center;

    @media screen and ${FROM_TABLET} {
        margin-top: 40px;
        text-align: left;
    }
`;

export const imageContainerStyles = css`
    height: 151px;
    position: relative;

    @media screen and ${FROM_TABLET} {
        height: 201px;
        position: absolute;
        right: 0;
        top: 0;
        width: 203px;
    }
`;
