import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import {
    Container,
    Heading,
} from 'components';
import {
    outerContainerStyles,
    innerContainerStyles,
    headingContainerStyles,
    headingStyles,
    articlesContainerStyles,
    articleStyles,
    articleHeadingStyles,
    articleDescriptionStyles,
} from 'components/MeetLuminSection/styles';
import { IconNews2, IconSchedule } from 'components/atomics/atoms/Icons/Icons';

const StyledOuterContainer = styled.section`
    ${outerContainerStyles}
`;
const StyledInnerContainer = styled(Container)`
    ${innerContainerStyles}
`;
const StyledHeadingContainer = styled.div`
    ${headingContainerStyles}
`;
const StyledHeading = styled(Heading)`
    ${headingStyles}
`;
const StyledArticlesContainer = styled.div`
    ${articlesContainerStyles}
`;
const StyledArticle = styled.article`
    ${articleStyles}
`;
const StyledArticleHeading = styled(Heading)`
    ${articleHeadingStyles}
`;
const StyledArticleDescription = styled.p`
    ${articleDescriptionStyles}
`;

const MeetLuminSection = () => (
    <StyledOuterContainer>
        <StyledInnerContainer>
            <StyledHeadingContainer>
                <StyledHeading level={2}>
                    <FormattedMessage
                        id="meetLuminSection.heading"
                        values={{ break: <br /> }}
                    />
                </StyledHeading>
            </StyledHeadingContainer>
            <StyledArticlesContainer>
                <StyledArticle>
                    <IconNews2 />
                    <StyledArticleHeading level={3}>
                        <FormattedMessage id="meetLuminSection.clientQuestions.heading" />
                    </StyledArticleHeading>
                    <StyledArticleDescription>
                        <FormattedMessage id="meetLuminSection.clientQuestions.description" />
                    </StyledArticleDescription>
                </StyledArticle>
                <StyledArticle>
                    <IconSchedule />
                    <StyledArticleHeading level={3}>
                        <FormattedMessage id="meetLuminSection.freshIdeas.heading" />
                    </StyledArticleHeading>
                    <StyledArticleDescription>
                        <FormattedMessage id="meetLuminSection.freshIdeas.description" />
                    </StyledArticleDescription>
                </StyledArticle>
            </StyledArticlesContainer>
        </StyledInnerContainer>
    </StyledOuterContainer>
);

export default MeetLuminSection;
