import * as type from 'state-management/constants/homepage.common';

export const getHomepage = () => ({
    type: type.GET_HOMEPAGE_DATA,
});

export const getHomepageSuccess = components => ({
    type: type.GET_HOMEPAGE_DATA_SUCCESS,
    components,
});

export const getHomepageFailure = error => ({
    type: type.GET_HOMEPAGE_DATA_FAIL,
    error,
});


export const postHomepagePoll = parameters => ({
    type: type.POST_HOMEPAGE_POLL,
    parameters,
});

export const postHomepagePollSuccess = response => ({
    type: type.POST_HOMEPAGE_POLL_SUCCESS,
    response,
});

export const postHomepagePollFailure = error => ({
    type: type.POST_HOMEPAGE_POLL_FAILURE,
    error,
});

export const getHomepagePollData = parameters => ({
    type: type.GET_HOMEPAGE_POLL_DATA,
    parameters,
});

export const getHomepagePollDataSuccess = response => ({
    type: type.GET_HOMEPAGE_POLL_DATA_SUCCESS,
    response,
});

export const getHomepagePollDataFailure = error => ({
    type: type.GET_HOMEPAGE_POLL_DATA_FAILURE,
    error,
});
