import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import anime from 'animejs';
import {
    Button, Container, Sticky, StickyContainer,
} from 'components';
import { IconCaratDown } from 'components/atomics/atoms/Icons/Icons';
import {
    contentWrapperStyles,
    innerContainerStyles,
    titleContainerStyles,
    titleStyles,
    linksContainerStyles,
    itemStyles,
    linkStyles,
    sectionStyles,
    triggerIconStyles,
    triggerStyles,
    triggerTextStyles,
    outerContainerStyles,
} from 'components/SubNavigation/styles';

export const StyledSection = styled.div`
    ${sectionStyles};
`;
const StyledOuterContainer = styled(({ passedRef, ...other }) => (
    <Container innerRef={passedRef} {...other} />
))`
    ${outerContainerStyles};
`;
const StyledInnerContainer = styled(Container)`
    ${innerContainerStyles};
`;
const StyledTrigger = styled(Button)`
    ${triggerStyles};
`;
const StyledTriggerText = styled.span`
    ${triggerTextStyles};
`;
const StyledTriggerIcon = styled(IconCaratDown)`
    ${triggerIconStyles};
`;
const StyledContentWrapper = styled.div`
    ${contentWrapperStyles};
`;
const StyledTitleContainer = styled.div`
    ${titleContainerStyles}
`;
const StyledTitle = styled.p`
    ${titleStyles};
`;
const StyledLinksContainer = styled.ul`
    ${linksContainerStyles};
`;
const StyledItem = styled.li`
    ${itemStyles};
`;
export const StyledLink = styled.a`
    ${linkStyles};
`;

const SCROLL_DURATION = 100;

class SubNavigation extends React.Component {
    constructor(props) {
        super(props);

        this.containerRef = React.createRef();

        this.state = {
            isExpanded: false,
            activeItem: -1,
        };

        this.collapse = this.collapse.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick);
    }

    getActiveLinkId(links) {
        const containerNode = this.containerRef.current;
        let containerHeight = containerNode ? containerNode.clientHeight : "";
        let activeId;
        let i = links.length;

        while (i > 0) {
            const link = links[i - 1];
            const node = document.getElementById(`sage-moment-${link.id}`);

            if (node) {
                const position = node.getBoundingClientRect();

                if (position.top < containerHeight) {
                    activeId = link.id;
                    break;
                }
            }

            i -= 1;
        }

        return activeId;
    }

    /**
 * Animate scroll to signup section near bottom of logged out homepage.
 */
    scrollToArea(areaId) {
        const scrollElement = window.document.scrollingElement
            || window.document.documentElement
            || window.document.body;
        const toElement = document.getElementById(areaId);
        const toTop = toElement.getBoundingClientRect().top;
        const scrollTo = (toTop + scrollElement.scrollTop + 20);
        anime({
            targets: scrollElement,
            scrollTop: scrollTo, //20px padding from top of viewport to purple title of signup
            duration: SCROLL_DURATION,
            easing: 'easeInOutQuad',
        });
    }

    toggle() {
        const { isExpanded } = this.state;

        if (isExpanded) {
            this.collapse();
        } else {
            this.expand();
        }
    }

    collapse() {
        this.setState({
            isExpanded: false,
        });

        document.removeEventListener('click', this.handleOutsideClick);
    }

    expand() {
        this.setState({
            isExpanded: true,
        });

        document.addEventListener('click', this.handleOutsideClick);
    }

    handleOutsideClick(event) {
        const { target } = event;
        const container = this.containerRef.current;

        if (container && container !== target && !container.contains(target)) {
            this.collapse();
        }
    }

    handleClick(index){
        this.setState({
            activeItem: index
        });             
    }

    renderNavigation(isSticky) {
        const { id, data: { links, subNavigationTitle } } = this.props;
        const { isExpanded } = this.state;
        const activeId = isSticky ? this.getActiveLinkId(links) : '';
        const triggerTitle = activeId ? links.find(link => link.id === activeId).subNavigationTitle : subNavigationTitle;

        return (
            <React.Fragment>
                <StyledTrigger
                    aria-controls={`sage-moment-links-${id}`}
                    aria-expanded={isExpanded}
                    id="btn-sub-navigation-trigger"
                    onClick={this.toggle}
                    variant={{ isExpanded, isSticky }}
                >
                    <StyledTriggerText
                        variant={{ isExpanded }}
                    >
                        {triggerTitle}
                    </StyledTriggerText>
                    <StyledTriggerIcon
                        variant={{ isExpanded }}
                    />
                </StyledTrigger>
                <StyledLinksContainer
                    id={`sage-moment-links-${id}`}
                    variant={{ isExpanded, isSticky }}
                >
                    {
                        links.map((link, index) => {
                            const isActive = link.id === activeId;

                            return (
                                <StyledItem
                                    key={`sage-moment-link-${link.id}`}
                                    variant={{ isSticky }}
                                >
                                    <StyledLink
                                        onClick={() => {  this.handleClick(index); this.scrollToArea(`sage-moment-${link.id}`); }}
                                        variant={{ isActive, isSticky }} 
                                        selectedlist= {link.id== index ? 'nav-active' : ''}                                                           
                                    >
                                        {link.momentTitle}
                                    </StyledLink>
                                </StyledItem>
                            );
                        })
                    }
                </StyledLinksContainer>
            </React.Fragment>
        );
    }

    render() {
        const { id, data: { links, subNavigationTitle } } = this.props;

        return (
            links && subNavigationTitle && (
                <StyledSection>
                    <StickyContainer>
                        <Sticky
                            boundToContainer={Sticky.END}
                            enabled={links && links.length > 0}
                            id={`sticky-sub-navigation-${id}`}
                            isFixed
                            hasPlaceholder
                            render={({ isSticky }) => (
                                <StyledOuterContainer
                                    passedRef={this.containerRef}
                                    size={Container.LARGE}
                                    variant={{ isSticky }}
                                >
                                    <StyledInnerContainer
                                        id={`sub-navigation-${id}`}
                                        variant={{ isSticky }}
                                    >
                                        <StyledContentWrapper
                                            variant={{ isSticky }}
                                        >
                                            <StyledTitleContainer
                                                variant={{ isSticky }}
                                            >
                                                <StyledTitle>
                                                    {subNavigationTitle}
                                                </StyledTitle>
                                            </StyledTitleContainer>
                                            {this.renderNavigation(isSticky)}
                                        </StyledContentWrapper>
                                    </StyledInnerContainer>
                                </StyledOuterContainer>
                            )}
                        />
                    </StickyContainer>
                </StyledSection>
            ));
    }
}

SubNavigation.propTypes = {
    data: PropTypes.shape({
        subNavigationTitle: PropTypes.string,
        links: PropTypes.arrayOf(PropTypes.shape({
            subNavigationTitle: PropTypes.string,
            id: PropTypes.string,
        })),
    }).isRequired,
    id: PropTypes.string.isRequired,
};

export default SubNavigation;