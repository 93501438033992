import { css } from 'styled-components';
import { outerFocusMixin } from 'components/FocusVisibility/styles';
import {
    FROM_DESKTOP,
    FROM_TABLET,
} from '../../../../utils/mediaQueries';
import { COLORS, RGB_COLORS } from 'utils/variables';

export const dropdownWrapperStyles = css`
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    min-width: 220px;
    outline: none;

    * {
        outline: 0px !important;
        -webkit-appearance: none;
        box-shadow: none !important;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
    }
    
    @media screen and ${FROM_TABLET} {
        margin-top: -6px;
        min-width: 186px;
    }

    @media screen and ${FROM_DESKTOP} {
        min-width: 220px;
    }

    &:focus {
        div[data-rel="Select"] {
            color: ${COLORS.SAPPHIRE};
        }

        div[data-rel="selectLabel"] {
            color: ${COLORS.SAPPHIRE};
        }
    }
 `;

export const dropdownValueStyles = ({ variant }) => css`
    border-bottom: 1px solid ${COLORS.SAPPHIRE};
    box-sizing: border-box;
    font-size: 15px;
    line-height: 1;
    min-height: 36px;
    padding-top: 25px;
    padding-bottom: 17px;
    padding-left: 0;
    position: relative;
    user-select: none;
    width: 100%;
    color: ${COLORS.BLACK};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;

    &:not([value='0']){
        border-bottom: 1px solid ${COLORS.SAPPHIRE};
        color: ${COLORS.BLACK}
    }

    @media screen and ${FROM_TABLET} {
        padding-top: 25px;
        min-width:186px;
    }

    @media screen and ${FROM_DESKTOP} {
        width: 100%;
    }

    /* stylelint-disable */
    em {
        font-weight: 500;
        font-style: normal;
        display: inline-block;
        color:${COLORS.BLACK};
        text-overflow: ellipsis;
        overflow: hidden;

        ${props => props.multiSelectValue && props.multiSelectValue.length > 0 && css`
            width: calc(100% - 20px);
        `};

        ${variant.theme === 'dark' && css`
            color: ${COLORS.WHITE};
        `}
    }

    ${variant.theme === 'dark' && css`
        color: ${COLORS.WHITE};
    `}

    ${variant.size === 'medium' && css`
        min-height: 57.67px;

        @media screen and ${FROM_TABLET} {
            font-size: 15px;
            
        }

        @media screen and ${FROM_DESKTOP} {
            font-size: 15px;
        }
    `}

    ${variant.theme === 'dark' && css`
        border-bottom-color: ${COLORS.SAPPHIRE};
    `}

    ${variant.appearance === 'criteria' && css`
        border-bottom-width: 2px;
        cursor: pointer;
        font-size: 15px;
        font-weight: 500;
        padding-bottom: 15;
        padding-right: 0;
        padding-left: 0;

        @media screen and ${FROM_TABLET} {
            border-bottom-width: 2px;
            font-size: 15px;
            padding-right: 34px;
        }
    `}
    /* stylelint-enable */
`;

export const dropdownWrapperOptionsStyles = css`
    max-height: 222px;
    overflow: auto;
    box-shadow: 0 8px 16px 0 ${COLORS.MEDIUM_GRAY1};
    
    li:first-child[data-val='None'] {
        border-bottom: 1px solid  ${COLORS.LIGHT_GRAY2};
    }
`;

export const dropdownOptionsStyles = ({ variant }) => css`
    background: ${COLORS.WHITE};
    display: none;
    list-style: none;
    margin: 0;
    padding-left: 0;
    position: absolute;
    text-align: left;
    top: 0;
    width: 100%;
    z-index: 100;
    margin-top:10px;
    text-transform: none;
    /* stylelint-disable */
    font-weight: 500;

    ${variant.isOpen && css`
        display: block;
    `}

    /* stylelint-enable */
`;

export const dropdownEmptyOptionStyles = ({ variant }) => css`
    box-sizing: border-box;
    color: ${COLORS.BLACK};
    cursor: pointer;
    font-size: 15px;
    line-height: 1;
    ${outerFocusMixin};
    padding: 15px 0;
    position: relative;
    border-bottom: 2px solid ${COLORS.SAPPHIRE};
    background-color: ${COLORS.LIGHT_GRAY1};
    text-transform: none;
    font-weight: 500;
    min-height: 49px;

    span {
        font-weight: bold;
        color: ${COLORS.SAPPHIRE};
    }

    &:focus {
        background-color: ${COLORS.PALE_OCEAN};
    }

    @media screen and ${FROM_TABLET} {
        font-size: 15px;
        height: 49px;
    }

    /* stylelint-disable */

    ${variant.appearance === 'criteria' && css`
        color: ${COLORS.BLACK};
    `}

    /* stylelint-enable */
`;

export const dropdownClearOptionStyles = css`
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    line-height: normal;
    ${outerFocusMixin};
    padding: 10px 23px;
    text-decoration: underline;
    color: ${COLORS.SAPPHIRE};
    text-align: right;
    text-transform: none;;
    font-weight: bold;
    text-decoration: none;

    &:focus {
        background-color: ${COLORS.PALE_OCEAN};
    }

    @media screen and ${FROM_TABLET} {
        font-size: 12px;
    }
`;

export const dropdownLabelStyles = css`
    font-size: 10px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.58;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    position: absolute;
    top: -10px;
    color: ${COLORS.MEDIUM_GRAY2};
    margin-top: 2px; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const dropdownIconStyles = ({ variant }) => css`
    bottom: 36%;
    color: ${COLORS.SAPPHIRE};
    position: absolute;
    right: 0;

    &:focus {
        color: ${COLORS.SAPPHIRE};
    }

    /* stylelint-disable */
    ${variant.theme === 'dark' && css`
        color: ${COLORS.WHITE};
    `}

    ${variant.appearance === 'criteria' && css`
        right: 0;

        @media screen and ${FROM_TABLET} {
            right: 0;
        }
    `}
     /* stylelint-enable */
`;

export const dropdownOptionStyles = ({ variant }) => css`
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 15px;
    line-height: normal;
    ${outerFocusMixin};
    padding: 11px 23px;
    color: ${COLORS.BLACK};
    font-weight: 500;
    max-height: 18px;

    &:focus,
    &:hover {
        background-color: rgba(${RGB_COLORS.OCEAN}, 0.06);
    }


    @media screen and ${FROM_TABLET} {
        font-size: 15px;
    }

    /* stylelint-disable */
    ${!variant.isSelected && css`
        font-weight: 500;
    `}

    ${variant.isSelected && css`
        font-weight: 700;
        
        &:hover {
            background: none;
        }
    `};
    /* stylelint-enable */
`;

export const dropdownEmptyIconStyles = css`
    color: ${COLORS.SAPPHIRE};
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
`;

export const dropdownItemLabelStyles = ({ variant }) => css`
    flex: 1 1 auto;
    min-width: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    ${variant.isSelected && css`
        color:${COLORS.SAPPHIRE};
        font-weight:700;
    `};
`;

export const indicatorStyles = ({ variant }) => css`
    align-items: center;
    border: 2px solid ${COLORS.LIGHT_GRAY3};
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
    height: 18px;
    justify-content: center;
    margin-left: 20px;
    width: 18px;

    /* stylelint-disable */
    ${variant.isSelected && css`
        background-color: ${COLORS.SAPPHIRE};
        border-color: ${COLORS.SAPPHIRE};
    `};
    /* stylelint-enable */
`;

export const checkIconStyles =  ParentComponent => css`
    color: ${COLORS.SAPPHIRE};
    border: 2px solid ${COLORS.SAPPHIRE};
    border-radius: 50%;
    height: 18.5px;
    margin-right: -3px;
    margin-left: -2px;
    width: 18.5px;
    padding-right: 3px;
    padding-left: 3px;
    background-color: white !important;
    fill: ${COLORS.SAPPHIRE};

    ${ParentComponent} & {
        color: transparent;
    }
`;

export const plusIconStyles = ParentComponent => css`
    color: transparent;

    /* stylelint-disable */
    ${ParentComponent}:hover & {
        color: ${COLORS.LIGHT_GRAY3};
    }
    /* stylelint-enable */
`;
