export const REFINEMENT_CARD_URL = '/personalization-service/advisor/refinement_card';

export const GET_PROFILE_REFINEMENT_CARD = 'profileRefinementCard/GET_PROFILE_REFINEMENT_CARD';
export const GET_PROFILE_REFINEMENT_CARD_SUCCESS = 'profileRefinementCard/GET_PROFILE_REFINEMENT_CARD_SUCCESS';

export const NOT_INTERESTED_IN = 'profileRefinementCard/NOT_INTERESTED_IN';

export const REFINEMENT_SESSION_COUNT_URL = '/api/personalization-service/advisor/refinement_card_details';

export const INCREMENET_SESSION_COUNT = 'profileRefinementCard/INCREMENT_LOGIN_COUNT';
