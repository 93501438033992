import { css } from 'styled-components';
import {
    RGB_COLORS,
    COLORS,
    FONT_AVENIR_NEXT,
} from "../../../../utils/variables";
import { FROM_DESKTOP } from "../../../../utils/mediaQueries";

export const iconClose = css`
    fill: none;
    color: ${COLORS.SAPPHIRE};
    height: 16px;
    stroke-width: 1px;
    width: 16px;
`;

export const iconEyeInactive = css`
    fill: none;
    color: ${COLORS.SAPPHIRE};
    height: 22px;
    stroke-width: 2px;
    width: 23px;
    margin-left: -5px;
`;

export const iconSave = css`
    fill: none; 
    color: ${COLORS.SAPPHIRE};
    height: 18px;
    stroke-width: 2px;
    width: 14px;

    ${props => props.saved === 'true' && css`
        fill: ${COLORS.SAPPHIRE};
    `}
`;

export const iconEye = css`
    fill: none;
    color: ${COLORS.SAPPHIRE};
    height: 22px;
    stroke-width: 2px;
    width: 23px;
    margin-left: -5px;
`;

export const kebabTextStyles = css`
    color: ${COLORS.MEDIUM_GRAY2};
    line-height: 38px;
    font-size: 22px;
    font-weight: 600;
    padding-left:40px;

    @media screen and ${FROM_DESKTOP} {
        line-height: 42px;
    }

    ${props => props.color === 'alternate' && css`
        color: ${COLORS.SAPPHIRE};
    `}
`;

export const iconContainerStyles = css`
    position: absolute;
    left: 25px;
    top: 10px;

    @media screen and ${FROM_DESKTOP} {
        top: 13px;
    }
`;

export const panelStyles = css`
    background:${COLORS.WHITE};
    height: auto;
    position: absolute;
    left: 0;
    z-index: 10000;
    width: 99.5%;
    box-shadow: 0 1px 3px rgba(${RGB_COLORS.BLACK},0.12), 0 1px 2px rgba(${RGB_COLORS.BLACK},0.12);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    border:1px Solid ${COLORS.LIGHT_GRAY3};
    bottom: 0;
    max-width: -webkit-fill-available;

    &:hover {
        box-shadow: 0 7px 14px rgba(${RGB_COLORS.BLACK},0.12), 0 5px 5px rgba(${RGB_COLORS.BLACK},0.12);
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        margin-bottom: 0 !important;
        
        li {
            button {
                overflow: hidden;
                height: 38px;
                width: 100%;
                background: ${COLORS.WHITE};
                padding-left: 20px;
                border:0;
                text-align: left;
                position: relative;
                font-family: ${FONT_AVENIR_NEXT};

                span {
                    font-size: 15px;
                    color: ${COLORS.SAPPHIRE};
                }

                @media screen and ${FROM_DESKTOP} {
                    height: 42px;
                }
            }

            button:hover, button:focus {
                cursor:pointer;
                background-color: ${COLORS.PALE_OCEAN};
            }

            &:last-child {
                border-top: 1px solid ${COLORS.LIGHT_GRAY1};
            }

            button:active {
                background: ${COLORS.PALE_OCEAN};
            }
        }
    }
`;
