import {
    all,
    call,
    put,
    takeLatest,
} from 'redux-saga/effects';

import Api from '../bridge/api';

import {
    GET_MARKET_INDICES,
    INDICES_URL,
} from '../constants/marketIndices.common';

import {
    getMarketIndicesSuccess,
    getMarketIndicesFailure,
} from '../actions/marketIndices.common';

/**
 * Get market indices items.
 */
function* getMarketIndicesItems() {
    try {
        const indicesData = yield call(Api.get, INDICES_URL);

        yield put(getMarketIndicesSuccess(indicesData.data));
    } catch (e) {
        yield put(getMarketIndicesFailure(e));
    }
}

function* marketIndicesSaga() {
    yield all([
        takeLatest(GET_MARKET_INDICES, getMarketIndicesItems),
    ]);
}

export default marketIndicesSaga;
