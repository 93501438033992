import { COLLAPSE, EXPAND, TOGGLE } from 'state-management/constants/navigation';

export function collapse() {
    return {
        type: COLLAPSE,
    };
}

export function expand() {
    return {
        type: EXPAND,
    };
}

export function toggle() {
    return {
        type: TOGGLE,
    };
}
