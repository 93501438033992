import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'components/atomics/atoms/Link/Link';
import isDesktop from 'utils/isDesktop';
import { IconWrench } from 'components/atomics/atoms/Icons/Icons';
import {
    containerStyles,
    contentContainerStyles,
    titleStyles,
    titleContainerStyles,
    topicTagStyles,
    iconContainerStyles,
    footerLinksStyles,
    footerLinksContainerStyles,
    contentLinkContainerStyles,
    footerBytxtStyles,
    toolCategoryStyles,
} from 'components/atomics/molecules/ToolsContentCardNew/styles';
import { contextHubRecordEvent } from 'utils/contextHub';
import {
    CARDS_EVENT_LIST_Tool, CARDS_EVENT_LIST,
} from 'utils/contextHubEventListConstants';
import { getTruncatedString } from 'utils/contentCardUtils';

export const TopicTag = styled.div`
    ${topicTagStyles}
`;

export const IconContainer = styled.div`
    ${iconContainerStyles}
`;

export const TitleContainer = styled.div`
    ${titleContainerStyles}
`;

export const Title = styled.div`
    ${titleStyles}
`;

export const Container = styled.div`
    ${containerStyles}
`;

export const ContentContainer = styled.div`
    ${contentContainerStyles}
`;

export const ContentLinkContainer = styled(Link)`
    ${contentLinkContainerStyles}
`;

export const FooterContainerLinks = styled.div`
    ${footerLinksContainerStyles}
`;

export const FooterLinks = styled.p`
    ${footerLinksStyles}
`;

export const FooterBytxt = styled.p`
    ${footerBytxtStyles}
`;

export const ToolCategory = styled(Link)`
    ${toolCategoryStyles}
`;

const MAX_ARTICLE_TITLE_CHARS = 75;

/**
 * Creates a tool content card. 
 * TODO: Can we remove 'ToolsContentCard' now and rename this to remove the 'new'?
 */
class ToolsContentCard extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.state = {
            isDesktop: isDesktop(),
            status: false,
            clickChange: false,
        };

        this.onClick = this.onClick.bind(this);
        this.handleBtnClick = this.handleBtnClick.bind(this);
    }

    /**
     * Pass up the fact this guide was clicked, so parent container can track that data.
     */
    onClick() {
        this.props.handleGuideClick(this.props.article);
    }

    /**
     * Handle tool button clicks.
     */
    handleBtnClick() {
        this.setState({
            status: !this.state.status,
        });
    }


    /**
     * Render the tool card and underlying components.
     */
    render() {
        let linkValue = '';

        if (this.props.toolCardInfo.categoryCode !== null) {
            linkValue = "/learn/collections/client-life-events/" + this.props.toolCardInfo.categoryCode;
        } else {
            linkValue = "-";
        }

        return (
            <Container data-rel="ToolCard">
                <ContentContainer >
                    <TopicTag >
                        <ContentLinkContainer
                            to={this.props.toolCardInfo.link}
                            onClick={() => {
                                this.props.toolCardInfo.title;
                                contextHubRecordEvent(CARDS_EVENT_LIST(
                                    'tool',
                                    title
                                ));
                                this.onClick();
                            }}>
                            <IconContainer>
                                <IconWrench
                                    className="icon-wrench"
                                />
                            </IconContainer>
                        </ContentLinkContainer>
                        <div>
                            <div />
                            <div>
                                {this.props.toolCardInfo.toolCategory
                                    && <ToolCategory to={linkValue}>
                                        {this.props.toolCardInfo.toolCategory}
                                    </ToolCategory>}
                            </div>
                            <div />
                        </div>
                    </TopicTag>
                    <ContentLinkContainer to={this.props.toolCardInfo.link}
                        onClick={() => {
                            contextHubRecordEvent(CARDS_EVENT_LIST_Tool(
                                'tool',
                                this.props.toolCardInfo.name
                            ));
                            this.props.sendClickOnTools(this.props.toolCardInfo.id);
                            this.onClick();
                        }}
                    >
                        <TitleContainer>
                            <Title>
                                {getTruncatedString(this.props.toolCardInfo.name, MAX_ARTICLE_TITLE_CHARS)}
                            </Title>
                            <div data-rel="description" dangerouslySetInnerHTML={{ __html: this.props.toolCardInfo.description }} />
                        </TitleContainer>
                    </ContentLinkContainer>
                    <ContentLinkContainer
                        to="/learn/tools"
                        isfooter="true"
                    >
                        <FooterContainerLinks>
                            <FooterLinks>Tools</FooterLinks>
                            <FooterBytxt>Provided by Capital Group</FooterBytxt>
                        </FooterContainerLinks>
                    </ContentLinkContainer>
                </ContentContainer>
            </Container>
        );
    }
}

// TODO: USE TOOLS API
ToolsContentCard.propTypes = {
    toolCardInfo: PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        link: PropTypes.string,
        order: PropTypes.number,
        latestPublishTimestamp: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.string),
        visited: PropTypes.bool,
    }),
    handleGuideClick: PropTypes.func,
    sendClickOnTools: PropTypes.func,
};

ToolsContentCard.defaultProps = {
    handleGuideClick: () => { },
}

export default ToolsContentCard;
