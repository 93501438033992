import { css } from 'styled-components';
import { outerFocusMixin } from 'components/FocusVisibility/styles';
import { FROM_DESKTOP, FROM_TABLET, FROM_MOBILE_TO_DESKTOP, SMALL_MOBILE, FROM_MOBILE, FROM_MOBILE_TO_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS, RGB_COLORS } from 'utils/variables';

export const navigationWrapperStyles = css`
    box-sizing: border-box;
    margin: 0 60px 0 22px;
    padding: 0 40px;
    word-wrap: break-word;

    @media screen and ${FROM_DESKTOP} {
        align-self: center;
        margin: 0;
        padding: 0;
        position: relative;
        width: auto;
    }

    a#profile{
        color: ${COLORS.DARK_GRAY2};
        text-decoration: none;
        display: inline-block;
        padding: 7px 0px 45px 0;
        margin-left: -18px;
        margin-top: 9px;
        font-family: 'AvenirNext',sans-serif;
        font-size: 18px;
        font-weight: 600;
        text-transform: capitalize;
        line-height: 0.1em;
        max-width: 200px;        
        position: relative;
        text-overflow: ellipsis;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        white-space: nowrap;
        z-index: 4;  
        ${outerFocusMixin}; 
        
        @media screen and ${FROM_TABLET} {
            font-size: 20px;
        }

        @media screen and ${FROM_DESKTOP} {
            font-size: 13px;
            padding: 10px 0px 10px 0;
        }

        &:active:focus {
            outline: none;
        }

    }

    a#profile:hover, a#profile.selected {
        color: ${COLORS.SAPPHIRE};
    }

    #profile:first-child {
        @media screen and ${FROM_DESKTOP} {
            display: inline-block;
        }

        @media screen and ${FROM_MOBILE_TO_DESKTOP} {
            display: none !important;
        }
    }

    #profile:nth-child(2) {
        @media screen and ${FROM_DESKTOP} {
            display:  none !important;
        }

        @media screen and ${FROM_MOBILE_TO_DESKTOP} {
            display: inline-block !important;
        }
    }
`;

export const navigationTriggerStyles = css`
    background: none;
    border: 0;
    box-sizing: border-box;
    color: ${COLORS.DARK_GRAY2};
    cursor: pointer;
    display: none;
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 16px;
    text-transform: capitalize;
    height: 36px;
    line-height: 1.5;
    max-width: 200px;
    min-width: 120px;
    overflow: hidden;
    padding: 10px 50px 10px 24px;
    position: relative;
    text-overflow: ellipsis;
    user-select: none;
    white-space: nowrap;
    z-index: 4;

    @media screen and ${FROM_DESKTOP} {
        display: inline-block;
        padding: 7px 0px 10px 40px;
    }

    @media screen and ${FROM_MOBILE_TO_DESKTOP} {
        display: inline-block;
        margin-bottom: 30px;
        margin-left: -50px;
    }

    @media screen and ${FROM_TABLET} {        
        margin-left: -40px;
    }

    a {
        color: ${COLORS.DARK_GRAY2};
        text-decoration: none;

        &:hover {
            color: ${COLORS.SAPPHIRE};
        }

        &:focus {
            outline: 2px solid ${COLORS.OCEAN};
        }

        &.selected {
            color: ${COLORS.SAPPHIRE};
            text-decoration: none;
        }
    }
`;

export const navigationTriggerLinkStyles = css`
    background: none;
    border: 0;
    box-sizing: border-box;
    color: ${COLORS.DARK_GRAY2};
    cursor: pointer;
    display: none;
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 13px;
    text-transform: capitalize;
    height: 36px;
    line-height: 1.8;
    max-width: 200px;
    min-width: 120px;
    overflow: hidden;
    padding: 0;
    text-decoration: none;
    position: relative;
    text-overflow: ellipsis;
    user-select: none;
    white-space: nowrap;
    margin-bottom: -14px;
    z-index: 4;

    &:hover {
        color: ${COLORS.SAPPHIRE};
    }

    &:focus {
        outline: 2px solid ${COLORS.OCEAN};
    }

    @media screen and ${FROM_DESKTOP} {
        display: inline;
    } 

    @media screen and ${SMALL_MOBILE} {
        font-size: 18px !important;
        margin-top: 248px;
    }

    @media screen and ${FROM_MOBILE_TO_DESKTOP} {
        display: inline-block;
        margin-bottom: 30px;
        margin-top: 0;
        margin-left: -20px;
        font-size: 20px;
    }
`;

export const navigationTriggerIconStyles = css`
    position: absolute;
    right: 24px;
    stroke: ${COLORS.DARK_GRAY2};
    top: 50%;
    transform: translateY(-50%);

    /* stylelint-disable */

    ${props => props.variant && props.variant.isOpen && css`
        transform: translateY(-50%) rotateZ(180deg);
    `}

    /* stylelint-enable */
    @media screen and ${FROM_MOBILE_TO_DESKTOP} {
        display: none;
    }
`;

export const navigationListStyles = css`
    display: block;
    list-style: none;
    margin: 0 0 30px 0;
    padding: 0;

    @media screen and ${FROM_DESKTOP} {
        background: ${COLORS.WHITE};
        box-shadow: 0 4px 20px 0 rgba(${RGB_COLORS.BLACK}, 0.16);
        margin: 0;
        min-width: 100%;
        padding: 45px 0 10px;
        position: absolute;
        top: -5px;

        /* stylelint-disable */

        ${props => props.variant && !props.variant.isOpen && css`
            display: none;
        `}

        /* stylelint-enable */
    }
    @media screen and ${FROM_MOBILE_TO_DESKTOP} {
        display: none;
    }
`;

export const navigationListItemStyles = css`
    margin-bottom: 20px;

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 0;
    }
`;

export const navigationLinkStyles = css`
    color: ${COLORS.MEDIUM_GRAY2};
    display: flex;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    ${outerFocusMixin};
    text-decoration: none;

    &:focus {
        outline: 2px solid ${COLORS.OCEAN};
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 16px;
        font-weight: 500;
        line-height: 1;
        padding: 7px 24px;

        &:hover {
            background-color: ${COLORS.LIGHT_GRAY1};
        }
    }
`;
