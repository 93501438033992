import axios from "axios";
//import { cleanErrors } from "./errorHandler";
/*import {
  closeAllModals,
  setShowError,
  setSystemError,
} from "../redux/reducers/common";*///
import store from 'state-management/store/storeFactory';
import localStore from "./store";
import { CSRF_TOKEN, SIGNOUT_URL } from "./api";
import {
  SIGN_IN_URL,
} from 'state-management/constants/signIn';

//import { setNavigationState } from "../redux/reducers/common";
/*import {
  RESET_PASSWORD,
} from "../redux/constants/identity";*/

// variable declaration for future use
const BASE_API_URL = window.location.protocol + '//' + window.location.host + '/api';

export const STORAGE_APPID = "appId";

export class ApiCore {
  service;
  constructor() {
    this.service = axios.create({
      baseURL: BASE_API_URL,
      timeout: 60000,
      headers: { "content-type": "application/json" },
      xsrfCookieName: "access_token",
      xsrfHeaderName: "X-XSRF-TOKEN",
      validateStatus: function (status) {
        return (
          status === 200 ||
          status === 201 ||
          /*status === 401 ||*/ //not allowed to enable token refresh
          status === 403 ||
          // status === 404 ||
          status === 409
          // status === 400
        );
      },
    });
    this.service.interceptors.response.use(
      this.handleSuccess,
      this.handleError
    );
  }

  static REFRESH_CODE = 401;
  static REFRESH_ENDPOINT = "/api/security-service/refresh";
  static CREDENIALS = "/advisor-service/advisors/me/credentials";
  //static LOGOUT_ENDPOINT = "/api/advisor/registration-service/cookies";

  handleSuccess(response) {
    return response.data;
  }

  /*showTimeoutError() {
    console.log('DEBUG: going to show timeout error..');
    axios.delete(Api.LOGOUT_ENDPOINT).then(
      (response) => {
        console.log('DEBUG, i called endpoint (delete method): ' + Api.LOGOUT_ENDPOINT);
        store.dispatch(setNavigationState(true));
        store.dispatch(closeAllModals());
        setTimeout(() => {
          console.log('DEBUG, setting error page to L2 error with timeout=true');
          window.location.href =
            window.location.protocol +
            "//" +
            window.location.host +
            "/" +
            ROUTES.BASENAME +
            ROUTES.ERROR_L2 +
            "?timeout=true";
        }, 500);

      },
      (error) => {
        //cookie clearing failed?
        console.log("DEBUG: something errored in previous attempt to show error page: " + error);

        setTimeout(() => {
          console.log('DEBUG (from error catch), setting error page to L2 error with timeout=true');
          window.location.href =
            window.location.protocol +
            "//" +
            window.location.host +
            "/" +
            ROUTES.BASENAME +
            ROUTES.ERROR_L2 +
            "?timeout=true";
        }, 500);
      });
  }*/

  manualRefresh = () => {
    /*return axios(ApiCore.REFRESH_ENDPOINT, {
      method: 'GET',
      withCredentials: true,
    });

    return true;*/
    return axios(ApiCore.REFRESH_ENDPOINT, {
      method: 'GET',
      withCredentials: true,
      ...(window.location.hostname === 'localhost' ? { headers: { local: true } } : null)
    });
  }

  handleError = (error) => {
    const originalRequest = error.config;

    /*if (
      error.response.status === Api.REFRESH_CODE &&
      !originalRequest._retry &&
      originalRequest.url !== Api.REFRESH_ENDPOINT
    ) {*/
    if (
        error.message === "Request failed with status code 401" &&
        !originalRequest._retry &&
        originalRequest.url !== ApiCore.REFRESH_ENDPOINT &&
        originalRequest.url !== ApiCore.CREDENIALS &&
        originalRequest.url !== SIGN_IN_URL
    ) {
      originalRequest._retry = true;

      return axios(ApiCore.REFRESH_ENDPOINT, {
        method: 'GET',
        withCredentials: true,
        ...(window.location.hostname === 'localhost' ? { headers: { local: true } } : null)
      }).then(

      //return this.get(ApiCore.REFRESH_ENDPOINT, null).then(
        (response) => {
          //axios(..) worked
          return this.service(originalRequest).catch((error) => {
            //original request failed after token refresh
            //goto error page, timeout state
            //store.dispatch(closeAllModals());
            //this.showTimeoutError();
            this.signOut();
            //alert('Would do sign-out..');
            //store.dispatch(setShowTimeoutError(true));
            //store.dispatch(setShowError(true));
          });
        },
        (error) => {
          //token refresh failed
          //goto error page, timeout state
          //store.dispatch(closeAllModals());
          //this.showTimeoutError();
          this.signOut(); 

            //alert('Would do sign-out..');
          //store.dispatch(setShowTimeoutError(true));
          //store.dispatch(setShowError(true));
        }
      );
    //catch 500's and 409's
    // } else if (error.response.status > 499 && error.response.status < 600) {
    //   //goto error application error page / external generic AEM error page
    //   error.response.status === 503
    //     ? store.dispatch(setSystemError(true))
    //     : store.dispatch(setShowError(true));
    } else {
      return Promise.reject(error);
    }
  };

  signOut() {
    if (window.location.pathname !== '/sign-out') {
        window.location.href = SIGNOUT_URL;
    }
  }

  post(path, payload) {
    return this.executeWithCredentials(path, "POST", payload);
  }

  get(path, payload) {
    if (path === ApiCore.REFRESH_ENDPOINT) {
      payload = {
        params: {
          refreshCall: 'true'
        }
      };
    }
    
    return this.executeWithCredentials(path, "GET", null, null, null, payload);
  }

  delete(path, payload) {
    return this.executeWithCredentials(path, "DELETE", payload);
  }

  put(path, payload) {
    return this.executeWithCredentials(path, "PUT", payload);
  }

  execute(
    path,
    method,
    payload,
    successCallback,
    failureCallback,
    params,
  ) {
    //cleanErrors();

    const requestHeaders = {
      "content-type": "application/json",
      "x-csrf-token": localStore.get(CSRF_TOKEN),
      //"x-app-id": null,
      ...(window.location.hostname === 'localhost' ? { local: true } : null)
    };

    const appId = localStore.get(STORAGE_APPID);

    //if we have an app id from reset password, append as header to api request for reset
    /*if (appId && path === RESET_PASSWORD) {
      requestHeaders["x-app-id"] = appId;
    }*/

    return this.service
      .request({
        method: method,
        url: path,
        responseType: "json",
        data: payload,
        headers: requestHeaders,
        params: params ? params.params : null,
      });
      //.then((responseType) => successCallback(response.data, response.status))
      //.catch((error) => failureCallback(error));
  }

  executeWithCredentials(
    path,
    method,
    payload,
    successCallback,
    failureCallback,
    params,
  ) {
    //cleanErrors();

    const requestHeaders = {
        "content-type": "application/json",
        "x-csrf-token": localStore.get(CSRF_TOKEN),
        //"x-app-id": null,
        //Cookie:  Cookies.get("access_token"),
        ...(window.location.hostname === 'localhost' ? { local: true } : null)
      };

    return this.service
      .request({
        method: method,
        url: path,
        responseType: "json",
        data: payload,
        withCredentials: true,
        headers: requestHeaders,
        params: params ? params.params : null,
      });
      //.then((response) => successCallback(response.data, response.status))
      //.catch((error) => failureCallback(error));
  }
}

export default ApiCore;
