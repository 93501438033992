export const getVisiblePagesInfo = (currentPage, totalPages) => {
    const pages = Array.from({ length: totalPages }, (v, k) => k + 1);

    return {
        visiblePages: pages.filter(page => page === 1
            || page === pages.length
            || Math.abs(page - currentPage) <= 1
            || (currentPage === 1 && page === 3)
            || (currentPage === pages.length && page === pages.length - 2)),
        showLeftPlaceholder: pages.length > 4 && currentPage > 3,
        showRightPlaceholder: pages.length > 4 && pages.length - currentPage > 2,
    };
};
