import { css } from 'styled-components';
import { getSpanInPercent, GUTTER } from 'utils/grid';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS, RGB_COLORS } from 'utils/variables';

export const sectionStyles = css`
    padding: 90px 0 45px;
    /* stylelint-disable */

    ${props => props.variant && props.variant.hasTitle && css`
        background: linear-gradient(to bottom, rgba(${RGB_COLORS.LIGHT_GRAY2}, 0.5), rgba(${RGB_COLORS.LIGHT_GRAY1}, 0)) no-repeat;
        background-size: 100% 150px;
    `};

    /* stylelint-enable */
`;

export const contentWrapperStyles = css`
    margin: 0 ${getSpanInPercent(1, true, 'mobile')};
    padding-bottom: 5px;
    position: relative;

    &::after {
        background-image: linear-gradient(89deg, ${COLORS.SAPPHIRE}, ${COLORS.OCEAN});
        bottom: 0;
        content: '';
        height: 5px;
        left: 0;
        position: absolute;
        right: 0;
    }

    @media screen and ${FROM_TABLET} {
        margin: 0 28px;
        width: ${getSpanInPercent(9, true, 'tablet')};
    }

    @media screen and ${FROM_DESKTOP} {
        margin-left: calc(${getSpanInPercent(1)} + ${GUTTER.desktop}px);
        width: ${getSpanInPercent(6)};
    }
`;

export const titleStyles = css`
    box-sizing: border-box;
    color: ${COLORS.MEDIUM_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        font-size: 24px;
        letter-spacing: -0.2px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 30px;
    }
`;

export const contentStyles = css`
    color: ${COLORS.DARK_SAPPHIRE};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 24px;
    font-weight: 400;
    letter-spacing: -0.6px;
    line-height: 1.25;
    padding: 15px 0 20px 0;
    position: relative;
    word-wrap: break-word;

    p {
        margin: 0;
    }

    @media screen and ${FROM_TABLET} {
        font-size: 36px;
        letter-spacing: -0.3px;
        line-height: 1.17;
        padding: 30px 0 20px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 44px 0 20px 0;
    }
`;
