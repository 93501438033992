import React from 'react';
import PropTypes from 'prop-types';

import { getSanitizedHtml } from 'utils/sanitizeHtml';

const SanitizedHTML = (props) => {
    const {
        htmlContent,
        tag,
        contentRef,
        ...other
    } = props;
    const adjustedProps = Object.assign({}, other, {
        dangerouslySetInnerHTML: getSanitizedHtml(htmlContent),
        ref: contentRef,
    });

    return React.createElement(
        tag,
        adjustedProps,
    );
};

SanitizedHTML.propTypes = {
    htmlContent: PropTypes.string,
    tag: PropTypes.string,
    contentRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
};

SanitizedHTML.defaultProps = {
    htmlContent: '',
    tag: 'span',
    contentRef: undefined,
};

export default SanitizedHTML;
