import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Container, Heading, SanitizedHTML } from 'components';

import {
    contentStyles,
    contentWrapperStyles,
    sectionStyles,
    titleStyles,
} from 'components/SageMoment/styles';

const StyledSection = styled.div`
    ${sectionStyles};
`;
const StyledContentWrapper = styled.div`
    ${contentWrapperStyles}
`;
const StyledHeading = styled(Heading)`
    ${titleStyles}
`;
const StyledContent = styled(SanitizedHTML)`
    ${contentStyles}
`;

const SageMoment = (props) => {
    if (!SageMoment.areAttributesValid(props.data)) {
        return null;
    }

    const hasTitle = props.data && props.data.momentTitle;

    return (
        <StyledSection
            id={`sage-moment-${props.id}`}
            variant={{ hasTitle }}
        >
            <Container>
                <StyledContentWrapper>
                    {
                        props.data.momentTitle && (
                            <StyledHeading
                                level={2}
                            >
                                <SanitizedHTML
                                    htmlContent={props.data.momentTitle}
                                />
                            </StyledHeading>
                        )
                    }
                    <StyledContent
                        htmlContent={props.data.momentContent}
                        tag="div"
                    />
                </StyledContentWrapper>
            </Container>
        </StyledSection>

    );
};

SageMoment.areAttributesValid = data => data.momentContent;

SageMoment.propTypes = {
    data: PropTypes.shape({
        momentTitle: PropTypes.string,
        momentContent: PropTypes.string,
    }).isRequired,
    id: PropTypes.string.isRequired,
};

export default SageMoment;