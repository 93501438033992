import { css } from 'styled-components';

export const containerStyles = css`
    display: flex;
    flex-direction: column;

    &>div {
        max-width: none;
    }
`;

export const gridOfFourStyles = css`
    &>div {
        width: calc(25% - 18px);
    }

    &>div:nth-child(2) {
        margin-left: 24px;

    }

    &>div:nth-child(3) {
        margin-left: 24px;
        margin-right: 24px;
    }
`;

export const gridOfFourThreeStyles = css`
    &>div {
        max-width: none;
        width: calc(33.3% - 16px);
    }

    &>div:nth-child(2) {
        margin-left: 24px;
        margin-right: 24px;
    }
`;
