import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Tabs } from 'components';
import { BreakpointsContext, MOBILE } from 'components/Breakpoints/Breakpoints';
import { containerStyles, tabsContainerStyles } from 'containers/Profile/Preferences/styles';
import SourcesTabPane from '../../../containers/Profile/Preferences/SourcesTabPane/SourcesTabPane';
import TopicsTabPane from 'containers/Profile/Preferences/TopicsTabPane/TopicsTabPane';

const StyledContainer = styled.div`
    ${containerStyles};
`;
const StyledTabsContainer = styled.div`
    ${tabsContainerStyles};
`;

/**
 * Preferences page under account screen.
 * @param {*} param0 
 */
const Preferences = ({
    formik,
    sources,
    section,
    topicCategories,
    onTouch,
    onEditDefaultClick,
    isInEditMode
}) => {
    const getPanes = (breakpoint) => {
        const isMobile = breakpoint === MOBILE;
        const sourcesTitleId = isMobile
            ? 'profilePage.tab.sources' : 'profilePage.tab.preferredSources';
        const topicsTitleId = isMobile
            ? 'profilePage.tab.topics' : 'profilePage.tab.preferredTopics';

        return [
            {
                id: 'sources-tab',
                tabTitle: <FormattedMessage id={sourcesTitleId} />,
                content: () => (
                    <SourcesTabPane
                        formik={formik}
                        sources={sources}
                        onTouch={onTouch}
                        onEditDefaultClick={onEditDefaultClick}
                        isInEditMode={isInEditMode}
                    />
                ),
            },
            {
                id: 'topics-tab',
                tabTitle: <FormattedMessage id={topicsTitleId} />,
                content: () => (
                    <TopicsTabPane
                        formik={formik}
                        topicCategories={topicCategories}
                        onTouch={onTouch}
                        onEditDefaultClick={onEditDefaultClick}
                        breakpoint={breakpoint}
                        isInEditMode={isInEditMode}
                    />
                ),
            },
        ];
    };

    const activeIndex = section === 'topics' ? 1 : 0;
    return (
        <BreakpointsContext.Consumer>
            {breakpoint => (
                <StyledContainer>
                    <Tabs
                        activeIndex={activeIndex}
                        ariaLabelledBy="preferences"
                        panes={getPanes(breakpoint)}
                        tabListWrapper={StyledTabsContainer}
                    />
                </StyledContainer>
            )}
        </BreakpointsContext.Consumer>
    );
};

Preferences.propTypes = {
    formik: PropTypes.shape({}).isRequired,
    sources: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    topicCategories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onTouch: PropTypes.func.isRequired,
    section: PropTypes.string,
};

Preferences.defaultProps = {
    section: null,
};

export default Preferences;
