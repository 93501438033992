import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { sortBy } from 'lodash';
import OnboardingContent from '../../../containers/Onboarding/OnboardingContent/OnboardingContent';
import OnboardingSidebar from 'containers/Onboarding/OnboardingSidebar/OnboardingSidebar';
import TopSourcesTabPanel from '../../../containers/Onboarding/Sources/TopSourcesTabPanel/TopSourcesTabPanel';
import AllSourcesTabPanel from '../../../containers/Onboarding/Sources/AllSourcesTabPanel/AllSourcesTabPanel';
import { setOnBoardingStep as setOnBoardingStepAction } from 'state-management/actions/onboarding';
import { addMessage as addAriaNotificationAction } from 'state-management/actions/ariaNotification';
import {
    Sticky, StickyContainer,
} from 'components';
import * as analytics from 'utils/adobeAnalytics';
import {
    onboardingSectionContainerStyles,
    onboardingSectionTextStyles,
    onboardingTabsContainerStyles,
    stickyContainerStyles,
    sourceTabs,
} from 'containers/Onboarding/styles';
import { STEP_NUMS } from 'state-management/constants/onboarding';
import { PAGE_NAME_SOURCES, SITE_SECTION_SOURCES } from 'utils/analyticsConstants';
import OnboardingErrors from 'containers/Onboarding/OnboardingErrors/OnboardingErrors';
import Button from 'components/atomics/atoms/Button/Button';
import { TYPE_TAB } from 'components/atomics/atoms/Button/Button';
import {
    BreakpointsContext,
    MOBILE,
} from 'components/Breakpoints/Breakpoints';
import { contextHubRecordEvent } from 'utils/contextHub';
import language from '../../../lang/en.json'

const StyledStickyContainer = styled(StickyContainer)`
    ${stickyContainerStyles};
`;

const StyledTabsContainer = styled.div`
    ${sourceTabs};
`;

const StyledSectionContainer = styled.div`
    ${onboardingSectionContainerStyles};
`;

const StyledOnboardingSectionText = styled.p`
    ${onboardingSectionTextStyles};
`;

/**
 * Render the sources page for onboarding.
 */
class Sources extends React.Component {
    /**
     * Initial state.
     */
    state = {
        tab: 0,
    };

    /**
     * Gather needed data at mount.
     */
    componentDidMount() {
        this.props.setOnBoardingStep(STEP_NUMS.SOURCES);
        this.props.handleIsValid(this.props.selected);
        this.props.addAriaNotification('onboarding.sources.loaded', {
            translated: false,
        });

        analytics.dispatchPageViewStart(PAGE_NAME_SOURCES, SITE_SECTION_SOURCES);
    }

    /**
     * Set the selected tab.
     * @param {*} index 
     */
    changeTab(index) {
        this.setState({
            tab: index,
        });
        let analyticsId
        if (this.state.tab == 0) {
            analyticsId = language.translations['onboarding.sources.allSources']
        }
        if (this.state.tab == 1) {
            analyticsId = 'Top 20 Sources'
        }
        this.CH_handleComponentEventList(analyticsId)
    }

    //analytics 
    CH_handleComponentEventList(analyticsId) {

        var tracking =
        {
            "type": "tab",
            "action": analyticsId,
        };
        contextHubRecordEvent(tracking);
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const { isLoaded, isMobile, isChoose, sources } = this.props;
        const topSources = sources.filter(s => s.top);
        const orderedSources = sortBy(sources, s => s.name.toLowerCase());

        return (
            <StyledSectionContainer>
                <StyledStickyContainer>
                    <Sticky
                        enabled={!isMobile}
                        id="sticky-sources-desc"
                        topOffset={40}
                    >

                        <OnboardingSidebar
                            isAnimatable
                            isLoaded={isLoaded}
                            featuredText={(
                                <React.Fragment>
                                    <FormattedMessage
                                        id="onboarding.sources.heading1"
                                        values={{ firstName: this.props.firstName }}
                                    />&nbsp;
                                    {!isChoose
                                        && <FormattedMessage id="onboarding.sources.heading2" />}
                                    {isChoose
                                        && <FormattedMessage id="onboarding.sources.heading2.choose" />}
                                </React.Fragment>
                            )}
                            sectionTitle={(
                                <FormattedMessage id="onboarding.sources.title" />
                            )}
                        >
                            <div data-rel="splash" />
                            <StyledOnboardingSectionText>
                                {!isChoose
                                    && <FormattedMessage id="onboarding.sources.text" />}
                                {isChoose
                                    && <FormattedMessage id="onboarding.sources.text.choose" />}
                            </StyledOnboardingSectionText>
                            <OnboardingErrors
                                validationMessageId="onboarding.sources.error"
                                showValidationError={this.props.showError}
                                errorRef={this.props.errorRef}
                                globalErrors={this.props.globalErrors}
                            />
                        </OnboardingSidebar>
                    </Sticky>
                    <OnboardingContent
                        isAnimatable
                        isLoaded={isLoaded}
                        key="sources-onboarding-content"
                        topPadding="9px"
                    >
                        <div>
                            <BreakpointsContext.Consumer>
                                {(breakpoint) => {
                                    if (breakpoint !== MOBILE) {
                                        return (
                                            <React.Fragment>
                                                <StyledTabsContainer>
                                                    <Button
                                                        type={TYPE_TAB}
                                                        href="#topSources"
                                                        selected={this.state.tab === 0}
                                                        onClick={() => { this.changeTab(0) }}
                                                    >
                                                        <FormattedMessage
                                                            id="onboarding.sources.topSources"
                                                            values={{ number: topSources.length }}
                                                        />
                                                    </Button>
                                                    <Button
                                                        type={TYPE_TAB}
                                                        href="#allSources"
                                                        selected={this.state.tab === 1}
                                                        onClick={() => { this.changeTab(1) }}
                                                    >
                                                        <FormattedMessage id="onboarding.sources.allSources" />
                                                    </Button>
                                                </StyledTabsContainer>
                                                {this.state.tab === 0
                                                    && <TopSourcesTabPanel
                                                        {...this.props}
                                                        sources={topSources}
                                                    />}
                                                {this.state.tab === 1
                                                    && <AllSourcesTabPanel
                                                        isChoose={isChoose}
                                                        {...this.props}
                                                        sources={orderedSources}
                                                    />}
                                            </React.Fragment>
                                        );
                                    } else {
                                        return (
                                            <AllSourcesTabPanel
                                                isChoose={isChoose}
                                                {...this.props}
                                                sources={orderedSources}
                                            />
                                        );
                                    }
                                }}
                            </BreakpointsContext.Consumer>
                        </div>
                    </OnboardingContent>
                </StyledStickyContainer>
            </StyledSectionContainer>
        );
    }
}

Sources.propTypes = {
    addAriaNotification: PropTypes.func.isRequired,
    changeStatus: PropTypes.func.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    sources: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        imageName: PropTypes.string,
    })).isRequired,
    firstName: PropTypes.string.isRequired,
    handleIsValid: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.number).isRequired,
    setOnBoardingStep: PropTypes.func.isRequired,
    showError: PropTypes.bool.isRequired,
    errorRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    globalErrors: PropTypes.arrayOf(PropTypes.shape),
    isChoose: PropTypes.bool,
};

Sources.defaultProps = {
    errorRef: React.createRef(),
    globalErrors: [],
    isChoose: false,
};

const mapStateToProps = state => ({
    isLoaded: state.sources.isLoaded,
    sources: state.sources.sources,
});

const mapDispatchToProps = dispatch => ({
    addAriaNotification: (message, options) => (
        dispatch(addAriaNotificationAction(message, options))
    ),
    setOnBoardingStep: idx => dispatch(setOnBoardingStepAction(idx)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sources);
