import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    cardStyles,
    clickableContainerStyles,
    imageContainerStyles,
    headingStyles,
    linkStyles,
    descriptionStyles,
    headingIconStyles,
    descriptionIconStyles,
} from 'containers/Home/HomepageGreeting/LinkCard/styles';
import {
    ClickableContainer, Heading, Link,
} from 'components';
import { IconSeeMore } from 'components/atomics/atoms/Icons/Icons';
import { connect } from "react-redux";
import { SourceModal } from 'components/atomics/molecules/NewsSource/SourceModal';

const StyledCardContainer = styled.article`
    ${cardStyles}
`;

const StyledClickableContainer = styled(ClickableContainer)`
    ${clickableContainerStyles}
`;

const StyledImageContainer = styled.div`
    ${imageContainerStyles}
`;

const StyledHeading = styled(Heading)`
    ${headingStyles}
`;

export const StyledLink = styled(Link)`
    ${linkStyles}
`;

const StyledDescription = styled.p`
    ${descriptionStyles}
`;

const StyledHeadingIcon = styled(IconSeeMore)`
    ${headingIconStyles}
`;

const StyledDescriptionIcon = styled(IconSeeMore)`
    ${descriptionIconStyles}
`;

class LinkCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sourceModal: false,
        }
    }

    closeSourceModal = () => {
        this.setState({ sourceModal: false });
    };

    render() {
        const {
            title, description, imageAsset,
        } = this.props;
        let altTitle = title;
        let link = this.props.link;
        const sourceLength = this.props.selectedSources.length;
        if (link != undefined &&
            link === '/news' && sourceLength != undefined && sourceLength < 3) {
            link = '';
        }

        return (
            <>
                <SourceModal isOpen={this.state.sourceModal} closeSourceModal={this.closeSourceModal} />
                <StyledCardContainer>
                    <StyledClickableContainer
                        url={link}
                        onClick={(e) => {
                            if (link == '') {
                                this.setState({ sourceModal: true });
                            }

                        }}
                    >
                        {imageAsset
                            && (
                                <StyledImageContainer background={imageAsset}>
                                    <img
                                        src={`/api${imageAsset}`}
                                        alt={altTitle}
                                    />
                                </StyledImageContainer>
                            )}
                        <StyledHeading level={2}>
                            <StyledLink
                                to={link}
                            >
                                {title}
                            </StyledLink>
                            <StyledHeadingIcon />
                        </StyledHeading>
                        <StyledDescription>
                            {description}
                            <StyledDescriptionIcon />
                        </StyledDescription>
                    </StyledClickableContainer>
                </StyledCardContainer>
            </>
        );
    }
}

LinkCard.defaultProps = {
    imageAsset: null,
    selectedSources: [],
};

LinkCard.propTypes = {
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    imageAsset: PropTypes.string,
    selectedSources: PropTypes.arrayOf(PropTypes.number),
};
const mapStateToProps = (state) => ({
    selectedSources: state.basicProfile.sourceIds,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(LinkCard);