import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { getPracticeLab } from 'state-management/actions/guide';
import { SIZE as CARD_SIZE } from 'components/ContentCard/constants';
import { dismissCoachMark } from 'state-management/actions/coachMark';
import language from '../../../lang/en.json';
import anime from 'animejs';
import { GLOBAL } from 'utils/variables';
import { StyledLoader, defaultOptions } from 'components/Loader/Loader';
import {
    BreakpointsContext,
    DESKTOP,
    MOBILE,
    TABLET,
} from 'components/Breakpoints/Breakpoints';
import Row from 'components/atomics/atoms/Row/Row';
import Button from 'components/atomics/atoms/Button/Button';
import Column from 'components/atomics/atoms/Column/Column';
import {
    TYPE_GHOST_RIGHT,
    TYPE_PRIMARY_MEDIUM,
} from 'components/atomics/atoms/Button/Button';
import {
    TYPE_4COLUMN,
    TYPE_3COLUMN,
} from 'components/atomics/atoms/Row/Row';
import {
    TYPE_4ROW,
} from 'components/atomics/atoms/Column/Column';
import CapitalIdeasCard from '../../../components/atomics/molecules/CapitalIdeasCard/CapitalIdeasCard';
import bodyPractice from '../../../../../stories/mockData/practiceLab.json';
import {
    Container,
    Heading,
} from 'components';
import {
    headerStyles,
    sectionStyles,
    cardsArea,
    listArea,
    buttonContainer,
    listAreaCard,
    listAreaCardWrapper,
    sliderContainerStyles,
    loaderStyles,
    blankAreaStyles,
} from 'containers/Guides/PracticeLab/styles';
import {
    headingStyles,
} from 'containers/Guides/GuidesContainer/styles';
import { Carousel } from "../../../components/atomics/molecules/CarouselMolecule/Carousel";
import { transformContentItem } from "utils/contentCardUtils";
import {
    Loader,
} from 'components';

export const ListAreaCard = styled.div`
    ${listAreaCard};
`;

export const ListAreaCardWrapper = styled.div`
    ${listAreaCardWrapper}
`;

export const ButtonContainer = styled.div`
    ${buttonContainer};
`;

export const StyledSection = styled.section`
    ${sectionStyles};
`;

export const StyledHeader = styled.div`
    ${headerStyles};
`;

export const StyledHeading = styled(Heading)`
    ${headingStyles};
`;

export const ListArea = styled.div`
    ${listArea}
`;

export const CardsArea = styled.div`
    ${cardsArea};
`;

const SliderContainer = styled.section`
    ${sliderContainerStyles}
`;

const StyledLoaderContainer = styled.div`
    ${loaderStyles}
`;

const StyledBlankArea = styled.div`
    ${blankAreaStyles}
`;

export const getSizeProps = (idx, internal) => {
    if (idx < 2 || internal) {
        return {
            size: CARD_SIZE.STANDARD,
        };
    }

    return {
        size: CARD_SIZE.STANDARD,
        mobile: CARD_SIZE.SMALL,
    };
};

let replacedIndex = null;

/**
 * Render the Practice Lab landing page.
 */
class PracticeLab extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.animateClosed = this.animateClosed.bind(this);

        this.contentRef = React.createRef();
    }

    /**
     * Update state on property changes.
     */
    componentDidMount() {
        this.setState({
            cardPosition: Math.floor(Math.random() * Math.floor(12)) + 1,
        });

        this.props.getPracticeLab();
    }

    componentWillUpdate(nextProps) {
        if (nextProps.loadingFailed === true) {
            this.animateClosed();
        }
    }

    /**
     * Collapse this component closed (and opacity to 0) from bottom to top.
     */
    animateClosed() {

        const contentNode = this.contentRef.current;

        this.animation = anime.timeline();

        this.animation
            .add({
                targets: contentNode,
                easing: [0.53, 0.05, 0.01, 0.97],
                opacity: [1, 0],
                duration: GLOBAL.ANIMATIONS.COLLAPSE_TIME.MILISECONDS,
                height: 0,
                marginTop: 0,
                paddingTop: 0,
            });

        this.props.handleComponentFailure();
    }

    /**
     * Transform the underlying data to be in line with required display properties of Practice Lab.
     * @param {*} start 
     * @param {*} end 
     */
    getArticles(start, end) {
        const articles = [];
        const firstItems = bodyPractice.data.content.contentItems.slice(start, end);

        Object.keys(firstItems).forEach(function (key, index) {
            articles.push(transformContentItem(firstItems[index]));
        });

        return articles;
    };

    /**
     * Render a standard content card.
     */
    renderStandardCard = (articleIndex, index, articles) => {
        let article = articles[articleIndex];

        if (article == null && replacedIndex != null) {
            article = articles[replacedIndex];
            replacedIndex = null;
        }

        if (article == null) {
            return null;
        }

        return (
            <CapitalIdeasCard
                article={article}
            />
        );

    }

    /**
     * Render the section header.
     */
    renderHeader = () => {
        return (
            <div>

                {
                    <StyledHeader>
                        <StyledHeading
                            level={2}
                        >
                            {this.props.header}
                        </StyledHeading>
                    </StyledHeader>
                }
            </div>
        )
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const { practiceData } = this.props;

        let bodyArticles = null

        if (practiceData && practiceData.contentItems) {
            bodyArticles = practiceData && practiceData.contentItems.map(v => {
                return {
                    ...v,
                    url: v.link,
                    sourceName: v.sourceTitle
                }
            });
        } else {
            bodyArticles = practiceData && practiceData.map(v => {
                return {
                    ...v,
                    url: v.link,
                    sourceName: v.sourceTitle
                }
            });
        }

        if (practiceData) {
            return (
                <StyledSection ref={this.contentRef}>
                    <Container noMobilePadding={true}>
                        {this.renderHeader()}
                        {bodyArticles.length < 1
                            && <StyledBlankArea>
                                <StyledLoaderContainer>
                                    <StyledLoader
                                        options={defaultOptions}
                                    />
                                </StyledLoaderContainer>
                            </StyledBlankArea>}

                        {bodyArticles.length > 0
                            && <CardsArea>
                                <BreakpointsContext.Consumer>
                                    {(breakpoint) => {
                                        if (breakpoint === DESKTOP) {

                                            return (
                                                <Column type={TYPE_4ROW}>
                                                    <div>
                                                        <Row type={TYPE_4COLUMN}>
                                                            {this.renderStandardCard(0, 1, bodyArticles)}
                                                            {this.renderStandardCard(1, 2, bodyArticles)}
                                                            {this.renderStandardCard(2, 3, bodyArticles)}
                                                            {this.renderStandardCard(3, 4, bodyArticles)}
                                                        </Row>
                                                    </div>
                                                </Column>
                                            );

                                        }

                                        if (breakpoint === TABLET) {
                                            return (
                                                <Column type={TYPE_4ROW}>
                                                    <Row type={TYPE_3COLUMN}>
                                                        {this.renderStandardCard(0, 1, bodyArticles)}
                                                        {this.renderStandardCard(1, 2, bodyArticles)}
                                                        {this.renderStandardCard(2, 3, bodyArticles)}
                                                    </Row>
                                                </Column>
                                            );
                                        }

                                        if (breakpoint === MOBILE) {
                                            const cItems = [
                                                {
                                                    component: this.renderStandardCard(0, 1, bodyArticles)
                                                }, {
                                                    component: this.renderStandardCard(1, 2, bodyArticles)
                                                }, {
                                                    component: this.renderStandardCard(2, 3, bodyArticles)
                                                }, {
                                                    component: this.renderStandardCard(3, 4, bodyArticles)
                                                },
                                            ].filter(({ component }) => component != null);

                                            return (
                                                <SliderContainer
                                                    data-rel="PracticeLabCollectionOfFour"
                                                >
                                                    <Carousel items={cItems} />
                                                </SliderContainer>
                                            );
                                        }
                                    }}
                                </BreakpointsContext.Consumer>
                            </CardsArea>}

                        {bodyArticles.length > 0
                            && <BreakpointsContext.Consumer>
                                {(breakpoint) => {
                                    if (breakpoint === MOBILE) {
                                        return (
                                            <ButtonContainer>
                                                <Button
                                                    type={TYPE_PRIMARY_MEDIUM}
                                                    to={`https://www.capitalgroup.com/advisor/practicelab.html`}
                                                    dataAnalyticsPlacement="Anchor : body"
                                                    dataAnalyticsLabel="trackLink : anchor"
                                                    dataAnalyticsId={language.translations["practiceLab.more"]}
                                                >
                                                    <FormattedMessage id="practiceLab.more" />
                                                </Button>
                                            </ButtonContainer>
                                        );
                                    } else {
                                        return (
                                            <ButtonContainer>
                                                <Button
                                                    type={TYPE_GHOST_RIGHT}
                                                    to={`https://www.capitalgroup.com/advisor/practicelab.html`}
                                                    dataAnalyticsPlacement="Anchor : body"
                                                    dataAnalyticsLabel="trackLink : anchor"
                                                    dataAnalyticsId={language.translations["practiceLab.more"]}
                                                >
                                                    <FormattedMessage id="practiceLab.more" />
                                                </Button>
                                            </ButtonContainer>
                                        );
                                    }
                                }}
                            </BreakpointsContext.Consumer>}
                    </Container>
                </StyledSection>
            );
        } else return null;
    }
}

PracticeLab.propTypes = {
    isLoaded: PropTypes.bool,
    practiceLabData: PropTypes.arrayOf(PropTypes.shape({})),
    isSeeMoreLinkVisible: PropTypes.bool,
    handleComponentFailure: PropTypes.func,
    loadingFailed: PropTypes.bool
};

PracticeLab.defaultProps = {
    isLoaded: false,
    isSeeMoreLinkVisible: true,
    practiceLabData: [],
    handleComponentFailure: () => { },
    loadingFailed: false
};

const mapStateTopProps = state => ({
    practiceData: state.guide.practiceLabData,
    isLoading: state.guide.isLoading,
    loadingFailed: state.guide.practiceLabFailed,
    header: state.guide.practiceLabDataHeader
})

const mapDispatchToProps = dispatch => ({
    dismissCoachMark: () => { dispatch(dismissCoachMark('KEBAB', true)) },
    getPracticeLab: () => dispatch(getPracticeLab()),
});

export default connect(mapStateTopProps, mapDispatchToProps)(PracticeLab);

