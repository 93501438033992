export const TOPICS_URL = '/advisor-service/contact-us/topics';
export const SUBMIT_FEEDBACK_URL = '/advisor-service/contact-us';

export const CONTACT_METHOD_EMAIL = 'email';
export const CONTACT_METHOD_PHONE = 'phone';

export const GET_TOPICS = 'contactUs/GET_TOPICS';
export const GET_TOPICS_SUCCESS = 'contactUs/GET_TOPICS_SUCCESS';
export const GET_TOPICS_FAIL = 'contactUs/GET_TOPICS_FAIL';

export const SUBMIT_FEEDBACK = 'contactUs/SUBMIT_FEEDBACK';
export const SUBMIT_FEEDBACK_SUCCESS = 'contactUs/SUBMIT_FEEDBACK_SUCCESS';
export const SUBMIT_FEEDBACK_FAIL = 'contactUs/SUBMIT_FEEDBACK_FAIL';

export const RESET_CONTACT_FORM = 'contactUs/RESET_CONTACT_FORM';
