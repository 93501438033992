import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { onboardingSectionErrorStyles } from 'containers/Onboarding/styles';

const OnboardingErrorText = styled.p`
    ${onboardingSectionErrorStyles};
`;

/**
 * Renders visible error states for onboarding steps.
 * @param {*} props 
 */
const OnboardingErrors = (props) => {
    const {
        globalErrors,
        validationMessageId,
        showValidationError,
        errorRef,
    } = props;

    const errorMessageId = showValidationError
        ? validationMessageId
        : globalErrors.length > 0 && globalErrors[0].errorMessage;

    return (
        <OnboardingErrorText
            tabIndex={errorMessageId ? '0' : '-1'}
            ref={errorRef}
        >
            {errorMessageId && <FormattedMessage id={errorMessageId} />}
        </OnboardingErrorText>
    );
};

OnboardingErrors.propTypes = {
    globalErrors: PropTypes.arrayOf(PropTypes.shape).isRequired,
    validationMessageId: PropTypes.string.isRequired,
    showValidationError: PropTypes.bool.isRequired,
    errorRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

OnboardingErrors.defaultProps = {
    errorRef: {},
};

export default OnboardingErrors;
