export const ARTICLE_VISIT_URL = (articleId, topicId) => `/personalization-service/article/${articleId}/${topicId}/click`;

export const VISIT_ARTICLE = 'articles/VISIT_ARTICLE';
export const ARTICLE_WAS_VISITED = 'articles/ARTICLE_WAS_VISITED';
export const VISIT_TT_ARTICLE = 'articles/VISIT_TT_ARTICLE';
export const TT_ARTICLE_WAS_VISITED = 'articles/TT_ARTICLE_WAS_VISITED';
//export const TRENDING_TOPIC_ARTICLE_WAS_VISITED = 'articles/TRENDING_TOPIC_ARTICLE_WAS_VISITED';

export const GET_ARTICLE = 'articles/GET_ARTICLE';
export const GET_ARTICLE_SUCCESS = 'articles/GET_ARTICLE_SUCCESS';
export const GET_ARTICLE_FAILURE = 'articles/GET_ARTICLE_FAILURE';

export const ARTICLE_PUBLISH_DATE_FORMAT = 'MMM[.] DD[,] YYYY';

export const ARTICLE_URL = articleId => `/content-service/articles/${articleId}`;

export const PL_VISIT_ARTICLE = 'articles/PL_VISIT_ARTICLE';
export const GET_VISIT_PRACTICE_LAB_URL = '/personalization-service/article/click';
export const PL_WAS_VISITED = 'articles/PL_WAS_VISITED';