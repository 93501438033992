import { css } from 'styled-components';
import { getSpanInPercent } from 'utils/grid';
import {
    FROM_DESKTOP,
    FROM_MOBILE_TO_TABLET,
    FROM_TABLET,
    FROM_TABLET_TO_DESKTOP,
    breakpoints,
} from 'utils/mediaQueries';
import { COLORS } from 'utils/variables';

export const wrapperContainerStyles = css`
    background-color: ${COLORS.WHITE};
    padding-top: 40px;

    @media screen and ${FROM_TABLET} {
        padding-top: 0;
    }
`;

export const outerContainerStyles = css`
    background: ${COLORS.WHITE};
    position: relative;
    max-height: 920px;
    max-width: ${breakpoints.max_splash_width}px;
    width: 100%;
    margin: auto;
    border: 1px solid transparent;

    @media screen and ${FROM_MOBILE_TO_TABLET} {
        &::before {
            background-color: ${COLORS.WHITE};
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: -1;
        }
    }

    @media screen and ${FROM_TABLET} {
        max-height: inherit;
        height: auto;
        min-height: 350px;
        margin-top: -4px;
    }

    @media screen and ${FROM_DESKTOP} {
        max-height: inherit;
        min-height: 300px;
    }
`;

export const imageContainerStyles = css`
    position: relative;
    top: 40px;
    z-index: 2;

    /* nasty IE hacks */
    @media  screen and (-ms-high-contrast: active) and (min-width: 250px) and (max-width: 349px),
            screen and (-ms-high-contrast: none) and (min-width: 250px) and (max-width: 349px) {
        height: 380px;
    }

    @media  screen and (-ms-high-contrast: active) and (min-width: 350px) and (max-width: 449px),
            screen and (-ms-high-contrast: none) and (min-width: 350px) and (max-width: 449px) {
        height: 480px;
    }

    @media  screen and (-ms-high-contrast: active) and (min-width: 450px) and (max-width: 559px),
            screen and (-ms-high-contrast: none) and (min-width: 450px) and (max-width: 559px) {
        height: 590px;
    }

    @media  screen and (-ms-high-contrast: active) and (min-width: 560px) and (max-width: 659px),
            screen and (-ms-high-contrast: none) and (min-width: 560px) and (max-width: 659px) {
        height: 680px;
    }

    @media  screen and (-ms-high-contrast: active) and (min-width: 660px),
            screen and (-ms-high-contrast: none) and (min-width: 660px) {
        height: 780px;
    }

    @media screen and ${FROM_TABLET} {
        position: absolute;
        top: auto;
        right: 0;
        bottom: -1px;
        width: 55%;
        height: auto;
        border: none;
    }

    @media screen and ${FROM_TABLET_TO_DESKTOP} {
        width: 62%;
    }

    @media screen and ${FROM_DESKTOP} {
        width: 45%;
    }

    img {
        display: block;
        max-width: 120%;
        width: 100%;

        @media screen and ${FROM_TABLET} {
            max-width: 100%;
            width: auto;
        }
    }
`;

export const contentContainerStyles = css`
    @media screen and ${FROM_MOBILE_TO_TABLET} {
        margin: 0 auto;
        max-width: 310px;
        text-align: center;
    }

    @media screen and ${FROM_TABLET} {
        padding-top: 60px;
        width: ${getSpanInPercent(5, true)};

        ${props => props.isWebinar && css`
            padding-top: 20px;
        `}
    }

    @media screen and ${FROM_TABLET} {
        padding-top: 94px;

        ${props => props.isWebinar && css`
            padding-top: 20px;
        `}
    }
`;

export const headingStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-size: 20px;
    font-weight: 600;
    line-height: 1.31;

    @media screen and ${FROM_TABLET} {
        font-size: 24px;
        line-height: 1.3;
        max-width: 400px;
    }
`;

export const descriptionStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-size: 18px;
    line-height: 1.39;
    margin: 15px 0 0;

    ${props => props.isWebinar && css`
        margin-bottom: 18px;
    `}

    @media screen and ${FROM_TABLET} {
        color: ${COLORS.MEDIUM_GRAY2};
        font-weight: 500;
        line-height: 1.3;
        margin: 10px 0 50px;
    }
`;
