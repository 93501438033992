import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import errorMessageStyles from 'components/Error/styles';

const StyledErrorMessage = styled.div`
    ${errorMessageStyles}
`;

const Error = ({ message }) => (
    <StyledErrorMessage>
        <FormattedMessage id={message} />
    </StyledErrorMessage>
);

Error.propTypes = {
    message: PropTypes.string.isRequired,
};

export default Error;
