import { css } from 'styled-components';
import { POSITIONS } from './constants';
import { FROM_TABLET, FROM_DESKTOP } from 'utils/mediaQueries';
import { COLORS } from 'utils/variables';

export const SIDE_OFFSET = 25;

export const coachMarkWrapperStyles = css`
    position: relative;
`;

export const coachMarkStyles = css`
    background-color: ${COLORS.DARK_SAPPHIRE};
    box-sizing: border-box;
    color: ${COLORS.WHITE};
    cursor: ${COLORS.WHITE};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 190px;

    ${props => props.noMinHeight && css`
        min-height: initial ! important;
    `};
    
    padding: 24px 24px 16px;
    position: absolute;
    width: 352px;
    height: 171px;
    z-index: 5;
    text-align: left;

    &::after {
        border: 8px solid transparent;
        content: '';
        position: absolute;
    }

    @media screen and ${FROM_TABLET} {
        width: 320px;
    }

    /* stylelint-disable */

    ${props => props.variant && props.variant.placement === '' && css`
        left: 0;
        position: fixed;
    `};

    ${props => props.variant && props.variant.placement === POSITIONS.TOP_LEFT && css`
        bottom: 100%;
        left: -${SIDE_OFFSET}px;
        margin-bottom: 12px;

        &::after {
            border-top-color: ${COLORS.DARK_SAPPHIRE};
            left: ${SIDE_OFFSET}px;
            top: 100%;
        }
    `};

    ${props => props.variant && props.variant.placement === POSITIONS.TOP_CENTER && css`
        bottom: 100%;
        left: 50%;
        margin-bottom: 12px;
        transform: translateX(-50%);

        &::after {
            border-top-color: ${COLORS.DARK_SAPPHIRE}
            left: 50%;
            margin-left: -8px;
            top: 100%;
        }
    `};

    ${props => props.variant && props.variant.placement === POSITIONS.TOP_RIGHT && css`
        bottom: 100%;
        margin-bottom: 12px;
        right: -${SIDE_OFFSET}px;

        &::after {
            border-top-color: ${COLORS.DARK_SAPPHIRE};
            right: ${SIDE_OFFSET}px;
            top: 100%;
        }
    `};

    ${props => props.variant && props.variant.placement === POSITIONS.RIGHT_TOP && css`
        left: 100%;
        margin-left: 12px;
        top: -25px;

        &::after {
            border-right-color: ${COLORS.DARK_SAPPHIRE};
            margin-top: 8px;
            right: 100%;
            top: 25px;
        }
    `};

    ${props => props.variant && props.variant.placement === POSITIONS.RIGHT_CENTER && css`
        left: 100%;
        margin-left: 12px;
        top: 50%;
        transform: translateY(-50%);

        &::after {
            border-right-color: ${COLORS.DARK_SAPPHIRE};
            margin-top: -8px;
            right: 100%;
            top: 50%;
        }
    `};

    ${props => props.variant && props.variant.placement === POSITIONS.RIGHT_BOTTOM && css`
        bottom: -25px;
        left: 100%;
        margin-left: 12px;

        &::after {
            border-right-color: ${COLORS.DARK_SAPPHIRE};
            bottom: 25px;
            margin-bottom: 8px;
            right: 100%;
        }
    `};

    ${props => props.variant && props.variant.placement === POSITIONS.BOTTOM_LEFT && css`
        left: -${SIDE_OFFSET}px;
        margin-top: 12px;
        top: 100%;

        &::after {
            border-bottom-color: ${COLORS.DARK_SAPPHIRE};
            bottom: 100%;
            left: ${SIDE_OFFSET}px;
        }
    `};

    ${props => props.variant && props.variant.placement === POSITIONS.BOTTOM_CENTER && css`
        left: 50%;
        margin-top: 12px;
        top: 100%;
        transform: translateX(-50%);

        &::after {
            border-bottom-color: ${COLORS.DARK_SAPPHIRE};
            bottom: 100%;
            left: 50%;
            margin-left: -8px;
        }
    `};

    ${props => props.variant && props.variant.placement === POSITIONS.BOTTOM_RIGHT && css`
        margin-top: 20px;
        right: 0;
        top: 100%;

        &::after {
            border-bottom-color: ${COLORS.DARK_SAPPHIRE};
            bottom: 100%;
            right: ${SIDE_OFFSET}px;
        }
    `};

    ${props => props.variant && props.variant.placement === POSITIONS.LEFT_TOP && css`
        margin-right: 12px;
        right: 100%;
        top: -25px;

        &::after {
            border-left-color: ${COLORS.DARK_SAPPHIRE};
            left: 100%;
            margin-top: 8px;
            top: 25px;
        }
    `};

    ${props => props.variant && props.variant.placement === POSITIONS.LEFT_CENTER && css`
        margin-right: 12px;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);

        &::after {
            border-left-color: ${COLORS.DARK_SAPPHIRE};
            left: 100%;
            margin-top: -8px;
            top: 50%;
        }
    `};

    ${props => props.variant && props.variant.placement === POSITIONS.LEFT_BOTTOM && css`
        bottom: -25px;
        margin-right: 12px;
        right: 100%;

        &::after {
            border-left-color: ${COLORS.DARK_SAPPHIRE};
            bottom: 25px;
            left: 100%;
            margin-bottom: 8px;
        }
    `};

    ${props => props.offsetLeft && css`
        left: 0 !important;
    `};

    ${props => props.forceLeft && typeof(props.forceLeft) === 'string' && css`
        left: ${props.forceLeft} !important;
    `};

    ${props => props.forceTop && typeof(props.forceTop) === 'string' && css`
        top: ${props.forceTop} !important;
    `};

    ${props => props.forceRight && typeof(props.forceRight) === 'string' && css`
        right: ${props.forceRight} !important;
        left: inherit !important;
    `};

    /* stylelint-enable */
`;

export const contentWrapperStyles = css`
    cursor: text;
    font-size: 15px;
    line-height: 20.8px;
    letter-spacing: -0.14px;
    font-weight: 500;
    max-width: 100%;
    word-wrap: break-word;
`;

export const controlsWrapperStyles = css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 12px;
    white-space: nowrap;

    &>a:first-child {
        margin-right: 8px;
        span{
            transition: unset;
        }
        
    }

    a:hover {
        color: ${COLORS.HOVER_SAPPHIRE};
        border-color: ${COLORS.HOVER_SAPPHIRE};
    }
`;

export const controlButtonStyles = css`
    margin-top: 5px;
    white-space: nowrap;

    & + & {
        margin-left: 9px;
    }
`;
