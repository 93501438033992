import { css } from 'styled-components';
import {
    TYPE_3ROW,
    TYPE_2ROW,
    TYPE_4ROW,
} from 'components/atomics/atoms/Column/Column';

export const containerStyles = css`
    display: flex;
    flex-direction: column;

    ${props => props.type === TYPE_3ROW && css`
        &>div {
            max-height: initial;
            height: calc(33.3% - 16px);
        }

        &>div:nth-child(2) {
            margin-top: 24px;
            margin-bottom: 24px;
        }
    `};

    ${props => props.type === TYPE_2ROW && css`
        &>div {
            height: calc(50% - 12px);
        }

        &>div:first-child {
            margin-bottom: 24px;
        }
    `};

    ${props => props.type === TYPE_4ROW && css`
        &>div {
            height: calc(25% - 18px);
        }

        &>div:nth-child(2) {
            margin-top: 24px;

        }

        &>div:nth-child(3) {
            margin-top: 24px;
            margin-bottom: 24px;
        }
    `};
`;
