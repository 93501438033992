import { css } from 'styled-components';

import { outerFocusMixin } from 'components/FocusVisibility/styles';
import { FROM_DESKTOP } from 'utils/mediaQueries';

export const navigationStyles = css`
    align-items: flex-start;
    display: flex;
`;

export const linkStyles = css`
    color: inherit;
    line-height: 1;
    max-width: 100%;
    ${outerFocusMixin};
    padding: 4px 0;
    text-decoration: none;
    word-wrap: break-word;

    &:hover {
        text-decoration: underline;
    }

    @media screen and ${FROM_DESKTOP} {
        margin: 0 0 0 36px;
        padding: 0;
    }
`;
