import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_MOBILE_TO_DESKTOP } from 'utils/mediaQueries';
import { COLORS, RGB_COLORS } from 'utils/variables';

export const drawerWrapperStyles = css`
    @media screen and ${FROM_MOBILE_TO_DESKTOP} {
        align-items: center;
        display: flex;
        position: relative;

        /* stylelint-disable */

        ${props => props.variant && props.variant.isExpanded && css`
            background-color: rgba(${RGB_COLORS.DARK_SAPPHIRE}, 0.6);
            bottom: 0;
            left: 0;
            position: fixed;
            right: 0;
            top: 0;
            z-index: 13;
        `};

        /* stylelint-enable */
    }
`;

export const navToggleButtonStyles = css`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    width: 20px;
    height: 12px;
    padding: 0;  
    z-index: 10;

    @media screen and ${FROM_DESKTOP} {
        display: none;
    }

    /* stylelint-disable */

    ${props => props.variant && props.variant.isExpanded && css`
        animation: fadeIn 0.3s forwards;
        animation-delay: 0.25s;
        opacity: 0;
        position: absolute;
        right: 87px;
        top: 26px;

        @keyframes fadeIn {
            100% {
                opacity: 1;
             }
        }
    `};

    /* stylelint-enable */
`;

export const navToggleIndicatorStyles = css`
    background-color: ${COLORS.DARK_GRAY2};
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;

    &::before,
    &::after {
        background-color: ${COLORS.DARK_GRAY2};
        content: '';
        height: 2px;
        left: 0;
        position: absolute;
        width: 20px;
    }

    &::before {
        bottom: -12px;
    }

    &::after {
        bottom: -6px;
    }

    /* stylelint-disable */

    ${props => props.variant && props.variant.isExpanded && css`
        transform: translate3d(-2px, 10px, 0) rotate(45deg);
        width: 32px;

        &::before {
            display: none;
        }

        &::after {
            transform: translate3d(0, -20px, 0) rotate(-90deg);
            width: 32px;
            bottom: -20px;
        }
    `};

    /* stylelint-enable */
`;

export const drawerStyles = css`
    align-items: stretch;
    background-color: ${COLORS.WHITE};
    bottom: 0;
    box-shadow: 0 11px 20px 0 rgba(${RGB_COLORS.BLACK}, 0.05);
    display: flex;
    left: 0;
    position: fixed;
    right: 60px;
    top: 0;
    transform: ${props => (props.variant && props.variant.isExpanded ? 'translate3d(0, 0, 0)' : 'translate3d(-100%, 0, 0)')};
    transition: transform linear 0.25s;

    @media screen and ${FROM_DESKTOP} {
        background-color: initial;
        bottom: auto;
        box-shadow: none;
        left: auto;
        position: relative;
        right: auto;
        top: auto;
        transform: none;
    }
`;

export const drawerContentStyles = css`
    position: relative;
    width: 100%;

    @media screen and ${FROM_MOBILE_TO_DESKTOP} {
        align-items: stretch;
        display: flex;
        flex-direction: row;
    }
`;
