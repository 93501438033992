import * as type from 'state-management/constants/savedContent';
import { ARTICLE_WAS_VISITED, PL_WAS_VISITED } from 'state-management/constants/articles.common';
import { GUIDE_WAS_VISITED, VISIT_CE_CREDIT } from 'state-management/constants/guide';
import * as saveTypes from 'state-management/constants/save';
import { isSubtypeOfGuide } from 'utils/contentCardUtils';
import {
    TOGGLE_SAVE_ARTICLE_SUCCESS,
    TOGGLE_SAVE_GUIDE_SUCCESS,
} from 'state-management/constants/save';

const initialState = {
    page: 0,
    isLoading: false,
    savedContent: [],
    totalElements: 0,
    totalPages: 0,
};

const setGuideIsSaving = (item, id) => (
    isSubtypeOfGuide(item.type)
        && item.id === id ? ({ ...item, isSaving: true }) : item);

const setArticleIsSaving = (item, id) => (
    item.type === type.TYPE_ARTICLE
        && item.id === id ? ({ ...item, isSaving: true }) : item);

const removeItemFromState = (state, itemId, totalElements) => {
    const { savedContent } = state;
    const newSavedContent = savedContent.reduce((accum, item, i) => {
        const { id } = item;

        return id !== itemId && Number.parseInt(id) !== itemId
            ? [...accum, item]
            : accum;
    }, []);

    return {
        ...state,
        savedContent: newSavedContent,
        totalElements: totalElements != undefined ? (totalElements - 1) : newSavedContent.length
    }
}

function savedContentReducer(state = initialState, action = {}) {
    switch (action.type) {
        case type.GET_SAVED_CONTENT_DATA:
            return {
                ...state,
                // isLoading is true when initially loading saves landing page
                // or changing pagination page
                isLoading: action.shouldScrollToTop,
            };
        case type.GET_SAVED_CONTENT_FAIL:
            return {
                ...state,
                isLoading: false,
            };
        case TOGGLE_SAVE_ARTICLE_SUCCESS:
            return state.savedContent.length > 0
                ? removeItemFromState(state, action.id, state.totalElements)
                : state;
        case TOGGLE_SAVE_GUIDE_SUCCESS:
            return state.savedContent.length > 0
                ? removeItemFromState(state, action.id, state.totalElements)
                : state;

        case type.SET_SAVED_CONTENT_DATA:
            return {
                ...state,
                isLoading: false,
                page: action.data.currentPage,
                savedContent: action.data.content.map(item => ({ ...item, isSaving: false })),
                totalElements: action.data.totalElements,
                totalPages: action.data.totalPages,
            };
        case saveTypes.TOGGLE_SAVE_GUIDE: {
            const { id } = action;

            return {
                ...state,
                savedContent: state.savedContent.map(item => setGuideIsSaving(item, id)),
            };
        }
        case saveTypes.TOGGLE_SAVE_ARTICLE: {
            const { id } = action;

            return {
                ...state,
                savedContent: state.savedContent.map(item => setArticleIsSaving(item, id)),
            };
        }
        case ARTICLE_WAS_VISITED: {
            const { id } = action;
            return {
                ...state,
                savedContent: state.savedContent.map(item => (item.id === id
                    ? { ...item, visited: true } : item)),
            };
        }
        case GUIDE_WAS_VISITED: {
            const { id } = action;
            return {
                ...state,
                savedContent: state.savedContent.map(item => (item.id === id
                    ? { ...item, visited: true } : item)),
            };
        }
        case VISIT_CE_CREDIT: {
            const { id } = action;
            return {
                ...state,
                savedContent: state.savedContent.map(item => (item.id === id
                    ? { ...item, visited: true } : item)),
            };
        }
        case PL_WAS_VISITED: {
            const { id } = action;
            if (state == null || state.savedContent == undefined || state.savedContent.length == 0) {
                return {
                    ...state,
                    isLoading: false,
                };
            }
            return {
                ...state,
                savedContent: state.savedContent.map(item => (item.id === id
                    ? { ...item, visited: true } : item)),
            };
        }
        default:
            return state;
    }
}

export default savedContentReducer;
