import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET, FROM_MOBILE_TO_TABLET } from 'utils/mediaQueries';
import {
    StyledDropdownOptions,
    StyledDropdownValue,
} from 'components/atomics/molecules/Dropdown/Dropdown';
import { COLORS, RGB_COLORS } from 'utils/variables';

export const searchToolBarWrapperStyles = css`
    display: grid;
    grid-column-gap: 18px;
    grid-template-columns: 1fr 1fr;
    padding-top: 23px;
    position: relative;
    z-index: 7;
    margin-top: -144px;

    @media screen and ${FROM_TABLET} {
        margin-top: -123px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-top: -142px;
    }

    @media screen and ${FROM_TABLET} {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding-top: 7px;
        z-index: 2;
    }
`;

export const searchToolBarActionStyles = css`
    border: 0;
    display: flex;
    flex: 0 0 50%;
    flex-flow: row nowrap;
    margin: 0;
    padding: 0;

    *[data-rel="Select-Wrapper"] {
        width: 100%;
    }

    @media screen and ${FROM_MOBILE_TO_TABLET} {
        /* stylelint-disable */

        ${StyledDropdownOptions} {
            right: 0;
        }

        /* stylelint-enable */
    }

    @media screen and ${FROM_TABLET} {
        flex-basis: auto;
        margin-top: 8px;
    }

   ul li:first-child{
        background-color: ${COLORS.WHITE};
        text-transform: capitalize;
    }
`;

export const searchToolBarFilterActionStyles = ({ isOpen }) => css`
    ${searchToolBarActionStyles}
    position: relative;
    top: 0;
    margin-left: 20px;

    svg {
        bottom: auto;
        color: ${COLORS.SAPPHIRE};
        position: absolute;
        right: 0;
        top: 53%;
        transform: translateY(-50%);
        transition: transform 100ms linear;
        width: 14px;
        will-change: transform;
        @media screen and ${FROM_TABLET} {
            top: 41%;
        }
    }

    @media screen and ${FROM_TABLET} {
        min-width: 120px;

        svg {
            color: ${COLORS.SAPPHIRE};
            width: 15px;
        }

        /* stylelint-disable */

        ${isOpen && css`
            background: ${COLORS.WHITE};
            margin-left: 20px;
            min-width: 120px;

            div{
                border-bottom: 1px solid ${COLORS.WHITE};;
            }

            &::before,
            &::after {
                content: '';
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }

            &::before {
                box-shadow: 0 4px 20px 0 rgba(${RGB_COLORS.BLACK}, 0.16);
                z-index: -1;
            }

            &::after {
                background-color: ${COLORS.WHITE};
                z-index: 1;
            }

            svg {
                transform: translate(-20px, -50%);
            }
        `};

        /* stylelint-enable */
    }
`;
export const searchToolBarActionLabelStyles = css`
    position: relative;
    top: -30px;
    left: 69px;
    display: none;

    @media screen and ${FROM_TABLET} {
        color: ${COLORS.MEDIUM_GRAY2};
        display: inline-block;
        font-size: 10px;
        font-weight: 600;
        line-height: 1.5;
        padding: 20px 0;
        vertical-align: middle;
        text-transform: uppercase;
        letter-spacing: 1.4px;
    }
`;

export const searchToolBarFilterActionLabelStyles = css`
    @media screen and ${FROM_MOBILE_TO_TABLET} {
        border-bottom: 1px solid ${COLORS.SAPPHIRE};
        padding-bottom: 8px;
        padding-top: 23px;
        width: 100%;

        ${props => props.isMobile && css`
            padding-top: 25px;
            padding-bottom: 10px;
        `}
    }

    @media screen and ${FROM_MOBILE_TO_TABLET} {
        padding-top: 33px;
        padding-bottom: 10px;
    }
`;

export const searchToolBarFilterActionLabelInnerStyles = ({ isOpen }) => css`
    color: ${COLORS.BLACK};
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    text-align: left;
    z-index: 2;

    @media screen and ${FROM_TABLET} {
        color: ${COLORS.BLACK};
        display: inline-block;
        font-size: 15px;
        font-weight: 500;
        min-width: 50px;
        padding: 5px 30px 16px 20px;
        position: relative;
        transition: padding 100ms linear;
        vertical-align: middle;
        border-bottom: 1px solid  ${COLORS.SAPPHIRE};
    }

    /* stylelint-disable */

    ${isOpen && css`
        font-weight: 400;

        @media screen and ${FROM_TABLET} {
            padding-right: 50px;
        }
    `};

    /* stylelint-enable */
`;

export const resultsCountStyles = css`
    color: ${COLORS.MEDIUM_GRAY2};
    flex: 1 1 100%;
    font-size: 16px;
    font-weight: 600;
    grid-column-end: 3;
    grid-column-start: 1;
    line-height: 1.5;
    margin-bottom: 17px;
    margin-left: 0;
    margin-right: auto;

    @media screen and ${FROM_TABLET} {
        flex-basis: auto;
        font-size: 24px;
        margin-bottom: 0;
    }
`;

export const dropdownStyles = css`
    cursor: pointer;

    /* stylelint-disable */

    ${StyledDropdownValue} {
        color: ${COLORS.MEDIUM_GRAY1};
        font-size: 16px;
        font-weight: 500;
        min-height: 0;

        svg {
            bottom: auto;
            color: ${COLORS.MEDIUM_GRAY1};
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 10px;
        }
    }

    /* stylelint-enable */

    @media screen and ${FROM_MOBILE_TO_TABLET} {
        width: 100%;

        /* stylelint-disable */

        ${StyledDropdownValue} {
            border-bottom: 1px solid ${COLORS.LIGHT_GRAY3};
            padding-bottom: 0;
            padding-top: 0;
        }

        ${StyledDropdownOptions} {
            width: auto;
            min-width: 100%;
            top: -26px;
            padding-top: 5px;
            padding-bottom: 2px;
        }

        /* stylelint-enable */
    }

    @media screen and ${FROM_TABLET} {

        /* stylelint-disable */

        ${StyledDropdownValue} {
            border-bottom: 0;
            color: ${COLORS.MEDIUM_GRAY2};
            font-size: 18px;
            font-weight: 700;
            padding: 20px 30px 20px 0;

            svg {
                color: ${COLORS.MEDIUM_GRAY2};
                width: 15px;
            }
        }

        ${StyledDropdownOptions} {
            min-width: 238px;
            right: 0;
            top: 4px;
        }

        /* stylelint-enable */
    }
`;
