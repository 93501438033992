import {
    put, takeEvery, call,
} from 'redux-saga/effects';

import {
    getDisclaimerFragmentFailure,
    getDisclaimerFragmentSuccess,
} from 'state-management/actions/globalDisclaimer';
import {
    DISCLAIMER_URL,
    GET_DISCLAIMER_FRAGMENT,
} from 'state-management/constants/globalDisclaimer';
import Api from 'utils/api';
import { setError } from 'state-management/actions/errors';

/**
 * Get disclaimer by type.
 * @param {*} param0
 */
export function* fetchDisclaimerFragment({ disclaimerType }) {
    try {
        const fragmentData = yield call(Api.get, DISCLAIMER_URL, {
            params: {
                fragment: disclaimerType,
            },
            isPublic: true,
        });
        yield put(getDisclaimerFragmentSuccess(disclaimerType, fragmentData.data));
    } catch (error) {
        yield put(getDisclaimerFragmentFailure(disclaimerType, error));
        yield put(setError('global.errors.search', error));
    }
}

export default function* globalDisclaimerSaga() {
    yield takeEvery(GET_DISCLAIMER_FRAGMENT, fetchDisclaimerFragment);
}
