import { css } from 'styled-components';
import {
    FROM_DESKTOP,
    FROM_MOBILE_TO_TABLET,
    FROM_TABLET,
    ABOVE_MAX_WIDTH,
    ABOVE_MAX_SPLASH_WIDTH,
    breakpoints,
} from 'utils/mediaQueries';
import { COLORS } from 'utils/variables';

export const outerContainerStyles = css`
    position: relative;
    width: 100%;
    max-width: ${breakpoints.max_splash_width}px;
    margin: auto;
    overflow: hidden;
`;

export const imageContainerStyles = css`
    display: none;

    img {
        width: 100%;
    }

    @media screen and ${FROM_TABLET} {
        display: block;
    }
`;

export const innerContainerStyles = css`
    display: flex;
    justify-content: center;

    ${props => props.isWebinar && css`
        padding-top: 34px;
        padding-bottom: 0;
    `}

    @media screen and ${FROM_MOBILE_TO_TABLET} {
        background-color: ${COLORS.LIGHT_GRAY1};
        padding-top: 41px;
    }

    @media screen and ${FROM_TABLET} {
        align-items: center;
        height: auto;
        justify-content: flex-end;
        margin-top:50px;
        padding-bottom: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    @media screen\0 and ${FROM_TABLET} {
         margin-top:0;
    }

    @media screen and ${FROM_DESKTOP} {
       margin-top:200px;
    }

    ${props => props.isWebinar && css`
        margin-top: 0 !important;
    `}
`;

export const contentContainerStyles = css`
    max-width: 310px;
    position: relative;
    text-align: center;

    @media screen and ${FROM_TABLET} {
        max-width: 320px;
        text-align: left;
        top: 42px;

        ${props => props.isWebinar && css`
            top: 54px;
        `}
    }

    @media screen and ${FROM_DESKTOP} {
        max-width: 370px;
        top: 0;

        ${props => props.isWebinar && css`
            top: 140px;
        `}
    }
`;

export const headingStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-size: 20px;
    font-weight: 600;
    line-height: 1.31;

    @media screen and ${FROM_TABLET} {
        font-size: 24px;
        line-height: 1.3;
    }
`;

export const descriptionStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-size: 18px;
    line-height: 1.39;
    margin: 15px 0 50px;

    @media screen and ${FROM_TABLET} {
        color: ${COLORS.MEDIUM_GRAY2};
        font-weight: 500;
        line-height: 1.3;
        margin: 10px 0 50px;
    }
`;
