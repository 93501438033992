import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';

import TopicsList from './../../../components/TopicsList/TopicsList';
import {
    topicsContainerStyles,
    topicsTitleStyles,
    topicsStyles,
} from 'containers/Article/ArticleTopics/styles';
import Heading from 'components/Heading/Heading';

const StyledTopicsContainer = styled.div`
    ${topicsContainerStyles}
`;

const StyledTopicsTitle = styled(Heading)`
    ${topicsTitleStyles}
`;

export const StyledTopics = styled(TopicsList)`
    ${topicsStyles}
`;

/**
 * Topics list display for article pages.
 * @param {*} param0 
 */
const ArticleTopics = ({
    topics,
    onTopicSelect
}) => !isEmpty(topics) && (
    <StyledTopicsContainer>
        <StyledTopicsTitle level={4}>
            <FormattedMessage id="articles.topics" />
        </StyledTopicsTitle>
        <StyledTopics topics={topics} onTopicClick={(item) => onTopicSelect(item)}/>
    </StyledTopicsContainer>
);

ArticleTopics.propTypes = {
    topics: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default ArticleTopics;
