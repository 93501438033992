import { css } from 'styled-components';
import { FONT_AVENIR_NEXT } from 'utils/variables';
import {
    COLORS,
    FONTS,
} from '../../../utils/variables';
import { FROM_DESKTOP, FROM_TABLET, SMALL_MOBILE } from '../../../utils/mediaQueries';

export const containerStyles = css`
    margin: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
    min-height: 200px;
`;

export const cardHolderStyles = css`
    margin-bottom:-35px;

    @media screen and ${SMALL_MOBILE} {
        margin-top: 10px;
    }

    @media screen and ${FROM_TABLET} {
        margin-top: 10px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-top: 0;
    }
`;
