import { css } from 'styled-components';
import { getSpanInPixels } from 'utils/grid';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS, RGB_COLORS, ANIMATIONS } from 'utils/variables';

export const quizWrapperStyles = css`
    border: 0;
    box-sizing: border-box;
    margin: 0 0 36px 0;
    min-width: 0;
    padding: 0;
    text-align: center;
    width: 100%;

    /* stylelint-disable */

    ${props => props.variant && props.variant.isPoll && props.position.isCentered && css`
        padding: 0 16px;

        @media screen and ${FROM_TABLET} {
            padding: 0 38px;
        }
    `};
    /* stylelint-enable */
`;

export const quizContainerStyles = css`
    background-color: ${COLORS.WHITE};
    box-shadow: 0 4px 20px 0 rgba(${RGB_COLORS.BLACK}, 0.16);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 40px 0 45px;
    position: relative;
    width: 100%;

    &::before {
        background-image: linear-gradient(89deg, ${COLORS.SAPPHIRE}, ${COLORS.OCEAN});
        content: '';
        display: block;
        height: 5px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    /* stylelint-disable */

    ${props => props.variant && props.variant.isPoll && css`
        margin: 0 auto;
    `};

    ${props => props.variant && props.variant.isPoll && props.position.isCentered && css`
        margin: 25px auto;

        @media screen and ${FROM_TABLET} {
            margin: 80px auto;
            max-width: ${getSpanInPixels(8)};
        }

        @media screen and ${FROM_DESKTOP} {
            margin: 90px auto;
        }
    `};

    /* stylelint-enable */

    @media screen and ${FROM_TABLET} {
        padding: 50px 0 35px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 60px 0;
    }
`;

export const quizQuestionContainer = css`
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 35px;
    width: 100%;

    @media screen and ${FROM_TABLET} {
        padding: 0 65px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 0 102px;
    }
`;

export const quizTopicStyles = css`
    color: ${COLORS.SAPPHIRE};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    margin: 0 0 25px;
    text-transform: uppercase;
    word-wrap: break-word;
`;

export const quizQuestionStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.3px;
    line-height: 1.44;
    margin: 0 0 45px;
    text-align: center;
    width: 100%;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        font-size: 24px;
        letter-spacing: -0.4px;
        line-height: 1.33;
        margin: 0 0 40px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 36px;
        letter-spacing: -0.6px;
        line-height: 1.17;
    }
`;

export const quizOptionsContainerStyles = css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
`;

export const quizOptionStyles = css`
    background: ${COLORS.WHITE};
    border: 2px solid ${COLORS.SAPPHIRE};
    border-radius: 30px;
    box-sizing: border-box;
    color: ${COLORS.SAPPHIRE};
    display: inline-block;
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 12px;
    font-weight: 700;
    height: auto;
    letter-spacing: 1.5px;
    margin-bottom: 15px;
    min-height: 60px;
    padding: 20px 50px;
    position: relative;
    text-align: center;
    width: 100%;
    word-wrap: break-word;
    text-transform: uppercase;
    transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;

    @media screen and ${FROM_TABLET} {
        margin-bottom: 10px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 20px;
        min-height: 43px;
        padding: 10px 50px;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &:hover,
    &:focus {
        background-color: ${COLORS.WHITE};
        border-color: ${COLORS.DARK_SAPPHIRE};
        color: ${COLORS.DARK_SAPPHIRE};
        cursor: pointer;
    }

    /* stylelint-disable */

    ${props => props.readOnly && css`
        color: ${COLORS.MEDIUM_GRAY2};
        font-weight: 500;
        border-color: ${COLORS.MEDIUM_GRAY2};

        &:hover,
        &:focus {
            border: 2px solid ${COLORS.MEDIUM_GRAY2};
            color: ${COLORS.MEDIUM_GRAY2};
            cursor: not-allowed;
        }
    `};

    ${props => props.showCorrect && props.readOnly && css`
        background-color: ${COLORS.SAPPHIRE};
        border-color: ${COLORS.SAPPHIRE};
        color: ${COLORS.WHITE};
        font-weight: 700;

        &:hover,
        &:focus {
            background-color: ${COLORS.SAPPHIRE};
            border-color: ${COLORS.SAPPHIRE};
            color: ${COLORS.WHITE};
        }
    `};

    ${props => props.incorrectSelection && css`
        background-color: ${COLORS.LIGHT_GRAY3};
        color: ${COLORS.DARK_GRAY2};
        font-weight: 500;

        &:hover,
        &:focus {
            background-color: ${COLORS.LIGHT_GRAY3};
            color: ${COLORS.DARK_GRAY2};
        }
    `};

    /* stylelint-enable */
`;

export const quizOptionIconStyles = css`
    height: 15px;
    left: 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 17px;
`;

export const quizAnswerContainerStyles = css`
    border-top: 2px solid ${COLORS.LIGHT_GRAY3};
    display: block;
    margin: 40px 13px 0;
    padding: 40px 20px 0;

    @media screen and ${FROM_TABLET} {
        margin: 40px 17px 0;
        padding: 30px 40px 0;
    }

    @media screen and ${FROM_DESKTOP} {
        margin: 40px 27px 0;
        padding: 40px 80px 0;
    }
`;

export const explanationContainer = css`
    width: 100%;
`;

export const answerStyles = css`
    color: ${COLORS.SAPPHIRE};
    display: inline-block;
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 18px;
    letter-spacing: -0.3px;
    line-height: 1.33;
    margin: 0 auto;
    text-align: center;
    word-wrap: break-word;
    font-weight: 400;

    &::after {
        background-image: linear-gradient(89deg, ${COLORS.SAPPHIRE}, ${COLORS.OCEAN});
        content: '';
        display: block;
        height: 2px;
        margin-bottom: 10px;
        margin-top: 0;
        position: relative;
        width: 100%;
    }
`;

export const explanationStyles = css`
    color: ${COLORS.SAPPHIRE};
    display: inline-block;
    font-family: ${FONT_AVENIR_NEXT};
    font-weight: 400;
    font-size: 18px;
    letter-spacing: -0.3px;
    line-height: 1.43;
    margin: -20px;
    text-align: center;
    white-space: pre-wrap;
    word-wrap: break-word;

    &::after {
        background-image: linear-gradient(89deg, ${COLORS.SAPPHIRE}, ${COLORS.OCEAN});
        content: '';
        display: block;
        height: 2px;
        position: relative;
    }
`;

export const loadingexplanationStyles = css`
    color: ${COLORS.SAPPHIRE};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 14px;
    letter-spacing: -0.3px;
    line-height: 1.43;
    margin: 17px 0 0;
    text-align: center;
    white-space: pre-wrap;
    word-wrap: break-word;
`;

export const optionsPercentage = css`
    height: 15px;
    left: 15px;
    letter-spacing: -1px;
    position: absolute;
`;
