export const COLUMN_COUNT = {
    desktop: 12,
    mobile: 6,
    tablet: 12,
};
export const GUTTER = {
    desktop: 48,
    mobile: 50,
    tablet: 52,
};
export const CONTAINER_WIDTH = {
    desktop: 1170,
    mobile: 325,
    tablet: 716,
};

export function getSpanInPixels(cols, withUnits = true, breakpoint = 'desktop') {
    const gutter = GUTTER[breakpoint];
    const columnCount = COLUMN_COUNT[breakpoint];
    const containerWidth = CONTAINER_WIDTH[breakpoint];
    const colWidth = (containerWidth - ((columnCount - 1) * gutter)) / columnCount;
    const value = (cols * colWidth) + ((cols - 1) * gutter);

    return withUnits ? `${value}px` : value;
}

export function getSpanInPercent(cols, withUnits = true, breakpoint = 'desktop') {
    const containerWidth = CONTAINER_WIDTH[breakpoint];
    const value = (getSpanInPixels(cols, false, breakpoint) * 100) / containerWidth;

    return withUnits ? `${value}%` : value;
}
