import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'formik';
import { connect } from 'react-redux';
import IdentityModal from 'containers/SignUp/SignUpForm/IdentityModal/IdentityModal';
import Modal from 'components/atomics/templates/ClosableModal/ClosableModal';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { isEmpty, isEqual, omit } from 'lodash';
import isPasswordFormatValid from 'utils/validators/passwordValidator';
import { isEmailValid } from 'utils/validators/emailValidator';
import { linkStyles } from 'containers/SignUp/styles';
import { Button, FieldText, FieldCheckBox } from 'components';
import LegalAgreement from 'containers/LegalAgreement/LegalAgreement';
import {
    isMobileOnly,
    isIOS,
} from 'react-device-detect';
import updateAgreement, { getLegalAgreement as getLegalAgreementAction } from 'state-management/actions/legalAgreement.common';
import {
    setSuccess,
    resetErrors as resetErrorsAction,
} from 'state-management/actions/signUp';
import {
    fieldsRowStyles,
    fieldWrapperStyles,
    controlsStyles,
    checkboxStyles,
    buttonStyles,
    errorStyles, agreementErrorStyles,
    uhOhStyles,
    rulesContainer,
    mockLinkStyles,
} from 'containers/SignUp/SignUpForm/styles';
import { setFocusOnError } from 'state-management/actions/errors';
import isNameInvalid from 'utils/validators/tagInNameValidator';
import Loader from 'components/Loader/Loader';
import PasswordRules, {
    handlePasswordBlur,
    handlePasswordChange,
} from 'components/PasswordRules/PasswordRules';
import * as analytics from 'utils/adobeAnalytics';
import HaveWeMetBefore from 'containers/SignUp/SignUpForm/HaveWeMetBefore/HaveWeMetBefore';
import {
    RGB_COLORS,
} from 'utils/variables';

const Spinner = isSubmitting => (isSubmitting ? <Loader /> : null);

const RulesContainer = styled.div`
    ${rulesContainer}
`;

const StyledFieldsRow = styled.div`
    ${fieldsRowStyles};
`;

const StyledFieldWrapper = styled.div`
    ${fieldWrapperStyles};
`;

const StyledControls = styled.div`
    ${controlsStyles};
`;

export const StyledButton = styled(Button)`
    ${buttonStyles};
`;

const StyledCheckbox = styled(FieldCheckBox)`
    ${checkboxStyles};
`;

const StyledErrors = styled.div`
    ${errorStyles};
`;

const StyledAgreementError = styled.div`
    ${agreementErrorStyles};
`;

const StyledLink = styled(Link)`
    ${linkStyles};
`;

const StyledMockLink = styled.span`
    ${mockLinkStyles}
`;

const StyledUhOh = styled.span`
    ${uhOhStyles}
`;

const ModalStyles = {
    content: {
        background: 'none',
        border: '0',
        borderRadius: '0',
        bottom: 'auto',
        left: '50%',
        marginRight: '-50%',
        padding: '0',
        right: 'auto',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        textAlign: 'center',
    },
    overlay: {
        backgroundColor: `rgba(${RGB_COLORS.BLACK}, 0.25)`,
        zIndex: '1',
    },
};

const IdentityModalStyles = {
    content: {
        maxWidth: '700px',
        background: 'none',
        border: '0',
        borderRadius: '0',
        bottom: 'auto',
        left: '50%',
        marginRight: '-50%',
        padding: '0',
        right: 'auto',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        textAlign: 'center',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        zIndex: '1',
    },
};

const fieldOrder = [
    'firstName',
    'lastName',
    'email',
    'password',
    'passwordVerification',
    'acceptLegalAgreement',
    'haveWeMetBefore',
];

/**
 * The signup form component used in splash page.
 */
export class SignUpForm extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.closeIdentityModal = this.closeIdentityModal.bind(this);
        this.openIdentityModal = this.openIdentityModal.bind(this);
        this.onIdentitySubmit = this.onIdentitySubmit.bind(this);
        this.resetIdentityErrors = this.resetIdentityErrors.bind(this);
    }

    /**
     * Get submitting state from props.
     * @param {*} props 
     */
    static getDerivedStateFromProps(props) {
        const state = {};

        if (props.isSubmitting !== props.isSubmittingState) {
            props.setSubmitting(props.isSubmittingState);
        }

        return state;
    }

    state = {
        passwordHasBeenBlurred: false,
        showLegalAgreement: false,
        errors: {},
        passwordFormatRules: {},
        isSubmitted: false,
        isEdited: false,
        identityModalOpen: false,
        identityModalCRDInUse: false,
        cachedError: null,
    };

    /**
     * Reset the CRD in use error state.
     */
    resetIdentityErrors() {
        this.setState({
            identityModalCRDInUse: false,
        })
    }

    /**
     * Gather needed data at mount.
     */
    componentDidMount() {
        const {
            getLegalAgreement,
            legalAgreement,
            setSuccessStatus,
        } = this.props;

        this.updateAgreementStatus(false);
        setSuccessStatus(false);

        if (!legalAgreement) {
            getLegalAgreement();
        }
    }

    /**
     * Adjust errors on property changes.
     * @param {*} prevProps 
     */
    componentDidUpdate(prevProps) {
        if (this.props.isSuccessState === true && prevProps.isSuccessState === false) {
            this.props.resetForm();
        }

        if (this.props.errorsState !== prevProps.errorsState) {
            this.setServerErrors();
        }

        const keys = Object.keys(this.props.errors);

        if (this.props.isFocused && (keys.length || this.getGlobalErrors().length)) {
            this.setFocus(this.props.errors);
            this.props.focusOnError(false);
        }
    }

    /**
     * Validate password on blur.
     */
    onBlurPassword = (e) => {
        this.setState((prevState) => {
            const passwordHasBeenBlurred = true;
            const emailElement =  document.getElementById('email');
            const passwordFormatRules = (
                handlePasswordBlur(e, prevState.passwordFormatRules, passwordHasBeenBlurred, emailElement ? emailElement.value : null)
            );

            return {
                passwordHasBeenBlurred,
                passwordFormatRules,
            };
        });
    };

    /**
     * Validate password on change of value.
     */
    onChangePassword = (e) => {
        const { passwordFormatRules, passwordHasBeenBlurred } = this.state;
        const email =  document.getElementById('email');
        const updatedPasswordRules = (
            handlePasswordChange(e, passwordFormatRules, passwordHasBeenBlurred, email ? email.value : null)
        );

        this.setState({ passwordFormatRules: updatedPasswordRules });
    };

    /**
     * Render advisor not allowed messaging.
     */
    getAdvisorNotAllowedErrorMessage = () => {
        if (this.state.identityModalOpen) {
            return null;
        }

        return (
            <FormattedMessage
                id="signUp.error.advisorNotAllowed"
                values={{
                    contactLink: (
                        <StyledLink to="/contact-us">
                            <FormattedMessage id="contactLink" />
                        </StyledLink>
                    ),
                    modalLink: (
                        <StyledMockLink
                            onClick={this.openIdentityModal}
                        >
                            <FormattedMessage id="signUp.error.advisorNotAllowed.signup" />
                        </StyledMockLink>
                    ),
                    uhOh: (
                        <StyledUhOh>
                            <FormattedMessage id="signUp.error.advisorNotAllowed.uhOh" />
                        </StyledUhOh>
                    ),
                }}
            />
        );
    }

    /**
     * Handle setting errors for user passed back from BE.
     */
    setServerErrors() {
        const serverErrors = this.props.errorsState;
        const result = {};

        this.resetIdentityErrors();

        serverErrors.forEach((e) => {
            switch (e.code) {
                case 'error.dupliate.sign_up.whitelist.crd':
                    result.email = <FormattedMessage
                        id="error.dupliate.sign_up.whitelist.crd"
                        values={{
                            link: (
                                <StyledLink to="/contact-us">
                                    <FormattedMessage id="error.dupliate.sign_up.whitelist.crd.contact" />
                                </StyledLink>
                            ),
                        }}
                    />;

                    analytics.dispatchSignUpFailed('crdAlreadyInUse');

                    break;
                case 'error.validation':
                    //This is CRD already in use error
                    this.setState({
                        identityModalCRDInUse: true,
                        identityModalOpen: true,
                    }, () => {
                        window.identityData.crd = '';
                    });

                    analytics.dispatchSignUpFailed('crdAlreadyInUse');

                    break;
                case 'error.okta.validation.user.exists':
                    result.email = this.renderExistingEmailError();
                    analytics.dispatchSignUpFailed('userExists');

                    break;
                case 'error.okta.validation.email':
                    result.email = this.getTranslation(e.code);
                    analytics.dispatchSignUpFailed('email');

                    break;
                case 'error.sign_up.advisor_not_allowed':
                    this.setState({
                        identityModalOpen: true,
                        cachedError: {
                            email: this.getAdvisorNotAllowedErrorMessage(),
                            haveWeMetBefore: <HaveWeMetBefore />,
                        },
                    });

                    //KEEPING in case we want the first error back after review..
                    //result.email = this.getAdvisorNotAllowedErrorMessage();
                    //result.haveWeMetBefore = <HaveWeMetBefore />;
                    analytics.dispatchSignUpFailed('advisorNotAllowed');

                    break;
                default:
                    analytics.dispatchSignUpFailed('serverError');

                    break;
            }
        });

        if (!isEqual(this.state.errors, result)) {
            this.setState({ errors: result }, () => {
                Object.keys(result).forEach(key => this.props.setFieldError(key, result[key]));
            });
        }
    }

    /**
     * Get a localized string resource by ID and and insert values.
     * @param {*} id 
     * @param {*} values 
     */
    getTranslation(id, values = {}) {
        const { intl: { formatMessage } } = this.props;

        return formatMessage({
            id,
        }, values);
    }

    /**
     * Focus a specific field on error.
     */
    setFocus = (errors) => {
        const fieldName = fieldOrder.find(name => !!errors[name]);
        const self = this;
        const timing = isIOS && isMobileOnly ? 1000 : 0;

        if (fieldName) {
            setTimeout(() => {
                self[fieldName].focus();
            }, timing);
        }

        if (!fieldName && this.getGlobalErrors().length && !this.state.identityModalOpen) {
            setTimeout(() => {
                self.globalErrors.focus();
            }, timing);
        }
    };

    /**
     * Get global scoped errors.
     */
    getGlobalErrors = () => {
        const { errorsState } = this.props;

        return errorsState.filter(e => [
            'error.okta.validation.user.exists',
            'error.okta.validation.email',
            'error.sign_up.advisor_not_allowed',
            'error.validation',
        ].indexOf(e.code) === -1);
    };

    /**
     * Handle legal agreement closure.
     */
    closeLegalAgreement = () => {
        this.setState({ showLegalAgreement: false });
    };

    /**
     * Handle saving the CRD on identity modal submission.
     * @param {*} values 
     */
    onIdentitySubmit(values) {
        const submitButton = document.getElementById("sign-up-button");

        window.identityData = {
            crd: values.crd,
            previousCrd: values.crd,
        }

        this.closeIdentityModal(true);

        submitButton.click();
    }

    /**
     * Handle opening the identity modal from the error text link.
     */
    openIdentityModal() {
        this.setState({
            identityModalOpen: true,
        });
    }

    /**
     * Handle close logic of the identity modal.
     */
    closeIdentityModal(dontShowCache = false) {
        this.setState({
            identityModalOpen: false,
        });

        if (!dontShowCache) {
            if (this.state.cachedError) {
                if (!isEqual(this.state.errors, this.state.cachedError)) {
                    this.setState({ errors: this.state.cachedError }, () => {
                        Object.keys(this.state.cachedError).forEach(key => this.props.setFieldError(key, this.state.cachedError[key]));

                        this.setState({
                            cachedError: null,
                        });
                    });
                }
            }
        }
    }

    /**
     * Handle opening legal agreement modal.
     */
    openLegalAgreement = () => {
        this.setState({ showLegalAgreement: true });
    };

    /**
     * Update legal agreement checkbox.
     */
    handleCheckBoxChange = (e, isChecked) => {
        if (isChecked) {
            this.updateAgreementStatus(false);
        } else {
            this.openLegalAgreement();
        }
    };

    /**
     * Handle closure of legal agreement.
     */
    handleClose = () => {
        this.updateAgreementStatus(false);
        this.closeLegalAgreement();
    };

    /**
     * Future hook if needed for CRD modal close.
     */
    handleIdenityModalClose = () => {
        //possible hook if needed on modal close outside normal flow
    }

    /**
     * Update agreement/disagree for legal disclosure.
     */
    updateAgreementStatus = (isAgreed) => {
        this.props.setFieldValue('legalAgreement', isAgreed);
        this.props.onAgreementUpdate(isAgreed);
    };

    /**
     * Handle form submission.
     */
    handleSubmit = (e) => {
        this.setState({ isSubmitted: true, isEdited: false });
        analytics.dispatchSignUpStart();
        this.props.resetSignUpErrors();

        const errors = this.submitValidate();

        if (isEmpty(errors)) {
            this.setState({ errors: {} });
            this.props.setErrors({});
            this.props.handleSubmit(e);
        } else {
            e.preventDefault();
            this.setFocus(errors);
            this.props.setErrors(errors);

            if (errors.password || errors.passwordVerification) {
                analytics.dispatchSignUpFailed('incorrectPassword');
            } else {
                analytics.dispatchSignUpFailed('validationError');
            }
        }
    };

    /**
     * Handle field changes in bulk.
     */
    handleChange = (e) => {
        const { name } = e.target;
        const { isEdited } = this.state;

        if (!isEdited) {
            this.setState({ isEdited: true });
        }

        if (name && this.props.errors[name]) {
            this.setState(prevState => ({
                errors: omit(prevState.errors, name),
            }));
            this.props.setFieldError(name, null);
        }

        if (name === 'legalAgreement' && this.props.errors.acceptLegalAgreement) {
            this.props.setFieldError('acceptLegalAgreement', null);
        }
    };

    /**
     * Validate all fields.
     */
    validate = (field) => {
        const { values, setFieldTouched, errors } = this.props;

        if (field !== null) {
            setFieldTouched(field, true, false);
        }

        switch (field) {
            case 'firstName':
                if (values.firstName !== null && !values.firstName.trim()) {
                    errors.firstName = this.getTranslation('error.emptyField', {
                        field: this.getTranslation('signUp.field.firstName'),
                    });
                }

                if (values.firstName !== null && isNameInvalid(values.firstName)) {
                    errors.firstName = this.getTranslation('error.specialCharacter', {
                        field: this.getTranslation('signUp.field.firstName'),
                    });
                }
                break;

            case 'lastName':
                if (values.lastName !== null && isNameInvalid(values.lastName)) {
                    errors.lastName = this.getTranslation('error.specialCharacter', {
                        field: this.getTranslation('signUp.field.lastName'),
                    });
                }

                if (values.lastName !== null && !values.lastName.trim()) {
                    errors.lastName = this.getTranslation('error.emptyField', {
                        field: this.getTranslation('signUp.field.lastName'),
                    });
                }
                break;

            case 'passwordVerification':
                if (values.passwordVerification != '' && ((!values.password) || values.password !== values.passwordVerification)) {
                    errors.passwordVerification = this.getTranslation('validation.error.passwordMatch');
                }
                break;

            case 'password':
                if (values.password != '' && (!values.password) || values.password && !isPasswordFormatValid(values.password, document.getElementById('email').value)) {
                    errors.password = this.getTranslation('validation.error.passwordFormat');
                }
                break;

            case 'email':
                if ((!values.email) || values.email && !isEmailValid(values.email)) {
                    errors.email = this.getTranslation('validation.error.email');
                }
                break;
        }
    };

    /**
     * Handle submission and error field recording.
     */
    submitValidate = () => {
        const { values, setFieldTouched } = this.props;
        const errors = {};

        if (values.firstName !== null && !values.firstName.trim()) {
            errors.firstName = this.getTranslation('error.emptyField', {
                field: this.getTranslation('signUp.field.firstName'),
            });
        }

        if (values.firstName !== null && isNameInvalid(values.firstName)) {
            errors.firstName = this.getTranslation('error.specialCharacter', {
                field: this.getTranslation('signUp.field.firstName'),
            });
        }
        if (values.lastName !== null && isNameInvalid(values.lastName)) {
            errors.lastName = this.getTranslation('error.specialCharacter', {
                field: this.getTranslation('signUp.field.lastName'),
            });
        }

        if (values.lastName !== null && !values.lastName.trim()) {
            errors.lastName = this.getTranslation('error.emptyField', {
                field: this.getTranslation('signUp.field.lastName'),
            });
        }
        if ((!values.password) || values.password !== values.passwordVerification) {
            errors.passwordVerification = this.getTranslation('validation.error.passwordMatch');
        }
        const emailElement = document.getElementById('email');

        if ((!values.password) || values.password && !isPasswordFormatValid(values.password, emailElement ? emailElement.value: null)) {
            errors.password = this.getTranslation('validation.error.passwordFormat');
        }
        if ((!values.email) || values.email && !isEmailValid(values.email)) {
            errors.email = this.getTranslation('validation.error.email');
        }
        if (!values.legalAgreement) {
            errors.acceptLegalAgreement = this.getTranslation('validation.error.legalAgreement');
        }

        const emptyValues = Object.keys(values)
            .filter(key => key !== 'legalAgreement')
            .filter(key => !values[key] && !errors[key]);
        if (emptyValues) {
            emptyValues.forEach((key) => {
                errors[key] = this.getTranslation('error.emptyField', {
                    field: this.getTranslation(`signUp.field.${key}`),
                });
            });
        }

        Object.keys(errors).forEach((key) => {
            this.props.setFieldTouched(key, true);
            this.props.setFieldError(key, errors[key]);
        });

        return errors;

    };

    /**
     * Render error for email already exists at signup.
     */
    renderExistingEmailError() {
        return (
            <span>
                <FormattedMessage
                    id="error.okta.validation.user.exists"
                    values={{
                        link: (
                            <StyledLink to="/contact-us">
                                <FormattedMessage id="error.okta.validation.user.exists.link" />
                            </StyledLink>
                        ),
                    }}
                />
            </span>
        );
    }

    /**
     * Render the signup form fields.
     */
    renderFields() {
        const { isSubmitting, setFieldTouched, setFieldError } = this.props;
        const { isSubmitted, isEdited } = this.state;
        const firstNameLabel = this.getTranslation('signUp.field.firstName');
        const lastNameLabel = this.getTranslation('signUp.field.lastName');
        const emailLabel = this.getTranslation('signUp.field.email');
        const passwordLabel = this.getTranslation('signUp.field.password');
        const verifyPasswordLabel = this.getTranslation('signUp.field.passwordVerification');
        const isFieldValid = !isSubmitting && isSubmitted && !isEdited;

        return (
            <React.Fragment>
                <StyledFieldsRow>
                    <StyledFieldWrapper>
                        <Field
                            component={FieldText}
                            disabled={isSubmitting}
                            id="firstname"
                            name="firstName"
                            label={firstNameLabel}
                            maxLength="50"
                            autoComplete={'off'}
                            ref={(input) => { this.firstName = input; }}
                            isValidated={isFieldValid}
                            isSubmitted={isSubmitted}
                            onBlur={(e) => {
                                this.validate('firstName');
                            }}
                            onClick={(e) => {
                                setFieldError('firstName', null);
                            }}
                        />
                    </StyledFieldWrapper>
                    <StyledFieldWrapper>
                        <Field
                            component={FieldText}
                            disabled={isSubmitting}
                            id="lastname"
                            name="lastName"
                            autoComplete={'off'}
                            label={lastNameLabel}
                            maxLength="50"
                            ref={(input) => { this.lastName = input; }}
                            isValidated={isFieldValid}
                            isSubmitted={isSubmitted}
                            onBlur={(e) => {
                                this.validate('lastName');
                            }}
                            onClick={(e) => {
                                setFieldError('lastName', null);
                            }}
                        />
                    </StyledFieldWrapper>
                </StyledFieldsRow>
                <StyledFieldsRow fullWidth="true">
                    <StyledFieldWrapper>
                        <Field
                            component={FieldText}
                            disabled={isSubmitting}
                            id="email"
                            name="email"
                            autoComplete={'off'}
                            label={emailLabel}
                            maxLength="255"
                            ref={(input) => { this.email = input; }}
                            isValidated={isFieldValid}
                            isSubmitted={isSubmitted}
                            onBlur={(e) => {
                                this.validate('email');
                                this.validate('password');
                            }}
                            onClick={(e) => {
                                setFieldError('email', null);
                            }}
                        />
                    </StyledFieldWrapper>
                </StyledFieldsRow>
                <StyledFieldsRow>
                    <StyledFieldWrapper forceWidth={true}>
                        <Field
                            component={FieldText}
                            disabled={isSubmitting}
                            id="password"
                            name="password"
                            label={passwordLabel}
                            type="password"
                            autoComplete={'off'}
                            maxLength="50"
                            ref={(input) => { this.password = input; }}
                            onBlur={(e) => {
                                this.onBlurPassword(e);
                                this.validate('password');
                                this.validate('passwordVerification');
                            }}
                            onChange={this.onChangePassword}
                            isValidated={isFieldValid}
                            isSubmitted={isSubmitted}
                            onClick={(e) => {
                                setFieldError('password', null);
                                setFieldError('passwordVerification', null);
                            }}
                        />
                    </StyledFieldWrapper>
                    <StyledFieldWrapper forceWidth={true}>
                        <Field
                            component={FieldText}
                            disabled={isSubmitting}
                            id="password-verification"
                            name="passwordVerification"
                            label={verifyPasswordLabel}
                            type="password"
                            autoComplete={'off'}
                            maxLength="50"
                            ref={(input) => { this.passwordVerification = input; }}
                            isValidated={isFieldValid}
                            isSubmitted={isSubmitted}
                            onBlur={(e) => {
                                // this.onBlurPassword(e);
                                // this.validate('password');
                                this.validate('passwordVerification');
                            }}
                            onClick={(e) => {
                                // setFieldError('password', null);
                                setFieldError('passwordVerification', null);
                            }}
                        />
                    </StyledFieldWrapper>
                </StyledFieldsRow>
            </React.Fragment>
        );
    }

    /**
     * Display any global level errors.
     */
    renderGlobalErrors() {
        if (this.state.identityModalOpen) {
            return false;
        }

        const errors = this.getGlobalErrors()
            .map((e) => {
                if (e.code === 'signUp.error.failed') {
                    return this.getTranslation(e.code);
                }
                return e.message;
            });

        return (
            errors.length ? (
                <StyledErrors
                    id="signup-errors"
                    tabIndex="0"
                    ref={(input) => { this.globalErrors = input; }}
                >
                    { errors.join(', ')}
                </StyledErrors>
            )
                : null
        );
    }

    /**
     * Render the legal agreement modal and checkbox.
     */
    renderLegalAgreement() {
        const {
            errors,
            globalErrors,
            isUpdateAgreementAccepted,
            isSubmitting,
            legalAgreement,
        } = this.props;
        const checkboxLabelStart = this.getTranslation('signUp.field.legalAgreementAccept');
        const checkboxLabelEnd = this.getTranslation('signUp.field.legalAgreement');

        /* eslint-disable */

        const checkboxLabelFormatted = <div>
            <span>{checkboxLabelStart}</span>{checkboxLabelEnd}
        </div>;

        /* eslint-enable */

        const legalAgreementTitle = this.getTranslation('legalAgreement.terms.heading');

        return (
            <React.Fragment>
                <RulesContainer>
                    <Field
                        checked={isUpdateAgreementAccepted}
                        component={StyledCheckbox}
                        disabled={isSubmitting}
                        id="legal-agreement"
                        name="legalAgreement"
                        label={checkboxLabelFormatted} // TODO: fix format
                        onChange={this.handleCheckBoxChange}
                    />
                    <StyledAgreementError
                        topSpacing
                        id="accept-legal-agreement-errors"
                        tabIndex="-1"
                        ref={(input) => { this.acceptLegalAgreement = input; }}
                    >
                        {errors.acceptLegalAgreement}
                    </StyledAgreementError>
                </RulesContainer>
                <Modal
                    htmlOpenClassName={null}
                    shouldFocusAfterRender={false}
                    ariaHideApp={false}
                    contentLabel={legalAgreementTitle}
                    isOpen={this.state.showLegalAgreement}
                    onRequestClose={this.handleClose}
                    style={ModalStyles}
                >
                    <LegalAgreement
                        closeModal={this.closeLegalAgreement}
                        globalErrors={globalErrors}
                        legalAgreement={legalAgreement}
                        updateAgreement={this.updateAgreementStatus}
                    />
                </Modal>
            </React.Fragment>
        );
    }

    /**
     * Render info level errors.
     */
    renderInformationalErrors() {
        const { errors } = this.props;

        return errors.haveWeMetBefore;
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const { isSubmitting, isUpdateAgreementAccepted, values } = this.props;
        let isDisabled = Object.keys(values).some(key => !values[key])
            || !isUpdateAgreementAccepted;

        if (this.props.errors.passwordVerification && this.props.errors.passwordVerification.length > 1) {
            isDisabled = true;
        }

        return (
            <form
                onChange={this.handleChange}
                onSubmit={this.handleSubmit}
                autoComplete={'off'}
            >
                {this.renderFields()}
                <PasswordRules passwordRules={this.state.passwordFormatRules} />
                <StyledControls data-rel="controlsPanel">
                    {this.renderLegalAgreement()}
                    <StyledButton
                        appearance={Button.APPEARANCE.PRIMARY}
                        type={Button.TYPES.SUBMIT}
                        id="sign-up-button"
                        isDisabled={isSubmitting || isDisabled ? "true" : null}
                        disabled={isSubmitting}
                        size={Button.SIZES.LARGE}
                    >
                        <FormattedMessage id="signUp.button.submit" />
                    </StyledButton>
                </StyledControls>
                {this.renderGlobalErrors()}
                {Spinner(isSubmitting)}
                {this.renderInformationalErrors()}
                <Modal
                    htmlOpenClassName={null}
                    shouldFocusAfterRender={false}
                    ariaHideApp={false}
                    contentLabel={'IdentityModal'}
                    isOpen={this.state.identityModalOpen}
                    onRequestClose={this.handleIdenityModalClose}
                    style={IdentityModalStyles}
                >
                    <IdentityModal
                        crdDuplicateError={this.state.identityModalCRDInUse}
                        onSubmit={this.onIdentitySubmit}
                        closeModal={this.closeIdentityModal}
                        resetServerError={this.resetIdentityErrors}
                        previousCrd={window.identityData.previousCrd}
                    />
                </Modal>
            </form>
        );
    }
}

SignUpForm.propTypes = {
    intl: intlShape.isRequired,
    // redux state
    globalErrors: PropTypes.arrayOf(PropTypes.shape).isRequired,
    errorsState: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    isSubmittingState: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    setSuccessStatus: PropTypes.func.isRequired,
    isSuccessState: PropTypes.bool.isRequired,
    isUpdateAgreementAccepted: PropTypes.bool.isRequired,
    legalAgreement: PropTypes.string,
    isFocused: PropTypes.bool.isRequired,
    // redux actions
    resetSignUpErrors: PropTypes.func.isRequired,
    focusOnError: PropTypes.func.isRequired,
    getLegalAgreement: PropTypes.func.isRequired,
    onAgreementUpdate: PropTypes.func.isRequired,
    // formik state
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    values: PropTypes.objectOf(PropTypes.any).isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    // formik methods
    handleSubmit: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setErrors: PropTypes.func.isRequired,
    setFieldError: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    setSubmitting: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    resetForm: PropTypes.func.isRequired,
};

SignUpForm.defaultProps = {
    legalAgreement: '',
};

const mapDispatchToProps = dispatch => ({
    resetSignUpErrors: () => dispatch(resetErrorsAction()),
    getLegalAgreement: () => dispatch(getLegalAgreementAction()),
    onAgreementUpdate: accepted => dispatch(updateAgreement(accepted)),
    setSuccessStatus: isSuccess => dispatch(setSuccess(isSuccess)),
    focusOnError: isFocused => dispatch(setFocusOnError(isFocused)),
});

const mapStateToProps = state => ({
    globalErrors: state.errors.errors,
    isUpdateAgreementAccepted: state.legalAgreement.accepted,
    isSuccessState: state.signUp.isSuccess,
    errorsState: state.signUp.errors,
    isSubmittingState: state.signUp.isSubmitting,
    legalAgreement: state.legalAgreement.text,
    isFocused: state.errors.isFocused,
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SignUpForm));

