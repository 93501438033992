import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GuideContentCard from 'components/atomics/molecules/GuideContentCard/GuideContentCard';
import Row from 'components/atomics/atoms/Row/Row';
import {
    BreakpointsContext,
    MOBILE,
    TABLET,
} from 'components/Breakpoints/Breakpoints';
import {
    sliderContainerStyles,
    singleCardHolderStyles,
} from './styles';
import { Carousel } from "../../molecules/CarouselMolecule/Carousel";
import moment from 'moment';
import WebinarCard from 'components/atomics/organisms/WebinarCard/WebinarCard';

const SliderContainer = styled.section`
    ${sliderContainerStyles}
`;

const SingleCardHolder = styled.section`
    ${singleCardHolderStyles}
`;

export const TYPE_4COLUMN = "type/4column";
export const TYPE_3COLUMN = "type/3column";

/**
 * Creates a guide card set of four.
 */
class GuideCardCollectionOfFour extends React.Component {

    /** 
     *  Renders cards - Logic within this. 
     */
    renderCardsWithWebinar = (numberOfCardsToBeShown) => {
        let firstWebinarCards = [],
            newCombinedList = [],
            listToDisplay = [],
            haveToRemove = [];
        let x = this.props.webinarData.map(v => {
            v.publishTimestamp = v.latestPublishTimestamp;
            if ((new Date().getTime() / 1000) < (new Date(v.endTimestamp).getTime() / 1000)) {
                firstWebinarCards.push(v);
            }
            if (
                ((new Date().getTime() / 1000) < (new Date(v.dateToPromote).getTime() / 1000))
            ) {
                haveToRemove.push(v);
            }
        });
        if (this.props.guideCategories && this.props.guideCategories.length > 0) {
            let webinarUdatedList = this.props.webinarData.map(v => {
                v.publishTimestamp = v.latestPublishTimestamp;
                return v;
            })
            let merged = [...this.props.guideCategories, ...webinarUdatedList];
            let firstWebinarCardIds = firstWebinarCards.map(v => v.id)
            let newList = merged.map(v => {
                if (!firstWebinarCardIds.includes(v.id)) {
                    return v;
                }
            });
            newCombinedList = newList.filter(v => !!v);
        }
        newCombinedList.sort((a, b) => (moment(a.publishTimestamp).unix() < moment(b.publishTimestamp).unix()) ? 1 : -1);
        if (haveToRemove.length > 0) {
            let haveToRemoveIds = haveToRemove.map(v => v.id);
            newCombinedList.filter(v => !haveToRemoveIds.includes(v.id));
        }
        listToDisplay = [...firstWebinarCards, ...newCombinedList].slice(0, numberOfCardsToBeShown);
        let renderedCards =

            <Row data-rel="GuideCardCollectionOfThree" type={numberOfCardsToBeShown === 4 ? TYPE_4COLUMN : TYPE_3COLUMN}>
                {
                    listToDisplay.map((listCard, idx) => {
                        return listCard.type == 'WEBINAR' ?
                            <WebinarCard article={listCard} key={idx} /> :
                            <GuideContentCard guideCardInfo={listCard} key={idx} />

                    })
                }
            </Row>

        return renderedCards;

    }

    /**
     *  Renders cards - Logic within this. 
     */
    renderCardsWithWebinarForMobile = (numberOfCardsToBeShown) => {
        let firstWebinarCards = [],
            newCombinedList = [],
            listToDisplay = [],
            haveToRemove = [];
        let x = this.props.webinarData.map(v => {
            v.publishTimestamp = v.latestPublishTimestamp;
            if ((new Date().getTime() / 1000) < (new Date(v.endTimestamp).getTime() / 1000)) {
                firstWebinarCards.push(v);
            }
            if (
                ((new Date().getTime() / 1000) < (new Date(v.dateToPromote).getTime() / 1000))
            ) {
                haveToRemove.push(v);
            }
        });
        if (this.props.guideCategories && this.props.guideCategories.length > 0) {
            let webinarUdatedList = this.props.webinarData.map(v => {
                v.publishTimestamp = v.latestPublishTimestamp;
                return v;
            })
            let merged = [...this.props.guideCategories, ...webinarUdatedList];
            let firstWebinarCardIds = firstWebinarCards.map(v => v.id)
            let newList = merged.map(v => {
                if (!firstWebinarCardIds.includes(v.id)) {
                    return v;
                }
            });
            newCombinedList = newList.filter(v => !!v);
        }
        newCombinedList.sort((a, b) => (moment(a.publishTimestamp).unix() < moment(b.publishTimestamp).unix()) ? 1 : -1);
        if (haveToRemove.length > 0) {
            let haveToRemoveIds = haveToRemove.map(v => v.id);
            newCombinedList.filter(v => !haveToRemoveIds.includes(v.id));
        }
        listToDisplay = [...firstWebinarCards, ...newCombinedList].slice(0, numberOfCardsToBeShown);
        let renderedCards = listToDisplay.map((listCard, idx) => {
            return listCard.type == 'WEBINAR' ?
                {
                    component: (<WebinarCard article={listCard} key={idx} />)
                } :
                {
                    component: (<GuideContentCard guideCardInfo={listCard} key={idx} />)
                }
        })
        return (
            <SliderContainer
                data-rel="GuidesCardCollectionOfFour"
            >
                <Carousel items={renderedCards} />
            </SliderContainer>
        )
    }

    renderCardsWithOutForMobile = (numberOfCardsToBeShown) => {
        let firstWebinarCards = [],
            newCombinedList = [],
            listToDisplay = [],
            haveToRemove = [];


        if (this.props.guideCategories && this.props.guideCategories.length > 0) {

            let merged = [...this.props.guideCategories];
            let firstWebinarCardIds = firstWebinarCards.map(v => v.id)
            let newList = merged.map(v => {
                if (!firstWebinarCardIds.includes(v.id)) {
                    return v;
                }
            });
            newCombinedList = newList.filter(v => !!v);
        }
        newCombinedList.sort((a, b) => (moment(a.publishTimestamp).unix() < moment(b.publishTimestamp).unix()) ? 1 : -1);
        if (haveToRemove.length > 0) {
            let haveToRemoveIds = haveToRemove.map(v => v.id);
            newCombinedList.filter(v => !haveToRemoveIds.includes(v.id));
        }

        listToDisplay = [...firstWebinarCards, ...newCombinedList].slice(0, numberOfCardsToBeShown);
        let renderedCards = listToDisplay.map((listCard, idx) => {
            return listCard.type == 'WEBINAR' ?
                {
                    component: (<WebinarCard article={listCard} key={idx} />)
                } :
                {
                    component: (<GuideContentCard guideCardInfo={listCard} key={idx} />)
                }
        })
        return (
            <SliderContainer
                data-rel="GuidesCardCollectionOfFour"
            >
                <Carousel items={renderedCards} />
            </SliderContainer>
        )
    }



    loadCards = (device) => {
        let deviceScreen = device;
        let listCards;
        listCards = [...this.props.guideCategories];


        /**
        * Commenting it for future purpose. 
        */
        // if (this.props.webinarData && this.props.webinarData.length == 1) {
        //     listCards = [...this.props.guideCategories, {
        //         ...this.props.webinarData[0],
        //         publishTimestamp: this.props.webinarData[0].latestPublishTimestamp
        //     }];
        // } else {

        // }

        let updatedlistCards = listCards.sort(function (a, b) {
            return (moment(b.publishTimestamp)) - (moment(a.publishTimestamp));
        })

        if (updatedlistCards.length > 4) {
            updatedlistCards.pop();
        }

        if (deviceScreen === 'tablet' && updatedlistCards.length > 3) {
            updatedlistCards.pop();
        }


        let contentVal = updatedlistCards.map((listCard, idx) => {

            return listCard.type == 'WEBINAR' ?
                <WebinarCard article={listCard} key={idx} /> :
                <GuideContentCard guideCardInfo={listCard} key={idx} />

        });

        return contentVal;
    }

    renderDesktopWithOutWebinar = () => {
        let contentVal = this.loadCards();

        return (
            <Row data-rel="GuideCardCollectionOfFour" type={TYPE_4COLUMN} lastCard={(this.props.guideCategories.length < 4)}>
                {contentVal}
            </Row>
        );
    }

    renderTabletWithOutWebinar = () => {
        let contentVal = this.loadCards(TABLET);
        return (
            <Row data-rel="GuideCardCollectionOfThree" type={TYPE_3COLUMN}>
                {contentVal}
            </Row>
        );
    }

    render() {
        let webinarCondition = "f";
        let cardStartDate = false;
        let promteDateValidation;

        if (this.props.webinarData && this.props.webinarData.length > 0) {
            let enddateDB = moment.utc(this.props.webinarData[0].endTimestamp).format();
            const today = new Date().getTime();
            let enddateTime = new Date(enddateDB).getTime() / 1000;
            const todayTime = new Date().getTime() / 1000;
            const nowDate = new Date().getTime() / 1000;
            const promoteDate = new Date(this.props.webinarData[0].dateToPromote).getTime() / 1000;
            promteDateValidation = nowDate > promoteDate ? true : false;
            let startDate = new Date(this.props.webinarData[0].startTimestamp).getTime();

            if (todayTime < enddateTime) {
                cardStartDate = true;
            }

            if ((enddateTime > todayTime && this.props.webinarData.length > 0) && promteDateValidation) {
                webinarCondition = "t";
            }
        }

        //if only 2 cards are present to display in carousal then set only single cards in carousal display settings
        //in all other cases show 3 cards in carousal display for making edges of other 2 cards visible
        //utility works well when cards to show is 1 less than total cards

        return (
            <BreakpointsContext.Consumer>
                {(breakpoint) => {


                    if (breakpoint === MOBILE) {
                        if (this.props.guideCategories && this.props.guideCategories.length == 1) {
                            return (
                                <SingleCardHolder>
                                    <GuideContentCard
                                        guideCardInfo={this.props.guideCategories[0]}
                                    />
                                </SingleCardHolder>
                            )
                        } else {
                            if (this.props.webinarData && webinarCondition == 't') {
                                return this.renderCardsWithWebinarForMobile(4);
                            } else {
                                return this.renderCardsWithOutForMobile(4);
                            }
                        }

                    }

                    if (breakpoint === TABLET) {
                        if (this.props.webinarData && this.props.webinarData.length > 0 && webinarCondition == "t") {
                            return this.renderCardsWithWebinar(3);
                        } else {
                            return (
                                this.renderTabletWithOutWebinar()
                            );
                        }
                        //for Desktop
                    } else {

                        if (this.props.webinarData && this.props.webinarData.length > 0 && webinarCondition == 't') {
                            return this.renderCardsWithWebinar(4);
                        } else {
                            return (
                                this.renderDesktopWithOutWebinar()
                            );

                        }
                    }
                }}
            </BreakpointsContext.Consumer>
        );
    }
}

GuideCardCollectionOfFour.propTypes = {
    guideCategories: PropTypes.array,
    webinarData: PropTypes.array,
};

export default GuideCardCollectionOfFour;
