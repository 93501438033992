import { css } from 'styled-components';
import { getSpanInPercent } from 'utils/grid';
import {
    FROM_DESKTOP,
    FROM_TABLET,
} from 'utils/mediaQueries';
import {
    FONT_AVENIR_NEXT,
    COLORS,
    ANIMATIONS,
} from 'utils/variables';

export const containerStyles = css`
    margin-top: 55px;
    background: ${COLORS.WHITE};
    border: 1px solid ${COLORS.LIGHT_GRAY2};
    padding: 40px 24px 40px 24px;

    @media screen and ${FROM_DESKTOP} {
        padding: 50px 40px 50px 40px;
    }

    /* stylelint-disable */

    ${props => props.profile && css`
        margin-top: 30px;

        @media screen and ${FROM_TABLET} {
            margin-top: 40px;
        }
    `}

    /* stylelint-enable */

    @media screen and ${FROM_TABLET} {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 95px;

        /* stylelint-disable */
        ${props => props.onboarding && css`
            flex-direction: column;
        `}

        ${props => props.profile === 'true' && css`
            margin-top: 50px;
        `}
        /* stylelint-enable */
    }
`;

export const textContainerStyles = css`
    margin: 0;

    @media screen and ${FROM_TABLET} {
        padding-right: 30px;
    }

    @media screen and ${FROM_DESKTOP} {
        max-width: 488px;

        /* stylelint-disable */
        ${props => props.onboarding && css`
            max-width: 100%;
        `}
        /* stylelint-enable */
    }
`;

export const textQuestionStyles = css`
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.03px;
    margin: 0 0 8px;
    word-wrap: break-word;
    color: ${COLORS.DARK_GRAY2};

    @media screen and ${FROM_TABLET} {
        font-size: 22px;
        font-weight: 600;
        letter-spacing: -0.04px;
        margin: 0 0 18px;
    }
`;

export const textAnswerStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-size: 15px;
    letter-spacing: -0.28px;
    margin: 0;
    font-weight: 500;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        font-size: 13px;
        letter-spacing: -0.02px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 16px;
        letter-spacing: -0.03px;
    }

    /* stylelint-disable */

    ${({ profile }) => profile === 'true' && css`
        max-width: 466px;
    `}

    /* stylelint-enable */
`;

export const inputContainerStyles = css`
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    width: 270px;
    max-width: 270px;

    label {
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 3px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin: 0;
        /* stylelint-disable */
        ${props => props.onboarding && css`
            max-width: 100%;
        `}
        /* stylelint-enable */
    }

    &>div {
        height: 90px;
        min-height: 90px;
        width: 270px;

        @media screen and ${FROM_TABLET} {
            height: 130px;
            min-height: 130px;
        }
    }
`;

export const textInputStyles = css`
    width: 100%;
`;

export const buttonStyles = css`
    width: 130px;
    min-width: 120px;
    border: none;
    height: auto;
    background-color: ${COLORS.SAPPHIRE};
    color: ${COLORS.WHITE};
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1.5px;
    padding: 15px 22px 15px 22px;
    text-transform: uppercase;
    margin-top: 20px;
    transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;

    &:not(:disabled):hover {
        background-color: ${COLORS.DARK_SAPPHIRE} !important;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-top: 0;
    }
`;
