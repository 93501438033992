import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { signOut } from 'state-management/actions/signOut';
import { setScrollToTop } from 'state-management/actions/scroll';
import Loader from 'components/Loader/Loader';

/**
 * Sign-out page (just a loader until signout is confirmed, then forward to signin).
 */
export class SignOut extends React.Component {
    /**
     * Sign user out at mount.
     */
    componentDidMount() {
        this.props.scrollToTop();
        this.props.onSignOut();
        console.log('XPPX: sent signout request from container..');
    }

    /**
     * Show a loader until signout completes and redirects user.
     */
    render() {
        return <Loader />;
    }
}

SignOut.propTypes = {
    onSignOut: PropTypes.func,
    scrollToTop: PropTypes.func.isRequired,
};

SignOut.defaultProps = {
    onSignOut: () => {},
};

const mapDispatchToProps = dispatch => ({
    onSignOut: () => dispatch(signOut()),
    scrollToTop: () => dispatch(setScrollToTop()),
});

export default connect(null, mapDispatchToProps)(SignOut);
