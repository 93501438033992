import {
    all, put, select, take, takeLatest, call,
} from 'redux-saga/effects';
import { isEqual } from 'lodash';
import { saveBasicProfile, saveProfileCredentials } from 'state-management/actions/basicProfile';
import {
    saveBasicProfileFormSuccess,
    saveBasicProfileFormFail,
} from 'state-management/actions/basicProfileForm';
import { hidePopup } from 'state-management/actions/globalPopupContainer';
import { SAVE_BASIC_PROFILE_FORM } from 'state-management/constants/basicProfileForm';
import {
    SET_BASIC_PROFILE,
    BASIC_PROFILE_CREDENTIALS_URL,
} from 'state-management/constants/basicProfile';
import {
    setBasicProfileUpdateFailed,
    setBasicProfilePasswordFailed,
} from 'state-management/actions/basicProfile';
import { PUT_ERROR } from 'state-management/constants/errors';
import { getProfileSources } from 'state-management/actions/sources';
import { setError } from 'state-management/actions/errors';
import Api from 'utils/api';

/**
 * Save user basic profile.
 * @param {*} action
 */
function* saveBasicProfileForm(action) {
    const {
        firstName,
        lastName,
        curPassword,
        password,
        verifyPassword,
        aumId,
        durationId,
        amountId,
        serviceOfferingIds,
        sourceIds,
        topicIds,
        homepage,
        wmAUMId,
        wmHouseholdId,
        retirementPlanAUMId,
        retirementPlanPlansId,
    } = action.data;

    const effects = [];
    const previousSourceIds = yield select(state => state.basicProfile.sourceIds);

    if (password) {
        try {
            const response = yield call(Api.post, BASIC_PROFILE_CREDENTIALS_URL, { data: {
                firstName,
                lastName,
                curPassword,
                password,
                verifyPassword,
                /*crd,
                iard,*/
            }});
            yield call(Api.refreshToken);
        } catch (e) {
            if (e.data && e.data[0] && e.data[0].code === "error.okta.validation.password") {
                yield put(setBasicProfilePasswordFailed());
            } else {
                yield put(setBasicProfileUpdateFailed());
            }

            yield put(setError('global.errors.saveProfile'));

            //go no further if creds failed
            return;
        }
    }

    effects.push(put(saveBasicProfile({
        firstName,
        lastName,
        aumId,
        durationId,
        amountId,
        sourceIds,
        topicIds,
        serviceOfferingIds,
        homepage,
        wmAUMId,
        wmHouseholdId,
        retirementPlanAUMId,
        retirementPlanPlansId,
    })));

    yield all(effects);

    const resultAction = yield take([SET_BASIC_PROFILE, PUT_ERROR]);
    const isSuccessResult = resultAction.type !== PUT_ERROR;
    const isOpen = yield select(state => state.globalPopupContainer.isOpen);

    if (!isEqual(previousSourceIds, sourceIds)) {
        yield put(getProfileSources());
    }

    yield put(isSuccessResult ? saveBasicProfileFormSuccess : saveBasicProfileFormFail);

    if (isOpen) {
        yield put(hidePopup(isSuccessResult));
    }
}

function* basicProfileFormSaga() {
    yield takeLatest(SAVE_BASIC_PROFILE_FORM, saveBasicProfileForm);
}

export default basicProfileFormSaga;
