import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET, FROM_MOBILE_TO_TABLET } from 'utils/mediaQueries';

const sliderStyles = css`
    /* stylelint-disable */

    .slick-slider {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 -16px;
        position: relative;
        touch-action: pan-y;
        user-select: none;

        .slick-track {
            display: flex;
            left: 0;
            margin: 0 auto;
            position: relative;
            top: 0;
            transform: translate3d(0, 0, 0);

            &:before {
                content: '';
                display: table;
            }

            &:after {
                clear: both;
                content: '';
                display: table;
            }
        }

        .slick-list {
            display: block;
            margin: -16px 0 0 0;
            overflow: hidden;
            padding: 0;
            position: relative;
            transform: translate3d(0, 0, 0);

            &:focus {
                outline: none;
            }
        }

        @media screen and ${FROM_TABLET} {
            margin: 0 30px 60px;
            &.slider-custom-margin {
                margin:0 100px;
            }
        }

        @media screen and ${FROM_DESKTOP} {
            margin: 0 35px 60px;

            &.slider-custom-margin {
                margin:0 125px;
            }
        }

        @media screen and ${FROM_MOBILE_TO_TABLET} {
            margin: 0 35px 60px;

            &.slider-custom-margin {
                margin:0 50px;
            }
        }

        @media (min-width: 1440px) {
            margin: 0 auto 60px;

            &.slider-custom-margin {
                margin:0 150px;
            }
        }
    }

    .slick-list.dragging {
        cursor: hand;
    }

    .slick-loading {
        .slick-track {
            visibility: hidden;
        }

        .slick-slide {
            visibility: hidden;
        }
    }

    [dir='rtl'] {
        .slick-slide {
            float: right;
        }
    }

    .slick-slide {
        display: flex;
        min-height: 1px;

        > div {
            box-sizing: border-box;
            display: flex;
            height: auto;
            padding: 16px;
            width: 100%;
        }

        &:after {
            clear: both;
            content: '';
            display: table;
        }
    }

    .slider-custom-margin .slick-slide > div {
        padding: 0px;
    }

    .slick-slide.slick-loading {
        img {
            display: none;
        }
    }

    .slick-slide.dragging {
        img {
            pointer-events: none;
        }
    }

    .slick-initialized {
        .slick-slide {
            > div {
                opacity: 0;
            }
        }

        .slick-slide.slick-current {
            > div {
                display: flex;
                flex: 1 0 auto;
            }
        }

        .slick-slide.slick-active {
            > div {
                opacity: 1;
            }
        }
    }

    .slick-vertical {
        .slick-slide {
            border: 1px solid transparent;
            display: block;
            height: auto;
        }
    }

    .slick-arrow.slick-hidden {
        display: none;
    }

    .slick-arrow {
        background: transparent;
        border: 0;
        display: none !important;
        opacity: 0.4;
        padding: 0;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        z-index: 1;

        &:hover, &:focus {
            cursor: pointer;
            opacity: 1;
        }

        svg {
            display: block;
            fill: #007ac2;
            height: 44px;
            width: 44px;

            @media screen and ${FROM_TABLET} {
                height: 52px;
                width: 52px;
            }
        }

        @media screen and ${FROM_TABLET} {
            display: block !important;
        }
    }

    .slick-arrow.slick-disabled {
        &:hover, &:focus {
            cursor: not-allowed;
            opacity: 0.4;
        }
    }

    .slick-prev {
        left: -60px;
    }

    .slick-next {
        right: -60px;
    }

    @media screen and ${FROM_MOBILE_TO_TABLET} {
        &.slider-custom-margin .slick-prev {
            display: block !important;
            left: -35px;
        }
        &.slider-custom-margin .slick-next {
            display: block !important;
            right: -35px;
        }
    }

    .slick-dots {
        margin: 10px auto 0;
        position: relative;
        text-align: center;

        li {
            background: #d8d8d8;
            border-radius: 50%;
            display: inline-block;
            height: 11px;
            list-style-type: none;
            margin-right: 20px;
            width: 11px;

            &:last-child {
                margin-right: 0;
            }

            @media screen and ${FROM_TABLET} {
                height: 12px;
                width: 12px;
            }
        }

        .slick-active {
            background: #007ac2;
        }
    }

    .slider-custom-margin .slick-arrow svg {
        height: 20px;
        width: 20px;
        fill: #ccc;
    }

    .slick-slider-market-indices {
        height: 81px;
        border-right: 2px solid #f5f5f5;
        border-left: 2px solid #f5f5f5;

        .slick-prev {
            left: -36px;
        }

        .slick-next {
            right: -36px;
        }

        .slick-arrow svg {
            height: 26px;
            width: 26px;
        }
    }
`;

export default sliderStyles;
