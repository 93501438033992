import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import OnboardingContent from 'containers/Onboarding/OnboardingContent/OnboardingContent';
import OnboardingSidebar from 'containers/Onboarding/OnboardingSidebar/OnboardingSidebar';
import { Sticky, StickyContainer } from 'components';
import TopicsCategorySection from 'containers/Onboarding/Topics/TopicsCategorySection';
import {
    onboardingSectionContainerStyles,
    onboardingSectionTextStyles,
    onboardingSectionTextSmallStyles,
    stickyContainerStyles,
} from 'containers/Onboarding/styles';
import { setOnBoardingStep as setOnBoardingStepAction } from 'state-management/actions/onboarding';
import { addMessage as addAriaNotificationAction } from 'state-management/actions/ariaNotification';
import { STEP_NUMS } from 'state-management/constants/onboarding';
import OnboardingErrors from 'containers/Onboarding/OnboardingErrors/OnboardingErrors';
import { SITE_SECTION_TOPICS, PAGE_NAME_TOPICS } from 'utils/analyticsConstants';

const StyledStickyContainer = styled(StickyContainer)`
    ${stickyContainerStyles};
`;

const StyledSectionContainer = styled.div`
    ${onboardingSectionContainerStyles};
`;

const StyledSidebarText = styled.p`
    ${onboardingSectionTextStyles};
`;

const StyledSidebarTextSmall = styled.p`
    ${onboardingSectionTextSmallStyles};
`;

/**
 * Render the topics page of onboarding.
 */
export class Topics extends React.Component {
    /**
     * Gather needed data at mount.
     */
    componentDidMount() {
        this.props.setOnBoardingStep(STEP_NUMS.TOPICS);
        this.props.handleIsValid(this.props.selectedTopicIds);
        this.props.addAriaNotification('onboarding.topics.loaded', {
            translated: false,
        });
    }

    /**
     * Render topics category groups.
     */
    renderCategories() {
        const { topicCategories, selectedTopicIds, onSelectionChange } = this.props;

        return topicCategories.map(category => (
            <TopicsCategorySection
                key={`topics-category-${category.id}`}
                categoryName={category.name}
                topics={category.topics}
                selectedTopicIds={selectedTopicIds}
                onSelectionChange={onSelectionChange}
            />
        ));
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const {
            firstName,
            isLoaded,
            isMobile,

        } = this.props;

        return (
            <StyledSectionContainer>
                <StyledStickyContainer>
                    <Sticky
                        enabled={!isMobile}
                        id="sticky-topics-desc"
                        topOffset={40}
                    >
                        <OnboardingSidebar
                            isAnimatable
                            isLoaded={isLoaded}
                            featuredText={(
                                <FormattedMessage
                                    id="onboarding.topics.heading"
                                    values={{ name: firstName }}
                                />
                            )}
                            sectionTitle={(
                                <FormattedMessage id="onboarding.topics.title" />
                            )}
                        >
                            <div data-rel="splash" />
                            <StyledSidebarText>
                                <FormattedMessage id="onboarding.topics.text" />
                            </StyledSidebarText>
                            <StyledSidebarTextSmall>
                                <FormattedMessage id="onboarding.topics.secondaryText" />
                            </StyledSidebarTextSmall>
                            <OnboardingErrors
                                validationMessageId="onboarding.topics.error"
                                showValidationError={this.props.showError}
                                errorRef={this.props.errorRef}
                                globalErrors={this.props.globalErrors}
                            />
                        </OnboardingSidebar>
                    </Sticky>
                    <OnboardingContent
                        isAnimatable
                        isLoaded={isLoaded}
                        key="topics-onboarding-content"
                    >
                        {this.renderCategories()}
                    </OnboardingContent>
                </StyledStickyContainer>
            </StyledSectionContainer>
        );
    }
}

Topics.propTypes = {
    addAriaNotification: PropTypes.func.isRequired,
    firstName: PropTypes.string.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    topicCategories: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        topics: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })).isRequired,
    })).isRequired,
    selectedTopicIds: PropTypes.arrayOf(PropTypes.number),
    onSelectionChange: PropTypes.func.isRequired,
    setOnBoardingStep: PropTypes.func.isRequired,
    handleIsValid: PropTypes.func.isRequired,
    showError: PropTypes.bool.isRequired,
    errorRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    globalErrors: PropTypes.arrayOf(PropTypes.shape),
};

Topics.defaultProps = {
    selectedTopicIds: [],
    errorRef: React.createRef(),
    globalErrors: [],
};

const mapStateToProps = state => ({
    isLoaded: state.topics.isLoaded,
    topicCategories: state.topics.onboarding.categories,
});

const mapDispatchToProps = dispatch => ({
    addAriaNotification: (message, options) => (
        dispatch(addAriaNotificationAction(message, options))
    ),
    setOnBoardingStep: idx => dispatch(setOnBoardingStepAction(idx)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Topics);
