import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Container, BackToLink, Loader } from 'components';
import {
    containerStyles,
    cardHolderStyles
} from './styles';
import CollectionHeaderCard from '../../../components/atomics/molecules/CollectionHeaderCard/CollectionHeaderCard';
import NewGuides from '../NewGuides/NewGuides';
import FeaturedTools from '../FeaturedTools/FeaturedTools';
import { setScrollToTop } from 'state-management/actions/scroll';
import { getCollection } from 'state-management/actions/guide';
import { wrapBackButtonLabels } from "../../../utils/urlUtils";
import { headerStyles } from "../../PracticeManagement/Tools/styles";

const COLLECTION_PAGE = "Collection";

const Header = styled.div`
    ${headerStyles}
`;

const StyledContainer = styled.div`
    ${containerStyles};
`;

export const StyledCardHolder = styled.div`
    ${cardHolderStyles};
`;

export const BackToLinkContainer = styled.div`
`;

/**
 * Render a single collection page.
 */
class CollectionContainer extends React.Component {

    /**
     * Scroll to top and gather the collections data.
     */
    componentDidMount() {
        const { scrollToTop, getCollectionData, match } = this.props;
        const { params } = match;

        scrollToTop();

        getCollectionData(params.code);

    }

    /**
     * Update to other collection if the collection code changed.
     * @param {*} nextProps
     */
    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.code !== this.props.match.params.code) {
            this.props.getCollectionData(nextProps.match.params.code);
        }
    }

    /**
     * Update active tab as needed on property change.
     */
    componentDidUpdate() {
        if (this.props.collectionInfo) {
            this.setActiveTabName(this.props.collectionInfo.group);
        }
    }

    /**
     * Set the active tab selected.
     * @param {*} group 
     */
    setActiveTabName(group) {
        let activeTabIndex = group === "PRACTICE_MILESTONES" ? 1 : 0;
        sessionStorage.setItem("ACTIVE_TAB_INDEX", activeTabIndex);
    }

    /**
     * Render the collection page container and underlying components.
     */
    render() {
        const {
            urlHistoryList
        } = this.props;

        if (this.props.isLoading === true || this.props.showLoader === true) {
            return <Loader />;
        }

        const backLabel = urlHistoryList !== null && urlHistoryList.length
            ? urlHistoryList.length - 2 >= 0
                ? urlHistoryList[urlHistoryList.length - 2] : null
            : null;

        return (
            <React.Fragment>
                {this.props.isLoading === false &&
                    <StyledContainer>
                        <Container>
                            <Header>
                                <BackToLinkContainer>
                                    <BackToLink
                                        to={backLabel !== null ? backLabel.currentUrl : '#'}
                                        customLabel={wrapBackButtonLabels(backLabel)}
                                        isDisable={backLabel == null}
                                    >
                                    </BackToLink>
                                </BackToLinkContainer>
                            </Header>

                        </Container>
                        {((this.props.collectionInfo != null) &&
                            <Container>
                                <StyledCardHolder>
                                    <CollectionHeaderCard
                                        collectionInfo={this.props.collectionInfo}
                                    />
                                </StyledCardHolder>
                            </Container>
                        )}
                        {((this.props.collectionInfo !== null
                            && this.props.collectionInfo.guides
                            && this.props.collectionInfo.guides.length) &&
                            <NewGuides
                                guideListData={this.props.collectionInfo.guides}
                                guidesHeader={this.props.collectionInfo.guideDescription}
                                parentPage={COLLECTION_PAGE}
                            />
                        )}
                        {((this.props.collectionInfo !== null
                            && this.props.collectionInfo.tools
                            && this.props.collectionInfo.tools.length) &&

                            <FeaturedTools
                                toolsList={this.props.collectionInfo.tools}
                                toolsHeader={this.props.collectionInfo.toolDescription}
                                toolsSubHeader={this.props.collectionInfo.toolDescription}
                                parentPage={COLLECTION_PAGE}
                            />

                        )}
                    </StyledContainer>
                }
            </React.Fragment>
        );
    }
};

CollectionContainer.propTypes = {
    collectionInfo: PropTypes.shape({
    }),
    isLoading: PropTypes.bool,
    email: PropTypes.string,
    name: PropTypes.string,
    goBack: PropTypes.func,
    showLoader: PropTypes.bool,
};

CollectionContainer.defaultProps = {
    collectionInfo: null,
    goBack: null,
    isLoading: true,
    showLoader: false
}

const mapStateToProps = (state) => ({
    collectionInfo: (state.guide.collectionData ? state.guide.collectionData : null),
    isLoading: state.guide.isLoading,
    urlHistoryList: state.UrlHistoryReducer.history,
    showLoader: state.guide.showLoader
});

const mapDispatchToProps = dispatch => ({
    getCollectionData: title => dispatch(getCollection(title)),
    scrollToTop: () => { dispatch(setScrollToTop()) },
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CollectionContainer));
