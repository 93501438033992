import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { intlShape, injectIntl } from 'react-intl';
import {
    BreakpointsContext,
    MOBILE,
} from 'components/Breakpoints/Breakpoints';
import {
    Button,
    FieldText,
} from 'components';
import {
    formContentContainerStyles,
    buttonStyles,
    controlsStyles,
    errorStyles,
    fieldsWrapperStyles,
    fieldWrapperStyles,
    passwordRulesStyles,
} from 'containers/ResetPassword/ResetPasswordForm/styles';
import PasswordRules, {
    handlePasswordBlur,
    handlePasswordChange,
} from 'components/PasswordRules/PasswordRules';
import labels from '../../../lang/en.json';
import storage from 'utils/store';
import {
    RESET_PASSWORD_EMAIL,
} from 'state-management/constants/forgotPassword';

export const StyledFormContentContainer = styled.div`
    ${formContentContainerStyles};
`;

export const StyledFieldsWrapper = styled.div`
    ${fieldsWrapperStyles};
`;

export const StyledFieldWrapper = styled.div`
    ${fieldWrapperStyles};
`;

const StyledControls = styled.div`
    ${controlsStyles};
`;

export const StyledButton = styled(Button)`
    ${buttonStyles};
`;

const StyledErrors = styled.div`
    ${errorStyles};
`;

const StyledPasswordRules = styled.div`
    ${passwordRulesStyles};
`;

export class ResetPasswordForm extends React.Component {
    state = {
        passwordHasBeenBlurred: false,
        passwordFormatRules: {},
        isSubmitted: false,
        isEdited: false,
    };

    /**
     * Set error for when password includes username.
     * @param {*} nextProps 
     */
    componentWillUpdate(nextProps) {
        if (nextProps.serverError
            && nextProps.serverError !== this.props.serverError
            && nextProps.serverError.data && nextProps.serverError.data[0]
            && nextProps.serverError.data[0].code === "error.okta.validation.password") {

            this.props.setFieldError(
                'password',
                labels.translations['profilePage.errors.correctPasswordRules']
            );
        }
    }

    onBlur = (e) => {
        const email = storage.get(RESET_PASSWORD_EMAIL);

        this.setState((prevState) => {
            const passwordHasBeenBlurred = true;
            const passwordFormatRules = (
                handlePasswordBlur(e, prevState.passwordFormatRules, passwordHasBeenBlurred, email)
            );

            return {
                passwordHasBeenBlurred,
                passwordFormatRules,
            };
        });
    };

    onChange = (e) => {
        const email = storage.get(RESET_PASSWORD_EMAIL);
        const { passwordFormatRules, passwordHasBeenBlurred } = this.state;

        const updatedPasswordRules = (
            handlePasswordChange(e, passwordFormatRules, passwordHasBeenBlurred, email)
        );

        this.setState({ passwordFormatRules: updatedPasswordRules });
    };

    getTranslation(id, values = {}) {
        const { intl: { formatMessage } } = this.props;

        return formatMessage({
            id,
        }, values);
    }

    handleChange = (e) => {
        const { name } = e.target;
        const { errors, setFieldError } = this.props;

        this.setState({ isEdited: true });

        if (name && errors[name]) {
            setFieldError(name, null);
        }
    };

    handleSubmit = (e) => {
        this.setState({ isSubmitted: true, isEdited: false });

        this.props.handleSubmit(e);
    };

    renderField = () => {
        const { verifyPasswordRef, passwordRef } = this.props;
        const { isSubmitted, isEdited } = this.state;
        const isFieldValid = isSubmitted && !isEdited;

        return (
            <React.Fragment>
                <StyledFieldsWrapper>
                    <StyledFieldWrapper>
                        <Field
                            component={FieldText}
                            id="password"
                            name="password"
                            label={this.getTranslation('forgotPassword.field.password')}
                            type="password"
                            ref={ref => passwordRef(ref)}
                            onChange={this.onChange}
                            onBlur={this.onBlur}
                            isValidated={isFieldValid}
                            isSubmitted={isSubmitted}
                        />
                    </StyledFieldWrapper>
                    <StyledFieldWrapper>
                        <Field
                            onBlur={this.props.confirmBlur}
                            component={FieldText}
                            id="passwordConfirm"
                            name="passwordConfirm"
                            label={this.getTranslation('forgotPassword.field.passwordConfirm')}
                            type="password"
                            ref={ref => verifyPasswordRef(ref)}
                            isValidated={isFieldValid}
                            isSubmitted={isSubmitted}
                        />
                    </StyledFieldWrapper>
                </StyledFieldsWrapper>
            </React.Fragment>
        );
    };

    renderGlobalErrors = () => {
        if (!isEmpty(this.props.serverError)) {
            return (
                <StyledErrors
                    id="forgot-password-errors"
                    ref={this.props.authRef}
                    tabIndex="-1"
                >
                    {this.getTranslation('forgotPassword.serverError')}
                </StyledErrors>);
        }

        return null;
    };

    render() {
        const { values } = this.props;
        const buttonText = this.getTranslation('forgotPassword.button');
        const isDisabled = Object.keys(values).some(key => !values[key]) || this.props.hasErrors;
        
        return (
            <BreakpointsContext.Consumer>
                {(breakpoint) => {
                    return (
                        <form
                            aria-labelledby="forgot-password-recover"
                            noValidate
                            onSubmit={this.handleSubmit}
                            onChange={this.handleChange}
                        >
                            <StyledFormContentContainer>
                                {this.renderField()}
                                {breakpoint !== MOBILE
                                    && <StyledPasswordRules>
                                        <PasswordRules passwordRules={this.state.passwordFormatRules} />
                                    </StyledPasswordRules>}
                                {breakpoint === MOBILE
                                    && <StyledButton
                                        appearance={Button.APPEARANCE.PRIMARY}
                                        id="forgot-password-in"
                                        type={Button.TYPES.SUBMIT}
                                        isDisabled={isDisabled}
                                        onClick={this.setSubmitting}
                                    >
                                        {buttonText}
                                    </StyledButton>}
                            </StyledFormContentContainer>
                            <StyledControls>
                                {breakpoint === MOBILE
                                    && <StyledPasswordRules>
                                        <PasswordRules passwordRules={this.state.passwordFormatRules} />
                                    </StyledPasswordRules>}
                                {breakpoint !== MOBILE
                                    && <StyledButton
                                        appearance={Button.APPEARANCE.PRIMARY}
                                        id="forgot-password-in"
                                        type={Button.TYPES.SUBMIT}
                                        isDisabled={isDisabled}
                                        onClick={this.props.setSubmitting}
                                    >
                                        {buttonText}
                                    </StyledButton>}
                            </StyledControls>
                            {this.renderGlobalErrors()}
                        </form>
                    );
                }}
            </BreakpointsContext.Consumer>
        );
    }
}

ResetPasswordForm.propTypes = {
    hasErrors: PropTypes.bool,
    setSubmitting: PropTypes.func.isRequired,
    confirmBlur: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.string).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    verifyPasswordRef: PropTypes.func.isRequired,
    passwordRef: PropTypes.func.isRequired,
    authRef: PropTypes.func.isRequired,
    setFieldError: PropTypes.func.isRequired,
    serverError: PropTypes.shape({}).isRequired,
    intl: intlShape.isRequired,
    values: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default injectIntl(ResetPasswordForm);
