import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET, FROM_MOBILE_TO_TABLET } from 'utils/mediaQueries';
import {
    RGB_COLORS,
    COLORS,
    FONTS,
    ANIMATIONS,
    FONT_AVENIR_NEXT,
} from 'utils/variables';

export const mobileSignupTitle = `
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    color: ${COLORS.SAPPHIRE};
    text-align: center;
    margin-bottom: 16px;
`;

export const tabletOnly = `
    display: none;

    @media screen and ${FROM_TABLET} {
        display: block;
    }
`;

export const mobileOnly = `
    display: block;

    @media screen and ${FROM_TABLET} {
        display: none;
    }
`;

export const videoTitle = css`
    font-size: 18px;
    line-height: 24px;
    color: ${COLORS.BLACK};
    font-weight: 600;
    margin-top: 50px;
    margin-bottom: 16px;

    @media screen and ${FROM_DESKTOP} {
        font-size: 24px;
        line-height: 33px;
    }
`;

export const videoContainer = css`
    width: 100%;
    margin-bottom: 40px;
    position: relative;

    @media screen and ${FROM_TABLET} {
        width: calc(100% + 123.5px);
        margin-left: -60.5px;
        margin-right: 63px
    }
    @media screen and ${FROM_DESKTOP} {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    
    video {
        width: 100%;
    }
`;

export const videoOverlay = css`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
`;

export const videoPlayButton = css`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: ${COLORS.SAPPHIRE};
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;
    cursor: pointer;

    &:hover {
        background: ${COLORS.SAPPHIRE};
    }
`;

export const videoPlayButtonArrow = css`
    border-bottom: 10px solid ${COLORS.WHITE};
    border-right: 10px solid ${COLORS.WHITE};
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    width: 0;
    height: 0;
    transform: rotate(-45deg);
`;

export const videoArrowWrapper = css`
    transform: scaleX(1.5);
    position: absolute;
    left: 7px;
    top: 14px;
`;

export const speakerTitle = css`
    font-size: 12px;
    letter-spacing: 0.5px;
    color: ${COLORS.DARK_GRAY2};
    white-space: nowrap;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 16px;
`;

export const ceCredit = css`
    font-size: 12px;
    letter-spacing: 0.5px;
    color: ${COLORS.DARK_GRAY2};
    white-space: nowrap;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 26px;

    img {
        margin-top: 8px;
        display: block;
        width: 112px;
    }
`;

export const splashContentTopExplored = css`
    background: ${COLORS.WHITE};
    overflow: hidden;

    /* text container */
    &>div:first-child {
        max-width: 280px;
        padding-left: 16px;
        padding-top: 10px;
        padding-bottom: 32px;

        h2 {
            max-width: 318px;
            font-size: 20px;
            color: ${COLORS.BLACK};
            font-weight: 600;
            line-height: 33px;
        }

        p {
            font-size: 15px;
            line-height: 20px;
            color: ${COLORS.MEDIUM_GRAY2};
            margin-bottom: 65px;
        }

        a {
            
        }

        @media screen and ${FROM_TABLET} {
            max-width: 44%;
            padding-left: 64px;
            padding-top: 40px;
            padding-bottom: 134px;

            h2 {
                font-size: 22px;
                line-height: 33px;
            }
    
            p {
                line-height: 16px;
                font-weight: 500;
                letter-spacing: 0.12px;
                margin-bottom: 32px;
            }
        }

        @media screen and ${FROM_DESKTOP} {
            padding-left: 156px;
            max-width: 33%;
            padding-bottom: 127px;
            position: relative;
            top: 40px;

            h2 {
                font-size: 24px;
            }
    
            p {
                font-size: 18px;
                line-height: 24px;
                letter-spacing: 0;
            }
        }
    }

    /* image container */
    &>div:last-child {
        position: relative;
        height: 60px;
        background-image: linear-gradient(to bottom, #f6f6fd 3%, ${COLORS.WHITE} 102%);

        img {
            max-height: 240px;
            position: absolute;
            bottom: 0;
            right: -90px;
        }

        @media screen and ${FROM_TABLET} {
            height: 90px;

            img {
                max-height: 500px;
                bottom: -10px;
                right: -159px;
            }
        }

        @media screen and ${FROM_DESKTOP} { 
            height: 110px;

            img {
                max-height: 600px;
                bottom: -100px;
                right: initial;
                left: 40%;
            }
        }
    }

    @media screen and ${FROM_TABLET} { 
        padding-bottom: 40px;
    }

    @media screen and ${FROM_DESKTOP} { 
        padding-bottom: 100px;
    }
`;

export const splashContentTopPoints = css`
    overflow: hidden;
    background: ${COLORS.WHITE};

    &>section {
        background: transparent !important;
    }
`;

export const splashContentBottomFirst = css`
`;

export const splashContentBottomSecond = css`
`;

export const splashContentBottomThird = css`
`;

export const formContainerStyles = css`
    background: ${COLORS.LIGHT_GRAY1};
    padding-left: 10px;
    padding-right: 10px;

    ${props => props.isClaimSpot && css`
        text-align: center;
        background: ${COLORS.WHITE};
        
        a {
            width: calc(100% - 36px);
            margin-bottom: 48px;
        }
    `}

    @media screen and ${FROM_TABLET} {
        padding-left: 35px;
        padding-right: 0;
        background: ${COLORS.WHITE};

        ${props => props.isClaimSpot && css`
            padding-top: 100px;
            padding-bottom: 100px;

            a {
                width: 250px;
                margin-bottom: initial;
            }
        `}
    }

    @media screen and ${FROM_DESKTOP} {
        padding-left: 60px;
    }

    *[data-rel="controlsPanel"] {
        display: block;

        &>div:first-child {
            padding-right: 14px;
        }

        button {
            margin-top: 26px;
        }

        @media screen and ${FROM_DESKTOP} {
            display: flex;

            button {
                width: 175px;
                margin-top: 0;
            }
        }
    }
`;

export const splashTitleStyles = css`
    display: none;
    font-size: 24px;
    line-height: 24px;
    color: ${COLORS.BLACK};
    font-weight: 600;
    margin-bottom: 20px;

    @media screen and ${FROM_TABLET} {
        display: block;
    }
`;

export const mobileSplashTitleStyles = css`
    font-size: 24px;
    line-height: 33px;
    color: ${COLORS.BLACK};
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;

    @media screen and ${FROM_TABLET} {
        display: none;
    }
`;

export const splashImageStyles = css`
    height: 116px;
    overflow: hidden;
    margin-bottom: 21px;

    img {
        max-width: 100%;
    }

    @media screen and ${FROM_TABLET} {
        margin-bottom: 25px;
        height: 146px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 24px;
        height: 208px;
    }
`;

export const splashDateStyles = css`
    text-align: center;
    margin-bottom: 20px;
    padding-bottom: 0;

    div[data-rel="DateDisplayContainer"] {
        width: 100%;    
        padding: 0;
    }

    @media screen and ${FROM_TABLET} {
        text-align: left;
        margin-bottom: 2px;

        div[data-rel="DateDisplayContainer"] {
            text-align: left;
            width: auto;
        }
    }
`;

export const splashDescriptionStyles = css`
    font-size: 15px;
    letter-spacing: 0.1px;
    text-align:justify;
    margin-bottom: 40px;

    ul {
        padding-left: 18px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 18px;
        letter-spacing: 0.12px;
    }
`;

export const formDescriptionStyles = css`
    font-size: 15px;
    line-height: 20px;
    color: ${COLORS.BLACK};
    margin-bottom: 24px;
`;

export const formLinkStyles = css`
    color: ${COLORS.SAPPHIRE};
    text-decoration: none;
    font-weight: bold;
`;

export const formTitleStyles = css`
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    color: ${COLORS.SAPPHIRE};
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-top: 40px;
    margin-bottom: 16px;

    @media screen and ${FROM_TABLET} {
        font-size: 26px;
        line-height: 36px;
        margin-top: 0;

        ${props => props.isClaimSpot && css`
            font-size: 35px;
            line-height: 45px;
            margin-bottom: 42px;
        `}
    }

    @media screen and ${FROM_DESKTOP} {
        margin-top: 10px;
    }
`;

export const titleStyles = css`
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 26px;
    line-height: 36px;
    color: ${COLORS.WHITE};
    font-weight: 400;
    margin-bottom: 32px;
    margin-top: 42px;
    margin-left: 50px;

    @media screen and ${FROM_TABLET} {
        margin-top: 50px;
        margin-left: 40px;

        br {
            display: none;
        }
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 35px;
        line-height: 42px;
        margin-bottom: 50px;
        margin-left: 58px;
    }
`;

export const titleContainerStyles = css`
`;

export const colorBarStyles = css`
    min-width: 58px;
    width: calc(100% - 1170px);
    height: 5px;
    overflow: hidden;
    background-image: linear-gradient(to right, ${COLORS.LIGHT_OCEAN}, ${COLORS.OCEAN});
    position: absolute;
    left: 0;
    top: 54px;

    ${props => props.hasBackButton && css`
        top: 101px;
    `}

    @media screen and ${FROM_TABLET} {
        min-width: 58px;
        top: 63px;

        ${props => props.hasBackButton && css`
            top: 120px;
        `}
    }

    @media screen and ${FROM_DESKTOP} {
        min-width: 80px;
        width: calc(((100% - 1282px) / 2) + 20px);
    }
`;

export const splashContentStyles = css`
    padding-left: 10px;
    padding-right: 10px;

    @media screen and ${FROM_TABLET} {
        padding-right: 60px;
        padding-left: 0;
    }


    a[type="type/primary"] {
        width: calc(100% - 36px);
        margin-bottom: 30px;
        font-size: 12px;
        line-height: 26px;
        padding: 9px 18px;
        background-color:${COLORS.OCEAN};

        &:hover{
            background-color:${COLORS.SAPPHIRE};
        }


        @media screen and ${FROM_TABLET} {
            margin-bottom: 0;
        }
    }
`;

export const sectionContainerStyles = css`
    display: flex;
    flex-direction: column;

    @media screen and ${FROM_TABLET} {
        flex-direction: row;
        align-items: end;
        
        &>div {
            width: 50%;
        }

        &>div:last-child {
            width: calc(50% - 1px);
            border-left: 1px solid ${COLORS.LIGHT_GRAY2};
        }
    }
`;

export const pageContainerStyles = css`
    background: ${COLORS.DARK_SAPPHIRE};
    position: relative;
`;

export const containerStyles = css`
    display: flex;
    flex: auto;
    flex-direction: column;
    padding: 0 16px;
    width: calc(100% - 32px);
    padding-bottom: 40px;
    position: initial;

    ${props => props.hasLowerContent && css`
        padding-bottom: 0 !important;
    `}

    @media screen and ${FROM_TABLET} {
        padding: 0 38px;
        padding-bottom: 38px;
        width: calc(100% - 76px);
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 0;
        margin: auto;
        padding-bottom: 76px;
        max-width: 1282px;

        ${props => props.isSplash && css`
            max-width: 1170px;
        `}

        width: calc(100% - 96px);
    }
`;

export const contentWrapperStyles = css`
    background: ${COLORS.WHITE};
    padding-top: 10px;

    @media screen and ${FROM_TABLET} {
        padding-top: 28px;
        padding: 28px;
        width: calc(100% - 54px);
    }

    @media screen and ${FROM_DESKTOP} {
        padding-top: 56px;
        padding: 56px;
        width: calc(100% - 112px);
        max-width: 1170px;
    }
`;

export const styleContainerStyles = css`
    display: flex;
    flex: auto;
    flex-direction: column;
    padding: 0 16px;
    width: calc(100% - 32px);
    background: ${COLORS.DARK_SAPPHIRE};    
    position: relative;
    max-width: 1170px;
    margin: 0 auto;

    @media screen and ${FROM_TABLET} {
        padding: 0 38px;        
        width: calc(100% - 76px);
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 0 48px 76px;       
        width: calc(100% - 96px);
    }
`;

export const stylesContentWrapper = css`
    background: ${COLORS.WHITE};
    margin: 0 -16px;

    ${props => props.existingUser && css`
        margin-top: 16px;
    `}

    @media screen and ${FROM_TABLET} {
        margin: 0  -38px;

        ${props => props.existingUser && css`
            margin-top: 24px;
        `}
    }

    @media screen and ${FROM_DESKTOP} {
        margin: unset;

        ${props => props.existingUser && css`
            margin-top 24px;
        `}
    }
`;

export const stylesSplashImage = css`    
    overflow: hidden;
    background: ${COLORS.DARK_SAPPHIRE};
    height: 222px;
    padding: 0 16px;
    margin-top: -1px;

    ${props => props.isNewUser && css`
        margin-top: 20px;
    `}

    @media screen and ${FROM_TABLET} {
        height: 399px;
        padding: 0 38px;

        ${props => props.isNewUser && css`
            margin-top: 44px;
        `}
    }

    @media screen and ${FROM_DESKTOP} {
        height: 425px;
        max-width: 1170px;
        margin: auto;
        padding: 0;
        width: calc(100% - 96px);

        ${props => props.existingUser && css`
            width: auto;
        `}

        ${props => props.isNewUser && css`
            margin-top: 50px;
        `}
    }

    img {
        max-width: 100%;
        width: 100%;
        max-height: 452px;
        height: 100%;
        object-fit: cover;
    }   
`;

export const popupStyles = css`
    background-color: ${COLORS.WHITE};
    box-sizing: border-box;
    margin: 0 16px;
    max-height: 90%;
    max-width: 683px;
    min-width: 280px;
    overflow: hidden;
    padding: 15px;

    @media screen and ${FROM_TABLET} {
        margin: 0 45px;
        padding: 20px;
        width: 100%;
    }

    &>div {
        width: auto;
        height: auto;
        border: none;
    }
`;

export const stylesTxtWrapperContainer = css`   
    display: inline;
    width: calc(100% - 96px);
    margin: 0 auto;

    ${props => props.isNewUser && css`
        width: auto;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        display: block;
        background: ${COLORS.WHITE};
        padding-bottom: 40px;
        position: relative;

        @media screen and ${FROM_DESKTOP} {
            margin-left: auto;
            margin-right: auto;
            max-width: 1170px;
            width: calc(100% - 96px);
            position: initial;
        }
    `}
`;

export const stylesTxtWrapper = css`   
    width: 326px;
    margin: -45px auto 72px;
    background: ${COLORS.WHITE};    
    min-height: 302px;
    box-sizing: border-box;
    position: relative;

    @media screen and ${FROM_TABLET} {
        width: 554px;
        margin: -75px auto 72px;
    }

    @media screen and ${FROM_DESKTOP} {
        width: 746px;
        margin: -190px auto 72px;
    }

    ${props => props.isNewUser && css`
        margin-bottom: 0 !important;
        margin-top: 0;
        position: relative;
        top: -45px;

        @media screen and ${FROM_TABLET} {
            top: -75px;
        }

        @media screen and ${FROM_DESKTOP} {
            top: 0;
            margin: -190px auto 72px;
        }
    `}

    &:before {
        box-shadow: 0 -5px 2px 0 rgba(${RGB_COLORS.BLACK}, 0.16);
        content: '';
        z-index: 1;
        position: absolute;
        left: 0;
        height: 90px;
        width: 100%;  
        top: 0;

        @media screen and ${FROM_TABLET} {
            height: 169px;
        }

        @media screen and ${FROM_TABLET} {
            height: 302px;
        }
    }
`;

export const stylesWrapper = css`
    z-index: 1;
    position: relative;
    padding: 16px 24px 0 17px;

    @media screen and ${FROM_TABLET} {        
        padding: 37px 63px 0 60.5px;        
    }

    @media screen and ${FROM_DESKTOP} {        
        padding: 35px 65px 0;       
    }

    ${props => props.isNewUser && css`
        padding-bottom: 24px;
    `}
`;

export const stylesTopBorder = css`
    background-image: linear-gradient(to right, #00cbf1 , #7c00d0);
    height: 8px;
`;

export const stylesPageHeading = css`
    font-size: 26px;
    font-weight: 400;
    color: ${COLORS.SAPPHIRE};
    line-height: 36px;
    margin-bottom: 16px;
    font-family: ${FONTS.AVENIR_NEXT.FONT};

    @media screen and ${FROM_TABLET} {
        margin-bottom: 21px;        
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 42px;
        line-height: 52px;
        margin-bottom: 32px;
    }
`;

export const stylesTextStart = css`
    font-size: 18px;
    margin-bottom: 15px;
`;

export const stylesCardType = css`
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: ${COLORS.DARK_GRAY2};
    margin-bottom: 8px;
`;
export const stylesCenterContent = css`
    text-align: center;
`;

export const formDescription = css`
    font-family:${FONT_AVENIR_NEXT};    
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    font-size: 15px;
    letter-spacing: 0.1px;
    text-align: center;
    color:${COLORS.BLACK};
    padding: 24px;
    margin: auto;
    margin-bottom: 60px;

    @media screen and ${FROM_TABLET} {
        font-size: 15px;
        letter-spacing: 0.12px;
        padding:12px;
    }  

    @media screen and ${FROM_DESKTOP} {
        font-size: 18px;
        letter-spacing: 0.12px;
        padding:12px;
    }
`;

export const buttonContainer = css`
    margin: 27px 0 13px;

    @media screen and ${FROM_TABLET} {
        margin: 27px 0 43px;
    }
`;

export const styledBack = css`
    margin: 0;
    max-width: 1282px;

    ${props => props.existingUser && css`
        margin-top: 24px;

        @media screen and ${FROM_DESKTOP} {
            margin-top: 36px;
        }

        a {
            margin-top: 0;
        }
    `}

    a {
        color: ${COLORS.WHITE};
    }
`;

export const styledBackInner = css`
    max-width: 1170px;
    margin: 0 auto;
`;

export const stylesSplashTitle = css`
    display: none;
    font-size: 24px;
    line-height: 24px;
    color: ${COLORS.BLACK};
    font-weight: 600;
    margin-bottom: 20px;

    @media screen and ${FROM_TABLET} {
        display: block;
        font-size: 37.6px;
        line-height: 46.2px;
    }
`;

export const stylesSplashTitleBottom = css`    
    font-size: 24px;
    line-height: 33px;
    color: ${COLORS.BLACK};
    font-weight: 600;    

    @media screen and ${FROM_TABLET} {
        display: block;
        font-size: 24px;
        line-height: 26px;
    }
`;
export const stylesCountDown = css`
    margin: 0 auto 21.6px;
    max-width: 350px

    @media screen and ${FROM_DESKTOP} {
        max-width: 490px;
    }
`;

export const stylesTopTxt = css`
    font-size: 18px;
    font-weight: 400;
    line-height: 1.33;
    letter-spacing: 0.12px;
    color: ${COLORS.BLACK};
    max-width: 473px;
    margin: 0 auto 43px;
`;

export const stylesDateContainer = css`
    font-size: 12px;
    line-height: 16px;
    color: ${COLORS.DARK_GRAY2};
    font-weight: 500;
`;

export const stylesSplashDescription = css`
    font-size: 18px;    
`;

export const stylesCeCreditContainer = css`
        border: 1px solid #dddddd;
        padding: 24px;
        margin-top: 56px;

        @media screen and ${FROM_DESKTOP} {
            margin-top: 73px;
        }       
`;

export const stylesCeCredit = css`
    font-size: 12px;
    letter-spacing: 0.5px;
    color: ${COLORS.DARK_GRAY2};
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 26px;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;

    img {
        width: 129px;
        height: 33px;

        @media screen and ${FROM_MOBILE_TO_TABLET} {
            position: relative;
            top: 25px;
        }
    }
`;

export const stylesCeCreditTitle = css`
    position: relative;
    top: 6px;
    font-size: 18px;
    margin-right: 5px;

    @media screen and ${FROM_DESKTOP} {
        font-size: 24px;
    }
    `;

export const stylesCeCreditList = css`
    font-size: 15px;
    margin-bottom: 29px;
    position: relative;
    &:last-child{
        margin-bottom: 11px;
    }
    @media screen and ${FROM_DESKTOP} {        
        font-size: 18px;
        margin-bottom: 40px;
    }
`;

export const stylesCeCreditCount = css`
    display: inline-block;
    background: ${COLORS.SAPPHIRE};
    border-radius: 50%;
    padding: 7px 13px;
    width: 35px;
    height: 35px;
    box-sizing: border-box;
    color: ${COLORS.WHITE};
    margin-right: 25px;
    font-weight: 700;

    @media screen and ${FROM_DESKTOP} {
        margin-right: 41px;
    }
`;

export const stylesCeCreditText = css`
    position: absolute;
    top: 10px;
`;

export const stylesbottomContainer = css`
    background: ${COLORS.LIGHT_GRAY1};
`;

export const stylesbottomTextContainer = css`
    padding: 62px 65px 60px 65px;   
    margin: 0 auto;

    @media screen and ${FROM_TABLET} {
        width: 425px;
    }

    @media screen and ${FROM_DESKTOP} {
        width: 616px;
    }
`;

export const styledBottomHeadingMain = css`
    color: ${COLORS.BLACK};
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    font-family: ${FONT_AVENIR_NEXT};
`;

export const styledBottomUL = css`
    margin: 21px 0 64px;
    list-style: none;
    padding-left: 17px;
`;

export const styledBottomLI = css`
     font-size: 15px;
     font-family: ${FONT_AVENIR_NEXT};
     color: ${COLORS.BLACK};
     line-height: 20px; 
     margin-bottom: 24px;
     
     &:last-child{
         margin: 0;
     }
     

     &::before{
        content: "•";
        color: ${COLORS.SAPPHIRE};
        font-weight: bold;
        display: inline-block; 
        width: 1em;
        margin-left: -1em;
     }

`;

export const styledBottomHeading = css`
    color: ${COLORS.DARK_GRAY2};
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 24px;
    font-weight: 600;
`;

export const styledBottomTitle = css`
    font-size: 15px;
    font-weight: 600;
    color: ${COLORS.BLACK};
    margin-bottom: 8px;
`;

export const styledBottomBody = css`
    display: flex;
    flex-direction: column;

    @media screen and ${FROM_TABLET} {
        flex-direction: row;
    }
`;

export const styledBottomText = css`
    font-size: 15px;
    margin-bottom: 24px;
`;

export const styledBottomleft = css`
    margin-bottom: 48px;

    @media screen and ${FROM_TABLET} {
        margin-right: 44px;
        margin-bottom: 0px;
        width: calc(50% - 44px);
    }
`;

export const styledBottomRight = css`
    @media screen and ${FROM_TABLET} {
        width: 50%;
    }
`;

export const stylesquizButtonContainer = css`
    justify-content: space-between;
    text-align: center;
`;

export const stylesvideoReplacementContainer = css`
        border: 1px solid #dddddd;
        padding: 24px;
        margin-top: 56px;

        @media screen and ${FROM_DESKTOP} {
            margin-top: 73px;
        }       
`;

export const stylescomeBackHeading = css`
    font-size: 26px;
    line-height: 36px;
    font-weight: 400;
    color: ${COLORS.SAPPHIRE};
    line-height: 36px;
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    text-align: center;
    margin: 0 auto 8px; 
    max-width: 255px;
    
    @media screen and ${FROM_TABLET} {
        max-width: 308px;
    }

    @media screen and ${FROM_DESKTOP} {               
        max-width: 374px;
        
    }
`;

export const stylescomeBackText = css`
    font-size: 15px;
    line-height: 20px;
    color: ${COLORS.BLACK};    
    margin-bottom: 16px;
    text-align: center;
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    max-width: 255px; 

    @media screen and ${FROM_TABLET} {
        margin: 0 auto 21px;  
        font-size: 15px;
        line-height: 20px; 
        max-width: 254px;    
    }

    @media screen and ${FROM_DESKTOP} {
        max-width: 374px;
        margin: 0 auto 40px;
    }
`;

export const stylesDownloadTrans = css`
    text-align: right;
`;
