import {
    all, select, put, takeLatest,
} from 'redux-saga/effects';
import {
    flatMap, sortBy, uniq, uniqBy,
} from 'lodash';

import { GET_TOPICS_SUCCESS } from 'state-management/constants/topics';
import {
    SEARCH_SUCCESS, TYPE_ARTICLE,
} from 'state-management/constants/search';
import {
    APPLY_FILTERS,
    ARTICLE,
    GUIDE,
    TOPICS_SECTION,
    TYPE_SECTION,
    SEE_MORE_NEWS,
    SEE_MORE_PRACTICE_MANAGEMENT,
    SOURCES_SECTION,
    SOURCE_CAPITAL_GROUP,
    SOURCE_PRACTICE_LAB,
    SOURCE_HBR,
    SOURCE_MC_KINSEY,
} from 'state-management/constants/searchFilter';
import { GET_SOURCES_SUCCESS } from 'state-management/constants/sources';
import {
    applyFilters,
    setSection,
    toggleOpen,
} from 'state-management/actions/searchFilter';
import { changePage } from 'state-management/actions/search';
import { setScrollToTop } from 'state-management/actions/scroll';
import { articleSelection, guideSelection, initialTypeState } from 'state-management/reducers/searchFilter';
import setQueryParams from 'state-management/actions/location';

import { isSubtypeOfGuide } from 'utils/contentCardUtils';
import { itemHasCapitalGroupSource, itemHasPracticeLabSource} from 'containers/Search/filterByHelpers';
import { TYPE } from 'components/ContentCard/constants';

const topicsFromCategories = categories => flatMap(categories, x => x.topics);

const formatItem = item => ({ id: item.id, name: item.name, checked: false });

const uniqueSearchItemsTopicIds = (searchItems) => {
    const duplicateTopics = flatMap(searchItems.filter(x => x.topics), x => x.topics);

    const uniqueTopics = uniqBy(duplicateTopics, 'id');

    return uniqueTopics.map(x => x.id);
};

const uniqueSearchItemsSourceIds = (searchItems) => {
    const duplicateSourcesIds = flatMap(searchItems.filter(x => x.sourceId), x => x.sourceId);

    return uniq(duplicateSourcesIds);
};

const getFilteredTopics = (searchItems, topicCategories) => {
    const topicIds = uniqueSearchItemsTopicIds(searchItems);

    const topics = topicsFromCategories(topicCategories);

    return topics.filter(topic => topicIds.includes(topic.id));
};

/**
 * Get sources by search filter.
 */
const getFilteredSources = (searchItems, sources) => {
    const hasItemWithCapitalGroupSource = !!searchItems
        .find(item => itemHasCapitalGroupSource(item));
    const hasItemWithPracticeLab = !!searchItems
        .find(item => itemHasPracticeLabSource(item));
    const hasHbrGuide = !!searchItems.find(item => item.type === TYPE.GUIDE_HBR);
    const hasMcKinseyGuide = !!searchItems.find(item => item.type === TYPE.GUIDE_MC_KINSEY);

    const sourceIds = uniqueSearchItemsSourceIds(searchItems);

    const filteredSources = sources.filter(source => sourceIds.includes(source.id));

    if (hasItemWithCapitalGroupSource) {
        filteredSources.push({
            id: SOURCE_CAPITAL_GROUP,
            name: 'Capital Group',
            checked: false,
        });
    }

    if (hasItemWithPracticeLab) {
        filteredSources.push({
            id: SOURCE_PRACTICE_LAB,
            name: 'Practice Lab',
            checked: false,
        });
    }

    if (hasHbrGuide) {
        filteredSources.push({
            id: SOURCE_HBR,
            name: 'Harvard Business Review',
            checked: false,
        });
    }

    if (hasMcKinseyGuide) {
        filteredSources.push({
            id: SOURCE_MC_KINSEY,
            name: 'McKinsey',
            checked: false,
        });
    }

    return filteredSources;
};

const checkPreviouslyAppliedSectionFilters = (filteredItems, prevFiltersApplied, sectionName) => {
    const previouslyCheckedIds = prevFiltersApplied[sectionName].items
        .filter(item => item.checked)
        .map(item => item.id);

    return filteredItems.map(item => (previouslyCheckedIds.includes(item.id)
        ? ({ ...item, checked: true })
        : item));
};

/**
 * Filter items by topic.
 * @param {*} param0
 */
function* filterTopics({ shouldUpdateFilters }) {
    if (!shouldUpdateFilters) {
        return;
    }

    const searchItems = yield select(state => state.search.items);
    const topicCategories = yield select(state => state.topics.all.categories);
    const prevFiltersApplied = yield select(state => state.searchFilter.filtersApplied);

    const filteredTopics = getFilteredTopics(searchItems, topicCategories).map(formatItem);

    const checkedFilteredTopics = prevFiltersApplied
        ? checkPreviouslyAppliedSectionFilters(filteredTopics, prevFiltersApplied, TOPICS_SECTION)
        : filteredTopics;

    const sortedCheckedFilteredTopics = sortBy(checkedFilteredTopics, x => x.name);

    const section = {
        title: TOPICS_SECTION,
        items: sortedCheckedFilteredTopics,
    };

    yield put(setSection(section, TOPICS_SECTION));
}

/**
 * Filter items by source.
 * @param {*} param0
 */
function* filterSources({ shouldUpdateFilters }) {
    if (!shouldUpdateFilters) {
        return;
    }

    const searchItems = yield select(state => state.search.items);
    const sources = yield select(state => state.sources.sources);
    const prevFiltersApplied = yield select(state => state.searchFilter.filtersApplied);

    const filteredSources = getFilteredSources(searchItems, sources).map(formatItem);

    const checkedFilteredSources = prevFiltersApplied
        ? checkPreviouslyAppliedSectionFilters(filteredSources, prevFiltersApplied, SOURCES_SECTION)
        : filteredSources;

    const sortedCheckedFilteredSources = sortBy(checkedFilteredSources, x => x.name);

    const section = {
        title: SOURCES_SECTION,
        items: sortedCheckedFilteredSources,
    };

    yield put(setSection(section, SOURCES_SECTION));
}

const getTypesAvailable = (searchItems) => {
    const types = [];

    const areThereArticles = !!searchItems.filter(item => item.type === TYPE_ARTICLE).length;
    const areThereGuides = !!searchItems
        .filter(item => isSubtypeOfGuide(item.type))
        .length;

    if (areThereArticles) {
        types.push({ ...articleSelection });
    }

    if (areThereGuides) {
        types.push({ ...guideSelection });
    }

    return types;
};

/**
 * Filter items by type.
 * @param {*} param0
 */
function* filterType({ shouldUpdateFilters }) {
    if (!shouldUpdateFilters) {
        return;
    }

    const searchItems = yield select(state => state.search.items);
    const prevFiltersApplied = yield select(state => state.searchFilter.filtersApplied);

    const types = getTypesAvailable(searchItems);

    const checkedTypes = prevFiltersApplied
        ? checkPreviouslyAppliedSectionFilters(types, prevFiltersApplied, TYPE_SECTION)
        : types;

    const section = { ...initialTypeState, items: [...checkedTypes] };

    yield put(setSection(section, TYPE_SECTION));
}

/**
 * Get see more news page data.
 */
function* seeMoreNews() {
    const query = yield select(state => state.location.query);
    const searchItems = yield select(state => state.search.items);

    const types = getTypesAvailable(searchItems);
    types.find(type => type.id === ARTICLE).checked = true;

    const section = {
        ...initialTypeState,
        items: [...types],
    };

    const newQueryParams = {
        orderBy: null,
        q: query.q,
    };

    yield put(setQueryParams(newQueryParams));
    yield put(setSection(section, TYPE_SECTION));
    yield put(toggleOpen(false));
    yield put(setScrollToTop());
    yield put(applyFilters());
}

/**
 * Get see more practice management data.
 */
function* seeMorePracticeManagement() {
    const query = yield select(state => state.location.query);
    const searchItems = yield select(state => state.search.items);

    const types = getTypesAvailable(searchItems);
    types.find(type => type.id === GUIDE).checked = true;

    const section = {
        ...initialTypeState,
        items: [...types],
    };

    const newQueryParams = {
        orderBy: null,
        q: query.q,
    };

    yield put(setSection(section, TYPE_SECTION));
    yield put(toggleOpen(false));
    yield put(setQueryParams(newQueryParams));
    yield put(setScrollToTop());
    yield put(applyFilters());
}

/**
 * Reset search/list page to first page.
 */
function* changeToFirstPage() {
    yield put(changePage(1));
}

function* searchFilterSaga() {
    yield all([
        takeLatest(GET_TOPICS_SUCCESS, filterTopics),
        takeLatest(GET_SOURCES_SUCCESS, filterSources),
        takeLatest(SEARCH_SUCCESS, filterTopics),
        takeLatest(SEARCH_SUCCESS, filterSources),
        takeLatest(SEARCH_SUCCESS, filterType),
        takeLatest(SEE_MORE_NEWS, seeMoreNews),
        takeLatest(SEE_MORE_PRACTICE_MANAGEMENT, seeMorePracticeManagement),
        takeLatest(APPLY_FILTERS, changeToFirstPage),
    ]);
}

export default searchFilterSaga;
