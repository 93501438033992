import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { kebabCase } from 'lodash';

import {
    accordionHeaderStyles,
    accordionWrapperStyles,
    footerStyles,
    subtitleStyles,
    titleStyles,
    titleWrapperStyles,
} from 'containers/Search/NoResultsAccordion/styles';

import { THEME } from 'components/Accordion/constants';

export const StyledAccordionWrapper = styled.div`
    ${accordionWrapperStyles};
`;

export const StyledAccordionHeader = styled.div`
    ${accordionHeaderStyles};
`;

export const StyledTitleWrapper = styled.div`
    ${titleWrapperStyles};
`;

const StyledTitle = styled.strong`
    ${titleStyles};
`;

const StyledSubtitle = styled.span`
    ${subtitleStyles};
`;

export const StyledFooter = styled.div`
    ${footerStyles};
`;

/**
 * Accordian header for no search results display.
 * @param {*} param0 
 */
const NoResultsAccordion = ({
    showFooter,
    subtitle,
    theme,
    title,
    ...other
}) => (
    <StyledAccordionWrapper {...other}>
        <StyledAccordionHeader
            id={`no-results-accordion-${kebabCase(title)}`}
            theme={theme}
        >
            <StyledTitleWrapper>
                <StyledTitle theme={theme}>{title}</StyledTitle>
                {subtitle && (
                    <React.Fragment>
                        {' '}
                        <StyledSubtitle>
                            {subtitle}
                        </StyledSubtitle>
                    </React.Fragment>
                )}
            </StyledTitleWrapper>
        </StyledAccordionHeader>
        { showFooter && <StyledFooter /> }
    </StyledAccordionWrapper>
);

NoResultsAccordion.propTypes = {
    showFooter: PropTypes.bool,
    subtitle: PropTypes.string,
    theme: PropTypes.oneOf(Object.values(THEME)),
    title: PropTypes.string.isRequired,
};

NoResultsAccordion.defaultProps = {
    showFooter: true,
    subtitle: undefined,
    theme: THEME.DEFAULT,
};

export default NoResultsAccordion;
