import * as type from 'state-management/constants/guide';
import * as saveTypes from 'state-management/constants/save';
import { STANDARD } from 'containers/Guide/guideTypes';
import {
    TOGGLE_SAVE_WEBINAR,
} from 'state-management/constants/save';
import { PL_WAS_VISITED } from 'state-management/constants/articles.common';
import { VISIT_CE_CREDIT } from 'state-management/constants/guide';

const initialState = {
    category: '',
    code: '',
    components: [],
    isLoading: true,
    isRecentLoading: true,
    isSaving: false,
    saved: false,
    title: '',
    type: STANDARD,
    allTools: null,
    guideRecent: {},
    guideTools: {},
    collectionData: null,
    guideLoadingFailed: false,
    collectionLoadingFailed: false,
    toolLoadingFailed: false,
    practiceLabData: []
};

const formatCecreditItem = item => ({
    ...item,
    isSaving: false,
});

const setGuideSaved = (state, guideId, isSaving, saved) =>
    state.guideRecent != null && state.guideRecent.hasOwnProperty('guidesList')
        ? state.guideRecent.guidesList.map((item) =>
            (item.id === guideId)
                ? {
                    ...item,
                    saved: !item.saved,
                    isSaving: false,
                } : item
        ) : [];

const setCollectionGuideSaved = (state, guideId, isSaving, saved) =>
    state.collectionData != null && state.collectionData.hasOwnProperty('guides')
        ? state.collectionData.guides.map((item) =>
            (item.id === guideId)
                ? {
                    ...item,
                    saved: !item.saved,
                    isSaving: false,
                } : item
        ) : [];

const toggleSave = (recentState) => {
    recentState.webinarList[0] = {
        ...recentState.webinarList[0],
        saved: !recentState.webinarList[0].saved,
    }

    return recentState;
}

function guideReducer(state = initialState, action = {}) {
    switch (action.type) {
        case type.CLEAR_GUIDES_AND_COLLECTIONS:
            return initialState;
        case type.SAVE_POLL_ANSWER_SUCCESS: {
            const index = state.components.findIndex(c => c.id === action.pollId);
            const poll = {
                ...state.components[index],
                optionPercentages: action.optionPercentages,
            };
            return {
                ...state,
                components: [
                    ...state.components.slice(0, index),
                    poll,
                    ...state.components.slice(index + 1),
                ],
            };
        }
        case type.GET_TOOLS_SUCCESS:
            return {
                ...state,
                allTools: action.data,
            }
        case type.GET_GUIDE_DATA:
            return {
                ...state,
                isLoading: true,
            };
        case type.GET_GUIDE_DATA_SUCCESS:
            return {
                ...state,
                id: action.data.id,
                category: action.data.category,
                code: action.id,
                components: action.data.components,
                isLoading: false,
                isSaving: false,
                saved: action.data.components[0].saved,
                title: action.data.components[0].articleHeadline,
                topicNames: action.data.topicNames,
                type: action.data.type
            };
        case type.GET_GUIDE_DATA_FAIL:
            return {
                ...state,
                isLoading: false,
            };

        case type.GET_GUIDE_COLLECTIONS_DATA:
            return {
                ...state,
                isLoading: true,
                collectionLoadingFailed: false,
            };
        case type.GET_GUIDE_COLLECTIONS_DATA_SUCCESS:
            return {
                ...state,
                data: action.data,
                isLoading: false,
                collectionLoadingFailed: false,
            };
        case type.GET_GUIDE_COLLECTIONS_DATA_FAIL:
            return {
                ...state,
                isLoading: false,
                collectionLoadingFailed: true,
            };

        case type.GET_GUIDE_RECENT_DATA:
            return {
                ...state,
                isRecentLoading: true,
                guideLoadingFailed: false,
            };
        case type.GET_GUIDE_RECENT_DATA_SUCCESS:
            return {
                ...state,
                guideRecent: action.data,
                isRecentLoading: false,
                guideLoadingFailed: false,
            };
        case TOGGLE_SAVE_WEBINAR:
            if (state.guideRecent.webinarList) {
                return {
                    ...state,
                    guideRecent: toggleSave(state.guideRecent),
                };
            } else {
                return {
                    ...state,
                };
            }
        case type.GET_GUIDE_RECENT_DATA_FAIL:
            return {
                ...state,
                isLoading: false,
                guideLoadingFailed: true,
            };

        case type.GET_GUIDE_TOOLS_DATA:
            return {
                ...state,
                isLoading: true,
                guideLoadingFailed: false,
            };
        case type.GET_GUIDE_TOOLS_DATA_SUCCESS:
            return {
                ...state,
                guideTools: action.data,
                toolLoadingFailed: false,
            };
        case type.GET_GUIDE_TOOLS_DATA_FAIL:
            return {
                ...state,
                isLoading: false,
                toolLoadingFailed: true,
            };
        case type.RESET_GUIDE_DATA:
            return initialState;
        case saveTypes.TOGGLE_SAVE_GUIDE_SUCCESS: {
            return {
                ...state,
                saved: action.save,
                guideRecent: {
                    ...state.guideRecent,
                    guidesList: setGuideSaved(state, action.id, action.save),
                },
                collectionData: {
                    ...state.collectionData,
                    guides: setCollectionGuideSaved(state, action.id, action.save),
                },
            };
        }
        case saveTypes.TOGGLE_SAVE_GUIDE_FAILURE: {
            return state;
        }

        case type.GET_COLLECTION_DATA:
            return {
                ...state,
                isLoading: true,
            };
        case type.GET_COLLECTION_DATA_SUCCESS:
            return {
                ...state,
                collectionData: action.data,
                isLoading: false,
            };
        case type.GET_COLLECTION_DATA_FAIL:
            return {
                ...state,
                isLoading: false,
            };

        case type.GET_CE_CREDITS_LIST:
            return {
                ...state,
                isLoading: true,
                ceLoadingFailed: false,
            };
        case type.GET_CE_CREDITS_LIST_SUCCESS:
            return {
                ...state,
                ceCredits: action.data.map(formatCecreditItem),
                ceCreditsIds: action.data.map(item => item.id),
                seeMore: action.seeMore,
                isLoading: false,
                isLoaded: true,
                ceLoadingFailed: false,
            };
        case type.GET_CE_CREDITS_SUCCESS:
            return {
                ...state,
                ceCredits: action.data.map(formatCecreditItem),
                ceCreditsIds: action.data.map(item => item.id),
                seeMore: action.seeMore,
                isLoading: false,
                isLoaded: true,
                ceLoadingFailed: false,
            };
        case type.GET_CE_CREDITS_LIST_FAIL:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                ceLoadingFailed: true,
            };
        case saveTypes.TOGGLE_SAVE_CECREDIT: {
            let { id, save } = action;
            return {
                ...state,
                ceCredits: state.ceCredits && state.ceCredits.map(item => (item.id === id ? ({
                    ...item,
                    isSaving: true,
                    saved: save,
                }) : item)),
            };
        }
        case saveTypes.TOGGLE_SAVE_CECREDIT_FAILURE: {
            const { id } = action;

            return {
                ...state,
                ceCredits: state.ceCredits && state.ceCredits.map(item => (item.id === id ? ({
                    ...item,
                    isSaving: false,
                    saved: !item.saved,
                }) : item)),
            };
        }
        case saveTypes.TOGGLE_SAVE_CECREDIT_SUCCESS: {
            const { id } = action;
            return {
                ...state,
                ceCredits: state.ceCredits && state.ceCredits.map(item => (item.id === id
                    ? { ...item, isSaving: false } : item)),
            };
        }
        case VISIT_CE_CREDIT: {
            const { id } = action;
            return {
                ...state,
                ceCredits: state.ceCredits.map(item => (item.id === id
                    ? { ...item, visited: true } : item)),
            };
        }
        case type.GET_PRACTICE_LAB:
            return {
                ...state,
                isLoading: true,
            };
        case type.GET_PRACTICE_LAB_SUCCESS:
            return {
                ...state,
                practiceLabData: action.data.content,
                isLoading: false,
                practiceLabFailed: false,
                practiceLabDataHeader: action.data.content.header,
            };
        case type.GET_PRACTICE_LAB_FAIL:
            return {
                ...state,
                isLoading: false,
                practiceLabFailed: true,
            };
        case PL_WAS_VISITED: {
            if (state == null || state.practiceLabData == undefined || state.practiceLabData.length == 0) {
                return {
                    ...state,
                    isLoading: false,
                };
            }
            const cotentItems = state.practiceLabData.contentItems != undefined ? state.practiceLabData.contentItems : state.practiceLabData;
            const { id } = action;
            return {
                ...state,
                practiceLabData: cotentItems.map(item => (item.id === id
                    ? { ...item, visited: true } : item)),
            };
        }
        case saveTypes.TOGGLE_SAVE_PL_ARTICLE: {
            const savedContent = state.practiceLabData.contentItems != undefined ? state.practiceLabData.contentItems : state.practiceLabData;
            const { id, save } = action;

            return {
                ...state,
                practiceLabData: savedContent.map(item => (item.id === id ? ({
                    ...item,
                    isSaving: true,
                    saved: save,
                }) : item)),
            };
        }
        case saveTypes.TOGGLE_SAVE_PL_ARTICLE_FAILURE: {
            const savedContent = state.practiceLabData.contentItems != undefined ? state.practiceLabData.contentItems : state.practiceLabData;
            const { id } = action;

            return {
                ...state,
                practiceLabData: savedContent.map(item => (item.id === id ? ({
                    ...item,
                    isSaving: false,
                    saved: !item.saved,
                }) : item)),
            };
        }
        case saveTypes.TOGGLE_SAVE_PL_ARTICLE_SUCCESS:
            const savedContent = state.practiceLabData.contentItems != undefined ? state.practiceLabData.contentItems : state.practiceLabData;
            const { id } = action;
            return {
                ...state,
                practiceLabData: savedContent.map(item => (item.id === id
                    ? { ...item, isSaving: false } : item)),
            };

        case type.SHOW_LOADER:
            return {
                ...state,
                showLoader: true,
            };

        case type.HIDE_LOADER:
            return {
                ...state,
                showLoader: false,
            };

        default:
            return state;
    }
}

export default guideReducer;
