import * as type from 'state-management/constants/news';
import * as saveTypes from 'state-management/constants/save';
import { ARTICLE_WAS_VISITED } from 'state-management/constants/articles.common';
import { VISIT_CE_CREDIT } from 'state-management/constants/guide';

const initialState = {
    news: [],
    isLoading: false,
    isLoaded: false,
};

const formatNewsItem = item => ({
    ...item,
    isSaving: false,
});

function pageableNewsReducer(state = initialState, action = {}) {
    switch (action.type) {
    case type.GET_PAGEABLE_NEWS:
        return {
            ...state,
            isLoading: true,
        };
    case type.GET_PAGEABLE_NEWS_SUCCESS:
        return {
            ...state,
            news: action.data.map(formatNewsItem),
            isLoading: false,
            isLoaded: true,
        };
    case type.GET_PAGEABLE_NEWS_FAIL:
        return {
            ...state,
            isLoading: false,
            isLoaded: true,
        };
    case saveTypes.TOGGLE_SAVE_ARTICLE: {
        const { id, save } = action;
        return {
            ...state,
            news: state.news.map(item => (item.id === id ? ({
                ...item,
                isSaving: true,
                saved: save,
            }) : item)),
        };
    }
    case saveTypes.TOGGLE_SAVE_ARTICLE_FAILURE: {
        const { id } = action;

        return {
            ...state,
            news: state.news.map(item => (item.id === id ? ({
                ...item,
                isSaving: false,
                saved: !item.saved,
            }) : item)),
        };
    }
    case saveTypes.TOGGLE_SAVE_ARTICLE_SUCCESS: {
        const { id } = action;
        return {
            ...state,
            news: state.news.map(item => (item.id === id
                ? { ...item, isSaving: false } : item)),
        };
    }
    case ARTICLE_WAS_VISITED: {
        const { id } = action;
        return {
            ...state,
            news: state.news.map(item => (item.id === id
                ? { ...item, visited: true } : item)),
        };
    }
    case VISIT_CE_CREDIT: {
        const { id } = action;
        return {
            ...state,
            news: state.news.map(item => (item.id === id
                ? { ...item, visited: true } : item)),
        };
    }
    default:
        return state;
    }
}

export default pageableNewsReducer;
