import { isString } from 'lodash';
import queryString from 'query-string';
import {
    all, call, put, select, takeLatest,
} from 'redux-saga/effects';
import {
    push,
    LOCATION_CHANGE,
} from 'connected-react-router';
import {
    changePage,
    setSearchLoading,
    setSearchSuccess,
    setSearchError,
    close,
} from 'state-management/actions/search';
import { setError } from 'state-management/actions/errors';
import {
    SEARCH_API_URL,
    SEARCH_URL,
    SEARCH_START,
    SEARCH_REDIRECT,
    MIN_QUERY_LENGTH,
    MAX_QUERY_LENGTH,
} from 'state-management/constants/search';
import Api from 'utils/api';
import { trimEncodedTextToSize } from 'utils/urlUtils';
import { resetSearchFilter } from 'state-management/actions/searchFilter';
import { contextHubRecordSearch } from 'utils/contextHub';

/**
 * Get search results.
 * @param {*} action
 */
function* getSearch(action) {
    const value = trimEncodedTextToSize(action.value, MAX_QUERY_LENGTH);

    if (value && value.length >= MIN_QUERY_LENGTH) {
        try {
            yield put(setSearchLoading());
            const response = yield call(Api.get, SEARCH_API_URL, {
                params: { q: value },
            });

            //only fire search analytics if coming from search page
            if(window.location.href.indexOf("/search?") > -1) {
                contextHubRecordSearch(value, response.data.length);
            }

            yield put(setSearchSuccess(response.data, {
                shouldUpdateFilters: action.shouldUpdateFilters,
            }));
            yield put(changePage(1));
        } catch (e) {
            yield put(setSearchError());
            yield put(setError('global.errors.search', e));
        }
    }
}

/**
 * Close search results view.
 * @param {*} action
 */
function* closeSearch(action) {
    const isOpen = yield select(state => state.search.isOpen);
    if (action.payload.pathname !== SEARCH_URL && isOpen) {
        yield put(close());
    }
}

/**
 * Handle redirects to search page.
 * @param {*} param0
 */
function* searchRedirect({ value, resetFilters }) {
    const searchString = isString(value) ? value : value.q;
    const params = isString(value) ? {} : value;
    const encodedText = encodeURIComponent(searchString);
    params.q = trimEncodedTextToSize(encodedText, MAX_QUERY_LENGTH);

    const url = `${SEARCH_URL}?${queryString.stringify(params, { encode: false })}`;

    if (resetFilters) {
        yield put(resetSearchFilter());
    }

    yield put(push(url));
}

function* searchSaga() {
    yield all([
        yield takeLatest(SEARCH_START, getSearch),
        yield takeLatest(LOCATION_CHANGE, closeSearch),
        yield takeLatest(SEARCH_REDIRECT, searchRedirect),
    ]);
}

export default searchSaga;
