import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET, SMALL_MOBILE, FROM_MOBILE_TO_TABLET } from '../../../utils/mediaQueries';
import {
    COLORS,
    FONTS,
} from '../../../utils/variables';

export const blankAreaStyles = css`
    width: 100%;
    background: ${COLORS.WHITE};
    height: 342px;
    position: relative;

    @media screen and ${FROM_TABLET} {
        height: 310px;
    }

    @media screen and ${FROM_DESKTOP} {
        height: 404px;
    }
`;

export const skinnyListContainer = css`
    margin-left: 16px;
    margin-right: 16px;
`;

export const loaderStyles = css`
    position: absolute;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
    height: 50px;
    width: 50px;

    @media screen and ${FROM_TABLET} {
        width: 80px;
        height: 80px;
        left: calc(50% - 40px);
        top: calc(50% - 40px);
    }

    @media screen and ${FROM_DESKTOP} {
        width: 100px;
        height: 100px;
        left: calc(50% - 50px);
        top: calc(50% - 50px);
    }
`;

export const headerStyles = `{
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 26px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.23;
    letter-spacing: 0;
    color:${COLORS.SAPPHIRE};
    padding-top: 32px;
    padding-bottom: 24px;
    font-size: 20px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.3;
    letter-spacing: 0;
    padding-right: 16px;

    @media screen and ${FROM_MOBILE_TO_TABLET} {
        display: flex;
    }

    div:first-child {
        display: inline-block;
        left: 0;
        top: 12px;
        height: 4px;
        width: calc(((100vw - 1170px) / 2) - 25px);
        min-width: 40px;
        background-image: linear-gradient(89deg, ${COLORS.SAPPHIRE}, ${COLORS.OCEAN});
        margin-right: 10px;
        margin-bottom: 5px;

        @media screen and ${FROM_MOBILE_TO_TABLET} {
            margin-top: 10px;
        }
    }

    div:last-child {
        position: absolute;
        display: inline-block;

        @media screen and ${FROM_MOBILE_TO_TABLET} {
            position: unset;
        }
    }

    @media screen and ${FROM_TABLET} {
        padding-top: 50px;
        padding-bottom: 28px;
        font-size: 20px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.3;
        letter-spacing: 0;
        padding-right: 0;

        div:first-child {
            display: inline-block;
            left: 0;
            top: 12px;
            height: 4px;
            width: calc(((100vw - 1170px) / 2) - 25px);
            min-width: 40px;
            background-image: linear-gradient(89deg, ${COLORS.SAPPHIRE}, ${COLORS.OCEAN});
            margin-right: 10px;
            margin-bottom: 5px;
        }

        div:last-child {
            position: absolute;
            display: inline-block;
        }
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 26px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.23;
        letter-spacing: 0;

        div:first-child {
            display: inline-block;
            left: 0;
            top: 12px;
            height: 4px;
            width: calc(((100vw - 1170px) / 2) - 25px);
            min-width: 40px;
            background-image: linear-gradient(89deg, ${COLORS.SAPPHIRE}, ${COLORS.OCEAN});
            margin-right: 20px;
            margin-bottom: 5px;
        }

        div:last-child {
            position: absolute;
            display: inline-block;
        }
    }
}`;

export const linkContainerStyles = `
    text-align: right;
    padding-top: 0;
    padding-bottom: 32px;

    @media screen and ${FROM_TABLET} {
        padding-top: 7px;
        padding-bottom: 40px;
    }
`;

export const linkStyles = `
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    white-space: nowrap;
    text-align: center;
    text-decoration:none;
    transition: 0.66s all;

    svg {
        height: 12px;
        margin-left: 6px;
        position: relative;
        stroke: ${COLORS.DARK_GRAY2};
        top: -1px;
        width: 12px;
    }

    &>a {
        display: block;
    }

    &:hover {
        color: ${COLORS.MEDIUM_GRAY2};
        svg{
            stroke: ${COLORS.MEDIUM_GRAY2};
        }
    }

    @media screen and ${FROM_TABLET} {
        text-align: right;
        background-color: transparent;
        border-bottom: none;

        &>a {
            display: inline-block;
        }
    }
`;

export const buttonContainerStyles = css`
    text-align: center;
    border-bottom: 1px solid ${COLORS.LIGHT_GRAY2};
    z-index: 10;
    margin-bottom: 0;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 14px;
    padding-bottom: 32px;

    &>a {
        display: block;
    }

    @media screen and ${SMALL_MOBILE} {
        margin-bottom: 55px;
    }

    @media screen and ${FROM_TABLET} {
        text-align: right;
        background-color: transparent;
        border-bottom: none;
        padding-left: 0;
        padding-right: 0;

        &>a {
            display: inline-block;
        }
    }
`;

export const guideCardStripStyles = css`

    @media screen and ${SMALL_MOBILE} {
        padding-bottom:0;
    }
`;
