import { css } from 'styled-components';

import { StyledList } from 'components/CardsGrid/CardsGrid';
import { FROM_TABLET, FROM_DESKTOP } from 'utils/mediaQueries';
import {
    COLORS,
} from 'utils/variables';

export const resultContainerStyles = css`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding-top: 20px;
    max-width: 100%;
    width: 100%;
    padding-right: 16px !important;
    padding-left: 16px !important;


    @media screen and ${FROM_TABLET} {
        padding-right: 36px !important;
        padding-left: 36px !important;
        margin-bottom: 60px;
        padding-top: 23px;

    }
    @media screen and ${FROM_DESKTOP} {
        padding-right: 48px !important;
        padding-left: 48px !important;
        padding-top: 44px;
    }

    @media (min-width: 1281px) {
        max-width: 1266px;
        padding-right: 48px !important;
        padding-left: 48px !important;
    }

    /* stylelint-disable */
    ${StyledList} {
        padding-top: 9px;
    }
    /* stylelint-enable */
`;
