import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import anime from 'animejs';
import ToastSwitch from 'containers/Toasts/ToastsSwitch/ToastsSwitch';
import { toastsContainerStyles } from 'containers/Toasts/styles';

const StyledToastsContainer = styled.div`
    ${toastsContainerStyles};
`;

const SLIDE_ANIMATION_TIME = 660;
const SLIDEOUT_ANIMATION_DELAY = 3600;

/**
 * Global toast wrapper.
 */
class Toasts extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.containerRef = React.createRef();
        this.willAnimate = false;
    }

    /**
     * Animate when full datas has been aquired.
     * @param {*} nextProps 
     * @param {*} nextState 
     */
    componentWillUpdate(nextProps, nextState) {
        this.willAnimate = (nextProps.toasts !== this.props.toasts && nextProps.toasts.length);
    }

    /**
     * Animate toast in and out of view.
     */
    componentDidUpdate() {
        if (!this.willAnimate) {
            return;
        }
        
        const duration = SLIDE_ANIMATION_TIME;
        const node = this.containerRef.current;

        delete this.animation;
        delete this.animationOut;

        this.animation = anime.timeline();
        this.animationOut = anime.timeline();

        clearTimeout(this.animateOutTimer);

        this.animation.add({
            targets: node,
            easing: [0.53, 0.05, 0.01, 0.97],
            opacity: [0, 1],
            translateY: [50, 0],
            duration,
            complete: () => {
                this.animateOutTimer = setTimeout(() => {
                    this.animationOut.add({
                        targets: node,
                        easing: 'easeInOutQuad',
                        opacity: [1, 0],
                        translateY: [0, 50],
                        duration,
                    });
                }, SLIDEOUT_ANIMATION_DELAY);
            },
        });
    }

    /**
     * Render toast container and underlying toast components.
     */
    render() {
        const {
            className,
            id,
            isLoaded,
            toasts,
        } = this.props;

        return (
            <StyledToastsContainer
                className={className}
                id={id}
                ref={this.containerRef}
            >
                {
                    isLoaded && toasts.map((toast, idx) => {
                        const key = `${id}-${idx}`;

                        return (
                            <ToastSwitch
                                key={key}
                                toast={toast}
                            />
                        );
                    })
                }
            </StyledToastsContainer>
        );
    };
};

Toasts.propTypes = {
    className: PropTypes.string,
    id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    isLoaded: PropTypes.bool,
    toasts: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({
            toastProps: PropTypes.shape({}),
            toastType: PropTypes.string,
        }),
        PropTypes.string,
    ])),
};

Toasts.defaultProps = {
    className: '',
    id: 'toasts',
    isLoaded: false,
    toasts: [],
};

const mapStateToProps = state => ({
    isLoaded: state.toasts.isLoaded,
    toasts: state.toasts.toasts,
});

export default connect(mapStateToProps)(Toasts);
