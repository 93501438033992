import { css } from 'styled-components';
import {
    COLORS,
    FONTS,
    ANIMATIONS,
} from 'utils/variables';
import {
    FROM_DESKTOP,
} from 'utils/mediaQueries';

export const titleStyles = css`
    display: inline-block;
    color: ${COLORS.BLACK};
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.1px;
    line-height: 26px;
    margin: 0;
    margin-bottom: 36px;
    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.TITLE.TIME.SECONDS}s all;
    height: 131px;

    @media screen and ${FROM_DESKTOP} {
        font-size: 26px;
        line-height: 32px;
    }

    &:hover {
        color: ${COLORS.MEDIUM_GRAY2};
    }

    ${props => props.visited && css`
        color: ${COLORS.MEDIUM_GRAY2};

        &:hover {
            color: ${COLORS.BLACK};
        }
    `};
`;

export const imageContainerStyles = css`
    display: block;
    overflow: hidden;
    height: 290px;
    background-image: linear-gradient(to left, ${COLORS.OCEAN}, ${COLORS.SAPPHIRE} 50%);
    
    @media screen and ${FROM_DESKTOP} {
        height: 322px;
    }

    ${props => props.visited && css`
        img {
            opacity: 0.6;
        }
    `};
`;

export const containerStyles = css`
    background: ${COLORS.WHITE};
    color: ${COLORS.BLACK};
    display: inline-block;
    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.CONTAINER.TIME.SECONDS}s all;
    position: relative;

    &:hover {
        img {
            transform: scale3d(1.05,1.05,1);
        }
    }
    
    img {
        margin-bottom: -4px;
        object-fit: cover;
        transition: ${ANIMATIONS.ARTICLES.IMAGE.TIME.SECONDS}s all;
        width: 100%;
    }

    .kebab__trigger {
        right: 21px;
        bottom: 17px;
    }
`;

export const dateAndTopicContainerStyles = css`
    display: flex;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-bottom: 16px;
    text-transform: uppercase;
    white-space: nowrap;

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 22px;
    }
`;

export const dateContainerStyles = css`
    color: ${COLORS.DARK_GRAY1}
`;

export const topicLinkStyles = css`
    color: ${COLORS.SAPPHIRE};
    flex: 1;
    text-align: right;
    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.TOPIC.TIME.SECONDS}s all;
   
    &:hover {
        color: ${COLORS.DARK_SAPPHIRE};
    }
`;

export const imageStyles = css`
    height: auto;
    margin-bottom: -4px;
    object-fit: cover;
    transition: ${ANIMATIONS.ARTICLES.IMAGE.TIME.SECONDS}s all;
    width: 100%;
`;

export const contentContainerStyles = css`
    padding: 21px 21px 19px 21px;
`;
