const LOCAL_PART_ATOM = '[a-z0-9!#$%&\'*+/=?^_`{|}~\u0080-\uFFFF-]';
const LOCAL_PART_INSIDE_QUOTES_ATOM = '([a-z0-9!#$%&\'*.(),<>\\[\\]:;  @+/=?^_`{|}~\u0080-\uFFFF-]|\\\\\\\\|\\\\\\")';

const DOMAIN_CHARS_WITHOUT_DASH = '[a-z\u0080-\uFFFF0-9!#$%&\'*+/=?^_`{|}~]';
const DOMAIN_LABEL = `(${DOMAIN_CHARS_WITHOUT_DASH}-*)*${DOMAIN_CHARS_WITHOUT_DASH}+`;
const DOMAIN = `${DOMAIN_LABEL}(\\.${DOMAIN_LABEL})*`;
const IP_DOMAIN = '[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}';
const IP_V6_DOMAIN = '(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))';

export const emailLocalPartRegex = new RegExp(
    `^(${LOCAL_PART_ATOM}+|"${LOCAL_PART_INSIDE_QUOTES_ATOM}+")(\\.(${LOCAL_PART_ATOM}+|"${LOCAL_PART_INSIDE_QUOTES_ATOM}+"))*$`,
    'i',
);
export const emailDomainPartRegex = new RegExp(
    `^(${DOMAIN}|\\[${IP_DOMAIN}\\]|\\[IPv6:${IP_V6_DOMAIN}\\])$`,
    'i',
);
export const zipCodeRegex = /^\d{5}$/;
export const phoneNumberRegex = /^([+]?1[\s-]?)?((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/;
