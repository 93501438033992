import React from 'react';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { IconLogoText, IconEmblem, IconPoweredBy, LuminLogoIcon } from 'components/atomics/atoms/Icons/Icons';
import {
    logoContainerStyles,
    logoIconStyles,
    emblemIconStyles,
    poweredByIconStyles,
    luminIconStyles
} from 'components/Logo/styles';

export const StyledLogoContainer = styled.span`
    ${logoContainerStyles};
`;

export const StyledLogoIcon = styled(IconLogoText)`
    ${logoIconStyles};
`;

export const StyledEmblemIcon = styled(IconEmblem)`
    ${emblemIconStyles};
`;

export const StyledPoweredByIcon = styled(IconPoweredBy)`
    ${poweredByIconStyles};
`;

export const StyledLuminLogoIcon = styled(LuminLogoIcon)`
    ${luminIconStyles};
`;

export class Logo extends React.Component {
    translate = (id, values = {}) => {
        const { intl: { formatMessage } } = this.props;
        return formatMessage({ id }, values);
    }

    render() {
        return (
            <StyledLogoContainer>
                <StyledLuminLogoIcon alt={this.translate('accessible.logo')} />
            </StyledLogoContainer>
        );
    }
}

export default injectIntl(Logo);
