import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';

const StyledTime = styled.time`
    white-space: nowrap;
`;

class TimeAgo extends React.Component {
    getRelativeDate(fromDate, toDate) {
        const { dateFormat } = this.props;
        const fromMoment = moment.isMoment(fromDate) ? fromDate : moment(fromDate);
        const toMoment = moment.isMoment(toDate) ? toDate : moment(toDate);

        const daysDiff = toMoment.diff(fromMoment, 'days');

        if (daysDiff > 3) {
            return fromMoment.format(dateFormat);
        }

        if (daysDiff >= 1) {
            return this.getTranslation('timeAgo.days', {
                days: daysDiff,
            });
        }

        const hoursDiff = toMoment.diff(fromMoment, 'hours');

        if (hoursDiff >= 1) {
            return this.getTranslation('timeAgo.hours', {
                hours: hoursDiff,
            });
        }

        const minutesDiff = toMoment.diff(fromMoment, 'minutes');

        if (minutesDiff >= 20) {
            return this.getTranslation('timeAgo.minutes', {
                minutes: minutesDiff,
            });
        }

        return this.getTranslation('timeAgo.now');
    }

    getTranslation(id, params = {}) {
        const { intl: { formatMessage } } = this.props;

        return formatMessage({
            id,
        }, params);
    }

    render() {
        const { className, fromDate, toDate } = this.props;
        const from = moment(fromDate);
        const to = toDate ? moment(toDate) : moment();
        const dateTime = from.format('YYYY-MM-DD hh:mm');

        return (
            <StyledTime className={className} dateTime={dateTime}>
                {this.getRelativeDate(from, to)}
            </StyledTime>
        );
    }
}

TimeAgo.propTypes = {
    className: PropTypes.string,
    dateFormat: PropTypes.string,
    fromDate: PropTypes.string.isRequired,
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    toDate: PropTypes.string,
};

TimeAgo.defaultProps = {
    className: '',
    dateFormat: 'MMM DD, YYYY',
    toDate: null,
};

export default injectIntl(TimeAgo);
