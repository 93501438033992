import {
    GET_SAVED_CONTENT_DATA,
    GET_SAVED_CONTENT_FAIL,
    SET_SAVED_CONTENT_DATA,
    TOGGLE_SAVE_ARTICLE,
    TOGGLE_SAVE_ARTICLE_FAIL,
    TOGGLE_SAVE_ARTICLE_SUCCESS,
    TOGGLE_SAVE_CECREDIT,
    TOGGLE_SAVE_CECREDIT_FAILURE,
    TOGGLE_SAVE_CECREDIT_SUCCESS,
    TOGGLE_SAVE_PL_ARTICLE,
    TOGGLE_SAVE_PL_ARTICLE_FAILURE,
    TOGGLE_SAVE_PL_ARTICLE_SUCCESS,
} from 'state-management/constants/savedContent';

export const getSavedContentData = ({ page }, shouldScrollToTop = true) => ({
    type: GET_SAVED_CONTENT_DATA,
    page,
    shouldScrollToTop,
});

export const setSavedContentData = savedContentData => ({
    type: SET_SAVED_CONTENT_DATA,
    data: savedContentData,
});

export const getSavedContentFail = {
    type: GET_SAVED_CONTENT_FAIL,
};

export const toggleSaveArticle = (articleId, save) => ({
    type: TOGGLE_SAVE_ARTICLE,
    articleId,
    save,
});

export const toggleSaveArticleSuccess = articleId => ({
    type: TOGGLE_SAVE_ARTICLE_SUCCESS,
    articleId,
});

export const toggleSaveArticleFailure = (error, articleId, save) => ({
    type: TOGGLE_SAVE_ARTICLE_FAIL,
    error,
    articleId,
    save,
});

export const toggleSaveCecredit = (articleId, save) => ({
    type: TOGGLE_SAVE_CECREDIT,
    articleId,
    save,
});


export const toggleSaveCecreditSuccess = articleId => ({
    type: TOGGLE_SAVE_CECREDIT_SUCCESS,
    articleId,
});

export const toggleSaveCecreditFailure = (error, articleId, save) => ({
    type: TOGGLE_SAVE_CECREDIT_FAILURE,
    error,
    articleId,
    save,
});

export const toggleSavePlArticle = (articleId, save) => ({
    type: TOGGLE_SAVE_PL_ARTICLE,
    articleId,
    save,
});

export const toggleSavePlArticleSuccess = articleId => ({
    type: TOGGLE_SAVE_PL_ARTICLE_SUCCESS,
    articleId,
});

export const toggleSavePlArticleFailure = (error, articleId, save) => ({
    type: TOGGLE_SAVE_PL_ARTICLE_FAILURE,
    error,
    articleId,
    save,
});
