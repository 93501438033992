import { merge } from 'lodash';
import {
    EVENT_ACTION_ADD_TO_SAVES,
    EVENT_ACTION_PAGE_VIEW,
    EVENT_ACTION_REMOVE_FROM_SAVES,
    EVENT_ACTION_SEARCH_PAGE_VIEW,
    PAGE_NAME_NEWS,
    PAGE_NAME_SAVES,
    PAGE_NAME_SEARCH,
    SITE_SECTION_GUIDE,
    SITE_SECTION_NEWS,
    PAGE_NAME_GUIDE,
    EVENT_ACTION_GUIDE_NAV_PAGE_VIEW,
    EVENT_ACTION_READ_EXTERNAL_CONTENT,
    PAGE_NAME_SIGN_UP,
    SITE_SECTION_SIGN_UP,
    SITE_SECTION_SEARCH,
    EVENT_ACTION_SIGN_UP_START,
    EVENT_ACTION_SIGN_UP_FAILED,
    PAGE_NAME_SIGN_IN,
    SITE_SECTION_SIGN_IN,
    EVENT_ACTION_SIGN_IN_PAGE_VIEW,
    EVENT_ACTION_SIGN_UP_SUCCESS,
    EVENT_ACTION_BLOOMBERGTV,
} from 'utils/analyticsConstants';



export const EVENT_VIEW_START = 'event-view-start';
export const EVENT_VIEW_END = 'event-view-end';
export const EVENT_ACTION_TRIGGER = 'event-action-trigger';

export const dispatchEvent = (eventName, data = {}) => {
    window.digitalData = data;
    document.dispatchEvent(new CustomEvent(eventName, data));
};


let aeid;

export const getAEID = () => aeid;

export const setAEID = (profileAEID) => {
    aeid = profileAEID;
};

export const removeAEID = () => {
    aeid = null;
};

export const getAnalyticsArticleData = (article, pageName) => ({
    analyticsPageName: pageName,
    sourceNames: article.sourceTitle ? [article.sourceTitle] : [],
    topicNames: article.topics ? article.topics.map(topic => topic.name) : [],
    title: article.title,
});

export const getAnalyticsGuideData = (guide, pageName) => ({
    analyticsPageName: pageName,
    topicNames: guide.topicNames,
    title: guide.title,
});

export const getAnalyticsNewsData = article => getAnalyticsArticleData(
    article,
    PAGE_NAME_NEWS,
);
export const getAnalyticsArticleSavesData = article => getAnalyticsArticleData(
    article,
    PAGE_NAME_SAVES,
);
export const getAnalyticsGuideSavesData = guide => getAnalyticsGuideData(
    guide,
    PAGE_NAME_SAVES,
);
export const getAnalyticsArticleSearchData = article => getAnalyticsArticleData(
    article,
    PAGE_NAME_SEARCH,
);
export const getAnalyticsGuideSearchData = guide => getAnalyticsGuideData(
    guide,
    PAGE_NAME_SEARCH,
);

export const getPageData = (pageName, siteSection) => ({
    page: {
        pageInfo: {
            pageName,
            siteSection,
        },
    },
});

export const getPageDataScroll = (pageName, siteSection, pageNameProcentage) => ({
    page: {
        pageInfo: {
            pageName,
            siteSection,
            percentOfPageViewed: pageNameProcentage,
        },
    },
});

export const getEventData = eventAction => ({
    eventHolder: {
        eventInfo: {
            eventName: eventAction,
        },
    },
});

export const getMediaEventData = eventAction => ({
    mediaHolder: {
        mediaInfo: {
            mediaName: eventAction,
        },
    },
});

export const getGuidePageData = (guideCode) => {
    const data = getPageData(PAGE_NAME_GUIDE(guideCode), SITE_SECTION_GUIDE);
    merge(data, getEventData(EVENT_ACTION_GUIDE_NAV_PAGE_VIEW));
    return data;
};

export const getSearchData = (searchTerm, itemsCount) => {
    const data = getPageData(PAGE_NAME_SEARCH, SITE_SECTION_SEARCH);
    data.page.pageInfo.onsiteSearchTerm = searchTerm;
    if (itemsCount != null) {
        data.page.pageInfo.onsiteSearchResult = itemsCount;
    }
    merge(data, getEventData(EVENT_ACTION_SEARCH_PAGE_VIEW));
    return data;
};

export const getSignInPageData = () => {
    const data = getPageData(PAGE_NAME_SIGN_IN, SITE_SECTION_SIGN_IN);
    merge(data, getEventData(EVENT_ACTION_SIGN_IN_PAGE_VIEW));
    return data;
};

export const getCommonData = () => ({
    page: {
        pageInfo: {
            cleanURL: window.location.href,
            server: __ENVIRONMENT_CONFIG__.ADOBE_ANALYTICS_SERVER,
            previousUrl: window.previousUrl,
        },
        category: {
            siteName: 'lumin:us:en',
        },
    },
    user: {
        info: {
            advisorId: getAEID(),
        },
    },
    mediaHolder: {
        mediaInfo: {
            mediaName: 'session',
        },
    },
});

function getEventDataWithComponent(
    eventAction,
    componentID,
    componentName,
    componentTopics,
    componentSources,
) {
    const data = getEventData(eventAction);
    if (componentID || componentName) {
        data.component = [{
            componentInfo: {
                componentID,
                componentName,
                componentTopics,
                componentSources,
            },
        }];
    }
    return data;
}

const dispatchViewEvent = (eventName, analyticsPageData) => {
    const data = getCommonData();
    const pageData = {
        ...getEventData(EVENT_ACTION_PAGE_VIEW),
        ...analyticsPageData,
    };
    merge(data, pageData);
    dispatchEvent(eventName, data);
};

export const dispatchViewStart = (analyticsPageData) => {
    dispatchViewEvent(EVENT_VIEW_START, analyticsPageData);
};

export const dispatchViewEnd = (analyticsPageData) => {
    dispatchViewEvent(EVENT_VIEW_END, analyticsPageData);
};

export const dispatchPageViewStart = (pageName, siteSection) => {
    dispatchViewStart(getPageData(pageName, ));
};

export const dispatchPageViewStartScroll = (pageName, siteSection, pageNameProcentage) => {
    dispatchViewStart(getPageDataScroll(pageName, siteSection, pageNameProcentage));
};

export const dispatchPageViewEnd = (pageName, siteSection) => {
    dispatchViewEnd(getPageData(pageName, siteSection));
};

export const dispatchActionTrigger = (
    analyticsData,
    eventAction,
    componentId,
    componentName,
    componentTopics,
    componentSources,
) => {
    const data = getCommonData();
    const eData = getEventDataWithComponent(
        eventAction,
        componentId,
        componentName,
        componentTopics,
        componentSources,
    );
    merge(data, eData);
    merge(data, analyticsData);
    dispatchEvent(EVENT_ACTION_TRIGGER, data);
};

export const dispatchPageActionTrigger = (
    pageName,
    siteSection,
    eventAction,
    componentId,
    componentName,
    componentTopics,
    componentSources,
) => {
    const pageData = getPageData(pageName, siteSection);
    dispatchActionTrigger(
        pageData,
        eventAction,
        componentId,
        componentName,
        componentTopics ? componentTopics.join(',') : '',
        componentSources ? componentSources.join(',') : '',
    );
};

export const dispatchSignUpFailed = (errorType) => {
    dispatchPageActionTrigger(
        PAGE_NAME_SIGN_UP,
        SITE_SECTION_SIGN_UP,
        EVENT_ACTION_SIGN_UP_FAILED,
        errorType,
    );
};

export const dispatchSignUpStart = () => {
    dispatchPageActionTrigger(
        PAGE_NAME_SIGN_UP,
        SITE_SECTION_SIGN_UP,
        EVENT_ACTION_SIGN_UP_START,
    );
};

export const dispatchSignUpSuccess = () => {
    const data = getCommonData();
    const pageData = getPageData(PAGE_NAME_SIGN_UP, SITE_SECTION_SIGN_UP);
    const eData = getEventData(EVENT_ACTION_SIGN_UP_SUCCESS);

    merge(data, eData);
    merge(data, pageData);

    dispatchEvent(EVENT_ACTION_TRIGGER, data);
};

export const dispatchGuideAddToSaves = (pageName, guideId, title, topics, sources) => {
    dispatchPageActionTrigger(
        pageName,
        SITE_SECTION_GUIDE,
        EVENT_ACTION_ADD_TO_SAVES,
        guideId,
        title,
        topics,
        sources,
    );
};

export const dispatchGuideRemoveFromSaves = (pageName, guideId, title, topics, sources) => {
    dispatchPageActionTrigger(
        pageName,
        SITE_SECTION_GUIDE,
        EVENT_ACTION_REMOVE_FROM_SAVES,
        guideId,
        title,
        topics,
        sources,
    );
};

export const dispatchReadExternalContent = ({
    analyticsPageName,
    id,
    title,
    topics,
    sources,
}) => {
    dispatchPageActionTrigger(
        analyticsPageName,
        analyticsPageName.substring(analyticsPageName.lastIndexOf(':') + 1),
        EVENT_ACTION_READ_EXTERNAL_CONTENT,
        id,
        title,
        topics,
        sources,
    );
};

export const dispatchArticleAddToSaves = (pageName, guideId, title, topics, sources) => {
    dispatchPageActionTrigger(
        pageName,
        SITE_SECTION_NEWS,
        EVENT_ACTION_ADD_TO_SAVES,
        guideId,
        title,
        topics,
        sources,
    );
};

export const dispatchArticleRemoveFromSaves = (pageName, guideId, title, topics, sources) => {
    dispatchPageActionTrigger(
        pageName,
        SITE_SECTION_NEWS,
        EVENT_ACTION_REMOVE_FROM_SAVES,
        guideId,
        title,
        topics,
        sources,
    );
};

export const dispatchMediaActionTrigger = (
    pageName,
    siteSection,
    eventAction,
) => {
    const pageData = getPageData(pageName, siteSection);
    dispatchActionTrigger(
        pageData,
        eventAction,
    );
};

export const dispatchBloombergTVevent = (eventName) => {
    const data = getCommonData();
    merge(data, getMediaEventData(EVENT_ACTION_BLOOMBERGTV));
    dispatchEvent(eventName, data);
};

export const recordClick = (arg) => {
    // refactor
};

export const recordComponentClick = (arg, componentType = 'article') => {
    // refactor
};

