import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import SearchFilterSection from 'containers/Search/SearchFilterSection/SearchFilterSection';
import {
    DATE_SECTION, SOURCES_SECTION, TOPICS_SECTION, TYPE_SECTION,
} from 'state-management/constants/searchFilter';
import DateSearchFilterSection from 'containers/Search/DateSearchFilterSection/DateSearchFilterSection';
import Button from 'components/atomics/atoms/Button/Button';
import {
    clearAllButtonStyles,
    columnStyles,
    wrapperStyles,
    containerStyles, doneButtonStyles,
    footerStyles,
} from 'containers/Search/SearchFilters/styles';

const CONTAINER_MIN_HEIGHT = 240;

const CONTAINER_DISTANCE_FROM_BOTTOM = 285;

const CONTAINER_CONTROLS_HEIGHT = 76;

const StyledContainer = styled.div`
    ${containerStyles}
`;

const StyledWrapper = styled.div`
    ${wrapperStyles}
`;

const StyledColumn = styled.div`
    ${columnStyles}
`;

const StyledFooter = styled.div`
    ${footerStyles}
`;

const StyledClearAllButton = styled(Button)`
    ${clearAllButtonStyles}
`;

const StyledDoneButton = styled(Button)`
    ${doneButtonStyles}
`;

class DesktopSearchFilters extends React.Component {
    /**
     * Gets the proper filter container (scrollable) height considering minimum height and current window viewport
     * and scroll position.
     */
    getContainerHeight() {
        if (this.props.buttonRef.current === null) {
            return null;
        }

        const windowHeight = window.innerHeight;
        const bottom = this.props.buttonRef.current.getBoundingClientRect().bottom;
        const availableSpace = ((windowHeight - bottom) - CONTAINER_DISTANCE_FROM_BOTTOM) - CONTAINER_CONTROLS_HEIGHT;

        if (availableSpace < CONTAINER_MIN_HEIGHT) {
            return CONTAINER_MIN_HEIGHT;
        }

        return availableSpace;
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const {
            clearAll,
            clearSection,
            handleDone,
            isDateSectionStateInitial,
            resetSection,
            sections,
            selectedFiltersCount,
            toggleDateItem,
            toggleItem,
        } = this.props;

        const containerHeight = this.getContainerHeight()

        return (
            <StyledWrapper>
                <StyledContainer
                    id='searchFilterContainerItem'
                    height={containerHeight}
                >
                    <StyledColumn>
                        <SearchFilterSection
                            section={sections[TYPE_SECTION]}
                            onClear={clearSection}
                            onToggleItem={toggleItem}
                        />
                        <DateSearchFilterSection
                            isInitialState={isDateSectionStateInitial}
                            section={sections[DATE_SECTION]}
                            onClear={resetSection}
                            onToggleItem={toggleDateItem}
                        />
                    </StyledColumn>
                    <StyledColumn>
                        <SearchFilterSection
                            noResultsTranslationId="search.filters.sections.topics.noResults"
                            section={sections[TOPICS_SECTION]}
                            onClear={clearSection}
                            onToggleItem={toggleItem}
                        />
                    </StyledColumn>
                    <StyledColumn>
                        <SearchFilterSection
                            noResultsTranslationId="search.filters.sections.sources.noResults"
                            section={sections[SOURCES_SECTION]}
                            onClear={clearSection}
                            onToggleItem={toggleItem}
                        />
                    </StyledColumn>
                </StyledContainer>
                <StyledFooter>
                    {!!selectedFiltersCount && (
                        <StyledClearAllButton
                            id="search-filter-clear-all"
                            onClick={clearAll}
                            type="type/ghost"
                        >
                            <FormattedMessage id="search.filters.clearAll" />
                        </StyledClearAllButton>
                    )}
                    <StyledDoneButton
                        onClick={handleDone}                        
                        id="search-filter-done"
                        type="type/secondayMedium"
                    >
                        <FormattedMessage id="search.filters.done" />
                    </StyledDoneButton>
                </StyledFooter>
            </StyledWrapper>
        );
    }
}

DesktopSearchFilters.propTypes = {
    clearAll: PropTypes.func.isRequired,
    clearSection: PropTypes.func.isRequired,
    handleDone: PropTypes.func.isRequired,
    isDateSectionStateInitial: PropTypes.bool.isRequired,
    resetSection: PropTypes.func.isRequired,
    sections: PropTypes.shape({
        date: PropTypes.shape({}),
        sources: PropTypes.shape({}),
        topics: PropTypes.shape({}),
        type: PropTypes.shape({}),
    }).isRequired,
    selectedFiltersCount: PropTypes.number.isRequired,
    toggleDateItem: PropTypes.func.isRequired,
    toggleItem: PropTypes.func.isRequired,
    buttonRef: PropTypes.object,
};

export default DesktopSearchFilters;
