import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ArrowPaginationIcon } from 'components/atomics/atoms/Icons';
import {
    footerContentStyles,
    footerContentCategoryStyles,
    footerPointerStyles,
    footerContentTitleStyles,
    footerSideStyles,
    footerPointerArrowStyles,
} from 'containers/Guide/Footer/styles';

const StyledFooterSide = styled.div`
    ${footerSideStyles}
`;
const StyledFooterPointer = styled.span`
    ${footerPointerStyles}
`;
export const StyledFooterContent = styled.div`
    ${footerContentStyles}
`;
export const StyledFooterContentCategory = styled.div`
    ${footerContentCategoryStyles}
`;
export const StyledFooterContentTitle = styled.div`
    ${footerContentTitleStyles}
`;
const StyledArrowIcon = styled(ArrowPaginationIcon)`
    ${footerPointerArrowStyles}
`;

const FooterSide = ({
    pointerText, category, title, ...other
}) => (
    <StyledFooterSide {...other}>
        <StyledFooterPointer {...other}>
            <StyledArrowIcon
                height={13}
                width={17}
                {...other}
            />
            <FormattedMessage id={pointerText} />
        </StyledFooterPointer>
        <StyledFooterContent>
            <StyledFooterContentCategory>
                {category}
            </StyledFooterContentCategory>
            <StyledFooterContentTitle>
                {title}
            </StyledFooterContentTitle>
        </StyledFooterContent>
    </StyledFooterSide>
);

FooterSide.propTypes = {
    pointerText: PropTypes.string.isRequired,
    category: PropTypes.string,
    title: PropTypes.string,
};

FooterSide.defaultProps = {
    category: '',
    title: '',
};

export default FooterSide;
