import { css } from 'styled-components';
import {
    COLORS,
    FONTS,
} from 'utils/variables';
import {
    FROM_DESKTOP,
    FROM_TABLET,
} from 'utils/mediaQueries';

export const PROVIDER_LEXIS = 'LEXIS_NEXIS';
const CAPITAL_IDEAS = "Capital Ideas";
const CE_CREDITS = "CE Credits";
const PRACTICE_LAB = "Practice Lab";

export const sourceLinkDisabled = css`
    color: ${COLORS.MEDIUM_GRAY2};
`;

export const sourceTagStyles = css`
`;

export const sourceLinkStyles = ({ variant, type, standarddisplay }) => css`
    color: ${COLORS.SAPPHIRE};
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
    transition: 0.33s all;

    &:hover {
        color: ${COLORS.DARK_SAPPHIRE};
    }

    ${props => props.cecard && props.cecard === 'true' && css`
        &:hover {
            color: ${COLORS.DARK_SAPPHIRE};
        }
    `};

    ${variant.source === CE_CREDITS && standarddisplay === "true" && css`
        color: ${COLORS.WHITE};
        &:hover {
            color: ${COLORS.WHITE};
            opacity: 0.75;
        }
    `}

    ${variant.source === CAPITAL_IDEAS && standarddisplay !== "true" && css`
        color: ${COLORS.WHITE};
        &:hover {
            color: ${COLORS.WHITE};
            opacity: 0.75;
        }
    `}

    ${variant.source === PRACTICE_LAB && !variant.isListCard && css`
        color: ${COLORS.WHITE};
        &:hover {
            color: ${COLORS.WHITE};
            opacity: 0.75;
        }
    `}

    ${type === "list" && css`
       color: ${COLORS.SAPPHIRE};
    `}

    @media screen and ${FROM_TABLET} {
        font-size: 10px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.4;
        letter-spacing: 0.1px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 12px;
    }
`;

export const poweredByStyles = ({ variant, type, standarddisplay }) => css`
    color: ${COLORS.DARK_GRAY1};
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;

    ${variant.source == CE_CREDITS && standarddisplay === "true" && css`
        color: ${COLORS.WHITE};
    `}

    ${variant.source == CAPITAL_IDEAS && standarddisplay !== "true" && css`
        color: ${COLORS.WHITE};
    `}

    ${variant.source === PRACTICE_LAB && !variant.isListCard && css`
        color: ${COLORS.WHITE};
    `}

    ${type === "list" && css`
       color: ${COLORS.DARK_GRAY1};
    `}

    @media screen and ${FROM_TABLET} {
        font-size: 10px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
    }
`;
