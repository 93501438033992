import {
    all, call, put, select, takeLatest,
} from 'redux-saga/effects';

import {
    getTopicsSuccess,
    getTopicsFail,
    submitFeedbackSuccess,
    submitFeedbackFail,
} from 'state-management/actions/contactUs';
import { setError } from 'state-management/actions/errors';
import {
    TOPICS_URL,
    SUBMIT_FEEDBACK_URL,
    CONTACT_METHOD_EMAIL,
    GET_TOPICS,
    SUBMIT_FEEDBACK,
} from 'state-management/constants/contactUs';
import Api from 'utils/api';

/**
 * Get topics for contact us page.
 */
function* getTopics() {
    try {
        const response = yield call(Api.get, TOPICS_URL);
        yield put(getTopicsSuccess(response.data));
    } catch (e) {
        yield put(getTopicsFail);
        yield put(setError('contactUs.errors.server.load', e));
    }
}

/**
 * Handle contact us form submission.
 * @param {*} param0
 */
function* submitFeedbackForm({ data }) {
    try {
        const isAuthenticated = yield select(state => state.signIn.isAuthenticated);
        const { firstName, lastName } = yield select(state => state.basicProfile);
        const body = {
            topic: data.topic,
            details: data.details,
            firstName: isAuthenticated ? firstName : data.firstName,
            lastName: isAuthenticated ? lastName : data.lastName,
        };
        if (data.contactMethod === CONTACT_METHOD_EMAIL) {
            body.email = data.email;
        } else {
            body.phone = data.phone;
        }

        yield call(Api.post, SUBMIT_FEEDBACK_URL, { data: body });
        yield put(submitFeedbackSuccess);
    } catch (e) {
        yield put(submitFeedbackFail);
    }
}

function* contactUsSaga() {
    yield all([
        takeLatest(GET_TOPICS, getTopics),
        takeLatest(SUBMIT_FEEDBACK, submitFeedbackForm),
    ]);
}

export default contactUsSaga;
