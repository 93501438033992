import React from 'react';
import { Link as InternalLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { defaultLinkStyles } from 'components/Link/styles';
import { MOUSE_MIDDLE } from 'utils/keyCodes';
import scrollHelper from 'utils/scrollToTop';
import { connect } from 'react-redux';
import { showLoader } from 'state-management/actions/guide';
import { hideLoader } from 'state-management/actions/guide';

const StyledExternalLink = styled.a`
    ${defaultLinkStyles};
`;

const StyledInternalLink = styled(InternalLink)`
    ${defaultLinkStyles};
`;

const handleMouseDown = (e, handler) => {
    if (e.nativeEvent.which === MOUSE_MIDDLE) {
        handler();
    }
};

class Link extends React.Component {

    render() {
        const {
            children,
            className,
            location,
            to,
            onClick,
            staticContext,
            ...other
        } = this.props;
        const isExternalLink = to && (to.startsWith('http') || to.startsWith('#'));
        const isEmailLink = to && to.startsWith('mailto:');
        const isDownloadLink = to && to.startsWith('itms-services:');
        const currentPath = location.pathname + location.search + location.hash;
        const isSamePath = to === currentPath;

        if (isExternalLink || isEmailLink || isDownloadLink) {
            const target = isExternalLink && !to.startsWith('#') ? '_blank' : '_self';

            return (
                <StyledExternalLink
                    className={className}
                    href={to}
                    target={target}
                    onMouseDown={e => handleMouseDown(e, onClick)}
                    onClick={onClick}
                    rel="noopener noreferrer"
                >
                    {children}
                </StyledExternalLink>
            );
        }

        const handleInternalClick = () => {
            if (isSamePath) {
                scrollHelper.scrollToTop();
                let that = this;
                setTimeout(function () {
                    try {
                        that.props.hideLoaderOnTimer();
                    } catch (e) {
                        //console.log(e);
                    }
                }, 1000);
                this.props.showLoaderOnClick();
            }

            onClick();
        };

        return (
            <StyledInternalLink
                className={className}
                to={to}
                onClick={handleInternalClick}
                onMouseDown={e => handleMouseDown(e, onClick)}
                replace={isSamePath}
                {...other}
            >
                {children}
            </StyledInternalLink>
        );
    };
}
Link.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    location: PropTypes.shape({
        hash: PropTypes.string,
        pathname: PropTypes.string,
        search: PropTypes.string,
    }).isRequired,
    to: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};

Link.defaultProps = {
    className: '',
    children: null,
    onClick: () => { },
};

const mapDispatchToProps = dispatch => ({
    showLoaderOnClick: () => { dispatch(showLoader()) },
    hideLoaderOnTimer: () => { dispatch(hideLoader()) },
});

export default withRouter(connect(null, mapDispatchToProps)(Link));