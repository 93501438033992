import React from 'react';
import { FormattedMessage } from 'react-intl';
import Toast from 'components/atomics/molecules/Toast/Toast';

/**
 * Toast shown when user copies link from share menu.
 */
class CopyToast extends React.Component {
    render() {
        return (
            <Toast>
                <Toast.Title>
                    <span><FormattedMessage id="toast.link.copied" /></span>
                    <span><FormattedMessage id="toast.link.copied" /></span>
                </Toast.Title>
            </Toast>
        );
    }
}

export default CopyToast;
