import {
    all,
    put,
    call,
    takeLatest,
} from 'redux-saga/effects';

import {
    GET_FEATURE_TOGGLE,
    GET_FEATURE_TOGGLE_URL,
} from '../constants/featureToggle.common';

import {
    getFeatureToggleSuccess,
    getFeatureToggleFail,
} from '../actions/featureToggle.common';

import Api from '../bridge/api';

/**
 * Get a feature toggle (on/off) from BE.
 * @param {*} action
 */
function* getFeatureToggle(action) {
    try {
        const response = yield call(Api.get,
            GET_FEATURE_TOGGLE_URL(action.featureId));
        yield put(getFeatureToggleSuccess({
            featureId: action.featureId,
            enabled: response.data.featureOn,
        }));
    } catch (e) {
        yield put(getFeatureToggleFail(e));
    }
}

function* featureToggleSaga() {
    yield all([
        takeLatest(GET_FEATURE_TOGGLE, getFeatureToggle),
    ]);
}

export default featureToggleSaga;
