import { css } from 'styled-components';
import {
    COLORS,
} from 'utils/variables';
import {
    FROM_TABLET,
    FROM_DESKTOP,
} from 'utils/mediaQueries';

export const containerStyles = css`
    width: 374px;
    padding: 10px;
    text-align: center;
    color: ${COLORS.BLACK};

    ${props => props.dataPage === "promoBanner" && css`
        width: 100%;
        padding-top: 22px;
        @media screen and ${FROM_TABLET} {
            width: 374px;
            padding-left: 0;
            padding-top: 38px;
        }

        @media screen and ${FROM_DESKTOP} {
            padding-top: 30px;
            padding-left: 10px;           
        }
    `}

    ${props => props.dataPage === "landingPage" && css`   
        padding-bottom: 0;
        width: auto;
    `}
`;

export const dateContainer = css`
    color: ${COLORS.DARK_GRAY1};
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: -0.1px;
    font-weight: 500;
    padding-bottom: 16px;
    
    ${props => props.dataPage === "landingPage" && css`
        font-size: 12px;
        padding-bottom: 0;
    `}
`;
