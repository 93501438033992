import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { startSearch, setSearchError } from 'state-management/actions/search';
import { getSeeMoreTrending } from 'state-management/actions/allTopics.common';
import { getCeCreditsItems } from 'state-management/actions/guide';
import ConnectedPageableNews from './../../containers/PageableNews/PageableNews';
import Loader from 'components/Loader/Loader';
import { TYPE } from 'components/ContentCard/constants';
import {
    getGuideRecent,
    getGuideCollections,
} from 'state-management/actions/guide';
import {
    BreakpointsContext,
} from 'components/Breakpoints/Breakpoints';

const TYPE_GUIDES = "guidesandfeatures";
const TYPE_COLLECTIONS = "collections";
const TYPE_TRENDING = "trending";
const TYPE_CECREDITS = "cecredits";
const TYPE_TOPIC = "topic";
const TYPE_SOURCE = "source";

/**
 * Landing container wrapper for most pagable pages 'see mores', wraps PageableNews.
 */
class LandingContainer extends React.Component {
    state = {
        initLoading: true,
    };

    /**
     * Gather pagable data at mount depending upon type, guides, search, trending or collections.
     */
    componentWillMount() {
        const { query, type } = this.props;
        const { section } = this.props.match.params;
        const { topics, excludeArticles, trendingVariant, trendingType } = this.props;

        if (type === TYPE_GUIDES) {
            this.props.getGuideRecentData();
        } else if (type === TYPE_COLLECTIONS) {
            this.props.getCollections();
        } else if (topics && topics.length && excludeArticles) {
            this.props.getTrendingTopics(topics, excludeArticles, trendingType, trendingVariant);
        } else if (type === TYPE_CECREDITS) {
            this.props.getCeCredits();
        } else {
            this.props.search(query(section));
        }
    }

    /**
     * Clear search data at unmount.
     */
    componentWillUnmount() {
        this.props.clearSearch();
    }

    /**
     * Perform search on search update when search param has changed.
     * @param {*} prevProps
     */
    componentDidUpdate(prevProps) {
        const { query } = this.props;
        const { section } = this.props.match.params;

        if (prevProps.match.params.section !== section) {
            this.props.clearSearch();
            this.props.search(query(section));
        }
    }

    /**
     * Remove loader security at update of loading state.
     * @param {*} nextProps 
     */
    componentDidUpdate(nextProps) {
        if ((nextProps.isLoading === true || this.props.type !== TYPE_TOPIC)
            && this.state.initLoading) {
            this.setState({
                initLoading: false,
            });
        }
    }

    /**
     * Get articles only from payload.
     */
    getArticles = () => this.props.news.filter(item => item.type === TYPE.ARTICLE);

    /**
     * Get articles for guides and featured content permutation.
     */
    getGuidesArticles() {
        let finalarray = [];

        if (this.props.guides) {
            finalarray = [...this.props.guides]
        }

        return finalarray
    }

    /**
     * Get all collection items for display.
     */
    getCollectionArticles() {
        return this.props.collections ? this.props.collections : [];
    }

    /**
     * Get all CECRedits items for display.
     */
    getCECreditCollection() {
        return this.props.ceCreditCollections ? this.props.ceCreditCollections : [];
    }

    /**
     * Render this and the underlying components.
     */
    render() {
        const { headingTranslationId, type } = this.props;
        let { section } = this.props.match.params;
        let articles;

        if (type === TYPE_GUIDES) {
            articles = this.getGuidesArticles();
        } else if (type === TYPE_COLLECTIONS) {
            articles = this.getCollectionArticles();
        } else if (type === TYPE_CECREDITS) {
            articles = this.getCECreditCollection();
        } else {
            articles = this.getArticles();
        }

        if (this.props.isLoading || this.state.initLoading
            || (this.props.isGuideLoading && type === TYPE_GUIDES)
            || (type === TYPE_TRENDING && articles.length === 0)
            || (type === TYPE_TOPIC && articles.length === 0 && this.props.isLoading)
            || (type === TYPE_SOURCE & articles.length === 0 && this.props.isLoading)
            || (type === TYPE_GUIDES && this.props.guides === null)
            || (type === TYPE_COLLECTIONS && this.props.collections === null)
            || (type === TYPE_CECREDITS  && this.props.ceCreditCollections === null)
            ) {
            return <Loader />;
        }

        let showEmptyResults = false;

        if (articles.length === 0 && type !== TYPE_TOPIC && type !== TYPE_SOURCE) {
            return null;
        } else if (articles.length === 0 && (type === TYPE_TOPIC || type === TYPE_SOURCE)) {
            showEmptyResults = true;
        }

        return (
            <BreakpointsContext.Consumer>
                {(breakpoint) => {
                    return (
                        <ConnectedPageableNews
                            showEmptyResults={showEmptyResults}
                            noMaxWidth={this.props.noMaxWidth}
                            content={articles}
                            headingTranslationId={headingTranslationId}
                            goBack={this.props.history.goBack}
                            section={section}
                            displayResultCount={this.props.displayResultCount}
                            type={type}
                            breakpoint={breakpoint}
                            webinar={this.props.webinarList}
                        />
                    );
                }}
            </BreakpointsContext.Consumer>
        );
    }
}

LandingContainer.propTypes = {
    search: PropTypes.func.isRequired,
    getTrendingTopics: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            section: PropTypes.string,
        }),
        path: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({
        goBack: PropTypes.func.isRequired,
    }).isRequired,
    news: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    isLoading: PropTypes.bool.isRequired,
    headingTranslationId: PropTypes.string.isRequired,
    query: PropTypes.func.isRequired,
    topics: PropTypes.arrayOf(
        PropTypes.number
    ),
    guides: PropTypes.arrayOf(PropTypes.shape()),
    collections: PropTypes.arrayOf(PropTypes.shape()),
    ceCreditCollections: PropTypes.arrayOf(PropTypes.shape()),
    excludeArticles: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ])
    ),
    trendingType: PropTypes.string,
    trendingVariant: PropTypes.number,
    type: PropTypes.string,
    getGuideRecentData: PropTypes.func,
    getCollections: PropTypes.func,
    displayResultCount: PropTypes.bool,
    hasRefinementCard: PropTypes.bool.isRequired,
    isSeeMoreLinkVisible: PropTypes.bool,
    noMaxWidth: PropTypes.bool,
};

LandingContainer.defaultProps = {
    topics: null,
    excludeArticles: null,
    type: null,
    guides: null,
    collections: null,
    getGuideRecentData: () => { },
    getCollections: () => { },
    displayResultCount: true,
    ceCreditCollections: [],
    noMaxWidth: false,
};

const mapStateToProps = state => ({
    ceCredits: state.search.items,
    news: state.search.items,
    guides: state.guide.guideRecent ? state.guide.guideRecent.guidesList : null,
    webinarList:state.guide.guideRecent ? state.guide.guideRecent.webinarList : null,
    isLoading: state.search.loading,
    isGuideLoading: state.guide.isRecentLoading,
    collections: state.guide.data ? state.guide.data.lstCollection : null,
    ceCreditCollections: state.guide.ceCredits,
    hasRefinementCard: state.profileRefinementCard.hasSuggestion,
    showCoachMark: state.coachMark.display.KEBAB && !state.coachMark.display.INFO_BAR,
});

const mapDispatchToProps = dispatch => ({
    search: topic => dispatch(startSearch(topic)),
    clearSearch: () => dispatch(setSearchError(false)),
    getCollections: () => dispatch(getGuideCollections(null)),
    getGuideRecentData: () => dispatch(getGuideRecent(false)),
    getTrendingTopics: (topics, excludeArticles, type, variant) => dispatch(getSeeMoreTrending(topics, excludeArticles, type, variant)),
    getCeCredits: () => dispatch(getCeCreditsItems()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingContainer);
