import * as type from 'state-management/constants/homepage.common';

const initialState = {
    components: [],
    pollResponse: [],
    isLoading: false,
};

function homepageReducer(state = initialState, action = {}) {
    switch (action.type) {
    case type.GET_HOMEPAGE_DATA:
        return {
            ...state,
            isLoading: true,
        };
    case type.GET_HOMEPAGE_DATA_FAIL:
        return {
            ...state,
            isLoading: false,
        };
    case type.GET_HOMEPAGE_DATA_SUCCESS:
        return {
            ...state,
            isLoading: false,
            components: action.components,
        };

    // TODO
    case type.POST_HOMEPAGE_POLL:
        return {
            ...state,
            isLoading: false,
        };
    case type.POST_HOMEPAGE_POLL_FAILURE:
        return {
            ...state,
            isLoading: false,
        };
    case type.POST_HOMEPAGE_POLL_SUCCESS:
        return {
            ...state,
            isLoading: false,
            //components: action.components,
        };

    case type.GET_HOMEPAGE_POLL_DATA:
        return {
            ...state,
            isLoading: false,
        };
    case type.GET_HOMEPAGE_POLL_DATA_FAILURE:
        return {
            ...state,
            isLoading: false,
        };
    case type.GET_HOMEPAGE_POLL_DATA_SUCCESS:
        return {
            ...state,
            isLoading: false,
            pollResponse: action.response,
        };


    default:
        return state;
    }
}

export default homepageReducer;
