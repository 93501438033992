import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS, RGB_COLORS } from 'utils/variables';

export const jargonContainerStyles = css`
    background-color: ${COLORS.WHITE};
    box-shadow: 0 4px 20px rgba(${RGB_COLORS.BLACK}, 0.16);
    display: flex;
    flex-direction: column;
    font-family: ${FONT_AVENIR_NEXT};
    justify-content: space-around;
    margin-bottom: 10px;
    padding: 15px 20px 25px;

    &:last-child {
        margin-bottom: 0;
    }

    @media screen and ${FROM_TABLET} {
        flex-direction: row;
        justify-content: space-between;
        padding: 38px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 48px 40px;
    }
`;

export const jargonStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.4px;
    line-height: 1.96;
    /*word-wrap: break-word;*/

    @media screen and ${FROM_TABLET} {
        flex-basis: 30%;
        flex-shrink: 0;
        font-size: 26px;
        letter-spacing: -0.5px;
        line-height: 1.77;
        min-width: 0;
        padding-right: 16px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 25px;
        line-height: 32px;
        object-fit: contain;
        padding-right: 40px;
    }
`;

export const translationContainerStyles = css`
    display: flex;
    flex-direction: column;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        flex-basis: 60%;
        flex-shrink: 0;
        min-width: 0;
    }
`;

export const translationHeadingStyles = css`
    color: ${COLORS.MEDIUM_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    margin: 10px 0;
    text-transform: uppercase;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        margin: 20px 0 25px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin: 5px 0 30px;
    }
`;

export const translationStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.3px;
    line-height: 1.44;
    word-wrap: break-word;

    * {
        margin-top: 0;
    }

    @media screen and ${FROM_TABLET} {
        letter-spacing: normal;
        line-height: 1.33;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 24px;
        letter-spacing: -0.4px;
        line-height: 1.25;
        object-fit: contain;
    }
`;
