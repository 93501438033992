import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { createImageUrl } from 'utils/urlUtils';
import { ROUTES } from 'containers/App/constants';
import Button from 'components/atomics/atoms/Button/Button';
import {
    Container,
    Heading,
    Logo,
} from 'components';
import Image from 'components/atomics/atoms/Image/Image';
import { BreakpointsContext, MOBILE } from 'components/Breakpoints/Breakpoints';
import {
    outerContainerStyles,
    contentContainerStyles,
    logoWrapperStyles,
    headingStyles,
    descriptionStyles,
    topFlexCtnStyles,
    imageContainerStyles,
    logolinkStyles,
    gradientOverlayLeftStyles,
    gradientOverlayRightStyles,
    wrapperContainerStyles,
} from 'components/SignInSection/styles';
import anime from 'animejs';
import {
    TYPE_SECONDARY_MEDIUM,
} from 'components/atomics/atoms/Button/Button';

export const FORM_HASH = '#form';

const Wrapper = styled.div`
    ${wrapperContainerStyles}
`;

const StyledOuterContainer = styled.section`
    ${outerContainerStyles}
`;

const GradientOverlayLeft = styled.div`
    ${gradientOverlayLeftStyles}
`;

const GradientOverlayRight = styled.div`
    ${gradientOverlayRightStyles}
`;

const StyledContentContainer = styled.div`
    ${contentContainerStyles}
`;

const StyledLogoWrapper = styled.div`
    ${logoWrapperStyles}
`;

const StyledHeading = styled(Heading)`
    ${headingStyles}
`;

const StyledDescription = styled.p`
    ${descriptionStyles}
`;

const StyledControlWrapper = styled.div`
`;

const StyledTopFlexCtn = styled.div`
    ${topFlexCtnStyles}
`;

const StyledLogoLink = styled.a`
    ${logolinkStyles}
`;

const StyledImageContainer = styled.div`
    ${imageContainerStyles}
`;

const renderCoverImage = (breakpoint) => {
    const COVER_IMAGE_URL = breakpoint === MOBILE
        ? createImageUrl('landing-page', 'Homepage_news_mobile.png')
        : '/images/laptop.png';
    return (
        <StyledImageContainer isMobile={breakpoint === MOBILE}>
            <Image src={COVER_IMAGE_URL} alt="" isExternal={breakpoint === MOBILE} />
        </StyledImageContainer>
    );
};

const SCROLL_DURATION = 666;

export class SignInSection extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.scrollToSignup = this.scrollToSignup.bind(this);
    }

    /**
     * Scroll to sign up section if passed the anchor
     */
    componentDidMount() {
        if (document.location.hash === FORM_HASH) {
            setTimeout(() => {
                document.getElementById('signupLink').click();
            }, 600);
        }
    }

    /**
     * Animate scroll to signup section near bottom of logged out homepage.
     */
    scrollToSignup() {
        const scrollElement = window.document.scrollingElement
            || window.document.documentElement
            || window.document.body;
        const toElement = document.getElementById('signup-form-new');
        const toTop = toElement.getBoundingClientRect().top;
        const scrollTo = (toTop + scrollElement.scrollTop);

        anime({
            targets: scrollElement,
            scrollTop: scrollTo, //20px padding from top of viewport to purple title of signup
            duration: SCROLL_DURATION,
            easing: 'easeInOutQuad',
        });
    }

    pageReload() {
        window.location.reload();
    }

    /**
     * Render the signin section and underlying components.
     */
    render() {
        var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        var isSafari = !isChrome && navigator.userAgent.indexOf("Safari") > -1;

        return (
            <Wrapper>
                <StyledOuterContainer>
                    <GradientOverlayLeft isSafari={isSafari} />
                    <GradientOverlayRight isSafari={isSafari} />
                    <BreakpointsContext.Consumer>
                        {renderCoverImage}
                    </BreakpointsContext.Consumer>

                    <StyledContentContainer>
                        <Container>

                            <StyledTopFlexCtn>
                                <StyledLogoWrapper >
                                    <StyledLogoLink onClick={this.pageReload}
                                        href="#"
                                    >
                                        <Logo />
                                    </StyledLogoLink>
                                </StyledLogoWrapper>

                                <StyledControlWrapper>
                                    <Button
                                        type={TYPE_SECONDARY_MEDIUM}
                                        id="landing-sign-in"
                                        to={ROUTES.SIGN_IN}
                                    >
                                        <FormattedMessage id="signInSection.button.signIn" />
                                    </Button>
                                </StyledControlWrapper>
                            </StyledTopFlexCtn>

                            <StyledHeading level={1}>
                                <FormattedMessage id="signInSection.heading1" />
                            </StyledHeading>
                            <StyledDescription>
                                <FormattedMessage id="signInSection.description" />
                            </StyledDescription>

                        </Container>
                    </StyledContentContainer>
                </StyledOuterContainer>
            </Wrapper>
        );
    }
};

export default SignInSection;
