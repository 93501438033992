import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { createImageUrl } from 'utils/urlUtils';
import Image from 'components/atomics/atoms/Image/Image';
import {
    Container,
    Heading,
} from 'components';
import {
    outerContainerStyles,
    imageContainerStyles,
    innerContainerStyles,
    contentContainerStyles,
    headingStyles,
    descriptionStyles,
} from 'components/SignUpSection/styles';
import { BreakpointsContext, MOBILE } from 'components/Breakpoints/Breakpoints';

const StyledOuterContainer = styled.section`
    ${outerContainerStyles}
`;

const StyledImageContainer = styled.div`
    ${imageContainerStyles}
`;

const StyledInnerContainer = styled(Container)`
    ${innerContainerStyles}
`;

const StyledContentContainer = styled.div`
    ${contentContainerStyles}
`;

const StyledHeading = styled(Heading)`
    ${headingStyles}
`;

const StyledDescription = styled.p`
    ${descriptionStyles}
`;

const renderCoverImage = (breakpoint) => {
    const COVER_IMAGE_URL = breakpoint === MOBILE
        ? createImageUrl('landing-page', 'homepage_man_mobile.png')
        : '/images/man.jpg';

    return <Image src={COVER_IMAGE_URL} alt="" isExternal={breakpoint === MOBILE} />;
};

const SignInSection = (props) => (
    <StyledOuterContainer isWebinar={props.isWebinar}>
        <StyledImageContainer>
            <BreakpointsContext.Consumer>
                {renderCoverImage}
            </BreakpointsContext.Consumer>
        </StyledImageContainer>
        <StyledInnerContainer isWebinar={props.isWebinar}>
            <StyledContentContainer isWebinar={props.isWebinar}>
                <StyledHeading level={2}>
                    <FormattedMessage id="signUpSection.heading" />
                </StyledHeading>
                <StyledDescription>
                    <FormattedMessage id="signUpSection.description" />
                </StyledDescription>
            </StyledContentContainer>
        </StyledInnerContainer>
    </StyledOuterContainer>
);

export default SignInSection;
