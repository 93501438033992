import { css } from 'styled-components';
import { FROM_TABLET } from 'utils/mediaQueries';
import {
    COLORS,
    FONTS,
    ANIMATIONS,
} from 'utils/variables';
import {
    TYPE_LOWER_CONTROLS,
} from './Toast';

export const toastWrapperStyles = css`
    background-color: ${COLORS.DARK_SAPPHIRE};
    box-sizing: border-box;
    color: ${COLORS.WHITE};
    font-size: 16px;
    padding: 16px;
    text-align: left;
    word-wrap: break-word;
    display: inline-block;
    max-width: 400px;

    & + & {
        margin-top: 10px;
    }

    ${props => props.smallMobile && css`
        max-height: 56px;

        @media screen and ${FROM_TABLET} {
            max-height: none;
        }
    `};

    &>div {
        display: flex;

        ${props => props.type === TYPE_LOWER_CONTROLS && css`
            @media screen and ${FROM_TABLET} {
                display: block;
            }
        `};
    }

    @media screen and ${FROM_TABLET} {
        padding: 24px;
    }

    svg {
        stroke: ${COLORS.WHITE};
    }

    a {
        transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;
    }

    a:hover {
        color: ${COLORS.HOVER_SAPPHIRE};
        border-color: ${COLORS.HOVER_SAPPHIRE};

        svg {
            stroke: ${COLORS.HOVER_SAPPHIRE};
        }
    }
`;

export const toastTitleStyles = css`
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.12px;
    font-weight: normal;
    word-wrap: break-word;

    &>span {
        display block;
    }

    &>span:first-child {
        font-weight: 600;
    }

    &>span:nth-child(2) {
        white-space: nowrap;
    }

    &>span {
        display: none;
    }

    &>span:last-child {
        display: block;
    }

    @media screen and ${FROM_TABLET} {
        &>span {
            display: block;
        }

        &>span:last-child {
            display: none;
        }
    }
`;

export const rightControls = css`
    padding-left: 16px;
    text-align: right;
    display: flex;
    flex-direction: column;

    &>div:first-child {
        flex: 1;
    }

    &>div:last-child {
        flex: 1;
    }
`;

export const lowerControls = css`
    padding-left: 16px;
    text-align: right;
    display: flex;
    flex-direction: column;

    &>div:first-child {
        flex: 1;
    }

    a:first-child {
        display: none;
    }

    @media screen and ${FROM_TABLET} {
        padding-top: 24px;

        a:first-child {
            display: inline-block;
        }

        a:last-child {
            display: none;
        }
    }

    &>div:last-child {
        flex: 1;
    }
`;

export const toastControlsWrapperStyles = css`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
`;
