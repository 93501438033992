import React from 'react';
import styled from 'styled-components';
import Link from 'components/atomics/atoms/Link/Link';
import { TOPIC_LINK } from 'state-management/constants/topics';
import { withCommonCard } from 'components/atomics/hocs/CommonCard/CommonCard';
import moment from 'moment';
import ImageAtom from 'components/atomics/atoms/Image/Image';
import { getArticleUrl, getArticleImage } from '../../../../utils/getArticleAsset';
import CoachMarks from 'containers/CoachMarks/CoachMarks';
import { TYPES } from 'containers/CoachMarks/CoachMarks';
import {
    containerStyles,
    imageStyles,
    contentContainerStyles,
    dateAndTopicContainerStyles,
    dateContainerStyles,
    topicLinkStyles,
    titleStyles,
    imageContainerStyles,
    cardFooterContainerStyles,
} from 'components/atomics/molecules/StandardContentCard/styles';
import ListContentCardFooter from "../ListContentCard/ListContentCardFooter";
import { getTruncatedString } from 'utils/contentCardUtils';
import language from '../../../../lang/en.json';

export const ImageContainer = styled(Link)`
    ${imageContainerStyles}
`;

export const Title = styled(Link)`
    ${titleStyles}
`;

export const DateAndTopic = styled.div`
    ${dateAndTopicContainerStyles}
`;

export const DateContainer = styled.div`
    ${dateContainerStyles}
`;

export const TopicLink = styled(Link)`
    ${topicLinkStyles}
`;

export const Container = styled.div`
    ${containerStyles}
`;

export const Image = styled(ImageAtom)`
    ${imageStyles}
`;

export const ContentContainer = styled.div`
    ${contentContainerStyles}
`;

const CardFooterContainer = styled.div`
    ${cardFooterContainerStyles}
`;

const MAX_ARTICLE_TITLE_CHARS = 85;

/**
 * Creates a standard content card.
 */
class StandardContentCard extends React.Component {
    /**
     * Build the kebab with potential coachmark.
     */
    buildCoachMarkKebab = (coachMark) => {
        const { showCoachMark, onCoachMarkDismiss, activateDismissCoachMark } = this.props;

        return (
            <ListContentCardFooter
                onElement={this.props.onCardHover}
                profile={this.props.profile}
                sourceName={this.props.article.sourceName}
                providerName={this.props.article.provider}
                article={this.props.article}
                topics={this.props.article.topics}
                kebabButtonClickListener={() => showCoachMark || activateDismissCoachMark ? onCoachMarkDismiss() : null}
                sourceId={this.props.article.sourceId}
                topicName={this.props.article.topicName}
                saved={this.props.article.saved}
                articleId={parseInt(this.props.article.id)}
                articleTitle={this.props.article.title}
                type={this.props.article.type || this.props.article.contentType}
                shouldShowToast={this.props.shouldShowToast}
                onSaveArticle={(id, save, title, getContentAfterSave, article) => {
                    this.props.toggleSaveArticle(id, save, title, getContentAfterSave, article);
                }}
                onSaveGuide={(id, save, title, getContentAfterSave, article) => {
                    this.this.props.toggleSaveGuide(id, save, title, getContentAfterSave, article);
                }}
                onFollowTopic={(topicId, status) => {
                    this.props.toggleFollowTopic(topicId, status);
                }}
                onFollowSource={(sourceId, status) => {
                    this.props.toggleFollowSource(sourceId, status);
                }}
            >
                <CoachMarks
                    showCoachMark={showCoachMark}
                    type={TYPES.KEBAB}
                >
                    <div data-rel="cmPlaceholder" />
                </CoachMarks>
            </ListContentCardFooter>
        );
    }

    /**
     * Render the content card and underlying components.
     */
    render() {
        const linkUrl = getArticleUrl(this.props.article);
        const imageUrl = getArticleImage(this.props.article, 2);

        return (
            <Container
                data-rel="StandardContentCard"
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this.props.onMouseLeave}
            >
                <ImageContainer
                    visited={this.props.article.visited ? "true" : undefined}
                    to={linkUrl}
                    onClick={() => {
                        this.props.recordClickAnalytics();
                        this.props.onArticleClick();
                    }}
                >
                    <Image
                        src={imageUrl}
                        alt={language.translations['global.alt.image']}
                    />
                </ImageContainer>
                <ContentContainer>
                    <DateAndTopic>
                        <DateContainer>
                            {moment.utc(this.props.article.date).format('MMM D, YYYY')}
                        </DateContainer>
                        <TopicLink
                            to={TOPIC_LINK(`${this.props.article.topicName}/${this.props.article.topicId}`)}
                            data-analytics-placement="Anchor : body"
                            data-analytics-label="trackLink : anchor"
                            data-analytics-id={"topic:" + this.props.article.topicName}
                        >
                            <span>{this.props.article.topicName}</span>
                        </TopicLink>
                    </DateAndTopic>
                    <Title
                        visited={this.props.article.visited ? "true" : undefined}
                        to={linkUrl}
                        onClick={() => {
                            this.props.recordClickAnalytics();
                            this.props.onArticleClick();
                        }}
                    >
                        {getTruncatedString(this.props.article.title, MAX_ARTICLE_TITLE_CHARS)}
                    </Title>
                    <CardFooterContainer>
                        {this.buildCoachMarkKebab(this.props.showCoachMark)}
                    </CardFooterContainer>
                </ContentContainer>
            </Container>
        );
    }
}

export default withCommonCard(StandardContentCard);
