// ACTIONS
export const GET_COACH_MARKS = 'coachMark/GET_COACH_MARKS';
export const GET_COACH_MARKS_SUCCESS = 'coachMark/GET_COACH_MARKS_SUCCESS';
export const HIDE_COACH_MARK = 'coachMark/HIDE_COACH_MARK';
export const DISMISS_COACH_MARK = 'coachMark/DISMISS_COACH_MARK';
export const DISMISS_COACH_MARK_SUCCESS = 'coachMark/DISMISS_COACH_MARK_SUCCESS';
export const DISMISS_COACH_MARK_FAILURE = 'coachMark/DISMISS_COACH_MARK_FAILURE';
export const DISABLE_COACH_MARKS = 'coachMark/DISABLE_COACH_MARKS';
export const ENABLE_COACH_MARKS = 'coachMark/ENABLE_COACH_MARKS';
export const SET_COACH_MARK_AS_SAVED = 'coachMark/SET_COACH_MARK_AS_SAVED';

// URL
export const COACH_MARK_URL = 'advisor-service/coach-mark';

// TYPES
export const COACH_MARK_SAVE = 'SAVE';
export const COACH_MARK_SAVE_CONFIRMATION = 'SAVE_CONFIRMATION';
