import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import LegalFDIC from 'components/LegalFDIC/LegalFDIC';
import { COLORS } from '../../utils/variables';
import { APPLICATION_URL_GOOGLE_PLAY, APPLICATION_URL_APPLE_STORE } from 'utils/globalConstants';
import {
    IOSView,
    MobileView,
    AndroidView,
    BrowserView,
    isMobileOnly,
    isIOS,
    isAndroid
} from 'react-device-detect';
import {
    Container,
    Heading,
    VisuallyHidden,
} from 'components';
import { setScrollToTop } from 'state-management/actions/scroll';
import Button from '../../components/atomics/atoms/Button/Button';
import { TYPE_PRIMARY_LARGE } from '../../components/atomics/atoms/Button/Button';
import { connect } from 'react-redux';
import {
    visitedDownload
} from '../../state-management/actions/download';
import {
    wrapperStyles,
    containerStyles,
    browserContainerStyles,
    browserMessageStyles,
    mobileContainerStyles,
    androidMessageContainerStyles,
    androidMessageStyles,
    androidNoteStyles,
    browserNoteStyles,
    qrCodeStyles,
} from './styles';

const OverWrap = styled.div`
    background: ${COLORS.WHITE};
`;

const StyledWrapper = styled.div`
    ${wrapperStyles};
`;

const StyledContainer = styled(Container)`
    ${containerStyles};
`;

const StyledQRCode = styled.img`
    ${qrCodeStyles};
`;

const StyledBrowserContainer = styled.div`
    ${browserContainerStyles};
`;

const StyledBrowserMessage = styled.p`
    ${browserMessageStyles};
`;

const StyledMobileContainer = styled.div`
    ${mobileContainerStyles};
`;

const StyledAndroidMessageContainer = styled.div`
    ${androidMessageContainerStyles};
`;

const StyledAndroidMessage = styled.p`
    ${androidMessageStyles};
`;

const StyledAndroidNote = styled.p`
    ${androidNoteStyles};
`;

const StyledBrowserNote = styled.p`
    ${browserNoteStyles};
`;

/* Inline styling is used, because widget overrides styled component */
const detectDeviceContainerStyles = {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    height: '100%',
};

const qrCodeImagePath = '/images/newQR-code.png';

/**
 * Download iOS application page.
 */
class Download extends React.Component {
    /**
     * Scroll to top and mark the user as having seen this page.
     */
    componentDidMount() {
        this.props.scrollToTop();

        if (isIOS === true) {
            this.props.visitedDownload();
        }
    }

    /**
     * Render this and underlying components.
     */
    render() {
        function renderIphoneAppStore() {
            window.location.href = APPLICATION_URL_APPLE_STORE;
            return;
        }

        function renderGoogleStore() {
            window.location.href = APPLICATION_URL_GOOGLE_PLAY;
            return;
        }

        if (isIOS === true && isMobileOnly === true) {
            { renderIphoneAppStore() }
        }
        if (isAndroid === true && isMobileOnly === true) {
            { renderGoogleStore() }
        }

        return (
            <OverWrap>
                <StyledWrapper>
                    <StyledContainer>
                        <VisuallyHidden>
                            <Heading level={1}>
                                <FormattedMessage id="download.heading" />
                            </Heading>
                        </VisuallyHidden>
                        <BrowserView style={detectDeviceContainerStyles}>
                            <StyledBrowserContainer>
                                <StyledBrowserMessage>
                                    <FormattedMessage id="download.browser.message" />
                                </StyledBrowserMessage>
                                <StyledBrowserNote>
                                    <FormattedMessage id="download.desktop.viewInstructions" />
                                </StyledBrowserNote>
                                <StyledQRCode src={qrCodeImagePath}
                                ></StyledQRCode>
                            </StyledBrowserContainer>
                        </BrowserView>
                        <MobileView style={detectDeviceContainerStyles}>
                            <AndroidView style={detectDeviceContainerStyles}>
                                <StyledBrowserContainer>
                                    <StyledBrowserMessage>
                                        <FormattedMessage id="download.browser.message" />
                                    </StyledBrowserMessage>
                                    <StyledBrowserNote>
                                        <FormattedMessage id="download.desktop.viewInstructions" />
                                    </StyledBrowserNote>
                                    <StyledQRCode src={qrCodeImagePath}
                                    ></StyledQRCode>
                                </StyledBrowserContainer>
                            </AndroidView>
                            <IOSView style={detectDeviceContainerStyles}>
                                <StyledBrowserContainer>
                                    <StyledBrowserMessage>
                                        <FormattedMessage id="download.browser.message" />
                                    </StyledBrowserMessage>
                                    <StyledBrowserNote>
                                        <FormattedMessage id="download.desktop.viewInstructions" />
                                    </StyledBrowserNote>
                                    <StyledQRCode src={qrCodeImagePath}
                                    ></StyledQRCode>
                                </StyledBrowserContainer>
                            </IOSView>
                        </MobileView>
                    </StyledContainer>
                </StyledWrapper>
                <LegalFDIC background={COLORS.WHITE} />
            </OverWrap>
        );
    };
};

Download.propTypes = {
    visitedDownload: PropTypes.func,
    scrollToTop: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
    scrollToTop: () => dispatch(setScrollToTop()),
    visitedDownload: () => dispatch(visitedDownload)
});

export default connect(null, mapDispatchToProps)(Download);