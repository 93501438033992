import { css } from 'styled-components';
import {
    FROM_TABLET,
} from '../../utils/mediaQueries';
import {
    COLORS,
} from '../../utils/variables';

export const modalBody = css`
    background-color: ${COLORS.WHITE};
    color: ${COLORS.BLACK};
    padding: 32px;
    text-align: center;
    position: relative;

    @media screen and ${FROM_TABLET} {
        padding: 30px 60px 30px 60px;
    }

    input {
        height: 1px;
        padding: 0;
        border: none;
        opacity: 0;
    }
`;

export const modalTitle = css`
    color: ${COLORS.DARK_GRAY2};
    white-space: nowrap;
    font-size: 18px;
    letter-spacing: -0.09px;
    font-weight: 600;
    margin-bottom: 12px;
`;

export const closeIconStyles = css`
    height: 18px;
    width: 18px;
    position: absolute;
    top: 18px;
    right: 18px;
    cursor: pointer;
    text-decoration: none;
`;

export const modalDescription = css`
    font-size: 13px;
    letter-spacing: 0.07px;
    margin-bottom: 24px;
`;

export const modalButtons = css`
    margin: auto;

    &>a {
        margin-bottom: 16px;
        max-width: 282px;
        display: block;
    }

    &>a:last-child {
        margin-bottom: 22px;
    }

    @media screen and ${FROM_TABLET} {
        margin-bottom: 26px;

        &>a {
            display: inline-block;
            margin-bottom: 0;
            max-width: initial;
        }
    
        &>a:first-child {
            margin-right: 16px;
        }

        &>a:last-child {
            margin-bottom: 0;
        }
    }
`;

export const modalNote = css`
    font-size: 12px;
    font-style: italic;
    letter-spacing: 0.07px;
`;
