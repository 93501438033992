import {
    REAUTHENTICATE, SIGN_IN, SIGN_IN_FAIL, SIGN_IN_SUCCESS,
} from 'state-management/constants/signIn';

export function signIn(values, actions) {
    return {
        type: SIGN_IN,
        values,
        actions,
    };
}

export function signInSuccess() {
    return {
        type: SIGN_IN_SUCCESS,
    };
}

export function signInFail() {
    return {
        type: SIGN_IN_FAIL,
    };
}

export function refreshSuccess() {
    return {
        ...signInSuccess(),
        refresh: true,
    };
}

export function refreshFail() {
    return {
        ...signInFail(),
        refresh: true,
    };
}

export function reauthenticate() {
    return {
        type: REAUTHENTICATE,
    };
}
