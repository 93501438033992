import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
    difference,
    union,
    uniqueId,
    xor,
} from 'lodash';
import DropDown from 'components/atomics/molecules/Dropdown/Dropdown';
import {
    SortAndFilterContainer,
    DropDownWrapper,
    sortValues,
} from 'containers/Profile/Preferences/SourcesTabPane/SourcesTabPane';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { StyledSourceCheckbox } from 'containers/Profile/Preferences/SourcesTabPane/SourcesTabPane';
import { DESKTOP, MOBILE, TABLET } from 'components/Breakpoints/Breakpoints';
import TopicCategorySection from 'containers/Profile/Preferences/TopicsTabPane/TopicCategorySection';
import {
    listStyles,
    categoryContainerStyles,
    categoryListStyles,
} from 'containers/Profile/Preferences/TopicsTabPane/styles';
import language from '../../../../lang/en.json';

const StyledList = styled.div`
    ${listStyles}
`;

const CategoryContainer = styled.div`
    ${categoryContainerStyles}
`;

const Categories = styled.div`
    ${categoryListStyles}
`;

/**
 * Topics tab for account preferences.
 */
class TopicsTabPane extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.state = {
            content: null,
            preferred: null,
            previousSelections: null,
            sortId: 0, // A-Z default ordering
        };

        this.renderTopic = this.renderTopic.bind(this);
        this.onSelectAllClick = this.onSelectAllClick.bind(this);
        this.onDeselectAllClick = this.onDeselectAllClick.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
    }

    /**
     * Stuff to do on mount.
     */
    componentWillMount() {
        const { topicCategories } = this.props;

        if (topicCategories && topicCategories.length) {
            this.sortDataAndSetState();
        }
        this.props.onEditDefaultClick()
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.isInEditMode)
            this.props.onEditDefaultClick()
    }

    /**
     * Stuff to do on sorting type change.
     * @param {*} val
     */
    setSort(val) {
        this.setState({
            sortId: val,
        });
    }

    /**
     * Sort the data and set it to the state for display.
     */
    sortDataAndSetState() {
        const { topicCategories } = this.props;
        const sortedData = topicCategories.slice();
        const self = this;

        let preferredTopics = [];

        Object.keys(sortedData).forEach(function (key, index) {
            const category = sortedData[index];

            switch (self.state.sortId) {
                /* A-Z */
                case 0:
                    category.topics.sort((a, b) => (a.name > b.name) ? 1 : -1);
                    break;
                /* Preferred */
                case 1:
                    category.topics.sort((a, b) => (a.name > b.name) ? 1 : -1);

                    const preferredItems = category.topics.filter(item => { return self.props.formik.values.topicIds.includes(item.id) });

                    preferredTopics = preferredTopics.concat(preferredItems);
                    break;
                default:
                //NON-OP
            };
        });

        this.setState({
            content: sortedData,
            previousSelections: self.props.formik.values.topicIds.slice(),
            preferred: {
                topics: preferredTopics.sort((a, b) => (a.name > b.name) ? 1 : -1),
            },
        });
    }

    /**
     * Stuff to do on sorting change.
     * @param {*} val
     */
    onSortChange(val) {
        if (this.state.sortId !== val) {
            this.setState({
                sortId: val,
            }, this.sortDataAndSetState);
        }
    }

    /**
     * Select all logic.
     */
    onSelectAllClick(topics) {
        this.handleSelectionChange(union(this.props.formik.values.topicIds, topics.map(x => x.id)));
    }

    /**
     * Deselect all logic.
     */
    onDeselectAllClick(topics) {
        this.handleSelectionChange(difference(this.props.formik.values.topicIds, topics.map(topic => topic.id)));
    }

    /**
     * Get the categories subtitle.
     */
    getCategorySubtitle = (topicsCount) => {
        const { breakpoint, intl } = this.props;
        let subtitle;

        if (topicsCount > 0) {
            const subtitleId = breakpoint === MOBILE
                ? 'profilePage.preferences.topics.selectedTopicsMobile'
                : 'profilePage.preferences.topics.selectedTopics';
            subtitle = intl.formatMessage({ id: subtitleId }, { topicsCount });
        }

        return subtitle;
    };

    handleSelectionChange = (selectedTopicIds) => {
        this.props.formik.setFieldValue('topicIds', selectedTopicIds);
        this.props.onTouch();
    };

    /**
     * Handle topic change to form.
     */
    handleTopicChange = (id) => {
        this.props.formik.setFieldValue(
            'topicIds',
            xor([...this.props.formik.values.topicIds], [id]),
        );
        this.props.onEditDefaultClick();
        this.props.onTouch();
    };

    /**
     * Render a single topic
     * @param {*} item
     * @param {*} index
     */
    renderTopic(item, index) {
        return (
            <StyledSourceCheckbox
                label={item.name}
                key={`profile-topic-${item.id}`}
                id={`profile-topic-${item.id}`}
                name="topicIds"
                checked={this.props.formik.values.topicIds.includes(item.id)}
                onChange={() => this.handleTopicChange(item.id)}
                fieldRef={index === 0 ? this.firstPageItemRef : undefined}
            />
        );
    }

    /**
     * Render a category and underlying topics.
     */
    renderCategory = (category, i, renderHeader = true) => {
        const allTopicsSelected = category.topics.every(topic => this.props.formik.values.topicIds.includes(topic.id));
        const actionButtonClickHandler = allTopicsSelected ? this.onDeselectAllClick : this.onSelectAllClick;
        const actionButtonLabelId = allTopicsSelected
            ? 'profilePage.preferences.topics.deselectAll'
            : 'profilePage.preferences.topics.selectAll';

        let topics = category.topics.slice();

        if (this.state.sortId === 1 && renderHeader) {
            topics = topics.filter(item => { return !this.state.previousSelections.includes(item.id) });
        }

        if (!topics.length) {
            return null;
        }

        return (
            <CategoryContainer key={i}>
                {renderHeader && <div>
                    <h4>{category.name}</h4>
                    <div
                        onClick={() => { actionButtonClickHandler(category.topics); }}
                        data-analytics-placement="Button : body"
                        data-analytics-label="trackLink : button"
                        data-analytics-id={language.translations[actionButtonLabelId] + ":" + category.name}
                    >
                        <FormattedMessage id={actionButtonLabelId} />
                    </div>
                </div>}
                {!renderHeader && <div />}
                <div>
                    {topics.map(this.renderTopic)}
                </div>
            </CategoryContainer>
        );
    };

    /**
     * Render any sort controls.
     */
    renderSort() {
        return (
            <SortAndFilterContainer>
                <DropDownWrapper>
                    <DropDown
                        isEmptyOptionSelectable={false}
                        emptyOptionLabel={language.translations['practiceManagement.guidesAndFeatures.sort.ascending']}
                        showLabel={true}
                        onValueChanged={this.onSortChange}
                        value={this.state.sortId}
                        options={sortValues}
                        id="sort"
                        isNone="no"
                        notitle="no"
                        label={language.translations['practiceManagement.guidesAndFeatures.sort.label']}
                        title={language.translations['practiceManagement.guidesAndFeatures.sort.label']}
                        ariaLabel={language.translations['practiceManagement.guidesAndFeatures.sort.label']}
                    />
                </DropDownWrapper>
            </SortAndFilterContainer>
        );
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const { content, sortId, preferred } = this.state;

        return (
            <StyledList>
                {this.renderSort()}
                <Categories>
                    {sortId === 1
                        && this.renderCategory(preferred, 0, false)}
                    {content.map(this.renderCategory)}
                </Categories>
            </StyledList>
        );
    }
}

TopicsTabPane.defaultProps = {
    topicCategories: [],
};

TopicsTabPane.propTypes = {
    formik: PropTypes.shape({
        values: PropTypes.shape({
            topicIds: PropTypes.arrayOf(PropTypes.number).isRequired,
        }).isRequired,
        setFieldValue: PropTypes.func.isRequired,
    }).isRequired,
    topicCategories: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        topics: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })).isRequired,
    })),
    onTouch: PropTypes.func.isRequired,
    breakpoint: PropTypes.oneOf([
        DESKTOP,
        TABLET,
        MOBILE,
    ]).isRequired,
    intl: intlShape.isRequired,
};

export default injectIntl(TopicsTabPane);
