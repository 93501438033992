import React from 'react';

import {
    TOPIC_QUERY,
} from 'state-management/constants/pageableNews';
import LandingContainer from 'containers/PageableNews/LandingContainer';

/**
 * All guides page.
 * @param {*} props 
 */
const GuidesAndFeaturedContentContainer = props => {
    return (
        <LandingContainer
            {...props}
            noMaxWidth={true}
            displayResultCount={false}
            query={TOPIC_QUERY}
            headingTranslationId="practiceManagement.allguides"
            type="guidesandfeatures"
        />
    );
}

export default GuidesAndFeaturedContentContainer;
