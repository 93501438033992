import { css } from 'styled-components';
import { outerFocusMixin } from 'components/FocusVisibility/styles';
import {
    FROM_DESKTOP,
    SMALL_MOBILE,
    FROM_MOBILE_TO_DESKTOP,
} from 'utils/mediaQueries';
import {
    FONT_AVENIR_NEXT,
    COLORS,
    RGB_COLORS,
} from 'utils/variables';

export const activeClassName = 'selected';

export const navStyles = css`
    display: block;
`;

export const navListStyles = css`
    flex: 1 1 auto;
    flex-direction: column;
    list-style: none;
    margin: 20px 0 0;
    padding: 0 30px 0 40px;

    @media screen and ${FROM_DESKTOP} {
        align-items: center;
        display: flex;
        flex-direction: row;
        padding: 0;
        position: relative;
        margin-top: 8px;
    }
`;

const getCircleColor = (props) => {
    if (props && props.variant && props.variant.appearance) {
        switch (props.variant.appearance) {
         /* case 'blue':
            return '#5147dc';
        case 'sky-blue':
            return '#009ae1';
        case 'cyan':
            return '#00cbf1';
        default:
            return '#3122a3';*/
        default:
            return COLORS.SAPPHIRE;
        }
    }

    return COLORS.BLACK;
};

export const navLinkStyles = css`
    box-sizing: border-box;
    color: ${COLORS.DARK_GRAY2};
    cursor: pointer;
    display: inline-block;
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 28px;
    font-weight: 700;
    ${outerFocusMixin};
    padding: 0;
    position: relative;
    text-decoration: none;
    width: 100%;
    word-wrap: break-word;
    margin: 10px 5px 20px;

    &::before {
        bottom: 0;
        content: '';
        width: 100%;
        height: 4px;
        left: -24px;
        margin: auto 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    &.${activeClassName} {
        &::before {
            background-color: ${props => getCircleColor(props)};
            display: block;
        }
    }

    @media screen and ${SMALL_MOBILE} {
        font-size: 18px !important;
        font-weight: 600;
        line-height: 1;

        &::before {
            width: 10px;
            height: 10px;
        }

    }

    @media screen and ${FROM_MOBILE_TO_DESKTOP} {
        font-size: 20px;
        font-weight: 600;
        line-height: 1;

        &::before {
            width: 10px;
            height: 10px;
        }

    }
    
    @media screen and ${FROM_DESKTOP} {
        font-size: 13px;
        font-weight: 600;
        line-height: 1;
        display: inline;
        padding: 0;
        margin: 10px 24px;
        text-align: center;

        &::before {
            bottom: -12px;
            left: 0;
            margin: 0 auto;
            position: absolute;
            right: 0;
            top: auto;
        }

        &:not(.${activeClassName}) {
            &:hover {
                &::before {
                    background-color:rgba(${RGB_COLORS.SAPPHIRE}, .15);
                    display: block;
                }
            }
        }
    }
`;
