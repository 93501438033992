import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    IconArrowDown,
} from 'components/atomics/atoms/Icons/Icons';
import TopNews from '../../containers/News/TopNews/TopNews';
import { setScrollToTop } from 'state-management/actions/scroll';
import * as analytics from 'utils/adobeAnalytics';
import { getNews, resetNews } from 'state-management/actions/news';
import { resetTrendingTopicNews } from 'state-management/actions/trendingTopic.common';
import { PAGE_NAME_NEWS, SITE_SECTION_NEWS } from 'utils/analyticsConstants';
import LatestCapitalIdeas from 'containers/News/LatestCapitalIdeas/LatestCapitalIdeas';
import InfoBar from 'containers/News/InfoBar/InfoBar';
import TrendingTopics from '../../containers/News/TrendingTopics/TrendingTopics';
import SplashBanner from 'components/atomics/atoms/SplashBanner/SplashBanner';
import SplashNotification from 'components/atomics/molecules/SplashNotification/SplashNotification';
import { StyleBackToTopButton } from 'components/MarketBriefing/page/MarketBriefingPage';
import labels from '../../lang/en.json';
import { setError } from 'state-management/actions/errors';

export const COMPONENT_TYPES = {
    TOP_NEWS: 'component/topNews',
    TRENDING_TOPICS: 'components/trendingTopics',
    CAPITAL_IDEAS: 'components/capitalIdeas',
};

/**
 * The main news page.
 */
export class News extends React.Component {
    /**
     * Default constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            showBackToTop: false,
            enableScrollTracking: false,
            componentsFailed: [],
        }

        this.handleWindowScroll = this.handleWindowScroll.bind(this);
        this.backToTop = this.backToTop.bind(this);
    }

    /**
     * Gather required data for news page and setup listeners and scroll to top.
     */
    componentDidMount() {
        this.props.getNewsItems();
        this.props.scrollToTop();

        window.addEventListener('scroll', this.handleWindowScroll);
        if (typeof adobe != 'undefined' && adobe.target && typeof adobe.target.triggerView === 'function') {
            adobe.target.triggerView('/news');
        }
        setTimeout(
            function () {
                this.setState({ enableScrollTracking: true });
            }
                .bind(this),
            1000
        );
    }

    /**
     * Record component load failures, send to error page if all fail.
     * @param {*} componentId 
     */
    handleComponentFailure(componentId) {
        let failedComponents = this.state.componentsFailed;

        if (!failedComponents.includes(componentId)) {
            failedComponents.push(componentId);

            this.setState({
                componentsFailed: failedComponents,
            }, () => {
                if (this.state.componentsFailed.length >= 3) {
                    this.props.showError();
                }
            });
        }
    }

    /**
     * Scroll the user back to the top of page.
     */
    backToTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behaviour: 'smooth'
        });
    }

    /**
     * Track window scrolling for back to top button usage.
     */
    handleWindowScroll() {
        const scrollTop = document.body.scrollTop
            ? document.body.scrollTop : document.documentElement.scrollTop;
        if (scrollTop > 0) {
            this.setState({
                showBackToTop: true,
            });
        } else {
            this.setState({
                showBackToTop: false,
            });
        }
    }

    /**
     * Dispatch page view at data completion.
     * @param {*} prevProps 
     */
    componentDidUpdate(prevProps) {
        if (!prevProps.topNewsIsLoaded && this.props.topNewsIsLoaded) {
            analytics.dispatchPageViewEnd(PAGE_NAME_NEWS, SITE_SECTION_NEWS);
        }
    }

    /**
     * Reset news data and remove window listeners at destroy.
     */
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleWindowScroll);
        this.props.resetNews();
        this.props.resetTrendingTopicNews();
    }

    /**
     * Render the back to top sticky button.
     */
    renderBackToTop() {
        return (
            <StyleBackToTopButton
                aria-label={labels.translations['marketBriefing.page.scrollToTop']}
                type="button"
                id="backToTopLink"
                onClick={this.backToTop}
                data-analytics-placement="Button : body"
                data-analytics-label="trackLink : button"
                data-analytics-id="backtotop:News Page"
            >
                <IconArrowDown />
            </StyleBackToTopButton>
        );
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const { isLoading } = this.props;
        const splashTitle = labels.translations['news.caughtUp'];
        return (
            <React.Fragment>
                {/*<HomepagePrompt />*/}
                <InfoBar
                    showBriefing={this.props.subscribedSources.includes(115)}
                />
                <SplashBanner
                    name={this.props.name}
                />
                <TopNews
                    handleComponentFailure={() => {
                        this.handleComponentFailure(COMPONENT_TYPES.TOP_NEWS)
                    }}
                />
                <LatestCapitalIdeas
                    handleComponentFailure={() => {
                        this.handleComponentFailure(COMPONENT_TYPES.CAPITAL_IDEAS)
                    }}
                />
                <TrendingTopics
                    handleComponentFailure={() => {
                        this.handleComponentFailure(COMPONENT_TYPES.TRENDING_TOPICS)
                    }}
                    isLoading={isLoading}
                />
                <SplashNotification
                    title={splashTitle}
                    buttonLabel={labels.translations['news.splashNotification']}
                    url="/learn"
                />
                {this.state.showBackToTop
                    && this.renderBackToTop()}
            </React.Fragment>
        );
    }
}

News.propTypes = {
    scrollToTop: PropTypes.func.isRequired,
    resetNews: PropTypes.func,
    resetTrendingTopicNews: PropTypes.func.isRequired,
    topNewsIsLoaded: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    getNews: PropTypes.func,
    id: PropTypes.string,
    subscribedSources: PropTypes.array,
    email: PropTypes.string,
    name: PropTypes.string,
};

News.defaultProps = {
    resetNews: () => { },
    subscribedSources: [],
    email: null,
    name: null,
    getNewsItems: () => { },
};

const mapStateToProps = state => ({
    topNewsIsLoaded: state.topNews.isLoaded,
    isLoading: state.topNews.isLoading || state.trendingTopicNews.isLoading
        || state.latestCapitalIdeas.isLoading || state.basicProfile.isLoading
        || state.marketNews.isLoading,
    subscribedSources: state.basicProfile.sourceIds,
    email: state.basicProfile.email,
    name: state.basicProfile.firstName,
});

const mapDispatchToProps = dispatch => ({
    scrollToTop: () => dispatch(setScrollToTop()),
    resetNews: () => dispatch(resetNews()),
    resetTrendingTopicNews: () => dispatch(resetTrendingTopicNews()),
    getNewsItems: () => dispatch(getNews()),
    showError: () => dispatch(setError('global.errors.componentLoader', { status: 500 })),
});

export default connect(mapStateToProps, mapDispatchToProps)(News);
