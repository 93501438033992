import { css } from 'styled-components';
import { APPEARANCE, SIZES } from 'components/Button/constants';
import { outerFocusMixin } from 'components/FocusVisibility/styles';
import { FROM_DESKTOP, FROM_MOBILE_TO_TABLET, FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS } from 'utils/variables';

const disabledButtonStyles = ({ variant }) => css`
    /* stylelint-disable */

    ${variant.appearance === APPEARANCE.PRIMARY && css`
        background-color: ${COLORS.LIGHT_GRAY3} !important;
        border-color: ${COLORS.LIGHT_GRAY3} !important;
    `}

    ${((variant.appearance === APPEARANCE.SECONDARY_LIGHT) || (variant.appearance === APPEARANCE.SECONDARY_DARK)) && css`
        color: ${COLORS.LIGHT_GRAY3};
    `}

    /* stylelint-enable */
`;

const baseStyles = ({ variant }) => css`
    border-style: solid;
    box-sizing: border-box;
    display: inline-block;
    font-family: ${FONT_AVENIR_NEXT};
    font-weight: 600;
    text-align: center;

    &:not(:disabled) {
        cursor: pointer;
    }

    /* stylelint-disable */

    ${variant.appearance !== APPEARANCE.ICON && variant.appearance !== APPEARANCE.DEFAULT && css`
        @media screen and ${FROM_DESKTOP} {

            /* stylelint-disable */

            ${((variant.sizeOnDesktop === SIZES.SMALL) || (!variant.sizeOnDesktop && variant.size === SIZES.SMALL)) && css`
                border-width: 1px;
                font-size: 10px;
                height: 36px;
                letter-spacing: 2.4px;
                min-width: 94px;
                padding: 0 12px;
            `};

            ${((variant.sizeOnDesktop === SIZES.MEDIUM) || (!variant.sizeOnDesktop && variant.size === SIZES.MEDIUM)) && css`
                border-width: 1px !important;
                font-size: 19px;
                height: auto;
                letter-spacing: 2.4px;
                padding: 18px 48px;
                font-weight: 700;
            `};

            ${((variant.sizeOnDesktop === SIZES.LARGE) || (!variant.sizeOnDesktop && variant.size === SIZES.LARGE)) && css`
                border-width: 2px;
                font-size: 14px;
                height: 54px;
                letter-spacing: 0;
                padding: 0 30px;
            `};

            /* stylelint-enable */
        }

        @media screen and ${FROM_TABLET} {

            /* stylelint-disable */

            ${((variant.sizeOnTablet === SIZES.SMALL) || (!variant.sizeOnTablet && variant.size === SIZES.SMALL)) && css`
                border-width: 1px;
                font-size: 10px;
                height: 36px;
                letter-spacing: 2.4px;
                min-width: 94px;
                padding: 0 12px;
            `};

            ${((variant.sizeOnTablet === SIZES.MEDIUM) || (!variant.sizeOnTablet && variant.size === SIZES.MEDIUM)) && css`
                border-width: 2px;
                font-size: 19px;
                height: auto;
                letter-spacing: 2.4px;
                padding: 18px 48px;
            `};

            ${((variant.sizeOnTablet === SIZES.LARGE) || (!variant.sizeOnTablet && variant.size === SIZES.LARGE)) && css`
                border-width: 2px;
                font-size: 14px;
                height: 54px;
                letter-spacing: 0;
                padding: 0 30px;
            `};

            /* stylelint-enable */
        }

        @media screen and ${FROM_MOBILE_TO_TABLET} {

            /* stylelint-disable */

            ${((variant.sizeOnMobile === SIZES.SMALL) || (!variant.sizeOnMobile && variant.size === SIZES.SMALL)) && css`
                border-width: 2px;
                font-size: 10px;
                height: 36px;
                letter-spacing: 2px;
                min-width: 94px;
                padding: 0 12px;
            `};

            ${((variant.sizeOnMobile === SIZES.MEDIUM) || (!variant.sizeOnMobile && variant.size === SIZES.MEDIUM)) && css`
                border-width: 2px;
                font-size: 12px;
                height: 44px;
                letter-spacing: 2.4px;
                padding: 0 30px;
            `};

            ${((variant.sizeOnMobile === SIZES.LARGE) || (!variant.sizeOnMobile && variant.size === SIZES.LARGE)) && css`
                border-width: 2px;
                font-size: 14px;
                height: 54px;
                letter-spacing: 0;
                padding: 0 30px;
            `};

            /* stylelint-enable */
        }
    `};

    ${variant.appearance === APPEARANCE.DEFAULT && css`
        background-color: transparent;
        border-color: transparent;
    `}

    ${variant.appearance === APPEARANCE.PRIMARY && css`
        color: ${COLORS.WHITE};
        text-transform: capitalize;
        background-color: ${COLORS.OCEAN};

        &:disabled {
            ${disabledButtonStyles}
        }

        /* stylelint-disable */

        ${!variant.isVisuallyDisabled && css`
            background-color: ${COLORS.OCEAN};
            border-color: transparent;

            &:not(:disabled):hover {
                background-color: ${COLORS.SAPPHIRE};
                border-color: ${COLORS.SAPPHIRE};
            }
        `}

        ${variant.isVisuallyDisabled && css`
            ${disabledButtonStyles}
        `}

        /* stylelint-enable */
    `}

    ${((variant.appearance === APPEARANCE.SECONDARY_LIGHT) || (variant.appearance === APPEARANCE.SECONDARY_DARK)) && css`
        background-color: transparent;
        border-color: ${COLORS.LIGHT_GRAY3};
        text-transform: capitalize;

        &:disabled {
            ${disabledButtonStyles}
        }

        /* stylelint-disable */

        ${variant.isVisuallyDisabled && css`
            ${disabledButtonStyles}
        `}

        /* stylelint-enable */
    `};

    ${variant.appearance === APPEARANCE.SECONDARY_LIGHT && css`
        color: ${COLORS.MEDIUM_GRAY2};

        &:not(:disabled):hover {
            border-color: ${COLORS.SAPPHIRE};
        }
    `};

    ${variant.appearance === APPEARANCE.SECONDARY_DARK && css`
        color: ${COLORS.WHITE};

        &:not(:disabled):hover {
            border-color: ${COLORS.WHITE};
        }
    `};

    ${variant.appearance === APPEARANCE.TRANSPARENT && css`
        background-color: transparent;
        border-color: ${COLORS.LIGHT_GRAY3};
        color: ${COLORS.MEDIUM_GRAY2};
        text-transform: capitalize;

        &:hover {
            background-color: ${COLORS.WHITE};
            color: ${COLORS.BLACK};
        }
    `}

    ${variant.appearance === APPEARANCE.ICON && css`
        background-color: transparent;
        border-color: transparent;
        color: ${COLORS.MEDIUM_GRAY2};
        text-transform: uppercase;

        &:disabled {
            opacity: 0.15;
        }
    `}

    /* stylelint-enable */
`;

const urlStyles = ({ variant }) => css`
    text-decoration: none;

    /* stylelint-disable */

    ${variant.appearance !== APPEARANCE.ICON && variant.appearance !== APPEARANCE.DEFAULT && css`
        @media screen and ${FROM_TABLET} {

            /* stylelint-disable */

            ${((variant.sizeOnDesktop === SIZES.SMALL) || (!variant.sizeOnDesktop && variant.size === SIZES.SMALL)) && css`
                line-height: 34px;
            `};

            ${((variant.sizeOnDesktop === SIZES.MEDIUM) || (!variant.sizeOnDesktop && variant.size === SIZES.MEDIUM)) && css`
                line-height: 40px;
            `};

            ${((variant.sizeOnDesktop === SIZES.LARGE) || (!variant.sizeOnDesktop && variant.size === SIZES.LARGE)) && css`
                line-height: 50px;
            `};

            /* stylelint-enable */
        }

        @media screen and ${FROM_MOBILE_TO_TABLET} {

            /* stylelint-disable */

            ${((variant.sizeOnDesktop === SIZES.SMALL) || (!variant.sizeOnDesktop && variant.size === SIZES.SMALL)) && css`
                line-height: 34px;
            `};

            ${((variant.sizeOnDesktop === SIZES.MEDIUM) || (!variant.sizeOnDesktop && variant.size === SIZES.MEDIUM)) && css`
                line-height: 40px;
            `};

            ${((variant.sizeOnDesktop === SIZES.LARGE) || (!variant.sizeOnDesktop && variant.size === SIZES.LARGE)) && css`
                line-height: 50px;
            `};

            /* stylelint-enable */
        }
    `};

    /* stylelint-enable */
`;

export const buttonStyles = css`
    ${baseStyles};
    ${outerFocusMixin};
`;

export const linkStyles = css`
    ${baseStyles};
    ${urlStyles};
    ${outerFocusMixin};
`;
