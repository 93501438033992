import { css } from 'styled-components';
import { getSpanInPercent } from 'utils/grid';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import {
    FONT_AVENIR_NEXT,
    FONTS,
    COLORS,
    ANIMATIONS,
} from 'utils/variables';

export const formContentContainerStyles = css`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    text-align: center;

    @media screen and ${FROM_TABLET} {
        flex-direction: column;
        justify-content: flex-start;
        text-align: left;
    }
`;

export const fieldsWrapperStyles = css`
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    @media screen and ${FROM_TABLET} {
        margin: 0;
        width: 100%;
    }
`;

export const fieldWrapperStyles = css`
    margin-bottom: 30px;
    padding-left: 26px;
    padding-right: 26px;

    @media screen and ${FROM_TABLET} {
        margin-bottom: 18px;
        width: 100%;
        padding: 0;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 25px;
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

export const passwordRulesStyles = css`
    padding-left: 26px;
    padding-right: 26px;

    @media screen and ${FROM_TABLET} {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }
`;

export const controlsStyles = css`
    display: flex;
    justify-content: center;
    margin-top: 60px;

    @media screen and ${FROM_TABLET} {
        justify-content: flex-end;
        margin-top: 35px;
    }
`;

export const buttonStyles = css`
    border: 0;
    display: inline-block;
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    text-align: center;
    text-decoration: none;
    transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;
    cursor: pointer;
    background-color: ${COLORS.SAPPHIRE};
    color: ${COLORS.WHITE};
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 1.5px;
    padding: 20px 18px 20px 18px;
    text-transform: uppercase;
    height: auto;
    width: 100%;
    margin: auto;
    margin-top: 40px !important;

    @media screen and ${FROM_TABLET} {
        width: auto;
        margin: 0;
        margin-top: 0 !important;
    }

    &:hover {
        background-color: ${COLORS.SAPPHIRE} !important;
    }

    /* stylelint-disable */

    ${props => props.isDisabled && css`
        background-color:  ${COLORS.LIGHT_GRAY3};

        &:hover {
            background-color:  ${COLORS.LIGHT_GRAY3} !important;
            cursor: not-allowed;
        }
    `};

    /* stylelint-enable */
`;

export const errorStyles = css`
    color: ${COLORS.ALERT_RED}
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 14px;
    line-height: 1;
    margin-top: 15px;
    text-align: center;
    width: 100%;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        margin: 20px 0 0 auto;
        max-width: ${getSpanInPercent(5)};
        text-align: right;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-top: 25px;
        max-width: 100%;
    }
`;
