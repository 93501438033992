import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { injectIntl, intlShape } from 'react-intl';

import { UnderlinedCheckbox } from 'components';
import { itemStyles, listStyles } from 'containers/Search/SearchFilterItems/styles';

const StyledList = styled.ul`
    ${listStyles}
`;

export const StyledUnderlinedCheckbox = styled(UnderlinedCheckbox)`
    ${itemStyles}
`;

/**
 * Render a set of filter items for search results filter menu.
 * @param {*} param0 
 */
const SearchFilterItems = ({ intl, section, onToggleItem }) => {
    const getLabel = item => (item.translationId
        ? intl.formatMessage({ id: item.translationId })
        : item.name);

    return (
        <StyledList>
            {section.items.map((item, idx) => (
                <li key={item.id || item.name}>
                    <StyledUnderlinedCheckbox
                        checked={item.checked}
                        label={getLabel(item)}
                        onChange={() => onToggleItem(section.title, idx)}
                    />
                </li>))}
        </StyledList>
    );
};

SearchFilterItems.propTypes = {
    intl: intlShape.isRequired,
    section: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
    onToggleItem: PropTypes.func.isRequired,
};

export default injectIntl(SearchFilterItems);
