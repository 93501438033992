import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { camelCase } from 'lodash';
import storage from 'utils/store';
import { getPageableNews } from 'state-management/actions/news';
import ConnectedPageableNews from 'containers/PageableNews/PageableNews';
import Loader from 'components/Loader/Loader';
import { TOP_NEWS_DATA } from 'containers/News/TopNews/TopNews';

/**
 * Generic pageable news wrapping container.
 */
class PageableNewsContainer extends React.Component {
    /**
     * Handle basic data needs at mount.
     */
    componentDidMount() {
        const { trendingTopic } = this.props;
        const { section } = this.props.match.params;
        const basicProfile = {};

        let previousUrl = "not News";
            const urlLink = store.getState().UrlHistoryReducer.history[0].currentUrl;
            let pathName = store.getState().router.location.pathname;
                if(pathName =="/news/top" || pathName =="/news/capital-ideas"){
                    if(urlLink == "/news?b=1" || urlLink == "/news"){
                     previousUrl = "newsPage";
                        
                    }
                }

        Promise.resolve(JSON.parse(storage.get(TOP_NEWS_DATA))).then(excludedArticleIds => {
            if (previousUrl != "newsPage"){                
                excludedArticleIds = []; 
            }

            this.props.getPageableNews(section, excludedArticleIds ? excludedArticleIds : [1,2], trendingTopic, basicProfile);
        });
    }

    /**
     * Render this and underlying pages.
     */
    render() {
        const { section } = this.props.match.params;

        if (this.props.isLoading) {
            return <Loader />;
        }

        return (
            <ConnectedPageableNews
                content={this.props.news}
                headingTranslationId={camelCase(section)}
            />
        );
    }
}

PageableNewsContainer.propTypes = {
    getPageableNews: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            section: PropTypes.string,
        }),
        path: PropTypes.string.isRequired,
    }).isRequired,
    news: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    isLoading: PropTypes.bool.isRequired,
    queryParams: PropTypes.shape({
        excluded: PropTypes.string,
        aumId: PropTypes.string,
        durationId: PropTypes.string,
        serviceOfferingId: PropTypes.string,
    }).isRequired,
    basicProfile: PropTypes.shape({
        aumId: PropTypes.number,
        durationId: PropTypes.number,
        serviceOfferingId: PropTypes.number,
    }).isRequired,
    trendingTopic: PropTypes.shape({
        id: PropTypes.number,
    }),
};

PageableNewsContainer.defaultProps = {
    trendingTopic: null,
};

const mapStateToProps = state => ({
    news: state.pageableNews.news,
    isLoading: state.pageableNews.isLoading,
    queryParams: state.location.query,
    basicProfile: state.basicProfile,
    trendingTopic: state.trendingTopicNews.topic,
});

const mapDispatchToProps = dispatch => ({
    getPageableNews:
        (section, excludedArticleIds, trendingTopic, basicProfile) => dispatch(
            getPageableNews(section, excludedArticleIds, trendingTopic, basicProfile),
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageableNewsContainer);
