import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET,SMALL_MOBILE } from '../../utils/mediaQueries';
import { SIZE } from 'components/ContentCard/constants';
import {
    COLORS,
} from 'utils/variables';

export const listStyles = css`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 0 0 -16px;
    padding: 0 8px 0 0;    
    display: inline-flex;
    @media screen and ${SMALL_MOBILE} {
        display: inline-block;
    }
`;

export const itemStyles = css`
     padding-left: 16px;
    word-wrap: break-word;
    color: ${COLORS.SAPPHIRE};
    padding-right: 25px;   
    padding-top: 5px;
`;

export const linkStyles = css`
    color: ${COLORS.SAPPHIRE};
    text-decoration: none;
    :hover{
        color: ${COLORS.SAPPHIRE};
    }
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;

`;