import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS } from 'utils/variables';

export const noResultsContainerStyles = css`
    padding-top: 20px;
    padding-bottom: 80px;
    text-align: center;

    @media screen and ${FROM_TABLET} {
        padding-top: 0;
        text-align: left;
    }
`;

export const noResultsWrapperStyles = css`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 40px;
    padding-top: 0;

    @media screen and ${FROM_TABLET} {
        margin: 0 auto;
        width: 70%;
    }

    @media screen and ${FROM_DESKTOP} {
        padding-bottom: 95px;
        padding-top: 95px;
    }
`;

export const noResultsHeadingStyles = css`
    color: ${COLORS.SAPPHIRE};
    font-family: ${FONT_AVENIR_NEXT};
    font-weight: 400;
    font-size: 26px;
    line-height: 31px;
    letter-spacing: -0.11px
    margin: 0;
    text-align: center;
    width: 100%;
    word-wrap: break-word;
`;

export const noResultsIconStyles = css`
    padding: 30px 0;

    @media screen and ${FROM_DESKTOP} {
        padding: 40px 0;
    }
`;

export const noResultsContactUsMessageStyles = css`
    color: ${COLORS.BLACK};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 15px;
    font-weight: normal;
    margin: 0;
    text-align: center;
    width: 80%;

    a {
        color: ${COLORS.SAPPHIRE};
        text-decoration: none;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.26px;
    }
`;

export const resultHeaderContainerStyles = css`
    background-color: ${COLORS.WHITE};
    box-sizing: border-box;
    font-family: ${FONT_AVENIR_NEXT};
    padding: 5px 0;
    text-align: left;
    padding-bottom: 90px;

    @media screen and ${FROM_TABLET} {
        padding-bottom: 72px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding-top: 27px;
        padding-bottom: 54px;
    }

    h1 {
        font-size: 20px !important;
        font-weight: 600 !important;
        color: ${COLORS.BLACK} !important;
        letter-spacing: -0.1px !important;

        @media screen and ${FROM_TABLET} {
            letter-spacing: -0.08px !important;
        }

        @media screen and ${FROM_DESKTOP} {
            font-size: 26px !important;
            letter-spacing: -0.1px !important;
        }
    }

    p {
        color: ${COLORS.BLACK};
        font-size: 13px;
        line-height: 17px;
        letter-spacing: -0.06px;

        @media screen and ${FROM_TABLET} {
            font-size: 15px;
            line-height: 22px;
            letter-spacing: -0.08px;
        }

        @media screen and ${FROM_DESKTOP} {
            font-size: 18px;
            letter-spacing: -0.1px;
        }
    }
`;

export const resultQueryStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-size: 28px;
    font-weight: 700;
    line-height: 1.14;
    margin: 0;
    width: 100%;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        text-align: left;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 48px;
        line-height: 1.35;
    }
`;
