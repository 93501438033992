import { css } from 'styled-components';
import { getSpanInPercent } from 'utils/grid';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import {
    FONT_AVENIR_NEXT,
    COLORS,
    ANIMATIONS,
    FONTS,
} from 'utils/variables';

export const forgotPasswordSectionStyles = css`
    background-color: ${COLORS.LIGHT_GRAY1};
    flex-grow: 1;
    height: 100%;
    padding: 48px 0 60px;

    @media screen and ${FROM_TABLET} {
        padding: 60px 0;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 120px 0 100px;
    }
`;

export const containerStyles = css`
    padding: 0 30px;

    @media screen and ${FROM_TABLET} {
        padding: 0 38px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 0 48px;
    }
`;

export const contentWrapperStyles = css`
    box-sizing: border-box;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    ${props => !props.isNotForm && css`
        @media screen and ${FROM_TABLET} {
            flex-direction: row;
            justify-content: space-between;
        }
    `};
`;

export const headingContainerStyles = css`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-wrap: wrap;
    margin-bottom: 55px;

    @media screen and ${FROM_TABLET} {
        margin-bottom: 35px;
    }

    @media screen and ${FROM_DESKTOP} {
        width: ${getSpanInPercent(6)};
    }

    ${props => props.isNotForm && css`
        margin-bottom: 0 !important;
    `}
`;

export const headingIconStyles = css`
    display: none;
`;

export const headingStyles = css`
    color: ${COLORS.SAPPHIRE};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 26px;
    font-weight: 400;
    letter-spacing: -0.85px;
    line-height: 32px;
    position: relative;
    text-align: left;
    word-wrap: break-word;
    padding-left: 26px;
    max-width: 200px;

    @media screen and ${FROM_TABLET} {
        margin-left: 52px;
        max-width: 280px;
        padding-left: 0;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 42px;
        line-height: 52px;
        margin-left: 105px;
        max-width: initial;
    }

    &::before {
        background-image: linear-gradient(to right, ${COLORS.LIGHT_OCEAN}, ${COLORS.OCEAN});
        content: '';
        display: block;
        height: 5px;
        left: 18px;
        position: absolute;
        top: 11px;
        transform: translateX(-100%);
        width: 48px;

        @media screen and ${FROM_TABLET} {
            top: 11px;
            left: -15px;
            width: 75px;
        }

        @media screen and ${FROM_DESKTOP} {
            top: 20px;
            min-width: 190px;
            width: calc(100vw - 1170px);
        }
    }
`;

export const contentContainerStyles = css`
    ${props => !props.isNotForm && css`
        display: flex;
        flex-direction: column;
        flex-shrink: 0;

        @media screen and ${FROM_TABLET} {
            width: ${getSpanInPercent(6)};
            position: relative;
            top: -22px;
        }

        @media screen and ${FROM_DESKTOP} {
            top: -16px;
        }
    `}

    ${props => props.isNotForm && css`
        @media screen and ${FROM_TABLET} {
            padding-left: 52px;
        }

        @media screen and ${FROM_DESKTOP} {
            padding-left: 105px;
        }
    `}
`;

export const messageContainerStyles = css`
    display: flex;
    flex-direction: column;

    @media screen and ${FROM_DESKTOP} {
        align-self: center;
        width: ${getSpanInPercent(8)};
    }
`;

export const messageIconContainer = css`
    margin: 0 auto 45px;
    text-align: center;
`;

export const successIconWrapperStyles = css`
    align-items: center;
    background-color: ${COLORS.SAPPHIRE};
    border-radius: 50%;
    display: flex;
    height: 30px;
    justify-content: center;
    margin: 0 auto 40px;
    width: 30px;
`;

export const successIconStyles = css`
    fill: ${COLORS.WHITE};
`;

export const errorIconStyles = css`
    fill: ${COLORS.ALERT_RED};
`;

export const alertMessageStyles = css`
    color: ${COLORS.BLACK};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.08px;
    margin: 0;
    text-align: left;
    font-weight: 500;
    padding-left: 26px;
    margin-top: 24px;
    margin-bottom: 44px;

    ${props => props.isError && css`
        color: ${COLORS.ALERT_RED};
    `};


    @media screen and ${FROM_TABLET} {
        padding-left: 0;
        margin-top: 35px;
        margin-bottom: 35px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.1px;
        margin-top: 48px;
        margin-bottom: 48px;
    }
`;

export const alertButtonStyles = css`
    border: 0;
    display: inline-block;
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    text-align: center;
    text-decoration: none;
    transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;
    cursor: pointer;
    background-color: ${COLORS.SAPPHIRE};
    color: ${COLORS.WHITE};
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 1.5px;
    padding: 20px 18px 20px 18px;
    text-transform: uppercase;
    height: auto;
    width: 100%;

    &:hover {
        background-color: ${COLORS.SAPPHIRE} !important;
    }

    @media screen and ${FROM_TABLET} {
        width: 236px;
    }
`;
