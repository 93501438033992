import { css } from 'styled-components';
import { 
    FROM_TABLET,
    FROM_DESKTOP,
} from 'utils/mediaQueries';
import {
    COLORS,
} from 'utils/variables';

export const placeholderStyles = css`
    height: 122px;
    overflow: hidden;
    background: ${COLORS.WHITE};

    @media screen and ${FROM_TABLET} {
        height: 95px;
    }
`;

export const actionBarStyles = css`
    background: ${COLORS.WHITE};
    height: 100px;
    padding-top: 22px;
    text-align: center;
    padding-right: 16px;
    padding-left: 16px;

    a {
        display: block;
    }

    a:first-child {
        margin-bottom: 16px;
    }

    @media screen and ${FROM_TABLET} {
        height: 95px;
        padding-top: 0;
        text-align: right;
        padding-right: 38px;

        a {
            display: inline-block;
            position: relative;
            top: 18px;
        }

        a:first-child {
            margin-right: 15px;
            margin-bottom: 0;
        }
        
        a:last-child {
            margin-left: 15px;
        }
    }

    @media screen and ${FROM_DESKTOP} {
        padding-right: 48px;
    }

    ${props => props.isSticky && css`
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    `}
`;

export const contentContainerStyles = css`
    padding-top: 40px;
    padding-bottom: 40px;
    margin-left: 0;
    margin-right: 0;

    @media screen and ${FROM_TABLET} {
        padding-top: 100px;
        margin-left: auto;
        margin-right: auto;
    }
`;
