export const SIGN_IN = 'signIn/SIGN_IN';
export const SIGN_IN_SUCCESS = 'signIn/SIGN_IN_SUCCESS';
export const SIGN_IN_FAIL = 'signIn/SIGN_IN_FAIL';
export const REAUTHENTICATE = 'signIn/REAUTHENTICATE';

export const SIGN_IN_URL = '/security-service/login';
export const LOGOUT_URL = '/security-service/logout';
export const REFRESH_URL = '/security-service/refresh';
export const CSRF_URL = '/security-service/csrf';

export const FIRST_NAME = 'first_name';

export const REDIRECT_AFTER_LOGIN_URL = 'REDIRECT_AFTER_LOGIN_URL';

export const IDENTITY_PROFILE_URL = '/advisor-service/identity/profiles/me';
