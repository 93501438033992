import React from 'react';
import PropTypes from 'prop-types';

import { TYPE } from 'components/ContentCard/constants';
import { isSubtypeOfGuide, isPracticeLab } from 'utils/contentCardUtils';
import { TYPE_ARTICLE, TYPED_SEARCH_PAGE_SIZE } from 'state-management/constants/search';
import TypedIndexView from './TypedIndexView';

/**
 * Wrapper for all typed index views.
 * Search results areas when filter by types is selected from search page filter menu.
 * @param {*} props 
 */
const TypedIndexViews = (props) => {
    const {
        items,
        onSeeMoreNews,
        onSeeMorePracticeManagement,
    } = props;
    
    const news = items.filter((i) => {
        return (i.type === TYPE_ARTICLE && !isPracticeLab(i.sourceTitle));
    }).slice(0, TYPED_SEARCH_PAGE_SIZE);
    
    const guides = items.filter((i) => {
        return (isSubtypeOfGuide(i.type) || isPracticeLab(i.sourceTitle));
    }).slice(0, TYPED_SEARCH_PAGE_SIZE);

    return (
        <React.Fragment>
            <TypedIndexView
                type={TYPE.ARTICLE}
                items={news}
                pageSize={TYPED_SEARCH_PAGE_SIZE}
                onSeeMore={onSeeMoreNews}
            />
            <TypedIndexView
                type={TYPE.GUIDE_STANDARD}
                items={guides}
                pageSize={TYPED_SEARCH_PAGE_SIZE}
                onSeeMore={onSeeMorePracticeManagement}
            />
        </React.Fragment>
    );
};

TypedIndexViews.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        category: PropTypes.string,
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        image: PropTypes.string,
        internal: PropTypes.bool,
        isSaving: PropTypes.bool,
        link: PropTypes.string,
        publishDate: PropTypes.string,
        saved: PropTypes.bool,
        sourceLogo: PropTypes.string,
        sourceTitle: PropTypes.string,
        title: PropTypes.string,
        topics: PropTypes.array,
        type: PropTypes.string,
        visited: PropTypes.bool,
        contentType: PropTypes.string,
        interactionType: PropTypes.string,
        interactionId: PropTypes.string,
    })),
    onSeeMoreNews: PropTypes.func.isRequired,
    onSeeMorePracticeManagement: PropTypes.func.isRequired,
};

TypedIndexViews.defaultProps = {
    items: [],
};

export default TypedIndexViews;
