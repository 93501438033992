import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_MOBILE_TO_TABLET, FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS, ANIMATIONS, RGB_COLORS } from 'utils/variables';

export const cardStyles = css`
    box-sizing: border-box;
    padding-left: 12px;
    width: 50%;

    @media screen and ${FROM_TABLET} {
        padding-left: 38px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding-left: 47px;
    }
`;

export const clickableContainerStyles = css`
    cursor: pointer;

    &:hover {
        svg {
            stroke: ${COLORS.DARK_SAPPHIRE} !important;
        }
    }
`;

export const imageContainerStyles = css`
    box-shadow: 0 4px 20px 0 rgba(${RGB_COLORS.BLACK}, 0.16);
    height: 140px;
    margin-bottom: 11px;
    position: relative;
    text-align: center;
    background-color: ${COLORS.WHITE};

    img {
        margin: auto;
        height: 70px;
        margin-top: 35px;
    }

    @media screen and ${FROM_TABLET} {
        height 150px;
        max-width: 200px;

        img {
            margin-top: 40px; 
        }
    }

    @media screen and ${FROM_DESKTOP} {
        height: 250px;
        max-width: none;
        
        img {
            height: 100px;
            margin-top: 75px;
        }
    }
`;

export const headingStyles = css`
    color: ${COLORS.MEDIUM_GRAY2};
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        font-size: 24px;
        line-height: 1.3;
        text-align: left;
    }
`;

export const linkStyles = css`
    color: inherit;
    display: block;
    text-decoration: none;

    @media screen and ${FROM_MOBILE_TO_TABLET} {
        word-spacing: 100vw;
    }
`;

export const descriptionStyles = css`
    color: ${COLORS.MEDIUM_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.3px;
    margin: 0;
    padding-top: 10px;
    white-space: pre-wrap;
    word-wrap: break-word;

    @media screen and ${FROM_MOBILE_TO_TABLET} {
        display: none;
    }
`;

export const headingIconStyles = css`
    margin-top: 10px;
    stroke: ${COLORS.SAPPHIRE};
    stroke-width: 2px;

    @media screen and ${FROM_TABLET} {
        display: none;
    }
`;

export const descriptionIconStyles = css`
    margin: -1px 0 0 8px;
    stroke: ${COLORS.SAPPHIRE};
    stroke-width: 2px;
    transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;

    &:hover {
        stroke: ${COLORS.DARK_SAPPHIRE};
    }
`;
