import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'components/atomics/atoms/Link/Link';
import {
    categorySectionWrapperStyles,
    wrapperOverrideStyles,
} from 'components/Topics/TopicsPanel/styles';
import CoachMarks from 'containers/CoachMarks/CoachMarks';
import { TYPES } from 'containers/CoachMarks/CoachMarks';
import {
    COLORS,
} from 'utils/variables';

const StyledSectionWrapper = styled.div`
    ${categorySectionWrapperStyles}
`;

const StyledTopicsWrapper = styled.div`
    ${wrapperOverrideStyles}
`;

const LinkWrapper = styled.div`
    a {
      color: ${COLORS.SAPPHIRE} !important;
      text-decoration: none;
      cursor: pointer;
      width: 100%;
      display: block;

      &:hover{
        color: ${COLORS.DARK_SAPPHIRE} !important;
      }
    }
`;

/**
 * Render a single topic, include coachmarks as needed.
 * @param {*} topic
 * @param {*} selectedTopicIds
 * @param {*} showCoachMark
 * @param {*} onTopicToggle
 */
const renderTopic = (topic, selectedTopicIds, showCoachMark, onTopicToggle, onTopicClick, breakpoint, onHistoryAdd, list) => {
    const itemNumber = breakpoint === 'mobile' ? list : list;
    const showFirstCoachMark = showCoachMark && topic.id === itemNumber;

    if (showFirstCoachMark) {
        return (
            <CoachMarks
                type={TYPES.ALLTOPICS}
                key={topic.id}
            >
                <LinkWrapper onClick={() => onHistoryAdd(`/topics/${topic.name}/${topic.id}`)}>
                    <Link
                        onClick={onTopicClick}
                        to={`/topics/${topic.name}/${topic.id}`}
                    >
                        <span>{topic.name}</span>
                    </Link>
                </LinkWrapper>
            </CoachMarks>
        );
    }

    return (
        <div onClick={() => onHistoryAdd(`/topics/${topic.name}/${topic.id}`)}>
            <Link
                key={topic.id}
                onClick={onTopicClick}
                to={`/topics/${topic.name}/${topic.id}`}
            >
                <span >{topic.name}</span>
            </Link>
        </div>
    );
};

/**
 * Display a set of selectable topics (for topics page usage).
 * @param {*} param0
 */
const TopicsPanel = ({
    topics,
    selectedTopicIds,
    onSelectionChange,
    showOnlySelected,
    showCoachMark,
    onTopicClick,
    onDismiss,
    breakpoint, onHistoryAdd, list
}) => {
    const onTopicToggle = (id) => {
        onSelectionChange(id);
    };


    let myTopics = topics;

    if (showOnlySelected) {
        myTopics = topics.filter(item => selectedTopicIds.includes(item.id));
    }

    return (
        <StyledSectionWrapper>
            <StyledTopicsWrapper>
                {myTopics.map(topic => renderTopic(topic,
                    selectedTopicIds, showCoachMark, onTopicToggle, onDismiss, breakpoint, onHistoryAdd, list))}
            </StyledTopicsWrapper>
        </StyledSectionWrapper>
    );
};

TopicsPanel.propTypes = {
    topics: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    selectedTopicIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    onSelectionChange: PropTypes.func.isRequired,
    showOnlySelected: PropTypes.bool,
    showCoachMark: PropTypes.bool,
    onTopicClick: PropTypes.func.isRequired,
    onDismiss: PropTypes.func.isRequired,
    breakpoint: PropTypes.string,
};

TopicsPanel.defaultProps = {
    showOnlySelected: false,
    showCoachMark: false,
    breakpoint: null,
    onHistoryAdd: ()=>{},
};

export default TopicsPanel;
