import React from 'react';
import PropTypes from 'prop-types';
import { BreakpointsContext } from 'components/Breakpoints/Breakpoints';
import { Toast } from 'components';
import { TYPES } from 'containers/Toasts/constants';
import CopyToast from 'containers/Toasts/CopyToast/CopyToast';
import EmailSentToast from 'containers/Toasts/EmailSentToast/EmailSentToast';
import FailedActionToast from 'containers/Toasts/FailedActionToast/FailedActionToast';
import FollowSourceToast from 'containers/Toasts/FollowSourceToast/FollowSourceToast';
import UnfollowSourceToast from 'containers/Toasts/UnfollowSourceToast/UnfollowSourceToast';
import FollowTopicToast from 'containers/Toasts/FollowTopicToast/FollowTopicToast';
import UnfollowTopicToast from 'containers/Toasts/UnfollowTopicToast/UnfollowTopicToast';
import SourceLimitToast from 'containers/Toasts/SourceLimitToast/SourceLimitToast';
import TopicLimitToast from 'containers/Toasts/TopicLimitToast/TopicLimitToast';
import SuccessfulSaveToast from 'containers/Toasts/SuccessfulSaveToast/SuccessfulSaveToast';
import SuccessfulUnsaveToast from 'containers/Toasts/SuccessfulUnsaveToast/SuccessfulUnsaveToast';

/**
 * Switch wrapper to determine which toast to show.
 * @param {*} param0 
 */
const ToastsSwitch = ({ toast }) => {
    if (typeof toast === 'string') {
        return (
            <Toast>
                {toast}
            </Toast>
        );
    }

    if (typeof toast === 'object'
        && toast.toastType) {
        let Component = null;

        switch (toast.toastType) {
            case TYPES.SUCCESSFULLY_SAVED_ARTICLE:
            case TYPES.SUCCESSFULLY_SAVED_CECREDIT:
            case TYPES.SUCCESSFULLY_SAVED_GUIDE:
                Component = SuccessfulSaveToast;
                break;
            case TYPES.FAILED_ACTION:
                Component = FailedActionToast;
                break;
            case TYPES.COPY:
                Component = CopyToast;
                break;
            case TYPES.SUCCESSFULLY_UNSAVED_ARTICLE:
            case TYPES.SUCCESSFULLY_UNSAVED_CECREDIT:
                Component = SuccessfulUnsaveToast;
                break;
            case TYPES.SUCCESSFULLY_UNSAVED_GUIDE:
                Component = SuccessfulUnsaveToast;
                break;
            case TYPES.SOURCE_LIMIT_REACHED:
                Component = SourceLimitToast;
                break;
            case TYPES.TOPIC_LIMIT_REACHED:
                Component = TopicLimitToast;
                break;
            case TYPES.FOLLOW_SOURCE:
                Component = FollowSourceToast;
                break;
            case TYPES.UNFOLLOW_SOURCE:
                Component = UnfollowSourceToast;
                break;
            case TYPES.FOLLOW_TOPIC:
                Component = FollowTopicToast;
                break;
            case TYPES.UNFOLLOW_TOPIC:
                Component = UnfollowTopicToast;
                break;
            case TYPES.RESEND_EMAIL:
                Component = EmailSentToast;
                break;
            default:
                Error('Unknown toast type');
                break;
        }

        if (Component) {
            const toastProps = toast.toastProps || {};

            return (
                <BreakpointsContext.Consumer>
                    {breakpoint => (
                        <Component
                            {...toastProps}
                            breakpoint={breakpoint}
                        />
                    )}
                </BreakpointsContext.Consumer>
            );
        }
    }

    return null;
};

ToastsSwitch.propTypes = {
    toast: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({}),
    ]),
};

ToastsSwitch.defaultProps = {
    toast: {},
};

export default ToastsSwitch;
