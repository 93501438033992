import { css } from 'styled-components';
import { SIZE } from 'components/ContentCard/constants';
import {
    COLORS,
    ANIMATIONS,
} from 'utils/variables';

export const listStyles = css`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 0 0 -16px;
    padding: 0 8px 0 0;
`;

export const itemStyles = css`
    font-weight: 700;
    padding-left: 16px;
    word-wrap: break-word;

    /* stylelint-disable */

    ${props => props.variant && props.variant.followed && css`
        font-weight: 700;
    `};

    ${props => props.variant && props.variant.internal && props.variant.size === SIZE.STANDARD && css`
    color: ${COLORS.WHITE};
    `};

    ${props => props.variant && !props.variant.followed && (!props.variant.internal || props.variant.size === SIZE.SMALL) && css`
    color: ${COLORS.MEDIUM_GREY2};
    `};

    ${props => props.variant && props.variant.followed && (!props.variant.internal || props.variant.size !== SIZE.STANDARD) && css`
        color: ${COLORS.SAPPHIRE};
    `};

    /* stylelint-enable */
`;

export const linkStyles = css`
    color: ${COLORS.SAPPHIRE};
    text-decoration: none;
    transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;
    
    :hover {
        color: ${COLORS.DARK_SAPPHIRE};
    }
`;