import { css } from 'styled-components';
import { visuallyHiddenStyles } from 'components/VisuallyHidden/styles';
import { StyledIcon, StyledText } from 'components/SaveControl/SaveControl';
import { FROM_DESKTOP } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS, RGB_COLORS } from 'utils/variables';

export const buttonStyles = css`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    font-family: ${FONT_AVENIR_NEXT};
    line-height: 1;
    min-width: 17px;
    overflow: visible;
    padding: 0;
    position: relative;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;

    /* stylelint-disable */

    ${props => props.variant && props.variant.appearance === 'minimal' && css`
        height: 24px;

        /* stylelint-disable */

        @media screen and ${FROM_DESKTOP} {
            &:hover {
                ${StyledIcon} {
                    color: ${COLORS.SAPPHIRE};
                    fill: ${COLORS.SAPPHIRE};
                }
            }
        }

        /* stylelint-enable */
    `}

    ${props => props.variant && props.variant.appearance === 'extended' && css`

        /* stylelint-disable */

        @media screen and ${FROM_DESKTOP} {
            &:hover {
                ${StyledIcon} {
                    color: ${COLORS.SAPPHIRE};
                    fill: ${COLORS.SAPPHIRE};
                }

                ${StyledText} {
                    color: ${COLORS.SAPPHIRE};
                }
            }
        }

        /* stylelint-enable */
    `}

    /* stylelint-enable */
`;

export const iconContainerStyles = css`
    align-items: center;
    display: inline-flex;
    justify-content: center;
    left: 0;
    top: 0;
    vertical-align: middle;

    /* stylelint-disable */

    ${props => props.variant && props.variant.appearance === 'extended' && css`
        background-color: ${COLORS.WHITE};
        border-radius: 50%;
        height: 30px;
        width: 30px;
    `}

    /* stylelint-enable */
`;

export const iconStyles = css`
    fill: none;

     /* stylelint-disable */

    ${props => props.variant && props.variant.appearance === 'minimal' && css`
        color: ${COLORS.LIGHT_GRAY3};
        stroke-width: 2px;

        /* stylelint-disable */

        ${props.saved === 'true' && css`
            color: ${COLORS.OCEAN};
            fill: ${COLORS.OCEAN};
        `}

        /* stylelint-enable */
    `}

    ${props => props.variant && props.variant.appearance === 'extended' && css`
        /* stylelint-disable */

        color: ${COLORS.SAPPHIRE};
        height: 14px;
        stroke-width: 1px;
        width: 10px;

        ${props.saved === 'true' && css`
            fill: ${COLORS.SAPPHIRE};
        `}

        /* stylelint-enable */
    `}
     /* stylelint-enable */
`;

export const textStyles = css`
     /* stylelint-disable */

    ${props => props.variant && props.variant.appearance === 'minimal' && css`
        ${visuallyHiddenStyles};
    `}

    ${props => props.variant && props.variant.appearance === 'extended' && css`
        color: ${COLORS.MEDIUM_GRAY2};
        display: inline-block;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 2px;
        margin-left: 12px;
        text-transform: uppercase;
        vertical-align: middle;
    `}

    ${props => props.saved && css`
        color: ${COLORS.SAPPHIRE};
    `}

     /* stylelint-enable */
`;
