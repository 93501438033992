import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_MOBILE_TO_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT } from 'utils/variables';
import { COLORS, RGB_COLORS } from 'utils/variables';

export const contentWrapperStyles = css`
    @media screen and ${FROM_MOBILE_TO_DESKTOP} {
        background: linear-gradient(to bottom, rgba(${RGB_COLORS.LIGHT_GRAY2}, 0.5), rgba(${RGB_COLORS.LIGHT_GRAY1}, 0)) no-repeat;
        background-size: 100% 150px;
        padding: 42px 13% 0;
    }

    @media (max-width: 479px) {
        padding: 19px 16px 0;
    }
`;

export const imageCreditStyles = css`
    color: ${COLORS.MEDIUM_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 12px;
    font-weight: 500;
    line-height: 1.43;
    margin-bottom: 12px;
    margin-top: 0;

    @media screen and ${FROM_DESKTOP} {
        font-size: 14px;
        margin-bottom: 8px;
    }
`;

export const additionalInfoWrapperStyles = css`
    margin-bottom: 8px;
    margin-top: 0;

    @media screen and ${FROM_TABLET} {
        margin-bottom: 12px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 42px;
        margin-top: 21px;
    }
`;

export const authorStyles = css`
    color: ${COLORS.MEDIUM_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 12px;
    font-weight: 500;
    line-height: 1.43;

    @media screen and ${FROM_DESKTOP} {
        font-size: 14px;
    }
`;

export const contentStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 14px;
    line-height: 1.43;
    white-space: pre-line;
    word-wrap: break-word;

    @media screen and ${FROM_DESKTOP} {
        font-size: 18px;
        line-height: 1.33;
    }
`;

export const imageStyles = css`
    img {
        display: block;
        width: 100%;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 20px;
        margin-right: -13%;
    }
`;
