import * as type from '../constants/marketIndices.common';

const initialState = {
    items: { marketQuotes: [], closed: false, lastUpdatedDate: '' },
};

function marketIndicesReducer(state = initialState, action = {}) {
    switch (action.type) {
    case type.GET_MARKET_INDICES_SUCCESS:
        return {
            ...state,
            items: action.data,
        };
    default:
        return state;
    }
}

export default marketIndicesReducer;
