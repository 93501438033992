import { css } from 'styled-components';
import { FROM_DESKTOP,FROM_TABLET, SMALL_MOBILE } from '../../../utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS } from '../../../utils/variables';

export const collectionsContainerStyles = css`
    font-size: 13px;
    line-height: 1.65;
    margin: 16px 0;
    padding: 8px 16px 0;

    @media screen and ${SMALL_MOBILE} {
        padding: 0;
        width: 90%;
    }

    @media screen and ${FROM_TABLET} {
        padding: 0;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 14px;
        padding: 32px 0 0;
        width:100%;
    }
`;

export const collectionTitleStyles = css`
    border-bottom: ${COLORS.LIGHT_GRAY3} solid 1px;
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    margin-bottom: 10px;
    margin-top: 0;
    padding-bottom: 7px;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
`;

export const collectionStyles = css`
    font-size: 13px;
`;
