import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isGuideFeatured } from 'utils/contentCardUtils';
import { toggleSaveGuide as toggleSaveGuideAction } from 'state-management/actions/save';
import GuideComponentSwitch from '../../containers/Guide/GuideComponentSwitch/GuideComponentSwitch';
import { getGuide, resetGuide, getGuideCollections, getGuideRecent, getGuideTools } from 'state-management/actions/guide';
import * as analytics from 'utils/adobeAnalytics';
import { PAGE_NAME_GUIDE } from 'utils/analyticsConstants';
import {
    wrapperContainerStyles,
    collectionContainerStyles
} from 'containers/Guide/styles';
import { getCoachMarkStatus as getCoachMarkStatusAction } from 'state-management/actions/coachMark';
import Loader from 'components/Loader/Loader';
import GuideCollections from './GuideCollections/GuideCollections';
import { contextHubRecordEvent } from 'utils/contextHub';
import {
    KEBAB_EVENT_LIST,
} from 'utils/contextHubEventListConstants';

import labels from '../../lang/en.json';
import { StyleBackToTopButton } from 'components/MarketBriefing/page/MarketBriefingPage';
import {
    IconArrowDown,
} from 'components/atomics/atoms/Icons/Icons';

const StyledWrapperContainer = styled.div`
    ${wrapperContainerStyles}
`;

const StyledCollectionContainer = styled.div`
    ${collectionContainerStyles}
`;

/**
 * Guide display page.
 */
export class Guide extends React.Component {

    constructor(props) {
        super(props);
        this.state = {           
            showBackToTop: false,
        };
        this.handleWindowScroll = this.handleWindowScroll.bind(this);
        this.backToTop = this.backToTop.bind(this);
    }

    /**
     * Gather needed guide data at mount.
     */
    componentDidMount() {
        const { getCoachMarkStatus, getGuideData, getGuideCollectionsData, getGuideRecentData, getGuideToolsData } = this.props;
        const code = this.getGuideCode();

        getGuideData(code);
        getCoachMarkStatus();
        getGuideRecentData(4);
        window.addEventListener('scroll', this.handleWindowScroll);
    }

    /**
     * Change guide data and record analytics as guides link to other guides.
     * @param {*} prevProps 
     */
    componentDidUpdate(prevProps) {
        const { getGuideData, match: { params } } = this.props;

        if (prevProps.match.params.id !== params.id) {
            const id = this.getGuideCode();

            getGuideData(id);
        }

        if (this.props.guide.isLoading && !prevProps.guide.isLoading) {
            analytics.dispatchViewStart(analytics.getGuidePageData(this.getGuideCode()));
        }

        if (!this.props.guide.isLoading && prevProps.guide.isLoading) {
            analytics.dispatchViewEnd(analytics.getGuidePageData(this.getGuideCode()));
        }

        const fetchedDataGuidesRecent = this.props.guideRecent.guidesList
    }

    /**
     * Clear guide data at destroy to ensure fresh load next time.
     */
    componentWillUnmount() {
        const { resetGuideData } = this.props;

        resetGuideData();
    }

    /**
     * Get unique key for iterator usage.
     * @param {*} id 
     * @param {*} idx 
     */
    static getKey(id, idx) {
        return id + idx;
    }

    /**
     * Gather the displayed guides code.
     */
    getGuideCode = () => decodeURIComponent(this.props.match.params.id);

    /**
     * Handle guide save/unsave.
     */
    handleToggleSaveGuide = () => {
        const { saved, id, title } = this.props.guide;

        this.props.toggleSaveGuide(
            id,
            title,
            !saved,
            analytics.getAnalyticsGuideData(
                this.props.guide,
                PAGE_NAME_GUIDE,
            ),
        );

        contextHubRecordEvent(KEBAB_EVENT_LIST(
            "save",
            !saved,
            title,
            null,
            null,
            'guide'
        ));
    };

    /**
     * Render the back to top sticky button.
     */
    renderBackToTop() {
        return (
            <StyleBackToTopButton
                aria-label={labels.translations['marketBriefing.page.scrollToTop']}
                type="button"
                id="backToTopLink"
                onClick={this.backToTop}
                data-analytics-placement="Button : body"
                data-analytics-label="trackLink : button"
                data-analytics-id="backtotop:News Page"
            >
                <IconArrowDown />
            </StyleBackToTopButton>
        );
    }

    /**
     * Track window scrolling for back to top button usage.
     */
    handleWindowScroll() {
        const scrollTop = document.body.scrollTop
            ? document.body.scrollTop : document.documentElement.scrollTop;
        if (scrollTop > 0) {
            this.setState({
                showBackToTop: true,
            });
        } else {
            this.setState({
                showBackToTop: false,
            });
        }
    }

    /**
     * Scroll the user back to the top of page.
     */
    backToTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behaviour: 'smooth'
        });
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const { guide, isLoading } = this.props;
        const heroComponents = [];
        const otherComponents = [];
        const collectionscomponent = [];

        guide.components.forEach((component) => {
            if (component.type === 'HERO') {
                heroComponents.push(component);
            } else {
                otherComponents.push(component);
            }
        });

        if (isLoading) {
            return null;
        }

        return (
            <div>
                <StyledWrapperContainer isFeatured={isGuideFeatured(guide.type)}>
                    {
                        heroComponents.map((component, idx) => (
                            <GuideComponentSwitch
                                {...component}
                                guideType={guide.type}
                                key={component.id}
                                index={idx}
                                isSaving={guide.isSaving}
                                onToggleSaveGuide={this.handleToggleSaveGuide}
                                saved={guide.saved}
                            />
                        ))
                    }
                    {
                        otherComponents.map((component, idx) => (
                            <GuideComponentSwitch
                                {...component}
                                guideType={guide.type}
                                key={Guide.getKey(component.id, idx)}
                                index={heroComponents.length + idx}
                            />
                        ))
                    }
                </StyledWrapperContainer>
                {this.state.showBackToTop
                    && this.renderBackToTop()}
            </div>
        );
    }
}

Guide.propTypes = {
    getCoachMarkStatus: PropTypes.func.isRequired,
    getGuideData: PropTypes.func.isRequired,
    guide: PropTypes.shape({
        category: PropTypes.string,
        title: PropTypes.string.isRequired,
        components: PropTypes.array,
        isLoading: PropTypes.bool.isRequired,
        isSaving: PropTypes.bool.isRequired,
        saved: PropTypes.bool,
        topicNames: PropTypes.array,
    }),
    guideCollections: PropTypes.shape({
    }),
    guideRecent: PropTypes.shape({
    }),
    guideTools: PropTypes.shape({
    }),
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    resetGuideData: PropTypes.func.isRequired,
    toggleSaveGuide: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

Guide.defaultProps = {
    guide: {},
    guideCollections: {},
    guideRecent: {},
    guideTools: {},
};

const mapStateToProps = state => ({
    guide: state.guide,
    guideCollections: state.guideCollections,
    guideRecent: state.guide.data,
    guideTools: state.guideTools,
    isLoading: state.guide.isLoading,
});

const mapDispatchToProps = dispatch => ({
    getCoachMarkStatus: () => dispatch(getCoachMarkStatusAction()),
    getGuideData: id => dispatch(getGuide(id)),
    getGuideCollectionsData: group => dispatch(getGuideCollections(group)),
    getGuideRecentData: no => dispatch(getGuideRecent(no)),
    getGuideToolsData: no => dispatch(getGuideTools(no)),
    resetGuideData: () => dispatch(resetGuide()),
    toggleSaveGuide: (id, title, save, analyticsData) => (
        dispatch(toggleSaveGuideAction(id, save, {
            title,
            analyticsData,
        }))
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Guide);
