import { css } from 'styled-components';
import {
    TYPE_3COLUMN,
    TYPE_3COLUMN_LARGELASTCOLUMN,
    TYPE_3COLUMN_LARGEFIRSTCOLUMN,
    TYPE_4COLUMN,
    TYPE_2COLUMN,
} from 'components/atomics/atoms/Row/Row';
import {
    FROM_DESKTOP,
    FROM_TABLET,
} from '../../../../utils/mediaQueries';

export const containerStyles = css`
    display: flex;

    ${props => props.type === TYPE_3COLUMN && css`
        &>div {
            max-width: none;
            width: calc(33% - 16px);
            
            @media screen and (min-width: 1025px) {
                width: calc(27.8% - 16px);
            }

        }

        &>div:nth-child(2) {
            margin-left: 24px;
            margin-right: 24px;
        }
    `};

    ${props => props.type === TYPE_3COLUMN_LARGELASTCOLUMN && css`
        &>div {
            width: calc(25% - 16px);
        }

        &>div:last-child {
            width: calc(50% - 16px);
        }

        &>div:nth-child(2) {
            margin-left: 24px;
            margin-right: 24px;
        }
    `};

    ${props => props.type === TYPE_3COLUMN_LARGEFIRSTCOLUMN && css`
        &>div {
            width: calc(25% - 16px);
        }

        &>div:first-child {
            width: calc(50% - 16px);
        }

        &>div:nth-child(2) {
            margin-left: 24px;
            margin-right: 24px;
        }
    `};

    ${props => props.type === TYPE_2COLUMN && css`
        &>div {
            width: calc(50% - 12px);
        }

        &>div:last-child {
            margin-left: 24px;
        }
    `};

    ${props => props.type === TYPE_4COLUMN && css`
        &>div {
            width: calc(24.8% - 18px);
        }

        &>div:nth-child(2) {
            margin-left: 24px;

        }

        &>div:nth-child(3) {
            margin-left: 24px;
            margin-right: 24px;
        }
    `};
`;
