import { css } from 'styled-components';
import { FONT_AVENIR_NEXT, COLORS } from 'utils/variables';

export const buttonStyles = css`
    align-items: center;
    background: ${COLORS.SAPPHIRE};
    border: solid 2px ${COLORS.SAPPHIRE};
    border-radius: 22px;
    box-sizing: border-box;
    color: ${COLORS.WHITE};
    cursor: pointer;
    display: inline-flex;
    height: 44px;
    justify-content: space-between;
    line-height: 1;
    min-width: 160px;
    width: 200px;
    padding: 5px 6px;

    &:hover {
        background: ${COLORS.DARK_SAPPHIRE};
        border-color: ${COLORS.DARK_SAPPHIRE};
    }

    &.active {
        background: transparent;

        /* stylelint-disable */
        ${props => props.theme === 'light' && css`
            border-color: ${COLORS.SAPPHIRE};
            color: ${COLORS.SAPPHIRE};
        `};

        ${props => props.theme === 'dark' && css`
            border-color: ${COLORS.WHITE};
            color: ${COLORS.WHITE};
        `};
        /* stylelint-enable */
    }
`;

export const textStyles = css`
    color: inherit;
    flex: 1 0 auto;
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 2.4px;
    line-height: 1;
    margin: 0 18px;
    margin-top: 2px;
    text-align: center;
    text-transform: uppercase;
`;

export const iconContainerStyles = css`
    align-items: center;
    background: ${COLORS.DARK_SAPPHIRE};
    border: ${COLORS.DARK_SAPPHIRE} solid 1px;
    border-radius: 50%;
    box-sizing: border-box;
    color: ${COLORS.WHITE};
    display: inline-flex;
    height: 30px;
    justify-content: center;
    overflow: hidden;
    width: 30px;

    .active & {

        /* stylelint-disable */
        ${props => props.theme === 'light' && css`
            background: transparent;
            border-color: ${COLORS.SAPPHIRE};
        `};

        ${props => props.theme === 'dark' && css`
            background: ${COLORS.SAPPHIRE};
            border-color: ${COLORS.SAPPHIRE};
        `};
        /* stylelint-enable */
    }
`;

export const iconStyles = css`
    fill: ${COLORS.WHITE};
    margin: 0;

    .active & {

        /* stylelint-disable */
        ${props => props.theme === 'light' && css`
            fill: ${COLORS.SAPPHIRE};
        `};

        ${props => props.theme === 'dark' && css`
            fill: ${COLORS.WHITE};
        `};
        /* stylelint-enable */
    }
`;
