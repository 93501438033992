import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS } from 'utils/variables';

export const sectionStyles = css`
    position: relative;

    &:not(:first-of-type) {
        &::before {
            background-image: linear-gradient(to top, ${COLORS.WHITE}, ${COLORS.LIGHT_GRAY1});
            content: '';
            height: 544px;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: -1;
        }
    }

    @media screen and ${FROM_TABLET} {
        &::before {
            display: none;
        }
    }
`;

export const headingStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 24px;
    font-weight: 600;
    line-height: 1.17;
    margin-bottom: 2px;
    word-wrap: break-word;

    @media screen and ${FROM_DESKTOP} {
        font-size: 32px;
    }
`;

export const descriptionStyles = css`
    color: ${COLORS.MEDIUM_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
    margin: 0;
    word-wrap: break-word;

    @media screen and ${FROM_DESKTOP} {
        font-size: 28px;
        font-weight: 500;
        line-height: 1.6;
    }
`;

export const headerStyles = css`
    border-bottom: solid 2px ${COLORS.LIGHT_GRAY3};
    margin-bottom: 30px;
    padding-bottom: 19px;
    padding-top: 24px;

    @media screen and ${FROM_TABLET} {
        margin-bottom: 48px;
        padding-top: 64px;
    }
`;

export const bodyStyles = css`
    >div{
        margin-bottom: 25px;
    }
    margin-bottom: 16px;
`;

export const guidesListStyles = css`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-top: 16px;
    padding: 0;

    @media screen and ${FROM_TABLET} {
        margin-left: -24px;
       
    }

    @media screen and ${FROM_DESKTOP} {
        margin-left: -55px;
       
    }
`;

export const guidesItemStyles = css`
    align-items: stretch;
    box-sizing: border-box;
    display: flex;
    margin-bottom: ${({ isSavesItem }) => (isSavesItem ? '24px' : '19px')};
    width: 100%;

    @media screen and ${FROM_TABLET} {
        border-bottom: none;
        margin-bottom: 24px;
        padding-left: 24px;
        width: ${props => 100 / (props.mode || 3)}%;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 48px;
        padding-left: 48px;
    }
`;

export const guideContainerStyles = css`
    align-items: stretch;
    display: flex;
    width: 100%;

    @media screen and ${FROM_TABLET} {
        /* stylelint-disable */
        ${props => props.idx === 0 && css`
            &::before {
                background-image: linear-gradient(to top, ${COLORS.WHITE}, ${COLORS.LIGHT_GRAY1});
                content: '';
                height: 544px;
                left: 0;
                position: absolute;
                right: 0;
                transform: translateY(74px);
                z-index: -1;
            }
        `};
        /* stylelint-enable */
    }
`;
