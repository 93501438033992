import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from "react-intl";
import moment from 'moment';
import {
    containerStyles,
    dateContainer,
    timeContainer,
    timeItem,
} from 'components/atomics/atoms/CountDown/styles';

import DateDisplay from 'components/atomics/atoms/DateDisplay/DateDisplay';

export const CountDownContainer = styled.div`
    ${containerStyles}
`;

export const DateContainer = styled.div`
    ${dateContainer}
`;

export const TimeContainer = styled.div`
    ${timeContainer}
`;

export const TimeItem = styled.div`
    ${timeItem}
`;

const sdate = new Date();
const ydate = new Date(sdate.getTime() + (10 * 24 * 60 * 60 * 1000) + 433);

/**
 * A countdown component used for webinar date displays.
 */
class CountDown extends React.Component {
    /**
     * Default constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            past: true,
            days: null,
            hours: null,
            minutes: null,
            seconds: null,
        }
    }

    /**
     * Initialize the tick value and start timer.
     */
    componentDidMount() {
        this.tick();
        
        this.interval = setInterval(this.tick, 1000);
    }

    /**
     * Clear the set timer.
     */
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    /**
     * Increment tick/count values.
     */
    tick = () => {
        const { timestamp } = this.props;
        const target = moment(new Date(timestamp));
        const now = moment();

        if (target.isBefore(now)) {
            this.setState({ past: true });
            
            clearInterval(this.interval);
        } else {
            const duration = moment.duration(target.diff(now), 'milliseconds');
            
            this.setState({
                past: false,
                days: Math.floor(duration.asDays()) > 9 ? Math.floor(duration.asDays()) : '0' + Math.floor(duration.asDays()),
                hours: duration.hours() > 9 ? duration.hours() : '0' + duration.hours(),
                minutes: duration.minutes() > 9 ? duration.minutes() : '0' + duration.minutes(),
                seconds: duration.seconds() > 9 ? duration.seconds() : '0' + duration.seconds(),
            });
        }
    }

    /**
     * Render the countdown component.
     */
    render() {
        const { noDate, dataPage } = this.props;
        const { days, hours, minutes, seconds, past } = this.state;

        if (past) {
            return null;
        } else {
            return (
                <CountDownContainer
                    data-rel="countdown"
                >
                    {!noDate
                        && <DateDisplay
                            timestamp={ydate.getTime()}
                            timezone='PST'
                        />}
                    <TimeContainer>
                        <TimeItem dataPage={dataPage}>
                            <span>{days}</span>
                            <span><FormattedMessage id="general.days" /></span>
                        </TimeItem>
                        <TimeItem dataPage={dataPage}>
                            <span>{hours}</span>
                            <span><FormattedMessage id="general.hours" /></span>
                        </TimeItem>
                        <TimeItem dataPage={dataPage}>
                            <span>{minutes}</span>
                            <span><FormattedMessage id="general.minutes" /></span>
                        </TimeItem>
                        <TimeItem dataPage={dataPage}>
                            <span>{seconds}</span>
                            <span><FormattedMessage id="general.seconds" /></span>
                        </TimeItem>
                    </TimeContainer>
                </CountDownContainer>
            );
        }
    }
}

CountDown.propTypes = {
    timestamp: PropTypes.number,
    timezone: PropTypes.string,
    noDate: PropTypes.bool,
}

CountDown.defaultProps = {
    timestamp: null,
    timezone: null,
    noDate: false,
}

export default CountDown;
