import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TimeAgo from 'components/TimeAgo/TimeAgo';
import Container from 'components/Container/Container';
import Heading from 'components/Heading/Heading';
import SanitizedHTML from 'components/SanitizedHTML/SanitizedHTML';
import {
    sectionStyles,
    containerStyles,
    contentContainerStyles,
    dateStyles,
    headingStyles,
    descriptionStyles,
} from 'components/Posts/LuminPost/styles';

const StyledSection = styled.section`
    ${sectionStyles}
`;
const StyledContainer = styled(Container)`
    ${containerStyles}
`;
const StyledContentContainer = styled.div`
    ${contentContainerStyles}
`;
const StyledDate = styled(TimeAgo)`
    ${dateStyles}
`;
const StyledHeading = styled(Heading)`
    ${headingStyles}
`;
const StyledDescription = styled(SanitizedHTML)`
    ${descriptionStyles}
`;

const LuminPost = (props) => {
    const { publishTimestamp, title, description } = props;

    return (
        LuminPost.areAttributesValid(props) && (
            <StyledSection>
                <StyledContainer>
                    <StyledContentContainer>
                        <StyledDate fromDate={publishTimestamp} />
                        <StyledHeading level={2}>
                            {title}
                        </StyledHeading>
                        <StyledDescription
                            htmlContent={description}
                            tag="div"
                        />
                    </StyledContentContainer>
                </StyledContainer>
            </StyledSection>
        )
    );
};

LuminPost.areAttributesValid = ({ title, description, publishTimestamp }) => title
    && description
    && publishTimestamp;

LuminPost.propTypes = {
    publishTimestamp: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
};

export default LuminPost;
