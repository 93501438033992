export const SIGN_UP = 'signUp/SIGN_UP';
export const SET_ERRORS = 'signUp/SET_ERRORS';
export const SET_SUCCESS = 'signUp/SET_SUCCESS';
export const SET_SUBMITTING = 'signUp/SET_SUBMITTING';
export const RESEND_EMAIL = 'signUp/RESEND_EMAIL';
export const CHANGE_EMAIL = 'signUp/CHANGE_EMAIL';
export const CHANGE_EMAIL_FAIL = 'signUp/CHANGE_EMAIL_FAIL';
export const CHANGE_EMAIL_SUCCESS = 'signUp/CHANGE_EMAIL_SUCCESS';
export const CONFIRM_EMAIL = 'signUp/CONFIRM_EMAIL';
export const CONFIRM_EMAIL_FAIL = 'signUp/CONFIRM_EMAIL_FAIL';
export const CONFIRM_EMAIL_SUCCESS = 'signUp/CONFIRM_EMAIL_SUCCESS';
export const RESEND_EMAIL_COMPLETE = 'signUp/RESEND_EMAIL_COMPLETE';
export const RESEND_EMAIL_FAIL = 'signUp/RESEND_EMAIL_FAIL';

export const SIGN_UP_URL = '/advisor-service/advisors/sign-up';
export const CHANGE_EMAIL_URL = '/advisor-service/advisors/update-confirmation-email';
//export const CHANGE_EMAIL_GET_URL = (values) => `/advisor-service/advisors/update-confirmation-email?value=${values}`;
export const RESEND_EMAIL_URL = '/advisor-service/advisors/email-confirmation/resend';
export const CONFIRM_EMAIL_URL = token => `/advisor-service/advisors/verify-email/${token}`;
export const CONFIRM_MIGRATED_EMAIL_URL = (token, email) => `/advisor-service/identities/verify-email/${token}/${email}`;

export const CHANGE_EMAIL_GET_URL = '/advisor-service/advisors/v2/update-confirmation-email';