import {
    all, put, takeLatest, call,
} from 'redux-saga/effects';

import { setError } from 'state-management/actions/errors';
import { GET_TOOLS_DATA, TOOLS_GET_URL, SEND_CLICK_TOOLS, SEND_CLICK_TOOLS_URL} from 'state-management/constants/tools';
import { getToolsFailure, getToolsSuccess } from 'state-management/actions/tools';
import Api from 'utils/api';

/**
 * Get all tools data.
 */
function* getToolsData() {
    try {
        const toolsData = yield call(Api.get, TOOLS_GET_URL);
        yield put(getToolsSuccess(toolsData.data));
    } catch (e) {
        yield put(getToolsFailure(e));
        yield put(setError('toolsPage.server.error', e));
    }
}

function* sendClick({ id }) {
    try {
        yield call(Api.get, `${SEND_CLICK_TOOLS_URL}?id=${id}&name=tool`);
    } catch (e) {
        yield put(setError('Send click error', e));
    }
} 


function* toolsSaga() {
    yield all([
        takeLatest(GET_TOOLS_DATA, getToolsData),
        takeLatest(SEND_CLICK_TOOLS, sendClick),
    ]);
}

export default toolsSaga;
