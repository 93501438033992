import { css } from 'styled-components';
import {
    FROM_DESKTOP,
    FROM_TABLET,
} from '../../utils/mediaQueries';
import { COLORS } from '../../utils/variables';

export const collectionBlankAreaStyles = css`
    height: 1292px;
    position: relative;
    margin: auto;
    margin-left: 16px;
    margin-right: 16px;

    @media screen and ${FROM_TABLET} {
        height: 652px;
        margin-left: 38px;
        margin-right: 38px;
    }

    @media screen and ${FROM_DESKTOP} {
        height: 666px;
        padding-left: 48px;
        padding-right: 48px;
        margin-left: auto;
        margin-right: auto;
        max-width: 1170px;
    }
`;

export const collectionBlankAreaInnerStyles = css`
    width: 100%;
    height: 100%;
    background: ${COLORS.WHITE};
`;

export const loaderStyles = css`
    position: absolute;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
    height: 50px;
    width: 50px;

    @media screen and ${FROM_TABLET} {
        width: 80px;
        height: 80px;
        left: calc(50% - 40px);
        top: calc(50% - 40px);
    }

    @media screen and ${FROM_DESKTOP} {
        width: 100px;
        height: 100px;
        left: calc(50% - 50px);
        top: calc(50% - 50px);
    }
`;

export const containerStyles = css`
    margin: 0;
    background: ${COLORS.LIGHT_GRAY1};
`;

export const tabsContainerStyles = css`
    margin: 0 0 25px;
    text-transform:uppercase;
    @media screen and ${FROM_TABLET} {
        margin: 15px auto 40px;
    }
`;
