/**
 * URL History Reducer (for back button logic).
 */
import {
    ADD_URL_HISTORY,
    DELETE_URL_HISTORY,
} from "../actions/UrlHistoryActions";
import { ROUTES } from "../../containers/App/constants";

const initialState = {
    history: []
}

const UrlHistoryReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case DELETE_URL_HISTORY:
            let tempHistory = state.history.slice(0);

            tempHistory.pop();

            return {
                history: tempHistory,
            }
        case ADD_URL_HISTORY:
            const url = action.data.currentUrl != null ? action.data.currentUrl.replace('?b=1', '').replace('&b=1', '') : null;

            //reset array when hitting these base pages
            if (url === ROUTES.NEWS
                || url === ROUTES.GUIDES
                || url === ROUTES.HOME) {
                return {
                    ...state,
                    history: [action.data],
                }
            //otherwise let the chain grow for now
            } else {
                let newHistory = state.history.slice(0);

                if (!newHistory.length || action.data.currentUrl !== newHistory[newHistory.length - 1].currentUrl) {
                    newHistory.push(action.data);
                }

                return {
                    ...state,
                    history: newHistory,
                }
            }
        default:
            return state;
    }

}

export default UrlHistoryReducer;
