import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';
import {
    camelCase, difference, union, xor,
} from 'lodash';
import { Button, UnderlinedCheckbox } from 'components';
import {
    categoryWrapperStyles,
    categoryHeaderStyles,
    categoryNameStyles,
    categoryHeaderActionStyles,
    topicsWrapperStyles,
    topicCheckboxStlyes,
} from 'containers/Onboarding/Topics/styles';

const StyledCategoryWrapper = styled.div`
    ${categoryWrapperStyles}
`;

const StyledCategoryHeader = styled.div`
    ${categoryHeaderStyles}
`;

export const StyledCategoryName = styled.span`
    ${categoryNameStyles}
`;

export const StyledHeaderAction = styled(Button)`
    ${categoryHeaderActionStyles}
`;

const StyledTopicsWrapper = styled.div`
    ${topicsWrapperStyles}
`;

export const StyledTopicCheckbox = styled(UnderlinedCheckbox)`
    ${topicCheckboxStlyes}
`;

/**
 * Render a single category of topic for onboarding.
 */
class TopicsCategorySection extends React.Component {
    /**
     * Handle select all click.
     */
    onSelectAllClick = () => {
        const { selectedTopicIds, topics, onSelectionChange } = this.props;

        onSelectionChange(union(selectedTopicIds, topics.map(topic => topic.id)));
    }

    /**
     * Handle deselect all click.
     */
    onDeselectAllClick = () => {
        const { selectedTopicIds, topics, onSelectionChange } = this.props;

        onSelectionChange(difference(selectedTopicIds, topics.map(topic => topic.id)));
    }

    /**
     * Handle topic select/deselect.
     */
    onTopicChange = (id) => {
        const { selectedTopicIds, onSelectionChange } = this.props;

        onSelectionChange(xor(selectedTopicIds, [id]));
    }

    /**
     * Render the topics for this category.
     */
    renderTopics() {
        const { topics, selectedTopicIds } = this.props;
        return topics.map(topic => (
            <StyledTopicCheckbox
                key={`topics-topic-${topic.id}`}
                label={topic.name}
                checked={selectedTopicIds.includes(topic.id)}
                onChange={() => this.onTopicChange(topic.id)}
            />
        ));
    }

    /**
     * Render the header for this topic category.
     */
    renderCategoryHeader() {
        const { categoryName, topics, selectedTopicIds } = this.props;
        const allTopicsSelected = topics.every(topic => selectedTopicIds.includes(topic.id));
        const actionButtonClickHandler = allTopicsSelected
            ? this.onDeselectAllClick : this.onSelectAllClick;
        const actionButtonLabelId = allTopicsSelected
            ? 'onboarding.topics.deselectAll' : 'onboarding.topics.selectAll';

        return (
            <StyledCategoryHeader>
                <StyledCategoryName>{categoryName}</StyledCategoryName>
                <StyledHeaderAction
                    id={`btn-${camelCase(categoryName)}`}
                    onClick={actionButtonClickHandler}
                >
                    <FormattedMessage id={actionButtonLabelId} />
                </StyledHeaderAction>
            </StyledCategoryHeader>
        );
    }

    /**
     * Render this and underlying components.
     */
    render() {
        return (
            <StyledCategoryWrapper>
                {this.renderCategoryHeader()}
                <StyledTopicsWrapper>
                    {this.renderTopics()}
                </StyledTopicsWrapper>
            </StyledCategoryWrapper>
        );
    }
}

TopicsCategorySection.propTypes = {
    categoryName: PropTypes.string.isRequired,
    topics: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    selectedTopicIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    onSelectionChange: PropTypes.func.isRequired,
};

export default injectIntl(TopicsCategorySection);
