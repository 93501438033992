import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Dropdown from 'components/atomics/molecules/Dropdown/Dropdown';
import Title from 'components/atomics/atoms/Title/Title';
import Link from 'components/atomics/atoms/Link/Link';
import TopicsPanel from 'components/Topics/TopicsPanel/TopicsPanel';
import { dismissCoachMark } from 'state-management/actions/coachMark';
import BackToLink from "components/BackToLink/BackToLink";
import {
    Container,
    Loader,
} from 'components';
import {
    headerStyles,
    navContainerStyles,
    navWrapperStyles,
    categoryBlockStyles,
    categoryLeftColumnStyles,
    categoryRightColumnStyles,
    outerContainerStyles,
    noTopicsStyles,
    stickyCategory,
    stickyContent,
} from 'components/Topics/styles';
import labels from '../../lang/en.json';

const StickyCategory = styled.div`
    ${stickyCategory};
`;
const StickyContent = styled.div`
    ${stickyContent}
`;

const StyledNoTopics = styled.div`
    ${noTopicsStyles}
`;

const StyledOuterBlock = styled.section`
    ${outerContainerStyles}
`;

const StyledCategoryBlock = styled.div`
    ${categoryBlockStyles}
`;

const StyledCategoryLeftColumn = styled.div`
    ${categoryLeftColumnStyles}
`;

const StyledCategoryRightColumn = styled.div`
    ${categoryRightColumnStyles}
`;

const StyledHeader = styled.div`
    ${headerStyles}
`;

const StyledNavContainer = styled.div`
    ${navContainerStyles}
`;

const StyledNavWrapper = styled.div`
    ${navWrapperStyles}
`;

const ID_ALL = 'All Topics';
const ID_PREFERRED = 'Preferred Topics';

const selectItems = [
    {
        key: ID_ALL,
        value: labels.translations['allTopics.page.show.label.allTopics'],
    },
    {
        key: ID_PREFERRED,
        value: labels.translations['allTopics.page.show.label.preferredTopics'],
    },
];

const SCROLL_OFFSET = 190;

/**
 * All topics page component.
 */
class Topics extends React.Component {
    /**
     * Default constructor and state assignment.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.state = {
            showingId: ID_ALL,
            showStickyTitle: null,
            isLoading: true,
        };

        let self = this;

        setTimeout(function () {
            self.setState({ isLoading: false });
        }, 1000);

        this.handleSelectionChange = this.handleSelectionChange.bind(this);
        this.renderCategory = this.renderCategory.bind(this);
        this.handleTopicSelection = this.handleTopicSelection.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    /**
     * Start scroll handling.
     */
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    /**
     * Stop scroll handling.
     */
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    /**
     * Handle topic selection/deselection.
     */
    handleTopicSelection(id) {
        this.props.handleFollowChange(id);
    }

    /**
     * Handle selection changes for 'all' vs. 'preferred'.
     * @param {*} id
     */
    handleSelectionChange(id) {
        this.setState({
            showingId: id,
        });
    }

    /**
     * Render the page header.
     */
    renderPageHeader() {
        return (
            <StyledHeader>
                <Container>
                    <BackToLink
                        to="/news"
                    >
                        <FormattedMessage id="allTopics.page.back.label" />
                    </BackToLink>
                    <Title
                        resourceId="allTopics.page.show.label.allTopics"
                    />
                </Container>
            </StyledHeader>
        );
    }

    /**
     * Render the preferred vs. all topics drop-down.
     */
    renderSelector() {
        const title = this.state.showingId === ID_PREFERRED
            ? labels.translations['allTopics.page.show.label.preferredTopics']
            : labels.translations['allTopics.page.show.label.allTopics'];

        return (
            <StyledNavContainer>
                <StyledNavWrapper>
                    <Dropdown
                        value={this.state.showingId}
                        onValueChanged={this.handleSelectionChange}
                        options={selectItems}
                        emptyOptionLabel={this.state.showingId}
                        isEmptyOptionSelectable={false}
                        isNone="no"
                        label="Sort By"
                        showLabel={true}
                    />
                </StyledNavWrapper>
            </StyledNavContainer>
        );
    }

    /**
     * Render the topics for a single category.
     * @param {*} category
     */
    renderTopics(category, showCoachMark, list) {
        return (
            <React.Fragment>
                <TopicsPanel
                    showOnlySelected={this.state.showingId === ID_PREFERRED}
                    onSelectionChange={this.handleTopicSelection}
                    selectedTopicIds={this.props.subscribedTopics}
                    topics={category.topics}
                    showCoachMark={showCoachMark}
                    onTopicClick={this.props.onTopicClick}
                    onDismiss={this.props.onDismiss}
                    breakpoint={this.props.breakpoint}
                    onHistoryAdd={(url) => {
                        //this.addItemToHistory(url);
                    }}
                    list={list}
                />
            </React.Fragment>
        );
    }

    /**
     * Handle possible sticky category title display.
     */
    handleScroll() {
        const scrollDepth = window.pageYOffset;
        const categories = document.body.querySelectorAll('div[data-type]');
        let scrollItemName = null;

        for (const index in categories) {
            const top = categories[index].offsetTop + SCROLL_OFFSET;
            const bottom = top + categories[index].offsetHeight;

            if (top < scrollDepth
                && bottom > scrollDepth) {
                scrollItemName = categories[index].getAttribute('data-name');
                break;
            }
        }

        if (scrollItemName && this.state.showStickyTitle !== scrollItemName) {
            this.setState({
                showStickyTitle: scrollItemName,
            });
        } else if (this.state.showStickyTitle !== null && scrollItemName === null) {
            this.setState({
                showStickyTitle: null,
            });
        }

    }

    /**
     * Render a single topic category block.
     * @param {*} category
     */
    renderCategory(category) {
        const subs = this.props.subscribedTopics;
        const foundSubscribedTopic = category.topics.some(item => subs.indexOf(item.id) >= 0);

        if (this.state.showingId === ID_ALL
            || foundSubscribedTopic) {
            const coachMark = category.id === 1 && this.state.showingId === ID_ALL;
            const coachMarkAll = true;
            const list = this.state.showingId != ID_ALL ? this.props.subscribedTopics[1] :
                category.id === 1 ? category.topics[1].id : '';

            console.log('xxxx:' + this.props.subscribedTopics.length);
            console.log('xxxx:' + JSON.stringify(this.props.subscribedTopics));
            console.log('xxxx:' + list);

            return (
                <StyledCategoryBlock key={category.id} data-type="categoryBlock" data-name={category.name} data-rel={`category-${category.id}`}>
                    <StyledCategoryLeftColumn>
                        <h3>
                            {category.name}
                        </h3>
                        <p>
                            {category.description}
                        </p>
                    </StyledCategoryLeftColumn>
                    <StyledCategoryRightColumn>
                        {this.renderTopics(category, coachMarkAll, list)}
                    </StyledCategoryRightColumn>
                </StyledCategoryBlock>
            );
        }

        return null;
    }

    /**
     * Show the no subscribed topics message.
     */
    renderNoTopics() {
        return (
            <StyledNoTopics>
                <FormattedMessage id="allTopics.page.noTopicsSuffix" /><br />
                <Link
                    to="/profile/preferences"
                >
                    <FormattedMessage id="allTopics.page.noTopicsLink" />
                </Link>
                <FormattedMessage id="allTopics.page.noTopicsAppendix" />
            </StyledNoTopics>
        );
    }

    /**
     * Render the topic category blocks.
     */
    renderCategories() {
        if (this.state.showingId === ID_PREFERRED
            && this.props.subscribedTopics.length < 1) {
            return this.renderNoTopics();
        }

        return (
            <React.Fragment>
                {this.props.topics.map(this.renderCategory)}
            </React.Fragment>
        );
    }

    /**
     * Render the all topics page body.
     */
    renderPageBody() {
        return (
            <Container>
                {this.renderSelector()}
                {this.renderCategories()}
            </Container>
        );
    }

    /**
     * Render the component.
     */
    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <StyledOuterBlock>
                {this.state.showStickyTitle !== null
                    && <StickyCategory>
                        <StickyContent>
                            {this.state.showStickyTitle}
                        </StickyContent>
                    </StickyCategory>
                }
                {this.renderPageHeader()}
                {this.renderPageBody()}
            </StyledOuterBlock>
        );
    }
}

Topics.propTypes = {
    topics: PropTypes.arrayOf(PropTypes.shape({})),
    subscribedTopics: PropTypes.arrayOf(PropTypes.number),
    handleFollowChange: PropTypes.func.isRequired,
    onTopicClick: PropTypes.func.isRequired,
    breakpoint: PropTypes.string,
};

Topics.defaultProps = {
    topics: {},
    subscribedTopics: {},
    breakpoint: null,
};

const mapStateToProps = state => ({
    urlHistoryList: state.UrlHistoryReducer.history
});

const mapDispatchToProps = dispatch => ({
    onDismiss: () => dispatch(dismissCoachMark('ALL_TOPICS_TOPIC', true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Topics);
