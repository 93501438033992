import { css } from 'styled-components';
import {
    COLORS,
    FONTS,
    ANIMATIONS,
} from 'utils/variables';
import {
    FROM_TABLET,
    FROM_DESKTOP,
    FROM_MOBILE_TO_TABLET,
} from 'utils/mediaQueries';

export const MINIMUM_DESKTOP_WIDTH = 1100;

export const mainSectionStyles = css`
    overflow: initial;
`;

export const blankAreaStyles = css`
    width: 100%;
    background: ${COLORS.WHITE};
    height: 2075px;
    position: relative;

    @media screen and ${FROM_TABLET} {
        height: 1192px;
    }

    @media screen and ${FROM_DESKTOP} {
        height: 1151px;
    }
`;

export const loaderStyles = css`
    position: absolute;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
    height: 50px;
    width: 50px;

    @media screen and ${FROM_TABLET} {
        width: 80px;
        height: 80px;
        left: calc(50% - 40px);
        top: calc(50% - 40px);
    }

    @media screen and ${FROM_DESKTOP} {
        width: 100px;
        height: 100px;
        left: calc(50% - 50px);
        top: calc(50% - 50px);
    }
`;

export const parentFilter = css`
    &>div:first-child {
        font-size: 12px;
        line-height: 31px;
        letter-spacing: 1.4px;
        color: ${COLORS.BLACK};
        font-weight: 500;
        text-transform: none;
        position: relative;
        top: 7px;
        margin-bottom: -10px;
    }

    &>div:last-child {
        &>div {
            width: auto;
        }
    }

    .Dropdown__StyledDropdownWrapperOptions-tQzqd{
 
        &>li:first-child{
            padding: 12px 23px;

            @media screen and ${FROM_TABLET} {
                padding: 20px 23px;
            }
        }
    }
`;

export const childFilter = css`
    position: relative;
    top: -22px;
    text-align: left;

    @media screen and (min-width: ${MINIMUM_DESKTOP_WIDTH}px) {
        top: -60px;
    }

    ${props => props.mode === 'desktop' && css`
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            transform: translateY(-28px);
        }
    `};

    &>div:first-child {
        font-size: 12px;
        line-height: 31px;
        letter-spacing: 1.4px;
        color: ${COLORS.BLACK};
        font-weight: 500;
        text-transform: uppercase;
        position: relative;
        top: 7px;
        white-space: nowrap;
    }

    &>div:last-child {
        margin-top: 0;
        position: relative;
        @media screen and ${FROM_DESKTOP} {
            margin-top: 25px;
        }

        &>div {
            width: auto;

            &[id='typeId-label']{
                margin-top: 10px;
                @media screen and ${FROM_DESKTOP} {
                    margin-top: 13px;
                }
            }
        }
    }

    div[role='combobox']{
         padding-top: 35px;

         @media screen and ${FROM_DESKTOP} {
            padding-top: 38px;
         }
       
        svg[role='presentation']{
            top: 40px;
         }
    }
    ul[role='listbox']{
        top: 9px;
    }
    ul>li[role='option']{
        padding: 16px 0 10px;
        @media screen and (min-width: 1023px) and (max-width: 1025px) {
            height: 53px;
        }

        @media screen and ${FROM_DESKTOP} {
            padding: 19px 0 15px;
            height: 52px;
        }
    }
`;

export const tabletContainer = css`
    &>div {
        &>div {
            &>div {
                max-width: none;
            }
        }
    }
`;

export const mobileContainer = css`
    &>div {
        &>div {
            &>div {
                max-width: none;
            }
        }

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active), ${FROM_MOBILE_TO_TABLET} {
            img {
                width: 120% !important;
                height: auto !important;
            }
        }
    }
`;

export const desktopColumns = css`
    display: flex;

    &>div:first-child {
        flex: 1;
        margin-right: 24px;
    }

    &>div:nth-child(2) {
        margin-right: 24px;
    }
`;

export const selectionLeft = css`
    position: relative;
    top: -13px;
    z-index: 101;
    ul{
        margin-top: 10px;
        @media screen and ${FROM_TABLET} {
            margin-top: 26px;
        }
    }

    @media screen and ${FROM_TABLET} {
        width: calc(50% - 12px);
        padding-right: 12px;
    
        ul{
            margin-top: 10px;
        }
    }

    @media screen and (min-width: 1023px) and (max-width: 1025px) {    
        ul{
            margin-top: 15px;
        }
    }

    @media screen and (min-width: 1100px) {
        flex: 1;
        width: auto;
    }

    &>div:last-child {
        display: block;

        @media screen and ${FROM_TABLET} {
            width: 100%;
            display: inline-block;
            text-align: left;
            padding-top: 15px;
        }

        @media screen and (min-width: ${MINIMUM_DESKTOP_WIDTH}px) {
            display: initial;
            max-width: none;
            min-width: none;
            text-align: initial;
        }
    }
    div[role='combobox']{
        @media screen and ${FROM_TABLET} {
            padding-top: 25px;
        }
        @media screen and ${FROM_DESKTOP} {
            padding-top: 30px;
        }
    }


    @media screen and ${FROM_TABLET} {
        &>a {
            margin-right: 16px;
            transition: 0.0s all;
            span{
                transition: 0.0s all;
            }
        }

        &>a:last-child {
            margin-right: 0;
        }

        &>a:first-child {
            width: 110.29px;
        }

        &>a:nth-child(2) {
            width: 240.06px;
        }

        &>a:nth-child(3) {
            width: 215.92px;
        }

        &>a:nth-child(4) {
            width:160.65px;
        }
    }

    @media screen and (min-width: ${MINIMUM_DESKTOP_WIDTH}px) {
        position: initial;
    }
`;

export const selectionRight = css`
    text-align: left;
    position: relative;
    top: -13px;
    padding-top: 30px;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        top: -22px;
    }

    @media screen and ${FROM_TABLET} {
        width: calc(50% - 12px);
        padding-top: 10px;
        padding-left: 12px;
        &>div:last-child {
            display: inline-block;
        }
    }

    @media screen and (min-width: ${MINIMUM_DESKTOP_WIDTH}px) {
        text-align: right;
        padding-left: 0;
        width: auto;
        min-width: 160px;
    }

    @media screen and ${FROM_TABLET} {
        padding-top: 0;

        &>div:first-child {
            color: ${COLORS.BLACK};
            flex: 1;
            font-size: 13px;
            font-weight: 600;
            leter-spacing: 0.11px;
            padding-right: 6px;
            position: relative;
            top: 22px;
            white-space: norwap;
            width: 100%;
        }
    }

    @media screen and (min-width: ${MINIMUM_DESKTOP_WIDTH}px) {
        &>div:first-child {
            width: 100%;
            top: 13px;
        }
    }

    div {
        z-index:100;
    }
    div[id='typeId']{
        margin-top: 10px !important;
        @media screen and ${FROM_TABLET} {
            margin-top: 0 !important;
        }

        @media screen and ${FROM_DESKTOP} {
            margin-top: 1px !important;
        }

        @media screen and (min-width: 1025px) {
            margin-top: 25px !important;
        }
    }
`;

export const selectionAreaStyles = css`
    margin-bottom: 24px;

    @media screen and ${FROM_TABLET} {
        display: flex;
    }

    @media screen and (min-width: ${MINIMUM_DESKTOP_WIDTH}px) {
        height: 58px;

        ${props => props.selectedTab === '0' && css`
            margin-bottom: 37px;
        `};
    }

    ${props => props.noBottomMargin === 'true' && css`
        margin-bottom: 0!important;
    `};
`;

export const lowerSectionStyles = css`
    background-color: ${COLORS.LIGHT_GRAY1};
`;

export const sectionStyles = css`
    background-color: ${COLORS.LIGHT_GRAY1};
`;

export const cardAreaStyles = css`
    position: relative;

    *[data-rel="StandardContentCard"]:first-child {
        max-width: inherit;

        &>div {
            width: 100%;
            max-width: inherit;
        }
    }

    ${props => props.breakpoint === 'tablet' && css`
        *[data-rel="StandardContentCard"] {
            max-width: inherit;

            &>div {
                width: 100%;
                max-width: inherit;
            }
        }
    `};

    ${props => props.breakpoint === 'mobile' && css`
        *[data-rel="StandardContentCard"] {
            max-width: inherit;

            &>div {
                width: 100%;
                max-width: inherit;
            }
        }
    `};

    *[data-rel="TrendingTopicCard"] {
        max-width: none;
        height: 100%;
        width: 100%;
    }

    *[data-rel="SplashNotification"] {
        border: 1px solid ${COLORS.LIGHT_GRAY2};
        max-width: 100%;
        margin-top: 0;
        margin-bottom: 20px;
        text-align: center;
        margin: auto;

        @media screen and ${FROM_TABLET} {
            max-width: 100%;
        }

        @media screen and ${FROM_DESKTOP} {
            max-width: 886px;
            margin-top: 36px;
        }
    }

    /* min-height: 651px;*/
    .Loader__StyledOverlay-ispHNl{
        height: 600px;
        width: 100%;
        z-index: 99;
        position: absolute;
        text-align: center;
        padding-bottom: 74px;
        padding-top: 47px;
    }

    .emptyCard {
        min-height: 800px;
    }
`;

export const splashWrapper = css`
    padding-top: 20px;
    padding-bottom: 100px;

    @media screen and ${FROM_TABLET} {
        padding-top: 20px;

    }

    @media screen and ${FROM_DESKTOP} {
        padding-top: 40px;

    }

    .SplashNotification__ColorBar-ePDEGh{
        display: none;
    }
`;

export const listAreaStyles = css`
    position: relative;

    &>div:first-child {
        min-width: 200px;
        padding-bottom: 30px;

        @media screen and ${FROM_DESKTOP} {
            padding-bottom: 0;
            padding-right: 80px;
            max-width: 300px;
        }

        h4 {
            color: ${COLORS.BLACK};
            font-size: 18px;
            font-weight: 500;
            margin: 0;

            @media screen and ${FROM_DESKTOP} {
                font-size: 20px;
                padding-top: 14px;
            }
        }
    }

    &>div:last-child {
        flex: 1;
    }

    @media screen and ${FROM_DESKTOP} {
        display: flex;
    }

    &>div:last-child {
        display: flex;
        flex-direction: column;

        @media screen and ${FROM_TABLET} {
            flex-direction: row;
        }

        &>a {
            margin-bottom: 4px;
            text-align: left;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;

            @media screen and ${FROM_TABLET} {
                margin-right: 24px;
                width: calc(32.7% - 16px);
            }
        }

        @media screen and ${FROM_TABLET} {
            flex-direction: initial;
            flex-wrap: wrap;
        }

        @media screen and ${FROM_TABLET} {
            &>a:nth-child(3),
            &>a:nth-child(6) {
                margin-right: 0;
                flex: 1;
            }
        }
    }
`;

export const colorBarStyles = css`
    height: 8px;
    background-image: linear-gradient(to left, ${COLORS.OCEAN}, ${COLORS.LIGHT_OCEAN});
    overflow: hidden;
`;

export const upperCtaAreaStyles = css`
    position: relative;
    text-align: right;
    padding-bottom: 32px;

    @media screen and ${FROM_TABLET} {
        padding-bottom: 40px;
        padding-top: 32px;
    }
`;

export const lowerCtaAreaStyles = css`
    position: relative;
    padding-bottom: 51px;
    padding-top: 30px;
    text-align: center;

    &>div:first-child {
        margin-bottom: 21px;
    }

    @media screen and ${FROM_TABLET} {
        text-align: right;

        &a> {
            display: inline-block;
        }

        &>a:first-child {
            margin-right: 30px;
        }
    }

    @media screen and ${FROM_DESKTOP} {
        padding-bottom: 75px;
    }
`;

export const titleStyles = css`
    color: ${COLORS.SAPPHIRE};
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 26px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0;
    margin: 0;
    padding-bottom: 24px;
    padding-top: 32px;

    @media screen and ${FROM_TABLET} {
        font-size: 26px;
        line-height: 32px;
        letter-spacing: 0;
        padding-bottom: 28px;
        padding-top: 50px;
    }
`;

export const containerStyles = css`
    padding-left: 16px;
    padding-right: 16px;
    margin: auto;
    max-width: 1170px;

    @media screen and ${FROM_TABLET} {
        padding-left: 38px;
        padding-right: 38px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding-left: 48px;
        padding-right: 48px;
    }
`;
