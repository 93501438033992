import { css } from 'styled-components';
import { GUTTER } from 'utils/grid';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import { COLORS, RGB_COLORS } from 'utils/variables';

export const listStyles = css`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    list-style: none;
    margin-bottom: 0;
    padding: 0;
    position: relative;

    /* stylelint-disable */

    ${props => props.variant && props.variant.isMarketNews && css`
        margin-top: -24px;
    `}

    /* stylelint-enable */

    @media screen and ${FROM_TABLET} {

    }

    @media screen and ${FROM_DESKTOP} {
        /* stylelint-disable */

        ${props => props.variant && props.variant.hasBackground && css`
            &::before {
                background-image: linear-gradient(to top, ${COLORS.LIGHT_GRAY2}, ${COLORS.LIGHT_GRAY});
                content: '';
                height: calc(100% - 210px);
                left: 0;
                margin-top: 74px;
                max-height: 544px;
                position: absolute;
                right: 0;
            }
        `};

        ${props => props.variant && props.variant.isMarketNews && css`
            margin-left: -28px;
            margin-top: -36px;
        `}

        /* stylelint-enable */
    }

    /* stylelint-disable */

    ${props => props.variant && props.variant.noBottomSpacing && css`
        margin-bottom: -16px;

        @media screen and ${FROM_TABLET} {
            margin-bottom: -${GUTTER.desktop / 2}px;
        }

        @media screen and ${FROM_DESKTOP} {
            margin-bottom: -${GUTTER.desktop}px;
        }
    `};

    /* stylelint-enable */
`;

export const listItemStyles = css`
    align-items: stretch;
    box-sizing: border-box;
    display: flex;
    -webkit-tap-highlight-color: rgba(${RGB_COLORS.BLACK}, 0);
    width: ${props => ((props.variant && props.variant.mobileColumns) ? (100 / props.variant.mobileColumns) : 100)}%;

    @media screen and ${FROM_TABLET} {
        padding-bottom: ${GUTTER.desktop / 2}px;
        padding-left: ${GUTTER.desktop / 2}px;
        width: ${props => ((props.variant && props.variant.tabletColumns) ? (100 / props.variant.tabletColumns) : 100)}%;
    }

    @media screen and ${FROM_DESKTOP} {
        padding-bottom: ${GUTTER.desktop}px;
        padding-left: ${GUTTER.desktop}px;
        width: ${props => ((props.variant && props.variant.desktopColumns) ? (100 / props.variant.desktopColumns) : 100)}%;
    }

    /* stylelint-disable */

    ${props => props.variant && props.variant.isMarketNews && css`
        @media screen and ${FROM_TABLET} {
            padding-left: 24px;
        }

        @media screen and ${FROM_DESKTOP} {
            padding-left: 28px;
        }
    `}

    /* stylelint-enable */
`;
