import { css } from 'styled-components';
import { FROM_MOBILE_TO_TABLET, FROM_TABLET } from 'utils/mediaQueries';
import { COLORS } from 'utils/variables';

export const sectionHeadingStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
`;

export const clearButtonStyles = css`
    border: 0;
    color: ${COLORS.SAPPHIRE};
    font-size: 12px;
    font-weight: 700;
    line-height: 1.5;
    padding: 0;
    text-transform: uppercase;

    &:hover{
        color: ${COLORS.DARK_SAPPHIRE};
    }

    @media screen and ${FROM_MOBILE_TO_TABLET} {
        margin-bottom: 21px;
        margin-top: -10px;
        text-align: left;
    }
`;

export const sectionHeaderStyles = css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`;

export const listStyles = css`
    list-style: none;
    margin: 0;
    padding: 0;
`;

export const filterSectionStyles = css`
    padding: 30px 32px;

    &:last-of-type:not(:first-of-type) {
        margin-top: -7px;
    }
`;

export const selectionCountStyles = css`
    font-weight: 400;
    margin-left: 4px;
`;

export const noResultsStyles = css`
    color: ${COLORS.MEDIUM_GRAY1};
    font-size: 15px;
    font-style: italic;
    padding-top: 18px;
`;

export const noResultsHeaderStyles = css`
    border-bottom: solid 1px ${COLORS.LIGHT_GRAY2};

    @media screen and ${FROM_TABLET} {
        border-bottom-width: 2px;
    }

    opacity: 0.75;
`;
