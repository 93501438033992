import { css } from 'styled-components';
import {
    COLORS,
    FONTS,
} from 'utils/variables';

export const wrapperStyle = css`
    position: relative;
    font-size: 12px;
`;

export const badgeStyle = css`
    ${props => props.position === 'center' ? css`
        align-self: center;
        justify-self: center;
        margin-left: 10px;
    ` : css`
        position: absolute;
    `};
    background-color: ${COLORS.ALERT_RED};
    z-index: ${props => props.zIndex || 1};
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 0.9em;
    font-weight: 500;
    color: ${COLORS.WHITE};

    span {
        margin-top: 2px;
        font-size: 0.9em;
    }

    transform: scale(${props => props.scale || 1}) translate(${props => props.horizontalOffset || 0}px, ${props => props.verticalOffset || 0}px);

    ${props => props.label === ''
    ? css`
        ${props => props.position !== 'center' && css`
            right: -0.4em;
            top: -0.4em;
        `};
        min-width: 0.8em;
        height: 0.8em;
        border-radius: 0.4em;
    ` : css`
        ${props => props.position !== 'center' && css`
            right: -0.8em;
            top: -0.8em;
        `};
        min-width: 1.6em;
        height: 1.6em;
        border-radius: 0.8em;
    `};
`;