import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { SourceSelector } from 'components';
import { FROM_TABLET } from 'utils/mediaQueries';
import { formatSourceName } from "../../../../utils/sourceUtils";

const StyledSources = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and ${FROM_TABLET} {
        flex-direction: row;
        flex-wrap: wrap;
        margin: 25px 0 0 -10px;
    }
`;

/**
 * Render the top sources tab for source onboarding page.
 * @param {*} param0 
 */
const TopSourcesTabPanel = ({ sources, selected, changeStatus }) => (
    <StyledSources>
        {sources.map(source => (
            <SourceSelector
                noUnderline={true}
                key={source.id}
                onChange={() => changeStatus(source.id)}
                checked={selected.includes(source.id)}
                imageName={source.imageName}
                value={source.id}
                name={formatSourceName(source.name)}
            >
                {source}
            </SourceSelector>
        ))}
    </StyledSources>
);

TopSourcesTabPanel.propTypes = {
    sources: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        imageName: PropTypes.string,
    })).isRequired,
    selected: PropTypes.arrayOf(PropTypes.number),
    changeStatus: PropTypes.func.isRequired,
};

TopSourcesTabPanel.defaultProps = {
    selected: [],
};

export default TopSourcesTabPanel;
