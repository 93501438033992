import { css } from 'styled-components';
import {
    FROM_DESKTOP,
    FROM_TABLET, 
} from 'utils/mediaQueries';
import {
    COLORS,
} from 'utils/variables';

export const sectionStyles = css`
    position: relative;
    background: ${COLORS.LIGHT_GRAY1};
`;

export const blankAreaStyles = css`
    width: 100%;
    background: ${COLORS.WHITE};
    height: 2430px;
    position: relative;

    @media screen and ${FROM_TABLET} {
        height: 1898px;
    }

    @media screen and ${FROM_DESKTOP} {
        height: 1726px;
    }
`;

export const loaderStyles = css`
    position: absolute;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
    height: 50px;
    width: 50px;

    @media screen and ${FROM_TABLET} {
        width: 80px;
        height: 80px;
        left: calc(50% - 40px);
        top: calc(50% - 40px);
    }

    @media screen and ${FROM_DESKTOP} {
        width: 100px;
        height: 100px;
        left: calc(50% - 50px);
        top: calc(50% - 50px);
    }
`;

export const buttonContainer = css`
    text-align: center;
    padding-top: 24px;
    padding-bottom: 32px;
    background-color: ${COLORS.WHITE};
    border-bottom: 1px solid ${COLORS.LIGHT_GRAY2};
    padding-left: 16px;
    padding-right: 16px;

    &>a {
        display: block;

        &:hover{
            color: ${COLORS.MEDIUM_GRAY2};

            svg{
                stroke: ${COLORS.MEDIUM_GRAY2};
            }
        }
    }

    @media screen and ${FROM_TABLET} {
        padding-left: 0;
        padding-right: 0;
        padding-top: 32px;
        text-align: right;
        padding-bottom: 40px;
        background-color: transparent;
        border-bottom: none;

        &>a {
            display: inline-block;
        }
    }
`;

export const listAreaCard = css`
    border-top: 16px solid ${COLORS.LIGHT_GRAY1};
    border-bottom: 16px solid ${COLORS.LIGHT_GRAY1};
    background: ${COLORS.LIGHT_GRAY1};

    &>div {
        max-width: inherit;
    }
`;

export const listAreaCardWrapper = css`
    border-bottom: 1px solid ${COLORS.LIGHT_GRAY2};
`;

export const listArea = css`
    display: flex;
    flex-direction: column;
    border-top: 1px solid ${COLORS.LIGHT_GRAY2};
    margin-top: -56px;

    *[data-rel="SplashNotification"] {
        margin-left: 16px;
        margin-right: 16px;
    }

    &>div {
        max-width: inherit;
        background-color: ${COLORS.WHITE};
    }

    @media screen and ${FROM_TABLET} {
        *[data-rel="SplashNotification"] {
            margin-left: initial;
            margin-right: initial;
        }
    }
`;

export const cardsArea = css`
    @media screen and ${FROM_TABLET} {
        padding-top: 25px;
    }
`;

export const controlsContainerStyles = css`
    display: flex;
    justify-content: center;
    margin-bottom: 83px;
    margin-top: 24px;

    @media screen and ${FROM_TABLET} {
        justify-content: flex-end;
        margin-bottom: 81px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 66px;
    }
`;

export const headerStyles = css`
    padding-bottom: 14px;
    padding-left: 16px;
    padding-right: 16px;

    h2 {
        color: ${COLORS.BLACK};
        font-weight: 600;
        margin-bottom: 0;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: -0.09px;

        span{
            color:${COLORS.ALERT_RED};
            font-size:15px;
        }
    }

    @media screen and ${FROM_TABLET} {
        padding-bottom: 24px;
        padding-left: 0;
        padding-right: 0;
    }

    @media screen and ${FROM_DESKTOP} {
        h2 {
            font-size: 26px;
            line-height: 32px;
            letter-spacing: -0.1px;
        }
        padding-bottom: 24px;
    }
`;
