export const POSITIONS = {
    BOTTOM_CENTER: 'bottom-center',
    BOTTOM_LEFT: 'bottom-left',
    BOTTOM_RIGHT: 'bottom-right',
    LEFT_BOTTOM: 'left-bottom',
    LEFT_CENTER: 'left-center',
    LEFT_TOP: 'left-top',
    RIGHT_BOTTOM: 'right-bottom',
    RIGHT_CENTER: 'right-center',
    RIGHT_TOP: 'right-top',
    TOP_CENTER: 'top-center',
    TOP_LEFT: 'top-left',
    TOP_RIGHT: 'top-right',
};
