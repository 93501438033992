import React from 'react';

import {
    TOPIC_QUERY,
} from 'state-management/constants/pageableNews';

import LandingContainer from 'containers/PageableNews/LandingContainer';

/**
 * CE credit center page.
 * @param {*} props 
 */
const CECreditsContainer = props => {
    return (
        <LandingContainer
            {...props}
            noMaxWidth={true}
            displayResultCount={false}
            query={TOPIC_QUERY}
            headingTranslationId="practiceManagement.cecredits"
            type="cecredits"
        />
    );
}

export default CECreditsContainer;
