import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { isSubtypeOfGuide } from 'utils/contentCardUtils';
import { CardsGrid, PagedList, SeeMoreButton } from 'components';
import {
    titleStyles,
    pagedTypedListWrapperStyles,
    seeMoreButtonWrapStyles,
} from 'containers/Search/View/styles';
import ItemView from './ItemView';

const StyledPagedTypedListWrapper = styled.div`
    ${pagedTypedListWrapperStyles}
`;

const StyledSeeMoreButtonWrap = styled.div`
    ${seeMoreButtonWrapStyles}
`;

const StyledTitle = styled.h2`
    ${titleStyles}
`;

const renderItem = (item, idx) => <ItemView item={item} idx={idx} page={1} key={item.id} />;

const CardsGridWithBackground = cardsGridProps => (
    <CardsGrid
        {...cardsGridProps}
        hasBackground
    />
);

/**
 * Render a single section of results by type.
 * Used by TypedIndexViews when search results filter by types are used.
 * @param {*} props 
 */
const TypedIndexView = (props) => {
    const {
        items, type, pageSize, onSeeMore,
    } = props;

    const titleTranslationId = isSubtypeOfGuide(type) ? 'search.sort.type.practiceManagement' : 'search.sort.type.news';
    const seeMoreTranslationId = isSubtypeOfGuide(type) ? 'search.sort.seeMore.practiceManagement' : 'search.sort.seeMore.news';

    return items.length > 0 && (
        <StyledPagedTypedListWrapper>
            <StyledTitle>
                <FormattedMessage id={titleTranslationId} />
            </StyledTitle>
            <PagedList
                itemsCount={items.length}
                onPageChange={() => {}}
                page={1}
                pageItems={items}
                pageWrapperComponent={CardsGridWithBackground}
                pageSize={pageSize}
                renderItem={renderItem}
                scrollToTop
            />
            {items.length >= 9 && (
                <StyledSeeMoreButtonWrap>
                    <SeeMoreButton onClick={onSeeMore}>
                        <FormattedMessage id={seeMoreTranslationId} />
                    </SeeMoreButton>
                </StyledSeeMoreButtonWrap>
            )}
        </StyledPagedTypedListWrapper>
    );
};

TypedIndexView.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        category: PropTypes.string,
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        image: PropTypes.string,
        internal: PropTypes.bool,
        isSaving: PropTypes.bool,
        link: PropTypes.string,
        publishDate: PropTypes.string,
        saved: PropTypes.bool,
        sourceLogo: PropTypes.string,
        sourceTitle: PropTypes.string,
        title: PropTypes.string,
        topics: PropTypes.array,
        type: PropTypes.string,
        visited: PropTypes.bool,
        contentType: PropTypes.string,
        interactionType: PropTypes.string,
        interactionId: PropTypes.string,
    })),
    onSeeMore: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    pageSize: PropTypes.number.isRequired,
};

TypedIndexView.defaultProps = {
    items: [],
};

export default TypedIndexView;
