import { css } from 'styled-components';
import {
    COLORS,
    FONTS,
    ANIMATIONS,
} from '../../../../utils/variables';
import {
    FROM_DESKTOP,
    FROM_TABLET,
    SMALL_MOBILE,
} from 'utils/mediaQueries';

export const mobileIndiceContainerStyles = css`
    width: 130px;
    max-width: 130px;
    min-width: 130px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 6px;

    >div {
        width: auto;
        max-width: none;
    }
`;

export const bloombergMobileContainerStyles = css`
    color: ${COLORS.SAPPHIRE};
    max-width: 104px;
    width: 104px;
    min-width: 104px;
    display: inline-block;
    height: 64px;
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 7px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    background-color: ${COLORS.WHITE};
    padding-top: 0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px Solid ${COLORS.LIGHT_GRAY2};
    background-color: ${COLORS.WHITE};

    >div {
        span {
            color: ${COLORS.BLACK};
            font-weight: 900;
        }

        svg{
            width: 78px;
            height: 25px;
            padding-left: 23px;
        }
    }

    @media screen and ${FROM_TABLET} {
        >div {
            svg {
                width: 104px;
            }
        }
    }

    .bloomberg-logo {
        padding-top: 4px;
    }

    >span {
        overflow: hidden;
        background-color: ${COLORS.SAPPHIRE};
        transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;
        display: inline-block;
        height: 22px;
        padding-top: 8px;
        width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        svg {
            width: 32px;
            height: 32px;
            position: relative;
            transform: scale(.6);
            top: -9px;
        }
    }

    &:hover {
        >span {
            background-color: ${COLORS.DARK_SAPPHIRE};
        }
    }
`;

export const briefingMobileContainerStyles = css`
    color: ${COLORS.WHITE};
    max-width: 104px;
    min-width: 104px;
    width: 104px;
    display: inline-block;
    height: 64px;
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px Solid ${COLORS.LIGHT_GRAY2};
    background-color: ${COLORS.WHITE};

    span {
        background-color: ${COLORS.SAPPHIRE};
        transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;
        display: inline-block;
        height: 22px;
        padding-top: 8px;
        width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    svg {
        width: 70px;
        height: 22px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 15px;
        padding-right: 15px;
    }

    @media screen and ${FROM_TABLET} {
        svg {
            width: 104px;
        }
    }

    &:hover {
        span {
            background-color: ${COLORS.DARK_SAPPHIRE};
        }
    }
`;


export const indiceStyles = css`
    /* stylelint-disable */
    height: 20px;
    color: ${COLORS.DARK_GRAY1};
    text-align: left;
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    color: var(--darkGray1);
    width: calc(33.3% - 16px);
    max-width: 126px;

    em {
        width: 0;
        height: 0;
        border-style: solid;
        display: inline-block;
        position: relative;
        top: 2px;
    }

    @media screen and ${SMALL_MOBILE} {
        padding-top:8px;
    }

    @media screen and ${FROM_DESKTOP} {
        max-width: 150px;
    }
`;

export const directionContainerStyles = css`
    text-align: left;
    margin: auto;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;

    span {
        display: block;
    }

    span:nth-child(2) {
        text-align: right;
        flex: 1;
        margin-right: 14px;
    }

    ${props => props.direction === 'negative' && css`
        em  {
            border-width: 8px 5px 0 5px;
            border-color: ${COLORS.ALERT_RED} transparent transparent transparent;
        }

        span:nth-child(2) {
            color: ${COLORS.ALERT_RED};
        }
    `};

    ${props => props.direction === 'positive' && css`
        em {
            border-width: 0 5px 8px 5px;
            border-color: transparent transparent ${COLORS.BOND_GREEN} transparent;
        }

        span:nth-child(2) {
            color: ${COLORS.BOND_GREEN};
        }
    `};
`;

export const outerWrapperStyles = css`
    div[data-rel="CoachMarkWrapper"] {
        display: block;
    }
`;

export const mobileContainer = css`
    text-align: center;

    >div {
        margin: auto;
        display: inline-block;
        width: 50%;

        >div {
            display: flex;
            padding-top: 4px;
            padding-bottom: 4px;

            >a {
                margin-left: 4px;
                margin-right: 4px;
                width: 100%;
                max-width: initial;
            }
        }
    }

    ${props => props.hasBriefing && css`
        >div {
            display: block;
            padding-left: 16px;
            padding-right: 16px;
            width: auto;

            >div {
                width: 100%;
                
                >a {
                    max-width: none;
                    width: 50%;
                }
            }
        }
    `};
`;

export const containerStyles = css`
    background-color: ${COLORS.WHITE};
    height: 32px;
    overflow: hidden;
    padding-top: 7px;
    text-align: center;

    &>div {
        margin: auto;
        display: inline-block;
    }

    @media screen and ${SMALL_MOBILE} {
        margin-top: -24px;
    }

    @media screen and ${FROM_TABLET} {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    @media screen and ${FROM_DESKTOP} {
        text-align: right;
    }
`;

export const mobileContainerStyles = css`
    background-color: ${COLORS.WHITE};
    overflow: hidden;
    padding-top: 7px;
    height: 74px;
    padding-left: 0px !important;
    padding-right: 0px !important;

    .slick-slide {
        position: relative;
        top: 8px;

        >div {
            padding: 8px !important;
        }
    }

    .slick-arrow {
        display: block !important;

        svg {
            width: 20px !important;
            height: 20px !important;
            padding:8px !important;
        }
    }

    .slick-prev {
        left: -37px !important;
        height: 85px;
        width: 37px;
        background-color: ${COLORS.LIGHT_GRAY2};
        margin-top:-1px;
    }

    .slick-next {
        right: -37px !important;
        height: 85px;
        width: 37px;
        background-color: ${COLORS.LIGHT_GRAY2};
        margin-top:-1px;
    }

    .slick-list,
    .slick-track {
        touch-action: pan-y; 
    }
`;

export const indicesContainerStyles = css`
    display: flex;
    width: 440px;
    padding-top:10px;
    flex-wrap: wrap;
    text-align: right;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;

    >div:first-child {
        margin-right: 24px;
        margin-bottom: 4px;
        border-bottom: 1px solid ${COLORS.NEUTRAL2};
        padding-bottom:5px;
    }

    >div:nth-child(2) {
        margin-right: 24px;
        margin-bottom: 4px;
        border-bottom: 1px solid ${COLORS.NEUTRAL2};
        padding-bottom:5px;
    }

    >div:nth-child(3) {
        margin-bottom: 4px;
        border-bottom: 1px solid ${COLORS.NEUTRAL2};
        padding-bottom:5px;
    }

    >div:nth-child(4) {
        margin-right: 24px;
    }

    >div:nth-child(5) {
        margin-right: 24px;
    }

    @media screen and ${FROM_DESKTOP} {
        width: 498px;
        padding-top:10px;
    }
`;

export const bloombergContainerStyles = css`
    color: ${COLORS.WHITE};
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 10px;
    font-weight: 500;
    text-decoration: none;
    margin-left: 12px;
    display: flex;
    flex-direction: row;
    width: 198px;

    ${props => !props.hasBriefing && css`
        margin-left: 0;
    `};

    span {
        margin-top: 4px;
    }

    div:last-child {
        margin-top: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color:${COLORS.WHITE};
        border:1px Solid ${COLORS.LIGHT_GRAY2};
        height: 30px;
        width: 97px;

        svg {
            width: 104px;
            text-align: center;
            padding-left: 23px;
            padding-top: 4px;
        }
    }

    &:hover {
        >div:first-child {
            background-color: ${COLORS.DARK_SAPPHIRE};
        }
    }
`;

export const briefingContainerStyles = ({ variant }) => css`
    color: ${COLORS.WHITE};
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 10px;
    font-weight: 500;
    text-decoration: none;
    display: flex;
    flex-direction: row;

    div:nth-child(2) {
        width: 97px;
        height: 30px;
        margin-top: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color:${COLORS.WHITE};
        border: 1px Solid ${COLORS.LIGHT_GRAY2};
    }

    svg {
        width: 104px;
        text-align: center;
        padding-left: 15px;
        padding-top: 4px;
    }

    &:hover {
        >div:first-child {
            background-color: ${COLORS.DARK_SAPPHIRE};
        }
    }
`;

export const chipsContainerStyles = css`
    display: flex;
`;

export const topChipContainerStyles = ({ variant }) => css`
    display: block;
    background-color: ${COLORS.SAPPHIRE};
    color: ${COLORS.WHITE};
    width: 99px;
    height: 22px;
    padding-top: 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    text-align:center;
    background-size: 100%;
    transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 104px;
        height: 32px;
    }
`;

export const topChipBBContainerStyles = ({ variant }) => css`
    display: block;
    background-color: ${COLORS.SAPPHIRE};
    color: ${COLORS.WHITE};
    width: 104px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    text-align: center;
    background-size: 100%;
    height:32px;
    transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;

    svg {
        text-align: center;
        padding-top: 7px;
        height: 18px;
        width: 18px;
    }
`;
