import React from 'react';
import styled from 'styled-components';
import Link from '../../../../components/atomics/atoms/Link/Link';
import ImageAtom from 'components/atomics/atoms/Image/Image';
import { withCommonCard } from 'components/atomics/hocs/CommonCard/CommonCard';
import {
    containerStyles,
    contentContainerStyles,
    dateAndTopicContainerStyles,
    dateContainerStyles,
    topicLinkStyles,
    titleStyles,
    imageContainerStyles,
    imageOverlayStyles,
} from 'components/atomics/molecules/SkinnyListGuideCard/styles';
import {
    BreakpointsContext,
    MOBILE,
} from 'components/Breakpoints/Breakpoints';
import labels from '../../../../lang/en.json';
import ListContentCardFooter from "../ListContentCard/ListContentCardFooter";
import { TYPE_GUIDE } from "../../../../state-management/constants/savedContent";
import { getTruncatedString, getResizedImage } from 'utils/contentCardUtils';
import {  getArticleImage } from 'utils/getArticleAsset';

export const Title = styled(Link)`
    ${titleStyles}
`;

export const DateAndTopic = styled.div`
    ${dateAndTopicContainerStyles}
`;

export const DateContainer = styled.div`
    ${dateContainerStyles}
`;

export const MainContainer = styled.div`
    ${dateContainerStyles}
`;

export const TopicLink = styled(Link)`
    ${topicLinkStyles}
`;

export const Container = styled.div`
    ${containerStyles}
`;

export const ImageContainer = styled(Link)`
    ${imageContainerStyles}
`;

export const ContentContainer = styled.div`
    ${contentContainerStyles}
`;

export const ImageOverlay = styled.div`
    ${imageOverlayStyles}
`;

const MAX_ARTICLE_TITLE_CHARS = 120;

const TYPE_STANDARD = 'STANDARD';
const TYPE_HBR = 'HBR';
const TYPE_MCKINSEY = 'MC_KINSEY';
const TYPE_HBR_ALT = 'GUIDE_HBR';
const TYPE_MCKINSEY_ALT = 'GUIDE_MC_KINSEY';

export const TYPE_INPRACTICE = 'IN_PRACTICE';
export const TYPE_INPRACTICE_ALT = 'GUIDE_IN_PRACTICE';

/**
 * Creates a skinny list guide card.
 */
class SkinnyListGuideCard extends React.Component {
    /**
     * Render the list guide content card and underlying components.
     */
    render() {
        const hasImage = (this.props.article.image) ? true : false;
        const isLastCard = (this.props.isLastCard) ? 'item-last' : '';
        const classNames = `TYPE-${this.props.article.type} ${ this.props.alternativeBackground} ${isLastCard}`;

        let typeLabel = labels.translations['guideCard.guide'];

        if (this.props.article.type === TYPE_HBR
            || this.props.article.type === TYPE_HBR_ALT) {
            typeLabel = labels.translations['guideCard.hbr'];
        } else if (this.props.article.type === TYPE_MCKINSEY
            || this.props.article.type === TYPE_MCKINSEY_ALT) {
            typeLabel = labels.translations['guideCard.mck'];
        } else if (this.props.article.type === TYPE_INPRACTICE
            || this.props.article.type === TYPE_INPRACTICE_ALT) {
            typeLabel = labels.translations['guideCard.inpractice'];
        }

        const guideCardCategory = this.props.article.categoryGroup ? this.props.article.category : null;
        const guideCardCategoryLink = guideCardCategory != null
            ? `/learn/collections/${this.props.article.categoryGroup.toLowerCase().replace(/_/g, '-')}/${this.props.article.categoryCode}`
            : null;
 
        return (
            <BreakpointsContext.Consumer>
                    {(breakpoint) => {
                        const width = breakpoint === MOBILE ? 61 : 129;
                        const height = breakpoint === MOBILE ? 61 : 129;
                        const imageSource = this.props.article.image.includes('http') ? this.props.article.image : getResizedImage(this.props.article.image, width, height);
                        const imageUrl = getArticleImage(this.props.article, 2);

                        return (
                            <Container
                                onMouseEnter={this.props.onMouseEnter}
                                onMouseLeave={this.props.onMouseLeave}
                                className={classNames}
                                className={`${this.props.article.visited ? (this.props.article.visited===true?'news-visited' : 'news-not-visited'):'news-not-visited'}`}
                            >
                                <ImageContainer
                                    className={`${this.props.article.visited ? (this.props.article.visited===true?'news-visited' : 'news-not-visited'):'news-not-visited'}`}
                                    type={this.props.article.type}
                                    to={`/learn/ideas/${this.props.article.link}`}
                                    onClick={() => {
                                        this.props.recordClickAnalytics('guide');
                                        this.props.onArticleClick();
                                    }}
                                >
                                    {hasImage && (
                                        <ImageAtom
                                            src={imageUrl}
                                            alt={labels.translations['global.alt.image']}
                                        />
                                    )}
                                    {(this.props.article.type === TYPE_INPRACTICE
                                    || this.props.article.type === TYPE_INPRACTICE_ALT)
                                    && <ImageOverlay />}
                                </ImageContainer>
                                <ContentContainer>
                                    <DateAndTopic>
                                        <MainContainer>
                                            {typeLabel}
                                        </MainContainer>
                                    </DateAndTopic>
                                    <Title
                                        className={`${this.props.article.visited ? (this.props.article.visited===true?'news-visited' : 'news-not-visited'):'news-not-visited'}`}
                                        to={`/learn/ideas/${this.props.article.link}`}
                                        onClick={() => {
                                            this.props.recordClickAnalytics('guide');
                                            this.props.onArticleClick();
                                        }}
                                    >
                                        {getTruncatedString(this.props.article.title, MAX_ARTICLE_TITLE_CHARS)}
                                    </Title>
                                    <ListContentCardFooter
                                        onElement={this.props.onCardHover}
                                        profile={this.props.profile}
                                        isGuide={true}
                                        setKebabMenuLimitSize={375}
                                        articleTitle={this.props.article.title}
                                        guideCardCategory={guideCardCategory}
                                        guideCardCategoryLink={guideCardCategoryLink}
                                        sourceName={this.props.article.category}
                                        article={this.props.article}
                                        hideSourceOption={true}
                                        saved={this.props.article.saved}
                                        articleOrGuideId={parseInt(this.props.article.id)}
                                        type={TYPE_GUIDE}
                                        shouldShowToast={this.props.shouldShowToast}
                                        onSaveArticle={(id, save, title, getContentAfterSave, article) => {
                                            this.props.toggleSaveArticle(id, save, title, getContentAfterSave, article);
                                        }}
                                        onSaveGuide={(id, save, title, getContentAfterSave, article) => {
                                            this.props.toggleSaveGuide(id, save, title, getContentAfterSave, article);
                                        }}
                                        onFollowTopic={(topicId, status) => {
                                            this.props.toggleFollowTopic(topicId, status);
                                        }}
                                        onFollowSource={(sourceId, status) => {
                                            this.props.toggleFollowSource(sourceId, status);
                                        }}
                                    />
                                </ContentContainer>
                            </Container>
                        );
                }}
            </BreakpointsContext.Consumer>
        );
    }
}

export default withCommonCard(SkinnyListGuideCard);