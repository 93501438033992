import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { linkStyles } from 'components/SkipToMainContent/styles';

const StyledLink = styled.a`
    ${linkStyles};
`;

const SkipToMainContent = props => (
    <StyledLink href={`#${props.hash}`}>
        <FormattedMessage id="skip.link.text" />
    </StyledLink>
);

SkipToMainContent.propTypes = {
    hash: PropTypes.string.isRequired,
};

export default SkipToMainContent;
