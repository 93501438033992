import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'components/atomics/atoms/Link/Link';
import isDesktop from 'utils/isDesktop';
import ImageAtom from 'components/atomics/atoms/Image/Image';
import {
    BreakpointsContext,
    DESKTOP,
    MOBILE,
    TABLET,
} from 'components/Breakpoints/Breakpoints';
import {
    containerStyles,
    contentContainerStyles,
    topicLinkStyles,
    titleStyles,
    sourceLinkStyles,
    imageContainerStyles,
    infoContainerStyles,
    categoriesTitleContainerStyles,
    imageOverlayStyles,
    linkItem,
    topCard,
} from '../../../../components/atomics/molecules/GuideContentCard/styles';
import { contextHubRecordEvent } from 'utils/contextHub';
import {
    CARDS_EVENT_LIST,
} from 'utils/contextHubEventListConstants';
import labels from '../../../../lang/en.json';
import ListContentCardFooter from "../ListContentCard/ListContentCardFooter";
import {
    toggleSaveArticle,
    toggleSaveGuide as toggleSaveGuideAction,
} from "../../../../state-management/actions/save";
import { handleGuideVisit } from '../../../../state-management/actions/guide';
import { toggleTopicFollow } from "../../../../state-management/actions/followTopic";
import { toggleSourceFollow } from "../../../../state-management/actions/sources";
import { connect } from "react-redux";
import { TYPE_GUIDE } from "../../../../state-management/constants/savedContent";
import { getTruncatedString, getResizedImage } from 'utils/contentCardUtils';
import { zip } from 'lodash';

export const ImageContainer = styled(Link)`
    ${imageContainerStyles}
`;

export const TopCardContainer = styled.div`
    ${topCard}
`;

export const SourceLink = styled(Link)`
    ${sourceLinkStyles}
`;

export const Title = styled.div`
    ${titleStyles}
`;

export const TopicLink = styled(Link)`
    ${topicLinkStyles}
`;

export const Container = styled.div`
    ${containerStyles}
`;

export const ContentContainer = styled.div`
    ${contentContainerStyles}
`;

export const LinkContainer = styled(Link)`
    ${linkItem}
`;

export const InfoContainer = styled.div`
    ${infoContainerStyles}
`;

export const CategoriesTitleContainer = styled(Link)`
    ${categoriesTitleContainerStyles}
`;

export const ImageOverlay = styled.div`
    ${imageOverlayStyles}
`;

const MAX_ARTICLE_TITLE_CHARS = 75;

const TYPE_STANDARD = 'STANDARD';
const TYPE_HBR = 'HBR';
const TYPE_MCKINSEY = 'MC_KINSEY';
export const TYPE_INPRACTICE = 'IN_PRACTICE';

/**
 * Creates a guide content card.
 */
class GuideContentCard extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.state = {
            isDesktop: isDesktop(),
            isKebabOpen: false,
            onCardHover: false
        };

        this.handleKebabOpen = this.handleKebabOpen.bind(this);
    }

    /**
     * Handle kebab open/close.
     * @param {*} bool
     */
    handleKebabOpen(bool) {
        this.setState({
            isKebabOpen: bool
        });
    }

    /**
     * Handle analytics.
     */
    CH_handleComponentEventList() {
        contextHubRecordEvent(CARDS_EVENT_LIST(
            'guide',
            this.props.guideCardInfo.title
        ));
    }

    /**
     * Handle article click action.
     */
    onArticleClick() {
        const { guideCardInfo } = this.props;

        this.props.onArticleClick(guideCardInfo);

        return;
    }

    /**
     * Set hover state.
     */
    onMouseEnter = () => {
        this.setState({onCardHover: true});
    }

    /**
     * Remove hover state.
     */
    onMouseLeave = () => {
        this.setState({onCardHover: false});
    }

    /**
     * Handle save guide action.
     */
    saveGuide = (id, save, title, getContentAfterSave, article ) => {
        const {toggleSaveGuide} = this.props;

        toggleSaveGuide(id, save, title, getContentAfterSave, article);
    }

    /**
     * Handle follow topic action.
     */
    followTopic = (id, status) => {
        const {toggleFollowTopic} = this.props;

        toggleFollowTopic(id, status);
    }

    /**
     * Handle follow source action.
     */
    followSource = (id, status) => {
        const {toggleFollowSource} = this.props;

        toggleFollowSource(id, status);
    }

    /**
     * Handle save article action.
     */
    saveArticle = (id, save, title, getContentAfterSave, article ) => {
        const {toggleSaveArticle} = this.props;

        toggleSaveArticle(id, save, title, getContentAfterSave, article);
    }

    /**
     * Render the guide card and underlying components.
     */
    render() {
        const guideTitle = getTruncatedString(this.props.guideCardInfo.title, MAX_ARTICLE_TITLE_CHARS);

        let typeLabel = labels.translations['guideCard.guide'];

        if (this.props.guideCardInfo.type === TYPE_HBR) {
            typeLabel = labels.translations['guideCard.hbr'];
        } else if (this.props.guideCardInfo.type === TYPE_MCKINSEY) {
            typeLabel = labels.translations['guideCard.mck'];
        } else if (this.props.guideCardInfo.type === TYPE_INPRACTICE) {
            typeLabel = labels.translations['guideCard.inpractice'];
        }

        const { onCardHover } = this.state;
        const guideCardCategory = this.props.guideCardInfo.categoryGroup ? this.props.guideCardInfo.category : null;
        const guideCardCategoryLink = guideCardCategory != null
            ? `/learn/collections/${this.props.guideCardInfo.categoryGroup.toLowerCase().replace(/_/g, '-')}/${this.props.guideCardInfo.categoryCode}`
            : null;

        return (
            <BreakpointsContext.Consumer>
                    {(breakpoint) => {
                        const width = breakpoint === DESKTOP ? 272 :
                            breakpoint === TABLET ? 300 : 300;
                        const height = breakpoint === DESKTOP ? 158 :
                            breakpoint === TABLET ? 200: 200;
                        const imageSource = this.props.guideCardInfo.image.includes('http') ? this.props.guideCardInfo.image : getResizedImage(this.props.guideCardInfo.image, width, height);

                        return (
                            <Container
                                data-rel="GuideCard"
                                onMouseEnter={this.onMouseEnter}
                                onMouseLeave={this.onMouseLeave}
                            >
                                <ImageContainer
                                    visited={this.props.guideCardInfo.visited ? "true" : undefined}
                                    type={this.props.guideCardInfo.type}
                                    onClick={() => {
                                        this.CH_handleComponentEventList();
                                    }}
                                    to={`/learn/ideas/${this.props.guideCardInfo.link}`}
                                >
                                    <ImageAtom
                                        src={imageSource}
                                        alt={labels.translations['global.alt.image']}
                                    />
                                    {this.props.guideCardInfo.type === TYPE_INPRACTICE
                                        && <ImageOverlay />}
                                </ImageContainer>

                                <ContentContainer>
                                    <LinkContainer
                                        onClick={() => {
                                            this.CH_handleComponentEventList();
                                        }}
                                        to={`/learn/ideas/${this.props.guideCardInfo.link}`}>
                                        <InfoContainer>
                                            <p>{typeLabel}</p>
                                        </InfoContainer>
                                        <Title
                                            visited={this.props.guideCardInfo.visited ? "true" : undefined}
                                        >
                                            {guideTitle}
                                        </Title>
                                    </LinkContainer>

                                    <ListContentCardFooter onElement={onCardHover}
                                                        profile={this.props.profile}
                                                        isGuide={true}
                                                        articleTitle={guideTitle}
                                                        guideCardCategory={guideCardCategory}
                                                        guideCardCategoryLink={guideCardCategoryLink}
                                                        sourceName={this.props.guideCardInfo.category}
                                                        article={this.props.guideCardInfo}
                                                        hideSourceOption={true}
                                                        saved={this.props.guideCardInfo.saved}
                                                        articleOrGuideId={parseInt(this.props.guideCardInfo.id)}
                                                        type={TYPE_GUIDE}
                                                        shouldShowToast={this.props.shouldShowToast}
                                                        onSaveArticle={(id, save, title, getContentAfterSave, article) => {
                                                            this.saveArticle(id, save, title, getContentAfterSave, article );
                                                        }}
                                                        onSaveGuide={(id, save, title, getContentAfterSave, article) => {
                                                            this.saveGuide(id, save, title, getContentAfterSave, article );
                                                        }}
                                                        onFollowTopic={(topicId, status) => {
                                                            this.followTopic(topicId, status);
                                                        }}
                                                        onFollowSource={(sourceId, status) => {
                                                            this.followSource(sourceId, status);
                                                        }} />
                                </ContentContainer>
                            </Container>
                        );
                    }}
            </BreakpointsContext.Consumer>
        );
    }
}

GuideContentCard.propTypes = {
    guideCardInfo: PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]).isRequired,
        saved: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        type: PropTypes.string,
        visited: PropTypes.bool
    }).isRequired,
    onArticleClick:PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
    onGuideClick: id => dispatch(handleGuideVisit(id)),
    toggleSaveArticle: (id, save, title, getContentAfterSave, article) => dispatch(toggleSaveArticle(id, save, {
        title,
        getContentAfterSave,
        article
    })),
    toggleSaveGuide: (id, save, title, shouldShowToast) => dispatch(toggleSaveGuideAction(id, save, {
        title,
        shouldShowToast
    })),
    toggleFollowTopic: (topicId, isFollowing) => dispatch(toggleTopicFollow(topicId, isFollowing)),
    toggleFollowSource: (sourceId, isFollowing) => (
        dispatch(toggleSourceFollow(sourceId, isFollowing))
    ),
});

const mapState = state => ({
    isLoading: state.followTopic.toggleInProgress,
    profile: state.basicProfile,
});

export default connect(mapState, mapDispatchToProps)(GuideContentCard);
