import { css } from 'styled-components';
import {
    FROM_DESKTOP,
    FROM_TABLET,
} from 'utils/mediaQueries';
import {
    FONTS,
    COLORS,
    ANIMATIONS,
} from 'utils/variables';

export const containerStyles = css`
    min-height: 500px;

    &>div {
        background: transparent;

        &>div {
            padding-top 0;
        }
    }

    p {
        white-space: nowrap;
    }
`;

export const failedContainerStyles = css`
    text-align: center;

    a {
        width: 220px;
        margin-top: 45px;
        margin-bottom: 45px;
    }

    @media screen and ${FROM_TABLET} {
        a {
            width: 188px;
            margin-top: 70px;
            margin-bottom: 70px;
        }
    }

    div {
        margin: auto;
        text-align: left;
    }

    ${props => props.showResendFailure === false && css`
        padding-bottom: 100px;
    `}
`;

export const failedTitleStyles = css`
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    color: ${COLORS.SAPPHIRE};
    font-weight: 400;
    font-size: 26px;
    line-height: 36px;
    max-width: 220px;
    padding-top: 80px;
    margin: auto;

    @media screen and ${FROM_TABLET} {
        font-size: 42px;
        line-height: 52px;
        padding-top: 145px;
        max-width: 340px;
    }

    @media screen and ${FROM_DESKTOP} {
        max-width: 560px;
        padding-top: 250px;
        padding-bottom: 16px;
    }
`;

export const failedDescriptionStyles = css`
    color: ${COLORS.BLACK};
    font-size: 15px;
    line-height: 20px;
    margin: auto;
    max-width: 320px;
    padding-bottom: 40px;
    padding-top: 20px;
    margin-bottom: 100px;

    @media screen and ${FROM_TABLET} {
        max-width: 340px;
    }

    @media screen and ${FROM_DESKTOP} {
        max-width: 560px;
        margin-bottom: 200px;
    }

    a {
        color: ${COLORS.SAPPHIRE};
        transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;

        &:hover {
            color: ${COLORS.DARK_SAPPHIRE}
        }
    }
`;

export const FailedTextStyles = css`
    color: ${COLORS.ALERT_RED};
    font-size: 14px;
`;