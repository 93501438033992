import * as type from 'state-management/constants/tools';

export const getToolsData = id => ({
    type: type.GET_TOOLS_DATA,
    id,
});

export const getToolsSuccess = toolsData => ({
    type: type.GET_TOOLS_DATA_SUCCESS,
    data: toolsData,
});

export const getToolsFailure = error => ({
    type: type.GET_TOOLS_DATA_FAIL,
    error,
});

export const sendClickOnTools = id => ({
    type: type.SEND_CLICK_TOOLS,
    id,
})