import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { routerReducer } from 'react-router-redux';
import history from 'app/history';
import { SIGN_OUT } from 'state-management/constants/signOut';
import advisorClassifications from 'state-management/reducers/advisorClassifications';
import ariaNotification from 'state-management/reducers/ariaNotification';
import articles from 'state-management/reducers/articles.common';
import basicProfile from 'state-management/reducers/basicProfile';
import basicProfileForm from 'state-management/reducers/basicProfileForm';
import categories from 'state-management/reducers/categories';
import coachMark from 'state-management/reducers/coachMark';
import commonContentFragments from 'state-management/reducers/commonContentFragments';
import contactUs from 'state-management/reducers/contactUs';
import errors from 'state-management/reducers/errors';
import followTopic from 'state-management/reducers/followTopic';
import forgotPassword from 'state-management/reducers/forgotPassword';
import forgotUsername from 'state-management/reducers/forgotUsername';
import globalDisclaimer from 'state-management/reducers/globalDisclaimer';
import globalLogoLink from 'state-management/reducers/globalLogoLink';
import globalPopupContainer from 'state-management/reducers/globalPopupContainer';
import guide from 'state-management/reducers/guide';
import homepage from 'state-management/reducers/homepage.common';
import latestCapitalIdeas from 'state-management/reducers/latestCapitalIdeas';
import legalAgreement from 'state-management/reducers/legalAgreement.common';
import location from 'state-management/reducers/location';
import marketNews from 'state-management/reducers/marketNews';
import marketIndices from 'state-management/reducers/marketIndices.common';
import navigation from 'state-management/reducers/navigation';
import onboarding from 'state-management/reducers/onboarding';
import pageableNews from 'state-management/reducers/pageableNews';
import profileRefinementCard from 'state-management/reducers/profileRefinementCard';
import requestSource from 'state-management/reducers/requestSource';
import savedContent from 'state-management/reducers/savedContent';
import scroll from 'state-management/reducers/scroll';
import search from 'state-management/reducers/search';
import searchFilter from 'state-management/reducers/searchFilter';
import signIn from 'state-management/reducers/signIn';
import signUp from 'state-management/reducers/signUp';
import sources from 'state-management/reducers/sources';
import toasts from 'state-management/reducers/toasts';
import tools from 'state-management/reducers/tools';
import topics from 'state-management/reducers/topics';
import UrlHistoryReducer from 'state-management/reducers/UrlHistoryReducer';
import allTopics from 'state-management/reducers/allTopics.common';
import topNews from 'state-management/reducers/topNews';
import trendingTopicNews from 'state-management/reducers/trendingTopic.common';
import optin from 'state-management/reducers/optin.common';
import marketBriefing from 'state-management/reducers/marketBriefing.common';
import featureToggle from 'state-management/reducers/featureToggle.common';
import webinar from 'state-management/reducers/webinar';

const appReducer = combineReducers({
    advisorClassifications,
    ariaNotification,
    articles,
    basicProfile,
    basicProfileForm,
    categories,
    commonContentFragments,
    coachMark,
    contactUs,
    errors,
    followTopic,
    forgotPassword,
    forgotUsername,
    globalDisclaimer,
    globalLogoLink,
    globalPopupContainer,
    guide,
    homepage,
    marketNews,
    marketIndices,
    latestCapitalIdeas,
    legalAgreement,
    location,
    navigation,
    onboarding,
    pageableNews,
    UrlHistoryReducer,
    profileRefinementCard,
    requestSource,
    router: routerReducer,
    savedContent,
    scroll,
    search,
    searchFilter,
    signIn,
    signUp,
    sources,
    toasts,
    tools,
    topics,
    allTopics,
    topNews,
    trendingTopicNews,
    optin,
    marketBriefing,
    featureToggle,
    webinar,
    router: connectRouter(history),
});

export default (state, action) => {
    let updatedState = Object.assign({}, state);

    if (action.type === SIGN_OUT) {
        const { router } = updatedState;
        updatedState = {
            router,
        };
    }

    return appReducer(updatedState, action);
};
