import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isSupported } from 'utils/stickyUtils';
import { ScrollPositionContext } from 'components/ScrollPosition/ScrollPosition';
import animationFrame from 'utils/animationFrame';

let globalSubscribers = [];
const stickyItems = {
    items: [],
    top: 0,
};

export const StickyContainerContext = React.createContext({
    subscribe: () => {},
    unsubscribe: () => {},
});

const StyledContainer = styled.div`
    position: relative;
`;

const StickyContainer = props => (
    <ScrollPositionContext.Consumer>
        {scrollProps => (
            <StickyContainerInner
                {...props}
                {...scrollProps}
            />
        )}
    </ScrollPositionContext.Consumer>
);

class StickyContainerInner extends React.PureComponent {
    constructor(props) {
        super(props);

        this.localSubscribers = [];

        this.containerRef = React.createRef();

        this.handleScrollPositionChange = this.handleScrollPositionChange.bind(this);
        this.getContainerNode = this.getContainerNode.bind(this);
        this.subscribe = this.subscribe.bind(this);
        this.unsubscribe = this.unsubscribe.bind(this);

        this.state = {
            providerValue: {
                subscribe: this.subscribe,
                unsubscribe: this.unsubscribe,
            },
        };
    }

    componentDidMount() {
        const { subscribeToScroll } = this.props;

        this.handleScrollPositionChange();

        subscribeToScroll(this.handleScrollPositionChange);

        this.createObserver();
    }

    componentWillUnmount() {
        const { unsubscribeFromScroll } = this.props;

        if (this.rafHandler) {
            animationFrame.cancel(this.rafHandler);
            this.rafHandler = null;
        }

        unsubscribeFromScroll(this.handleScrollPositionChange);

        if (this.observer) {
            this.observer.disconnect();
        }
    }

    getContainerNode() {
        return this.containerRef.current;
    }

    handleObserve = (entries) => {
        const node = this.getContainerNode();
        let height;

        entries.forEach(() => {
            if (node && height !== node.clientHeight) {
                height = node.clientHeight;
                this.handleScrollPositionChange();
            }
        });
    };

    subscribe(subscriber) {
        this.localSubscribers.push(subscriber);
        globalSubscribers.push(subscriber);
    }

    unsubscribe(id) {
        this.localSubscribers = this.localSubscribers.filter(item => item.id !== id);
        globalSubscribers = globalSubscribers.filter(item => item.id !== id);
    }

    createObserver() {
        const node = this.getContainerNode();
        const config = { attributes: false, childList: true, subtree: true };

        this.observer = new MutationObserver(this.handleObserve);

        this.observer.observe(node, config);
    }

    handleScrollPositionChange(scrollPosition = {}) {
        if (!this.framePending) {
            this.rafHandler = animationFrame.request(() => {
                this.framePending = false;
                const containerNode = this.getContainerNode();
                const containerPosition = containerNode
                    ? containerNode.getBoundingClientRect()
                    : {};

                if (scrollPosition.top !== stickyItems.top) {
                    stickyItems.items = [];
                    stickyItems.top = scrollPosition.top;

                    globalSubscribers.forEach(({ id, stickyPositionGetter }) => {
                        const position = stickyPositionGetter();
                        stickyItems.items.push({
                            id,
                            position,
                        });
                    });
                }

                this.localSubscribers.forEach(({ id, scrollHandler }) => {
                    let beforeCurrent = true;
                    const stickyPosition = {
                        after: 0,
                        before: 0,
                    };

                    stickyItems.items.forEach((item) => {
                        if (item.id === id) {
                            beforeCurrent = false;
                        } else {
                            const { position } = item;

                            if (!isSupported || (isSupported && position.isFixed)) {
                                if (beforeCurrent) {
                                    const isStickyTop = typeof position.height === 'number'
                                        && typeof position.topOffset === 'number';
                                    stickyPosition.before += isStickyTop ? position.height : 0;
                                } else {
                                    const isStickyBottom = typeof position.height === 'number'
                                        && typeof position.bottomOffset === 'number';
                                    stickyPosition.after += isStickyBottom ? position.height : 0;
                                }
                            }
                        }
                    });

                    if (scrollHandler && typeof scrollHandler === 'function') {
                        scrollHandler(
                            containerPosition,
                            scrollPosition,
                            stickyPosition,
                        );
                    }
                });
            });

            this.framePending = true;
        }
    }

    render() {
        const { children, className } = this.props;

        return (
            <StickyContainerContext.Provider
                value={this.state.providerValue}
            >
                <StyledContainer
                    className={className}
                    ref={this.containerRef}
                >
                    { children }
                </StyledContainer>
            </StickyContainerContext.Provider>
        );
    }
}

StickyContainerInner.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    className: PropTypes.string,
    subscribeToScroll: PropTypes.func,
    unsubscribeFromScroll: PropTypes.func,
};

StickyContainerInner.defaultProps = {
    children: null,
    className: '',
    subscribeToScroll: () => {},
    unsubscribeFromScroll: () => {},
};

StickyContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    className: PropTypes.string,
};

StickyContainer.defaultProps = {
    children: null,
    className: '',
};

export default StickyContainer;
