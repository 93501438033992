import { css } from 'styled-components';
import {
    FROM_TABLET,
    FROM_DESKTOP,
    ABOVE_MAX_SPLASH_WIDTH,
    breakpoints,
} from 'utils/mediaQueries';
import { COLORS } from '../../utils/variables';

export const wrapperContainerStyles = css`
    @media screen and ${FROM_TABLET} {
        background-image: linear-gradient(to bottom, ${COLORS.WHITE}, ${COLORS.LIGHT_GRAY1} 80%, ${COLORS.LIGHT_GRAY2} 80%, ${COLORS.LIGHT_GRAY1} 100%);
    }
`;

export const outerContainerStyles = css`
    background-image: linear-gradient(to bottom, ${COLORS.WHITE}, ${COLORS.LIGHT_GRAY1} 88%, ${COLORS.LIGHT_GRAY2} 88%, ${COLORS.LIGHT_GRAY1} 100%);    
    position: relative;
    padding-bottom: 60px;
    overflow: hidden;
    margin: auto;
    width: 100%;
    max-width: ${breakpoints.max_splash_width}px;
    height: 460px;
    max-height: 460px;

    @media screen and ${FROM_TABLET} {
        background-image: linear-gradient(to bottom, ${COLORS.WHITE}, ${COLORS.LIGHT_GRAY1} 80%, ${COLORS.LIGHT_GRAY2} 80%, ${COLORS.LIGHT_GRAY1} 100%);
        height: 430px;
        max-height: 430px;
    }

    @media screen and ${FROM_DESKTOP} {
        height: 700px;
        max-height: 700px;
    }

    a[type="type/primary"] {
        padding-left: 22px;
        padding-right: 22px;

        @media screen and ${FROM_TABLET} {
            padding-left: 48px;
            padding-right: 48px;
        }
    }
`;

export const gradientOverlayLeftStyles = css`
    position: absolute;
    top: 80%;
    bottom: 0;
    left: 0;
    width: 120px;
    background-image: linear-gradient(to right, ${COLORS.LIGHT_GRAY1}, transparent);
    display: none;

    @media screen and ${ABOVE_MAX_SPLASH_WIDTH} {
        display: block;
    }

    ${props => props.isSafari && css`
        display: none !important;
    `};

    display: none !important;
`;

export const gradientOverlayRightStyles = css`
    position: absolute;
    top: 80%;
    bottom: 0;
    right: 0;
    width: 120px;
    background-image: linear-gradient(to left, ${COLORS.LIGHT_GRAY1}, transparent);
    display: none;

    @media screen and ${ABOVE_MAX_SPLASH_WIDTH} {
        display: block;
    }

    ${props => props.isSafari && css`
        display: none !important;
    `};

    display: none !important;
`;

export const contentContainerStyles = css`
    position: relative;
`;

export const logoWrapperStyles = css`
    text-align: left;
    cursor: pointer;
    position: relative;
    left: -12px;

    svg {
        min-width: 150px;
        height: 28px;
    }

    @media screen and ${FROM_TABLET} {
        svg {
            min-width: 150px;
            height: 39px;
        }

        left: 0;
    }

    @media screen and ${FROM_DESKTOP} {
        svg {
            min-width: 290px;
            height: 54px;
        }
    }
`;

export const headingStyles = css`
    font-weight: 600;
    font-size: 22px;
    line-height: 1.22;
    max-width: 350px;

    @media screen and ${FROM_TABLET} {
        font-size: 32px;
        line-height: 1.14;
        max-width: 400px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 48px;
        line-height: 1;
        max-width: 600px;
    }
`;

export const descriptionStyles = css`
    color: ${COLORS.BLACK};
    font-weight: 500;
    max-width: 64%;
    line-height: 1.46;
    font-size: 16px;
    margin-bottom: 40px;

    @media screen and ${FROM_TABLET} {
        font-size: 18px;
        margin-top: 25px;
        max-width: 400px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 24px;
        margin-bottom: 60px;
        margin-top: 25px;
        max-width: 400px;
    }
`;

export const topFlexCtnStyles = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    margin-bottom: 60px;

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 160px;
    }
`;

export const logolinkStyles = css`
    border: 0;
`;

export const imageContainerStyles = css`
    position: absolute;
    bottom: 0;
    right: 0;

    @media screen and ${FROM_TABLET} {
        right: -210px;
    }

    @media screen and ${FROM_DESKTOP} {
        right: auto;
        left: 540px;
    }

    ${props => props.isMobile && css`
        width: 100%;

        img {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 53%;
        }
    `};

    @media screen and ${FROM_TABLET} {
        bottom: 0;
    }

    img {
        @media screen and ${FROM_TABLET} {
            height: 360px;
        }

        @media screen and ${FROM_DESKTOP} {
            height: 610px;
        }
    }
`;
