import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    IconPlus,
    IconAnimatedCheck,
} from 'components/atomics/atoms/Icons/Icons';
import {
    inputContainerStyles,
    labelStyles,
    iconContainerStyles,
} from 'components/atomics/atoms/Input/styles';

export const TYPE_LARGE = 'type/large';
export const TYPE_MEDIUM = 'type/medium';
export const TYPE_XLARGE = 'type/xlarge';

export const InputContainer = styled.div`
    ${inputContainerStyles}
`;

export const Label = styled.div`
    ${labelStyles}
`;

export const IconContainer = styled.div`
    ${iconContainerStyles}
`;

/**
 * Renders a form input of one of 3 sizes.
 */
class Input extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.state = {
            isErrored: false,
            isFocused: false,
            value: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
    }

    /**
     * Set error and value states at mount.
     */
    componentDidMount() {
        this.setState({
            isErrored: this.props.isErrored,
            value: this.props.value,
        });
    }

    /**
     * Change to error state on property update as needed.
     */
    componentDidUpdate() {
        if (this.props.isErrored !== this.state.isErrored) {
            this.setState({
                isErrored: this.props.isErrored,
            });
        }
    }

    /**
     * Handle changes to the input.
     * @param {*} event
     */
    handleChange(event) {
        this.setState({
            value: event.target.value,
        }, () => {
            if (this.props.onChange) {
                this.props.onChange({ id: this.props.id, value: this.state.value, event });
            }
        });
    }

    /**
     * Handle key down events and respect
     * onKeyDown parent handlers return of true/false.
     * @param {*} event 
     */
    onKeyDown(event) {
        if (this.props.onKeyDown) {
            const response = this.props.onKeyDown(event);

            if (!response) {
                event.preventDefault();
                event.stopPropagation();
                
                return false;
            }
        }
    }

    /**
     * Permit onkeyup listening for extended validation routines.
     * @param {*} event 
     */
    onKeyUp(event) {
        if (this.props.onKeyUp) {
            this.props.onKeyUp(event);
        }
    }

    /**
     * Handle input focus.
     * @param {*} event
     */
    onFocus(event) {
        this.setState({
            isFocused: true,
        });

        if (this.props.onFocus) {
            this.props.onFocus(event);
        }
    }

    /**
     * Handle input blur.
     * @param {*} event
     */
    onBlur(event) {
        this.setState({
            isFocused: false,
        });

        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    }

    /**
     * Render the input component.
     */
    render() {
        const isEmpty = this.state.value.length === 0;
        const showLabel = !this.props.hideLabelOnPlaceholder
            || (this.props.hideLabelOnPlaceholder
                && this.state.isFocused)
            || (this.props.hideLabelOnPlaceholder
                && this.state.value.length);
        const label = showLabel ? this.props.label : '';
        const inputValueIsValid = this.props.showCheckWhenValid 
            ? this.props.validated : true;

        return (
            <InputContainer
                data-rel="Input"
                type={this.props.type}
                isFocused={this.state.isFocused.toString()}
                isEmpty={(isEmpty).toString()}
                isErrored={(this.props.isErrored).toString()}
                helperText={this.props.helperText}
            >
                {this.props.label
                && <Label
                    type={this.props.type}
                    isFocused={this.state.isFocused.toString()}
                    isErrored={(this.props.isErrored).toString()}
                >
                    {label}
                </Label>}
                {}
                <div>
                    <input
                        autoComplete={this.props.autocomplete}
                        onKeyDown={this.onKeyDown}
                        onKeyUp={this.onKeyUp}
                        placeholder={this.props.placeholder && !showLabel ? this.props.placeholder : null}
                        id={this.props.id}
                        value={this.state.value}
                        type={this.props.inputType ? this.props.inputType : "text"}
                        onChange={this.handleChange}
                        onBlur={this.onBlur}
                        onFocus={this.onFocus}
                        maxLength={this.props.maxLength ? this.props.maxLength : null}
                    />
                    {!isEmpty
                    && <IconContainer
                        type={this.props.type}
                        isErrored={(this.props.isErrored).toString()}
                    >
                        {/*this.state.isErrored
                        && <IconPlus />*/}
                        {(!this.state.isErrored && inputValueIsValid)
                        && <IconAnimatedCheck />}
                    </IconContainer>}
                </div>
                {this.state.isErrored
                && <div>
                    {this.props.error}
                </div>}
                {(!this.state.isErrored
                && this.props.helperText)
                && <div data-rel="helperText">
                    {this.props.helperText}
                </div>}
            </InputContainer>
        );
    }
}

Input.propTypes = {
    placeholder: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.string,
    isErrored: PropTypes.bool,
    type: PropTypes.string,
    onChange: PropTypes.func,
    id: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    inputType: PropTypes.string,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    helperText: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    hideLabelOnPlaceholder: PropTypes.bool,
    autocomplete: PropTypes.string,
    showCheckWhenValid: PropTypes.bool,
    validated: PropTypes.bool,
}

Input.defaultProps = {
    placeholder: null,
    label: null,
    value: '',
    error: null,
    isErrored: false,
    type: TYPE_LARGE,
    onChange: null,
    maxLength: null,
    inputType: null,
    onKeyDown: null,
    onKeyUp: null,
    helperText: null,
    onBlur: null,
    onFocus: null,
    hideLabelOnPlaceholder: false,
    autocomplete: null,
    showCheckWhenValid: false,
    validate: true,
}

export default Input;
