import {
    all, call, put, takeLatest, takeEvery
} from 'redux-saga/effects';
import { setError } from 'state-management/actions/errors';
import {
    GET_TRENING_TOPIC_FILTERS,
    GET_TRENDING_TOPIC_NEWS,
    TRENDING_TOPIC_NEWS_URL,
    TRENDING_TOPIC_FILTER_DURATION_URL,
    TRENDING_TOPIC_FILTER_AUM_URL,
    TRENDING_TOPIC_FILTER_HOUSEHOLD_URL,
    TRENDING_TOPIC_FILTER_RETIREMENT_URL,
    TRENDING_TOPIC_FILTER_RETIREMENT_PLANS_URL,
} from 'state-management/constants/trendingTopic.common';
import {
    getTrendingTopicNewsFailure,
    getTrendingTopicNewsSuccess,
    getTrendingTopicFiltersSuccess,
    getTrendingTopicFiltersFailure,
} from 'state-management/actions/trendingTopic.common';
import Api from '../bridge/api';
import {
    VISIT_TT_ARTICLE,
    ARTICLE_VISIT_URL,
} from 'state-management/constants/articles.common';
import { handleTTArticleVisit } from 'state-management/actions/articles.common';

/**
 * Get default trending topic news.
 */
function* getTrendingTopicNews() {
    try {
        const newsData = yield call(Api.get, TRENDING_TOPIC_NEWS_URL);

        const { content, seeMore } = newsData.data;
        yield put(getTrendingTopicNewsSuccess(content, seeMore.stories_trending));
    } catch (e) {
        yield put(getTrendingTopicNewsFailure(e));
        yield put(setError('news.headlines.server.error', e));
    }
}

/**
 * Get the filter values for the trending topics component.
 * @param {*} action
 */
function* getTrendingTopicFilters(action) {
    try {
        let householdDataItem = null;
        let retirementPlanDataItem = null;
        
        const aumData = yield call(Api.get, TRENDING_TOPIC_FILTER_AUM_URL);

        const durationData = yield call(Api.get, TRENDING_TOPIC_FILTER_DURATION_URL);

        const retirementData = yield call(Api.get, TRENDING_TOPIC_FILTER_RETIREMENT_URL);

        if (!action.variant) {
            const retirementPlanData = yield call(Api.get, TRENDING_TOPIC_FILTER_RETIREMENT_PLANS_URL);
            const householdData = yield call(Api.get, TRENDING_TOPIC_FILTER_HOUSEHOLD_URL);

            householdDataItem = householdData.data;
            retirementPlanDataItem = retirementPlanData.data;
        }

        yield put(getTrendingTopicFiltersSuccess({
            duration: durationData.data,
            aum: aumData.data,
            household: householdDataItem,
            retirement: retirementData.data,
            retirementPlans: retirementPlanDataItem,
        }));
    } catch (e) {
        yield put(getTrendingTopicFiltersFailure(e));
        yield put(setError('news.headlines.server.error', e));
    }
}

/**
 * Record article clicks.
 * @param {*} param0
 */
function* clickTTArticle({ article }) {
    try {
        const topicId = article.topicId ? article.topicId : article.topics[0].id;

        const articleId = typeof(article.id) === 'string' ? btoa(article.id) : article.id;

        const dataObj =  {
            data: {
                id: articleId,
                provider: article.provider,
                publishDate: article.publishDate ? article.publishDate : article.date,
                contentType: article.contentType ? article.contentType : null,
                sourceId: article.sourceId,
                interactionId: article.interactionId ? article.interactionId : null,
                topicId: article.topicId,
                sourceId: article.sourceId,
                interactionType: article.interactionType,
            }
        };

        yield call(Api.post, ARTICLE_VISIT_URL(articleId, topicId), dataObj);
        yield put(handleTTArticleVisit(article.id));
    } catch (e) {
        // eslint-disable-next-line
    }
}

function* trendingTopicNewsSaga() {
    yield all([
        takeLatest(GET_TRENDING_TOPIC_NEWS, getTrendingTopicNews),
        takeLatest(GET_TRENING_TOPIC_FILTERS, getTrendingTopicFilters),
        takeEvery(VISIT_TT_ARTICLE, clickTTArticle),
    ]);
}

export default trendingTopicNewsSaga;
