import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET, SMALL_MOBILE } from '../../utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS } from '../../utils/variables';

const mainMessageTextStyle = css`
    font-family:  ${FONT_AVENIR_NEXT};
    color:${COLORS.SAPPHIRE};
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
`;


export const wrapperStyles = css`
    background-color:${COLORS.WHITE}; 
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    z-index:5;
`;

export const containerStyles = css`
    box-sizing: border-box;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: 100%;
    padding: 0 0;
    width: 100%;

    @media screen and ${FROM_TABLET} {
        padding: 0 38px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 0 48px;
    }
`;

export const browserContainerStyles = css`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 0 15px;

    @media screen and ${FROM_TABLET} {
        padding: 80px 0;
    }
`;

export const browserMessageStyles = css`
    box-sizing: border-box;
    margin: 50px auto 10px;
    ${mainMessageTextStyle};
    font-size:26px;
    @media screen and ${FROM_TABLET} {
        width: 75%;
    }
    @media screen and ${FROM_DESKTOP} {
        width: 50%;
    }
`;

export const browserNoteStyles = css`   
    margin: 0;
    font-family: ${FONT_AVENIR_NEXT};
    color:${COLORS.BLACK};
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    padding-bottom:20px; 
    @media screen and ${FROM_TABLET} {
        width: 65%;
    }
    @media screen and ${FROM_DESKTOP} {
        width:41%;
    }
`;


export const mobileContainerStyles = css`
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: space-between;
    padding: 64px 0 80px;
    width: 100%;
`;

export const logoContainerStyles = css`
    display: flex;
    flex-grow: 1;
    justify-content: center;
`;


export const androidMessageContainerStyles = css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 20px 0 20px;
    width: 90%;

    &::after {
        content: '';
        display: flex;
        flex-grow: 1;
    }
`;

export const androidMessageStyles = css`
    margin: 0;
    ${mainMessageTextStyle};
    padding-left: 10%;
    padding-right: 10%;
`;

export const androidNoteStyles = css`
    margin: 16px 0 0;
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    padding-bottom:20px; 
`;



export const tabletMessageContainerStyles = css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    margin: 40px 0 32px;
    width: 100%;
`;

export const tabletMessageStyles = css`
    ${mainMessageTextStyle};
`;

export const instructionsButtonStyles = css`
    margin-top: 10px;
    padding: 0;
    width: 241px;

    /* stylelint-disable */

    ${props => props.isLink && css`
        border: 0;
        color: ${COLORS.DARK_GRAY2};
        font-family: ${FONT_AVENIR_NEXT};
        font-size: 14px;
        font-weight: 400;
        line-height: 1.71;
        margin-top: 21px;
        text-decoration: underline;
        width: auto;
    `}

    /* stylelint-enable */
`;

export const hintTextStyles = css`
    color: ${COLORS.MEDIUM_GRAY1};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 14px;
    line-height: 1.71;
    margin-top: 12px;
    text-align: center;
    span{
     width:24px;
     height:24px;
     border-radius:12px;
     background-color:${COLORS.DARK_SAPPHIRE};
     color:${COLORS.SAPPHIRE}; 
     display: inline-block;
     margin-right: 10px;
     font-weight: bold;  
    }
`;

export const controlsStyles = css`
    align-items: center;
    display: flex;
    flex-direction: column;
`;

export const buttonStyles = css`
    width: 203px;
`;

export const qrCodeStyles = css`
    width: 133px;
    height: 133px;
    padding-top: 75px;
    padding-bottom:120px;
`
