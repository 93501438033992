import { css } from 'styled-components';
import { outerFocusMixin } from 'components/FocusVisibility/styles';
import { FROM_MOBILE_TO_TABLET, FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS, ANIMATIONS, RGB_COLORS } from 'utils/variables';

export const sectionStyles = css`
    background-image: linear-gradient(to bottom, rgba(${RGB_COLORS.LIGHT_GRAY2}, 0.5), rgba(${RGB_COLORS.LIGHT_GRAY1}, 0));
    padding: 24px 0 24px;

    @media screen and ${FROM_TABLET} {
        padding: 70px 0 78px;
    }
`;

export const containerStyles = css`
    max-width: 962px;
`;

export const contentContainerStyles = css`
    max-width: 560px;
`;

export const dateStyles = css`
    color: ${COLORS.MEDIUM_GRAY2};
    font-size: 12px;
    font-weight: 600;

    @media screen and ${FROM_TABLET} {
        line-height: 2;
    }
`;

export const headingStyles = css`
    color: ${COLORS.SAPPHIRE};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 24px;
    font-weight: 400;
    line-height: 1.25;
    margin: 11px 0 16px;
    position: relative;
    word-wrap: break-word;

    &::before {
        background-image: linear-gradient(to left, ${COLORS.OCEAN}, ${COLORS.SAPPHIRE} 40px, ${COLORS.SAPPHIRE} 60px, transparent 80px);
        content: '';
        display: block;
        height: 4px;
        margin-top: 12px;
        position: absolute;
        transform: translateX(-100%) translateX(-10px);
        width: 40px;
    }

    @media screen and ${FROM_MOBILE_TO_TABLET} {
        padding-left: 22px;
    }

    @media screen and ${FROM_TABLET} {
        font-size: 36px;
        line-height: 1.17;
        margin: 9px 0 17px;

        &::before {
            margin-top: 16px;
            transform: translateX(-100%) translateX(-15px);
            width: 120px;
        }
    }
`;

export const descriptionStyles = css`
    color: ${COLORS.MEDIUM_GRAY2};
    font-size: 14px;
    font-weight: 400;
    line-height: 1.43;
    word-wrap: break-word;

    a {
        color: ${COLORS.SAPPHIRE};
        font-weight: 600;
        ${outerFocusMixin}
        text-decoration: none;
        transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;

        &:hover {
            color: ${COLORS.DARK_SAPPHIRE};
        }
    }

    > p {
        margin-top: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and ${FROM_TABLET} {
        font-size: 18px;
        line-height: 1.33;
    }
`;
