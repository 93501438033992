import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CheckIconSmall, IconPlus } from 'components/atomics/atoms/Icons/Icons';
import {
    labelStyles,
    labelTextStyles,
    checkboxStyles,
    indicatorStyles,
    iconPlusStyles,
    indicatorIconStyles,
} from 'components/UnderlinedCheckbox/styles';

const StyledLabel = styled.label`
    ${labelStyles}
`;
const StyledLabelText = styled.span`
    ${labelTextStyles}
`;
export const StyledCheckbox = styled.input`
    ${checkboxStyles}
`;
export const StyledIndicator = styled.span`
    ${indicatorStyles}
`;
const StyledPlusIcon = styled(IconPlus)`
    ${iconPlusStyles(StyledLabel)}
`;
const StyledIndicatorIcons = styled(CheckIconSmall)`
    ${indicatorIconStyles}
`;

class UnderlinedCheckbox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isFocused: false,
        };

        this.handleBlur = this.handleBlur.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
    }

    handleBlur(event) {
        const { field } = this.props;

        this.setState({
            isFocused: false,
        });

        if (field && field.onBlur) {
            field.onBlur(event);
        }
    }

    handleFocus() {
        this.setState({
            isFocused: true,
        });
    }

    render() {
        const {
            label,
            checked,
            onChange,
            id,
            name,
            fieldRef,
            ...other
        } = this.props;
        const { isFocused } = this.state;
        const isChecked = !!checked;

        return (
            <StyledLabel
                aria-selected={checked}
                htmlFor={id}
                variant={{ isChecked }}
                {...other}
            >
                <StyledLabelText>{label}</StyledLabelText>
                <StyledCheckbox
                    id={id}
                    name={name}
                    type="checkbox"
                    checked={checked}
                    onBlur={this.handleBlur}
                    onFocus={this.handleFocus}
                    onChange={onChange}
                    ref={fieldRef}
                />
                <StyledIndicator
                    variant={{
                        isChecked,
                        isFocused,
                    }}
                >
                    {checked ? <StyledIndicatorIcons /> : <StyledPlusIcon />}
                </StyledIndicator>
            </StyledLabel>
        );
    }
}

UnderlinedCheckbox.propTypes = {
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string,
    name: PropTypes.string,
    fieldRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

UnderlinedCheckbox.defaultProps = {
    checked: false,
    id: undefined,
    name: undefined,
    fieldRef: undefined,
};

export default UnderlinedCheckbox;
