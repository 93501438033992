
export const GUIDE_DATA_URL = '/content-service/cards';
export const SAVE_POLL_URL = '/content-service/guides/poll';

export const GET_GUIDE_DATA = 'guide/GET_GUIDE_DATA';
export const GET_GUIDE_DATA_FAIL = 'guide/GET_GUIDE_DATA_FAIL';
export const GET_GUIDE_DATA_SUCCESS = 'guide/GET_GUIDE_DATA_SUCCESS';
export const RESET_GUIDE_DATA = 'guide/RESET_GUIDE_DATA';
export const SAVE_POLL_ANSWER = 'guide/SAVE_POLL_ANSWER';
export const SAVE_POLL_ANSWER_SUCCESS = 'guide/SAVE_POLL_ANSWER_SUCCESS';

export const QUIZ = 'QUIZ';
export const POLL = 'POLL';

export const GUIDE_WAS_VISITED = 'guide/GUIDE_WAS_VISITED';
export const GET_TOOLS_URL = '/content-service/guide-tools/recent';

export const GET_TOOLS = 'tools/GET_TOOLS';
export const GET_TOOLS_SUCCESS = 'tools/GET_TOOLS_SUCCESS';
export const GET_TOOLS_FAIL = 'tool/GET_TOOLS_FAIL';

export const GUIDE_COLLECTIONS_DATA_URL = '/content-service/guide-collections';
export const GET_GUIDE_COLLECTIONS_DATA = 'guideCollections/GET_GUIDE_COLLECTIONS_DATA';
export const GET_GUIDE_COLLECTIONS_DATA_FAIL = 'guideCollections/GET_GUIDE_COLLECTIONS_DATA_FAIL';
export const GET_GUIDE_COLLECTIONS_DATA_SUCCESS = 'guideCollections/GET_GUIDE_COLLECTIONS_DATA_SUCCESS';

// export const GUIDE_RECENT_DATA_URL = '/content-service/guides/recent';

export const GUIDE_RECENT_DATA_URL = '/content-service/guides/v2/recent';



export const GET_GUIDE_RECENT_DATA = 'guide/GET_GUIDE_RECENT_DATA';
export const GET_GUIDE_RECENT_DATA_FAIL = 'guide/GET_GUIDE_RECENT_DATA_FAIL';
export const GET_GUIDE_RECENT_DATA_SUCCESS = 'guide/GET_GUIDE_RECENT_DATA_SUCCESS';

export const GUIDE_TOOLS_DATA_URL = '/content-service/guide-tools/recent';
export const GET_GUIDE_TOOLS_DATA = 'guide/GET_GUIDE_TOOLS_DATA';
export const GET_GUIDE_TOOLS_DATA_FAIL = 'guide/GET_GUIDE_TOOLS_DATA_FAIL';
export const GET_GUIDE_TOOLS_DATA_SUCCESS = 'guide/GET_GUIDE_TOOLS_DATA_SUCCESS';

export const MORE_GUIDES_ROUTE = '/learn/ideas';

export const COLLECTION_DATA_URL = title => `/content-service/guide-collections/details?code=${title}`;
export const GET_COLLECTION_DATA = 'guide/GET_COLLECTION_DATA';
export const GET_COLLECTION_DATA_FAIL = 'guide/GET_COLLECTION_DATA_FAIL';
export const GET_COLLECTION_DATA_SUCCESS = 'guide/GET_COLLECTION_DATA_SUCCESS';

export const CLEAR_GUIDES_AND_COLLECTIONS = 'guide/CLEAR_GUIDES_AND_COLLECTIONS';

export const CE_CREDITS_ROUTE = '/learn/ce-credits';
export const CE_CREDITS_BACK_ROUTE = '/learn';

export const CE_CREDITS_URL = '/content-service/ce-credits';
export const VISIT_CE_CREDIT_URL = (id) => `/content-service/cards?name=CECREDIT&id=${id}`;
export const VISIT_CE_CREDIT = 'guide/VISIT_CE_CREDIT';

export const GET_CE_CREDITS_LIST = 'getCeCreditsitem';
//export const GET_CE_CREDITS_LIST = 'getTopNewsitem';
export const GET_CE_CREDITS_LIST_SUCCESS = 'getCECreditsitemSuccess';
export const GET_CE_CREDITS_LIST_FAIL = 'getCECreditsitemFail';

export const GET_CE_CREDITS = 'guide/GET_CE_CREDITS';
export const GET_CE_CREDITS_FAIL = 'guide/GET_CE_CREDITS_FAILL';
export const GET_CE_CREDITS_SUCCESS = 'guide/GET_CE_CREDITS_SUCCESS';

export const PRACTICE_LAB_ROUTE = '/learn/practice-lab';
export const GET_PRACTICE_LAB_URL = '/content-service/pm/practice-lab?n=4';

export const GET_PRACTICE_LAB = 'getPracticeLab';
export const GET_PRACTICE_LAB_SUCCESS = 'getPracticeLabSuccess';
export const GET_PRACTICE_LAB_FAIL = 'getPracticeLabFailure';

export const TOGGLE_SAVE_PL_ARTICLE = 'save/TOGGLE_SAVE_PL_ARTICLE';
export const TOGGLE_SAVE_PL_ARTICLE_SUCCESS = 'save/TOGGLE_SAVE_PL_ARTICLE_SUCCESS';
export const TOGGLE_SAVE_PL_ARTICLE_FAILURE = 'save/TOGGLE_SAVE_PL_ARTICLE_FAILURE';

export const SHOW_LOADER = 'guide/showLoaderFlag';
export const HIDE_LOADER = 'guide/hideLoaderFlag';