import React from 'react';
import { Provider } from 'react-redux';
import createHistory from 'history/createBrowserHistory';
import ReactDOM from 'react-dom';

import I18nProvider from 'containers/I18nProvider/I18nProvider';
import store from 'state-management/store/storeFactory';
import ConnectedGlobalPopupContainer from 'app/history/GlobalPopupContainer';

if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
}

export default createHistory({
    getUserConfirmation(message, callback) {
        ReactDOM.render(
            (
                <Provider store={store}>
                    <I18nProvider>
                        <ConnectedGlobalPopupContainer type={message} redirectCallback={callback} />
                    </I18nProvider>
                </Provider>
            ), document.getElementById('modal'),
        );
    },
});
