import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';

export const toastsContainerStyles = css`
    bottom: 29px;
    left: 16px;
    position: fixed;
    right: 44px;
    z-index: 10001;

    @media screen and ${FROM_TABLET} {
        left: 40px;
        right: auto;
    }

    @media screen and ${FROM_DESKTOP} {
        bottom: 80px;
        left: 80px;
    }
`;
