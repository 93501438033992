import { css } from 'styled-components';
import { RGB_COLORS } from 'utils/variables';

export const imageContainerStyles = css`
    box-shadow: 0 4px 20px 0 rgba(${RGB_COLORS.BLACK}, 0.16);

    img {
        display: block;
        max-width: 100%;
        width: 100%;
    }
`;
