import {
    CREATE_SOURCE_REQUEST,
    CREATE_SOURCE_REQUEST_SUCCESS,
    CREATE_SOURCE_REQUEST_ERROR,
    SET_SOURCE_NAME,
    RESET_REQUEST_SOURCE,
} from 'state-management/constants/requestSource';

export function createSourceRequest() {
    return {
        type: CREATE_SOURCE_REQUEST,
    };
}

export function setSourceName(sourceName) {
    return {
        type: SET_SOURCE_NAME,
        sourceName,
    };
}

export function createSourceRequestSuccess() {
    return {
        type: CREATE_SOURCE_REQUEST_SUCCESS,
    };
}

export function createSourceRequestError(error) {
    return {
        type: CREATE_SOURCE_REQUEST_ERROR,
        error,
    };
}

export function resetRequestSource(error) {
    return {
        type: RESET_REQUEST_SOURCE,
        error,
    };
}
