import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'components/Link/Link';
import { IconSeeMore } from 'components/atomics/atoms/Icons/Icons';
import { seeMoreIconStyles, seeMoreLinkStyles } from 'components/SeeMoreLink/styles';

const StyledLink = styled(Link)`
    ${seeMoreLinkStyles};
`;

const StyledBasicLink = styled.a`
    ${seeMoreLinkStyles};
`;

const StyledLinkIcon = styled(IconSeeMore)`
    ${seeMoreIconStyles};
`;

const SeeMoreLink = (props) => {
    const {
        className,
        children,
        theme,
        to,
        basic,
    } = props;

    if (basic) {
        return (
            <StyledBasicLink
                className={className}
                href={to}
                variant={{ theme }}
            >
                {children}
                <StyledLinkIcon />
            </StyledBasicLink>
        );
    }

    return (
        <StyledLink
            data-analytics-label="trackLink:anchor"
            data-analytics-placement="text : center"
            data-analytics-id={children}
            className={className}
            to={to}
            variant={{ theme }}
        >
            {children}
            <StyledLinkIcon />
        </StyledLink>
    );
};

SeeMoreLink.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    className: PropTypes.string,
    theme: PropTypes.oneOf([
        'light',
        'dark',
    ]),
    to: PropTypes.string,
    basic: PropTypes.bool,
};

SeeMoreLink.defaultProps = {
    children: null,
    className: '',
    theme: 'light',
    to: '',
    basic: false,
};

export default SeeMoreLink;