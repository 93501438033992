import {
    REAUTHENTICATE, SIGN_IN, SIGN_IN_FAIL, SIGN_IN_SUCCESS,
} from 'state-management/constants/signIn';
import { SIGN_OUT } from 'state-management/constants/signOut';

const initialState = {
    isLoading: true,
    isAuthenticated: false,
    isReauthenticating: true,
};

function signInReducer(state = initialState, action = {}) {
    switch (action.type) {
    case SIGN_IN:
        return {
            ...state,
            isLoading: true,
            isAuthenticated: false,
        };
    case SIGN_IN_SUCCESS:
        return {
            ...state,
            isLoading: false,
            isAuthenticated: true,
            isReauthenticating: false,
        };
    case SIGN_IN_FAIL:
        return {
            ...state,
            isLoading: false,
            isAuthenticated: false,
            isReauthenticating: false,
        };
    case SIGN_OUT:
        return {
            ...state,
            isLoading: false,
            isAuthenticated: false,
            isReauthenticating: false,
        };
    case REAUTHENTICATE:
        return {
            ...state,
            isReauthenticating: true,
        };
    default:
        return state;
    }
}

export default signInReducer;
