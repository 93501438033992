export const CHANGE_PAGE = 'search/CHANGE_PAGE';
export const OPEN = 'search/OPEN';
export const CLOSE = 'search/CLOSE';
export const SEARCH_REDIRECT = 'search/SEARCH_REDIRECT';
export const SEARCH_START = 'search/SEARCH_START';
export const SEARCH_LOADING = 'search/SEARCH_LOADING';
export const SEARCH_SUCCESS = 'search/SEARCH_SUCCESS';
export const SEARCH_ERROR = 'search/SEARCH_ERROR';

export const SEARCH_API_URL = '/content-service/content/search';
export const SEARCH_URL = '/search';

export const SEARCH_PAGE_SIZE = 18;
export const TYPED_SEARCH_PAGE_SIZE = 9;
export const MIN_QUERY_LENGTH = 3;
export const MAX_QUERY_LENGTH = 1000;
export const TYPE_ARTICLE = 'ARTICLE';
