import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware, { END } from 'redux-saga';

import history from 'app/history';
import reducers from 'state-management/reducers';
import sagas from 'state-management/sagas';

export default function configureStore() {
    const sagaMiddleware = createSagaMiddleware();
    const middleware = applyMiddleware(
        routerMiddleware(history),
        sagaMiddleware,
    );

    /* eslint-disable no-underscore-dangle */
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    /* eslint-enable */

    const store = createStore(reducers, composeEnhancers(middleware));

    store.runSaga = sagaMiddleware.run;
    store.close = () => store.dispatch(END);

    sagaMiddleware.run(sagas);

    return store;
}
