import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import { COLORS } from 'utils/variables';

export const containerStyles = css`
    border: solid 1px ${COLORS.DARK_GRAY2};
    color: ${COLORS.DARK_GRAY2};
    font-size: 14px;
    line-height: 1.43;
    margin-top: 19px;
    padding: 15px 18px;
    display: none;

    @media screen and ${FROM_DESKTOP} {
        margin-top: 26px;
    }
`;

export const titleStyles = css`
    font-weight: 700;
    padding-bottom: 20px;
`;

export const messageStyles = css`
    margin: 0;
    max-width: 100%;

    @media screen and ${FROM_TABLET} {
        max-width: 80%;
    }
`;

export const linkStyles = css`
    color: ${COLORS.DARK_GRAY2};
    text-decoration: underline;
`;
