import React, { Component } from 'react';
import { Swipeable } from 'react-swipeable'
import {
    CarouselContent,
    CarouselWrapper,
    ContentItem,
    Point,
    PointsContent,
    PointsWrapper,
    SingleComponentWrapper,
    Wrapper
} from "./styles";

export class Carousel extends Component {
    /**
     * Default constructor.
     */
    constructor(props) {
        super(props);

        this.itemContainerRef = React.createRef();
        this.itemsRef = new Set();
    }

    /**
     * Initial state.
     */
    state = {
        animateTo: 0,
        isAnimated: false,
        itemPosition: 0
    }

    /**
     * Setup window listener and initial animation.
     */
    componentDidMount() {
        this.setState({
            animateTo: this.getItemPosition(this.state.animateTo)
        })

        window.addEventListener("resize", () => this.forceUpdate());
    }

    /**
     * Remove resize listener from window.
     */
    componentWillUnmount() {
        window.removeEventListener("resize", () =>{});
    }

    /**
     * Handle swipe actions.
     */
    swipeTo = (left = false) => {
        const {itemPosition} = this.state;
        const {items} = this.props;
        const numItems = items.length || 1;
        const isLast = (itemPosition + 1) === (numItems - 1);
        const isFirst = (itemPosition - 1) <= 0;
        const moveTo = left
            ? !isFirst && itemPosition + 1 >= 0
                ? itemPosition - 1
                : 0

            : !isLast && itemPosition + 1 < numItems
                ? itemPosition + 1
                : numItems - 1;

        this.setItemPosition(moveTo)
    }

    /**
     * Handle item position ordering after swipes.
     */
    setItemPosition = (index) => {
        this.setState({
            itemPosition: index,
            isAnimated: true,
            animateTo: this.getItemPosition(index),
        });
    }

    /**
     * Get next position where item should move
     * @param itemPosition
     * @returns {any|number}
     */
    getItemPosition = (itemPosition) => {
        if (this.itemContainerRef.current == null) return 0;

        const cContainerWidth = this.itemContainerRef.current.offsetWidth;
        const cItems = Array.from(this.itemsRef);
        const cItemWidth = cItems[itemPosition].scrollWidth;
        const cItemPosition = cItems[itemPosition].offsetLeft;
        const additionalSize = cItems[0].offsetLeft;
        const cItemMin = (cContainerWidth - cItemWidth) / 2;
        const result = (cItemPosition - cItemMin) + additionalSize / 2;
        
        return itemPosition === 0 ? Math.abs(result) : `-${result}`;
    }

    /**
     * Render the carousel and interior items.
     */
    renderCarousel = (items) => {
        const { animateTo, isAnimated } = this.state;

        return (
            <Swipeable trackMouse={true}
                       onSwipedRight={(e) => this.swipeTo(true)}
                       onSwipedLeft={(e) => this.swipeTo()}>
                <CarouselWrapper>
                    <CarouselContent
                        isAnimated={isAnimated}
                        animationPosition={animateTo}
                        ref={this.itemContainerRef}>
                        {items.map(({component}, key) => {
                            return (
                                <ContentItem
                                    ref={(ref) => {
                                        if (ref != null) {
                                            this.itemsRef.add(ref);
                                        }
                                        return true;
                                    }}
                                    key={key}>
                                    {component}
                                </ContentItem>
                            )
                        })}
                    </CarouselContent>
                </CarouselWrapper>
            </Swipeable>
        )
    }

    /**
     * Render the carousel dots.
     */
    renderItemPoints = (items) => {
        const { itemPosition } = this.state;
        const MAX_POINT = 5;

        return(
            <PointsWrapper>
                <PointsContent>
                    {items.slice(0, MAX_POINT ).map((i, k) => {
                        const isActive = itemPosition >= MAX_POINT
                            ? k >= MAX_POINT-1
                            : k === itemPosition;
                        return (<Point onClick={() => this.setItemPosition(k)}
                                       key={k} isActive={isActive}/>);
                    })}
                </PointsContent>
            </PointsWrapper>
        )
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const { items } = this.props;

        switch (items.length) {
            case 1:
                return (
                    <SingleComponentWrapper>
                        {items[0].component}
                    </SingleComponentWrapper>
                )
            default :
                return (
                    <Wrapper>
                        {this.renderCarousel(items)}
                        {this.renderItemPoints(items)}
                    </Wrapper>
                );
        }
    }
}

export default Carousel;
