import {
    GET_CONTENT_FRAGMENTS,
    SET_CONTENT_FRAGMENTS,
} from 'state-management/constants/commonContentFragments';

export function getContentFragments(fragments, showErrorPageOnFail = false) {
    return {
        type: GET_CONTENT_FRAGMENTS,
        fragments: typeof fragments === 'string' ? [fragments] : fragments,
        showErrorPageOnFail,
    };
}

export function setContentFragments(contentFragments) {
    return {
        type: SET_CONTENT_FRAGMENTS,
        contentFragments,
    };
}
