import {
    GET_SOURCES_SUCCESS,
    GET_SOURCES_FAIL,
    GET_SOURCES,
    TOGGLE_FOLLOW_SOURCE,
    TOGGLE_FOLLOW_SOURCE_FAIL,
    TOGGLE_FOLLOW_SOURCE_SUCCESS,
    GET_PROFILE_SOURCES_SUCCESS,
    GET_PROFILE_SOURCES_FAIL,
    GET_PROFILE_SOURCES,
} from 'state-management/constants/sources';

export const getSources = () => ({
    type: GET_SOURCES,
});

export const getSourcesFailure = () => ({
    type: GET_SOURCES_FAIL,
});

export const getSourcesSuccess = sources => ({
    type: GET_SOURCES_SUCCESS,
    data: sources,
});

export const getProfileSources = (displayLoader = false) => ({
    type: GET_PROFILE_SOURCES,
    displayLoader,
});

export const getProfileSourcesFailure = () => ({
    type: GET_PROFILE_SOURCES_FAIL,
});

export const getProfileSourcesSuccess = sources => ({
    type: GET_PROFILE_SOURCES_SUCCESS,
    data: sources,
});

export const toggleSourceFollow = (id, isFollowing, type, showToast = true) => ({
    type: TOGGLE_FOLLOW_SOURCE,
    id,
    isFollowing,
    typeValue: type,
    showToast,
});

export const toggleSourceFollowSuccess = data => ({
    data,
    type: TOGGLE_FOLLOW_SOURCE_SUCCESS,
});

export const toggleSourceFollowFailure = error => ({
    error,
    type: TOGGLE_FOLLOW_SOURCE_FAIL,
});
