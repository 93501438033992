import React, { Component } from 'react';
import styled from 'styled-components';
import {
    wrapperStyles,
} from './styles';

const Wrapper = styled.div`
    ${wrapperStyles}
`;

/**
 * Creates the top most level kebab wrapper for trigger and menu items.
 */
class KebabContentHolder extends Component {
    render() {
        const {showKebab = false, children} = this.props;

        return (
            <Wrapper display={showKebab.toString()}>
                {children}
            </Wrapper>
        );
    }
}

KebabContentHolder.propTypes = {};

export default KebabContentHolder;
