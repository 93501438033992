import {
    GET_COACH_MARKS_SUCCESS,
    HIDE_COACH_MARK,
    DISABLE_COACH_MARKS,
    ENABLE_COACH_MARKS,
    SET_COACH_MARK_AS_SAVED,
    DISMISS_COACH_MARK_SUCCESS,
} from 'state-management/constants/coachMark';

const initialState = {
    display: {
        SAVE: false,
        SAVE_CONFIRMATION: false,
    },
    isEnabled: true,
    isSaved: false,
    status: {
        SAVE: false,
        SAVE_CONFIRMATION: false,
    },
};

function coachMarksReducer(state = initialState, action = {}) {
    switch (action.type) {
    case GET_COACH_MARKS_SUCCESS: {
        const coachMarks = Object.assign({}, initialState.status);
        action.data.forEach((mark) => {
            coachMarks[mark.coachMark] = mark.visible;
        });

        return {
            ...state,
            display: coachMarks,
            status: coachMarks,
        };
    }
    case DISMISS_COACH_MARK_SUCCESS: {
        const { coachMarkType } = action;
        return {
            ...state,
            status: {
                ...state.status,
                [coachMarkType]: false,
            },
        };
    }
    case HIDE_COACH_MARK: {
        const { coachMarkType } = action;
        return {
            ...state,
            display: {
                ...state.display,
                [coachMarkType]: false,
            },
        };
    }
    case DISABLE_COACH_MARKS: {
        return {
            ...state,
            isEnabled: false,
        };
    }
    case ENABLE_COACH_MARKS: {
        return {
            ...state,
            isEnabled: true,
        };
    }
    case SET_COACH_MARK_AS_SAVED: {
        return {
            ...state,
            isSaved: true,
        };
    }
    default:
        return state;
    }
}

export default coachMarksReducer;
