import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { kebabCase } from 'lodash';
import { IconArrowDown } from 'components/atomics/atoms/Icons';
import Icon from 'components/atomics/atoms/Icons/Icon/Icon';
import { Button } from 'components';
import {
    accordionWrapperStyles,
    accordionHeaderStyles,
    titleWrapperStyles,
    titleStyles,
    subtitleStyles,
    footerStyles,
    arrowIconWrapperStyles,
    arrowIconStyles,
} from 'components/Accordion/styles';
import { THEME } from 'components/Accordion/constants';

export const StyledAccordionWrapper = styled.div`
    ${accordionWrapperStyles};
`;

export const StyledAccordionHeader = styled(Button)`
    ${accordionHeaderStyles};
`;

export const StyledTitleWrapper = styled.div`
    ${titleWrapperStyles};
`;

const StyledTitle = styled.strong`
    ${titleStyles};
`;

const StyledSubtitle = styled.span`
    ${subtitleStyles};
`;

export const StyledFooter = styled.div`
    ${footerStyles};
`;

const StyledArrowIconWrapper = styled.span`
    ${arrowIconWrapperStyles};
`;

const ArrowIcon = Icon(IconArrowDown, {
    width: 21,
    height: 11,
    strokeWidth: 3,
});

export const StyledArrowIcon = styled(ArrowIcon)`
    ${arrowIconStyles};
`;

const Accordion = ({
    title,
    subtitle,
    renderContent,
    isOpen,
    onAccordionToggle,
    showFooter,
    theme,
    ...other
}) => (
    <StyledAccordionWrapper {...other} aria-expanded={isOpen}>
        <StyledAccordionHeader
            id={`btn-accordion-${kebabCase(title)}`}
            onClick={onAccordionToggle}
            theme={theme}
            isOpen={isOpen}
        >
            <StyledTitleWrapper>
                <StyledTitle theme={theme}>{title}</StyledTitle>
                {subtitle && (
                    <React.Fragment>
                        {' '}
                        <StyledSubtitle>
                            {subtitle}
                        </StyledSubtitle>
                    </React.Fragment>
                )}
            </StyledTitleWrapper>
            <StyledArrowIconWrapper isOpen={isOpen} theme={theme}>
                <StyledArrowIcon theme={theme} />
            </StyledArrowIconWrapper>
        </StyledAccordionHeader>
        { isOpen && renderContent() }
        { showFooter && <StyledFooter /> }
    </StyledAccordionWrapper>
);

Accordion.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    renderContent: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    onAccordionToggle: PropTypes.func.isRequired,
    showFooter: PropTypes.bool,
    theme: PropTypes.oneOf(Object.values(THEME)),
};

Accordion.defaultProps = {
    subtitle: undefined,
    showFooter: true,
    isOpen: false,
    theme: THEME.DEFAULT,
};

export default Accordion;
