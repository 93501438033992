import {
    put, takeLatest, call, all, select,
} from 'redux-saga/effects';

import { setError } from 'state-management/actions/errors';
import {
    GET_ADVISOR_CLASSIFICATIONS,
    GET_ADVISOR_PROFILE_CLASSIFICATIONS,
    DURATIONS_URL,
    WMAUM_URL,
    WMHOUSE_HOLD_URL,
    RETIREMENT_AUM_URL,
    RETIREMENT_PPLANS_URL,
} from 'state-management/constants/advisorClassifications';
import { setAdvisorClassifications } from 'state-management/actions/advisorClassifications';
import Api from 'utils/api';

/**
 * Get all advisor classification data.
 */
function* getAdvisorClassifications() {
    try {
        const [
            durationsResponse,
            wmAumResponse,
            wmHouseHoldResponse,
            retirementAumResponse,
            retirementPlansResponse,
        ] = yield all([
            call(Api.get, DURATIONS_URL),
            call(Api.get, WMAUM_URL),
            call(Api.get, WMHOUSE_HOLD_URL),
            call(Api.get, RETIREMENT_AUM_URL),
            call(Api.get, RETIREMENT_PPLANS_URL),
        ]);

        yield put(setAdvisorClassifications({
            durations: durationsResponse.data,
            wmAum: wmAumResponse.data,
            wmHouseHold: wmHouseHoldResponse.data,
            retirementAum: retirementAumResponse.data,
            retirementPlans: retirementPlansResponse.data,
        }));
    } catch (e) {
        yield put(setError('classifications.data.error', e));
    }
}

/**
 * Get all advisor classifications for onboarding/profile screens.
 */
function* getAdvisorProfileClassifications() {
    try {
        const isLoaded = yield select(state => state.advisorClassifications.isLoaded);

        if (!isLoaded) {
            const [
                durationsResponse,
                wmAumResponse,
                wmHouseHoldResponse,
                retirementAumResponse,
                retirementPlansResponse,
            ] = yield all([
                call(Api.get, DURATIONS_URL),
                call(Api.get, WMAUM_URL),
                call(Api.get, WMHOUSE_HOLD_URL),
                call(Api.get, RETIREMENT_AUM_URL),
                call(Api.get, RETIREMENT_PPLANS_URL),
            ]);

            yield put(setAdvisorClassifications({
                durations: durationsResponse.data,
                wmAum: wmAumResponse.data,
                wmHouseHold: wmHouseHoldResponse.data,
                retirementAum: retirementAumResponse.data,
                retirementPlans: retirementPlansResponse.data,
            }));
        }
    } catch (e) {
        yield put(setError('classifications.data.error', e));
    }
}

function* advisorClassificationSaga() {
    yield takeLatest(GET_ADVISOR_CLASSIFICATIONS, getAdvisorClassifications);
    yield takeLatest(GET_ADVISOR_PROFILE_CLASSIFICATIONS, getAdvisorProfileClassifications);
}

export default advisorClassificationSaga;
