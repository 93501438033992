import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { StyledLoader, defaultOptions } from 'components/Loader/Loader';
import {
    Container,
    Tabs,
} from 'components';
import storage from 'utils/store';
import anime from 'animejs';
import { GLOBAL } from 'utils/variables';
import NewGuides from './NewGuides/NewGuides';
import FeaturedTools from './FeaturedTools/FeaturedTools';
import CollectionsContainer from './CollectionsContainer/CollectionsContainer';
import { setScrollToTop } from 'state-management/actions/scroll';
import { sendClickOnTools } from 'state-management/actions/tools';
import { getCoachMarkStatus } from 'state-management/actions/coachMark';
import { CLIENT_PERSPECTIVES, PRACTICE_PERSPECTIVES } from 'state-management/constants/categories';
import SplashBanner from '../../components/atomics/atoms/SplashBanner/SplashBanner';
import PromoBanner from '../../components/atomics/organisms/PromoBanner/PromoBanner';
import { getWebinarInfo, notInterested } from 'state-management/actions/webinar';
import SplashNotification from 'components/atomics/molecules/SplashNotification/SplashNotification';
import labels from '../../lang/en.json';
import CECredits from './CECredits/CECredits';
import PracticeLab from './PracticeLab/PracticeLab';
import {
    getGuideRecent,
    getGuideTools,
    getGuideCollections,
    clearGuidesAndCollections,
} from 'state-management/actions/guide';
import * as analytics from 'utils/adobeAnalytics';
import {
    PAGE_NAME_GUIDES_CLIENT_PERSPECTIVES,
    PAGE_NAME_GUIDES_PRACTICE_PERSPECTIVES,
    PAGE_NAME_GUIDES_TOOLS,
} from 'utils/analyticsConstants';
import {
    containerStyles,
    tabsContainerStyles,
    loaderStyles,
    collectionBlankAreaStyles,
    collectionBlankAreaInnerStyles,
} from 'containers/Guides/styles';
import { setError } from 'state-management/actions/errors';
import { StyleBackToTopButton } from 'components/MarketBriefing/page/MarketBriefingPage';
import {
    IconArrowDown,
} from 'components/atomics/atoms/Icons/Icons';

export const COMPONENT_TYPES = {
    COLLECTIONS: 'component/collections',
    GUIDES: 'components/guides',
    CECREDITS: 'components/CECredits',
    TOOLS: 'components/tools',
    PRACTICE_LAB: 'components/practice-lab',
};

const GUIDES_PAGE = "Guides";

const StyledTabWrapper = styled.div``;

const STORAGE_LEARN_TAB = "storage/learn/tab";

const StyledContainer = styled.div`
    ${containerStyles};
`;

const StyledTabsContainer = styled(Container)`
    ${tabsContainerStyles};
`;

const StyledLoaderContainer = styled.div`
    ${loaderStyles}
`;

const StyledCollectionBlankArea = styled.div`
    ${collectionBlankAreaStyles}
`;

const StyleCollectionInnerBlankArea = styled.div`
    ${collectionBlankAreaInnerStyles}
`;

const GUIDES_TOOLS = 'guides-tools';

export const GuidesTabContext = React.createContext({
    subscribe: () => { },
    unsubscribe: () => { },
});

/**
 * The main practice management page.
 */
export class Guides extends React.Component {
    /**
     * Default constructor and state.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.contentRef = React.createRef();

        this.state = {
            tabId: CLIENT_PERSPECTIVES,
            tabName: 'CLIENT_LIFE_EVENTS',
            savedGuideId: null,
            PRACTICE_PERSPECTIVES: {},
            isLoading: false,
            isInitialLoading: true,
            componentsFailed: [],
            tabIndex: 0,
            showBackToTop: false,
        };
        this.handleWindowScroll = this.handleWindowScroll.bind(this);
        this.backToTop = this.backToTop.bind(this);
    }

    /**
     * Scroll to top and gather needed data for page.
     */
    componentDidMount() {
        const { getGuideRecentData, getGuideToolsData, scrollToTop, getCoachMarks, getWebinarInfo } = this.props;

        scrollToTop();

        window.addEventListener('scroll', this.handleWindowScroll);

        getGuideRecentData(4);
        getGuideToolsData(4);
        getCoachMarks();
        getWebinarInfo();

        //reset the previous tab state if from back to action
        if (this.props.queryParams
            && this.props.queryParams.b
            && this.props.queryParams.b === '-1') {
            this.handleTabChange({ id: storage.get(STORAGE_LEARN_TAB) });
            //erase the back button portion as needed
            window.history.replaceState({}, document.title, "/learn");
            //remove old storage point
            storage.remove(STORAGE_LEARN_TAB);
        } else {
            let tab_id = storage.get(STORAGE_LEARN_TAB);
            if (tab_id == null) {
                //default callback to load initial tab, no longer handled by tab component.
                tab_id = 'CLIENT_PERSPECTIVES';
            }
            //instead of default changing the tab to previous one.
            //tab_id = 'CLIENT_PERSPECTIVES';
            this.handleTabChange({ id: tab_id });
        }
    }

    /**
    * Run the collections components close animation if the data failed to load.
    * @param {*} nextProps
    */
    componentWillUpdate(nextProps) {
        if (nextProps.collectionLoadingFailed === true) {
            this.animateClosed();
        }
    }

     /**
     * Render the back to top sticky button.
     */
    renderBackToTop() {
        return (
            <StyleBackToTopButton
                aria-label={labels.translations['marketBriefing.page.scrollToTop']}
                type="button"
                id="backToTopLink"
                onClick={this.backToTop}
                data-analytics-placement="Button : body"
                data-analytics-label="trackLink : button"
                data-analytics-id="backtotop:News Page"
            >
                <IconArrowDown />
            </StyleBackToTopButton>
        );
    }

    /**
     * Track window scrolling for back to top button usage.
     */
    handleWindowScroll() {
        const scrollTop = document.body.scrollTop
            ? document.body.scrollTop : document.documentElement.scrollTop;
        if (scrollTop > 0) {
            this.setState({
                showBackToTop: true,
            });
        } else {
            this.setState({
                showBackToTop: false,
            });
        }
    }

    /**
     * Scroll the user back to the top of page.
     */
    backToTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behaviour: 'smooth'
        });
    }

    /**
     * Update loader state when we have the needed data.
     */
    componentDidUpdate() {
        if (this.props.guideRecent && this.props.guideRecent.guidesList && this.state.isLoading) {
            this.setState({
                isLoading: false,
            });
        }
    }

    /**
     * Remove temporal data on unmount.
     */
    componentWillUnmount() {
        this.props.clearData();
    }

    /**
     * Record component load failures, send to error page if all fail.
     * @param {*} componentId 
     */
    handleComponentFailure(componentId) {
        let failedComponents = this.state.componentsFailed;

        if (!failedComponents.includes(componentId)) {
            failedComponents.push(componentId);

            this.setState({
                componentsFailed: failedComponents,
            }, () => {
                if (this.state.componentsFailed.length >= 5) {
                    this.props.showError();
                }
            });
        }
    }

    /**
     * Get a unique key for iterator usage.
     * @param {*} id 
     * @param {*} idx 
     */
    static getKey(id, idx) {
        return id + idx;
    }

    /**
     * Collapse this component closed (and opacity to 0) from bottom to top.
     */
    animateClosed() {
        const contentNode = this.contentRef.current;

        this.animation = anime.timeline();

        this.animation
            .add({
                targets: contentNode,
                easing: [0.53, 0.05, 0.01, 0.97],
                opacity: [1, 0],
                duration: GLOBAL.ANIMATIONS.COLLAPSE_TIME.MILISECONDS,
                height: 0,
            });

        this.handleComponentFailure(COMPONENT_TYPES.COLLECTIONS);
    }

    /**
     * Get a guide code param when passed.
     */
    getGuideCode = () => decodeURIComponent(this.props.match.params.id);

    /**
     * Get the unique page naming based on tab selection.
     * @param {*} tabId 
     */
    getPageName(tabId) {
        switch (tabId) {
            case CLIENT_PERSPECTIVES:
                return PAGE_NAME_GUIDES_CLIENT_PERSPECTIVES;
            case PRACTICE_PERSPECTIVES:
                return PAGE_NAME_GUIDES_PRACTICE_PERSPECTIVES;
            case GUIDES_TOOLS:
                return PAGE_NAME_GUIDES_TOOLS;
            default:
                return '';
        }
    }

    /**
     * Handle guide save/unsave.
     */
    handleToggleSaveGuide = () => {
        const { saved, id, title } = this.props.guide;

        this.props.toggleSaveGuide(
            id,
            title,
            !saved,
            analytics.getAnalyticsGuideData(
                this.props.guide,
                PAGE_NAME_GUIDE,
            ),
        );
    };

    /**
     * Handle PM tab changes.
     */
    handleTabChange = (tab) => {
        storage.set(STORAGE_LEARN_TAB, tab.id);

        let tabName = this.getActiveTabName(tab);

        if (tabName == "CLIENT_PERSPECTIVES") {
            this.setState({ tabName: "CLIENT_LIFE_EVENTS", tabIndex: 0 });
            this.props.getClientPerspecticveData("CLIENT_LIFE_EVENTS");
        } else if (tabName == "PRACTICE_PERSPECTIVES") {
            this.setState({ tabName: "PRACTICE_MILESTONES", tabIndex: 1 });
            this.props.getPracticePerspectiveData("PRACTICE_MILESTONES");
        } else {
            this.setState({ tabName: "CLIENT_LIFE_EVENTS", tabIndex: 0 });
            this.props.getClientPerspecticveData("CLIENT_LIFE_EVENTS");
        }
    };

    /**
     * Get unique index for tab by name.
     */
    getTabIndexByTabName = (tabName) => {
        return tabName === "CLIENT_PERSPECTIVES" ? 0 : 1;
    }

    /**
     * Get unique tab name by index value.
     */
    getTabNameByIndex = (index) => {
        return index == 0 ? "CLIENT_PERSPECTIVES" : "PRACTICE_PERSPECTIVES";
    }

    /**
     * Get the currently active tab.
     */
    getActiveTabName = (tab) => {
        const currentTabIndex = this.getTabIndexByTabName(tab.id);
        const tabIndex = currentTabIndex;
        const tabName = this.getTabNameByIndex(tabIndex);

        return tabName;
    }

    /**
     * Render collection cards area.
     */
    renderCollections = (tabName) => {
        const lstData = this.props.guideCollections;

        if (lstData.lstCollection) {
            return (
                this.renderCollection(lstData)
            );
        } else {
            return (
                <StyledCollectionBlankArea>
                    <StyleCollectionInnerBlankArea>
                        <StyledLoaderContainer>
                            <StyledLoader
                                options={defaultOptions}
                            />
                        </StyledLoaderContainer>
                    </StyleCollectionInnerBlankArea>
                </StyledCollectionBlankArea>
            );
        }
    };

    /**
     * Render a single collection segment.
     */
    renderCollection = (collectionsData) => {
        return (
            <div>
                {(collectionsData &&
                    <CollectionsContainer
                        greetingHeader={collectionsData.greetingHeader}
                        collectionsList={collectionsData.lstCollection}
                    />
                )}
            </div>
        );
    };

    /**
     * Render the PM landing page and underlying components.
     */
    render() {
        const splashTitle = labels.translations['guides.whatsUp'];

        return (
            <GuidesTabContext.Provider
                value={this.state.tabName}
            >
                <React.Fragment>
                    {this.props.promoBannerInfo
                    && <PromoBanner
                        promoBannerInfo={this.props.promoBannerInfo}
                        handleDismiss={this.props.dismissWebinar}
                    />}
                    {this.props.name && this.props.guideCollections.greetingHeader
                    && <SplashBanner
                        name={this.props.name}
                        pageType={GUIDES_PAGE}
                        message={this.props.guideCollections.greetingHeader}
                    />}
                    <StyledContainer>
                        <StyledTabWrapper
                            ref={this.contentRef}
                        >
                            <Tabs
                                activeIndex={this.state.tabIndex}
                                appearance="default"
                                tabListWrapper={StyledTabsContainer}
                                theme="dark-blue"
                                handleTabChange={this.handleTabChange}
                                panes={[
                                    {
                                        id: CLIENT_PERSPECTIVES,
                                        tabTitle: <FormattedMessage id="guidePage.client-perspective" />,
                                        content: () => this.renderCollections(CLIENT_PERSPECTIVES),
                                    },
                                    {
                                        id: PRACTICE_PERSPECTIVES,
                                        tabTitle: <FormattedMessage id="guidePage.practice-perspective" />,
                                        content: () => this.renderCollections(PRACTICE_PERSPECTIVES),
                                    }
                                ]}
                            />
                        </StyledTabWrapper>

                        <PracticeLab
                            handleComponentFailure={() => {
                                this.handleComponentFailure(COMPONENT_TYPES.PRACTICE_LAB);
                            }}
                        />

                        <NewGuides
                            guideListData={this.props.guideRecent.guidesList}
                            webinarData={this.props.guideRecent.webinarList}
                            guidesHeader={this.props.guideRecent.header}
                            parentPage={GUIDES_PAGE}
                            loadingFailed={this.props.guideLoadingFailed}
                            handleComponentFailure={() => {
                                this.handleComponentFailure(COMPONENT_TYPES.GUIDES);
                            }}
                        />

                        <CECredits
                            handleComponentFailure={() => {
                                this.handleComponentFailure(COMPONENT_TYPES.CECREDITS);
                            }}
                        />

                        <FeaturedTools
                            toolsList={this.props.guideTools.toolList}
                            toolsHeader={this.props.guideTools.header}
                            toolsSubHeader={this.props.guideTools.subHeader}
                            parentPage={GUIDES_PAGE}
                            sendClickOnTools={this.props.sendClickOnTools}
                            loadingFailed={this.props.toolLoadingFailed}
                            handleComponentFailure={() => {
                                this.handleComponentFailure(COMPONENT_TYPES.TOOLS);
                            }}
                        />

                        <SplashNotification
                            title={splashTitle}
                            buttonLabel="View News"
                            url="news"

                        />
                    </StyledContainer>
                    {this.state.showBackToTop
                    && this.renderBackToTop()}
                </React.Fragment>
            </GuidesTabContext.Provider>
        );
    }
}

Guides.defaultProps = {
    guideRecent: null,
    guideTools: {},
    guideCollections: {},
    clientPerspectiveCollection: null,
    practicePerspectiveCollection: null,
    email: null,
    name: null,
    guideLoadingFailed: false,
    collectionLoadingFailed: false,
    match: {
        params: {
            webinarID: null,
            eventID: null,
            mode: null,
        },
    },
    queryParams: PropTypes.shape({
        orderBy: PropTypes.string,
    }).isRequired,
    promoBannerInfo: null,
    getGuideRecentData: () => {},
    getGuideToolsData: () => {},
    getWebinarInfo: () => {},
    getCoachMarks: () => {},
    getClientPerspecticveData: () => {},
};

Guides.propTypes = {
    guideRecent: PropTypes.shape({
    }),
    guideTools: PropTypes.shape({
    }),
    guideCollections: PropTypes.shape({
    }),
    clientPerspectiveCollection: PropTypes.shape({
    }),
    practicePerspectiveCollection: PropTypes.shape({
    }),
    isLoading: PropTypes.bool.isRequired,
    email: PropTypes.string,
    name: PropTypes.string,
    guideLoadingFailed: PropTypes.bool,
    collectionLoadingFailed: PropTypes.bool,
    match: PropTypes.shape({
        params: PropTypes.shape({
            webinarID: PropTypes.string,
            eventID: PropTypes.number,
            mode: PropTypes.string,
        }),
    }),
    promoBannerInfo: PropTypes.shape({
    }),
    dismissWebinar: PropTypes.func,
};

const mapStateToProps = (state) => ({
    guideRecent: state.guide.guideRecent,
    guideTools: state.guide.guideTools,
    guideCollections: state.guide.data,
    clientPerspectiveCollection: state.guide.clientCollectionData,
    practicePerspectiveCollection: state.guide.perspectiveCollectionData,
    email: state.basicProfile.email,
    name: state.basicProfile.firstName,
    isLoading: state.guide.isLoading,
    guideLoadingFailed: state.guide.guideLoadingFailed,
    collectionLoadingFailed: state.guide.collectionLoadingFailed,
    toolLoadingFailed: state.guide.toolLoadingFailed,
    queryParams: state.location.query,
    promoBannerInfo: state.webinar.webinar,
});

const mapDispatchToProps = dispatch => ({
    getClientPerspecticveData: group => dispatch(getGuideCollections('CLIENT_LIFE_EVENTS')),
    getPracticePerspectiveData: group => dispatch(getGuideCollections('PRACTICE_MILESTONES')),
    getGuideToolsData: no => dispatch(getGuideTools(no)),
    getGuideRecentData: no => dispatch(getGuideRecent(no)),
    scrollToTop: () => dispatch(setScrollToTop()),
    getCoachMarks: () => dispatch(getCoachMarkStatus()),
    sendClickOnTools: (id) => dispatch(sendClickOnTools(id)),
    clearData: () => dispatch(clearGuidesAndCollections()),
    getWebinarInfo: (id) => dispatch(getWebinarInfo()),
    dismissWebinar: (eventId, descision) => dispatch(notInterested(eventId, descision)),
    showError: () => dispatch(setError('global.errors.componentLoader', { status: 500 })),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Guides));
