import { all, call, takeEvery } from 'redux-saga/effects';
import * as analytics from 'utils/adobeAnalytics';
import { TOGGLE_SAVE_ARTICLE, TOGGLE_SAVE_GUIDE } from 'state-management/constants/save';
import { SIGN_IN, SIGN_IN_FAIL, SIGN_IN_SUCCESS } from 'state-management/constants/signIn';
import { VISIT_ARTICLE } from 'state-management/constants/articles.common';
import {
    EVENT_ACTION_SIGN_IN_FAILURE,
    EVENT_ACTION_SIGN_IN_STARTS,
    EVENT_ACTION_SIGN_IN_SUCCESS,
    PAGE_NAME_SIGN_IN,
    SITE_SECTION_SIGN_IN,
} from 'utils/analyticsConstants';

/**
 * Analytics: save guide.
 * @param {*} action
 */
function* toggleSaveGuide(action) {
    const {
        id,
        title,
        save,
        analyticsData,
    } = action;

    if (!analyticsData) {
        return;
    }
    if (save) {
        yield call(
            analytics.dispatchGuideAddToSaves,
            analyticsData.analyticsPageName,
            id,
            title,
            analyticsData.topicNames,
            analyticsData.sourceNames,
        );
    } else {
        yield call(
            analytics.dispatchGuideRemoveFromSaves,
            analyticsData.analyticsPageName,
            id,
            title,
            analyticsData.topicNames,
            analyticsData.sourceNames,
        );
    }
}

/**
 * Analytics: article view.
 * @param {*} param0
 */
function* visitArticle({ article }) {
    try {
        if (!article.licensed) {
            yield call(analytics.dispatchReadExternalContent, article);
        }
    } catch (e) {
        // catch
    }
}

/**
 * Analytics: save article.
 * @param {*} action
 */
function* toggleSaveArticle(action) {
    const {
        id,
        title,
        save,
        analyticsData,
    } = action;

    if (!analyticsData) {
        return;
    }
    if (save) {
        yield call(
            analytics.dispatchArticleAddToSaves,
            analyticsData.analyticsPageName,
            id,
            title,
            analyticsData.topicNames,
            analyticsData.sourceNames,
        );
    } else {
        yield call(
            analytics.dispatchArticleRemoveFromSaves,
            analyticsData.analyticsPageName,
            id,
            title,
            analyticsData.topicNames,
            analyticsData.sourceNames,
        );
    }
}

/**
 * Analytics: sign in (start).
 */
function* signInStarts() {
    yield call(
        analytics.dispatchPageActionTrigger,
        PAGE_NAME_SIGN_IN,
        SITE_SECTION_SIGN_IN,
        EVENT_ACTION_SIGN_IN_STARTS,
    );
}

/**
 * Analytics:  sign in (success).
 * @param {*} action
 */
function* signInSuccess(action) {
    if (!action.refresh) {
        yield call(
            analytics.dispatchPageActionTrigger,
            PAGE_NAME_SIGN_IN,
            SITE_SECTION_SIGN_IN,
            EVENT_ACTION_SIGN_IN_SUCCESS,
        );
    }
}

/**
 * Analytics: sign in (failure).
 * @param {*} action
 */
function* signInFailure(action) {
    if (!action.refresh) {
        yield call(
            analytics.dispatchPageActionTrigger,
            PAGE_NAME_SIGN_IN,
            SITE_SECTION_SIGN_IN,
            EVENT_ACTION_SIGN_IN_FAILURE,
        );
    }
}

function* adobeAnalyticsSaga() {
    yield all([
        takeEvery(TOGGLE_SAVE_GUIDE, toggleSaveGuide),
        takeEvery(TOGGLE_SAVE_ARTICLE, toggleSaveArticle),
        takeEvery(VISIT_ARTICLE, visitArticle),

        takeEvery(SIGN_IN, signInStarts),
        takeEvery(SIGN_IN_SUCCESS, signInSuccess),
        takeEvery(SIGN_IN_FAIL, signInFailure),

    ]);
}

export default adobeAnalyticsSaga;
