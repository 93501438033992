import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    BreakpointsContext,
} from 'components/Breakpoints/Breakpoints';
import CeListContentCard from '../../../../components/atomics/molecules/CeListContentCard/CeListContentCard';
import {
    containerStyles,
    leftContainerStyles,
    rightContainerStyles,
    sliderContainerStyles,
    desktopContainerStyles,
    paddingContainerStyles,
    sliderCellStyles,
} from 'components/atomics/organisms/HeroContentCardCollectionRight/styles';

export const PaddingContainer = styled.div`
    ${paddingContainerStyles}
`;

export const DesktopContainer = styled.section`
    ${desktopContainerStyles}
`;

export const Container = styled.section`
    ${containerStyles}
`;

export const SliderContainer = styled.section`
    ${sliderContainerStyles}
`;

export const RightContainer = styled.section`
    ${rightContainerStyles}
`;

export const LeftContainer = styled.section`
    ${leftContainerStyles}
`;

export const SliderCell = styled.div`
    ${sliderCellStyles}
`;

const flickityOptions = {
    prevNextButtons: false,
};

/**
 * Creates a hero content card collection used for CE credits.
 */
class HeroContentCardCollectionRight extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

    }

    /**
     * Render the hero card collection and underlying components.
     */
    render() {
        return (
            <BreakpointsContext.Consumer>
                {(breakpoint) => {
                    return (
                        <DesktopContainer data-rel="HeroContentCardCollectionRight">
                            <PaddingContainer>
                                <Container>
                                    
                                    <RightContainer>
                                        <CeListContentCard
                                            showCoachMark={this.props.showCoachMark}
                                            article={this.props.articles[0]}
                                        />
                                        <CeListContentCard
                                            showCoachMark={this.props.showCoachMark}
                                            article={this.props.articles[1]}
                                        />
                                        <CeListContentCard
                                            showCoachMark={this.props.showCoachMark}
                                            article={this.props.articles[2]}
                                        />
                                    </RightContainer>
                                </Container>
                            </PaddingContainer>
                        </DesktopContainer>
                    );
                }}
            </BreakpointsContext.Consumer>
        );
    }
}

HeroContentCardCollectionRight.propTypes = {
    articles: PropTypes.arrayOf(PropTypes.shape({
        contentId: PropTypes.string,
        topicName: PropTypes.string.isRequired,
        topicId: PropTypes.number,
        title: PropTypes.string.isRequired,
        image: PropTypes.string,
        sourceName: PropTypes.string.isRequired,
        sourceId: PropTypes.number,
        provider: PropTypes.string.isRequired,
        id: PropTypes.any.isRequired,
        url: PropTypes.string,
        licensed: PropTypes.bool,
        visited: PropTypes.bool
    })).isRequired,
    showCoachMark: PropTypes.bool,
    dismissCoachMark: PropTypes.func,
};

HeroContentCardCollectionRight.defaultProps = {
    showCoachMark: false,
    dismissCoachMark: () => { }
}

export default HeroContentCardCollectionRight;
