import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import anime from 'animejs';
import { Heading } from 'components';
import {
    contentWrapperStyles,
    featuredTextStyles,
    sidebarStyles,
    titleStyles,
} from 'containers/Onboarding/OnboardingSidebar/styles';

const StyledSidebar = styled.div`
    ${sidebarStyles};
`;

const StyledSectionTitle = styled(Heading)`
    ${titleStyles};
`;

const StyledSectionFeaturedText = styled.p`
    ${featuredTextStyles};
`;

const StyledContentWrapper = styled.div`
    ${contentWrapperStyles};
`;

/**
 * Creates the onboarding sidebar for onboarding pages.
 */
class OnboardingSidebar extends React.PureComponent {
    /**
     * Default constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            isAnimated: false,
            isAnimating: false,
        };

        this.titleRef = React.createRef();
        this.featuredTextRef = React.createRef();
        this.contentWrapperRef = React.createRef();
    }

    /**
     * Animate on mound.
     */
    componentDidMount() {
        this.animate();
    }

    /**
     * Animate on step change.
     */
    componentDidUpdate() {
        this.animate();
    }

    /**
     * Cancel animations on destroy.
     */
    componentWillUnmount() {
        if (this.state.isAnimating) {
            this.animation.pause();
        }
    }

    /**
     * Handle animation.
     */
    animate() {
        if (!this.props.isAnimatable || !this.props.isLoaded || this.state.isAnimated) {
            return;
        }

        const titleNode = this.titleRef.current;
        const featuredTextNode = this.featuredTextRef.current;
        const contentWrapperNode = this.contentWrapperRef.current;

        this.setState({
            isAnimated: true,
        });

        if (titleNode && featuredTextNode && contentWrapperNode) {
            this.animation = anime.timeline();

            this.animation
                .add({
                    targets: [titleNode, contentWrapperNode],
                    easing: [0.99, 0.03, 0.47, 0.95],
                    duration: 330,
                    opacity: [0, 1],
                    offset: 330,
                    complete: () => {
                        titleNode.removeAttribute('style');
                        contentWrapperNode.removeAttribute('style');
                    },
                })
                .add({
                    targets: featuredTextNode,
                    easing: [0.53, 0.05, 0.01, 0.97],
                    translateY: [40, 0],
                    opacity: [0, 1],
                    offset: 330,
                    duration: 660,
                    complete: () => {
                        featuredTextNode.removeAttribute('style');
                        this.setState({
                            isAnimating: false,
                        });
                    },
                });
        }
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const {
            children,
            featuredText,
            isAnimatable,
            sectionTitle,
        } = this.props;
        const { isAnimated } = this.state;

        return (
            <StyledSidebar
                isSticky
            >
                <StyledSectionTitle
                    passedRef={this.titleRef}
                    level={1}
                    variant={{ isAnimatable, isAnimated }}
                >
                    {sectionTitle}
                </StyledSectionTitle>
                <StyledSectionFeaturedText
                    ref={this.featuredTextRef}
                    variant={{ isAnimatable, isAnimated }}
                >
                    {featuredText}
                </StyledSectionFeaturedText>
                <StyledContentWrapper
                    ref={this.contentWrapperRef}
                    variant={{ isAnimatable, isAnimated }}
                >
                    {children}
                </StyledContentWrapper>
            </StyledSidebar>
        );
    }
}

OnboardingSidebar.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    featuredText: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
    ]).isRequired,
    isAnimatable: PropTypes.bool,
    isLoaded: PropTypes.bool,
    sectionTitle: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
    ]).isRequired,
};

OnboardingSidebar.defaultProps = {
    children: null,
    isAnimatable: false,
    isLoaded: false,
};

export default OnboardingSidebar;
