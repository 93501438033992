import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import ButtonAtom from '../../components/atomics/atoms/Button/Button';
import {
    TYPE_SECONDARY_MEDIUM,
} from 'components/atomics/atoms/Button/Button';
import { Button, SanitizedHTML } from 'components';
import { Errors } from 'containers/Errors/Errors';
import { COLORS } from '../../utils/variables';
import {
    popupHolderStyles,
    popupContentWrapperStyles,
    popupContentContainerStyles,
    popupTitleStyles,
    popupContentStyles,
    popupControlsStyles,
    popupHeaderControlsStyles,
    declineButtonStyles,
    scrollDownMessageStyles,
    ContainerStyles,
} from 'containers/LegalAgreement/styles';

export const StyledPopupContentWrapper = styled.div`
    ${popupContentWrapperStyles};
`;

export const StyledPopupContentContainer = styled.div`
    ${popupContentContainerStyles};
`;

const StyledContainer = styled.div`
    ${ContainerStyles};
`;

const StyledPopupHolder = styled.div`
    ${popupHolderStyles};
`;

export const AcceptButton = styled.button`
    border: none;
    display: block;
    margin-bottom: 16px;
    width: 90%;
    font-family: AvenirNext, sans-serif;
    text-align: center;
    cursor: ${props => !props.active ? 'pointer' : 'not-allowed'};
    background-color: ${props => !props.active ? `${COLORS.OCEAN}` : `${COLORS.LIGHT_GRAY3}`};
    color: ${COLORS.WHITE};
    font-size: 19px;
    font-weight: 700;
    letter-spacing: 0;
    text-transform: capitalize;
    text-decoration: none;
    transition: all 0.33s ease 0s;
    padding: 19px 18px;
    
    &:hover{
        background-color:    ${props => !props.active ? `${COLORS.SAPPHIRE}` : `${COLORS.LIGHT_GRAY3}`}; 
    }
`;

const StyledPopupTitle = styled.div`
    ${popupTitleStyles};
`;

export const StyledPopupContent = styled(SanitizedHTML)`
    ${popupContentStyles};
`;

const StyledPopupControls = styled.div`
    ${popupControlsStyles};
`;

export const StyledPopupHeaderControls = styled.div`
    ${popupHeaderControlsStyles};
`;

const StyledScrollDownMessage = styled.p`
    ${scrollDownMessageStyles};
`;

export const StyledDeclineButton = styled(ButtonAtom)`
    ${declineButtonStyles};
`;

const CONTENT_EMPTY_SPACE = 45;

/**
 * Legal agreement area of the home page.
 */
class LegalAgreement extends React.Component {
    /**
    * Default constructor.
    * @param {*} props 
    */
    constructor(props) {
        super(props);

        this.state = {
            scrolledToBottom: false,
            hideScrollShadow: false,
        };
    }

    /**
     * Remove window listeners at destroy.
     */
    componentWillUnmount() {
        if (this.contentRef) {
            this.contentRef.removeEventListener('scroll', this.handleScroll);
        }
    }

    /**
     * Handle user acceptance of legal agreement.
     */
    acceptAgreement = () => {
        if (this.state.scrolledToBottom && !this.hasErrors()) {
            this.props.updateAgreement(true);
            this.props.closeModal();
        }
    };

    /**
     * Handle declining the legal agreement.
     */
    declineAgreement = (event) => {
        this.props.updateAgreement(false);
        this.props.closeModal();
        event && event.preventDefault();

        // var tracking =
        //     {
        //         "action": "content",
        //         "component": "decline",
        //         "location": "legalAgreement",
        //         "followed": "",
        //         "title": "",
        //         "topic": "",
        //         "source": ""
        //     };
        // contextHubRecordEvent(tracking);
    };

    analyticsHandleLegalAgreementModalClose() {
        // var tracking =
        //     {
        //         "action": "content",
        //         "component": "close",
        //         "location": "legalAgreement",
        //         "followed": "",
        //         "title": "",
        //         "topic": "",
        //         "source": ""
        //     };
        // contextHubRecordEvent(tracking);
    };

    /**
     * Handle scrolling at mount/display of modal.
     */
    paneDidMount = (node) => {
        if (node) {
            if (node.scrollHeight - CONTENT_EMPTY_SPACE <= node.clientHeight) {
                this.makeScrolledToBottom();
            } else {
                this.contentRef = node;

                node.focus();
                node.addEventListener('scroll', this.handleScroll);
            }
        }
    };

    /**
     * Handle scroll actions while modal is open.
     */
    handleScroll = (event) => {
        const node = event.target;
        const bottom = (node.scrollHeight - node.scrollTop) - CONTENT_EMPTY_SPACE
            <= node.clientHeight;
        if (bottom) {
            this.makeScrolledToBottom();
        }

        if (!bottom && this.state.scrolledToBottom) {
            this.setState({ hideScrollShadow: false });
        }
    };

    /**
     * Return if errors are present.
     */
    hasErrors = () => !!this.props.globalErrors.length;

    /**
     * Return if legal content is ready.
     */
    hasContent = () => this.props.legalAgreement;

    /**
     * Update scroll recorded state.
     */
    makeScrolledToBottom = () => {
        this.setState({ scrolledToBottom: true, hideScrollShadow: true });
    };

    /**
     * Render this and underlying components.
     */
    render() {
        const { globalErrors, legalAgreement } = this.props;
        const { hideScrollShadow } = this.state;
        const { formatMessage } = this.props.intl;
        const htmlContent = legalAgreement;
        const acceptDisabled = !this.state.scrolledToBottom || this.hasErrors();

        return (
            <React.Fragment>
                <StyledPopupHolder>
                    <StyledPopupHeaderControls>
                        <Button
                            appearance={Button.APPEARANCE.ICON}
                            id="close"
                            onClick={() => { this.declineAgreement(); }}
                            data-analytics-label="trackLink : button"
                            data-analytics-placement="Button : body"
                            data-analytics-id="agreement close"
                        />
                    </StyledPopupHeaderControls>
                    <StyledPopupContentContainer>
                        <StyledPopupTitle>
                            <FormattedMessage id="legalAgreement.terms.heading" />
                        </StyledPopupTitle>
                        <Errors errors={globalErrors} />
                        {!this.hasErrors() && this.hasContent() && (
                            <StyledPopupContentWrapper hideScrollShadow={hideScrollShadow}>
                                <StyledPopupContent
                                    id="LegalAgreement"
                                    contentRef={this.paneDidMount}
                                    tabIndex="0"
                                    aria-label={formatMessage({ id: 'legalAgreement.accessibility.region' })}
                                    role="region"
                                    htmlContent={htmlContent}
                                    tag="div"
                                />
                            </StyledPopupContentWrapper>
                        )}
                        <StyledScrollDownMessage>
                            <FormattedMessage id="legalAgreement.scrollDown" />
                        </StyledScrollDownMessage>

                        <StyledContainer>
                            <FormattedMessage id="legalAgreement.scrollDown" />
                        </StyledContainer>

                        <StyledPopupControls>
                            {acceptDisabled &&
                                <AcceptButton
                                    id="accept_disabled"
                                    active={acceptDisabled}
                                    aria-disabled={acceptDisabled}
                                >
                                    <FormattedMessage id="legalAgreement.terms.accept" />
                                </AcceptButton>
                            }
                            {!acceptDisabled &&
                                <AcceptButton
                                    id="accept"
                                    active={acceptDisabled}
                                    onClick={this.acceptAgreement}
                                    aria-disabled={acceptDisabled}
                                    data-analytics-label="trackLink : button"
                                    data-analytics-placement="Button : body"
                                    data-analytics-id="agreement accept"
                                >
                                    <FormattedMessage id="legalAgreement.terms.accept" />
                                </AcceptButton>
                            }
                        </StyledPopupControls>
                        <StyledPopupControls>
                            <StyledDeclineButton
                                type={TYPE_SECONDARY_MEDIUM}
                                appearance={Button.APPEARANCE.TRANSPARENT}
                                id="decline"
                                size={Button.SIZES.SMALL}
                                onClick={this.declineAgreement}
                                dataAnalyticsLabel="trackLink : button"
                                dataAnalyticsPlacement="Button : body"
                                dataAnalyticsId="agreement decline"
                            >
                                <FormattedMessage id="legalAgreement.terms.decline" />
                            </StyledDeclineButton>
                        </StyledPopupControls>
                    </StyledPopupContentContainer>
                </StyledPopupHolder>
            </React.Fragment>
        );
    }
}

LegalAgreement.propTypes = {
    closeModal: PropTypes.func.isRequired,
    globalErrors: PropTypes.arrayOf(PropTypes.shape).isRequired,
    intl: intlShape.isRequired,
    legalAgreement: PropTypes.string,
    updateAgreement: PropTypes.func.isRequired,
};

LegalAgreement.defaultProps = {
    legalAgreement: undefined,
};

export default injectIntl(LegalAgreement);
