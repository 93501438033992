import { css } from 'styled-components';
import { FROM_TABLET } from 'utils/mediaQueries';
import {
    COLORS,
    ANIMATIONS,
} from 'utils/variables';

export const backToLinkStyles = css`
    border: none;
    padding: 0 !important;
    align-items: center;
    display: inline-flex;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 1;
    text-decoration: none;
    text-transform: uppercase;
    color: ${COLORS.DARK_GRAY2};
    margin-top: 30px;
    transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;

    @media screen and ${FROM_TABLET} {
        margin-top: 40px;
    }

    svg {
        stroke: ${COLORS.DARK_GRAY2};
        transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;

        ${props => props.ismacos && css`
            position: relative;
            top: -2px;
        `}
    }

    &:hover {
        color: ${COLORS.MEDIUM_GRAY2} !important;

        svg {
            stroke: ${COLORS.MEDIUM_GRAY2} !important;           

            &:hover{
                stroke: ${COLORS.MEDIUM_GRAY2};                
            }
        }
    }

    ${props => props.theme === 'dark' && css`
        color: ${COLORS.WHITE} !important;

        svg {
            stroke: ${COLORS.WHITE} !important;
        }

        &:hover {
            opacity:0.6;

            svg {
                opacity:0.6;
            }
        }
    `};

    ${props => props.theme === 'medium' && css`
        color: ${COLORS.WHITE} !important;

        svg {
            stroke: ${COLORS.WHITE} !important;
        }

        &:hover {
            color: ${COLORS.HOVER_SAPPHIRE} !important;

            svg {
                stroke: ${COLORS.HOVER_SAPPHIRE} !important;           
    
                &:hover{
                    stroke: ${COLORS.HOVER_SAPPHIRE};
                    
                }
            }
        }
    `};

    ${props => props.isdisable === 'true' && css`
        display:none;
    `};
`;

export const backToIconStyles = css`
    padding-left: 6px;
    height: 11px;
    stroke-width: 3px;
    transform: rotate(180deg);
`;
