export const SEND_FORGOT_PASSWORD_EMAIL_URL = '/advisor-service/advisors/password/forgot';
export const CHANGE_FORGOT_PASSWORD_URL = '/advisor-service/advisors/password';
export const VERIFY_PASSWORD_TOKEN_URL = '/advisor-service/advisors/v2/password/token/verify';

export const SEND_FORGOT_PASSWORD_EMAIL = 'forgotPassword/SEND_FORGOT_PASSWORD_EMAIL';
export const SEND_FORGOT_PASSWORD_EMAIL_SUCCESS = 'forgotPassword/SEND_FORGOT_PASSWORD_EMAIL_SUCCESS';
export const SEND_FORGOT_PASSWORD_EMAIL_ERROR = 'forgotPassword/SEND_FORGOT_PASSWORD_EMAIL_ERROR';
export const RESET_FORGOT_PASSWORD_EMAIL = 'forgotPassword/RESET_FORGOT_PASSWORD_EMAIL';

export const VERIFY_PASSWORD_TOKEN = 'forgotPassword/VERIFY_PASSWORD_TOKEN';
export const VERIFY_PASSWORD_TOKEN_SUCCESS = 'forgotPassword/VERIFY_PASSWORD_TOKEN_SUCCESS';
export const VERIFY_PASSWORD_TOKEN_ERROR = 'forgotPassword/VERIFY_PASSWORD_TOKEN_ERROR';

export const CHANGE_PASSWORD = 'forgotPassword/CHANGE_PASSWORD';
export const CHANGE_PASSWORD_ERROR = 'forgotPassword/CHANGE_PASSWORD_ERROR';
export const CHANGE_PASSWORD_SUCCESS = 'forgotPassword/CHANGE_PASSWORD_SUCCESS';

export const CURRENT_PASSWORD_URL = '/advisor-service/advisors/me/credentials';
export const CURRENT_PASSWORD = 'CURRENT_PASSWORD';

export const RESET_PASSWORD_EMAIL = 'RESET_PASSWORD_EMAIL';
