import {
    call, put, take, fork,
} from 'redux-saga/effects';
import { SIGN_OUT } from 'state-management/constants/signOut';
import Api from 'utils/api';
import { clearBasicProfile } from 'state-management/actions/basicProfile';
import * as analytics from 'utils/adobeAnalytics';
import storage from 'utils/store';

/**
 * Sign a user out.
 */
function* signOut() {
    while (true) {
        const { redirectTo } = yield take(SIGN_OUT);

        yield put(clearBasicProfile());
        yield call(Api.logout);

        analytics.removeAEID();

        storage.set('signOutAction', true);
        storage.set('storage/learn/tab', 'CLIENT_PERSPECTIVES');

        window.location = "/";
    }
}

export default function* signOutSaga() {
    yield fork(signOut);
}
