import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getMarketBriefing } from 'state-management/actions/marketBriefing.common';
import MarketBriefingDisplay from 'components/MarketBriefing/page/MarketBriefingPage';
import { dismissCoachMark } from 'state-management/actions/coachMark';
import ScrollPercentage from 'react-scroll-percentage';
import * as analytics from 'utils/adobeAnalytics';
import storage from 'utils/store';
import {
    MARKET_BREIFING_HAS_READ_LATEST,
    MARKET_BRIEFING_LATEST_ARTICLE_ID,
} from 'containers/MarketBriefing/constants';
import {
    PAGE_NAME_DJBRIEFING,
    SITE_SECTION_DJBRIEFING,
    PAGE_NAME_DJBRIEFING_PROCENTAGE,
} from 'utils/analyticsConstants';
import Loader from 'components/Loader/Loader';

/**
 * Container for displaying a full market briefing.
 */
export class MarketBriefing extends React.Component {
    state = {
        hasEventSent25: false,
        hasEventSent50: false,
        hasEventSent75: false,
        hasEventSent100: false,
    };

    /**
     * Request the full briefing at mount (id was passed by parent page component).
     */
    componentDidMount() {
        this.props.getMarketBriefing(this.props.id);

        setTimeout(() => {
            this.props.onDismiss();
        }, 1000);

        const latestArticleId = storage.get(MARKET_BRIEFING_LATEST_ARTICLE_ID);

        // have they read the latest?
        // if so we set this so the sticky banner on the
        // news page doesnt show anymore (until we get a new one again).
        if (this.props.id === latestArticleId) {
            storage.set(MARKET_BREIFING_HAS_READ_LATEST, true);
        }
    }

    /**
     * Handle scroll percentage events.
     */
    onScrollChange = (percentage) => {
        const toleranceLevel = 5;
        const perc = Math.ceil(percentage.toPrecision(2) * 100) + toleranceLevel;

        if (perc >= 25
            && !this.state.hasEventSent25
            && !this.state.hasEventSent50
            && !this.state.hasEventSent75
            && !this.state.hasEventSent100
        ) {
            analytics.dispatchPageViewStartScroll(PAGE_NAME_DJBRIEFING,
                SITE_SECTION_DJBRIEFING, PAGE_NAME_DJBRIEFING_PROCENTAGE(25));
            this.setState({
                hasEventSent25: true,
            });
        }

        if (perc >= 50
            && !this.state.hasEventSent50
            && !this.state.hasEventSent75
            && !this.state.hasEventSent100
        ) {
            analytics.dispatchPageViewStartScroll(PAGE_NAME_DJBRIEFING,
                SITE_SECTION_DJBRIEFING, PAGE_NAME_DJBRIEFING_PROCENTAGE(50));
            this.setState({
                hasEventSent50: true,
            });
        }

        if (perc >= 75
            && !this.state.hasEventSent75
            && !this.state.hasEventSent100
        ) {
            analytics.dispatchPageViewStartScroll(PAGE_NAME_DJBRIEFING,
                SITE_SECTION_DJBRIEFING, PAGE_NAME_DJBRIEFING_PROCENTAGE(75));
            this.setState({
                hasEventSent75: true,
            });
        }

        if (perc >= 100 && !this.state.hasEventSent100) {
            analytics.dispatchPageViewStartScroll(PAGE_NAME_DJBRIEFING,
                SITE_SECTION_DJBRIEFING, PAGE_NAME_DJBRIEFING_PROCENTAGE(100));
            this.setState({
                hasEventSent100: true,
            });
        }
    }

    /**
     * Render the underlying components as needed.
     */
    render() {
        const { marketBriefing } = this.props;
        if (!marketBriefing || marketBriefing.loading === true) return <Loader />;
        if (marketBriefing.briefing && marketBriefing.briefing.id) {
            return (
                <div>
                    <ScrollPercentage
                        onChange={this.onScrollChange}
                    >
                        <MarketBriefingDisplay
                            date={marketBriefing.briefing.published_at}
                            type={marketBriefing.briefing.type}
                            content={marketBriefing.briefing.content}
                        />
                    </ScrollPercentage>
                </div>
            );
        }
        return null;
    }
}

MarketBriefing.propTypes = {
    id: PropTypes.string.isRequired,
    marketBriefing: PropTypes.shape({
        briefing: PropTypes.shape({
            id: PropTypes.string,
            content: PropTypes.string,
            type: PropTypes.string,
            published_at: PropTypes.string,
        }),
    }),
    getMarketBriefing: PropTypes.func,
    onDismiss: PropTypes.func,
};

MarketBriefing.defaultProps = {
    marketBriefing: {
        briefing: {
            id: null,
            content: null,
            type: null,
        },
    },
    getMarketBriefing: () => {},
    onDismiss: () => {},
};

const mapStateToProps = state => ({
    marketBriefing: state.marketBriefing,
});

const mapDispatchToProps = dispatch => ({
    getMarketBriefing: id => dispatch(getMarketBriefing(id)),
    onDismiss: () => dispatch(dismissCoachMark('MARKET_BRIEFING', true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketBriefing);
