import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get } from 'lodash';
import { CheckIconSmall, IconCross } from 'components/atomics/atoms/Icons/Icons';
import { SIZES, THEMES } from 'components/FieldText/constants';
import FieldError from 'components/FieldError/FieldError';
import {
    inputStyles,
    labelStyles,
    wrapperStyles,
    iconStyles,
    successIconStyles,
    errorIconStyles,
} from 'components/FieldText/styles';

const StyledWrapper = styled.div`
    ${wrapperStyles};
`;

export const StyledLabel = styled.label`
    ${labelStyles};
`;

export const StyledInput = styled.input`
    ${inputStyles};
`;

const StyledIcon = styled.span`
    ${iconStyles};
`;

const StyledSuccessIcon = styled(CheckIconSmall)`
    ${successIconStyles};
`;

const StyledErrorIcon = styled(IconCross)`
    ${errorIconStyles};
`;

class FieldText extends React.Component {
    static getDerivedStateFromProps(props, state) {
        const updatedState = {
            isFieldInvalid: props.isValidated,
        };

        if (props.value) {
            updatedState.value = props.value;
        } else if (props.field.value !== state.value) {
            updatedState.value = props.field.value;
        }

        if (!state.isFieldInvalid && props.isValidated && !state.isEdited) {
            updatedState.isValidationIconVisible = true;
        }

        return updatedState;
    }

    constructor(props) {
        super(props);

        this.state = {
            value: '',
            isAutofilled: false,
            isFocused: false,
            isFieldInvalid: false, // eslint-disable-line
            isEdited: false, // eslint-disable-line
            isValidationIconVisible: false,
        };

        this.textFieldRef = React.createRef();
    }

    componentDidMount() {
        const node = this.textFieldRef.current;

        if (node) {
            node.addEventListener('animationstart', this.setAutofilled);
        }
    }

    componentWillUnmount() {
        const node = this.textFieldRef.current;

        if (node) {
            node.removeEventListener('animationstart', this.setAutofilled);
        }
    }

    setAutofilled = () => {
        this.setState({
            isAutofilled: true,
            isFocused: true,
        });
    };

    handleBlur = (event) => {
        const { field, onBlur } = this.props;

        this.setState({
            isAutofilled: false,
            isFocused: false,
            isEdited: false, // eslint-disable-line
        });

        if (field && field.onBlur) {
            field.onBlur(event);
        }

        onBlur(event);
    };

    handleChange = (event) => {
        const {
            onChange,
            onFormat,
            field,
            form,
        } = this.props;
        const { name } = event.target;

        const value = onFormat ? onFormat(event.target.value) : event.target.value;

        this.setState({
            value,
            isValidationIconVisible: false,
            isFocused: true,
            isEdited: true // eslint-disable-line
        }, () => {
            if (onFormat) {
                form.setFieldValue(name, value);
            }
        });

        if (!onFormat && (field && field.onChange)) {
            field.onChange(event);
        }

        onChange(event);
    };

    handleFocus = (event) => {
        const { field } = this.props;

        this.setState({
            isFocused: true,
        });

        if (field && field.onFocus) {
            field.onFocus(event);
        }
    };

    handleKeyDown = (event) => {
        const { onKeyDown } = this.props;

        if (onKeyDown && typeof onKeyDown === 'function') {
            onKeyDown(event);
        }
    };

    render() {
        const {
            ariaLabel,
            ariaDescribedby,
            className,
            floatingLabel,
            form,
            id,
            isSubmitted,
            field,
            label,
            size,
            theme,
            hasGlobalError,
            ref,
            fieldRef,
            ...other
        } = this.props;
        const { value } = this.state;
        const { isAutofilled, isFocused, isValidationIconVisible } = this.state;
        const fieldName = field.name;
        const fieldId = id || fieldName;
        const error = get(form.errors, fieldName);
        const isInvalid = error !== undefined && error !== null;
        const isTouched = !!get(form.touched, fieldName);
        const fieldValue = get(form.values, fieldName);
        const isActive = isFocused || !!fieldValue || isAutofilled;
        const showFieldIcon = isValidationIconVisible && !hasGlobalError;
        const inputProps = {};

        if (ariaLabel) {
            inputProps['aria-label'] = ariaLabel;
        }

        return (
            <StyledWrapper className={className} ref={this.textFieldRef}>
                {label && (
                    <StyledLabel
                        htmlFor={fieldId}
                        variant={{
                            floatingLabel,
                            isActive,
                            isInvalid,
                            size,
                            fieldValue,
                        }}
                    >
                        {label}
                    </StyledLabel>
                )}
                <StyledInput
                    {...field}
                    {...other}
                    {...inputProps}
                    aria-describedby={`${fieldId}-errors ${ariaDescribedby}`}
                    aria-invalid={isInvalid}
                    id={fieldId}
                    ref={ref || fieldRef}
                    onBlur={this.handleBlur}
                    onFocus={this.handleFocus}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    value={value}
                    variant={{
                        floatingLabel,
                        isActive,
                        isInvalid,
                        isSubmitted,
                        size,
                        theme,
                        showFieldIcon,
                        fieldValue,
                    }}
                />
                {showFieldIcon && (
                    <StyledIcon>
                        {isInvalid
                            ? <StyledErrorIcon />
                            : <StyledSuccessIcon />
                        }
                    </StyledIcon>
                )}
                <FieldError
                    id={fieldId}
                    error={error}
                    isVisible={isTouched && isInvalid}
                />
            </StyledWrapper>
        );
    }
}

FieldText.propTypes = {
    ariaDescribedby: PropTypes.string,
    ariaLabel: PropTypes.string,
    className: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onFormat: PropTypes.func,
    field: PropTypes.shape({
        name: PropTypes.string,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        value: PropTypes.string,
    }),
    floatingLabel: PropTypes.bool,
    form: PropTypes.shape({
        errors: PropTypes.objectOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({}),
        ])),
        touched: PropTypes.objectOf(PropTypes.any),
        setFieldValue: PropTypes.func,
    }),
    label: PropTypes.string,
    id: PropTypes.string,
    size: PropTypes.oneOf(Object.values(SIZES)),
    theme: PropTypes.oneOf(Object.values(THEMES)),
    type: PropTypes.string,
    isValidated: PropTypes.bool,
    isSubmitted: PropTypes.bool,
    hasGlobalError: PropTypes.bool,
    ref: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({}),
    ]),
    fieldRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({}),
    ]),
    value: PropTypes.string,
};

FieldText.defaultProps = {
    ariaDescribedby: '',
    ariaLabel: '',
    className: '',
    field: {},
    floatingLabel: true,
    form: {},
    label: undefined,
    id: '',
    onBlur: () => { },
    onChange: () => { },
    onFormat: null,
    onKeyDown: () => { },
    size: SIZES.MEDIUM,
    theme: null,
    type: 'text',
    isValidated: false,
    isSubmitted: false,
    hasGlobalError: false,
    ref: undefined,
    fieldRef: undefined,
    value: '',
};

FieldText.SIZES = SIZES;
FieldText.THEMES = THEMES;

export default FieldText;
