import {
    all, call, fork, put, takeEvery, takeLatest, select, take, throttle
} from 'redux-saga/effects';
import { union, difference } from 'lodash';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import {
    getBasicProfile,
    getBasicProfileError,
    setBasicProfile,
    setBasicProfileLoading,
    setBasicProfileUpdateFailed,
    setBasicProfileUpdating,
    updateHomepage,
    getIsAppInstalledFailed,
    getIsAppInstalledSuccess,
    validateTokenSuccess,
    validateTokenFailed,
    getNotification,
    getNotificationSuccess,
    getNotificationFailure,
} from 'state-management/actions/basicProfile';
import {
    GO_TO_PAGE,
    GET_BASIC_PROFILE,
    BASIC_PROFILE_URL,
    SAVE_BASIC_PROFILE,
    SAVE_BASIC_PROFILE_AND_REDIRECT,
    SAVE_ONBOARDED_AND_SAVE_BASIC_PROFILE_AND_REDIRECT,
    SAVE_ONBOARDED_URL,
    SAVE_BASIC_PROFILE_CREDENTIALS,
    BASIC_PROFILE_CREDENTIALS_URL,
    SET_HOMEPAGE,
    SET_HOMEPAGE_URL,
    GET_ISAPPINSTALLED,
    SET_ISAPPINSTALLED_URL,
    SET_ISAPPINSTALLED,
    GET_ISAPPINSTALLED_URL,
    VALIDATE_TOKEN_URL,
    VALIDATE_TOKEN,
    BASIC_PROFILE_URL_WITH_TOKEN,
    SKIP_MIGRATION_STORAGE_KEY,
    DISMISS_EXPIRING_BOOKMARKS_MAIN_BADGE_STORAGE_KEY,
    DISMISS_EXPIRING_BOOKMARKS_DETAIL_BADGE_STORAGE_KEY,
    MIGRATION_INTERCEPT_URL,
    MIGRATION_ENUM,
    NOTIFICATION_URL,
    GET_NOTIFICATION,
    GET_NOTIFICATION_SUCCESS,
    DISMISS_EXPIRING_BOOKMARKS_NOTIFICATION,
    GET_NOTIFICATION_FAILURE,
} from '../constants/basicProfile';
import { IDENTITY_PROFILE_URL } from "../constants/signIn";
import Api from 'utils/api';
import { setError } from 'state-management/actions/errors';
import { setAEID, setCRD } from 'utils/contextHub';
import { getIdentityServiceBaseURL } from 'utils/urlUtils';


/**
 * Forward user to new page.
 */
function* goToPage({ page }) {
    yield put(push(page));
}

/**
 * Set user has iOS app installed.
 */
function* setIsAppInstalledData() {
    try {
        const response = yield call(Api.post, SET_ISAPPINSTALLED_URL);
    } catch (e) {
        yield put(setError('global.errors.loadProfile', e));
    }
}

/**
 * Get if the user has iOS app installed from BE.
 */
function* getIsAppInstalledData() {
    try {
        const response = yield call(Api.get, GET_ISAPPINSTALLED_URL);
        yield put(getIsAppInstalledSuccess(response.data));
    } catch (e) {
        // eslint-disable-next-line no-console
        yield put(getIsAppInstalledFailed());
        yield put(setError('global.errors.loadProfile', e));
    }
}

/**
 * Validate the users session is valid, actions and data consumed by PrivateRoute container.
 */
function* validateToken() {
    try {
        const response = yield call(Api.get, VALIDATE_TOKEN_URL);

        if (response && response.status === true) {
            yield put(validateTokenSuccess(response.expires));
        } else {
            yield put(validateTokenFailed());
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        yield put(validateTokenFailed());
    }
}

/**
 * Get users basic profile.
 */
function* fetchBasicProfile({ shareId }) {
    try {
        yield put(setBasicProfileLoading());

        let response;

        if (!shareId) {
            response = yield call(Api.get, BASIC_PROFILE_URL);
        } else {
            response = yield call(Api.get, BASIC_PROFILE_URL_WITH_TOKEN(shareId));
        }

        if (response.data.aeid) {
            setAEID(response.data.aeid);
        }
        if (response.data.crd) {
            setCRD(response.data.crd.toString());
        }

        try {
            yield put(getNotification());
            const action = yield take([ GET_NOTIFICATION_SUCCESS, GET_NOTIFICATION_FAILURE ]);
            if (action.type === GET_NOTIFICATION_FAILURE) {
                throw new Error(action.error);
            } else {
                // fetch the migrated profile data if user is migrated, merge it with CG Pro profile data
                if (notification.data.migrationState === MIGRATION_ENUM.MIGRATED) {
                    let migratedProfileResponse = yield call(
                        Api.request,
                        IDENTITY_PROFILE_URL,
                        null,
                        null,
                        'GET',
                        null,
                        false,
                    );
                    yield put(setBasicProfile({
                        ...response.data,
                        ...migratedProfileResponse.data,
                        proEmail: response.data.email,
                    }));
                } else {
                    yield put(setBasicProfile(response.data));
                }
            }
        } catch (e) {
            yield put(setBasicProfile(response.data));
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        yield put(getBasicProfileError('Could not load basic profile'));
        yield put(setError('global.errors.loadProfile', e));
    }
}

/**
 * Save users basic profile.
 */
function* saveBasicProfile() {
    while (true) {
        const onboardingData = yield take(SAVE_BASIC_PROFILE);

        let methodType = sessionStorage.getItem('profilePageName')== 'preferences' ?  Api.put : Api.post;
        try {
            yield put(setBasicProfileUpdating());
            yield call(methodType, BASIC_PROFILE_URL, {
                data: onboardingData.data,
            });

            yield call(Api.setFirstName, onboardingData.data.firstName);
            yield put(setBasicProfile(onboardingData.data));
        } catch (e) {
            yield put(setBasicProfileUpdateFailed());
            yield put(setError('global.errors.saveProfile'));
        }
    }
}

/**
 * Save users basic profile then forward to next flow page.
 */
function* saveBasicProfileAndRedirect() {
    while (true) {
        const action = yield take(SAVE_BASIC_PROFILE_AND_REDIRECT);
        const { redirect, ...profileData } = action.data;

        try {
            yield put(setBasicProfileUpdating());
            yield call(Api.post, BASIC_PROFILE_URL, {
                data: profileData,
            });

            yield call(Api.setFirstName, profileData.firstName);
            yield put(setBasicProfile(profileData));
            yield put(push(redirect));
        } catch (e) {
            yield put(setBasicProfileUpdateFailed());
            yield put(setError('global.errors.saveProfile'));
        }
    }
}

/**
 * Save users basic profile from onboarding then forward to next page in flow.
 */
function* saveOnboardedAndSaveBasicProfileAndRedirect() {
    while (true) {
        const action = yield take(SAVE_ONBOARDED_AND_SAVE_BASIC_PROFILE_AND_REDIRECT);
        const { redirect, ...profileData } = action.data;

        try {
            yield put(setBasicProfileUpdating());
            yield call(Api.post, BASIC_PROFILE_URL, {
                data: profileData,
            });
            yield call(Api.post, SAVE_ONBOARDED_URL);

            const response = yield call(Api.get, BASIC_PROFILE_URL);
            yield put(setBasicProfile(response.data));

            yield call(Api.setFirstName, profileData.firstName);
            if (redirect) {
                yield put(push(redirect));
            }
        } catch (e) {
            yield put(setBasicProfileUpdateFailed());
            yield put(setError('global.errors.saveProfile'));
        }
    }
}

/**
 * Save a users profile credentials.
 */
function* saveProfileCredentials() {
    while (true) {
        const data = yield take(SAVE_BASIC_PROFILE_CREDENTIALS);
        try {
            const repsone = yield call(Api.post, BASIC_PROFILE_CREDENTIALS_URL, { data: data.data });
        } catch (e) {
            yield put(setBasicProfileUpdateFailed());
            yield put(setError('global.errors.saveProfile'));
        }
    }
}

/**
 * Set/unset news as homepage.
 * @param {*} param0
 */
function* setHomepage({ homepageType }) {
    try {
        yield call(Api.post, SET_HOMEPAGE_URL, {
            data: {
                homepage: homepageType,
            },
        });
        yield put(updateHomepage(homepageType));
    } catch (e) {
        yield put(setError('global.errors.saveProfile'));
    }
}

function* getNotificationSaga() {
    try {
        const { data } = yield call(Api.get, NOTIFICATION_URL);
        const notification = {
            expiringBookmarks: data.expiringBookMarks || [],
            migrationState: data.migrationState || MIGRATION_ENUM.NOT_READY_TO_MIGRATE,
        };

        // fetch dismissed expiring articles and calculate badges
        let dismissExpiringBookmarksMainBadge = JSON.parse(localStorage.getItem(DISMISS_EXPIRING_BOOKMARKS_MAIN_BADGE_STORAGE_KEY) || '[]');
        let dismissExpiringBookmarksDetailBadge = JSON.parse(localStorage.getItem(DISMISS_EXPIRING_BOOKMARKS_DETAIL_BADGE_STORAGE_KEY) || '[]');

        const expiringBookmarksMainBadge = notification.expiringBookmarks.filter((expiringBookmark) => dismissExpiringBookmarksMainBadge.indexOf(expiringBookmark) < 0);
        const expiringBookmarksDetailBadge = notification.expiringBookmarks.filter((expiringBookmark) => dismissExpiringBookmarksDetailBadge.indexOf(expiringBookmark) < 0);

        yield put(getNotificationSuccess({
            ...notification,
            expiringBookmarksMainBadge: expiringBookmarksMainBadge.length,
            expiringBookmarksDetailBadge: expiringBookmarksDetailBadge.length,
        }));

        // remove already expired articles from dismissed ids and store updated lists
        dismissExpiringBookmarksMainBadge = dismissExpiringBookmarksMainBadge.filter((expiringBookMark) => notification.expiringBookmarks.indexOf(expiringBookMark) >= 0);
        dismissExpiringBookmarksDetailBadge = dismissExpiringBookmarksDetailBadge.filter((expiringBookMark) => notification.expiringBookmarks.indexOf(expiringBookMark) >= 0);

        localStorage.setItem(DISMISS_EXPIRING_BOOKMARKS_MAIN_BADGE_STORAGE_KEY, JSON.stringify(dismissExpiringBookmarksMainBadge));
        localStorage.setItem(DISMISS_EXPIRING_BOOKMARKS_DETAIL_BADGE_STORAGE_KEY, JSON.stringify(dismissExpiringBookmarksDetailBadge));
    } catch (e) {
        console.log(e);
        yield put(getNotificationFailure(e));
    }
}

function* dismissExpiringBookmarksNotificationSaga({ key, ids }) {
    const notification = yield select(state => state.basicProfile.notification.data);
    const { expiringBookmarks } = notification;
    try {
        if (key === DISMISS_EXPIRING_BOOKMARKS_MAIN_BADGE_STORAGE_KEY) {
            localStorage.setItem(key, JSON.stringify(expiringBookmarks));
            yield put(getNotificationSuccess({
                ...notification,
                expiringBookmarksMainBadge: 0,
            }));
        } else if (key === DISMISS_EXPIRING_BOOKMARKS_DETAIL_BADGE_STORAGE_KEY) {
            let dismissExpiringBookmarksDetailBadge = JSON.parse(localStorage.getItem(key) || '[]');
            dismissExpiringBookmarksDetailBadge = union(dismissExpiringBookmarksDetailBadge, ids);
            localStorage.setItem(key, JSON.stringify(dismissExpiringBookmarksDetailBadge));
            yield put(getNotificationSuccess({
                ...notification,
                expiringBookmarksDetailBadge: difference(expiringBookmarks, dismissExpiringBookmarksDetailBadge).length,
            }));
        }
    } catch (e) {
        console.log(e);
    }
}

/**
 * Handle location changes under profile.
 */
function* locationChanged({ payload }) {
    const errors = yield select(state => state.errors.errors);
    const isAuthenticated = yield select(state => state.signIn && state.signIn.isAuthenticated);

    const loadProfileError = errors.find(error => error.errorMessage === 'global.errors.loadProfile');

    if (loadProfileError && isAuthenticated) {
        yield put(getBasicProfile());
    }

    // check migration status if user is logged in
    if (isAuthenticated && (!payload || !payload.location || payload.location.pathname !== MIGRATION_INTERCEPT_URL)) {
        const skipMigration = localStorage.getItem(SKIP_MIGRATION_STORAGE_KEY);

        if (!skipMigration) {
            try {
                yield put(getNotification());
                const action = yield take([ GET_NOTIFICATION_SUCCESS, GET_NOTIFICATION_FAILURE ]);
                if (action.type === GET_NOTIFICATION_SUCCESS) {
                    if (action.data.migrationState === MIGRATION_ENUM.READY_TO_MIGRATE
                        || action.data.migrationState === MIGRATION_ENUM.MUST_MIGRATE) {

                        if (action.data.migrationState === MIGRATION_ENUM.READY_TO_MIGRATE) {
                            localStorage.setItem(SKIP_MIGRATION_STORAGE_KEY, JSON.parse(true));
                        }

                        window.location.href = MIGRATION_INTERCEPT_URL;
                    }
                }
            } catch (e) {}
        }
    }
}

function* basicProfileSaga() {
    yield all([
        takeLatest(GO_TO_PAGE, goToPage),
        takeLatest(SET_ISAPPINSTALLED, setIsAppInstalledData),
        takeLatest(GET_ISAPPINSTALLED, getIsAppInstalledData),
        takeLatest(VALIDATE_TOKEN, validateToken),
        fork(saveBasicProfile),
        fork(saveBasicProfileAndRedirect),
        fork(saveOnboardedAndSaveBasicProfileAndRedirect),
        fork(saveProfileCredentials),
        takeEvery(GET_BASIC_PROFILE, fetchBasicProfile),
        takeLatest(SET_HOMEPAGE, setHomepage),
        takeEvery(LOCATION_CHANGE, locationChanged),
        throttle(1000, GET_NOTIFICATION, getNotificationSaga),
        throttle(1000, DISMISS_EXPIRING_BOOKMARKS_NOTIFICATION, dismissExpiringBookmarksNotificationSaga),
    ]);
}

export default basicProfileSaga;
