import { css } from 'styled-components';
import { FROM_DESKTOP } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS } from 'utils/variables';

export const footerStyles = css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 8px 16px 0;

    @media (min-width: 480px) {
        padding: 8px 13% 0;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 32px 0 0;
    }
`;

export const linkStyles = css`
    color: ${COLORS.MEDIUM_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 12px;
    line-height: 1.33;
    text-decoration: underline;

    @media screen and ${FROM_DESKTOP} {
        font-size: 18px;
    }
`;

export const dowJonesCopyrightStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 14px;
    line-height: 1.43;
    padding-left: 16px;
    padding-right: 16px;

    @media (min-width: 480px) {
        padding-left: 13%;
        padding-right: 13%;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 18px;
        line-height: 1.33;
        padding-left: 0;
        padding-right: 0;
    }
`;

export const footerSeparatorStyles = css`
    color: ${COLORS.MEDIUM_GRAY2}
    font-size: 18px;
    line-height: 1.33;
    margin: 0 14px;

    @media screen and ${FROM_DESKTOP} {
        margin: 0 20px;
    }
`;
