import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import storage from '../../utils/store';
import {
    FIRST_NAME,
} from '../../state-management/constants/signIn';
import { Container, Heading } from 'components';
import { ROUTES } from 'containers/App/constants';
import { contextHubRecordPageView } from '../../utils/contextHub';
import {
    sectionStyles,
    containerStyles,
    headingStyles,
    errorCodeStyles,
    descriptionStyles,
    actionsWrapperStyles,
    reloadButtonStyles,
    buttons404,
    contactContainerStyles,
    underlinedLinkStyles,
} from 'components/PageError/styles';
import { setScrollToTop } from 'state-management/actions/scroll';
import {
    NOT_FOUND_ERROR,
    SERVER_ERROR,
    FORBIDDEN_ERROR
} from 'state-management/constants/errors';
import Button from 'components/atomics/atoms/Button/Button';
import { TYPE_SECONDARY_MEDIUM_LARGE } from 'components/atomics/atoms/Button/Button';
import Link from 'components/Link/Link';
import { resetCriticalError } from 'state-management/actions/errors';

const StyledSection = styled.section`
    ${sectionStyles};
`;

const StyledContainer = styled(Container)`
    ${containerStyles};
`;

export const StyledHeading = styled(Heading)`
    ${headingStyles};
`;

export const StyledErrorCode = styled.p`
    ${errorCodeStyles};
`;

export const StyledDescription = styled.p`
    ${descriptionStyles};
`;

const StyledActionsWrapper = styled.div`
    ${actionsWrapperStyles};
`;

const StyledContactContainer = styled.p`
    ${contactContainerStyles};
`;

const StyledUnderlinedLink = styled(Link)`
    ${underlinedLinkStyles};
`;

const Styled404Buttons = styled.div`
    ${buttons404}
`;

export class PageError extends React.Component {
    /**
     * Record anaylitcs at mount.
     */
    componentDidMount() {
        this.props.scrollToTop();

        try {
            contextHubRecordPageView('/errorPage');
        } catch(e) {
            //catching for testing purposes only
        }
    }

    /**
     * Get error message localized text.
     * @param {*} id 
     * @param {*} values 
     */
    getTranslation(id, values = {}) {
        const { intl: { formatMessage } } = this.props;

        return formatMessage({
            id,
        }, values);
    }

    /**
     * Determine if user is logged in.
     */
    isLoggedIn = () => {
        const a = storage.get(FIRST_NAME);

        return a;
    }

    /**
     * Handle page reload click.
     */
    handleReloadButtonClick = () => {
        if (this.isLoggedIn() !== null) {
            this.props.resetError();
        } else {
            window.location.reload(false);
        }
    };

    /**
     * Render proper page controls below error messaging.
     */
    renderButtons = () => {
        const { errorType } = this.props;

        switch (errorType) {
            case SERVER_ERROR: {
                return (
                    <Button
                        to="#"
                        onClick={this.handleReloadButtonClick}
                    >
                        <FormattedMessage id="global.errors.serverError.button" />
                    </Button>);
            }
            case FORBIDDEN_ERROR: {
                return (
                    <Button
                        to="#"
                        onClick={this.handleReloadButtonClick}
                    >
                        <FormattedMessage id="global.errors.serverError.button" />
                    </Button>);
            }
            case NOT_FOUND_ERROR: {
                return (
                    <Styled404Buttons>
                        <Button
                            type={TYPE_SECONDARY_MEDIUM_LARGE}
                            to={ROUTES.CONTACT_US}
                        >
                            <FormattedMessage id="global.errors.notFound.contactUs" />
                        </Button>
                        <Button
                            to={ROUTES.HOME}
                        >
                            <FormattedMessage id="global.errors.notFound.backToHome" />
                        </Button>
                    </Styled404Buttons>);
            }
            default: {
                return null;
            }
        }
    };

    /**
     * Render this and underlying components.
     */
    render() {
        const {
            title, text, errorType, code, errorCode, errorLocation
        } = this.props;
        const time = new Date();
        const finalCode = errorCode && typeof (errorCode) === 'number' ? errorCode : code;
        let emailBody = this.getTranslation('global.errors.serverError.contactSupport.email.body');
        let supportEmail = this.getTranslation('global.errors.serverError.contactSupport.emailWithAttributes');

        supportEmail = supportEmail.replace('{subject}', this.getTranslation('global.errors.serverError.contactSupport.email.subject'))
            .replace('{body}', emailBody.replace('{time}', time).replace('{page}', errorLocation));

        const contactUsMessage = (
            <StyledContactContainer>
                <FormattedMessage id="global.errors.serverError.contactSupport" />
                <StyledUnderlinedLink to={supportEmail}>
                    <FormattedMessage id="global.errors.serverError.contactSupport.emailText" />
                </StyledUnderlinedLink>
            </StyledContactContainer>
        );

        const clientError = 403;

        return (
            <StyledSection>
                <StyledContainer>
                    <div>
                        <StyledHeading
                            appearance={2}
                            id="error-page-heading"
                            level={1}
                        >
                            <FormattedMessage id={title} />
                        </StyledHeading>
                        <StyledErrorCode>
                            <FormattedMessage
                                id="global.errors.errorCode"
                                values={{ errorCode: finalCode }}
                            />
                        </StyledErrorCode>
                        <StyledDescription id="error-page-description">
                            <FormattedMessage id={text} />
                        </StyledDescription>
                        <StyledActionsWrapper>
                            {this.renderButtons()}
                        </StyledActionsWrapper>
                        {(errorType === SERVER_ERROR || finalCode === clientError ) && contactUsMessage}
                    </div>
                </StyledContainer>
            </StyledSection>
        );
    }
}

PageError.propTypes = {
    code: PropTypes.string.isRequired,
    errorType: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    resetError: PropTypes.func.isRequired,
    scrollToTop: PropTypes.func.isRequired,
    text: PropTypes.node.isRequired,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

const mapStateToProps = state => ({
    errorCode: state.errors.lastErrorStatus,
    errorLocation: state.errors.lastErrorLocation,
});

const mapDispatchToProps = dispatch => ({
    resetError: () => dispatch(resetCriticalError()),
    scrollToTop: () => dispatch(setScrollToTop()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PageError));
