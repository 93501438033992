import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CapitalIdeasCard from './../../../../components/atomics/molecules/CapitalIdeasCard/CapitalIdeasCard';
import Row from 'components/atomics/atoms/Row/Row';
import {
    BreakpointsContext,
    MOBILE,
    TABLET
} from 'components/Breakpoints/Breakpoints';
import {
    containerStyles,
    mobileContainerStyles,
    capitalIdeasRowTitleStyle,
    sliderContainerStyles,
    sliderCellStyles,
} from 'components/atomics/organisms/CapitalCardCollectionOfFour/styles';
import { Carousel } from "../../molecules/CarouselMolecule/Carousel";

export const Container = styled.div`
    ${containerStyles}
`;

export const MobileContainer = styled.div`
    ${mobileContainerStyles}
`;

export const CapitalIdeasRowTitle = styled.div`
    ${capitalIdeasRowTitleStyle}
`;

export const SliderContainer = styled.section`
    ${sliderContainerStyles}
`;

export const SliderCell = styled.div`
    ${sliderCellStyles}
`;

export const TYPE_4COLUMN = "type/4column";
export const TYPE_3COLUMN = "type/3column";

/**
 * Creates a capital ideas collection of 4, either cards or carousel with cards.
 */
class CapitalCardCollectionOfFour extends React.Component {
    /**
     * Render the container and underlying components.
     */
    render() {
        if (this.props.articles.length <= 1) {
            return null;
        }

            const newArray = this.props.articles;

            let updatedArticles = newArray.sort((a, b) => +new Date(b.publishDate) - +new Date(a.publishDate));


        return (
            <BreakpointsContext.Consumer>
                {(breakpoint) => {
                    if (breakpoint === MOBILE) {
                        return (
                            <SliderContainer
                                data-rel="CapitalCardCollectionOfFour"
                            >

                                <Carousel items={[
                                    {
                                        component: (<CapitalIdeasCard
                                            article={updatedArticles[0]}
                                            handleGuideClick={this.props.handleGuideClick}
                                        />)
                                    },
                                    {
                                        component: ( <CapitalIdeasCard
                                            article={updatedArticles[1]}
                                            handleGuideClick={this.props.handleGuideClick}
                                        />)
                                    },
                                    {
                                        component: ( <CapitalIdeasCard
                                            article={updatedArticles[2]}
                                            handleGuideClick={this.props.handleGuideClick}
                                        />)
                                    },
                                    {
                                        component: ( <CapitalIdeasCard
                                            article={updatedArticles[3]}
                                            handleGuideClick={this.props.handleGuideClick}
                                        />)
                                    }
                                ]}/>
                            </SliderContainer>
                        );
                    }

                    if (breakpoint === TABLET) {
                        return (
                            <Container>
                                <Row data-rel="CapitalCardCollectionOfFour" type={TYPE_3COLUMN}>
                                    <CapitalIdeasCard
                                        article={updatedArticles[0]}
                                        handleGuideClick={this.props.handleGuideClick}
                                    />
                                    <CapitalIdeasCard
                                        article={updatedArticles[1]}
                                        handleGuideClick={this.props.handleGuideClick}
                                    />

                                    <CapitalIdeasCard
                                        article={updatedArticles[2]}
                                        handleGuideClick={this.props.handleGuideClick}
                                    />
                                </Row>
                            </Container>
                        )
                    }

                    return (
                        <Container>
                            <Row data-rel="CapitalCardCollectionOfFour" type={TYPE_4COLUMN}>
                                <CapitalIdeasCard
                                    article={updatedArticles[0]}
                                    handleGuideClick={this.props.handleGuideClick}
                                />

                                <CapitalIdeasCard
                                    article={updatedArticles[1]}
                                    handleGuideClick={this.props.handleGuideClick}
                                />

                                <CapitalIdeasCard
                                    article={updatedArticles[2]}
                                    handleGuideClick={this.props.handleGuideClick}
                                />

                                <CapitalIdeasCard
                                    article={updatedArticles[3]}
                                    handleGuideClick={this.props.handleGuideClick}
                                />
                            </Row>
                        </Container>
                    );
                }}
            </BreakpointsContext.Consumer>
        );
    }
}

CapitalCardCollectionOfFour.propTypes = {
    articles: PropTypes.arrayOf(PropTypes.shape({
        date: PropTypes.string,
        topicName: PropTypes.string,
        topicId: PropTypes.number,
        title: PropTypes.string,
        image: PropTypes.string,
        sourceName: PropTypes.string,
        sourceId: PropTypes.number,
        provider: PropTypes.string,
        id: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        url: PropTypes.string,
        visited: PropTypes.bool,
    })),
    handleGuideClick: PropTypes.func,
};

CapitalCardCollectionOfFour.defaultProps = {
    handleGuideClick: () => { },
}

export default CapitalCardCollectionOfFour;
