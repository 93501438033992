import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ImageAtom from 'components/atomics/atoms/Image/Image';
import Title from 'components/atomics/atoms/Title/Title';
import {
    sloganStyles,
    collectionContainerStyles,
    collectionImageContainerStyles,
    collectionContentStyles,
    collectionTopContainerStyles
} from 'components/atomics/molecules/CollectionHeaderCard/styles';
import language from '../../../../lang/en.json';

export const Slogan = styled.div`
    ${sloganStyles}
`;

export const CollectionContainer = styled.div`
    ${collectionContainerStyles}
`;

export const CollectionImageContainer = styled.div`
    ${collectionImageContainerStyles}
`;

export const CollectionContentContainer = styled.div`
    ${collectionContentStyles}
`;

export const TopContainer = styled.div`
    ${collectionTopContainerStyles}
`;

/**
 * Creates a collection header area (used in collection display page).
 */
class CollectionHeaderCard extends React.Component {
    /**
     * Render this and underlying components.
     */
    render() {
        const title = this.props.collectionInfo.title;
        const sloganText = this.props.collectionInfo.description;
        let imageSource;
        
        imageSource = `/api${this.props.collectionInfo.image}`; 
    
        return (
            <CollectionContainer>
                <TopContainer>
                    <CollectionImageContainer>
                        <ImageAtom
                            src={imageSource}
                            alt={language.translations['global.alt.image']}
                        />

                    </CollectionImageContainer>
                    <CollectionContentContainer>
                        <Title>
                            {title}
                        </Title>
                        <Slogan>
                            {sloganText}
                        </Slogan>
                    </CollectionContentContainer>
                </TopContainer>
            </CollectionContainer>);
    }
}

CollectionHeaderCard.propTypes = {
    collectionInfo: PropTypes.shape({
        code: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        ordering: PropTypes.number.isRequired,
        image: PropTypes.string.isRequired
    })
}

export default CollectionHeaderCard;
