import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import anime from 'animejs';
import { contentWrapperStyles } from 'containers/Onboarding/OnboardingContent/styles';

const StyledContentWrapper = styled.div`
    ${contentWrapperStyles};
`;

/**
 * Render onboarding step pages splash content areas.
 */
class OnboardingContent extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isAnimated: false,
            isAnimating: false,
        };

        this.contentWrapperRef = React.createRef();
    }

    componentDidMount() {
        this.animate();
    }

    componentDidUpdate() {
        this.animate();
    }

    componentWillUnmount() {
        if (this.state.isAnimating) {
            this.animation.pause();
        }
    }

    animate() {
        if (!this.props.isLoaded || this.state.isAnimated) {
            return;
        }

        const contentWrapperNode = this.contentWrapperRef.current;

        this.setState({
            isAnimated: true,
        });

        if (contentWrapperNode) {
            this.animation = anime({
                targets: contentWrapperNode,
                easing: [0.99, 0.03, 0.47, 0.95],
                duration: 330,
                offset: 330,
                opacity: [0, 1],
                complete: () => {
                    contentWrapperNode.removeAttribute('style');
                    this.setState({
                        isAnimating: false,
                    });
                },
            });
        }
    }

    render() {
        const {
            children,
            isAnimatable,
            topPadding,
        } = this.props;
        const { isAnimated } = this.state;

        return (
            <StyledContentWrapper
                topPadding={topPadding}
                ref={this.contentWrapperRef}
                variant={{ isAnimatable, isAnimated }}
            >
                {children}
            </StyledContentWrapper>
        );
    }
}

OnboardingContent.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    isAnimatable: PropTypes.bool,
    isLoaded: PropTypes.bool,
    topPadding: PropTypes.string
};

OnboardingContent.defaultProps = {
    children: null,
    isAnimatable: false,
    isLoaded: false,
    topPadding: null,
};

export default OnboardingContent;
