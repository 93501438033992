
export const raf = (
    window.requestAnimationFrame
    || window.webkitRequestAnimationFrame
    || window.mozRequestAnimationFrame
).bind(window);

export const caf = (
    window.cancelAnimationFrame
    || window.webkitCancelAnimationFrame
    || window.mozCancelAnimationFrame
).bind(window);

export default {
    cancel: caf,
    request: raf,
};
