import { css } from 'styled-components';
import {
    COLORS,
    FONTS,
} from 'utils/variables';
import {
    FROM_TABLET,
    FROM_DESKTOP,
} from 'utils/mediaQueries';

export const containerStyles = css`
    padding: 10px 0 10px 0;
    text-align: center;
    color: ${COLORS.BLACK};
    font-family: ${FONTS.AVENIR_NEXT.FONT};

    ${props => props.dataPage === "promoBanner" && css`
        width: 100%;
        padding-top: 22px;
        @media screen and ${FROM_TABLET} {
            padding-left: 0;
            padding-top: 38px;
        }

        @media screen and ${FROM_DESKTOP} {
            padding-top: 30px;
            padding-left: 10px;           
        }
    `}

    ${props => props.dataPage === "landingPage" && css`
        width: auto;
    `}
`;

export const dateContainer = css`
    color: ${COLORS.DARK_GRAY1};
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: -0.1px;
    font-weight: 500;
    padding-bottom: 16px;
`;

export const timeContainer = css`
    display: flex;

    div:first-child {
        flex: 1;
    }

    div:last-child {
        flex: 1;
    }
`;

export const timeItem = css`
    font-size: 24px;
    font-weight: 600;
    padding: 0 1px 0 1px;

    @media screen and ${FROM_TABLET} {
        font-size: 28px;
        padding: 0 6px 0 6px;
    }

    @media screen and ${FROM_DESKTOP} {
        ${props => props.dataPage === "landingPage" && css`
            font-size: 47.6px;
        `}
    }

    span:first-child {
        padding-right: 4px;
        min-width: 39px;

        @media screen and ${FROM_DESKTOP} {
            ${props => props.dataPage === "landingPage" && css`
                font-size: 47.6px;
            `}
        }
        
    }

    span:last-child {
        color: ${COLORS.MEDIUM_GRAY2};
        font-size: 11px;
        letter-spacing: 1px;
        text-transform: uppercase;

        @media screen and ${FROM_TABLET} {
            font-size: 12px;
        }

        @media screen and ${FROM_DESKTOP} {
            ${props => props.dataPage === "landingPage" && css`
                font-size: 16.8px;
                font-weight: 500;
            `}
        }
    }
`;
