import { css } from 'styled-components';

import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';

export const contentWrapperStyles = css`
    box-sizing: border-box;
    width: 100%;

    p {
        margin-top: 0;
    }

    /* stylelint-disable */

    ${props => props.variant && props.variant.isAnimatable && !props.variant.isAnimated && css`
        opacity: 0;
    `};

    ${props => props.topPadding && props.topPadding !== null && css`
        padding-top: ${props.topPadding};
    `};

    /* stylelint-enable */

    @media screen and ${FROM_TABLET} {
        align-self: flex-start;
        box-sizing: border-box;
        margin-left: auto;
        width: 55%;
        word-wrap: break-word;
    }

    @media screen and ${FROM_DESKTOP} {
        padding-left: 72px;
        padding-top: 70px;
    }
`;
