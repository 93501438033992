import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import store from 'utils/store';
import { Prompt } from 'react-router-dom';
import {
    getBasicProfile,
    validateToken,
} from 'state-management/actions/basicProfile';
import Loader from 'components/Loader/Loader';
//import Api from 'utils/api';
import { ROUTES } from 'containers/App/constants';
import { contextHubRecordEvent } from 'utils/contextHub';
import {
    SHARE_EVENT_LIST,
} from 'utils/contextHubEventListConstants';
import { REDIRECT_AFTER_LOGIN_URL } from 'state-management/constants/signIn';
import { signOut } from 'state-management/actions/signOut';
import { USER_SHARE_TOKEN, MIGRATION_ENUM, SKIP_MIGRATION_STORAGE_KEY } from '../../state-management/constants/basicProfile';
//import { IS_KEEP_SIGNED_IN, LOGOUT_TIME } from 'state-management/sagas/authentication';
import { addUrlHistoryItem, initDefaultUrlHistoryStore } from "../../state-management/actions/UrlHistoryActions";

//const apiHandler = Api;

//const TOKEN_INTERVAL = 3550000;

//const SIGNOUT_URL = '/sign-out';

/**
 * Handles base routing logic for all private routes (authenticated user required).
 */
class PrivateRoute extends React.Component {
    /**
     * Default constructor.
     */
    constructor(props) {
        super(props);

        this.state = {
            currentPath: null,
            //sessionTimeoutHandler: null,
            //forceLogoutHandler: null,
            shareEventSent: false,
            subscribe: null,
        };

        //this.logoutUser = this.logoutUser.bind(this);
    }

    /**
     * Gather basic profile at mount.
     */
    componentDidMount() {
        const { getBasicProfileData, isAuthenticated, isBasicProfilePopulated } = this.props;

        if (isAuthenticated && !isBasicProfilePopulated) {
            const shareToken = store.get(USER_SHARE_TOKEN);

            getBasicProfileData(shareToken);

            if (shareToken) {
                store.remove(USER_SHARE_TOKEN);
            }
        }

        // fix for header logo click
        this.addUrlToHistory();
    }

    /**
     * Watch props for path changes and session timeout values.
     * @param {*} nextProps
     */
    componentWillReceiveProps(nextProps) {
        if (!nextProps.computedMatch || nextProps.computedMatch.url !== this.state.currentPath) {
            //clearTimeout(this.state.sessionTimeoutHandler);

            this.setState({
                currentPath: nextProps.computedMatch ? nextProps.computedMatch.url : null,
            }, () => {
                //this.props.validateUserToken();
            });

            this.addUrlToHistory();
        }

        //if we have a new expiration time, reset the timeout accordingly
        /*if (nextProps.tokenExpirationTime !== null
            && nextProps.tokenExpirationTime !== this.props.tokenExpirationTime) {
            const isKeepSignedIn = store.get(IS_KEEP_SIGNED_IN);

            clearTimeout(this.state.forceLogoutHandler);

            if (isKeepSignedIn) {
                clearInterval(this.state.sessionTimeoutHandler);
            } else {
                clearTimeout(this.state.sessionTimeoutHandler);
            }

            if (isKeepSignedIn) {
                const logoutTime = store.get(LOGOUT_TIME);
                const now = new Date();
                const logoutMiliseconds = logoutTime - now.getTime();

                this.setState({
                    sessionTimeoutHandler: setInterval(() => {
                        apiHandler.refreshToken();
                        //AUTO REFRESH SHOULD NOW BE HANDLED BY API LAYER WE SHOULD REMOVE THIS REFRESH CALL
                    }, TOKEN_INTERVAL),

                    forceLogoutHandler: setTimeout(() => {
                        this.handleLogout();
                    }, logoutMiliseconds > TOKEN_INTERVAL ? TOKEN_INTERVAL : logoutMiliseconds),
                });
            } else {
                this.setState({
                    sessionTimeoutHandler: setTimeout(() => {
                        //console.log('[api call from PR]: going to signout url');
                        console.log('XXDEBUG sign-out from timeout handler');
                        window.location.href = SIGNOUT_URL;
                    }, nextProps.tokenExpirationTime),
                });
            }
        }*/

        if (nextProps.sharingUser !== null && !this.state.shareEventSent) {
            this.setState({
                shareEventSent: true,
            }, () => {
                const idType = nextProps.sharingUser.aeid ? 'AIED' : 'CRD';
                const id = nextProps.sharingUser.aeid ? nextProps.sharingUser.aeid.toString() : nextProps.sharingUser.crd.toString();

                contextHubRecordEvent(SHARE_EVENT_LIST(
                    idType,
                    id,
                ));
            });
        }
    }

    /**
     * Set a final logout handler or continue to poll.
     */
    /*handleLogout() {
        const logoutTime = store.get(LOGOUT_TIME);
        const now = new Date();
        const logoutMiliseconds = logoutTime - now.getTime();

        if (logoutMiliseconds <= TOKEN_INTERVAL) {
            clearTimeout(this.state.forceLogoutHandler);

            this.setState({
                forceLogoutHandler: setTimeout(() => {
                    //console.log('[api call from PR]: going to signout from handleLogout');
                    console.log('XXDEBUG sign-out from handle logout');
                    window.location.href = SIGNOUT_URL;
                }, logoutMiliseconds),
            });
        } else {
            clearTimeout(this.state.forceLogoutHandler);

            this.setState({
                forceLogoutHandler: setTimeout(() => {
                    this.handleLogout();
                }, TOKEN_INTERVAL),
            });
        }
    }*/

    /**
     * Add each page in the flow to the URL history, handled from will recieve props on path changes.
     */
    addUrlToHistory() {
        const BACK_INDICATOR = 'b=1';
        const currentUrl = window.location.pathname + window.location.search;
        const to = currentUrl.includes(BACK_INDICATOR) ? currentUrl
            : currentUrl.indexOf('?') > -1 ? `${currentUrl}&${BACK_INDICATOR}` : `${currentUrl}?${BACK_INDICATOR}` ;

        this.props.addUrlHistoryItem({
            key: '',
            currentUrl: to,
            currentTitle: '',
        });
    }

    /**
     * Log the user out when their session times out under them (due to inactivity).
     */
    /*logoutUser() {
        store.set(REDIRECT_AFTER_LOGIN_URL, window.location.href);

        this.props.onSignOut();
    }*/

    /**
     * Render the underlying components.
     * @param {*} props
     */
    renderComponent(props) {
        const { component: Component, render } = this.props;

        const isNewUser = this.props.isAuthenticated
            ? (this.props.userSources.length === 0 && this.props.userTopics.length === 0) ?
                true : false
            : false;

        if (typeof render === 'function') {
            return render(props);
        }

        return (
            <React.Fragment>
                <Component
                    {...props}
                />
                <Prompt
                    when={isNewUser}
                    message={location => {
                        const blockRoute = location.pathname.startsWith('/news')
                            || location.pathname === "/profile/preferences";

                        return blockRoute ?
                            "webinarPage.confirmationModal"
                            : true;
                    }}
                />
            </React.Fragment>
        );
    }

    /**
     * Render onboarding page if not fully onboarded, else pass through.
     */
    renderIfOnboardedOrSkipped = (props, isOnboarded, skipOnboardedCheck, signUpType, migrationEnumValue) => {
        const redirectToOnboarding = {
            pathname: ROUTES.ONBOARDING,
            state: { from: props.location },
        };

        const redirectToMigration = {
            pathname: ROUTES.MIGRATE,
            state: { from: props.location },
        }

        const skipped = store.get(SKIP_MIGRATION_STORAGE_KEY);

        if ((migrationEnumValue === MIGRATION_ENUM.MUST_MIGRATE
            && window.location.pathname.indexOf(ROUTES.MIGRATE) === -1
            && window.location.pathname.indexOf(ROUTES.TERMS_AND_CONDITIONS) === -1)
            || (migrationEnumValue === MIGRATION_ENUM.READY_TO_MIGRATE
            && window.location.pathname.indexOf(ROUTES.MIGRATE) === -1
            && !skipped)) {
            return (
                <Redirect to={redirectToMigration} />
            );
        }

        return (
            isOnboarded || skipOnboardedCheck || signUpType === "WEBINAR"
                ? this.renderComponent(props)
                : <Redirect to={redirectToOnboarding} />
        );
    };

    /**
     * Render this container and underlying components (or onboarding or sign in as needed).
     */
    render() {
        //const logoutTime = store.get(LOGOUT_TIME);
        //const now = new Date();
        //const logoutMiliseconds = logoutTime - now.getTime();

        /* if a user has closed browser and then come back in a new window, we need to kick them to sign-in if its been more than their allowed session time */
        /*if (logoutMiliseconds < 0 || !logoutTime) {
            store.set(REDIRECT_AFTER_LOGIN_URL, window.location.href);

            console.log('XXDEBUG sign-out from render');
            window.location.href = SIGNOUT_URL;

            return <Loader />;
        }*/

        const {
            migrationEnumValue, signUpType, component, isAuthenticated, isLoading, isOnboarded, skipOnboardedCheck, ...other
        } = this.props;

        if (isLoading && isAuthenticated) { return <Loader />; }

        return (
            <Route
                {...other}
                render={(props) => {
                    const redirectTo = {
                        pathname: ROUTES.SIGN_IN,
                        state: { from: props.location },
                    };

                    if (!isAuthenticated) {
                        store.set(REDIRECT_AFTER_LOGIN_URL, window.location.href);
                    } else {
                        const redirectUrl = store.get(REDIRECT_AFTER_LOGIN_URL);

                        if (redirectUrl) {
                            store.remove(REDIRECT_AFTER_LOGIN_URL);
                            //TODO: rework away from timeouts, rather lets await refinement data response, then forward
                            //BUT thats a big enough flow change we dont want to do it now for this small bug
                            setTimeout(() => {
                                window.location.href = redirectUrl;
                            }, 1000);
                            return null;
                        }
                    }

                    return (
                        isAuthenticated
                            ? this.renderIfOnboardedOrSkipped(
                                props, isOnboarded, skipOnboardedCheck, signUpType, migrationEnumValue,
                            )
                            : <Redirect to={redirectTo} />
                    );
                }}
            />
        );
    }
}

PrivateRoute.propTypes = {
    component: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
    isAuthenticated: PropTypes.bool.isRequired,
    isBasicProfilePopulated: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    getBasicProfileData: PropTypes.func.isRequired,
    render: PropTypes.func,
    isOnboarded: PropTypes.bool,
    tokenExpirationTime: PropTypes.number,
    validateUserToken: PropTypes.func,
    onSignOut: PropTypes.func,
    migrationEnumValue: PropTypes.string,
};

PrivateRoute.defaultProps = {
    component: null,
    render: null,
    isOnboarded: false,
    tokenExpirationTime: false,
    migrationEnumValue: null,
};

const mapStateToProps = state => ({
    isAuthenticated: state.signIn.isAuthenticated,
    signUpType: state.basicProfile.signUpType,
    isBasicProfilePopulated: state.basicProfile.isPopulated,
    isLoading: state.basicProfile.isLoading,
    isOnboarded: state.basicProfile.onboarded,
    tokenExpirationTime: state.basicProfile.tokenExpiration,
    userSources: state.basicProfile.sourceIds,
    userTopics: state.basicProfile.topicIds,
    sharingUser: state.basicProfile.sharerId,
    migrationEnumValue: state.basicProfile.notification.data.migrationState,
});

const mapDispatchToProps = dispatch => ({
    getBasicProfileData: (shareId) => dispatch(getBasicProfile(shareId)),
    validateUserToken: () => dispatch(validateToken()),
    onSignOut: () => dispatch(signOut('/sign-in')),
    addUrlHistoryItem: (value) => dispatch(addUrlHistoryItem(value)),
    initDefaultUrlHistoryStore: (value) => dispatch(initDefaultUrlHistoryStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
