import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Link from 'components/atomics/atoms/Link/Link';
import Button from 'components/atomics/atoms/Button/Button';
import {
    TYPE_PRIMARY_MEDIUM,
} from 'components/atomics/atoms/Button/Button';
import { MORE_GUIDES_ROUTE } from 'state-management/constants/guide';
import { IconSeeMore } from 'components/atomics/atoms/Icons/Icons';
import {
    Container,
} from 'components';
import {
    headerStyles,
    linkStyles,
    linkContainerStyles,
    buttonContainerStyles,
    guideCardStripStyles,
    loaderStyles,
    blankAreaStyles,
    skinnyListContainer,
} from './styles';
import SkinnyListGuideCard from '../../../components/atomics/molecules/SkinnyListGuideCard/SkinnyListGuideCard';
import GuideCardCollectionOfFour from '../../../components/atomics/organisms/GuideCardCollectionOfFour/GuideCardCollectionOfFour';
import { StyledLoader, defaultOptions } from 'components/Loader/Loader';
import anime from 'animejs';
import { GLOBAL } from 'utils/variables';
import {
    BreakpointsContext,
    DESKTOP,
    MOBILE,
    TABLET,
} from 'components/Breakpoints/Breakpoints';
import language from '../../../lang/en.json';

const GUIDES_PAGE = "Guides";
const COLLECTION_PAGE = "Collection";

const SkinnyList = styled.div`
    ${skinnyListContainer}
`;

const StyledComponent = styled.div`
`;

const StyledLoaderContainer = styled.div`
    ${loaderStyles}
`;

const StyledBlankArea = styled.div`
    ${blankAreaStyles}
`;

const StyledHeader = styled.div`
    ${headerStyles}
`;

const StyledLink = styled(Link)`
    ${linkStyles}
`;

const LinkContainer = styled.div`
    ${linkContainerStyles}
`;

const ButtonContainer = styled.div`
    ${buttonContainerStyles};
`;

const GuideCardStrip = styled.div`
    ${guideCardStripStyles}
`;

/**
 * Render the guides area of the PM landing page.
 */
class NewGuides extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.animateClosed = this.animateClosed.bind(this);

        this.contentRef = React.createRef();
    }

    /**
     * Run the components close animation if the data failed to load.
     * @param {*} nextProps
     */
    componentWillUpdate(nextProps) {
        if (nextProps.loadingFailed === true) {
            this.animateClosed();
        }
    }

    /**
     * Collapse this component closed (and opacity to 0) from bottom to top.
     */
    animateClosed() {
        const contentNode = this.contentRef.current;

        this.animation = anime.timeline();

        this.animation
            .add({
                targets: contentNode,
                easing: [0.53, 0.05, 0.01, 0.97],
                opacity: [1, 0],
                duration: GLOBAL.ANIMATIONS.COLLAPSE_TIME.MILISECONDS,
                height: 0,
            });

        this.props.handleComponentFailure();
    }

    /**
     * Render the section header.
     */
    renderHeader = () => {
        return (
            <div>
                {
                    this.props.parentPage !== COLLECTION_PAGE &&
                    <Container>
                        <StyledHeader>
                            {this.props.guidesHeader}
                        </StyledHeader>
                    </Container>
                }
                {
                    this.props.parentPage === COLLECTION_PAGE &&
                    <StyledHeader>
                        <div />
                        <div>
                            {this.props.guidesHeader}
                        </div>
                    </StyledHeader>
                }
            </div>
        )
    }

    /**
     * Render the guides section buttons.
     */
    renderButtons = () => {
        return (
            <div>
                {this.props.parentPage == GUIDES_PAGE &&
                    <BreakpointsContext.Consumer>
                        {(breakpoint) => {
                            if (breakpoint === MOBILE) {
                                return (
                                    <ButtonContainer>
                                        <Button
                                            type={TYPE_PRIMARY_MEDIUM}
                                            to={`${MORE_GUIDES_ROUTE}`}
                                            data-analytics-placement="Anchor : body"
                                            data-analytics-label="trackLink : anchor"
                                            data-analytics-id={language.translations["guides.more"]}
                                        >
                                            <FormattedMessage id="guides.more" />
                                        </Button>
                                    </ButtonContainer>
                                );
                            } else {
                                return (
                                    <LinkContainer>
                                        <StyledLink
                                            to={`${MORE_GUIDES_ROUTE}`}
                                            data-analytics-placement="Anchor : body"
                                            data-analytics-label="trackLink : anchor"
                                            data-analytics-id={language.translations["guides.more"]}
                                        >   <FormattedMessage id="guides.more" />
                                            <IconSeeMore />
                                        </StyledLink>
                                    </LinkContainer>
                                );
                            }
                        }}
                    </BreakpointsContext.Consumer>
                }
            </div>
        )
    }

    /**
     * Render the strip/carousel of guides for this section.
     */
    renderGuidesStrip() {
        return (
            <BreakpointsContext.Consumer>
                {(breakpoint) => {
                    if (breakpoint === TABLET) {
                        return (
                            <div>
                                <GuideCardStrip>
                                    <GuideCardCollectionOfFour
                                        guideCategories={this.props.guideListData.slice(0, 3)}
                                        webinarData={this.props.webinarData}
                                    />
                                </GuideCardStrip>
                                {this.props.parentPage == COLLECTION_PAGE
                                    && <div>
                                        <GuideCardStrip>
                                            <GuideCardCollectionOfFour
                                                guideCategories={this.props.guideListData.slice(3, 6)}
                                                webinarData={this.props.webinarData}
                                            />
                                        </GuideCardStrip>
                                        {this.props.guideListData.length > 6
                                            && <GuideCardStrip>
                                                <GuideCardCollectionOfFour
                                                    guideCategories={this.props.guideListData.slice(6, 9)}
                                                    webinarData={this.props.webinarData}
                                                />
                                            </GuideCardStrip>}
                                        {this.props.guideListData.length > 9
                                            && <GuideCardStrip>
                                                <GuideCardCollectionOfFour
                                                    guideCategories={this.props.guideListData.slice(9, 12)}
                                                    webinarData={this.props.webinarData}
                                                />
                                            </GuideCardStrip>}
                                        {this.props.guideListData.length > 12
                                            && <GuideCardStrip>
                                                <GuideCardCollectionOfFour
                                                    guideCategories={this.props.guideListData.slice(12, 15)}
                                                    webinarData={this.props.webinarData}
                                                />
                                            </GuideCardStrip>}
                                    </div>}
                            </div>
                        )
                    }
                    if (breakpoint === DESKTOP) {
                        return (
                            <div>
                                <GuideCardStrip>
                                    <GuideCardCollectionOfFour
                                        guideCategories={this.props.guideListData.slice(0, 4)}
                                        webinarData={this.props.webinarData}

                                    />
                                </GuideCardStrip>
                                {this.props.guideListData.length > 4
                                    && <GuideCardStrip>
                                        <GuideCardCollectionOfFour
                                            guideCategories={this.props.guideListData.slice(4, 8)}
                                            webinarData={this.props.webinarData}
                                        />
                                    </GuideCardStrip>}
                                {this.props.guideListData.length > 8
                                    && <GuideCardStrip>
                                        <GuideCardCollectionOfFour
                                            guideCategories={this.props.guideListData.slice(8, 12)}
                                            webinarData={this.props.webinarData}
                                        />
                                    </GuideCardStrip>}
                                {this.props.guideListData.length > 12
                                    && <GuideCardStrip>
                                        <GuideCardCollectionOfFour
                                            guideCategories={this.props.guideListData.slice(12, 16)}
                                            webinarData={this.props.webinarData}
                                        />
                                    </GuideCardStrip>}
                            </div>
                        )
                    }
                    if (breakpoint === MOBILE) {

                        return (
                            <div>


                                {this.props.guideListData.length <= 5
                                    && <GuideCardCollectionOfFour
                                        guideCategories={this.props.guideListData}
                                        webinarData={this.props.webinarData}
                                    />}
                                {this.props.guideListData.length > 5
                                    && <SkinnyList>
                                        {this.props.guideListData.map(this.renderGuideListCard)}

                                    </SkinnyList>}
                            </div>
                        )
                    }
                }
                }
            </BreakpointsContext.Consumer>
        );
    }

    /**
     * Render a single skinny list guide card, for mobile view with more than 5 guides.
     * @param {*} guide 
     */
    renderGuideListCard(guide) {

        return (
            <SkinnyListGuideCard
                article={guide}
            />
        );


    }

    /**
     * Render this and underlying components.
     */
    render() {
        return (
            <StyledComponent ref={this.contentRef}>
                {this.renderHeader()}
                <Container noMobilePadding={true}>
                    {(!this.props.guideListData || this.props.guideListData.length < 1)
                        && <StyledBlankArea>
                            <StyledLoaderContainer>
                                <StyledLoader
                                    options={defaultOptions}
                                />
                            </StyledLoaderContainer>
                        </StyledBlankArea>}
                    {this.props.guideListData && this.props.guideListData.length > 0 && this.renderGuidesStrip()}
                    {this.props.guideListData && this.props.guideListData.length > 0 && this.renderButtons()}
                </Container>
            </StyledComponent>
        );
    }
}

NewGuides.propTypes = {
    guideListData: PropTypes.array,
    guidesHeader: PropTypes.string,
    parentPage: PropTypes.string.isRequired,
    loadingFailed: PropTypes.bool,
    handleComponentFailure: PropTypes.func,
};

NewGuides.defaultProps = {
    loadingFailed: false,
    handleComponentFailure: () => { },
};

export default NewGuides;
