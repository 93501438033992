import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Prompt } from 'react-router-dom';
import SkinnyListContentCard from '../../../components/atomics/molecules/SkinnyListContentCard/SkinnyListContentCard';
import SkinnyListToolCard from '../../../components/atomics/molecules/SkinnyListToolCard/SkinnyListToolCard';
import SkinnyListGuideCard from 'components/atomics/molecules/SkinnyListGuideCard/SkinnyListGuideCard';
import {
    skinnyListContentCardContainerStyles,
    clickContainerStyles,
} from 'containers/Search/View/styles';
import { showPreferencesModal } from 'state-management/actions/basicProfile';
import { sendClickOnTools } from 'state-management/actions/tools';
import { transformContentItem } from "utils/contentCardUtils";

const SkinnyListContentCardContainer = styled.div`
    ${skinnyListContentCardContainerStyles}
`;

const ClickContainer = styled.a`
    ${clickContainerStyles}
`;

const TYPE_TOOL = "TOOL";
const TYPE_ARTICLE = "ARTICLE";

/**
 * Wraps a single search result itm.
 */
class ItemView extends React.Component {
    /**
     * Default constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            savedNewsId: null,
        };

        this.showModalOnClick = this.showModalOnClick.bind(this);
    }

    /**
     * Show modal on attempt to click search items (for new users, not fully onboarded).
     */
    showModalOnClick() {
        this.props.showPreferencesModalAction();
    }

    /**
     * Handle save/unsave clicks.
     */
    onSaveToggleClick = (item) => {
        const { isSavePending } = this.props;
        const { savedNewsId } = this.state;

        if (isSavePending && !savedNewsId) {
            this.setState({ savedNewsId: item.id });
        }
    };

    /**
     * Render this and the underlying card.
     */
    render = () => {
        const { item, idx, pageItems, isAuthenticated, userSources, userTopics } = this.props;
        const isLastCard = (pageItems != null && (idx + 1) === pageItems.length) ? true : false;

        const newItem = transformContentItem(item);

        const isNewUser = isAuthenticated
                ? (userSources.length === 0 && userTopics.length === 0) ?
                    true : false
                : false;

        if (item.type === TYPE_TOOL) {
            return (
                <SkinnyListContentCardContainer>
                    <SkinnyListToolCard
                        article={newItem}
                        isLastCard={isLastCard}
                        alternativeBackground='WHITE'
                        shouldAllowClickArticleTracking={true}
                        sendClickOnTools={this.props.sendClickOnTools}
                    />
                </SkinnyListContentCardContainer>
            );
        } else if (item.type === TYPE_ARTICLE) {
            return (
                <SkinnyListContentCardContainer>
                    {(isNewUser)
                    && <React.Fragment>
                            <ClickContainer
                                href="#_"
                                onClick={this.showModalOnClick}
                            />
                            <Prompt
                                when={this.props.showPreferencesModal}
                                message="webinarPage.confirmationModal"
                            />
                    </React.Fragment>}
                    <SkinnyListContentCard
                        article={newItem}
                        isLastCard={isLastCard}
                        alternativeBackground='WHITE'
                        shouldAllowClickArticleTracking={true}
                    />
                </SkinnyListContentCardContainer>
            );
        }

        return (
            <SkinnyListContentCardContainer>
                <SkinnyListGuideCard
                    article={newItem}
                    isLastCard={isLastCard}
                    alternativeBackground='WHITE'
                    shouldAllowClickArticleTracking={true}
                />
            </SkinnyListContentCardContainer>
        );
    };
}

ItemView.propTypes = {
    item: PropTypes.shape({
        category: PropTypes.string,
        id: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        contentId: PropTypes.string,
        contentType: PropTypes.string,
        interactionType: PropTypes.string,
        interactionId: PropTypes.string,
        image: PropTypes.string,
        internal: PropTypes.bool,
        isSaving: PropTypes.bool,
        link: PropTypes.string,
        publishDate: PropTypes.string,
        saved: PropTypes.bool,
        sourceLogo: PropTypes.string,
        sourceTitle: PropTypes.string,
        title: PropTypes.string,
        topics: PropTypes.array,
        type: PropTypes.string,
        visited: PropTypes.bool,
    }).isRequired,
    idx: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    pageItems: PropTypes.array,
};


const mapStateToProps = state => ({
    isAuthenticated: state.signIn.isAuthenticated,
    userSources: state.basicProfile.sourceIds,
    userTopics: state.basicProfile.topicIds,
    showPreferencesModal: state.basicProfile.showPreferencesModal,
});

const mapDispatchToProps = dispatch => ({
    showPreferencesModalAction: () => dispatch(showPreferencesModal()),
    sendClickOnTools: (id) => dispatch(sendClickOnTools(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemView);
