import { css } from 'styled-components';
import {
    RGB_COLORS,
    COLORS,
    FONTS,
    ANIMATIONS,
} from 'utils/variables';
import {
    SMALL_MOBILE,
    FROM_DESKTOP,
} from 'utils/mediaQueries';
import { FROM_TABLET } from '../../../../utils/mediaQueries';

export const kebabAndSourceContainerStyles = css`
    display: flex;
    justify-content: space-between;

    *[data-rel="kebabOpenPanel"] {
        bottom: -7px;

        @media screen and ${FROM_DESKTOP} {
            bottom: -9px;
        }
    }
`;

export const titleStyles = css`
    color: ${COLORS.BLACK};
    flex: 1;
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.1px;
    line-height: 20px;
    margin: 0;
    margin-bottom: 10px;
    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.TITLE.TIME.SECONDS}s all;

    &:hover {
        color: ${COLORS.MEDIUM_GRAY2};
        opacity:0.6;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 20px;
        line-height: 24px;
    }
`;

export const containerStyles = css`
    background: transparent;
    position: relative;

    &.LIGHT_GRAY1 {
        background: ${COLORS.LIGHT_GRAY1};
    }
    border-bottom: 1px solid ${COLORS.LIGHT_GRAY2};
    color: ${COLORS.BLACK};
    display: flex;
    padding: 24px 0 21px 0;
    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.CONTAINER.TIME.SECONDS}s all;

    &:hover {
        img {
            transform: scale3d(1.05,1.05,1);
        }
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 23px 0 24px 0;
    }

    &.tools-not-visited{
        opacity:1;
    }

    &.tools-visited{
        opacity:0.6;
        color: rgba(${RGB_COLORS.BLACK}, 0.6) !important;
        .ToolsContentCard__Title-gvTAmA{
            opacity:0.6;
        }
    }
`;

export const dateAndTopicContainerStyles = css`
    display: block;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
    text-transform: uppercase;
    text-align: right;

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 15px;
    }
`;

export const dateContainerStyles = css`
    color: ${COLORS.DARK_GRAY1};
    white-space: nowrap;
    font-size: 12px;
    text-align: right;
    display: initial;
`;

export const topicLinkStyles = css`
    color: ${COLORS.SAPPHIRE};
    flex: 1;
    text-align: right;
    text-decoration: none;
    transition: 0.33s all;
    word-break: break-all;

    &:hover {
        opacity: 0.6;
    }
`;

export const imageContainerStyles = css`
    height: 61px;
    overflow: hidden;
    width: 61px;

    @media screen and ${FROM_TABLET} {
        height: 129px;
        width: 129px;
    }

    img {
        height: 100%;
        object-fit: cover;
        transition: 0.33s all;
        width: 100%;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            width: auto;
        }
    }
`;

export const contentContainerStyles = css`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 23px;
    width: calc(100% - 84px);
    min-width: 213px;

    /* why are custom breakpoints here?? they should NOT be */
    @media screen  and (min-width: 414px) {
        min-width: 260px;
    }

    @media screen  and (min-width: 414px) {
        min-width: 300px;
    }
    /* /END why are custom breakpoints here?? they should NOT be */

    @media screen and ${FROM_TABLET} {
        min-width: 540px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding-left: 30px;
        width: calc(100% - 159px);
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        min-height: 110px;
    }
`;


export const sourceContentContainerStyles = css `
    font-weight: 700;
    font-size: 12px;
    color: ${COLORS.SAPPHIRE};
`;

export const iconStyles = css`
    fill: ${COLORS.WHITE};
    margin: 0;
    width: 129px;
    height: 129px;
    display: none;
    @media screen and ${FROM_TABLET} {
        display: block;
    }

    .active & {
        /* stylelint-disable */
        ${props => props.theme === 'light' && css`
            fill: ${COLORS.SAPPHIRE};
        `};

        ${props => props.theme === 'dark' && css`
            fill: ${COLORS.WHITE};
        `};
        /* stylelint-enable */
    }
`;
export const iconMobileStyles= css`
    fill: ${COLORS.WHITE};
    margin: 0;
    width: 61px;
    height: 61px;
    display: block;

    @media screen and ${FROM_TABLET} {
        display: none;
    }
`;


export const sourceTagStyles = css`
`;

export const sourceLinkStyles = ({ variant, type, standarddisplay }) => css`
    color: ${COLORS.SAPPHIRE};
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
    transition: 0.33s all;

    ${type === "list" && css`
       color: ${COLORS.SAPPHIRE};
    `}

    &:hover {
        opacity: 0.6;
    }

    @media screen and ${FROM_TABLET} {
        font-size: 10px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.4;
        letter-spacing: 0.1px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 12px;
    }
`;

export const poweredByStyles = ({ variant, type, standarddisplay }) => css`
    color: ${COLORS.DARK_GRAY1};
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;

    @media screen and ${FROM_TABLET} {
        font-size: 10px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
    }
`;
