import { css } from 'styled-components';
import { getSpanInPercent } from 'utils/grid';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS } from 'utils/variables';

export const note = css`
    background-color: ${COLORS.WHITE};
    padding-top: 24px;
    padding-bottom: 24px;
    text-align: center;
    width: 100%;
    margin-top: 40px;
`;

export const noteText = css`
    max-width: 80%;
    margin: auto;
    text-align: center;
    margin-bottom: 10px;

    @media screen and ${FROM_TABLET} {
        max-width: 520px;
    }
`;

export const disclaimer = css`
    font-size: 12px;
    margin-bottom: 30px;
    margin: auto;
    text-align: center;
`;

export const title = css`
    font-size: 28px;
    color: ${COLORS.SAPPHIRE};
    font-weight: 300;
    text-align: center;
    max-width: 500px;
    margin: auto;
    line-height: 32px;

    @media screen and ${FROM_TABLET} {
        font-size: 38px;
        line-height: 42px;
    }
`;

export const buttons = css`
    padding: 20px 0 20px 0;
    text-align: center;
    margin: auto;

    a {
        display: inline-block;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 20px;
    }
`;

export const description = css`
    font-size: 14px;
    max-width: 520px;
    margin: auto;
    text-align: center;
    padding-top: 16px;
    
    @media screen and ${FROM_TABLET} {
        font-size: 16px;
    }
`;

export const buttons404 = css`
    text-align: center;
    display: flex;
    flex-direction: column;

    a {
        margin-top: 16px;
    }

    @media screen and ${FROM_TABLET} {
        display: block;
        
        a {
            margin-top: 0;
        }

        a:first-child {
            margin-right: 16px;
        }
    }
`;

export const sectionStyles = css`
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    padding-top: 48px;

    @media screen and ${FROM_TABLET} {
        padding-top: 80px;
    }
`;

export const containerStyles = css`
    box-sizing: border-box;
    padding: 0 30px;
    width: 100%;

    @media screen and ${FROM_TABLET} {
        padding: 0 38px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 0 48px;
    }
`;

export const headingStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.4px;
    line-height: 1.2;
    margin: 0;
    text-align: center;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        font-size: 36px;
        letter-spacing: -0.6px;
        line-height: 1.39;
    }
`;

export const errorCodeStyles = css`
    color: ${COLORS.MEDIUM_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    margin: 10px 0 0;
    text-align: center;
    text-transform: uppercase;
    word-wrap: break-word;
`;

export const descriptionStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 18px;
    line-height: 1.28;
    margin: 30px auto 0;
    text-align: center;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        font-size: 30px;
        letter-spacing: -0.6px;
        line-height: 1.4;
        width: ${getSpanInPercent(7)};
    }
`;

export const actionsWrapperStyles = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media screen and ${FROM_TABLET} {
        margin-top: 26px;
    }
`;

export const reloadButtonStyles = css`
    margin-top: 20px;
    min-width: 146px;
    padding: 0;

    @media screen and ${FROM_TABLET} {
        font-size: 19px;
        letter-spacing: 0;
        margin-top: 0;
        min-width: 200px;
        height: 60px;
    }
`;

export const contactButtonStyles = css`
    line-height: 34px;
    margin: 24px 5px 0;
    min-width: 146px;
    padding: 0;

    @media screen and ${FROM_TABLET} {
        line-height: 40px;
        margin-top: 0;
        min-width: 200px;
    }
`;

export const backButtonStyles = css`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 24px 5px 0;
    min-width: 146px;
    padding: 0;

    @media screen and ${FROM_TABLET} {
        margin-top: 0;
        min-width: 200px;
    }
`;

export const contactContainerStyles = css`
    color: ${COLORS.MEDIUM_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 12px;
    margin: 18px 0 0;
    text-align: center;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        font-size: 13px;
    }
`;

export const underlinedLinkStyles = css`
    color: ${COLORS.MEDIUM_GRAY2};
`;
