import {
    SEND_FORGOT_USERNAME_EMAIL,
    SEND_FORGOT_USERNAME_EMAIL_SUCCESS,
    SEND_FORGOT_USERNAME_EMAIL_ERROR,
    RESET_FORGOT_USERNAME_EMAIL,
} from 'state-management/constants/forgotUsername';

export const sendForgotUsernameEmail = email => ({
    type: SEND_FORGOT_USERNAME_EMAIL,
    email,
});

export const sendForgotUsernameEmailSuccess = email => ({
    type: SEND_FORGOT_USERNAME_EMAIL_SUCCESS,
    email,
});

export const sendForgotUsernameEmailError = error => ({
    type: SEND_FORGOT_USERNAME_EMAIL_ERROR,
    error,
});

export const resetForgotUsernameEmail = () => ({
    type: RESET_FORGOT_USERNAME_EMAIL,
});

