import { css } from 'styled-components';
import {
    FROM_DESKTOP,
    FROM_TABLET,
} from '../../utils/mediaQueries';
import {
    COLORS,
    FONTS,
} from '../../utils/variables';

export const footerStyles = css`
    background-color: ${COLORS.LIGHT_GRAY2};
`;

export const containerStyles = css`
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: ${FONTS.AVENIR_NEXT.FONT}, Arial, Sans-Serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    color: ${COLORS.DARK_GRAY1};

    &>div {
        border-bottom: 1px solid ${COLORS.MEDIUM_GRAY1};
        padding-top: 28px;
        padding-bottom: 28px;
    }

    
    /* app store container */
    &>div:first-child {
        text-align: left;

        &>div {
            display: flex;
            width: 100%;

            &>div:first-child {
              font-family: AvenirNext;
              font-size: 15px;
              font-weight: 600;
              color: var(--darkGray1);
              padding-right: 16px;
              align-self: center;
            }

            a {
                flex: 1;
                text-align: right;
                position: relative;
                top: -8px;
            }

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                a {
                    flex: auto;
                }
            }
        }
    }

    /* share container */
    &>div:last-child {
        padding-left: 0;
        width: 100%;
        text-align: left;

        &>div {
            display: flex;

            &>div:first-child {
              font-family: AvenirNext;
              font-size: 15px;
              font-weight: 600;
              color: var(--darkGray1);
              padding-right: 16px;
              align-self: center;
            }

            &>div:last-child {
                flex: 1;
                text-align: right;

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    flex: auto;
                }

                &>a {
                    margin-bottom: 0;
                }
            }
        }
    }

    @media screen and ${FROM_TABLET} {
        flex-direction: row;
        text-align: left;

        /* app store container */
        &>div:first-child {
            border-right: 1px solid ${COLORS.MEDIUM_GRAY1};
            padding-right: 28px;
            width: calc(50% - 29px);

            &>div {
                display: flex;
                width: 100%;

                &>div:first-child {
                    max-width: 200px;
                    padding-right: 0;
                }

                a {
                    flex: 1;
                    text-align: right;
                    position: relative;
                    top: -8px;
                }
            }
        }

        /* share container */
        &>div:last-child {
            padding-left: 28px;
            width: calc(50% - 28px);

            &>div {
                display: flex;
                height: 100%;

                &>div:first-child {
                    max-width: 180px;
                    padding-right: 0;
                }

                &>div:last-child {
                    flex: 1;
                    align-self: center;
                    text-align: right;
                }
            }
        }
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active), ${FROM_TABLET} {
        /* app store container */
        &>div:first-child {
            &>div {
                a {
                    flex: auto;
                }
            }
        }
    }

    @media screen and ${FROM_DESKTOP} {
        text-align: center;

        &>div {
            width: 50%;
            padding-left: 0;
            padding-right: 0;
        }

        /* app store container */
        &>div:first-child {
            width: calc(50% - 1px);
            display: flex;

            &>div {
                display: flex;
                margin: auto;
                position: relative;
                top: 10px;
                width: auto;

                ${props => props.isDesktop && css`
                    top: 0;
                `};

                &>div:first-child {
                    max-width: 212px;
                }

                a {
                    display: block;
                    position: relative;
                    top: -20px;
                    text-align: center;
                    flex: initial;
                    padding-left: 20px;
                    min-width: 120px;
                }

                ${props => props.isDesktop && css`
                    a {
                        top: -6px;
                    }

                    a:last-child {
                        padding-left: 0;
                    }
                `};
            }
        }

        /* share container */
        &>div:last-child {
            display: flex;
            text-align: center;
            width: 50%;

            &>div {
                display: flex;
                margin: auto;

                &>div:first-child {
                  width: 309px;
                  height: 24px;
                  font-family: AvenirNext;
                  font-size: 15px;
                  font-weight: 600;
                  color: var(--darkGray1);
                  padding-right: 20px;
                  max-width: inherit;
                }

                &>div:last-child {
                    flex: inherit;
                }
            }
        }
    }

    @media all and (-ms-high-contrast: none) and ${FROM_DESKTOP}, (-ms-high-contrast: active) and ${FROM_DESKTOP} {
        /* app store container */
        &>div:first-child {
            &>div {
                /*position: relative;*/
                /*left: -26%;*/

                &>div:first-child {
                    max-width: 194px;
                }

                a {
                    flex: auto;
                }
            }
        }
    }
`;

export const AppStoreLogoStyles = css`
    position: relative;
    top: 14px;
    width: 106px;
    height: 34px;
`;

export const playLogoStyles = css`
`;

export const logoContainerStyles = css`
    display: flex;
    flex: 1;
    flex-direction: column;

    @media screen and ${FROM_DESKTOP} {
        flex-direction: row;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        flex: auto;
    }
`;
