import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleSaveArticle, toggleSaveGuide as toggleSaveGuideAction, toggleSaveCecredit } from "../../../../state-management/actions/save";
import { toggleTopicFollow } from "../../../../state-management/actions/followTopic";
import { toggleSourceFollow } from "../../../../state-management/actions/sources";
import { visitArticle, getVisitPracticeLab } from 'state-management/actions/articles.common';
import { visitCeCredit } from 'state-management/actions/guide';
import { handleGuideVisit } from 'state-management/actions/guide';
import { contextHubRecordEvent } from 'utils/contextHub';
import { CARDS_EVENT_LIST } from 'utils/contextHubEventListConstants';
import { dismissCoachMark } from "../../../../state-management/actions/coachMark";

/**
 * Maps the HOC functions dispatch needs.
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
    onArticleClick: article => dispatch(visitArticle(article)),
    onGuideClick: id => dispatch(handleGuideVisit(id)),
    onCeCreditClick: id => dispatch(visitCeCredit(id)),
    toggleSaveArticle: (id, save, title, getContentAfterSave, article) => dispatch(toggleSaveArticle(id, save, {
        title,
        getContentAfterSave,
        article,
    })),
    toggleSaveGuide: (id, save, title, shouldShowToast) => dispatch(toggleSaveGuideAction(id, save, {
        title,
        shouldShowToast,
    })),
    toggleFollowTopic: (topicId, isFollowing) => dispatch(toggleTopicFollow(topicId, isFollowing)),
    toggleFollowSource: (sourceId, isFollowing) => (
        dispatch(toggleSourceFollow(sourceId, isFollowing))
    ),
    toggleSaveCecredit: (id, save, title, getContentAfterSave, article) => dispatch(toggleSaveCecredit(id, save, {
        title,
        getContentAfterSave,
        article,
    })),
    onCoachMarkDismiss: () => {
        dispatch(dismissCoachMark('KEBAB', true));
    },
});

/**
 * Maps the HOC functions redux state needs.
 * @param {*} state 
 */
const mapState = state => ({
    isLoading: state.followTopic.toggleInProgress,
    profile: state.basicProfile,
    isActiveCoachMark: state.coachMark.display.KEBAB,
});

/**
 * HOC function to give common eventing and props across skinny list cards.
 * @param {*} WrappedComponent 
 * @param {*} selectData 
 */
export function withCommonCard(WrappedComponent, selectData) {
    class CardClass extends React.Component {
        /**
         * Default constructor.
         */
        constructor(props) {
            super(props);

            this.state = {
                onCardHover: false
            };

            this.recordClickAnalytics = this.recordClickAnalytics.bind(this);
            this.onArticleClick = this.onArticleClick.bind(this);
        }

        /**
         * Handle skinny list card clicks.
         */
        onArticleClick() {
            const { onArticleClick, onCeCreditClick } = this.props;
            const { type } = this.props.article;

            if (this.props.onArticleClick) {
                this.props.onArticleClick();
            }

            if (type === 'ARTICLE') {
                onArticleClick(this.props.article);
            } else if (type === 'CECREDIT') {
                onCeCreditClick(this.props.article.id);
            }
        }

        /**
         * Set hover state.
         */
        onMouseEnter = () => {
            this.setState({ onCardHover: true });
        }

        /**
         * Remove hover state.
         */
        onMouseLeave = () => {
            this.setState({ onCardHover: false });
        }

        /**
         * Record skinny list card click events.
         * @param {*} type 
         */
        recordClickAnalytics(type = 'article') {
            contextHubRecordEvent(CARDS_EVENT_LIST(
                type,
                this.props.article.title,
                this.props.article.topicName,
                this.props.article.sourceName
            ));
        }

        /**
         * Render wrapped component version of the passed in component.
         */
        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    onCardHover={this.state.onCardHover}
                    onMouseLeave={this.onMouseLeave}
                    onMouseEnter={this.onMouseEnter}
                    recordClickAnalytics={this.recordClickAnalytics}
                    onArticleClick={this.onArticleClick}
                />
            );
        }
    };

    CardClass.propTypes = {
        article: PropTypes.shape({
            type: PropTypes.string,
            date: PropTypes.string.isRequired,
            topicName: PropTypes.string.isRequired,
            topicId: PropTypes.number,
            title: PropTypes.string.isRequired,
            image: PropTypes.string,
            sourceName: PropTypes.string,
            sourceId: PropTypes.number,
            provider: PropTypes.string,
            publishDate: PropTypes.string,
            id: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string,
            ]),
            url: PropTypes.string,
            link: PropTypes.string,
            saved: PropTypes.bool,
            licensed: PropTypes.bool,
            contentType: PropTypes.string,
            interactionId: PropTypes.string,
            interactionType: PropTypes.string,
            topics: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.string,
                ]).isRequired,
                followed: PropTypes.bool.isRequired,
                name: PropTypes.string.isRequired,
            })),
            visited: PropTypes.bool
        }).isRequired,
        isLastCard: PropTypes.bool,
        alternativeBackground: PropTypes.string,
        handleKebabOpenOutside: PropTypes.func,
        onArticleClick: PropTypes.func,
        handleSaveArticleKebabClick: PropTypes.func,
        shouldShowToast: PropTypes.bool,
        onUnsave: PropTypes.func,
        simpleList: PropTypes.bool,
        showCoachMark: PropTypes.bool,
        activateDismissCoachMark: PropTypes.bool,
        showKebab: PropTypes.bool,
        forceKebabOpen: PropTypes.bool,
        onKebabClose: PropTypes.func,
    };

    CardClass.defaultProps = {
        isLastCard: false,
        alternativeBackground: 'white',
        shouldShowToast: false,
        handleKebabOpenOutside: () => { },
        topics: [],
        onArticleClick: null,
        onUnsave: null,
        simpleList: false,
        showCoachMark: false,
        activateDismissCoachMark: false,
        showKebab: false,
        forceKebabOpen: false,
        onKebabClose: null,
    }

    return connect(mapState, mapDispatchToProps)(CardClass);
}
