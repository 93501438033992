import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { xor, isEqual } from 'lodash';
import { SOURCES_PAGE_SIZE } from 'containers/Profile/Preferences/constants';
import { RequestSource, UnderlinedCheckbox } from 'components';
import DropDown from 'components/atomics/molecules/Dropdown/Dropdown';
import {
    checkboxItemStyles,
} from 'containers/Profile/Preferences/styles';
import {
    tabPaneStyles,
    sourcesGrid,
    dropDownWrapperStyles,
    sortAndFiltersContainerStyles,
} from 'containers/Profile/Preferences/SourcesTabPane/styles';
import language from '../../../../lang/en.json';
import { lang } from 'moment';

export const SourcesGrid = styled.div`
    ${sourcesGrid}
`;

const StyledTabPane = styled.div`
    ${tabPaneStyles}
`;

export const StyledSourceCheckbox = styled(UnderlinedCheckbox)`
    ${checkboxItemStyles}
`;

export const SortAndFilterContainer = styled.div`
    ${sortAndFiltersContainerStyles}
`;

export const DropDownWrapper = styled.div`
    ${dropDownWrapperStyles};
`;

export const sortValues = [
    {
        key: 0,
        value: language.translations['practiceManagement.guidesAndFeatures.sort.ascending'],
    },
    {
        key: 1,
        value: language.translations['practiceManagement.guidesAndFeatures.sort.preferred'],
    },
];

/**
 * Source tab for account preferences.
 */
class SourcesTabPane extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.state = {
            content: null,
            page: 1,
            sortId: 0, // A-Z default ordering
        };

        this.onSortChange = this.onSortChange.bind(this);
    }

    firstPageItemRef = React.createRef();

    /**
    * Stuff to do on mount.
    */
    componentWillMount() {
        const { sources } = this.props;

        if (sources && sources.length) {
            this.sortDataAndSetState();
        }
        this.props.onEditDefaultClick()
    }
    componentWillReceiveProps(nextProps) {
        if (!nextProps.isInEditMode)
            this.props.onEditDefaultClick()
    }

    /**
     * Stuff to do when component updates.
     * @param {*} prevProps
     */
    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.sources, this.props.sources)) {
            this.onPageChange(1);
        }
    }

    /**
     * Stuff to do on sorting type change.
     * @param {*} val
     */
    setSort(val) {
        this.setState({
            sortId: val,
        });
    }

    /**
     * Sort the data and set it to the state for display.
     */
    sortDataAndSetState() {
        const { sources } = this.props;
        const sortedData = sources.slice();

        switch (this.state.sortId) {
            /* A-Z */
            case 0:
                sortedData.sort((a, b) => (a.name > b.name) ? 1 : -1);

                this.setState({
                    content: sortedData,
                });

                break;
            /* Preferred */
            case 1:
                sortedData.sort((a, b) => (a.name < b.name) ? 1 : -1);

                const preferredData = sortedData.filter(item => { return this.props.formik.values.sourceIds.includes(item.id) });
                const otherData = sortedData.filter(item => { return !this.props.formik.values.sourceIds.includes(item.id) });

                this.setState({
                    content: preferredData.sort((a, b) => (a.name > b.name) ? 1 : -1).concat(otherData.sort((a, b) => (a.name > b.name) ? 1 : -1)),
                });

                break;
            default:
            //NON-OP
        };
    }

    /**
     * Stuff to do on sorting change.
     * @param {*} val
     */
    onSortChange(val) {
        if (this.state.sortId !== val) {
            this.setState({
                sortId: val,
            }, this.sortDataAndSetState);
        }
    }

    onPageChange = (page) => {
        this.setState({ page }, () => this.firstPageItemRef.current.focus());
    };

    getPageItems = () => this.props.sources
        .slice((this.state.page - 1) * SOURCES_PAGE_SIZE, this.state.page * SOURCES_PAGE_SIZE);

    handleSourceChange = (id) => {
        this.props.formik.setFieldValue(
            'sourceIds',
            xor([...this.props.formik.values.sourceIds], [id]),
        );
        this.props.onEditDefaultClick();
        this.props.onTouch();
    };

    /**
     * Render any sort controls.
     */
    renderSort() {
        return (
            <SortAndFilterContainer>
                <DropDownWrapper>
                    <DropDown
                        isEmptyOptionSelectable={false}
                        emptyOptionLabel={language.translations['practiceManagement.guidesAndFeatures.sort.ascending']}
                        showLabel={true}
                        onValueChanged={this.onSortChange}
                        value={this.state.sortId}
                        options={sortValues}
                        id="sort"
                        isNone="no"
                        notitle="no"
                        label={language.translations['practiceManagement.guidesAndFeatures.sort.label']}
                        title={language.translations['practiceManagement.guidesAndFeatures.sort.label']}
                        ariaLabel={language.translations['practiceManagement.guidesAndFeatures.sort.label']}
                    />
                </DropDownWrapper>
            </SortAndFilterContainer>
        );
    }

    customLabelFormatter = (name) => {
        const formatName = name.toLowerCase().trim().replace(/ /g, '_');
        switch (formatName) {
            case 'wall_street_journal':
                return 'Wall Street Journal/ Dow Jones';
            default:
                return name;
        }
    }

    /**
     * Render a single source item.
     */
    renderItem = (item, index) => (
        <StyledSourceCheckbox
            label={this.customLabelFormatter(item.name)}
            key={`profile-source-${item.id}`}
            id={`profile-source-${item.id}`}
            name="sourceIds"
            checked={this.props.formik.values.sourceIds.includes(item.id)}
            onChange={() => this.handleSourceChange(item.id)}
            fieldRef={index === 0 ? this.firstPageItemRef : undefined}
        />
    );

    /**
     * Render all source items.
     */
    renderItems() {
        return (
            <SourcesGrid>
                {this.state.content.map(this.renderItem)}
            </SourcesGrid>
        );
    }

    /**
     * Render this and underlying components.
     */
    render() {
        if (!this.state.content || !this.state.content.length) {
            return null;
        }

        return (
            <StyledTabPane>
                {this.renderSort()}
                {this.renderItems()}
                <RequestSource profile />
            </StyledTabPane>
        );
    }
}

SourcesTabPane.propTypes = {
    formik: PropTypes.shape({
        values: PropTypes.shape({
            sourceIds: PropTypes.arrayOf(PropTypes.number).isRequired,
        }).isRequired,
        setFieldValue: PropTypes.func.isRequired,
    }).isRequired,
    sources: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    onTouch: PropTypes.func.isRequired,
};

export default SourcesTabPane;
