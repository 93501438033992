import { call } from 'redux-saga/effects';
import store from 'utils/store';
import Api from 'utils/api';

export const IS_KEEP_SIGNED_IN = 'authenticate/isKeepSignedIn';
export const LOGOUT_TIME = 'authentication/logoutTime';

/**
 * Instate basic auth flow.
 * @param {*} param0
 */
export default function* authenticate({ email, password, keepSignedIn }) {
    yield call(Api.authenticate, email.trim(), password, keepSignedIn);
}
