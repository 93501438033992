import { LOCATION_CHANGE } from 'connected-react-router';

import { containsNoSessionFoundError } from 'utils/api';
import {
    CLEAR_ERRORS,
    NOT_FOUND_ERROR,
    PUT_ERROR,
    RESET_GLOBAL_ERROR,
    SERVER_ERROR,
    SET_FOCUS_ON_ERROR,
    FORBIDDEN_ERROR
} from 'state-management/constants/errors';

const initialState = {
    errors: [],
    isFocused: false,
    errorType: null,
    lastErrorStatus: null,
    lastErrorLocation: null,
};

function errorsReducer(state = initialState, action = {}) {
    const filteredPreviousErrors = state.errors.filter(e => e.errorMessage !== action.errorMessage);

    switch (action.type) {
        case PUT_ERROR: {
            let errorType = null;
            const { error } = action;
            const { location } = error;
            const status = error && error.response ? error.response.status : undefined;

            if (status >= 500 || status === 400 || containsNoSessionFoundError(action.error)) {
                errorType = SERVER_ERROR;
            } else if (status === 404) {
                errorType = NOT_FOUND_ERROR;
            } else if (status === 403) {
                errorType = FORBIDDEN_ERROR;
            }

            return {
                ...state,
                errors: [...filteredPreviousErrors, {
                    errorMessage: action.errorMessage,
                }],
                errorType: errorType,
                lastErrorLocation: location || window.location.href,
                lastErrorStatus: action.error.status ? action.error.status : action.error,
            };
        }
        case CLEAR_ERRORS:
            return initialState;
        case SET_FOCUS_ON_ERROR:
            return {
                ...state,
                isFocused: action.isFocused,
            };
        case RESET_GLOBAL_ERROR:
            return {
                ...state,
                errorType: null,
                lastErrorStatus: null,
            };
        case LOCATION_CHANGE: {
            return {
                ...state,
                errorType: null,
                lastErrorStatus: null,
            };
        }
        default:
            return state;
    }
}

export default errorsReducer;
