import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { IconSeeMore } from 'components/atomics/atoms/Icons/Icons';
import Button from 'components/atomics/atoms/Button/Button';
import Toast from 'components/atomics/molecules/Toast/Toast';
import { TYPE_RIGHT_CONTROLS } from 'components/atomics/molecules/Toast/Toast';
import { TYPE_ICON } from 'components/atomics/atoms/Button/Button';
import { iconStyles } from 'containers/Toasts/SuccessfulSaveToast/styles';
import { getTruncatedString } from 'utils/contentCardUtils';
import { contextHubRecordEvent } from 'utils/contextHub';

const MAX_ARTICLE_TITLE_CHARS = 110;

/**
 * Toast shown when user saves an item.
 * @param {*} props 
 */

//analytics 

class SuccessfulSaveToast extends React.Component {
    constructor(props) {
        super(props);
    }

    CH_handleComponentEventList() {
        var tracking =
        {
            "type": "toast",
            "action": "save: " + this.props.title,
        };
        contextHubRecordEvent(tracking);
    }

    render() {
        const {
            title,
            history
        } = this.props;

        return (
            <Toast
                smallMobile={true}
                type={TYPE_RIGHT_CONTROLS}
            >
                <Toast.Title>
                    <span>"{getTruncatedString(title, MAX_ARTICLE_TITLE_CHARS)}"</span>
                    <span><FormattedMessage id="saveControlNew.add" /></span>
                    <span><FormattedMessage id="saveControlNew.add.short" /></span>
                </Toast.Title>
                <Toast.RightControls>
                    <Button
                        type={TYPE_ICON}
                        onClick={(e) => {
                            this.CH_handleComponentEventList();
                            history.push('/profile/saves');
                        }}
                    >
                        <IconSeeMore />
                    </Button>
                </Toast.RightControls>
            </Toast>
        );

    };
}

SuccessfulSaveToast.propTypes = {
    className: PropTypes.string,
    id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    title: PropTypes.string.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

SuccessfulSaveToast.defaultProps = {
    className: '',
    id: 'successful-save-toast',
};

export default withRouter(SuccessfulSaveToast);
