// fetch() polyfill
import 'whatwg-fetch';

// custom events polyfill
import 'custom-event-polyfill';

// IntersectionObserver polyfill
import 'intersection-observer';

// Promise polyfill
import rejectionTracking from 'promise/lib/rejection-tracking';
import es6Extensions from 'promise/lib/es6-extensions';

if (typeof Promise === 'undefined') {
    rejectionTracking.enable();
    window.Promise = es6Extensions;
}
