import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { toggleSaveArticle as toggleSaveArticleAction } from 'state-management/actions/save';
import {
    Container,
    SaveControl,
} from 'components';
import FormattedDate from 'components/FormattedDate/FormattedDate';
import FormattedProvider from 'components/FormattedProvider/FormattedProvider';
import Link from 'components/Link/Link';
import {
    dotStyles,
    headerContainerStyles,
    providerContainerStyles,
    sourceNameStyles,
    titleStyles,
} from './styles';
import { ARTICLE_PUBLISH_DATE_FORMAT } from 'state-management/constants/articles.common';
import { SOURCE_LINK } from 'state-management/constants/sources';
import BackToLink from "../../../components/BackToLink/BackToLink";
import { wrapBackButtonLabels } from "../../../utils/urlUtils";
import { BackButtonContainer } from "./styles";

const StyledHeaderContainer = styled(Container)`
    ${headerContainerStyles}
`;

const ProviderContainer = styled.div`
    ${providerContainerStyles}
`;

const StyledTitle = styled.div`
    ${titleStyles}
`;

export const StyledSourceName = styled.div`
    ${sourceNameStyles}
`;

const StyledDot = styled.span`
    ${dotStyles}
`;

const StyledLink = styled(Link)`
    text-decoration: none;
`;

/**
 * Header display for article pages.
 * @param {*} props 
 */
export const ArticleHeader = (props) => {
    const {
        source, provider, publishDate, title, saved,
    } = props.article;
    const { articleId, isSaving, toggleSaveArticle, urlHistoryList } = props;

    let backLabel = urlHistoryList != null && urlHistoryList.length
        ? urlHistoryList.length - 2 >= 0
            ? urlHistoryList[urlHistoryList.length - 2]
            : null
        : null;

    if (backLabel !== null) {
        if (backLabel.currentUrl == '/about?b=1' || backLabel.currentUrl == '/terms-and-conditions?b=1'
            || backLabel.currentUrl == '/contact-us?b=1') {
            backLabel = null
        }
    }

    return (
        <StyledHeaderContainer size={Container.SMALL}>
            <BackButtonContainer>
                <BackToLink
                    to={backLabel !== null ? backLabel.currentUrl : '#'}
                    customLabel={wrapBackButtonLabels(backLabel)}
                    isDisable={backLabel === null}
                />
            </BackButtonContainer>

            <StyledLink to={SOURCE_LINK(source.name)}>
                <StyledSourceName>{source.name}</StyledSourceName>
            </StyledLink>
            <ProviderContainer>
                <FormattedMessage
                    id="articles.provider"
                    values={{ provider: <FormattedProvider provider={provider} /> }}
                />
                &reg;
                <StyledDot>&middot;</StyledDot>
                <FormattedDate date={publishDate} dateFormat={ARTICLE_PUBLISH_DATE_FORMAT} />
            </ProviderContainer>
            <StyledTitle>
                {title}
            </StyledTitle>
            <SaveControl
                appearance="extended"
                saved={saved}
                isLoading={isSaving}
                onToggleSave={() => toggleSaveArticle(articleId, !saved, title, props.article)}
                title={title}
            />
        </StyledHeaderContainer>
    );
};

ArticleHeader.propTypes = {
    article: PropTypes.shape({
        trueId: PropTypes.string,
        id: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        title: PropTypes.string,
        saved: PropTypes.bool,
        source: PropTypes.shape({
            imageName: PropTypes.string,
            name: PropTypes.string,
        }),
        provider: PropTypes.string,
        publishDate: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        contentType: PropTypes.string,
        interactionType: PropTypes.string,
        interactionId: PropTypes.string,
        sourceId: PropTypes.sting,
    }).isRequired,
    articleId: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
    toggleSaveArticle: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    isSaving: state.articles.isSaving,
    urlHistoryList: state.UrlHistoryReducer.history
});

const mapDispatchToProps = dispatch => ({
    toggleSaveArticle: (id, save, title, article) => dispatch(toggleSaveArticleAction(id, save, { title, getContentAfterSave: false, article })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleHeader);
