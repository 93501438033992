export const KEY_BACKSPACE = 8;
export const KEY_DELETE = 46;
export const KEY_TAB = 9;

export const KEY_NUMBERS = Array.from(Array(10), (_, x) => x + 48);
export const KEY_NUMPAD_NUMBERS = Array.from(Array(10), (_, x) => x + 96);
export const KEY_ALL_NUMBERS = [...KEY_NUMBERS, ...KEY_NUMPAD_NUMBERS];

export const KEY_LEFT = 37;
export const KEY_RIGHT = 39;
export const KEY_UP = 38;
export const KEY_DOWN = 40;
export const KEY_ARROWS = [KEY_LEFT, KEY_RIGHT, KEY_UP, KEY_DOWN];

export const KEY_ENTER = 13;
export const KEY_SPACE = 32;
export const KEY_ESCAPE = 27;

export const KEY_PAGE_UP = 33;
export const KEY_PAGE_DOWN = 34;
export const KEY_END = 35;
export const KEY_HOME = 36;
export const KEY_INSERT = 45;

export const MOUSE_MIDDLE = 2;

export const KEY_NUMBERS_AND_SERVICE = [
    KEY_BACKSPACE,
    KEY_DELETE,
    KEY_TAB,
    KEY_PAGE_UP,
    KEY_PAGE_DOWN,
    KEY_END,
    KEY_HOME,
    KEY_INSERT,
    ...KEY_ALL_NUMBERS,
    ...KEY_ARROWS,
];
