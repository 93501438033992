import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS } from 'utils/variables';

export const skinnyListContentCardContainerStyles = css`
    width: 100%;
    max-width: 800px;

    .KebabButton__StyledTrigger-hupobq {
        right: 0;
        bottom: 17px;
    } 

    &.last-item {
        margin-bottom: 50px;
    }
`;

export const headingStyles = css`
    width: 215px;
    height: 45px;
    font-family: AvenirNext;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.55;
    letter-spacing: -0.25px    

    @media screen and ${FROM_TABLET} {
        font-size: 32px;
        margin-bottom: 13px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 20px;
        margin-bottom: 8px;
    }
`;

export const styledSubHeaderStyles = css`
    font-size: 20px;
`;

export const styledHeader = css`
    font-size: 15px;
    margin: 15px 0;
    font-weight: 500;
    
    @media screen and ${FROM_TABLET} {
        margin: 25px 60px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin: 70px 0 0 0;
    }
`;

export const styledBack = css `
    margin-top: -20px;
    @media screen and ${FROM_TABLET} {
        margin-top: -23px;
    }
    @media screen and ${FROM_DESKTOP} {
        display: none;
    }
`;

export const descriptionStyles = css`
    color: ${COLORS.MEDIUM_GRAY2};
    font-size: 20px;
    font-weight: 500;
    line-height: 1.3;
    margin: 0 auto;
    width: 80%;

    @media screen and ${FROM_TABLET} {
        color: ${COLORS.DARK_GRAY2};
        font-size: 18px;
        width: 100%;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 24px;
    }
`;

export const secondHeaderStyles = css`
    font-family: AvenirNext;
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.07;
    letter-spacing: -0.19px;    

    @media screen and ${FROM_TABLET} {
        margin-bottom: 0;
        padding-bottom: 18px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 15px;
        font-weight: 600;
        line-height:31px
        padding-bottom: 14px;
    }
`;

export const savedContentStyles = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 0 !important;

    @media screen and ${FROM_TABLET} {
        margin-bottom: 23px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-top 96px !important;
    }
`;

export const emptySavesCardStyles = css`
    margin-bottom: 36px;
    object-fit: contain;
    padding: 0;
    text-align: center;

    @media screen and ${FROM_TABLET} {
        background-color: ${COLORS.WHITE};
        margin-bottom: 54px;
        padding: 32px 0 45px;       
        text-align: center;
    }
 
    @media screen and ${FROM_DESKTOP} {
        margin: 32px 0 0;
        padding: 47px 20px 60px;
    }
`;

export const textStyles = css`
    color: ${COLORS.BLACK};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 15px;
    font-weight: 400;
    line-height: 1.56;
    margin-top: 22px;
    text-align: left;

    @media screen and ${FROM_TABLET} {
        text-align: center;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 18px;
        margin-top: 32px;
    }
`;

export const titleStyles = css`
    color: ${COLORS.SAPPHIRE};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 26px;
    font-weight: 400;
    line-height: 1.23;
    margin-top: 22px;
    text-align: left;

    @media screen and ${FROM_TABLET} {
        text-align: center;
    }
`;

export const iconEmptySaveStyles = css`
    color: ${COLORS.SAPPHIRE};
    fill: ${COLORS.SAPPHIRE};

    @media screen and ${FROM_TABLET} {
        height: 36px;
        width: 26px;
    }

    @media screen and ${FROM_DESKTOP} {
        height: 48px;
        width: 34px;
    }
`;

export const iconArrowLeftStyles = css`
    @media screen and ${FROM_DESKTOP} {
        display: none;
    }
`;

export const iconEmptySaveSmallStyles = css`
    color: ${COLORS.SAPPHIRE};
    fill: ${COLORS.WHITE};
    stroke-width: 3;
    transform: translateY(3px);
    position: relative;
    bottom: 4px;

    @media screen and ${FROM_DESKTOP} {
        height: 17px;
        transform: translateY(0);
        width: 15px;
        bottom: 2px;
    }
`;

export const savesContainerStyles = css`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    padding: 0;

    @media screen and ${FROM_DESKTOP} {
        padding: 0 0 0 54px;
    }

    @media (min-width: 1281px) {
        max-width: 1170px;
        margin-left: 55px;
        padding-left: 0;
        padding-right: 0;
    }
`;

export const styledEmptyContainer = css `
    margin:0 -16px;

    @media screen and ${FROM_TABLET} {
        margin:0 -38px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin:0;
    }
`;