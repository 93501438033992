import {
    GET_PROFILE_SOURCES,
    GET_PROFILE_SOURCES_FAIL,
    GET_PROFILE_SOURCES_SUCCESS,
    GET_SOURCES,
    GET_SOURCES_FAIL,
    GET_SOURCES_SUCCESS,
    TOGGLE_FOLLOW_SOURCE,
    TOGGLE_FOLLOW_SOURCE_FAIL,
    TOGGLE_FOLLOW_SOURCE_SUCCESS,
} from 'state-management/constants/sources';

const initialState = {
    sources: [],
    profileSources: [],
    isLoaded: false,
    isLoading: false,
};

function sourcesReducer(state = initialState, action = {}) {
    switch (action.type) {
    case GET_SOURCES:
        return {
            ...state,
            isLoading: true,
        };
    case GET_SOURCES_SUCCESS:
        return {
            ...state,
            sources: action.data,
            isLoaded: true,
            isLoading: false,
        };
    case GET_SOURCES_FAIL:
        return {
            ...state,
            isLoaded: true,
            isLoading: false,
        };
    case GET_PROFILE_SOURCES:
        return {
            ...state,
            isLoading: action.displayLoader,
        };
    case GET_PROFILE_SOURCES_SUCCESS:
        return {
            ...state,
            profileSources: action.data,
            isLoading: false,
        };
    case GET_PROFILE_SOURCES_FAIL:
        return {
            ...state,
            isLoading: false,
        };
    case TOGGLE_FOLLOW_SOURCE: {
        return {
            ...state,
            toggleInProgress: true,
        };
    }
    case TOGGLE_FOLLOW_SOURCE_SUCCESS: {
        return {
            ...state,
            toggleInProgress: false,
            isLoading: false,
        };
    }
    case TOGGLE_FOLLOW_SOURCE_FAIL: {
        return {
            ...state,
            toggleInProgress: false,
            isLoading: false,
        };
    }
    default:
        return state;
    }
}

export default sourcesReducer;
