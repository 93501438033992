import React from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { DESKTOP, MOBILE, TABLET } from 'components/Breakpoints/Breakpoints';
import Toast from 'components/atomics/molecules/Toast/Toast';
import { TYPE_RIGHT_CONTROLS } from 'components/atomics/molecules/Toast/Toast';
import { TYPE_ICON } from 'components/atomics/atoms/Button/Button';
import styled from 'styled-components';
import {
    IconExclamation,
    IconSeeMore,
} from 'components/atomics/atoms/Icons/Icons';
import Button from 'components/atomics/atoms/Button/Button';
import { connect } from "react-redux";

import {
    innerTitleStyles,
} from './styles';
import { Prompt } from 'react-router-dom';
import { showSourceModal } from 'state-management/actions/basicProfile';
import { contextHubRecordEvent } from '../../../utils/contextHub';

const InnerTitle = styled.div`
    ${innerTitleStyles};
`;

/**
 * Toast shown when user attempts to go below minimum 3 topics.
 */
class TopicLimitToast extends React.Component {
    constructor(props) {
        super(props);
        this.showModalOnClick = this.showModalOnClick.bind(this);
    }
    showModalOnClick() {
        this.props.showSourceModalAction();
    };

    //analytics 
    CH_handleComponentEventList() {
        var tracking =
        {
            "type": "toast",
            "action": "toast: " + this.props.id,
        };
        contextHubRecordEvent(tracking);
    }

    render() {
        const sourceLength = this.props.selectedSources.length;
        return (
            <Toast type={TYPE_RIGHT_CONTROLS}>
                <Toast.Title>
                    <InnerTitle>
                        <div>
                            <IconExclamation />
                        </div>
                        <div>
                            <FormattedMessage id="toast.topicLimit" />
                        </div>
                    </InnerTitle>
                </Toast.Title>
                <Toast.RightControls>
                    <Button
                        type={TYPE_ICON}
                        onClick={(e) => {
                            this.CH_handleComponentEventList();
                            if (sourceLength < 3) {
                                this.showModalOnClick();
                            }
                            else {
                                this.props.history.push('/profile/preferences/topic');
                            }
                        }}
                    >
                        <IconSeeMore />
                    </Button>
                    <Prompt
                        when={this.props.showSourceModal}
                        message="source.modal"
                    />

                </Toast.RightControls>
            </Toast>
        );
    }
}

TopicLimitToast.propTypes = {
    actionType: PropTypes.string,
    id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    analyticsData: PropTypes.objectOf(PropTypes.any),
    selectedSources: PropTypes.arrayOf(PropTypes.number),
};

TopicLimitToast.defaultProps = {
    actionType: '',
    id: 'topic-limit-toast',
    analyticsData: {},
    selectedSources: [],
};

const mapStateToProps = (state) => ({
    showSourceModal: state.basicProfile.showSourceModal,
    selectedSources: state.basicProfile.sourceIds,
});

const mapDispatchToProps = dispatch => ({
    showSourceModalAction: () => dispatch(showSourceModal()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopicLimitToast));
