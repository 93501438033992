import labels from '../lang/en.json';
import { ROUTES } from '../containers/App/constants';
const config = typeof (__ENVIRONMENT_CONFIG__) !== 'undefined' ? __ENVIRONMENT_CONFIG__ : {};
const { BACKEND_API_BASE_PATH, AEM_RESOURCE_PATH } = config;
//const AEM_IMAGE_LOCATION_PATH = 'content/dam/cgc/tenants/digital-prospecting/images';

const PATH_SOURCES = '/sources/';
const PATH_TOPICS = '/topics/';
const PATH_TOP_NEWS = '/news/top';
const PATH_CAP_IDEAS = '/news/capital-ideas';
const PATH_COLLECTIONS = '/collections/';
const PATH_IDEAS = '/learn/ideas/';
const PATH_ARTICLES = '/articles/';
const PATH_PREFERNCES = '/profile/preferences';
const PATH_SAVES = '/saves';

const SEGMENT_COLLECTIONS = '/collections';
const SEGMENT_MARKETBRIEFING = '/marketBriefing';

const DOUBLE_LINE_BREAK = '%0d%0a%0d%0a';

/**
 * Get a sharable URL for the current page with the authoring users email share token.
 * @param {*} userShareToken
 */
export const getShareLink = (userShareToken, forWeb = false, copy = false) => {
    //const url = window.location.href; //use this to share specific pages..
    const url = window.location.origin;

    if (forWeb) {
        return `${url}/?token=${userShareToken}`;
    } else {
        if (copy) {
            return `${url}/%3Ftoken=${userShareToken}`;
        } else {
            return `${url}/?token%3D${userShareToken}`;
        }
    }
}

/**
 * Get the body of an HTML email for sharing.
 * @param {*} userShareToken
 */
export const getShareEmailBody = (userShareToken) => {
    return labels.translations['share.message'] + DOUBLE_LINE_BREAK
        + getShareLink(userShareToken) + DOUBLE_LINE_BREAK
        + labels.translations['share.messageFooter'];
}

/**
 * Get a full mailto link for sharing including subject and body.
 * @param {*} userShareToken
 */
export const getShareEmailLink = (userShareToken) => {
    return 'mailto:?subject=' + labels.translations['share.subject']
        + '&body=' + getShareEmailBody(userShareToken);
}

export const getParamsFromUrl = (url) => {
    const params = {};
    const indexOfSearch = url.indexOf('?');
    if (indexOfSearch >= 0) {
        const search = url.slice(indexOfSearch + 1);
        const definitions = search.split('&');
        definitions.forEach((val) => {
            const [key, value] = val.split('=', 2);
            params[key] = decodeURIComponent(value);
        });
    }
    return params;
};

export const trimEncodedTextToSize = (encodedText, size) => {
    if (encodedText && encodedText.length > size) {
        const text = [...decodeURIComponent(encodedText)];
        const joinedText = () => encodeURIComponent(text.join(''));

        while (joinedText().length > size) {
            text.length -= 1;
        }

        return joinedText();
    }
    return encodedText;
};

export const createResourceUrl = (url) => {
    const normalizedUrl = url.startsWith('/') ? url.substring(1) : url;

    return `${BACKEND_API_BASE_PATH}/${normalizedUrl}`;
};

export const createImageUrl = (folder, filename) => `${BACKEND_API_BASE_PATH}/content-service/assets/image?folder=${folder}&filename=${filename}`;
//commented code is kept for future purpose
// export const createImageUrl = (folder, filename) => `${AEM_RESOURCE_PATH}/${AEM_IMAGE_LOCATION_PATH}/${folder}/${filename}`;

export const getDefaultCollectionHistoryKey = (url) => {
    return url.includes(PATH_COLLECTIONS) ? labels.translations['backLink.toCollection'] : labels.translations['backLink.toAllCollections'];
}

export const wrapBackButtonLabels = (pathObj) => {
    if (pathObj === null) {
        return '';
    }

    const { currentUrl } = pathObj;

    if (currentUrl.includes(PATH_TOPICS)) {
        return labels.translations['backLink.toTopic'];
    } else if (currentUrl.includes(PATH_PREFERNCES)) {
        return labels.translations['backLink.toPreferences'];
    } else if (currentUrl.includes(ROUTES.PROFILE)) {
        return currentUrl.includes(PATH_SAVES)
            ? labels.translations['backLink.toSaves']
            : labels.translations['backLink.toProfile'];
    } else if (currentUrl.includes(PATH_SOURCES)) {
        return labels.translations['backLink.toSource'];
    } else if (currentUrl.includes(PATH_TOP_NEWS)) {
        return labels.translations['backLink.toTopNews'];
    } else if (currentUrl.includes(PATH_CAP_IDEAS)) {
        return labels.translations['backLink.toCapIdeas'];
    } else if (currentUrl.includes(ROUTES.TRENDING_TOPICS_PAGE)) {
        return labels.translations['backLink.toTrending'];
    } else if (currentUrl.includes(ROUTES.TOOLS)) {
        return labels.translations['backLink.toTools'];
    } else if (currentUrl.includes(ROUTES.CECREDITS)) {
        return labels.translations['backLink.toCECreditCenter'];
    } else if (currentUrl.includes(SEGMENT_COLLECTIONS)) {
        return currentUrl.includes(PATH_COLLECTIONS)
            ? labels.translations['backLink.toCollection']
            : labels.translations['backLink.toAllCollections'];
    } else if (currentUrl.includes(ROUTES.GUIDES_AND_FEATURED_CONTENT_PAGE)) {
        return currentUrl.includes(PATH_IDEAS)
            ? labels.translations['backLink.toGuide']
            : labels.translations['backLink.toIdeas'];
    } else if (currentUrl.includes(PATH_ARTICLES)) {
        return labels.translations['backLink.toArticle'];
    } else if (currentUrl.includes(ROUTES.ALL_TOPICS_PAGE)) {
        return labels.translations['backLink.toAllTopics'];
    } else if (currentUrl.includes(SEGMENT_MARKETBRIEFING)) {
        return currentUrl.includes(ROUTES.MARKET_BRIEFING_ARCHIVE)
            ? labels.translations['backLink.toAllBriefings']
            : labels.translations['backLink.toBriefing'];
    } else if (currentUrl.includes(ROUTES.GUIDES)) {
        return labels.translations['backLink.toPM'];
    } else if (currentUrl.includes(ROUTES.NEWS)) {
        return labels.translations['backLink.toNews'];
    } else if (currentUrl.includes(ROUTES.SEARCH)) {
        return labels.translations['backLink.toSearch'];
    } else if (currentUrl.includes(ROUTES.HOME)) {
        return labels.translations['backLink.toHome'];
    } else if (currentUrl.includes(ROUTES.ABOUT_LUMIN)) {
        return labels.translations['backLink.toAbout'];
    } else if (currentUrl.includes(ROUTES.TERMS_AND_CONDITIONS)) {
        return labels.translations['backLink.toTerms&Conditions'];
    } else if (currentUrl.includes(ROUTES.CONTACT_US)) {
        return labels.translations['backLink.toContactUs'];
    }
}

export function lastItemKeyValidation(history, key) {
    return history[history.length - 1] !== null && history[history.length - 1].key.toLowerCase() === key.toLowerCase();
}

export const getExternalMigrationURL = () => {
    switch (window.envParsedFromUrl) {
        case 'dev':
            return 'https://www.dev.capitalgroup.com/advisor/login-upgrade/confirm?tenantId=Cgpro';
        case 'qa':
            return 'https://www.stg.capitalgroup.com/advisor/login-upgrade/confirm?tenantId=Cgpro';
        case 'prod':
        default:
            return 'https://www.capitalgroup.com/advisor/login-upgrade/confirm?tenantId=Cgpro';
    }
}

//true dev url for below, using d18 to isolate us from other changes to AFI
//return 'https://www.dev.capitalgroup.com/advisor/login-auth-required.htm?next=%2Fadvisor%2Fpreferences%2Fmailing-address-and-phone-numbers.htm';

export const getExternalEditProfileURL = () => {
    switch (window.envParsedFromUrl) {
        case 'dev':
            return 'https://www.dev.capitalgroup.com/advisor/profile-view';
        case 'qa':
            return 'https://www.stg.capitalgroup.com/advisor/profile-view';
        case 'prod':
        default:
            return 'https://www.capitalgroup.com/advisor/profile-view';
    }
}

export const getIdentityServiceBaseURL = () => {
    switch (window.envParsedFromUrl) {
        case 'dev':
            return window.location.host == 'pro.dev.capitalgroup.com'
                ? 'https://www.dev.capitalgroup.com/api'
                : window.location.protocol + '//' + window.location.host + '/cgApi';
        case 'qa':
            return 'https://www.stg.capitalgroup.com/api';
        case 'prod':
        default:
            return 'https://www.capitalgroup.com/api';
    }
}
