export const GET_MARKET_BRIEFINGS_URL = '/content-service/market-briefing/all';
export const GET_MARKET_BRIEFING_URL = id => `/content-service/market-briefing/${id}`;
export const GET_LATEST_MARKET_BRIEFING_URL = '/content-service/market-briefing';

export const GET_MARKET_BRIEFINGS = 'marketBriefing/GET_ALL';
export const GET_MARKET_BRIEFINGS_SUCCESS = 'marketBriefing/GET_ALL_SUCCESS';
export const GET_MARKET_BRIEFINGS_FAIL = 'marketBriefing/GET_ALL_FAIL';

export const GET_MARKET_BRIEFING = 'marketBriefing/GET';
export const GET_MARKET_BRIEFING_SUCCESS = 'marketBriefing/GET_SUCCESS';
export const GET_MARKET_BRIEFING_FAIL = 'marketBriefing/GET_FAIL';

export const GET_LATEST_MARKET_BRIEFING = 'marketBriefing/GET_LATEST';
export const GET_LATEST_MARKET_BRIEFING_SUCCESS = 'marketBriefing/GET_LATEST_SUCCESS';
export const GET_LATEST_MARKET_BRIEFING_FAIL = 'marketBriefing/GET_LATEST_FAIL';

export const DELETE_MARKET_BRIEFING = 'marketBriefing/DELETE';
