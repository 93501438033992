import {
    SET_ONBOARDING_STEP, SET_ONBOARDING_FINISHED,
} from 'state-management/constants/onboarding';

const initialState = {
    sources: [],
    topics: [],
    currentIdx: 0,
    onboardingFinished: false,
};

function onboardingReducer(state = initialState, action = {}) {
    switch (action.type) {
    case SET_ONBOARDING_STEP:
        return {
            ...state,
            currentIdx: action.currentIdx,
        };
    case SET_ONBOARDING_FINISHED:
        return {
            ...state,
            onboardingFinished: action.isOnboardingFinished,
        };
    default:
        return state;
    }
}

export default onboardingReducer;
