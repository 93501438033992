import styled, { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS } from 'utils/variables';
import { INPRACTICE } from 'containers/Guide/guideTypes';
import { FROM_TABLET_TO_DESKTOP } from "../../utils/mediaQueries";

export const imageOverlayStyles = css`
    opacity: 0.5;
    background-image: linear-gradient(45deg, ${COLORS.SAPPHIRE}, ${COLORS.OCEAN}); /* NON standard colors per IAs request */
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
`;

export const wrapperStyles = css`
    display: flex;
    flex-direction: column;
    padding-top: 60px;

    @media screen and ${FROM_DESKTOP} {
        box-sizing: border-box;
        flex-direction: row-reverse;
        padding: 0 48px;
    }
    
`;

export const categoryStyles = css`
    color: ${COLORS.DARK_GRAY1};
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    margin: 0 0 21px;
    text-align: center;
    text-transform: uppercase;
    word-wrap: break-word;
    
    @media screen and ${FROM_DESKTOP} {
        text-align: left;
    }
`;

export const titleStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-size: 24px;
    font-weight: 700;
    line-height: 1.4;
    margin: 0 0 20px;
    text-align: center;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        font-size: 48px;
        line-height: 1.25;
    }

    @media screen and ${FROM_DESKTOP} {
        text-align: left;
    }
`;

export const descriptionStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 14px;
    line-height: 1.57;
    margin: 0 0 30px;
    text-align: center;
    white-space: pre-wrap;
    word-wrap: break-word;

    /* stylelint-disable */

    ${props => props.isGuideFeatured && css`
        color: ${COLORS.MEDIUM_GRAY2};
        font-size: 12px;
        font-weight: 500;
        line-height: 2;
    `}

    /* stylelint-enable */

    @media screen and ${FROM_TABLET} {
        font-size: 24px;
        line-height: 1.25;
        margin: 0 0 46px;

        /* stylelint-disable */

        ${props => props.isGuideFeatured && css`
            font-size: 12px;
            line-height: 2;
        `}

        /* stylelint-enable */
    }

    @media screen and ${FROM_DESKTOP} {
        text-align: left;
    }
`;

export const imageWrapperStyles = css`
    margin: 0;
    position: relative;
    width: 100%;
    overflow: hidden;

    @media screen and ${FROM_DESKTOP} {
        right: -48px;
        width: 45%;
    }
`;

export const imageContainerStyles = css`
    height: 200px;
    position: relative;
    width: 100%;
    z-index: 5;

    ${props => props.type === INPRACTICE && css`
        img {
            filter: grayscale(100%);
        }
    `};

    @media screen and ${FROM_TABLET} {
        height: 296px;
    }

    @media screen and ${FROM_DESKTOP} {
        height: auto;
        margin-bottom: -20px;
        min-height: 685px;

        /* stylelint-disable */

        ${props => props.isGuideFeatured && css`
            margin-bottom: 0;
        `}

        /* stylelint-enable */
    
    }
    @media screen\0 and ${FROM_DESKTOP} {
        img{
            transform: translate(-18%, -50%);
        }
        }
`;

export const imageCaptionStyles = css`
    color: ${COLORS.MEDIUM_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 10px;
    margin-top: 16px;
    padding: 0 16px;
    word-wrap: break-word;

    @media screen and ${FROM_DESKTOP} {
        font-size: 12px;
        margin-bottom: -48px;
        padding: 0;
    }
`;

export const contentContainerStyles = css`
    box-sizing: border-box;
    flex: 1 1 auto;
    padding: 44px 16px;

    @media screen and ${FROM_TABLET} {
        padding: 64px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-left: auto;
        max-width: 645px;
        padding: 80px 80px 80px 0;
    }
`;

export const controlsContainerStyles = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media screen and ${FROM_DESKTOP} {
        justify-content: flex-start;
    }
`;

export const timeToReadStyles = css`
    &::before {
        content: '/';
        display: inline-block;
        font-size: 16px;
        margin: 0 10px;
    }
`;

export const BackButtonContainer = styled.div`
  position: absolute;
  top: 0;
  
   @media screen and ${FROM_TABLET_TO_DESKTOP} {
       top: -15px;
    }
`;
