import React from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';

export const ScrollPositionContext = React.createContext({
    subscribeToScroll: () => {},
    unsubscribeFromScroll: () => {},
});

class ScrollPosition extends React.PureComponent {
    static getScrollPosition() {
        return {
            height: window.innerHeight,
            x: window.pageXOffset || document.documentElement.scrollLeft,
            y: window.pageYOffset || document.documentElement.scrollTop,
            width: window.innerWidth,
        };
    }

    constructor(props) {
        super(props);

        this.subscribers = [];

        this.handleThrottledScroll = throttle(this.setPosition.bind(this), 25);
        this.subscribe = this.subscribe.bind(this);
        this.unsubscribe = this.unsubscribe.bind(this);

        this.state = {
            providerValue: {
                subscribeToScroll: this.subscribe,
                unsubscribeFromScroll: this.unsubscribe,
            },
        };
    }

    componentDidMount() {
        this.setPosition();
        window.addEventListener('scroll', this.handleThrottledScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleThrottledScroll);
    }

    setPosition() {
        const position = ScrollPosition.getScrollPosition();

        this.subscribers.forEach((handler) => {
            handler({
                height: position.height,
                left: position.x,
                leftDelta: position.x - this.state.left,
                top: position.y,
                topDelta: position.y - this.state.top,
                width: position.width,
            });
        });
    }

    subscribe(handler) {
        this.subscribers.push(handler);
    }

    unsubscribe(handler) {
        this.subscribers = this.subscribers.filter(current => current !== handler);
    }

    render() {
        const { children } = this.props;

        return (
            <ScrollPositionContext.Provider value={this.state.providerValue}>
                {children}
            </ScrollPositionContext.Provider>
        );
    }
}

ScrollPosition.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

ScrollPosition.defaultProps = {
    children: null,
};

export default ScrollPosition;
