import * as type from 'state-management/constants/guide';

export const getTools = () => ({
    type: type.GET_TOOLS,
});

export const getToolsSuccess = (data) => ({
    type: type.GET_TOOLS_SUCCESS,
    data,
})

export const getToolsFailure = error => ({
    type: type.GET_TOOLS_FAIL,
    error,
});

export const getGuide = id => ({
    type: type.GET_GUIDE_DATA,
    id,
});

export const getGuideSuccess = (guideId, guideData) => ({
    type: type.GET_GUIDE_DATA_SUCCESS,
    id: guideId,
    data: guideData,
});

export const getGuideFailure = error => ({
    type: type.GET_GUIDE_DATA_FAIL,
    error,
});

export const getGuideCollections = group => ({
    type: type.GET_GUIDE_COLLECTIONS_DATA,
    group,
});

export const getGuideCollectionsSuccess = (guideCollectionsData) => ({
    type: type.GET_GUIDE_COLLECTIONS_DATA_SUCCESS,
    data: guideCollectionsData,
});

export const getGuideCollectionsFailure = error => ({
    type: type.GET_GUIDE_COLLECTIONS_DATA_FAIL,
    error,
});

export const getGuideRecent = no => ({
    type: type.GET_GUIDE_RECENT_DATA,
    no,
});

export const clearGuidesAndCollections = () => ({
    type: type.CLEAR_GUIDES_AND_COLLECTIONS,
})

export const getGuideRecentSuccess = (guideRecentData) => ({
    type: type.GET_GUIDE_RECENT_DATA_SUCCESS,
    data: guideRecentData,
});

export const getGuideRecentFailure = error => ({
    type: type.GET_GUIDE_RECENT_DATA_FAIL,
    error,
});

export const getGuideTools = no => ({
    type: type.GET_GUIDE_TOOLS_DATA,
    no,
});

export const getGuideToolsSuccess = (guideToolsData) => ({
    type: type.GET_GUIDE_TOOLS_DATA_SUCCESS,
    data: guideToolsData,
});

export const getGuideToolsFailure = error => ({
    type: type.GET_GUIDE_TOOLS_DATA_FAIL,
    error,
});


export const resetGuide = () => ({
    type: type.RESET_GUIDE_DATA,
});

export const savePollAnswer = (pollId, optionId) => ({
    type: type.SAVE_POLL_ANSWER,
    pollId,
    optionId,
});

export const savePollAnswerSuccess = (pollId, optionPercentages) => ({
    type: type.SAVE_POLL_ANSWER_SUCCESS,
    pollId,
    optionPercentages,
});

export const handleGuideVisit = id => ({
    type: type.GUIDE_WAS_VISITED,
    id,
});

export const getCollection = title => ({
    type: type.GET_COLLECTION_DATA,
    title,
});

export const getCollectionSuccess = (guideRecentData) => ({
    type: type.GET_COLLECTION_DATA_SUCCESS,
    data: guideRecentData,
});

export const getCollectionFailure = error => ({
    type: type.GET_COLLECTION_DATA_FAIL,
    error,
});

export const getCeCreditsSuccess = (data, seeMore) => ({
    type: type.GET_CE_CREDITS_SUCCESS,
    data,
    seeMore,
});


export const getCeCreditsItems = (no) => ({
    type: type.GET_CE_CREDITS_LIST,
    no,
});

export const getCeCreditsFail = error => ({
    type: type.GET_CE_CREDITS_LIST_FAIL,
    error,
});

export const getCECreditsitemSuccess = data => ({
    type: type.GET_CE_CREDITS_LIST_SUCCESS,
    data,
});

export const visitCeCredit = (id) => ({
    type: type.VISIT_CE_CREDIT,
    id,
})

export const getPracticeLab = (data) => ({
    type: type.GET_PRACTICE_LAB,
    data,
});

export const getPracticeLabFailure = error => ({
    type: type.GET_PRACTICE_LAB_FAIL,
    error,
});

export const getPracticeLabSuccess = (data) => ({
    type: type.GET_PRACTICE_LAB_SUCCESS,
    data,
});

export const showLoader = () => ({
    type: type.SHOW_LOADER,
});

export const hideLoader = () => ({
    type: type.HIDE_LOADER,
});