import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
    authorDetailsStyles,
    authorStyles,
    quoteContainerStyles,
    quoteStyles,
    signatureStyles,
} from 'components/Quote/styles';
import { SanitizedHTML } from 'components';

const StyledQuoteContainer = styled.div`
    ${quoteContainerStyles}
`;
const StyledQuote = styled(SanitizedHTML)`
    ${quoteStyles}
`;
const StyledSignature = styled.div`
    ${signatureStyles}
`;
const StyledAuthor = styled.div`
    ${authorStyles}
`;
const StyledAuthorDetails = styled.div`
    ${authorDetailsStyles}
`;

const Quote = props => (
    Quote.areAttributesValid(props.data) && (
        <StyledQuoteContainer id={`quote-${props.id}`}>
            <StyledQuote
                htmlContent={props.data.quote}
                tag="div"
            />
            <StyledSignature>
                <StyledAuthor>
                    {props.data.personName}
                </StyledAuthor>
                <StyledAuthorDetails>
                    {props.data.personTitleOrPosition}
                </StyledAuthorDetails>
            </StyledSignature>
        </StyledQuoteContainer>
    ));

Quote.areAttributesValid = ({ quote, personTitleOrPosition, personName }) => (
    quote && personTitleOrPosition && personName
);

Quote.propTypes = {
    data: PropTypes.shape({
        quote: PropTypes.string,
        personTitleOrPosition: PropTypes.string,
        personName: PropTypes.string,
    }).isRequired,
    id: PropTypes.string.isRequired,
};

export default Quote;