import { css } from 'styled-components';
import { innerFocusMixin } from 'components/FocusVisibility/styles';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS, RGB_COLORS } from 'utils/variables';

export const footerContainerStyles = css`
    box-shadow: 0 2px 40px 0 rgba(${RGB_COLORS.BLACK}, 0.1);
    display: flex;
    flex-direction: column-reverse;
    width: 100%;

    @media screen and ${FROM_TABLET} {
        flex-direction: row;
    }
`;

export const footerSideContainerStyles = css`
    background-color: ${COLORS.WHITE};
    cursor: ${props => (props.hideOnMobile ? 'default' : 'pointer')};
    display: ${props => (props.hideOnMobile ? 'none' : 'flex')};
    ${innerFocusMixin};
    text-decoration: none;
    width: 100%;

    &:hover {
        polygon {
            fill: ${props => (props.hideOnMobile ? COLORS.LIGHT_GRAY3 : COLORS.DARK_SAPPHIRE)};
        }
    }

    @media screen and ${FROM_TABLET} {
        display: flex;
        min-height: 178px;
        width: 50%;
    }
`;

export const footerSideStyles = css`
    align-items: flex-start;
    box-shadow: 0 2px 40px 0 rgba(${RGB_COLORS.BLACK}, 0.1);
    box-sizing: border-box;
    display: flex;
    padding: 20px 16px;
    width: 100%;

    /* stylelint-disable */
    ${props => props.side === 'previous' && css`
        box-shadow: 0 -20px 15px -10px rgba(${RGB_COLORS.BLACK}, 0.1);
        margin-top: 5px;
        text-align: right;
        z-index: 1;
    `};
    /* stylelint-enable */

    @media screen and ${FROM_TABLET} {
        flex-direction: column;
        padding: 30px;

        /* stylelint-disable */
        ${props => props.side === 'previous' && css`
            box-shadow: none;
            margin-top: 0;
            text-align: left;
            z-index: 0;
        `};

        ${props => props.side === 'next' && css`
            box-shadow: -20px 0 15px -10px rgba(${RGB_COLORS.BLACK}, 0.1);
            text-align: right;
            z-index: 1;
        `};
        /* stylelint-enable */
    }

    @media screen and ${FROM_DESKTOP} {
        align-items: center;
        flex-direction: row;
        padding: 50px 60px;
    }
`;

export const footerPointerStyles = css`
    align-items: center;
    color: ${COLORS.MEDIUM_GRAY1};
    display: flex;
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 2px;
    position: relative;
    text-transform: uppercase;
    width: auto;

    /* stylelint-disable */
    ${props => props.side === 'next' && css`
        margin-left: 15px;
        order: 1;
    `};

    ${props => props.side === 'previous' && css`
        margin-right: 15px;
    `};
    /* stylelint-enable */

    @media screen and ${FROM_TABLET} {
        padding: 0 0 40px 0;

        /* stylelint-disable */
        ${props => props.side === 'next' && css`
            margin: 0 0 0 auto;
            order: 0;
        `};

        ${props => props.side === 'previous' && css`
            margin: 0 auto 0 0;
        `};
        /* stylelint-enable */
    }

    @media screen and ${FROM_DESKTOP} {
        flex: 1 0 auto;
        padding: 0;

        /* stylelint-disable */
        ${props => props.side === 'next' && css`
            margin: 0 0 0 40px;
            order: 1;
        `};

        ${props => props.side === 'previous' && css`
            margin: 0 40px 0 0;
        `};
        /* stylelint-enable */
    }
`;

export const footerContentStyles = css`
    align-self: center;
    flex: 1 1 auto;
    width: 100%;
`;

export const footerContentCategoryStyles = css`
    color: ${COLORS.SAPPHIRE};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 10px;
    text-transform: uppercase;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        font-size: 12px;
    }
`;

export const footerContentTitleStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 14px;
    line-height: 1.4;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        font-size: 24px;
        line-height: 1.25;
    }
`;

export const footerPointerArrowStyles = css`
    fill: ${COLORS.LIGHT_GRAY3};
    margin-top: -2px;
    min-width: 17px;
    padding: 0 0 0 0.5em;

    /* stylelint-disable */
    ${props => props.side === 'previous' && css`
        transform: rotate(180deg);
    `};

    ${props => props.side === 'next' && css`
        order: 1;
    `};
    /* stylelint-enable */
`;

export const emptyFooterSideContainer = css`
    width: 50%;
`;
