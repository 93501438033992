import React from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';

import { BREAKPOINTS } from 'components/Breakpoints/constants';

export const {
    DESKTOP,
    MOBILE,
    TABLET,
} = BREAKPOINTS;

export const BreakpointsContext = React.createContext(BREAKPOINTS.DESKTOP);

export const getBreakpoint = (windowWidth) => {
    if (windowWidth >= 1024) {
        return BREAKPOINTS.DESKTOP;
    }

    if (windowWidth >= 768) {
        return BREAKPOINTS.TABLET;
    }

    return BREAKPOINTS.MOBILE;
};

class Breakpoints extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            breakpoint: BREAKPOINTS.DESKTOP,
        };

        this.handleDebouncedResize = debounce(this.setWidth.bind(this), 50);
    }

    componentDidMount() {
        this.setWidth();
        window.addEventListener('resize', this.handleDebouncedResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleDebouncedResize);
    }

    setWidth() {
        const windowWidth = window.innerWidth;

        this.setState({
            breakpoint: getBreakpoint(windowWidth),
        });
    }

    render() {
        const { children } = this.props;

        return (
            <BreakpointsContext.Provider value={this.state.breakpoint}>
                {children}
            </BreakpointsContext.Provider>
        );
    }
}

Breakpoints.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

Breakpoints.defaultProps = {
    children: null,
};

export default Breakpoints;
