import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ToolsContentCard from 'components/atomics/molecules/ToolsContentCardNew/ToolsContentCard';
import Row from 'components/atomics/atoms/Row/Row';
import {
    BreakpointsContext,
    MOBILE,
    TABLET,
} from '../../../../components/Breakpoints/Breakpoints';
import {
    sliderContainerStyles,
    singleCardHolderStyles,
} from './styles';
import { Carousel } from "../../molecules/CarouselMolecule/Carousel";

const SliderContainer = styled.section`
    ${sliderContainerStyles}
`;

const SingleCardHolder = styled.section`
    ${singleCardHolderStyles}
`;

export const TYPE_4COLUMN = "type/4column";
export const TYPE_3COLUMN = "type/3column";

/**
 * Creates a collection of four tool cards.
 */
class ToolsCardCollectionOfFour extends React.Component {
    /**
     * Render the tool card collection and underlying components.
     */
    render() {
        //if only 2 cards are present to display in carousal then set only single cards in carousal display settings
        //in all other cases show 3 cards in carousal display for making edges of other 2 cards visible
        //utility works well when cards to show is 1 less than total cards
        return (
            <BreakpointsContext.Consumer>
                {(breakpoint) => {
                    if (breakpoint === MOBILE) {
                        if (this.props.toolsCollection.length == 1) {
                            return (
                                <SingleCardHolder>
                                    <ToolsContentCard
                                        toolCardInfo={this.props.toolsCollection[0]}
                                        sendClickOnTools={this.props.sendClickOnTools}
                                    />
                                </SingleCardHolder>
                            )
                        }
                        else {
                            const cItems = this.props.toolsCollection.map(toolInfo => {
                                return ({
                                    component: (<ToolsContentCard
                                        key={toolInfo.link}
                                        toolCardInfo={toolInfo}
                                        sendClickOnTools={this.props.sendClickOnTools}
                                    />)
                                })
                            })
                            return (
                                <SliderContainer
                                    data-rel="GuidesCardCollectionOfFour"
                                >
                                    <Carousel items={cItems} />
                                </SliderContainer>

                            );
                        }

                    } else if (breakpoint === TABLET) {
                        return (
                            <Row data-rel="ToolsCardCollectionOfFour" type={TYPE_3COLUMN}>
                                {this.props.toolsCollection[0] &&
                                    <ToolsContentCard
                                        toolCardInfo={this.props.toolsCollection[0]}
                                        type="tool"
                                        sendClickOnTools={this.props.sendClickOnTools}
                                    />}
                                {this.props.toolsCollection[1] &&
                                    <ToolsContentCard
                                        toolCardInfo={this.props.toolsCollection[1]}
                                        type="education"
                                        sendClickOnTools={this.props.sendClickOnTools}
                                    />}
                                {this.props.toolsCollection[2] &&
                                    <ToolsContentCard
                                        toolCardInfo={this.props.toolsCollection[2]}
                                        sendClickOnTools={this.props.sendClickOnTools}
                                    />}
                            </Row>
                        );
                    } else {
                        return (

                            <Row data-rel="ToolsCardCollectionOfFour" type={TYPE_4COLUMN} lastCard={(this.props.toolsCollection.length < 4)}>
                                {this.props.toolsCollection[0] &&
                                    <ToolsContentCard
                                        toolCardInfo={this.props.toolsCollection[0]}
                                        sendClickOnTools={this.props.sendClickOnTools}
                                    />}
                                {this.props.toolsCollection[1] &&
                                    <ToolsContentCard
                                        toolCardInfo={this.props.toolsCollection[1]}
                                        sendClickOnTools={this.props.sendClickOnTools}
                                    />}
                                {this.props.toolsCollection[2] &&
                                    <ToolsContentCard
                                        toolCardInfo={this.props.toolsCollection[2]}
                                        sendClickOnTools={this.props.sendClickOnTools}
                                    />}
                                {this.props.toolsCollection[3] &&
                                    <ToolsContentCard
                                        toolCardInfo={this.props.toolsCollection[3]}
                                        sendClickOnTools={this.props.sendClickOnTools}
                                    />}
                            </Row>
                        );
                    }
                }}
            </BreakpointsContext.Consumer>
        );
    }
}

ToolsCardCollectionOfFour.propTypes = {
    toolsCollection: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        order: PropTypes.string.number,
        latestPublishTimestamp: PropTypes.string.isRequired,
        tags: PropTypes.arrayOf(PropTypes.string),
        visited: PropTypes.bool,
    })).isRequired
};

export default ToolsCardCollectionOfFour;
