import { css } from 'styled-components';

/**
 * @license
 * MyFonts Webfont Build ID 3594036, 2018-06-08T11:18:34-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: FF Tisa Web Pro by FontFont
 * URL: https://www.myfonts.com/fonts/fontfont/tisa/pro-113466/
 * Copyright: 2009 Mitja Miklavcic published by FSI FontShop International GmbH
 * Licensed pageviews: 50,000
 *
 * Webfont: FF Tisa Web Pro Light by FontFont
 * URL: https://www.myfonts.com/fonts/fontfont/tisa/pro-light-113466/
 * Copyright: 2009 Mitja Miklavcic published by FSI FontShop International GmbH
 * Licensed pageviews: 50,000
 *
 * Webfont: AvenirNextLTPro-Bold by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-bold/
 * Copyright: Copyright &#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 * Licensed pageviews: 500,000
 *
 * Webfont: AvenirNextLTPro-BoldIt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-bold-italic/
 * Copyright: Copyright &#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 * Licensed pageviews: 500,000
 *
 * Webfont: AvenirNextLTPro-Demi by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-demi/
 * Copyright: Copyright &#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 * Licensed pageviews: 500,000
 *
 * Webfont: AvenirNextLTPro-DemiIt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-demi-italic/
 * Copyright: Copyright &#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 * Licensed pageviews: 500,000
 *
 * Webfont: AvenirNextLTPro-HeavyIt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-heavy-italic/
 * Copyright: Copyright &#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 * Licensed pageviews: 500,000
 *
 * Webfont: AvenirNextLTPro-Heavy by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-heavy/
 * Copyright: Copyright &#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 * Licensed pageviews: 500,000
 *
 * Webfont: AvenirNextLTPro-It by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-italic/
 * Copyright: Copyright &#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 * Licensed pageviews: 500,000
 *
 * Webfont: AvenirNextLTPro-LightIt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-light-italic/
 * Copyright: Copyright &#x00A9; 2012 - 2017 Monotype GmbH. All rights reserved.
 * Licensed pageviews: 500,000
 *
 * Webfont: AvenirNextLTPro-Light by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-light/
 * Copyright: Copyright &#x00A9; 2012 - 2017 Monotype GmbH. All rights reserved.
 * Licensed pageviews: 500,000
 *
 * Webfont: AvenirNextLTPro-MediumIt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-medium-italic/
 * Copyright: Copyright &#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 * Licensed pageviews: 500,000
 *
 * Webfont: AvenirNextLTPro-Regular by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-regular/
 * Copyright: Copyright &#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 * Licensed pageviews: 500,000
 *
 * Webfont: AvenirNextLTPro-Medium by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-medium/
 * Copyright: Copyright &#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 * Licensed pageviews: 500,000
 *
 * Webfont: AvenirNextLTPro-ThinIt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-thin-italic/
 * Copyright: Copyright &#x00A9; 2012 - 2017 Monotype GmbH. All rights reserved.
 * Licensed pageviews: 500,000
 *
 * Webfont: AvenirNextLTPro-Thin by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-thin/
 * Copyright: Copyright &#x00A9; 2012 - 2017 Monotype GmbH. All rights reserved.
 * Licensed pageviews: 500,000
 *
 * Webfont: AvenirNextLTPro-UltLtIt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-ultralight-italic/
 * Copyright: Copyright &#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 * Licensed pageviews: 500,000
 *
 * Webfont: AvenirNextLTPro-UltLt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-ultralight/
 * Copyright: Copyright &#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 * Licensed pageviews: 500,000
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3594036
 *
 * © 2018 MyFonts Inc
 */

// TISA PRO

// Regular

import TisaProNextRegularEOT from 'assets/fonts/36D734_0_0.eot';
import TisaProNextRegularWOFF2 from 'assets/fonts/36D734_0_0.woff2';
import TisaProNextRegularWOFF from 'assets/fonts/36D734_0_0.woff';
import TisaProNextRegularTTF from 'assets/fonts/36D734_0_0.ttf';

// Regular italic

import TisaProNextLightEOT from 'assets/fonts/36D734_1_0.eot';
import TisaProNextLightWOFF2 from 'assets/fonts/36D734_1_0.woff2';
import TisaProNextLightWOFF from 'assets/fonts/36D734_1_0.woff';
import TisaProNextLightTTF from 'assets/fonts/36D734_1_0.ttf';

// AVENIR NEXT

// Ultra light

import AvenirNextUltraLightEOT from 'assets/fonts/36D734_11_0.eot';
import AvenirNextUltraLightWOFF2 from 'assets/fonts/36D734_11_0.woff2';
import AvenirNextUltraLightWOFF from 'assets/fonts/36D734_11_0.woff';
import AvenirNextUltraLightTTF from 'assets/fonts/36D734_11_0.ttf';

// Ultra light italic

import AvenirNextUltraLightItalicEOT from 'assets/fonts/36D734_10_0.eot';
import AvenirNextUltraLightItalicWOFF2 from 'assets/fonts/36D734_10_0.woff2';
import AvenirNextUltraLightItalicWOFF from 'assets/fonts/36D734_10_0.woff';
import AvenirNextUltraLightItalicTTF from 'assets/fonts/36D734_10_0.ttf';

// Thin

import AvenirNextThinEOT from 'assets/fonts/36D734_F_0.eot';
import AvenirNextThinWOFF2 from 'assets/fonts/36D734_F_0.woff2';
import AvenirNextThinWOFF from 'assets/fonts/36D734_F_0.woff';
import AvenirNextThinTTF from 'assets/fonts/36D734_F_0.ttf';

// Thin italic

import AvenirNextThinItalicEOT from 'assets/fonts/36D734_E_0.eot';
import AvenirNextThinItalicWOFF2 from 'assets/fonts/36D734_E_0.woff2';
import AvenirNextThinItalicWOFF from 'assets/fonts/36D734_E_0.woff';
import AvenirNextThinItalicTTF from 'assets/fonts/36D734_E_0.ttf';

// Light

import AvenirNextLightEOT from 'assets/fonts/36D734_A_0.eot';
import AvenirNextLightWOFF2 from 'assets/fonts/36D734_A_0.woff2';
import AvenirNextLightWOFF from 'assets/fonts/36D734_A_0.woff';
import AvenirNextLightTTF from 'assets/fonts/36D734_A_0.ttf';

// Light italic

import AvenirNextLightItalicEOT from 'assets/fonts/36D734_9_0.eot';
import AvenirNextLightItalicWOFF2 from 'assets/fonts/36D734_9_0.woff2';
import AvenirNextLightItalicWOFF from 'assets/fonts/36D734_9_0.woff';
import AvenirNextLightItalicTTF from 'assets/fonts/36D734_9_0.ttf';

// Regular

import AvenirNextRegularEOT from 'assets/fonts/36D734_C_0.eot';
import AvenirNextRegularWOFF2 from 'assets/fonts/36D734_C_0.woff2';
import AvenirNextRegularWOFF from 'assets/fonts/36D734_C_0.woff';
import AvenirNextRegularTTF from 'assets/fonts/36D734_C_0.ttf';

// Regular italic

import AvenirNextRegularItalicEOT from 'assets/fonts/36D734_8_0.eot';
import AvenirNextRegularItalicWOFF2 from 'assets/fonts/36D734_8_0.woff2';
import AvenirNextRegularItalicWOFF from 'assets/fonts/36D734_8_0.woff';
import AvenirNextRegularItalicTTF from 'assets/fonts/36D734_8_0.ttf';

// Medium

import AvenirNextMediumEOT from 'assets/fonts/36D734_D_0.eot';
import AvenirNextMediumWOFF2 from 'assets/fonts/36D734_D_0.woff2';
import AvenirNextMediumWOFF from 'assets/fonts/36D734_D_0.woff';
import AvenirNextMediumTTF from 'assets/fonts/36D734_D_0.ttf';

// Medium italic

import AvenirNextMediumItalicEOT from 'assets/fonts/36D734_B_0.eot';
import AvenirNextMediumItalicWOFF2 from 'assets/fonts/36D734_B_0.woff2';
import AvenirNextMediumItalicWOFF from 'assets/fonts/36D734_B_0.woff';
import AvenirNextMediumItalicTTF from 'assets/fonts/36D734_B_0.ttf';

// SemiBold (a.k.a demi)

import AvenirNextSemiBoldEOT from 'assets/fonts/36D734_4_0.eot';
import AvenirNextSemiBoldWOFF2 from 'assets/fonts/36D734_4_0.woff2';
import AvenirNextSemiBoldWOFF from 'assets/fonts/36D734_4_0.woff';
import AvenirNextSemiBoldTTF from 'assets/fonts/36D734_4_0.ttf';

// SemiBold (a.k.a demi) italic

import AvenirNextSemiBoldItalicEOT from 'assets/fonts/36D734_5_0.eot';
import AvenirNextSemiBoldItalicWOFF2 from 'assets/fonts/36D734_5_0.woff2';
import AvenirNextSemiBoldItalicWOFF from 'assets/fonts/36D734_5_0.woff';
import AvenirNextSemiBoldItalicTTF from 'assets/fonts/36D734_5_0.ttf';

// Bold

import AvenirNextBoldEOT from 'assets/fonts/36D734_2_0.eot';
import AvenirNextBoldWOFF2 from 'assets/fonts/36D734_2_0.woff2';
import AvenirNextBoldWOFF from 'assets/fonts/36D734_2_0.woff';
import AvenirNextBoldTTF from 'assets/fonts/36D734_2_0.ttf';

// Bold italic

import AvenirNextBoldItalicEOT from 'assets/fonts/36D734_3_0.eot';
import AvenirNextBoldItalicWOFF2 from 'assets/fonts/36D734_3_0.woff2';
import AvenirNextBoldItalicWOFF from 'assets/fonts/36D734_3_0.woff';
import AvenirNextBoldItalicTTF from 'assets/fonts/36D734_3_0.ttf';

// Heavy

import AvenirNextHeavyEOT from 'assets/fonts/36D734_7_0.eot';
import AvenirNextHeavyWOFF2 from 'assets/fonts/36D734_7_0.woff2';
import AvenirNextHeavyWOFF from 'assets/fonts/36D734_7_0.woff';
import AvenirNextHeavyTTF from 'assets/fonts/36D734_7_0.ttf';

// Heavy italic

import AvenirNextHeavyItalicEOT from 'assets/fonts/36D734_6_0.eot';
import AvenirNextHeavyItalicWOFF2 from 'assets/fonts/36D734_6_0.woff2';
import AvenirNextHeavyItalicWOFF from 'assets/fonts/36D734_6_0.woff';
import AvenirNextHeavyItalicTTF from 'assets/fonts/36D734_6_0.ttf';

const fontStyles = css`
    @import url('//hello.myfonts.net/count/36d734');

    @font-face {
        font-family: 'TisaPro';
        font-style: normal;
        font-weight: 300;
        src: url(${TisaProNextLightEOT});
        src:
            url(${TisaProNextLightEOT}?#iefix) format('embedded-opentype'),
            url(${TisaProNextLightWOFF2}) format('woff2'),
            url(${TisaProNextLightWOFF}) format('woff'),
            url(${TisaProNextLightTTF}) format('truetype');
    }

    @font-face {
        font-family: 'TisaPro';
        font-style: normal;
        font-weight: 400;
        src: url(${TisaProNextRegularEOT});
        src:
            url(${TisaProNextRegularEOT}?#iefix) format('embedded-opentype'),
            url(${TisaProNextRegularWOFF2}) format('woff2'),
            url(${TisaProNextRegularWOFF}) format('woff'),
            url(${TisaProNextRegularTTF}) format('truetype');
    }

    @font-face {
        font-family: 'AvenirNext';
        font-style: normal;
        font-weight: 100;
        src: url('${AvenirNextUltraLightEOT}');
        src:
            url('${AvenirNextUltraLightEOT}?#iefix') format('embedded-opentype'),
            url('${AvenirNextUltraLightWOFF2}') format('woff2'),
            url('${AvenirNextUltraLightWOFF}') format('woff'),
            url('${AvenirNextUltraLightTTF}') format('truetype');
    }

    @font-face {
        font-family: 'AvenirNext';
        font-style: italic;
        font-weight: 100;
        src: url('${AvenirNextUltraLightItalicEOT}');
        src:
            url('${AvenirNextUltraLightItalicEOT}?#iefix') format('embedded-opentype'),
            url('${AvenirNextUltraLightItalicWOFF2}') format('woff2'),
            url('${AvenirNextUltraLightItalicWOFF}') format('woff'),
            url('${AvenirNextUltraLightItalicTTF}') format('truetype');
    }

    @font-face {
        font-family: 'AvenirNext';
        font-style: normal;
        font-weight: 200;
        src: url('${AvenirNextThinEOT}');
        src:
            url('${AvenirNextThinEOT}?#iefix') format('embedded-opentype'),
            url('${AvenirNextThinWOFF2}') format('woff2'),
            url('${AvenirNextThinWOFF}') format('woff'),
            url('${AvenirNextThinTTF}') format('truetype');
    }

    @font-face {
        font-family: 'AvenirNext';
        font-style: italic;
        font-weight: 200;
        src: url('${AvenirNextThinItalicEOT}');
        src:
            url('${AvenirNextThinItalicEOT}?#iefix') format('embedded-opentype'),
            url('${AvenirNextThinItalicWOFF2}') format('woff2'),
            url('${AvenirNextThinItalicWOFF}') format('woff'),
            url('${AvenirNextThinItalicTTF}') format('truetype');
    }

    @font-face {
        font-family: 'AvenirNext';
        font-style: normal;
        font-weight: 300;
        src: url('${AvenirNextLightEOT}');
        src:
            url('${AvenirNextLightEOT}?#iefix') format('embedded-opentype'),
            url('${AvenirNextLightWOFF2}') format('woff2'),
            url('${AvenirNextLightWOFF}') format('woff'),
            url('${AvenirNextLightTTF}') format('truetype');
    }

    @font-face {
        font-family: 'AvenirNext';
        font-style: italic;
        font-weight: 300;
        src: url('${AvenirNextLightItalicEOT}');
        src:
            url('${AvenirNextLightItalicEOT}?#iefix') format('embedded-opentype'),
            url('${AvenirNextLightItalicWOFF2}') format('woff2'),
            url('${AvenirNextLightItalicWOFF}') format('woff'),
            url('${AvenirNextLightItalicTTF}') format('truetype');
    }

    @font-face {
        font-family: 'AvenirNext';
        font-style: normal;
        font-weight: 400;
        src: url('${AvenirNextRegularEOT}');
        src:
            url('${AvenirNextRegularEOT}?#iefix') format('embedded-opentype'),
            url('${AvenirNextRegularWOFF2}') format('woff2'),
            url('${AvenirNextRegularWOFF}') format('woff'),
            url('${AvenirNextRegularTTF}') format('truetype');
    }

    @font-face {
        font-family: 'AvenirNext';
        font-style: italic;
        font-weight: 400;
        src: url('${AvenirNextRegularItalicEOT}');
        src:
            url('${AvenirNextRegularItalicEOT}?#iefix') format('embedded-opentype'),
            url('${AvenirNextRegularItalicWOFF2}') format('woff2'),
            url('${AvenirNextRegularItalicWOFF}') format('woff'),
            url('${AvenirNextRegularItalicTTF}') format('truetype');
    }

    @font-face {
        font-family: 'AvenirNext';
        font-style: normal;
        font-weight: 500;
        src: url('${AvenirNextMediumEOT}');
        src:
            url('${AvenirNextMediumEOT}?#iefix') format('embedded-opentype'),
            url('${AvenirNextMediumWOFF2}') format('woff2'),
            url('${AvenirNextMediumWOFF}') format('woff'),
            url('${AvenirNextMediumTTF}') format('truetype');
    }

    @font-face {
        font-family: 'AvenirNext';
        font-style: italic;
        font-weight: 500;
        src: url('${AvenirNextMediumItalicEOT}');
        src:
            url('${AvenirNextMediumItalicEOT}?#iefix') format('embedded-opentype'),
            url('${AvenirNextMediumItalicWOFF2}') format('woff2'),
            url('${AvenirNextMediumItalicWOFF}') format('woff'),
            url('${AvenirNextMediumItalicTTF}') format('truetype');
    }

    @font-face {
        font-family: 'AvenirNext';
        font-style: normal;
        font-weight: 600;
        src: url('${AvenirNextSemiBoldEOT}');
        src:
            url('${AvenirNextSemiBoldEOT}?#iefix') format('embedded-opentype'),
            url('${AvenirNextSemiBoldWOFF2}') format('woff2'),
            url('${AvenirNextSemiBoldWOFF}') format('woff'),
            url('${AvenirNextSemiBoldTTF}') format('truetype');
    }

    @font-face {
        font-family: 'AvenirNext';
        font-style: italic;
        font-weight: 600;
        src: url('${AvenirNextSemiBoldItalicEOT}');
        src:
            url('${AvenirNextSemiBoldItalicEOT}?#iefix') format('embedded-opentype'),
            url('${AvenirNextSemiBoldItalicWOFF2}') format('woff2'),
            url('${AvenirNextSemiBoldItalicWOFF}') format('woff'),
            url('${AvenirNextSemiBoldItalicTTF}') format('truetype');
    }

    @font-face {
        font-family: 'AvenirNext';
        font-style: normal;
        font-weight: 700;
        src: url('${AvenirNextBoldEOT}');
        src:
            url('${AvenirNextBoldEOT}?#iefix') format('embedded-opentype'),
            url('${AvenirNextBoldWOFF2}') format('woff2'),
            url('${AvenirNextBoldWOFF}') format('woff'),
            url('${AvenirNextBoldTTF}') format('truetype');
    }

    @font-face {
        font-family: 'AvenirNext';
        font-style: italic;
        font-weight: 700;
        src: url('${AvenirNextBoldItalicEOT}');
        src:
            url('${AvenirNextBoldItalicEOT}?#iefix') format('embedded-opentype'),
            url('${AvenirNextBoldItalicWOFF2}') format('woff2'),
            url('${AvenirNextBoldItalicWOFF}') format('woff'),
            url('${AvenirNextBoldItalicTTF}') format('truetype');
    }

    @font-face {
        font-family: 'AvenirNext';
        font-style: normal;
        font-weight: 900;
        src: url('${AvenirNextHeavyEOT}');
        src:
            url('${AvenirNextHeavyEOT}?#iefix') format('embedded-opentype'),
            url('${AvenirNextHeavyWOFF2}') format('woff2'),
            url('${AvenirNextHeavyWOFF}') format('woff'),
            url('${AvenirNextHeavyTTF}') format('truetype');
    }

    @font-face {
        font-family: 'AvenirNext';
        font-style: italic;
        font-weight: 900;
        src: url('${AvenirNextHeavyItalicEOT}');
        src:
            url('${AvenirNextHeavyItalicEOT}?#iefix') format('embedded-opentype'),
            url('${AvenirNextHeavyItalicWOFF2}') format('woff2'),
            url('${AvenirNextHeavyItalicWOFF}') format('woff'),
            url('${AvenirNextHeavyItalicTTF}') format('truetype');
    }
`;

export default fontStyles;
