import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { preventClickClassName } from 'components/PreventClick/PreventClick';

class ClickableContainer extends React.Component {
    shouldPrevent = node => node && node.nodeName && this.props.ignoredTags.includes(node.nodeName);

    /**
     * Handle click of container.
     * @param {*} event 
     */
    handleClick = (event) => {
        const { history, url } = this.props;

        if (!event) {
            return;
        }

        if (url == '') {
            event.preventDefault();
            this.props.onClick();

            return;
        }

        let currentNode = event.target;
        while (currentNode && currentNode.classList) {
            if (currentNode.classList.contains(preventClickClassName)
                || this.shouldPrevent(currentNode)
            ) {
                return;
            }
            currentNode = currentNode.parentNode;
        }

        this.props.onClick();

        if (url.startsWith('http')) {
            window.open(url, '_blank');
        } else {
            history.push(url);
        }
    };

    render() {
        const {
            children,
            className,
            id,
            passedRef,
            tag,
        } = this.props;
        const containerProps = {
            className,
            id,
            onClick: this.handleClick,
            ref: passedRef,
        };

        return React.createElement(
            tag,
            containerProps,
            children,
        );
    }
}

ClickableContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    className: PropTypes.string,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    ignoredTags: PropTypes.arrayOf(PropTypes.string),
    tag: PropTypes.string,
    url: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    passedRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({}),
    ]),
};

ClickableContainer.defaultProps = {
    children: null,
    className: '',
    id: '',
    ignoredTags: [
        'A',
        'BUTTON',
        'INPUT',
    ],
    tag: 'div',
    onClick: () => { },
    passedRef: null,
};

export default withRouter(ClickableContainer);
