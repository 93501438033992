import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Image from 'components/atomics/atoms/Image/Image';
import { Heading, SanitizedHTML } from 'components';
import {
    bodyCopyWrapperStyles,
    bodyCopyHeaderContainerStyles,
    bodyCopyTitleStyles,
    bodyCopyDescriptionStyles,
    bodyCopyContentWrapperStyles,
    bodyCopyContentImageStyles,
    bodyCopyContentStyles,
} from 'containers/Guide/BodyCopy/styles';
import { HBR, MC_KINSEY, STANDARD, INPRACTICE } from 'containers/Guide/guideTypes';

export const StyledBodyCopyWrapper = styled.section`
    ${bodyCopyWrapperStyles};
`;

export const StyledBodyCopyHeaderContainer = styled.header`
    ${bodyCopyHeaderContainerStyles};
`;

export const StyledBodyCopyTitle = styled(Heading)`
    ${bodyCopyTitleStyles};
`;

export const StyledBodyCopyDescription = styled(SanitizedHTML)`
    ${bodyCopyDescriptionStyles};
`;

export const StyledBodyCopyContentWrapper = styled.div`
    ${bodyCopyContentWrapperStyles};
`;

export const StyledBodyCopyContent = styled(SanitizedHTML)`
    ${bodyCopyContentStyles};
`;

const renderFields = fields => (fields.map(field => (
    <StyledBodyCopyContentWrapper key={field.id} id={`body-copy-field-${field.id}`}>
        {field.image && <Image src={field.image} />}
        <StyledBodyCopyContent htmlContent={field.bodyHeader} tag="div" />
    </StyledBodyCopyContentWrapper>)));

/**
 * Render the main guide body area.
 * @param {*} props 
 */
const BodyCopy = (props) => {
    const {
        sectionHeadline,
        sectionDescription,
        fields,
        guideType,
    } = props.data;

    return (
        BodyCopy.areAttributesValid(props.data) && (
            <StyledBodyCopyWrapper>
                <StyledBodyCopyHeaderContainer>
                    <StyledBodyCopyTitle level={2}>{sectionHeadline}</StyledBodyCopyTitle>
                    <StyledBodyCopyDescription
                        id={`body-copy-${props.id}`}
                        aria-labelledby={`title-${props.id}`}
                        htmlContent={sectionDescription.replace("►", "")}
                        tag="div"
                        isguidestandard={(guideType === STANDARD).toString()}
                    />
                </StyledBodyCopyHeaderContainer>
                {renderFields(fields)}
            </StyledBodyCopyWrapper>
        ));
};

BodyCopy.areAttributesValid = ({ sectionDescription, fields }) => (
    sectionDescription && fields.length !== 0
);

BodyCopy.propTypes = {
    id: PropTypes.string.isRequired,
    data: PropTypes.shape({
        sectionDescription: PropTypes.string,
        fields: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            bodyHeader: PropTypes.string,
            image: PropTypes.string,
        })),
        guideType: PropTypes.oneOf([
            STANDARD,
            HBR,
            MC_KINSEY,
            INPRACTICE,
        ]),
        note: PropTypes.string,
        noteHeader: PropTypes.string,
        sectionHeadline: PropTypes.string,
    }).isRequired,
};

export default BodyCopy;