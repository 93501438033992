import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import {
    FONT_AVENIR_NEXT,
    COLORS,
} from 'utils/variables';

export const popupHolderStyles = css`
    background-color: ${COLORS.LIGHT_GRAY1};
    box-shadow: 0 2px 14px 0 ${COLORS.MEDIUM_GRAY2};
    box-sizing: border-box;
    margin: 0 15px;
    max-width: 866px;
    padding: 15px;

    @media screen and ${FROM_TABLET} {
        margin: 0 45px;
        padding: 20px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin: 0 auto;
        padding: 25px;
    }
`;

export const popupContentWrapperStyles = css`
    position: relative;
`;

export const popupContentContainerStyles = css`
    margin: 0 0 10px;

    @media screen and ${FROM_TABLET} {
        margin: 33px 45px 30px 45px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin: 48px 60px 40px 60px;
    }
`;

export const popupTitleStyles = css`
    color: ${COLORS.BLACK};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    letter-spacing: -0.1px;
    margin: -10px 0 25px 0;
    padding: 0 20px 0 0;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        font-size: 20px;
        line-height: 26px;
        margin: 0 0 45px 0;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 26px;
        line-height: 32px;
        letter-spacing: -0.1px;
        margin: 0 0 45px 0;
    }
`;

export const popupContentStyles = css`
    box-sizing: border-box;
    color: ${COLORS.MEDIUM_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 15px;
    line-height: 20px;
    max-height: 55vh;
    overflow-y: auto;
    padding: 5px 24px 0 24px;
    position: relative;
    word-wrap: break-word;
    margin-bottom: 20px;
    border: 1px solid ${COLORS.DARK_GRAY2};

    &:focus {
        outline: none;
        border: 1px solid ${COLORS.SAPPHIRE};
    }
    
    > :first-child {
        margin-top: 0;
    }

    @media screen and ${FROM_TABLET} {
        font-size: 18px;
        line-height: 28px;
        max-height: 40vh;
    }

    @media screen and ${FROM_DESKTOP} {
        max-height: 50vh;
    }

    h2 {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;

        @media screen and ${FROM_TABLET} {
            font-size: 20px;
            line-height: 24px;
        }

        @media screen and ${FROM_DESKTOP} {
            font-size: 22px;
            line-height: 26px;
        }
    }
`;

export const popupControlsStyles = css`
    align-items: center;
    display: flex;
    flex-direction: column;

    @media screen and ${FROM_TABLET} {
        justify-content: center;
        flex-direction: row;
    }
`;

export const acceptButtonStyles = css`
    display: block;
    margin-bottom: 16px;
    width: 90%;

    /* stylelint-disable */

    ${props => props.isDisabled && css`
        background-color: ${COLORS.LIGHT_GRAY3};
        border: 1px solid ${COLORS.LIGHT_GRAY3};
        color: ${COLORS.WHITE};

        &:hover {
            background-color: ${COLORS.LIGHT_GRAY3};
            border: 1px solid ${COLORS.LIGHT_GRAY3};
            color: ${COLORS.WHITE};
            cursor: not-allowed;
        }
    `}

    /* stylelint-enable */

    @media screen and ${FROM_TABLET} {
        display: inline-block;
        width: 100%;       
        margin-bottom: 0;
    }

    @media screen and ${FROM_DESKTOP} {
        width: 100%;       
    
    }
`;

export const declineButtonStyles = css`
    background: transparent;
    font-size: 12px;
    border: none;
    
    &:hover {
        color: ${COLORS.DARK_SAPPHIRE};
    }
    
    @media screen and ${FROM_TABLET} {
        width: 200px;
    }
`;

export const popupHeaderControlsStyles = css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export const scrollDownMessageStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 26px;
    margin-top: 26px;
    text-align: center;
    display: none;
`;

export const ContainerStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    padding: 20px 0 20px 0;
`;
