import React from 'react';
import PropTypes from 'prop-types';

import { PROVIDER_DOW_JONES, PROVIDER_LEXIS_NEXIS,PROVIDER_CAPITAL_GROUP  } from 'state-management/constants/providers';

const FormattedProvider = ({ provider }) => {
    switch (provider) {
    case PROVIDER_DOW_JONES:
        return <span>Dow Jones</span>;
    case PROVIDER_LEXIS_NEXIS:
        return <span>LexisNexis</span>;
        case PROVIDER_CAPITAL_GROUP:
            return <span>Capital Group</span>
    default:
        return null;
    }
};

FormattedProvider.propTypes = {
    provider: PropTypes.string.isRequired,
};

export default FormattedProvider;
