import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { injectIntl, intlShape } from 'react-intl';
import { ROUTES } from 'containers/App/constants';
import Navigation from 'components/Navigation/Navigation';
import MobileAppNotification from 'containers/MobileAppNotification/MobileAppNotification';
import {
    Container,
    FooterLogo,
    SanitizedHTML,
} from 'components';
import {
    footerStyles,
    containerStyles,
    contentWrapperStyles,
    disclaimerWrapperStyles,
    logoContainerStyles,
    navigationStyles,
} from 'components/Footer/styles';

const StyledFooter = styled.footer`
    ${footerStyles}
`;

const StyledContainer = styled(Container)`
    ${containerStyles}
`;

const StyledContentWrapper = styled.div`
    ${contentWrapperStyles}
`;

const StyledDisclaimerWrapper = styled(SanitizedHTML)`
    ${disclaimerWrapperStyles}
`;

const StyledLogoContainer = styled.div`
    ${logoContainerStyles}
`;

const StyledNavigation = styled(Navigation)`
    ${navigationStyles}
`;

const Footer = (props) => {
    let routes = [
        {
            to: ROUTES.ABOUT_LUMIN,
            title: props.intl.formatMessage({ id: 'footer.title.about' }),
        },
        {
            to: 'https://www.capitalgroup.com/individual/privacy.html',
            title: props.intl.formatMessage({ id: 'footer.title.privacy' }),
        },
        {
            to: ROUTES.TERMS_AND_CONDITIONS,
            title: props.intl.formatMessage({ id: 'footer.title.terms' }),
        },
        {
            to: 'https://www.capitalgroup.com/advisor/trademarks.html',
            title: props.intl.formatMessage({ id: 'footer.title.trademarks' }),
        },
        {
            to: ROUTES.CONTACT_US,
            title: props.intl.formatMessage({ id: 'footer.title.contactUs' }),
        },
    ];

    if (props.forceMigration) {
        routes = [
            {
                to: 'https://www.capitalgroup.com/individual/privacy.html',
                title: props.intl.formatMessage({ id: 'footer.title.privacy' }),
            },
            {
                to: ROUTES.TERMS_AND_CONDITIONS,
                title: props.intl.formatMessage({ id: 'footer.title.terms' }),
            },
            {
                to: 'https://www.capitalgroup.com/advisor/trademarks.html',
                title: props.intl.formatMessage({ id: 'footer.title.trademarks' }),
            },
        ];
    }

    return (
        <StyledFooter>
            <MobileAppNotification
            />
            <StyledContainer>
                <StyledContentWrapper>
                    <StyledLogoContainer>
                        <FooterLogo
                            showPoweredBy
                        />
                    </StyledLogoContainer>
                    <StyledNavigation
                        id="footer-nav"
                        routes={routes}
                        forceMigration={props.forceMigration}
                        title={props.intl.formatMessage({ id: 'footer.title.footer' })}
                    />
                </StyledContentWrapper>
                {props.disclaimer && (
                    <StyledDisclaimerWrapper
                        htmlContent={props.disclaimer}
                        tag="div"
                    />
                )}
                <div className="evidon-notice-link"></div>
            </StyledContainer>
        </StyledFooter>
    );
};

Footer.propTypes = {
    disclaimer: PropTypes.string,
    intl: intlShape.isRequired,
    forceMigration: PropTypes.bool,
};

Footer.defaultProps = {
    disclaimer: undefined,
    forceMigration: false,
};

export default injectIntl(Footer);
