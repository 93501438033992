import { all, fork } from 'redux-saga/effects';

import advisorClassificationSaga from 'state-management/sagas/advisorClassifications';
import articlesSaga from 'state-management/sagas/articles.common';
import basicProfile from 'state-management/sagas/basicProfile';
import basicProfileForm from 'state-management/sagas/basicProfileForm';
import categories from 'state-management/sagas/categories';
import coachMarkSaga from 'state-management/sagas/coachMark';
import commonContentFragments from 'state-management/sagas/commonContentFragments';
import contactUs from 'state-management/sagas/contactUs';
import forgotPasswordSaga from 'state-management/sagas/forgotPassword';
import forgotUsernameSaga from 'state-management/sagas/forgotUsername';
import guide from 'state-management/sagas/guide';
import homepage from 'state-management/sagas/homepage.common';
import legalAgreement from 'state-management/sagas/legalAgreement.common';
import navigation from 'state-management/sagas/navigation';
import newsSaga from 'state-management/sagas/news';
import optinSaga from 'state-management/sagas/optin.common';
import featureToggleSaga from 'state-management/sagas/featureToggle.common';
import requestSourceSaga from 'state-management/sagas/requestSource';
import save from 'state-management/sagas/save';
import savedContent from 'state-management/sagas/savedContent';
import signIn from 'state-management/sagas/signIn';
import signOut from 'state-management/sagas/signOut';
import signUp from 'state-management/sagas/signUp';
import search from 'state-management/sagas/search';
import searchFilter from 'state-management/sagas/searchFilter';
import sources from 'state-management/sagas/sources';
import toasts from 'state-management/sagas/toasts';
import topics from 'state-management/sagas/topics';
import allTopics from 'state-management/sagas/allTopics.common';
import tools from 'state-management/sagas/tools';
import adobeAnalytics from 'state-management/sagas/adobeAnalytics';
import previousUrl from 'state-management/sagas/previousUrl';
import trendingTopic from 'state-management/sagas/trendingTopic.common';
import toggleFollowTopicSaga from 'state-management/sagas/followTopic';
import profileRefinementCard from 'state-management/sagas/profileRefinementCard';
import globalDisclaimer from 'state-management/sagas/globalDisclaimer';
import location from 'state-management/sagas/location';
import marketBriefing from 'state-management/sagas/marketBriefing.common';
import marketIndices from 'state-management/sagas/marketIndices.common';
import downloadSaga from 'state-management/sagas/download';
import webinarSaga from 'state-management/sagas/webinar';
export default function* root() {
    yield all([
        fork(advisorClassificationSaga),
        fork(articlesSaga),
        fork(basicProfile),
        fork(basicProfileForm),
        fork(categories),
        fork(coachMarkSaga),
        fork(commonContentFragments),
        fork(contactUs),
        fork(forgotPasswordSaga),
        fork(forgotUsernameSaga),
        fork(guide),
        fork(homepage),
        fork(legalAgreement),
        fork(navigation),
        fork(newsSaga),
        fork(optinSaga),
        fork(featureToggleSaga),
        fork(requestSourceSaga),
        fork(save),
        fork(savedContent),
        fork(signIn),
        fork(signOut),
        fork(signUp),
        fork(search),
        fork(searchFilter),
        fork(sources),
        fork(toasts),
        fork(topics),
        fork(allTopics),
        fork(profileRefinementCard),
        fork(tools),
        fork(adobeAnalytics),
        fork(previousUrl),
        fork(trendingTopic),
        fork(toggleFollowTopicSaga),
        fork(globalDisclaimer),
        fork(location),
        fork(marketBriefing),
        fork(marketIndices),
        fork(downloadSaga),
        fork(webinarSaga),
    ]);
}
