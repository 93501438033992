import {
    GET_ARTICLE_SUCCESS, GET_ARTICLE_FAILURE, GET_ARTICLE,
} from 'state-management/constants/articles.common';
import * as saveTypes from 'state-management/constants/save';

const initialState = {
    isLoading: false,
    article: {
        authors: '',
        content: '',
        image: {},
        provider: '',
        publishDate: '',
        saved: false,
        source: {},
        title: '',
        topics: [],
    },
    isSaving: false,
};

function articlesReducer(state = initialState, action = {}) {
    switch (action.type) {
    case GET_ARTICLE:
        return {
            ...state,
            isLoading: true,
        };
    case GET_ARTICLE_SUCCESS:
        return {
            ...state,
            article: action.article,
            isLoading: false,
        };
    case GET_ARTICLE_FAILURE:
        return {
            ...state,
            isLoading: false,
        };
    case saveTypes.TOGGLE_SAVE_ARTICLE: {
        const { save } = action;

        return {
            ...state,
            article: {
                ...state.article,
                saved: save,
            },
            isSaving: true,
        };
    }
    case saveTypes.TOGGLE_SAVE_ARTICLE_FAILURE: {
        return {
            ...state,
            article: {
                ...state.article,
                saved: !state.article.saved,
            },
            isSaving: false,
        };
    }
    case saveTypes.TOGGLE_SAVE_ARTICLE_SUCCESS: {
        return {
            ...state,
            isSaving: false,
        };
    }
    default:
        return state;
    }
}

export default articlesReducer;
