import { css } from 'styled-components';
import {
    FROM_DESKTOP,
    FROM_TABLET,
    FROM_TABLET_TO_DESKTOP,
} from 'utils/mediaQueries';
import {
    FONT_AVENIR_NEXT,
    COLORS,
    ANIMATIONS,
} from 'utils/variables';

export const categoryListStyles = css`
    padding-top: 20px;
`;

export const categoryContainerStyles = css`
    display: block;
    margin-bottom: 40px;

    h4 {
        letter-spacing: 1.8px;
        font-size: 12px;
        font-weight: 500;
        color: ${COLORS.DARK_GRAY2};
        margin-bottom: -10px;
        text-transform: uppercase;
    }

    &>div:first-child {
        display: flex;
        flex-direction: row;

        &>h4,
        &>div {
            margin-top: 20px;
            flex: 1;
        }

        &>div {
            text-align: right;
            cursor: pointer;
            font-size: 12px;
            letter-spacing: 1.8px;
            white-space: nowrap;
            font-weight: 700;
            transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;
            color: ${COLORS.SAPPHIRE};
            text-transform: uppercase;

            &:hover {
                color: ${COLORS.DARK_SAPPHIRE};
            }
        }
    }

    &>div:last-child {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-top: 30px;

        @media screen and ${FROM_TABLET_TO_DESKTOP} {
            &>label {
                width: calc(50% - 24px);
                flex-basis: initial;
                margin-right: 48px !important;
            }

            &>label:nth-child(2n) {
                margin-right: 0 !important;
            }
        }

        @media screen and ${FROM_DESKTOP} {
            &>label {
                margin-right: 24px !important;
                width: calc(32% - 16px);
                flex-basis: initial;
            }
        }
    }
`;

export const listStyles = css`
    display: flex;
    flex-direction: column;
    margin: 5px 0 0;

    @media screen and ${FROM_DESKTOP} {
        margin: 10px 0 0;
    }
`;

export const categorySectionWrapperStyles = css`
    margin-bottom: 24px;

    @media screen and ${FROM_TABLET} {
        margin-bottom: 46px;
    }
`;

export const categoryActionButtonStyles = css`
    background: transparent;
    border: none;
    color: ${COLORS.SAPPHIRE};
    cursor: pointer;
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 15px;
    font-weight: 700;
    letter-spacing: -0.1px;
    line-height: 0.93;
    margin-bottom: 20px;
    padding: 0;

    @media screen and ${FROM_TABLET} {
        font-size: 18px;
        line-height: 0.78;
    }
`;
