import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import {
    containerStyles,
    dateContainer,
} from 'components/atomics/atoms/DateDisplay/styles';

export const DateDisplayContainer = styled.div`
    ${containerStyles}
`;

export const DateContainer = styled.div`
    ${dateContainer}
`;

/**
 * A countdown component used for webinar date displays.
 */
class DateDisplay extends React.Component {
    /**
     * Render the countdown component.
     */
    render() {
        const { timestamp, timezone, dataPage } = this.props;

        if (!timestamp || !timezone) {
            return;
        }

        const today = new Date().getTime();

        const delta = timestamp - today;
      
        const dateString = `${moment(timestamp).format('MMM D, YYYY | HH:mm A')} ${timezone}`;

        return (
            <DateDisplayContainer dataPage={dataPage} data-rel="DateDisplayContainer">
                <DateContainer dataPage={dataPage}>
                    {this.props.showWebinarPrefix
                    && <span>
                        <FormattedMessage id="webinar.promo.prefix" />
                        &nbsp;
                    </span>}
                    {dateString}
                </DateContainer>                  
            </DateDisplayContainer>
        );
    }
}

DateDisplay.propTypes = {
    timestamp: PropTypes.number,
    timezone: PropTypes.string,
    showWebinarPrefix: PropTypes.bool,
}

DateDisplay.defaultProps = {
    timestamp: null,
    timezone: 'PST',
    showWebinarPrefix: false,
}

export default DateDisplay;
