import React from 'react';;
import styled from 'styled-components';
import { withCommonCard } from 'components/atomics/hocs/CommonCard/CommonCard';
import Link from 'components/atomics/atoms/Link/Link';
import { TOPIC_LINK } from 'state-management/constants/topics';
import moment from 'moment';
import ImageAtom from 'components/atomics/atoms/Image/Image';
import CoachMarks from './../../../../containers/CoachMarks/CoachMarks';
import { TYPES } from './../../../../containers/CoachMarks/CoachMarks';
import {
    getArticleUrl,
    getArticleImage,
} from 'utils/getArticleAsset';
import {
    containerStyles,
    imageStyles,
    contentContainerStyles,
    dateAndTopicContainerStyles,
    dateContainerStyles,
    topicLinkStyles,
    titleStyles,
    imageContainerStyles,
} from 'components/atomics/molecules/HeroContentCard/styles';
import ListContentCardFooter from "../ListContentCard/ListContentCardFooter";
import { getTruncatedString } from 'utils/contentCardUtils';
import language from '../../../../lang/en.json';

export const ImageContainer = styled(Link)`
    ${imageContainerStyles}
`;

export const Title = styled(Link)`
    ${titleStyles}
`;

export const DateAndTopic = styled.div`
    ${dateAndTopicContainerStyles}
`;

export const DateContainer = styled.div`
    ${dateContainerStyles}
`;

export const TopicLink = styled(Link)`
    ${topicLinkStyles}
`;

export const Container = styled.div`
    ${containerStyles}
`;

export const Image = styled(ImageAtom)`
    ${imageStyles}
`;

export const ContentContainer = styled.div`
    ${contentContainerStyles}
`;

const MAX_ARTICLE_TITLE_CHARS = 110;

/**
 * Creates a large hero content card.
 */
class HeroContentCard extends React.Component {
    /**
     * Render this hero card and underlying components.
     */
    render() {
        const linkUrl = getArticleUrl(this.props.article);
        const imageUrl = getArticleImage(this.props.article, 1);
        const { onCoachMarkDismiss, showCoachMark } = this.props;

        return (
            <Container
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this.props.onMouseLeave}>
                <ImageContainer
                    visited={this.props.article.visited ? "true" : undefined}
                    to={linkUrl}
                    onClick={() => {
                        this.props.recordClickAnalytics();
                        this.props.onArticleClick();
                    }}
                >
                    <Image
                        src={imageUrl}
                        alt={language.translations['global.alt.image']}
                    />
                </ImageContainer>
                <ContentContainer>
                    <DateAndTopic>
                        <DateContainer>
                            {moment.utc(this.props.article.date).format('MMM D, YYYY')}
                        </DateContainer>
                        <TopicLink
                            data-analytics-placement="Button : body"
                            data-analytics-label="trackLink : button"
                            data-analytics-id={"topic:" + this.props.article.topicName}

                            to={TOPIC_LINK(`${this.props.article.topicName}/${this.props.article.topicId}`)}
                        ><span>{this.props.article.topicName}</span>
                        </TopicLink>
                    </DateAndTopic>
                    <Title
                        visited={this.props.article.visited ? "true" : undefined}
                        to={linkUrl}
                        onClick={() => {
                            this.props.recordClickAnalytics();
                            this.props.onArticleClick();
                        }}
                    >
                        {getTruncatedString(this.props.article.title, MAX_ARTICLE_TITLE_CHARS)}
                    </Title>
                    <ListContentCardFooter
                        onElement={showCoachMark ? this.props.showCoachMark : this.props.onCardHover}
                        setKebabMenuLimitSize={375}
                        profile={this.props.profile}
                        sourceName={this.props.article.sourceName}
                        providerName={this.props.article.provider}
                        article={this.props.article}
                        topics={this.props.article.topics}
                        sourceId={this.props.article.sourceId}
                        topicName={this.props.article.topicName}
                        saved={this.props.article.saved}
                        articleId={parseInt(this.props.article.id)}
                        articleTitle={this.props.article.title}
                        type={this.props.article.type || this.props.article.contentType}
                        shouldShowToast={this.props.shouldShowToast}
                        kebabButtonClickListener={() => showCoachMark ? onCoachMarkDismiss() : null}
                        onSaveArticle={(id, save, title, getContentAfterSave, article) => {
                            this.props.toggleSaveArticle(id, save, title, getContentAfterSave, article);
                        }}
                        onSaveGuide={(id, save, title, getContentAfterSave, article) => {
                            this.props.toggleSaveGuide(id, save, title, getContentAfterSave, article);
                        }}
                        onFollowTopic={(topicId, status) => {
                            this.props.toggleFollowTopic(topicId, status);
                        }}
                        onFollowSource={(sourceId, status) => {
                            this.props.toggleFollowSource(sourceId, status);
                        }}>
                        {showCoachMark ? (
                            <CoachMarks
                                showCoachMark={showCoachMark}
                                type={TYPES.KEBAB}
                            >
                                <div data-rel="cmPlaceholder" />
                            </CoachMarks>) : null}
                    </ListContentCardFooter>
                </ContentContainer>
            </Container>
        );
    }
}

export default withCommonCard(HeroContentCard);
