import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import {
    FONT_AVENIR_NEXT,
    COLORS,
} from 'utils/variables';

export const categoryWrapperStyles = css`
    margin: 60px 0;

    &:first-child {
        margin-top: 30px;
    }

    @media screen and ${FROM_TABLET} {
        &:first-child {
            margin-top: 0;
        }
    }
`;

export const categoryHeaderStyles = css`
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
`;

export const categoryNameStyles = css`
    box-sizing: border-box;
    color: ${COLORS.DARK_GRAY2};
    font-size: 12px;
    letter-spacing: 1.8px;
    font-weight: 500;
    padding: 0 20px 0 0;
    width: 70%;
    word-wrap: break-word;
    text-transform: uppercase;
`;

export const categoryHeaderActionStyles = css`
    background: transparent;
    border: none;
    color: ${COLORS.SAPPHIRE};
    cursor: pointer;
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    padding: 0;
    text-align: right;
    width: 30%;
    word-wrap: break-word;

    &:hover {
        color: ${COLORS.DARK_SAPPHIRE};
    }
`;

export const topicsWrapperStyles = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

export const topicCheckboxStlyes = css`
    width: 100%;

    @media screen and ${FROM_DESKTOP} {
        width: 48%;
    }
`;
