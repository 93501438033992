import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT } from 'utils/variables';
import { THEME } from 'components/Accordion/constants';
import { StyledArrowIcon } from 'components/Accordion/Accordion';
import { COLORS } from '../../../utils/variables';

export const accordionWrapperStyles = css`
    display: flex;
    flex-direction: column;
`;

export const accordionHeaderStyles = ({ theme }) => css`
    align-items: center;
    background-color: transparent;
    border: none;
    display: flex;
    font-family: ${FONT_AVENIR_NEXT};
    justify-content: space-between;

    /* stylelint-disable */
    
    ${theme === THEME.DEFAULT && css`
        color: ${COLORS.DARK_GRAY1};
        font-size: 15px;
        font-weight: 700;
        padding: 18px 0;

        @media screen and ${FROM_TABLET} {
            color: ${COLORS.DARK_GRAY2};
            font-size: 18px;
            padding: 26px 0 32px;
        }
    `};
    
    ${theme === THEME.SECONDARY && css`
        color: ${COLORS.DARK_GRAY2};
        font-size: 24px;
        font-weight: 600;
        line-height: 1.5;
        padding: ${({ isOpen }) => (isOpen ? '24px 14px 17px 8px' : '24px 14px 24px 8px')};

        @media screen and ${FROM_TABLET} {
            font-size: 26px;
            padding: ${({ isOpen }) => (isOpen ? '29px 25px 27px 11px' : '29px 25px 29px 11px')};
        }
    `};

    /* stylelint-enable */
`;

export const titleWrapperStyles = css`
    display: flex;
    justify-content: space-between;
    padding-right: 14px;
    text-align: left;
    width: 100%;
    word-wrap: break-word;
`;

export const titleStyles = ({ theme }) => css`
    /* stylelint-disable */
    ${theme === THEME.DEFAULT && css`
        font-weight: 700;
    `};

    ${theme === THEME.SECONDARY && css`
        font-weight: 600;
    `};
    /* stylelint-enable */
`;

export const subtitleStyles = css`
    color: ${COLORS.LIGHT_GRAY2};
    font-weight: 600;
`;

export const footerStyles = css`
    border-bottom: solid 1px ${COLORS.LIGHT_GRAY3};

    @media screen and ${FROM_TABLET} {
        border-bottom-width: 2px;
    }

    @media screen and ${FROM_DESKTOP} {
        border-bottom-width: 2px;
    }
`;

export const arrowIconWrapperStyles = ({ isOpen, theme }) => css`
    line-height: 1;
    margin-left: 15px;
    transform: ${(isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};

    /* stylelint-disable */
    ${StyledArrowIcon} {
        ${theme === THEME.SECONDARY && isOpen && css`
            path {
                stroke: ${COLORS.DARK_SAPPHIRE};
            }
        `};
        @media screen and ${FROM_TABLET} {
            ${isOpen && css`
                vertical-align: 11px;
            `};            
        }
    }
    /* stylelint-enable */
`;

export const arrowIconStyles = ({ theme }) => css`
    @media screen and ${FROM_TABLET} {
        height: 14px;
        width: 27px;
    }

    path {
        stroke-width: 4px;
    }

    /* stylelint-disable */
    ${theme === THEME.SECONDARY && css`
        vertical-align: 7px;

        @media screen and ${FROM_TABLET} {
            height: 18px;
            vertical-align: middle;
            width: 32px;

            path {
                stroke-width: 3px;
            }
        }
    `};
    /* stylelint-enable */
`;
