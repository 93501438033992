export const SIZE = {
    LARGE: 'LARGE',
    MICRO: 'MICRO',
    SMALL: 'SMALL',
    STANDARD: 'STANDARD',
};

export const TYPE = {
    ARTICLE: 'ARTICLE',
    GUIDE_STANDARD: 'GUIDE_STANDARD',
    GUIDE_HBR: 'GUIDE_HBR',
    GUIDE_MC_KINSEY: 'GUIDE_MC_KINSEY',
    TOOL: 'TOOL',
    IN_PRACTICE: 'IN_PRACTICE',
    GUIDE_IN_PRACTICE: 'GUIDE_IN_PRACTICE',
};
