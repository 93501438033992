import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from 'components/atomics/atoms/Button/Button';
import InputAtom from 'components/atomics/atoms/Input/Input';
import { TYPE_XLARGE } from 'components/atomics/atoms/Input/Input';
import { FormattedMessage } from 'react-intl';
import {
    BreakpointsContext,
    MOBILE,
} from 'components/Breakpoints/Breakpoints';
import {
    TYPE_PRIMARY_LARGE,
    TYPE_SECONDARY_MEDIUM_LARGE,
    TYPE_GHOST,
} from 'components/atomics/atoms/Button/Button';
import {
    container,
    buttonRow,
    mobileButtonRow,
    fieldRow,
    title,
    subTitle,
    form,
} from 'containers/SignUp/SignUpForm/IdentityModal/styles';
import labels from '../../../../lang/en.json';

const Container = styled.div`
    ${container}
`;

const ButtonRow = styled.div`
    ${buttonRow}
`;

const MobileButtonRow = styled.div`
    ${mobileButtonRow}
`;

const Title = styled.div`
    ${title}
`;

const SubTitle = styled.div`
    ${subTitle}
`;

const FieldRow = styled.div`
    ${fieldRow}
`;

const Form = styled.div`
    ${form}
`;

const MIN_LENGTH_CRD = 4;
const MAX_LENGTH_CRD = 8;
const VALID_KEYCODES = [ 13, 8, 46, 9, ];
const VALID_NUMBER_KEYCODES = [ 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, ];

/**
 * CRD modal for signup form.
 */
export class IdentityModal extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.state = {
            crdInvalid: false,
            crd: '',
            crdFocused: false,
            crdCompleted: false,
        }

        this.validateCRD = this.validateCRD.bind(this);
        this.resetCRDValidation = this.resetCRDValidation.bind(this);
        this.onCRDChange = this.onCRDChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.filterInputCRD = this.filterInputCRD.bind(this);
        this.adjustCompletedState = this.adjustCompletedState.bind(this);

        this.contentRef = React.createRef();
    }

    /**
     * Focus input on display.
     */
    componentDidMount() {
        this.contentRef.current.getElementsByTagName("a")[0].focus();
    }

    /**
     * Handle sending data to parent container.
     */
    handleSubmit() {
        const returnBoolean = this.state.crd.length ? this.validateCRDValue(this.state.crd) : false;

        if (returnBoolean) {
            window.identityData.previousCrd = '';

            this.props.onSubmit({
                crd: this.state.crd,
            });
        } else {
            if (!returnBoolean) {
                this.setState({
                    crdInvalid: true,
                });
            }

            return returnBoolean;
        }
    }

    /**
     * Store CRD for wiring.
     * @param {*} obj
     */
    onCRDChange(obj) {
        this.setState({
            crd: obj.value,
        });
    }

    /**
     * Reset CRD validation state.
     */
    resetCRDValidation() {
        this.setState({
            crdInvalid: false,
            crdFocused: true,
        });

        this.props.resetServerError();
    }

    /**
     * Validate CRD field.
     * @param {*} event
     */
    validateCRD(event) {
        const value = event.target.value;

        this.setState({
            crdFocused: false,
        });

        if (value.length) {
            this.validateCRDValue(value);
        }
    }

    /**
     * Validate CRD by specific value (not by event as per above).
     * @param {*} value 
     */
    validateCRDValue(value) {
        if (value.length < MIN_LENGTH_CRD || value.length > MAX_LENGTH_CRD) {
            this.setState({
                crdInvalid: true,
            });

            return false;
        }

        return true;
    }

    /**
     * Manage CRD input values.
     * @param {*} event
     */
    filterInputCRD(event) {
        const key = event.keyCode;

        if ((!VALID_NUMBER_KEYCODES.includes(key)
            && !VALID_KEYCODES.includes(key))
            || event.shiftKey) {
            return false;
        }

        return true;
    }

    /**
     * Adjust if CRD looks complete on key up.
     * @param {*} event
     */
    adjustCompletedState(event) {
        this.setState({
            crdCompleted: (event.target.value.length >= MIN_LENGTH_CRD
                        && event.target.value.length <= MAX_LENGTH_CRD),
        });
    }

    /**
     * Render the modal and underlying components.
     */
    render() {
        const submitEnabled = this.state.crd.length && !this.state.crdInvalid;

        const crdDuplicateError = <FormattedMessage
            id="identity.modal.inUseError"
            values={{
                link: (
                    <Link to="/contact-us">
                        <FormattedMessage id="identity.modal.inUseLinkLabel" />
                    </Link>
                ),
            }}
        />;

        const hasPreviousCrdError = this.props.previousCrd.length && this.props.crdDuplicateError;

        if (hasPreviousCrdError && !submitEnabled) {
            if (this.state.crd !== this.props.previousCrd) {
                this.setState({
                    crd: this.props.previousCrd,
                });
            }
        }

        return (
            <BreakpointsContext.Consumer>
                {(breakpoint) => {
                    return (
                        <Container ref={this.contentRef}>
                            <Title>
                                <FormattedMessage id="identity.modal.title" />
                            </Title>
                            <SubTitle>
                                <FormattedMessage id="identity.modal.description" />
                            </SubTitle>
                            <Form>
                                <FieldRow>
                                    <InputAtom
                                        autocomplete="off"
                                        inputType="number"
                                        id='CRD'
                                        showCheckWhenValid={true}
                                        validated={this.state.crdCompleted}
                                        label={labels.translations['identity.modal.label.crd']}
                                        placeholder={labels.translations['identity.modal.label.crd']}
                                        onKeyDown={this.filterInputCRD}
                                        onKeyUp={this.adjustCompletedState}
                                        helperText={labels.translations['identity.modal.helper.crd']}
                                        error={hasPreviousCrdError ? crdDuplicateError : labels.translations['identity.modal.error']}
                                        onBlur={this.validateCRD}
                                        onFocus={this.resetCRDValidation}
                                        onChange={this.onCRDChange}
                                        isErrored={hasPreviousCrdError ? true : this.state.crdInvalid}
                                        type={TYPE_XLARGE}
                                        hideLabelOnPlaceholder={true}
                                        value={hasPreviousCrdError ? this.props.previousCrd : ''}
                                    />
                                </FieldRow>
                            </Form>
                            {breakpoint !== MOBILE
                            && <ButtonRow>
                                <Button
                                    type={TYPE_SECONDARY_MEDIUM_LARGE}
                                    onClick={this.props.closeModal}
                                    dontScroll={true}
                                    to="#dismiss"
                                >
                                    <FormattedMessage id="identity.modal.dismiss" />
                                </Button>
                                <Button
                                    type={TYPE_PRIMARY_LARGE}
                                    disabled={!submitEnabled}
                                    onClick={this.handleSubmit}
                                    dontScroll={true}
                                    to="#submit"
                                >
                                    <FormattedMessage id="identity.modal.submit" />
                                </Button>
                            </ButtonRow>}
                            {breakpoint === MOBILE
                            && <MobileButtonRow>
                                <div>
                                    <Button
                                        type={TYPE_PRIMARY_LARGE}
                                        disabled={!submitEnabled}
                                        onClick={this.handleSubmit}
                                        dontScroll={true}
                                        noAnchor
                                    >
                                        <FormattedMessage id="identity.modal.submit" />
                                    </Button>
                                </div>
                                <Button
                                    type={TYPE_GHOST}
                                    onClick={this.props.closeModal}
                                    dontScroll={true}
                                    noAnchor
                                >
                                    <FormattedMessage id="identity.modal.dismiss" />
                                </Button>
                            </MobileButtonRow>}
                        </Container>
                    );
                }}
            </BreakpointsContext.Consumer>
        );
    }
}

IdentityModal.propTypes = {
    closeModal: PropTypes.func,
    onSubmit: PropTypes.func,
    resetServerError: PropTypes.func,
    crdDuplicateError: PropTypes.bool,
    previousCrd: PropTypes.string,
};

IdentityModal.defaultProps = {
    closeModal: () => {},
    onSubmit: () => {},
    resetServerError: () => {},
    crdDuplicateError: false,
    previousCrd: '',
};

export default IdentityModal;
