import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Link from 'components/atomics/atoms/Link/Link';
import { getArticleUrl } from 'utils/getArticleAsset';
import {
    containerStyles,
    contentContainerStyles,
    titleStyles,
    imageContainerStyles,
    sourceContentContainerStyles,
    toolsHeaderIconStyles,
    webinarTypeContainerStyles,
} from '../WebinarListCard/styles';
import { contextHubRecordEvent } from 'utils/contextHub';
import {
    CARDS_EVENT_LIST,
} from 'utils/contextHubEventListConstants';
import WebinarCardFooter from "../WebinarListContentCard/WebinarListContentCardFooter";
import { toggleSourceFollow } from "../../../../state-management/actions/sources";
import { toggleSaveWebinar as toggleSaveWebinarAction} from "../../../../state-management/actions/save";
import { WEBINARIcon, WEBINRMOBILEIcon } from 'components/atomics/atoms/Icons/Icons';
import { FormattedMessage } from 'react-intl';
import { getTruncatedString } from 'utils/contentCardUtils';

export const ToolsHeaderIconContainer = styled.div`
    ${toolsHeaderIconStyles}
`;

export const Title = styled(Link)`
    ${titleStyles}
`;

export const WebinarType = styled.div`
    ${webinarTypeContainerStyles}
`;

export const Container = styled.div`
    ${containerStyles}
`;

export const ImageContainer = styled(Link)`
    ${imageContainerStyles}
`;

export const ContentContainer = styled.div`
    ${contentContainerStyles}
`;

export const SourceContentContainer = styled.div`
    ${sourceContentContainerStyles}
`;

const MAX_ARTICLE_TITLE_CHARS = 75;

/**
 * Creates a Webinar skinny list card.
 */
class WebinarListCard extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.state = {
            onCardHover: false
        };        
        this.CH_handleComponentEventList = this.CH_handleComponentEventList.bind(this);
    }

    /**
     * Handle analytics.
     */
    CH_handleComponentEventList() {
        contextHubRecordEvent(CARDS_EVENT_LIST(
            'webinar',
            this.props.article.title,           
            this.props.article.sourceName
        ));
    }

    /**
     * Set hover state.
     */
    onMouseEnter = () => {
        this.setState({ onCardHover: true });
    }

    /**
     * Remove hover state.
     */
    onMouseLeave = () => {
        this.setState({ onCardHover: false });
    }

    /**
     * Handle follow source action.
     */
    followSource = (id, status) => {
        const { toggleFollowSource } = this.props;

        toggleFollowSource(id, status);
    }

    /**
     * Handle save Webinar action.
     */
    saveWebinar = (id, save, title, getContentAfterSave, article) => {
        const { toggleSaveWebinar } = this.props;

        id = id ? id : article.cardId;

        toggleSaveWebinar(parseInt(id), save, title, this.props.getContentAfterSave, article);
    }

    /**
     * Render the list content card and underlying components.
     */
    render() {
        const isLastCard = (this.props.isLastCard) ? 'item-last' : '';
        const linkUrl =`/learn/webinar/${this.props.article.code}` ;
        const classNames = `TYPE-${this.props.article.type} ${this.props.alternativeBackground} ${isLastCard}`;
        const cardType = this.props.article.cardType;
        const { onCardHover } = this.state;

        return (
            <Container
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                className={classNames}
                className={`${this.props.article.readStatus ? (this.props.article.readStatus === true ? 'webinar-visited' : 'webinar-not-visited') : 'webinar-not-visited'}`}
            >
                <ImageContainer
                    to={linkUrl}
                    onClick={() => {
                        this.CH_handleComponentEventList();
                    }}
                >
                    <ToolsHeaderIconContainer>
                        <WEBINARIcon className="desk" />
                        <WEBINRMOBILEIcon className="mobile" />
                    </ToolsHeaderIconContainer>
                </ImageContainer>
                <ContentContainer>
                    <WebinarType>
                        <FormattedMessage id="webinar.page.type" />
                    </WebinarType>
                    <Title
                        to={linkUrl}
                        onClick={() => {
                            this.CH_handleComponentEventList();
                        }}
                    >
                        {getTruncatedString(this.props.article.title, MAX_ARTICLE_TITLE_CHARS)}
                    </Title>
                    
                    <WebinarCardFooter onElement={onCardHover}
                        profile={this.props.profile}
                        sourceName={this.props.article.sourceName}                                          
                        article={this.props.article}                                           
                        sourceId={this.props.article.sourceId}                                          
                        saved={this.props.article.saved}
                        articleId={parseInt(this.props.article.id)}
                        articleTitle={this.props.article.title}
                        type={this.props.article.type || this.props.article.contentType}
                        shouldShowToast={this.props.shouldShowToast}
                        hideSourceOption={true}
                        onSaveArticle={(id, save, title, getContentAfterSave, article) => {
                            this.saveWebinar(id, save, title, getContentAfterSave, article );
                        }}
                        onFollowSource={(sourceId, status) => {
                            this.followSource(sourceId, status);
                        }} 
                    />
                </ContentContainer>
            </Container>
        );
    }
}

WebinarListCard.propTypes = {
    article: PropTypes.shape({
        type: PropTypes.string,             
        title: PropTypes.string.isRequired,
        image: PropTypes.string,
        sourceName: PropTypes.string,
        sourceId: PropTypes.number,     
        id: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        url: PropTypes.string,
        link: PropTypes.string,
        saved: PropTypes.bool,
        licensed: PropTypes.bool,
        contentType: PropTypes.string,
        interactionId: PropTypes.string,
        interactionType: PropTypes.string,       
        visited: PropTypes.bool
    }).isRequired,
    isLastCard: PropTypes.bool,
    alternativeBackground: PropTypes.string,
    handleKebabOpenOutside: PropTypes.func,
    handleSaveArticleKebabClick: PropTypes.func,
    shouldShowToast: PropTypes.bool,
    onUnsave: PropTypes.func,
    getContentAfterSave: PropTypes.bool
};

WebinarListCard.defaultProps = {
    isLastCard: false,
    alternativeBackground: 'white',
    shouldShowToast: false,
    handleKebabOpenOutside: () => { },
    onUnsave: null,
    getContentAfterSave: false
}

const mapDispatchToProps = dispatch => ({
    toggleSaveWebinar: (id, save, title, getContentAfterSave, article) => dispatch(toggleSaveWebinarAction(id, save, {
        title,
        getContentAfterSave,
        article,
    })),
    toggleFollowSource: (sourceId, isFollowing) => (
        dispatch(toggleSourceFollow(sourceId, isFollowing))
    ),
});

const mapState = state => ({
    profile: state.basicProfile,
    isActiveCoachMark: state.coachMark.display.KEBAB,
});

export default connect(mapState, mapDispatchToProps)(WebinarListCard);
