import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    BreakpointsContext,
    MOBILE,
} from 'components/Breakpoints/Breakpoints';
import ListContentCard from '../../../../components/atomics/molecules/ListContentCard/ListContentCard';
import StandardContentCard from '../../../../components/atomics/molecules/StandardContentCard/StandardContentCard';
import HeroContentCard from '../../../../components/atomics/molecules/HeroContentCard/HeroContentCard';
import {
    containerStyles,
    leftContainerStyles,
    rightContainerStyles,
    sliderContainerStyles,
    desktopContainerStyles,
    paddingContainerStyles,
    sliderCellStyles,
} from 'components/atomics/organisms/HeroContentCardCollection/styles';
import { Carousel } from "../../molecules/CarouselMolecule/Carousel";

export const PaddingContainer = styled.div`
    ${paddingContainerStyles}
`;

export const DesktopContainer = styled.section`
    ${desktopContainerStyles}
`;

export const Container = styled.section`
    ${containerStyles}
`;

export const SliderContainer = styled.section`
    ${sliderContainerStyles}
`;

export const RightContainer = styled.section`
    ${rightContainerStyles}
`;

export const LeftContainer = styled.section`
    ${leftContainerStyles}
`;

export const SliderCell = styled.div`
    ${sliderCellStyles}
`;

/**
 * Creates a main hero card and a collection of three list cards.
 */
class HeroContentCardCollection extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

    }

    /**
     * Render the hero collection cards and underlying components.
     */
    render() {
        return (
            <BreakpointsContext.Consumer>
                {(breakpoint) => {
                    if (breakpoint === MOBILE) {
                        return (

                            <div>
                                {/*TODO: remove after test*/}
                                <SliderContainer
                                    data-rel="HeroContentCardCollection"
                                >
                                    <Carousel items={[
                                        {
                                            component: (<StandardContentCard
                                                article={this.props.articles[0]}
                                                showCoachMark={this.props.showCoachMark}
                                                dismissCoachMark={this.props.dismissCoachMark}
                                            />)
                                        },
                                        {
                                            component: ( <StandardContentCard
                                                article={this.props.articles[1]}
                                                dismissCoachMark={this.props.dismissCoachMark}
                                                activateDismissCoachMark={true}
                                            />)
                                        },{
                                            component: ( <StandardContentCard
                                                article={this.props.articles[2]}
                                                dismissCoachMark={this.props.dismissCoachMark}
                                                activateDismissCoachMark={true}
                                            />)
                                        },{
                                            component: ( <StandardContentCard
                                                article={this.props.articles[3]}
                                                dismissCoachMark={this.props.dismissCoachMark}
                                                activateDismissCoachMark={true}
                                            />)
                                        }
                                    ]}/>
                                </SliderContainer>
                            </div>
                        );
                    }
                    return (
                        <DesktopContainer data-rel="HeroContentCardCollection">
                            <PaddingContainer>
                                <Container>
                                    <LeftContainer>
                                        <HeroContentCard
                                            article={this.props.articles[0]}
                                            showCoachMark={this.props.showCoachMark}
                                            dismissCoachMark={this.props.dismissCoachMark}
                                        />
                                    </LeftContainer>
                                    <RightContainer>
                                        <ListContentCard
                                            showCoachMark={this.props.showCoachMark}
                                            article={this.props.articles[1]}
                                        />
                                        <ListContentCard
                                            showCoachMark={this.props.showCoachMark}
                                            article={this.props.articles[2]}
                                        />
                                        <ListContentCard
                                            showCoachMark={this.props.showCoachMark}
                                            article={this.props.articles[3]}
                                        />
                                    </RightContainer>
                                </Container>
                            </PaddingContainer>
                        </DesktopContainer>
                    );
                }}
            </BreakpointsContext.Consumer>
        );
    }
}

HeroContentCardCollection.propTypes = {
    articles: PropTypes.arrayOf(PropTypes.shape({
        contentId: PropTypes.string,
        date: PropTypes.string.isRequired,
        topicName: PropTypes.string.isRequired,
        topicId: PropTypes.number,
        title: PropTypes.string.isRequired,
        image: PropTypes.string,
        sourceName: PropTypes.string.isRequired,
        sourceId: PropTypes.number,
        provider: PropTypes.string.isRequired,
        id: PropTypes.any.isRequired,
        url: PropTypes.string,
        licensed: PropTypes.bool,
        visited: PropTypes.bool
    })).isRequired,
    showCoachMark: PropTypes.bool,
    dismissCoachMark: PropTypes.func,
};

HeroContentCardCollection.defaultProps = {
    showCoachMark: false,
    dismissCoachMark: () => { }
}

export default HeroContentCardCollection;
