import {
    SET_ONBOARDING_STEP, SET_ONBOARDING_FINISHED,
} from 'state-management/constants/onboarding';

export const setOnBoardingStep = idx => ({
    type: SET_ONBOARDING_STEP,
    currentIdx: idx,
});

export const setOnboardingFinished = isOnboardingFinished => ({
    type: SET_ONBOARDING_FINISHED,
    isOnboardingFinished,
});
