import React from 'react';
import PropTypes from 'prop-types';
import { getShareEmailLink, getShareLink } from 'utils/urlUtils';
import Modal from 'react-modal';
import styled from 'styled-components';
import Button from 'components/atomics/atoms/Button/Button';
import { FormattedMessage } from 'react-intl';
import { connect } from "react-redux";
import { CrossIcon } from 'components/atomics/atoms/Icons';
import { addToasts } from 'state-management/actions/toasts';
import { TYPES } from 'containers/Toasts/constants';
import {
    modalBody,
    modalTitle,
    modalNote,
    modalDescription,
    modalButtons,
    closeIconStyles,
} from 'components/ShareModal/styles';
import { RGB_COLORS } from 'utils/variables';

const StyledCloseIcon = styled.a`
    ${closeIconStyles};
`;

const ModalBody = styled.div`
    ${modalBody}
`;

const ModalTitle = styled.div`
    ${modalTitle}
`;

const ModalDescription = styled.div`
    ${modalDescription}
`;

const ModalButtons = styled.div`
    ${modalButtons}
`;

const ModalNote = styled.div`
    ${modalNote}
`;

const ModalStyles = {
    content: {
        background: 'none',
        border: '0',
        borderRadius: '0',
        bottom: 'auto',
        left: '50%',
        marginRight: '-50%',
        padding: '0',
        right: 'auto',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    overlay: {
        backgroundColor: `rgba(${RGB_COLORS.BLACK}, 0.25)`,
        zIndex: '1',
    },
};

class ShareModal extends React.Component {
    /**
    * Default constructor.
    * @param {*} props
    */
    constructor(props) {
        super(props);

        this.copyLink = this.copyLink.bind(this);
    }

    /**
     * Copy a shareable link to the current page to the users clipboard.
     */
    copyLink() {
        const copyText = document.getElementById("copyLinkInput");

        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/

        document.execCommand("copy");

        this.props.showCopyToast();

        this.props.closeModal();
    }

    /**
     * Render this and underlying components.
     */
    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                onRequestClose={this.props.closeModal}
                ariaHideApp={false}
                style={ModalStyles}
            >
                <ModalBody>
                    <input
                        id="copyLinkInput"
                        type="text"
                        value={getShareLink(this.props.userShareToken, true, true)}
                    />
                    <StyledCloseIcon
                        onClick={this.props.closeModal}
                        href="#close"
                    >
                        <CrossIcon />
                    </StyledCloseIcon>
                    <ModalTitle>
                        <FormattedMessage id="modal.share.title" />
                    </ModalTitle>
                    <ModalDescription>
                        <FormattedMessage id="modal.share.description" />
                    </ModalDescription>
                    <ModalButtons>
                        <Button
                            dataAnalyticsPlacement="share : body"
                            dataAnalyticsLabel="trackLink : share"
                            dataAnalyticsId=" email"
                            onClick={this.props.closeModal}
                            to={getShareEmailLink(this.props.userShareToken)}
                            disabled={false}
                        >
                            <FormattedMessage id="modal.share.button.email" />
                        </Button>
                        <Button
                            dataAnalyticsPlacement="share : body"
                            dataAnalyticsLabel="trackLink : share"
                            dataAnalyticsId=" copy link"
                            to="#copy"
                            onClick={this.copyLink}
                        >
                            <FormattedMessage id="modal.share.button.copy" />
                        </Button>
                    </ModalButtons>
                    <ModalNote>
                        <FormattedMessage id="modal.share.note" />
                    </ModalNote>
                </ModalBody>
            </Modal>
        );
    }
};

ShareModal.propTypes = {
    closeModal: PropTypes.func,
    isOpen: PropTypes.bool,
};

ShareModal.defaultProps = {
    closeModal: () => { },
    isOpen: false,
};


const mapDispatchToProps = dispatch => ({
    showCopyToast: () => dispatch(addToasts([{
        toastProps: {},
        toastType: TYPES.COPY,
    }])),
});

const mapState = state => ({
    userShareToken: state.basicProfile.uuid,
});

export default connect(mapState, mapDispatchToProps)(ShareModal);
