import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';

import AriaMessage from 'components/AriaMessage/AriaMessage';

/**
 * Generic wrapper for handling aria messaging.
 * @param {*} param0 
 */
const AriaNotification = ({ intl, message, options }) => {
    const translatedMessage = options && options.translated
        ? message
        : intl.formatMessage({ id: message });

    return (
        <AriaMessage
            message={translatedMessage}
        />
    );
};

AriaNotification.propTypes = {
    intl: intlShape.isRequired,
    message: PropTypes.string.isRequired,
    options: PropTypes.shape({
        translated: PropTypes.bool.isRequired,
    }).isRequired,
};

const mapStateToProps = state => ({
    message: state.ariaNotification.message,
    options: state.ariaNotification.options,
});

export default connect(mapStateToProps)(injectIntl(AriaNotification));
