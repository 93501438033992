import React from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import language from '../../lang/en.json';
import styled from 'styled-components';
import { isEqual } from 'lodash';
import Title from 'components/atomics/atoms/Title/Title';
import {
    Container,
    Sticky,
    StickyContainer,
} from 'components';
import PreferencesLanding from 'containers/Profile/Preferences/PreferencesNew/PreferencesLanding';
import AccountInformation from 'containers/Profile/AccountInformation/AccountInformation';
import Saves from 'containers/Saves/Saves'
import ActionsBar from 'containers/Profile/ActionsBar';
import {
    containerStyles,
    divContainerStyles,
    divContainerSavesStyles,
    practiceContainerStyles,
    styledBack,
    elementDisplayStyles,
    editButtonStyles,
    backToPlaceholder,
    subtitleStyles,
} from 'containers/Profile/styles';
import LinkListContainerEXAMPLE from 'components/atomics/molecules/LinkList/LinkListContainerEXAMPLE';
import EditProfileForm from '../../containers/Profile/AccountInformation/EditProfileForm/EditProfileForm';
import { connect } from "react-redux";
import { Button } from 'components';
import { wrapBackButtonLabels } from "../../utils/urlUtils";
import { BackToLinkContainer } from "../Guides/CollectionContainer/CollectionContainer";
import BackToLink from "../../components/BackToLink/BackToLink";
import { FormattedMessage } from "react-intl";
import { HistoryManager } from "../../utils/historyManager";
import storage from '../../utils/store';
import { backToLinkStyles } from 'components/BackToLink/styles';

const StyledContainer = styled(Container)`
    ${containerStyles};
`;

const Subtitle = styled.h1`
    ${subtitleStyles};
`;

const StyledPlaceHolder = styled.div`
    ${backToLinkStyles}
    ${backToPlaceholder}
`;

export const StyledDiv = styled.div`
    ${divContainerStyles};
`;

export const StyledSavesDiv = styled.div`
    ${divContainerSavesStyles};
`;

const PracticeContainer = styled.div`
    ${practiceContainerStyles};
`;

const StyledBack = styled.div`
    ${styledBack}
`;

const ElementDisplay = styled.div`
    ${elementDisplayStyles}
`;

export const StyledEditButton = styled(Button)`
    ${editButtonStyles};
`;

const ACTIONBAR_ISSTICKY_MINHEIGHT = 450;

/**
 * Wrapper for all profile form areas (various links from profile menu).
 */
class ProfileForm extends React.Component {
    /**
     * Default constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.errorRef = React.createRef();

        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.handlePracticeInfoChange = this.handlePracticeInfoChange.bind(this);
        this.handlePreferencesChange = this.handlePreferencesChange.bind(this);
        this.hideActionsBar = this.hideActionsBar.bind(this);

        this.state = {
            accountChange: false,
            practiceInfoChange: false,
            preferencesChange: false,
            isPracticeInfoEdit: false,
            toggleEdit: false,
        };

        window.addEventListener('keyup', this.focusHelper);
    }

    /**
     * Remove focus helper on destruction.
     */
    componentWillUnmount() {
        window.removeEventListener('keyup', this.focusHelper);
    }

    /**
     * Ensure next item focused by tab key is visible above the sticky action bar.
     * @param {*} event 
     */
    focusHelper(event) {
        if (event.keyCode === 9) {
            const element = document.activeElement;
            const windowHeight = window.innerHeight;
            const stickyMargin = 80;
            const stickyBarHeight = document.getElementById('profileActionBar').clientHeight + stickyMargin;

            let elementBottom = element.offsetTop
                + element.clientHeight;
            let tempElement = element;
            let additionalTop = 0;

            while (tempElement.offsetParent) {
                additionalTop += tempElement.offsetParent.offsetTop;

                tempElement = tempElement.offsetParent;
            }

            let isFormElement = false;

            tempElement = element;

            while (tempElement.parentNode) {
                if (tempElement.parentNode.getAttribute
                    && tempElement.parentNode.getAttribute('data-rel') === 'profile-form') {
                    isFormElement = true;

                    break;
                }

                tempElement = tempElement.parentNode;
            }

            elementBottom += additionalTop;

            if (elementBottom > (windowHeight - stickyBarHeight) && isFormElement) {
                const scrollDestination = elementBottom - (windowHeight - stickyBarHeight);

                window.scrollTo(0, scrollDestination);
            }
        }
    }

    /**
     * Handle checkbox toggle.
     */
    toggleBox() {
        const { opened } = this.state;

        this.setState({
            opened: !opened,
        });
    }

    /**
     * Handle state change to account.
     */
    handleAccountChange() {
        this.setState({
            accountChange: true,
        });
    }

    /**
     * Handle state change to preferences.
     */
    handlePreferencesChange() {
        this.setState({
            preferencesChange: true,
        });
    }

    /**
     * Handle state change to practice info.
     */
    handlePracticeInfoChange() {
        this.setState({
            practiceInfoChange: true,
        });
    }

    /**
     * Hide the lower action bar.
     */
    hideActionsBar() {
        this.setState({
            accountChange: false,
            practiceInfoChange: false,
            preferencesChange: false,
            toggleEdit: false,
        });
    }

    /**
     * Focus errors or reset errors or handle submit on component update.
     * @param {*} prevProps 
     */
    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.globalErrors, this.props.globalErrors)
            && this.props.globalErrors.length > 0 && this.errorRef.current) {
            this.errorRef.current.focus();
        }

        if (!prevProps.isSubmitTriggered && this.props.isSubmitTriggered) {
            this.props.formik.submitForm();
        }

        if (!prevProps.isFormToReset && this.props.isFormToReset) {
            this.props.formik.handleReset();
            this.props.resetFormState();
        }

    }

    /**
     * Handle hiding and disabling action bar during save.
     * @param {*} nextProps 
     */
    componentWillReceiveProps(nextProps) {
        if (this.props.isSaving && this.props.tabNameSelected === 'practice-info') {
            setTimeout(() => {
                this.hideActionsBar();
            }, 750);
        }
    }

    /**
     * Handle back button clicks.
     */
    handleBack = () => {
        const { removeItemFromHistory, urlHistoryList } = this.props;
        const historyItem = urlHistoryList.length - 2 >= 0
            ? urlHistoryList[urlHistoryList.length - 2]
            : urlHistoryList[urlHistoryList.length - 1];
        const url = historyItem.currentUrl.includes(window.location.origin)
            ? historyItem.currentUrl.replace(window.location.origin, '')
            : historyItem.currentUrl;

        HistoryManager.sentHistoryEvent(url).then(data => {
            removeItemFromHistory();
        })
    }

    /**
     * Display the back button.
     */
    renderBackButtonItem = () => {
        const { urlHistoryList } = this.props
        const backLabel = (urlHistoryList.length - 2) >= 0 ? urlHistoryList[urlHistoryList.length - 2] : null;

        if (backLabel === null || this.props.isInEditMode) {
            return (<BackToLinkContainer>
                <StyledPlaceHolder>
                    &nbsp;
                </StyledPlaceHolder>
            </BackToLinkContainer>);
        }

        return (<BackToLinkContainer>
            <BackToLink
                to={backLabel !== null ? backLabel.currentUrl : "/profile"}
                customLabel={wrapBackButtonLabels(backLabel)}
                isDisable={backLabel === null}
            >
            </BackToLink>
        </BackToLinkContainer>);
    }

    /**
     * Update edit state on changes to forms.
     */
    handleProfileEditL = () => {
        storage.set('profileEditClicked', true);
        this.setState(state => ({ toggleEdit: !state.toggleEdit }));
    };

    /**
     * Same as above but force to false.
     */
    handleProfileEdit = () => {
        this.setState(state => ({ toggleEdit: false }));
    };

    /**
     * Handle cancel button clicks from action bar.
     */
    handleCancel = () => {
        const { formik } = this.props;

        if (!(formik.values.sourceIds.length < 3
            || formik.values.topicIds.length < 3)) {
            this.props.togglePreferncesToDefault()
        }

        this.props.setFormUntouched();

        this.hideActionsBar();
    }

    /**
     * Render this and underlying components.
     */
    render() {
        this.page = (window.location.pathname).split("/");
        this.pageName = this.page[2];

        const {
            formik,
            classifications,
            sources,
            topicCategories,
            setFormTouched,
            setFormToEditMode,
            setFormToEditDefaultMode,
            setInputRef,
            isTouched,
            isInEditMode,
            isSaving,
            setFormToOnlyEditMode
        } = this.props;

        let PageContent = " ";
        let newValu = this.state.toggleEdit;

        if (this.props.tabNameSelected === "undefined") {
            PageContent = <form
                id="basic-profile-form"
                noValidate
                onSubmit={formik.handleSubmit}
            >
                <StickyContainer>
                    <StyledContainer>
                        <StyledDiv>
                            <LinkListContainerEXAMPLE />
                        </StyledDiv>
                    </StyledContainer>
                </StickyContainer>
            </form>
        }

        else if (this.props.tabNameSelected === "account") {
            let savableAccount = false;

            if (isTouched) {
                savableAccount = true;
            }

            PageContent = <form
                id="basic-profile-form"
                noValidate
                onSubmit={formik.handleSubmit}
            >
                <StickyContainer>
                    <StyledContainer>
                        <StyledDiv>

                            <StyledDiv className={"accountsTab show"}>
                                <LinkListContainerEXAMPLE />
                            </StyledDiv>

                            <StyledBack className="showElement">
                                {this.renderBackButtonItem()}
                            </StyledBack>
                            <div data-rel="profile-form">
                                <AccountInformation
                                    credentialUpdateFailed={this.props.credentialUpdateFailed}
                                    newPasswordFailed={this.props.newPasswordFailed}
                                    formik={formik}
                                    setInputRef={setInputRef}
                                    classifications={classifications}
                                    isInEditMode={isInEditMode}
                                    onEditClick={setFormToEditMode}
                                    onEditDefaultClick={setFormToEditDefaultMode}
                                    setFormTouched={setFormTouched}
                                    handlePracticeInfoChange={() => { this.handlePracticeInfoChange(); }}
                                />
                            </div>
                        </StyledDiv>
                    </StyledContainer>

                    <ElementDisplay className='showElement' />
                    <Sticky
                        bottomOffset={0}
                        boundToContainer={Sticky.END}
                        hasPlaceholder
                        leftOffset={0}
                        rightOffset={0}
                        id="sticky-profile-actions"
                        className="fixedBar"
                        minimumWindowHeightForSticky={ACTIONBAR_ISSTICKY_MINHEIGHT}
                    >
                        <ActionsBar
                            visible={isInEditMode}
                            isSaving={isSaving}
                            onResetClick={this.handleCancel}
                            canSave={savableAccount}
                        />
                    </Sticky>
                </StickyContainer>
                <Prompt when={isTouched} message="profilePage.confirmationModal" />
            </form>

        }
        else if (this.props.tabNameSelected == "practice-info") {
            let savablePracticeInfo = false;
            if (isTouched) {
                savablePracticeInfo = true;
            }

            PageContent = <form
                id="basic-profile-form"
                noValidate
                onSubmit={formik.handleSubmit}
                className="savesStyles"
            >
                <StickyContainer>
                    <StyledContainer>
                        <StyledDiv>
                            <LinkListContainerEXAMPLE handleChange={() => this.handleProfileEdit()} />
                            <PracticeContainer>

                            {
                            newValu == false ?
                            (
                                <StyledBack className="showElement" >
                                    {this.renderBackButtonItem()}
                                </StyledBack>
                            ): (
                                ''
                            )}

                                {/* <Title
                                    resourceId="Practice Info"
                               /> */}

                                    {

                                        newValu == false ?
                                            (
                                                <Title>
                                                <FormattedMessage id="Practice Info" />
                                                <StyledEditButton
                                                                    data-analytics-placement="Button : body"
                                                                    data-analytics-label="trackLink : button"
                                                                    data-analytics-id={language.translations["profilePage.accountInformation.edit"] + " Practice Info"}
                                                                    onClick={() => {
                                                                        this.handleProfileEditL();
                                                                    }}
                                                                >
                                                                    <FormattedMessage id="profilePage.accountInformation.edit" />
                                                                </StyledEditButton>
                                                </Title>
                                            ) : (
                                                <Subtitle>
                                                <FormattedMessage id="Practice Info" />
                                                </Subtitle>
                                            )
                                    }


                                <StyledContainer newContainer="practiceRightContainer">
                                    <div data-rel="profile-form">
                                        <EditProfileForm
                                            id="account-info-edit"
                                            formik={formik}
                                            setInputRef={setInputRef}
                                            classifications={classifications}
                                            formPage="false"
                                            setFormTouched={setFormTouched}
                                            handlePracticeInfoChange={() => { this.handlePracticeInfoChange(); setFormTouched(); }}
                                            formPracticeInfo={newValu}

                                        />
                                    </div>
                                </StyledContainer>
                            </PracticeContainer>
                        </StyledDiv>

                    </StyledContainer>

                    <Sticky
                        bottomOffset={0}
                        boundToContainer={Sticky.END}
                        hasPlaceholder
                        leftOffset={0}
                        rightOffset={0}
                        id="sticky-profile-actions"
                        className="fixedBar"
                        minimumWindowHeightForSticky={ACTIONBAR_ISSTICKY_MINHEIGHT}
                    >
                        <ActionsBar
                            visible={newValu}
                            isSaving={isSaving}
                            onResetClick={this.handleCancel}
                            canSave={savablePracticeInfo}
                        />
                    </Sticky>

                </StickyContainer>
                <Prompt when={isTouched} message="profilePage.confirmationModal" />
            </form>
        }
        else if (this.props.tabNameSelected == "saves") {

            PageContent = <form
                id="basic-profile-form"
                noValidate
                onSubmit={formik.handleSubmit}
                className="savesStyles"
            >
                <StickyContainer>
                    <StyledContainer>
                        <StyledSavesDiv>
                            <LinkListContainerEXAMPLE />
                            <div data-rel="profile-form">
                                <Saves />
                            </div>
                        </StyledSavesDiv>
                    </StyledContainer>
                </StickyContainer>
                <Prompt when={isTouched} message="profilePage.confirmationModal" />
            </form>
        }
        else if (this.props.tabNameSelected == "preferences") {
            let savablePreferences = true;
            let errorTypeNum = 0;

            if (!isTouched) {
                savablePreferences = false;
            }

            if (formik.values.sourceIds.length < 3
                || formik.values.topicIds.length < 3) {
                savablePreferences = false;

                if (formik.values.sourceIds.length < 3
                    && formik.values.topicIds.length < 3) {
                    errorTypeNum = 3;
                } else if (formik.values.topicIds.length < 3) {
                    errorTypeNum = 1;
                } else {
                    errorTypeNum = 2;
                }
            }

            PageContent = <form
                id="basic-profile-form"
                noValidate
                onSubmit={formik.handleSubmit}
                className="savesStyles"
            >
                <StickyContainer>
                    <StyledContainer>
                        <StyledDiv>
                            <LinkListContainerEXAMPLE />
                            <div data-rel="profile-form">
                                <PreferencesLanding
                                    handlePreferencesChange={this.handlePreferencesChange}
                                    onEditDefaultClick={setFormToOnlyEditMode}
                                    section={this.props.section}
                                    formik={formik}
                                    sourcesData={sources}
                                    topicCategories={topicCategories}
                                    setFormTouched={setFormTouched}
                                    formTouched={isTouched}
                                    goBacktoDefault={this.props.goBacktoDefault}
                                    getBackToPreviousState={this.props.getBackToPreviousState}
                                    isInEditMode={isInEditMode}
                                />
                            </div>
                        </StyledDiv>
                    </StyledContainer>
                    <Sticky
                        bottomOffset={0}
                        boundToContainer={Sticky.END}
                        hasPlaceholder
                        leftOffset={0}
                        rightOffset={0}
                        id="sticky-profile-actions-3"
                        className="fixedBar"
                        minimumWindowHeightForSticky={ACTIONBAR_ISSTICKY_MINHEIGHT}
                    >
                        <ActionsBar
                            visible={isInEditMode}
                            isSaving={isSaving}
                            canSave={savablePreferences}
                            errorType={errorTypeNum}
                            onResetClick={this.handleCancel}
                        />
                    </Sticky>

                </StickyContainer>
                {errorTypeNum === 0
                    && <Prompt
                        when={isTouched}
                        errorType={errorTypeNum}
                        message="profilePage.confirmationModal"
                    />}
                {errorTypeNum !== 0
                    && <Prompt
                        when={isTouched}
                        errorType={errorTypeNum}
                        message="profilePage.confirmationSourceModal"
                    />}
            </form>
        }
        else {

            PageContent = <form
                id="basic-profile-form"
                noValidate
                onSubmit={formik.handleSubmit}
            >
                <StickyContainer>
                    <StyledContainer>
                        <StyledDiv>
                            <LinkListContainerEXAMPLE />
                        </StyledDiv>

                    </StyledContainer>
                </StickyContainer>
                <Prompt when={isTouched} message="profilePage.confirmationModal" />
            </form>
        }
        return (
            <div>
                {PageContent}
            </div>

        );
    }
}

ProfileForm.propTypes = {
    formik: PropTypes.shape({
        handleSubmit: PropTypes.func.isRequired,
        handleReset: PropTypes.func.isRequired,
        submitForm: PropTypes.func.isRequired,
    }).isRequired,
    setFormTouched: PropTypes.func.isRequired,
    setFormToEditMode: PropTypes.func.isRequired,
    setFormToEditDefaultMode: PropTypes.func.isRequired,
    setInputRef: PropTypes.func.isRequired,
    globalErrors: PropTypes.arrayOf(PropTypes.shape).isRequired,
    classifications: PropTypes.shape({}).isRequired,
    sources: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    topicCategories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    isTouched: PropTypes.bool.isRequired,
    isInEditMode: PropTypes.bool.isRequired,
    isSubmitTriggered: PropTypes.bool.isRequired,
    section: PropTypes.string,
    isFormToReset: PropTypes.bool,
    resetFormState: PropTypes.func.isRequired,
    credentialUpdateFailed: PropTypes.bool,
    newPasswordFailed: PropTypes.bool,
    setFormUntouched: PropTypes.func.isRequired,
};

ProfileForm.defaultProps = {
    section: null,
    isFormToReset: false,
    credentialUpdateFailed: false,
    newPasswordFailed: false,
};

const mapStateToProps = state => ({
    article: state.articles.article,
    isLoading: state.articles.isLoading,
    urlHistoryList: state.UrlHistoryReducer.history,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);

