import React ,{ useContext }from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import anime from 'animejs';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Button, Container, ProgressBar } from 'components';
import { IconStepperNext, IconStepperPrevious } from 'components/atomics/atoms/Icons/Icons';
import {
    BreakpointsContext,
    MOBILE,
} from 'components/Breakpoints/Breakpoints';
import {
    buttonStyles,
    buttonWrapperStyles,
    iconStyles,
    stepperHolderStyles,
    stepperWrapperStyles,
    progressBarStyles,
    stepperTitleStyles,
    stepperButtonTitleStyles,
    stepperStepWrapper,
    stepperStepsContainer,
    stepperIconWrapper,
    titleNumberStyles
} from 'components/Stepper/styles';

const StyledStepperHolder = styled.div`
    ${stepperHolderStyles}
`;
const StyledStepperWrapper = styled(({ passedRef, ...other }) => <Container ref={passedRef} {...other} />)`
    ${stepperWrapperStyles}
`;
const StyledStepsContainer = styled.div`
    ${stepperStepsContainer}
`;
export const StyledStepWrapper = styled.div`
    ${stepperStepWrapper}
`;
export const StyledButtonWrapper = styled.div`
    ${buttonWrapperStyles};
`;
export const StyledButton = styled(Button)`
    ${buttonStyles};
`;
const StyledStepTitle = styled.span`
    ${stepperTitleStyles}
`;
const StylesTitleNumber = styled.span`
    ${titleNumberStyles}
`;
const StyledStepperButtonTitle = styled.span`
    ${stepperButtonTitleStyles}
`;
const StyledProgressBar = styled(ProgressBar)`
    ${progressBarStyles}
`;
export const StyledIconWrapper = styled.span`
    ${stepperIconWrapper}
`;
const StyledNextIcon = styled(IconStepperNext)`
    ${iconStyles};
`;
const StyledPreviousIcon = styled(IconStepperPrevious)`
    ${iconStyles};
`;
const LAST_STEP_INDEX = 2;

class Stepper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            stepOffset: 0,
            stepAnimated: false,
            isMobile:false
        };

        this.stepperHolderRef = React.createRef();
        this.stepperWrapperRef = React.createRef();

        this.titleRefs = [];
    }

    componentDidMount() {
        window.addEventListener('resize', this.calculateProgressBar);
        this.calculateProgressBar(true);
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentIdx !== prevProps.currentIdx) {
            this.calculateProgressBar(true);
        }
        if (this.props.isFinished && !prevProps.isFinished) {
            this.hideContent();
            this.calculateProgressBar(true);
        }
        if (!this.props.isFinished && prevProps.isFinished) {
            this.showContent();
            this.calculateProgressBar(true);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.calculateProgressBar);
    }

    setStepRef(ref, index) {
        this.titleRefs[index] = ref;
    }

    calculateProgressBar = (stepAnimated = false) => {
        const { isFinished } = this.props;
        const activeStep = this.titleRefs[this.props.currentIdx];

        if (isFinished) {
            const holderNode = this.stepperHolderRef.current;

            if (holderNode) {
                this.setState({
                    stepOffset: holderNode.clientWidth,
                    stepAnimated,
                });
            }
        } else if (activeStep !== undefined) {
            const offsetLeft = this.toggleOffset(activeStep);
            this.setState({
                stepOffset: offsetLeft,
                stepAnimated,
            });
        }
    };

    toggleOffset = (activeStep) => {
       const { innerWidth } = window;
        if (this.state.isMobile===true) {
            let stepWidth = innerWidth / 4;
            let currentStepWidth = (stepWidth) * (this.props.currentIdx+1);
            return  currentStepWidth;
        }
        else {
           return activeStep.getBoundingClientRect().left + (activeStep.getBoundingClientRect().width / 2);
        }
       

    }

    updateMobileState =(isMobile)=>{
        this.state.isMobile=isMobile;
    }

    renderProgressBar = () => {
        const {
            currentIdx,
            intl,
            isFinished,
            titleIds,
        } = this.props;
        const { stepAnimated, stepOffset } = this.state;
        const stepsCount = titleIds.length;
        const value = isFinished ? 1 : stepsCount / currentIdx;
        const text = intl.formatMessage({ id: 'stepper.currentStep' }, {
            current: isFinished ? stepsCount : currentIdx + 1,
            count: stepsCount,
        });

        return (
            <StyledProgressBar
                isAnimated={stepAnimated}
                offset={stepOffset}
                valueNow={value}
                valueText={text}
            />
        );
    };

    renderMobileTitle = () => {
        const { titleIds, currentIdx } = this.props;
        return titleIds.map((title, index) => {
            if (index === currentIdx) {
                const variant = {
                    previous: index < currentIdx,
                    current: index === currentIdx,
                    next: index > currentIdx,
                    stepNo: (index + 1)
                };
                return (
                    <StyledStepWrapper
                        ref={ref => this.setStepRef(ref, index)}
                        key={`key_${title}`}
                        variant={variant}
                    >
                        <StylesTitleNumber>{variant.stepNo}</StylesTitleNumber>
                        <StyledStepTitle variant={variant}>
                            <FormattedMessage id={title} />
                        </StyledStepTitle>
                    </StyledStepWrapper>
                );
            }
        }
        );
    }

    renderDesktopTitle = () => {
        const { titleIds, currentIdx } = this.props;
        return titleIds.map((title, index) => {

            const variant = {
                previous: index < currentIdx,
                current: index === currentIdx,
                next: index > currentIdx,
                stepNo: (index + 1)
            };
            return (
                title && (
                    <StyledStepWrapper
                        ref={ref => this.setStepRef(ref, index)}
                        key={`key_${title}`}
                        variant={variant}
                    >
                        <StyledStepTitle variant={variant}>
                            <FormattedMessage id={title} />
                        </StyledStepTitle>
                    </StyledStepWrapper>
                ));
        });
    }

    renderOnboardingTitles = () => {
        return (
            <BreakpointsContext.Consumer>
                {(breakpoint) => {
                    if (breakpoint === MOBILE) {
                        this.updateMobileState(true);                 
                        return this.renderMobileTitle();
                    }
                    else { 
                        this.updateMobileState(false); 
                        return this.renderDesktopTitle();
                    }
                }
                }
            </BreakpointsContext.Consumer>
        );
    };

    hideContent() {
        const stepperWrapperNode = this.stepperWrapperRef.current;

        if (stepperWrapperNode) {
            anime({
                targets: stepperWrapperNode,
                easing: [0.99, 0.03, 0.47, 0.95],
                duration: 330,
                opacity: [1, 1],
            });
        }
    }

    showContent() {
        const stepperWrapperNode = this.stepperWrapperRef.current;

        if (stepperWrapperNode) {
            anime({
                targets: stepperWrapperNode,
                easing: [0.99, 0.03, 0.47, 0.95],
                duration: 330,
                opacity: [0, 1],
            });
        }
    }

    renderPreviousButton() {
        const { onPreviousClick, buttonsDisabled, currentIdx, isFinished } = this.props;
        const isDisabled = buttonsDisabled;
        const isHidden = currentIdx === 0 || isFinished;

        return (
            <StyledButtonWrapper
                variant={{ isHidden }}
            >
                <StyledButton
                    disabled={isHidden}
                    id="previous-step-button"
                    onClick={onPreviousClick}
                    variant={{ isDisabled }}
                >
                    <StyledIconWrapper
                        variant={{ isDisabled }}
                    >
                        <StyledPreviousIcon   variant={{ isDisabled }}/>
                    </StyledIconWrapper>
                </StyledButton>
            </StyledButtonWrapper>
        );
    }

    renderNextButton() {
        const { validation: { isValid }, onNextClick, buttonsDisabled, currentIdx, isFinished } = this.props;
        const isDisabled = !isValid || buttonsDisabled;
        if (!isFinished) {
            return (

                <StyledButtonWrapper
                    variant={{ isNext: true }}
                >
                    <StyledButton
                        id="next-step-button"
                        onClick={onNextClick}
                        variant={{ isDisabled }}
                    >
                        {currentIdx === LAST_STEP_INDEX &&
                            <StyledStepperButtonTitle disabled={!isValid || buttonsDisabled}>
                                <FormattedMessage id="onboarding.stepper.lastStep" />
                            </StyledStepperButtonTitle>
                        }
                        {currentIdx != LAST_STEP_INDEX &&
                            <StyledIconWrapper>
                                <StyledNextIcon   variant={{ isDisabled }} />
                            </StyledIconWrapper>}
                    </StyledButton>
                </StyledButtonWrapper>
            );
        }
        else {
            return null
        }
    }

    render() {
        const { id, isFinished } = this.props;

        return (
            <StyledStepperHolder
                ref={this.stepperHolderRef}
                id={id}
            >
                {this.renderProgressBar()}
                <StyledStepperWrapper
                    passedRef={this.stepperWrapperRef}
                    size={Container.MEDIUM}
                    variant={{ isFinished }}
                >
                    {this.renderPreviousButton()}
                    <StyledStepsContainer>
                        {this.renderOnboardingTitles()}
                    </StyledStepsContainer>
                    {this.renderNextButton()}
                </StyledStepperWrapper>
            </StyledStepperHolder>
        );
    }
}
Stepper.propTypes = {
    id: PropTypes.string,
    intl: intlShape.isRequired,
    isFinished: PropTypes.bool,
    currentIdx: PropTypes.number.isRequired,
    onNextClick: PropTypes.func.isRequired,
    onPreviousClick: PropTypes.func.isRequired,
    titleIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    validation: PropTypes.shape({
        isValid: PropTypes.bool.isRequired,
    }).isRequired,
    buttonsDisabled: PropTypes.bool,
};

Stepper.defaultProps = {
    buttonsDisabled: false,
    id: 'stepper',
    isFinished: false,
};

export default injectIntl(Stepper);
