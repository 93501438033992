export const APPEARANCE = {
    DEFAULT: 'default', // TODO Remove
    ICON: 'icon',
    PRIMARY: 'primary',
    SECONDARY_LIGHT: 'secondary-light',
    SECONDARY_DARK: 'secondary-dark',
    TRANSPARENT: 'transparent', // TODO Remove
};

export const SIZES = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
};

export const TYPES = {
    BUTTON: 'button',
    RESET: 'reset',
    SUBMIT: 'submit',
};
