import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Field } from 'formik';
import styled from 'styled-components';
import { xor } from 'lodash';
import { FieldText, UnderlinedCheckbox } from 'components';
import Dropdown from '../../../../components/atomics/molecules/Dropdown/Dropdown';
import PasswordRules, {
    handlePasswordBlur,
    handlePasswordChange,
} from 'components/PasswordRules/PasswordRules';
import {
    rowStyles,
    keyContainerStyles,
    valueContainerStyles,
    fieldContainerStyles,
    fieldEmailContainerStyles,
    passwordRowWrapperStyles,
    passwordRowStyles,
    passwordRulesWrapperStyles,
    formStyles,
    servicesOfferedListItemStyles,
    styledHeadingContainerStyles,
    styledHeadingStyles,
    styledPasswordRow,
    styledPasswordSubHeader,
    styledLabelTitle,
    styledValueTxt,
} from 'containers/Profile/AccountInformation/EditProfileForm/styles';
import { KEY_NUMBERS_AND_SERVICE } from 'utils/keyCodes';
import labels from '../../../../lang/en.json';

const StyledInfoRow = styled.div`
    ${rowStyles};
`;

const StyledRowKey = styled.div`
    ${keyContainerStyles};
`;

const StyledRowValue = styled.div`
    ${valueContainerStyles};
`;

const StyledPasswordRowWrapper = styled.div`
    ${passwordRowWrapperStyles};
`;

const StyledPasswordRow = styled.div`
    ${passwordRowStyles};
`;

const StyledPasswordRulesWrapper = styled.div`
    ${passwordRulesWrapperStyles};
`;

const StyledFieldContainer = styled.div`
    ${fieldContainerStyles};
`;

const StyledEmailRowValue = styled.div`
    ${fieldEmailContainerStyles}
`;

const StyledForm = styled.div`
    ${formStyles};
`;

const StyledServicesOfferedListItem = styled.li`
    ${servicesOfferedListItemStyles};
`;

const StyledHiddenInput = styled.input`
    display: none;
`;

const StyledHeadingContainer = styled.div`
    ${styledHeadingContainerStyles};
`;
const StyledHeading = styled.div`
    ${styledHeadingStyles};
`;

const PasswordRow = styled.div`
    ${styledPasswordRow};
`;

const PasswordSubHeader = styled.h3`
    ${styledPasswordSubHeader};
`;

const LabelTitle = styled.div`
    ${styledLabelTitle};
`;

const ValueTxt = styled.div`
    ${styledValueTxt};
`;

/**
 * Edit view for account screen.
 */
class EditProfileForm extends React.Component {

    /**
     * If current password is wrong, dont continue with updates or flow, set the error state and leave page as is.
     * @param {*} nextProps
     */
    componentWillUpdate(nextProps) {
        if (nextProps.credentialUpdateFailed
            && nextProps.credentialUpdateFailed !== this.props.credentialUpdateFailed) {

            this.props.formik.setFieldError(
                'curPassword',
                labels.translations['profilePage.errors.correctPasswordFormat']
            );
        } else if (nextProps.newPasswordFailed
            && nextProps.newPasswordFailed !== this.props.newPasswordFailed) {

            this.props.formik.setFieldError(
                'password',
                labels.translations['profilePage.errors.correctPasswordRules']
            );
        }
    }

    /**
     * Reset form at mount.
     */
    componentDidMount() {
        this.props.formik.resetForm();
    }

    /**
     * Format field data.
     * @param {*} options 
     */
    static formatOptions(options) {
        if (!options) {
            return [];
        }

        return options.map(({ id, name }) => ({
            key: id,
            value: name,
        }));
    }

    /**
     * Format zip code.
     */
    static formatZip = (zip) => {
        const removeNonNumerics = value => value.replace(/\D/g, '');
        const takeFirstFive = value => value.substring(0, 5);

        return takeFirstFive(removeNonNumerics(zip));
    };

    state = {
        passwordHasBeenBlurred: false,
        passwordFormatRules: {},
    };

    /**
     * Filter zip code input.
     */
    filterZipInput = (e) => {
        if (KEY_NUMBERS_AND_SERVICE.indexOf(e.keyCode) === -1 && !e.ctrlKey) {
            e.preventDefault();
        }
    };

    /**
     * Handle changes to service offerings.
     */
    changeServiceOfferings = (id) => {
        const { setFieldValue, values } = this.props.formik;

        setFieldValue('serviceOfferingIds', xor(values.serviceOfferingIds, [id]));
        this.props.handlePracticeInfoChange();
    };

    /**
     * Handle changes to info dropdown.
     */
    handleDropdownChange() {
        this.props.handlePracticeInfoChange();
    }

    /**
     * Handle other generic changes.
     */
    handleChange = (e) => {
        const { name } = e.target;
        const { errors, setFieldError } = this.props.formik;

        if (name && errors[name]) {
            setFieldError(name, null);
        }

        this.props.handlePracticeInfoChange();
        this.props.onEditClick();
    };

    /**
     * Real time validation of first name field.
     * @param {*} e 
     */
    handleFirstNameBlur = (e) => {
        const { setFieldError } = this.props.formik;

        if (e.target.value.length < 1) {
            setFieldError('firstName', labels.translations['error.emptyField']);
        }
    }

    /**
     * Ensure current password has a value IF there are values in either new or confirm new password fields.
     * @param {*} e 
     */
    handleCurrentPasswordBlur = (e) => {
        this.handleCurrentPasswordBlurError(e.target.value);
    }

    /**
     * Handles setting an error to current password from various flows.
     * @param {*} value 
     */
    handleCurrentPasswordBlurError = (value) => {
        const { setFieldError } = this.props.formik;
        const newPasswordValue = document.getElementById('password').value;
        const newPasswordConfirmValue = document.getElementById('verifyPassword').value;

        if ((newPasswordValue.length || newPasswordConfirmValue.length) && !value.length) {
            setFieldError('curPassword', labels.translations['profilePage.errors.correctPasswordFormat']);
        }
    }

    /**
     * Real time validation of new password field.
     * @param {*} e 
     */
    handleNewPasswordBlur = (e) => {
        const currentPasswordValue = document.getElementById('curPassword').value;

        this.handleCurrentPasswordBlurError(currentPasswordValue);
    }

    /**
     * Real time validation of confirm new password field.
     * @param {*} e 
     */
    handleValidatePasswordBlur = (e) => {
        const { setFieldError } = this.props.formik;
        const value = e.target.value;
        const newPasswordValue = document.getElementById('password').value;

        if ((value.length || newPasswordValue.length) && value !== newPasswordValue) {
            setFieldError('verifyPassword', labels.translations['profilePage.errors.matchPasswords']);
        }

        const curPassValue = document.getElementById('curPassword').value;

        if ((value.length || newPasswordValue.length) && !curPassValue.length) {
            setTimeout(() => {
                setFieldError('curPassword', labels.translations['profilePage.errors.correctPasswordFormat']);
            }, 200);
        }
    }

    /**
     * Real time validation of last name field.
     * @param {*} e 
     */
    handleLastNameBlur = (e) => {
        const { setFieldError } = this.props.formik;

        if (e.target.value.length < 1) {
            setFieldError('lastName', labels.translations['error.emptyField']);
        }
    }

    /**
     * Handle password field validation on blur.
     */
    handlePasswordBlur = (e) => {

        const charLength = 'isCharLength';
        let isValid = false;
        let ruleCount = 0;
        const newPassword = document.getElementById('password').value;
        const verifyPass = document.getElementById('verifyPassword').value;
        const name = 'verifyPassword';
        const { errors, setFieldError } = this.props.formik;

        this.setState((prevState) => {
            const passwordHasBeenBlurred = true;
            const passwordFormatRules = (
                handlePasswordBlur(e, prevState.passwordFormatRules, passwordHasBeenBlurred, document.getElementById('email').value)
            );

            if ((verifyPass.length || newPassword.length) && newPassword === verifyPass && errors[name]) {
                setFieldError(name, null);
            }

            if (passwordFormatRules[charLength] === true) {
                for (let index in passwordFormatRules) {
                    if (index !== charLength && passwordFormatRules[index] === true) {
                        ruleCount++;
                        if (ruleCount > 3) {
                            isValid = true;
                        }
                    }
                }
            }

            if (isValid && passwordFormatRules.containsRepeat) {
                isValid = false;
            }

            if (!isValid) {
                const { setFieldError } = this.props.formik;

                setFieldError('password', labels.translations['profilePage.errors.badPasswordFormat']);
            }
            return {
                passwordHasBeenBlurred,
                passwordFormatRules,
            };
        });
    };

    /**
     * Handle changes to password field.
     */
    handlePasswordChange = (e) => {
        const { passwordFormatRules, passwordHasBeenBlurred } = this.state;

        const updatedPasswordRules = (
            handlePasswordChange(e, passwordFormatRules, passwordHasBeenBlurred, document.getElementById('email').value)
        );

        this.handleChange(e);

        this.setState({ passwordFormatRules: updatedPasswordRules });
    };

    /**
     * Render the service offerings selections.
     */
    renderServiceOfferings() {
        const { classifications, formik, formPage } = this.props;

        return classifications.serviceOfferings
            .map(item => (
                <StyledServicesOfferedListItem key={item.id}>
                    <UnderlinedCheckbox
                        label={item.name}
                        id={`serviceOffering-${item.id}`}
                        name="serviceOfferingIds"
                        value={item.id}
                        checked={formik.values.serviceOfferingIds.includes(item.id)}
                        onChange={() => this.changeServiceOfferings(item.id)}
                    />
                </StyledServicesOfferedListItem>
            ));
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const { intl, setInputRef, classifications, formPage, credentialUpdateFailed, formPracticeInfo } = this.props;
        const { values, setFieldValue } = this.props.formik;
        const {
            amounts,
            durations,
            aums,
            wmAum,
            wmHouseHold,
            retirementAum,
            retirementPlans,
        } = classifications;
        const { formatMessage } = intl;

        const crdValue = values.crd || false;
        const iardValue = values.iard || false;

        let ValuesPrint = (service, valueID) => {
            let result = '--';
            service.map((num) => {

                if (num.id == valueID) {
                    result = ` ${num.name}`;
                    return result;
                }
            });

            return result;
        }

        return (
            <StyledForm>
                {
                    formPage == "true"
                        ?
                        (
                            <StyledForm className="editAccount">
                                <StyledInfoRow edit>
                                    <StyledRowKey edit>
                                        <FormattedMessage id="profilePage.accountInformation.firstname"
                                            defaultMessage="First Name" />
                                    </StyledRowKey>
                                    <StyledRowValue>
                                        <StyledFieldContainer containerRow='firstName' >
                                            <Field
                                                component={FieldText}
                                                floatingLabel={false}
                                                id="firstName"
                                                maxLength={50}
                                                name="firstName"
                                                label={formatMessage({ id: 'profilePage.accountInformation.firstName' })}
                                                size="small"
                                                ref={setInputRef('firstName')}
                                                onChange={this.handleChange}
                                                onBlur={this.handleFirstNameBlur}
                                                isDefault
                                            />
                                        </StyledFieldContainer>

                                        <StyledRowKey edit>
                                            <FormattedMessage id="profilePage.accountInformation.lastname"
                                                defaultMessage="Last Name" />
                                        </StyledRowKey>
                                        <StyledFieldContainer containerRow='lastName'
                                            Last Name
                                        >
                                            <Field
                                                component={FieldText}
                                                floatingLabel={false}
                                                id="lastName"
                                                maxLength={50}
                                                name="lastName"
                                                label={formatMessage({ id: 'profilePage.accountInformation.lastName' })}
                                                size="small"
                                                ref={setInputRef('lastName')}
                                                onChange={this.handleChange}
                                                onBlur={this.handleLastNameBlur}
                                                isDefault
                                            />
                                        </StyledFieldContainer>
                                    </StyledRowValue>
                                </StyledInfoRow>

                                <StyledInfoRow edit>
                                    <StyledRowKey edit>
                                        <FormattedMessage id="profilePage.accountInformation.email" />
                                    </StyledRowKey>
                                    <StyledRowValue>
                                        <StyledEmailRowValue>
                                            <StyledFieldContainer containerRow='email'
                                                email
                                            >
                                                <Field
                                                    component={FieldText}
                                                    floatingLabel={false}
                                                    id="email"
                                                    name="email"
                                                    label={formatMessage({ id: 'profilePage.accountInformation.email' })}
                                                    size="small"
                                                    disabled
                                                    isDefault
                                                    multiple="true"
                                                />
                                            </StyledFieldContainer>
                                        </StyledEmailRowValue>
                                        {crdValue != false && (
                                            <div>
                                                <StyledRowKey edit>
                                                    <FormattedMessage id="profilePage.accountInformation.crd" />
                                                </StyledRowKey>

                                                <StyledFieldContainer
                                                    crd
                                                >
                                                    <Field
                                                        component={FieldText}
                                                        floatingLabel={false}
                                                        id="crd"
                                                        name="crd"
                                                        label={formatMessage({ id: 'profilePage.accountInformation.crd' })}
                                                        size="small"
                                                        isDefault
                                                        disabled
                                                    />
                                                </StyledFieldContainer>
                                            </div>
                                        )

                                        }

                                        {

                                            iardValue != false && (
                                                <div>
                                                    <StyledRowKey edit>
                                                        <FormattedMessage id="profilePage.accountInformation.iard" />
                                                    </StyledRowKey>
                                                    <StyledFieldContainer
                                                        iard
                                                    >
                                                        <Field
                                                            component={FieldText}
                                                            floatingLabel={false}
                                                            id="iard"
                                                            name="iard"
                                                            label={formatMessage({ id: 'profilePage.accountInformation.iard' })}
                                                            size="small"
                                                            isDefault
                                                            disabled
                                                        />
                                                    </StyledFieldContainer>
                                                </div>

                                            )
                                        }

                                    </StyledRowValue>
                                </StyledInfoRow>

                                <PasswordRow mainContainerRow='pass' >
                                    <PasswordSubHeader>
                                        <FormattedMessage id="profilePage.accountInformation.changePassword" />
                                    </PasswordSubHeader>
                                    <StyledInfoRow edit>
                                        <StyledRowValue
                                            halfFieldContainer
                                        >
                                            <StyledPasswordRowWrapper>
                                                <StyledPasswordRow>
                                                    <StyledFieldContainer
                                                        halfFieldContainer
                                                        passwordField
                                                    >
                                                        <Field
                                                            component={FieldText}
                                                            maxLength="50"
                                                            floatingLabel={false}
                                                            id="curPassword"
                                                            name="curPassword"
                                                            label={formatMessage({ id: 'profilePage.accountInformation.curPassword' })}
                                                            size="small"
                                                            type="Password"
                                                            ref={setInputRef('curPassword')}
                                                            onChange={this.handleChange}
                                                            onBlur={this.handleCurrentPasswordBlur}
                                                            placeholder="Current password"
                                                        />
                                                    </StyledFieldContainer>

                                                    <StyledFieldContainer
                                                        halfFieldContainer
                                                        passwordField
                                                    >
                                                        {/* Hack for bug fix: CDP-3147 */}
                                                        <StyledHiddenInput type="password" />

                                                        <Field
                                                            name="password"
                                                            component={FieldText}
                                                            maxLength="50"
                                                            id="password"
                                                            floatingLabel={false}
                                                            label={formatMessage({ id: "profilePage.accountInformation.newPassword" })}
                                                            size="small"
                                                            type="password"
                                                            autoComplete="new-password"
                                                            ref={setInputRef('password')}
                                                            onChange={this.handlePasswordChange}
                                                            onBlur={(e) => {
                                                                this.handlePasswordBlur(e);
                                                                //this.handleNewPasswordBlur(e);
                                                            }}
                                                            placeholder="New password"
                                                        />
                                                    </StyledFieldContainer>
                                                    <StyledFieldContainer
                                                        halfFieldContainer
                                                        passwordField
                                                    >
                                                        <Field
                                                            component={FieldText}
                                                            maxLength="50"
                                                            floatingLabel={false}
                                                            id="verifyPassword"
                                                            name="verifyPassword"
                                                            label={formatMessage({ id: 'profilePage.accountInformation.verifyPassword' })}
                                                            size="small"
                                                            type="password"
                                                            ref={setInputRef('verifyPassword')}
                                                            onChange={this.handleChange}
                                                            onBlur={this.handleValidatePasswordBlur}
                                                            placeholder="Verify new password"
                                                        />
                                                    </StyledFieldContainer>
                                                </StyledPasswordRow>
                                                <StyledPasswordRulesWrapper>
                                                    <PasswordRules
                                                        passwordRules={this.state.passwordFormatRules}
                                                        theme="small"
                                                    />
                                                </StyledPasswordRulesWrapper>
                                            </StyledPasswordRowWrapper>
                                        </StyledRowValue>
                                    </StyledInfoRow>
                                </PasswordRow>
                            </StyledForm>
                        )
                        :
                        (


                            formPracticeInfo == false ?
                                (

                                    <StyledForm className="practiceInfocontainer">
                                        <StyledHeadingContainer>
                                            <StyledHeading>
                                                Wealth Management
                                            </StyledHeading>
                                        </StyledHeadingContainer>

                                        <StyledInfoRow
                                            edit
                                            dropdownRow
                                        >
                                            <StyledRowKey edit>
                                                <FormattedMessage id="profilePage.accountInformation.wealthManagement" />
                                            </StyledRowKey>
                                            <StyledRowValue>
                                                <StyledFieldContainer>
                                                    <LabelTitle>
                                                        {formatMessage({ id: 'profilePage.accountInformation.totalAum' })}
                                                    </LabelTitle>
                                                    <ValueTxt>
                                                        {ValuesPrint(wmAum, values.wmAUMId)}
                                                    </ValueTxt>

                                                </StyledFieldContainer>


                                                <StyledFieldContainer>

                                                    <LabelTitle>
                                                        {formatMessage({ id: 'profilePage.accountInformation.numberOfHouseHolds' })}
                                                    </LabelTitle>
                                                    <ValueTxt>
                                                        {ValuesPrint(wmHouseHold, values.wmHouseholdId)}
                                                    </ValueTxt>

                                                </StyledFieldContainer>
                                            </StyledRowValue>
                                        </StyledInfoRow>

                                        <StyledHeadingContainer heading='headingRetirement'>
                                            <StyledHeading>
                                                Retirement Plans
                                            </StyledHeading>
                                        </StyledHeadingContainer>

                                        <StyledInfoRow
                                            edit
                                            dropdownRow
                                        >
                                            <StyledRowKey edit>
                                                <FormattedMessage id="profilePage.accountInformation.wealthManagement" />
                                            </StyledRowKey>
                                            <StyledRowValue>
                                                <StyledFieldContainer>
                                                    <LabelTitle>
                                                        {formatMessage({ id: 'profilePage.accountInformation.retirementTotalAum' })}
                                                    </LabelTitle>
                                                    <ValueTxt>
                                                        {ValuesPrint(retirementAum, values.retirementPlanAUMId)}
                                                    </ValueTxt>

                                                </StyledFieldContainer>
                                                <StyledFieldContainer>
                                                    <LabelTitle>
                                                        {formatMessage({ id: 'profilePage.accountInformation.numberOfPlans' })}
                                                    </LabelTitle>
                                                    <ValueTxt>
                                                        {ValuesPrint(retirementPlans, values.retirementPlanPlansId)}
                                                    </ValueTxt>

                                                </StyledFieldContainer>

                                            </StyledRowValue>
                                        </StyledInfoRow>

                                        <StyledHeadingContainer heading='aboutYou'>
                                            <StyledHeading>
                                                About You
                                            </StyledHeading>
                                        </StyledHeadingContainer>

                                        <StyledInfoRow
                                            edit
                                            dropdownRow
                                        >
                                            <StyledRowKey edit>
                                                <FormattedMessage id="profilePage.accountInformation.experience" />
                                            </StyledRowKey>
                                            <StyledRowValue>
                                                <StyledFieldContainer>
                                                    <LabelTitle>
                                                        {formatMessage({ id: 'profilePage.accountInformation.experience' })}
                                                    </LabelTitle>
                                                    <ValueTxt>
                                                        {ValuesPrint(durations, values.durationId)}
                                                    </ValueTxt>
                                                </StyledFieldContainer>
                                            </StyledRowValue>
                                        </StyledInfoRow>
                                    </StyledForm>

                                ) : (
                                    <StyledForm className="practiceInfocontainer">
                                        <StyledHeadingContainer heading='wealthMangt'>
                                            <StyledHeading>
                                                Wealth Management
                                            </StyledHeading>
                                        </StyledHeadingContainer>

                                        <StyledInfoRow
                                            edit
                                            dropdownRow
                                            content='welathContainer'
                                        >
                                            <StyledRowKey edit>
                                                <FormattedMessage id="profilePage.accountInformation.wealthManagement" />
                                            </StyledRowKey>
                                            <StyledRowValue>
                                                <StyledFieldContainer>
                                                    <Dropdown
                                                        id="wmAUMId"
                                                        title={formatMessage({ id: 'profilePage.accountInformation.totalAum' })}
                                                        ariaLabel={formatMessage({ id: 'profilePage.accountInformation.totalAum' })}
                                                        emptyOptionLabel={formatMessage({ id: 'profilePage.accountInformation.totalAum' })}
                                                        isEmptyOptionSelectable={true}
                                                        value={values.wmAUMId}
                                                        options={EditProfileForm.formatOptions(wmAum)}
                                                        onValueChanged={val => {
                                                            setFieldValue('wmAUMId', val); this.handleDropdownChange();
                                                        }}
                                                    />
                                                </StyledFieldContainer>


                                                <StyledFieldContainer containerRow='household' >
                                                    <Dropdown
                                                        id="wmHouseholdId"
                                                        title={formatMessage({ id: 'profilePage.accountInformation.numberOfHouseHolds' })}
                                                        ariaLabel={formatMessage({ id: 'profilePage.accountInformation.numberOfHouseHolds' })}
                                                        emptyOptionLabel={formatMessage({ id: 'profilePage.accountInformation.numberOfHouseHolds' })}
                                                        isEmptyOptionSelectable={true}
                                                        value={values.wmHouseholdId}
                                                        options={EditProfileForm.formatOptions(wmHouseHold)}
                                                        onValueChanged={val => {
                                                            setFieldValue('wmHouseholdId', val); this.handleDropdownChange();
                                                        }}
                                                    />
                                                </StyledFieldContainer>
                                            </StyledRowValue>
                                        </StyledInfoRow>

                                        <StyledHeadingContainer heading='headingRetirementEdit'>
                                            <StyledHeading>
                                                Retirement Plans
                                            </StyledHeading>
                                        </StyledHeadingContainer>

                                        <StyledInfoRow
                                            edit
                                            dropdownRow
                                        >
                                            <StyledRowKey edit>
                                                <FormattedMessage id="profilePage.accountInformation.wealthManagement" />
                                            </StyledRowKey>
                                            <StyledRowValue>
                                                <StyledFieldContainer containerRow='retirementAum'>
                                                    <Dropdown
                                                        id="retirementPlanAUMId"
                                                        title={formatMessage({ id: 'profilePage.accountInformation.retirementTotalAum' })}
                                                        ariaLabel={formatMessage({ id: 'profilePage.accountInformation.retirementTotalAum' })}
                                                        emptyOptionLabel={formatMessage({ id: 'profilePage.accountInformation.retirementTotalAum' })}
                                                        isEmptyOptionSelectable={true}
                                                        value={values.retirementPlanAUMId}
                                                        options={EditProfileForm.formatOptions(retirementAum)}
                                                        onValueChanged={val => {
                                                            setFieldValue('retirementPlanAUMId', val); this.handleDropdownChange();
                                                        }}
                                                    />
                                                </StyledFieldContainer>
                                                <StyledFieldContainer containerRow='retirementId'>
                                                    <Dropdown
                                                        id="retirementPlanPlansId"
                                                        title={formatMessage({ id: 'profilePage.accountInformation.numberOfPlans' })}
                                                        ariaLabel={formatMessage({ id: 'profilePage.accountInformation.numberOfPlans' })}
                                                        emptyOptionLabel={formatMessage({ id: 'profilePage.accountInformation.numberOfPlans' })}
                                                        isEmptyOptionSelectable={true}
                                                        value={values.retirementPlanPlansId}
                                                        options={EditProfileForm.formatOptions(retirementPlans)}
                                                        onValueChanged={val => {
                                                            setFieldValue('retirementPlanPlansId', val); this.handleDropdownChange();
                                                        }}
                                                    />
                                                </StyledFieldContainer>

                                            </StyledRowValue>
                                        </StyledInfoRow>

                                        <StyledHeadingContainer heading='aboutYouEdit'>
                                            <StyledHeading>
                                                About You
                                            </StyledHeading>
                                        </StyledHeadingContainer>

                                        <StyledInfoRow
                                            edit
                                            dropdownRow
                                        >
                                            <StyledRowKey edit>
                                                <FormattedMessage id="profilePage.accountInformation.experience" />
                                            </StyledRowKey>
                                            <StyledRowValue>
                                                <StyledFieldContainer>
                                                    <Dropdown
                                                        id="durationId"
                                                        title={formatMessage({ id: 'profilePage.accountInformation.experience' })}
                                                        ariaLabel={formatMessage({ id: 'profilePage.accountInformation.experience' })}
                                                        emptyOptionLabel={formatMessage({ id: 'profilePage.accountInformation.experience' })}
                                                        isEmptyOptionSelectable={true}
                                                        value={values.durationId}
                                                        options={EditProfileForm.formatOptions(durations)}
                                                        onValueChanged={val => {
                                                            setFieldValue('durationId', val); this.handleDropdownChange();
                                                        }}
                                                    />
                                                </StyledFieldContainer>
                                            </StyledRowValue>
                                        </StyledInfoRow>
                                    </StyledForm>

                                )
                        )
                }
            </StyledForm>
        );
    }
}

EditProfileForm.propTypes = {
    handlePracticeInfoChange: PropTypes.func,
};

EditProfileForm.propTypes = {
    formik: PropTypes.shape({
        values: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            email: PropTypes.string,
            crd: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string,
            ]),
            iard: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string,
            ]),
            curPassword: PropTypes.string,
            newPassword: PropTypes.string,
            verifyPassword: PropTypes.string,
            zip: PropTypes.string,
            durationId: PropTypes.number,
            serviceOfferingIds: PropTypes.arrayOf(PropTypes.number).isRequired,
            amountId: PropTypes.number,
            aumId: PropTypes.number,
            wmAUMId: PropTypes.number,
            wmHouseholdId: PropTypes.number,
            retirementPlanAUMId: PropTypes.number,
            retirementPlanPlansId: PropTypes.number,
        }).isRequired,
        setFieldValue: PropTypes.func.isRequired,
        setFieldError: PropTypes.func.isRequired,
        errors: PropTypes.shape({}).isRequired,
    }).isRequired,
    classifications: PropTypes.shape({
        durations: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })).isRequired,
        wmAum: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })).isRequired,
        wmHouseHold: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })).isRequired,
        retirementAUM: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })),
        retirementPlanPlans: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })),
    }).isRequired,
    setInputRef: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    handlePracticeInfoChange: PropTypes.func,
    credentialUpdateFailed: PropTypes.bool,
    newPasswordFailed: PropTypes.bool,
};

EditProfileForm.defaultProps = {
    credentialUpdateFailed: false,
    newPasswordFailed: false,
    handlePracticeInfoChange: () => { },
}

export default injectIntl(EditProfileForm);
