import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Button from 'components/atomics/atoms/Button/Button';
import styled from 'styled-components';
import { createImageUrl } from 'utils/urlUtils';
import ImageAtom from 'components/atomics/atoms/Image/Image';
import LegalFDIC from 'components/LegalFDIC/LegalFDIC';
import {
    FRAGMENT_ABOUT_LUMIN_WHAT_IS_LUMIN,
    FRAGMENT_ABOUT_LUMIN_HOW_DOES_IT_WORK,
    FRAGMENT_ABOUT_LUMIN_IS_THERE_A_CATCH,
    FRAGMENT_ABOUT_LUMIN_SOURCES,
    FRAGMENT_ABOUT_LUMIN_TOPICS,
    FRAGMENT_ABOUT_LUMIN_ONE_SPOT,
    FRAGMENT_ABOUT_LUMIN,
} from 'state-management/constants/commonContentFragments';
import { getContentFragments } from 'state-management/actions/commonContentFragments';
import { setScrollToTop } from 'state-management/actions/scroll';
import {
    Container, Heading, ImageHeading, Loader, SanitizedHTML,
} from 'components';
import { ROUTES } from 'containers/App/constants';
import {
    mainContentContainer,
    descriptionStyles,
    contentContainerStyles,
    contentCardStyles,
    questionsSectionContainer,
    questionsHeadingStyles,
    metricsContainerStyles,
    metricsContentStyles,
    metricStyles,
    imageTextContainer,
} from 'containers/AboutLumin/styles';

const COVER_IMAGE_URL = createImageUrl('hero-images', 'about-lumin.jpg');
const MOBILE_COVER_IMAGE_URL = createImageUrl('hero-images', 'about-lumin-mobile.jpg');

const StyledImageTextContainer = styled.div`
    ${imageTextContainer}
`;

const StyledMainContentContainer = styled.div`
    ${mainContentContainer}
`;

const StyledDescription = styled.p`
    ${descriptionStyles}
`;

const StyledContentContainer = styled.div`
    ${contentContainerStyles}
`;

const StyledContentCard = styled(SanitizedHTML)`
    ${contentCardStyles}
`;

const StyledQuestionsSectionContainer = styled.div`
    ${questionsSectionContainer}
`;

const StyledQuestionsHeading = styled(Heading)`
    ${questionsHeadingStyles}
`;

const StyledMetricsContainer = styled.div`
    ${metricsContainerStyles}
`;

const StyledMetricsContent = styled(Container)`
    ${metricsContentStyles}
`;

const StyledMetric = styled(SanitizedHTML)`
    ${metricStyles}
`;

/**
 * About us simple content page.
 */
class AboutLumin extends React.Component {
    /**
     * Gather content and scroll page to top at mount.
     */
    componentDidMount() {
        const { getContent, whatIsLuminContent, scrollToTop } = this.props;

        scrollToTop();
        if (!whatIsLuminContent) {
            getContent();
        }
    }

    /**
     * Trim down content at load.
     */
    contentLoaded = () => {
        let {
            aboutLumin
        } = this.props;

        return aboutLumin
    };

    /**
     * Render this and underlying components.
     */
    render() {
        let {
            whatIsLuminContent,
            howDoesItWorkContent,
            isThereACatchContent,
            sourcesContent,
            topicsContent,
            oneSpotContent,
            aboutLumin,
        } = this.props;

        if (aboutLumin && aboutLumin.contentList){
          let list = aboutLumin.contentList
          list.forEach(function(entry) {
              if (entry && entry.code == [FRAGMENT_ABOUT_LUMIN_WHAT_IS_LUMIN]){
                whatIsLuminContent = entry.text;
              } else if (entry && entry.code == [FRAGMENT_ABOUT_LUMIN_HOW_DOES_IT_WORK]){
                howDoesItWorkContent = entry.text;
              } else if (entry && entry.code == [FRAGMENT_ABOUT_LUMIN_IS_THERE_A_CATCH]){
                isThereACatchContent = entry.text;
              } else if  (entry && entry.code == [FRAGMENT_ABOUT_LUMIN_SOURCES]){
                sourcesContent = entry.text;
              }else  if (entry && entry.code == [FRAGMENT_ABOUT_LUMIN_TOPICS]){
                topicsContent = entry.text;
              } else if (entry && entry.code == [FRAGMENT_ABOUT_LUMIN_ONE_SPOT]){
                oneSpotContent = entry.text;
              }
            });
        }

        if (!this.contentLoaded()) {
            return <Loader />;
        }

        const heading = <FormattedMessage id="aboutLumin.heading" />;

        return (
            <Fragment>
                <ImageHeading
                    title={heading}
                    image={COVER_IMAGE_URL}
                    smallImage={MOBILE_COVER_IMAGE_URL}
                />
                <StyledMainContentContainer variant="banner">
                    <Container>
                        <StyledImageTextContainer>
                            <StyledDescription>
                                <FormattedMessage id="aboutLumin.subHeading" />
                            </StyledDescription>
                            <ImageAtom
                                src="/images/phone.svg"
                                altResourceId="aboutLumin.phone.alt"
                            />
                        </StyledImageTextContainer>
                    </Container>
                </StyledMainContentContainer>
                <StyledMainContentContainer theme="dark">
                    <Container>
                        <StyledContentContainer variant="lower">
                            <StyledContentCard
                                variant="first"
                                htmlContent={whatIsLuminContent}
                                tag="article"
                            />
                            <StyledContentCard
                                htmlContent={howDoesItWorkContent}
                                tag="article"
                            />
                            <StyledContentCard
                                htmlContent={isThereACatchContent}
                                tag="article"
                            />
                        </StyledContentContainer>
                    </Container>
                </StyledMainContentContainer>
                <StyledMainContentContainer>
                    <Container>
                        <StyledQuestionsSectionContainer>
                            <StyledQuestionsHeading level={2}>
                                <FormattedMessage id="aboutLumin.haveQuestions" />
                            </StyledQuestionsHeading>
                            <Button
                                id="about-lumin-contact-us"
                                to={ROUTES.CONTACT_US}
                            >
                                <FormattedMessage id="aboutLumin.contactTeam" />
                            </Button>
                        </StyledQuestionsSectionContainer>
                    </Container>
                </StyledMainContentContainer>
                <StyledMetricsContainer>
                    <StyledMetricsContent>
                        <StyledMetric htmlContent={sourcesContent} tag="div" />
                        <StyledMetric htmlContent={topicsContent} tag="div" />
                        <StyledMetric htmlContent={oneSpotContent} tag="div" />
                    </StyledMetricsContent>
                </StyledMetricsContainer>
                <LegalFDIC />
            </Fragment>
        );
    }
}

AboutLumin.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    getContent: PropTypes.func.isRequired,
    scrollToTop: PropTypes.func.isRequired,
    whatIsLuminContent: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }),
    howDoesItWorkContent: PropTypes.shape({
        text: PropTypes.string.isRequired,
    }),
    isThereACatchContent: PropTypes.shape({
        text: PropTypes.string.isRequired,
    }),
    sourcesContent: PropTypes.shape({
        text: PropTypes.string.isRequired,
    }),
    topicsContent: PropTypes.shape({
        text: PropTypes.string.isRequired,
    }),
    oneSpotContent: PropTypes.shape({
        text: PropTypes.string.isRequired,
    }),
};

AboutLumin.defaultProps = {
    whatIsLuminContent: undefined,
    howDoesItWorkContent: undefined,
    isThereACatchContent: undefined,
    sourcesContent: undefined,
    topicsContent: undefined,
    oneSpotContent: undefined,
};

const mapStateToProps = state => ({
    isLoading: state.commonContentFragments.isLoading,
    aboutLumin: state.commonContentFragments.fragments[FRAGMENT_ABOUT_LUMIN],
});

const mapDispatchToProps = dispatch => ({
    getContent: () => dispatch(getContentFragments([
        FRAGMENT_ABOUT_LUMIN,
    ], true)),
    scrollToTop: () => dispatch(setScrollToTop()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutLumin);
