import {
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_ERROR,
    CHANGE_PASSWORD_SUCCESS,
    SEND_FORGOT_PASSWORD_EMAIL,
    SEND_FORGOT_PASSWORD_EMAIL_SUCCESS,
    SEND_FORGOT_PASSWORD_EMAIL_ERROR,
    RESET_FORGOT_PASSWORD_EMAIL,
    VERIFY_PASSWORD_TOKEN,
    VERIFY_PASSWORD_TOKEN_ERROR,
    VERIFY_PASSWORD_TOKEN_SUCCESS,
    CURRENT_PASSWORD,
} from 'state-management/constants/forgotPassword';

export const curPassword = (curPassword, password, verifyPassword ) =>({
    type:  CURRENT_PASSWORD,
    curPassword,
    password,
    verifyPassword
});

export const sendForgotPasswordEmail = email => ({
    type: SEND_FORGOT_PASSWORD_EMAIL,
    email,
});

export const sendForgotPasswordEmailSuccess = email => ({
    type: SEND_FORGOT_PASSWORD_EMAIL_SUCCESS,
    email,
});

export const sendForgotPasswordEmailError = error => ({
    type: SEND_FORGOT_PASSWORD_EMAIL_ERROR,
    error,
});

export const resetForgotPasswordEmail = () => ({
    type: RESET_FORGOT_PASSWORD_EMAIL,
});

export const verifyToken = token => ({
    type: VERIFY_PASSWORD_TOKEN,
    token,
});

export const setVerifySuccess = () => ({
    type: VERIFY_PASSWORD_TOKEN_SUCCESS,
});

export const setVerifyError = error => ({
    type: VERIFY_PASSWORD_TOKEN_ERROR,
    error,
});

export const changePassword = (password, token) => ({
    type: CHANGE_PASSWORD,
    password,
    token,
});

export const changePasswordError = error => ({
    type: CHANGE_PASSWORD_ERROR,
    error,
});

export const changePasswordSuccess = () => ({
    type: CHANGE_PASSWORD_SUCCESS,
});


