export const GET_SOURCES_SUCCESS = 'sources/GET_SOURCES_SUCCESS';
export const GET_SOURCES_FAIL = 'sources/GET_SOURCES_FAIL';
export const GET_SOURCES = 'sources/GET_SOURCES';
export const GET_PROFILE_SOURCES_SUCCESS = 'sources/GET_PROFILE_SOURCES_SUCCESS';
export const GET_PROFILE_SOURCES_FAIL = 'sources/GET_PROFILE_SOURCES_FAIL';
export const GET_PROFILE_SOURCES = 'sources/GET_PROFILE_SOURCES';
export const SOURCES_URL = 'advisor-service/sources';
export const PROFILE_SOURCES_URL = 'advisor-service/sources/profile';

export const TOGGLE_FOLLOW_SOURCE = 'toggleFollow/TOGGLE_FOLLOW_SOURCE';
export const TOGGLE_FOLLOW_SOURCE_FAIL = 'toggleFollow/TOGGLE_FOLLOW_SOURCE_FAIL';
export const TOGGLE_FOLLOW_SOURCE_SUCCESS = 'toggleFollow/TOGGLE_FOLLOW_SOURCE_SUCCESS';

export const FOLLOW_SOURCE_URL = sourceId => `/advisor-service/sources/${sourceId}/follow`;

export const SOURCE_LINK = source => `/sources/${source}`;
