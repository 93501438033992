import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Button } from 'components';
import { KEY_LEFT, KEY_RIGHT } from 'utils/keyCodes';
import {
    tabItemStyles, tabListStyles, tabPanelStyles, tabsStyles,
} from 'components/Tabs/styles';
import { contextHubRecordEvent } from '../../utils/contextHub';

const StyledTabs = styled.div`
    ${tabsStyles};
`;

const StyledTabList = styled.div`
    ${tabListStyles};
`;

export const StyledTabItem = styled(({ passedRef, ...other }) => <Button ref={passedRef} {...other} />)`
    ${tabItemStyles};
`;

export const StyledTabPanel = styled.div`
    ${tabPanelStyles};
`;

class Tab extends React.Component {
    /**
     * Default constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: props.activeIndex ? props.activeIndex : 0,
        };

        this.checkHandleTabChange(props.activeIndex ? props.activeIndex : 0);

        this.tabItems = [];
    }

    /**
     * Update active tab when receiving new props and that index has changed.
     * @param {*} nextProps 
     */
    componentWillUpdate(nextProps) {
        if (nextProps.activeIndex !== this.props.activeIndex) {
            this.setState({
                activeIndex: nextProps.activeIndex,
            })
        }
    }

    /**
     * Check tab state after updates.
     * @param {*} previousProps 
     * @param {*} previousState 
     */
    componentDidUpdate(previousProps, previousState) {
        this.checkHandleTabChange(previousState.activeIndex);
    }

    /**
     * Handle tab clicks to update index.
     * @param {*} index 
     */
    handleItemClick = (index, id) => (e) => {
        //this is the only place to intersect tab change event for clearing session storage
        sessionStorage.removeItem("ACTIVE_TAB_INDEX");
        this.CH_handleComponentEventList(id)
        this.setState({
            activeIndex: index,
        });
    };

    /**
     * Handle keyboard based tab controls.
     * @param {*} index 
     */
    handleItemKeyUp = (index, id) => (e) => {
        /*let newIndex = index;
        if (e.keyCode === KEY_LEFT) {
            newIndex = index - 1;
        } else if (e.keyCode === KEY_RIGHT) {
            newIndex = index + 1;
        }
        if (newIndex >= this.props.panes.length || newIndex < 0) {
            return;
        }*/
        if (e.keyCode === 13) {
            document.getElementById(id).focus();
        }
    };

    handleMenuItemRef = (tabItem) => {
        if (this.tabItems.indexOf(tabItem) === -1) {
            this.tabItems.push(tabItem);
        }
    };

    checkHandleTabChange(oldActiveIndex) {
        if (this.props.handleTabChange
            && this.state.activeIndex !== oldActiveIndex) {
            this.props.handleTabChange(this.props.panes[this.state.activeIndex]);
        }
    }

    //analytics 
    CH_handleComponentEventList(id) {
        var tracking =
        {
            "type": "tab",
            "action": id,
            "value": 'tab',
        };
        contextHubRecordEvent(tracking);
    }

    renderMenuItems() {
        const { appearance, panes, theme, noSpace } = this.props;
        const { activeIndex } = this.state;

        return panes.map((pane, index) => {
            const isActive = activeIndex === index;

            return (
                <StyledTabItem
                    aria-selected={isActive}
                    id={`tab-menu-item-${pane.id}`}
                    key={pane.id}
                    onClick={this.handleItemClick(index, `tab-menu-item-${pane.id}`)}
                    onKeyUp={this.handleItemKeyUp(index, `tab-menu-item-${pane.id}`)}
                    tabIndex={0}
                    role="tab"
                    variant={{ appearance, isActive, theme }}
                    noSpace={noSpace}
                >
                    <span>
                        {pane.tabTitle}
                    </span>
                </StyledTabItem>
            );
        });
    }

    renderPanels() {
        const { panes } = this.props;
        const { activeIndex } = this.state;
        if (panes && panes.length !== 0) {
            return (
                <StyledTabPanel role="tabpanel">
                    {panes[activeIndex].content()}
                </StyledTabPanel>
            );
        }
        return null;
    }

    renderTabList() {
        const {
            ariaLabel,
            ariaLabelledBy,
        } = this.props;
        const listProps = {};

        if (ariaLabel) {
            listProps['aria-label'] = ariaLabel;
        }

        if (ariaLabelledBy) {
            listProps['aria-labelledby'] = ariaLabelledBy;
        }

        if (this.props.tabListWrapper) {
            const TabListWrapper = this.props.tabListWrapper;
            return (
                <TabListWrapper>
                    {this.renderInnerTabList(listProps)}
                </TabListWrapper>
            );
        }
        return this.renderInnerTabList(listProps);
    }

    renderInnerTabList(listProps) {
        const { appearance, fullWidth, theme } = this.props;

        return (
            <StyledTabList
                {...listProps}
                role="tablist"
                variant={{ appearance, fullWidth, theme }}
            >
                {this.renderMenuItems()}
            </StyledTabList>
        );
    }

    /**
     * Render this and underlying components.
     */
    render() {
        return (
            <StyledTabs theme={this.props.theme}>
                {this.renderTabList()}
                {this.renderPanels()}
            </StyledTabs>
        );
    }
}

Tab.propTypes = {
    appearance: PropTypes.string,
    ariaLabel: PropTypes.string,
    ariaLabelledBy: PropTypes.string,
    fullWidth: PropTypes.bool,
    tabListWrapper: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
    handleTabChange: PropTypes.func,
    panes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        tabTitle: PropTypes.object.isRequired,
        content: PropTypes.func.isRequired,
    })).isRequired,
    theme: PropTypes.string,
    activeIndex: PropTypes.number,
    noSpace: PropTypes.bool,
};


Tab.defaultProps = {
    fullWidth: false,
    appearance: 'default',
    ariaLabel: '',
    ariaLabelledBy: '',
    handleTabChange: null,
    tabListWrapper: null,
    theme: 'dark-blue',
    activeIndex: null,
    noSpace: false,
};

export default Tab;
