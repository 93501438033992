import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Image from 'components/atomics/atoms/Image/Image';
import { BreakpointsContext, MOBILE } from 'components/Breakpoints/Breakpoints';
import {
    imageContainerStyles,
} from 'components/ImageComponent/styles';
import { HBR, MC_KINSEY, STANDARD } from 'containers/Guide/guideTypes';

export const StyledImageContainer = styled.div`
    ${imageContainerStyles}
`;

const ImageComponent = props => (
    <BreakpointsContext.Consumer>
        {breakpoint => (
            <ImageContainer {...props} breakpoint={breakpoint} />
        )}
    </BreakpointsContext.Consumer>
);

export const ImageContainer = ({ id, data, breakpoint }) => {
    const imageSource = breakpoint === MOBILE && data.smallImage ? data.smallImage : data.image;   
    return (
        ImageComponent.areAttributesValid(data) && (
            <StyledImageContainer id={`image-${id}`}>
                <Image
                    alt={data.alternativeText}
                    src={`/api${imageSource}`}
                />
            </StyledImageContainer>
        ));
};

ImageComponent.areAttributesValid = ({ image }) => !!image;

ImageComponent.propTypes = {
    data: PropTypes.shape({
        alternativeText: PropTypes.string,
        guideType: PropTypes.oneOf([
            STANDARD,
            HBR,
            MC_KINSEY,
        ]),
        image: PropTypes.string,
        smallImage: PropTypes.string,
    }).isRequired,
    id: PropTypes.string.isRequired,
};

export default ImageComponent;
