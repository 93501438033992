import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import PropTypes from 'prop-types';
import { Button as DefaultButton } from 'components';
import Button from 'components/atomics/atoms/Button/Button';
import Heading from "../../components/Heading/Heading";
import { FONT_AVENIR_NEXT } from "../../utils/variables";
import { connect } from "react-redux";
import { claimYourSpot, resetWebinarError, getWebinarInfo } from "../../state-management/actions/webinar";
import { push } from 'connected-react-router';
import { css } from 'styled-components';
import { COLORS } from 'utils/variables';

export const StyledHeading = styled(Heading)`
  font-family: ${FONT_AVENIR_NEXT};
  font-size: 42px;
  text-align: center;
  font-weight: 400;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  color: ${COLORS.DARK_SAPPHIRE};
  line-height: 2.2;
`;

export const Description = styled.div`
  text-align: center;
  font-size: 15px;
  color: ${COLORS.BLACK};  
  font-weight: 600;
  font-family: ${FONT_AVENIR_NEXT};
`;

export const StyledActionButton = styled(DefaultButton)`
  text-transform: uppercase;
`;

export const LaterButton = styled(DefaultButton)`
    height: 50px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    color: ${COLORS.SAPPHIRE};
`;

export const Wrapper = styled.div`
    max-width: 561px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    padding-bottom: 80px;
`;

export const ActionsContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 282px;
    margin: auto;
    padding-top: 30px;

    ${props => props.noDataVersion && css`
        max-width: initial;
    `}
`;

/**
 * Webinar error display for PGI failure.
 */
class WebinarLandingError extends Component {
    /**
     * Get webinar info at mount.
     */
    componentDidMount() {
        //this.props.getWebinarInfo();
    }

    /**
     * Render the page and underlying components.
     */
    render() {
        const { claimYourSpot, goToMain, noDataVersion, } = this.props;

        return (
            <Wrapper>
                <StyledHeading
                    appearance={2}
                    id="error-page-heading"
                    level={2}
                >
                    <FormattedMessage id='webinar.error.title' />
                </StyledHeading>
                <Description>
                    {!noDataVersion
                    && <FormattedMessage id='webinar.error.description' />}
                    {noDataVersion
                    && <FormattedMessage id='webinar.error.noData.description' />}
                </Description>
                <ActionsContainer noDataVersion={noDataVersion}>
                    {!noDataVersion
                    && <StyledActionButton
                        onClick={() => {
                            claimYourSpot();
                        }}
                        appearance={DefaultButton.APPEARANCE.PRIMARY}>
                        <FormattedMessage id='webinar.error.button.resend' />
                    </StyledActionButton>}
                    {noDataVersion
                    && <Button
                        to="/learn"
                    >
                        <FormattedMessage id='webinar.page.back' />
                    </Button>}
                    {!noDataVersion
                    && <LaterButton onClick={() => goToMain() }>
                        <FormattedMessage id='webinar.error.button.register' />
                    </LaterButton>}
                </ActionsContainer>
            </Wrapper>
        );
    }
}

WebinarLandingError.propTypes = {
    noDataVersion: PropTypes.bool,
};

WebinarLandingError.defaultProps = {
    noDataVersion: false,
};

const mapDispatchToProps = dispatch => ({
    getWebinarInfo: () => dispatch(getWebinarInfo()),
    resetWebinarErrors: () => {dispatch(resetWebinarError())},
    goToMain: () => dispatch(push('/')),
    claimYourSpot: () => dispatch(claimYourSpot()),
});

const mapState = state => ({

});

export default connect(mapState, mapDispatchToProps)(WebinarLandingError);
