import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { debounce } from 'lodash';
import Button from 'components/atomics/atoms/Button/Button';
import { GLOBAL } from 'utils/variables';
import Dropdown from 'components/atomics/molecules/Dropdown/Dropdown';
import Row from 'components/atomics/atoms/Row/Row';
import Column from 'components/atomics/atoms/Column/Column';
import { TOPIC_LINK } from 'state-management/constants/topics';
import storage from 'utils/store';
import anime from 'animejs';
import { StyledLoader, defaultOptions } from 'components/Loader/Loader';
import CoachMarks from 'containers/CoachMarks/CoachMarks';
import { TYPES } from 'containers/CoachMarks/CoachMarks';
import TrendingTopicCard from '../../../../components/atomics/molecules/TrendingTopicCard/TrendingTopicCard';
import SplashNotification from 'components/atomics/molecules/SplashNotification/SplashNotification';
import {
    TYPE_TT_MEDIUM,
    TYPE_TT_LARGE,
} from 'components/atomics/molecules/TrendingTopicCard/TrendingTopicCard';
import {
    TYPE_GHOST,
    TYPE_SECONDARY_SMALL,
    TYPE_PRIMARY_MEDIUM,
    TYPE_TAB,
    TYPE_GHOST_RIGHT,
    TYPE_LIST,
} from 'components/atomics/atoms/Button/Button';
import {
    TYPE_2COLUMN,
} from 'components/atomics/atoms/Row/Row';
import {
    TYPE_3ROW,
} from 'components/atomics/atoms/Column/Column';
import {
    BreakpointsContext,
    DESKTOP,
    MOBILE,
    TABLET,
} from 'components/Breakpoints/Breakpoints';
import {
    containerStyles,
    lowerSectionStyles,
    sectionStyles,
    titleStyles,
    cardAreaStyles,
    listAreaStyles,
    lowerCtaAreaStyles,
    upperCtaAreaStyles,
    selectionAreaStyles,
    splashWrapper,
    colorBarStyles,
    desktopColumns,
    tabletContainer,
    mobileContainer,
    childFilter,
    parentFilter,
    selectionRight,
    selectionLeft,
    loaderStyles,
    blankAreaStyles,
    MINIMUM_DESKTOP_WIDTH,
    mainSectionStyles,
} from 'components/atomics/templates/TrendingTopics/styles';
import labels from '../../../../lang/en.json';
import language from '../../../../lang/en.json';
import Loader from 'components/Loader/Loader';
import { transformContentItem } from "utils/contentCardUtils";
import { contextHubRecordEvent } from 'utils/contextHub';

export const TRENDING_TOPIC_DATA = 'trendingTopicsData';
export const TRENDING_TOPIC_RESET = 'trendingTopicsReset';

const StyledSection = styled.section`
    ${mainSectionStyles}
`;

const StyledLoaderContainer = styled.div`
    ${loaderStyles}
`;

const StyledBlankArea = styled.div`
    ${blankAreaStyles}
`;

export const ChildFilter = styled.div`
    ${childFilter}
`;

export const SelectionLeft = styled.div`
    ${selectionLeft}
`;

export const SelectionRight = styled.div`
    ${selectionRight}
`;

export const ParentFilter = styled.div`
    ${parentFilter}
`;

export const DesktopRows = styled.div`
    ${desktopColumns}
`;

export const MobileContainer = styled.div`
    ${mobileContainer}
`;

export const TabletContainer = styled.div`
    ${tabletContainer}
`;

export const ColorBar = styled.div`
    ${colorBarStyles}
`;

export const SplashWrapper = styled.div`
    ${splashWrapper}
`;

export const SelectionArea = styled.div`
    ${selectionAreaStyles}
`;

export const CardArea = styled.div`
    ${cardAreaStyles}
`;

export const ListArea = styled.div`
    ${listAreaStyles}
`;

export const LowerCTAArea = styled.div`
    ${lowerCtaAreaStyles}
`;

export const UpperCTAArea = styled.div`
    ${upperCtaAreaStyles}
`;

export const UpperTemplate = styled.div`
    ${sectionStyles}
`;

export const LowerTemplate = styled.div`
    ${lowerSectionStyles}
`;

export const Container = styled.div`
    ${containerStyles}
`;

export const Title = styled.h3`
    ${titleStyles}
`;

const tabDropdownItems = [
    { key: 0, value: labels.translations['trendingTopicsNew.component.tabs.0'] },
    { key: 1, value: labels.translations['trendingTopicsNew.component.tabs.1'] },
    { key: 2, value: labels.translations['trendingTopicsNew.component.tabs.2'] },
    { key: 3, value: labels.translations['trendingTopicsNew.component.tabs.3'] },
];

const subFilterLabels = [
    labels.translations['trendingTopicsNew.component.filterLabel.0'],
    labels.translations['trendingTopicsNew.component.filterLabel.1'],
    labels.translations['trendingTopicsNew.component.filterLabel.2'],
];

const listTitles = [
    labels.translations['trendingTopicsNew.component.list.title.0'],
    labels.translations['trendingTopicsNew.component.list.title.1'],
    labels.translations['trendingTopicsNew.component.list.title.2'],
    labels.translations['trendingTopicsNew.component.list.title.3'],
];

/**
 * Creates the trending topics section used in news page.
 */
class TrendingTopics extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.state = {
            selectedTabId: 0,
            selectedFilterId: 1,
            windowWidth: null,
        };

        this.currentTabName = '';
        this.changeTab = this.changeTab.bind(this);
        this.requestUpdate = this.requestUpdate.bind(this);
        this.changeTabFromSelect = this.changeTabFromSelect.bind(this);
        this.changeFilter = this.changeFilter.bind(this);
        this.getArticlesForTopic = this.getArticlesForTopic.bind(this);
        this.getArticleIds = this.getArticleIds.bind(this);
        this.getTopicIds = this.getTopicIds.bind(this);
        this.getKeyValuesForFilter = this.getKeyValuesForFilter.bind(this);
        this.remapValues = this.remapValues.bind(this);
        this.handleDebouncedResize = debounce(this.setWidth.bind(this), 50);
        this.animateClosed = this.animateClosed.bind(this);

        this.contentRef = React.createRef();
    }

    /**
     * Set component width and listen for resize events at mount.
     */
    componentDidMount() {
        this.setWidth();

        window.addEventListener('resize', this.handleDebouncedResize);
    }

    /**
     * Remove resize listener at destroy.
     */
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleDebouncedResize);
    }

    /**
     * Collapse this component closed (and opacity to 0) from bottom to top.
     */
    animateClosed() {
        const contentNode = this.contentRef.current;

        this.animation = anime.timeline();

        this.animation
            .add({
                targets: contentNode,
                easing: [0.53, 0.05, 0.01, 0.97],
                opacity: [1, 0],
                duration: GLOBAL.ANIMATIONS.COLLAPSE_TIME.MILISECONDS,
                height: 0,
            });

        this.props.handleComponentFailure();
    }

    /**
     * Set the window width constant used for placeholder loader.
     */
    setWidth() {
        const windowWidth = window.innerWidth;

        this.setState({
            windowWidth,
        });
    }

    /**
     * Request fresh data and/or animate the component closed based on supplied data.
     * @param {*} nextProps 
     */
    componentWillUpdate(nextProps) {
        const alyData = storage.get(TRENDING_TOPIC_RESET);

        if (nextProps.loadingFailed === true) {
            this.animateClosed();
        }

        if (window.previousUrl
            && window.previousUrl.indexOf('/trending') > -1
            && alyData
            && nextProps.filters.duration) {

            const alyStore = JSON.parse(alyData);
            const tabId = Number.parseInt(alyStore.selectedTabId);
            const filterId = Number.parseInt(alyStore.selectedFilterId);

            storage.remove(TRENDING_TOPIC_RESET);

            if (this.state.selectedTabId !== tabId || this.state.selectedFilterId !== filterId) {
                this.setState({
                    selectedTabId: tabId,
                    selectedFilterId: filterId,
                }, () => {
                    this.requestUpdate();
                });
            }
        }
    }

    /**
     * Ask the container to perform updates (talk to API) on filter/tab changes.
     */
    requestUpdate() {
        if (this.props.requestUpdate != null) {
            storage.set(TRENDING_TOPIC_RESET, JSON.stringify({
                selectedTabId: this.state.selectedTabId,
                selectedFilterId: this.state.selectedFilterId,
            }));

            this.props.requestUpdate(this.state.selectedTabId, this.state.selectedFilterId);
        }
    }

    /**
     * Handle tab switching (or select switching) and potential data request.
     * @param {*} tabId
     */
    handleDataNeeds(tabId) {
        let filterId = 1;
        let makeRequest = true;

        if (this.props.onTabClick) {
            this.props.onTabClick();
        }

        switch (tabId) {
            case 1:
                if (this.props.wmAUMId != null) {
                    filterId = this.props.wmAUMId;
                } else {
                    makeRequest = false;
                }
                break;
            case 2:
                if (this.props.retirementPlanAUMId != null) {
                    filterId = this.props.retirementPlanAUMId;
                } else {
                    makeRequest = false;
                }
                break;
            case 3:
                if (this.props.experienceId != null) {
                    filterId = this.props.experienceId;
                } else {
                    makeRequest = false;
                }
                break;
            default:
            // code block, nothing to do
        }

        this.setState({
            selectedTabId: tabId,
            selectedFilterId: filterId,
        }, () => {
            if (makeRequest) {
                this.requestUpdate();
            }
        });
    }

    /**
     * Handle tab changes from select dropdown (used in tablet and mobile view).
     * @param {*} id
     */
    changeTabFromSelect(id) {
        if (id !== this.state.selectedTabId) {
            this.handleDataNeeds(id);
        }
    }

    /**
     * Handle child filter changes (second select).
     * @param {*} id
     */
    changeFilter(id) {
        if (id == undefined) {
            this.setState({
                selectedFilterId: this.renderSelectLabel(),
            });
        } else {
            this.setState({
                selectedFilterId: id,
            }, this.requestUpdate);
        }
    }
    /**
     * Handle tab change clicks.
     * @param {*} event
     */
    changeTab(event) {
        let tabId = event.target.getAttribute('data-rel');

        //sometimes the target is a child of the button, such as a label span or SVG
        if (tabId === null) {
            tabId = event.target.parentElement.getAttribute('data-rel')
        }
        if (tabId != null) {
            let analyticsId
            if (tabId == 0) {
                analyticsId = language.translations['trendingTopicsNew.component.tabs.0']
            }
            if (tabId == 1) {
                analyticsId = language.translations['trendingTopicsNew.component.tabs.1']
            }
            if (tabId == 2) {
                analyticsId = language.translations['trendingTopicsNew.component.tabs.2']
            }
            if (tabId == 3) {
                analyticsId = language.translations['trendingTopicsNew.component.tabs.3']
            }
            this.currentTabName = analyticsId;
            this.CH_handleComponentEventList(analyticsId)
        }
        if (Number(tabId) !== this.state.selectedTabId) {
            this.handleDataNeeds(Number(tabId));
        }
    }

    /**
     * Render parent filter in tab form.
     */
    renderTabs() {
        return (
            <React.Fragment>
                <Button
                    to="#_"
                    type={TYPE_TAB}
                    selected={this.state.selectedTabId === 0}
                    onClick={this.changeTab}
                    data-rel="0"
                    dataRel="0"
                >
                    <FormattedMessage id="trendingTopicsNew.component.tabs.0" />
                </Button>
                <Button
                    to="#_"
                    type={TYPE_TAB}
                    selected={this.state.selectedTabId === 1}
                    onClick={this.changeTab}
                    data-rel="1"
                    dataRel="1"
                >
                    <FormattedMessage id="trendingTopicsNew.component.tabs.1" />
                </Button>
                <Button
                    to="#_"
                    type={TYPE_TAB}
                    selected={this.state.selectedTabId === 2}
                    onClick={this.changeTab}
                    data-rel="2"
                    dataRel="2"
                >
                    <FormattedMessage id="trendingTopicsNew.component.tabs.2" />
                </Button>
                <Button
                    to="#_"
                    type={TYPE_TAB}
                    selected={this.state.selectedTabId === 3}
                    onClick={this.changeTab}
                    data-rel="3"
                    dataRel="3"
                >
                    <FormattedMessage id="trendingTopicsNew.component.tabs.3" />
                </Button>
            </React.Fragment>
        );
    }

    /**
     * Render the parent filter selection.
     */
    renderTypeSelect() {
        return (
            <ParentFilter>
                {/* <div>
                    <FormattedMessage
                        id="trendingTopicsNew.component.mainFilter.label"
                    />
                </div> */}
                <Dropdown
                    id="typeId"
                    title={labels.translations['trendingTopicsNew.component.mainFilter.label']}
                    label={labels.translations['trendingTopicsNew.component.mainFilter.label']}
                    value={this.state.selectedTabId}
                    options={tabDropdownItems}
                    emptyOptionLabel={labels.translations['trendingTopicsNew.component.mainFilter.label']}
                    onValueChanged={this.changeTabFromSelect}
                    appearance="criteria"
                    isEmptyOptionSelectable={false}
                    size="medium"
                    isNone="no"
                    showLabel={true}
                    notitle="no"
                />
            </ParentFilter>
        );
    }

    /**
     * Render the sub filter selection label as needed.
     */
    renderSelectLabel() {
        return (
            <React.Fragment>
                {subFilterLabels[this.state.selectedTabId - 1]}
            </React.Fragment>
        );
    }

    /**
     * Produce unique key values for filter items.
     */
    getKeyValuesForFilter() {
        const data = [];
        let source;

        switch (this.state.selectedTabId) {
            case 1:
                source = this.props.filters.aum;
                break;
            case 2:
                source = this.props.filters.retirement;
                break;
            case 3:
                source = this.props.filters.duration;
                break;
            default:
            //nothing
        }

        if (source) {
            return source.map(this.remapValues);
        }

        return null;
    }

    /**
     * Remap supplied values based on selected source.
     * @param {*} item 
     * @param {*} index 
     */
    remapValues(item, index) {
        return {
            key: item.id,
            value: item.name,
        };
    }

    /**
     * Render the child filter selection.
     */
    renderSelect(mode) {
        if (this.state.selectedTabId === 0
            || (this.state.selectedTabId === 1 && this.props.wmAUMId === null)
            || (this.state.selectedTabId === 2 && this.props.retirementPlanAUMId === null)
            || (this.state.selectedTabId === 3 && this.props.experienceId === null)) {
            return null;
        }

        const values = this.getKeyValuesForFilter();

        return (
            <div>
                <ChildFilter mode={mode}>
                    <Dropdown
                        id="typeId"
                        value={this.state.selectedFilterId}
                        options={values}
                        onValueChanged={this.changeFilter}
                        nullable
                        appearance="criteria"
                        isEmptyOptionSelectable={false}
                        size="medium"
                        title={this.renderSelectLabel()}
                        isNone="no"
                        currentTabName={this.currentTabName}
                    />
                </ChildFilter>
            </div>
        );
    }

    /**
     * Render the filter tabs and/or select fields.
     * @param {*} mode
     */
    renderFilters(mode) {
        if (this.state.windowWidth >= MINIMUM_DESKTOP_WIDTH) {
            return (
                <React.Fragment>
                    <SelectionLeft>
                        {this.renderTabs()}
                    </SelectionLeft>
                    <SelectionRight>
                        {this.renderSelect(mode)}
                    </SelectionRight>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <SelectionLeft>
                        {this.renderTypeSelect()}
                    </SelectionLeft>
                    <SelectionRight>
                        {this.renderSelect(mode)}
                    </SelectionRight>
                </React.Fragment>
            );
        }
    }

    /**
     * Get the articles needed for a single topic.
     */
    getArticlesForTopic(topicIndex, articleCount) {
        const topic = this.props.topics[topicIndex];
        const currentCount = 0;
        const articles = [];

        //make sure stuff exists first
        if (topic && topic.newsItems && topic.newsItems.length) {
            Object.keys(topic.newsItems).forEach(function (key, index) {
                articles.push(transformContentItem(topic.newsItems[index],
                    topic.topic.id,
                    topic.topic.name,
                ));

                articleCount++;

                if (articleCount === currentCount) {
                    return articles;
                }
            });
        }

        return articles;
    }

    /**
     * Get the article IDs for exclusion at see more click.
     */
    getArticleIds() {
        const articleIds = [];
        const self = this;
        //make sure stuff exists first
        if (this.props.topics && this.props.topics.length) {
            Object.keys(this.props.topics).forEach(function (key, index) {
                const topic = self.props.topics[index];
                Object.keys(topic.newsItems).forEach(function (key, index) {
                    articleIds.push(topic.newsItems[index].id);
                });
            });


        }

        return articleIds;
    }

    /**
     * Get the topic IDs for inclusion in the see more click.
     */
    getTopicIds() {
        const topicIds = [];
        const self = this;

        //make sure stuff exists first
        if (this.props.topics && this.props.topics.length) {
            Object.keys(this.props.topics).forEach(function (key, index) {
                if (self.props.topics[index].topic && self.props.topics[index].topic.id) {
                    topicIds.push(self.props.topics[index].topic.id);
                }
            });
        }

        return topicIds;
    }

    /**
     * Render a splash notification when the user has chosen a tab with no associated profile selections.
     */
    renderProfileNotification() {
        let title = labels.translations['trendingTopicsNew.component.notification.0'];
        let textDef = labels.translations['trendingTopicsNew.component.textDef.0'];

        switch (this.state.selectedTabId) {
            case 2:
                title = labels.translations['trendingTopicsNew.component.notification.1'];
                textDef = labels.translations['trendingTopicsNew.component.textDef.1']
                break;
            case 3:
                title = labels.translations['trendingTopicsNew.component.notification.2'];
                textDef = labels.translations['trendingTopicsNew.component.textDef.2']
                break;
            default:
            //no action needed, aum was already set as default..
        }

        return (
            <SplashWrapper>
                <SplashNotification
                    title={title}
                    textDef={textDef}
                    url="/profile/practice-info"
                    buttonLabel={labels.translations['trendingTopicsNew.component.notification.cta']}
                />
            </SplashWrapper>
        );
    }

    /**
     * Render the top cards area.
     * @param {*} mode if its tablet or mobile or desktop.
     */
    renderCards(mode) {
        if (this.state.selectedTabId > 0) {
            switch (this.state.selectedTabId) {
                case 1:
                    if (this.props.wmAUMId === null) {
                        return this.renderProfileNotification();
                    }
                    break;
                case 2:
                    if (this.props.retirementPlanAUMId === null) {
                        return this.renderProfileNotification();
                    }
                    break;
                case 3:
                    if (this.props.experienceId === null) {
                        return this.renderProfileNotification();
                    }
                    break;
                default:
                //no action needed yet
            }
        }

        if (mode === DESKTOP) {
            const articles1 = this.getArticlesForTopic(0, 0);
            const articles2 = this.getArticlesForTopic(1, 1);
            const articles3 = this.getArticlesForTopic(2, 1);

            const headArticle1 = articles1.shift();
            const headArticle2 = articles2.shift();
            const headArticle3 = articles3.shift();

            return (
                <DesktopRows>
                    <TrendingTopicCard
                        type={TYPE_TT_LARGE}
                        article={headArticle1}
                        articles={articles1}
                    />

                    <TrendingTopicCard
                        article={headArticle2}
                        articles={articles2}
                    />

                    <TrendingTopicCard
                        article={headArticle3}
                        articles={articles3}
                    />
                </DesktopRows>
            );
        } else if (mode === TABLET) {
            const articles1 = this.getArticlesForTopic(0, 2);
            const articles2 = this.getArticlesForTopic(1, 2);

            const headArticle1 = articles1.shift();
            const headArticle2 = articles2.shift();

            return (
                <TabletContainer>
                    <Row type={TYPE_2COLUMN}>
                        <TrendingTopicCard
                            type={TYPE_TT_MEDIUM}
                            article={headArticle1}
                            articles={articles1}
                        />
                        <TrendingTopicCard
                            type={TYPE_TT_MEDIUM}
                            article={headArticle2}
                            articles={articles2}
                        />
                    </Row>
                </TabletContainer>
            );
        }

        const articles1 = this.getArticlesForTopic(0, 2);
        const articles2 = this.getArticlesForTopic(1, 2);

        const headArticle1 = articles1.shift();
        const headArticle2 = articles2.shift();

        return (
            <MobileContainer>
                <Column type={TYPE_3ROW}>
                    <TrendingTopicCard
                        article={headArticle1}
                        articles={articles1}
                    />
                    <TrendingTopicCard
                        article={headArticle2}
                        articles={articles2}
                    />
                </Column>
            </MobileContainer>
        );
    }

    /**
     * Render a single lower topic link.
     * @param {*} item
     */
    renderListItem(item) {
        if (!item.topic) {
            return null;
        }

        return (
            <React.Fragment key={item.topic.id}>
                <Button
                    type={TYPE_LIST}
                    to={TOPIC_LINK(`${item.topic.name}/${item.topic.id}`)}
                    dataAnalyticsPlacement="Anchor : body"
                    dataAnalyticsLabel="trackLink : anchor"
                    dataAnalyticsId={"topic:" + item.topic.name + ":trendingtopics"}
                >
                    {item.topic.name}
                </Button>
            </React.Fragment>
        );
    }

    /**
     * Render the lower topic list.
     */
    renderList() {
        return (
            <React.Fragment>
                {this.props.topTopics.map(item => this.renderListItem(item))}
            </React.Fragment>
        );
    }

    /**
     * Render the lower topic list title.
     */
    renderListTitle() {
        return (
            <React.Fragment>
                {listTitles[this.state.selectedTabId]}
            </React.Fragment>
        );
    }

    checkTopicsExist() {
        return this.props.topics && this.props.topics.length
            && this.props.topics.length >= 5;
    }

    /**
     * Check the data before attempting to render stuff.
     */
    checkData() {
        if (this.props.topTopics === null || this.props.topTopics.length === 0) {
            return false;
        }

        switch (this.state.selectedTabId) {
            case 0:
                return this.checkTopicsExist();
                break;
            case 1:
                if (this.props.wmAUMId != null) {
                    return this.checkTopicsExist();
                }
                break;
            case 2:
                if (this.props.retirementPlanAUMId != null) {
                    return this.checkTopicsExist();
                }
                break;
            case 3:
                if (this.props.experienceId != null) {
                    return this.checkTopicsExist();
                }
                break;
            default:
            // code block, nothing to do
        }

        return true;
    }

    //analytics 
    CH_handleComponentEventList(analyticsId) {

        var tracking =
        {
            "type": "tab",
            "action": analyticsId,
        };
        contextHubRecordEvent(tracking);
    }

    /**
     * Render the trending topics template and underlying components.
     */
    render() {
        const { isTrendingLoading } = this.props;
        const checkData = this.checkData();

        let showUpperCta = true;
        let showLowerSection = true;
        let PageContent = ' ';

        if ((this.state.selectedTabId === 1 && this.props.wmAUMId === null)
            || (this.state.selectedTabId === 2 && this.props.retirementPlanAUMId === null)
            || (this.state.selectedTabId === 3 && this.props.experienceId === null)) {
            showUpperCta = false;
            showLowerSection = false;
        }

        let type;
        let variant;

        const articleIds = this.getArticleIds();
        const topicIds = this.getTopicIds();

        switch (this.state.selectedTabId) {
            case 1:
                type = "wm";
                variant = this.state.selectedFilterId;
                break;
            case 2:
                type = "retirement";
                variant = this.state.selectedFilterId;
                break;
            case 3:
                type = "experience";
                variant = this.state.selectedFilterId;
                break;
            default:
                type = "default";
                variant = 0;
        }

        storage.set(TRENDING_TOPIC_DATA, JSON.stringify({
            articleIds,
            topicIds,
            type,
            variant,
        }));

        let noBottomMargin = false;

        if (this.state.selectedTabId > 0) {
            switch (this.state.selectedTabId) {
                case 1:
                    if (this.props.wmAUMId === null) {
                        noBottomMargin = true;
                    }
                    break;
                case 2:
                    if (this.props.retirementPlanAUMId === null) {
                        noBottomMargin = true;
                    }
                    break;
                case 3:
                    if (this.props.experienceId === null) {
                        noBottomMargin = true;
                    }
                    break;
                default:
                //no action needed yet
            }
        }

        return (
            <StyledSection data-rel="TrendingTopicsComponent" ref={this.contentRef}>
                <BreakpointsContext.Consumer>
                    {(breakpoint) => {

                        if (isTrendingLoading === false) {
                            PageContent = this.renderCards(breakpoint);
                        }

                        return (
                            <React.Fragment>
                                <UpperTemplate>
                                    <Container>
                                        <CoachMarks
                                            type={TYPES.TRENDINGTOPICS}
                                        >
                                            <Title>
                                                <FormattedMessage id="trendingTopicsNew.component.title" />
                                            </Title>
                                        </CoachMarks>

                                        {checkData
                                            && <SelectionArea noBottomMargin={noBottomMargin.toString()} selectedTab={this.state.selectedTabId.toString()}>
                                                {this.renderFilters(breakpoint)}
                                            </SelectionArea>}

                                        {!isTrendingLoading
                                            && checkData
                                            && <CardArea breakpoint={breakpoint}>
                                                <div className="contentCard">
                                                    {PageContent}
                                                </div>

                                            </CardArea>
                                        }

                                        {isTrendingLoading &&
                                            <CardArea breakpoint={breakpoint}>
                                                <div className="emptyCard">
                                                    <StyledLoaderContainer>
                                                        <StyledLoader
                                                            options={defaultOptions}
                                                        />
                                                    </StyledLoaderContainer>
                                                </div>
                                            </CardArea>
                                        }

                                        {showUpperCta
                                            && breakpoint !== MOBILE
                                            && checkData
                                            && !isTrendingLoading
                                            && <UpperCTAArea>
                                                <Button
                                                    type={TYPE_GHOST_RIGHT}
                                                    to="/trending"
                                                    dataAnalyticsPlacement="Anchor : body"
                                                    dataAnalyticsLabel="trackLink : anchor"
                                                    dataAnalyticsId={language.translations["trendingTopicsNew.component.cta.0"]}
                                                >
                                                    <FormattedMessage id="trendingTopicsNew.component.cta.0" />
                                                </Button>
                                            </UpperCTAArea>}
                                        {showUpperCta && breakpoint === MOBILE
                                            && !isTrendingLoading && <UpperCTAArea>
                                                <Button
                                                    style={{ display: 'block' }}
                                                    type={TYPE_PRIMARY_MEDIUM}
                                                    to="/trending"
                                                    dataAnalyticsPlacement="Anchor : body"
                                                    dataAnalyticsLabel="trackLink : anchor"
                                                    dataAnalyticsId={language.translations["trendingTopicsNew.component.cta.0"]}
                                                >
                                                    <FormattedMessage id="trendingTopicsNew.component.cta.0" />
                                                </Button>
                                            </UpperCTAArea>}
                                    </Container>
                                </UpperTemplate>
                                {showLowerSection
                                    && checkData
                                    && !isTrendingLoading
                                    && <LowerTemplate>
                                        <Container>
                                            <ListArea>
                                                <div>
                                                    <h4>
                                                        {this.renderListTitle()}
                                                    </h4>
                                                </div>
                                                <div>
                                                    {this.renderList()}
                                                </div>
                                            </ListArea>
                                            <LowerCTAArea>
                                                {breakpoint !== MOBILE
                                                    && !isTrendingLoading && <React.Fragment>
                                                        <Button
                                                            type={TYPE_GHOST}
                                                            to="/profile/preferences"
                                                            dataAnalyticsPlacement="Anchor : body"
                                                            dataAnalyticsLabel="trackLink : anchor"
                                                            dataAnalyticsId={language.translations["trendingTopicsNew.component.cta.1"]}
                                                        >
                                                            <FormattedMessage id="trendingTopicsNew.component.cta.1" />
                                                        </Button>
                                                        <Button
                                                            type={TYPE_SECONDARY_SMALL}
                                                            to="/allTopics"
                                                            dataAnalyticsPlacement="Anchor : body"
                                                            dataAnalyticsLabel="trackLink : anchor"
                                                            dataAnalyticsId={language.translations["trendingTopicsNew.component.cta.2"]}
                                                        >
                                                            <FormattedMessage id="trendingTopicsNew.component.cta.2" />
                                                        </Button>
                                                    </React.Fragment>}
                                                {breakpoint === MOBILE
                                                    && !isTrendingLoading && <React.Fragment>
                                                        <div><Button
                                                            style={{ display: 'block' }}
                                                            type={TYPE_PRIMARY_MEDIUM}
                                                            to="/allTopics"
                                                            dataAnalyticsPlacement="Anchor : body"
                                                            dataAnalyticsLabel="trackLink : anchor"
                                                            dataAnalyticsId={language.translations["trendingTopicsNew.component.cta.2"]}
                                                        >
                                                            <FormattedMessage id="trendingTopicsNew.component.cta.2" />
                                                        </Button></div>
                                                        <div><Button
                                                            type={TYPE_GHOST}
                                                            to="/profile/preferences"
                                                            dataAnalyticsPlacement="Anchor : body"
                                                            dataAnalyticsLabel="trackLink : anchor"
                                                            dataAnalyticsId={language.translations["trendingTopicsNew.component.cta.1"]}
                                                        >
                                                            <FormattedMessage id="trendingTopicsNew.component.cta.1" />
                                                        </Button></div>
                                                    </React.Fragment>}
                                            </LowerCTAArea>
                                        </Container>
                                    </LowerTemplate>}
                            </React.Fragment>
                        );
                    }}
                </BreakpointsContext.Consumer>
            </StyledSection>
        );
    }
}

TrendingTopics.propTypes = {
    topics: PropTypes.arrayOf(PropTypes.shape({})),
    topTopics: PropTypes.arrayOf(PropTypes.shape({})),
    requestUpdate: PropTypes.func,
    experienceId: PropTypes.number,
    bobId: PropTypes.number,
    wmAUMId: PropTypes.number,
    retirementPlanAUMId: PropTypes.number,
    serviceId: PropTypes.number,
    filters: PropTypes.shape({}),
    onTabClick: PropTypes.func,
    isTrendingLoading: PropTypes.bool.isRequired,
    loadingFailed: PropTypes.bool,
    handleComponentFailure: PropTypes.func,
};

TrendingTopics.defaultProps = {
    requestUpdate: () => { },
    topics: [],
    topTopics: [],
    bobId: null,
    wmAUMId: null,
    retirementPlanAUMId: null,
    experienceId: null,
    serviceId: null,
    filters: null,
    onTabClick: null,
    loadingFailed: false,
    handleComponentFailure: () => { },
}

export default TrendingTopics;
