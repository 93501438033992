export const PAGE_NAME_ABOUT_YOU = 'dp:onboarding:aboutyou';
export const PAGE_NAME_GUIDE = guideId => `dp:guides:${guideId}`;
export const PAGE_NAME_GUIDES = 'dp:landingpage:guides';
export const PAGE_NAME_GUIDES_CLIENT_PERSPECTIVES = 'dp:landingpage:guides:clientperspectives';
export const PAGE_NAME_GUIDES_PRACTICE_PERSPECTIVES = 'dp:landingpage:guides:practiceperspectives';
export const PAGE_NAME_GUIDES_TOOLS = 'dp:landingpage:guides:tools';
export const PAGE_NAME_HOME = 'dp:landingpage:home';
export const PAGE_NAME_LANDING_PAGE = 'dp:landingpage:landingpage';
export const PAGE_NAME_LEGAL_AGREEMENT = 'dp:signup:legalagreement';
export const PAGE_NAME_NEWS = 'dp:landingpage:news';
export const PAGE_NAME_ON_BOARDING = 'dp:onboarding:onboarding';
export const PAGE_NAME_PROFILE = 'dp:landingpage:profile';
export const PAGE_NAME_PROFILE_PRACTICE_INFO = 'dp:landingpage:profile:practiceinfo';
export const PAGE_NAME_SAVES = 'dp:landingpage:saves';
export const PAGE_NAME_SIGN_IN = 'dp:landingpage:signin';
export const PAGE_NAME_SIGN_OUT = 'dp:landingpage:signout';
export const PAGE_NAME_SIGN_UP = 'dp:landingpage:signup';
export const PAGE_NAME_SOURCES = 'dp:onboarding:sources';
export const PAGE_NAME_TOPICS = 'dp:onboarding:topics';
export const PAGE_NAME_TOPIC = topicName => `dp:topics:${topicName}`;
export const PAGE_NAME_SOURCE = sourceName => `dp:sources:${sourceName}`;
export const PAGE_NAME_TOOLS = 'dp:landingPage:tools';
export const PAGE_NAME_SEARCH = 'dp:landingpage:search';
export const PAGE_NAME_ABOUT_LUMIN = 'dp:landingpage:aboutlumin';
export const PAGE_NAME_PRIVACY_POLICY = 'dp:landingpage:privacypolicy';
export const PAGE_NAME_TERMS_AND_CONDITIONS = 'dp:landingpage:termsandconditions';
export const PAGE_NAME_CONTACT_US = 'dp:landingpage:contactus';
export const PAGE_NAME_ALLTOPICS = 'dp:landingpage:allTopics';
export const PAGE_NAME_DJBRIEFING_ARCHIVE = 'dp:landingpage:djBriefingArchive';
export const PAGE_NAME_DJBRIEFING = 'dp:landingpage:djBriefing';
export const PAGE_NAME_DJBRIEFING_PROCENTAGE = procentage => `${procentage} Percent |dp:landingpage:djBriefing`;
export const PAGE_NAME_BLOOMBERG_FULL_SCREEN = 'dp:landingpage:bloomberg';
export const PAGE_NAME_TOP_NEWS = 'dp:landingpage:topnews';

export const PAGE_NAME_NEWS_CAPITAL_IDEAS_PANEL = `${PAGE_NAME_NEWS}:capitalIdeasPanel`;
export const PAGE_NAME_NEWS_TOP_NEWS_PANEL = `${PAGE_NAME_NEWS}:topNewsPanel`;
export const PAGE_NAME_NEWS_TRENDING_TOPICS_PANEL = `${PAGE_NAME_NEWS}:trendingTopicsPanel`;
export const PAGE_NAME_NEWS_READ_THE_GUIDES_PANEL = `${PAGE_NAME_NEWS}:readTheGuidesPanel`;

export const PAGE_NAME_NEWS_TRENDING_TOPICS_TAB_0_PANEL = `${PAGE_NAME_NEWS}:allAdvisors`;
export const PAGE_NAME_NEWS_TRENDING_TOPICS_TAB_1_PANEL = `${PAGE_NAME_NEWS}:similarBookOfBusiness`;
export const PAGE_NAME_NEWS_TRENDING_TOPICS_TAB_2_PANEL = `${PAGE_NAME_NEWS}:similarExperiance`;
export const PAGE_NAME_NEWS_TRENDING_TOPICS_TAB_3_PANEL = `${PAGE_NAME_NEWS}:similarServiceOffering`;



export const SITE_SECTION_ABOUT_YOU = 'aboutyou';
export const SITE_SECTION_GUIDE = 'guide';
export const SITE_SECTION_GUIDES = 'guides';
export const SITE_SECTION_PRACTICE_MANAGEMENT = 'practicemanagement';
export const SITE_SECTION_HOME = 'home';
export const SITE_SECTION_LANDING_PAGE = 'landingpage';
export const SITE_SECTION_LEGAL_AGREEMENT = 'legalagreement';
export const SITE_SECTION_NEWS = 'news';
export const SITE_SECTION_ON_BOARDING = 'onboarding';
export const SITE_SECTION_PROFILE = 'profile';
export const SITE_SECTION_SAVES = 'saves';
export const SITE_SECTION_SEARCH = 'search';
export const SITE_SECTION_SIGN_IN = 'signin';
export const SITE_SECTION_SIGN_OUT = 'signout';
export const SITE_SECTION_SIGN_UP = 'signup';
export const SITE_SECTION_SOURCES = 'sources';
export const SITE_SECTION_TOPICS = 'topics';
export const SITE_SECTION_TOPIC = 'topic';
export const SITE_SECTION_ABOUT_LUMIN = 'aboutlumin';
export const SITE_SECTION_PRIVACY_POLICY = 'privacypolicy';
export const SITE_SECTION_TERMS_AND_CONDITIONS = 'termsandconditions';
export const SITE_SECTION_CONTACT_US = 'contactus';
export const SITE_SECTION_TOP_NEWS = 'topnews';

export const EVENT_ACTION_PAGE_VIEW = 'pageView';
export const EVENT_ACTION_SEARCH_PAGE_VIEW = 'pageView,searchSuccess';
export const EVENT_ACTION_SIGN_IN_PAGE_VIEW = 'pageView,signIn';
export const EVENT_ACTION_READ_EXTERNAL_CONTENT = 'readExternalContent';
export const EVENT_ACTION_ADD_TO_SAVES = 'addToSaves';
export const EVENT_ACTION_REMOVE_FROM_SAVES = 'removeFromSaves';
export const EVENT_ACTION_SIGN_UP_START = 'signUpStart';
export const EVENT_ACTION_SIGN_UP_SUCCESS = 'signUpSuccess';
export const EVENT_ACTION_SIGN_UP_FAILED = 'signUpFailed';
export const EVENT_ACTION_GUIDE_NAV_PAGE_VIEW = 'pageView,guideNav';

export const EVENT_ACTION_SIGN_IN_STARTS = 'signInStarts';
export const EVENT_ACTION_SIGN_IN_SUCCESS = 'signInSuccess';
export const EVENT_ACTION_SIGN_IN_FAILURE = 'signInFailure';

export const EVENT_ACTION_FOLLOW_SOURCE = 'followSource';
export const EVENT_ACTION_FOLLOW_TOPIC = 'followTopic';

export const EVENT_ACTION_UNFOLLOW_SOURCE = 'unfollowSource';
export const EVENT_ACTION_UNFOLLOW_TOPIC = 'unfollowTopic';

export const EVENT_ACTION_NOT_INTERESTED_SOURCE = 'notInterestedSource';
export const EVENT_ACTION_NOT_INTERESTED_TOPIC = 'notInterestedTopic';

export const EVENT_ACTION_GUIDE_NAV = 'guideNav';

export const SITE_SECTION_BLOOMBERG = 'bloomberg';
export const EVENT_ACTION_VIDEO_STARTS = 'event-video-start';
export const EVENT_ACTION_VIDEO_COMPLETE = 'event-video-complete';
export const EVENT_ACTION_BLOOMBERGTV = 'bloomberglivetv';


export const SITE_SECTION_ALLTOPICS = 'allTopics';
export const SITE_SECTION_DJBRIEFING = 'djBriefing';
