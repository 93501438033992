import {
    all,
    put,
    call,
    takeLatest,
} from 'redux-saga/effects';

import {
    GET_OPTIN,
    OPTIN_URL,
    SAVE_URL,
    SAVE_ANSWER,
} from 'state-management/constants/optin.common';

import {
    getOptinSuccess,
    getOptinFailure,
} from 'state-management/actions/optin.common';

import Api from '../bridge/api';

/**
 * Get the saved optin values.
 * @param {*} action
 */
function* getOptinItems(action) {
    try {
        const optinData = yield call(Api.get, OPTIN_URL(action.id, action.email));
        yield put(getOptinSuccess(optinData.data));
    } catch (e) {
        yield put(getOptinFailure(e));
    }
}

/**
 * Save the users answers.
 * @param {*} param0
 */
function* saveAnswer({ id, optIn, email }) {
    try {
        yield call(Api.post, SAVE_URL, {
            data: {
                id,
                optIn,
                email,
            },
        });

        yield put(getOptinSuccess({ optIn, active: 't' }));
    } catch (e) {
        // TODO: Do we need an error state from IA?
    }
}

function* optinSaga() {
    yield all([
        takeLatest(GET_OPTIN, getOptinItems),
        takeLatest(SAVE_ANSWER, saveAnswer),
    ]);
}

export default optinSaga;
