import React from 'react';
import { withCommonCard } from 'components/atomics/hocs/CommonCard/CommonCard';
import styled from 'styled-components';
import Link from 'components/atomics/atoms/Link/Link';
import moment from 'moment';
import SourceTag, {
    PROVIDER_CAPITAL_GROUP,
} from '../../atoms/SourceTag/SourceTag';
import { getArticleUrl, getArticleImage } from 'utils/getArticleAsset';
import {
    containerStyles,
    contentContainerStyles,
    dateAndTopicContainerStyles,
    dateContainerStyles,
    topicLinkStyles,
    titleStyles,
    imageContainerStyles,
    sourceContentContainerStyles,
    toolsHeaderIconStyles,
} from '../SkinnyListCeCredit/styles';
import ListContentCardFooter from "../ListContentCard/ListContentCardFooter";
import { CESkinnyIcon, CESkinnyMobileIcon } from 'components/atomics/atoms/Icons/Icons';
import { getTruncatedString } from 'utils/contentCardUtils';

export const ToolsHeaderIconContainer = styled.div`
    ${toolsHeaderIconStyles}
`;

export const Title = styled(Link)`
    ${titleStyles}
`;

export const DateAndTopic = styled.div`
    ${dateAndTopicContainerStyles}
`;

export const DateContainer = styled.div`
    ${dateContainerStyles}
`;

export const TopicLink = styled(Link)`
    ${topicLinkStyles}
`;

export const Container = styled.div`
    ${containerStyles}
`;

export const ImageContainer = styled(Link)`
    ${imageContainerStyles}
`;

export const ContentContainer = styled.div`
    ${contentContainerStyles}
`;

export const SourceContentContainer = styled.div`
    ${sourceContentContainerStyles}
`;

const MAX_ARTICLE_TITLE_CHARS = 120;

/**
 * Creates a CE credit skinny list card.
 */
class SkinnyListCeCredit extends React.Component {
    /**
     * Handle save CE credit action.
     */
    saveCecredit = (id, save, title, getContentAfterSave, article) => {
        const { toggleSaveCecredit } = this.props;

        id = id ? id : article.cardId;

        toggleSaveCecredit(parseInt(id), save, title, this.props.getContentAfterSave, article);
    }

    /**
     * Render the list content card and underlying components.
     */
    render() {
        const isLastCard = (this.props.isLastCard) ? 'item-last' : '';
        // const classNames = `${ this.props.alternativeBackground} ${c}`;
        const linkUrl = getArticleUrl(this.props.article);
        const imageUrl = getArticleImage(this.props.article);
        const classNames = `TYPE-${this.props.article.type} ${this.props.alternativeBackground} ${isLastCard}`;

        let guideTxt, sourceContent;

        if (this.props.article.type === 'GUIDE_STANDARD' || this.props.article.type === 'GUIDE_MC_KINSEY') {
            guideTxt = "Guide";
            sourceContent = this.props.article.category;
        } else {
            guideTxt = moment.utc(this.props.article.date).format('MMM D, YYYY');
            sourceContent = <SourceTag
                type="list"
                onTopicClick={() => {
                }}
                sourceName={this.props.article.sourceName}
                provider={this.props.article.provider}
            />;
        }

        return (
            <Container
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this.props.onMouseLeave}
                className={classNames}
                className={`${this.props.article.readStatus ? (this.props.article.readStatus === true ? 'news-visited' : 'news-not-visited') : 'news-not-visited'}`}
            >
                <ImageContainer
                    to={linkUrl}
                    onClick={() => {
                        this.props.recordClickAnalytics('cecredits');
                        this.props.onArticleClick();
                    }}
                    visited={this.props.article && this.props.article.visited}
                >
                    <ToolsHeaderIconContainer>
                        <CESkinnyIcon className="desk" />
                        <CESkinnyMobileIcon className="mobile" />
                    </ToolsHeaderIconContainer>
                </ImageContainer>
                <ContentContainer>
                    <DateAndTopic>
                        <DateContainer>
                            {guideTxt}
                        </DateContainer>
                    </DateAndTopic>
                    <Title
                        to={linkUrl}
                        onClick={() => {
                            this.props.recordClickAnalytics('cecredits');
                            this.props.onArticleClick();
                        }}
                        visited={this.props.article && this.props.article.visited}
                    >
                        {getTruncatedString(this.props.article.title, MAX_ARTICLE_TITLE_CHARS)}
                    </Title>
                    <ListContentCardFooter
                        onElement={this.props.onCardHover}
                        profile={this.props.profile}
                        standardDisplayOfSource={false}
                        setKebabMenuLimitSize={460}
                        hideSourceOption={this.props.article.provider === PROVIDER_CAPITAL_GROUP}
                        sourceName={this.props.article.sourceName}
                        providerName={this.props.article.provider}
                        article={this.props.article}
                        topics={this.props.article.topics}
                        sourceId={this.props.article.sourceId}
                        topicName={this.props.article.topicName}
                        saved={this.props.article.saved}
                        articleId={parseInt(this.props.article.id)}
                        articleTitle={this.props.article.title}
                        type={this.props.article.type || this.props.article.contentType}
                        shouldShowToast={this.props.shouldShowToast}
                        onSaveArticle={(id, save, title, getContentAfterSave, article) => {
                            this.props.toggleSaveCecredit(parseInt(id), save, title, this.props.getContentAfterSave, article);
                        }}
                        onSaveGuide={(id, save, title, getContentAfterSave, article) => {
                            this.props.toggleSaveGuide(id, save, title, getContentAfterSave, article);
                        }}
                    />
                </ContentContainer>
            </Container>
        );
    }
}
export default withCommonCard(SkinnyListCeCredit);;
