import {
    all, put, takeLatest, call,
} from 'redux-saga/effects';

import { setError } from 'state-management/actions/errors';
import { GET_CATEGORIES_DATA, CATEGORIES_DATA_URL } from 'state-management/constants/categories';
import { setCategoriesData } from 'state-management/actions/categories';
import Api from 'utils/api';

/**
 * Get category taxonomy.
 * @param {*} state
 */
function* getCategoriesData(state) {
    try {
        let categoriesData;

        if (state.group !== null) {
            categoriesData = yield call(Api.get, CATEGORIES_DATA_URL, {
                params: {
                    group: state.group,
                },
            });
        } else {
            categoriesData = yield call(Api.get, CATEGORIES_DATA_URL);
        }
        yield put(setCategoriesData(categoriesData.data));
    } catch (e) {
        yield put(setError('guides.server.error', e));
    }
}

function* categoriesSaga() {
    yield all([
        takeLatest(GET_CATEGORIES_DATA, getCategoriesData),
    ]);
}

export default categoriesSaga;
