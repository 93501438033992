import { cloneDeep } from 'lodash';

import * as type from 'state-management/constants/categories';
import * as saveTypes from 'state-management/constants/save';
import { GUIDE_WAS_VISITED } from 'state-management/constants/guide';

const initialState = {
    categories: [],
    isLoading: false,
};

const formatGuide = guide => ({
    ...guide,
    isSaving: false,
});

const formatCategory = category => ({
    ...category,
    categoryGuides: category.categoryGuides.map(formatGuide),
});

const findGuidesById = (categories, guideId) => {
    const guidesFound = [];

    categories.forEach((category, categoryIdx) => {
        category.categoryGuides.forEach((guide, guideIdx) => {
            if (guide.id === guideId) {
                guidesFound.push({
                    categoryIdx,
                    guideIdx,
                });
            }
        });
    });

    return guidesFound;
};

function categoriesReducer(state = initialState, action = {}) {
    switch (action.type) {
    case type.GET_CATEGORIES_DATA:
        return {
            ...state,
            isLoading: true,
        };
    case type.SET_CATEGORIES_DATA:
        return {
            ...state,
            categories: action.data.map(formatCategory),
            isLoading: false,
        };
    case type.CLEAR_CATEGORIES_DATA:
        return {
            ...state,
            categories: [],
            isLoading: false,
        };
    case saveTypes.TOGGLE_SAVE_GUIDE: {
        const { id, save } = action;

        const guidesById = findGuidesById(state.categories, id);

        if (!guidesById.length) {
            return state;
        }

        const nextState = cloneDeep(state);

        guidesById.forEach(({ categoryIdx, guideIdx }) => {
            const guide = nextState.categories[categoryIdx].categoryGuides[guideIdx];
            guide.isSaving = true;
            guide.saved = save;
        });

        return nextState;
    }
    case saveTypes.TOGGLE_SAVE_GUIDE_SUCCESS: {
        const { id } = action;

        const guidesById = findGuidesById(state.categories, id);

        if (!guidesById.length) {
            return state;
        }

        const nextState = cloneDeep(state);

        guidesById.forEach(({ categoryIdx, guideIdx }) => {
            const guide = nextState.categories[categoryIdx].categoryGuides[guideIdx];
            guide.isSaving = false;
        });

        return nextState;
    }
    case saveTypes.TOGGLE_SAVE_GUIDE_FAILURE: {
        const { id } = action;

        const guidesById = findGuidesById(state.categories, id);

        if (!guidesById.length) {
            return state;
        }

        const nextState = cloneDeep(state);

        guidesById.forEach(({ categoryIdx, guideIdx }) => {
            const guide = nextState.categories[categoryIdx].categoryGuides[guideIdx];
            guide.isSaving = false;
            guide.saved = !guide.saved;
        });

        return nextState;
    }

    case GUIDE_WAS_VISITED: {
        const { id } = action;

        const guidesById = findGuidesById(state.categories, id);

        if (!guidesById.length) {
            return state;
        }

        const nextState = cloneDeep(state);

        guidesById.forEach(({ categoryIdx, guideIdx }) => {
            const guide = nextState.categories[categoryIdx].categoryGuides[guideIdx];
            guide.visited = true;
        });

        return nextState;
    }
    default:
        return state;
    }
}

export default categoriesReducer;
