import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
    listStyles, 
    listItemStyles,
} from 'components/CardsGrid/styles';

export const StyledList = styled.ul`
    ${listStyles};
`;

const StyledListItem = styled.li`
    ${listItemStyles};
`;

const CardsGridItem = (props) => {
    const {
        children,
        className,
        desktopColumns,
        mobileColumns,
        tabletColumns,
        isMarketNews,
    } = props;
    return (
        <StyledListItem
            classname={className}
            variant={{
                desktopColumns, mobileColumns, tabletColumns, isMarketNews,
            }}
        >
            {children}
        </StyledListItem>
    );
};

CardsGridItem.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    className: PropTypes.string,
    desktopColumns: PropTypes.number,
    mobileColumns: PropTypes.number,
    tabletColumns: PropTypes.number,
    isMarketNews: PropTypes.bool,
};

CardsGridItem.defaultProps = {
    children: null,
    className: '',
    desktopColumns: 3,
    mobileColumns: 1,
    tabletColumns: 3,
    isMarketNews: false,
};

class CardsGrid extends React.Component {
    /**
     * Default constructor.
     */
    constructor(props) {
        super(props);

        this.showModalOnClick = this.showModalOnClick.bind(this);
    }

    /**
     * Show modal on attempt to click search items (for new users, not fully onboarded).
     */
    showModalOnClick() {
        this.props.showPreferencesModalAction();
    }

    /**
     * Render cards grid and underlying items.
     */
    render() {
        const {
            className,
            children,
            desktopColumns,
            hasBackground,
            items,
            mobileColumns,
            noBottomSpacing,
            renderItem,
            tabletColumns,
            isMarketNews,
        } = this.props;

        return (
            <StyledList
                className={className}
                variant={{ hasBackground, noBottomSpacing, isMarketNews }}
            >
                {
                    typeof renderItem === 'function'
                        ? items.map((item, idx) => {
                            const key = item.id || idx;

                            return (
                                <CardsGridItem
                                    item={item}
                                    key={key}
                                    desktopColumns={desktopColumns}
                                    mobileColumns={mobileColumns}
                                    tabletColumns={tabletColumns}
                                    isMarketNews={isMarketNews}
                                >
                                    {
                                        renderItem(item, idx, items)
                                    }
                                </CardsGridItem>
                            );
                        })
                        : children

                }
            </StyledList>
        );
    }
};

CardsGrid.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    className: PropTypes.string,
    desktopColumns: PropTypes.number,
    hasBackground: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.shape({})),
    mobileColumns: PropTypes.number,
    noBottomSpacing: PropTypes.bool,
    renderItem: PropTypes.func,
    tabletColumns: PropTypes.number,
    isMarketNews: PropTypes.bool,
    showPreferencesModal: PropTypes.bool,
};

CardsGrid.defaultProps = {
    children: null,
    className: '',
    desktopColumns: 3,
    hasBackground: false,
    items: [],
    mobileColumns: 1,
    noBottomSpacing: false,
    renderItem: null,
    tabletColumns: 3,
    isMarketNews: false,
};

CardsGrid.Item = CardsGridItem;

const mapStateToProps = state => ({
    isAuthenticated: state.signIn.isAuthenticated,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CardsGrid);
