import { css } from 'styled-components';
import {
    COLORS,
    FONTS,
} from 'utils/variables';
import {
    FROM_DESKTOP,
} from 'utils/mediaQueries';

export const titleStyles = css`
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    color: ${COLORS.BLACK};
    font-size: 20px;
    font-weight: 600;
    line-height: 33px;
    margin-top: 30px;

    @media screen and ${FROM_DESKTOP} {
        font-size: 26px;
        line-height: 24px;
        letter-spacing: -0.22px;
        margin-top: 35px;
    }
`;
