import {
    all, put, takeLatest, call,
} from 'redux-saga/effects';
import {
    GET_LEGAL_AGREEMENT,
    LEGAL_AGREEMENT_URL,
} from 'state-management/constants/legalAgreement.common';
import {
    getLegalAgreementFailure,
    getLegalAgreementSuccess,
} from 'state-management/actions/legalAgreement.common';
import Api from '../bridge/api';

/**
 * Get the legal agreement data.
 */
function* getLegalAgreement() {
    try {
        const legalAgreement = yield call(Api.get, LEGAL_AGREEMENT_URL);
        yield put(getLegalAgreementSuccess(legalAgreement.data));
    } catch (e) {
        yield put(getLegalAgreementFailure());
    }
}

function* legalAgreementSaga() {
    yield all([
        takeLatest(GET_LEGAL_AGREEMENT, getLegalAgreement),
    ]);
}

export default legalAgreementSaga;
