import { css } from 'styled-components';
import {
    FROM_TABLET,
    FROM_DESKTOP,
} from 'utils/mediaQueries';
import {
    COLORS,
    FONTS,
    ANIMATIONS,
} from '../../../utils/variables';

export const djLogoContainerStyles = css`
    text-align: right;
    position: relative;

    svg {
        display: inline-block;
        position: relative;

        @media screen and ${FROM_TABLET} {
            left: -60px;
        }
    }
`;

export const listRowStyles = css`
    border-bottom: 1px solid ${COLORS.LIGHT_GRAY2};
    display: flex;
    padding-top: 28px;
    padding-bottom: 28px;
    padding-left: 49px;
    padding-right: 49px;
    font-size: 15px;
    line-height: 22px;
    letter-spacing -0.19px;
    font-weight: 500;
    color: ${COLORS.DARK_GRAY2};
    background: ${COLORS.LIGHT_GRAY1};
    padding-left: 15px;
    padding-right: 15px;

    ${props => props.isToday === 'true' && css`
    background: ${COLORS.WHITE};
    `};

    @media screen and ${FROM_TABLET} {
        color: ${COLORS.BLACK};
        letter-spacing: 0.1px;
        line-height: 16px;
        padding-top: 19px;
        padding-bottom: 19px;
        padding-left: 49px;
        padding-right: 49px;
    }

    div {
        width: 55%;

        button, a {
            color: ${COLORS.BLACK};
            font-family: ${FONTS.AVENIR_NEXT.FONT};
            font-weight: 600;
            font-size: 12px;
            line-height: 24px;
            letter-spacing: 0.59px;
            text-transform: uppercase;
            text-decoration: none;
            transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;
            border: none;
            background: transparent;
            padding: 0;

            @media screen and ${FROM_TABLET} {
                line-height: 16px;
                letter-spacing: 1.28px;
            }
        }

        button {
            opacity: 0.59;
        }

        a:hover {
            color: ${COLORS.SAPPHIRE};
        }

        a:first-child {
            margin-right: 60px;
        }
        /* exception this break point */
        @media screen and (max-width: 525px) {
            a:first-child {
                margin-right: 15px;
            }
        }

        a:last-child {
            text-align: right;
        }

        span {
            opacity: 0;
        }
    }

    div:last-child {
        display: block;
        margin-top: 0;
        text-align: right;
    }
`;

export const listContainerStyles = css`
    background: ${COLORS.WHITE};
    margin-bottom: 15px;

    @media screen and ${FROM_TABLET} {
        border: 1px solid ${COLORS.LIGHT_GRAY2};
        padding: 28px;
        margin-bottom: 20px;
    }
`;

export const previousButtonStyles = css`
    background: transparent;
    border: none;
    color: ${COLORS.BLACK};
    cursor: pointer;
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 13px;
    letter-spacing: 1.71px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    transition: 0.4s all;
    white-space: nowrap;
    outline: none;

    svg {
        margin-right: 10px;
        position: relative;
        top: -2px;
    }

    svg[data-rel="desktop"] {
        display: none;
        stroke: ${COLORS.SAPPHIRE};
        transform: scaleX(-1);
    }

    svg[data-rel="mobile"] {
        fill: ${COLORS.SAPPHIRE};
        transform: scale(1.2);
    }

    &:hover {
        color: ${COLORS.BLACK};
    }

    span {
        display: none;
    }

    &[disabled] {
        cursor: default;
        color: ${COLORS.MEDIUM_GRAY2};

        svg {
            opacity: 0.38;
        }
    }

    @media screen and ${FROM_TABLET} {
        span {
            display: inline-block;
        }

        svg[data-rel="desktop"] {
            display: inline-block;
        }

        svg[data-rel="mobile"] {
            display: none;
        }
    }
`;

export const labelClick = css`
    opacity: 1 !important;
`;

export const nextButtonStyles = css`
    background: transparent;
    border: none;
    color: ${COLORS.BLACK};
    cursor: pointer;
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 13px;
    letter-spacing: 1.71px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    transition: 0.4s all;
    white-space: nowrap;
    outline: none;

    svg[data-rel="desktop"] {
        display: none;
        stroke: ${COLORS.SAPPHIRE};
    }

    svg {
        margin-left: 10px;
        position: relative;
        top: -2px;
    }

    svg[data-rel="mobile"] {
        fill: ${COLORS.SAPPHIRE};
        transform: scale(1.2);
    }

    &:hover {
        color: ${COLORS.BLACK};
    }

    span {
        display: none;
    }

    &[disabled] {
        cursor: default;
        color: ${COLORS.MEDIUM_GRAY2};

        svg {
            opacity: 0.38;
        }
    }

    @media screen and ${FROM_TABLET} {
        span {
            display: inline-block;
        }

        svg[data-rel="desktop"] {
            display: inline-block;
        }

        svg[data-rel="mobile"] {
            display: none;
        }
    }
`;

export const listHeaderStyles = css`
    display: flex;
    margin-bottom: 15px;

    @media screen and ${FROM_TABLET} {
        margin-bottom: 22px;
    }
`;

export const listHeaderLabelStyles = css`
    flex: 1;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    letter-spacing: -0.15px;

    @media screen and ${FROM_TABLET} {
        line-height: 22px;
        letter-spacing: -0.1px;
    }
`;

export const listHeaderLeftStyles = css`
    text-align: left;
`;

export const listHeaderRightStyles = css`
    text-align: right;
`;

export const containerStyles = css`
    background: ${COLORS.LIGHT_GRAY1};
    margin-bottom: 80px;

    @media screen and ${FROM_TABLET} {
        margin-bottom: 118px;
    }
`;

export const headerStyles = css`
    background: ${COLORS.LIGHT_GRAY1};
    padding-bottom: 36px;
    text-align: left;

    p {
        font-size: 15px;
        line-height: 22px;
        letter-spacing: -0.12px;
        margin: 0;
    }

    h1 {
        margin-bottom: 8px;
    }

    a {
        color: ${COLORS.MEDIUM_GRAY2};
        display: inline-block;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 2.06px;
        margin-bottom: 14px;
        text-decoration: none;
        text-transform: uppercase;
        transition: 0.4s all;

        svg {
            margin-right: 6px;
            position: relative;
            stroke: ${COLORS.DARK_SAPPHIRE};
            stroke-width: 2px;
            top: -2px;
            transform: scaleX(-1);
        }

        &:hover {
            color: ${COLORS.BLACK};
        }
    }

    @media screen and ${FROM_TABLET} {
        padding-bottom: 104px;

        h1 {
            margin-bottom: 14px;
        }

        p {
            font-size: 18px;
            line-height: 22px;
            letter-spacing: -0.1px;
        }

        a {
            margin-bottom: 22px;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: 2.4px;
        }

        span svg {
            display: block;
            left: -8px;
            margin-top: 10px;
        }
    }
`;

export const mainContainerStyles = css`
    background: ${COLORS.LIGHT_GRAY1};
`;

export const paddedContainerStyles = css`
    margin: auto;
    max-width: 1170px;
    padding-left: 16px;
    padding-right: 16px;

    @media screen and ${FROM_TABLET} {
        padding-left: 38px;
        padding-right: 38px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding-left: 48px;
        padding-right: 48px;
    }
`;
