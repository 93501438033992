import { css } from 'styled-components';
import {
    FROM_DESKTOP,
    FROM_MOBILE_TO_TABLET,
    FROM_TABLET,
    FROM_TABLET_TO_DESKTOP,
} from 'utils/mediaQueries';
import {
    FONT_AVENIR_NEXT,
    COLORS,
    RGB_COLORS,
    ANIMATIONS,
} from 'utils/variables';
import { outerFocusMixin } from 'components/FocusVisibility/styles';

export const sectionStyles = css`
    background-image: linear-gradient(to bottom, rgba(${RGB_COLORS.LIGHT_GRAY2}, 0.5), rgba(${RGB_COLORS.LIGHT_GRAY1}, 0));
    padding: 46px 0 56px;

    @media screen and ${FROM_TABLET} {
        padding: 58px 0 80px;
    }
`;

export const containerStyles = css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media screen and ${FROM_DESKTOP} {
        flex-direction: row;
    }
`;

export const introStyles = css`
    box-sizing: border-box;
    padding: 0 0 23px;

    @media screen and ${FROM_MOBILE_TO_TABLET} {
        text-align: center;
    }

    @media screen and ${FROM_TABLET_TO_DESKTOP} {
        max-width: 630px;
    }

    @media screen and ${FROM_TABLET} {
        padding: 0 0 30px 0;
        position: relative;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 24px 74px 0 0;
        width: 48%;
    }
`;

export const headingStyles = css`
    color: ${COLORS.SAPPHIRE};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 32px;
    font-weight: 400;
    line-height: 1.06;
    margin: 17px 0 15px;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        font-size: 60px;
        line-height: 1.08;
        margin: 0 0 23px;

        &::before {
            background-image: linear-gradient(to left, ${COLORS.OCEAN}, ${COLORS.SAPPHIRE} 40px, ${COLORS.SAPPHIRE} 60px, transparent 80px);
            content: '';
            display: block;
            height: 4px;
            margin-top: 27px;
            position: absolute;
            transform: translateX(-100%) translateX(-18px);
            width: 80px;
        }
    }
`;

export const descriptionStyles = css`
    color: ${COLORS.MEDIUM_GRAY2};
    font-size: 16px;
    font-weight: 500;
    line-height: 1.3;

    a {
        color: ${COLORS.SAPPHIRE};
        font-weight: 600;
        ${outerFocusMixin}
        text-decoration: none;
        transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;

        &:hover {
            color: ${COLORS.DARK_SAPPHIRE};
        }
    }

    p {
        margin: 0;
    }

    @media screen and ${FROM_MOBILE_TO_TABLET} {
        margin: 0 auto;
        max-width: 280px;
    }

    @media screen and ${FROM_TABLET} {
        font-size: 24px;
        font-weight: 400;
        line-height: 1.58;
    }
`;

export const cardsContainerStyles = css`
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;
    max-width: 100%;

    @media screen and ${FROM_TABLET} {
        margin-left: -38px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-left: 0;
        width: 52%;
    }
`;
