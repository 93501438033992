import {
    put, takeLatest, call, all, select,
} from 'redux-saga/effects';

import {
    getTopicsSuccess,
    getTopicsFailure,
    setTopicsListLoadingStatus,
    getOnboardingTopicsSuccess,
    getOnboardingTopicsFailure,
} from 'state-management/actions/topics';
import {
    GET_TOPICS,
    TOPICS_URL,
    GET_ONBOARDING_TOPICS,
    ONBOARDING_TOPICS_URL,
} from 'state-management/constants/topics';
import Api from 'utils/api';

/**
 * Get all topics.
 */
export function* fetchTopics() {
    try {
        const isLoading = yield select(state => state.topics.isLoadingTopicsList);
        if (!isLoading) {
            yield put(setTopicsListLoadingStatus());
            const response = yield call(Api.get, TOPICS_URL);
            yield put(getTopicsSuccess(response));
        }
    } catch (e) {
        yield (put(getTopicsFailure('error.topics.loadingFailed')));
    }
}

/**
 * Get topics for onboarding pages.
 */
export function* fetchOnboardingTopics() {
    try {
        const response = yield call(Api.get, ONBOARDING_TOPICS_URL);
        yield put(getOnboardingTopicsSuccess(response));
    } catch (e) {
        yield (put(getOnboardingTopicsFailure('error.topics.loadingFailed')));
    }
}

function* topicSaga() {
    yield all([
        takeLatest(GET_TOPICS, fetchTopics),
        takeLatest(GET_ONBOARDING_TOPICS, fetchOnboardingTopics),
    ]);
}

export default topicSaga;
