import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Pager from '../../components/PagedList/Pager';
import Loader from 'components/Loader/Loader';
import { listWrapperStyles, defaultPageWrapperStyles } from 'components/PagedList/styles';
import scrollHelper from 'utils/scrollToTop';
import storage from 'utils/store';

const ListWrapper = styled.div`
    ${listWrapperStyles}
`;

const DefaultPageWrapper = styled.div`
    ${defaultPageWrapperStyles}
`;

const PAGE_CHANGE_INTERVAL_TIME = 200;

class PagedList extends React.Component {
    /**
     * Default constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            changingPages: false,
        };

        this.onPageChange = this.onPageChange.bind(this);

        const urlPage = parseInt(this.getUrlVars()['p']);
        const {
            itemsCount,
            page,
            pageSize,
        } = props;

        if (page === 1 && page !== urlPage && urlPage) {
            if (urlPage <= Math.ceil(itemsCount / pageSize)) {
                this.onPageChange(urlPage);
            } else {
                this.onPageChange(1);
            }
        }
    }

    /**
     * Handle change page logic.
     * @param {*} nextPage 
     */
    onPageChange = (nextPage) => {
        const props = this.props;

        props.onPageChange(nextPage);

        if (props.scrollToTop) {
            scrollHelper.scrollToTop();
        }

        this.setState({
            changingPages: true,
        }, () => {
            window.checkImagesInterval = setInterval(() => {
                const pageElement = document.getElementById('pageElement');
                const images = pageElement.getElementsByTagName("IMG");
                let loaded = true;

                for (let i = 0; i < images.length; i++) {
                    if (!images[i].complete) {
                        loaded = false;
                    }
                }

                if (loaded) {
                    clearInterval(window.checkImagesInterval);

                    this.setState({
                        changingPages: false,
                    });
                }
            }, PAGE_CHANGE_INTERVAL_TIME);
        });

        const vars = this.getUrlVars();
        const q = vars['q'];
        const orderBy = vars['orderBy'];

        let append = '';

        if (q) {
            append += `&q=${q}`;
        }

        if (orderBy) {
            append += `&orderBy=${orderBy}`;
        }

        window.history.pushState('', '', `${window.location.pathname}?p=${nextPage}${append}`);
    };

    /**
     * Get params from URL.
     */
    getUrlVars = () => {
        const vars = {};
        const parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            vars[key] = value;
        });

        return vars;
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const {
            itemsCount,
            page,
            pageItems,
            pageSize,
            pageWrapperComponent,
            renderItem,
            isLoading,
            totalItems,
            totalPages,
            hideButtons,
        } = this.props;
        const PageWrapper = pageWrapperComponent || DefaultPageWrapper;

        return (
            <ListWrapper id="pageElement" noMaxWidth={this.props.noMaxWidth}>
                <PageWrapper>
                    {pageItems.map(renderItem)}
                </PageWrapper>
                {this.state.changingPages
                && <Loader />}
                {!hideButtons
                && <Pager
                    currentPage={page}
                    pageSize={pageSize}
                    itemsCount={itemsCount}
                    totalItems={totalItems}
                    totalPages={totalPages}
                    onPageChange={this.onPageChange}
                    isLoading={isLoading}
                />}
            </ListWrapper>
        );
    }
};

PagedList.propTypes = {
    itemsCount: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    pageItems: PropTypes.arrayOf(PropTypes.any).isRequired,
    pageSize: PropTypes.number,
    pageWrapperComponent:  PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
    renderItem: PropTypes.func.isRequired,
    scrollToTop: PropTypes.bool,
    isLoading: PropTypes.bool,
    noMaxWidth: PropTypes.bool,
    hideButtons: PropTypes.bool,
};

PagedList.defaultProps = {
    pageSize: 20,
    pageWrapperComponent: undefined,
    scrollToTop: false,
    isLoading: false,
    noMaxWidth: false,
    hideButtons: false,
};

export default PagedList;