import { css } from 'styled-components';
import {
    COLORS,
    FONTS,
    ANIMATIONS,
} from 'utils/variables';
import {
    SMALL_MOBILE,
    FROM_DESKTOP,
} from 'utils/mediaQueries';
import { FROM_TABLET } from '../../../../utils/mediaQueries';

export const titleStyles = css`
    flex: 1;
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.1px;
    line-height: 20px;
    margin-bottom: 10px;
    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.TITLE.TIME.SECONDS}s all;

    @media screen and ${FROM_DESKTOP} {
        font-size: 20px;
        line-height: 24px;
        min-height: 67.47px;
    }

    ${props => props.visited
        ? css`
            color: ${COLORS.MEDIUM_GRAY2};
            &:hover {
                color: ${COLORS.BLACK};
            };
        `
        : css`
            color: ${COLORS.BLACK};
            &:hover {
                color: ${COLORS.MEDIUM_GRAY2};
            };
        `};
`;

export const containerStyles = css`
    background: transparent;
    position: relative;
    &.news-not-visited{
        opacity:1;
    }
    &.news-visited{
        opacity:0.6;
    }

    &.LIGHT_GRAY1 {
        background: ${COLORS.LIGHT_GRAY1};
    }
    border-bottom: 1px solid ${COLORS.LIGHT_GRAY2};
    color: ${COLORS.BLACK};
    display: flex;
    padding: 24px 0 30px 0;
    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.CONTAINER.TIME.SECONDS}s all;

    &:hover {
        img {
            transform: scale3d(1.05,1.05,1);
        }
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 23px 0 17px 0;
    }

    .ListContentCardFooter__SourceBlockContainer-hgsAQH{
       
        @media screen and ${FROM_DESKTOP} {
            bottom: 5px;
        }
    }
`;

export const dateAndTopicContainerStyles = css`
    display: flex;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-bottom: 8px;
    text-transform: uppercase;

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 15px;
    }
`;

export const dateContainerStyles = css`
    color: ${COLORS.DARK_GRAY1};
    padding-right: 24px;
    white-space: nowrap;
    font-size: 12px;
`;

export const topicLinkStyles = css`
    color: ${COLORS.SAPPHIRE};
    flex: 1;
    text-align: right;
    text-decoration: none;
    transition: 0.33s all;

    &:hover {
        opacity: 0.6;
    }
`;

export const imageContainerStyles = css`
    height: 61px;
    overflow: hidden;
    width: 61px;

    @media screen and ${FROM_DESKTOP} {
        height: 129px;
        width: 129px;
    }

    img {
        height: 100%;
        object-fit: cover;
        transition: 0.33s all;
        width: 100%;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            width: auto;
        }
    }
`;

export const contentContainerStyles = css`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 23px;
    width: calc(100% - 84px);
    min-width: 213px;
    padding-bottom: 25px;
   

    /* why are custom breakpoints here?? they should NOT be */
    @media screen  and (min-width: 414px) {
        min-width: 260px;
    }

    @media screen  and (min-width: 414px) {
        min-width: 300px;
    }
    /* /END why are custom breakpoints here?? they should NOT be */

    @media screen and ${FROM_TABLET} {
        min-width: 600px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding-left: 30px;
        width: calc(100% - 159px);
        position: relative;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        min-height: 110px;
    }
`;

export const sourceContentContainerStyles = css `
    font-weight: 700;
    font-size: 12px;
    color: ${COLORS.SAPPHIRE};
`;

export const toolsHeaderIconStyles = css`
    text-align: right;

    svg {
        position: relative;
        top: -5px;
        text-align: right; 

        &.mobile {
            display: block;
        } 

        &.desk {
            display: none;
        }   
        
        @media screen and ${FROM_DESKTOP} {
            &.mobile {
                display: none;
            } 
            
            &.desk {
                display: block;
            } 
        }
    }

    
`;

