import { css } from 'styled-components';
import { getSpanInPercent } from 'utils/grid';
import { FROM_DESKTOP, FROM_TABLET, SMALL_MOBILE } from '../../utils/mediaQueries';
import {
    StyledBodyCopyWrapper,
    StyledBodyCopyContent,
    StyledBodyCopyTitle,
    StyledBodyCopyDescription,
    StyledBodyCopyHeaderContainer,
    StyledBodyCopyContentWrapper,
} from 'containers/Guide/BodyCopy/BodyCopy';
import { StyledImageContainer } from 'components/ImageComponent/ImageComponent';
import {
    StyledContainerWithoutSageNote,
} from 'containers/Guide/GuideComponentSwitch/GuideComponentSwitch';
import {
    StyledComponentsContainer,
} from 'containers/Guide/GuideComponentSwitch/constants';
import {
    StyledImageCaption,
    StyledImageWrapper,
    StyledContentContainer,
} from 'components/Hero/Hero';
import { RGB_COLORS } from 'utils/variables';
import { StyledSection as SubNavigationSection } from 'components/SubNavigation/SubNavigation';

export const componentsContainerStyles = css`
    margin-top: 0;

    @media screen and ${FROM_TABLET} {
        margin-top: 0;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-top: 0;
    }

    &:first-child {
        margin-top: 0;
    }
`;


export const containerWithoutSageNoteStyles = css`
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
    position: relative;

    @media screen and ${FROM_TABLET} {
        padding-left: 50%;
        justify-content: flex-end;

        /* stylelint-disable */

        ${StyledComponentsContainer} & {
            ${StyledBodyCopyWrapper},
            ${StyledImageContainer} {
                flex-grow: 1;
                flex-shrink: 1;
                max-width: 70%;
                overflow: hidden;
            }
        }
        /* stylelint-enable */
    }
`;

export const wrapperContainerStyles = ({ isFeatured }) => css`
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: flex-end;

    .footer {
        + .citations {
            margin-top: 0;
        }
    }

    .poll {
        + .conclusion,
        + .footer,
        + .sage_moment,
        + .poll {
            margin-top: 0;
        }
    }

    .sidekick {
        + .body_copy,
        + .image,
        + .image_metaphor,
        + .jargon,
        + .poll,
        + .qa,
        + .quiz,
        + .quote,
        + .tools {
            margin-top: ${24 + 40}px;

            @media screen and ${FROM_TABLET} {
                margin-top: ${36 + 50}px;
            }

            @media screen and ${FROM_DESKTOP} {
                margin-top:0px;
            }
        }

        + .conclusion,
        + .footer,
        + .sage_moment,
        + .sidekick {
            margin-top: 0;
        }
    }

    .hero {
        + .conclusion,
        + .sage_moment,
        + .sidekick,
        + .sub_navigation {
            margin-top: 0;
        }
    }

    /* To make navigation stick to the bottom */
    > div:nth-last-child(2) {
        flex: 1 0 auto;
    }

    /* stylelint-disable */

    ${isFeatured && css`

        /* stylelint-disable */

        ${StyledComponentsContainer}.sub_navigation ${SubNavigationSection} {
            background: rgba(${RGB_COLORS.LIGHT_GRAY2},0.5);
        }

        ${StyledComponentsContainer}.sub_navigation:empty +
        ${StyledComponentsContainer}.body_copy ${StyledContainerWithoutSageNote} {
            padding-top: 31px;
        }

        ${StyledComponentsContainer}.sub_navigation +
        ${StyledComponentsContainer}.body_copy {
            margin-top: 0;
            position: relative;

            &::before {
                background: linear-gradient(to bottom, rgba(${RGB_COLORS.LIGHT_GRAY2}, 0.5), rgba(${RGB_COLORS.LIGHT_GRAY1}, 0)) no-repeat;
                background-size: 100% 150px;
                content: '';
                height: 100%;
                left: 0;
                margin-left: calc(-50vw + 50%);
                position: absolute;
                top: 0;
                width: 100vw;
                z-index: -1;
            }
        }

        /* stylelint-enable */

        @media screen and ${FROM_TABLET} {
            /* stylelint-disable */

            ${StyledComponentsContainer}.body_copy {
                ${StyledContainerWithoutSageNote} {

                    ${StyledBodyCopyHeaderContainer},
                    ${StyledBodyCopyContentWrapper} {
                        padding-left: 0;
                        padding-right: 0;
                    }

                    ${StyledBodyCopyContent},
                    ${StyledBodyCopyTitle},
                    ${StyledBodyCopyDescription} {
                        max-width: none;
                    }
                }
            }
            ${StyledComponentsContainer}.image,
            ${StyledComponentsContainer}.body_copy {

                ${StyledContainerWithoutSageNote} {
                    display: flex;
                    flex-flow: column nowrap;

                    ${StyledBodyCopyWrapper},
                    ${StyledImageContainer} {
                        margin-left: auto;
                        margin-right: auto;
                        width: ${getSpanInPercent(8)};
                    }
                }
            }
            /* stylelint-enable */
        }

        @media screen and ${FROM_DESKTOP} {

            /* stylelint-disable */

            ${StyledComponentsContainer}.hero {

                ${StyledImageCaption} {
                    left: 0;
                    margin-bottom: 0;
                    position: absolute;
                    top: 100%;
                    width: 100%;
                }

                ${StyledContentContainer} {
                    padding-bottom: 140px;
                }

                ${StyledImageWrapper} {
                    align-self: flex-start;
                }
            }

            ${StyledComponentsContainer}.image,
            ${StyledComponentsContainer}.body_copy {

                ${StyledContainerWithoutSageNote} {

                    ${StyledBodyCopyWrapper},
                    ${StyledImageContainer} {
                        margin-right: 8.5%;
                    }
                }
            }

            ${StyledComponentsContainer}.sub_navigation:not(:empty) {


                ${SubNavigationSection} {
                    padding-top: 94px;
                }
            }

            ${StyledComponentsContainer}.sub_navigation:empty + ${StyledComponentsContainer}.body_copy {
                margin-top: -95px;

                > div:first-of-type {
                    padding-top: 138px;
                }
            }

            /* stylelint-enable */
        }
    `};

    /* stylelint-enable */
`;

export const collectionContainerStyles = css`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    @media screen and ${SMALL_MOBILE} {
        width: 90%;
        margin: 0% 10% 1%;
    }
    @media screen and ${FROM_TABLET} {
        width: 80%;
        margin: 0% 10% 2%;
    }
    @media screen and ${FROM_DESKTOP} {
        width: 50%;
        margin: 0% 25% 2%;
    }
`


export const topmarginStyles = css`
    margin-top: 65px;
`

