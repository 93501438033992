import { css } from 'styled-components';
import {
    COLORS,
    FONTS,
    ANIMATIONS,
} from 'utils/variables';
import {
    FROM_DESKTOP,
    FROM_TABLET,
} from 'utils/mediaQueries';

export const imageContainerStyles = css`
    display: block;
    overflow: hidden;
    background-image: linear-gradient(to left, ${COLORS.OCEAN}, ${COLORS.SAPPHIRE} 50%);

    ${props => props.visited && css`
        img {
            opacity: 0.6;
        }
    `};
`;

export const titleStyles = css`
    color: ${COLORS.BLACK};
    display: inline-block;
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.06px;
    line-height: 20px;
    margin: 0;
    margin-bottom: 6px;
    text-decoration: none;
    overflow: hidden;
    height: 60px;
    transition: ${ANIMATIONS.ARTICLES.TITLE.TIME.SECONDS}s all;

    &:hover {
        color: ${COLORS.MEDIUM_GRAY2};
    }

    ${props => props.visited && css`
        color: ${COLORS.MEDIUM_GRAY2};
        opacity: 0.75;
        &:active {
            opacity:1;
            color: ${COLORS.BLACK};
        }
        @media screen and ${FROM_DESKTOP} {
            &:hover {
                opacity:1;
                color: ${COLORS.BLACK};
            }
        }    
    `};

    @media screen and ${FROM_TABLET} {
        font-size: 12px;
        line-height: 16px;
        height: 52px;
        margin-bottom: 7px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 6px;
        font-size: 15px;
        line-height: 20px;
        height: 64px;
    }
`;

export const containerStyles = css`
    position: relative;
    background: ${COLORS.WHITE};
    border: 1px solid ${COLORS.LIGHT_GRAY2};
    color: ${COLORS.BLACK};
    display: inline-block;
    max-width: 296px;
    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.CONTAINER.TIME.SECONDS}s all;
    
    &:hover {
        img {
            transform: scale3d(1.05,1.05,1);
        }
    }

    @media screen and ${FROM_TABLET} {
        max-width: 250px;
        height: 274px;
    }

    @media screen and ${FROM_DESKTOP} {
        max-width: 291px;
        height: 331px;
    }

    img {
        height: 157px;
        margin-bottom: -4px;
        object-fit: cover;
        transition: ${ANIMATIONS.ARTICLES.IMAGE.TIME.SECONDS}s all;
        width: 100%;

        @media screen and ${FROM_TABLET} {
            height: 88px;
        }

        @media screen and ${FROM_DESKTOP} {
            height: 153px;
        }
    }
`;

export const dateAndTopicContainerStyles = css`
    display: flex;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-bottom: 7px;
    text-transform: uppercase;
    height: 38px;

    @media screen and ${FROM_TABLET} {
        margin-bottom: 0;
        font-size: 10px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 7px;
        font-size: 12px;
    }
`;

export const dateContainerStyles = css`
    color: ${COLORS.DARK_GRAY2};
    padding-right: 22px;
    white-space: nowrap;
`;

export const topicLinkStyles = css`
    color: ${COLORS.SAPPHIRE};
    flex: 1;
    text-align: right;
    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.TOPIC.TIME.SECONDS}s all;

    &:hover {
    color: ${COLORS.DARK_SAPPHIRE};
  }
`;

export const imageStyles = css`
    height: 157px;
    margin-bottom: -4px;
    object-fit: cover;
    transition: ${ANIMATIONS.ARTICLES.IMAGE.TIME.SECONDS}s all;
    width: 100%;

    @media screen and ${FROM_TABLET} {
        height: 88px;
    }

    @media screen and ${FROM_DESKTOP} {
        height: 157px;
    }
`;

export const contentContainerStyles = css`
    padding: 12px 16px 12px 16px;

    @media screen and ${FROM_TABLET} {
        padding: 12px 13px 12px 13px;
        height: 122px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 12px 16px 12px 16px;
        height: 154px;
    }
`;

export const cardFooterContainerStyles = css`
    margin-top: 33px;

    .kebab__trigger {
        right: 15px;
        bottom: 14px;
    }

    div[data-analytics-label='trackLink : button']{
        bottom: 15px;
    }
`;
