import {
    SET_GLOBAL_LOGO, CLEAR_GLOBAL_LOGO,
} from 'state-management/constants/globalLogoLink';

export const setGlobalLogo = logoLink => ({
    type: SET_GLOBAL_LOGO,
    logoLink,
});

export const clearGlobalLogo = () => ({
    type: CLEAR_GLOBAL_LOGO,
});
