import { css } from 'styled-components';
import {
    COLORS,
    FONTS,
    ANIMATIONS,
} from 'utils/variables';
import {
    FROM_DESKTOP,
    FROM_TABLET,
    SMALL_MOBILE,
} from 'utils/mediaQueries';
import {
    TYPE_TT_SMALL,
    TYPE_TT_MEDIUM,
    TYPE_TT_LARGE,
} from './TrendingTopicCard';

export const outerContainer = css`
    position: relative;
`;

export const topicTagStyles = css`
    color: ${COLORS.SAPPHIRE};
    white-space: nowrap;
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: -0.1px;
    margin-bottom: 17px;
    text-decoration: none;
    display: block;
    position: relative;
    left: -9px;
    z-index: 2;
    transition: ${ANIMATIONS.ARTICLES.TITLE.TIME.SECONDS}s all;

    svg {
        fill: ${COLORS.DARK_SAPPHIRE};
        height: 14px;
        position: relative;
        top: -1px;
        transition: ${ANIMATIONS.ARTICLES.TITLE.TIME.SECONDS}s all;
    }

    &:hover {
        color: ${COLORS.DARK_SAPPHIRE};

        svg {
            fill: ${COLORS.DARK_SAPPHIRE};
        }
    }
`;

export const childArticleContainer = css`
    &.news-not-visited {
        opacity: 1;
    }

    &.news-visited {

        &:hover{
            opacity: 1;
        }
    }
`;

export const imageContainerStyles = css`
    display: block;
    overflow: hidden;
    height: 236px;
    background-image: linear-gradient(to left, ${COLORS.OCEAN}, ${COLORS.SAPPHIRE} 50%);

    &.news-not-visited {
        opacity: 1;
    }

    &.news-visited {
        opacity: 0.6;
    }
`;

export const titleStyles = css`
    color: ${COLORS.BLACK};
    display: inline-block;
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.06px;
    line-height: 20px;
    margin: 0;
    margin-bottom: 6px;
    text-decoration: none;
    overflow: hidden;
    height: 75px;
    padding-bottom: 26px;  
    transition: ${ANIMATIONS.ARTICLES.TITLE.TIME.SECONDS}s all;

    &.news-not-visited {
        opacity: 1;
    }

    &.news-visited {
        opacity: 0.75;
        color: ${COLORS.MEDIUM_GRAY2};

        &:active {
            opacity: 1;
            color: ${COLORS.BLACK};
        }
        
        @media screen and ${FROM_DESKTOP} {
            &:hover {
                opacity: 1;
                color: ${COLORS.BLACK};
            }
        }
    }

    ${props => props.type === TYPE_TT_MEDIUM && css`
        font-size: 18px;
        font-weight: 600;
        letter-spacing: -0.09px;
        line-height: 23px;
        height: 85px;
    `};

    ${props => props.type === TYPE_TT_LARGE && css`
        font-size: 20px;
        font-weight: 600;
        letter-spacing: -0.1px;
        line-height: 24px;
    `};

    &:hover {
        color: ${COLORS.MEDIUM_GRAY2};
    }
`;

export const parentArticleContainer = css`
    border-bottom: 1px solid ${COLORS.LIGHT_GRAY2};
    &.news-not-visited{
        opacity:1;
    }
`;

export const containerStyles = css`
    position: relative;
    background: ${COLORS.WHITE};
    border: 1px solid ${COLORS.LIGHT_GRAY2};
    color: ${COLORS.BLACK};
    display: inline-block;
    max-width: 275px;
    overflow: hidden;
    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.CONTAINER.TIME.SECONDS}s all;

    ${props => props.type === TYPE_TT_MEDIUM && css`
        max-width: 347px;
    `};

    ${props => props.type === TYPE_TT_LARGE && css`
        max-width: 571px;
    `};

    img {
        margin-bottom: -4px;
        object-fit: cover;
        transition: ${ANIMATIONS.ARTICLES.IMAGE.TIME.SECONDS}s all;
        min-height: 100%;
        width: 100%;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            min-width: auto;
            min-height: auto;
            width: auto;
            height: 140%;
        }
    }

    &:hover {
        img {
            transform: scale3d(1.05,1.05,1);
        }
    }
`;

export const dateAndTopicContainerStyles = css`
    display: flex;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-bottom: 0;
    text-transform: uppercase;
    height: 30px;
`;

export const dateContainerStyles = css`
    color: ${COLORS.DARK_GRAY2};
    padding-right: 22px;
    white-space: nowrap;
`;

export const topicLinkStyles = css`
    color: ${COLORS.SAPPHIRE};
    flex: 1;
    text-align: right;
    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.TOPIC.TIME.SECONDS}s all;

    &:hover {
        opacity: 0.6;
    }
`;

export const imageStyles = css`
    margin-bottom: -4px;
    object-fit: cover;
    transition: ${ANIMATIONS.ARTICLES.IMAGE.TIME.SECONDS}s all;
`;

export const contentContainerStyles = css`
    padding: 22px 15px 17px 15px;
    position: relative;

    .kebab__trigger {
        right: 13px;
        bottom: 18px;
    }
`;
