import { css } from 'styled-components';
import {
    FROM_TABLET,
    FROM_TABLET_TO_DESKTOP,
    FROM_DESKTOP,
} from 'utils/mediaQueries';
import {
    COLORS,
    FONTS,
} from 'utils/variables';

export const tabPaneStyles = css`
    display: flex;
    flex-direction: column;
`;

export const sourcesPageWrapperStyles = css`
    margin-bottom: 20px;
    padding: 0 16px;

    @media screen and ${FROM_TABLET} {
        margin: 14px 0 40px;
    }
`;

export const dropDownWrapperStyles = css`
    display: inline-block;
    width: 100%;

    *[data-rel="Select"] em {
        display: none;
    }

    *[data-rel="Select"] {
        width: 100%;
    }

    /* sorting drop-down molecule */
    &>div {
        text-align: left;
        float: right;
        width: 100%;

    }

    @media screen and ${FROM_TABLET} {
        width: inherit;

        *[data-rel="Select"] {
            width: 220px;
        }
    }
`;

export const sortAndFiltersContainerStyles = css`
    @media screen and ${FROM_TABLET} {
        text-align: right;
    }
`;

export const sourcesGrid = css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 30px;

    @media screen and ${FROM_TABLET_TO_DESKTOP} {
        &>label {
            margin-right: 24px !important;
            width: calc(50% - 24px);
            flex-basis: initial;
        }
    }

    @media screen and ${FROM_DESKTOP} {
        &>label {
            width: calc(32% - 16px);
            flex-basis: initial;
        }

    }
`;
