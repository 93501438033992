import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import storage from '../../utils/store';
import { Container, Heading } from 'components';
import { contextHubRecordPageView } from '../../utils/contextHub';
import { getBasicProfile as getBasicProfileAction, getNotification } from 'state-management/actions/basicProfile';
import { MIGRATION_ENUM, SKIP_MIGRATION_STORAGE_KEY } from 'state-management/constants/basicProfile';
import { signOut as signOutAction } from 'state-management/actions/signOut';
import {
    sectionStyles,
    containerStyles,
    headingStyles,
    errorCodeStyles,
    descriptionStyles,
    title,
    description,
    buttons,
    disclaimer,
    note,
    noteText,
} from './styles';
import Button from 'components/atomics/atoms/Button/Button';
import { TYPE_PRIMARY_MEDIUM, TYPE_GHOST } from 'components/atomics/atoms/Button/Button';
import { getExternalMigrationURL } from 'utils/urlUtils';

const Note = styled.div`
    ${note}
`;

const NoteText = styled.div`
    ${noteText}
`;

const Disclaimer = styled.div`
    ${disclaimer}
`;

const Buttons = styled.div`
    ${buttons}
`;

const Description = styled.p`
    ${description}
`;

const Title = styled.h1`
    ${title}
`;

const StyledSection = styled.section`
    ${sectionStyles};
`;

const StyledContainer = styled(Container)`
    ${containerStyles};
`;

const StyledHeading = styled(Heading)`
    ${headingStyles};
`;

const StyledErrorCode = styled.p`
    ${errorCodeStyles};
`;

const StyledDescription = styled.p`
    ${descriptionStyles};
`;

export class Migrate extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
     constructor(props) {
        super(props);

        this.state = {
            loaded: false,
        };
    }

    /**
     * Record anaylitcs at mount.
     */
    componentDidMount() {
        //this.props.getBasicProfile();

        //this.props.scrollToTop();

        /*if (this.props.migrationState === MIGRATION_ENUM.MUST_MIGRATE) {
            window.addEventListener('beforeunload', function (e) {
                // Cancel the event
                e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
                // Chrome requires returnValue to be set
                e.returnValue = '';
            });
        }*/

        //document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this), false);

        try {
            contextHubRecordPageView('/migrate');
        } catch(e) {
            //catching for testing purposes only
        }

        this.props.getNotification();
    }

    componentWillUnmount() {
        //document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    }

    componentDidUpdate() {
        if (this.props.migrationState === MIGRATION_ENUM.MIGRATED) {
            this.props.signOut();
        }
    }

    handleVisibilityChange() {
        /*if (document.visibilityState === 'visible' && this.props.migrationState === null && !this.state.loaded) {

            this.setState({
                loaded: true,
            });

            //document.removeEventListener('visibilitychange', this.handleVisibilityChange);

            console.log('DEBUG migration');

            this.props.getBasicProfile();
        }*/
    }

    /**
     * Render this and underlying components.
     */
    render() {
        //allow navigation after render
        setTimeout(() => {
            storage.set(SKIP_MIGRATION_STORAGE_KEY, true);
        }, 300);

        return (
            <StyledSection>
                <StyledContainer>
                    <div>
                        <Title>
                            <FormattedMessage id="migrate.title" />
                        </Title>
                        <Description>
                            <FormattedMessage id="migtrate.description" />
                            <sup>*</sup>
                        </Description>
                       <Buttons>
                            <Button
                                to={getExternalMigrationURL()}
                                type={TYPE_PRIMARY_MEDIUM}
                            >
                                <FormattedMessage id="migrate.cta.confirm" />
                            </Button>
                            <br/>
                            {!(this.props.migrationState === MIGRATION_ENUM.MUST_MIGRATE)
                            && <Button
                                onClick={() => {
                                    window.location.href = '/home';
                                }}
                                noAnchor={true}
                                type={TYPE_GHOST}
                            >
                                <FormattedMessage id="migrate.cta.skip" />
                            </Button>
                            }
                       </Buttons>
                       <Disclaimer>
                            <sup>*</sup>
                            <FormattedMessage id="migrate.note" />
                       </Disclaimer>
                    </div>
                </StyledContainer>
                <Note>

                    <NoteText>
                        <FormattedMessage id="migrate.login" />
                    </NoteText>
                    <Button
                        to="https://www.capitalgroup.com/advisor/contact-us.html"
                        type={TYPE_GHOST}
                    >
                        <FormattedMessage id="migrate.cta.loginHelp" />
                    </Button>
                </Note>
            </StyledSection>
        );
    }
}

Migrate.propTypes = {
    getBasicProfile: PropTypes.func,
    migrationState: PropTypes.string,
};

const mapStateToProps = state => ({
    migrationState: state.basicProfile.notification.data.migrationState,
});

const mapDispatchToProps = dispatch => ({
    getBasicProfile: () => dispatch(getBasicProfileAction()),
    getNotification: () => dispatch(getNotification()),
    signOut: () => dispatch(signOutAction('/sign-in')),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Migrate));
