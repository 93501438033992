import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button, { TYPE_GHOST, TYPE_PRIMARY_MEDIUM_LARGE } from 'components/atomics/atoms/Button/Button';
import InputAtom, { TYPE_LARGE } from 'components/atomics/atoms/Input/Input';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { sendForgotPasswordEmail, resetForgotPasswordEmail } from 'state-management/actions/forgotPassword';
import { isEmailValid } from 'utils/validators/emailValidator';

import {
    container,
    buttonRow,
    title,
    subTitle,
    callout,
    fieldRow,
    form,
    errorRow,
    signUpLinkStyles,
} from './styles';

const Container = styled.div`
    ${container}
`;

const Callout = styled.div`
    ${callout}
`;

const ButtonRow = styled.div`
    ${buttonRow}
`;

const Title = styled.div`
    ${title}
`;

const SubTitle = styled.div`
    ${subTitle}
`;

const FieldRow = styled.div`
    ${fieldRow}
`;

const Form = styled.div`
    ${form}
`;

const ErrorRow = styled.div`
    ${errorRow}
`;

const StyledSignUpLink = styled(Link)`
    ${signUpLinkStyles};
`;

export class ForgotPasswordModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            error: null,
        };
    }

    componentDidMount() {
        this.props.resetForgotPasswordEmail();
    }

    translate = (id, values = {}) => {
        const { intl: { formatMessage } } = this.props;
        return formatMessage({ id }, values);
    }

    validate = () => {
        const { email } = this.state;
        if (!email) {
            this.setState({ error: this.translate('validation.error.email.empty') });
        } else if (!isEmailValid(email)) {
            this.setState({ error: this.translate('validation.error.email.format') });
        }
    }

    submit = () => {
        const { email } = this.state;
        if (email && isEmailValid(email)) {
            this.props.sendForgotPasswordEmail(email);
        }
    }

    renderError = () => {
        const { forgotPasswordError } = this.props;
        if (forgotPasswordError) {
            if (forgotPasswordError.response && forgotPasswordError.response.status === 400) {
                return (
                    <ErrorRow>
                        <FormattedMessage id="signIn.forgotPassword.invalid" />
                    </ErrorRow>
                );
            } else {
                return (
                    <ErrorRow>
                        <FormattedMessage id="signIn.forgotPassword.failure" />
                    </ErrorRow>
                );
            }
        }
        return null;
    }

    render() {
        const { forgotPasswordEmail, forgotPasswordError } = this.props;
        return (
            <Container>

                {forgotPasswordEmail && !forgotPasswordError ? (
                    <Form>
                        <Title>
                            <FormattedMessage id="signIn.forgotPassword.success.title" />
                        </Title>

                        <SubTitle>
                            <FormattedMessage id="signIn.forgotPassword.success.description" values={{ email: forgotPasswordEmail }} />
                        </SubTitle>

                        <Callout>
                            <FormattedMessage id="signIn.forgotPassword.success.hint" />
                        </Callout>

                        <ButtonRow>
                            <Button
                                type={TYPE_GHOST}
                                onClick={this.submit}
                                dontScroll={true}
                                noAnchor
                            >
                                <FormattedMessage id="signIn.forgotPassword.success.action" />
                            </Button>
                        </ButtonRow>
                    </Form>
                ) : (
                    <Form>
                        <Title>
                            <FormattedMessage id="signIn.forgotPassword.title" />
                        </Title>

                        <SubTitle>
                            <FormattedMessage id="signIn.forgotPassword.description" />
                        </SubTitle>

                        <FieldRow>
                            <InputAtom
                                autocomplete="off"
                                inputType="email"
                                id="email"
                                showCheckWhenValid
                                validated={false}
                                label={this.translate('signIn.forgotPassword.email')}
                                placeholder={this.translate('signIn.forgotPassword.email')}
                                error={this.state.error}
                                onBlur={this.validate}
                                onFocus={() => this.setState({ error: null })}
                                onChange={({ value }) => {
                                    this.setState({ email: value });
                                    if (forgotPasswordError) this.props.resetForgotPasswordEmail();
                                }}
                                isErrored={this.state.error !== null}
                                type={TYPE_LARGE}
                                value={this.state.email || ''}
                            />
                        </FieldRow>

                        { this.renderError() }

                        <ButtonRow>
                            <Button
                                type={TYPE_PRIMARY_MEDIUM_LARGE}
                                onClick={this.submit}
                                dontScroll={true}
                                noAnchor
                            >
                                <FormattedMessage id="signIn.forgotPassword.action" />
                            </Button>
                        </ButtonRow>

                        <Callout>
                            <FormattedMessage
                                id="signIn.forgotPassword.hint"
                                values={{
                                    link: (
                                        <StyledSignUpLink to="/contact-us">
                                            <FormattedMessage id="signIn.forgotPassword.hint.link" />
                                        </StyledSignUpLink>
                                    ),
                                }}
                            />
                        </Callout>
                    </Form>
                )}

            </Container>
        );
    }
}

ForgotPasswordModal.propTypes = {
    sendForgotPasswordEmail: PropTypes.func,
};

const mapStateToProps = state => ({
    forgotPasswordEmail: state.forgotPassword.forgotPasswordEmail,
    forgotPasswordError: state.forgotPassword.forgotPasswordError,
});

const mapDispatchToProps = dispatch => ({
    resetForgotPasswordEmail: () => dispatch(resetForgotPasswordEmail()),
    sendForgotPasswordEmail: email => dispatch(sendForgotPasswordEmail(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ForgotPasswordModal));
