/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import Video from 'components/Video/Video';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { IconClose, IconPlayerExpand, IconPlayerPlay } from 'components/atomics/atoms/Icons/Icons';
import labels from '../../lang/en.json';

import {
    BloombergVideoComp,
    BloombergVideoCompSticky,
    LiveTvButton,
    MinimizedWrapper,
    ClosedButton,
    BloombergText,
    LiveTV,
    PlayButton,
    FullScreenButton,
    ThumbnailWrapper,
    FullScreenViewWrapper,
    closeIconStyles,
    playerExpandIconStyles,
    playerPlayIconStyles,
    ClickableWrapper,
    ClickableWrapperT,
} from './styles';

import { contextHubRecordEvent } from 'utils/contextHub';

const StyledMainContainer = styled.div`
    display: block;
`;

const StyledBloombergVideoComp = styled.div`
    ${BloombergVideoComp}
`;

const StyledBloombergVideoCompSticky = styled.div`
    ${BloombergVideoCompSticky}
`;

const StyledLiveTvButton = styled.button`
    ${LiveTvButton}
`;

const StyledMinimizedWrapper = styled.div`
    ${MinimizedWrapper}
`;

const StyledThumbnailWrapper = styled.div`
    ${ThumbnailWrapper}
`;

const StyledClosedButton = styled.button`
    ${ClosedButton}
`;

const StyledBloombergText = styled.h4`
    ${BloombergText}
`;

const StyledLiveTV = styled.span`
    ${LiveTV}
`;

const StyledPlayButton = styled.button`
    ${PlayButton}
`;

const StyledFullScreenButton = styled.a`
    ${FullScreenButton}
`;

const StyledFullScreenViewWrapper = styled.div`
    ${FullScreenViewWrapper}
`;

const StyledClickableWrapper = styled.div`
    ${ClickableWrapper}
`;

const StyledClickableWrapperT = styled.div`
    ${ClickableWrapperT}
`;

const STATE_MAX_MINIMIZED = 'maxminimized';
const STATE_MINIMIZED = 'minimized';
const STATE_THUMBNAIL = 'thumbnail';
const STATE_FULLSCREEN = 'fullscreen';

const StyledCloseIcon = styled(IconClose)`
    ${closeIconStyles};
`;

const StyledPlayerExpandIcon = styled(IconPlayerExpand)`
    ${playerExpandIconStyles};
`;

const StyledPlayerPlayIcon = styled(IconPlayerPlay)`
    ${playerPlayIconStyles};
`;

class BloombergLiveStream extends React.Component {
    /**
     * Default constructor and state assignment.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.state = {
            videoState: props.videoState,
            isFooterInView: false,
        };

        this.showFullScreenView = this.showFullScreenView.bind(this);
        this.showMaxMinimizedView = this.showMaxMinimizedView.bind(this);
        this.showMinimizedView = this.showMinimizedView.bind(this);
        this.showMaxMinimizedViewFromThumbnail = this.showMaxMinimizedViewFromThumbnail.bind(this);
        this.showMinimizedViewFromThumbnail = this.showMinimizedViewFromThumbnail.bind(this);
        this.showThumbnailView = this.showThumbnailView.bind(this);
        this.handleWindowScroll = this.handleWindowScroll.bind(this);
        this.handleVideoPlay = this.handleVideoPlay.bind(this);
        this.stickyElementRef = React.createRef();
        this.placeholderRef = React.createRef();
    }

    /**
     * Add event listeners to control sticky behavior.
     */
    componentDidMount() {
        window.addEventListener('scroll', this.handleWindowScroll);
        window.addEventListener('resize', this.handleWindowScroll);
    }

    /**
     * Remove event listeners that control sticky behavior.
     */
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleWindowScroll);
        window.removeEventListener('resize', this.handleWindowScroll);
    }

    handleVideoPlay() {
        var tracking =
            {
                "type": "video",
                "action": "start",
                "value": null,
                "title": "Bloomberg Live Stream",
                "topic": null,
                "source": null,
                "location": null
            };
        contextHubRecordEvent(tracking);

    }

    /**
     * Handle if the player is sticky or not on scroll or resize of window.
     */
    handleWindowScroll() {
        const scrollTop = document.body.scrollTop
            ? document.body.scrollTop : document.documentElement.scrollTop;
        const windowHeight = window.innerHeight;

        const stickyElement = this.stickyElementRef.current;
        const placeholderElement = this.placeholderRef.current;

        if (stickyElement) {
            placeholderElement.setAttribute('style', `min-height:${stickyElement.offsetHeight}px;`);
        }

        if (scrollTop + windowHeight >= this.placeholderRef.current.offsetTop) {
            this.setState({
                isFooterInView: true,
            });
        } else {
            this.setState({
                isFooterInView: false,
            });
        }
    }

    /**
     * Show the thumbnail view.
     */
    showThumbnailView() {
        this.setState({
            videoState: STATE_THUMBNAIL,
        });

        this.placeholderRef.current.setAttribute('style', 'min-height:268px;');
    }

    /**
     * Show the minimized state with mutliple controls.
     */
    showMinimizedView() {
        this.setState({
            videoState: STATE_MINIMIZED,
        });

        this.placeholderRef.current.setAttribute('style', 'min-height:54px;');
    }

    /**
     * Show the minimized state with mutliple controls. FROM thumbnail, for analytics wrapping..
     */
    showMinimizedViewFromThumbnail() {
        this.showMinimizedView();
    }

    /**
     * Show the max minimized view, clickable to expand to minimized.
     */
    showMaxMinimizedView() {
        this.setState({
            videoState: STATE_MAX_MINIMIZED,
        });

        this.placeholderRef.current.setAttribute('style', 'min-height:54px;');
    }

    /**
     * Show the max minimized view, FROM thumbnail, for analytics wrapping..
     */
    showMaxMinimizedViewFromThumbnail() {
        this.showMaxMinimizedView();
    }

    /**
     * Show the full screen view, this is where a prop helps, as it will be in a new page.
     */
    showFullScreenView() {
        this.setState({
            videoState: STATE_FULLSCREEN,
        });
    }

    /**
     * Render the max minimized styled component.
     */
    renderMaxMinimized() {
        return (
            <StyledLiveTvButton
                onClick={this.showMinimizedView}
                aria-label={labels.translations['news.bloomberg.liveTv']}
            >
                <em />
                <FormattedMessage id="news.bloomberg.liveTv" />
            </StyledLiveTvButton>
        );
    }

    /**
     * Render the minimized styled component.
     */
    renderMinimized() {
        return (
            <StyledMinimizedWrapper>
                <StyledClosedButton
                    data-rel="collapse"
                    onClick={this.showMaxMinimizedView}
                    aria-label={labels.translations['news.bloomberg.close']}
                >
                    <StyledCloseIcon />
                </StyledClosedButton>
                <StyledClickableWrapper data-rel="expand" onClick={this.showThumbnailView}>
                    <StyledBloombergText>
                        <FormattedMessage id="news.bloomberg.label" />
                    </StyledBloombergText>
                    <StyledLiveTV>
                        <em />
                        <FormattedMessage id="news.bloomberg.liveTv" />
                    </StyledLiveTV>
                    <StyledPlayButton
                        aria-label={labels.translations['news.bloomberg.play']}
                    >
                        <StyledPlayerPlayIcon />
                    </StyledPlayButton>
                </StyledClickableWrapper>
            </StyledMinimizedWrapper>
        );
    }

    /**
     * Render the thumbnail styled component.
     */
    renderThumbnail() {
        return (
            <StyledThumbnailWrapper>
                <StyledMinimizedWrapper>
                    <StyledClosedButton
                        onClick={this.showMaxMinimizedViewFromThumbnail}
                        aria-label={labels.translations['news.bloomberg.close']}
                    >
                        <StyledCloseIcon />
                    </StyledClosedButton>
                    <StyledClickableWrapperT onClick={this.showMinimizedViewFromThumbnail}>
                        <StyledBloombergText>
                            <FormattedMessage id="news.bloomberg.label" />
                        </StyledBloombergText>
                        <StyledLiveTV>
                            <em />
                            <FormattedMessage id="news.bloomberg.liveTv" />
                        </StyledLiveTV>
                    </StyledClickableWrapperT>
                    <StyledFullScreenButton
                        href="/bloomberg"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={this.showMinimizedViewFromThumbnail}
                        aria-label={labels.translations['news.bloomberg.expandToFullScreen']}
                    >
                        <StyledPlayerExpandIcon />
                    </StyledFullScreenButton>
                </StyledMinimizedWrapper>
                <Video
                    onPlayFunction={this.handleVideoPlay}
                />
            </StyledThumbnailWrapper>
        );
    }

    /**
     * Render the fullscreen styled component.
     */
    renderFullscreen() {
        return (
            <StyledFullScreenViewWrapper>
                <h4>
                    <FormattedMessage id="news.bloomberg.label" />
                </h4>
                <Video
                    onPlayFunction={this.handleVideoPlay}
                />
            </StyledFullScreenViewWrapper>
        );
    }

    /**
     * Render the sticky version.
     */
    renderSticky() {
        return (
            <StyledBloombergVideoCompSticky ref={this.stickyElementRef}>
                {this.state.videoState === STATE_MAX_MINIMIZED
                && this.renderMaxMinimized()}
                {this.state.videoState === STATE_MINIMIZED
                && this.renderMinimized()}
                {this.state.videoState === STATE_THUMBNAIL
                && this.renderThumbnail()}
            </StyledBloombergVideoCompSticky>);
    }

    /**
     * Render the non-sticky version.
     */
    renderNonSticky() {
        return (
            <StyledBloombergVideoComp>
                {this.state.videoState === STATE_MAX_MINIMIZED
                && this.renderMaxMinimized()}
                {this.state.videoState === STATE_MINIMIZED
                && this.renderMinimized()}
                {this.state.videoState === STATE_THUMBNAIL
                && this.renderThumbnail()}
                {this.state.videoState === STATE_FULLSCREEN
                && this.renderFullscreen()}
            </StyledBloombergVideoComp>);
    }

    /**
     * Main component render.
     */
    render() {
        let isSticky = this.props.videoState !== STATE_FULLSCREEN;

        if (this.state.isFooterInView) {
            isSticky = false;
        }

        return (
            <StyledMainContainer ref={this.placeholderRef}>
                {isSticky
                && this.renderSticky() }
                {!isSticky
                && this.renderNonSticky() }
            </StyledMainContainer>
        );
    }
}

BloombergLiveStream.propTypes = {
    videoState: PropTypes.string,
};

BloombergLiveStream.defaultProps = {
    videoState: STATE_MINIMIZED,
};

export default BloombergLiveStream;
