import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import {
    Button, Heading,
} from 'components';
import {
    sectionHeadingStyles,
    clearButtonStyles,
    sectionHeaderStyles,
    filterSectionStyles,
    selectionCountStyles,
    noResultsHeaderStyles,
    noResultsStyles,
} from 'containers/Search/SearchFilterSection/styles';
import SearchFilterItems from 'containers/Search/SearchFilterItems/SearchFilterItems';

const StyledSectionHeading = styled(Heading)`
    ${sectionHeadingStyles}
`;

const StyledClearButton = styled(Button)`
    ${clearButtonStyles}
`;

const StyledSectionHeader = styled.div`
    ${sectionHeaderStyles}
`;

const StyledFilterSection = styled.div`
    ${filterSectionStyles}
`;

const StyledSelectionCount = styled.span`
    ${selectionCountStyles}
`;

const StyledNoResults = styled.div`
    ${noResultsStyles}
`;

const StyledNoResultsHeader = styled.div`
    ${noResultsHeaderStyles}
`;

/**
 * Renders a single section for search page filters menu.
 */
class SearchFilterSection extends PureComponent {
    /**
     * Get a localized string resource by ID.
     * @param {*} id 
     */
    getTranslation(id) {
        const { intl: { formatMessage } } = this.props;

        return formatMessage({ id });
    }

    /**
     * Get the correct label for a single search section.
     */
    getLabel = item => (item.translationId
        ? this.getTranslation(item.translationId)
        : item.name);

    /**
     * Render no results version.
     */
    renderNoResults = () => {
        const { noResultsTranslationId } = this.props;
        return noResultsTranslationId ? (
            <React.Fragment>
                <StyledNoResultsHeader />
                <StyledNoResults>
                    <FormattedMessage id={noResultsTranslationId} />
                </StyledNoResults>
            </React.Fragment>
        ) : null;
    };

    /**
     * Render this and underlying components.
     */
    render() {
        const {
            onClear,
            onToggleItem,
            section,
        } = this.props;

        const selectedFilters = section.items.filter(item => item.checked).length;

        return (
            <StyledFilterSection>
                <StyledSectionHeader>
                    <StyledSectionHeading level={2}>
                        <FormattedMessage id={`search.filters.sections.${section.title}`} />
                        {!!selectedFilters && (
                            <StyledSelectionCount>
                                (
                                {selectedFilters}
                                )
                            </StyledSelectionCount>
                        )}
                    </StyledSectionHeading>
                    {!!selectedFilters && (
                        <StyledClearButton
                            id={`clear-${section.title}-section`}
                            onClick={() => onClear(section.title)}
                        >
                            <FormattedMessage id="search.filters.clear" />
                        </StyledClearButton>
                    )}
                </StyledSectionHeader>
                {section.items.length
                    ? <SearchFilterItems section={section} onToggleItem={onToggleItem} />
                    : this.renderNoResults()}
            </StyledFilterSection>
        );
    }
}

SearchFilterSection.defaultProps = {
    noResultsTranslationId: null,
};

SearchFilterSection.propTypes = {
    intl: intlShape.isRequired,
    onClear: PropTypes.func.isRequired,
    onToggleItem: PropTypes.func.isRequired,
    noResultsTranslationId: PropTypes.string,
    section: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
};

export default injectIntl(SearchFilterSection);
