import React from 'react';
import PropTypes from 'prop-types';

const BlueOverlay = props => (
    <g>
        <filter id={props.id}>
            <feColorMatrix
                in="SourceGraphic"
                type="matrix"
                values="0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 0.4 0"
                result="greyscale"
            />
            <feFlood
                result="blueBackground"
                x="0"
                y="0"
                width="100%"
                height="100%"
                floodColor="#007ac2"
                floodOpacity="1"
            />
            <feBlend in="greyscale" in2="blueBackground" mode="multiply" />
        </filter>
        <linearGradient id="linear" x1="0%" y1="0%" x2="0" y2="100%">
            <stop offset="0%" stopColor="#0065a0" stopOpacity="0" />
            <stop offset="100%" stopColor="#007ac2" stopOpacity="1" />
        </linearGradient>
        <rect x="0" y="0" width="100%" height="100%" fill="url(#linear)" />
    </g>
);

BlueOverlay.propTypes = {
    id: PropTypes.string,
};

BlueOverlay.defaultProps = {
    id: 'filter',
};

export default BlueOverlay;
