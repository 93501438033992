import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { IconClose, IconSearch } from 'components/atomics/atoms/Icons/Icons';
import { Button, VisuallyHidden } from 'components';
import {
    close,
    open,
    searchRedirect as searchRedirectAction,
} from 'state-management/actions/search';
import { BreakpointsContext, MOBILE } from 'components/Breakpoints/Breakpoints';
import { MIN_QUERY_LENGTH } from 'state-management/constants/search';
import {
    closeIconStyles,
    formContainerStyles,
    formStyles,
    inputStyles,
    labelStyles,
    toggleButtonStyles,
    searchIconStyles,
    submitButtonStyles,
    wrapperStyles,
    borderStyles,
} from 'components/SearchBar/styles';
import { ROUTES } from 'containers/App/constants';

const StyledWrapper = styled.div`
    ${wrapperStyles};
`;
const StyledForm = styled.form`
    ${formStyles};
`;
const StyledFormContainer = styled.div`
    ${formContainerStyles};
`;
const StyledLabel = styled.label`
    ${labelStyles};
`;
export const StyledInput = styled.input`
    ${inputStyles};
`;
const StyledCloseIcon = styled(IconClose)`
    ${closeIconStyles};
`;
const StyledSearchIcon = styled(IconSearch)`
    ${searchIconStyles};
`;
export const StyledToggleButton = styled(Button)`
    ${toggleButtonStyles};
`;
export const StyledSubmitButton = styled(Button)`
    ${submitButtonStyles};
`;

const StyledBordered = styled.div`
    ${borderStyles};
`;

export class SearchBarComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        if (state.previousSearchValueFromProps !== props.searchValue) {
            return {
                previousSearchValueFromProps: props.searchValue,
                searchValue: props.searchValue,
            };
        }

        return null;
    }

    constructor(props) {
        super(props);

        this.state = {
            // eslint-disable-next-line react/no-unused-state
            previousSearchValueFromProps: this.props.searchValue,
            searchValue: this.props.searchValue,
        };
        this.searchInput = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.focusInput();
        }
    }

    handleChange = (event) => {
        const { target } = event;

        this.setState({
            searchValue: target.value,
        });
    };

    handleSubmit = (event) => {
        const {
            minQueryLength,
            searchRedirect,
        } = this.props;
        const searchValue = this.state.searchValue.trim();
        let url = ROUTES.SEARCH + '/?orderBy=Newest First&q=' + searchValue;

        if (searchValue && searchValue.length >= minQueryLength) {
            location.href = url;
        }
        event.preventDefault();
    };

    toggle() {
        const { closeSearch, isOpen, openSearch } = this.props;

        if (isOpen) {
            closeSearch();
        } else {
            openSearch();
        }
    }

    focusInput() {
        const inputNode = this.searchInput.current;

        if (inputNode) {
            inputNode.focus();
        }
    }

    translate(id, params = {}) {
        const { intl: { formatMessage } } = this.props;

        return formatMessage({
            id,
        }, params);
    }

    renderPlaceholderText = (breakpoint) => {
        const text = breakpoint === MOBILE
            ? 'search.placeholderMobile'
            : 'search.placeholder';

        return this.translate(text);
    };

    render() {
        const {
            id,
            isOpen,
            minQueryLength,
        } = this.props;

        return (
            <StyledWrapper>
                <StyledForm
                    id={id}
                    role="search"
                    variant={{ isOpen }}
                    onSubmit={this.handleSubmit}
                >
                    <StyledFormContainer>
                        <StyledLabel
                            htmlFor={`${id}-input`}
                        >
                            <BreakpointsContext.Consumer>
                                {this.renderPlaceholderText}
                            </BreakpointsContext.Consumer>
                            <VisuallyHidden>
                                {this.translate('search.label', { limit: minQueryLength })}
                            </VisuallyHidden>
                        </StyledLabel>
                        <StyledInput
                            id={`${id}-input`}
                            ref={this.searchInput}
                            onChange={this.handleChange}
                            value={this.state.searchValue}
                        />

                        <StyledSubmitButton
                            id={`${id}-trigger`}
                            type="submit"
                        >

                            <VisuallyHidden>
                                {this.translate('search.submit')}
                            </VisuallyHidden>
                            <StyledSearchIcon />
                        </StyledSubmitButton>
                        <StyledBordered />
                    </StyledFormContainer>
                </StyledForm>

                <StyledToggleButton
                    aria-controls={id}
                    aria-expanded={isOpen}
                    id={`${id}-toggle`}
                    onClick={() => this.toggle()}
                    variant={{ isOpen }}
                >
                    <VisuallyHidden>
                        {this.translate('search.toggle')}
                    </VisuallyHidden>
                    {
                        isOpen
                            ? <StyledCloseIcon />
                            : <StyledSearchIcon />
                    }
                </StyledToggleButton>
            </StyledWrapper>
        );
    }
}

SearchBarComponent.propTypes = {
    id: PropTypes.string,
    intl: intlShape.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeSearch: PropTypes.func.isRequired,
    minQueryLength: PropTypes.number,
    openSearch: PropTypes.func.isRequired,
    searchRedirect: PropTypes.func.isRequired,
    searchValue: PropTypes.string.isRequired,
};

SearchBarComponent.defaultProps = {
    id: 'search',
    minQueryLength: MIN_QUERY_LENGTH,
};

const mapStateToProps = state => ({
    currentUrl: state.router.location.pathname,
    isOpen: state.search.isOpen,
    searchValue: state.search.searchValue,
});

const mapDispatchToProps = dispatch => ({
    closeSearch: () => dispatch(close()),
    openSearch: () => dispatch(open()),
    searchRedirect: url => dispatch(searchRedirectAction(url, { resetFilters: true })),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SearchBarComponent));
