import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BodyCopy from 'containers/Guide/BodyCopy/BodyCopy';
import ImageMetaphor from 'containers/Guide/ImageMetaphor/ImageMetaphor';
import QuestionsAnswers from 'containers/Guide/QuestionsAnswers/QuestionsAnswers';
import QuizComponent from 'containers/Guide/Quiz/Quiz';
import Footer from 'containers/Guide/Footer/Footer';
import GuideCollections from '../GuideCollections/GuideCollections';
import {
    Citations,
    Conclusion,
    Container,
    Hero,
    Jargon,
    Quote,
    SageMoment,
    SageNote,
    Sidekick,
    SubNavigation,
    ImageComponent,
} from '../../../components';
import Tools from 'containers/Tools/Tools';

import {
    componentsContainerStyles,
    containerWithoutSageNoteStyles,
    collectionContainerStyles,
    topmarginStyles,
} from 'containers/Guide/styles';

import { connect } from "react-redux";


export const TopMarginAdder = styled.div`
    ${topmarginStyles}
`;


export const StyledComponentsContainer = styled.div`
    ${componentsContainerStyles}
`;

export const StyledContainerWithoutSageNote = styled(Container)`
    ${containerWithoutSageNoteStyles}
`;

export const StyledCollectionContainer = styled.div`
    ${collectionContainerStyles}
`;

/**
 * Wraper for all guide page components, decides which display component and passes it relevant context and data.
 * @param {*} type 
 * @param {*} data 
 */
const renderComponent = (type, data) => {
    switch (type) {
        case 'BODY_COPY': {
            if (SageNote.areAttributesValid(data.tipTitle, data.tipSubTitle)) {
                return BodyCopy.areAttributesValid(data) && (
                    <Container>
                        <SageNote
                            id={data.id}
                            component={(
                                <BodyCopy
                                    id={data.id}
                                    data={data}
                                />
                            )}
                            title={data.tipTitle}
                            description={data.tipSubTitle}
                        />
                    </Container>
                );
            }
            return BodyCopy.areAttributesValid(data) && (
                <StyledContainerWithoutSageNote>
                    <BodyCopy
                        id={data.id}
                        data={data}
                    />
                </StyledContainerWithoutSageNote>
            );
        }
        case 'CITATIONS':
            return Citations.areAttributesValid(data) && (
                <Container>
                    <Citations
                        data={data}
                        id={data.id}
                    />
                </Container>
            );
        case 'CLOSING':
            return Conclusion.areAttributesValid(data) && (
                <Conclusion
                    data={data}
                    id={data.id}
                />
            );
        case 'FOOTER':
            return (
                <Footer
                    {...data}
                />
            );
        case 'HERO':
            return Hero.areAttributesValid(data) && (
                <Hero
                    {...data}
                />
            );
        case 'IMAGE_CONTENT':
            return SageNote.areAttributesValid(data.tipTitle, data.tipSubTitle) && (
                <Container>
                    <SageNote
                        id={data.id}
                        key={data.id}
                        component={(
                            <ImageMetaphor
                                {...data}
                            />
                        )}
                        title={data.tipTitle}
                        description={data.tipSubTitle}
                    />
                </Container>
            );
        case 'TERMINOLOGY':
            return SageNote.areAttributesValid(data.tipTitle, data.tipSubTitle) && (
                <Container>
                    <SageNote
                        id={data.id}
                        key={data.id}
                        component={(
                            <Jargon
                                key={data.id}
                                id={data.id}
                                data={data.termDefinitions}
                            />
                        )}
                        title={data.tipTitle}
                        description={data.tipSubTitle}
                    />
                </Container>
            );
        case 'ACCORDION':
            return SageNote.areAttributesValid(data.tipTitle, data.tipSubTitle)
                && QuestionsAnswers.areAttributesValid(data) && (
                    <TopMarginAdder>
                        <Container>
                            <SageNote
                                id={data.id}
                                component={(
                                    <QuestionsAnswers
                                        id={data.id}
                                        elements={data.elements}
                                    />
                                )}
                                title={data.tipTitle}
                                description={data.tipSubTitle}
                            />
                        </Container>
                    </TopMarginAdder>
                );
        case 'POLL':
        case 'QUIZ': {

            if (data.tipSubTitle === null) {
                data.tipSubTitle = ' ';
            }
            if (data.tipTitle === null) {
                data.tipTitle = ' ';
            }

            if (!QuizComponent.areAttributesValid(data)
                || (!SageNote.areAttributesValid(data.tipTitle, data.tipSubTitle) && type === 'QUIZ')) {
                return null;
            }


            const quizComponent = (
                <QuizComponent
                    id={data.id}
                    data={{ ...data, type }}
                />
            );
            return (
                <Container>
                    <SageNote
                        id={data.id}
                        component={quizComponent}
                        title={data.tipTitle}
                        description={data.tipSubTitle}
                    />
                </Container>

            );
        }
        case 'PULL_QUOTE':
            return SageNote.areAttributesValid(data.tipTitle, data.tipSubTitle)
                && Quote.areAttributesValid(data) && (
                    <Container>
                        <SageNote
                            id={data.id}
                            key={data.id}
                            component={(
                                <Quote
                                    data={data}
                                    id={data.id}
                                />
                            )}
                            title={data.tipTitle}
                            description={data.tipSubTitle}
                        />
                    </Container>
                );
        case 'PRO_MOMENT':
            return SageMoment.areAttributesValid(data) && (
                <SageMoment
                    id={data.id}
                    data={data}
                />
            );
        case 'FEATURED_FACT':
            return Sidekick.areAttributesValid(data) && (
                <Sidekick
                    id={data.id}
                    data={data}
                />
            );
        case 'SUB_NAVIGATION':
            return (
                <SubNavigation
                    id={data.id}
                    data={data}
                />
            );
        case 'TOOLS':
            return Tools.areAttributesValid(data) && (
                <Container>
                    <Tools
                        id={data.id}
                        data={data}
                    />
                </Container>
            );
        case 'IMAGE':
            if (SageNote.areAttributesValid(data.tipTitle, data.tipSubTitle)) {
                return ImageComponent.areAttributesValid(data) && (
                    <Container>
                        <SageNote
                            id={data.id}
                            key={data.id}
                            component={(
                                <ImageComponent
                                    data={data}
                                    id={data.id}
                                />
                            )}
                            title={data.tipTitle}
                            description={data.tipSubTitle}
                        />
                    </Container>
                );
            }
            return ImageComponent.areAttributesValid(data) && (
                <StyledContainerWithoutSageNote>
                    <ImageComponent
                        data={data}
                        id={data.id}
                    />
                </StyledContainerWithoutSageNote>);
        case 'COLLECTIONS':
            return (
                <StyledCollectionContainer>
                    <GuideCollections id={data.id} collections={data.collections} />
                </StyledCollectionContainer>
            );
        default:
            return null;
    }
};

const GuideComponentSwitch = ({ type, index, ...other }) => {
    const component = renderComponent(type, other);
    return component ? (
        <StyledComponentsContainer
            className={`guide-component-${index} ${type.toLowerCase()}`}
            isImage={type === 'IMAGE'}
        >
            {component}
        </StyledComponentsContainer>
    ) : null;
};

GuideComponentSwitch.propTypes = {
    type: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
    urlHistoryList: state.UrlHistoryReducer.history
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(GuideComponentSwitch);