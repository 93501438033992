import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { CheckmarkIcon, CrossIcon } from 'components/atomics/atoms/Icons';
import { Button, Heading } from 'components';
import {
    quizQuestionContainer,
    quizTopicStyles,
    quizOptionStyles,
    quizOptionIconStyles,
    quizWrapperStyles,
    quizOptionsContainerStyles,
    quizQuestionStyles,
    quizAnswerContainerStyles,
    explanationContainer,
    answerStyles,
    loadingexplanationStyles,
    explanationStyles,
    quizContainerStyles,
    optionsPercentage,
} from 'containers/Guide/Quiz/styles';
import { QUIZ, POLL } from 'state-management/constants/guide';
import { savePollAnswer as savePollAnswerAction } from 'state-management/actions/guide';
import { postHomepagePoll as postHomepagePollAction } from 'state-management/actions/homepage.common';
import { contextHubRecordEvent } from '../../../utils/contextHub';

const StyledQuizWrapper = styled.fieldset`
    ${quizWrapperStyles}
`;

const StyledQuizContainer = styled.div`
    ${quizContainerStyles}
`;

const StyledQuizQuestionContainer = styled.div`
    ${quizQuestionContainer}
`;

const StyledQuizTopic = styled(Heading)`
    ${quizTopicStyles}
`;

const StyledQuizQuestion = styled.legend`
    ${quizQuestionStyles}
`;

const StyledQuizOptionsContainer = styled.div`
    ${quizOptionsContainerStyles}
`;

const StyledQuizOption = styled(Button)`
    ${quizOptionStyles}
`;

const StyledQuizOptionIcon = styled.span`
    ${quizOptionIconStyles}
`;

const StyledQuizAnswerContainer = styled.footer`
    ${quizAnswerContainerStyles}
`;

const StyledAnswer = styled.p`
    ${answerStyles}
`;

const StyledExplanationContainer = styled.div`
    ${explanationContainer}
`;

const LoadingExplanationStyles = styled.p`
    ${loadingexplanationStyles}
`;

const StyledExplanationText = styled.p`
    ${explanationStyles}
`;

const StyledOptionsPercentage = styled.span`
    ${optionsPercentage}
`;

/**
 * Renders a quiz for guide page.
 */
export class Quiz extends React.Component {
    /**
     * Default constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        const { type, selectedOption, options } = this.props.data;

        const option = options.find(o => o.id === selectedOption);
        const selectedOptionId = (option && option.id) || null;

        this.state = {
            selectedOptionId,
            showResults: type === POLL && selectedOptionId,
        };
    }

    /**
     * Initialize state at mount.
     */
    componentDidMount() {
        if (this.props.data.selectedOption) {
            this.setState({
                selectedOptionId: this.props.data.selectedOption,
                showResults: true,
            });
        }
    }

    /**
     * Update state on prop updates.
     * @param {*} nextProps 
     */
    componentWillReceiveProps(nextProps) {
        if (this.props.data.optionPercentages && this.props.data.optionPercentages.length) {
            this.setState({
                selectedOptionId: this.props.data.selectedOption || window.optionId,
                showResults: true,
            });
        }
    }

    /**
     * Render loading state.
     */
    renderLoadingText = () => {
        const { optionPercentages } = this.props.data;
        const isLoaded = optionPercentages && optionPercentages.length > 0;
        if (!isLoaded) {
            return (
                <LoadingExplanationStyles>
                    <FormattedMessage
                        id="guidePage.poll.loadingText"
                    />
                </LoadingExplanationStyles>
            );
        }

        return null;
    };

    /**
     * Render quiz results.
     */
    renderExplanationText = () => {
        const { optionPercentages } = this.props.data;
        const isLoaded = (optionPercentages && optionPercentages.length > 0) || this.props.pollResponse.length;
        if (isLoaded) {
            return (
                <StyledExplanationText>
                    <FormattedMessage
                        id="guidePage.poll.resultExplanation"
                    />
                </StyledExplanationText>
            );
        }

        return null;
    };

    /**
     * Handle quiz option selections.
     * @param {*} optionId 
     */
    selectOption(optionId) {
        const {
            data,
            savePollAnswer,
            useHomepage,
            postHomepagePoll,
        } = this.props;

        window.optionId = optionId;

        if (!this.state.showResults) {

            this.setState({
                showResults: true,
                selectedOptionId: optionId,
            });

            if (useHomepage) {
                postHomepagePoll({ optionId, id: data.id });
            } else {
                savePollAnswer(data.id, optionId);
            }
            // }
        }
        var item = this.props.data.options.find(item => item.id === optionId);
        this.CH_handleComponentEventList(item);
    }

    //analytics 
    CH_handleComponentEventList = (item) => {
        var tracking =
        {
            "type": "pollButton",
            "action": 'Button:' + this.props.data.type + ":" + this.props.data.question + " : " + item.option,
        };
        contextHubRecordEvent(tracking);
    };

    /**
     * Render quiz choice options.
     */
    renderOptions() {
        const {
            id,
            options,
            type,
            optionPercentages,
        } = this.props.data;
        const {
            selectedOptionId,
            showResults,
        } = this.state;

        const isEven = options.length % 2 === 0;
        const isPoll = type === POLL;
        const isQuiz = type === QUIZ;
        return (
            <StyledQuizOptionsContainer>
                {options.map((option, index) => {
                    let className = '';
                    const selected = selectedOptionId === option.id;
                    const incorrect = showResults && !option.correct && isQuiz && selected;
                    const correct = showResults && (option.correct || (isPoll && selected));

                    if (selectedOptionId && (selected || option.correct)) {
                        if (isPoll) {
                            className = 'correct';
                        } else if (isQuiz) {
                            className = option.correct ? 'correct' : 'incorrect';
                        }
                    }

                    return (
                        <StyledQuizOption
                            id={`quiz-option-${id}-${option.id}`}
                            key={option.id}
                            isEven={isEven}
                            showCorrect={correct}
                            incorrectSelection={incorrect}
                            className={className}
                            readOnly={showResults}
                            showHover={!showResults}
                            onClick={() => this.selectOption(option.id)}
                        >
                            {incorrect && isQuiz && (
                                <StyledQuizOptionIcon>
                                    <CrossIcon />
                                </StyledQuizOptionIcon>
                            )}
                            {correct && isQuiz && (
                                <StyledQuizOptionIcon>
                                    <CheckmarkIcon />
                                </StyledQuizOptionIcon>
                            )}
                            {this.state.selectedOptionId && isPoll && (
                                <StyledOptionsPercentage>
                                    {optionPercentages[index]}
                                    {(optionPercentages.length > 0) ? '%' : ''}
                                </StyledOptionsPercentage>
                            )}
                            {option.option}
                        </StyledQuizOption>
                    );
                })}
            </StyledQuizOptionsContainer>
        );
    }

    /**
     * Render a quiz for home page usage (outside guide scope).
     * TODO: refactor quiz to sit abstract from guide, or move this code to a unique homepage component.
     */
    renderPollHomePageSuccessOptions() {
        const {
            id,
            options,
        } = this.props.data;

        const optionPercentages = this.props.pollResponse;
        const isEven = options.length % 2 === 0;

        return (
            <StyledQuizOptionsContainer>
                {options.map((option, index) => {
                    let className = '';
                    const selected = window.optionId === option.id;
                    const correct = option.correct || selected;

                    if (window.optionId && (selected || option.correct)) {
                        className = 'correct';
                    }

                    return (
                        <StyledQuizOption
                            id={`quiz-option-${id}-${option.id}`}
                            key={option.id}
                            isEven={isEven}
                            showCorrect={correct}
                            className={className}
                            readOnly
                            showHover={false}
                        >
                            {window.optionId && (
                                <StyledOptionsPercentage>
                                    {optionPercentages[index]}
                                    {(optionPercentages.length > 0) ? '%' : ''}
                                </StyledOptionsPercentage>
                            )}
                            {option.option}
                        </StyledQuizOption>
                    );
                })}
            </StyledQuizOptionsContainer>
        );
    }

    /**
     * Render this and underlying components.
     */
    render() {
        if (!this.props.data.type) {
            this.props.data.type = 'POLL';
        }


        const {
            options,
            question,
            type,
            title,
            noteHeader,
        } = this.props.data;

        const {
            selectedOptionId,
            showResults,
        } = this.state;

        const isPoll = type === POLL;
        const isQuiz = type === QUIZ;
        const isCentered = (!title && !noteHeader) || title.length === 1;
        const prefix = isPoll ? 'guidePage.poll.topicPrefix' : 'guidePage.quiz.topicPrefix';

        //const selectedOption = options.find(o => o.id === selectedOptionId);
        // const showPollHomeResults = (isPoll && this.props.useHomepage && this.props.data.optionPercentages.length);
        const showPollHomeResults = (isPoll && this.props.useHomepage && this.props.pollResponse.length);
        const selectedOption = (showPollHomeResults) ? window.optionId : options.find(o => o.id === selectedOptionId);

        return (
            Quiz.areAttributesValid(this.props.data) && (
                <StyledQuizWrapper
                    position={{ isCentered }}
                    variant={{ isPoll }}
                >
                    <StyledQuizContainer
                        position={{ isCentered }}
                        variant={{ isPoll }}
                    >
                        <StyledQuizQuestionContainer>
                            <StyledQuizTopic level={3}>
                                <FormattedMessage id={prefix} />
                            </StyledQuizTopic>
                            <StyledQuizQuestion>
                                {question}
                            </StyledQuizQuestion>
                            {showPollHomeResults
                                ? this.renderPollHomePageSuccessOptions()
                                : this.renderOptions()
                            }
                        </StyledQuizQuestionContainer>
                        {(showPollHomeResults || showResults) && (
                            <StyledQuizAnswerContainer>
                                <StyledAnswer>
                                    {selectedOption && (isQuiz ? selectedOption.confirmation
                                        : null)}
                                </StyledAnswer>
                                <StyledExplanationContainer>
                                    {selectedOption && (isPoll ? this.renderExplanationText()
                                        : selectedOption.explanation)}
                                    {/* {selectedOption && (isPoll ? this.renderLoadingText()
                                        : null)} */}
                                </StyledExplanationContainer>
                            </StyledQuizAnswerContainer>
                        )}
                    </StyledQuizContainer>
                </StyledQuizWrapper>
            ));
    }
}

Quiz.areAttributesValid = ({ question, options }) => question && options.length !== 0;

Quiz.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        noteHeader: PropTypes.string,
        title: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            correct: PropTypes.bool,
            title: PropTypes.string,
            confirmation: PropTypes.string,
            explanation: PropTypes.string,
        })).isRequired,
        question: PropTypes.string,
        selectedOption: PropTypes.number,
        optionPercentages: PropTypes.arrayOf(PropTypes.number),
        type: PropTypes.string,
    }).isRequired,
    savePollAnswer: PropTypes.func.isRequired,
    // if this component was used on homepage
    postHomepagePoll: PropTypes.func.isRequired,
    useHomepage: PropTypes.bool,
    pollResponse: PropTypes.arrayOf(Number),
};

Quiz.defaultProps = {
    useHomepage: false,
    pollResponse: [],
};

const mapDispatchToProps = dispatch => ({
    savePollAnswer: (pollId, optionId) => dispatch(savePollAnswerAction(pollId, optionId)),
    postHomepagePoll: parameters => dispatch(postHomepagePollAction(parameters)),
});

const mapStateToProps = state => ({
    pollResponse: state.homepage.pollResponse,
});

export default connect(mapStateToProps, mapDispatchToProps)(Quiz);