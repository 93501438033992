import { SET_ADVISOR_CLASSIFICATIONS } from 'state-management/constants/advisorClassifications';

const initialState = {
    aums: [],
    amounts: [],
    durations: [],
    serviceOfferings: [],
    isLoaded: false,
    wmAum: [],
    wmHouseHold:[],
    retirementAum: [],
    retirementPlans:[],
};

function advisorClassificationsReducer(state = initialState, action = {}) {
    switch (action.type) {
    case SET_ADVISOR_CLASSIFICATIONS:
        return {
            ...state,
            ...action.data,
            isLoaded: true,
        };
    default:
        return state;
    }
}

export default advisorClassificationsReducer;
