import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
    sidekickImageStyles,
    sidekickWrapperStyles,
    sidekickContainerStyles,
    sidekickContentWrapperStyles,
    sidekickContentContainerStyles,
    sidekickContentHeadingStyles,
    sidekickContentStyles,
    sidekickFooterStyles,
    cardContainerStyles,
    cardWrapperContainerStyles,
    cardHeaderStyles,
    cardBodyStyles,
    sidekickSectionContainerStyles,
} from 'components/Sidekick/styles';

import Heading from 'components/Heading/Heading';
import CoverImage from 'components/CoverImage/CoverImage';
import { Container, SanitizedHTML } from 'components';
import FilterBlue from 'components/Filters/BlueOverlay';

const StyledSidekickImage = styled.figure`
    ${sidekickImageStyles}
`;

const StyledSidekickWrapper = styled.section`
    ${sidekickWrapperStyles}
`;

const StyledSidekickSectionContainer = styled(Container)`
    ${sidekickSectionContainerStyles}
`;

const StyledSidekickContainer = styled.div`
    ${sidekickContainerStyles}
`;

const StyledSidekickContentWrapper = styled.div`
    ${sidekickContentWrapperStyles}
`;

const StyledSidekickContentContainer = styled.div`
    ${sidekickContentContainerStyles}
`;

const StyledSidekickHeading = styled(Heading)`
    ${sidekickContentHeadingStyles}
`;

const StyledSidekickContent = styled(SanitizedHTML)`
    ${sidekickContentStyles}
`;

const StyledSidekickFooter = styled.footer`
    ${sidekickFooterStyles}
`;

const StyledCardContainer = styled.article`
    ${cardContainerStyles}
`;

const StyledCardWrapperContainer = styled.div`
    ${cardWrapperContainerStyles}
`;

const StyledCardHeader = styled(Heading)`
    ${cardHeaderStyles}
`;

const StyledCardBody = styled(SanitizedHTML)`
    ${cardBodyStyles}
`;

class Sidekick extends Component {
    state = {
        showCoverImage: true,
    };

    handleCoverImageLoadError = () => {
        this.setState({
            showCoverImage: false,
        });
    };

    render() {
        const { id, data } = this.props;
        const imageAsset = this.state.showCoverImage ? this.props.data.imageAsset : null;
        return (
            Sidekick.areAttributesValid(data) && (
                <StyledSidekickWrapper
                    id={`sidekick-${id}`}
                    imageAsset={`/api${imageAsset}`}
                >
                    <StyledSidekickSectionContainer>
                        <StyledSidekickContainer>
                            {imageAsset && (
                                <StyledSidekickImage>
                                    <CoverImage
                                        src={`/api${imageAsset}`}
                                        onLoadError={this.handleCoverImageLoadError}
                                        filter={<FilterBlue id="filter-blue" />}
                                        filterId="filter-blue"
                                        forGuidePage={true}
                                    />
                                </StyledSidekickImage>
                            )}
                            <StyledSidekickContentWrapper
                                imageAsset={`/api${imageAsset}`}
                            >
                                <StyledSidekickContentContainer
                                    imageAsset={`/api${imageAsset}`}
                                >
                                    <StyledSidekickHeading
                                        appearance={1}
                                        level={2}
                                    >
                                        {data.factTitle}
                                    </StyledSidekickHeading>
                                    <StyledSidekickContent
                                        footer={data.source}
                                        htmlContent={data.factContent}
                                        tag="div"
                                        title={data.factTitle}
                                    />
                                </StyledSidekickContentContainer>
                                {data.source && (
                                    <StyledSidekickFooter
                                        imageAsset={`/api${imageAsset}`}
                                    >
                                        {data.source}
                                    </StyledSidekickFooter>
                                )}
                            </StyledSidekickContentWrapper>
                        </StyledSidekickContainer>
                        <StyledCardContainer>
                            <StyledCardWrapperContainer>
                                <StyledCardHeader
                                    level={3}
                                >
                                    <SanitizedHTML
                                        htmlContent={data.cardTitle}
                                    />
                                </StyledCardHeader>
                                <StyledCardBody
                                    htmlContent={data.cardContent}
                                    tag="div"
                                />
                            </StyledCardWrapperContainer>

                        </StyledCardContainer>
                    </StyledSidekickSectionContainer>
                </StyledSidekickWrapper>
            ));
    }
}

Sidekick.areAttributesValid = ({ factContent, cardTitle, cardContent }) => (
    factContent && cardTitle && cardContent
);

Sidekick.propTypes = {
    data: PropTypes.shape({
        factTitle: PropTypes.string,
        factContent: PropTypes.string,
        source: PropTypes.string,
        imageAsset: PropTypes.string,
        cardContent: PropTypes.string,
        cardTitle: PropTypes.string,
    }).isRequired,
    id: PropTypes.string.isRequired,
};

export default Sidekick;