import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { findIndex } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
    KEY_DOWN, KEY_ENTER, KEY_ESCAPE, KEY_SPACE, KEY_UP,
} from 'utils/keyCodes';
import DropdownOption from 'components/atomics/molecules/Dropdown/DropdownOption';
import {
    dropdownWrapperStyles,
    dropdownValueStyles,
    dropdownOptionsStyles,
    dropdownEmptyOptionStyles,
    dropdownClearOptionStyles,
    dropdownLabelStyles,
    dropdownIconStyles,
    dropdownEmptyIconStyles,
    dropdownWrapperOptionsStyles,
} from '../../../../components/atomics/molecules/Dropdown/styles';
import { IconArrowDown } from 'components/atomics/atoms/Icons/Icons';

const DROPDOWN_EMPTY_OPTION = 'dropdown-empty-option-key';
const DROPDOWN_CLEAR_OPTION = 'dropdown-clear-option-key';

const StyledDropdownWrapper = styled.div`
    ${dropdownWrapperStyles}
`;

const StyledDropdownWrapperOptions = styled.div`
    ${dropdownWrapperOptionsStyles}
`;

const StyledDropdownIcon = styled(IconArrowDown)`
    ${dropdownIconStyles}
`;

const StyledDropdownEmptyIcon = styled(IconArrowDown)`
    ${dropdownEmptyIconStyles}
`;

export const StyledDropdownOptions = styled.ul`
    ${dropdownOptionsStyles}
`;

export const StyledDropdownValue = styled.div`
    ${dropdownValueStyles}
`;

export const StyledDropdownEmptyOption = styled.li`
    ${dropdownEmptyOptionStyles}
`;

export const StyledDropdownClearOption = styled.li`
    ${dropdownClearOptionStyles}
`;

export const StyledDropdownLabel = styled.div`
    ${dropdownLabelStyles}
`;

/**
 * Creates a drop-down form control.
 */
class Dropdown extends React.Component {
    /**
     * Default constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = { isOpen: false };
        this.dropdownRef = React.createRef();
        this.selectedValueRef = React.createRef();
        this.dropdownItemRefs = new Map();

        this.handleWrapperKeyPress = this.handleWrapperKeyPress.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    /**
     * Get the index of the focused menu item.
     */
    getFocusIndex = (key) => {
        const {
            emptyOptionLabel,
            multiSelect,
            options,
            showClearAll,
            title,
        } = this.props;
        const index = {};
        const availableItems = options && [...options];

        if (emptyOptionLabel || title) {
            availableItems.unshift({ key: DROPDOWN_EMPTY_OPTION });
        }

        if (multiSelect && showClearAll) {
            availableItems.push({ key: DROPDOWN_CLEAR_OPTION });
        }

        const currentIndex = findIndex(availableItems, item => item.key === key);

        if (currentIndex + 1 < availableItems.length) {
            index.nextIndex = availableItems[currentIndex + 1].key;
        } else {
            index.nextIndex = availableItems[0].key;
        }

        if (currentIndex - 1 >= 0) {
            index.previousIndex = availableItems[currentIndex - 1].key;
        } else {
            index.previousIndex = availableItems[availableItems.length - 1].key;
        }

        return index;
    };

    /**
     * Allow enter key to open/close dropdown.
     * @param {*} event 
     */
    handleWrapperKeyPress(event) {
        if (event.keyCode === 13) {
            this.setState({
                isOpen: !this.state.isOpen,
            })
        }
    }

    /**
     * Get the drop-down current title display.
     */
    getTitle = () => {
        const {
            multiSelect,
            options,
            title,
            value,
        } = this.props;

        let result;
        let selectedItemCounter = this.getSelectedCount();

        if (multiSelect) {
            result = title + " " + (selectedItemCounter != 0 ? ("(" + selectedItemCounter + ")") : "");
        } else {
            const selectedItem = options && options.find(x => x.key === value);

            if (selectedItem) {
                result = `${selectedItem.value}`;
            } else {
                result = title;
            }
        }

        return result;
    };

    /**
     * Get the count of selected items.
     */
    getSelectedCount = () => {
        const {
            options
        } = this.props;

        let selectedItemCounter = 0;

        options && options.forEach(x => {
            if (x.selected === true) {
                selectedItemCounter = selectedItemCounter + 1;
            }
        });

        return selectedItemCounter;
    }

    /**
     * Get the drop-down title for multi-select mode.
     */
    getTitleMultiSelect = () => {
        const {
            multiSelect,
            title,
            value,
        } = this.props;

        let result;

        if (multiSelect) {
            result = "";
        } else {
            result = value ? '' : this.props.title
        }
        return result;
    }

    /**
     * Handle key press events.
     */
    handleSelectedValueKeyPress = (event) => {
        const keyCode = event.keyCode || event.which || 0;

        if (keyCode === KEY_ENTER || keyCode === KEY_SPACE || keyCode === KEY_DOWN) {
            event.preventDefault();
            this.toggleDropdown();
        }
    };

    /**
     * Handle clicks of empty option (reset selections).
     */
    handleEmptyOptionClick = () => {
        if (this.props.multiSelect) {
            this.toggleDropdown();
        } else {
            this.onSelect(undefined);
        }
    };

    /**
     * Handle movement keys and escape in drop-down.
     */
    handleDropdownOptionKeyDown = key => (event) => {
        const keyCode = event.keyCode || event.which || 0;
        const { previousIndex, nextIndex } = this.getFocusIndex(key);

        switch (keyCode) {
            case KEY_ESCAPE:
                event.preventDefault();
                this.collapse();
                this.selectedValueRef.current.focus();
                break;
            case KEY_DOWN:
                event.preventDefault();
                this.dropdownItemRefs.get(nextIndex).focus();
                break;
            case KEY_UP:
                event.preventDefault();
                this.dropdownItemRefs.get(previousIndex).focus();
                break;

            // no default
        }
    };

    /**
     * Handle empty select by key press.,
     */
    handleEmptyOptionKeyPress = (event) => {
        const keyCode = event.keyCode || event.which || 0;

        if (keyCode === KEY_ENTER || keyCode === KEY_SPACE) {
            event.preventDefault();
            this.handleEmptyOptionClick();
        }
    };

    /**
     * Handle key selects of invalid items.
     */
    handleNotSelectableEmptyOptionKeyPress = (event) => {
        const keyCode = event.keyCode || event.which || 0;

        if (keyCode === KEY_ENTER || keyCode === KEY_SPACE) {
            event.preventDefault();
            this.collapse();
        }
    };

    /**
     * Clear all selections.
     */
    handleClearAll = () => {
        const { onValueChanged, value } = this.props;
        onValueChanged([], value);
    };

    handleClearAlOptionKeyPress = (event) => {
        const keyCode = event.keyCode || event.which || 0;

        if (keyCode === KEY_ENTER || keyCode === KEY_SPACE) {
            event.preventDefault();
            this.handleClearAll();
        }
    };

    /**
     * Handle ref to drop-down molecule.
     */
    handleDropdownOptionRef = key => (dropDownItem) => {
        this.dropdownItemRefs.set(key, dropDownItem);
    };

    /**
     * Handle user selections.
     */
    onSelect = (val) => {
        const { multiSelect, value, onValueChanged } = this.props;
        const previousValue = value;

        let currentValue;

        if (multiSelect) {
            if (previousValue) {
                currentValue = previousValue.includes(val)
                    ? previousValue.filter(x => x !== val)
                    : [...previousValue, val];
            } else {
                currentValue = val;
            }
        } else {
            currentValue = val;
        }

        if (currentValue !== previousValue) {
            onValueChanged(currentValue, previousValue);
        }

        if (!multiSelect) {
            this.collapse();
        }
    };

    /**
     * Handle element blur.
     */
    onElementBlur = (event) => {
        const node = this.dropdownRef.current;

        if (node !== event.target && !node.contains(event.target)) {
            this.toggleDropdown();
        }
    };

    /**
     * Open/close drop-down.
     */
    toggleDropdown = () => {
        const { isOpen } = this.state;
        if (isOpen) {
            this.collapse();
        } else {
            this.expand();
        }
    };

    /**
     * Open drop-down.
     */
    expand = () => {
        document.addEventListener('click', this.onElementBlur);

        this.setState({
            isOpen: true,
        }, () => {
            this.focusActiveOption();
        });
    };

    /**
     * Close drop-down.
     */
    collapse = () => {
        document.removeEventListener('click', this.onElementBlur);

        this.setState({
            isOpen: false,
        }, () => {
            this.selectedValueRef.current.focus();
        });
    };

    /**
     * Set focus to active drop-down option.
     */
    focusActiveOption = () => {
        const {
            emptyOptionLabel,
            multiSelect,
            options,
            title,
            value,
        } = this.props;

        const firstSelectedItem = (value) => {
            if (value !== undefined && value !== null) {
                options && options.find(x => (
                    multiSelect ? value.includes(x.key) : value === x.key))
            }
        };

        const defaultItemKey = (emptyOptionLabel || title)
            ? DROPDOWN_EMPTY_OPTION
            : options[0] && options[0].key;
        const selectedItemKey = firstSelectedItem ? firstSelectedItem.key : defaultItemKey;

        if (selectedItemKey) {
            this.dropdownItemRefs.get(selectedItemKey)
                .focus();
        }
    };

    /**
     * Determine if an item is already selected.
     */
    isOptionSelected = (item) => {
        const { multiSelect, value } = this.props;

        let result;

        if (value !== undefined && value !== null) {
            if (multiSelect === true) {
                result = item.selected;
            } else {
                result = value === item.key
            }
        }

        return result;
    };

    /**
     * Render the drop-down label.
     */
    renderLabel = () => {
        const { label, id, nullable, value, showLabel } = this.props;

        if (nullable === false || showLabel) {
            if (label) {
                return (
                    <StyledDropdownLabel
                        id={`${id}-label`}
                        data-rel="selectLabel"
                    >
                        {label}
                    </StyledDropdownLabel>
                );
            }
        } else {
            let labelVal = !value ? null : this.props.title;

            if (labelVal) {
                return (
                    <StyledDropdownLabel
                        id={`${id}-label`}
                        data-rel="selectLabel"
                    >
                        {labelVal}
                    </StyledDropdownLabel>
                );
            } else {
                return null;
            }
        }

        return null;
    };

    /**
     * Render active drop-down value.
     */
    renderDropdownValue = () => {
        const { isOpen } = this.state;
        const {
            ariaLabel,
            id,
            multiSelect,
            value,
            size,
            theme,
            appearance,
            label,
        } = this.props;
        const isPlaceholder = multiSelect || value == null;

        let labelledby;

        const dropdownValueProps = {};

        dropdownValueProps['multiSelect'] = multiSelect;
        dropdownValueProps['value'] = value != null ? value : 0;

        if (label) {
            labelledby = `${id}-label ${id}-selected-value`;
        } else {
            labelledby = `${id}-selected-value`;
        }

        if (ariaLabel) {
            dropdownValueProps['aria-label'] = ariaLabel;
        }

        return (
            <StyledDropdownValue
                {...dropdownValueProps}
                onClick={this.toggleDropdown}
                onKeyPress={this.handleSelectedValueKeyPress}
                role="combobox"
                data-rel="Select"
                aria-autocomplete="none"
                aria-labelledby={labelledby}
                aria-owns={`${id}-options`}
                aria-expanded={isOpen}
                ref={this.selectedValueRef}
                multiSelectValue={this.getTitleMultiSelect()}
                variant={{
                    isPlaceholder,
                    size,
                    theme,
                    appearance,
                }}
            >
                {/* <em>{value ? "" : this.props.title}</em> */}
                {/*  <em>{this.getTitleMultiSelect()}</em> */}
                <span id={`${id}-selected-value`}>{this.getTitle()}</span>
                <StyledDropdownIcon
                    variant={{
                        theme,
                        appearance,
                    }}
                />
            </StyledDropdownValue>
        );
    };

    /**
     * Render an empty/null option.
     */
    renderEmptyOption = () => {
        const {
            emptyOptionLabel,
            title,
            isEmptyOptionSelectable,
            appearance,
            id,
        } = this.props;

        if (emptyOptionLabel || title) {
            const emptyOptionValue = this.props.value && this.props.value >= 0 && this.props.options ?
                this.props.notitle == 'no' && this.props.value > 0 ? this.props.options[this.props.value - 1].value :
                    this.props.value === 0 ? this.props.options[this.props.value].value : this.props.options[this.props.value - 1].value
                : emptyOptionLabel || title
            return (
                <StyledDropdownEmptyOption
                    ref={this.handleDropdownOptionRef(DROPDOWN_EMPTY_OPTION)}
                    onClick={
                        isEmptyOptionSelectable
                            ? this.handleEmptyOptionClick
                            : this.collapse
                    }
                    onKeyPress={
                        isEmptyOptionSelectable
                            ? this.handleEmptyOptionKeyPress
                            : this.handleNotSelectableEmptyOptionKeyPress
                    }
                    onKeyDown={this.handleDropdownOptionKeyDown(DROPDOWN_EMPTY_OPTION)}
                    tabIndex="0"
                    role="option"
                    variant={{ appearance }}
                >
                    {/* emptyOptionLabel || title */}
                    {emptyOptionValue}
                    <StyledDropdownEmptyIcon />
                </StyledDropdownEmptyOption>
            );
        }

        return null;
    };

    /**
     * Render empty/null option for multi-select.
     */
    renderMultiSelecteEmptyOption = () => {
        const {
            emptyOptionLabel,
            title,
            isEmptyOptionSelectable,
            appearance,
            id,
        } = this.props;

        let selectedItemCounter = this.getSelectedCount();

        const result = title + " " + (selectedItemCounter != 0 ? ("(" + selectedItemCounter + ")") : "");
        if (emptyOptionLabel || title) {
            return (
                <StyledDropdownEmptyOption
                    tabIndex="0"
                    role="option"
                    variant={{ appearance }}
                >
                    {result || title}
                    <StyledDropdownEmptyIcon />
                </StyledDropdownEmptyOption>
            );
        }

        return null;
    };

    /**
     * Render all drop-down options.
     */
    renderOptions = () => {
        const { multiSelect, options, isNone } = this.props;
        const item = [{ id: 0, value: "None" }];
        
        let withNone = isNone != "no" && options ? [...item, ...options] : [...options];

        return (
            <StyledDropdownWrapperOptions>
                {
                    withNone.map((item, index) => (

                        <DropdownOption
                            title={this.props.title}
                            item={item}
                            key={index}
                            onSelect={this.onSelect}
                            onKeyDown={this.handleDropdownOptionKeyDown(item.key)}
                            isSelected={this.isOptionSelected(item)}
                            multiSelect={multiSelect}
                            currentTabName={this.props.currentTabName}
                        />
                    ))
                }
            </StyledDropdownWrapperOptions>
        )

    };

    /**
     * Render the clear all control.
     */
    renderClearAllOption = () => {
        const { multiSelect, showClearAll, options } = this.props;
        const isAnyItemSelected = options && options.findIndex(x => x.selected === true) >= 0 ? true : false;

        if (multiSelect && showClearAll && (isAnyItemSelected === true)) {
            return (
                <StyledDropdownClearOption
                    ref={this.handleDropdownOptionRef(DROPDOWN_CLEAR_OPTION)}
                    onClick={this.handleClearAll}
                    onKeyPress={this.handleClearAlOptionKeyPress}
                    onKeyDown={this.handleDropdownOptionKeyDown(DROPDOWN_CLEAR_OPTION)}
                    tabIndex="0"
                    role="option"
                >
                    <FormattedMessage id="dropdown.clearAll" />
                </StyledDropdownClearOption>
            );
        }

        return null;
    };

    /**
     * Handle on blur logic of dropdown control.
     * @param {*} e 
     */
    onBlur(e) {
        if (e.relatedTarget && e.relatedTarget.tagName !== "LI" && this.props.onBlur) {
            this.props.onBlur();
        }
    }

    /**
     * Render this and underlying elements.
     */
    render() {
        const {
            className,
            id,
            multiSelect,
        } = this.props;

        const { isOpen } = this.state;
        const dropdownOptionsProps = {};

        if (multiSelect) {
            dropdownOptionsProps['aria-multiselectable'] = true;
        }

        return (
            <StyledDropdownWrapper
                id={id}
                data-rel="Select-Wrapper"
                className={className}
                ref={this.dropdownRef}
                tabIndex={0}
                onBlur={this.onBlur}
                onKeyDown={this.handleWrapperKeyPress}
            >
                {this.renderLabel()}
                {this.renderDropdownValue()}
                <StyledDropdownOptions
                    {...dropdownOptionsProps}
                    id={`${id}-options`}
                    aria-hidden={!isOpen}
                    role="listbox"
                    variant={{ isOpen }}
                >

                    {multiSelect === true ? this.renderMultiSelecteEmptyOption() : this.renderEmptyOption()}


                    {this.renderClearAllOption()}
                    {this.renderOptions()}

                </StyledDropdownOptions>
            </StyledDropdownWrapper>
        );
    }
}

Dropdown.propTypes = {
    ariaLabel: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string,
    title: PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.string,
    ]),
    label: PropTypes.string,
    emptyOptionLabel: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ])),
    ]),
    options: PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.shape({}),
            PropTypes.string,
        ])),
    ]),
    multiSelect: PropTypes.bool,
    showClearAll: PropTypes.bool,
    onValueChanged: PropTypes.func,
    isEmptyOptionSelectable: PropTypes.bool,
    size: PropTypes.oneOf([
        'medium',
    ]),
    theme: PropTypes.oneOf([
        'dark',
    ]),
    appearance: PropTypes.oneOf([
        'criteria',
    ]),
    showLabel: PropTypes.bool,
    currentTabName: PropTypes.string,
    onBlur: PropTypes.func,
};

Dropdown.defaultProps = {
    ariaLabel: '',
    className: '',
    id: undefined,
    title: undefined,
    label: undefined,
    emptyOptionLabel: undefined,
    value: undefined,
    options: [],
    multiSelect: false,
    showClearAll: false,
    isEmptyOptionSelectable: true,
    size: null,
    theme: null,
    appearance: null,
    onValueChanged: () => { },
    showLabel: false,
    currentTabName: '',
    onBlur: () => {},
};

export default injectIntl(Dropdown);
