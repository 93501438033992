export const DISCLAIMER_STANDARD = 'standard-disclaimer';
export const DISCLAIMER_SECONDARY = 'secondary-disclaimer';
export const DISCLAIMER_TERTIARY = 'third-disclaimer';
export const DISCLAIMER_NONE = 'none';

export const DISCLAIMER_URL = 'content-service/content/common/public/v2';

export const GET_DISCLAIMER_FRAGMENT = 'globalDisclaimer/SET_DISCLAIMER_FRAGMENT';
export const GET_DISCLAIMER_FRAGMENT_SUCCESS = 'globalDisclaimer/SET_DISCLAIMER_FRAGMENT_SUCCESS';
export const GET_DISCLAIMER_FRAGMENT_FAIL = 'globalDisclaimer/SET_DISCLAIMER_FRAGMENT_FAIL';
export const SET_DISCLAIMER_TYPE = 'globalDisclaimer/SET_DISCLAIMER_TYPE';
