import {
    all, put, takeLatest, call,
} from 'redux-saga/effects';

import { generateError } from 'state-management/bridge/error';
import { setScrollToTop } from 'state-management/actions/scroll';
import {
    GET_HOMEPAGE_DATA,
    POST_HOMEPAGE_POLL,
    GET_HOMEPAGE_POLL_DATA,
    HOMEPAGE_DATA_URL,
    HOMEPAGE_POLL_URL,
    HOMEPAGE_POLL_DATA_URL,
} from 'state-management/constants/homepage.common';
import {
    getHomepageFailure,
    getHomepageSuccess,
    postHomepagePollSuccess,
    postHomepagePollFailure,
    getHomepagePollDataSuccess,
    getHomepagePollDataFailure,
    getHomepagePollData as getHomepagePollDataAction,
} from 'state-management/actions/homepage.common';
import Api from '../bridge/api';

/**
 * Get homepage data.
 */
function* getHomepageData() {
    try {
        const homepageData = yield call(Api.get, HOMEPAGE_DATA_URL);
        yield put(getHomepageSuccess(homepageData.data.components));
        yield put(setScrollToTop());
    } catch (e) {
        yield put(getHomepageFailure(e));
        //yield put(generateError('homepage.server.error', e));
    }
}

/**
 * Handle homepage poll result clicks.
 * @param {*} rest
 */
function* postHomepagePoll(rest) {
    const parameters = { optionId: rest.parameters.optionId, code: 'home-page', pollId: rest.parameters.id };

    try {
        const response = yield call(Api.post, HOMEPAGE_POLL_URL, {
            data: parameters,
        });
        yield put(postHomepagePollSuccess(response.data));
        yield put(getHomepagePollDataAction(parameters));
    } catch (e) {
        yield put(postHomepagePollFailure(e));
        //yield put(generateError('homepage.post.poll.error', e));
    }
}

/**
 * Get homepage poll data.
 * @param {*} rest
 */
function* getHomepagePollData(rest) {
    try {
        const response = yield call(Api.post, HOMEPAGE_POLL_DATA_URL, {
            data: rest.parameters,
        });
        yield put(getHomepagePollDataSuccess(response.data));
    } catch (e) {
        yield put(getHomepagePollDataFailure(e));
        //yield put(generateError('homepage.get.poll.error', e));
    }
}

function* guideSaga() {
    yield all([
        takeLatest(GET_HOMEPAGE_DATA, getHomepageData),
        takeLatest(POST_HOMEPAGE_POLL, postHomepagePoll),
        takeLatest(GET_HOMEPAGE_POLL_DATA, getHomepagePollData),
    ]);
}

export default guideSaga;
