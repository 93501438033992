import { css } from 'styled-components';

import { SMALL, MEDIUM, LARGE } from 'components/Container/Container';

import { CONTAINER_WIDTH, GUTTER, getSpanInPixels } from 'utils/grid';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';

const mobileSpacing = 16;
const tabletSpacing = (GUTTER.tablet / 2) + getSpanInPixels(1, false, 'tablet');
const desktopSpacing = GUTTER.desktop;

export const spacingStyles = css`
    ${props => props.noMobilePadding === 'false' && css`
        padding-left: ${mobileSpacing}px;
        padding-right: ${mobileSpacing}px;
    `};

    @media screen and ${FROM_TABLET} {
        padding-left: ${tabletSpacing}px;
        padding-right: ${tabletSpacing}px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding-left: ${desktopSpacing}px;
        padding-right: ${desktopSpacing}px;
    }
`;

const getMaxWidth = (size) => {
    switch (size) {
    case LARGE:
        return 1440;
    case MEDIUM:
        return 1280;
    default:
        return CONTAINER_WIDTH.desktop;
    }
};

const containerStyles = css`
    margin-left: auto;
    margin-right: auto;
    max-width: ${props => getMaxWidth(props.variant.size || props.size)}px;
    position: relative;

    /* stylelint-disable */

    ${props => ((props.variant && props.variant.size === SMALL) || (props.size === SMALL)) && css`
        ${spacingStyles};
    `};

    ${props => ((props.variant && props.variant.size === MEDIUM) || (props.size === MEDIUM)) && css`
        ${spacingStyles};
    `};

    max-width: 1170px;

    /* stylelint-enable */
`;

export default containerStyles;
