import {
    CLEAR_ERRORS,
    PUT_ERROR,
    RESET_GLOBAL_ERROR,
    SET_FOCUS_ON_ERROR,
} from 'state-management/constants/errors';

export function setError(errorMessage, error = { status: null, location: null }) {
    return {
        type: PUT_ERROR,
        errorMessage,
        error,
    };
}

export function clearErrors() {
    return {
        type: CLEAR_ERRORS,
    };
}

export function resetCriticalError() {
    return {
        type: RESET_GLOBAL_ERROR,
    };
}

export function setFocusOnError(isFocused) {
    return {
        type: SET_FOCUS_ON_ERROR,
        isFocused,
    };
}
