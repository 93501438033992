import { css } from 'styled-components';
import {
    FROM_DESKTOP,
    FROM_TABLET,
} from 'utils/mediaQueries';
import { 
    FONT_AVENIR_NEXT,
    FONT_AVENIR_NEXT_BOLD,
    COLORS,
    RGB_COLORS,
    FONTS,
} from 'utils/variables';

export const navigationWrapperStyles = css`
    width: 100%;

    .title {
        margin: 0 0 5px 10px;
        text-transform: uppercase;
        font-family: ${FONTS.AVENIR_NEXT.FONT};
        font-size: 12px;
        margin-top: 25px;
    }

    .signOutbtn {
        margin: 0 auto;
        display: block;        
        font-size: 12px;
        margin-top: 35px;
        margin-bottom: 16px;        
        width: auto;
        margin-left: 0;
        margin-right: 0;
        
        @media screen and ${FROM_TABLET} {
            padding: 13px 24px;       
        }
        
        @media screen and ${FROM_DESKTOP} {
            margin: 35px 32px 16px;
        }
    }

    @media screen and ${FROM_DESKTOP} {
        max-width: 243px;
        width: 243px;
        margin: 76px 0 30px;

        .title {
            display: none;
        }
    }
`;

export const navigationListStyles = css`
    display: block;
    list-style: none;
    padding: 12px 0;
    margin: 0;
    padding: 10px 0;
    
    @media screen and ${FROM_DESKTOP} {
        background: ${COLORS.WHITE};
        box-shadow: 0 4px 20px 0 rgba(${RGB_COLORS.BLACK}, 0.16);
        min-width: 100%;
        padding: 24px 0;
    }
`;

export const navigationListItemStyles = css`
    position: relative;   
`;

export const navigationLinkStyles = css`
    height: 56px;
    line-height: 56px;
    border-bottom: 1px solid rgba(${RGB_COLORS.BLACK}, 0.16);
    margin-bottom: 0;
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 15px;

    @media screen and ${FROM_DESKTOP} {
        font-family: ${FONTS.AVENIR_NEXT.FONT};
        font-size: 15px;
        height: 45px;
        line-height: 45px;
        border-bottom: 0;
        margin-bottom: 8px;
    }

    &.last-item {
        border-bottom: 0;
        margin-bottom: 0;
    }

    
    padding-left: 10px;

    color: ${COLORS.BLACK};
    display: flex;
    font-weight: 500;
    text-decoration: none;

    &:active {
        background-color: ${COLORS.PALE_OCEAN};
    }

    @media screen and ${FROM_DESKTOP} {
        padding-left: 32px;

        &:hover {
            background-color: ${COLORS.PALE_OCEAN};
        }
    }

    @media screen and ${FROM_DESKTOP} {
        &.selected {
            background-color: ${COLORS.PALE_OCEAN};
            padding-left: 28px;
            border-left: 4px solid ${COLORS.SAPPHIRE};
        }
    }
`;


export const linkListIconStyles = css`
    bottom: 32%;
    color: ${COLORS.SAPPHIRE};
    position: absolute;
    right: 0;
    fill: ${COLORS.SAPPHIRE};

    @media screen and ${FROM_DESKTOP} {
        display: none;
    }
`;
