import { css } from 'styled-components';
import {
    COLORS,
    FONT_AVENIR_NEXT,
    ANIMATIONS,
} from '../../../../utils/variables';
import {
    FROM_DESKTOP,
    FROM_TABLET,
    SMALL_MOBILE
} from '../../../../utils/mediaQueries';

export const containerStyles = css`   
    background-color: ${COLORS.WHITE};
    border: 1px solid ${COLORS.LIGHT_GRAY2};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0;

    ${props => props.isConfirmation && css`
        padding-bottom: 30px;
    `};

    a {
        min-width: 244px;
        margin: 12px !important;
        letter-spacing: 0.6px;
        padding: 10px 0;    
    }
    
    a[type$="primary"]{
        padding: 19px 0;

        &:hover{
            color: ${COLORS.WHITE};
            background-color: ${COLORS.SAPPHIRE};
        }
    }

    a[type$="ghostBright"]{
        margin-bottom: 50px !important;
        padding: 0px;

        &:hover {
            color: ${COLORS.DARK_SAPPHIRE};
        }
    }

    @media screen and ${SMALL_MOBILE} {
        width: 345px;
        height: 395px;
    }

    @media screen and ${FROM_TABLET} {
        width: 537px;
        height: 337px;
    }
`;

export const titleStyles = css`
    font-family: ${FONT_AVENIR_NEXT};   
    color: ${COLORS.BLACK};
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 33px;
    letter-spacing: normal; 
    text-align: center;     
    margin: 56px auto 8px;    
    padding-bottom: 0;

    ${props => props.isConfirmation && css`
        margin-top: 48px;
    `};
`;

export const descriptionStyles = css`
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 23px;
    letter-spacing: 0.12px;
    text-align: center;
    color: ${COLORS.BLACK};   
    margin-bottom: 12px;

    @media screen and ${SMALL_MOBILE} {
        width: 297px;
        height: 125px;
    }

    @media screen and ${FROM_TABLET} {
        width:417px;   
        margin:0 12px 12px;
    }

`;

export const defaultLinkStyles = css`
`;
