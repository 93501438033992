import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_MOBILE_TO_TABLET, FROM_TABLET } from 'utils/mediaQueries';
import { StyledList } from 'components/CardsGrid/CardsGrid';
import { StyledPagerWrapper } from 'components/PagedList/Pager';
import { COLORS } from 'utils/variables';

export const clickContainerStyles = css`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0.1;
    background: transparent;
    z-index: 1;
`;

export const titleStyles = css`
    border-bottom: 2px solid ${COLORS.LIGHT_GRAY3};
    font-size: 24px;
    font-weight: 600;
    margin: 24px -2px 0;
    padding-bottom: 13px;

    @media screen and ${FROM_TABLET} {
        font-size: 32px;
        margin: 43px 0 26px;
        padding-bottom: 22px;
    }
`;

export const skinnyListContentCardContainerStyles = css`
    width: 100%;
    max-width: 800px;
    position: relative;
`;

export const pagedListWrapperStyles = css`
    /* stylelint-disable */

    ${StyledPagerWrapper} {
        margin-bottom: 40px;
        margin-top: 32px;

        @media screen and ${FROM_TABLET} {
            margin-bottom: 20px;
            margin-top: 16px;
        }
    }
    /* stylelint-enable */
`;

export const pagedTypedListWrapperStyles = css`
    /* stylelint-disable */

    ${pagedListWrapperStyles}

    @media screen and ${FROM_MOBILE_TO_TABLET} {
        ${StyledList}::before {
            display: none;
        }

        & + & ${StyledList}::before {
            display: block;
        }
    }

    ${StyledList}::before {
        content: '';
        background-image: linear-gradient(to top, ${COLORS.LIGHT_GRAY2} , ${COLORS.LIGHT_GRAY1});
        height: calc(100% - 210px);
        left: 0;
        margin-top: -79px;
        max-height: 544px;
        position: absolute;
        right: 0;
        z-index: -1;

        @media screen and ${FROM_TABLET} {
            margin-top: 74px;
        }
    }
`;

export const seeMoreButtonWrapStyles = css`
    margin-bottom: 50px;
    margin-top: 25px;
    text-align: center;

    @media screen and ${FROM_TABLET} {
        margin-bottom: 20px;
        margin-top: 0;
        text-align: right;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 50px;
        margin-top: -25px;
        text-align: right;
    }
`;
