import {
    all, put, select, takeEvery,
} from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import * as queryString from 'query-string';

import setQueryParams from 'state-management/actions/location';

/**
 * Handle location change search params.
 */
function* locationChanged() {
    const search = yield select(state => state.router.location.search);

    const queryParams = queryString.parse(search);

    yield put(setQueryParams(queryParams));
}

function* locationSaga() {
    yield all([
        takeEvery(LOCATION_CHANGE, locationChanged),
    ]);
}

export default locationSaga;
