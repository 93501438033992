import { css } from 'styled-components';
import {
    RGB_COLORS,
    COLORS,
    FONTS,
} from 'utils/variables';
import {
    FROM_TABLET,
    FROM_DESKTOP,
} from 'utils/mediaQueries';

export const liveTitleStyles = css`
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    color: ${COLORS.SAPPHIRE};
    font-weight: 400;
    font-size: 18px;
    white-space: nowrap;
    text-align: center;
    margin: 13px auto;
    padding-top: 10px;

    @media screen and ${FROM_TABLET} {
        padding-top: 30px;
        font-size: 26px;
        margin-bottom: -10px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding-top: 10px;
    }
`;

export const nearTitleStyles = css`
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    color: ${COLORS.SAPPHIRE};
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    margin: auto;
    padding-top: 10px;

    @media screen and ${FROM_TABLET} {
        white-space: nowrap;
        padding-top: 20px;
        font-size: 26px;
        margin-bottom: -10px;
        padding-bottom: 10px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding-top: 10px;
    }
`;

export const contentContainerStyles = css`
    margin: auto;
    max-width: 1170px;
    padding: 8px;
    display: flex;
    flex-direction: column;

    @media screen and ${FROM_TABLET} {
        padding: 10px;
    }

    @media screen and ${FROM_DESKTOP} {
        flex-direction: row;
    }
`;

export const bodyContainerStyles = css`
    background: linear-gradient(180deg, ${COLORS.DARK_SAPPHIRE} 60%, ${COLORS.LIGHT_GRAY1} 40%);
    padding: 18px 20px;

    @media screen and ${FROM_TABLET} {
        padding: 22px 20px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 53px 20px;
    }
`;

export const backgroundStyles = css`
`;

export const containerStyles = css`    
    padding: 10px;    
    color: ${COLORS.BLACK};
    box-shadow: 0 0 5px 0 rgba(${RGB_COLORS.BLACK}, 0.16);
    background-color: ${COLORS.WHITE};
    max-width: 1240px;
    margin: 0 auto;
`;

export const textContainerStyles = css`
    max-width: 520px;
    padding: 10px 0;
    text-align: center;

    @media screen and ${FROM_TABLET} {
        padding: 10px 10px 10px 33px;
        text-align: left;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 21px 30px 50px 33px;
    }
`;

export const titleStyles = css`
    font-family: AvenirNext;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;

    @media screen and ${FROM_TABLET} {
        font-size: 24px;
        padding-bottom: 10px;
    }
`;

export const textStyles = css`
    font-family: AvenirNext;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    max-width: 100%;
    display: none;

    @media screen and ${FROM_TABLET} {
        display: block;
    }
`;

export const rightSectionStyles = css`
    max-width: 374px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and ${FROM_TABLET} {
        width: auto;
    }

    @media screen and ${FROM_DESKTOP} {       
        margin: 0 0 0 auto;
    }

    ${props => props.isNear && css`
        div[data-rel="countdown"] {
            display: none;

            @media screen and ${FROM_TABLET} {
                display: block;
            }
        }
    `}
`;

export const leftSectionStyles = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and ${FROM_TABLET} {
        flex-direction: row;
    }

    @media screen and ${FROM_DESKTOP} {
        max-width: 700px;
    }
`;

export const buttonsContainerStyles = css`
    width: 100%;
    text-align: center;
    padding-top: 20px;

    a[type="type/primary"] {
        margin-bottom: 16px;
        min-width: 228px;
    }

    @media screen and ${FROM_TABLET} {
        a[type="type/primary"] {
            min-width: 338px;
        }
    }

    @media screen and ${FROM_DESKTOP} {
        padding-left: 0;
    }
`;

export const imageContainerStyles = css`
    height: 61px;
    overflow: hidden;
    width: 61px;

    @media screen and ${FROM_DESKTOP} {
        height: 129px;
        width: 129px;
    }

    img {
        object-fit: unset;
        transition: 0.33s all;
        width: 100%;
        height: 116px;
        max-width: 100%;

        @media screen and ${FROM_TABLET} {
            height: 135px;
            max-width: 135px;
        }
        @media screen and ${FROM_DESKTOP} {
            height: 186px;
            width: 186px;
            max-width: 186px;
        }

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            width: auto;
        }
    }
`;

export const dateContainer = css`
    color: ${COLORS.DARK_GRAY1};
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: -0.1px;
    font-weight: 500;
    padding-bottom: 9px;

    @media screen and ${FROM_DESKTOP} {
        padding-bottom: 16px;
    }
`;

export const toolsHeaderIconStyles = css`
    text-align: right;
    position: absolute;
    top: 12px;
    left: 14px;
    z-index: 1;

    svg {
        position: relative;
        text-align: right;
       
    }
    
    @media screen and ${FROM_TABLET} {
        top: 12px;
        left: 14px;
    }

    @media screen and ${FROM_DESKTOP} {
        top: 12px;
        left: 14px;
    }
`;

export const ImageHolderDivStyles = css`
    position:relative;
`;