import styled, { css } from 'styled-components';
import { getSpanInPercent } from 'utils/grid';
import { FROM_DESKTOP, FROM_MOBILE_TO_DESKTOP } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS } from 'utils/variables';

export const headerContainerStyles = css`
    position: relative;
    @media screen and ${FROM_DESKTOP} {
        min-height: 162px;
        padding-bottom: 56px;
        padding-top: 52px;
    }

    @media screen and ${FROM_MOBILE_TO_DESKTOP} {
        box-sizing: border-box;
        margin: 0 auto;
        padding: 50px 13%;
        text-align: center;
    }

    @media (max-width: 479px) {
        padding: 50px 16px;
    }
`;

export const sourceNameStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
    margin-bottom: 11px;
    text-align: center;

    @media (min-width: 480px) {
        margin-bottom: 13px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 11px;
        margin-top: 12px;
        text-align: left;
    }
`;

export const providerContainerStyles = css`
    color: ${COLORS.MEDIUM_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 12px;
    font-weight: 500;
    margin: 0 0 27px;

    @media (min-width: 480px) {
        margin: 0 0 37px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin: 0 0 25px;
    }
`;

export const titleStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 24px;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 20px;
    word-wrap: break-word;

    @media (min-width: 480px) {
        font-size: 36px;
        line-height: 1.28;
        margin-bottom: 31px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 36px;
        line-height: 1.28;
        margin-bottom: 22px;
        width: ${getSpanInPercent(10)};
    }
`;

export const dotStyles = css`
    margin: 0 5px;
`;

export const BackButtonContainer = styled.div`
 position: absolute;
 top: 0;
 left: 47px;
 
 @media screen and ${FROM_MOBILE_TO_DESKTOP} {
    position: absolute;
    top: 0;
    left: 30px;
 }
`;
