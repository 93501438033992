import { css } from 'styled-components';

import { getSpanInPercent } from 'utils/grid';
import { FROM_TABLET, FROM_DESKTOP } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS, ANIMATIONS } from 'utils/variables';

export const rowStyles = css`
    align-items: center;
    align-self: stretch;
    border-top: solid 0px ${COLORS.LIGHT_GRAY2};
    box-sizing: border-box;
    display: flex;
    margin: 0 0 30px;
    min-height: 70px;
    padding: 12px 0;
    width: 100%;

    &:first-child{
        border: 0;
    }

    @media screen and ${FROM_TABLET} {
        padding: 12px 0;
        margin-bottom: 47px;
        width: 50%;
        margin: 0 0 31px;
    }


    h3{
        display: none;
    }
    &[data-value="password"]{
        border-top: solid 1px ${COLORS.LIGHT_GRAY2};
        width: 100%;
        display: block;
        margin-top: 17px;

        @media screen and ${FROM_TABLET} {
            margin-top: 15px;
        }

        @media screen and ${FROM_DESKTOP} {
            margin-top: 7px;
        }

        h3{
            display: block;           
            margin-bottom: 33px;
            margin-top: 35px;

            @media screen and ${FROM_DESKTOP} {
                margin-top: 41px;
            }
        }
    }
`;

export const keyContainerStyles = css`
    color: ${COLORS.MEDIUM_GRAY2};
    display: inline-block;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 1.5;   
    word-wrap: break-word;
    text-transform: uppercase;


    @media screen and ${FROM_TABLET} {
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0.5px;
       
    }
`;

export const valueContainerStyles = css`
    color: ${COLORS.BLACK};
    display: inline-block;
    font-size: 18px;
    letter-spacing: -0.3px;
    line-height: 1.5;
    margin-top: 11px;
    margin-left: 0;
    width: 100%;
    word-wrap: break-word;
    font-weight: 400;
    text-transform: none;
    height:1.5em;


    @media screen and ${FROM_TABLET} {
        font-size: 18px;        
        width: 100%;
    }
`;


export const passwordHeaderStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-size: 12px;
    font-weight: 700;
    line-height: 1.78;
    word-wrap: break-word;
    text-transform: uppercase;
    margin-bottom: 0;

`;

export const containerStyles = css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 10px 0 75px;

    @media screen and ${FROM_TABLET} {
        margin: 20px 0 110px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin: 0;
    }
`;

export const linkStyles = css`
    color: ${COLORS.SAPPHIRE};
    transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;
    &:active, &:hover {
        color: ${COLORS.DARK_SAPPHIRE};
    }
`;

export const headerStyles = css`
    align-items: baseline;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 0.8em;
    margin-bottom: 20px;

    @media screen and ${FROM_TABLET} {
        margin-top: 20px;
        justify-content: normal;
        padding: 0;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-top: 50px;
        padding: 0;
    }
`;

export const footerStyles = css`
    font-size: 0.8em;
    margin-bottom: 20px;
    border-top: solid 1px ${COLORS.LIGHT_GRAY2};
`;

export const notificationStyles = css`
    margin: 10px 0 10px 0;
`;

export const notificationIconStyles = css`
    margin-right: 5px;
    path {
        fill: ${COLORS.SAPPHIRE};
    }
`;

export const notificationTextStyles = css`
    color: ${COLORS.SAPPHIRE};
`;
