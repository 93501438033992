import {
    AGREEMENT_UPDATE,
    GET_LEGAL_AGREEMENT,
    GET_LEGAL_AGREEMENT_FAILURE,
    GET_LEGAL_AGREEMENT_SUCCESS,
} from 'state-management/constants/legalAgreement.common';

export default function updateAgreement(accepted) {
    return {
        type: AGREEMENT_UPDATE,
        accepted,
    };
}

export const getLegalAgreement = () => ({
    type: GET_LEGAL_AGREEMENT,
});

export const getLegalAgreementSuccess = legalAgreement => ({
    type: GET_LEGAL_AGREEMENT_SUCCESS,
    legalAgreement,
});

export const getLegalAgreementFailure = () => ({
    type: GET_LEGAL_AGREEMENT_FAILURE,
});
