export const CLEAR_SECTION = 'searchFilter/CLEAR_SECTION';
export const CLEAR_ALL = 'searchFilter/CLEAR_ALL';
export const RESET_SECTION = 'searchFilter/RESET_SECTION';
export const SET_SECTION = 'searchFilter/SET_SECTION';
export const TOGGLE_DATE_ITEM = 'searchFilter/TOGGLE_DATE_ITEM';
export const TOGGLE_ITEM = 'searchFilter/TOGGLE_ITEM';
export const TOGGLE_OPEN = 'searchFilter/TOGGLE_OPEN';
export const APPLY_FILTERS = 'searchFilter/APPLY_FILTERS';
export const RESET_SEARCH_FILTER = 'searchFilter/RESET_SEARCH_FILTER';

export const SEE_MORE_NEWS = 'searchFilter/SEE_MORE_NEWS';
export const SEE_MORE_PRACTICE_MANAGEMENT = 'searchFilter/SEE_MORE_PRACTICE_MANAGEMENT';

export const TYPE_SECTION = 'type';
export const DATE_SECTION = 'date';
export const TOPICS_SECTION = 'topics';
export const SOURCES_SECTION = 'sources';

export const LAST_24_HOURS = 'LAST_24_HOURS';
export const PAST_5_DAYS = 'PAST_5_DAYS';
export const PAST_MONTH = 'PAST_MONTH';
export const ANY_TIME = 'ANY_TIME';
export const GUIDE = 'GUIDE';
export const ARTICLE = 'ARTICLE';

export const SOURCE_CAPITAL_GROUP = 'SOURCE_CAPITAL_GROUP';
export const SOURCE_HBR = 'SOURCE_HBR';
export const SOURCE_MC_KINSEY = 'SOURCE_MC_KINSEY';
export const SOURCE_PRACTICE_LAB = 'SOURCE_PRACTICE_LAB';
