import { css } from 'styled-components';
import {
    RGB_COLORS,
    COLORS,
    FONTS,
    ANIMATIONS,
} from 'utils/variables';
import {
    FROM_DESKTOP,
    FROM_TABLET,
    SMALL_MOBILE,
} from 'utils/mediaQueries';

export const kebabAndSourceContainerStyles = css`
    display: flex;
    position: absolute;
    bottom: 12px;
`;

export const overlayStyles = css`
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    top: 0;
`;

export const imageContainerStyles = css`
    cursor: pointer;
    display: block;
    overflow: hidden;
    @media screen and ${FROM_TABLET} {
       height: 88px;
    }

    @media screen and ${FROM_DESKTOP} {
        height: 156px;
     }

    &:before{
        content: "";
        width: 100%;
        height: 157px;
        background-color: rgba(${RGB_COLORS.SAPPHIRE}, 0.5);
        position: absolute;

        @media screen and ${FROM_TABLET} {
            height: 88px;
         }

        @media screen and ${FROM_DESKTOP} {
            height: 156px;
         }
    }

    img {
        height: 157px;
        margin-bottom: -4px;
        object-fit: cover;        
        width: 100%;

        @media screen and ${FROM_DESKTOP} {
            height: 156px;
         }

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            height: auto;
        }

        ${props => props.visited === 'true' && css`
            opacity: 0.4;
        `};
    }
`;

export const kebabContainerStyles = css`
    right: -10px;
    bottom: 5px;
    width: 36px;
    height:16px;
    text-align: right;
    transition: ${ANIMATIONS.ARTICLES.KEBAB.TIME.SECONDS}s all;
    opacity: 1;
    position: absolute;

    &.kebab-open {
        opacity: 1;
    }

    @media screen and ${SMALL_MOBILE} {
        right: 0;
    }

    @media screen and ${FROM_TABLET} {
        right: 0;
    }

    @media screen and ${FROM_DESKTOP} {
        right: 0;
        opacity: 0;
    }
`;

export const  playContainerStyles = css`
    text-align: center;
    position: absolute;
    top: 45px;    
    z-index: 1;
    width: 100%;

    @media screen and ${FROM_TABLET} {
        top: 9px;
    }

    @media screen and ${FROM_DESKTOP} {
        top: 45px;   

    }
`;

export const titleStyles = css`
    color: ${COLORS.BLACK};
    display: inline-block;
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.1px;
    margin: 0;
    margin-bottom: 8px;
    text-decoration: none;
    min-height:60px;
    overflow:hidden;
    transition: ${ANIMATIONS.ARTICLES.TITLE.TIME.SECONDS}s all;

    &:hover{
        color: ${COLORS.MEDIUM_GRAY2};
    }

    @media screen and ${FROM_TABLET} {
        min-height: 52px;
        margin-bottom: 7px;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: -0.1px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-top: 23px;
        font-size: 15px;
        line-height: 20px;
        min-height: 58px;
    }
`;

export const containerStyles = css`
    position: relative;
    border: 1px solid ${COLORS.LIGHT_GRAY2};
    display: inline-block;
    width: 296px;
    height:330px;
    text-decoration: none;
    background: ${COLORS.WHITE};
    
    &.news-not-visited{
        opacity:1;
    }

    &.news-visited{
        opacity:0.6;
    }

    @media screen and ${FROM_TABLET} {
        max-width: 213px;
        height: 274px;
    }

    @media screen and ${FROM_DESKTOP} {
        max-width: 272px;
        height:331px;
    }
    
`;

export const typeStyles = css`
    display: flex;
    font-size:12px;
    line-height:16px;
    letter-spacing:0.6px
    font-weight: 500;
    margin-bottom: 21px;
    text-transform: uppercase;

    @media screen and ${FROM_TABLET} {
        margin-bottom: 24px;
        font-size: 10px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 7px;
        font-size: 12px;
    }
`;

export const typeContainerStyles = css`
    padding-right: 0;
    white-space: nowrap;
    color: ${COLORS.DARK_GRAY1};

    @media screen and ${FROM_DESKTOP} {
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: 0.6px;
    }
`;

export const contentContainerStyles = css`
    padding: 12px 14px 11px 14px;

    @media screen and ${FROM_TABLET} {
        padding: 12px 13px 12px 13px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 12px 16px 12px 16px;
    }

`;

export const toolsHeaderIconStyles = css`
    text-align: right;
    position: absolute;
    top: 11px;
    left: 11px;
    z-index: 1;
`;
