import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { injectIntl, intlShape } from 'react-intl';
import { BreakpointsContext, DESKTOP } from 'components/Breakpoints/Breakpoints';
import PrimaryNavigation from 'components/atomics/molecules/PrimaryNavigation/PrimaryNavigation';
import {
    ConnectedBaseLayout,
    Logo,
    NavigationDrawer,
    SearchBar,
    SecondaryNavigation,
} from 'components';
import { primary, secondary } from 'containers/PrivateLayout/routes';
import {
    headerContainerStyles,
    headerNavContainerStyles,
    headerNavLogoWrapperStyles,
    headerNavPrimaryNavStyles,
    headerNavSecondaryNavStyles,
    mainContainerStyles,
    logoLinkStyles,
} from 'containers/PrivateLayout/styles';
import Api from '../../utils/api';
import { Link } from 'react-router-dom';
import { ROUTES } from 'containers/App/constants';
import storage from '../../utils/store';
import {
    FIRST_NAME,
} from '../../state-management/constants/signIn';

const StyledHeaderContainer = styled.div`
    ${headerContainerStyles};
`;

const StyledHeaderNavContainer = styled.div`
    ${headerNavContainerStyles};
`;

const StyledHeaderNavLogoWrapper = styled.div`
    ${headerNavLogoWrapperStyles};
`;

const StyledPrimaryNavWrapper = styled.div`
    ${headerNavPrimaryNavStyles};
`;

const StyledSecondaryNavWrapper = styled.div`
    ${headerNavSecondaryNavStyles};
`;

const StyledMainContainer = styled.div`
    ${mainContainerStyles};
`;

const StyledLogoLink = styled(Link)`
    ${logoLinkStyles};
`;

/**
 * Wrapper for all private pages (authenticated user required).
 * @param {*} param0 
 */
export const PrivateLayout = ({
    children, firstName, intl, notification
}) => {
    //mobile safari tap action grey outline fix
    document.addEventListener("touchstart", function(){}, true);

    const translate = id => intl.formatMessage({ id });
    const translateMenuTitles = routes => routes.map(route => ({
        ...route,
        title: translate(route.title),
    }));

    let page = (window.location.pathname).split("/");
    let pageName = page[1];

    const userFirstName = storage.get(FIRST_NAME);

    let logoLinkNew = pageName == 'sign-in' ? ROUTES.BASE :
        userFirstName == null ? ROUTES.BASE : ROUTES.HOME;

    const primaryRoutes = translateMenuTitles(primary);
    const secondaryRoutes = translateMenuTitles(secondary);
    const badge = notification.data.expiringBookmarksMainBadge;

    const NavigationContent = (breakpoint) => (
        <NavigationDrawer
            id="header-nav"
            badge={breakpoint !== DESKTOP ? badge : undefined}
        >
            <StyledHeaderNavContainer>
                <StyledHeaderNavLogoWrapper>
                    <StyledLogoLink
                        to={logoLinkNew}
                    >
                        <Logo />
                    </StyledLogoLink>
                </StyledHeaderNavLogoWrapper>
                <StyledPrimaryNavWrapper>
                    <PrimaryNavigation
                        ariaLabel={translate('privateLayout.navigation.primary')}
                        routes={primaryRoutes}
                    />
                </StyledPrimaryNavWrapper>
                <StyledSecondaryNavWrapper>
                    <SecondaryNavigation
                        ariaLabel={translate('privateLayout.navigation.secondary')}
                        routes={secondaryRoutes}
                        triggerTitle={firstName}
                        badge={badge}
                    />
                </StyledSecondaryNavWrapper>
            </StyledHeaderNavContainer>
        </NavigationDrawer>
    );

    const MainContent = (
        <StyledMainContainer>
            {children}
        </StyledMainContainer>
    );

    return (
        <BreakpointsContext.Consumer>
            {(breakpoint) => {
                const isMobileOrTablet = breakpoint !== DESKTOP;
                const HeaderContentAfter = (
                    <StyledHeaderContainer>
                        {!isMobileOrTablet && NavigationContent(breakpoint)}
                        <SearchBar />
                    </StyledHeaderContainer>
                );
                const HeaderContentBefore = isMobileOrTablet
                    ? (
                        <StyledHeaderContainer>
                            {NavigationContent(breakpoint)}
                        </StyledHeaderContainer>
                    ) : null;

                return (
                    <React.Fragment>
                        <ConnectedBaseLayout
                            headerContentAfter={HeaderContentAfter}
                            headerContentBefore={HeaderContentBefore}
                            mainContent={MainContent}
                            withClickableLogo
                        />
                    </React.Fragment>
                );
            }}
        </BreakpointsContext.Consumer>
    );
};

PrivateLayout.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    firstName: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
};

PrivateLayout.defaultProps = {
    children: null,
};

const mapStateToProps = state => ({
    firstName: state.basicProfile.firstName || Api.getFirstName(),
    optin: state.optin.optIn,
    notification: state.basicProfile.notification
});

export const ConnectedPrivateLayout = injectIntl(connect(mapStateToProps, null)(PrivateLayout));

function withPrivateLayout(Component) {
    return props => (
        <ConnectedPrivateLayout>
            <Component {...props} />
        </ConnectedPrivateLayout>
    );
}

export default withPrivateLayout;
