import { find } from 'lodash';
import storage from 'utils/store';
import ApiCore from './apicore';
import {
    SIGN_IN_URL,
    REFRESH_URL,
    LOGOUT_URL,
    CSRF_URL,
    FIRST_NAME,
} from 'state-management/constants/signIn';
import {
    IS_KEEP_SIGNED_IN,
    LOGOUT_TIME,
} from 'state-management/sagas/authentication';
import store from 'state-management/store/storeFactory';
import { setError } from "../state-management/actions/errors";
import { Api as newApi } from 'cg-common-util';
import { errorMap } from './errorMapping/map';
import { addToasts } from 'state-management/actions/toasts';
import { TYPES } from 'containers/Toasts/constants';

export const CSRF_TOKEN = 'csrf_token';
export const REFRESH_TOKEN = 'refresh_token';
export const ACCESS_TOKEN = 'access_token';
const NO_SESSION_FOUND_ERROR_CODE = 'error.security.no_session_found';
const FORBIDDEN_ERROR = 'error.security.forbidden';
const UNKNOWN_ERROR = 'global.errors.unknownError';
const INVALID_PASSWORD_ERROR_MESSAGE = 'Invalid current password';
const UNAUTHORIZED_ERROR_STATUS = 401;
const CSRF_ERROR_STATUS = 403;
export const SIGNOUT_URL = '/sign-out';

export const IS_LOGGED_OUT = 'userIsLoggedOut';

export const containsNoSessionFoundError = error => !!find(error.data,
    item => item.code === NO_SESSION_FOUND_ERROR_CODE);

export const containsForbiddenError = error => !!find(error.data,
    item => item.code === FORBIDDEN_ERROR);

export const containsUnknownError = error => !!find(error.data,
    item => item.code === UNKNOWN_ERROR);

export const isIgnored = () => {
    const isContacts = window.location.href.includes('/contact-us');
    const isOnboarding = window.location.href.includes('/onboarding/');
    const isWebinar = window.location.href.includes('/webinar');
    const isSplash = window.location.pathname === '/';

    return isContacts || isOnboarding || isWebinar || isSplash;
}

const service = new ApiCore();

/*const redirectNoTokenErrorPage = () => {
    const lastPage = window.location.pathname.slice(1);

    store.dispatch(setError('contactUs.errors.server.submit', { status: 403, location: lastPage }));
}

const redirectForbiddenErrorPage = () => {
    const lastPage = window.location.pathname.slice(1);

    store.dispatch(setError('global.errors.forbiddenError', { status: 403, location: lastPage }));
}*/

const redirectToServerErrorPage = () => {
    const lastPage = window.location.pathname.slice(1);

    store.dispatch(setError('global.errors.serverError', { status: 500, location: lastPage }));
}

/**
 * Determine if the error is critical or can be overlooked and return true/false according to it being fatal.
 * @param {string} url
 * @param {object} error //currently unused but may become important for .status usage.
 */
const isErrorFatal = (url, error) => {
    let isFatal = true;
    let isPageItem = true;

    if (!url.includes(errorMap.apiPrefix)) {
        url = errorMap.apiPrefix + url;
    }

    //first see if we always allow failures for this API
    errorMap.alwaysAllowFailureFor.map((basepath) => {
        const match = `${errorMap.apiPrefix}${basepath}`;

        if (url.startsWith(match)) {
            isFatal = false;
            isPageItem = false;
        }
    });

    //if we have not determined already that it is NOT fatal, lets check page level contact adherance
    if (isFatal) {
        errorMap.allowFailuresByPage.map((pageContract) => {
            if (window.location.pathname.startsWith(pageContract.pathname)) {
                pageContract.apis.map((basepath) => {
                    const match = `${errorMap.apiPrefix}${basepath}`;

                    if (url.startsWith(match)) {
                        isFatal = false;
                    }
                });
            }
        });
    }

    if (isFatal && window.location.host == "pro.dev.capitalgroup.com"
        || window.location.host.indexOf('localhost') > -1) {
        errorMap.alwaysAllowFailureForDev.map((basepath) => {
            const match = `${errorMap.apiPrefix}${basepath}`;

            if (url.startsWith(match)) {
                isFatal = false;
                isPageItem = false;
            }
        });
    }

    if (!isFatal && !isPageItem) {
        let showToast = true;

        errorMap.noToastOnFailure.map((basepath) => {
            const match = `${errorMap.apiPrefix}${basepath}`;

            if (url.startsWith(match)) {
                showToast = false;
            }
        });

        if (showToast) {
            store.dispatch(addToasts([{
                toastType: TYPES.FAILED_ACTION,
            }]));
        }
    } else if (window.location.pathname.startsWith('/email-confirmation')) {
        store.dispatch(addToasts([{
            toastType: TYPES.FAILED_ACTION,
        }]));
    }

    return isFatal;
}

export class Api {
    constructor() {
        /* define API basics */
        const apiConfiguration = {
            storage,
            debug: true,
            defaultHeaders: true,
            accessTokenStorageKey: ACCESS_TOKEN,
            refreshTokenStorageKey: REFRESH_TOKEN,
            csrfTokenStorageKey: CSRF_TOKEN,
            logoutTimeKey: IS_KEEP_SIGNED_IN,
            keepSignedInKey: LOGOUT_TIME,
            authenticationHeaderKey: 'x-dp-authorization',
            baseURL: window.location.protocol + '//' + window.location.host + '/api',
        };

        this.axiosApi = new newApi(apiConfiguration);

        // // hooks to wire into state management to sync user's authentication state
        this.axiosApi.onSignedIn = () => {
            //console.log('XXDEBUG Signed in!!!!!!!!!');
        };

        this.axiosApi.onSignedOut = () => {/*console.log('xxx Signed out');*/};

        //authentication call definition
        this.axiosApi.generateSignInRequest = (api, credentials) => {
            return api.post(SIGN_IN_URL, credentials, undefined, true);
        }

        //logout definition
        this.axiosApi.generateSignOutRequest = (api, credentials) => {
            //console.log('[apiWrapper] calling signout endpoint');

            //return api.post(LOGOUT_URL);
            //console.log('ttyl DEBUG XY: logout called from where?');
        }

        //refresh token definition
        this.axiosApi.generateRefreshTokenRequest = (api, token) => {
            return api.get(REFRESH_URL);
        }

        //refresh token definition
        this.axiosApi.generateCsrfTokenRequest = (api, token) => {
            return api.get(CSRF_URL);
        }

        //determine if an error requires we refresh tokens
        this.axiosApi.shouldRefreshTokensOnError = (error) => {
            let shouldRefresh = error.status === UNAUTHORIZED_ERROR_STATUS
                || (error.status === CSRF_ERROR_STATUS && window.location.pathname === '/sign-in');

            // when user attepts to change password with incorrect current password we get a 401
            shouldRefresh = shouldRefresh && !(error.data && error.data.data === INVALID_PASSWORD_ERROR_MESSAGE) && window.location.pathname !== '/profile/account';
            return shouldRefresh;
        }

        /**
         * Show toasts on accepted service errors.
         */
        this.axiosApi.customErrorHandler = (error) => {
            const isFatal = isErrorFatal(error.request.url, error);

            if (isFatal) {
                redirectToServerErrorPage();
            }
        }
        /* END define API basics */

        //required data point for all post requests, make sure its present
        //console.log('xxx new api construction: ' + window.location.href);
        //this.updateCsrf();
    }

    /**
     * Cleanup URLs with duplicate api entry.
     * @param {*} url
     */
    cleanUrl(url) {
        let myUrl = url;

        if (myUrl.startsWith('/api')) {
            myUrl = myUrl.substring(4);
        }

        if (!myUrl.startsWith('/')) {
            myUrl = '/' + myUrl;
        }

        return myUrl;
    }

    /**
     * GET request through axios api.
     * @param {*} url
     * @param {*} params
     */
    get = (url, params) => {
        const myUrl = this.cleanUrl(url);

        //new api core method
        return service.get(myUrl, params);

        //old cg-common-util method
        //return this.axiosApi.get(myUrl, params ? params.params : null);
    }

    post = (url, params) => {
        const myUrl = this.cleanUrl(url);

        //new api core method
        return service.post(myUrl, params ? params.data : null);

        // NOTE: we need something similar to `shouldRefreshTokensOnError` on the new service
        //       to allow some errors get handled on components, e.g. change password with incorrect current password
        //old cg-common-util method
        //return this.axiosApi.post(myUrl, params ? params.data : null);
    }

    put = (url, params) => {
        const myUrl = this.cleanUrl(url);

        return service.put(myUrl, params ? params.data : null);

        //old cg-common-util method
        //return this.axiosApi.put(myUrl, params ? params.data: null);
    }

    delete = (url, params) => {
        const myUrl = this.cleanUrl(url);

        //new api core method
        return service.delete(myUrl, params ? params.data : null);

        //old cg-common-util method
        //return this.axiosApi.delete(myUrl, params ? params.data : null);
    }

    // exposing axios request for custom API calls
    request = (url, params, data, method, headers, isPublic, configuration) => {
        return this.axiosApi.request(url, params, data, method, headers, isPublic, configuration);
    }

    /**
     * Sign user in.
     * @param {*} email
     * @param {*} password
     * @param {*} keepSignedIn
     */
    authenticate = (email, password, keepSignedIn) => {
        storage.remove(REFRESH_TOKEN);
        storage.remove(IS_KEEP_SIGNED_IN);
        storage.remove(LOGOUT_TIME);

        //const auth = this.axiosApi.signIn({ email, password, keepSignedIn });
        //api.post(SIGN_IN_URL, credentials, undefined, true);

        return this.post(SIGN_IN_URL, {data: { email, password, keepSignedIn }});

        //this.updateCsrf();

        return auth;
    };

    /**
     * Sign user out.
     */
    logout = () => {
        //const isLoggedOut = storage.get(IS_LOGGED_OUT);

        //if (!isLoggedOut) {
            storage.set(IS_LOGGED_OUT, true);
            //console.log('[apiWrapper] calling signOut through api');
            this.removeFirstName();

            storage.remove(REFRESH_TOKEN);
            storage.remove(IS_KEEP_SIGNED_IN);
            storage.remove(LOGOUT_TIME);

            return this.post(LOGOUT_URL);

            //return this.axiosApi.signOut();
        //}
    };

    /**
     * Update the stored CSRF token.
     */
    updateCsrf = () => {
        const headers = {
            'Content-type': 'application/json',
        };

        this.get(CSRF_URL, headers)
            .then(response => {
                if (response.data
                    && response.data.csrf_token
                    && typeof (response.data.csrf_token) === 'string') {
                    //store only valid token
                    this.storeCsrfToken(response.data);
                }
            });
    };

    /**
     * Refresh the users access token.
     */
    refreshToken = () => {
        return service.manualRefresh();
    };

    storeCsrfToken(token) {
        storage.set(CSRF_TOKEN, token[CSRF_TOKEN]);
        this.axiosApi.setCsrfToken(token[CSRF_TOKEN]);
    }

    removeCsrfToken() {
        storage.remove(CSRF_TOKEN);
        this.axiosApi.setCsrfToken(null);
    }

    getCsrfToken() {
        return storage.get(CSRF_TOKEN);
    }

    removeFirstName() {
        storage.remove(FIRST_NAME);
    }

    setFirstName(firstName) {
        storage.set(FIRST_NAME, firstName);
    }

    getFirstName() {
        return storage.get(FIRST_NAME);
    }
}

//ensure only one api wrapper is active
if (!window.apiWrapper) {
    window.apiWrapper = new Api();
}

export default window.apiWrapper;
