import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { debounce } from 'lodash';
import Button from 'components/atomics/atoms/Button/Button';
import { TYPE_SECONDARY_SMALL_WHITE } from 'components/atomics/atoms/Button/Button';
import { POSITIONS } from './constants';
import {
    coachMarkStyles,
    coachMarkWrapperStyles,
    contentWrapperStyles,
    controlsWrapperStyles,
    SIDE_OFFSET,
} from './styles';
import labels from '../../../../lang/en.json';
import { contextHubRecordEvent } from 'utils/contextHub';

export const StyledCoachMark = styled.div`
    ${props => props.extraHeight && css`
        height: 210px !important;
    `}

    ${coachMarkStyles}
`;

const StyledCoachMarkWrapper = styled.div`
    ${coachMarkWrapperStyles}
`;

const StyledContentWrapper = styled.div`
    ${contentWrapperStyles};
`;

const StyledControlsWrapper = styled.div`
    ${controlsWrapperStyles};
`;

/**
 * Creates a coachmark for user instruction.
 */
class CoachMark extends React.Component {
    /**
     * Default constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            placement: '',
        };

        this.resetPlacement = this.resetPlacement.bind(this);
        this.setPlacement = this.setPlacement.bind(this);
        this.debouncedResizeHandler = debounce(this.handleWindowSizeChange.bind(this), 50);
    }

    /**
     * Show coach mark at mount if visible.
     */
    componentDidMount() {
        const { isVisible } = this.props;

        if (isVisible) {
            this.onCoachMarkShow();
        }
    }

    /**
     * Show or hide coachmark based on isVisible property change.
     * @param {*} prevProps 
     */
    componentDidUpdate(prevProps) {
        if (!prevProps.isVisible && this.props.isVisible) {
            this.onCoachMarkShow();
        }

        if (prevProps.isVisible && !this.props.isVisible) {
            this.onCoachMarkHide();
        }
    }

    /**
     * Hide coachmark on destroy.
     */
    componentWillUnmount() {
        this.onCoachMarkHide();
    }

    /**
     * Add resize listener when showing coachmark.
     */
    onCoachMarkShow() {
        this.setPlacement();

        window.addEventListener('resize', this.debouncedResizeHandler);
    }

    /**
     * Remove resize event listener when coachmark hidden.
     */
    onCoachMarkHide() {
        window.removeEventListener('resize', this.debouncedResizeHandler);
    }

    /**
     * Get coachmark placement dimensions.
     */
    getDimensions() {
        if (!this.coachMarkWrapper || !this.coachMarkTooltip) {
            return {};
        }

        const position = this.coachMarkWrapper.getBoundingClientRect();
        const width = this.coachMarkTooltip.clientWidth;
        const height = this.coachMarkTooltip.clientHeight;
        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;

        return {
            distanceFromLeft: position.left,
            distanceFromRight: windowWidth - position.right,
            distanceFromTop: position.top,
            distanceFromBottom: windowHeight - position.bottom,
            width,
            height,
        };
    }

    /**
     * Set coachmark placement based on dimensions.
     */
    setPlacement() {
        const {
            distanceFromBottom,
            distanceFromLeft,
            distanceFromRight,
            distanceFromTop,
            width,
            height,
        } = this.getDimensions();
        let placement = '';

        if (width <= distanceFromRight) {
            if (((height / 2) <= distanceFromTop) && ((height / 2) <= distanceFromBottom)) {
                placement = POSITIONS.RIGHT_CENTER;
            } else if (height <= distanceFromTop) {
                placement = POSITIONS.RIGHT_BOTTOM;
            } else {
                placement = POSITIONS.RIGHT_TOP;
            }
        } else if (height <= distanceFromTop) {
            if (width - SIDE_OFFSET <= distanceFromRight) {
                placement = POSITIONS.TOP_LEFT;
            } else if (width - SIDE_OFFSET <= distanceFromLeft) {
                placement = POSITIONS.TOP_RIGHT;
            } else {
                placement = POSITIONS.TOP_CENTER;
            }
        } else if (width <= distanceFromLeft) {
            if ((height / 2) <= distanceFromTop && (height / 2) <= distanceFromBottom) {
                placement = POSITIONS.LEFT_CENTER;
            } else if (height <= distanceFromTop) {
                placement = POSITIONS.LEFT_BOTTOM;
            } else {
                placement = POSITIONS.LEFT_TOP;
            }
        } else {
            // eslint-disable-next-line no-lonely-if
            if (width - SIDE_OFFSET <= distanceFromRight) {
                placement = POSITIONS.BOTTOM_LEFT;
            } else if (width - SIDE_OFFSET <= distanceFromLeft - 25) {
                placement = POSITIONS.BOTTOM_RIGHT;
            } else {
                placement = POSITIONS.BOTTOM_CENTER;
            }
        }

        if (this.props.placement) {
            placement = this.props.placement;
        }

        this.setState({
            placement,
        });
    }

    /**
     * Reset placement properties.
     */
    resetPlacement() {
        this.setState({
            placement: '',
        }, this.setPlacement);
    }

    /**
     * Handle window resize events.
     */
    handleWindowSizeChange() {
        this.resetPlacement();
    }

    /**
     * Render the coachmark content (text) area.
     */
    renderContent() {
        const { message } = this.props;

        return (
            <StyledContentWrapper>
                {message}
            </StyledContentWrapper>
        );
    }

    /**
     * Handle got it clicks for analytics.
     */
    handleAnalytics() {
        /*
        dataAnalyticsPlacement="Button : body"
                    dataAnalyticsLabel="trackLink : button"
                    dataAnalyticsId={analyticsId}*/
        const { message } = this.props;

        const analyticsId = `Button: coachmark: ${message}`;

        var tracking =
            {
                "type": "coachMark",
                "action": analyticsId,
                "value": null,
                "title": "GOT IT",
                "topic": null,
                "source": null,
                "location": null
            };
        contextHubRecordEvent(tracking);
    }

    /**
     * Render any neede coachmark controls.
     */
    renderControls() {
        const { message, onAccept, onDecline, onAcceptUrl, declineLabel, acceptLabel } = this.props;

        const url = onAcceptUrl ? onAcceptUrl : "#nonop";

        const analyticsId = `coachmark: ${message}`;

        return (
            <StyledControlsWrapper>
                {onDecline && <Button
                    type={TYPE_SECONDARY_SMALL_WHITE}
                    to="#"
                    onClick={() => { onDecline(); this.handleAnalytics() }}
                >
                    {declineLabel}
                </Button>}
                {(onAccept || onAcceptUrl)
                    && <Button
                        type={TYPE_SECONDARY_SMALL_WHITE}
                        onClick={() => { onAccept(); this.handleAnalytics() }}
                        to={url}
                    >
                        {acceptLabel}
                    </Button>}
            </StyledControlsWrapper>
        );
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const { isVisible, offsetLeft, externalHeight } = this.props;
        const { placement } = this.state;

        return (
            <StyledCoachMarkWrapper
                ref={(ref) => { this.coachMarkWrapper = ref; }}
                data-rel="CoachMarkWrapper"
            >
                {this.props.children}
                {isVisible && (
                    <StyledCoachMark
                        extraHeight={externalHeight}
                        forceRight={this.props.forceRight}
                        forceLeft={this.props.forceLeft}
                        forceTop={this.props.forceTop}
                        onClick={(e) => { e.preventDefault(); }}
                        noMinHeight={this.props.noMinHeight}
                        ref={(ref) => { this.coachMarkTooltip = ref; }}
                        variant={{ placement }}
                        offsetLeft={offsetLeft}
                        data-styles='coachMark'
                    >
                        {this.renderContent()}
                        {this.renderControls()}
                    </StyledCoachMark>
                )}
            </StyledCoachMarkWrapper>
        );
    }
}

CoachMark.propTypes = {
    message: PropTypes.string,
    onAccept: PropTypes.func,
    onAcceptUrl: PropTypes.string,
    isVisible: PropTypes.bool,
    placement: PropTypes.string,
    noMinHeight: PropTypes.bool,
    offsetLeft: PropTypes.bool,
    acceptLabel: PropTypes.string,
    declineLabel: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    forceLeft: PropTypes.string,
    forceTop: PropTypes.string,
    forceRight: PropTypes.string,
    externalHeight: PropTypes.bool
};

CoachMark.defaultProps = {
    children: null,
    onAccept: null,
    onAcceptUrl: null,
    onDecline: null,
    message: null,
    isVisible: false,
    placement: null,
    noMinHeight: false,
    offsetLeft: false,
    acceptLabel: labels.translations['coachMark.button.link'],
    declineLabel: labels.translations['coachMark.button.close'],
    forceLeft: null,
    forceTop: null,
    forceRight: null,
    externalHeight: false
};

export default CoachMark;
