import { css } from 'styled-components';
import { outerFocusMixin } from 'components/FocusVisibility/styles';
import {
    StyledLogoIcon,
    StyledLogoContainer,
    StyledEmblemIcon,
} from 'components/Logo/Logo';
import { FROM_DESKTOP, FROM_TABLET, FROM_MOBILE_TO_TABLET } from 'utils/mediaQueries';
import { COLORS } from 'utils/variables';

export const brandedHeader = css`
    text-align: center;
    width: 100%;

    img[data-img="capgrouplogo"]{
        height: 35px;
        margin-top: 6px;

        @media screen and ${FROM_DESKTOP} {
            height: 52px;
        }
    }

`;

export const headerStyles = css`
    background-color: ${COLORS.WHITE};
    box-sizing: border-box;
    display: flex;
    height: 60px;
    position: relative;
    z-index: 1000;
    border-bottom:1px Solid ${COLORS.LIGHT_GRAY2};

    @media screen and ${FROM_DESKTOP} {
        height: 88px;
    }
`;

export const containerStyles = css`
    align-items: center;
    display: flex;
    flex: 1 1 100%;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
 
    @media screen and ${FROM_DESKTOP} {
        padding-top: 15px;
        padding-left: 48px;
        padding-right: 48px;
    }
    @media screen and ${FROM_TABLET} {
       height: 56px;
    }
`;

export const wrapperStyles = css`
    align-items: center;
    display: flex;
    flex: 1 1 100%;
    justify-content: space-between;
    padding-bottom: 14px;
    padding-top: 14px;
    position: relative;
    

    @media screen and ${FROM_TABLET} {
        padding: 0 15px 0 25px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding-bottom: 20px;
        padding-top: 20px;
        padding-left: 0px;
        padding-right: 0px;
    }
`;

export const iconWrapperStyles = css`
    align-items: center;
    display: flex;
    min-width: 30px;
`;

export const logoWrapperStyles = css`
    display: flex;
    order: 0;

    @media screen and ${FROM_DESKTOP} {
        margin: 0;
        order: 0;
        transform: none;
    }

    /* stylelint-disable */
    ${StyledLogoContainer} {
        height: 28px;
        width: 113px;

        @media screen and ${FROM_TABLET} {
            height: 28px;
            width: 113px;
        }

        @media screen and ${FROM_DESKTOP} {
            height: 41px;
            width: 197px;
        }
    }

    ${StyledLogoIcon} {
        height: 30px;
        width: 102px;

        @media screen and ${FROM_TABLET} {
            height: 25px;
            width: 84px;
        }

        @media screen and ${FROM_DESKTOP} {
            height: 29px;
            width: 97px;
        }
    }

    ${StyledEmblemIcon} {
        max-height: 37px;
        max-width: 37px;

        @media screen and ${FROM_MOBILE_TO_TABLET} {
            display: none;
        }

        @media screen and ${FROM_TABLET} {
            max-height: 30px;
            max-width: 30px;
        }

        @media screen and ${FROM_DESKTOP} {
            max-height: 35px;
            max-width: 35px;
        }
    }
    /* stylelint-enable */
`;

export const logoLinkStyles = css`
    ${logoWrapperStyles};
    ${outerFocusMixin};
`;
