import {
    VISITED_DOWNLOAD,
    VISITED_DOWNLOAD_SUCCESS,
    VISITED_DOWNLOAD_FAIL
} from 'state-management/constants/download';
export const visitedDownload = {
    type: VISITED_DOWNLOAD,
};

export const visitedDownloadSuccess = ({
    type: VISITED_DOWNLOAD_SUCCESS
});

export const visitedDownloadFail = {
    type: VISITED_DOWNLOAD_FAIL,
};