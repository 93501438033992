import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ErrorsContainer from 'containers/Errors/Errors';
import { getWebinarInfo } from 'state-management/actions/webinar';
import {
    PAGE_SIZE,
} from '../../state-management/constants/savedContent';
import { clearErrors as clearErrorsAction } from 'state-management/actions/errors';
import { getSavedContentData as getSavedContentDataAction } from 'state-management/actions/savedContent';
import {
    CardsGrid,
    Container,
    Heading,
    PagedList,
    Loader,
} from '../../components';
import {
    descriptionStyles,
    headingStyles,
    savedContentStyles,
    secondHeaderStyles,
    savesContainerStyles,
    skinnyListContentCardContainerStyles,
} from 'containers/Saves/styles';
import EmptySavesCard from 'containers/Saves/EmptySavesCard';
import WebinarListCard from 'components/atomics/molecules/WebinarListCard/WebinarListCard';
import SkinnyListContentCard from 'components/atomics/molecules/SkinnyListContentCard/SkinnyListContentCard';
import SkinnyListGuideCard from 'components/atomics/molecules/SkinnyListGuideCard/SkinnyListGuideCard';
import SkinnyListCeCredit from 'components/atomics/molecules/SkinnyListCeCredit/SkinnyListCeCredit';
import BackToLink from "../../components/BackToLink/BackToLink";
import { wrapBackButtonLabels } from "../../utils/urlUtils";
import { BackToLinkContainer } from "../Guides/CollectionContainer/CollectionContainer";
import { FROM_DESKTOP } from "../../utils/mediaQueries";
import { transformContentItem } from "../../utils/contentCardUtils";
import Title from 'components/atomics/atoms/Title/Title';

const SkinnyListContentCardContainer = styled.div`
    ${skinnyListContentCardContainerStyles}
`;

const StyledIntro = styled.div``;

const StyledHeading = styled(Heading)`
    ${headingStyles};
`;

const StyledDescription = styled.p`
    ${descriptionStyles};
`;

const StyledSavedContent = styled.div`
    ${savedContentStyles};
`;

const StyledSecondHeader = styled.div`
    ${secondHeaderStyles}
`;

const StyledSavesContainer = styled(Container)`
    ${savesContainerStyles}
`;

const BackContainerButton = styled.div`
     @media screen and ${FROM_DESKTOP} {
        display: none;
     }
`;

window.savesComponentUpdatedByCoachMark = false;

/**
 * Account saves page.
 */
export class Saves extends React.Component {
    /**
     * Default constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.keyCount = 0;
        this.getKey = this.getKey.bind(this);

        this.handleUnsave = this.handleUnsave.bind(this);
    }

    /**
     * Gather webinar data and clear errors at mount.
     */
    componentDidMount() {
        this.props.clearErrors();
        this.props.getWebinarInfo();
        this.props.getSavedContentData({ page: 1 });
    }

    componentDidUpdate(prevProps) {
        const { page: pageProp, savedContent, getSavedContentData } = this.props;
        const page = parseInt(pageProp, 10);
        if (prevProps.savedContent.length > savedContent.length && prevProps.page === pageProp) {
            if (savedContent.length === 0 && page > 0) {
                window.location.href = `/profile/saves?p=${page - 1}`;
            } else {
                getSavedContentData({ page }, false);
            }
        }
    }

    /**
     * Handle unsave.
     */
    handleUnsave() {
        this.props.getSavedContentData({ page: 1 });
    }

    onPageChange = (page) => {
        this.props.getSavedContentData({ page });
    };

    /**
     * Get unique key for iterator usage.
     */
    getKey() {
        return this.keyCount++;
    }

    /**
     * Return true/false for if the card is the last one.
     * @param {*} idx 
     */
    isLastCard(idx) {
        return ((idx + 1) === this.props.totalElements) ? true : false;
    }

    /**
     * Render a single saved item.
     */
    renderItem = (item, idx) => (
        <SkinnyListContentCardContainer
            key={this.getKey()}
            className={this.isLastCard(idx) ? 'last-item' : ''}
        >
            {item.type === 'WEBINAR'
                && <WebinarListCard
                    article={transformContentItem(item)}
                    isLastCard={this.isLastCard(idx)}
                    alternativeBackground='WHITE'
                    shouldShowToast={true}
                    shouldAllowClickArticleTracking={true}
                    onUnsave={this.handleUnsave}
                />}
            {item.type === 'ARTICLE'
                && <SkinnyListContentCard
                    article={transformContentItem(item)}
                    isLastCard={this.isLastCard(idx)}
                    alternativeBackground='WHITE'
                    shouldShowToast={true}
                    shouldAllowClickArticleTracking={true}
                    onUnsave={this.handleUnsave}
                />}
            {(item.type === 'GUIDE_STANDARD'
                || item.type === 'GUIDE_HBR'
                || item.type === 'GUIDE_IN_PRACTICE'
                || item.type === 'GUIDE_MC_KINSEY')
                && <SkinnyListGuideCard
                    article={transformContentItem(item)}
                    isLastCard={this.isLastCard(idx)}
                    alternativeBackground='WHITE'
                    shouldShowToast={true}
                    shouldAllowClickArticleTracking={true}
                    onUnsave={this.handleUnsave}
                />}
            {item.type == 'CECREDIT'
                && <SkinnyListCeCredit
                    article={transformContentItem(item)}
                    isLastCard={this.isLastCard(idx)}
                    alternativeBackground='WHITE'
                    shouldShowToast={true}
                    shouldAllowClickArticleTracking={true}
                    onUnsave={this.handleUnsave}
                    getContentAfterSave={true}
                />}
        </SkinnyListContentCardContainer>
    );

    /**
     * Render the page level content.
     */
    renderContent = (item) => {
        const { urlHistoryList } = this.props;

        if (this.props.isLoading) {
            return <Loader />;
        }
        if (this.state !== null && this.state.articleCounter === 0) {
            return <EmptySavesCard urlHistoryList= {urlHistoryList}/>;
        }

        if (this.props.totalElements === 0) {
            if (this.props.page > 1) {
                let navPage = this.props.page - 1;
                window.location.href = '/profile/saves?p=' + navPage;
            } else {
                return <EmptySavesCard urlHistoryList= {urlHistoryList}/>;
            }
        }

        const backLabel = (urlHistoryList.length - 2) >= 0 ? urlHistoryList[urlHistoryList.length - 2] : null;

        return (
            <StyledSavedContent>
                <StyledSavesContainer>
                    <StyledSecondHeader>
                        <BackContainerButton>
                            <BackToLinkContainer>
                                <BackToLink
                                    to={backLabel != null ? backLabel.currentUrl : '/'}
                                    customLabel={wrapBackButtonLabels(backLabel)}
                                    isDisable={backLabel == null}
                                >
                                </BackToLink>
                            </BackToLinkContainer>
                        </BackContainerButton>
                        <Title
                            resourceId="saves.intro.title"
                        />
                        <FormattedMessage
                            id="saves.count"
                            values={{ savesCount: this.props.totalSavedElements }}
                        />
                    </StyledSecondHeader>
                    <PagedList
                        pageWrapperComponent={CardsGrid}
                        itemsCount={this.props.savedContent.length}
                        onPageChange={this.onPageChange}
                        page={this.props.page}
                        totalItems={this.props.totalSavedElements}
                        pageItems={this.props.savedContent}
                        pageSize={PAGE_SIZE}
                        totalPages={this.props.totalPages}
                        renderItem={this.renderItem}
                    />
                </StyledSavesContainer>
            </StyledSavedContent>);
    };

    /**
     * Render this and underlying components.
     */
    render() {
        if (this.props.totalElements === null) {
            return null;
        }
        const isEmpty = this.props.totalElements === 0;
        return (
            <React.Fragment>
                <ErrorsContainer />
                <StyledIntro>
                    <StyledSavesContainer>
                        {!isEmpty && (
                            <StyledDescription>
                                <FormattedMessage id="saves.intro.desc" />
                            </StyledDescription>
                        )}
                    </StyledSavesContainer>
                </StyledIntro>
                {this.renderContent()}
            </React.Fragment>
        );
    }
}

Saves.propTypes = {
    clearErrors: PropTypes.func.isRequired,
    getSavedContentData: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    page: PropTypes.number.isRequired,
    savedContent: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    totalElements: PropTypes.number,
    totalPages: PropTypes.number.isRequired,
    totalSavedElements: PropTypes.number.isRequired,
};

Saves.defaultProps = {
    totalElements: null,
}


const mapStateToProps = state => ({
    page: state.savedContent.page,
    savedContent: state.savedContent.savedContent,
    totalElements: state.savedContent.savedContent.length,
    isLoading: state.savedContent.isLoading,
    totalSavedElements: state.savedContent.totalElements,
    totalPages: state.savedContent.totalPages,
    currentUrlStep: state.UrlHistoryReducer.currentStep,
    urlHistoryList: state.UrlHistoryReducer.history
});

const mapDispatchToProps = dispatch => ({
    clearErrors: () => dispatch(clearErrorsAction()),
    getWebinarInfo: (id) => dispatch(getWebinarInfo()),
    getSavedContentData: ({ page }, shouldScrollToTop) => dispatch(getSavedContentDataAction({ page }, shouldScrollToTop)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Saves);
