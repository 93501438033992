import { css } from 'styled-components';

import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT } from 'utils/variables';
import {
    COLORS,
    FONTS
} from '../../../utils/variables';
import { SMALL_MOBILE } from '../../../utils/mediaQueries';

export const sectionStyles = css`
    position: relative;

    &:not(:first-of-type) {
        &::before {
            background-image: linear-gradient(to top, ${COLORS.LIGHT_GRAY2}, ${COLORS.LIGHT_GRAY1});
            content: '';
            height: 544px;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: -1;
        }
    }

    @media screen and ${FROM_TABLET} {
        &::before {
            display: none;
        }
    }
`;

export const bodyStyles = css`
    >div {
        margin-bottom: 0;
    }

    @media screen and ${SMALL_MOBILE} {
        >div {
            margin-bottom: 0;
        }
    }
`;
export const linkContainerStyles = `
    text-align: right;
    margin-top: -1px;
    padding-bottom: 32px;

    @media screen and ${FROM_TABLET} {
        margin-top: 0;
        padding-top: 7px;
        padding-bottom: 40px;
    }
`;

export const linkStyles = `
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    white-space: nowrap;
    text-align: center;
    text-decoration:none;
    transition: 0.66s all;

    svg {
        height: 12px;
        margin-left: 6px;
        position: relative;
        stroke: ${COLORS.DARK_GRAY2};
        top: -1px;
        width: 12px;
    }

    &>a {
        display: block;
    }

    &:hover {
        color: ${COLORS.MEDIUM_GRAY2};
        svg{
            stroke: ${COLORS.MEDIUM_GRAY2};
        }
    }

    @media screen and ${FROM_TABLET} {
        text-align: right;
        background-color: transparent;
        border-bottom: none;

        &>a {
            display: inline-block;
        }
    }

`;
export const buttonContainerStyles = css`
    text-align: center;
    border-bottom: 1px solid ${COLORS.LIGHT_GRAY2};
    z-index: 10;
    margin-top: -9px;
    padding-bottom: 32px;

    &>a {
        display: block;
    }

    @media screen and ${FROM_TABLET} {
        text-align: right;
        background-color: transparent;
        border-bottom: none;

        &>a {
            display: inline-block;
        }
    }

`;

export const collectionCardStripStyles = css`
    padding-bottom:25px;
    @media screen and ${SMALL_MOBILE} {
        padding-bottom:0;
    }
`;
