import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Link from 'components/atomics/atoms/Link/Link';
import moment from 'moment';
import ImageAtom from 'components/atomics/atoms/Image/Image';
import {
    containerStyles,
    contentContainerStyles,
    backgroundStyles,
    buttonsContainerStyles,
    rightSectionStyles,
    leftSectionStyles,
    imageContainerStyles,
    textContainerStyles,
    titleStyles,
    textStyles,
    dateContainer,
    bodyContainerStyles,
    liveTitleStyles,
    nearTitleStyles,
    toolsHeaderIconStyles,
    ImageHolderDivStyles
} from 'components/atomics/organisms/PromoBanner/styles';
import { SanitizedHTML } from 'components';
import SplashBanner from 'components/atomics/atoms/SplashBanner/SplashBanner';
import CountDown from 'components/atomics/atoms/CountDown/CountDown';
import language from '../../../../lang/en.json';
import Button from 'components/atomics/atoms/Button/Button';

import {
    TYPE_PILL,
    TYPE_GHOST,
} from 'components/atomics/atoms/Button/Button';
import { CEWebinarIcon } from 'components/atomics/atoms/Icons/Icons';
import { addUrlHistoryItem, initDefaultUrlHistoryStore } from "../../../../state-management/actions/UrlHistoryActions";
import { connect } from "react-redux";

const GUIDES_PAGE = "Guides";
const PST = "Pacific Standard Time";
const CST = "Central Standard Time";
const EST = "Eastern Standard Time";
const EDT = "Eastern Daylight Time";
const PDT = "Pacific Daylight Time";
const CDT = "Central Daylight Time";

export const Container = styled.div`
    ${containerStyles}
`;

export const DateContainer = styled.div`
    ${dateContainer}
`;

export const Background = styled.section`
    ${backgroundStyles}
`;

export const ContentContainer = styled.div`
    ${contentContainerStyles}
`;

export const BodyContainer = styled.div`
    ${bodyContainerStyles}
`;

export const ButtonsContainer = styled.div`
    ${buttonsContainerStyles}
`;

export const RightSection = styled.div`
    ${rightSectionStyles}
`;

export const LeftSection = styled.div`
    ${leftSectionStyles}
`;

export const ImageContainer = styled(Link)`
    ${imageContainerStyles}
`;

export const TextContainer = styled.div`
    ${textContainerStyles}
`;
export const Title = styled.div`
     ${titleStyles}
`;

export const Text = styled(SanitizedHTML)`
    ${textStyles}
`;

export const LiveTitle = styled.div`
    ${liveTitleStyles}
`;

export const NearTitle = styled.div`
    ${nearTitleStyles}
`;

export const ToolsHeaderIconContainer = styled.div`
    ${toolsHeaderIconStyles}
`;

export const ImageHolderDiv = styled.div`
    ${ImageHolderDivStyles}
`;

const Status = {
    FUTURE: 1,
    PREVIOUS_DAY: 2,
    IN_HALF_HOUR: 3,
    LIVE: 4,
    PAST: 5,
};

const Decision = {
    NOT_DECIDED: 'ND',
    NOT_INTERESTED: 'NOT_INTERESTED',
    NOT_INTERESTED_PREVIOUS_DAY: 'NOT_INTERESTED_PREVIOUS_DAY',
    NOT_INTERESTED_IN_HALF_HOUR: 'NOT_INTERESTED_IN_HALF_HOUR',
    NOT_INTERESTED_LIVE: 'NOT_INTERESTED_LIVE',
}

class PromoBanner extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);
        this.state = {
            status: Status.FUTURE,
        }
    }

    componentDidMount() {
        this.tick();
        this.interval = setInterval(this.tick, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getTimeZoneAbbr() {
        const zone = new Date().toString().replace(/.*[(](.*)[)].*/, '$1');
        let currentZone = "PST";
        switch (zone) {
            case PST:
                currentZone = "PST";
                break;
            case PDT:
                currentZone = "PDT";
                break;
            case EST:
                currentZone = "EST";
                break;
            case EDT:
                currentZone = "EDT";
                break;
            case CST:
                currentZone = "CST";
                break;
            case CDT:
                currentZone = "CDT";
                break;
            default:
                currentZone = "PST";
                break;
        }
        return currentZone;
    }


    tick = () => {
        const { startTimestamp, endTimestamp } = this.props.promoBannerInfo.content;
        const { status } = this.state;
        let newStatus = status;

        if (status != Status.PAST) {
            const now = moment();
            const tomorrow = moment(new Date()).add(1, 'day').startOf('day');
            const twoDays = moment(new Date()).add(2, 'day').startOf('day');
            const start = moment(new Date(startTimestamp));
            const end = moment(new Date(endTimestamp));

            if (now.isAfter(end)) {
                newStatus = Status.PAST;
            } else if (now.isBetween(start, end)) {
                newStatus = Status.LIVE;
            }
            else if (moment.duration(start.diff(now)).asMinutes() <= 30) {
                newStatus = Status.IN_HALF_HOUR;
            } else if (start.isSame(tomorrow, 'day') || start.isSame(now, 'day')) {
                newStatus = Status.PREVIOUS_DAY;
            } else if (start.isSame(tomorrow, 'day') || start.isSame(twoDays, 'day')) {
                newStatus = Status.IN_TWO_DAYS;
            } else if (start.isAfter(twoDays, 'day')) {
                newStatus = Status.FUTURE;
            } if (newStatus !== status) {
                this.setState({ status: newStatus });
            }
        } else {
            clearInterval(this.interval);
        }
    }

    addUrlToHistory(valueUrl) {
        this.props.addUrlHistoryItem({
            key: '',
            currentUrl: valueUrl,
            currentTitle: '',
        });
    }

    handleDismiss = () => {

        const { status } = this.state;
        let nextDecision = Decision.NOT_INTERESTED;
        if (status === Status.PREVIOUS_DAY) {
            nextDecision = Decision.NOT_INTERESTED_PREVIOUS_DAY;
        }
        else if (status === Status.IN_HALF_HOUR) {
            nextDecision = Decision.NOT_INTERESTED_IN_HALF_HOUR;
        }
        else if (status === Status.LIVE) {
            nextDecision = Decision.NOT_INTERESTED_LIVE;
        }

        this.props.handleDismiss(this.props.promoBannerInfo.eventId, nextDecision);
    }
    /**
     * Render the grid and child components.
     */
    render() {
        const { promoBannerInfo } = this.props;
        //const isRegistered = promoBannerInfo.advisorId ? true : false;
        const isRegistered = this.props.isRegisterd === true ? true : this.props.webinarMeta ? (this.props.webinarMeta.advisorId ? true : false) : false;
        const { status } = this.state;
        const now = new Date();
        const then = new Date(this.props.promoBannerInfo.content.startTimestamp);
        const isLive = (now.getTime() >= new Date(this.props.promoBannerInfo.content.startTimestamp).getTime() && now.getTime() <= new Date(this.props.promoBannerInfo.content.endTimestamp).getTime());
        now.setDate(now.getDate() + 1);
        const oneDayBefore = (then.getTime() - now.getTime()) < 24 * 60 * 60 * 1000 ? true : false;
        const nowTime = now.getTime();
        const thenTime = then.getTime();
        let isNear = (then.getTime() - now.getTime()) < 48 * 60 * 60 * 1000 ? true : false;
        if (isLive) {
            isNear = false
        }
        const nearTime = new Date(new Date(this.props.promoBannerInfo.content.startTimestamp) - (30 * 60000));
        now.setDate(now.getDate() - 1);
        const isVeryNear = nearTime.getTime() <= now.getTime() && !isLive;

        const nowDate = moment();
        const promoteDate = moment(promoBannerInfo.content.dateToPromote);
        const promoteDateCheck = nowDate.isBefore(promoteDate)

        // commented on purpose
        // console.log("isRegistered",isRegistered);
        // console.log("now:",now,"start:",new Date(this.props.promoBannerInfo.startTimestamp),"end:",new Date(this.props.promoBannerInfo.endTimestamp));
        // console.log("isLive:",isLive,"isNear:",isNear,"nearTime:",nearTime,"isVeryNear:",isVeryNear);
        //console.log("isLive Start date an time" + new Date(this.props.promoBannerInfo.content.startTimestamp).getTime());

        if (isVeryNear) {
            isNear = false;
        }

        if (
            promoteDateCheck ||
            ((promoBannerInfo.decision === Decision.NOT_INTERESTED || isRegistered) && status !== Status.PREVIOUS_DAY && status !== Status.IN_HALF_HOUR && status !== Status.LIVE) ||
            (promoBannerInfo.decision === Decision.NOT_INTERESTED_PREVIOUS_DAY && status !== Status.IN_HALF_HOUR && status !== Status.LIVE) ||
            (promoBannerInfo.decision === Decision.NOT_INTERESTED_IN_HALF_HOUR && status !== Status.LIVE) ||
            promoBannerInfo.decision === Decision.NOT_INTERESTED_LIVE ||
            status === Status.PAST
        ) {
            return null;

        } else {
            const trueDate = moment(new Date(this.props.promoBannerInfo.content.startTimestamp)).valueOf();
            const dateString = moment(trueDate).format('dddd, MMM DD \xa0|\xa0 hh:mm A');
            const countDownDate = trueDate;

            return (
                <Background data-rel="SplashBanner">
                    <BodyContainer>
                        <Container>
                            <ContentContainer>
                                <LeftSection>
                                    <ImageHolderDiv>
                                        <ImageContainer
                                            to={promoBannerInfo.content.link ? promoBannerInfo.content.link : '#'}
                                            onClick={() => {
                                            }}
                                            data-analytics-placement="Button : body"
                                            data-analytics-label="trackLink : button"
                                            data-analytics-id={promoBannerInfo.content.link}
                                        >
                                            <ImageAtom
                                                src={'/api/content-service/assets/content-image?filename=/' + this.props.promoBannerInfo.content.image}
                                            />
                                            {promoBannerInfo.content.ceCredit === true &&
                                                <ToolsHeaderIconContainer>
                                                    <CEWebinarIcon />
                                                </ToolsHeaderIconContainer>
                                            }
                                        </ImageContainer>
                                    </ImageHolderDiv>
                                    <TextContainer>
                                        <DateContainer>

                                            {dateString}&nbsp;{this.getTimeZoneAbbr()}
                                        </DateContainer>
                                        <Title>
                                            {this.props.promoBannerInfo.content.title}
                                        </Title>
                                        <Text htmlContent={this.props.promoBannerInfo.content.shortDescription} tag="div">
                                        </Text>
                                    </TextContainer>
                                </LeftSection>
                                <RightSection isNear={isNear}>
                                    {isLive
                                        && <LiveTitle>
                                            <FormattedMessage id="webinar.promo.live" />
                                        </LiveTitle>}
                                    {(isNear && !isRegistered)
                                        && <NearTitle>
                                            <FormattedMessage id="webinar.promo.near" />
                                        </NearTitle>}
                                    {(isNear && isRegistered)
                                        && <NearTitle>
                                            <FormattedMessage id="webinar.promo.nearRegistered" />
                                        </NearTitle>}
                                    {isVeryNear
                                        && <LiveTitle>
                                            <FormattedMessage id="webinar.promo.veryNear" />
                                        </LiveTitle>}
                                    {(!isLive && !isVeryNear)
                                        && <CountDown
                                            timestamp={countDownDate}
                                            timezone='PST'
                                            noDate={true}
                                        />}
                                    <ButtonsContainer>
                                        {(isLive || isVeryNear)
                                            && <Button
                                                dataPage="promoBanner"
                                                dataAnalyticsPlacement="Button : body"
                                                dataAnalyticsLabel="trackLink : button"
                                                dataAnalyticsId={isLive ? "webinar.promo.learnMoreLive" : isVeryNear ? "webinar.promo.learnMoreNear" : "webinar.promo.learnMore"}
                                                to={`${this.props.promoBannerInfo.content.link}`}
                                                onClick={() => {
                                                    this.addUrlToHistory(this.props.promoBannerInfo.content.link);
                                                }}
                                            >
                                                <FormattedMessage id={isLive ? "webinar.promo.learnMoreLive" : isVeryNear ? "webinar.promo.learnMoreNear" : "webinar.promo.learnMore"} />
                                            </Button>
                                        }
                                        {(!isLive && !isVeryNear)
                                            && <Button
                                                dataPage="promoBanner"
                                                dataAnalyticsPlacement="Button : body"
                                                dataAnalyticsLabel="trackLink : button"
                                                dataAnalyticsId={isLive ? "webinar.promo.learnMoreLive" : isVeryNear ? "webinar.promo.learnMoreNear" : "webinar.promo.learnMore"}
                                                to={`/learn/webinar/${this.props.promoBannerInfo.content.code}`}
                                                onClick={() => {
                                                    this.addUrlToHistory(`/learn`);
                                                }}
                                            >
                                                <FormattedMessage id={isLive ? "webinar.promo.learnMoreLive" : isVeryNear ? "webinar.promo.learnMoreNear" : "webinar.promo.learnMore"} />
                                            </Button>
                                        }
                                        <Button
                                            to="#"
                                            type={TYPE_GHOST}
                                            onClick={this.handleDismiss}
                                            dataPage="promoBanner"
                                            dataAnalyticsPlacement="Button : body"
                                            dataAnalyticsLabel="trackLink : button"
                                            dataAnalyticsId={language.translations['webinar.promo.notInterested']}
                                        >
                                            <FormattedMessage id="webinar.promo.notInterested" />
                                        </Button>
                                    </ButtonsContainer>
                                </RightSection>
                            </ContentContainer>
                        </Container>
                    </BodyContainer>
                </Background >
            );
        }
    }
}

PromoBanner.propTypes = {
    promoBannerInfo: PropTypes.shape({
        eventId: PropTypes.number,
        eventKey: PropTypes.string,
        title: PropTypes.string,
        startTimestamp: PropTypes.string,
        endTimestamp: PropTypes.string,
        publishTimestamp: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.string,
        link: PropTypes.string,
        decision: PropTypes.string,
        code: PropTypes.string,
        ceCredit: PropTypes.bool,
        cardType: PropTypes.string,
        advisorId: PropTypes.string
    }),
    handleDismiss: PropTypes.func
}

PromoBanner.defaultProps = {
    promoBannerInfo: PropTypes.shape({
        eventId: null,
        eventKey: null,
        title: '',
        startTimestamp: null,
        endTimestamp: null,
        publishTimestamp: null,
        timezone: 'PST',
        description: null,
        image: null,
        link: '#',
        decision: '',
        code: '',
        ceCredit: false,
        cardType: '',
        advisorId: null
    })
}

const mapStateToProps = state => ({
    isRegisterd: state.webinar.userRegistered,
    webinarMeta: state.webinar.webinar,
});

const mapDispatchToProps = dispatch => ({
    addUrlHistoryItem: (value) => dispatch(addUrlHistoryItem(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(PromoBanner);;
