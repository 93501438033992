import {
    GET_WEBINAR_INFO_WITH_ID,
    GET_WEBINAR_INFO_WITH_ID_FAIL,
    GET_WEBINAR_INFO_WITH_ID_SUCCESS,
    GET_WEBINAR_INFO,
    GET_WEBINAR_INFO_SUCCESS,
    GET_WEBINAR_INFO_FAIL,
    GET_WEBINAR_META_INFO,
    GET_WEBINAR_META_INFO_SUCCESS,
    GET_WEBINAR_META_INFO_FAIL,
    GET_WEBINAR_DATA,
    GET_WEBINAR_DATA_SUCCESS,
    GET_WEBINAR_DATA_FAIL,
    CLAIM_YOUR_SPOT,
    CLAIM_YOUR_SPOT_FAIL,
    CLAIM_YOUR_SPOT_SUCCESS,
    WEBINAR_IS_USER_REGISTERD,
    WEBINAR_IS_USER_REGISTERD_NO,
    WEBINAR_IS_USER_REGISTERD_YES,
    NOT_INTERESTED,
    NOT_INTERESTED_SUCCESS,
    NOT_INTERESTED_FAIL, RESET_ERROR, RESET_WEBINAR_ERROR
} from "../constants/webinar";

export const getWebinarIsUserRegistered = () => {
    return {
        type: WEBINAR_IS_USER_REGISTERD,
    }
};

export const getWebinarIsUserRegisteredYes = () => ({
    type: WEBINAR_IS_USER_REGISTERD_YES,
});

export const getWebinarIsUserRegisteredNo = () => ({
    type: WEBINAR_IS_USER_REGISTERD_NO,
});

export const getWebinarInfo = () => ({
    type: GET_WEBINAR_INFO,
});

export const getWebinarInfoWithEventId = (code) => ({
    type: GET_WEBINAR_INFO_WITH_ID,
    code,
})

export const getWebinarInfoSuccess = (webinar) => ({
    type: GET_WEBINAR_INFO_SUCCESS,
    webinar
});

export const getWebinarInfoFail = () => ({
    type: GET_WEBINAR_INFO_FAIL,
});

export const getWebinarInfoWithIDSuccess = (webinar) => ({
    type: GET_WEBINAR_INFO_WITH_ID_SUCCESS,
    webinar
});

export const getWebinarInfoWithIDFail = () => ({
    type: GET_WEBINAR_INFO_WITH_ID_FAIL,
});

export const getWebinarMetaInfo = (webinarId) => ({
    type: GET_WEBINAR_META_INFO,
    webinarId,
});

export const getWebinarMetaInfoSuccess = (webinar) => ({
    type: GET_WEBINAR_META_INFO_SUCCESS,
    webinar,
});

export const getWebinarMetaInfoFail = () => ({
    type: GET_WEBINAR_META_INFO_FAIL,
});


export const claimYourSpot = (decision) => ({
    type: CLAIM_YOUR_SPOT,
    decision,
});

export const claimYourSpotSuccess = () => ({
    type: CLAIM_YOUR_SPOT_SUCCESS,
});

export const claimYourSpotFail = (error = true) => ({
    type: CLAIM_YOUR_SPOT_FAIL,
    data:error
});

export const resetWebinarError = () => ({
    type: RESET_WEBINAR_ERROR,
});


export const notInterested = (eventId,decision) =>({
    type: NOT_INTERESTED,
    eventId,
    decision
});
export const notInterstedSuccess = (decision) => ({
    type: NOT_INTERESTED_SUCCESS,
    decision
});

export const notInterstedFail = () => ({
    type: NOT_INTERESTED_FAIL,
});
