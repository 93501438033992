import React from 'react';
import styled from 'styled-components';
import { badgeStyle, wrapperStyle } from './styles';

const StyledBadge = styled.div`
    ${badgeStyle};
`;

export const StyledWrapper = styled.div`
    ${wrapperStyle};
`;

const Badge = ({ label, horizontalOffset, verticalOffset, scale, position, zIndex }) => (
    <StyledBadge
        label={label}
        horizontalOffset={horizontalOffset}
        verticalOffset={verticalOffset}
        scale={scale}
        position={position}
        zIndex={zIndex}
    >
        {label !== '' && <span>{label}</span>}
    </StyledBadge>
);

export default Badge;
