import { SHOW_POPUP, HIDE_POPUP } from 'state-management/constants/globalPopupContainer';

const initialState = {
    isOpen: false,
    redirect: true,
};

function globalPopupContainerReducer(state = initialState, action = {}) {
    switch (action.type) {
    case SHOW_POPUP:
        return {
            ...state,
            isOpen: true,
        };
    case HIDE_POPUP:
        return {
            ...state,
            isOpen: false,
            redirect: action.redirect,
        };
    default:
        return state;
    }
}

export default globalPopupContainerReducer;
