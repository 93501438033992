import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET, SMALL_MOBILE } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS } from 'utils/variables';

export const wrapperStyles = css`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    position: relative;

    > :first-child {
        flex: 1 1 auto;
        width: 100%;

        @media screen and ${FROM_TABLET} {
            flex: 1 1 30%;
        }
    }

    > :last-child {
        flex: 1 1 auto;

        @media screen and ${FROM_TABLET} {
            flex: 1 1 70%;
        }
    }

    @media screen and ${FROM_TABLET} {
        flex-direction: row;
    }

    a {
        color: ${COLORS.SAPPHIRE};
        transition: 0.3s all;
        text-decoration: none;

        &:hover {
            color: ${COLORS.DARK_SAPPHIRE};
        }
    }
`;

export const guideStyles = css`
    margin-left: auto;
    max-width: 100%;
    width: 100%;
    margin: 0 auto;

    @media screen and ${FROM_TABLET} {
        max-width: 50%;
        width: 70%;
    }

    &.center {
        @media screen and ${FROM_DESKTOP} {
            max-width: 50%;
            width: 50%;
        }
    }
`;

export const titleStyles = css`
    color: ${COLORS.BLACK};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 18px;
    line-height: 1.33;
    padding: 0 0 20px;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        font-size: 24px;
        line-height: 1;
    }
`;

export const descriptionStyles = css`
    color: ${COLORS.DARK_SAPPHIRE};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 14px;
    line-height: 1.4;
    word-wrap: break-word;
    font-weight: 400;

    &::before {
        background-image: linear-gradient(to left, ${COLORS.OCEAN}, ${COLORS.SAPPHIRE} 40px, ${COLORS.SAPPHIRE} 60px, transparent 80px);
        content: '';
        display: block;
        height: 2px;
        margin: 8px 0 0 0;
        position: absolute;
        transform: translateX(-100%) translateX(-15px);
        width: 90px;
    }

    * {
        margin-top: 0;
    }

    @media screen and ${FROM_TABLET} {
        &::before {
            width: 60px;
        }

        * {
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 18px;
        line-height: 1.33;

        &::before {
            width: 100px;
        }
    }
`;

export const sageNoteStyles = css`
    box-sizing: border-box;
    margin: 0 0 50px;
    padding: 0 0 0 85px;
    width: 100%;

    @media screen and ${FROM_TABLET} {
        margin: 0;
        width: 210px;
        padding: 0 30px 0 28px;
    }

    @media screen and ${FROM_DESKTOP} {
        width: 325px;
        padding: 0 50px 0 0;
    }
`;

export const descriptionContainerStyles = css`
    display: block;
`;
export const spacerStyles = css`
    @media screen and ${SMALL_MOBILE} {
    }
    @media screen and ${FROM_TABLET} {
    }
    @media screen and ${FROM_DESKTOP} {
        width:50%;
    }
`;
