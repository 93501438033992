import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import {
    emptySavesCardStyles, textStyles, titleStyles, iconEmptySaveStyles, iconEmptySaveSmallStyles,
    styledHeader, styledBack, styledEmptyContainer
} from 'containers/Saves/styles';
import { IconEmptySave, IconEmptySaveSmall } from 'components/atomics/atoms/Icons/Icons';
import { StyledResultsContainer as StyledSavesContainer } from 'containers/Search/SearchResults/SearchResults';
import BackToLink from "components/BackToLink/BackToLink";
import { wrapBackButtonLabels } from "../../utils/urlUtils";

const StyledEmptySavesCard = styled.section`
    ${emptySavesCardStyles}
`;

const StyledText = styled.p`
    ${textStyles}
`;

const StyledTitle = styled.h3`
    ${titleStyles}
`;

const StyledHeader = styled.div`
    ${styledHeader}
`;

const StyledEmptyContainer = styled.div`
    ${styledEmptyContainer}
`;

const StyledBack = styled.div`
    ${styledBack}
`;

const StyledIconEmptySave = styled(IconEmptySave)`
    ${iconEmptySaveStyles}
`;

const StyledIconEmptySaveSmall = styled(IconEmptySaveSmall)`
    ${iconEmptySaveSmallStyles}
`;

/**
 * Empty saves card display for when user account->saves has 0 items.
 */
export class EmptySavesCard extends React.Component {
    render() {
        const { urlHistoryList } = this.props;
        const backLabel = (urlHistoryList.length - 2) >= 0 ? urlHistoryList[urlHistoryList.length - 2] : null;

        return (
            <StyledSavesContainer>
                <StyledEmptyContainer>
                    <StyledBack>
                                <BackToLink
                                    to={backLabel != null ? backLabel.currentUrl : '/'}
                                    customLabel={wrapBackButtonLabels(backLabel)}
                                    isDisable={backLabel == null}                                   
                                >
                                </BackToLink>
                    </StyledBack>
                    <StyledHeader>
                        <FormattedMessage id="saves.intro.title" />
                    </StyledHeader>
                    <StyledEmptySavesCard>
                        <StyledIconEmptySave>
                            <IconEmptySave />
                        </StyledIconEmptySave>
                        <StyledTitle>
                            <FormattedMessage id="saves.empty.title" />
                        </StyledTitle>
                        <StyledText>
                            {/*   saveIcon={IconEmptySaveSmall} */}
                            <FormattedMessage id="saves.empty.textWithIcon"
                                values={{
                               saveIcon: <StyledIconEmptySaveSmall><IconEmptySaveSmall /> </StyledIconEmptySaveSmall>
                                }}
                            />
                        </StyledText>
                        {/*<StyledText>
                            <FormattedMessage id="saves.empty.text" />
                        </StyledText>*/}
                    </StyledEmptySavesCard>
                </StyledEmptyContainer>
            </StyledSavesContainer>
        )
    }
}

export default EmptySavesCard;
