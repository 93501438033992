import { css } from 'styled-components';

import { getSpanInPixels } from 'utils/grid';
import { FROM_DESKTOP, FROM_TABLET, SMALL_MOBILE } from '../../utils/mediaQueries';
import { COLORS, FONTS } from '../../utils/variables';

export const footerStyles = css`
    background-color: ${COLORS.LIGHT_GRAY2};
    box-sizing: border-box;
    min-height: 220px;
    padding: 20px 0 50px;

    @media screen and ${FROM_TABLET} {
        padding-top: 25px;
        padding-bottom:45px;
    }
`;

export const containerStyles = css`
    display: flex;
    flex-direction: column;


    @media screen and ${FROM_TABLET} {
        flex-direction: column;
    }

    @media screen and ${FROM_DESKTOP} {
        flex-direction: column;
    }

    span[id="_evidon-link-text"] {
        color: ${COLORS.SAPPHIRE} !important;
    }
`;

export const contentWrapperStyles = css`
    display: flex;
    flex-direction: column;
    margin: 0 0 0;
    padding-bottom:25px;
    border-bottom:2px Solid ${COLORS.MEDIUM_GRAY1};

    @media screen and ${FROM_TABLET} {
        flex-direction: row;
        justify-content: space-between;
        margin: 0 0 20px;
        width: 100%;
    }

    @media screen and ${FROM_DESKTOP} {
        padding-bottom:17px;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 0 0;
        width: 100%;
    }
`;

export const disclaimerWrapperStyles = css`
    color: ${COLORS.BLACK};
    font-size: 12px;
    font-weight: 500;
    line-height: 1.4;
    width: 100%;
    word-wrap: break-word;
    font-family: ${FONTS.AVENIR_NEXT.FONT};

    @media screen and ${SMALL_MOBILE} {
        padding-top: 10px;
        width: 100%;
    }

    @media screen and ${FROM_TABLET} {
        margin: 0;
        width: 100%;
    }

    @media screen and ${FROM_DESKTOP} {
        margin: 12px 0;
        padding-top: 5px;
        font-weight: 600;
        width: 100%;
    }
`;

export const logoContainerStyles = css`
    margin: 0;

    svg {
        fill: ${COLORS.WHITE};
    }
`;

export const navigationStyles = css`
    color: ${COLORS.BLACK};
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.1px;
    text-align: right;
    display: flex;
    flex-direction: column;
    padding-top: 26px;
    a{
        text-decoration: none;
        padding-bottom: 2px;
    }
    a:hover{
        text-decoration: none;
        border-bottom:1px Solid ${COLORS.SAPPHIRE};
    }

    @media screen and ${FROM_DESKTOP} {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
        max-width: 80%;
    }
    @media screen and ${FROM_TABLET} {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
        max-width: 90%;
        a{
           margin-right: 20px;
        }
        a:last-child {
            margin-right: 0;
        }
    }
    @media screen and ${SMALL_MOBILE} {
        a{
            padding-top: 6px;
            padding-bottom: 6px;
         }
    }
`;
