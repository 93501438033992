import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    containerStyles,
} from 'components/atomics/atoms/Column/styles';

export const Container = styled.div`
    ${containerStyles}
`;

export const TYPE_3ROW = "type/3row";
export const TYPE_4ROW = "type/4row";
export const TYPE_2ROW = "type/2row";

/**
 * Represents a column in table or grid formats,
 * type allows for automatic sizing.
 */
class Column extends React.Component {
    /**
     * Render the column and child components.
     */
    render() {
        return (
            <Container type={this.props.type}>
               {this.props.children}
            </Container>
        );
    }
}

Column.propTypes = {
    type: PropTypes.string,
}

Column.defaultProps = {
    type: TYPE_3ROW,
}

export default Column;
