import {
    all, call, put, select, takeEvery,
} from 'redux-saga/effects';
import moment from 'moment';
import Api from 'utils/api';
import { TYPES } from 'containers/Toasts/constants';
import {
    REMOVE_GUIDE_FROM_SAVES,
    ADD_GUIDE_TO_SAVES_URL,
    ADD_ARTICLE_TO_SAVES_URL,
    ADD_CECREDIT_TO_SAVES_URL,
    TOGGLE_SAVE_ARTICLE,
    TOGGLE_SAVE_GUIDE,
    TOGGLE_SAVE_WEBINAR,
    ADD_WEBINAR_TO_SAVES_URL,
    TOGGLE_SAVE_PL_ARTICLE
} from 'state-management/constants/save';
import {
    toggleSaveArticleFailure,
    toggleSaveArticleSuccess,
    toggleSaveGuideFailure,
    toggleSaveGuideSuccess,
    toggleSaveWebinarFailure,
    toggleSaveWebinarSuccess,
    toggleSaveCecreditSuccess,
    toggleSaveCecreditFailure,
    toggleSavePlArticleSuccess,
    toggleSavePlArticleFailure,
} from 'state-management/actions/save';
import { addToasts } from 'state-management/actions/toasts';
import {
    getSavedContentData as getSavedContentDataAction,
} from 'state-management/actions/savedContent';
import { hideCoachMark } from 'state-management/actions/coachMark';
import { TOGGLE_SAVE_CECREDIT } from '../constants/save';
import { getNotification } from '../actions/basicProfile';

/**
 * Get a unique page of saved data.
 * @param {*} save
 */
function* getPage(save) {
    const page = yield select(state => state.savedContent.page);
    if (!save) {
        const length = yield select(state => state.savedContent.savedContent.length);
        if (length <= 1 && page !== 1) {
            return page - 1;
        }
    }

    return page;
}

/**
 * Get saved content by page.
 * @param {*} param0
 */
function* handleGetSavedContent({ save }) {

    const page = yield call(getPage, save);

    yield put(getSavedContentDataAction({ page }, false));
}

/**
 * Handle save page toast display.
 * @param {*} action
 * @param {*} toastType
 * @param {*} analyticsData
 */
function* handleShowToast(action, toastType, analyticsData) {
    const {
        id, isUndo, title, type,
    } = action;

    if (!isUndo) {
        yield put(addToasts([{
            toastProps: {
                actionType: type,
                id,
                title,
                analyticsData,
                article: action.article,
            },
            toastType,
        }]));
    }
}

let isGuideSaving = false;

/**
 * Save/unsave guide.
 * @param {*} action
 */
function* toggleSaveGuide(action) {
    const {
        id,
        analyticsData,
        getContentAfterSave,
        save,
    } = action;

    if (isGuideSaving) {
        //DONT call this until the last action is completed or dismissed, else API latency can do bad stuff to source of truth.
        return;
    }

    isGuideSaving = true;

    try {
        let response;

        if (save) {
            response = yield call(Api.post, ADD_GUIDE_TO_SAVES_URL(id));
        } else {
            response = yield call(Api.delete, REMOVE_GUIDE_FROM_SAVES(id));
        }

        if (response && response.status && response.status === "success") {
            yield put(toggleSaveGuideSuccess(id, save));

            const toastType = save
                ? TYPES.SUCCESSFULLY_SAVED_GUIDE
                : TYPES.SUCCESSFULLY_UNSAVED_GUIDE;

            yield call(handleShowToast, action, toastType, analyticsData);

            if (getContentAfterSave) {

                if (window.removeSavesIds && window.removeSavesIds.length > 0) {
                    window.removeSavesIds.pop();
                }
                window.savesComponentUpdatedByCoachMark = true;

                yield call(handleGetSavedContent, action);
            }
        } else {
            yield put(toggleSaveGuideFailure(e, id, save));
        }
    } catch (e) {
        yield put(toggleSaveGuideFailure(e, id, save));
    }

    isGuideSaving = false;
}

/**
 * Save/unsave guide.
 * @param {*} action
 */
function* toggleSaveWebinar(action) {
    const {
        id,
        analyticsData,
        save,
    } = action;

    try {
        if (save) {
            yield call(Api.post, ADD_WEBINAR_TO_SAVES_URL(id));
        } else {
            yield call(Api.delete, ADD_WEBINAR_TO_SAVES_URL(id));
        }

        yield put(toggleSaveGuideSuccess(id, save));

        const toastType = save
            ? TYPES.SUCCESSFULLY_SAVED_GUIDE
            : TYPES.SUCCESSFULLY_UNSAVED_GUIDE;

        yield call(handleShowToast, action, toastType, analyticsData);
    } catch (e) {
        yield put(toggleSaveGuideFailure(e, id, save));
    }

    isGuideSaving = false;
}

/**
 * Save/unsave article.
 * @param {*} action
 */
function* toggleSaveArticle(action) {
    const {
        id,
        analyticsData,
        getContentAfterSave,
        save,
    } = action;

    try {
        const { article } = action;
        let articleId = id;
        let dataObj = {};
        let cleanArticleId;

        if (article !== null) {
            cleanArticleId = article.id;

            articleId = typeof (cleanArticleId) === 'string' ? btoa(cleanArticleId) : cleanArticleId;

            dataObj = {
                data: {
                    id: article.trueId ? article.trueId : articleId,
                    provider: article.provider,
                    publishDate: article.publishDate ? article.publishDate : article.date,
                    contentType: article.contentType ? article.contentType : null,
                    sourceId: article.sourceId ? article.sourceId : (article.source && article.source.id) ? article.source.id : null,
                    interactionType: article.interactionType,
                    interactionId: article.interactionId ? article.interactionId : null,
                }
            };
        }

        const response = yield call(save ? Api.post : Api.delete, ADD_ARTICLE_TO_SAVES_URL(article.trueId ? article.trueId : articleId), dataObj);

        if (response && response.status && (response.status === 200 || response.status === 'success')) {
            yield put(toggleSaveArticleSuccess(id, save));

            const toastType = save
                ? TYPES.SUCCESSFULLY_SAVED_ARTICLE
                : TYPES.SUCCESSFULLY_UNSAVED_ARTICLE;

            yield call(handleShowToast, action, toastType, analyticsData);

            if (getContentAfterSave) {
                if (window.removeSavesIds && window.removeSavesIds.length > 0) {
                    window.removeSavesIds.pop();
                }

                window.savesComponentUpdatedByCoachMark = true;

                yield call(handleGetSavedContent, action);
            }

            // check if article is expiring
            if (dataObj.data) {
                const publishDate = moment(dataObj.data.publishDate);
                const expireDate = publishDate.clone().add(90, 'days');
                const hoursToExpire = moment.duration(expireDate.diff(moment())).asHours();    
                if (hoursToExpire <= 24 * 15) {
                    yield put(getNotification());
                }
            }

        } else {
            yield put(toggleSaveArticleFailure({}, id));
        }
    } catch (e) {
        yield put(toggleSaveArticleFailure(e, id));
    }
}

/**
 * Save/unsave cecredit.
 * @param {*} action
 */
function* toggleSaveCecredit(action) {
    const {
        id,
        analyticsData,
        getContentAfterSave,
        save,
    } = action;

    try {
        const response = yield call(save ? Api.post : Api.delete, ADD_CECREDIT_TO_SAVES_URL(id, 'CECREDIT'));

        if (response && response.status && response.status === "success") {
            yield put(toggleSaveCecreditSuccess(id, save));

            const toastType = save
                ? TYPES.SUCCESSFULLY_SAVED_CECREDIT
                : TYPES.SUCCESSFULLY_UNSAVED_CECREDIT;

            yield call(handleShowToast, action, toastType, analyticsData);

            if (getContentAfterSave) {
                if (window.removeSavesIds && window.removeSavesIds.length > 0) {
                    window.removeSavesIds.pop();
                }
                window.savesComponentUpdatedByCoachMark = true;

                yield call(handleGetSavedContent, action);
            }
        } else {
            yield put(toggleSaveCecreditFailure(e, id));
        }
    } catch (e) {
        yield put(toggleSaveCecreditFailure(e, id));
    }
}

/**
 * Save/unsave article.
 * @param {*} action
 */
function* toggleSavePlArticle(action) {
    const {
        id,
        analyticsData,
        getContentAfterSave,
        save,
    } = action;

    try {
        const { article } = action;
        let articleId = id;
        let dataObj = {};
        let cleanArticleId;

        if (article != null) {
            //try {
            //    cleanArticleId = typeof(article.id) === 'string' ? atob(article.id) : article.id;
            //} catch (e) {
            cleanArticleId = article.id;
            //}
            articleId = typeof (cleanArticleId) === 'string' ? btoa(cleanArticleId) : cleanArticleId;

            dataObj = {
                data: {
                    id: article.trueId ? article.trueId : articleId,
                    provider: article.provider,
                    publishDate: article.publishDate ? article.publishDate : article.date,
                    contentType: article.contentType ? article.contentType : null,
                    sourceId: article.sourceId ? article.sourceId : (article.source && article.source.id) ? article.source.id : null,
                    interactionType: article.interactionType,
                    interactionId: article.interactionId ? article.interactionId : null,
                }
            };
        }

        const response = yield call(save ? Api.post : Api.delete, ADD_ARTICLE_TO_SAVES_URL(article.trueId ? article.trueId : articleId), dataObj);

        if (response && response.status && response.status === "success") {
            yield put(toggleSavePlArticleSuccess(id, save));

            const toastType = save
                ? TYPES.SUCCESSFULLY_SAVED_ARTICLE
                : TYPES.SUCCESSFULLY_UNSAVED_ARTICLE;

            yield call(handleShowToast, action, toastType, analyticsData);

            if (getContentAfterSave) {
                if (window.removeSavesIds && window.removeSavesIds.length > 0) {
                    window.removeSavesIds.pop();
                }

                window.savesComponentUpdatedByCoachMark = true;

                yield call(handleGetSavedContent, action);
            }
        } else {
            yield put(toggleSavePlArticleFailure(e, id));
        }
    } catch (e) {
        yield put(toggleSavePlArticleFailure(e, id));
    }
}

function* saveSaga() {
    yield all([
        takeEvery(TOGGLE_SAVE_GUIDE, toggleSaveGuide),
        takeEvery(TOGGLE_SAVE_WEBINAR, toggleSaveWebinar),
        takeEvery(TOGGLE_SAVE_ARTICLE, toggleSaveArticle),
        takeEvery(TOGGLE_SAVE_CECREDIT, toggleSaveCecredit),
        takeEvery(TOGGLE_SAVE_PL_ARTICLE, toggleSavePlArticle),
    ]);
}

export default saveSaga;
