import { put, select, takeEvery } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import { collapse } from 'state-management/actions/navigation';

/**
 * Handle global navigation collapse by action.
 */
function* closeNavigation() {
    const isExpanded = yield select(state => state.navigation.isExpanded);

    if (isExpanded) {
        yield put(collapse());
    }
}

function* navigationSaga() {
    yield takeEvery(LOCATION_CHANGE, closeNavigation);
}

export default navigationSaga;
