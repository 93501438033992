import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAllTopics } from 'state-management/actions/allTopics.common';
import { toggleTopicFollow } from 'state-management/actions/followTopic';
import TopicsDisplay from 'components/Topics/Topics';
import * as analytics from 'utils/adobeAnalytics';
import { BreakpointsContext } from 'components/Breakpoints/Breakpoints';
import {
    getCoachMarkStatus,
    dismissCoachMark,
} from 'state-management/actions/coachMark';
import { SITE_SECTION_ALLTOPICS, PAGE_NAME_ALLTOPICS } from 'utils/analyticsConstants';
import scrollHelper from "../../../utils/scrollToTop";

/**
 * All topics page.
 */
class Topics extends React.Component {
    /**
     * Default constructor and state assignment.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.handleFollowChange = this.handleFollowChange.bind(this);
    }

    /**
     * Fetch the top topics at mount, fire page view.
     */
    componentDidMount() {
        scrollHelper.scrollToTop();
        analytics.dispatchPageViewStart(PAGE_NAME_ALLTOPICS, SITE_SECTION_ALLTOPICS);
        this.props.getAllTopics();
        this.props.getCoachMarkStatus();
    }

    /**
     * Fire page view end at destruction.
     */
    componentWillUnmount() {
        analytics.dispatchPageViewEnd(PAGE_NAME_ALLTOPICS, SITE_SECTION_ALLTOPICS);
    }

    /**
     * Handle follow/unfollow event broadcasting.
     * @param {*} id
     */
    handleFollowChange(id) {
        // TODO: pass this method to the child display and then wire in the right event hooks.
        this.props.onCheckboxClick();
        this.props.toggleTopicFollow(id, this.props.subscribedTopics.includes(id));
        
        setTimeout(() => {
            this.props.getCoachMarkStatus();
        }, 1000);
    }

    /**
     * Render the container and child components.
     */
    render() {
        if (this.props.topics.length) {
            return (
                <div data-rel="Topics">
                    <BreakpointsContext.Consumer>
                        {(breakpoint) => {
                            return (
                                <TopicsDisplay
                                    breakpoint={breakpoint}
                                    handleFollowChange={this.handleFollowChange}
                                    {...this.props}
                                />
                            );
                        }}
                    </BreakpointsContext.Consumer>
                </div>
            );
        }

        return null;
    }
}

Topics.propTypes = {
    topics: PropTypes.arrayOf(PropTypes.shape({})),
    subscribedTopics: PropTypes.arrayOf(PropTypes.number),
    getAllTopics: PropTypes.func.isRequired,
    toggleTopicFollow: PropTypes.func.isRequired,
    getCoachMarkStatus: PropTypes.func.isRequired,
    onCheckboxClick: PropTypes.func.isRequired,
    onTopicClick: PropTypes.func.isRequired,
};

Topics.defaultProps = {
    topics: {},
    subscribedTopics: {},
};

const mapStateToProps = state => ({
    topics: state.allTopics.all.categories,
    subscribedTopics: state.basicProfile.topicIds,
});

const mapDispatchToProps = dispatch => ({
    getCoachMarkStatus: () => dispatch(getCoachMarkStatus()),
    getAllTopics: () => dispatch(getAllTopics()),
    toggleTopicFollow: (id, isFollowing) => dispatch(toggleTopicFollow(id, isFollowing)),
    onCheckboxClick: () => dispatch(dismissCoachMark('ALL_TOPICS_FOLLOW', true)),
    onTopicClick: () => dispatch(dismissCoachMark('ALL_TOPICS_TOPIC', true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Topics);
