import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';

export const containerStyles = css`
    margin: 0;
`;

export const checkboxItemsWrapperStyles = css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 10px;

    @media screen and ${FROM_TABLET} {
        padding: 0 36px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 0;
    }
`;

export const checkboxItemStyles = css`
    display: flex;
    flex-basis: 100%;

    @media screen and ${FROM_TABLET} {
        flex-basis: calc(50% - 0.5 * 90px);
        margin-right: 90px;

        &:nth-child(2n) {
            margin-right: 0;
        }
    }

    @media screen and ${FROM_DESKTOP} {
        flex-basis: calc(25% - 0.75 * 24px);
        margin-right: 24px;

        &:nth-child(2n) {
            margin-right: 24px;
        }
    }
`;

export const tabsContainerStyles = css`
    margin: -11px -14px 0;

    @media screen and ${FROM_TABLET} {
        margin: -18px 0 0;
    }
`;
