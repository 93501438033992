import React from 'react';
import PropTypes from 'prop-types';

export const preventClickClassName = 'prevent-click';

const PreventClick = ({ children }) => (
    <div className={preventClickClassName}>{children}</div>
);

PreventClick.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default PreventClick;
