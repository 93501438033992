import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { dismissCoachMark } from 'state-management/actions/coachMark';
import CoachMark from 'components/atomics/molecules/CoachMark/CoachMark';
import { POSITIONS } from 'components/atomics/molecules/CoachMark/constants';
import {
    BreakpointsContext,
    MOBILE,
    TABLET,
    DESKTOP,
} from 'components/Breakpoints/Breakpoints';
import labels from '../../lang/en.json';
import { wrapperStyles } from './styles';

const Wrapper = styled.div`
    ${wrapperStyles}
`;

export const TYPES = {
    ALLTOPICS: 0,
    INFOBAR: 1,
    KEBAB: 2,
    TRENDINGTOPICS: 3,
    PMCOLLECTION: 4,
    PMTOOL: 5,
};

/**
 * Handler for all coach marks by type.
 */
class CoachMarks extends React.Component {
    /**
     * Render the component.
     */
    render() {
        const {
            show,
            children,
            onDismiss,
            isEnabledAllTopics,
            isEnabledInfoBar,
            subscribedSources,
            type,
            showCoachMark,
            isEnabledKebab,
            prerequistDismissedKebab,
            isEnabledTrending,
            prerequistDismissedTrending,
            isEnabledPMCollection,
            isCustomCoachMarkPosition,
            isEnabledPMTool,
            prerequistDismissedPMTool,
            customCoachMarkPositionOption,
        } = this.props;


        /* ALL TOPICS */
        if (type === TYPES.ALLTOPICS) {
            return (
                <BreakpointsContext.Consumer>
                    {(breakpoint) => {
                        /*const placementState = breakpoint === MOBILE
                            ? POSITIONS.TOP_LEFT : POSITIONS.LEFT_CENTER;
                        const forceLeft = breakpoint === MOBILE
                            ? "16px" : "-380px";*/
                            
                        const placementState = POSITIONS.TOP_LEFT;
                        const forceLeft = "16px";

                        return (
                            <CoachMark
                                isVisible={isEnabledAllTopics}
                                placement={placementState}
                                message={labels.translations["allTopics.page.coachMark.topic.label"]}
                                onDecline={() => { onDismiss('ALL_TOPICS_TOPIC') }}
                                offsetLeft={true}
                                forceLeft={forceLeft}
                            >
                                {children}
                            </CoachMark>
                        );
                    }}
                </BreakpointsContext.Consumer>
            );


        /* INFO BAR */
        } else if (type === TYPES.INFOBAR) {
            if (subscribedSources === null) {
                return null;
            }
        
            const coachMarkMsg = subscribedSources.includes(115)
                ? labels.translations["coachMark.label.SUBSCRIBED_TO_WSJ"]
                : labels.translations["coachMark.label.NOT_SUBSCRIBED_TO_WSJ"];

            return (
                <BreakpointsContext.Consumer>
                    {(breakpoint) => {
                        if (breakpoint !== DESKTOP) {
                            return (
                                <CoachMark
                                    isVisible={isEnabledInfoBar && coachMarkMsg && showCoachMark}
                                    placement={POSITIONS.BOTTOM_CENTER}
                                    message={coachMarkMsg}
                                    onDecline={() => { onDismiss('INFO_BAR') }}
                                    offsetLeft={false}
                                    externalHeight={!subscribedSources.includes(115)}
                                >
                                    {children}
                                </CoachMark>
                            );
                        } else {
                            return (
                                <CoachMark
                                    isVisible={isEnabledInfoBar && coachMarkMsg && showCoachMark}
                                    placement={POSITIONS.BOTTOM_RIGHT}
                                    message={coachMarkMsg}
                                    externalHeight={!subscribedSources.includes(115)}
                                    onDecline={() => { onDismiss('INFO_BAR') }}
                                    offsetLeft={false}
                                >
                                    {children}
                                </CoachMark>
                            );
                        }
                    }}
                </BreakpointsContext.Consumer>
            );

        /* KEBAB */
        } else if (type === TYPES.KEBAB) {
            return (
                <BreakpointsContext.Consumer>
                    {(breakpoint) => {
                        if (breakpoint === DESKTOP) {
                            return (
                                <Wrapper>
                                    <CoachMark
                                        isVisible={isEnabledKebab && showCoachMark && prerequistDismissedKebab}
                                        placement={POSITIONS.LEFT_CENTER}
                                        message={labels.translations["coachMark.label.kebab"]}
                                        onDecline={() => { onDismiss('KEBAB') }}
                                        forceRight="45px"
                                        forceTop="-10px"
                                    >
                                        {children}
                                    </CoachMark>
                                </Wrapper>
                            );
                        } else if (breakpoint === TABLET) {
                            return (
                                <Wrapper>
                                    <CoachMark
                                        isVisible={isEnabledKebab && showCoachMark && prerequistDismissedKebab}
                                        placement={POSITIONS.LEFT_CENTER}
                                        message={labels.translations["coachMark.label.kebab"]}
                                        onDecline={() => { onDismiss('KEBAB') }}
                                        offsetLeft={true}
                                        forceRight="45px"
                                        forceTop="-10px"
                                    >
                                        {children}
                                    </CoachMark>
                                </Wrapper>
                            );
                        } else {
                            return (
                                <Wrapper>
                                    <CoachMark
                                        isVisible={isEnabledKebab && showCoachMark && prerequistDismissedKebab}
                                        placement={POSITIONS.TOP_RIGHT}
                                        message={labels.translations["coachMark.label.kebab"]}
                                        onDecline={() => { onDismiss('KEBAB') }}
                                        forceRight="-13px"
                                        forceTop="-224px"
                                    >
                                        {children}
                                    </CoachMark>
                                </Wrapper>
                            );
                        }
                    }}
                </BreakpointsContext.Consumer>
            );
        
        /* TRENDING TOPICS */
        } else if (type === TYPES.TRENDINGTOPICS) {
            return (
                <BreakpointsContext.Consumer>
                    {(breakpoint) => {
                        if (breakpoint === MOBILE) {
                            return (
                                <CoachMark
                                    isVisible={isEnabledTrending && prerequistDismissedTrending}
                                    placement={POSITIONS.TOP_LEFT}
                                    message={labels.translations["coachMark.label.trendingTopics"]}
                                    onDecline={() => { onDismiss('TRENDING_TOPICS') }}
                                    offsetLeft={true}
                                    forceTop="-162px"
                                >
                                    {children}
                                </CoachMark>
                            );
                        } else if (breakpoint === TABLET) {
                            return (
                                <CoachMark
                                    isVisible={isEnabledTrending && prerequistDismissedTrending}
                                    placement={POSITIONS.TOP_LEFT}
                                    message={labels.translations["coachMark.label.trendingTopics"]}
                                    onDecline={() => { onDismiss('TRENDING_TOPICS') }}
                                    offsetLeft={true}
                                    forceTop="-143px"
                                >
                                    {children}
                                </CoachMark>
                            );
                        } else {
                            return (
                                <CoachMark
                                    isVisible={isEnabledTrending && prerequistDismissedTrending}
                                    placement={POSITIONS.TOP_LEFT}
                                    message={labels.translations["coachMark.label.trendingTopics"]}
                                    onDecline={() => { onDismiss('TRENDING_TOPICS') }}
                                    offsetLeft={true}
                                    forceTop="-146px"
                                >
                                    {children}
                                </CoachMark>
                            );
                        }
                    }}
                </BreakpointsContext.Consumer>
            );
        } else if (type === TYPES.PMCOLLECTION) {
            return (
                <BreakpointsContext.Consumer>
                    {(breakpoint) => {
                        if (breakpoint === MOBILE) {
                            return (
                                <CoachMark
                                    isVisible={isEnabledPMCollection && show}
                                    placement={POSITIONS.BOTTOM_CENTER}
                                    message={labels.translations["coachMark.label.pmCollection"]}
                                    onDecline={() => { onDismiss('PM_COLLECTIONS') }}
                                >
                                    {children}
                                </CoachMark>
                            );
                        } else {
                            return (
                                <CoachMark
                                    isVisible={isEnabledPMCollection && show}
                                    placement={POSITIONS.BOTTOM_LEFT}
                                    message={labels.translations["coachMark.label.pmCollection"]}
                                    onDecline={() => { onDismiss('PM_COLLECTIONS') }}
                                    offsetLeft={true}
                                >
                                    {children}
                                </CoachMark>
                            );
                        }
                    }}
                </BreakpointsContext.Consumer>
            );
        } else if (type === TYPES.PMTOOL) {
            return (
                <BreakpointsContext.Consumer>
                    {(breakpoint) => {
                        if (breakpoint === MOBILE) {
                            return (
                                <CoachMark
                                    isVisible={isEnabledPMTool && show && prerequistDismissedPMTool}
                                    placement={POSITIONS.BOTTOM_RIGHT}
                                    message={labels.translations["coachMark.label.pmToolIcon"]}
                                    onDecline={() => { onDismiss('PM_TOOLS') }}
                                    offsetLeft={true}
                                    forceLeft="-306px"
                                    forceTop="20px"
                                >
                                    {children}
                                </CoachMark>
                            );
                        } else {
                            return !isCustomCoachMarkPosition  ? (
                                <CoachMark
                                    isVisible={isEnabledPMTool && show && prerequistDismissedPMTool}
                                    placement={POSITIONS.BOTTOM_RIGHT}
                                    message={labels.translations["coachMark.label.pmToolIcon"]}
                                    onDecline={() => { onDismiss('PM_TOOLS') }}
                                    offsetLeft={true}
                                    forceRight="-22px"
                                    forceTop="20px"
                                >
                                    {children}
                                </CoachMark>
                            ) : (
                                <CoachMark
                                    isVisible={isEnabledPMTool && show && prerequistDismissedPMTool}
                                    message={labels.translations["coachMark.label.pmToolIcon"]}
                                    onDecline={() => { onDismiss('PM_TOOLS') }}
                                    {...customCoachMarkPositionOption}
                                >
                                    {children}
                                </CoachMark>
                            );
                        //BELOW IS OLD DESKTOP POSITION IF NEEDED BACK..
                        }/* else {
                            return (
                                <CoachMark
                                    isVisible={isEnabledPMTool && show && prerequistDismissedPMTool}
                                    placement={POSITIONS.RIGHT_CENTER}
                                    message={labels.translations["coachMark.label.pmToolIcon"]}
                                    onDecline={() => { onDismiss('PM_TOOLS') }}
                                    forceTop="16px"
                                >
                                    {children}
                                </CoachMark>
                            );
                        }*/
                    }}
                </BreakpointsContext.Consumer>
            );
        }
    }
}

CoachMarks.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    onDismiss: PropTypes.func,
    isEnabledAllTopics: PropTypes.bool,
    isEnabledInfoBar: PropTypes.bool,
    isEnabledKebab: PropTypes.bool,
    isEnabledTrending: PropTypes.bool,
    isEnabledPMCollection: PropTypes.bool,
    isEnabledPMTool: PropTypes.bool,
    type: PropTypes.number,
    showCoachMark: PropTypes.bool,
    show: PropTypes.oneOfType([
        PropTypes.bool, 
        PropTypes.number,
    ]),
    prerequistDismissedKebab: PropTypes.bool,
    prerequistDismissedTrending: PropTypes.bool,
    prerequistDismissedPMTool: PropTypes.bool,
    isCustomCoachMarkPosition: PropTypes.bool,
    customCoachMarkPositionOption: PropTypes.object,
};

CoachMarks.defaultProps = {
    children: null,
    onDismiss: () => {},
    isEnabledAllTopics: false,
    isEnabledInfoBar: false,
    isEnabledKebab: false,
    isEnabledTrending: false,
    isEnabledPMCollection: false,
    isEnabledPMTool: false,
    type: null,
    showCoachMark: false,
    show: false,
    prerequistDismissedKebab: false,
    prerequistDismissedTrending: false,
    prerequistDismissedPMTool: false,
    isCustomCoachMarkPosition: false,
};

const mapStateToProps = state => ({
    isEnabledPMCollection: state.coachMark.display.PM_COLLECTIONS,
    isEnabledAllTopics: state.coachMark.display.ALL_TOPICS_TOPIC,
    isEnabledInfoBar: state.coachMark.display.INFO_BAR,
    isEnabledKebab: state.coachMark.display.KEBAB,
    isEnabledTrending: state.coachMark.display.TRENDING_TOPICS,
    isEnabledPMTool: state.coachMark.display.PM_TOOLS,
    prerequistDismissedPMTool: !state.coachMark.display.PM_COLLECTIONS,
    prerequistDismissedTrending: !state.coachMark.display.KEBAB && !state.coachMark.display.INFO_BAR,
    prerequistDismissedKebab: !state.coachMark.display.INFO_BAR,
    subscribedSources: state.basicProfile.sourceIds,
});

const mapDispatchToProps = dispatch => ({
    onDismiss: (typeString) => dispatch(dismissCoachMark(typeString, true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CoachMarks);
