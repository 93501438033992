import { css } from 'styled-components';
import {
    COLORS,
    FONTS,
    ANIMATIONS,
} from 'utils/variables';
import {
    SMALL_MOBILE,
    FROM_DESKTOP,
    FROM_TABLET,
} from '../../../../utils/mediaQueries';
import {
    TYPE_INPRACTICE,
} from './SkinnyListGuideCard';

export const imageOverlayStyles = css`
    opacity: 0.5;
    background-image: linear-gradient(45deg, ${COLORS.OCEAN}, ${COLORS.SAPPHIRE}); /* NON standard colors per IAs request */
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
`;

export const titleStyles = css`
    color: ${COLORS.BLACK};
    flex: 1;
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.1px;
    line-height: 20px;
    margin: 0;
    margin-bottom: 10px;
    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.TITLE.TIME.SECONDS}s all;
    word-break: break-word;
    padding-bottom: 30px;

    &:hover {
        color: ${COLORS.MEDIUM_GRAY2};
    }

    @media screen and ${FROM_TABLET} {
        margin-bottom: 0px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 10px;
        
    }

    &.news-not-visited {
        opacity: 1;
    }
    
    &.news-visited {
        opacity: 0.6;

        &:hover {
             opacity: 1;
             color: ${COLORS.BLACK};
        }
    }
`;

export const containerStyles = css`
    background-color: transparent;
    position: relative;
    border-bottom: 1px solid ${COLORS.LIGHT_GRAY2};
    color: ${COLORS.BLACK};
    display: flex;
    padding: 24px 0 21px 0;
    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.CONTAINER.TIME.SECONDS}s all;

    &:hover {
        img {
            transform: scale(1.05) translate(-50%, -50%);
        }
    }

    @media screen and ${SMALL_MOBILE} {
        width:auto;
        max-height:177px;
        padding: 24px 0 8px 0;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 23px 0 24px 0;
    }
`;

export const dateAndTopicContainerStyles = css`
    display: flex;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: ${COLORS.DARK_GRAY1};

    @media screen and ${SMALL_MOBILE} {
        letter-spacing: 0.6px;
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 10px;

        p {
            margin-bottom:0;
            margin-top: 0;
        }
    }

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 15px;
    }
`;

export const dateContainerStyles = css`
    color: ${COLORS.DARK_GRAY1};
    padding-right: 24px;
    font-size: 12px;
`;

export const topicLinkStyles = css`
    color: ${COLORS.SAPPHIRE};
    flex: 1;
    text-align: right;
    text-decoration: none;
    transition: 0.33s all;

    &:hover {
        opacity: 0.6;
    }
`;

export const imageContainerStyles = css`
    height: 61px;
    overflow: hidden;
    width: 61px;
    position: relative;

    @media screen and ${FROM_DESKTOP} {
        height: 129px;
        width: 129px;
    }

    &.news-not-visited {
        opacity:1;
    }

    &.news-visited {
        opacity:0.6;
    }

    img {
        height: 100%;
        object-fit: cover;
        transition: 0.33s all;
        width: 100%;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: transform 0.2s;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            width: 150%;           
        }

        ${props => props.type === TYPE_INPRACTICE && css`
            filter: grayscale(100%);
        `};
    }
`;

export const contentContainerStyles = css`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 23px;
    width: calc(100% - 84px);

    @media screen and ${FROM_DESKTOP} {
        padding-left: 30px;
        width: calc(100% - 159px);
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        min-height: 110px;
    }
`;

export const kebabAndSourceAnchorsLinkStyles = css`
    font-family: AvenirNext;
    font-size: 12px;
    font-weight: bold;
    color: ${COLORS.SAPPHIRE};
    text-decoration: none;
    z-index: 7;
`;
