import { css } from 'styled-components';
import { FROM_TABLET } from 'utils/mediaQueries';
import {
    FONT_AVENIR_NEXT,
    COLORS,
    FONTS,
    ANIMATIONS,
} from 'utils/variables';

export const rulesContainerStyles = css`
    margin-top: -18px;

    @media screen and ${FROM_TABLET} {
        margin-top: 0;
    }
`;

export const disclaimerStyles = css`
    font-size: 12px;
    font-weight: normal;
    font-style: italic;
    margin-top: 8px;
`;

export const rulesDescStyles = css`
    color: ${COLORS.BLACK};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 15px;
    font-weight:500;
    font-weight: normal;
    line-height: 16px;
    letter-spacing: 0.1px;
    word-wrap: break-word;
`;

export const rulesListStyles = css`
    list-style: none;
    padding: 0;
`;

export const ruleStyles = css`
    color: ${COLORS.MEDIUM_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.1px;
    word-wrap: break-word;

    /* stylelint-disable */

    ${({ theme }) => theme === 'small' && css`
        @media (min-width: 50px) {
            font-size: 15px;
            letter-spacing: normal;
            line-height: 2.58;
        }
    `};

    ${props => props.ruleState !== undefined && css`
        @media (max-width: 352px) {
            max-width: 90%;
        }

        @media (min-width: 768px) and (max-width: 814px) {
            max-width: 90%;
        }
    `};

    ${props => props.ruleState !== undefined && props.ruleState && css`
        color: ${COLORS.SAPPHIRE};
    `};

    ${props => props.ruleState !== undefined && !props.ruleState && css`
        color: ${COLORS.ALERT_RED};
    `};

    /* stylelint-enable */
`;

export const ruleIconStyles = css`
    margin-left: 12px;
`;

export const successIconStyles = css`
    fill: ${COLORS.SAPPHIRE};
`;

export const errorIconStyles = css`
    color: ${COLORS.ALERT_RED};
`;
