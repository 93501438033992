import { css } from 'styled-components';
import { outerFocusMixin } from 'components/FocusVisibility/styles';
import {
    COLORS,
} from './../../../../utils/variables';

export const defaultLinkStyles = css`
    ${outerFocusMixin};
`;

export const indicatorStyles = css`
    width: 46px;
    height: 46px;
    border: 2px solid ${COLORS.LIGHT_GRAY2};
    border-radius: 50%;
    background: ${COLORS.WHITE};
    right: 40px;
    bottom: 40px;
`;

export const backToTopIconStyles = css`
    width: 18px;
    height: 18px;
    margin-left: 14px;
    margin-top: 14px;
    transform:rotate(180deg);
`;

export const linkTitleStyles = css`
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.6px;
    color:${COLORS.SAPPHIRE};
    display: block;
    right: 25px;
    bottom: 47px;
`;



