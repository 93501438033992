import { ROUTES } from 'containers/App/constants';

export const primary = [
    {
        appearance: 'blue',
        id: 'news',
        title: 'privateLayout.navigation.news',
        to: ROUTES.NEWS,
    },
    {
        appearance: 'sky-blue',
        id: 'practice',
        title: 'privateLayout.navigation.practice',
        to: ROUTES.PRACTICE_MANAGEMENT,
    },

    /*{
        appearance: 'cyan',
        id: 'collections',
        title: 'privateLayout.navigation.watchlist',
        to: ROUTES.WATCHLIST,
    },*/
];

export const secondary = [
    {
        id: 'profile',
        title: 'privateLayout.navigation.account',
        to: "/profile/account",
    },
    {
        id: 'profile',
        title: 'privateLayout.navigation.account',
        to: "/profile",
    },
    {
        id: 'sign-out',
        title: 'privateLayout.navigation.signOut',
        to: ROUTES.SIGN_OUT,
    },

    /*
     {
        id: 'profile',
        title: 'privateLayout.navigation.account',
        to: ROUTES.PROFILE,
    },
    */
];

export const sideMenu = [
    {
        appearance: 'sky-blue',
        id: "account",
        title: "Account",
        to: "/profile/account",
        menu: "account"
    },
    {
        appearance: 'sky-blue',
        id: "preferences",
        title: "Preferences",
        to: "/profile/preferences",
        menu: "preferences"
    },
    {
        appearance: 'sky-blue',
        id: "practiceInfo",
        title: "Practice Info",
        to: "/profile/practice-info",
        menu: "practice-info"
    },
    {
        appearance: 'sky-blue',
        id: "saves",
        title: "Saves",
        to: "/profile/saves",
        menu: "saves"
    },
];
