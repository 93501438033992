import { css, keyframes } from 'styled-components';

import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import { SIZES, THEMES } from 'components/FieldText/constants';
import {
    COLORS,
    FONTS,
    ANIMATIONS,
} from 'utils/variables';

/* stylelint-disable */
const autofillKeyframes = keyframes``;
/* stylelint-enable */
const ERROR_COLOR = `${COLORS.ALERT_RED}`;
const ACTIVE_COLOR = `${COLORS.SAPPHIRE}`;

export const wrapperStyles = css`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding-top: 11px;
    position: relative;
    color: ${COLORS.DARK_GRAY2};

    @media screen and ${FROM_TABLET} {
        padding-top: 11px;
    }

    &:focus-within{
        color: ${COLORS.SAPPHIRE};
    }
`;

export const inputStyles = ({ variant }) => css`
    background-color: transparent;
    border-radius: 0;
    border-style: solid;
    border-width: 0 0 1px;
    box-sizing: border-box;
    color: ${COLORS.DARKER_GREY2};
    display: inline-block;
    font-family: inherit;
    margin-bottom: 16px;
    margin-top: 11px;
    padding-bottom: 8px;
    width: 100%;
    line-height: 40px;
    caret-color: ${COLORS.DARK_GREY2} !important;

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 10px;
    }

    &::-ms-clear,
    &::-ms-reveal {
        display: none;
    }

    &[type="password"] {
        /* font-size: 26px;
        letter-spacing: 8px; */
    }

    &:focus {
        outline: 0;
        border-width: 0 0 2px !important;
        color: ${COLORS.DARK_GRAY2} !important;
        border-color: ${COLORS.SAPPHIRE};
        caret-color: ${COLORS.DARK_GRAY2} !important;
    }

    &:-webkit-autofill {
        animation-name: ${autofillKeyframes};
        border-color: ${COLORS.SAPPHIRE} ;
    }

    /* stylelint-disable */

    ${variant.showFieldIcon && css`
        padding-right: 35px;
    `}

    ${variant.size === SIZES.SMALL && css`
        font-size: 18px;
        height: 40px;
        padding-bottom: 5px;
    `}

    ${variant.size === SIZES.MEDIUM && css`
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.03px;

        @media screen and ${FROM_TABLET} {
            font-size: 20px;
            font-weight: 500;
            letter-spacing: -0.04px;
        }
    `}

    ${variant.size === SIZES.LARGE && css`
        font-size: 18px;

        @media screen and ${FROM_TABLET} {
            font-size: 24px;
        }

        @media screen and ${FROM_DESKTOP} {
            font-size: 29px;
        }
    `}

    ${!variant.isInvalid && css`
        &:focus {
            border-color: ${ACTIVE_COLOR};
        }

        /* stylelint-disable */

        ${variant.showFieldIcon && css`
            border-color: ${ACTIVE_COLOR};
        `}

        /* stylelint-enable */
    `}

    ${variant.isInvalid && css`
        border-color: ${ERROR_COLOR} !important;
        border-bottom-width: 1px !important;
    `}

    ${variant.theme !== THEMES.DARK && !variant.isInvalid && !variant.showFieldIcon && css`
        border-color: ${COLORS.MEDIUM_GRAY2};

        ${variant.fieldValue && css`
            border-color: ${COLORS.SAPPHIRE};
        `}
    `}

    ${variant.theme === THEMES.DARK && css`
        border-color: ${COLORS.SAPPHIRE};
        font-weight: 500;

        &::placeholder {
            color: ${COLORS.MEDIUM_GRAY2};;
        }
    `}

    /* stylelint-enable */
`;

export const iconStyles = css`
    display: none;
    position: absolute;
    right: 2px;
    top: 23px;

    @media screen and ${FROM_TABLET} {
        right: 7px;
        top: 42px;
    }

    @media screen and ${FROM_DESKTOP} {
        top: 40px;
    }
`;

export const labelStyles = ({ variant }) => css`
    position: absolute;
    top: 0;

    /* stylelint-disable */

    ${variant.size === SIZES.SMALL && css`
        /* stylelint-disable*/
        font-size: 10px;

        ${variant.floatingLabel && !variant.isActive && css`
        font-size: 10px;
        color: ${COLORS.SAPPHIRE};
    `}



        ${!variant.isActive && variant.floatingLabel && css`
            font-size: 18px;
            color: ${COLORS.SAPPHIRE};
        `}

        ${variant.isActive && variant.floatingLabel && css`
            font-size: 12px;
            color: ${COLORS.SAPPHIRE};
        `}

        /* stylelint-enable */
    `}

    ${variant.size === SIZES.MEDIUM && css`
        /* stylelint-disable */

        ${!variant.floatingLabel && css`
            font-size: 12px;
        `}

        ${!variant.isActive && variant.floatingLabel && css`
            color: ${COLORS.MEDIUM_GRAY2};
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.03px;

            @media screen and ${FROM_TABLET} {
                font-size: 20px;
                font-weight: 500;
                letter-spacing: -0.04px;
                top: -5px;
            }

            @media screen and ${FROM_DESKTOP} {
                top: 0;
            }
        `}

        ${variant.isActive && variant.floatingLabel && css`
            font-size: 12px;
            letter-spacing: 3px;
        `}

        /* stylelint-enable */
    `}

    ${variant.floatingLabel && css`
        transform: translateY(28px);
        transition: transform ease-out 50ms;

        /* stylelint-disable */

        ${variant.isActive && css`
            font-weight: 600;
            text-transform: uppercase;
            transform: translateY(0);

            /* Need to duplicate value to prevent incorrect styling and !important use */

            @media screen and ${FROM_TABLET} {
                transform: translateY(0);
            }
        `}

        /* stylelint-enable */
    `}

    ${!variant.floatingLabel && css`
        font-weight: 600;
        text-transform: uppercase;
    `}

    ${variant.isInvalid && css`
        color: ${ERROR_COLOR};
    `}

    /* stylelint-enable */
`;

export const successIconStyles = css`
    fill: ${COLORS.SAPPHIRE};
`;

export const errorIconStyles = css`
    color: ${COLORS.ALERT_RED};
    fill: ${COLORS.ALERT_RED};
`;
