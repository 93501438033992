import React from 'react';
import { Link as InternalLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { defaultLinkStyles } from 'components/atomics/atoms/Button/styles';
import { MOUSE_MIDDLE } from 'utils/keyCodes';
import scrollHelper from 'utils/scrollToTop';
import {
    IconSeeMore,
    IconPlus,
    CheckIconSmall,
    IconLightning,
    IconArrowDown,
    Download,
    IconPDF,
} from 'components/atomics/atoms/Icons/Icons';

export const TYPE_PRIMARY_LARGE = 'type/primary';
export const TYPE_SECONDARY_MEDIUM_LARGE = 'type/primaryInverted';
export const TYPE_PRIMARY_LARGE_LARGE = 'type/primaryLarge';
export const TYPE_PRIMARY_LARGE_MINI = 'type/primaryMini';
export const TYPE_SECONDARY_MEDIUM = 'type/secondary';
export const TYPE_PRIMARY_MEDIUM = 'type/secondayMedium';
export const TYPE_SECONDARY_SMALL = 'type/secondaryNavigational';
export const TYPE_PILL = 'type/pill';
export const TYPE_GHOST_RIGHT = 'type/flatRightBright';
export const TYPE_GHOST_LEFT = 'type/flatLeftBright';
export const TYPE_DOWNLOAD_LEFT_BRIGHT = 'type/downloadLeftBright';
export const TYPE_PDF_LEFT_BRIGHT = 'type/pdfLeftBright';
export const TYPE_DOWNLOAD_LEFT = 'type/downloadLeft';
export const TYPE_GHOST_LEFT_WHITE = 'type/flatLeftBrightWhite';
export const TYPE_FLAT_LEFT = 'type/flatLeft';
export const TYPE_LIST = 'type/underline';
export const TYPE_TAB = 'type/tab';
export const TYPE_GHOST = 'type/ghostBright';
export const TYPE_BACK_TO_TOP = 'type/BackTotop';
export const TYPE_ICON = 'type/Icon';
export const TYPE_SECONDARY_SMALL_WHITE = 'type/Dark';
export const TYPE_MULTIADD = 'type/MultiAdd';
export const TYPE_SHARECG_NAVIGATIONAL = 'type/shareCGNavigational';

const StyledExternalLink = styled.a`
    ${defaultLinkStyles};
`;

const StyledInternalLink = styled(InternalLink)`
    ${defaultLinkStyles};
`;

const handleMouseDown = (e, handler) => {
    if (e.nativeEvent.which === MOUSE_MIDDLE) {
        handler();
    }
};

/**
 * Button artifact, styled per types, per exported constants.
 * @param {*} props 
 */
const Button = (props) => {
    const {
        children,
        className,
        location,
        disabled,
        to,
        onClick,
        staticContext,
        type,
        selected,
        dataPage,
        dontScroll,
        dataRel,
        noAnchor,
        forceNewWindow,
        dataAnalyticsId,
        dataAnalyticsLabel,
        dataAnalyticsPlacement,
        id,
        ...other
    } = props;
    const isExternalLink = (to && (to.startsWith('http') || to.startsWith('#')) || to === null);
    const isEmailLink = to && to.startsWith('mailto:');
    const isDownloadLink = to && to.startsWith('itms-services:');
    const currentPath = location ? location.pathname + location.search + location.hash : window.location.href;
    const isSamePath = to === currentPath;

    const isSelected = (selected === true || selected === 'true');

    if (isExternalLink || isEmailLink || isDownloadLink || forceNewWindow) {
        const target = ((isExternalLink && (to !== null && !to.startsWith('#'))) || forceNewWindow) ? '_blank' : '_self';
        
        return (
            <StyledExternalLink
                id={id}
                data-analytics-placement={dataAnalyticsPlacement}
                data-analytics-label={dataAnalyticsLabel}
                data-analytics-id={dataAnalyticsId}
                role="button"
                type={type}
                disabled={disabled}
                selected={isSelected}
                className={className}
                href={noAnchor ? null : to}
                target={target}
                onMouseDown={e => handleMouseDown(e, onClick)}
                onClick={onClick}
                rel="noopener noreferrer"
                data-state={isSelected.toString()}
                datapage={dataPage}
                data-rel={dataRel}
            >
                {type === TYPE_LIST
                && <IconLightning />}
                {type === TYPE_PILL
                && <span>
                    <IconPlus data-rel="plus" />
                    <CheckIconSmall data-rel="check" />
                </span>}
                {(type === TYPE_FLAT_LEFT || type === TYPE_GHOST_LEFT || type === TYPE_GHOST_LEFT_WHITE)
                && <IconSeeMore />}
                {(type === TYPE_DOWNLOAD_LEFT || type === TYPE_DOWNLOAD_LEFT_BRIGHT)
                && <Download />}
                {(type === TYPE_PDF_LEFT_BRIGHT)
                && <IconPDF />}
                {type !== TYPE_TAB && <span>{children}</span>}
                {type === TYPE_TAB && <React.Fragment>
                    {isSelected && <span>{children}</span>}{!isSelected && <span data-aspect="trans">{children}</span>}{!isSelected && <span>{children}</span>}{isSelected && <span/>}
                </React.Fragment>}
                {(type === TYPE_GHOST_RIGHT || type === TYPE_GHOST_RIGHT)
                && <IconSeeMore />}
                {(type === TYPE_LIST || type === TYPE_TAB)
                && <span/>}
            </StyledExternalLink>
        );
    }

    /**
     * Handle internal link clicks, scroll to top if same page.
     * @param {*} e 
     */
    const handleInternalClick = (e) => {
        if (disabled || type === TYPE_PILL) {
            e.preventDefault();
        }

        if (isSamePath) {
            scrollHelper.scrollToTop();
        }

        onClick(e);
    };

    return (
        <StyledInternalLink
            id={id}
            data-analytics-placement={dataAnalyticsPlacement}
            data-analytics-label={dataAnalyticsLabel}
            data-analytics-id={dataAnalyticsId}
            type={type}
            disabled={disabled}
            selected={isSelected}
            className={className}
            to={to}
            onClick={!dontScroll ? handleInternalClick : onClick}
            onMouseDown={e => handleMouseDown(e, onClick)}
            replace={isSamePath}
            datapage={dataPage}
            {...other}
        >
            {type === TYPE_MULTIADD
                && <IconPlus />}
            {type === TYPE_LIST
                && <IconLightning />}
            {type === TYPE_PILL
            && <span>
                <IconPlus data-rel="plus" />
                <CheckIconSmall data-rel="check" />
            </span>}
            {(type === TYPE_FLAT_LEFT || type === TYPE_GHOST_LEFT || type === TYPE_GHOST_LEFT_WHITE)
            && <IconSeeMore />}
             {(type === TYPE_DOWNLOAD_LEFT || type === TYPE_DOWNLOAD_LEFT_BRIGHT)
            && <Download />}
            <span>{children}</span>
            {(type === TYPE_GHOST_RIGHT || type === TYPE_GHOST_RIGHT)
            && <IconSeeMore />}
            {(type === TYPE_LIST || type === TYPE_TAB)
            && <span/>}
             {(type === TYPE_BACK_TO_TOP)
           && <IconArrowDown />}
        </StyledInternalLink>
    );
};

Button.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    location: PropTypes.shape({
        hash: PropTypes.string,
        pathname: PropTypes.string,
        search: PropTypes.string,
    }).isRequired,
    to: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    selected: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
    ]),
    dontScroll: PropTypes.bool,
    dataRel: PropTypes.string,
    noAnchor: PropTypes.bool,
    forceNewWindow: PropTypes.bool,
    dataAnalyticsPlacement: PropTypes.string,
    dataAnalyticsLabel: PropTypes.string,
    dataAnalyticsId: PropTypes.string,
    id: PropTypes.string,
};

Button.defaultProps = {
    className: '',
    children: null,
    to: '#',
    onClick: () => { },
    type: TYPE_PRIMARY_LARGE,
    disabled: false,
    selected: false,
    dontScroll: false,
    dataRel: null,
    noAnchor: false,
    forceNewWindow: false,
    dataAnalyticsPlacement: null,
    dataAnalyticsLabel: null,
    dataAnalyticsId: null,
    id: null,
    location: window.location,
};

export const BasicButton = Button;

export default withRouter(Button);
