import * as type from 'state-management/constants/scroll';

const initialState = {
    shouldScrollToTop: false,
};

function scrollReducer(state = initialState, action = {}) {
    switch (action.type) {
    case type.SET_SCROLL_TO_TOP:
        return {
            ...state,
            shouldScrollToTop: action.shouldScrollToTop,
        };
    default:
        return state;
    }
}

export default scrollReducer;
