import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Button from 'components/atomics/atoms/Button/Button';
import {
    TYPE_PRIMARY_LARGE, TYPE_GHOST
} from 'components/atomics/atoms/Button/Button';
import { connect } from 'react-redux';
import {
    containerStyles,
    titleStyles,
    descriptionStyles
} from './styles';

export const Container = styled.div`
    ${containerStyles}
`;

export const TitleText = styled.div`
     ${titleStyles}
`;

export const Description = styled.div`
     ${descriptionStyles}
`;

class NewsPreferences extends React.Component {
    /**
     * Render the modal and underlying contents.
     */
    render() {
        return (
            <Container>
                <TitleText>
                    <FormattedMessage id="webinar.preferences.title" />
                </TitleText>
                <Description>
                <FormattedMessage id="webinar.preferences.description" />
                    </Description>
                <Button
                    type={TYPE_PRIMARY_LARGE}
                    to="#"
                    onClick={() => {}}
                >
                   <FormattedMessage id="webinar.preferences.setPrefernces" />
                </Button>
                <Button
                    type={TYPE_GHOST}
                    to="#"
                    onClick={() => {}}
                >
                    <FormattedMessage id="webinar.preferences.dontSet" />
                </Button>
            </Container>
        );
    }
}

NewsPreferences.propTypes = {
}

NewsPreferences.defaultProps = {
}

const mapDispatchToProps = dispatch => ({});

const mapState = state => ({});

export default connect(mapState, mapDispatchToProps)(NewsPreferences);