import * as type from 'state-management/constants/optin.common';

const initialState = {
    active: '',
    optIn: '',
    remindUserLater: false,
};

function optinReducer(state = initialState, action = {}) {
    switch (action.type) {
    case type.SET_REMINDER:
        return {
            ...state,
            remindUserLater: true,
        };
    case type.GET_OPTIN_SUCCESS:
        return {
            ...state,
            active: action.data.active,
            optIn: action.data.optIn,
        };
    case type.SAVE_ANSWER_SUCCESS:
        return {
            ...state,
            items: action.data,
        };
    default:
        return state;
    }
}


export default optinReducer;
