import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import styled from 'styled-components';
import { Accordion } from 'components';
import {
    DATE_SECTION, SOURCES_SECTION, TOPICS_SECTION, TYPE_SECTION,
} from 'state-management/constants/searchFilter';
import Button from 'components/Button/Button';
import {
    clearAllButtonStyles,
    doneButtonStyles,
    footerStyles,
    wrapperStyles,
    mobileContainerStyles,
} from 'containers/Search/SearchFilters/styles';
import { clearButtonStyles } from 'containers/Search/SearchFilterSection/styles';
import DateSearchFilterItems from 'containers/Search/DateSearchFilterItems/DateSearchFilterItems';
import SearchFilterItems from 'containers/Search/SearchFilterItems/SearchFilterItems';
import NoResultsAccordion from 'containers/Search/NoResultsAccordion/NoResultsAccordion';

const StyledContainer = styled.div`
    ${mobileContainerStyles}
`;

const StyledWrapper = styled.div`
    ${wrapperStyles}
`;

const StyledFooter = styled.div`
    ${footerStyles}
`;

const StyledClearAllButton = styled(Button)`
    ${clearAllButtonStyles}
`;

const StyledDoneButton = styled(Button)`
    ${doneButtonStyles}
`;

const StyledClearButton = styled(Button)`
    ${clearButtonStyles}
`;

/**
 * Mobile version of search page filters menu.
 */
class MobileSearchFilters extends React.Component {
    static noSourcesTranslationId = 'search.filters.sections.sources.noResultsShort';

    static noTopicsTranslationId = 'search.filters.sections.topics.noResultsShort';

    state = {
        openSectionId: null,
    };

    /**
     * Get a localized string by ID.
     * @param {*} id 
     */
    getTranslation(id) {
        const { intl: { formatMessage } } = this.props;

        return formatMessage({ id });
    }

    /**
     * Gather all selected date filters.
     */
    getSelectedDateItem = () => {
        const { date } = this.props.sections;
        return date.items.find(item => item.checked);
    };

    /**
     * Get the search filter menu title.
     */
    getTitle = section => this.getTranslation(`search.filters.sections.${section.title}`);

    /**
     * Handle toggles to checkboxes.
     */
    handleSectionToggle = (sectionId) => {
        this.setState(prevState => ({
            openSectionId: sectionId !== prevState.openSectionId && sectionId,
        }));
    };

    /**
     * Render no results filter version.
     */
    renderNoResults = (section, noResultsTranslationId) => {
        const title = this.getTitle(section);
        const subtitle = noResultsTranslationId
            ? this.getTranslation(noResultsTranslationId) : undefined;

        return (
            <NoResultsAccordion
                title={title}
                subtitle={subtitle}
            />
        );
    };

    /**
     * Render a single section of filters menu.
     */
    renderSection = (section, noResultsTranslationId) => {
        const { clearSection, toggleItem } = this.props;

        if (!section.items.length) {
            return this.renderNoResults(section, noResultsTranslationId);
        }

        const selectedFilters = section.items.filter(item => item.checked).length;
        const title = this.getTitle(section);
        const subtitle = selectedFilters ? `(${selectedFilters})` : undefined;

        return (
            <Accordion
                isOpen={this.state.openSectionId === section.title}
                onAccordionToggle={() => this.handleSectionToggle(section.title)}
                title={title}
                subtitle={subtitle}
                renderContent={() => (
                    <React.Fragment>
                        {!!selectedFilters && (
                            <StyledClearButton
                                id={`clear-${section.title}-section`}
                                onClick={() => clearSection(section.title)}
                            >
                                <FormattedMessage id="search.filters.clear" />
                            </StyledClearButton>
                        )}
                        <SearchFilterItems section={section} onToggleItem={toggleItem} />
                    </React.Fragment>
                )}
            />
        );
    };

    /**
     * Render the date specific section of filters menu.
     */
    renderDateSection = (section) => {
        const { isDateSectionStateInitial, resetSection, toggleDateItem } = this.props;

        const selectedDateItem = this.getSelectedDateItem();

        const title = this.getTitle(section);

        const subtitle = `(${this.getTranslation(selectedDateItem.translationId)})`;

        return (
            <Accordion
                isOpen={this.state.openSectionId === section.title}
                onAccordionToggle={() => this.handleSectionToggle(section.title)}
                title={title}
                subtitle={subtitle}
                renderContent={() => (
                    <React.Fragment>
                        {!isDateSectionStateInitial && (
                            <StyledClearButton
                                id={`clear-${section.title}-section`}
                                onClick={() => resetSection(section.title)}
                            >
                                <FormattedMessage id="search.filters.reset" />
                            </StyledClearButton>
                        )}
                        <DateSearchFilterItems section={section} onToggleItem={toggleDateItem} />
                    </React.Fragment>
                )}
            />
        );
    };

    /**
     * Render this and underlying components.
     */
    render() {
        const {
            clearAll,
            handleDone,
            sections,
            selectedFiltersCount,
        } = this.props;

        return (
            <StyledWrapper>
                <StyledContainer>
                    {this.renderSection(sections[TYPE_SECTION])}
                    {this.renderDateSection(sections[DATE_SECTION])}
                    {this.renderSection(
                        sections[TOPICS_SECTION],
                        MobileSearchFilters.noTopicsTranslationId,
                    )}
                    {this.renderSection(
                        sections[SOURCES_SECTION],
                        MobileSearchFilters.noSourcesTranslationId,
                    )}
                </StyledContainer>
                <StyledFooter>
                    {!!selectedFiltersCount && (
                        <StyledClearAllButton
                            id="search-filter-clear-all"
                            onClick={clearAll}
                        >
                            <FormattedMessage id="search.filters.clearAll" />
                        </StyledClearAllButton>
                    )}
                    <StyledDoneButton
                        onClick={handleDone}
                        appearance={Button.APPEARANCE.PRIMARY}
                        id="search-filter-done"
                    >
                        <FormattedMessage id="search.filters.done" />
                    </StyledDoneButton>
                </StyledFooter>
            </StyledWrapper>
        );
    }
}

MobileSearchFilters.propTypes = {
    clearAll: PropTypes.func.isRequired,
    clearSection: PropTypes.func.isRequired,
    handleDone: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    isDateSectionStateInitial: PropTypes.bool.isRequired,
    resetSection: PropTypes.func.isRequired,
    sections: PropTypes.shape({
        date: PropTypes.shape({}),
        sources: PropTypes.shape({}),
        topics: PropTypes.shape({}),
        type: PropTypes.shape({}),
    }).isRequired,
    selectedFiltersCount: PropTypes.number.isRequired,
    toggleDateItem: PropTypes.func.isRequired,
    toggleItem: PropTypes.func.isRequired,
};

export default injectIntl(MobileSearchFilters);
