import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Modal from 'components/atomics/templates/ClosableModal/ClosableModal';
import {
    TYPE_PRIMARY_LARGE,
    TYPE_GHOST,
} from 'components/atomics/atoms/Button/Button';
import { FormattedMessage } from 'react-intl';
import {
    containerStyles,
    titleStyles,
    descriptionStyles
} from 'components/atomics/molecules/NewsSource/styles';
import Button from 'components/atomics/atoms/Button/Button';
import { BrowserRouter } from 'react-router-dom';
import { hideSourceModal } from 'state-management/actions/basicProfile';
import storage from 'utils/store';

export const Container = styled.div`
    ${containerStyles}
`;

export const TitleText = styled.div`
     ${titleStyles}
`;

export const Description = styled.div`
     ${descriptionStyles}
`;

const StyledPopup = styled.div`
`;

const ModalStyles = {
    content: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        position: 'fixed',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        borderRadius: '0',
        boxSizing: 'border-box',
        outline: '0',
        background: 'transparent',
        border: '0',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        zIndex: '1',
    },
};

/**
 * Preferences modal when user attempts to go to a page requiring personalization but has not fully onboarded.
 * (webinar signup user only)
 */
export class SourceModal extends React.Component {
    constructor(props) {
        super(props);
    };

    closeSourceModal = () => {
        this.setState({ showSourceModal: false });
    };

    onClose = () => {
        this.props.hideModal();

        setTimeout(() => {
            this.props.onRequestClose(true);
        }, 400);
    };

    /**
     * Render the preferences modal and underlying components.
     */
    render() {
        return (
            <BrowserRouter>
                <Modal
                    isOpen={this.props.isOpen}
                    style={ModalStyles}
                    ariaHideApp={false}
                    shouldFocusAfterRender={false}
                >
                    <StyledPopup>
                        <Container>
                            <TitleText>
                                <FormattedMessage id="news.source.title" />
                            </TitleText>
                            <Description>
                                <FormattedMessage id="news.source.description" />
                            </Description>
                            <Button
                                type={TYPE_PRIMARY_LARGE}
                                onClick={() => {
                                    if (this.props.showSourceModal) {
                                        this.onClose();
                                    }
                                    else {
                                        this.props.closeSourceModal();
                                    }

                                    storage.set('referrer', window.location.pathname);

                                    if (window.location.search) {
                                        storage.set('referrer', window.location.pathname + window.location.search);
                                    }
                                    window.location = '/choose-sources';
                                }}
                            >
                                <FormattedMessage id="news.source.btnText" />
                            </Button>
                            <Button
                                type={TYPE_GHOST}
                                to="#"
                                onClick={(e) => {
                                    e.preventDefault()
                                    if (this.props.showSourceModal) {
                                        this.onClose();
                                    }
                                    else {
                                        this.props.closeSourceModal();
                                    }
                                }}
                            >
                                <FormattedMessage id="news.source.linkText" />
                            </Button>
                        </Container>
                    </StyledPopup>
                </Modal>
            </BrowserRouter>
        );
    }
}

const mapStateToProps = state => ({
    showSourceModal: state.basicProfile.showSourceModal,
    hideSourceModal: state.basicProfile.hideSourceModal,
    redirect: state.globalPopupContainer.redirect,
});

const mapDispatchToProps = dispatch => ({
    hideModal: () => dispatch(hideSourceModal()),
});

SourceModal.propTypes = {
    closeSourceModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SourceModal);
