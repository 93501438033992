import { css } from 'styled-components';
import { GUTTER } from 'utils/grid';
import {
    FROM_DESKTOP,
    FROM_TABLET,
    FROM_TABLET_TO_DESKTOP,
    SMALL_MOBILE
} from '../../utils/mediaQueries';
import {
    RGB_COLORS,
    COLORS,
    FONTS,
} from 'utils/variables';

export const noResultsContainer = css`
    text-align: center;
`;

export const noResultsTitle = css`
    margin: auto;
    margin-top: 100px;
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-weight: normal;
    font-size: 26px;
    line-height: 32px;
    color: ${COLORS.SAPPHIRE};
    max-width: 350px;
    margin-bottom: 20px;

    @media screen and ${FROM_DESKTOP} {
        max-width: 546px;
        margin-bottom: 27px;
        font-size: 35px;
        line-height: 45px;
    }
`;

export const noResultsDescription = css`
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 20px;
    color: ${COLORS.BLACK};
    font-weight: normal;
    font-family: ${FONTS.AVENIR_NEXT.FONT};

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 27px;
        font-size: 18px;
    }
`;

export const noResultsCta = css`
    margin-bottom: 150px;
`;

export const dropDownWrapperStyles = css`
    @media screen and ${SMALL_MOBILE} {
        display: block;
    }

    @media screen and ${FROM_TABLET} {
        display: inline-flex;
        justify-content: flex-end;
        width: 100%;
    }

    *[data-rel="Select"] em {
        display: none;
    }

    /* sorting drop-down molecule */
    &>div {
        text-align: left;

        @media screen and ${SMALL_MOBILE}{
            display:block;
        }
        @media screen and ${FROM_TABLET}{
            display: inline-block;
        }
        @media screen and ${FROM_DESKTOP} {
            display:inline-block;
            width: unset;

            &>div:first-child {
                display:block;
            }
        }
    }

`;

export const sortAndFiltersContainerStyles = css`
    background: ${COLORS.LIGHT_GRAY1};

    @media screen and ${FROM_DESKTOP} {
        text-align: right;
    }
`;

export const listStyles = css`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;

    *[data-rel="collectionsMobileCard"] {
        width: 100%;
    }

    @media screen and ${FROM_TABLET} {
        margin-left: -${GUTTER.desktop / 2}px;
        
    }

    @media screen and ${FROM_DESKTOP} {
        margin-left: -${GUTTER.desktop}px;
        
    }
`;

export const followControlStyles = css`
    margin-top: 4px;
    right: 16px;
    list-style-type: none;
    top: 62px;

    a {
        width: 90px;
    }


    @media screen and ${SMALL_MOBILE} {
        right: 0;
        top: 0;
        position: relative;
        text-align: center;
    }

    @media screen and ${FROM_TABLET} {
        right: 38px;
        position: absolute;
        margin-top: 0;
    }

    @media screen and ${FROM_DESKTOP} {
        right: 48px;

    }

    .Button__StyledInternalLink-hUgpsZ[isfollowing="false"]{
        color: ${COLORS.SAPPHIRE};
        border: 2px solid ${COLORS.SAPPHIRE};
        background-color: ${COLORS.WHITE};

        span:nth-child(2) {
            margin-left: 23px;
        }

        span:first-child {
            background: transparent;
            border: 2px solid ${COLORS.SAPPHIRE};
            top: 5px;

            svg {
                color: ${COLORS.SAPPHIRE};
            }
        }
    }

    .Button__StyledInternalLink-hUgpsZ[isfollowing="true"]{
        background-color: ${COLORS.SAPPHIRE};
        color: ${COLORS.WHITE};

        &:hover{
            background-color: ${COLORS.SAPPHIRE};
        }

        span:nth-child(2) {
            margin-left: 15px;
           }

        span:first-child {
            background-color: ${COLORS.SAPPHIRE};

            svg {
                fill: ${COLORS.WHITE};

                path:nth-child(2) {
                    fill: ${COLORS.WHITE};
                }

            }
        }
    }
`;

export const listItemStyles = css`
    align-items: stretch;
    box-sizing: border-box;
    display: flex;
    padding-bottom: 16px;
    -webkit-tap-highlight-color: rgba(${RGB_COLORS.BLACK}, 0);
    width: 100%;

    @media screen and ${FROM_TABLET} {
        padding-bottom: ${GUTTER.desktop / 2}px;
        padding-left: ${GUTTER.desktop / 2}px;
        width: ${props => 100 / (props.mode || 3)}%;
    }

    @media screen and ${FROM_DESKTOP} {
        padding-bottom: ${GUTTER.desktop}px;
        padding-left: ${GUTTER.desktop}px;
    }
`;

export const listContentContainerStyles = css`
    padding-left: 0;
    text-align: left;
    padding-right: 0;
    margin-top:5px;

    ul {
        margin-left: 0;
    }

    &>div >ul >div {
        width: 100%;
    }

    ${props => (props.type === 'guidesandfeatures' || props.type === 'collections' || props.type === 'cecredits') && css`
        ul {

            @media screen and ${FROM_TABLET} {
            }

            @media screen and ${FROM_DESKTOP} {
            }
        }

        &>div {
            &>ul {
                &>div {
                    ${props => props.type != 'cecredits' &&
            ` background-color: ${COLORS.LIGHT_GRAY1};`}
                }
            }
            @media screen and ${FROM_TABLET} {
                display: flex !important;
                flex-direction: row !important;
                flex-wrap: wrap !important;
                min-width: 100%;

                &>ul {
                    &>div {
                        ${props => props.type != 'cecredits' &&
            ` background-color: ${COLORS.WHITE};`}
                        &>a:first-child {
                            width: initial;
                        }
                    }
                }
            }

            @media screen and ${FROM_TABLET_TO_DESKTOP} {
                &>ul {
                    &>div {
                        width: calc(33% - 20px);
                        margin-right: 24px;
                        margin-bottom: 24px;
                        max-width: initial;
                    }

                    &>div:nth-child(3),
                    &>div:nth-child(6),
                    &>div:nth-child(9),
                    &>div:nth-child(12) {
                        margin-right: 0;
                    }
                }
            }

            @media screen and ${FROM_DESKTOP} {
                &>ul {
                    min-width: 100%;
                    &>div {
                        width: calc(25% - 22px);
                        margin-right: 26px;
                        margin-bottom: 24px;
                        max-width: initial;
                    }

                    &>div:nth-child(4),
                    &>div:nth-child(8),
                    &>div:nth-child(12),
                    &>div:nth-child(16),
                    &>div:nth-child(20) {
                        margin-right: 0;
                    }
                }
            }
        }
    `};
`;

export const subtitleStyles = css`
    white-space: nowrap;
`;

export const resultsWrapper = css`
    margin-top: -10px;

    h2 {
        color: ${COLORS.DARK_GRAY2};
        font-size: 14px;
        line-height: 18px;
        padding-top: 0;
        border-bottom: none;
    }

    @media screen and ${FROM_TABLET} {
        top: -24px;

        h2 {
            font-size: 18px;
            line-height: 22px;
            padding-top: 13px;
        }
    }

    @media screen and ${FROM_DESKTOP} {
        top: 0;
        margin-top: -32px;

        h2 {
            padding-top: 28px;
        }
    }

    ${props => props.type === 'trending' && css`
        margin-top: 0;
        top: 0;
    `};
`;

export const containerStyles = css`
    ${props => (props.type === 'guidesandfeatures' || props.type === 'collections') && css`
        background-color: ${COLORS.LIGHT_GRAY1};
    `};
`;

export const sectionStyles = css`
    ${props => (props.type === 'guidesandfeatures' || props.type === 'collections') && css`
        background-color: ${COLORS.LIGHT_GRAY1};
        display:none;
    `};
`;

export const headerStyles = css`
    background-color: ${COLORS.LIGHT_GRAY1};
    position: relative;
    text-align: center;

    &>div>div[data-rel="backTo"] {
        text-align: left !important;
        margin: 0 !important;
        background: transparent;
    }

    @media screen and ${FROM_TABLET} {
        text-align: left;
    }

    ${props => (props.type === 'guidesandfeatures' || props.type === 'collections') && css`
        background-color: ${COLORS.LIGHT_GRAY1};
    `};

    button {
        &.active {
            background: ${COLORS.SAPPHIRE};

            &:hover {
                background: ${COLORS.DARK_SAPPHIRE};
            }
        }
    }

    &>div >div {
        color: ${COLORS.DARK_GRAY2} !important;
        font-size: 13px;
        line-height: 17px;
        letter-spacing: 0;
        margin: auto;
        max-width: 200px;

        @media screen and ${FROM_TABLET} {
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0;
            max-width: initial;
            margin-bottom: 12px;
            margin-left: 0 !important;
            margin-right: 0 !important;
        }

        @media screen and ${FROM_DESKTOP} {
            margin-bottom: 0;
        }
    }

    @media screen and ${SMALL_MOBILE} {
        &>div >div {
           max-width: 100%;
        }
    }
`;