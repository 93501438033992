import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import {
    FONT_AVENIR_NEXT,
    COLORS,
} from 'utils/variables';

export const labelStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-size: 12px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    font-weight: 500;
`;

export const sectionStyles = css`
    margin-bottom: 56px;

    &>div {
        &>div {
            width: 100%;
        }
    }

    &>div:last-child {
        margin-top: 14px;
    }

    @media screen and ${FROM_TABLET} {
        display: flex;

        &>div {
            width: calc(50% - 12px);
        }

        &>div:first-child {
            margin-right: 24px;
            margin-top:15px;
        }

        &>div:last-child {
            margin-top: 15px;
        }
    }

   ul>li:first-child {
        background-color:  ${COLORS.LIGHT_GRAY4};
    }
`;

export const formStyles = css`
    box-sizing: border-box;
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 18px;
    font-weight: 500;
    line-height: 3.06;
    position: relative;
    display: flex;
    flex-direction: column-reverse;

    @media screen and ${FROM_TABLET} {
        padding-left: 30px;
        font-size: 24px;
        line-height: 3.12;
        position: static;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 28px;
        flex-direction: column;
    }

    &>div:first-child {
        &>div:first-child {
            font-size: 12px;
            color: ${COLORS.DARK_GRAY2};
            letter-spacing: 1.8px;
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: -6px;
        }

        &>div:last-child {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: 40px;
            @media screen and ${FROM_DESKTOP} {
                &>label {
                    width: calc(50% - 12px);
                    margin-right: 24px;
                }

                &>label:nth-child(even) {
                    margin-right: 0;
                }
            }
        }
    }

    /* select/input areas */
    &>div:last-child {
        margin-bottom: 40px;
        width:100%;
        @media screen and ${FROM_DESKTOP} {
            width: calc(50% - 12px);
            margin-right: 24px;
        }
        *[data-rel="Select"],
        *[data-rel="Input"] {
            margin-top:25px;
            width: 100%;
            min-width:inherit;
        }

        *[data-rel="Select-Wrapper"]:first-child {
            margin-bottom: 25px;
        }

        @media screen and ${FROM_DESKTOP} {
            margin-bottom: 0;
        }
        @media screen and ${FROM_TABLET} {

            *[data-rel="Select"],
            *[data-rel="Input"] {
                width: 50%;
                min-width:inherit;
            }
        }
    }
`;

export const dropdownWrapperStyles = css`
    display: inline-block;

    /* stylelint-disable */
    ${props => props.profession && css`
        min-width: 100%;

        @media screen and ${FROM_TABLET} {
            min-width: 306px;
        }

        @media screen and ${FROM_DESKTOP} {
            min-width: 252px;
        }
    `}

    ${props => props.duration && css`
        min-width: 181px;
    `}

    ${props => props.aum && css`
        min-width: 191px;

        @media screen and ${FROM_DESKTOP} {
            min-width: 217px;
        }
    `}
`;

export const serviceOfferingsDropdownWrapperStyles = css`
    display: inline-block;
    min-width: 100%;
    text-align: left;

     @media screen and ${FROM_DESKTOP} {
        min-width: 430px;
    }
`;

export const serviceOfferingsItemStyles = css`
    border-bottom: 2px solid ${COLORS.SAPPHIRE};
    box-sizing: border-box;
    display: inline-block;
    line-height: 1.5;
    padding: 0 43px 4px 0;
    position: relative;
    min-width: 100%;

    @media screen and ${FROM_TABLET} {
       margin-right: 19px;
       min-width: 100px;
       padding-bottom: 10px;
    }
`;

export const iconButtonStyles = css`
    bottom: 6px;
    position: absolute;
    right: 2px;

    @media screen and ${FROM_TABLET} {
       bottom: 14px;
    }

    @media screen and ${FROM_DESKTOP} {
       bottom: 18px;
    }
`;

export const zipInputWrapperStyles = css`
    display: inline-block;
    width: 72px;

    @media screen and ${FROM_TABLET} {
        width: 95px;
    }

    @media screen and ${FROM_DESKTOP} {
        width: 109px;
    }
`;

export const fieldTextStyles = css`
    padding-top: 0;
`;
