import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { visuallyHiddenStyles } from 'components/VisuallyHidden/styles';

const StyledVisuallyHidden = styled.span`
    ${visuallyHiddenStyles};
`;

const VisuallyHidden = props => (
    <StyledVisuallyHidden>{props.children}</StyledVisuallyHidden>
);

VisuallyHidden.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

VisuallyHidden.defaultProps = {
    children: null,
};

export default VisuallyHidden;
