import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
    DISCLAIMER_STANDARD,
    DISCLAIMER_SECONDARY,
    DISCLAIMER_TERTIARY,
    DISCLAIMER_NONE,
} from 'state-management/constants/globalDisclaimer';
import Header from '../../components/atomics/molecules/Header/Header';
import { mainStyles, wrapperStyles } from 'components/BaseLayout/styles';
import {
    notFoundErrorMessages, internalServerErrorMessages, forbiddenErrorMessages
} from '../../components/PageError/PageErrorMessages';
import PageErrorContainer from 'components/PageError/PageError';
import FooterAppStoreAndShare from '../../components/FooterAppStoreAndShare/FooterAppStoreAndShare';
import Footer from '../../components/Footer/Footer';
import {
    NOT_FOUND_ERROR,
    SERVER_ERROR,
    FORBIDDEN_ERROR,
} from 'state-management/constants/errors';
import { MIGRATION_ENUM } from '../../state-management/constants/basicProfile';

const StyledWrapper = styled.div`
    ${wrapperStyles}
`;

const StyledMain = styled.main`
    ${mainStyles}
`;

const mainContentSwitch = (errorType, mainContent) => {
    switch (errorType) {
        case SERVER_ERROR: {
            return <PageErrorContainer errorType={errorType} {...internalServerErrorMessages} />;
        }
        case NOT_FOUND_ERROR: {
            return <PageErrorContainer errorType={errorType} {...notFoundErrorMessages} />;
        }
        case FORBIDDEN_ERROR: {
            return <PageErrorContainer errorType={errorType} {...forbiddenErrorMessages} />;
        }
        default: {
            return mainContent;
        }
    }
};

const BaseLayout = (props) => {
    const disclaimer = props.disclaimerType !== DISCLAIMER_NONE
        ? props.disclaimerFragments[props.disclaimerType]
        : undefined;
    const disclaimerText = disclaimer && disclaimer.contentList[0] && disclaimer.contentList[0].text;
    const isWebinar = window.location.pathname.includes('/learn/webinar/');
    const content = (!props.isAuthenticated && isWebinar) ? <a target="_blank" href="https://www.capitalgroup.com"><img src="/images/capgroup.svg" data-img="capgrouplogo" /></a> : props.headerContent;
    const url = window.location.pathname;
    let withClickableLogoMain = true;
    const showAppFooter =
        url !== "/"
        && !url.includes("/onboarding")
        && !url.includes("webinar")
        && !url.includes("email-confirmation")
        && url !== "/sign-in"
        && url !== "/sign-up"
        && url !== "/contact-us"
        && url !== "/about"
        && url !== "/terms-and-conditions"
        && url !== "/confirm-email";
    const hideNav = url.includes("/choose-sources");

    if ((url.includes("/news") || url.includes("/profile/preferences")) && props.selectedSources && props.selectedSources.length < 3) {
        window.location.replace('/home');
        return;
    }

    return (
        <StyledWrapper isExpanded={props.isExpanded}>
            {
                !props.hideHeader
                    ? (
                        <Header
                            brandedHeader={!props.isAuthenticated && isWebinar}
                            contentAfter={props.headerContentAfter}
                            contentBefore={props.headerContentBefore}
                            id="header"
                            role="banner"
                            withClickableLogo={withClickableLogoMain}
                            logoLink={props.globalLogoLink || props.logoLink}
                            hideNav={hideNav}
                            forceMigration={props.migrationEnum === MIGRATION_ENUM.MUST_MIGRATE}
                        >
                            {content}
                        </Header>
                    )
                    : null
            }

            <StyledMain role="main" id="content" tabIndex="-1">
                {mainContentSwitch(props.errorType, props.mainContent)}
            </StyledMain>

            {(showAppFooter && props.migrationEnum !== MIGRATION_ENUM.MUST_MIGRATE)
                && <FooterAppStoreAndShare
                    id="footer"
                    role="contentinfo"
                />}
            <Footer
                id="footer"
                role="contentinfo"
                disclaimer={disclaimerText}
                forceMigration={props.migrationEnum === MIGRATION_ENUM.MUST_MIGRATE}
            >
                {props.footerContent}
            </Footer>
        </StyledWrapper>
    );
};

BaseLayout.propTypes = {
    disclaimerType: PropTypes.oneOf([
        DISCLAIMER_STANDARD,
        DISCLAIMER_SECONDARY,
        DISCLAIMER_TERTIARY,
        DISCLAIMER_NONE,
    ]).isRequired,
    disclaimerFragments: PropTypes.objectOf(PropTypes.shape({
        contentList: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string.isRequired,
            code: PropTypes.string.isRequired,
        }))
    }).isRequired).isRequired,
    headerContentAfter: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    headerContentBefore: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    headerContent: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    mainContent: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    isExpanded: PropTypes.bool,
    withClickableLogo: PropTypes.bool,
    globalLogoLink: PropTypes.string,
    logoLink: PropTypes.string,
    errorType: PropTypes.string,
    hideHeader: PropTypes.bool,
};

BaseLayout.defaultProps = {
    footerContent: null,
    headerContentAfter: null,
    headerContentBefore: null,
    headerContent: null,
    mainContent: null,
    isExpanded: false,
    withClickableLogo: false,
    globalLogoLink: undefined,
    logoLink: undefined,
    errorType: null,
    hideHeader: false,
    selectedSources: [],
};

const mapStateToProps = state => ({
    globalLogoLink: state.globalLogoLink.logoLink,
    isExpanded: state.navigation.isExpanded,
    disclaimerFragments: state.globalDisclaimer.fragments,
    disclaimerType: state.globalDisclaimer.disclaimerType,
    errorType: state.errors.errorType,
    isAuthenticated: state.signIn.isAuthenticated,
    migrationEnum: state.basicProfile.notification.data.migrationState,
    selectedSources: state.basicProfile.sourceIds,
});

export const ConnectedBaseLayout = connect(mapStateToProps)(BaseLayout);

function withBaseLayout(Component, logoLink) {
    return (props) => {
        const content = (<Component {...props} />);
        return (
            <ConnectedBaseLayout
                withClickableLogo={props.withClickableLogo || !!logoLink}
                logoLink={logoLink}
                mainContent={content}
            />
        );
    };
}

export default withBaseLayout;
