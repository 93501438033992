import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Modal from 'components/atomics/templates/ClosableModal/ClosableModal';
import { FormattedMessage } from 'react-intl';
import { triggerFormSubmit as triggerFormSubmitAction, setFormUntouched }
    from 'state-management/actions/basicProfileForm';
import {
    BasicButton as Button,
    TYPE_SECONDARY_MEDIUM_LARGE,
} from 'components/atomics/atoms/Button/Button';
import {
    popupStyles,
    popupContentStyles,
    popupTitleStyles,
    popupActionsContainerStyles,
    confirmationTextStyles,
    buttonsContainerStyles,
} from 'containers/Profile/styles';
import { RGB_COLORS } from 'utils/variables';
import storage from 'utils/store';

const StyledPopup = styled.div`
    ${popupStyles}
`;

const StyledHeaderActionsContainer = styled.div`
    ${popupActionsContainerStyles}
`;

const StyledPopupContent = styled.div`
    ${popupContentStyles}
`;

const StyledPopupTitle = styled.h2`
    ${popupTitleStyles}
`;

const StyledConfirmationText = styled.p`
    ${confirmationTextStyles}
`;

const StyledButtonsContainer = styled.div`
    ${buttonsContainerStyles}
`;

const ModalStyles = {
    content: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        position: 'fixed',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        borderRadius: '0',
        boxSizing: 'border-box',
        width: '100%',
        outline: '0',
        background: 'transparent',
        border: '0',
    },
    overlay: {
        backgroundColor: `rgba(${RGB_COLORS.DARK_SAPPHIRE}, 0.6)`,
        zIndex: '1',
    },
};

/**
 * Creates the profile modal save/dismiss popup (for ALL OTHER THAN source and topic changes).
 */
export class ProfileConfirmationPopup extends React.Component {
    /**
     * Handle discard clicks.
     */
    onDiscard = (e) => {
        if (window.location.pathname === "/choose-sources") {
            history.go(-1);
            return;
        }
        this.props.onRequestClose(true);
    };

    /**
     * Handle visual closer.
     */
    onClose = () => {
        this.props.onRequestClose(false);
    };

    handleSaveOnClick = (eve) => {
        if (window.location.pathname === '/choose-sources') {
            storage.set('modalSave', true)
            this.props.onRequestClose(false);
            setTimeout(function () {
                document.getElementsByClassName('chooseSourcesSave')[0].click();
            }, 2000);

        }
        else {
            this.props.triggerFormSubmit();
        }

    };


    /**
     * Render this and underlying components.
     */
    render() {
        if (!this.props.isOpen) {
            return null;
        }
        return (
            <Modal
                isOpen={this.props.isOpen}
                style={ModalStyles}
                ariaHideApp={false}
            >
                <StyledPopup>
                    <StyledHeaderActionsContainer>
                    </StyledHeaderActionsContainer>
                    <StyledPopupTitle>
                        <FormattedMessage id="profilePage.confirmationModal.title" />
                    </StyledPopupTitle>
                    <StyledPopupContent>
                        <StyledConfirmationText>
                            <FormattedMessage id="profilePage.confirmationModal.description" />
                        </StyledConfirmationText>
                    </StyledPopupContent>
                    <StyledButtonsContainer type="new">
                        <Button
                            type={TYPE_SECONDARY_MEDIUM_LARGE}
                            id="discard-button"
                            onClick={() => { this.onDiscard(); this.props.untouchForm(); }}
                            disabled={this.props.isSaving}
                            dataAnalyticsPlacement="Button : body"
                            dataAnalyticsLabel="trackLink : button"
                            dataAnalyticsId="save profile popup cancel"
                        >
                            <FormattedMessage id="profilePage.confirmationModal.discard" />
                        </Button>
                        <Button
                            id="save-button"
                            onClick={this.handleSaveOnClick}
                            disabled={this.props.isSaving}
                            dataAnalyticsPlacement="Button : body"
                            dataAnalyticsLabel="trackLink : button"
                            dataAnalyticsId="save profile popup"
                        >
                            <FormattedMessage id="profilePage.confirmationModal.confirm" />
                        </Button>
                    </StyledButtonsContainer>
                </StyledPopup>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    isSaving: state.basicProfileForm.isSaving,
    userProfile: state.basicProfile,
    selectedSources: state.basicProfile.sourceIds,
});

const mapDispatchToProps = dispatch => ({
    triggerFormSubmit: () => {
        console.log('dispatching'); dispatch(triggerFormSubmitAction)
    },
    saveProfile: data => { dispatch(saveBasicProfileForm(data)) },
    untouchForm: () => dispatch(setFormUntouched()),
});

ProfileConfirmationPopup.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    triggerFormSubmit: PropTypes.func.isRequired,
    untouchForm: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileConfirmationPopup);