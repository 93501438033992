import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS, RGB_COLORS } from 'utils/variables';

export const sectionStyles = css`
    background-image: linear-gradient(to bottom, rgba(${RGB_COLORS.LIGHT_GRAY2}, 0.5), rgba(${RGB_COLORS.LIGHT_GRAY1}, 0));
`;

export const conclusionContainerStyles = css`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 32px 34px;

    @media screen and ${FROM_TABLET} {
        padding: 150px 55px;
    }
`;

export const conclusionStyles = css`
    align-self: stretch;
    color: ${COLORS.SAPPHIRE};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 24px;
    font-weight: 400;
    letter-spacing: -0.6px;
    line-height: 1.25;
    margin: 30px auto 17px;
    text-align: center;
    word-wrap: break-word;

    * {
        margin-top: 0;
    }

    @media screen and ${FROM_TABLET} {
        font-size: 36px;
        letter-spacing: -0.3px;
        line-height: 1.33;
        margin: 24px auto 4px;
    }

    @media screen and ${FROM_DESKTOP} {
        line-height: 1.17;
        margin: 22px auto 23px;
        max-width: 768px;
        width: 100%;
    }
`;

export const captionStyles = css`
    align-self: stretch;
    color: ${COLORS.MEDIUM_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        font-size: 24px;
        letter-spacing: -0.2px;
        line-height: 1.88;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 30px;
        margin: 0 auto;
        max-width: 768px;
        width: 100%;
    }
`;
