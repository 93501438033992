import * as type from 'state-management/constants/marketBriefing.common';

const initialState = {
    briefing: null,
    briefingsArchive: null,
    loading: false,
};

function optinReducer(state = initialState, action = {}) {
    switch (action.type) {
    case type.GET_MARKET_BRIEFING:
        return {
            ...state,
            loading: true,
        };
    case type.GET_MARKET_BRIEFING_SUCCESS:
        return {
            ...state,
            loading: false,
            briefing: action.data,
        };
    case type.GET_MARKET_BRIEFINGS_FAIL:
        return {
            ...state,
            loading: false,
        };
    
    case type.GET_LATEST_MARKET_BRIEFING_SUCCESS:
        return {
            ...state,
            briefing: action.data,
        };
    case type.GET_MARKET_BRIEFINGS_SUCCESS:
        return {
            ...state,
            briefingsArchive: action.data,
        };
    case type.DELETE_MARKET_BRIEFING:
        return {
            ...state,
            briefing: null,
        };
    default:
        return state;
    }
}


export default optinReducer;
