import {
    SET_PASSWORD_FAILED,
    SET_BASIC_PROFILE,
    SET_LOADING_BASIC_PROFILE,
    CLEAR_BASIC_PROFILE,
    SET_UPDATING_BASIC_PROFILE,
    SET_UPDATE_PROFILE_FAILED,
    GET_BASIC_PROFILE_ERROR,
    FOLLOW_SOURCE,
    UNFOLLOW_SOURCE,
    UPDATE_HOMEPAGE,
    FOLLOW_TOPIC,
    UNFOLLOW_TOPIC,
    GET_ISAPPINSTALLED_SUCCESS,
    SET_ISAPPINSTALLED,
    VALIDATE_TOKEN_SUCCESS,
    VALIDATE_TOKEN_FAILED,
    REMOVE_VALIDATE_TOKEN,
    SHOW_PREFERENCES_MODAL,
    HIDE_PREFERENCES_MODAL,
    SHOW_SOURCE_MODAL,
    HIDE_SOURCE_MODAL,
    GET_NOTIFICATION,
    GET_NOTIFICATION_SUCCESS,
    GET_NOTIFICATION_FAILURE,
} from 'state-management/constants/basicProfile';
import {
    CLEAR_ERRORS,
} from 'state-management/constants/errors';

export const emptyProfileState = {
    firstName: '',
    lastName: '',
    email: '',
    zip: '',
    crd: '',
    iard: '',
    memberStartDate: '',
    aumId: null,
    amountId: null,
    durationId: null,
    topicIds: [],
    sourceIds: [],
    serviceOfferingIds: [],
    aeid: null,
    homepage: null,
    onboarded: true,
    wmAUMId: null,
    wmHouseholdId: null,
    retirementPlanAUMId: null,
    retirementPlanPlansId: null,
    signUpType: null,
    firstLogin: 'NA',
    showPreferencesModal: false,
    uuid: null,
    sharerId: null,
    showSourceModal: false,
    readyForMigration: false,
    migrated: false,
    forceMigration: false,
    notification: {
        data: {
            migrationState: undefined,
            expiringBookmarks: [],
            expiringBookmarksMainBadge: 0,
            expiringBookmarksDetailsBadge: 0,
        },
        error: null,
        pending: false,
    }
};

const initialState = {
    ...emptyProfileState,
    isLoading: true,
    isUpdating: false,
    isUpdateFailed: false,
    isPasswordFailed: false,
    isPopulated: false,
    isLoaded: false,
    isAppProposeDeclined: true,
    isiOSAppInstalled: false,
    validateToken: null,
    tokenExpiration: null,
};

function basicProfileReducer(state = initialState, action = {}) {
    switch (action.type) {
        case SET_BASIC_PROFILE:
            return {
                ...state,
                isLoading: false,
                isUpdating: false,
                isUpdateFailed: false,
                isPasswordFailed: false,
                isPopulated: true,
                ...action.data,
                zip: action.data.zip || '',
                isLoaded: true,
            };
        case GET_ISAPPINSTALLED_SUCCESS:
            const { iosAppInstalled, userAppChoice } = action.data;
            return {
                ...state,
                isAppProposeDeclined: JSON.parse(userAppChoice),
                isiOSAppInstalled: JSON.parse(iosAppInstalled),
            };
        case VALIDATE_TOKEN_SUCCESS:
            return {
                ...state,
                validateToken: true,
                tokenExpiration: action.expires,
            }
        case SHOW_PREFERENCES_MODAL:
            return {
                ...state,
                showPreferencesModal: true,
            }
        case HIDE_PREFERENCES_MODAL:
            return {
                ...state,
                showPreferencesModal: false,
            }
        case VALIDATE_TOKEN_FAILED:
            return {
                ...state,
                validateToken: false,
                tokenExpiration: null,
            }
        case REMOVE_VALIDATE_TOKEN:
            return {
                ...state,
                validateToken: null,
            }
        case SET_ISAPPINSTALLED:
            return {
                ...state,
                isAppProposeDeclined: true,
            };
        case SET_LOADING_BASIC_PROFILE:
            return {
                ...state,
                isLoading: action.isLoading,
            };
        case SET_UPDATING_BASIC_PROFILE:
            return {
                ...state,
                isUpdating: action.isUpdating,
            };
        case SET_UPDATE_PROFILE_FAILED:
            return {
                ...state,
                isUpdateFailed: true,
                isUpdating: false,
            };
        case SET_PASSWORD_FAILED:
            return {
                ...state,
                isPasswordFailed: true,
                isUpdating: false,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                isUpdateFailed: false,
                isPasswordFailed: false,
            };
        case GET_BASIC_PROFILE_ERROR:
            return {
                ...state,
                isLoading: false,
                isUpdating: false,
            };
        case CLEAR_BASIC_PROFILE:
            return {
                ...state,
                ...emptyProfileState,
            };
        case FOLLOW_SOURCE:
            return {
                ...state,
                sourceIds: state.sourceIds.concat(action.sourceId),
            };
        case UNFOLLOW_SOURCE:
            return {
                ...state,
                sourceIds: state.sourceIds.filter(topic => topic !== action.sourceId),
            };
        case UPDATE_HOMEPAGE:
            return {
                ...state,
                homepage: action.homepageType,
            };
        case FOLLOW_TOPIC:
            return {
                ...state,
                topicIds: state.topicIds.concat(action.topicId),
            };
        case UNFOLLOW_TOPIC:
            return {
                ...state,
                topicIds: state.topicIds.filter(topic => topic !== action.topicId),
            };
        case SHOW_SOURCE_MODAL:
            return {
                ...state,
                showSourceModal: true,
            };
        case HIDE_SOURCE_MODAL:
            return {
                ...state,
                showSourceModal: false,
            };

        case GET_NOTIFICATION:
            return {
                ...state,
                notification: {
                    ...state.notification,
                    pending: true,
                },
            };
        case GET_NOTIFICATION_SUCCESS:
            return {
                ...state,
                notification: {
                    ...state.notification,
                    pending: false,
                    error: null,
                    data: action.data,
                },
            };
        case GET_NOTIFICATION_FAILURE:
            return {
                ...state,
                notification: {
                    ...state.notification,
                    pending: true,
                    error: action.error,
                },
            };

        default:
            return state;
    }
}

export default basicProfileReducer;
