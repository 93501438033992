import {
    GET_CATEGORIES_DATA,
    SET_CATEGORIES_DATA,
    CLEAR_CATEGORIES_DATA,
} from 'state-management/constants/categories';

export const getCategoriesData = group => ({
    type: GET_CATEGORIES_DATA,
    group,
});

export const setCategoriesData = categoriesData => ({
    type: SET_CATEGORIES_DATA,
    data: categoriesData,
});

export const clearCategoriesData = () => ({
    type: CLEAR_CATEGORIES_DATA,
});
