import * as type from 'state-management/constants/featureToggle.common';

const initialState = {
    features: {},
};

function featureToggleReducer(state = initialState, action = {}) {
    const { features } = state;

    switch (action.type) {
    case type.GET_FEATURE_TOGGLE_SUCCESS:
        features[action.data.featureId] = action.data.enabled;
        return {
            ...state,
            features,
        };
    default:
        return state;
    }
}


export default featureToggleReducer;
