import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from 'components';
import { CheckIconSmall } from 'components/atomics/atoms/Icons/Icons';
import { PlusWhiteIcon } from 'components/atomics/atoms/Icons';
import {
    buttonStyles,
    iconContainerStyles,
    iconStyles,
    textStyles,
} from 'components/FollowControl/styles';

const StyledButton = styled(Button)`
    ${buttonStyles}
`;
const StyledIconContainer = styled.span`
    ${iconContainerStyles}
`;
const StyledIconCheck = styled(CheckIconSmall)`
    ${iconStyles}
`;
const StyledIconPlus = styled(PlusWhiteIcon)`
    ${iconStyles}
`;
export const StyledText = styled.span`
    ${textStyles}
`;

const FollowControl = (props) => {
    const {
        onClick,
        isLoading,
        isFollowing,
        theme,
    } = props;
    const translationId = isFollowing ? 'followControl.following' : 'followControl.follow';
    const iconCheck = <StyledIconCheck theme={theme} />;
    const iconPlus = <StyledIconPlus theme={theme} />;

    return (
        <StyledButton
            id="follow-button"
            isDisabled={isLoading}
            onClick={onClick}
            className={isFollowing ? 'active' : 'inactive'}
            theme={theme}
        >
            <StyledIconContainer theme={theme}>
                {isFollowing ? iconCheck : iconPlus}
            </StyledIconContainer>
            <StyledText>
                <FormattedMessage id={translationId} />
            </StyledText>
        </StyledButton>
    );
};

FollowControl.propTypes = {
    theme: PropTypes.oneOf([
        'light',
        'dark',
    ]),
    onClick: PropTypes.func,
    isLoading: PropTypes.bool,
    isFollowing: PropTypes.bool,
};

FollowControl.defaultProps = {
    theme: 'light',
    onClick: () => {},
    isLoading: false,
    isFollowing: false,
};

export default FollowControl;
