import '@babel/polyfill';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import App from 'containers/App/App';
import Breakpoints from 'components/Breakpoints/Breakpoints';
import FocusVisibility from 'components/FocusVisibility/FocusVisibility';
import ScrollPosition from 'components/ScrollPosition/ScrollPosition';
import I18nProvider from 'containers/I18nProvider/I18nProvider';
// store/history import order is important!
import store from 'state-management/store/storeFactory';
import history from 'app/history';
import { contextHubRecordPageView, contextHubRecordArticleClick } from "./utils/contextHub";
import storage from 'utils/store';
// Styles
import { default as globalStyling } from 'app/global-styles';
import { createGlobalStyle } from 'styled-components';
import fontStyles from 'app/global-styles/fonts-styles';
import sliderStyles from 'app/global-styles/slider-styles';

const GlobalStyle = createGlobalStyle`
    ${fontStyles}
    ${globalStyling}
    ${sliderStyles}
`;

history.listen(location => {

    if (!location.hash && location.pathname !== "/search" && location.pathname !== "/" && location.key !== undefined &&
        !storage.get('onBoarding') && location.pathname !== "/news") {
        if (storage.get('advisorArticle')) {
            setTimeout(() => {
                contextHubRecordArticleClick(location.pathname, storage.get('advisorArticle'))
                storage.set('advisorArticle', '')
            }, 1000);
        } else {
            contextHubRecordPageView(location.pathname);
        }
        if (location.pathname === "/onboarding/pick-sources") {
            storage.set('onBoarding', true);
        }
    }

    setTimeout(function () {
        try {
            window.evidonSetDoNotSellHTML();
            window.styleEvidonFunc();
        } catch (e) {
            // Let it go
        }
    }, 500);
});

window.store = store;

render(
    (
        <Provider store={store}>
            <I18nProvider>
                <Breakpoints>
                    <GlobalStyle />
                    <FocusVisibility />
                    <ScrollPosition>
                        <App history={history} />
                    </ScrollPosition>
                </Breakpoints>
            </I18nProvider>
        </Provider>
    ), document.getElementById('root'),
);
