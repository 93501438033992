import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { uniqueId } from 'lodash';
import { IconSave } from 'components/atomics/atoms/Icons/Icons';
import { Button } from 'components';
import {
    buttonStyles, iconContainerStyles, iconStyles, textStyles,
} from 'components/SaveControl/styles';
import { contextHubRecordEvent } from 'utils/contextHub';
import {
    KEBAB_EVENT_LIST,
} from 'utils/contextHubEventListConstants';

const StyledButton = styled(Button)`
    ${buttonStyles};
`;

const StyledIconContainer = styled.span`
    ${iconContainerStyles};
`;

export const StyledIcon = styled(IconSave)`
    ${iconStyles};
`;

export const StyledText = styled.span`
    ${textStyles};
`;

class SaveControl extends React.Component {
    handleClick = (event) => {
        const { saved, title } = this.props;

        if (title) {
            contextHubRecordEvent(KEBAB_EVENT_LIST(
                "save",
                !saved,
                title,
                null,
                null,
                'article'
            ));
        }

        if (this.props.isLoading) {
            return;
        }

        this.props.onToggleSave();

        event.stopPropagation();
    };

    render() {
        const { appearance, isLoading, saved } = this.props;
        const translationId = saved ? 'saveControl.remove' : 'saveControl.add';

        return (
            <StyledButton
                id={`btn-save-${uniqueId()}`}
                isDisabled={isLoading}
                data-state={isLoading ? "disabled" : "enabled"}
                onClick={this.handleClick}
                variant={{ appearance }}
            >
                <StyledIconContainer variant={{ appearance }}>
                    <StyledIcon variant={{ appearance }} saved={saved ? 'true' : 'false'} />
                </StyledIconContainer>
                <StyledText
                    variant={{ appearance }}
                    saved={saved}
                >
                    <FormattedMessage id={translationId} />
                </StyledText>
            </StyledButton>
        );
    }
}

SaveControl.propTypes = {
    appearance: PropTypes.oneOf([
        'minimal',
        'extended',
    ]),
    isLoading: PropTypes.bool.isRequired,
    onToggleSave: PropTypes.func.isRequired,
    saved: PropTypes.bool,
    title: PropTypes.string,
};

SaveControl.defaultProps = {
    appearance: 'minimal',
    saved: false,
    title: null,
};

export default SaveControl;
