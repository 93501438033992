import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { errorContainerStyles, errorStyles } from 'containers/ContactUs/styles';

const StyledErrorsContainer = styled.div`
    ${errorContainerStyles}
`;

const StyledError = styled.p`
    ${errorStyles}
`;

/**
 * Handles form error displays for contact form.
 */
class FormErrors extends React.Component {
    errorRef = React.createRef();

    /**
     * Refocus on error state change.
     * @param {*} prevProps 
     */
    componentDidUpdate(prevProps) {
        if (!prevProps.globalErrors.length && this.props.globalErrors.length) {
            this.errorRef.current.focus();
        }
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const { globalErrors } = this.props;

        return (
            <StyledErrorsContainer ref={this.errorRef} tabIndex="-1">
                {
                    globalErrors.map(error => (
                        <StyledError key={error.errorMessage}>
                            <FormattedMessage id={error.errorMessage} />
                        </StyledError>
                    ))
                }
            </StyledErrorsContainer>
        );
    }
}

FormErrors.propTypes = {
    globalErrors: PropTypes.arrayOf(PropTypes.shape({
        errorMessage: PropTypes.string,
    })).isRequired,
};

export default FormErrors;
