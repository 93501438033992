import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import {
    FONT_AVENIR_NEXT,
    COLORS,
    ANIMATIONS,
} from 'utils/variables';

export const bodyCopyWrapperStyles = css`
    display: flex;
    flex-direction: column;
    margin: 0;
    position: relative;
    width: 100%;

    img {
        display: block;
        margin-bottom: 40px;
        max-height: 48px;
        max-width: 48px;
        position: relative;

        @media screen and ${FROM_TABLET} {
            margin-bottom: 24px;
        }

        @media screen and ${FROM_DESKTOP} {
            margin-bottom: 20px;
        }
    }

    a {
        color: ${COLORS.SAPPHIRE};
        transition: 0.3s all;
        text-decoration: none;

        &:hover {
            color: ${COLORS.DARK_SAPPHIRE};
        }
    }
`;

export const bodyCopyHeaderContainerStyles = css`
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    padding: 0 12px;

    @media screen and ${FROM_TABLET} {
        margin-bottom: 40px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 0 10px;
    }
`;

export const bodyCopyTitleStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.4px;
    line-height: 1.25;
    width: 100%;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        font-size: 26px;
        letter-spacing: -0.5px;
        line-height: 1.77;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 36px;
        letter-spacing: -0.6px;
        line-height: 1.28;
        max-width: 604px;
    }
`;

export const bodyCopyDescriptionStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    margin-top: 28px;
    width: 100%;
    word-wrap: break-word;

    /* stylelint-disable */

    ${props => props.isguidestandard !== "true" && css`
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
    `}

    /* stylelint-enable */

    @media screen and ${FROM_TABLET} {
        margin-top: 9px;
        max-width: 372px;

        /* stylelint-disable */

        ${props => props.isguidestandard !== "true" && css`
            line-height: 1.43;
        `}

        /* stylelint-enable */
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 24px;
        line-height: 1.25;
        margin-top: 28px;
        max-width: 604px;

        /* stylelint-disable */

        ${props => props.isguidestandard !== "true" && css`
            font-size: 18px;
            line-height: 1.33;
        `}

        /* stylelint-enable */
    }

    p {
        margin-top: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const bodyCopyContentWrapperStyles = css`
    border-top: 2px solid ${COLORS.LIGHT_GRAY3};
    padding: 50px 12px;

    @media screen and ${FROM_TABLET} {
        padding: 40px 12px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 40px 10px;
    }
`;

export const bodyCopyContentStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 14px;
    line-height: 1.5;
    width: 100%;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        line-height: 1.43;
        max-width: 372px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 18px;
        line-height: 1.33;
        max-width: 604px;
    }

    /* stylelint-disable */

    p {
        margin-top: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    a {
        color: ${COLORS.SAPPHIRE};
        transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;

        &:hover {
            color: ${COLORS.DARK_SAPPHIRE};
        }
    }

    /* stylelint-enable */
`;
