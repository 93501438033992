/* eslint-disable jsx-a11y/media-has-caption */
import 'video.js/dist/video-js.min.css';
import 'hls.js';
import videojs from 'video.js';
import React from 'react';
import PropTypes from 'prop-types';

class Video extends React.Component {
    componentDidMount() {
        const {
            controls,
            autoplay,
            poster,
            width,
            height,
        } = this.props;
        this.player = videojs(this.videoNode, {
            controls,
            autoplay,
            poster,
            width,
            height,
        });

        this.videoNode.onplay = this.props.onPlayFunction;
        this.videoNode.onpause = this.props.onStopFunction;
    }

    componentWillUnmount() {
        if (this.player) {
            this.player.dispose();
        }
    }

    render() {
        const { url, autoplay } = this.props;

        return (
            <div>
                <video
                    autoPlay={autoplay}
                    ref={(node) => { this.videoNode = node; }}
                    className="video-js vjs-default-skin"
                >
                    <source
                        src={url}
                        type="application/x-mpegURL"
                    />
                </video>
            </div>);
    }
}

Video.propTypes = {
    url: PropTypes.string,
    controls: PropTypes.bool,
    autoplay: PropTypes.bool,
    poster: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    onPlayFunction: PropTypes.func,
    onStopFunction: PropTypes.func,
};

Video.defaultProps = {
    url: 'https://c300.play.lura.app/play/v1/live/9sx-4fqxN5Qbf4bf8YNSIA/master.m3u8?ts=1618789565358&exp=2249509560&app=MBzCAZoE1bI4N2KJVumf2w&auth=7OZfsvDTv6Elrdo2WyUhTYW0nS3d03YCPW1gw2Ln4GE&partner=capitalgroup',
    controls: true,
    autoplay: true,
    poster: '',
    width: 415,
    height: 235,
    onPlayFunction: null,
    onStopFunction: null,
};

export default Video;
