import { css } from 'styled-components';
import { 
    FROM_DESKTOP,
    FROM_TABLET,
    SMALL_MOBILE,
} from 'utils/mediaQueries';
import { COLORS, RGB_COLORS } from 'utils/variables';

export const VERTICAL_FIT = 'vertical';
export const HORIZONTAL_FIT = 'horizontal';

export const containerStyles = ({ variant }) => css`
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;

    ${props => props.forLinkCard === true && css`
        height: auto;
        position: initial;

        img {
            position: initial;
            max-width: 100%;
            margin-bottom: -4px;
        }
    `};  

    @media screen and  ${SMALL_MOBILE} {
        img {
            display:block !important;

            ${props => !props.svgFilter && css`
                height: 100%;
                left: 0;
                object-fit: cover;
                position: absolute;
                top: 0;
                width: 100%;
            `};
    
            ${props => props.isAnimatable && css`
                height: calc(100% + 10px);
                top: -10px;
            `};
    
            ${props => !props.objectFitSupported && props.fit === VERTICAL_FIT && css`
                left: 50%;
                transform: translateX(-50%);
                width: auto;
            `};
    
            ${props => !props.objectFitSupported && props.fit === HORIZONTAL_FIT && css`
                height: auto;
                top: 50%;
                transform: translateY(-50%);
            `};      
        }
    }

    @media screen and  ${FROM_TABLET} {
        img {
            display:block !important;
            height:auto;

            ${props => !props.svgFilter && css`
                height: auto;
                left: 0;
                object-fit: cover;
                position: absolute;
                top: 0;
                width: 100%;
            `};
    
            ${props => props.isAnimatable && css`
                height: calc(100% + 10px);
                top: -10px;
            `};
    
            ${props => !props.objectFitSupported && props.fit === VERTICAL_FIT && css`
                left: 50%;
                transform: translateX(-50%);
                width: auto;
            `};
    
            ${props => !props.objectFitSupported && props.fit === HORIZONTAL_FIT && css`
                height: auto;
                top: 50%;
                transform: translateY(-50%);
            `};       
        }

        ${props => props.forLinkCard === true && css`
            height: auto;
            position: initial;

            img {
                position: initial;
                max-width: initial;
                margin-bottom: initial;
            }
        `};  
    }

    @media screen and  ${FROM_DESKTOP} {
        img {
            display: block !important;
            height: auto;
            min-height: 100%;

            ${props => props.isAnimatable && css`
                height: calc(100% + 10px);
                top: -10px;
            `};

            ${props => !props.objectFitSupported && props.fit === VERTICAL_FIT && css`
                left: 50%;
                transform: translateX(-50%);
                width: auto;
            `}; 

            ${props => !props.objectFitSupported && props.fit === HORIZONTAL_FIT && props.forGuidePage && css`
                height: 100%;
                width:  auto;
                top: 50%;
                transform: translate(-18%, -50%);
            `}; 

            ${props => !props.objectFitSupported && props.fit === HORIZONTAL_FIT && !props.forGuidePage && css`
                height: 100%;
                width:  100%;
                top: 50%;
                transform: translate(0%, -50%);
            `};   

            ${props => props.forLinkCard === true && css`
                width: 200%;
                height: 100%;
                left: -50%;
                transform: translate(0%, 0%) !important;
            `};           
        }  

        ${props => props.forLinkCard === true && css`
            position: absolute;

            img {
                position: absolute;
            }
        `}; 
    }

    @media screen\0  {
        img {
            ${props => props.forLinkCard === true && css`
                width: 200%;
                height: 100%;
                left: -50%;
                transform: translate(0%, -50%) !important;
            `};         
        }
    }

    
`;

export const filterBlockStyles = css`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background-color: ${COLORS.SAPPHIRE};
    opacity: 0.85;

    @media screen\0 {
        +img {
            display:block !important;
            transform: translate(0, -50%) !important;
            width: 100% !important;
        }
    }
`;
