// internal constants
const fonts = {
    avenirNext: '\'AvenirNext\', sans-serif',
    /*tisaPro: '\'TisaPro\', serif',*/
    avenirNextBold: '\'AvenirNextBoldEOT\', sans-serif',
};

// fonts
export const FONT_AVENIR_NEXT = fonts.avenirNext;
export const FONT_AVENIR_NEXT_BOLD = fonts.avenirNextBold;

export const GLOBAL = {
    ANIMATIONS: {
        TIME: {
            SECONDS: 0.33,
        },
        COLLAPSE_TIME: {
            MILISECONDS: 660,
        }
    },
};

export const ANIMATIONS = {
    CTAS: {
        TIME: {
            SECONDS: GLOBAL.ANIMATIONS.TIME.SECONDS,
        },
    },
    ARTICLES: {
        CONTAINER: {
            TIME: {
                SECONDS: GLOBAL.ANIMATIONS.TIME.SECONDS,
            },
        },
        IMAGE: {
            TIME: {
                SECONDS: GLOBAL.ANIMATIONS.TIME.SECONDS,
            },
        },
        KEBAB: {
            TIME: {
                SECONDS: GLOBAL.ANIMATIONS.TIME.SECONDS,
            },
        },
        TITLE: {
            TIME: {
                SECONDS: GLOBAL.ANIMATIONS.TIME.SECONDS,
            },
        },
        TOPIC: {
            TIME: {
                SECONDS: GLOBAL.ANIMATIONS.TIME.SECONDS,
            },
        },
        SOURCE: {
            TIME: {
                SECONDS: GLOBAL.ANIMATIONS.TIME.SECONDS,
            },
        },
    },
};

// all fonts and variants
export const FONTS = {
    /*TISA_PRO: {
        FONT: fonts.tisaPro,
        VARIANTS: [
            {
                fontWeight: 'normal',
            },
            {
                fontWeight: 300,
            },
            {
                fontWeight: 400,
            },
        ],
    },*/
    AVENIR_NEXT: {
        FONT: fonts.avenirNext,
        VARIANTS: [
            {
                fontWeight: 'normal',
            },
            {
                fontWeight: 'normal',
                fontStyle: 'italic',
            },
            {
                fontWeight: '100',
            },
            {
                fontWeight: '100',
                fontStyle: 'italic',
            },
            {
                fontWeight: '200',
            },
            {
                fontWeight: '200',
                fontStyle: 'italic',
            },
            {
                fontWeight: '300',
            },
            {
                fontWeight: '300',
                fontStyle: 'italic',
            },
            {
                fontWeight: '400',
            },
            {
                fontWeight: '400',
                fontStyle: 'italic',
            },
            {
                fontWeight: '500',
            },
            {
                fontWeight: '500',
                fontStyle: 'italic',
            },
            {
                fontWeight: '600',
            },
            {
                fontWeight: '600',
                fontStyle: 'italic',
            },
            {
                fontWeight: '700',
            },
            {
                fontWeight: '700',
                fontStyle: 'italic',
            },
            {
                fontWeight: '900',
            },
            {
                fontWeight: '900',
                fontStyle: 'italic',
            },
        ],
    },
};

// colors in RGB
export const RGB_COLORS = {
    WHITE: '255,255,255',
    LIGHT_GRAY1: '247,247,248', //replaces; 247,247,247
    LIGHT_GRAY2: '229,229,229', //replaces: 216,216,216 :: 221,221,221
    LIGHT_GRAY3: '204,204,204', //replaces: 184, 184, 184

    MEDIUM_GRAY1: '148,148,148', //replaces: 175, 175, 175 and 151, 151, 151

    DARK_GRAY1: '76,76,76',

    BLACK: '0,0,0',

    SAPPHIRE: '0,95,158', //replaces: 49, 34, 163 :: 81, 71, 220 :: 0, 122, 194

    DARK_SAPPHIRE: '0,41,75', //replaces: 5, 0, 33 :: 0, 101, 160

    OCEAN: '0,156,220',
}

// colors
export const COLORS = {
    WHITE: '#ffffff',
    LIGHT_GRAY1: '#F7F7F7',
    LIGHT_GRAY2: '#e5e5e5',
    LIGHT_GRAY3: '#CCCCCC',
    LIGHT_GRAY4: '#f1f1f2',
    MEDIUM_GRAY1: '#949494',
    MEDIUM_GRAY2: '#666666',
    DARK_GRAY1: '#4C4C4C',
    DARK_GRAY2: '#333333',
    BLACK: '#000000',

    LIGHT_OCEAN: '#7BD0E3', //LIGHT_PURPLE: '#B2ACFF',
    SAPPHIRE: '#005F9E', //BRIGHT_PURPLE: '#5147DC', //LUMIN_SAPHIRE: '#005BA2',
    OCEAN: '#009CDC', //LUMIN_OCEAN: '#009AE1',
    DARK_SAPPHIRE: '#00294B', //DARKER_PURPLE: '#3122A3', //DARKEST_PURPLE: '#0C0052'
    TRANSPARENT_OCEAN: '#BBE1F1', //TRANSPARENT_PURPLE: '#dad8f6',
    PALE_OCEAN: '#F1FAFD',  //PALE_PURPLE: '#F6F6FD',

    HOVER_SAPPHIRE: '#99A9B7',
    //SAFETY_BLUE: '#007AC2', ? NEVER APPEARS TO HAVE BEEN USED

    ALERT_RED: '#D91943', //ERROR_STATE: '#D91943', //FINANCE_RED_FG: '#e80000',

    FINANCE_GREEN_BG: '#edf4e6',
    BOND_GREEN: '#00965e', //FINANCE_GREEN_FG: '#447b0c', //SUCCESS_STATE: '#00807D',
    FINANCE_RED_BG: '#fef2f2',
};

// default exports
export default {
    FONT_AVENIR_NEXT,
    FONT_AVENIR_NEXT_BOLD,
    COLORS,
    FONTS,
};
