import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { toastControlsWrapperStyles, toastTitleStyles, toastWrapperStyles } from 'components/Toast/styles';

const StyledToastWrapper = styled.div`
    ${toastWrapperStyles};
`;
const StyledToastTitle = styled.span`
    ${toastTitleStyles};
`;
const StyledToastControlsWrapper = styled.div`
    ${toastControlsWrapperStyles};
`;

const ToastControls = ({ children, className }) => (
    <StyledToastControlsWrapper
        className={className}
    >
        {children}
    </StyledToastControlsWrapper>
);

ToastControls.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    className: PropTypes.string,
};

ToastControls.defaultProps = {
    children: null,
    className: '',
};

const ToastTitle = ({ children, className }) => (
    <StyledToastTitle
        className={className}
    >
        {children}
    </StyledToastTitle>
);

ToastTitle.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    className: PropTypes.string,
};

ToastTitle.defaultProps = {
    children: null,
    className: '',
};

const Toast = (props) => {
    const { children, className, id } = props;

    return (
        <StyledToastWrapper
            className={className}
            id={id}
        >
            {children}
        </StyledToastWrapper>
    );
};

Toast.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    className: PropTypes.string,
    id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
};

Toast.defaultProps = {
    children: null,
    className: '',
    id: 'toast',
};

Toast.Controls = ToastControls;
Toast.Title = ToastTitle;

export default Toast;
