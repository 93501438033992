//commentred for future use
//const CONTENT_SOURCES_IMAGE_PREFIX = '/content/dam/cgc/tenants/digital-prospecting/images/content-sources/';
//const TOPIC_CATEGORIES_IMAGE_PREFIX = '/content/dam/cgc/tenants/digital-prospecting/images/content-sources/';
const CONTENT_SOURCES_IMAGE_PREFIX = 'content-service/assets/image?folder=content-sources&filename=';
const TOPIC_CATEGORIES_IMAGE_PREFIX = 'content-service/assets/image?folder=topic-categories&filename=';

export function getContentSourcesImageUrl(imageName) {
    return CONTENT_SOURCES_IMAGE_PREFIX + imageName;
}

export function getTopicCategoriesImageUrl(imageName) {
    return TOPIC_CATEGORIES_IMAGE_PREFIX + imageName;
}