import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import {
    COLORS,
    FONTS
} from '../../../utils/variables';

export const sectionStyles = css`
    background-color:${COLORS.WHITE};
    position: relative;
    padding-top: 32px;

    @media screen and ${FROM_TABLET} {
        padding-top: 50px;
    }
`;

export const blankAreaStyles = css`
    width: 100%;
    background: ${COLORS.WHITE};
    height: 513px;
    position: relative;

    @media screen and ${FROM_TABLET} {
        height: 458px;
    }

    @media screen and ${FROM_DESKTOP} {
        height: 494px;
    }
`;

export const loaderStyles = css`
    position: absolute;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
    height: 50px;
    width: 50px;

    @media screen and ${FROM_TABLET} {
        width: 80px;
        height: 80px;
        left: calc(50% - 40px);
        top: calc(50% - 40px);
    }

    @media screen and ${FROM_DESKTOP} {
        width: 100px;
        height: 100px;
        left: calc(50% - 50px);
        top: calc(50% - 50px);
    }
`;

export const controlsContainerStyles = css`
    display: flex;
    justify-content: center;
    margin-bottom: 83px;
    margin-top: 24px;
    position: relative;

    @media screen and ${FROM_TABLET} {
        justify-content: flex-end;
        margin-bottom: 89px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 113px;
    }
`;

export const linkContainerStyles = `
    text-align: right;
    padding-top: 24px;
    padding-bottom: 32px;

    @media screen and ${FROM_TABLET} {
        padding-top: 32px;
        padding-bottom: 40px;
    }
`;

export const linkStyles = `
    color: ${COLORS.DARK_GRAY2};
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    white-space: nowrap;
    text-align: center;
    text-decoration:none;
    transition: 0.66s all;

    svg {
        height: 12px;
        margin-left: 6px;
        position: relative;
        stroke: ${COLORS.DARK_GRAY2};
        top: -1px;
        width: 12px;
    }

    &>a {
        display: block;
    }

    &:hover {
        color: ${COLORS.MEDIUM_GRAY2};
        svg{
            stroke: ${COLORS.MEDIUM_GRAY2};
        }
    }

    @media screen and ${FROM_TABLET} {
        text-align: right;
        background-color: transparent;
        border-bottom: none;

        &>a {
            display: inline-block;
        }
    }

`;

export const headerStyles = `{
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 26px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.23;
    letter-spacing: 0px;
    color: ${COLORS.SAPPHIRE};
    padding-bottom: 24px;
    padding-left: 16px;
    padding-right: 16px;

    @media screen and ${FROM_TABLET} {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 28px;
    }
}`

export const buttonContainerStyles = css`
    text-align: center;
    border-bottom: 1px solid ${COLORS.LIGHT_GRAY2};
    z-index:10;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 4px !important;
    padding-bottom: 32px !important;
    
    &>a {
        display: block;
    }

    @media screen and ${FROM_TABLET} {
        text-align: right;
        background-color: transparent;
        border-bottom: none;
        padding-left: 0;
        padding-right: 0;

        &>a {
            display: inline-block;
        }
    }
`;
