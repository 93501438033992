/**
 * Adds a 'createdId' field to every object in the array which is created
 * using a specific object field given in function parameters
 * @param {Array} objects
 * @param {String} field
 * @returns {Array}
 */
export const createIdsForObjectsByField = (objects, field) => {
    const objectsWithIds = [];

    objects.forEach((object) => {
        objectsWithIds.push({
            ...object,
            createdId: object[field] + objectsWithIds
                .filter(item => item[field] === object[field]).length,
        });
    });

    return objectsWithIds;
};
