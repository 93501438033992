import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Button, VisuallyHidden } from 'components';
import Badge from 'components/atomics/atoms/Badge/Badge';
import { collapse, toggle } from 'state-management/actions/navigation';
import {
    drawerContentStyles,
    drawerStyles,
    drawerWrapperStyles,
    navToggleButtonStyles,
    navToggleIndicatorStyles,
} from 'components/NavigationDrawer/styles';

export const StyledDrawerWrapper = styled.div`
    ${drawerWrapperStyles};
`;

export const StyledToggleButton = styled(Button)`
    ${navToggleButtonStyles};
`;

export const StyledToggleIndicator = styled.span`
    ${navToggleIndicatorStyles};
`;

export const StyledDrawer = styled.div`
    ${drawerStyles};
`;

export const StyledDrawerContent = styled.div`
    ${drawerContentStyles};
`;

class NavigationDrawer extends React.Component {
    /**
     * Default constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const {
            children,
            id,
            isExpanded,
            handleToggle,
            badge,
        } = this.props;

        return (
            <StyledDrawerWrapper
                ref={this.wrapperRef}
                variant={{ isExpanded }}
            >
                <StyledToggleButton
                    aria-controls={id}
                    aria-expanded={isExpanded}
                    id={`${id}-trigger`}
                    onClick={() => handleToggle()}
                    variant={{ isExpanded }}
                >
                    <VisuallyHidden>
                        <FormattedMessage
                            id="navigationTrigger.toggle.title"
                        />
                    </VisuallyHidden>
                    <StyledToggleIndicator
                        variant={{ isExpanded }}
                    />
                </StyledToggleButton>
                {badge !== undefined && badge > 0 && !isExpanded && <Badge label={badge} verticalOffset={-3} zIndex={20} scale={0.9} />}
                <StyledDrawer
                    id={id}
                    variant={{ isExpanded }}
                >
                    <StyledDrawerContent
                        variant={{ isExpanded }}
                    >
                        {children}
                    </StyledDrawerContent>
                </StyledDrawer>
            </StyledDrawerWrapper>
        );
    }
}

NavigationDrawer.propTypes = {
    id: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    handleCollapse: PropTypes.func,
    handleToggle: PropTypes.func,
    isExpanded: PropTypes.bool.isRequired,
};

NavigationDrawer.defaultProps = {
    children: null,
    handleCollapse: () => { },
    handleToggle: () => { },
    id: 'header-nav',
};

const mapStateToProps = state => ({
    isExpanded: state.navigation.isExpanded,
});

const mapDispatchToProps = dispatch => ({
    handleCollapse: () => dispatch(collapse()),
    handleToggle: () => dispatch(toggle()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavigationDrawer);
