import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { VisuallyHidden } from 'components';
import { progressBarStyles, progressBarWrapperStyles } from 'components/ProgressBar/styles';

const StyledProgressBarWrapperStyles = styled.div`
    ${progressBarWrapperStyles};
`;
const StyledProgressBar = styled.div`
    ${progressBarStyles};
`;

const ProgressBar = (props) => {
    const {
        className,
        id,
        isAnimated,
        offset,
        valueMin,
        valueNow,
        valueMax,
        valueText,
    } = props;
    const additionalAria = {};

    if (valueText) {
        additionalAria['aria-valuetext'] = valueText;
    }

    return (
        <StyledProgressBarWrapperStyles
            className={className}
            id={id}
        >
            <StyledProgressBar
                role="progressbar"
                aria-valuemin={valueMin}
                aria-valuenow={valueNow}
                aria-valuemax={valueMax}
                {...additionalAria}
                variant={{
                    isAnimated, offset, valueNow, valueMax,
                }}
            >
                <VisuallyHidden>
                    {valueText}
                </VisuallyHidden>
            </StyledProgressBar>
        </StyledProgressBarWrapperStyles>
    );
};

ProgressBar.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    isAnimated: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object,
    ]),
    offset: PropTypes.number,
    valueMin: PropTypes.number,
    valueNow: PropTypes.number,
    valueMax: PropTypes.number,
    valueText: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
    ]),
};

ProgressBar.defaultProps = {
    className: '',
    id: 'progress-bar',
    isAnimated: false,
    offset: null,
    valueMin: 0,
    valueNow: 0,
    valueMax: 100,
    valueText: null,
};

export default ProgressBar;
