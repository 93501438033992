import { css } from 'styled-components';
import { StyledIndicator as UnderlinedRadioIndicator } from 'components/UnderlinedRadio/UnderlinedRadio';
import { StyledIndicator as UnderlinedCheckboxIndicator } from 'components/UnderlinedCheckbox/UnderlinedCheckbox';
import { FROM_TABLET } from 'utils/mediaQueries';
import { COLORS, RGB_COLORS } from 'utils/variables';

export const listStyles = css`
    list-style: none;
    margin: 0;
    padding: 2px 12px 22px 20px;

    @media screen and ${FROM_TABLET} {
        border-top: 1px solid ${COLORS.LIGHT_GRAY2};
        padding: 0;
    }

    li {
        &:hover {
            background-color: rgba(${RGB_COLORS.OCEAN}, 0.06);
        }
    }
`;

export const itemStyles = css`
    border-bottom: 1px solid ${COLORS.LIGHT_GRAY3};
    color: ${COLORS.DARK_GRAY2};
    display: flex;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    padding: 15px 0;

    @media screen and ${FROM_TABLET} {
        border-bottom-color: ${COLORS.LIGHT_GRAY2};
        font-size: 14px;
        padding: 5px 2px;
    }

    /* stylelint-disable */
    ${UnderlinedRadioIndicator} {
        border-width: 1px;
        margin-right: 0;

        @media screen and ${FROM_TABLET} {
            height: 16px;
            margin-right: 0;
            width: 16px;

            &::before {
                height: 8px;
                width: 8px;
            }
        }
    }

    ${UnderlinedCheckboxIndicator} {
        border-width: 1px;
        height: 21px;
        width: 21px;

        svg {
            height: 9px;
            width: 11px;
        }

        @media screen and ${FROM_TABLET} {
            height: 16px;
            width: 16px;

            svg {
                height: 12px;
                width: 12px;
            }
        }
    }
    /* stylelint-enable */
`;
