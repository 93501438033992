import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container } from 'components';
import TypedIndexViews from '../../../containers/Search/View/TypedIndexViews';
import IndexView from '../../../containers/Search/View/IndexView';
import { resultContainerStyles } from 'containers/Search/SearchResults/styles';
import SearchOptions from 'containers/Search/SearchOptions/SearchOptions';
import NoResultsHeader from 'containers/Search/Header/NoResultsHeader';

export const StyledResultsContainer = styled(Container)`
    ${resultContainerStyles}
`;

/**
 * Search results list wrapper.
 * @param {*} param0 
 */
const SearchResults = ({
    filteredItems,
    items,
    onDoneClick,
    onSeeMoreNews,
    onSeeMorePracticeManagement,
    searchValue,
    queryParams,
    onSort,
    typeFilters,
    sort,
}) => (
    <React.Fragment>
        <StyledResultsContainer>
            {!!items.length && <SearchOptions sort={sort} onSort={onSort} items={filteredItems} onDoneClick={onDoneClick} />}
            {filteredItems.length && queryParams.orderBy === 'Type'
                ? (
                    <TypedIndexViews
                        typeFilters={typeFilters}
                        items={filteredItems}
                        onSeeMoreNews={onSeeMoreNews}
                        onSeeMorePracticeManagement={onSeeMorePracticeManagement}
                    />
                )
                : <IndexView
                    typeFilters={typeFilters}
                    items={filteredItems}
                />}
        </StyledResultsContainer>
        {!filteredItems.length && <NoResultsHeader searchValue={searchValue} />}
    </React.Fragment>
);

SearchResults.propTypes = {
    filteredItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onDoneClick: PropTypes.func.isRequired,
    onSeeMoreNews: PropTypes.func.isRequired,
    onSeeMorePracticeManagement: PropTypes.func.isRequired,
    searchValue: PropTypes.string.isRequired,
    queryParams: PropTypes.shape({
        orderBy: PropTypes.oneOf(['', 'Oldest First', 'Type']),
        q: PropTypes.string,
    }).isRequired,
    onSort: PropTypes.func,
};

export default SearchResults;
