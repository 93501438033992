import React from 'react';
import PropTypes from 'prop-types';
import language from '../../../../lang/en.json';

/**
 * Produces a visible image artifact with load error
 * handling to display application default image.
 */
class Image extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.onImageLoadError = this.onImageLoadError.bind(this);
        this.onImageLoad = this.onImageLoad.bind(this);
    }

    /**
     * Handle image load faiures and replace with local placeholder.
     * @param {*} event
     */
    onImageLoadError(event) {
        event.target.setAttribute('src', '/images/gradient.png');
        event.target.setAttribute( 'alt' , language.translations['global.alt.image']);

        if (this.props.onLoadError) {
            this.props.onLoadError();
        }
    }

    /**
     * Return a load success to a parent that might care.
     * @param {*} event
     */
    onImageLoad(event) {
        if (this.props.onLoadSuccess) {
            this.props.onLoadSuccess();
        }
    }

    /**
     * Render the image tag.
     */
    render() {
        const alt = this.props.altResourceId ? language.translations[this.props.altResourceId]
            : this.props.alt;
        const src = (this.props.src.indexOf('content-service') > -1 && this.props.src.indexOf('api/') === -1)
            ? this.props.src
            : this.props.src;
        return (
            <img
                src={src}
                alt={alt}
                onLoad={this.onImageLoad}
                onError={this.onImageLoadError}
            />
        );
    }
}

Image.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    altResourceId: PropTypes.string,
    onLoadError: PropTypes.func,
    onLoadSuccess: PropTypes.func,
}

Image.defaultProps = {
    src: null,
    alt: null,
    altResourceId: null,
    onLoadError: null,
    onLoadSuccess: null,
}

export default Image;
