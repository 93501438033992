import { css } from 'styled-components';
import { FROM_DESKTOP } from 'utils/mediaQueries';
import { RGB_COLORS, COLORS, ANIMATIONS } from 'utils/variables';

export const seeMoreLinkStyles = css`
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 2.1px;
    line-height: 1;
    text-decoration: none;
    text-transform: uppercase;
    word-wrap: break-word;

    @media screen and ${FROM_DESKTOP} {
        font-size: 14px;
    }

    /* stylelint-disable */

    ${props => props.variant && props.variant.theme === 'light' && css`
        color: ${COLORS.DARK_GRAY1};

        &:hover {
            color: ${COLORS.MEDIUM_GRAY2};
        }
    `};

    ${props => props.variant && props.variant.theme === 'dark' && css`
        color: rgba(${RGB_COLORS.WHITE}, 0.8);

        &:hover {
            color: rgba(${RGB_COLORS.WHITE}, 1);
        }
    `};

    &:hover {
        svg {
            stroke: ${COLORS.DARK_SAPPHIRE} !important;
        }
    }
    /* stylelint-enable */
`;

export const seeMoreIconStyles = css`
    margin-left: 8px;
    stroke: ${COLORS.SAPPHIRE};
    stroke-width: 2px;
    vertical-align: -4px;
    transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;
`;
