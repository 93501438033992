import moment from 'moment/moment';

const daytimeTypes = [
    'night',
    'morning',
    'afternoon',
    'evening',
];

export default function getDaytimeType() {
    const currentHour = moment().format('HH');

    if (currentHour < 4) {
        return daytimeTypes[0];
    } if (currentHour >= 4 && currentHour < 12) {
        return daytimeTypes[1];
    } if (currentHour >= 12 && currentHour < 18) {
        return daytimeTypes[2];
    }

    return daytimeTypes[3];
}
