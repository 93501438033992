import {
    SAVE_BASIC_PROFILE_FORM,
    SAVE_BASIC_PROFILE_FORM_SUCCESS,
    SAVE_BASIC_PROFILE_FORM_FAIL,
    SET_FORM_TOUCHED,
    SET_FORM_UNTOUCHED,
    SET_FORM_TO_EDIT_MODE,
    SET_FORM_TO_EDIT_DEFAULT_MODE,
    SET_FORM_TO_ONLY_EDIT_MODE,
    TRIGGER_FORM_SUBMIT,
    RESET_FORM_SUBMIT_TRIGGER,
    RESET_FORM_STATE,
    SET_FORM_RESET,
    SET_PREFERENCES_TO_DEFAULT,
    GET_BACK_TO_PREV_STATE
} from 'state-management/constants/basicProfileForm';
import {
    GET_PROFILE_SOURCES_SUCCESS
} from  'state-management/constants/sources';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
    SET_UPDATE_PROFILE_FAILED,
} from 'state-management/constants/basicProfile';

const initialState = {
    isSaving: false,
    isTouched: false,
    isInEditMode: false,
    isSubmitTriggered: false,
    isResetting: false,
    savedPreferences: false
};

function basicProfileFormReducer(state = initialState, action = {}) {
    switch (action.type) {
    case SAVE_BASIC_PROFILE_FORM:
        return {
            ...state,
            isSaving: true,
            isSubmitTriggered: false,
        };
    case RESET_FORM_STATE:
        return {
            ...initialState,
            savedPreferences: true,
        };
    case SET_PREFERENCES_TO_DEFAULT: 
        return {
            ...state,
            savedPreferences: true,
        };
    case GET_BACK_TO_PREV_STATE:
        return {
            ...state,
            savedPreferences: false,
        }
    case SAVE_BASIC_PROFILE_FORM_SUCCESS:
        return {
            ...initialState,
            savedPreferences: true,
        };
    case GET_PROFILE_SOURCES_SUCCESS: {
        return {
            ...state,
            savedPreferences: false,
        }
    }
    case SAVE_BASIC_PROFILE_FORM_FAIL:
        return {
            ...state,
            isSaving: false,
        };
    case SET_UPDATE_PROFILE_FAILED:
        return {
            ...state,
            isSaving: false,
        };
    case SET_FORM_TOUCHED:
        return {
            ...state,
            isTouched: true,
        };
    case SET_FORM_RESET:
        return {
            ...state,
            isResetting: false,
        }
    case SET_FORM_UNTOUCHED:
        return {
            ...state,
            isTouched: false,
            isResetting: true,
        };
        case LOCATION_CHANGE:
            return {
                ...state,
                isInEditMode: false,
            };        
        case SET_FORM_TO_ONLY_EDIT_MODE: 
        return {
            ...state,
            isInEditMode: true,
        }
        case SET_FORM_TO_EDIT_DEFAULT_MODE:
            return {
                ...state,
                isInEditMode: true,
                isTouched: false,
                isTouchedPrompt: false,
            };    
    case SET_FORM_TO_EDIT_MODE:
        return {
            ...state,
            isInEditMode: true,
            isTouched: true,
            isTouchedPrompt: true,
        };
    case TRIGGER_FORM_SUBMIT:
        return {
            ...state,
            isSubmitTriggered: true,
        };
    case RESET_FORM_SUBMIT_TRIGGER:
        return {
            ...state,
            isSubmitTriggered: false,
        };
    default:
        return state;
    }
}

export default basicProfileFormReducer;