import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button, { TYPE_GHOST, TYPE_PRIMARY_MEDIUM_LARGE } from 'components/atomics/atoms/Button/Button';
import InputAtom, { TYPE_LARGE } from 'components/atomics/atoms/Input/Input';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { sendForgotUsernameEmail, resetForgotUsernameEmail } from 'state-management/actions/forgotUsername';
import { isEmailValid } from 'utils/validators/emailValidator';

import {
    container,
    buttonRow,
    title,
    subTitle,
    callout,
    fieldRow,
    form,
    errorRow,
    signUpLinkStyles,
} from './styles';

const Container = styled.div`
    ${container}
`;

const Callout = styled.div`
    ${callout}
`;

const ButtonRow = styled.div`
    ${buttonRow}
`;

const Title = styled.div`
    ${title}
`;

const SubTitle = styled.div`
    ${subTitle}
`;

const FieldRow = styled.div`
    ${fieldRow}
`;

const Form = styled.div`
    ${form}
`;

const ErrorRow = styled.div`
    ${errorRow}
`;

const StyledSignUpLink = styled(Link)`
    ${signUpLinkStyles};
`;

export class ForgotUsernameModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            error: null,
        };
    }

    componentDidMount() {
        this.props.resetForgotUsernameEmail();
    }

    translate = (id, values = {}) => {
        const { intl: { formatMessage } } = this.props;
        return formatMessage({ id }, values);
    }

    validate = () => {
        const { email } = this.state;
        if (!email) {
            this.setState({ error: this.translate('validation.error.email.empty') });
        } else if (!isEmailValid(email)) {
            this.setState({ error: this.translate('validation.error.email.format') });
        }
    }

    submit = () => {
        const { email } = this.state;
        if (email && isEmailValid(email)) {
            this.props.sendForgotUsernameEmail(email);
        }
    }

    render() {
        const { forgotUsernameEmail, forgotUsernameError } = this.props;

        return (
            <Container>

                {forgotUsernameEmail ? (
                    <Form>
                        <Title>
                            <FormattedMessage id="signIn.forgotUsername.success.title" />
                        </Title>

                        <SubTitle>
                            <FormattedMessage id="signIn.forgotUsername.success.description" values={{ email: forgotUsernameEmail }} />
                        </SubTitle>

                        <Callout>
                            <FormattedMessage id="signIn.forgotUsername.success.hint" />
                        </Callout>

                        <ButtonRow>
                            <Button
                                type={TYPE_GHOST}
                                onClick={this.submit}
                                dontScroll={true}
                                noAnchor
                            >
                                <FormattedMessage id="signIn.forgotUsername.success.action" />
                            </Button>
                        </ButtonRow>
                    </Form>
                ) : (
                    <Form>
                        <Title>
                            <FormattedMessage id="signIn.forgotUsername.title" />
                        </Title>

                        <SubTitle>
                            <FormattedMessage id="signIn.forgotUsername.description" />
                        </SubTitle>

                        <FieldRow>
                            <InputAtom
                                autocomplete="off"
                                inputType="email"
                                id="email"
                                showCheckWhenValid
                                validated={false}
                                label={this.translate('signIn.forgotUsername.email')}
                                placeholder={this.translate('signIn.forgotUsername.email')}
                                error={this.state.error}
                                onBlur={this.validate}
                                onFocus={() => this.setState({ error: null })}
                                onChange={({ value }) => this.setState({ email: value })}
                                isErrored={this.state.error !== null}
                                type={TYPE_LARGE}
                                value={this.state.email || ''}
                            />
                        </FieldRow>

                        { forgotUsernameError && (
                            <ErrorRow>
                                <FormattedMessage id={`signIn.forgotUsername.failure.${forgotUsernameError}`} />
                            </ErrorRow>
                        )}

                        <ButtonRow>
                            <Button
                                type={TYPE_PRIMARY_MEDIUM_LARGE}
                                onClick={this.submit}
                                dontScroll={true}
                                noAnchor
                            >
                                <FormattedMessage id="signIn.forgotUsername.action" />
                            </Button>
                        </ButtonRow>

                        <Callout>
                            <FormattedMessage
                                id="signIn.forgotUsername.hint"
                                values={{
                                    link: (
                                        <StyledSignUpLink to="/contact-us">
                                            <FormattedMessage id="signIn.forgotUsername.hint.link" />
                                        </StyledSignUpLink>
                                    ),
                                }}
                            />
                        </Callout>
                    </Form>
                )}

            </Container>
        );
    }
}

ForgotUsernameModal.propTypes = {
    sendForgotUsernameEmail: PropTypes.func,
};

const mapStateToProps = state => ({
    forgotUsernameEmail: state.forgotUsername.email,
    forgotUsernameError: state.forgotUsername.error,
});

const mapDispatchToProps = dispatch => ({
    resetForgotUsernameEmail: () => dispatch(resetForgotUsernameEmail()),
    sendForgotUsernameEmail: email => dispatch(sendForgotUsernameEmail(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ForgotUsernameModal));
