import {
    GET_TOPICS,
    GET_TOPICS_SUCCESS,
    GET_TOPICS_FAIL,
    SUBMIT_FEEDBACK,
    SUBMIT_FEEDBACK_SUCCESS,
    SUBMIT_FEEDBACK_FAIL,
    RESET_CONTACT_FORM,
} from 'state-management/constants/contactUs';

export const getTopics = {
    type: GET_TOPICS,
};

export const getTopicsSuccess = topics => ({
    type: GET_TOPICS_SUCCESS,
    topics,
});

export const getTopicsFail = {
    type: GET_TOPICS_FAIL,
};

export const submitFeedback = data => ({
    type: SUBMIT_FEEDBACK,
    data,
});

export const submitFeedbackSuccess = {
    type: SUBMIT_FEEDBACK_SUCCESS,
};

export const submitFeedbackFail = {
    type: SUBMIT_FEEDBACK_FAIL,
};

export const resetContactForm = {
    type: RESET_CONTACT_FORM,
};
