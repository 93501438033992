import { css } from 'styled-components';
import {
    FROM_TABLET,
    FROM_MOBILE_TO_TABLET,
} from 'utils/mediaQueries';
import { StyledHeader } from 'components/atomics/molecules/Header/Header';
import { COLORS } from 'utils/variables';

export const wrapperStyles = css`
    display: flex;
    flex-direction: column;
    min-height: 105vh;
    background: ${COLORS.LIGHT_GRAY2};
   
    /* stylelint-disable */

    ${props => props.isExpanded && css`
        position: fixed;
        width: 100%;

        @media screen and ${FROM_TABLET} {
            position: relative;
        }

        @media screen and ${FROM_MOBILE_TO_TABLET} {
            /* stylelint-disable */

            ${StyledHeader} {
                z-index: 8;
            }
            /* stylelint-enable */
        }
    `};

    /* stylelint-enable */
`;

export const mainStyles = css`
    display: block;
    // flex: 1 1 auto;
    flex-direction: column;
    min-height: 1px;
    outline: 0;
    background-color: ${COLORS.LIGHT_GRAY1};
`;
