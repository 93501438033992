import {
    GET_BASIC_PROFILE, GET_BASIC_PROFILE_ERROR, SAVE_BASIC_PROFILE,
    SET_BASIC_PROFILE, SET_LOADING_BASIC_PROFILE, CLEAR_BASIC_PROFILE,
    SAVE_BASIC_PROFILE_AND_REDIRECT, SAVE_BASIC_PROFILE_CREDENTIALS,
    SAVE_ONBOARDED_AND_SAVE_BASIC_PROFILE_AND_REDIRECT,
    SET_UPDATING_BASIC_PROFILE, SET_UPDATE_PROFILE_FAILED,
    SET_PASSWORD_FAILED,
    FOLLOW_SOURCE, UNFOLLOW_SOURCE,
    SET_HOMEPAGE, UPDATE_HOMEPAGE,
    FOLLOW_TOPIC, UNFOLLOW_TOPIC,
    GET_ISAPPINSTALLED, GET_ISAPPINSTALLED_SUCCESS, GET_ISAPPINSTALLED_FAIL,
    SET_ISAPPINSTALLED,
    VALIDATE_TOKEN,
    VALIDATE_TOKEN_FAILED,
    VALIDATE_TOKEN_SUCCESS,
    REMOVE_VALIDATE_TOKEN,
    SHOW_PREFERENCES_MODAL,
    HIDE_PREFERENCES_MODAL,
    GET_USERID_FROM_TOKEN,
    GET_USERID_FROM_TOKEN_SUCCESS,
    GET_USERID_FROM_TOKEN_FAIL,
    CLEAR_REFERRER_USERID,
    SHOW_SOURCE_MODAL,
    HIDE_SOURCE_MODAL,
    GO_TO_PAGE,
    GET_NOTIFICATION,
    GET_NOTIFICATION_SUCCESS,
    GET_NOTIFICATION_FAILURE,
    DISMISS_EXPIRING_BOOKMARKS_NOTIFICATION,
} from 'state-management/constants/basicProfile';

export const showPreferencesModal = () => ({
    type: SHOW_PREFERENCES_MODAL,
});

export const goToPage = (page) => ({
    type: GO_TO_PAGE,
    page,
});

export const hidePreferencesModal = () => ({
    type: HIDE_PREFERENCES_MODAL,
});


export const setIsAppInstalled = () => ({
    type: SET_ISAPPINSTALLED,
});

export const getBasicProfile = (shareId) => ({
    type: GET_BASIC_PROFILE,
    shareId,
});

export const getIsAppInstalled = () => ({
    type: GET_ISAPPINSTALLED,
});

export const getIsAppInstalledFailed = () => ({
    type: GET_ISAPPINSTALLED_FAIL,
});

export const getIsAppInstalledSuccess = data => ({
    type: GET_ISAPPINSTALLED_SUCCESS,
    data,
});

export const setBasicProfileLoading = () => ({
    type: SET_LOADING_BASIC_PROFILE,
    isLoading: true,
});

export const setBasicProfileUpdating = () => ({
    type: SET_UPDATING_BASIC_PROFILE,
    isUpdating: true,
});

export const setBasicProfile = data => ({
    type: SET_BASIC_PROFILE,
    data,
});

export const setBasicProfileUpdateFailed = () => ({
    type: SET_UPDATE_PROFILE_FAILED,
});

export const setBasicProfilePasswordFailed = () => ({
    type: SET_PASSWORD_FAILED,
});

export const getBasicProfileError = error => ({
    type: GET_BASIC_PROFILE_ERROR,
    error,
});

export const saveBasicProfile = data => ({
    type: SAVE_BASIC_PROFILE,
    data,
});

export const saveBasicProfileAndRedirect = data => ({
    type: SAVE_BASIC_PROFILE_AND_REDIRECT,
    data,
});

export const saveOnboardedAndSaveBasicProfileAndRedirect = data => ({
    type: SAVE_ONBOARDED_AND_SAVE_BASIC_PROFILE_AND_REDIRECT,
    data,
});

export const saveProfileCredentials = data => ({
    type: SAVE_BASIC_PROFILE_CREDENTIALS,
    data,
});

export const clearBasicProfile = () => ({
    type: CLEAR_BASIC_PROFILE,
});

export const setHomepage = homepageType => ({
    type: SET_HOMEPAGE,
    homepageType,
});

export const updateHomepage = homepageType => ({
    type: UPDATE_HOMEPAGE,
    homepageType,
});

export const followTopic = topicId => ({
    type: FOLLOW_TOPIC,
    topicId,
});

export const unfollowTopic = topicId => ({
    type: UNFOLLOW_TOPIC,
    topicId,
});

export const followSource = sourceId => ({
    type: FOLLOW_SOURCE,
    sourceId,
});

export const unfollowSource = sourceId => ({
    type: UNFOLLOW_SOURCE,
    sourceId,
});

export const validateToken = () => ({
    type: VALIDATE_TOKEN,
});

export const validateTokenSuccess = expires => ({
    type: VALIDATE_TOKEN_SUCCESS,
    expires,
});

export const validateTokenFailed = () => ({
    type: VALIDATE_TOKEN_FAILED,
});

export const removeValidateToken = () => ({
    type: REMOVE_VALIDATE_TOKEN,
});

export const showSourceModal = () => ({
    type: SHOW_SOURCE_MODAL,
});

export const hideSourceModal = () => ({
    type: HIDE_SOURCE_MODAL,
});

export const getNotification = () => ({ type: GET_NOTIFICATION });
export const getNotificationSuccess = (data) => ({ type: GET_NOTIFICATION_SUCCESS, data });
export const getNotificationFailure = (error) => ({ type: GET_NOTIFICATION_FAILURE, error });
export const dismissExpiringBookmarksNotification = (key, ids) => ({ type: DISMISS_EXPIRING_BOOKMARKS_NOTIFICATION, key, ids });