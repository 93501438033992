import { css } from 'styled-components';
import { FROM_TABLET } from 'utils/mediaQueries';
import { COLORS, RGB_COLORS } from '../../utils/variables';

export const toastWrapperStyles = css`
    background-color: rgba(${RGB_COLORS.DARK_SAPPHIRE}, 0.85);
    border-radius: 6px;
    box-sizing: border-box;
    color: ${COLORS.WHITE};
    font-size: 16px;
    padding: 16px;
    text-align: center;
    word-wrap: break-word;

    & + & {
        margin-top: 10px;
    }

    @media screen and ${FROM_TABLET} {
        padding: 24px;
        text-align: left;
        width: 280px;
    }
`;

export const toastTitleStyles = css`
    font-weight: 700;
    word-wrap: break-word;
`;

export const toastControlsWrapperStyles = css`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
`;
