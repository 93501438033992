import { css } from 'styled-components';
import { FROM_DESKTOP, FROM_TABLET } from 'utils/mediaQueries';
import {
    COLORS,
} from 'utils/variables';

export const loginSectionStyles = css`
    background-color: ${COLORS.LIGHT_GRAY1};
    display: flex;
    flex: 1 0 auto;
    padding: 0 0 60px;
    position: relative;

    @media screen and ${FROM_TABLET} {
        padding: 0 0;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 120px 0;
    }

    &>div[data-rel="splash"] {
        position: absolute;
        left: 0;
        top: 103px;
        height: 4px;
        width: 45px;
        background-image: linear-gradient(to left, ${COLORS.OCEAN}, ${COLORS.SAPPHIRE});

        @media screen and ${FROM_TABLET} {
            top: 103px;
            left: 0;
            width: 50px;
        }

        @media screen and ${FROM_DESKTOP} {
            top: 141px;
            left: 0;
            width: calc(((100vw - 1170px) / 2) - 25px);
            min-width: 61px;
        }
    }
`;

export const containerStyles = css`
    display: flex;
    flex: auto;
    flex-direction: column;
    padding: 0 30px;
    width: 100%;

    @media screen and ${FROM_TABLET} {
        padding: 0 38px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 0 48px;
    }
`;

export const contentWrapperStyles = css`
    box-sizing: border-box;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
`;
