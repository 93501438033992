import * as type from '../constants/marketIndices.common';

export const getMarketIndices = () => ({
    type: type.GET_MARKET_INDICES,
});

export const getMarketIndicesSuccess = data => ({
    type: type.GET_MARKET_INDICES_SUCCESS,
    data,
});

export const getMarketIndicesFailure = error => ({
    type: type.GET_MARKET_INDICES_FAIL,
    error,
});
