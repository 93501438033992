export const SAVE_BASIC_PROFILE_FORM = 'basicProfileForm/SAVE_BASIC_PROFILE_FORM';
export const SAVE_BASIC_PROFILE_FORM_SUCCESS = 'basicProfileForm/SAVE_BASIC_PROFILE_FORM_SUCCESS';
export const SAVE_BASIC_PROFILE_FORM_FAIL = 'basicProfileForm/SAVE_BASIC_PROFILE_FORM_FAIL';

export const SET_FORM_TOUCHED = 'basicProfileForm/SET_FORM_TOUCHED';
export const SET_FORM_UNTOUCHED = 'basicProfileForm/SET_FORM_UNTOUCHED';
export const SET_FORM_RESET = 'basicProfileForm/SET_FORM_RESET';
export const SET_FORM_TO_EDIT_MODE = 'basicProfileForm/SET_FORM_TO_EDIT_MODE';
export const SET_FORM_TO_EDIT_DEFAULT_MODE = 'basicProfileForm/SET_FORM_TO_EDIT_DEFAULT_MODE';
export const TRIGGER_FORM_SUBMIT = 'basicProfileForm/TRIGGER_FORM_SUBMIT';
export const RESET_FORM_SUBMIT_TRIGGER = 'basicProfileForm/RESET_FORM_SUBMIT_TRIGGER';
export const RESET_FORM_STATE = 'basicProfileForm/RESET_FORM_STATE';
export const SET_PREFERENCES_TO_DEFAULT = 'basicProfileForm/SET_PREFERENCES_TO_DEFAULT';
export const GET_BACK_TO_PREV_STATE = 'basicProfileForm/GET_BACK_TO_PREV_STATE';
export const SET_FORM_TO_ONLY_EDIT_MODE = 'basicProfileForm/SET_FORM_TO_ONLY_EDIT_MODE';