import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import {
    authorStyles,
    contentStyles,
    imageCreditStyles,
    imageStyles,
    contentWrapperStyles,
    additionalInfoWrapperStyles,
} from 'containers/Article/ArticleContent/styles';
import Image from 'components/atomics/atoms/Image/Image';
import {COLORS} from "../../../utils/variables";

const StyledContentWrapper = styled.div`
    ${contentWrapperStyles}
`;

const StyledImageCredit = styled.p`
    ${imageCreditStyles}

`;

const PhotoCreditContainer = styled.div`
         font-family: 'AvenirNext', sans-serif;
         font-size: 10px;
         text-align: right;
         text-transform: uppercase;
         margin-top: 10px;
         color: ${COLORS.MEDIUM_GRAY2};

`;

const StyledAdditionalInfoWrapper = styled.div`
    ${additionalInfoWrapperStyles};
`;

const StyledAuthor = styled.p`
    ${authorStyles}
`;

const StyledContent = styled.div`
    ${contentStyles}
`;

const StyledImage = styled.div`
    ${imageStyles}
`;

const renderImageCaption = (image) => {
    const credit = image && image.credit;

    return credit && <FormattedMessage  id="articles.imageCredit" values={{ credit }} style={{display:'none'}} />;
};

const renderAuthors = authors => (
    <StyledAuthor>
        {authors && (
            <FormattedMessage id="articles.author" values={{ author: authors }} />
        )}
    </StyledAuthor>
);

/**
 * Wrapper for main article content display.
 * @param {*} param0 
 */
const ArticleContent = ({
    content,
    image,
    authors,
    title,
}) => (
    <div>
        {image && image.url && (
            <StyledImage>
                <Image isExternal src={image.url} alt={title} />
                <PhotoCreditContainer>
                    {renderImageCaption(image)}
                </PhotoCreditContainer>
            </StyledImage>
        )}
        <StyledContentWrapper>
            <StyledAdditionalInfoWrapper>

                {renderAuthors(authors)}
            </StyledAdditionalInfoWrapper>
            <StyledContent>
                {content}
            </StyledContent>
        </StyledContentWrapper>
    </div>
);

ArticleContent.propTypes = {
    content: PropTypes.string.isRequired,
    image: PropTypes.shape({
        credit: PropTypes.string,
        url: PropTypes.string,
    }),
    authors: PropTypes.string,
    title: PropTypes.string,
};

ArticleContent.defaultProps = {
    image: null,
    title: '',
    authors: '',
};

export default ArticleContent;
