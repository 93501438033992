import { css } from 'styled-components';
import {
    COLORS,
    FONTS,
} from 'utils/variables';
import {
    FROM_TABLET,
    FROM_DESKTOP,
} from 'utils/mediaQueries';

export const contentContainerStyles = css`
    margin: auto;
    max-width: 1170px;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;

    @media screen and ${FROM_TABLET} {
        padding-left: 38px;
        padding-right: 38px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding-left:  48px;
        padding-right: 48px;
    }

    div {
        position: absolute;
        left: -70px;
        top: 10px;
        height: 4px;
        width: 80px;
        background-image: linear-gradient(to left, ${COLORS.OCEAN}, ${COLORS.SAPPHIRE} 40px, ${COLORS.SAPPHIRE} 60px, transparent 80px);

        @media screen and ${FROM_TABLET} {
            top: 13px;
            left: -50px;
        }

        @media screen and ${FROM_DESKTOP} {
            top: 22px;
            left: -45px;
        }
    }
`;

export const backgroundStyles = css`
    background: ${COLORS.LIGHT_GRAY1};
`;

export const containerStyles = css`
    display: block;
    position: relative;

    h1 {
        color: ${COLORS.SAPPHIRE};
        font-family: ${FONTS.AVENIR_NEXT.FONT};
        font-size: 20px;
        font-weight: 400;
        letter-spacing: -0.08px
        line-height: 26px;
        margin: 0;
    }

    margin-top: 29px;
    margin-bottom: 29px;

    @media screen and ${FROM_TABLET} {
        h1 {
            font-size: 26px;
            line-height: 31px;
            letter-spacing: -0.11px;
            max-width: 520px;
        }
        
        margin-top: 40px;
        margin-bottom: 45px;
    }

    @media screen and ${FROM_DESKTOP} {
        h1 {
            font-size: 42px;
            line-height: 52px;
            max-width: 886px;
        }

        margin-top: 52px;
        margin-bottom: 53px;
    }
`;
