import { css } from 'styled-components';
import { outerCustomFocusMixin } from 'components/FocusVisibility/styles';
import { visuallyHiddenStyles } from 'components/VisuallyHidden/styles';
import {
    COLORS,    
} from 'utils/variables';

export const labelStyles = css`
    align-items: center;
    color: ${COLORS.DARK_GRAY2};
    cursor: pointer;
    display: inline-flex;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 3px;
    text-transform: uppercase;
`;

export const radioInputStyles = css`
    ${visuallyHiddenStyles}
`;

export const indicatorStyles = ({ variant }) => css`
    border: 2px solid ${COLORS.MEDIUM_GRAY2};
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    flex-shrink: 0;
    height: 20px;
    margin-right: 14px;
    position: relative;
    width: 20px;

    /* stylelint-disable */

    ${variant.checked && css`
        border-color: ${COLORS.SAPPHIRE};

        &::before {
            background-color: ${COLORS.SAPPHIRE};
            border-radius: 50%;
            content: '';
            height: 10px;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 10px;
        }
    `}
    
    ${variant.isFocused && css`
        ${outerCustomFocusMixin};
    `}

    /* stylelint-enable */
`;
