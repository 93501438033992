import { css } from 'styled-components';
import {
    COLORS,
    FONTS,
    ANIMATIONS,
} from 'utils/variables';
import {
    FROM_DESKTOP,
    FROM_TABLET,
    FROM_MOBILE_TO_TABLET,
    SMALL_MOBILE,
} from 'utils/mediaQueries';

export const buttonContainer = css`
    transition: 0.3s;
    opacity: 1;   

    @media screen and ${FROM_DESKTOP} {
        &:hover{
            opacity: 1; 
        }

        display: ${props => JSON.parse(props.display) ? 'block' : 'none'};
    }
`;

export const contentContainer = css`
    @media screen and ${FROM_DESKTOP} {
        opacity: ${props => JSON.parse(props.display) ? '1' : '0'};
        display: ${props => JSON.parse(props.display) ? 'block' : 'none'};
    }

    transition: 0.3s;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 460px;

    ${props => props.hasLimit && css`
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        max-width: ${props.limit}px;
    `}
`;

export const sourceItem = css`
    position: absolute;
    bottom: 18px;
`;

export const categoryTitle = css`
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    color: ${COLORS.SAPPHIRE};
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    position:absolute;
    bottom: 6px;
    display: inline-block;
    text-decoration: none;

    p {
        font-size: 12px;
        line-height: 1.33;
        letter-spacing: normal;
    }

    @media screen and ${SMALL_MOBILE} {
        p {
            font-size: 12px;
            line-height: 1.33;
            letter-spacing: normal;
        }
    }

    @media screen and ${FROM_TABLET} {
        p {
            font-size: 10px;
            line-height: 1.4;
            letter-spacing: 0.1px;
        }
    }

    @media screen and ${FROM_DESKTOP} {
        p {
            font-size: 12px;
            line-height: 1.33;
            letter-spacing: normal;
        }
    }
`;

export const titleStyles = css`
    display: inline-block;
    color: ${COLORS.BLACK};
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.1px;
    line-height: 20px;
    margin: 0;
    margin-bottom: 8px;
    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.TITLE.TIME.SECONDS}s all;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        min-height: 62px;
    }

    ${props => props.simplelist === 'true' && css`
        display: inline-block;
        margin-bottom: 13px;
    `};

    &:hover {
        color: ${COLORS.MEDIUM_GRAY2};
    }
`;

export const containerStyles = css`
    position: relative;
    border-bottom: 1px solid ${COLORS.LIGHT_GRAY2};
    color: ${COLORS.BLACK};
    display: block;
    &.news-not-visited{
        opacity:1;
    }
    &.news-visited{
        opacity:0.6;
    }

    ${props => props.simplelist === 'false' && css`
        max-width: 230px;
    `};

    padding: 13px 16px 11px 16px;

    ${props => props.simplelist === 'true' && css`
        padding: 19px 17px 19px 17px;
    `};

    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.CONTAINER.TIME.SECONDS}s all;

    &:hover {
        img {
            transform: scale3d(1.05,1.05,1);
        }
    }

    ${props => props.simplelist === 'true' && css`
        @media screen and ${FROM_TABLET} {
            padding: 19px 23px 19px 23px;
        }
    `};

    ${props => props.simplelist === 'false' && css`
        @media screen and ${FROM_MOBILE_TO_TABLET} {
            display: flex;
            max-width: 375px;
            padding: 22px 23px 19px 25px;
        }

        @media screen and ${FROM_DESKTOP} {
            display: flex;
            max-width: 375px;
            padding: 22px 23px 19px 25px;
        }
    `};
`;

export const dateAndTopicContainerStyles = css`
    display: flex;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-bottom: 8px;
    text-transform: uppercase;
`;

export const dateContainerStyles = css`
    color: ${COLORS.DARK_GRAY1}
    padding-right: 10px;
    white-space: nowrap;
`;

export const topicLinkStyles = css`
    color: ${COLORS.SAPPHIRE};
    flex: 1;
    text-align: right;
    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.TOPIC.TIME.SECONDS}s all;

    &:hover {
        opacity: 0.6;
    }
`;

export const imageContainerStyles = css`
    height: 96px;
    max-height: 69px;
    width: 69px;
    overflow: hidden;


    img {
        display: none;
        height: 100%;
        object-fit: cover;
        transition: ${ANIMATIONS.ARTICLES.IMAGE.TIME.SECONDS}s all;

        @media screen and ${FROM_MOBILE_TO_TABLET} {
            display block;
        }

        @media screen and ${FROM_DESKTOP} {
            display: block;
        }
    }
`;

export const contentContainerStyles = css`
    display: flex;
    flex-direction: column;

    &>a {
        flex: 1;
    }

    ${props => props.simplelist === 'false' && css`
        @media screen and ${FROM_MOBILE_TO_TABLET} {
            max-width: calc(100% - 94px);
            width: calc(100% - 94px);
            padding-left: 23px;
            padding-bottom: 30px;
        }

        @media screen and ${FROM_DESKTOP} {
            max-width: calc(100% - 94px);
            width: calc(100% - 94px);
            padding-left: 23px;
        }
    `};
`;
