import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Link from 'components/atomics/atoms/Link/Link';
import Button from 'components/atomics/atoms/Button/Button';
import storage from 'utils/store';
import {
    TYPE_PRIMARY_MEDIUM,
} from 'components/atomics/atoms/Button/Button';
import { connect } from 'react-redux';
import { handleGuideVisit } from 'state-management/actions/guide';
import { visitArticle } from 'state-management/actions/articles.common';
import { CAPITAL_IDEAS_ROUTE } from 'state-management/constants/news';
import { Container } from 'components';
import { IconSeeMore } from 'components/atomics/atoms/Icons/Icons';
import language from '../../../lang/en.json';;
import {
    sectionStyles,
    linkStyles,
    linkContainerStyles,
    headerStyles,
    buttonContainerStyles,
    loaderStyles,
    blankAreaStyles,
} from 'containers/News/LatestCapitalIdeas/styles';
import { COACH_MARK_SAVE_CONFIRMATION } from 'state-management/constants/coachMark';
import CapitalCardCollectionOfFour from '../../../components/atomics/organisms/CapitalCardCollectionOfFour/CapitalCardCollectionOfFour';
import {
    BreakpointsContext,
    MOBILE,
} from 'components/Breakpoints/Breakpoints';
import { GLOBAL } from 'utils/variables';
import anime from 'animejs';
import { StyledLoader, defaultOptions } from 'components/Loader/Loader';
import { transformContentItem } from "utils/contentCardUtils";

export const CAPITAL_IDEAS_DATA = 'capitalIdeasData';

const StyledSection = styled.section`
    ${sectionStyles};
`;

const StyledLink = styled(Link)`
    ${linkStyles}
`;

const LinkContainer = styled.div`
    ${linkContainerStyles}
`;

const StyledHeader = styled.div`
    ${headerStyles}
`;

const StyledLoaderContainer = styled.div`
    ${loaderStyles}
`;

const StyledBlankArea = styled.div`
    ${blankAreaStyles}
`;

const ButtonContainer = styled.div`
    padding-bottom: 25px;
    ${buttonContainerStyles};
`;

/**
 * Latest capital ideas component for news page.
 */
class LatestCapitalIdeas extends React.Component {
    /**
     * Default constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            savedNewsId: null,
            hidden: false,
        };

        this.handleGuideClick = this.handleGuideClick.bind(this);
        this.animateClosed = this.animateClosed.bind(this);

        this.contentRef = React.createRef();
    }

    /**
     * Run the components close animation if the data failed to load.
     * @param {*} nextProps 
     */
    componentWillUpdate(nextProps) {
        if (nextProps.loadingFailed === true) {
            this.animateClosed();
        }
    }

    /**
     * Collapse this component closed (and opacity to 0) from bottom to top.
     */
    animateClosed() {
        const contentNode = this.contentRef.current;

        if (!contentNode) {
            return;
        }

        this.animation = anime.timeline();

        this.animation
            .add({
                targets: contentNode,
                easing: [0.53, 0.05, 0.01, 0.97],
                opacity: [1, 0],
                duration: GLOBAL.ANIMATIONS.COLLAPSE_TIME.MILISECONDS,
                height: 0,
                paddingTop: 0,
                paddingBottom: 0,
            });

        setTimeout(() => {
            this.setState({
                hidden: true,
            });
        }, 3000);

        this.props.handleComponentFailure();
    }

    /**
     * Handle save actions with capital ideas component.
     */
    onSaveToggleClick = (item) => {
        if (this.props.isSavePending && !this.state.savedNewsId) {
            this.setState({ savedNewsId: item.id });
        }
    };

    /**
     * Handle recording a guide click so visited state is updated.
     * @param {*} id
     */
    handleGuideClick(article) {
        this.props.onGuideClick(article.id);
        this.props.onArticleClick(article);
    }

    /**
     * Render view more buttons.
     */
    renderMoreButton() {
        return (
            <BreakpointsContext.Consumer>
                {(breakpoint) => {
                    if (breakpoint === MOBILE) {
                        return (
                            <ButtonContainer>
                                <Button
                                    type={TYPE_PRIMARY_MEDIUM}
                                    to={`${CAPITAL_IDEAS_ROUTE}`}
                                    data-analytics-placement="Anchor : body"
                                    data-analytics-label="trackLink : anchor"
                                    data-analytics-id={language.translations["latestCapitalIdeas.more"]}
                                >
                                    <FormattedMessage id="latestCapitalIdeas.more" />
                                </Button>
                            </ButtonContainer>
                        );
                    } else {
                        return (
                            <LinkContainer>
                                <StyledLink
                                    to={`${CAPITAL_IDEAS_ROUTE}`}
                                    data-analytics-placement="Anchor : body"
                                    data-analytics-label="trackLink : anchor"
                                    data-analytics-id={language.translations["latestCapitalIdeas.more"]}
                                >    <FormattedMessage id="latestCapitalIdeas.more" />
                                    <IconSeeMore />
                                </StyledLink>
                            </LinkContainer>
                        );
                    }
                }}
            </BreakpointsContext.Consumer>
        )
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const {
            news,
            isLoaded,
        } = this.props;
        const articles = [];

        if (isLoaded && news && news.length) {
            news.map(newsItem => {
                articles.push(transformContentItem(newsItem));

                storage.set(CAPITAL_IDEAS_DATA, JSON.stringify(articles.filter(item => !!item.id).map(item => item.id)));
            });
        }

        if (this.state.hidden) {
            return null;
        }

        return (
            <StyledSection ref={this.contentRef}>
                <Container noMobilePadding={true}>
                    <StyledHeader>
                        <FormattedMessage id="capitalideas.title" />
                    </StyledHeader>

                    {!isLoaded
                        && <StyledBlankArea>
                            <StyledLoaderContainer>
                                <StyledLoader
                                    options={defaultOptions}
                                />
                            </StyledLoaderContainer>
                        </StyledBlankArea>}

                    {isLoaded
                        && <CapitalCardCollectionOfFour
                            articles={articles}
                            handleGuideClick={this.handleGuideClick}
                        />}
                    {isLoaded
                        && this.renderMoreButton()}
                </Container>
            </StyledSection>
        );
    }
}

LatestCapitalIdeas.propTypes = {
    isLoaded: PropTypes.bool,
    isSavePending: PropTypes.bool,
    isSeeMoreLinkVisible: PropTypes.bool.isRequired,
    news: PropTypes.arrayOf(PropTypes.shape({})),
    newsIds: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ])),
    loadingFailed: PropTypes.bool,
    handleComponentFailure: PropTypes.func,
};

LatestCapitalIdeas.defaultProps = {
    isLoaded: false,
    isSavePending: false,
    news: [],
    newsIds: [],
    loadingFailed: false,
    handleComponentFailure: () => { },
};

const mapStateToProps = state => ({
    loadingFailed: state.latestCapitalIdeas.loadingFailed,
    isLoaded: state.latestCapitalIdeas.isLoaded,
    isSavePending: state.coachMark.display[COACH_MARK_SAVE_CONFIRMATION],
    isSeeMoreLinkVisible: state.latestCapitalIdeas.seeMore,
    news: state.latestCapitalIdeas.news,
    newsIds: state.latestCapitalIdeas.newsIds,
    showCoachMark: state.coachMark.display.KEBAB && !state.coachMark.display.INFO_BAR
});

const mapDispatchToProps = dispatch => ({
    onGuideClick: id => dispatch(handleGuideVisit(id)),
    onArticleClick: article => dispatch(visitArticle(article)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LatestCapitalIdeas);
