import {
    all, call, fork, put, take, takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { WEBINAR_DATA_URL } from "../constants/webinar";
import { setScrollToTop } from 'state-management/actions/scroll';
import {
    SIGN_UP,
    SIGN_UP_URL,
    RESEND_EMAIL,
    RESEND_EMAIL_URL,
    CONFIRM_EMAIL,
    CONFIRM_EMAIL_URL,
    CONFIRM_MIGRATED_EMAIL_URL,
    CHANGE_EMAIL,
    CHANGE_EMAIL_GET_URL,
} from 'state-management/constants/signUp';
import {
    getWebinarInfoSuccess,
    getWebinarIsUserRegisteredYes,
    getWebinarIsUserRegisteredNo,
    notInterested
} from 'state-management/actions/webinar';
import { addToasts } from 'state-management/actions/toasts';
import {
    setSubmitting, setErrors, resetErrors, setSuccess,
    confirmEmailSuccess, confirmEmailFail,
    resendEmailComplete, changeEmailSuccess, changeEmailFail,
    resendEmailFail,
} from 'state-management/actions/signUp';
import Api from 'utils/api';
import authenticate from 'state-management/sagas/authentication';
import { setFocusOnError } from 'state-management/actions/errors';
import { signInSuccess } from 'state-management/actions/signIn';
import * as analytics from 'utils/adobeAnalytics';
import storage from "../../utils/store";
import { TYPES } from 'containers/Toasts/constants';
import { EMAIL_CHANGED } from 'containers/EmailVerification/ConfirmEmail/ConfirmEmail';
import { MOBILE_APP_NOTIFICATION } from "../../containers/MobileAppNotification/MobileAppNotification";

export const SIGNUP_EMAIL = 'storage/signupEmail';
export const SIGNUP_FNAME = 'storage/signupFname';

const formatSignUpData = (data, identityData, isWebinar, webinarData) => {
    const {
        email, firstName, lastName, password,
    } = data;
    const {
        crd,
    } = identityData;
    const crdValue = crd && crd.length ? crd : null;
    const url = window.location.href;
    let signUpType = 'OTHERS';
    
    if (url.indexOf("?token=") > -1)
        signUpType = "REFER";

    return {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim(),
        password,
        crd: crdValue,
        signUpType: isWebinar ? 'WEBINAR' : signUpType,
        eventId: isWebinar ? webinarData.eventId : null,
        eventCode: isWebinar ? webinarData.id : null,
    };
};

const formatSignUpError = (error) => {
    if (error.code === 'global.errors.unknownError') {
        return {
            ...error, code: 'signUp.error.failed',
        };
    }

    return error;
};

/**
 * Handle save page toast display.
 * @param {*} action
 * @param {*} toastType
 * @param {*} analyticsData
 */
function* handleShowToast(action, toastType, analyticsData) {
    const {
        id, isUndo, title, type,
    } = action;

    if (!isUndo) {
        yield put(addToasts([{
            toastProps: {
                actionType: type,
                id,
                title,
                analyticsData,
                article: action.article,
            },
            toastType,
        }]));
    }
}

/**
 * Attempt user signup.
 */
function* signUp(values, actions, identityData, isWebinar, webinarData) {
    try {
        /* clear last onboarding steps if attempting another signup */
        storage.remove('onboarding.step');
        storage.remove(EMAIL_CHANGED);
        
        yield put(setSuccess(false));
        yield put(setSubmitting(true));
        yield put(resetErrors());

        const formattedData = formatSignUpData(values, identityData, isWebinar, webinarData);
        const signupResponse = yield call(Api.post, SIGN_UP_URL, {
            data: formattedData,
        });

        if (signupResponse && signupResponse.data && signupResponse.data.requiredEmailConfirmation) {
            storage.set(SIGNUP_EMAIL, formattedData.email);
            storage.set(SIGNUP_FNAME, formattedData.firstName);

            yield put(setSubmitting(false));
            yield put(setScrollToTop());
            yield put(push('/confirm-email'));

            return;
        }

        if (!formattedData.crd) {
            yield call(authenticate, values);
            yield put(signInSuccess());
        }
        
        if (webinarData && webinarData.eventId && webinarData.decision) {          
            yield put(notInterested(webinarData.eventId, webinarData.decision));
        }    

        yield put(setSuccess(true));

        analytics.dispatchSignUpSuccess();

        if (!formattedData.crd) {
            storage.set(MOBILE_APP_NOTIFICATION, JSON.stringify({
                loginCount: 0,
                dismissed: false,
                showAlternate: true,
                hideLumen: false,
                user: values.email
            }));

            if (isWebinar) {
                const webinarStatus = yield call(Api.get, WEBINAR_DATA_URL);

                if (webinarStatus.data.advisorId) {
                    yield put(getWebinarInfoSuccess(webinarStatus.data));
                    yield put(getWebinarIsUserRegisteredYes());
                    yield put(push(`/learn/webinar/${webinarStatus.data.content.code}`));
                } else {
                    yield put(getWebinarIsUserRegisteredNo());
                    yield put(push('/learn/webinar/error'));
                }
            } else {
                yield put(push('/onboarding'));
            }
        } else {
            storage.set(SIGNUP_EMAIL, formattedData.email);
            storage.set(SIGNUP_FNAME, formattedData.firstName);

            yield put(setScrollToTop());

            yield put(push('/confirm-email'));
        }
    } catch (error) {
        yield put(setSuccess(false));
        
        if (error.data) {
            yield put(setErrors(error.data.map(formatSignUpError)));
        }

        yield put(setSubmitting(false));
        yield put(setFocusOnError(true));
    }
}

/**
 * Request resend of email verification.
 * @param {*} action
 */
function* requestResend(action) {
    try {
        yield call(Api.post, RESEND_EMAIL_URL, {
            data: {
                email: action.email,
            },
        });
        yield put(resendEmailComplete());
        yield call(handleShowToast, action, TYPES.RESEND_EMAIL, null);
    } catch (e) {
        yield put(resendEmailFail());
    }
}

/**
 * Request change for user email.
 * @param {*} action
 */
function* requestEmailChange(action) {
    try {
        const updateResponse = yield call(Api.post, CHANGE_EMAIL_GET_URL, {
            data: {
                emails: btoa(JSON.stringify({
                    currentEmail: action.oldEmail,
                    newEmail: action.email,
                    password: action.password,
                })),
            },
        });

        const successTime = new Date();

        //update stored email on success.
        storage.set(SIGNUP_EMAIL, action.email); 

        if (updateResponse.data.emailUpdated === false) {
            storage.remove(EMAIL_CHANGED);
        }

        yield put(changeEmailSuccess(successTime.getTime()));

        yield call(handleShowToast, action, TYPES.RESEND_EMAIL, null);
    } catch (e) {
        yield put(changeEmailFail());
    }
}

/**
 * Request validation of email by token.
 * @param {*} action
 */
function* confirmEmailByToken(action) {
    try {
        yield call(Api.get, action.email ? CONFIRM_MIGRATED_EMAIL_URL(action.token, action.email) : CONFIRM_EMAIL_URL(action.token));
        yield put(confirmEmailSuccess());
    } catch (e) {
        yield put(confirmEmailFail());
    }
}

function* signUpFlow() {
    while (true) {
        const { actions, values, identityData, isWebinar, webinarData } = yield take(SIGN_UP);
        yield fork(signUp, values, actions, identityData, isWebinar, webinarData);
    }
}

export default function* signUpSaga() {
    yield all([
        takeLatest(RESEND_EMAIL, requestResend),
        takeLatest(CHANGE_EMAIL, requestEmailChange),
        takeLatest(CONFIRM_EMAIL, confirmEmailByToken),
        fork(signUpFlow),
    ]);
}