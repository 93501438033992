import { css } from 'styled-components';
import { getSpanInPercent } from 'utils/grid';
import { FROM_DESKTOP, FROM_TABLET, FROM_MOBILE_TO_TABLET } from 'utils/mediaQueries';
import {
    COLORS,
    RGB_COLORS,
} from 'utils/variables';

export const fieldContainerStyles = css`
    align-self: stretch;
    box-sizing: border-box;
    display: block;
    padding: 0 0 0;
    margin-top: 12px;

    label {
        font-size: 10px;
    }

    div[role="combobox"] {
        min-width: 250px !important;
        width: 100%;
        height: 70px;
        margin-top: 37px;
        line-height: 31px;

        svg{
            bottom: 34%;

            @media screen and ${FROM_TABLET} {
                bottom: 36%;
            }
        }
    }

    @media screen and ${FROM_TABLET} {
        display: flex;
        flex-direction: column;
        min-width:225px;
        margin-right: 35px;
        padding: 0px;

        div[role="combobox"] {
            width: 225px;
            min-width: 186px !important;
            margin-top: 0;
            line-height: 42px;
                
            @media screen and ${FROM_TABLET} {
                line-height: 38px;
            }
        }
    }

    div>div{
        top: -10px;

        @media screen and ${FROM_TABLET} {
            top: 0px;
        }
    }
   
    @media screen and ${FROM_DESKTOP} {
        width: 25%;
    }

    /* stylelint-disable */

    ${props => props.halfFieldContainer && css`
        margin-left: 0;
        width: 100%;

        @media screen and ${FROM_TABLET} {
            width: 50%;
            margin-left: 10px;
        }

        @media screen and ${FROM_TABLET} {
            margin-left: 28px;
        }

        &:first-child {
            margin-left: 0;
        }
    `};

    ${props => props.passwordField && css`
        @media screen and ${FROM_TABLET} {
            margin: 0 0 46px;
            width: 53%;
        }
    `}

    ${props => props.email && css`
        @media screen and ${FROM_TABLET} {
            width: 48%;
        }

        @media screen and ${FROM_DESKTOP} {
            width: 53%;
        }
    `}

    /* stylelint-enable */

    .FieldText__StyledInput-bDQjQU {
        font-size: 15px;
        border-bottom-width: 1px;
        height: 38px;
        margin-bottom: 16px;
        margin-top: 17px;

        &:focus {
            border-width: 0 0 2px;
            padding-bottom: 4px;
        }

        &[name='email'] {
            border-color: ${COLORS.MEDIUM_GRAY2};
            margin-bottom: 38px;
            border-bottom-width: 0 !important;

            @media screen and ${FROM_TABLET} {
                margin-bottom: 15px;
            }

            @media screen and ${FROM_DESKTOP} {
                margin-bottom: 17px;
            }
        }

        &[name='crd'] {
            border-color: ${COLORS.MEDIUM_GRAY2};
            margin-bottom: 40px;
            border-bottom-width: 0 !important;

            @media screen and ${FROM_TABLET} {
                margin-bottom: 15px;
            }

            @media screen and ${FROM_DESKTOP} {
                margin-bottom: 17px;
            }
        }

        &[name='iard'] {
            border-color: ${COLORS.MEDIUM_GRAY2};
            margin-bottom: 40px;
            border-bottom-width: 0 !important;
            margin-bottom: 13px;

            @media screen and ${FROM_TABLET} {
                margin-bottom: 15px;
            }

            @media screen and ${FROM_DESKTOP} {
                margin-bottom: 17px;
            }
        }

        margin-bottom: 16px;

        @media screen and ${FROM_TABLET} {
            width: 100%;
            margin-top: 17px;
        }

        @media screen and ${FROM_DESKTOP} {               
            margin-bottom: 10px;               
        }

        ::placeholder{
            color: ${COLORS.BLACK};
        }
    }

    .FieldText__StyledWrapper-cJBknI {
        max-width: 275px;
    }

    .FieldText__StyledLabel-MxipH {
        font-weight: 500;

        &[for="email"], &[for="crd"] {
            color: ${COLORS.MEDIUM_GRAY2}!important;
        }
    }
    ul[role='listbox'] {
        @media screen and ${FROM_TABLET} {
            max-width: 225px;
            margin-top: 24px;
        }

        li:first-child {
            height: 51px;

            @media screen and ${FROM_TABLET} {
                height: 47px;
            }
        }

        & > li:first-child {              
            min-height: 47px;
            padding-top: 13px;

            @media screen and ${FROM_TABLET} {
                min-height: 46px;
                padding-top: 15px;
            }

            svg {
                top: 42%;

                @media screen and ${FROM_TABLET} {
                    top: 50%;
                }
            }
        }
    }

    ${props => props.containerRow === 'household' && css`
        margin-top: -4px;
        @media screen and ${FROM_TABLET} {
            margin-top: 12px;
        }
    `}

    ${props => props.containerRow === 'retirementId' && css`
   
        @media screen and ${FROM_TABLET} {
            margin-top: -2px;
        }
    `}

    ${props => props.containerRow === 'retirementAum' && css`
        margin-top: -0.5px;
    `}

    ${props => props.containerRow === 'firstName' && css`
        margin-top: 5px;
        
        @media screen and ${FROM_TABLET} {
            min-width: 310px;
        }
       
    `}

    ${props => props.containerRow === 'lastName' && css`
        margin-top: 34px;
        @media screen and ${FROM_TABLET} {
            margin-top: 5px;
            min-width: 310px;

        }

    `}

    ${props => props.containerRow === 'email' && css`
        margin-top: 38px;
        padding-bottom: 30px;
        @media screen and ${FROM_TABLET} {
            margin-top: 9px;
        }
    `} 
`;

export const fieldEmailContainerStyles = css`
    width: 100%;

    .EditProfileForm__StyledFieldContainer-kcbtOJ, 
    .FieldText__StyledWrapper-cJBknI {
        @media screen and ${FROM_TABLET} {
            width: 100% !important;
            max-width: 648px;
        }
    }  
`;

export const formStyles = css`
    margin-top: 3.5px;

    &.editAccount {
        .EditProfileForm__StyledInfoRow-foUITm {
            border-bottom: solid 0px rgba(${RGB_COLORS.LIGHT_GRAY2},0.61);
        }
    }

    &.practiceInfocontainer {
        @media screen and ${FROM_MOBILE_TO_TABLET} {
            .EditProfileForm__StyledHeadingContainer-ikBVdQ {
                margin: 50px 0 0 0;

                &:first-child {{
                    margin: 0;
                }
            }
        }
    }
`;

export const mobileLabelStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-size: 10px;
    font-weight: 600;
    margin-bottom: 10px;
    text-transform: uppercase;
    display: none;

    @media screen and ${FROM_TABLET} {
        display: none;
    }
`;

export const rowStyles = css`
    align-items: center;
    align-self: stretch;
    margin: 0;
    min-height: 70px;
    padding: 0;
    display: flex;
    width: 100%;
    border-bottom: solid 0px ${COLORS.LIGHT_GRAY2};
    flex-wrap: wrap;

    @media screen and ${FROM_TABLET} {
        padding: 0 0 26px;
        display: flex;
        align-items: baseline;
        width: 100%;
        margin: 0;
    }

    /* stylelint-disable */

    ${props => props.dropdownRow && css`
        padding: 17px 0;
    `}

    /* stylelint-enable */

    &:last-child {
        border: 0;
    }

    
    ${props => props.content === 'welathContainer' && css`
        padding: 17px 0 4px;
    `}

    /* stylelint-enable */

    &:last-child {
        border: 0;
    }
`;

export const passwordRowWrapperStyles = css`

    width: 100%;

    @media screen and ${FROM_TABLET} {
        flex-direction: row;
        justify-content: space-between;
    }

    @media screen and ${FROM_DESKTOP} {
        width: 100%;
    }
`;

export const passwordRowStyles = css`
    flex-direction: row;
    justify-content: center;
    width: 100%;

    @media screen and ${FROM_TABLET} {
        display: flex;
        flex-direction: column;
        width: 50%;
        float: left;
    }

    @media screen and ${FROM_DESKTOP} {
        width: 50%;
        float: left;
    }
`;

export const passwordRulesWrapperStyles = css`
    display: flex;
    flex-direction: column;
    margin: 6px 0 -12px;

    @media screen and ${FROM_TABLET} {
        margin: 0;
        width: 50%;
        float: left;
    }

    @media screen and ${FROM_DESKTOP} {
        width: 50%;
        float: left;
    }
`;

export const keyContainerStyles = css`
    display: none;
    vertical-align: top;


    @media screen and ${FROM_TABLET} {
        box-sizing: border-box;
        color: ${COLORS.DARK_GRAY2};
        display: none;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: -0.3px;
        line-height: 1.5;
        width: ${getSpanInPercent(3, true, 'tablet')};
        word-wrap: break-word;
        text-transform: uppercase;
    }

    @media screen and ${FROM_DESKTOP} {
        width: 111px;
        min-width: 75px;
        text-transform: uppercase;
    }
`;

export const valueContainerStyles = css`
    box-sizing: border-box;
    color: ${COLORS.MEDIUM_GRAY2};
    display: inline-block;
    font-size: 15px;
    letter-spacing: -0.3px;
    line-height: 1.5;
    width: 100%;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        font-size: 15px;
        display: flex;
        padding-left: 0;
        width: 100%;
        flex-wrap: wrap;
    }

    @media screen and ${FROM_DESKTOP} {
        flex: auto;
        padding-left:0;
        width: 50%;
    }

    .FieldText__StyledLabel-MxipH {
        letter-spacing: 0.5px;
        top: 5px;

        &[for="curPassword"], &[for="password"], &[for="verifyPassword"] {
            display: none;
        }

    }
`;

export const servicesOfferedRowStyles = css`
    margin-bottom: 10px;
    width: 100%;
`;

export const servicesOfferedListWrapperStyles = css`
    @media screen and ${FROM_TABLET} {
        margin-left: -18px;
    }
`;

export const servicesOfferedListStyles = css`
    box-sizing: border-box;
    display: block;
    list-style-type: none;
    margin: -10px 0 0;
    padding: 0 15px;
    width: 100%;

    @media screen and ${FROM_TABLET} {
        align-items: baseline;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0;
    }
`;

export const servicesOfferedListItemStyles = css`
    & > label {
        width: 100%;

        @media screen and ${FROM_TABLET} {
            display: flex;
            flex: 1;
        }
    }

    @media screen and ${FROM_TABLET} {
        align-self: stretch;
        box-sizing: border-box;
        display: flex;
        padding-left: 18px;
        width: 50%;
    }

    @media screen and ${FROM_DESKTOP} {
        flex: auto;
        width: ${getSpanInPercent(4)};
    }
`;

export const styledHeadingContainerStyles = css`
    padding: 0 0 0;

    @media screen and ${FROM_TABLET} {
        padding: 37px 0 0;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 40px 0 0;
    }

    ${props => props.heading === 'headingRetirement' && css`
         border-top: solid 1px ${COLORS.LIGHT_GRAY2};       
        padding-top: 50px;
        
    `}

    ${props => props.heading === 'headingRetirementEdit' && css`
        border-top: solid 1px ${COLORS.LIGHT_GRAY2};       
        padding-top: 50px;
        @media screen and ${FROM_TABLET} {
            margin-top: 5px;
            padding: 34px 0 30px;
        }

    `}


    ${props => props.heading === 'wealthMangt' && css`
        @media screen and ${FROM_TABLET} {
            padding-top: 36px;
            padding-bottom: 17px;
        }

        @media screen and ${FROM_DESKTOP} {
            padding-top: 43px;
        }
    `}


    ${props => props.heading === 'aboutYou' && css`
        border-top: solid 1px ${COLORS.LIGHT_GRAY2};        
        padding-top: 50px;
        
    `}

    ${props => props.heading === 'aboutYouEdit' && css`
        border-top: solid 1px ${COLORS.LIGHT_GRAY2};        
        padding-top: 50px;
        @media screen and ${FROM_TABLET} {
            margin-top: 5px;
            padding: 34px 0 17px;
        }
        
    `}
`;

export const styledHeadingStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.5px;
`;

export const styledPasswordRow = css`
    width: 100%;
    border-top: solid 1px rgba(${RGB_COLORS.LIGHT_GRAY2},0.61);
    margin-top: 9px;

    @media screen and ${FROM_TABLET} {
        margin: 15px 0 0;
    }

    @media screen and ${FROM_DESKTOP} {
        margin: -20px 0 0;
    }

    .EditProfileForm__StyledInfoRow-foUITm {
        width: 100%;
    }
 
    ${props => props.mainContainerRow === 'pass' && css`
        margin-top: 5px;
    `}
`;


export const styledPasswordSubHeader = css`
    color: ${COLORS.DARK_GRAY2};
    font-size: 12px;
    font-weight: 700;
    line-height: 1.78;
    word-wrap: break-word;
    text-transform: uppercase;
    margin: 50px 0 33px;

    @media screen and ${FROM_TABLET} {
        margin: 47px 0 32px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin: 53px 0 33px;
    }
`;

export const styledLabelTitle = css`
    font-size: 10px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    color: ${COLORS.MEDIUM_GRAY2};
    margin-top: 28px;
    line-height: 2.58;

    @media screen and ${FROM_TABLET} {
        margin-top: 12px;
    }
`;

export const styledValueTxt = css`
    min-width: 186px !important;
    margin-top: 0;
    height: 50px;
    box-sizing: border-box;
    font-size: 15px;
    line-height: 1;
    min-height: 36px;
    padding-top: 6px;
    padding-bottom: 15px;
    padding-left: 0;
    position: relative;
    user-select: none;
    width: 100%;
    color: ${COLORS.BLACK};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and ${FROM_TABLET} {
        padding-top: 10px;
        height: 56px;
    }

    @media screen and ${FROM_DESKTOP} {
        height: 53px;
    }
`;
