/**
 * Constants object to map to error handling, used in http-client by isErrorFatal function.
 */
export const errorMap = {
    /**
     * List of APIS (by page) we can allow to fail (usually expect a component would be hidden as a result of failure).
     */
    allowFailuresByPage: [
        {
            //VERIFY EMAIL page
            pathname: '/email-confirmation',
            apis: [
                '/advisor-service/advisors/verify-email', //verify token fail/success endpoint
                '/advisor-service/identities/verify-email/', //verify token fail/success endpoint for migrated users
            ],

        },
        {
            //CONFIRM EMAIL page
            pathname: '/confirm-email',
            apis: [
                '/advisor-service/advisors/v2/update-confirmation-email', //change email v2
                '/advisor-service/advisor/update-confirmation-email', //change email endpoint
            ],
        },
        {
            //NEWS page
            pathname: '/news',
            apis: [
                '/content-service/market-indices', //market indices request for info bar data
                '/content-service/news/trending-topics-articles', //Trending Topics component
                '/content-service/news/capital-ideas', //Capital Ideas component
                '/content-service/news?n=16', //Top News component
                '/content-service/news/similar-profiles', //Similar Profile news component
                '/content-service/market-briefing', //market briefing request for info bar data
            ],
        },
        {
            //LEARN (Practice Management main..) page
            pathname: '/learn',
            apis: [
                '/content-service/pm/practice-lab?', //Practice Lab
                '/advisor-service/webinars/banners/', //Webinar banner request
                '/content-service/guide-collections?', //Collections recent list
                '/advisor-service/webinars/v2', //Webinar data request used by card
                '/content-service/ce-credits?', //CE Credits component
                '/content-service/guide-tools/recent', //Tools component.
                '/content-service/guides/v2/recent', //Collections component.
                '/content-service/guides/recent', //Collections component.
                '/content-service/guide-collections', //Collections recent list
            ],
        },
        {
            //IDEAS page
            pathname: '/learn/ideas',
            apis: [
                '/advisor-service/webinars/banners/', //Webinar banner request
                '/advisor-service/webinars/v2', //Webinar data request used by card
            ],
        },
        {
            //SAVES page
            pathname: '/profile/saves',
            apis: [
                '/advisor-service/webinars/v2', //Webinar data request used by card
            ],
        },
        {
            //ACCOUNT page
            pathname: '/profile/account',
            apis: [
                '/advisor-service/advisors/me/credentials', //Change password attempt with incorrect current password
            ],
        },
    ],

    /**
     * List of APIs (by base path match) to always allow failures to proceed without concern
     */
    alwaysAllowFailureFor: [
        '/personalization-service/articles/', //save article
        '/personalization-service/guides/', //save guide
        '/advisor-service/coach-mark', //coach mark data request
        '/personalization-service/article/', //article click/read state recording
        '/advisor-service/advisors/userAppChoice', //user app download choice toggle
        '/advisor-service/advisors/nativeAppStatus', //iOS app install status data request
        '/advisor-service/advisors/iosDownload/status', //iOS app install download choice toggle
        '/advisor-service/topics/', //topic follow/unfollow
        '/content-service/guides/poll', //guide poll data request
        '/content-service/homepage/poll', //homepage poll data request
        '/content-service/homepage/poll/result', //homepage poll results data request
        '/advisor-service/opt-in', //optin status for DJ briefing subscription status
        '/personalization-service/advisor/refinement_card', //refinement card request (has suggestion true/false)
        '/personalization-service/advisor/refinement_card_details', //refinement card data request (details)
        '/content-service/cards/', //save webinar from card
        '/advisor-service/advisors/email-confirmation/resend', //email confirmation resend
        '/advisor-service/sources/', //source follow/unfollow
        '/personalization-service/toolClick/click', //tool click/read state recording
        '/advisor-service/webinars/banners/', //webinar banner data request AND descission recording
        '/content-service/cards/CECREDIT/', //CE Credit save
        '/security-service/login', //LOGIN with bad credentials
        '/advisor-service/advisors/sign-up', //SIGNUP not whitelisted
        '/advisor-service/advisors/v2/password/token/verify', //VERIFY EMAIL TOKEN
    ],

    noToastOnFailure: [
        '/security-service/login', //LOGIN with bad credentials
        '/advisor-service/advisors/sign-up', //SIGNUP not whitelisted
    ],

    /**
     * List of additional APIs we allow to fail (only in dev)
     */
    alwaysAllowFailureForDev: [
        '/content-service/content/legal-agreement', //legal
        '/content-service/homepage/poll', //home page aspect
        '/content-service/homepage/poll/result', //home page aspect
        '/content-service/homepage', //home page aspect
    ],

    /**
     * Constant to be appended to any API url before we judge its match
     */
    apiPrefix: '/api',
};