export const ROUTES = {
    MIGRATE: '/migrate',
    CHOOSE_SOURCES: '/choose-sources',
    WEBINAR_LANDING: '/learn/webinar/:eventName',
    WEBINAR_ERROR: '/learn/webinar/error',
    CONFIRM_EMAIL: '/confirm-email',
    VERIFY_EMAIL: '/email-confirmation/:token/:email?',
    ABOUT_LUMIN: '/about',
    ARTICLE: '/articles/:id/:interactionId/:contentType/:interactionType',
    BASE: '/',
    CONTACT_US: '/contact-us',
    DOWNLOAD: '/download',
    FORGOT_PASSWORD: '/forgot-password/:token',
    GUIDE: '/learn/ideas/:id',
    GUIDES: '/learn',
    HOME: '/home',
    NEWS: '/news',
    TOOLS: '/learn/tools',
    PRACTICE_MANAGEMENT: '/learn',
    CECREDITS: '/learn/ce-credits',
    PRACTICELAB: '/learn/practice-lab',
    BLOOMBERG_FULL_SCREEN: '/bloomberg',
    MARKET_BRIEFING: '/marketBriefing/:id',
    MARKET_BRIEFING_ARCHIVE: '/marketBriefings',
    NOT_FOUND: '/not-found',
    ONBOARDING: '/onboarding',
    ONBOARDING_PICK_SOURCES: '/onboarding/pick-sources',
    PAGEABLE_NEWS: '/news/:section',
    PRIVACY_POLICY: '/privacy-policy',
    PROFILE: '/profile',
    PROFILE_SECTION: '/profile/:section',
    PROFILE_SECTION_SUBSECTION: '/profile/:section/:section+',
    SAVES: '/profile/saves',
    WATCHLIST: '/saves',
    SEARCH: '/search',
    SERVER_ERROR: '/server-error',
    SIGN_IN_WITH_SECTION: '/sign-in/:webinarID/:eventID',
    SIGN_IN: '/sign-in',
    SIGN_OUT: '/sign-out',
    SOURCE_LANDING_PAGE: '/sources/:section+',
    TERMS_AND_CONDITIONS: '/terms-and-conditions',
    TOPIC_LANDING_PAGE: '/topics/:section+/:id',
    TRENDING_TOPICS_PAGE: '/trending',
    ALL_TOPICS_PAGE: '/allTopics',
    GUIDES_AND_FEATURED_CONTENT_PAGE: '/learn/ideas',
    COLLECTIONS: '/learn/collections',
    COLLECTION: '/learn/collections/client-life-events/:code',
    COLLECTION_PM: '/learn/collections/practice-milestones/:code',
    WEBINAR: '/learn/webinar'
};
