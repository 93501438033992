import {
    GET_ALLTOPICS,
    GET_ALLTOPICS_SUCCESS,
    GET_ALLTOPICS_FAIL,
    GET_TOP_ALLTOPICS,
    GET_TOP_ALLTOPICS_SUCCESS,
    GET_TOP_ALLTOPICS_FAIL,
    GET_TRENDING_ALLTOPICS,
    GET_TRENDING_ALLTOPICS_SUCCESS,
    GET_TRENDING_ALLTOPICS_FAIL,
    TOGGLE_SAVE_TRENDING_ALLTOPICS,
    SEEMORE_TRENDING,
    SEEMORE_TRENDING_FAIL,
    SEEMORE_TRENDING_SUCCESS,
} from 'state-management/constants/allTopics.common';

export function getAllTopics() {
    return {
        type: GET_ALLTOPICS,
    };
}

export function getAllTopicsSuccess(response) {
    return {
        type: GET_ALLTOPICS_SUCCESS,
        data: response.data,
    };
}

export function getAllTopicsFailure(error) {
    return {
        type: GET_ALLTOPICS_FAIL,
        error,
    };
}

export function getTopAllTopics(omitIds) {
    return {
        type: GET_TOP_ALLTOPICS,
        omitIds,
    };
}

export function getSeeMoreTrending(topicIds, articleIds, trendingType, trendingVariant) {
    return {
        type: SEEMORE_TRENDING,
        topicIds,
        articleIds,
        trendingType,
        trendingVariant,
    };
}

export function getSeeMoreTrendingFailure(error) {
    return {
        type: SEEMORE_TRENDING_FAIL,
        error,
    };
}

export function getSeeMoreTrendingSuccess(data) {
    return {
        type: SEEMORE_TRENDING_SUCCESS,
        data,
    };
}

export function getTopAllTopicsSuccess(data) {
    return {
        type: GET_TOP_ALLTOPICS_SUCCESS,
        data,
    };
}

export function getTopAllTopicsFailure(error) {
    return {
        type: GET_TOP_ALLTOPICS_FAIL,
        error,
    };
}

export function getTrendingAllTopics(typeId, filterId) {
    return {
        type: GET_TRENDING_ALLTOPICS,
        typeId,
        filterId,
    };
}

export function getTrendingAllTopicsSuccess(data) {
    return {
        type: GET_TRENDING_ALLTOPICS_SUCCESS,
        data,
    };
}

export function getTrendingAllTopicsFailure(error) {
    return {
        type: GET_TRENDING_ALLTOPICS_FAIL,
        error,
    };
}

export function toggleSaveTrendingAllTopics(articleId, isSaved, topicId) {
    return {
        type: TOGGLE_SAVE_TRENDING_ALLTOPICS,
        articleId,
        isSaved,
        topicId,
    };
}
