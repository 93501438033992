import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { camelCase } from 'lodash';
import { Container, CoverImage, SaveControl } from 'components';
import Heading from 'components/Heading/Heading';
import TimeToRead from 'components/TimeToRead/TimeToRead';
import { isGuideFeatured } from 'utils/contentCardUtils';
import language from '../../lang/en.json';
import { STANDARD, HBR, MC_KINSEY, INPRACTICE } from 'containers/Guide/guideTypes';

import {
    categoryStyles,
    contentContainerStyles,
    controlsContainerStyles,
    descriptionStyles,
    imageWrapperStyles,
    imageContainerStyles,
    imageCaptionStyles,
    timeToReadStyles,
    titleStyles,
    wrapperStyles,
    imageOverlayStyles,
} from 'components/Hero/styles';
import { wrapBackButtonLabels } from "../../utils/urlUtils";
import BackToLink from "../BackToLink/BackToLink";
import { BackButtonContainer } from "./styles";

export const StyledContainer = styled(Container)`
    ${wrapperStyles};

    @media screen and (min-width: 1024px) {
        box-sizing: content-box !important;
    }
`;
export const StyledCategory = styled.p`
    ${categoryStyles};
`;
export const StyledTitle = styled(Heading)`
    ${titleStyles};
`;
export const StyledDescription = styled.p`
    ${descriptionStyles};
`;
export const StyledImageWrapper = styled.figure`
    ${imageWrapperStyles};

    @media screen and (min-width: 1024px) {
        width: 52% !important;
        right: 0 !important;
    }
`;
export const StyledImageContainer = styled.div`
    ${imageContainerStyles};
`;
export const StyledImageCaption = styled.figcaption`
    ${imageCaptionStyles};
`;
export const StyledContentContainer = styled.div`
    ${contentContainerStyles};

     @media screen and (min-width: 1024px) {
        max-width: 564px !important;
    }
`;
export const StyledControlsContainer = styled.div`
    ${controlsContainerStyles};
`;
export const StyledTimeToRead = styled(TimeToRead)`
    ${timeToReadStyles};
`;
export const ImageOverlay = styled.div`
    ${imageOverlayStyles}
`;


const Hero = ({
    id,
    imageAsset,
    isSaving,
    category,
    copyright,
    articleHeadline,
    guideType,
    articleDescription,
    readTime,
    onToggleSaveGuide,
    saved,
    urlHistoryList
}) => {

    const backLabel = urlHistoryList != null && urlHistoryList.length
        ? urlHistoryList.length - 2 >= 0
            ? urlHistoryList[urlHistoryList.length - 2]
            : null
        : null;
    return (
        Hero.areAttributesValid({ category, articleHeadline }) && (
            <StyledContainer
                className={`cover-${id}`}
                id={id}
                size={Container.LARGE}
            >
                <StyledImageWrapper>
                    <StyledImageContainer type={guideType} isGuideFeatured={isGuideFeatured(guideType)}>
                        <CoverImage
                            isPublic={false}
                            src={`/api${imageAsset}`}
                            alt={language.translations['global.alt.image']}
                        />
                    </StyledImageContainer>
                    {guideType === INPRACTICE
                        && <ImageOverlay />}
                    {copyright && isGuideFeatured(guideType) && (
                        <StyledImageCaption>{copyright}</StyledImageCaption>
                    )}
                </StyledImageWrapper>
                <StyledContentContainer>
                    <BackButtonContainer>
                        <BackToLink
                            to={backLabel !== null ? backLabel.currentUrl : '#'}
                            customLabel={wrapBackButtonLabels(backLabel)}
                            isDisable={backLabel === null}
                        />
                    </BackButtonContainer>

                    <StyledCategory>
                        <FormattedMessage id={`guidePage.hero.${camelCase(guideType)}.category`} values={{ category }} />
                    </StyledCategory>
                    <StyledTitle
                        level={1}
                    >
                        {articleHeadline}
                    </StyledTitle>
                    {
                        articleDescription && (
                            <StyledDescription isGuideFeatured={isGuideFeatured(guideType)}>
                                {articleDescription}
                            </StyledDescription>
                        )}
                    <StyledControlsContainer>
                        <SaveControl
                            appearance="extended"
                            onToggleSave={onToggleSaveGuide}
                            saved={saved}
                            isLoading={isSaving}
                        />
                        <StyledTimeToRead minutes={readTime} />
                    </StyledControlsContainer>
                </StyledContentContainer>
            </StyledContainer>
        ))
};

Hero.areAttributesValid = ({ category, articleHeadline }) => category && articleHeadline;

Hero.propTypes = {
    id: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    copyright: PropTypes.string,
    articleDescription: PropTypes.string,
    guideType: PropTypes.oneOf([STANDARD, HBR, MC_KINSEY, INPRACTICE]),
    imageAsset: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
    articleHeadline: PropTypes.string.isRequired,
    readTime: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    onToggleSaveGuide: PropTypes.func.isRequired,
    saved: PropTypes.bool.isRequired,
};

Hero.defaultProps = {
    articleDescription: '',
    copyright: '',
    readTime: 0,
};

export default Hero;