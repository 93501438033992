import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import WebinarPreferencesModal from 'containers/WebinarLanding/WebinarPreferencesModal';
import ConnectedProfileConfirmationSourcePopup from 'containers/Profile/ProfileConfirmationSourcePopup';
import ConnectedProfileConfirmationPopup from 'containers/Profile/ProfileConfirmationPopup';
import {
    clearErrors as clearErrorsAction,
} from 'state-management/actions/errors';
import {
    hidePopup as hidePopupAction, showPopup as showPopupAction,
} from 'state-management/actions/globalPopupContainer';
import SourceModal from 'components/atomics/molecules/NewsSource/SourceModal';

export class GlobalPopupContainer extends React.Component {
    constructor(props) {
        super(props);

        this.props.showPopup();
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.isOpen && !this.props.isOpen) {
            this.props.showPopup();
        }

        if (!prevProps.isOpen && this.props.isOpen) {
            this.props.clearErrors();
        }

        if (prevProps.isOpen && !this.props.isOpen) {
            this.props.redirectCallback(this.props.redirect);
        }
    }

    getPopup = () => {
        switch (this.props.type) {
            case 'webinarPage.confirmationModal':
                if (window.location.pathname === '/sign-out') {
                    return <div />;
                }

                return (
                    <WebinarPreferencesModal
                        isOpen={this.props.isOpen}
                        onRequestClose={this.props.onRequestClose}
                    />
                );
            case 'source.modal': return (
                <SourceModal
                    isOpen={this.props.isOpen}
                    onRequestClose={this.props.onRequestClose}
                />
            );
            case 'profilePage.confirmationModal': return (
                <ConnectedProfileConfirmationPopup
                    isOpen={this.props.isOpen}
                    onRequestClose={this.props.onRequestClose}
                />
            );
            case 'profilePage.confirmationSourceModal': return (
                <ConnectedProfileConfirmationSourcePopup
                    isOpen={this.props.isOpen}
                    onRequestClose={this.props.onRequestClose}
                />
            );
            default: return <div />;
        }
    };

    render() {
        return (
            this.getPopup()
        );
    }
}

GlobalPopupContainer.propTypes = {
    type: PropTypes.string.isRequired,
    redirectCallback: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    showPopup: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    redirect: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    isOpen: state.globalPopupContainer.isOpen,
    redirect: state.globalPopupContainer.redirect,
});

const mapDispatchToProps = dispatch => ({
    onRequestClose: redirect => dispatch(hidePopupAction(redirect)),
    showPopup: () => dispatch(showPopupAction()),
    clearErrors: () => dispatch(clearErrorsAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalPopupContainer);
