import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'components/atomics/atoms/Link/Link';
import moment from 'moment';
import { TOPIC_LINK } from 'state-management/constants/topics';
import ImageAtom from 'components/atomics/atoms/Image/Image';
import {
    containerStyles,
    contentContainerStyles,
    dateAndTopicContainerStyles,
    dateContainerStyles,
    topicLinkStyles,
    titleStyles,
    imageContainerStyles,
    overlayStyles,
} from 'components/atomics/molecules/CapitalIdeasCard/styles';
import { contextHubRecordEvent } from 'utils/contextHub';
import {
    CARDS_EVENT_LIST,
} from 'utils/contextHubEventListConstants';
import { getTruncatedString } from 'utils/contentCardUtils';
import ListContentCardFooter from "../ListContentCard/ListContentCardFooter";
import {
    toggleSaveArticle,
    toggleSaveGuide as toggleSaveGuideAction, toggleSavePlArticle,
} from "../../../../state-management/actions/save";
import { toggleTopicFollow } from "../../../../state-management/actions/followTopic";
import { toggleSourceFollow } from "../../../../state-management/actions/sources";
import { dismissCoachMark } from "../../../../state-management/actions/coachMark";
import { connect } from "react-redux";
import { getVisitPracticeLab } from '../../../../state-management/actions/articles.common';
import language from '../../../../lang/en.json';


export const PROVIDER_CAPITAL_IDEAS = 'CAPITAL_IDEAS';

export const Overlay = styled(Link)`
    ${overlayStyles}
`;

export const DateContainer = styled.div`
    ${dateContainerStyles}
`;

export const ImageContainer = styled(Link)`
    ${imageContainerStyles}
`;

export const Title = styled(Link)`
    ${titleStyles}
`;

export const DateAndTopic = styled.div`
    ${dateAndTopicContainerStyles}
`;

export const TopicLink = styled(Link)`
    ${topicLinkStyles}
`;

export const Container = styled.div`
    ${containerStyles}
`;

export const ContentContainer = styled.div`
    ${contentContainerStyles}
`;

const MAX_ARTICLE_TITLE_CHARS = 75;

/**
 * Creates a capital ideas content card.
 */
class CapitalIdeasCard extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.state = {
            isKebabOpen: false,
            onCardHover: false

        };

        this.onClick = this.onClick.bind(this);
        this.handleKebabOpenOutside = this.handleKebabOpenOutside.bind(this);
        this.CH_handleComponentEventList = this.CH_handleComponentEventList.bind(this);
    }

    /**
      * Handle clicks outside the kebab.
      * @param {W} bool
      */
    handleKebabOpenOutside(bool) {
        this.setState({
            isKebabOpen: bool,
        });
    }

    /**
     * Pass up the fact this guide was clicked, so parent container can track that data.
     */
    onClick() {
        this.props.handleGuideClick(this.props.article);
        if (this.props.article.sourceTitle === 'Practice Lab') {
            this.props.getVisitPracticeLab(this.props.article);
        }
    }

    /**
     * Handle analytics click for capital card.
     * @param {*} arg
     */
    CH_handleComponentEventList() {
        contextHubRecordEvent(CARDS_EVENT_LIST(
            'article',
            this.props.article.title,
            this.props.article.topicName,
            this.props.article.sourceName
        ));
    }

    /**
     * Handle hover state.
     */
    onMouseEnter = () => {
        this.setState({ onCardHover: true });
    }

    /**
     * Handle remove of hover state.
     */
    onMouseLeave = () => {
        this.setState({ onCardHover: false });
    }

    /**
     * Handle guide save action.
     */
    saveGuide = (id, save, title, getContentAfterSave, article) => {
        const { toggleSaveGuide } = this.props;

        toggleSaveGuide(id, save, title, getContentAfterSave, article);
    }

    /**
     * Handle topic follow action.
     */
    followTopic = (id, status) => {
        const { toggleFollowTopic } = this.props;

        toggleFollowTopic(id, status);
    }

    /**
     * Handle source follow action.
     */
    followSource = (id, status) => {
        const { toggleFollowSource } = this.props;

        toggleFollowSource(id, status);
    }

    /**
     * Handle save article action.
     */
    saveArticle = (id, save, title, getContentAfterSave, article) => {
        const { toggleSaveArticle, toggleSavePlArticle } = this.props;

        if (this.props.article.sourceTitle === 'Practice Lab') {
            toggleSavePlArticle(id, save, title, getContentAfterSave, article);
        }
        else {
            toggleSaveArticle(id, save, title, getContentAfterSave, article);
        }
    }

    /**
     * Render the capital ideas card and underlying components.
     */
    render() {
        if (this.props.article === undefined) {
            return null;
        }

        const { onCardHover } = this.state;
        const { activateDismissCoachMark, onCoachMarkDismiss } = this.props;

        return (
            <Container
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            >
                <ImageContainer
                    visited={this.props.article.visited ? "true" : undefined}
                    to={this.props.article.url}
                    onClick={() => {
                        this.CH_handleComponentEventList();
                        this.onClick();
                    }}
                >

                    <ImageAtom
                        src={this.props.article.image}
                        alt={language.translations['global.alt.image']}
                    />
                </ImageContainer>
                <ContentContainer>
                    <DateAndTopic>
                        <DateContainer>
                            {moment.utc(this.props.article.publishDate).format('MMM D, YYYY')}
                        </DateContainer>
                        {this.props.article.topicName
                            && <TopicLink

                                to={TOPIC_LINK(`${this.props.article.topicName}/${this.props.article.topicId}`)}
                                data-analytics-placement="Button : body"
                                data-analytics-label="trackLink : button"
                                data-analytics-id={"topic:" + this.props.article.topicName + ":cicard"}
                            >
                                <span>{this.props.article.topicName}</span>
                            </TopicLink>}
                    </DateAndTopic>
                    <Title
                        visited={this.props.article.visited ? "true" : undefined}
                        to={this.props.article.url}
                        onClick={() => {
                            this.CH_handleComponentEventList();
                            this.onClick();
                        }}
                    >
                        {getTruncatedString(this.props.article.title, MAX_ARTICLE_TITLE_CHARS)}
                    </Title>

                    <ListContentCardFooter onElement={onCardHover}
                        profile={this.props.profile}
                        sourceName={this.props.article.sourceName}
                        providerName={this.props.article.provider}
                        article={this.props.article}
                        topics={this.props.article.topics}
                        sourceId={this.props.article.sourceId}
                        topicName={this.props.article.topicName}
                        saved={this.props.article.saved}
                        articleId={parseInt(this.props.article.id)}
                        articleTitle={this.props.article.title}
                        kebabButtonClickListener={() => activateDismissCoachMark ? onCoachMarkDismiss() : null}
                        type={this.props.article.type || this.props.article.contentType}
                        shouldShowToast={this.props.shouldShowToast}
                        hideSourceOption={true}
                        onSaveArticle={(id, save, title, getContentAfterSave, article) => {
                            this.saveArticle(id, save, title, getContentAfterSave, article);
                        }}
                        onSaveGuide={(id, save, title, getContentAfterSave, article) => {
                            this.saveGuide(id, save, title, getContentAfterSave, article);
                        }}
                        onFollowTopic={(topicId, status) => {
                            this.followTopic(topicId, status);
                        }}
                        onFollowSource={(sourceId, status) => {
                            this.followSource(sourceId, status);
                        }} />
                </ContentContainer>
            </Container>
        );
    }
}

CapitalIdeasCard.propTypes = {
    article: PropTypes.shape({
        date: PropTypes.string,
        topicName: PropTypes.string,
        topicId: PropTypes.number,
        title: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        sourceName: PropTypes.string.isRequired,
        sourceId: PropTypes.number,
        provider: PropTypes.string.isRequired,
        id: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        url: PropTypes.string.isRequired,
        visited: PropTypes.bool,
        saved: PropTypes.bool,
        topics: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string,
            ]).isRequired,
            followed: PropTypes.bool.isRequired,
            name: PropTypes.string.isRequired,
        })),
        contentType: PropTypes.string,
        interactionId: PropTypes.string,
    }).isRequired,
    handleGuideClick: PropTypes.func,
    handleKebabOpenOutside: PropTypes.func,
};

CapitalIdeasCard.defaultProps = {
    handleGuideClick: () => { },
    handleKebabOpenOutside: () => { },
}

const mapDispatchToProps = dispatch => ({
    toggleSaveArticle: (id, save, title, getContentAfterSave, article) => dispatch(toggleSaveArticle(id, save, {
        title,
        getContentAfterSave,
        article
    })),
    toggleSaveGuide: (id, save, title, shouldShowToast) => dispatch(toggleSaveGuideAction(id, save, {
        title,
        shouldShowToast
    })),
    toggleSavePlArticle: (id, save, title, getContentAfterSave, article) => dispatch(toggleSavePlArticle(id, save, {
        title,
        getContentAfterSave,
        article
    })),
    toggleFollowTopic: (topicId, isFollowing) => dispatch(toggleTopicFollow(topicId, isFollowing)),
    toggleFollowSource: (sourceId, isFollowing) => (
        dispatch(toggleSourceFollow(sourceId, isFollowing))
    ),
    onCoachMarkDismiss: () => dispatch(dismissCoachMark('KEBAB', true)),
    getVisitPracticeLab: article => dispatch(getVisitPracticeLab(article)),
});

const mapState = state => ({
    isLoading: state.followTopic.toggleInProgress,
    profile: state.basicProfile,
    activateDismissCoachMark: state.coachMark.display.KEBAB && !state.coachMark.display.INFO_BAR,

});

export default connect(mapState, mapDispatchToProps)(CapitalIdeasCard);
