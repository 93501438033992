import { replace } from "lodash";
import store from "../state-management/store/storeFactory";
let aeid = null;
let crd = null;

export const getAEID = () => aeid;
export const getCRD = () => crd;

export const setAEID = (profileAEID) => {
    aeid = profileAEID;
};

export const setCRD = (val) => {
    crd = val;
};

const shouldTrack = () => {
    let track = true;
    try {
        track = JSON.parse(localStorage.getItem("_evidon_consent_ls_22719"))
            .categories["1"]["analytics provider"];
    } catch (e) {
        // do nothing
    }
    return track;
};

export const STORE_SHOW_NEWUSER_VERSION = "showNewUserVersion";
export const WEBINAR_STATE_NEW_USER_LANDING = "NewUser";
export const WEBINAR_STATE_NEW_USER_SIGN_UP = "NewUserSignUp";
export const WEBINAR_STATE_CLAIM_UR_SPOT = "ClaimYourSpot";
export const WEBINAR_STATE_SUCCESSFUL_REGISTRATION = "Registered";
export const WEBINAR_STATE_POST = "PostWebinar";

export const getWebinarState = () => {
    let webinarState;
    const pagelevelState = store ? store.getState() : null;
    const isAuthenticated = pagelevelState.signIn.isAuthenticated;
    const isRegistered = pagelevelState.webinar.userRegistered;
    const userSources = pagelevelState.basicProfile.sourceIds;
    const userTopics = pagelevelState.basicProfile.topicIds;
    const email = pagelevelState.basicProfile.email;
    const hasSeenNewUserVersion =
        pagelevelState.STORE_SHOW_NEWUSER_VERSION + email;
    let isNewUser = isAuthenticated
        ? userSources.length === 0 && userTopics.length === 0
            ? true
            : false
        : false;

    if (isRegistered && isNewUser && !hasSeenNewUserVersion) {
        webinarState = WEBINAR_STATE_NEW_USER_LANDING;
    }
    if (isRegistered && (!isNewUser || hasSeenNewUserVersion)) {
        webinarState = WEBINAR_STATE_SUCCESSFUL_REGISTRATION;
    }
    if (!isRegistered && isAuthenticated) {
        webinarState = WEBINAR_STATE_CLAIM_UR_SPOT;
    }
    if (!isRegistered && !isAuthenticated) {
        webinarState = WEBINAR_STATE_NEW_USER_SIGN_UP;
    }
    return webinarState;
};

let articleTitle;
let articleSource;
export function contextHubRecordPageView(path) {
    if (!shouldTrack()) {
        return;
    }

    path = path.substring(1);
    if (path === "" || !path) {
        path = "landingpage";
    }

    const loggedIn = localStorage.getItem("loggedIn");
    let breadCrumbList = ("lumin/" + path).split("/");
    if (breadCrumbList.indexOf("webinar") >= 0) {
        const webinarPageState = getWebinarState();
        breadCrumbList.push(webinarPageState);
    }
    // const ddUserStore = Capgroup.ContextHub.stores.user;
    // const ddPageStore = Capgroup.ContextHub.stores.page;
    let pageName
    if (window.location.href.indexOf("articles") > -1 && articleTitle) {
        pageName = "lumin" + " > " + articleSource + " : " + articleTitle;
    } else {
        pageName = "lumin" + " > " + replace(path, "/", " > ");
    }
    
    // ddPageStore.setItem("info/name", pageName);
    // ddPageStore.setItem("category/audience", "lumin");
    // ddPageStore.setItem("category/country", "us");
    // ddPageStore.setItem("category/channel", "Capital Group - Lumin");
    // ddPageStore.setItem("category/language", "en");
    // ddPageStore.setItem("info/breadCrumbList", breadCrumbList);

    window.appEventData = window.appEventData || [];
    appEventData.push({
      event: "Page Load Started",
      page: {
        baseURL: window.location.href,
        channel:"Capital Group - Lumin",
        navigationPath: "",
        pageName: pageName,
        siteAudience: "lumin",
        siteCountry: "us",
        siteLanguage: "en",
        siteSection1: breadCrumbList?breadCrumbList[0]:"",
        siteSection2: breadCrumbList?breadCrumbList[1]:"",
        siteSection3: breadCrumbList?breadCrumbList[2]:"",
        siteSection4: breadCrumbList?breadCrumbList[3]:"",
        webPageType: "AEM",
        workfrontID: ""
      },
      user: {
        advisorId:  aeid?aeid.toString():"",
        dealerCode: "",
        institutionalID: "",
        investorId: "",
        partyId: "",
        personaSegment: "",
        planId: "",
        planParticipantId: "",
        tracSponsorId: "",
        userRole: "",
        visionId: "",
      },
    });
    console.log("Page Load Started fired");
    setTimeout(()=>{
        window.appEventData = window.appEventData || [];
        console.log("Page Load Completed Fired");
        window.appEventData.push({event:"Page Load Completed"});
    },1000); // delaying the page load completed to fire after the 

    //AEID
    // if (aeid && aeid !== null) {
    //     ddUserStore.setItem('primaryProfile/info/advisorId', aeid.toString());
    // } else {
    //     ddUserStore.setItem('primaryProfile/info/advisorId', "");
    // }
    // // CRD
    // if (crd && crd !== null) {
    //     ddUserStore.setItem('primaryProfile/info/crd', crd.toString());
    // } else {
    //     ddUserStore.setItem('primaryProfile/info/crd', "");
    // }

    // if (loggedIn) {
    //     var ddEventStore = Capgroup.ContextHub.stores.event,
    //         ddEventListItem = ddEventStore.models.newEventListItem();
    //     ddEventListItem.info.action = Capgroup.ContextHub.stores.event.Constants.EVENT_ACTIONS.COMPLETE;
    //     ddEventListItem.info.label = "Lumin Login";
    //     ddEventListItem.info.name = Capgroup.ContextHub.stores.event.Constants.EVENT_NAMES.MULTI_STEP;
    //     ddEventListItem.info.type = Capgroup.ContextHub.stores.event.Constants.EVENT_TYPES.LOGIN;
    //     ddEventListItem.info.value = "Complete: Lumin Sign in";
    //     ddEventStore.pushItem("eventList", ddEventListItem);
    // }

    // if (path === "sign-in") {
    //     var ddEventStore = Capgroup.ContextHub.stores.event,
    //         ddEventListItem = ddEventStore.models.newEventListItem();
    //     ddEventListItem.info.action = Capgroup.ContextHub.stores.event.Constants.EVENT_ACTIONS.START;
    //     ddEventListItem.info.label = "Lumin Login";
    //     ddEventListItem.info.name = Capgroup.ContextHub.stores.event.Constants.EVENT_NAMES.MULTI_STEP;
    //     ddEventListItem.info.type = Capgroup.ContextHub.stores.event.Constants.EVENT_TYPES.LOGIN;
    //     ddEventListItem.info.value = "Step 1: Lumin Sign in";
    //     ddEventStore.pushItem("eventList", ddEventListItem);
    // }

    // if ((path === "landingpage" && localStorage.getItem("digital_prospecting.first_name")) || path === "onboarding") {
    //     return;
    // }

    // if (typeof _satellite !== 'undefined') {
    //     _satellite.track("luminClientSideDefaults");
    //     localStorage.removeItem("loggedIn");
    // }
}

export function contextHubRecordSearch(query, results) {
    if (!shouldTrack()) {
        return;
    }

    // if (query !== '' && query !== undefined) {

    //     var ddComponentStore = Capgroup.ContextHub.stores.component,
    //         ddComponentListItem = ddComponentStore.models.newComponentListItem();

    //     ddComponentListItem.info.name = "Search";
    //     ddComponentListItem.attribute.onSiteSearchTerm = query;
    //     ddComponentListItem.attribute.onSiteSearchResult = results;
    //     ddComponentStore.pushItem("componentList", ddComponentListItem);

    //     var ddEventStore = Capgroup.ContextHub.stores.event,
    //         ddEventListItem = ddEventStore.models.newEventListItem();
    //     ddEventListItem.info.action = Capgroup.ContextHub.stores.event.Constants.EVENT_ACTIONS.RESULTS;
    //     ddEventListItem.info.label = ddComponentListItem.info.name;
    //     ddEventListItem.info.name = Capgroup.ContextHub.stores.event.Constants.EVENT_NAMES.ON_SITE_SEARCH;
    //     ddEventListItem.info.type = Capgroup.ContextHub.stores.event.Constants.EVENT_TYPES.SEARCH;
    //     ddEventListItem.info.value = results;
    //     ddEventStore.pushItem("eventList", ddEventListItem);
    // }
    contextHubRecordPageView("/search");
}

export function contextHubRecordNews(path, analyticsData) {
    if (!shouldTrack()) {
        return;
    }
    if (path !== '' && path !== undefined && path === '/news') {
        // var ddComponentStore = Capgroup.ContextHub.stores.component,
        //     ddComponentListItem = ddComponentStore.models.newComponentListItem();

        // ddComponentListItem.info.name = "News";
        // ddComponentListItem.attribute.onPageLoad = analyticsData;
        // ddComponentStore.pushItem("componentList", ddComponentListItem);

        // var ddEventStore = Capgroup.ContextHub.stores.event,
        //     ddEventListItem = ddEventStore.models.newEventListItem();
        // ddEventListItem.info.label = ddComponentListItem.info.name;
        // ddEventListItem.info.name = Capgroup.ContextHub.stores.event.Constants.EVENT_NAMES.NEWS;
        // ddEventStore.pushItem("eventList", ddEventListItem);
        contextHubRecordPageView("/news");
    }
}

export function contextHubRecordArticleClick(path, analyticsData) {
    if (!shouldTrack()) {
        return;
    }
    if (path !== '' && path !== undefined) {
        // var ddComponentStore = Capgroup.ContextHub.stores.component,
        //     ddComponentListItem = ddComponentStore.models.newComponentListItem();

        // ddComponentListItem.info.name = "Article click";
        // ddComponentListItem.attribute.onPageLoad = analyticsData;
        // ddComponentStore.pushItem("componentList", ddComponentListItem);

        // var ddEventStore = Capgroup.ContextHub.stores.event,
        //     ddEventListItem = ddEventStore.models.newEventListItem();
        // ddEventListItem.info.label = ddComponentListItem.info.name;
        // ddEventListItem.info.name = "articleClick";
        // ddEventStore.pushItem("eventList", ddEventListItem);
        contextHubRecordPageView(path);
    }
}

export function contextHubRecordEvent(obj) {
    try {
        if (!shouldTrack()) {
            return;
        }

        // const cs = "padding: 10px; color: #fff; background-color: #000; font-weight: bold";

        // var ddComponentStore = Capgroup.ContextHub.stores.component,
        //     ddComponentListItem = ddComponentStore.models.newComponentListItem(),
        //     ddEventStore = Capgroup.ContextHub.stores.event,
        //     ddEventListItem = ddEventStore.models.newEventListItem();

        // switch (obj.type) {
        //     case "share":
        //         //user has just signed in to lumin via a shared link
        //         var ddEventStore = Capgroup.ContextHub.stores.event,
        //             ddEventListItem = ddEventStore.models.newEventListItem();
        //         ddEventListItem.info.type = `share`;
        //         ddEventListItem.info.action = `Page Load: ${obj.valueType} - ${obj.value}`;

        //         break;

        //     case "article":
        //     case "guide":
        //     case "tool":
        //     case "cecredits":
        //     case "toast":
        //     case "dropDown":
        //     case "tab":
        //     case "contactUs":
        //     case "anchor":
        //     case "pollButton":

        //         //kebab
        //         if (obj.location == "kebab") {
        //             var kebabAction = "";

        //             if (obj.action == "save") {
        //                 kebabAction = obj.value ? "saved" : "unsaved";
        //             } else {
        //                 if (obj.value !== '') {
        //                     kebabAction = (obj.value ? "follow" : "unfollow") + " " + obj.action;
        //                 } else {
        //                     kebabAction = "unfollow unsuccessful " + " " + obj.action;
        //                 }
        //             }
        //             //set up the event item
        //             ddEventListItem.info.type = obj.type;
        //             ddEventListItem.info.action = kebabAction;
        //             ddEventListItem.info.value = obj.title;
        //             ddEventListItem.info.name = obj.title;
        //             ddEventListItem.info.label = "luminKebab"

        //             //set up component item
        //             ddComponentListItem.info.name = ddEventListItem.info.label;
        //             ddComponentListItem.category.type = obj.topic + "|" + obj.source;
        //             ddComponentListItem.category.placement = obj.location;
        //             //kebab

        //         } else {
        //             ddEventListItem.info.label = "luminContent"
        //             if (obj.type === 'dropDown' || obj.type === 'toast' || obj.type === 'tab' ||
        //                 obj.type === 'contactUs' || obj.type === 'anchor' || obj.type === 'pollButton') {
        //                 if (obj.type === 'dropDown') {
        //                     ddEventListItem.info.type = obj.type;
        //                     ddEventListItem.info.action = obj.action;
        //                     ddEventListItem.info.name = obj.value;
        //                 }
        //                 //article guide or tool or toast click
        //                 if (obj.type === 'toast') {
        //                     ddEventListItem.info.type = obj.type;
        //                     ddEventListItem.info.action = obj.action;
        //                     ddEventListItem.info.name = obj.value;
        //                     ddEventListItem.info.value = obj.value;
        //                 }
        //                 if (obj.type === 'tab') {
        //                     ddEventListItem.info.type = obj.type;
        //                     ddEventListItem.info.action = obj.action;
        //                     ddEventListItem.info.name = obj.value;
        //                     ddEventListItem.info.value = obj.value;
        //                 }
        //                 if (obj.type === 'contactUs') {
        //                     ddEventListItem.info.type = obj.type;
        //                     ddEventListItem.info.action = obj.action;
        //                     ddEventListItem.info.name = obj.value;
        //                     ddEventListItem.info.value = obj.value;
        //                 }
        //                 if (obj.type === 'anchor') {
        //                     ddEventListItem.info.type = obj.type;
        //                     ddEventListItem.info.action = 'Anchor: ' + obj.action;
        //                     ddEventListItem.info.name = obj.value;
        //                     ddEventListItem.info.value = obj.value;
        //                 }
        //                 if (obj.type === 'pollButton') {
        //                     ddEventListItem.info.type = obj.type;
        //                     ddEventListItem.info.action = obj.action;
        //                     ddEventListItem.info.name = obj.value;
        //                     ddEventListItem.info.value = obj.value;
        //                 }
        //             } else {
        //                 articleTitle = obj.title;
        //                 articleSource = obj.source;
        //                 ddEventListItem.info.type = obj.type;
        //                 ddEventListItem.info.action = "read";
        //                 ddEventListItem.info.name = obj.title;
        //                 ddEventListItem.info.value = obj.title;
        //             }

        //             //set up component item
        //             ddComponentListItem.info.name = ddEventListItem.info.label;
        //             ddComponentListItem.category.type = obj.topic + "|" + obj.source;
        //         }

        //         break;

        //     case "coachMark":
        //         ddEventListItem.info.type = obj.type;
        //         ddEventListItem.info.name = obj.title;
        //         ddEventListItem.info.value = obj.title;
        //         ddEventListItem.info.action = obj.action;
        //         break;

        //     case "video":
        //         ddEventListItem.info.type = obj.type;
        //         ddEventListItem.info.name = obj.title;
        //         ddEventListItem.info.value = obj.title;
        //         ddEventListItem.info.action = obj.action;
        //         break;

        //     case "LoginFail":
        //         var ddEventStore = Capgroup.ContextHub.stores.event,
        //             ddEventListItem = ddEventStore.models.newEventListItem();
        //         ddEventListItem.info.action = Capgroup.ContextHub.stores.event.Constants.EVENT_ACTIONS.FAILURE;
        //         ddEventListItem.info.label = "Lumin Login";
        //         ddEventListItem.info.name = Capgroup.ContextHub.stores.event.Constants.EVENT_NAMES.MULTI_STEP;
        //         ddEventListItem.info.type = Capgroup.ContextHub.stores.event.Constants.EVENT_TYPES.LOGIN;
        //         ddEventListItem.info.value = "Lumin Login Failure";
        //         ddEventStore.pushItem("eventList", ddEventListItem);

        //     default:
        // }

        // //push items to contexhub store
        // ddComponentStore.pushItem("componentList", ddComponentListItem);
        // ddEventStore.pushItem("eventList", ddEventListItem);

        // if (_satellite) {
        //     _satellite.track("luminEvent");
        // }
    } catch (e) {
        console.log("error at analytics..");
        console.log("error:" + JSON.stringify(e));
    }
}
