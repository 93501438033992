import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { LottieWrapper } from 'components';
import animation from 'assets/loader/page-loader.json';
import VisuallyHidden from 'components/VisuallyHidden/VisuallyHidden';
import {
    loaderStyles,
    overlayStyles,
} from 'components/Loader/styles';

const StyledOverlay = styled.div`
    ${overlayStyles};
`;

export const StyledLoader = styled(LottieWrapper)`
    ${loaderStyles};
`;

export const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
};

const Loader = () => (
    <StyledOverlay>
        <VisuallyHidden>
            <FormattedMessage id="loader.message" />
        </VisuallyHidden>
        <StyledLoader
            options={defaultOptions}
        />
    </StyledOverlay>
);

export default Loader;
