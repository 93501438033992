import {
    RESET_FORGOT_USERNAME_EMAIL,
    SEND_FORGOT_USERNAME_EMAIL,
    SEND_FORGOT_USERNAME_EMAIL_ERROR,
    SEND_FORGOT_USERNAME_EMAIL_SUCCESS,
} from 'state-management/constants/forgotUsername';

const initialState = {
    loading: false,
    email: null,
    error: null,
};

function forgotUsernameReducer(state = initialState, action = {}) {
    switch (action.type) {
        case RESET_FORGOT_USERNAME_EMAIL:
            return {
                ...state,
                loading: false,
                error: null,
                email: null,
            };
        case SEND_FORGOT_USERNAME_EMAIL:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case SEND_FORGOT_USERNAME_EMAIL_SUCCESS:
            return {
                ...state,
                email: action.email,
                error: null,
            };
        case SEND_FORGOT_USERNAME_EMAIL_ERROR:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
}

export default forgotUsernameReducer;
