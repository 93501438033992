export const HOMEPAGE_DATA_URL = '/content-service/homepage';

export const GET_HOMEPAGE_DATA = 'homepage/GET_HOMEPAGE_DATA';
export const GET_HOMEPAGE_DATA_FAIL = 'homepage/GET_HOMEPAGE_DATA_FAIL';
export const GET_HOMEPAGE_DATA_SUCCESS = 'homepage/GET_HOMEPAGE_DATA_SUCCESS';

export const HOMEPAGE_POLL_URL = '/content-service/homepage/poll';
// export const HOMEPAGE_POLL_DATA_URL = '/content-service/homepage/poll/percentage';
export const HOMEPAGE_POLL_DATA_URL = '/content-service/homepage/poll/result';
export const POST_HOMEPAGE_POLL = 'homepage/POST_HOMEPAGE_POLL';
export const POST_HOMEPAGE_POLL_SUCCESS = 'homepage/POST_HOMEPAGE_POLL_SUCCESS';
export const POST_HOMEPAGE_POLL_FAILURE = 'homepage/POST_HOMEPAGE_POLL_FAILURE';
export const GET_HOMEPAGE_POLL_DATA = 'homepage/GET_HOMEPAGE_POLL_DATA';
export const GET_HOMEPAGE_POLL_DATA_SUCCESS = 'homepage/GET_HOMEPAGE_POLL_DATA_SUCCESS';
export const GET_HOMEPAGE_POLL_DATA_FAILURE = 'homepage/GET_HOMEPAGE_POLL_DATA_FAILURE';
