import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { defaultsDeep } from 'lodash';
import { iconStyles } from 'components/atomics/atoms/Icons/Icon/styles';

/**
 * Default class to wrap SVG icons for use as tags in other components.
 * @param {*} Component 
 * @param {*} defaultProps 
 */
const Icon = (Component, defaultProps = {}) => {
    const StyledIcon = styled(Component)`
        ${iconStyles};
    `;

    const WithIcon = (props) => {
        const iconProps = {};
        defaultsDeep(iconProps, props, defaultProps);
        const { focusable, ...other } = iconProps;

        return (
            <StyledIcon
                {...other}
                focusable={focusable.toString()}
            />
        );
    };

    WithIcon.propTypes = {
        className: PropTypes.string,
        focusable: PropTypes.bool,
        height: PropTypes.number,
        role: PropTypes.string,
        strokeWidth: PropTypes.number,
        width: PropTypes.number,
    };

    WithIcon.defaultProps = {
        className: '',
        focusable: false,
        height: undefined,
        role: 'presentation',
        strokeWidth: 1,
        width: undefined,
    };

    return WithIcon;
};

export default Icon;
