import { css } from 'styled-components';
import { FROM_TABLET } from 'utils/mediaQueries';
import { FONT_AVENIR_NEXT, COLORS, ANIMATIONS } from 'utils/variables';

export const contentContainerStyles = css`
    border-top: 1px solid ${COLORS.LIGHT_GRAY2};
`;

export const contentStyles = css`
    margin: 0 auto;
    max-width: 300px;
    padding: 48px 0;
    text-align: center;

    @media screen and ${FROM_TABLET} {
        max-width: 520px;
        padding: 147px 0 118px;
    }
`;

export const headingStyles = css`
    color: ${COLORS.SAPPHIRE};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 32px;
    font-weight: 400;
    line-height: 1.06;

    @media screen and ${FROM_TABLET} {
        font-size: 55px;
        line-height: 1.18;
    }
`;

export const linkStyles = css`
    border-bottom: 2px solid currentColor;
    color: inherit;
    display: inline-block;
    line-height: 0.7;
    text-decoration: none;
    transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;
    text-shadow: 2px -1px ${COLORS.WHITE}, 2px -2px ${COLORS.WHITE} -2px -1px ${COLORS.WHITE}, -2px -2px ${COLORS.WHITE};

    @media screen and ${FROM_TABLET} {
        line-height: 0.8;
    }

    &:hover {
        color: ${COLORS.DARK_SAPPHIRE};
    }
`;

export const iconStyles = css`
    margin-top: 24px;

    @media screen and ${FROM_TABLET} {
        margin-top: 54px;
    }
`;
