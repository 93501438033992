import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'components/atomics/atoms/Button/Button';
import { FormattedMessage } from 'react-intl';
import {
    TYPE_PRIMARY_MEDIUM,
    TYPE_SECONDARY_SMALL,
} from 'components/atomics/atoms/Button/Button';
import {
    containerStyles,
    colorBarStyles,
    titleStyles,
} from 'components/atomics/molecules/MobileAppNotification/styles';

export const Container = styled.div`
    ${containerStyles}
`;

export const ColorBar = styled.div`
    ${colorBarStyles}
`;

export const Title = styled.h4`
    ${titleStyles}
`;

/**
 * Creates a banner notification to tell the user about lumin mobile app.
 */
class MobileAppNotification extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.handleDismiss = this.handleDismiss.bind(this);
    }

    /**
     * Handle dismissal of the notification.
     */
    handleDismiss() {
        if (this.props.dismissAction) {
            this.props.dismissAction();
        }
    }

    /**
     * Render the mobile app notification and underlying components (unless dismissed).
     */
    render() {
        if (this.props.isDismissed || !this.props.isLoggedIn || !this.props.isIOSMobile) {
            return null;
        }

        return (
            <Container data-rel="MobileAppNotification" showAlternate={this.props.showAlternate.toString()}>
                <ColorBar />
                <Title>
                    {this.props.title}
                </Title>
                <p>
                    {this.props.description}
                </p>
                <div data-rel="controls">
                    <Button
                        data-analytics-placement="Button : body"
                        data-analytics-label="trackLink : button"
                        data-analytics-id={this.props.dismissLabel}
                        type={TYPE_SECONDARY_SMALL}
                        to="#"
                        onClick={this.handleDismiss}
                    >
                        {this.props.dismissLabel}
                    </Button>

                    <Button
                        data-analytics-placement="Button : body"
                        data-analytics-label="trackLink : button"
                        data-analytics-id={this.props.acceptLabel}
                        type={TYPE_PRIMARY_MEDIUM}
                        to={this.props.url}
                        onClick={this.handleDismiss}
                    >
                        {this.props.acceptLabel}
                    </Button>
                </div>
                {this.props.showAlternate
                    && <div data-rel="additional-controls">
                        <a
                            href="#"
                            onClick={this.props.dismissFinalAction}>
                            <FormattedMessage id="mobileAppNotification.dontAsk" />
                        </a>
                    </div>}
            </Container>
        );
    }
}

MobileAppNotification.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
    acceptLabel: PropTypes.string,
    dismissLabel: PropTypes.string,
    description: PropTypes.string,
    dismissAction: PropTypes.func,
    isDismissed: PropTypes.bool,
    showAlternate: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    isIOSMobile: PropTypes.bool,
    dismissFinalAction: PropTypes.func,
};

MobileAppNotification.defaultProps = {
    title: null,
    url: '/download',
    acceptLabel: null,
    dismissLabel: null,
    description: null,
    dismissAction: () => { },
    dismissFinalAction: () => { },
    isDismissed: false,
    showAlternate: false,
    isLoggedIn: false,
    isIOSMobile: false,
}

export default MobileAppNotification;
