import { css } from 'styled-components';
import {
    COLORS,
    FONTS,
    ANIMATIONS,
} from 'utils/variables';
import {
    FROM_DESKTOP,
    FROM_TABLET,
} from 'utils/mediaQueries';

export const imageContainerStyles = css` 
    width: 78px;
    height: 78px;

    img {
        width: 78px;
        height: 78px;
        object-fit: cover;
    }
`;

export const titleStyles = css`
    color: ${COLORS.BLACK};
    display: inline-block;
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.1px;
    margin: 0;
    margin-bottom: 8px;
    text-decoration: none;
    min-height:60px;
    overflow:hidden;
    transition: ${ANIMATIONS.ARTICLES.TITLE.TIME.SECONDS}s all;

    &:hover {
        color: ${COLORS.MEDIUM_GRAY2};
    }

    @media screen and ${FROM_TABLET} {
        min-height: 52px;
        margin-bottom: 7px;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: -0.1px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-top: 23px;
        font-size: 15px;
        line-height: 20px;
        min-height: 58px;
    }
`;

export const containerStyles = css`
    position: relative;   
    display: inline-block;       
    text-decoration: none;
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    margin-bottom: 20px;
    max-width: 465px;

    ${props => props.dataPage === "landingPage" && css`
        margin-bottom: 6px;
        max-width: unset;
    ` }
`;

export const typeStyles = css`
    display: flex;
    font-size:12px;
    line-height:16px;
    letter-spacing:0.6px
    font-weight: 500;
    margin-bottom: 21px;
    text-transform: uppercase;

    @media screen and ${FROM_TABLET} {
        margin-bottom: 24px;
        font-size: 10px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 7px;
        font-size: 12px;
    }
`;

export const typeContainerStyles = css`
    padding-right: 0;
    white-space: nowrap;
    color: ${COLORS.DARK_GRAY1};

    @media screen and ${FROM_DESKTOP} {
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: 0.6px;
    }
`;

export const mainContainerStyles = css`
    display: flex;
    flex-direction: column;

    @media screen and ${FROM_DESKTOP} {
        flex-direction: row;
    }

    @media screen and ${FROM_TABLET} {
        ${props => props.dataPage === "landingPage" && css`
        flex-direction: row;
        `}
        }
`;

export const sectionHeadingStyles = css`
    color: ${COLORS.DARK_GRAY2};
    font-size: 12px;
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 16px;
`;

export const contentContainerStyles = css`
    padding: 17px 0 0 0;
    width: 100%;

    @media screen and ${FROM_DESKTOP} {
        padding: 0 0 0 21px;
    }

    @media screen and ${FROM_TABLET} {
        ${props => props.dataPage === "landingPage" && css`
            padding: 0 0 0 21px;
        `}
    }
`;

export const descriptionStyles = css`
    margin-top: 19px;
    font-size: 15px;
    line-height: 20px;
`;

export const speakerNameStyles = css`
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 20px;
   
    ${props => props.dataPage === "landingPage" && css`
        font-size: 15px;

        @media screen and ${FROM_DESKTOP} {
            font-size: 18px;
        }

    `}  
`;

export const speakerDesignationStyles = css`
    font-size: 15px;
    line-height: 20px;
`;
