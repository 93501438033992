import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
    navigationWrapperStyles,
    navigationListItemStyles,
    navigationListStyles,
    navigationLinkStyles,
    linkListIconStyles,
} from './styles';
import { IconStepperNext } from 'components/atomics/atoms/Icons/Icons';
import Button from 'components/atomics/atoms/Button/Button';
import { FormattedMessage } from "react-intl";
import {
    TYPE_SECONDARY_MEDIUM,
} from 'components/atomics/atoms/Button/Button';
import Badge from 'components/atomics/atoms/Badge/Badge';
import {
    removeItemFromHistory,
} from "../../../../state-management/actions/UrlHistoryActions";
import { connect } from "react-redux";
import { SourceModal } from '../NewsSource/SourceModal';
import storage from '../../../../utils/store';

export const StyledNav = styled.nav`
    ${navigationWrapperStyles};
`;

export const StyledNavList = styled.ul`
    ${navigationListStyles}
`;

export const StyledNavListItem = styled.li`
    ${navigationListItemStyles};
`;

export const StyledNavLink = styled(({ passedRef, ...other }) => <Link ref={passedRef} {...other} />)`
    ${navigationLinkStyles};
`;

const StyledLinkListIcon = styled(IconStepperNext)`
    ${linkListIconStyles}
`;

/**
 * Creates a list of links for menu item building,
 * used in account screens menu.
 */
class LinkList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sourceModal: false,
        }
    }
    /**
    * Stuff to do on modal close.
    */
    closeSourceModal = () => {
        this.setState({ sourceModal: false });
    }

    render() {
        const {
            id,
            routes,
            title,
            notification,
        } = this.props;

        this.page = (window.location.pathname).split("/");
        this.pageName = this.page[2];

        const badge = notification.data.expiringBookmarksDetailBadge;
    
        return (
            <>
                <SourceModal isOpen={this.state.sourceModal} closeSourceModal={this.closeSourceModal} />
                <StyledNav
                    id={id}
                >
                    <h4 className='title'>{title}</h4>
                    <StyledNavList
                        id={`${id}-list`}
                    >

                        {
                            routes.map((item, index) => {
                                const { title, id, menu, ...other } = item;
                                const selected = (id === this.props.selectedNav) ? 'selected' : '';
                                const selectedSub = (menu === this.pageName) ? 'selected' : '';
                                const isLastItem = (index === (routes && routes.length - 1)) ? 'last-item' : '';
                                const klass = `${selected} ${isLastItem} ${selectedSub}`;

                                return (
                                    <StyledNavListItem key={item.id} onClick={() => {
                                        if (this.props.handleChange) {
                                            this.props.handleChange();
                                        }
                                    }}>
                                        <StyledNavLink
                                            className={klass}
                                            {...other}
                                            onClick={(e) => {
                                                const editMode = storage.get('profileEditClicked')
                                                if (editMode == null || editMode == false) {
                                                    const sourceLength = this.props.selectedSources.length;
                                                    if (item.id === "preferences" && sourceLength != undefined && sourceLength < 3) {
                                                        e.preventDefault();
                                                        this.setState({ sourceModal: true });
                                                    }
                                                }
                                            }}
                                        >
                                            {title}
                                            {id === 'saves' && badge !== undefined && badge > 0 && <Badge label={badge} position="center" /> }
                                        </StyledNavLink>
                                        <StyledLinkListIcon />
                                    </StyledNavListItem>
                                );
                            })
                        }
                        <StyledNavListItem>
                            <Button
                                type={TYPE_SECONDARY_MEDIUM}
                                to="/sign-out"
                                className='signOutbtn'
                            >
                                <FormattedMessage id="privateLayout.navigation.signOut" />
                            </Button>
                        </StyledNavListItem>
                    </StyledNavList>
                </StyledNav>
            </>
        );
    }
}

LinkList.propTypes = {
    id: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]).isRequired,
        title: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.string,
        ]).isRequired,
        to: PropTypes.string.isRequired,
    })),
    selectedNav: PropTypes.string,
    title: PropTypes.string,
    selectedSources: PropTypes.arrayOf(PropTypes.number),
};

LinkList.defaultProps = {
    id: 'sideNav',
    routes: [],
    selectedNav: null,
    title: 'Profile',
    selectedSources: [],

};

const mapStateToProps = (state) => ({
    urlHistoryList: state.UrlHistoryReducer.history,
    selectedSources: state.basicProfile.sourceIds,
    notification: state.basicProfile.notification
});

const mapDispatchToProps = dispatch => ({
    removeItemFromHistory: () => dispatch(removeItemFromHistory()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LinkList);
