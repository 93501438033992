import { css } from 'styled-components';
import { FROM_TABLET, FROM_DESKTOP } from 'utils/mediaQueries';
import {
    COLORS,
    FONT_AVENIR_NEXT,
} from 'utils/variables';

export const wrapperStyles = css`
    align-items: center;
    background-color: ${COLORS.LIGHT_GRAY1};
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
`;

export const contentStyles = css`
    padding-top:200px;
    @media screen\0 {
           padding-top:0;
    }
`;

export const markStyles = css`
    margin: 0 auto;
    transform: scale(0.2);

    @media screen and ${FROM_DESKTOP} {
        transform: scale(0.6);
    }
`;

export const textStyles = css`
    color: ${COLORS.SAPPHIRE};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 48px;
    line-heght: 26px;
    font-weight: 400;
    letter-spacing: -0.2px;
    margin: 0;
    margin-top: -70px;
    text-align: center;

    /* stylelint-disable */

    ${props => props.variant && props.variant.isAnimatable && !props.variant.isAnimated && css`
        opacity: 0;
    `};

    /* stylelint-enable */

    @media screen and ${FROM_TABLET} {
        font-size: 48px;
        line-height: 42px;
        letter-spacing: 0;
        margin-top: 0;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 88px;
    }
`;
