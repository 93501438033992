import {
    APPLY_FILTERS,
    CLEAR_ALL,
    CLEAR_SECTION,
    RESET_SEARCH_FILTER,
    RESET_SECTION,
    SEE_MORE_NEWS,
    SEE_MORE_PRACTICE_MANAGEMENT,
    SET_SECTION,
    TOGGLE_DATE_ITEM,
    TOGGLE_ITEM,
    TOGGLE_OPEN,
} from 'state-management/constants/searchFilter';

export const applyFilters = () => ({
    type: APPLY_FILTERS,
});

export const clearAll = () => ({
    type: CLEAR_ALL,
});

export const clearSection = section => ({
    type: CLEAR_SECTION,
    section,
});

export const resetSearchFilter = () => ({
    type: RESET_SEARCH_FILTER,
});

export const resetSection = section => ({
    type: RESET_SECTION,
    section,
});

export const seeMoreNews = () => ({
    type: SEE_MORE_NEWS,
});

export const seeMorePracticeManagement = () => ({
    type: SEE_MORE_PRACTICE_MANAGEMENT,
});

export const setSection = (section, name) => ({
    type: SET_SECTION,
    name,
    section,
});

export const toggleDateItem = idx => ({
    type: TOGGLE_DATE_ITEM,
    idx,
});

export const toggleItem = (section, idx) => ({
    idx,
    section,
    type: TOGGLE_ITEM,
});

export const toggleOpen = (open, params) => ({
    type: TOGGLE_OPEN,
    open,
    ...params,
});
