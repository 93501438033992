import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import isPasswordFormatValid from 'utils/validators/passwordValidator';
import isNameInvalid from 'utils/validators/tagInNameValidator';
import isZipValid from 'utils/validators/zipCodeValidator';
import { getAdvisorClassifications } from 'state-management/actions/advisorClassifications';
import {
    setFormReset,
    saveBasicProfileForm,
    setFormTouched as setFormTouchedAction,
    setFormToEditMode as setFormToEditModeAction,
    setFormToOnlyEditMode as setFormToOnlyEditModeAction,
    setFormToEditDefaultMode as setFormToEditDefaultModeAction,
    resetFormSubmitTrigger as resetFormSubmitTriggerAction,
    resetFormState as resetFormStateAction,
    togglePreferncesToDefault,
    getBackToPreviousState,
    setFormUntouched,
} from 'state-management/actions/basicProfileForm';
import { dismissExpiringBookmarksNotification } from 'state-management/actions/basicProfile';
import { DISMISS_EXPIRING_BOOKMARKS_MAIN_BADGE_STORAGE_KEY } from 'state-management/constants/basicProfile';
import { clearErrors } from 'state-management/actions/errors';
import { hidePopup as hidePopupAction } from 'state-management/actions/globalPopupContainer';
import { resetRequestSource } from 'state-management/actions/requestSource';
import { getProfileSources as getSourcesAction } from 'state-management/actions/sources';
import { setScrollToTop } from 'state-management/actions/scroll';
import { getTopics as getTopicsAction } from 'state-management/actions/topics';
import ProfileForm from 'containers/Profile/ProfileForm';
import {
    HOMEPAGE_DEFAULT,
    HOMEPAGE_NEWS,
} from 'state-management/constants/basicProfile';
import { profileSectionStyles } from 'containers/Profile/styles';
import animationFrame from 'utils/animationFrame';
import Loader from 'components/Loader/Loader';

const StyledProfileSection = styled.section`
    ${profileSectionStyles};
`;

/**
 * Profile page(s) parent container.
 */
export class Profile extends React.Component {
    /**
     * Default constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.inputRefs = {};
        isPracticeInfoEdit: false;

        this.handleProfileEdit = this.handleProfileEdit.bind(this);
    }

    /**
     * Gather needed data at mount.
     */
    componentDidMount() {
        this.props.getSources(true);

        if (this.props.topicCategories.length === 0) {
            this.props.getTopics();
        }

        this.props.getAdvisorClassificationsData();
        this.props.clearGlobalErrors();
        this.props.scrollToTop();
        this.props.dismissExpiringBookmarksMainBadge();
    }

    /**
     * Placeholder in case needed for transient data changes..
     */
    handleProfileEdit() {

    }

    /**
     * Reset state and cancel animations on destroy.
     */
    componentWillUnmount() {
        this.props.resetRequestSource();
        this.props.resetFormState();

        if (this.focusRaf) {
            animationFrame.cancel(this.focusRaf);
            this.focusRaf = null;
        }
    }

    /**
     * Handle input to control namespace alignments.
     */
    setInputRef = name => (ref) => {
        this.inputRefs[name] = document.getElementById(name);
    };

    /**
     * Handle form validation.
     */
    validate = (values) => {
        const errors = {};
        const {
            curPassword,
            password,
            verifyPassword,
            firstName,
            lastName,
            zip,
            crd,
            iard
        } = values;
        const { formatMessage } = this.props.intl;

        if (!firstName.trim()) {
            errors.firstName = this.formatFieldErrorMessage('error.emptyField', 'profilePage.accountInformation.firstName');
        } else if (isNameInvalid(values.firstName)) {
            errors.firstName = this.formatFieldErrorMessage('error.specialCharacter', 'profilePage.accountInformation.firstName');
        }

        if (!lastName.trim()) {
            errors.lastName = this.formatFieldErrorMessage('error.emptyField', 'profilePage.accountInformation.lastName');
        } else if (isNameInvalid(values.lastName)) {
            errors.lastName = this.formatFieldErrorMessage('error.specialCharacter', 'profilePage.accountInformation.lastName');
        }

        if (password || verifyPassword) {
            if (!isPasswordFormatValid(password)) {
                errors.password = formatMessage({ id: 'profilePage.errors.badPasswordFormat' });
            }

            if (password !== verifyPassword) {
                errors.verifyPassword = formatMessage({ id: 'profilePage.errors.matchPasswords' });
            }
        }

        if (password || curPassword) {
            if (password === curPassword) {
                errors.password = formatMessage({ id: 'profilePage.errors.samePasswordFormat' });
            }
        }

        if (password && !curPassword) {
            errors.curPassword = this.formatFieldErrorMessage('error.emptyField', 'profilePage.accountInformation.curPassword');
        }

        if (!isEmpty(errors)) {
            this.props.resetFormSubmitTrigger();
            this.focusRaf = animationFrame.request(() => this.focusError(errors));
        } else {
            this.props.hidePopup(true);
        }

        return errors;
    };

    /**
     * Format input errors.
     */
    formatFieldErrorMessage = (id, field) => this.props.intl.formatMessage(
        { id },
        { field: this.props.intl.formatMessage({ id: field }) },
    );

    /**
     * Focus input errors.
     */
    focusError = (errors) => {
        const inputName = Object.keys(errors)[0];
        this.inputRefs[inputName].focus();
    };

    /**
     * Format initial form values.
     */
    formatInitialValues = values => ({
        ...values,
        isHomepageNews: values.homepage === HOMEPAGE_NEWS,
    });

    /**
     * Format data for submission to BE.
     */
    formatValuesBeforeSubmit = values => ({
        ...values,
        homepage: values.isHomepageNews ? HOMEPAGE_NEWS : HOMEPAGE_DEFAULT,
    });

    /**
     * Handle user submit actions.
     */
    handleSubmit = (values) => {
        const { clearGlobalErrors, saveProfile } = this.props;
        clearGlobalErrors();
        this.props.scrollToTop();
        saveProfile(this.formatValuesBeforeSubmit(values));

    };

    /**
     * Handle form reset.
     */
    handleReset = () => {
        this.props.resetFormState();
    };

    /**
     * Render the form area.
     */
    renderForm = (formik) => {
        const {
            globalErrors,
            classifications,
            sources,
            topicCategories,
            setFormTouched,
            setFormToEditMode,
            setFormToEditDefaultMode,
            isTouched,
            isTouchedPrompt,
            isInEditMode,
            isSaving,
            isSubmitTriggered,
            resetFormState,
            isFormToReset,
            handleProfileEdit,
        } = this.props;


        return (
            <ProfileForm
                credentialUpdateFailed={this.props.credentialUpdateFailed}
                newPasswordFailed={this.props.newPasswordFailed}
                section={this.props.match.params.section}
                formik={formik}
                globalErrors={globalErrors}
                classifications={classifications}
                sources={sources}
                topicCategories={topicCategories}
                setFormTouched={setFormTouched}
                setFormToEditMode={setFormToEditMode}
                setFormToEditDefaultMode={setFormToEditDefaultMode}
                setInputRef={this.setInputRef}
                isTouched={isTouched}
                isTouchedPrompt={isTouchedPrompt}
                isInEditMode={isInEditMode}
                isSaving={isSaving}
                isSubmitTriggered={isSubmitTriggered}
                tabNameSelected={this.pageName}
                isFormToReset={isFormToReset}
                resetFormState={resetFormState}
                isPracticeInfoEdit="false"
                handleProfileEdit={handleProfileEdit}
                goBacktoDefault={this.props.savedPreferences}
                togglePreferncesToDefault={this.props.togglePreferncesToDefaultAction}
                getBackToPreviousState={this.props.getBackToPreviousState}
                setFormToOnlyEditMode={this.props.setFormToOnlyEditMode}
                setFormUntouched={this.props.setFormUntouched}
            />
        );
    };

    /**
     * Render this and underlying components.
     */
    render() {
        const { isTopicsLoading, isProfileLoading, isSourcesLoading } = this.props;
        if (isTopicsLoading || isProfileLoading || isSourcesLoading) {
            return <Loader />;
        }

        this.page = (window.location.pathname).split("/");
        this.pageName = this.page[2];

        sessionStorage.setItem('profilePageName', this.pageName);

        const isPracticeInfo = window.location.pathname.indexOf('practice-info') > -1;

        return (
            <StyledProfileSection
                isPracticeInfo={isPracticeInfo}
            >
                <Formik
                    enableReinitialize
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={this.handleSubmit}
                    onReset={this.handleReset}
                    initialValues={this.formatInitialValues(this.props.values)}
                    validate={this.validate}
                >
                    {this.renderForm}
                </Formik>
            </StyledProfileSection>
        );
    }
}

Profile.propTypes = {
    setFormUntouched: PropTypes.func,
    credentialUpdateFailed: PropTypes.bool,
    newPasswordFailed: PropTypes.bool,
    setFormTouched: PropTypes.func.isRequired,
    setFormToEditMode: PropTypes.func.isRequired,
    setFormToEditDefaultMode: PropTypes.func.isRequired,
    saveProfile: PropTypes.func.isRequired,
    resetFormState: PropTypes.func.isRequired,
    getAdvisorClassificationsData: PropTypes.func.isRequired,
    dismissExpiringBookmarksMainBadge: PropTypes.func.isRequired,
    globalErrors: PropTypes.arrayOf(PropTypes.shape).isRequired,
    clearGlobalErrors: PropTypes.func.isRequired,
    resetRequestSource: PropTypes.func.isRequired,
    scrollToTop: PropTypes.func.isRequired,
    hidePopup: PropTypes.func.isRequired,
    getSources: PropTypes.func.isRequired,
    getTopics: PropTypes.func.isRequired,
    resetFormSubmitTrigger: PropTypes.func.isRequired,
    values: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        curPassword: PropTypes.string,
        password: PropTypes.string,
        verifyPassword: PropTypes.string,
        zip: PropTypes.string,
        homepage: PropTypes.string,
        crd: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        iard: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
    }).isRequired,
    classifications: PropTypes.shape({}).isRequired,
    sources: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    topicCategories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    isSaving: PropTypes.bool.isRequired,
    isTouched: PropTypes.bool.isRequired,
    isInEditMode: PropTypes.bool.isRequired,
    isSubmitTriggered: PropTypes.bool.isRequired,
    isProfileLoading: PropTypes.bool.isRequired,
    isTopicsLoading: PropTypes.bool.isRequired,
    isSourcesLoading: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
    email: PropTypes.string.isRequired,
    id: PropTypes.string,
    match: PropTypes.shape({
        params: PropTypes.shape({
            section: PropTypes.string,
        }),
    }),
};

Profile.defaultProps = {
    credentialUpdateFailed: false,
    newPasswordFailed: false,
    match: {
        params: {
            section: null,
        },
    },
};

const mapStateToProps = state => ({
    globalErrors: state.errors.errors,
    credentialUpdateFailed: state.basicProfile.isUpdateFailed,
    newPasswordFailed: state.basicProfile.isPasswordFailed,
    values: {
        firstName: state.basicProfile.firstName,
        lastName: state.basicProfile.lastName,
        email: state.basicProfile.email,
        crd: state.basicProfile.crd,
        iard: state.basicProfile.iard,
        curPassword: '',
        password: '',
        verifyPassword: '',
        zip: state.basicProfile.zip,
        durationId: state.basicProfile.durationId,
        wmAUMId: state.basicProfile.wmAUMId,
        wmHouseholdId: state.basicProfile.wmHouseholdId,
        retirementPlanAUMId: state.basicProfile.retirementPlanAUMId,
        retirementPlanPlansId: state.basicProfile.retirementPlanPlansId,
        aumId: state.basicProfile.aumId,
        amountId: state.basicProfile.amountId,
        serviceOfferingIds: state.basicProfile.serviceOfferingIds,
        sourceIds: state.basicProfile.sourceIds,
        topicIds: state.basicProfile.topicIds,
        homepage: state.basicProfile.homepage,
    },
    classifications: state.advisorClassifications,
    topicCategories: state.topics.all.categories,
    sources: state.sources.profileSources,
    isSaving: state.basicProfileForm.isSaving,
    isTouched: state.basicProfileForm.isTouched,
    isTouchedPrompt: state.basicProfileForm.isTouchedPrompt,
    isInEditMode: state.basicProfileForm.isInEditMode,
    isSubmitTriggered: state.basicProfileForm.isSubmitTriggered,
    isProfileLoading: state.basicProfile.isLoading,
    isFormToReset: state.basicProfileForm.isResetting,
    isTopicsLoading: state.topics.isLoading,
    isSourcesLoading: state.sources.isLoading,
    email: state.basicProfile.email,
    savedPreferences: state.basicProfileForm.savedPreferences,
});

const mapDispatchToProps = dispatch => ({
    setFormUntouched: () => dispatch(setFormUntouched()),
    getAdvisorClassificationsData: () => dispatch(getAdvisorClassifications()),
    clearGlobalErrors: () => dispatch(clearErrors()),
    resetRequestSource: () => dispatch(resetRequestSource()),
    saveProfile: data => dispatch(saveBasicProfileForm(data)),
    scrollToTop: () => dispatch(setScrollToTop()),
    hidePopup: redirect => dispatch(hidePopupAction(redirect)),
    getSources: displayLoader => dispatch(getSourcesAction(displayLoader)),
    getTopics: () => dispatch(getTopicsAction()),
    setFormTouched: () => dispatch(setFormTouchedAction),
    resetFormState: () => dispatch(setFormReset),
    setFormToEditMode: () => dispatch(setFormToEditModeAction),
    setFormToEditDefaultMode: () => dispatch(setFormToEditDefaultModeAction),
    resetFormSubmitTrigger: () => dispatch(resetFormSubmitTriggerAction),
    resetFormState: () => dispatch(resetFormStateAction),
    changePassword: (curPassword, password, verifyPassword) => dispatch(curPassword(curPassword, password, verifyPassword)),
    togglePreferncesToDefaultAction: () => dispatch(togglePreferncesToDefault),
    getBackToPreviousState: () => dispatch(getBackToPreviousState),
    setFormToOnlyEditMode: () => dispatch(setFormToOnlyEditModeAction),
    dismissExpiringBookmarksMainBadge: () => dispatch(dismissExpiringBookmarksNotification(DISMISS_EXPIRING_BOOKMARKS_MAIN_BADGE_STORAGE_KEY)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Profile));
