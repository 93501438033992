import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { styledDotStyles } from 'containers/Guide/ImageMetaphor/styles';

const StyledDot = styled.div`
    ${styledDotStyles}
`;

const Dot = ({ id }) => (
    <StyledDot id={`slide-${id}`} />
);

Dot.propTypes = {
    id: PropTypes.number.isRequired,
};

export default Dot;
