import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
    labelStyles,
    radioInputStyles,
    indicatorStyles,
} from 'components/FieldRadio/styles';

const StyledLabel = styled.label`
    ${labelStyles}
`;
export const StyledRadioInput = styled.input`
    ${radioInputStyles}
`;
const StyledIndicator = styled.span`
    ${indicatorStyles}
`;

class FieldRadio extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isFocused: false,
        };

        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
    }

    handleBlur(event) {
        const { field } = this.props;

        this.setState({
            isFocused: false,
        });

        if (field && field.onBlur) {
            field.onBlur(event);
        }
    }

    handleChange(event) {
        const { field, onChange } = this.props;

        onChange(event);

        if (field && field.onChange) {
            field.onChange(event);
        }
    }

    handleFocus() {
        this.setState({
            isFocused: true,
        });
    }

    render() {
        const {
            className,
            id,
            value,
            field,
            label,
        } = this.props;
        const { ...other } = field;
        const { isFocused } = this.state;
        const fieldName = field.name;
        const fieldId = id || fieldName;
        const checked = value === field.value;

        return (
            <div className={className}>
                <StyledRadioInput
                    {...other}
                    checked={checked}
                    id={fieldId}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    type="radio"
                    value={value}
                />
                <StyledLabel
                    htmlFor={fieldId}
                >
                    <StyledIndicator
                        aria-hidden="true"
                        variant={{
                            checked,
                            isFocused,
                        }}
                    />
                    {label}
                </StyledLabel>
            </div>
        );
    }
}

FieldRadio.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    field: PropTypes.shape({
        name: PropTypes.string,
        onChange: PropTypes.func,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    }).isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

FieldRadio.defaultProps = {
    className: '',
    id: '',
    onChange: () => {},
};

export default FieldRadio;
