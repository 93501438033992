import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SeeMoreLink from 'components/SeeMoreLink/SeeMoreLink';
import TimeAgo from 'components/TimeAgo/TimeAgo';
import Heading from 'components/Heading/Heading';
import CoverImage from 'components/CoverImage/CoverImage';
import SanitizedHTML from 'components/SanitizedHTML/SanitizedHTML';
import language from '../../../lang/en.json';
import {
    postContainerStyles,
    postDetailsStyles,
    postContentStyles,
    separatorStyles,
    categoryTitleStyles,
    headingStyles,
    descriptionStyles,
    linkContainerStyles,
    imageContainerStyles,
} from 'components/Posts/SmallPost/styles';

const StyledPostContainer = styled.article`
    ${postContainerStyles}
`;
const StyledPostDetails = styled.div`
    ${postDetailsStyles}
`;
const StyledSeparator = styled.span`
    ${separatorStyles}
`;
const StyledPostContent = styled.div`
    ${postContentStyles}
`;
const StyledCategoryTitle = styled.span`
    ${categoryTitleStyles}
`;
const StyledHeading = styled(Heading)`
    ${headingStyles}
`;
const StyledDescription = styled(SanitizedHTML)`
    ${descriptionStyles}
`;
const StyledLinkContainer = styled.div`
    ${linkContainerStyles}
`;
const StyledImageContainer = styled.div`
    ${imageContainerStyles}
`;

const SmallPost = (props) => {
    const {
        category,
        publishTimestamp,
        title,
        description,
        link,
        linkText,
        imageAsset,
    } = props;

    const renderImage = () => {
        if (imageAsset) {
            return (
                <StyledImageContainer>
                    <CoverImage                        
                        src={`/api${imageAsset}`}
                        alt={language.translations['global.alt.image']}
                        forLinkCard={false}
                    />
                </StyledImageContainer>
            );
        }

        return null;
    };

    const renderLink = () => {
        if (link && linkText) {
            return (
                <StyledLinkContainer>
                    <SeeMoreLink to={link}>
                        {linkText}
                    </SeeMoreLink>
                </StyledLinkContainer>
            );
        }

        return null;
    };

    return (
        <StyledPostContainer>
            <StyledPostDetails>
                <StyledCategoryTitle>
                    {category}
                </StyledCategoryTitle>
                <StyledSeparator>&middot;</StyledSeparator>
                <TimeAgo
                    fromDate={publishTimestamp}
                />
            </StyledPostDetails>
            <StyledPostContent>
                <StyledHeading level={3}>
                    {title}
                </StyledHeading>
                {renderImage()}
                <StyledDescription
                    htmlContent={description}
                    tag="div"
                />
                {renderLink()}
            </StyledPostContent>
        </StyledPostContainer>
    );
};

SmallPost.defaultProps = {
    imageAsset: null,
    link: null,
    linkText: null,
};

SmallPost.propTypes = {
    category: PropTypes.string.isRequired,
    publishTimestamp: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    link: PropTypes.string,
    linkText: PropTypes.string,
    imageAsset: PropTypes.string,
};

export default SmallPost;
