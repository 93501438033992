import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import anime from 'animejs';

import Stepper from 'components/Stepper/Stepper';

class AnimatedStepper extends React.PureComponent {
    constructor(props) {
        super(props);

        this.handleEndListener = this.handleEndListener.bind(this);
    }

    handleEndListener(node, done) {
        const { isVisible } = this.props;

        if (isVisible) {
            this.animation = anime({
                targets: node,
                easing: [0.53, 0.05, 0.01, 0.97],
                duration: 330,
                translateY: ['100%', '0%'],
                complete: () => {
                    node.removeAttribute('style');
                    done();
                },
            });
        } else {
            this.animation = anime({
                targets: node,
                easing: [0.53, 0.05, 0.01, 0.97],
                duration: 330,
                translateY: ['0%', '100%'],
                complete: () => {
                    node.removeAttribute('style');
                    done();
                },
            });
        }
    }

    render() {
        const { isVisible } = this.props;

        return (
            <Transition
                in={isVisible}
                addEndListener={this.handleEndListener}
                mountOnEnter
                unmountOnExit
                timeout={500}
            >
                <Stepper {...this.props} />
            </Transition>
        );
    }
}

AnimatedStepper.propTypes = {
    isVisible: PropTypes.bool,
};

AnimatedStepper.defaultProps = {
    isVisible: true,
};

export default AnimatedStepper;
