import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GuestsOnlyRoute from 'containers/GuestsOnlyRoute/GuestsOnlyRoute';
import PrivateRoute from 'containers/PrivateRoute/PrivateRoute';

/**
 * Global route switch between private and guest.
 * @param {*} param0 
 */
const RouteSwitch = ({ isAuthenticated, ...otherProps }) => (
    isAuthenticated
        ? <PrivateRoute {...otherProps} skipOnboardedCheck />
        : <GuestsOnlyRoute {...otherProps} />
);

RouteSwitch.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    isAuthenticated: state.signIn.isAuthenticated,
});

export default connect(mapStateToProps, null)(RouteSwitch);
