import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import moment from 'moment';
import Link from 'components/atomics/atoms/Link/Link';
import Title from 'components/atomics/atoms/Title/Title';
import {
    containerStyles,
    headerStyles,
    paddedContainerStyles,
    listHeaderStyles,
    listHeaderLabelStyles,
    listHeaderLeftStyles,
    listHeaderRightStyles,
    previousButtonStyles,
    nextButtonStyles,
    listContainerStyles,
    listRowStyles,
    djLogoContainerStyles,
    mainContainerStyles,
    labelClick,
} from '../../../components/MarketBriefing/archive/styles';
import storage from 'utils/store';
import {
    IconSeeMore,
    IconStepperPrevious,
    IconStepperNext,
    DJLogo,
} from 'components/atomics/atoms/Icons/Icons';
import { TYPE_AM } from 'components/MarketBriefing/constants';
import labels from '../../../lang/en.json';
import BackToLink from "../../BackToLink/BackToLink";
import { NEWS_ROUTE } from "../../../state-management/constants/news";
import { wrapBackButtonLabels } from "../../../utils/urlUtils";
import { connect } from "react-redux";
import { HistoryManager } from "../../../utils/historyManager";

const MARKET_BRIEFINGS_PAGE = 'marketBreifings/page';

const DJLogoContainer = styled.div`
    ${djLogoContainerStyles}
`;

const StyledListRow = styled.div`
    ${listRowStyles}
`;

const Header = styled.div`
`;

const StyledListContainer = styled.div`
    ${listContainerStyles}
`;

const StyledPreviousButton = styled.button`
    ${previousButtonStyles}
`;

const StyledNextButton = styled.button`
    ${nextButtonStyles}
`;

const StyledListheader = styled.div`
    ${listHeaderStyles}
`;

const StyledListHeaderLabel = styled.div`
    ${listHeaderLabelStyles}
`;

const StyledListHeaderLeft = styled.div`
    ${listHeaderLeftStyles}
`;

const StyledListHeaderRight = styled.div`
    ${listHeaderRightStyles}
`;

export const StyledContainer = styled.div`
    ${containerStyles}
`;

export const StyledHeader = styled.div`
    ${headerStyles}
`;

export const StyledPaddedContainer = styled.div`
    ${paddedContainerStyles}
`;

const MainContainer = styled.section`
    ${mainContainerStyles}
`;

const LabelClick = styled.span`
    ${labelClick}
`;

/**
 * Market Briefing archive page component,
 * displays a weekly page-able summary of evening and morning briefings.
 */
class MarketBriefingArchive extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.state = {
            today: moment(),
            week: moment().startOf('week'),
        };

        this.gotoPreviousWeek = this.gotoPreviousWeek.bind(this);
        this.gotoNextWeek = this.gotoNextWeek.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.savePage = this.savePage.bind(this);
    }

    /**
     * Find if we have a saved week (to support back logic),
     * if so apply and dispose.
     */
    componentDidMount() {
        const weekValue = storage.get(MARKET_BRIEFINGS_PAGE);

        if (weekValue
            && window.location.href.indexOf('?b=1') > -1) {
            this.setState({
                week: moment(weekValue).startOf(),
            });
        }

        storage.remove(MARKET_BRIEFINGS_PAGE);
    }

    /**
     * Saves the current week selected so we can use it back button logic from briefing display page.
     */
    savePage() {
        storage.set(MARKET_BRIEFINGS_PAGE, this.state.week);
    }

    /**
     * Goto previous week.
     */
    gotoPreviousWeek() {
        const { week } = this.state;

        this.setState({
            week: week.subtract(7, 'days'),
        });
    }

    /**
     * Goto next week.
     */
    gotoNextWeek() {
        const { week } = this.state;

        this.setState({
            week: week.add(7, 'days'),
        });
    }

    /**
     * Render the week titling and paginagtion controls.
     */
    renderWeekHeader() {
        const { today, week } = this.state;
        const fromDate = moment(today).startOf('week').subtract(1, 'day');
        const toDate = moment(today).endOf('week');
        const thisWeek = week.isBetween(fromDate, toDate);
        const label = thisWeek
            ? labels.translations['marketBriefing.archive.currentWeekLabel']
            : `${moment(week).add(1, 'day').format('MMM D')} - ${moment(week).add(5, 'day').format('MMM D')}`;
        const itemsInRangeAfter = this.props.marketBriefings.filter(
            item => moment(item.published_at).isAfter(week.endOf('week')),
        );
        const itemsInRangeBefore = this.props.marketBriefings.filter(
            item => moment(item.published_at).isBefore(week.startOf('week')),
        );
        const hasNextWeek = itemsInRangeAfter.length > 0;
        const hasPrevWeek = itemsInRangeBefore.length > 0;

        return (
            <StyledListheader>
                <StyledListHeaderLeft>
                    <StyledPreviousButton
                        disabled={!hasPrevWeek}
                        type="button"
                        id="previousButton"
                        onClick={this.gotoPreviousWeek}
                        data-analytics-placement="Anchor : anchor"
                        data-analytics-label="trackLink : anchor"
                        data-analytics-id={"Prev: " + "Previous Week"}
                    >
                        <IconStepperPrevious data-rel="mobile" />
                        <IconSeeMore data-rel="desktop" />
                        <span>
                            <FormattedMessage id="marketBriefing.archive.previousLabel" />
                        </span>
                    </StyledPreviousButton>
                </StyledListHeaderLeft>
                <StyledListHeaderLabel>
                    {label}
                </StyledListHeaderLabel>
                <StyledListHeaderRight>
                    <StyledNextButton
                        disabled={!hasNextWeek}
                        type="button"
                        id="nextButton"
                        onClick={this.gotoNextWeek}
                        data-analytics-placement="Anchor : anchor"
                        data-analytics-label="trackLink : anchor"
                        data-analytics-id={"Next: " + "Next week"}
                    >
                        <span>
                            <FormattedMessage id="marketBriefing.archive.nextLabel" />
                        </span>
                        <IconStepperNext data-rel="mobile" />
                        <IconSeeMore data-rel="desktop" />
                    </StyledNextButton>
                </StyledListHeaderRight>
            </StyledListheader>
        );
    }

    /**
     * Render a single days row.
     * @param {*} items
     */
    renderDay(items, keyIndex) {
        if (items.length) {
            const morning = items.filter(item => item.type === TYPE_AM);
            const afternoon = items.filter(item => item.type !== TYPE_AM);
            const isToday = this.state.today.format('dddd, MMM D') === moment(items[0].published_at).format('dddd, MMM D');
            let morningNode = (<span><FormattedMessage id="marketBriefing.archive.amLabel" /></span>);
            let afternoonNode = (<span><FormattedMessage id="marketBriefing.archive.pmLabel" /></span>);

            if (morning.length) {
                morningNode = (
                    <Link
                        id="morningBriefing"
                        onClick={this.savePage}
                        to={`/marketBriefing/${morning[0].id}`}
                    >
                        <LabelClick>
                            <FormattedMessage id="marketBriefing.archive.amLabel" />
                        </LabelClick>
                    </Link>
                );
            }

            if (afternoon.length) {
                afternoonNode = (
                    <Link
                        id="morningBriefing"
                        onClick={this.savePage}
                        to={`/marketBriefing/${afternoon[0].id}`}
                    >
                        <LabelClick>
                            <FormattedMessage id="marketBriefing.archive.pmLabel" />
                        </LabelClick>
                    </Link>
                );
            } else {
                afternoonNode = (
                    <button
                        disabled
                        type="button"
                    >
                        <FormattedMessage id="marketBriefing.archive.pmLabel" />
                    </button>
                );
            }


            return (
                <StyledListRow key={keyIndex} isToday={isToday.toString()}>
                    <div>
                        {moment(items[0].published_at).format('dddd, MMM D')}
                    </div>
                    <div>
                        {morningNode}
                        {afternoonNode}
                    </div>
                </StyledListRow>
            );
        }
        return null;
    }

    /**
     * Render the day rows for the selected week.
     */
    renderDays() {
        const { week } = this.state;
        const fromDate = moment(week).startOf('week');
        const toDate = moment(week).endOf('week');
        const itemsInRange = this.props.marketBriefings.filter(
            item => moment(item.published_at).isBetween(fromDate, toDate),
        );
        const startingDate = moment(fromDate);
        const fragments = [];

        let keyIndex = 1;

        while (startingDate < toDate) {
            const items = itemsInRange.filter(item => startingDate.isSame(item.published_at));
            fragments.push(
                this.renderDay(items, keyIndex),
            );
            keyIndex += 1;
            startingDate.add(1, 'day');
        }

        return (
            <StyledListContainer>
                {fragments.reverse()}
            </StyledListContainer>
        );
    }

    /**
     * Render the DJ logo.
     */
    renderLogo() {
        return (
            <DJLogoContainer>
                <DJLogo />
            </DJLogoContainer>
        );
    }

    /**
     * Render a weeks worth of briefings/summaries.
     */
    renderWeek() {
        return (
            <StyledPaddedContainer>
                {this.renderWeekHeader()}
                {this.renderDays()}
                {this.renderLogo()}
            </StyledPaddedContainer>
        );
    }

    /**
     * Handle back button flow
     */
    handleBack() {
        const { urlHistoryList } = this.props;
        const historyItem = urlHistoryList.length - 2 >= 0
            ? urlHistoryList[urlHistoryList.length - 2]
            : null;

        if (historyItem == null) return;

        const url = historyItem.currentUrl.includes(window.location.origin)
            ? historyItem.currentUrl.replace(window.location.origin, '')
            : historyItem.currentUrl;

        HistoryManager.sentHistoryEvent(url);
    }

    /**
     * Render the main component.
     */
    render() {
        const {
            urlHistoryList
        } = this.props;

        const backLabel = (urlHistoryList.length - 2) >= 0 ? urlHistoryList[urlHistoryList.length - 2] : null;

        if (this.props.marketBriefings) {
            return (
                <MainContainer>
                    <StyledContainer>
                        <StyledHeader>
                            <StyledPaddedContainer>
                                <Header>
                                    {
                                        backLabel &&
                                        <BackToLink
                                            to={backLabel !== null ? backLabel.currentUrl : NEWS_ROUTE}
                                            goBack={this.handleBack}
                                            customLabel={wrapBackButtonLabels(backLabel)}
                                            isDisable={backLabel == null}
                                        />
                                    }
                                </Header>
                                <Title
                                    resourceId="marketBriefing.archive.title"
                                />
                                <p>
                                    <FormattedMessage id="marketBriefing.archive.description" />
                                </p>
                            </StyledPaddedContainer>
                        </StyledHeader>
                        {this.renderWeek()}
                    </StyledContainer>
                </MainContainer>
            );
        }

        return null;
    }
}

MarketBriefingArchive.propTypes = {
    marketBriefings: PropTypes.arrayOf(PropTypes.shape({})),
};

MarketBriefingArchive.defaultProps = {
    marketBriefings: [],
};

const mapStateToProps = (state) => ({
    urlHistoryList: state.UrlHistoryReducer.history
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketBriefingArchive)
