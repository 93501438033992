import React from 'react';
import PropTypes from 'prop-types';

import JargonItem from 'components/Jargon/JargonItem';

const Jargon = props => (
    props.data.map(term => (
        <JargonItem key={term.id} data={term} id={`jargon-${term.id}`} />)));

Jargon.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        jargon: PropTypes.string,
        translation: PropTypes.string,
    })).isRequired,
    id: PropTypes.string.isRequired,
};
export default Jargon;