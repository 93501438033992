
export function getArticleUrl(article, extend = false, modalData = null) {
    /* cognitive urls */
    const isIdNumeric = /^\d+$/.test(article.id);

    if (typeof (article.id) === 'string' && !isIdNumeric && article.type !== 'GUIDE_IN_PRACTICE' && article.type !== 'GUIDE_STANDARD' && article.type !== 'GUIDE_MC_KINSEY') {
        let linkUrl = article.licensed ? `/articles/${btoa(article.id)}/${article.interactionId}/${article.contentType}/${article.interactionType}`
            : article.url;
        if (extend && article.licensed == false && (modalData.find(a => a.includes(article.id)))) {
            let newIndex = (modalData.find(a => a.includes(article.id))).slice(-1);
            linkUrl = linkUrl + "&section=" + article.modelDisplayName + "Tile" + newIndex;
        }
        return linkUrl;
    }

    /* all others */
    let linkUrl;

    if (article.type === 'GUIDE_STANDARD' || article.type === 'GUIDE_MC_KINSEY' || article.type === 'GUIDE_IN_PRACTICE') {
        linkUrl = `/learn/ideas/${article.link}`;
    } else {
        linkUrl = article.url && article.url.length && !article.licensed
            ? article.url : `/articles/${article.id}/${article.interactionId}/${article.contentType}/${article.interactionType}`;
    }

    return linkUrl;
}

/**
 * Get the most appropriately sized image for a given article.
 * @param {*} article 
 * @param {*} defaultSizeIndex
 */
export function getArticleImage(article, defaultSizeIndex = -1) {
    const isExternal = new RegExp("^(http|https)://", "i");
    const hasApiPrefix = new RegExp("^/api/", "i");
    const hasImagesPrefix = new RegExp("^/images/", "i");

    let image = article.image;

    if (defaultSizeIndex !== -1 && article.mediaLinks && article.mediaLinks.length > defaultSizeIndex) {
        image = article.mediaLinks[defaultSizeIndex].link;

        if (article.mediaLinks[defaultSizeIndex].type === "LOGO") {
            if (defaultSizeIndex > 0) {
                image = article.mediaLinks[defaultSizeIndex - 1].link;
            }
        }
    }

    return image
        ? (isExternal.test(image) || hasImagesPrefix.test(image))
            ? image
            : hasApiPrefix.test(image)
                ? image
                : '/api' + image
        : '/images/gradient.png';
}
