import {
    SIGN_UP, SET_SUBMITTING, SET_ERRORS,
    SET_SUCCESS, RESEND_EMAIL, CONFIRM_EMAIL,
    CONFIRM_EMAIL_SUCCESS, CONFIRM_EMAIL_FAIL,
    RESEND_EMAIL_COMPLETE, CHANGE_EMAIL,
    CHANGE_EMAIL_FAIL, CHANGE_EMAIL_SUCCESS,
    RESEND_EMAIL_FAIL,
} from 'state-management/constants/signUp';

export function resendEmail(email, oldEmail) {
    return {
        type: RESEND_EMAIL,
        email,
        oldEmail,
    }
}

export function changeEmail(email, oldEmail, password) {
    return {
        type: CHANGE_EMAIL,
        email,
        oldEmail,
        password,
    }
}

export function changeEmailSuccess(updateTime) {
    return {
        type: CHANGE_EMAIL_SUCCESS,
        updateTime,
    }
}

export function changeEmailFail() {
    return {
        type: CHANGE_EMAIL_FAIL,
    }
}

export function resendEmailComplete() {
    return {
        type: RESEND_EMAIL_COMPLETE,
    }
}

export function resendEmailFail() {
    return {
        type: RESEND_EMAIL_FAIL,
    }
}

export function confirmEmail(token, email) {
    return {
        type: CONFIRM_EMAIL,
        token,
        email,
    }
}

export function confirmEmailSuccess() {
    return {
        type: CONFIRM_EMAIL_SUCCESS,
    }
}

export function confirmEmailFail() {
    return {
        type: CONFIRM_EMAIL_FAIL,
    }
}

export function signUp(values, actions, identityData, isWebinar = false, webinarData) {
    return {
        type: SIGN_UP,
        values,
        identityData,
        isWebinar,
        webinarData,
    };
}

export function setSubmitting(isSubmitting) {
    return {
        type: SET_SUBMITTING,
        isSubmitting,
    };
}

export function setSuccess(isSuccess) {
    return {
        type: SET_SUCCESS,
        isSuccess,
    };
}

export function setErrors(errors = []) {
    return {
        type: SET_ERRORS,
        errors,
    };
}

export function resetErrors() {
    return {
        type: SET_ERRORS,
        errors: [],
    };
}
