import {
    SET_SUBMITTING,
    SET_ERRORS,
    SET_SUCCESS,
    CONFIRM_EMAIL,
    CHANGE_EMAIL,
    CHANGE_EMAIL_FAIL,
    CHANGE_EMAIL_SUCCESS,
    CONFIRM_EMAIL_SUCCESS,
    CONFIRM_EMAIL_FAIL,
    RESEND_EMAIL_COMPLETE,
    RESEND_EMAIL,
    RESEND_EMAIL_FAIL,
} from 'state-management/constants/signUp';

const initialState = {
    isSubmitting: false,
    isSuccess: false,
    errors: [],
    isConfirming: false,
    isConfirmed: false,
    resendEmailComplete: false,
    lastChangeTime: null,
    changeEmailFailed: false,
    resendEmailFail: false,
};

function signOutReducer(state = initialState, action = {}) {
    switch (action.type) {
    case RESEND_EMAIL_COMPLETE:
        return {
            ...state,
            resendEmailComplete: true,
            resendEmailFail: false,
        }
    case RESEND_EMAIL_FAIL:
        return {
            ...state,
            resendEmailComplete: false,
            resendEmailFail: true,
        }
    case RESEND_EMAIL:
        return {
            ...state,
            resendEmailComplete: false,
            changeEmailFailed: false,
            lastChangeTime: null,
        }
    case CHANGE_EMAIL:
        return {
            ...state,
            changeEmailFailed: false,
        }
    case CHANGE_EMAIL_SUCCESS:
        return {
            ...state,
            lastChangeTime: action.updateTime,
            changeEmailFailed: false,
        }
    case CHANGE_EMAIL_FAIL:
        return {
            ...state,
            lastChangeTime: 500,
            changeEmailFailed: true,
        }
    case CONFIRM_EMAIL: 
        return {
            ...state,
            isConfirming: true,
        };
    case CONFIRM_EMAIL_FAIL:
        return {
            ...state,
            isConfirming: false,
            isConfirmed: false,
        }
    case CONFIRM_EMAIL_SUCCESS: 
        return {
            ...state,
            isConfirming: false,
            isConfirmed: true,
        }
    case SET_SUBMITTING:
        return {
            ...state,
            isSubmitting: action.isSubmitting,
        };
    case SET_SUCCESS:
        return {
            ...state,
            isSuccess: action.isSuccess,
        };
    case SET_ERRORS:
        return {
            ...state,
            errors: action.errors || [],
        };

    default:
        return state;
    }
}

export default signOutReducer;
