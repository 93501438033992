import {
    all, call, put, takeLatest,
} from 'redux-saga/effects';
import { TYPES } from 'containers/Toasts/constants';
import { addToasts } from 'state-management/actions/toasts';
import Api from 'utils/api';
import {
    FOLLOW_TOPIC_URL,
    TOGGLE_FOLLOW_TOPIC,
} from 'state-management/constants/followTopic';
import {
    toggleTopicFollowFailure,
    toggleTopicFollowSuccess,
} from 'state-management/actions/followTopic';
import { followTopic, unfollowTopic } from 'state-management/actions/basicProfile';
import { REFINEMENT_CARD_URL } from "../constants/profileRefinementCard";
import { getProfileRefinementCardSuccess} from "../actions/profileRefinementCard";
import { delay } from 'redux-saga/effects';
//TODO: replace old purposed failure
/**
 * Handle limit reached toast display.
 * @param {*} toastType
 */
function* handleShowToast(toastType) {
    yield put(addToasts([{
        toastType,
    }]));
}

/**
 * Follow/unfollow topic.
 * @param {*} param0
 */
function* toggleTopicFollow({ id, isFollowing, typeValue, showToast }) {
    try {
        const { data } = yield call(isFollowing ? Api.delete : Api.post, FOLLOW_TOPIC_URL(id));
        const response = typeValue != null ? yield call(Api.post, REFINEMENT_CARD_URL, {
            data: {
                type:typeValue,
                id,
            },
        }) : null;
        if (isFollowing) {
            if (!data.userUnfollowLimitReached) {
                if (showToast) {
                    yield call(handleShowToast, TYPES.UNFOLLOW_TOPIC);
                }
                yield put(unfollowTopic(id));
            } else {
                yield call(handleShowToast, TYPES.TOPIC_LIMIT_REACHED);
            }
        } else {
            yield put(followTopic(id));

            if (showToast) {
                yield call(handleShowToast, TYPES.FOLLOW_TOPIC);
            }

            if (response != null) {
                yield delay(2500);
                yield put(getProfileRefinementCardSuccess({
                    ...response.data,
                    hasSuggestion: response.data != null
                }));
            }
        }

        yield put(toggleTopicFollowSuccess());
    } catch (e) {
        yield put(toggleTopicFollowFailure());
    }
}

function* toggleFollowTopicSaga() {
    yield all([
        takeLatest(TOGGLE_FOLLOW_TOPIC, toggleTopicFollow),
    ]);
}

export default toggleFollowTopicSaga;
