import React, { useCallback } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { CrossIcon } from 'components/atomics/atoms/Icons'
import { closeButtonStyles } from './styles';

const StyledCloseButton = styled.a`
    ${closeButtonStyles}
`;

const ClosableModal = (props) => {
    const { children, onRequestClose, ...rest } = props;

    const close = useCallback(() => {
        if (onRequestClose) onRequestClose();
    }, [onRequestClose]);

    return (
        <Modal onRequestClose={close} { ...rest }>
            <>
                <StyledCloseButton onClick={close}>
                    <CrossIcon />
                </StyledCloseButton>
                { children }
            </>
        </Modal>
    );
};

export default ClosableModal;
