import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import {
    DISCLAIMER_STANDARD,
    DISCLAIMER_SECONDARY,
    DISCLAIMER_TERTIARY,
} from 'state-management/constants/globalDisclaimer';
import { ROUTES } from 'containers/App/constants';
import ConfirmEmail from 'containers/EmailVerification/ConfirmEmail/ConfirmEmail';
import VerifyEmail from 'containers/EmailVerification/VerifyEmail/VerifyEmail';
import ConnectedApplicationRoute from 'containers/ApplicationRoute/ApplicationRoute';
import HomepageRoute from 'containers/HomepageRoute/HomepageRoute';
import PrivateRoute from 'containers/PrivateRoute/PrivateRoute';
import GuestsOnlyRoute from 'containers/GuestsOnlyRoute/GuestsOnlyRoute';
import RouteSwitch from 'containers/RouteSwitch/RouteSwitch';
import { contextHubRecordPageView } from "../../utils/contextHub";
import LayoutSwitch from 'containers/LayoutSwitch/LayoutSwitch';
import Migrate from 'containers/migrate/Migrate';
import {
    internalServerErrorMessages,
    notFoundErrorMessages,
} from 'components/PageError/PageErrorMessages';
import storage from 'utils/store';

// Layouts
import {
    withBaseLayout,
    withLandingLayout,
    Loader,
} from 'components';
import withPrivateLayout from 'containers/PrivateLayout/PrivateLayout';

// Containers
import HomeContainer from 'containers/Home/HomeContainer';
import LandingContainer from 'containers/Landing/Landing';
import ProfileContainer from 'containers/Profile/Profile';
import SignInContainer from 'containers/SignIn/SignIn';
import WebinarLanding from 'containers/WebinarLanding/WebinarLanding';
import SignOutContainer from 'containers/SignOut/SignOut';
import GuideContainer from 'containers/Guide/Guide';
import GuidesContainer from 'containers/Guides/Guides';
import NewsContainer from 'containers/News/News';
import BloombergFullScreenContainer from 'containers/News/BloombergFullScreen';
import MarketBriefingPage from 'containers/MarketBriefing/pageContainers/MarketBriefingPage';
import MarketBriefingArchivePage from 'containers/MarketBriefing/pageContainers/MarketBriefingArchivePage';
import PageableNewsContainer from 'containers/PageableNews/PageableNewsContainer';
import TopicLandingContainer from 'containers/PageableNews/TopicLandingContainer';
import TrendingLandingContainer from 'containers/PageableNews/TrendingLandingContainer';
import GuidesAndFeaturedContentContainer from 'containers/PageableNews/GuidesAndFeaturedContentContainer';
import CollectionsContainer from 'containers/PageableNews/CollectionsContainer';
import SourceLandingContainer from 'containers/PageableNews/SourceLandingContainer';
import ToolsContainer from 'containers/PracticeManagement/Tools/Tools';
import CECredits from 'containers/PageableNews/CECreditsContainer';
import SearchContainer from 'containers/Search/Search';
import PageErrorContainer from 'components/PageError/PageError';
import ScrollContainer from 'containers/Scroll/Scroll';
import OnboardingContainer from 'containers/Onboarding/Onboarding';
import { reauthenticate } from 'state-management/actions/signIn';
import ResetPassword from 'containers/ResetPassword/ResetPassword';
import ContactUsContainer from 'containers/ContactUs/ContactUs';
import PrivacyPolicyContainer from 'containers/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditionsContainer from 'containers/TermsAndConditions/TermsAndConditions';
import AboutLuminContainer from 'containers/AboutLumin/AboutLumin';
import ConnectedToasts from 'containers/Toasts/Toasts';
import ConnectedAriaNotification from 'containers/AriaNotification/AriaNotification';
import ArticleContainer from 'containers/Article/Article';
import DownloadContainer from 'containers/Download/Download';
import TopicsContainer from 'containers/News/Topics/Topics';
import CollectionContainer from 'containers/Guides/CollectionContainer/CollectionContainer';
import { HistoryManager } from "../../utils/historyManager";
import WebinarLandingError from "../WebinarLanding/WebinarLandingError";
import ChooseSources from "../ChooseSources/ChooseSources";

// Pages
const Home = withPrivateLayout(HomeContainer);
const Landing = withLandingLayout(LandingContainer);
const SignIn = withBaseLayout(SignInContainer);
const WebinarLandingPage = LayoutSwitch(WebinarLanding);
const SignOut = withBaseLayout(SignOutContainer);
const Profile = withPrivateLayout(ProfileContainer);
const Guide = withPrivateLayout(GuideContainer);
const Guides = withPrivateLayout(GuidesContainer);
const News = withPrivateLayout(NewsContainer);
const Tools = withPrivateLayout(ToolsContainer);
const Credits = withPrivateLayout(CECredits);
const Topics = withPrivateLayout(TopicsContainer);
const MigratePage = withPrivateLayout(Migrate);
const BloombergFullScreen = withPrivateLayout(BloombergFullScreenContainer);
const MarketBriefingPageDisplay = withPrivateLayout(MarketBriefingPage);
const MarketBriefingArchivePageDisplay = withPrivateLayout(MarketBriefingArchivePage);
const PageableNews = withPrivateLayout(PageableNewsContainer);
const TopicLanding = withPrivateLayout(TopicLandingContainer);
const TrendingLanding = withPrivateLayout(TrendingLandingContainer);
const GuidesAndContentLanding = withPrivateLayout(GuidesAndFeaturedContentContainer);
const CollectionsLanding = withPrivateLayout(CollectionsContainer);
const SourceLanding = withPrivateLayout(SourceLandingContainer);
const Onboarding = withBaseLayout(OnboardingContainer, ROUTES.ONBOARDING_PICK_SOURCES);
const ForgotPassword = withBaseLayout(ResetPassword);
const Search = withPrivateLayout(SearchContainer);
const ContactUs = LayoutSwitch(ContactUsContainer);
const PrivacyPolicy = LayoutSwitch(PrivacyPolicyContainer);
const TermsAndConditions = LayoutSwitch(TermsAndConditionsContainer);
const AboutLumin = LayoutSwitch(AboutLuminContainer);
const WebinarError = withPrivateLayout(WebinarLandingError);
const NotFoundError = LayoutSwitch(PageErrorContainer, notFoundErrorMessages);
const InternalServerError = LayoutSwitch(PageErrorContainer, internalServerErrorMessages);
const Article = withPrivateLayout(ArticleContainer);
const Download = LayoutSwitch(DownloadContainer);
const ConfirmEmailPage = LayoutSwitch(ConfirmEmail);
const VerifyEmailPage = LayoutSwitch(VerifyEmail);
const CollectionLanding = withPrivateLayout(CollectionContainer);
const ChooseSourcesPage = withPrivateLayout(ChooseSources);

//const WEBINAR_PATH = "/webinar/";
const STORE_SHOW_NEWUSER_VERSION = 'showNewUserVersion';

/**
 * Our application container and all routing declarations to child containers.
 */
class App extends React.Component {
    /**
     * Check users authentication status and reroute appropriately.
     */
    componentWillMount() {
        this.props.tryToReauthenticate();
    }

    /**
     * Begin history and analytics tracking.
     */
    componentDidMount() {
        //document the page load
        new HistoryManager(this.props.history);

        let currentPath = this.props.history.location.pathname;

        if (currentPath !== "/search/" && !storage.get('signOutAction') && currentPath !== "/onboarding/pick-sources" && currentPath !== "/news") {
            window.addEventListener('DOMContentLoaded', () => {
                contextHubRecordPageView(this.props.history.location.pathname);
            });
        }
        if (storage.get('signOutAction')) {
            storage.set('signOutAction', false);
        }
        console.log('BRANCH API BEIGN USED');
    }

    /**
     * Get the proper disclaimer type for webinar landing page display.
     */
    getWebinarDisclaimerType() {
        let disclaimerType = DISCLAIMER_TERTIARY;
        /*  let isNewUser = this.props.isAuthenticated
             ? (this.props.webinarMeta && !this.props.webinarMeta.advisorId) ?
                 true : false
             : true; */

        let isNewUser = this.props.isAuthenticated
            ? false
            : true;


        if (isNewUser) {
            disclaimerType = DISCLAIMER_STANDARD;
        }
        return disclaimerType;
    }

    /**
     * Render this and underlying components.
     */
    render() {
        if (this.props.isReauthenticating && window.location.pathname !== "/sign-out") {
            return <Loader />;
        }

        return (
            <React.Fragment>
                <ScrollContainer />
                <ConnectedRouter history={this.props.history}>
                    <Route
                        render={() => (
                            <React.Fragment>
                                <Switch>
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.NOT_FOUND}
                                        component={NotFoundError}
                                        routeComponent={RouteSwitch}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.SERVER_ERROR}
                                        component={InternalServerError}
                                        routeComponent={RouteSwitch}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.CHOOSE_SOURCES}
                                        component={ChooseSourcesPage}
                                        routeComponent={RouteSwitch}
                                        disclaimerType={DISCLAIMER_STANDARD}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.CONFIRM_EMAIL}
                                        component={ConfirmEmailPage}
                                        routeComponent={GuestsOnlyRoute}
                                        disclaimerType={DISCLAIMER_STANDARD}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.VERIFY_EMAIL}
                                        component={VerifyEmailPage}
                                        routeComponent={GuestsOnlyRoute}
                                        disclaimerType={DISCLAIMER_STANDARD}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.SIGN_IN}
                                        component={SignIn}
                                        routeComponent={GuestsOnlyRoute}
                                        disclaimerType={DISCLAIMER_STANDARD}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.SIGN_IN_WITH_SECTION}
                                        component={SignIn}
                                        routeComponent={GuestsOnlyRoute}
                                        disclaimerType={DISCLAIMER_STANDARD}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.WEBINAR_LANDING}
                                        component={WebinarLandingPage}
                                        routeComponent={RouteSwitch}
                                        disclaimerType={this.getWebinarDisclaimerType()}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.WEBINAR_ERROR}
                                        component={WebinarError}
                                        routeComponent={RouteSwitch}
                                        disclaimerType={DISCLAIMER_STANDARD}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.FORGOT_PASSWORD}
                                        component={ForgotPassword}
                                        routeComponent={GuestsOnlyRoute}
                                        disclaimerType={DISCLAIMER_STANDARD}
                                    />
                                    <Route
                                        exact
                                        path={ROUTES.SIGN_OUT}
                                        component={SignOut}
                                        disclaimerType={DISCLAIMER_STANDARD}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.DOWNLOAD}
                                        component={Download}
                                        routeComponent={RouteSwitch}
                                        disclaimerType={DISCLAIMER_TERTIARY}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.MIGRATE}
                                        component={MigratePage}
                                        routeComponent={PrivateRoute}
                                        disclaimerType={DISCLAIMER_STANDARD}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.SAVES}
                                        component={Profile}
                                        routeComponent={PrivateRoute}
                                        disclaimerType={DISCLAIMER_SECONDARY}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.PROFILE}
                                        component={Profile}
                                        routeComponent={PrivateRoute}
                                        disclaimerType={DISCLAIMER_TERTIARY}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.PROFILE_SECTION}
                                        component={Profile}
                                        routeComponent={PrivateRoute}
                                        disclaimerType={DISCLAIMER_TERTIARY}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.PROFILE_SECTION_SUBSECTION}
                                        component={Profile}
                                        routeComponent={PrivateRoute}
                                        disclaimerType={DISCLAIMER_TERTIARY}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.HOME}
                                        component={Home}
                                        routeComponent={PrivateRoute}
                                        disclaimerType={DISCLAIMER_TERTIARY}
                                    />
                                    <ConnectedApplicationRoute
                                        path={ROUTES.ONBOARDING}
                                        component={Onboarding}
                                        routeComponent={PrivateRoute}
                                        disclaimerType={DISCLAIMER_STANDARD}
                                        skipOnboardedCheck
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.GUIDES}
                                        component={Guides}
                                        routeComponent={PrivateRoute}
                                        disclaimerType={DISCLAIMER_TERTIARY}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.NEWS}
                                        component={News}
                                        routeComponent={PrivateRoute}
                                        disclaimerType={DISCLAIMER_SECONDARY}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.TOOLS}
                                        component={Tools}
                                        routeComponent={PrivateRoute}
                                        disclaimerType={DISCLAIMER_TERTIARY}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.ALL_TOPICS_PAGE}
                                        component={Topics}
                                        routeComponent={PrivateRoute}
                                        disclaimerType={DISCLAIMER_SECONDARY}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.BLOOMBERG_FULL_SCREEN}
                                        component={BloombergFullScreen}
                                        routeComponent={PrivateRoute}
                                        disclaimerType={DISCLAIMER_SECONDARY}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.MARKET_BRIEFING}
                                        component={MarketBriefingPageDisplay}
                                        routeComponent={PrivateRoute}
                                        disclaimerType={DISCLAIMER_SECONDARY}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.MARKET_BRIEFING_ARCHIVE}
                                        component={MarketBriefingArchivePageDisplay}
                                        routeComponent={PrivateRoute}
                                        disclaimerType={DISCLAIMER_SECONDARY}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.PAGEABLE_NEWS}
                                        component={PageableNews}
                                        routeComponent={PrivateRoute}
                                        disclaimerType={DISCLAIMER_SECONDARY}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.CECREDITS}
                                        component={Credits}
                                        routeComponent={PrivateRoute}
                                        disclaimerType={DISCLAIMER_TERTIARY}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.TOPIC_LANDING_PAGE}
                                        component={TopicLanding}
                                        routeComponent={PrivateRoute}
                                        disclaimerType={DISCLAIMER_SECONDARY}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.TRENDING_TOPICS_PAGE}
                                        component={TrendingLanding}
                                        routeComponent={PrivateRoute}
                                        disclaimerType={DISCLAIMER_SECONDARY}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.GUIDES_AND_FEATURED_CONTENT_PAGE}
                                        component={GuidesAndContentLanding}
                                        routeComponent={PrivateRoute}
                                        disclaimerType={DISCLAIMER_TERTIARY}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.COLLECTIONS}
                                        component={CollectionsLanding}
                                        routeComponent={PrivateRoute}
                                        disclaimerType={DISCLAIMER_TERTIARY}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.COLLECTION}
                                        component={CollectionLanding}
                                        routeComponent={PrivateRoute}
                                        disclaimerType={DISCLAIMER_TERTIARY}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.COLLECTION_PM}
                                        component={CollectionLanding}
                                        routeComponent={PrivateRoute}
                                        disclaimerType={DISCLAIMER_TERTIARY}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.SOURCE_LANDING_PAGE}
                                        component={SourceLanding}
                                        routeComponent={PrivateRoute}
                                        disclaimerType={DISCLAIMER_SECONDARY}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.GUIDE}
                                        component={Guide}
                                        routeComponent={PrivateRoute}
                                        disclaimerType={DISCLAIMER_TERTIARY}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.SEARCH}
                                        component={Search}
                                        routeComponent={PrivateRoute}
                                        disclaimerType={DISCLAIMER_SECONDARY}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.PRIVACY_POLICY}
                                        component={PrivacyPolicy}
                                        routeComponent={RouteSwitch}
                                        disclaimerType={DISCLAIMER_STANDARD}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.CONTACT_US}
                                        component={ContactUs}
                                        routeComponent={RouteSwitch}
                                        disclaimerType={DISCLAIMER_TERTIARY}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.TERMS_AND_CONDITIONS}
                                        component={TermsAndConditions}
                                        routeComponent={RouteSwitch}
                                        disclaimerType={DISCLAIMER_STANDARD}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.ABOUT_LUMIN}
                                        component={AboutLumin}
                                        routeComponent={RouteSwitch}
                                        disclaimerType={DISCLAIMER_STANDARD}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.ARTICLE}
                                        component={Article}
                                        routeComponent={PrivateRoute}
                                        disclaimerType={DISCLAIMER_SECONDARY}
                                    />
                                    <ConnectedApplicationRoute
                                        exact
                                        path={ROUTES.BASE}
                                        component={Landing}
                                        routeComponent={HomepageRoute}
                                        disclaimerType={DISCLAIMER_STANDARD}
                                    />
                                    <ConnectedApplicationRoute
                                        component={NotFoundError}
                                        routeComponent={RouteSwitch}
                                        disclaimerType={DISCLAIMER_STANDARD}
                                    />

                                </Switch>
                                <ConnectedToasts />
                                <ConnectedAriaNotification />
                            </React.Fragment>
                        )}
                    />
                </ConnectedRouter>
            </React.Fragment>
        );
    }
}

App.propTypes = {
    history: PropTypes.shape({
        history: PropTypes.object,
        listen: PropTypes.func,
    }).isRequired,
    isReauthenticating: PropTypes.bool.isRequired,
    tryToReauthenticate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    isReauthenticating: state.signIn.isReauthenticating,
    webinarMeta: state.webinar.webinar,
    isRegistered: state.webinar.userRegistered,
    userSources: state.basicProfile.sourceIds,
    userTopics: state.basicProfile.topicIds,
    email: state.basicProfile.email,
    isAuthenticated: state.signIn.isAuthenticated
});

const mapDispatchToProps = dispatch => ({
    tryToReauthenticate: () => dispatch(reauthenticate()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
