import {
    all,
    put,
    takeLatest,
    call,
} from 'redux-saga/effects';
import { getArticleFailure, getArticleSuccess } from 'state-management/actions/articles.common';
import {
    ARTICLE_URL,
    GET_ARTICLE,
} from 'state-management/constants/articles.common';
import { generateError } from '../bridge/error';
import Api from '../bridge/api';

/**
 * Get a single article by ID.
 * @param {*} param0
 */
function* getArticle({ id }) {
    try {
        const article = yield call(Api.get, ARTICLE_URL(id));
        yield put(getArticleSuccess(article.data));
    } catch (e) {
        yield put(getArticleFailure());
        yield put(generateError('global.errors.serverError', e));
    }
}

function* articlesSaga() {
    yield all([
        takeLatest(GET_ARTICLE, getArticle),
    ]);
}

export default articlesSaga;
