import { css } from 'styled-components';
import {
    COLORS,
    FONTS,
    ANIMATIONS,
} from 'utils/variables';
import {
    FROM_DESKTOP,
    FROM_TABLET,
    SMALL_MOBILE,
} from 'utils/mediaQueries';

export const contentContainer = css`
    @media screen and ${FROM_DESKTOP} {
        opacity: ${props => JSON.parse(props.display) ? '1' : '0'};
        display: ${props => JSON.parse(props.display) ? 'block' : 'none'};
    }

    transition: 0.3s;

    ${props => props.hasLimit && css`
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        max-width: ${props.limit}px;
    `}
`;

export const sourceItem = css`
    position: absolute;
    bottom: 16px;
`;

export const categoryTitle = css`
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    color: ${COLORS.SAPPHIRE};
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    position:absolute;
    bottom: 6px;
    display: inline-block;
    text-decoration: none;

    p {
        font-size: 12px;
        line-height: 1.33;
        letter-spacing: normal;
    }

    @media screen and ${SMALL_MOBILE} {
        p {
            font-size: 12px;
            line-height: 1.33;
            letter-spacing: normal;
        }
    }

    @media screen and ${FROM_TABLET} {
        p {
            font-size: 10px;
            line-height: 1.4;
            letter-spacing: 0.1px;
        }
    }

    @media screen and ${FROM_DESKTOP} {
        p {
            font-size: 12px;
            line-height: 1.33;
            letter-spacing: normal;
        }
    }
`;

export const buttonContainer = css`
    transition: 0.3s;
    opacity: 1;   

    @media screen and ${FROM_DESKTOP} {
        &:hover{
            opacity: 1; 
        }

        display: ${props => JSON.parse(props.display) ? 'block' : 'none'};
    }
`;

export const wrapper = css`
    .SourceTag__SourceLink-fhtUEY, .SourceTag__PoweredBy-cNtmDH{
        color: ${COLORS.WHITE};
    }
`;

export const imageContainerStyles = css`
    display: block;
    overflow: hidden;
    position: relative;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        height: 154px;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active), ${FROM_TABLET} {
        height: 90px;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active), ${FROM_DESKTOP} {
        height: 154px;
    }

    img {
        ${props => props.visited && css`
            opacity: 0.6;
        `};
    }
`;

export const titleStyles = css`
    color:  ${COLORS.WHITE};
    display: inline-block;
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.06px;
    line-height: 20px;
    margin: 0;
    margin-bottom: 6px;
    text-decoration: none;
    overflow: hidden;
    height: 43px;
    transition: ${ANIMATIONS.ARTICLES.TITLE.TIME.SECONDS}s all;

    &:hover {
        opacity:0.75;
        color: ${COLORS.WHITE};
    }

    @media screen and ${FROM_TABLET} {
        font-size: 12px;
        line-height: 16px;
        height: 52px;
        margin-bottom: 7px;
        padding-top: 21px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 6px;
        font-size: 15px;
        line-height: 20px;
        height: 61px;
        padding-top: 0;
    }

    ${props => props.visited && css`
        opacity: 0.6;
        &:hover {
            opacity: 1;
        }
    `};
`;

export const containerStyles = css`
    position: relative;
    background: ${COLORS.SAPPHIRE};
    border: 1px solid ${COLORS.LIGHT_GRAY2};
    color:  ${COLORS.WHITE};
    display: inline-block;
    max-width: 299px;
    height: 330px;
    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.CONTAINER.TIME.SECONDS}s all;

    &:hover {
      &.news-not-visited{
          opacity:0.75;
      }
        img {
            background-color: ${COLORS.WHITE};
            transform: scale3d(1.05,1.05,1);
        }
    }

    margin: 0 6px;

    @media screen and ${FROM_TABLET} {
        max-width: 250px;
        margin: 0;
        height: 274px;
    }

    @media screen and ${FROM_DESKTOP} {
        max-width: 291px;
        height: 331px;
    }

    img {
        height: 153px;
        margin-bottom: -4px;
        object-fit: cover;
        transition: ${ANIMATIONS.ARTICLES.IMAGE.TIME.SECONDS}s all;
        width: 100%;

        @media screen and ${FROM_TABLET} {
            height: 88px;
        }

        @media screen and ${FROM_DESKTOP} {
            min-height: 153px;
            max-width: 100%;
            height: auto;
            width: auto;
        }
    }
`;

export const dateAndTopicContainerStyles = css`
    display: flex;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-bottom: 21px;
    text-transform: uppercase;

    @media screen and ${FROM_TABLET} {
        margin-bottom: 13px;
        font-size: 10px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-bottom: 28px;
        font-size: 12px;
    }
`;

export const dateContainerStyles = css`
    color: ${COLORS.WHITE};
    padding-right: 22px;
    white-space: nowrap;
`;

export const topicLinkStyles = css`
    display: none;
    color:  ${COLORS.WHITE};
    flex: 1;
    text-align: right;
    text-decoration: none;
    transition: ${ANIMATIONS.ARTICLES.TOPIC.TIME.SECONDS}s all;

    &:hover {
        opacity: 0.6;
    }
`;

export const imageStyles = css`
    height: 157px;
    margin-bottom: -4px;
    object-fit: cover;
    transition: ${ANIMATIONS.ARTICLES.IMAGE.TIME.SECONDS}s all;
    width: 100%;

    @media screen and ${FROM_TABLET} {
        height: 88px;
    }

    @media screen and ${FROM_DESKTOP} {
        height: 157px;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        min-width: 100%;
        width: auto;
        height: auto;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active), ${FROM_TABLET} {
        min-width: 100%;
        width: auto;
        height: auto;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active), ${FROM_DESKTOP} {
        min-width: 100%;
        width: auto;
        height: auto;
    }
`;

export const contentContainerStyles = css`
    padding: 12px 16px 12px 16px;
    height: 157px;

    @media screen and ${FROM_TABLET} {
        padding: 12px 13px 12px 13px;
    }

    @media screen and ${FROM_DESKTOP} {
        padding: 14px 16px 12px 16px;
    }
`;


export const toolsHeaderIconStyles = css`
    text-align: right;
    position: absolute;
    top: 16px;
    left: 12px;
    z-index: 1;

    svg {
        position: relative;
        top: -5px;
        text-align: right;
    }
`;
