export const SET_BASIC_PROFILE = 'basicProfile/SET_PROFILE_DATA';
export const SAVE_BASIC_PROFILE = 'basicProfile/SAVE_PROFILE_DATA';
export const SAVE_BASIC_PROFILE_AND_REDIRECT = 'basicProfile/SAVE_BASIC_PROFILE_AND_REDIRECT';
export const SAVE_ONBOARDED_AND_SAVE_BASIC_PROFILE_AND_REDIRECT = 'basicProfile/SAVE_ONBOARDED_AND_SAVE_BASIC_PROFILE_AND_REDIRECT';
export const SAVE_BASIC_PROFILE_CREDENTIALS = 'basicProfile/SAVE_BASIC_PROFILE_CREDENTIALS';
export const GET_BASIC_PROFILE = 'basicProfile/GET_BASIC_PROFILE';
export const GET_BASIC_PROFILE_ERROR = 'basicProfile/GET_BASIC_PROFILE_ERROR';
export const SET_LOADING_BASIC_PROFILE = 'basicProfile/SET_LOADING_BASIC_PROFILE';
export const SET_UPDATING_BASIC_PROFILE = 'basicProfile/SET_UPDATING_BASIC_PROFILE';
export const CLEAR_BASIC_PROFILE = 'basicProfile/CLEAR_BASIC_PROFILE';
export const SET_UPDATE_PROFILE_FAILED = 'basicProfile/SET_UPDATE_PROFILE_FAILED';
export const SET_PASSWORD_FAILED = 'basicProfile/SET_UPDATE_FAILED';
export const UNFOLLOW_SOURCE = 'basicProfile/UNFOLLOW_SOURCE';
export const FOLLOW_SOURCE = 'basicProfile/FOLLOW_SOURCE';
export const SET_HOMEPAGE = 'basicProfile/SET_HOMEPAGE';
export const SET_HOMEPAGE_URL = '/advisor-service/advisors/me/homepage';
export const UPDATE_HOMEPAGE = 'basicProfile/UPDATE_HOMEPAGE';
export const HOMEPAGE_DEFAULT = 'DEFAULT';
export const HOMEPAGE_NEWS = 'NEWS';
export const HOMEPAGE_NONE = 'NONE';
export const GET_ISAPPINSTALLED = 'basicProfile/GET_APPINSTALL';
export const GET_ISAPPINSTALLED_SUCCESS = 'basicProfile/GET_APPINSTALL_SUCCESS';
export const GET_ISAPPINSTALLED_FAIL = 'basicProfile/GET_APPINSTALL_FAIL';
export const SET_ISAPPINSTALLED = 'basicProfile/SET_ISAPPINSTALLED';
export const GO_TO_PAGE = 'basicProfile/GO_TO_PAGE';

export const FOLLOW_TOPIC = 'basicProfile/FOLLOW_TOPIC';
export const UNFOLLOW_TOPIC = 'basicProfile/UNFOLLOW_TOPIC';

export const SET_FORCE_MIGRATION_ENUM = 'basicProfile/SET_FORCE_MIGRATION_ENUM';

export const MIGRATION_ENUM = {
    NOT_READY_TO_MIGRATE: 'NotReadyToMigrate',
    READY_TO_MIGRATE: 'ReadyToMigrate',
    MUST_MIGRATE: 'MustMigrate',
    MIGRATED: 'Migrated',
};

export const GET_NOTIFICATION = 'basicProfile/GET_NOTIFICATION';
export const GET_NOTIFICATION_SUCCESS = 'basicProfile/GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAILURE = 'basicProfile/GET_NOTIFICATION_FAILURE';
export const DISMISS_EXPIRING_BOOKMARKS_NOTIFICATION = 'basicProfile/DISMISS_EXPIRING_BOOKMARKS_NOTIFICATION';
export const DISMISS_EXPIRING_BOOKMARKS_MAIN_BADGE_STORAGE_KEY = 'dismissExpArticlesMain';
export const DISMISS_EXPIRING_BOOKMARKS_DETAIL_BADGE_STORAGE_KEY = 'dismissExpArticlesDetail';

export const BASIC_PROFILE_URL = '/advisor-service/advisors/me';
export const BASIC_PROFILE_URL_WITH_TOKEN = shareId => `/advisor-service/advisors/me?sharerId=${shareId}`;
export const SAVE_ONBOARDED_URL = '/advisor-service/advisors/me/onboard';
export const BASIC_PROFILE_CREDENTIALS_URL = '/advisor-service/advisors/me/credentials';
export const SET_ISAPPINSTALLED_URL = '/advisor-service/advisors/userAppChoice/true';
export const GET_ISAPPINSTALLED_URL = '/advisor-service/advisors/nativeAppStatus';
export const NOTIFICATION_URL = '/advisor-service/notifications';
export const VALIDATE_TOKEN_URL = '/security-service/validate-token';
export const VALIDATE_TOKEN = 'basicProfile/VALIDATE_TOKEN';
export const VALIDATE_TOKEN_SUCCESS = 'basicProfile/VALIDATE_TOKEN_SUCCESS';
export const VALIDATE_TOKEN_FAILED = 'basicProfile/VALIDATE_TOKEN_ERROR';

export const REMOVE_VALIDATE_TOKEN = 'basicProfile/REMOVE_VALIDATE_TOKEN';

export const SHOW_PREFERENCES_MODAL = 'basicProfile/SHOW_PREFENCES_MODAL';
export const HIDE_PREFERENCES_MODAL = 'basicProfile/HIDE_PREFERENCES_MODAL';

export const GET_USERID_FROM_TOKEN = 'basicProfile/GET_USERID';
export const GET_USERID_FROM_TOKEN_SUCCESS = 'basicProfile/GET_USERID_SUCCESS';
export const GET_USERID_FROM_TOKEN_FAIL = 'basicProfile/GET_USERID_FAIL';

export const USER_SHARE_TOKEN = 'userShareTokenStorage';
export const SHOW_SOURCE_MODAL = 'basicProfile/SHOW_SOURCE_MODAL';
export const HIDE_SOURCE_MODAL = 'basicProfile/HIDE_SOURCE_MODAL';

export const SKIP_MIGRATION_STORAGE_KEY = 'skipMigration';
export const MIGRATION_INTERCEPT_URL = '/migrate';
