export const DURATIONS_URL = 'advisor-service/durations';

export const GET_ADVISOR_CLASSIFICATIONS = 'advisorClassifications/GET_ADVISOR_CLASSIFICATIONS';
export const GET_ADVISOR_PROFILE_CLASSIFICATIONS = 'advisorClassifications/GET_ADVISOR_PROFILE_CLASSIFICATIONS';
export const SET_ADVISOR_CLASSIFICATIONS = 'advisorClassifications/SET_ADVISOR_CLASSIFICATIONS';

export const WMAUM_URL = 'advisor-service/wmAUM';
export const WMHOUSE_HOLD_URL = 'advisor-service/wmHouseHold';
export const RETIREMENT_AUM_URL = 'advisor-service/retirementAUM';
export const RETIREMENT_PPLANS_URL = 'advisor-service/retirementPPlans';
