import {
    put, takeLatest,
} from 'redux-saga/effects';
import { delay } from 'redux-saga/effects';
import { ADD_TOASTS } from 'state-management/constants/toasts';
import { hideToasts, removeToasts, showToasts } from 'state-management/actions/toasts';

/**
 * Default toast timing (all toasts).
 */
function* onToastsAdd() {
    yield put(hideToasts());
    yield put(showToasts());
    yield delay(5000);
    yield put(removeToasts());
}

function* toastsSaga() {
    yield takeLatest(ADD_TOASTS, onToastsAdd);
}

export default toastsSaga;
