export const GET_TOPICS_SUCCESS = 'topics/GET_TOPICS_SUCCESS';
export const GET_TOPICS_FAIL = 'topics/GET_TOPICS_FAIL';
export const GET_TOPICS = 'topics/GET_TOPICS';

export const GET_ONBOARDING_TOPICS = 'topics/GET_ONBOARDING_TOPICS';
export const SET_LOADING_TOPICS_LIST_STATUS = 'topics/SET_LOADING_TOPICS_LIST_STATUS';
export const GET_ONBOARDING_TOPICS_SUCCESS = 'topics/GET_ONBOARDING_TOPICS_SUCCESS';
export const GET_ONBOARDING_TOPICS_FAIL = 'topics/GET_ONBOARDING_TOPICS_FAIL';

export const TOPICS_URL = '/advisor-service/topics';
export const ONBOARDING_TOPICS_URL = '/advisor-service/topics?visibility=onboarding';

export const TOPIC_LINK = topic => `/topics/${topic}`;
