import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ROUTES } from 'containers/App/constants';
import storage from '../../../../utils/store';
import {
    FIRST_NAME,
} from '../../../../state-management/constants/signIn';

import {
    Container,
    Logo,
    SkipToMainContent,
    VisuallyHidden,
} from 'components';
import {
    containerStyles,
    headerStyles,
    logoLinkStyles,
    logoWrapperStyles,
    iconWrapperStyles,
    wrapperStyles,
    brandedHeader,
} from './styles';

const BrandedHeader = styled.div`
    ${brandedHeader};
`;

export const StyledHeader = styled.header`
    ${headerStyles};
`;

const StyledContainer = styled(Container)`
    ${containerStyles};
`;

const StyledWrapper = styled.div`
    ${wrapperStyles};
`;

const StyledIconWrapper = styled.div`
    ${iconWrapperStyles};
`;

const StyledLogoLink = styled(Link)`
    ${logoLinkStyles};
`;

const StyledLogoWrapper = styled.div`
    ${logoWrapperStyles}
`;

const Header = (props) => {
    const {
        children,
        contentAfter,
        contentBefore,
        withClickableLogo,
        logoLink,
        brandedHeader,
        hideNav,
        forceMigration,
        ...others
    } = props;

    let page = (window.location.pathname).split("/");
    let pageName = page[1];

    const firstName = storage.get(FIRST_NAME);

    let logoLinkNew = pageName == 'sign-in' ? ROUTES.BASE :
        firstName == null ? ROUTES.BASE : ROUTES.HOME;

    return (
        <StyledHeader {...others}>
            <SkipToMainContent
                hash="content"
            />
            <StyledContainer
                size={Container.MEDIUM}
            >
                <StyledWrapper>
                    {
                        contentBefore && (
                            <StyledIconWrapper>
                                {contentBefore}
                            </StyledIconWrapper>
                        )
                    }
                    {
                        (withClickableLogo && !brandedHeader || hideNav)
                            ? (
                                <StyledLogoLink
                                    to={logoLinkNew}
                                >
                                    <VisuallyHidden>
                                        <FormattedMessage id="header.logo" />
                                    </VisuallyHidden>
                                    <Logo />
                                </StyledLogoLink>
                            )
                            : (!withClickableLogo && !brandedHeader) ? (
                                <StyledLogoWrapper>
                                    <VisuallyHidden>
                                        <FormattedMessage id="header.logo" />
                                    </VisuallyHidden>
                                    <Logo />
                                </StyledLogoWrapper>
                            )
                                : <BrandedHeader>{children}</BrandedHeader>
                    }
                    {
                        ((contentAfter || children) && !brandedHeader && !hideNav && !forceMigration) && (
                            <StyledIconWrapper>
                                {contentAfter || children}
                            </StyledIconWrapper>
                        )
                    }
                </StyledWrapper>
            </StyledContainer>
        </StyledHeader>
    );
};

Header.propTypes = {
    contentAfter: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    contentBefore: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    withClickableLogo: PropTypes.bool,
    logoLink: PropTypes.string,
    brandedHeader: PropTypes.bool,
    forceMigration: PropTypes.bool,
};

Header.defaultProps = {
    contentAfter: null,
    contentBefore: null,
    children: null,
    withClickableLogo: false,
    logoLink: "/",
    brandedHeader: false,
    hideNav: false,
    forceMigration: false,
};

export default Header;
