import { css } from 'styled-components';
import {
    COLORS,
    FONTS,
    ANIMATIONS,
} from 'utils/variables';
import {
    FROM_TABLET,
    FROM_DESKTOP,
    SMALL_MOBILE
} from 'utils/mediaQueries';

export const containerStyles = css`
    padding-bottom: 100px;

    *[role="tablist"] {
        display: none;
    }

    @media screen and ${FROM_DESKTOP} {
        *[role="tablist"] {
            display: block;
        }

        padding-bottom: 0;
        margin: 23px 0 30px;
        display: inline-block;
        float: left;
        width: calc(100% - 245px);
        margin: 95px 0 30px;
    }
`;

export const styledBack = css`
    padding-top: 23px;
    margin-bottom: 20px;

    @media screen and ${FROM_TABLET} {
        margin-bottom: 40px;
    }

    @media screen and ${FROM_DESKTOP} {
        display: none;
    }
`;

export const contentContainerStyles = css`
    @media screen and ${FROM_TABLET} {
    }

    @media screen and ${FROM_DESKTOP} {
        padding-left: 54px;
    }

    h3 {
        font-size: 24px;
        letter-spacing: -0.19px;
        line-height: 31px;
        font-weight: 500;
        margin-bottom: 40px;

        @media screen and ${FROM_TABLET} {
            font-size: 24px;
            letter-spacing: -0.25px;
        }
    }
`;

export const itemStyles = css`
    text-decoration: none;
`;

export const itemContainerStyles = css`
    @media screen and ${FROM_TABLET} {
        &>div:first-child {
            border-bottom: 1px solid ${COLORS.LIGHT_GRAY2};
        }
    }

    &>a:last-child {
        padding-top: 20px;
    }

    &>a {
        display: flex;
        white-space: nowrap;
        border-top: 1px solid ${COLORS.LIGHT_GRAY2};
        margin-top: 10px;

        &:first-child{
            border: 0;
        }

        @media screen and ${FROM_TABLET} {
            padding-bottom: 20px;
        }

        &>div:first-child {
            font-size: 12px;
            letter-spacing: 1.5px;
            text-transform: uppercase;
            font-weight: 700;
            color: ${COLORS.DARK_GRAY2};
            padding-right: 60px;
            min-width: 130px;

            @media screen and ${FROM_TABLET} {
                min-width: 230px;
            }
        }

        &>div:nth-child(2) {
            flex: 1;
            font-size: 13px;
            letter-spacing: -0.24px;
            color: ${COLORS.BLACK};
            font-weight: normal;
            text-align: right;
            padding-right: 20px;

            @media screen and ${FROM_TABLET} {
                position: relative;
                top: -1px;                
                text-align: left;
                padding-right: 0;
            }
        }

        &>div:last-child {
            text-align: right;

            div {
                font-size: 12px;
                font-weight: 700;
                letter-spacing: 1.42px;
                text-transform: uppercase;
                color: ${COLORS.SAPPHIRE};
                transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;
            }

            svg {
                display: inline-block;
                fill: ${COLORS.SAPPHIRE};
                position: relative;
                top: -1px;
                transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;

                @media screen and ${FROM_TABLET} {
                    display: none;
                }
            }

            span {
                display: none;

                @media screen and ${FROM_TABLET} {
                    display: inline-block;
                }
            }
        }

        &:hover {
            &>div:last-child {
                div {
                    color: ${COLORS.DARK_SAPPHIRE};
                }

                svg {
                    fill: ${COLORS.DARK_SAPPHIRE};
                }
            }
        }
    }
`;

export const subtitleStyles = css`
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    color: ${COLORS.BLACK};
    font-size: 20px;
    font-weight: 600;
    line-height: 33px;
    margin-top: 76.5px;
    
    @media screen and ${FROM_TABLET} {
        margin-top: 88px;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 26px;
        line-height: 24px;
        letter-spacing: -0.22px;
        margin-top: 35px;
    }
`;