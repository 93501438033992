import { css } from 'styled-components';
import { COLORS } from 'utils/variables';

export const touchStyles = css`
    height: 44px;
    width: 52px;
    position: absolute;
    z-index: 10000;
    top: -22px;
    left: -36px;

    &:hover,
    &:focus {
        outline: none;
    }
`;

export const triggerStyles = css`
    cursor: pointer;
    width: 44px;
    max-width: 44px;
    height: 19px;
    border-radius: 13.5px;
    margin-left: -5px;
    background-color: ${COLORS.LIGHT_GRAY2};
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 15px;
    right: 0px;
    z-index: 1;

    section {
        position: relative;
    }

    em {
        width: 4px;
        height: 4px;
        margin-right:4px;
        background-color: ${COLORS.MEDIUM_GRAY2};
        border-radius: 50%;
        display: inline-block;
        line-height: 19px;
    }

    em[data-state="true"] {
        background-color: ${COLORS.SAPPHIRE};
    }

    &:hover {
        em {
            background-color: ${COLORS.SAPPHIRE};
        }
    }

    em:first-child {
        margin-left: 4px;
    }
`;
