import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Modal from 'components/atomics/templates/ClosableModal/ClosableModal';
import {
    TYPE_PRIMARY_LARGE,
} from 'components/atomics/atoms/Button/Button';
import { FormattedMessage } from 'react-intl';
import {
    containerStyles,
    titleStyles,
    descriptionStyles
} from 'components/atomics/molecules/NewsSource/styles';
import Button from 'components/atomics/atoms/Button/Button';

export const Container = styled.div`
    ${containerStyles}
`;

export const TitleText = styled.div`
     ${titleStyles}
`;

export const Description = styled.div`
     ${descriptionStyles}
`;

const StyledPopup = styled.div`
`;

const ModalStyles = {
    content: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        position: 'fixed',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        borderRadius: '0',
        boxSizing: 'border-box',
        outline: '0',
        background: 'transparent',
        border: '0',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        zIndex: '1',
    },
};

/**
 * Preferences modal when user attempts to go to a page requiring personalization but has not fully onboarded.
 * (webinar signup user only)
 */
export class SourceConfirmationModal extends React.Component {
    /**
     * Render the preferences modal and underlying components.
     */
    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                onRequestClose={this.props.closeSourceConfirmationModal}
                style={ModalStyles}
                ariaHideApp={false}
                shouldFocusAfterRender={false}
                shouldCloseOnOverlayClick={true}
            >
                <StyledPopup>
                    <Container isConfirmation={true}>
                        <TitleText
                            isConfirmation={true}
                        >
                            <FormattedMessage id="news.source.conformation.title" />
                        </TitleText>
                        <Description>
                            <FormattedMessage id="news.source.conformation.description" />
                        </Description>
                        <Button
                            type={TYPE_PRIMARY_LARGE}
                            to="#"
                            onClick={() => {
                                this.props.closeSourceConfirmationModal();
                            }}
                        >
                            <FormattedMessage id="news.source.conformation.btnText" />
                        </Button>
                    </Container>
                </StyledPopup>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

SourceConfirmationModal.propTypes = {
    closeSourceConfirmationModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SourceConfirmationModal);
