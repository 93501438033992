import * as type from 'state-management/constants/followTopic';

const initialState = {
    toggleInProgress: false,
};

function followTopicReducer(state = initialState, action = {}) {
    switch (action.type) {
    case type.TOGGLE_FOLLOW_TOPIC: {
        return {
            ...state,
            toggleInProgress: true,
        };
    }
    case type.TOGGLE_FOLLOW_TOPIC_SUCCESS: {
        return {
            ...state,
            toggleInProgress: false,
        };
    }
    case type.TOGGLE_FOLLOW_TOPIC_FAIL: {
        return {
            ...state,
            toggleInProgress: false,
        };
    }
    default:
        return state;
    }
}

export default followTopicReducer;
