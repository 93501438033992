import {
    call,
    put,
    takeLatest,
    all,
} from 'redux-saga/effects';
import {
    sendForgotUsernameEmailError,
    sendForgotUsernameEmailSuccess,
} from 'state-management/actions/forgotUsername';
import Api from 'utils/api';
import {
    SEND_FORGOT_USERNAME_EMAIL,
    SEND_FORGOT_USERNAME_EMAIL_URL,
} from 'state-management/constants/forgotUsername';
import { TYPES } from 'containers/Toasts/constants';
import { addToasts } from 'state-management/actions/toasts';

/**
 * Handle save page toast display.
 * @param {*} action
 * @param {*} toastType
 * @param {*} analyticsData
 */
function* handleShowToast(toastType) {
    yield put(addToasts([{
        toastProps: {
            actionType: null,
        },
        toastType,
    }]));
}

/**
 * Request username email.
 * @param {*} param0
 */
function* sendForgotUsernameEmail({ email }) {
    try {
        yield call(Api.post, SEND_FORGOT_USERNAME_EMAIL_URL, {
            data: { email },
        });
        yield put(sendForgotUsernameEmailSuccess(email));
        yield call(handleShowToast, TYPES.RESEND_EMAIL);
    } catch (error) {
        if (error && error.response && error.response.status === 404) {
            yield put(sendForgotUsernameEmailError('notFound'));
        } else {
            yield put(sendForgotUsernameEmailError('general'));
        }
    }
}

function* forgotUsernameSaga() {
    yield all([
        takeLatest(SEND_FORGOT_USERNAME_EMAIL, sendForgotUsernameEmail),
    ]);
}

export default forgotUsernameSaga;
