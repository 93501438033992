import { css } from 'styled-components';
import {
    TYPE_MEDIUM,
    TYPE_LARGE,
    TYPE_XLARGE,
} from 'components/atomics/atoms/Input/Input';
import {
    COLORS,
    FONTS,
    ANIMATIONS,
} from 'utils/variables';
import { FROM_TABLET } from 'utils/mediaQueries';

export const inputContainerStyles = css`
    min-width: 240px;
    padding: 10px 0 10px 0;
    position: relative;

    /* remove number input arrows: Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
        margin: 0;
    }

    /* remove number input arrows: Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    input {
        caret-color: ${COLORS.BLACK};
        font-family: ${FONTS.AVENIR_NEXT.FONT};
        background: none;
        border: none;
        color: ${COLORS.BLACK};
        font-size: 15px;
        font-weight: 500;
        letter-spacing: -0.04px;
        line-height: 40px;
        padding: 0;
        width: calc(100% - 40px);
        outline: none;

        ${props => props.type === TYPE_XLARGE && css`
            font-size: 20px;
            line-height: 20px;
            height: 50px;
            
            @media screen and ${FROM_TABLET} {
                line-height: 44px;
                height: 44px;
            }
        `};

        ${props => props.isEmpty === 'false' && css`
            color: ${COLORS.DARK_GRAY2};
        `};

        &:focus {
            color: ${COLORS.DARK_GRAY2};
            caret-color: ${COLORS.BLACK} !important;
        }

        ${props => props.type === TYPE_LARGE && css`
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                height: 31px;
            }
        `};

        ${props => props.type === TYPE_MEDIUM && css`
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.03px;
            padding-bottom: 4px;
        `};
    }

    &>div:nth-child(2) {
        position: relative;
        border-bottom: 1px solid ${COLORS.MEDIUM_GRAY2};
        margin-bottom: 2px;

        ${props => (props.isFocused === 'true' || props.isEmpty === 'false') && css`
            border-color: ${COLORS.SAPPHIRE};
        `};

        ${props => props.isErrored === 'true' && css`
            border-color: ${COLORS.ALERT_RED};
        `};

        ${props => props.isFocused === 'true' && css`
            border-width: 2px;
            margin-bottom: 0;
        `};
    }

    &>div:last-child {
        color: ${COLORS.ALERT_RED};
        font-size: 14px;
        font-weight: 500;
        
        ${props => props.isErrored === 'true' && !props.helperText && css`
            padding-top: 16px;
        `};

        a {
            color: ${COLORS.SAPPHIRE};
            transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;

            &:hover {
                color: ${COLORS.SAPPHIRE};
            }
        }
    }

    &>div[data-rel="helperText"] {
        color: ${COLORS.MEDIUM_GRAY2};
    }
`;

export const labelStyles = css`
    color: ${COLORS.BLACK};
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    margin-bottom: 0;
    text-transform: uppercase;
    line-height: 1.3px;
    position: relative;
    z-index: 2;
    
    ${props => props.isFocused === 'true' && css`
        color: ${COLORS.SAPPHIRE};
    `};

    ${props => props.isErrored === 'true' && css`
        color: ${COLORS.ALERT_RED};
    `};
`;

const curve = "cubic-bezier(0.650, 0.000, 0.450, 1.000)";

export const iconContainerStyles = css`
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;

    svg {
        fill: ${COLORS.SAPPHIRE};
        stroke: ${COLORS.SAPPHIRE};
        position: relative;
        top: -2px;
        left: -6px;

        ${props => props.type === TYPE_MEDIUM && css`
            top: -18px;
        `};

        ${props => props.isErrored === 'false' && css`
            transform: scale(0.6);
            stroke-width: 3;
        `};

        @supports not (-ms-high-contrast: none) {
            path {
                transform-origin: 50% 50%;
                stroke-dasharray: 48;
                stroke-dashoffset: 48;
                animation: stroke .1s ${curve} .6s forwards;
            }

            @keyframes stroke {
                100% {
                    stroke-dashoffset: 0;
                }
            }
        }

        ${props => props.isErrored === 'true' && css`
            color: ${COLORS.ALERT_RED};
            fill: ${COLORS.ALERT_RED};
            transform: rotate(45deg);
            top: 8px;
            left: 10px;

            ${props => props.type === TYPE_MEDIUM && css`
                top: -6px;
            `};
        `};
    }
`;
