import { defaultsDeep } from 'lodash';

import { ADD_MESSAGE } from 'state-management/constants/ariaNotification';

const defaultOptions = {
    translated: true,
};

const initialState = {
    message: '',
    options: defaultOptions,
};

function ariaNotificationReducer(state = initialState, action = {}) {
    switch (action.type) {
    case ADD_MESSAGE: {
        return {
            ...state,
            message: action.message,
            options: defaultsDeep(action.options, defaultOptions),
        };
    }
    default:
        return state;
    }
}

export default ariaNotificationReducer;
