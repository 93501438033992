import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from "react-intl";
import { Button as OldButton, Container } from 'components';
import Button from 'components/atomics/atoms/Button/Button';
import { TYPE_SECONDARY_MEDIUM } from 'components/atomics/atoms/Button/Button';
import {
    actionsBarContainerStyles,
    saveButtonStyles,
    buttonsWrapperStyles,
    errorMessageStyles,
} from 'containers/Profile/styles';

const StyledContainer = styled.div`
    ${actionsBarContainerStyles}
`;

const StyledButtonsWrapper = styled(Container)`
    ${buttonsWrapperStyles};
`;

const StyledSaveButton = styled(OldButton)`
    ${saveButtonStyles}
`;

const ErrorMessage = styled.div`
    ${errorMessageStyles};
`;

/**
 * Lower actions bar for profile pages.
 * @param {*} param0 
 */
const ActionsBar = ({ visible, isSaving, onResetClick, canSave, errorType }) => visible && (
    <StyledContainer id="profileActionBar">
        <StyledButtonsWrapper>
            {errorType !== 0
                && <ErrorMessage errorType={errorType}>
                    {errorType === 1
                        && <FormattedMessage id="minimumRequirements.topics" />
                    }
                    {errorType === 2
                        && <FormattedMessage id="minimumRequirements.sources" />
                    }
                    {errorType === 3
                        && <FormattedMessage id="minimumRequirements.topicsAndSources" />
                    }
                </ErrorMessage>}
            <Button
                type={TYPE_SECONDARY_MEDIUM}
                id="profile-cancel"
                disabled={isSaving}
                onClick={onResetClick}
                dataAnalyticsPlacement="Button : body"
                dataAnalyticsLabel="trackLink : button"
                dataAnalyticsId="cancel profile save button"
            >
                <FormattedMessage id="profilePage.button.cancel" />
            </Button>
            <StyledSaveButton
                appearance={OldButton.APPEARANCE.PRIMARY}
                type={OldButton.TYPES.SUBMIT}
                id="profile-save"
                isVisuallyDisabled={isSaving || !canSave || errorType !== 0}
                disabled={isSaving || !canSave || errorType !== 0}
                data-analytics-placement="Button : body"
                data-analytics-label="trackLink : button"
                data-analytics-id="profile save button"
            >
                <FormattedMessage id="profilePage.button.save" />
            </StyledSaveButton>
        </StyledButtonsWrapper>
    </StyledContainer>
);

ActionsBar.propTypes = {
    onResetClick: PropTypes.func.isRequired,
    visible: PropTypes.bool,
    isSaving: PropTypes.bool,
    canSave: PropTypes.bool,
    errorType: PropTypes.number,
};

ActionsBar.defaultProps = {
    visible: false,
    isSaving: false,
    canSave: true,
    errorType: 0,
};

export default ActionsBar;
