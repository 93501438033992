import {
    DISCLAIMER_NONE,
    GET_DISCLAIMER_FRAGMENT,
    GET_DISCLAIMER_FRAGMENT_SUCCESS,
    GET_DISCLAIMER_FRAGMENT_FAIL,
    SET_DISCLAIMER_TYPE,
} from 'state-management/constants/globalDisclaimer';

const initialState = {
    disclaimerType: DISCLAIMER_NONE,
    errors: {},
    fragments: {},
    isLoading: false,
};

function globalDisclaimerReducer(state = initialState, action = {}) {
    switch (action.type) {
    case GET_DISCLAIMER_FRAGMENT:
        return {
            ...state,
            isLoading: true,
        };
    case GET_DISCLAIMER_FRAGMENT_SUCCESS:
        return {
            ...state,
            fragments: {
                ...state.fragments,
                [action.disclaimerType]: action.fragment,
            },
            isLoading: false,
        };
    case GET_DISCLAIMER_FRAGMENT_FAIL:
        return {
            ...state,
            errors: {
                [action.disclaimerType]: action.error,
            },
            isLoading: false,
        };
    case SET_DISCLAIMER_TYPE:
        return {
            ...state,
            disclaimerType: action.disclaimerType,
        };
    default:
        return state;
    }
}

export default globalDisclaimerReducer;
