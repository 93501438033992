export const GET_TRENDING_TOPIC_NEWS = 'trendingTopic/GET_TRENDING_TOPIC_NEWS';
export const GET_TRENDING_TOPIC_NEWS_FAIL = 'trendingTopic/GET_TRENDING_TOPIC_NEWS_FAIL';
export const GET_TRENDING_TOPIC_NEWS_SUCCESS = 'trendingTopic/GET_TRENDING_TOPIC_NEWS_SUCCESS';

export const RESET_TRENDING_TOPIC_NEWS = 'trendingTopic/RESET_TRENDING_TOPIC_NEWS';

export const TRENDING_TOPIC_NEWS_URL = '/content-service/news/trending-topic';

export const GET_TRENING_TOPIC_FILTERS = 'trendingTopic/GET_FILTERS';
export const GET_TRENING_TOPIC_FILTERS_FAIL = 'trendingTopic/GET_FILTERS_FAIL';
export const GET_TRENING_TOPIC_FILTERS_SUCCESS = 'trendingTopic/GET_FILTERS_SUCCESS';

export const TRENDING_TOPIC_FILTER_DURATION_URL = '/advisor-service/durations';

export const TRENDING_TOPIC_FILTER_AUM_URL = '/api/advisor-service/wmAUM';
export const TRENDING_TOPIC_FILTER_HOUSEHOLD_URL = '/api/advisor-service/wmHouseHold';
export const TRENDING_TOPIC_FILTER_RETIREMENT_URL = '/api/advisor-service/retirementAUM';
export const TRENDING_TOPIC_FILTER_RETIREMENT_PLANS_URL = '/api/advisor-service/retirementPPlans';
