import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { find, get as getValue } from 'lodash';
import { FormattedMessage } from 'react-intl';
import {
    keyContainerStyles, rowStyles, valueContainerStyles,passwordHeaderStyles,
} from 'containers/Profile/AccountInformation/ViewAccountInformation/styles';

export const StyledInfoRow = styled.dl`
    ${rowStyles};
`;

export const StyledRowKey = styled.div`
    ${keyContainerStyles};
`;

export const StyledRowValue = styled.div`
    ${valueContainerStyles};
`;

export const PasswordHeader = styled.h3`
    ${passwordHeaderStyles};
`;

const initialAccountInfo = {
    firstname: '-',
    lastname: '-',
    email: '-',
    crd: '-',
    iard: '-',
    password: '-',
};

const getAdvisorClassificationName = (classifications, id) => getValue(find(classifications, classification => classification.id === id), 'name', null);

/**
 * View (not edit) version of account information.
 * @param {*} props 
 */
const ViewAccountInformation = (props) => {
    const {
        aums, amounts, durations, serviceOfferings, profile, isClassificationsLoaded, wmAum
    } = props;

    const accountInfo = { ...initialAccountInfo };

    if (isClassificationsLoaded && profile.email) {
        accountInfo.firstname = `${profile.firstName}`;
        accountInfo.lastname = `${profile.lastName}`;
        accountInfo.email = profile.email;
        accountInfo.password = '**********';
        accountInfo.crd = profile.crd || false;
        accountInfo.iard = profile.iard || false;
    }

    let fvalues = {
        firstname: 'First Name',
        lastname: 'Last Name',
        email: 'Email',
        crd: 'CRD#',
        iard: 'IARD',
        password: 'Password',
    }

    return (
        <React.Fragment>
            {Object.keys(accountInfo).map(key => (           
                accountInfo[key] != false && (
                    <StyledInfoRow key={key} data-value={key} >       
                    <PasswordHeader >Password</PasswordHeader>                 
                    <StyledRowKey>
                        <FormattedMessage id={`${key}`} defaultMessage={fvalues[key]} />
                        <StyledRowValue>{accountInfo[key] || '-'}</StyledRowValue>
                    </StyledRowKey>
                </StyledInfoRow>
                )               
                               
            ))}
        </React.Fragment>
    );
};

ViewAccountInformation.propTypes = {
    profile: PropTypes.shape({}).isRequired,
    aums: PropTypes.arrayOf(PropTypes.object).isRequired,
    amounts: PropTypes.arrayOf(PropTypes.object).isRequired,
    durations: PropTypes.arrayOf(PropTypes.object).isRequired,
    serviceOfferings: PropTypes.arrayOf(PropTypes.object).isRequired,
    isClassificationsLoaded: PropTypes.bool.isRequired,
    wmAum: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => ({
    profile: state.basicProfile,
    amounts: state.advisorClassifications.amounts,
    aums: state.advisorClassifications.aums,
    durations: state.advisorClassifications.durations,
    serviceOfferings: state.advisorClassifications.serviceOfferings,
    isClassificationsLoaded: state.advisorClassifications.isLoaded,
    wmAum: state.advisorClassifications.wmAum,
});

export default connect(mapStateToProps)(ViewAccountInformation);
