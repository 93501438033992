import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconLogoText, IconLogoEmblem } from 'components/atomics/atoms/Icons/Icons';
import {
    emblemIconStyles,
    logoContainerStyles,
    logoIconStyles,
} from 'components/FooterLogo/styles';

const StyledLogoContainer = styled.span`
    ${logoContainerStyles};
`;
const StyledLogoIcon = styled(IconLogoText)`
    ${logoIconStyles};
`;
const StyledEmblemIcon = styled(IconLogoEmblem)`
    ${emblemIconStyles};
`;

const FooterLogo = ({ showEmblem, showPoweredBy }) => (
    <StyledLogoContainer>

        <StyledLogoIcon />

        {showEmblem && <StyledEmblemIcon height={36} width={36} />}

    </StyledLogoContainer>
);

FooterLogo.propTypes = {
    showEmblem: PropTypes.bool,
    showPoweredBy: PropTypes.bool,
};

FooterLogo.defaultProps = {
    showEmblem: false,
    showPoweredBy: false,
};

export default FooterLogo;
