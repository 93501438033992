import { css } from 'styled-components';
import { 
    FROM_DESKTOP,
    FROM_TABLET,
} from 'utils/mediaQueries';
import {
    COLORS,
    FONTS,
} from 'utils/variables';

export const container = css`
    background-color: ${COLORS.WHITE};
    width: calc(100% - 64px);
    padding: 52px 16px 32px 16px;
    margin: auto;
    text-align: center;

    @media screen and ${FROM_TABLET} {
        width: 50%;
        padding: 42px 66px 42px 66px;
        min-width: 558px;
        box-sizing: border-box;
    }
`;

export const title = css`
    font-family: ${FONTS.AVENIR_NEXT.FONT};
    color: ${COLORS.BLACK};
    font-size: 24px;
    line-height: 33px;
    padding-bottom: 10px;
    font-weight: 600;
    white-space: normal;
    margin: auto;
`;

export const subTitle = css`
    color: ${COLORS.BLACK};
    font-size: 18px;
    padding-bottom: 32px;
    font-weight: normal;
    letter-spacing: 0.12px;
    margin: auto;
`;

export const callout = css`
    color: ${COLORS.BLACK};
    font-size: 18px;
    padding-bottom: 16px;
    font-weight: normal;
    letter-spacing: 0.12px;
    margin: auto;
`;

export const buttonRow = css`
    margin: auto;
`;