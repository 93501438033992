import { css } from 'styled-components';
import { FROM_TABLET } from 'utils/mediaQueries';
import { COLORS, ANIMATIONS } from 'utils/variables';

export const containerStyles = css`
    max-width: 962px;
`;

export const headingContainerStyles = css`
    border-bottom: 2px solid ${COLORS.MEDIUM_GRAY1};
    margin-bottom: 26px;
    padding-bottom: 12px;

    @media screen and ${FROM_TABLET} {
        margin-bottom: 74px;
        padding-bottom: 14px;
    }
`;

export const headingStyles = css`
    color: ${COLORS.DARK_GRAY1};
    font-size: 24px;
    font-weight: 600;
    line-height: 1.17;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        font-size: 32px;
    }
`;

export const descriptionStyles = css`
    color: ${COLORS.MEDIUM_GRAY2};
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
    margin: 0;
    white-space: pre-wrap;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        font-size: 28px;
        font-weight: 500;
        line-height: 1.61;
    }
`;
