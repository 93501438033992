import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    containerStyles,
} from 'components/atomics/atoms/Row/styles';

export const Container = styled.div`
    ${containerStyles}
`;

export const TYPE_3COLUMN = "type/3column";
export const TYPE_3COLUMN_LARGELASTCOLUMN = "type/3columnLargeLast";
export const TYPE_3COLUMN_LARGEFIRSTCOLUMN = "type/3columnLargeFirst";
export const TYPE_4COLUMN = "type/4column";
export const TYPE_2COLUMN = "type/2column";

/**
 * Creates a row for use in tables or grids.
 */
class Row extends React.Component {
    /**
     * Render the row and child components.
     */
    render() {
        let lastContainerStyles = {}

        if (this.props.lastCard && this.props.type === TYPE_4COLUMN) {
            lastContainerStyles = { marginBottom: '110px' }
        }
        else {
            lastContainerStyles = { marginBottom: '25px' }
        }

        return (
            <Container type={this.props.type} style={lastContainerStyles}>
                {this.props.children}
            </Container>
        );
    }
}

Row.propTypes = {
    type: PropTypes.string,
    lastCard: PropTypes.bool,
}

Row.defaultProps = {
    type: TYPE_3COLUMN,
    lastCard: false,
}

export default Row;
