import React from 'react';
import styled from 'styled-components';
import Image from 'components/atomics/atoms/Image/Image';
import DateDisplay from 'components/atomics/atoms/DateDisplay/DateDisplay';
import PropTypes from 'prop-types';
import Speaker from 'components/atomics/organisms/Speaker/Speaker';
import Loader from 'components/Loader/Loader';
import { BackToLink, SanitizedHTML } from 'components';
import {
    BreakpointsContext,
    DESKTOP,
    MOBILE,
} from 'components/Breakpoints/Breakpoints';
import { wrapBackButtonLabels } from "../../utils/urlUtils";
import { createImageUrl } from 'utils/urlUtils';
import { claimYourSpot } from '../../state-management/actions/webinar';
import MeetLuminSection from 'components/MeetLuminSection/MeetLuminSection';
import SignUpSection from 'components/SignUpSection/SignUpSection';
import SignUpSecondarySection from 'components/SignUpSecondarySection/SignUpSecondarySection';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import moment from 'moment';
import SignUpFormContainer from 'containers/SignUp/SignUpForm/SignUpForm';
import { injectIntl, FormattedMessage } from 'react-intl';
import { clearErrors as clearErrorsAction } from 'state-management/actions/errors';
import { signUp } from 'state-management/actions/signUp';
import { getWebinarInfo, notInterested, getWebinarInfoWithEventId } from 'state-management/actions/webinar';
import { setScrollToTop } from 'state-management/actions/scroll';
import { Container } from 'components';
import {
    getWebinarIsUserRegistered,
} from '../../state-management/actions/webinar';
import store from 'utils/store';
import { REDIRECT_AFTER_LOGIN_URL } from 'state-management/constants/signIn';
import Button from 'components/atomics/atoms/Button/Button';
import SplashNotification from 'components/atomics/molecules/SplashNotification/SplashNotification';
import {
    TYPE_PRIMARY_LARGE_LARGE,
    TYPE_PRIMARY_LARGE_MINI,
    TYPE_SECONDARY_SMALL,
    TYPE_GHOST_RIGHT,
    TYPE_PDF_LEFT_BRIGHT,
    TYPE_DOWNLOAD_LEFT_BRIGHT,
} from 'components/atomics/atoms/Button/Button';
import CountDown from 'components/atomics/atoms/CountDown/CountDown';
import language from '../../lang/en.json';
import {
    containerStyles,
    mobileSignupTitle,
    contentWrapperStyles,
    sectionContainerStyles,
    splashContentStyles,
    titleContainerStyles,
    colorBarStyles,
    titleStyles,
    splashTitleStyles,
    mobileSplashTitleStyles,
    splashImageStyles,
    splashDescriptionStyles,
    splashDateStyles,
    formContainerStyles,
    formTitleStyles,
    formDescriptionStyles,
    formLinkStyles,
    styleContainerStyles,
    stylesContentWrapper,
    stylesSplashImage,
    stylesTxtWrapperContainer,
    stylesTxtWrapper,
    stylesCardType,
    buttonContainer,
    styledBack,
    stylesWrapper,
    stylesSplashTitle,
    stylesSplashDescription,
    pageContainerStyles,
    splashContentTopExplored,
    splashContentTopPoints,
    splashContentBottomFirst,
    splashContentBottomSecond,
    splashContentBottomThird,
    stylesTopBorder,
    stylesPageHeading,
    stylesCenterContent,
    stylesTextStart,
    stylesSplashTitleBottom,
    stylesDateContainer,
    stylesTopTxt,
    stylesCountDown,
    ceCredit,
    speakerTitle,
    videoTitle,
    videoContainer,
    mobileOnly,
    tabletOnly,
    stylesquizButtonContainer,
    stylesvideoReplacementContainer,
    stylescomeBackHeading,
    stylescomeBackText,
    stylesCeCreditContainer,
    stylesCeCredit,
    stylesCeCreditList,
    stylesCeCreditCount,
    stylesCeCreditTitle,
    stylesbottomTextContainer,
    stylesbottomContainer,
    styledBottomHeading,
    styledBottomTitle,
    styledBottomBody,
    styledBottomText,
    styledBottomleft,
    styledBottomRight,
    stylesCeCreditText,
    videoOverlay,
    videoPlayButton,
    videoPlayButtonArrow,
    videoArrowWrapper,
    formDescription,
    styledBackInner,
    stylesDownloadTrans,
    styledBottomHeadingMain,
    styledBottomUL,
    styledBottomLI,
} from 'containers/WebinarLanding/styles';
import { TYPE_GHOST_LEFT_WHITE } from '../../components/atomics/atoms/Button/Button';
import WebinarLandingError from './WebinarLandingError';

const VideoTag = styled.video``;

const DownloadTrans = styled.div`
    ${stylesDownloadTrans}
`;

const MobileSignupTitle = styled.div`
    ${mobileSignupTitle}
`;

const TabletOnly = styled.div`
    ${tabletOnly}
`;

const StyledBackInner = styled.div`
    ${styledBackInner}
`;

const MobileOnly = styled.div`
    ${mobileOnly}
`;

const FormDescription = styled.div`
    ${formDescription}
`;

const CECredit = styled.div`
    ${ceCredit}
`;

const StylesCeCredit = styled.div`
    ${stylesCeCredit}
`;

const StylesCeCreditContainer = styled.div`
    ${stylesCeCreditContainer}
`;

const StylesCeCreditCount = styled.span`
    ${stylesCeCreditCount}
`;

const StylesCeCreditList = styled.div`
    ${stylesCeCreditList}
`;

const StylesCeCreditTitle = styled.span`
    ${stylesCeCreditTitle}
`;

const StylesbottomContainer = styled.div`
    ${stylesbottomContainer}
`;

const StylesbottomTextContainer = styled.div`
    ${stylesbottomTextContainer}
`;

const StyledBottomHeading = styled.div`
    ${styledBottomHeading}
`;

const StyledBottomTitle = styled.div`
    ${styledBottomTitle}
`;

const StyledBottomBody = styled.div`
    ${styledBottomBody}
`;

const StyledBottomText = styled.div`
    ${styledBottomText}
`;

const StyledBottomleft = styled.div`
    ${styledBottomleft}
`;

const StylesCeCreditText = styled.span`
    ${stylesCeCreditText}
`;

const StyledBottomRight = styled.div`
    ${styledBottomRight}
`;

const VideoContainer = styled.div`
    ${videoContainer}
`;

const VideoOverlay = styled.div`
    ${videoOverlay}
`;

const PlayButton = styled.div`
    ${videoPlayButton}
`;

const Arrow = styled.div`
    ${videoPlayButtonArrow}
`;

const ArrowWrapper = styled.div`
    ${videoArrowWrapper}
`;

const VideoTitle = styled.div`
    ${videoTitle}
`;

const SpeakerTitle = styled.div`
    ${speakerTitle}
`;

const SplashTopExplore = styled.div`
    ${splashContentTopExplored}
`;

const SplashTopPoints = styled.div`
    ${splashContentTopPoints}
`;

const SplashBottomFirst = styled.div`
    ${splashContentBottomFirst}
`;

const SplashBottomSecond = styled.div`
    ${splashContentBottomSecond}
`;

const SplashBottomThird = styled.div`
    ${splashContentBottomThird}
`;

const PageContainer = styled.div`
    ${pageContainerStyles}
`;

const FormTitle = styled.div`
    ${formTitleStyles}
`;

const FormLink = styled(Link)`
    ${formLinkStyles}
`;

const FormContainer = styled.div`
    ${formContainerStyles}
`;

const SplashTitle = styled.div`
    ${splashTitleStyles}
`;

const MobileSplashTitle = styled.div`
    ${mobileSplashTitleStyles}
`;

const SplashDescription = styled.div`
    ${splashDescriptionStyles}
`;

const SplashImage = styled.div`
    ${splashImageStyles}
`;

const SplashDate = styled.div`
    ${splashDateStyles}
`;

const TitleContainer = styled.div`
    ${titleContainerStyles}
`;

const Title = styled.h1`
    ${titleStyles}
`;

const ColorBar = styled.div`
    ${colorBarStyles}
`;

const Sections = styled.div`
    ${sectionContainerStyles}
`;

const SplashContent = styled.div`
    ${splashContentStyles}
`;

const StyleContainerStyles = styled.div`
    ${styleContainerStyles}
`;

const StyledContainer = styled(Container)`
    ${containerStyles};
`;

const StyledContentWrapper = styled.div`
    ${contentWrapperStyles}
`;

const StylesContentWrapper = styled.div`
    ${stylesContentWrapper}
`;

const StylesSplashImage = styled.div`
    ${stylesSplashImage}
`;

const StylesTxtWrapperContainer = styled.div`
    ${stylesTxtWrapperContainer}
`;

const StylesTxtWrapper = styled.div`
    ${stylesTxtWrapper}
`;

const StylesCardType = styled.div`
    ${stylesCardType}
`;
const ButtonContainer = styled.div`
    ${buttonContainer}
`;

const StyledBack = styled.div`
    ${styledBack}
`;

const StylesWrapper = styled.div`
    ${stylesWrapper}
`;

const StylesSplashTitle = styled.div`
    ${stylesSplashTitle}
`;
const StylesSplashTitleBottom = styled.div`
    ${stylesSplashTitleBottom}
`;

const StylesSplashDescription = styled.div`
    ${stylesSplashDescription}
`;

const StylesTopBorder = styled.div`
    ${stylesTopBorder}
`;

const StylesPageHeading = styled.div`
    ${stylesPageHeading}
`;

const StylesCenterContent = styled.div`
    ${stylesCenterContent}
`;

const StylesTextStart = styled.div`
    ${stylesTextStart}
`;

const StylesDateContainer = styled.div`
    ${stylesDateContainer}
`;

const StylesTopTxt = styled.div`
    ${stylesTopTxt}
`;

const StylesCountDown = styled.div`
    ${stylesCountDown}
`;

const QuizButtonContainer = styled.div`
    ${stylesquizButtonContainer}
`;

const VideoReplacementContainer = styled.div`
    ${stylesvideoReplacementContainer}
`;

const ComeBackText = styled.div`
    ${stylescomeBackText}
`;

const ComeBackHeading = styled.div`
    ${stylescomeBackHeading}
`;

const StyledBottomHeadingMain = styled.h3`
    ${styledBottomHeadingMain}
    `;
const StyledBottomUL = styled.ul`
    ${styledBottomUL}
`;

const StyledBottomLI = styled.li`
    ${styledBottomLI}
`;

//initial formik signup values
const initialValues = {
    email: '',
    firstName: '',
    lastName: '',
    legalAgreement: false,
    password: '',
    passwordVerification: '',
};

//store identity data outside formik
window.identityData = {
    crd: '',
    previousCrd: '',
};

const STORE_SHOW_NEWUSER_VERSION = 'showNewUserVersion';

const Status = {
    FUTURE: 0,
    PREVIOUS_DAY: 1,
    IN_HALF_HOUR: 2,
    LIVE: 3,
    PAST: 4,
    IN_TWO_DAYS: 5,
};

/**
 * Main webinar landing page.
 */
class WebinarLanding extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.state = {
            additionalDataCompleted: false,
            status: Status.FUTURE,
            checkValue: 0,
            apiStatus: 1,
            isContentLoaded: false
        };

        this.videoNode = React.createRef();
        this.overlayNode = React.createRef();

        this.onSignUpHandler = this.onSignUpHandler.bind(this);
        this.startVideo = this.startVideo.bind(this);
    }


    /**
     * Clear errors and scroll to top at mount.
     */
    componentDidMount() {
        this.props.clearErrors();
        this.props.scrollToTop();

        //this.props.getWebinarInfo();
        this.props.getWebinarInfoWithEventId(this.props.match.params.eventName);

        //BELOW NOT NEEDED unless we reinstate sign-in flow also from webinar landing
        //if (!this.props.isAuthenticated) {
        //    store.set(REDIRECT_AFTER_LOGIN_URL, `/learn/webinar/${this.props.match.params.webinarID}/${this.props.match.params.eventID}`);
        //}
    }

    /**
     * Ask for additional BE data as needed after mount.
     * @param {*} nextProps
     */
    // componentWillUpdate(nextProps) {
    //     if (nextProps.isAuthenticated && !this.state.additionalDataCompleted) {
    //         this.setState({
    //             additionalDataCompleted: true,
    //         }, () => {
    //             this.props.getIsUserRegistered();
    //         });
    //     }
    // }

    /**
     * Start video, enable controls, hide overlay.
     */
    startVideo() {
        if (this.videoNode.current == null) return;

        this.videoNode.current.play();

        this.videoNode.current.controls = true;

        this.overlayNode.current.style.display = 'none';
    }

    /**
     * Handle user signup.
     * @param {*} values
     * @param {*} actions
     */
    onSignUpHandler(values, actions) {
        store.remove(REDIRECT_AFTER_LOGIN_URL);
        let nextDecision = this.getNextDecision()
        this.props.onSignUp(values, actions, {
            id: this.props.webinarMeta.content.code,
            eventId: this.props.webinarMeta.eventId,
            decision: nextDecision,
        });
        /*  setTimeout(() => {
             this.handleDismiss();
         }, 5000); */
    }

    /**
     * Render the standard webinar details, image, speakers, video etc..
     */
    renderMetaDetails(isClaimSpot = false, breakpoint) {
        const trueDate = this.props.webinarMeta ? moment(this.props.webinarMeta.content.startTimestamp).valueOf() : null;

        return (
            <SplashContent>
                <SplashTitle>
                    <FormattedMessage id="webinar.page.title.prefix" />&nbsp;
                    <span dangerouslySetInnerHTML={{ __html: this.props.webinarMeta.content.title }} />
                </SplashTitle>
                <SplashImage>
                    <Image
                        src={'/api/content-service/assets/content-image?filename=/' + this.props.webinarMeta.content.image}
                    />
                </SplashImage>
                <MobileSplashTitle>
                    <FormattedMessage id="webinar.page.title.prefix" />&nbsp;
                    <span dangerouslySetInnerHTML={{ __html: this.props.webinarMeta.content.title }} />
                </MobileSplashTitle>
                <SplashDate>
                    <DateDisplay timestamp={trueDate} />
                </SplashDate>
                {!isClaimSpot
                    && <MobileOnly>
                        <MobileSignupTitle>
                            <FormattedMessage id="webinar.page.mobile.signup" />
                        </MobileSignupTitle>
                        <Button
                            to="#signup"
                        >
                            <FormattedMessage id="signUp.title" />
                        </Button>
                    </MobileOnly>}
                {isClaimSpot
                    && <MobileOnly>
                        <MobileSignupTitle isClaimSpot={true} id={breakpoint === MOBILE ? "claim" : ""}>
                            <FormattedMessage id="webinar.claimYourSpot.title" />
                        </MobileSignupTitle>
                        <Button
                            to="#"
                            onClick={() => { this.processClaimYourSpot() }}
                            data-analytics-placement="Button : body"
                            data-analytics-label="trackLink : button"
                            data-analytics-id={language.translations['webinar.claimYourSpot']}
                        >
                            <FormattedMessage id="webinar.claimYourSpot" />
                        </Button>
                    </MobileOnly>}
                {this.props.webinarMeta.content.ceCredit
                    && <CECredit>
                        <FormattedMessage id="webinar.page.ceCredit" />
                        <img src="/images/ce-imca.png" />
                    </CECredit>}
                <SplashDescription
                    dangerouslySetInnerHTML={{ __html: this.props.webinarMeta.content.description }}
                />
                {/*<SplashDescription>
                    <span
                        dangerouslySetInnerHTML={{ __html: this.props.webinarMeta.content.planInformation[0].title }}
                    />
                    <div
                        dangerouslySetInnerHTML={{ __html: this.props.webinarMeta.content.planInformation[0].description }}
                    />
                </SplashDescription>*/}
                <SpeakerTitle>
                    <FormattedMessage id="webinar.page.speaker" />
                </SpeakerTitle>
                {this.props.webinarMeta.content.speakerInformation.map((item, item_index) => (<Speaker key={item_index} item={item} />))}
                <VideoTitle
                    dangerouslySetInnerHTML={{ __html: this.props.webinarMeta.content.videos[0].title }}
                />
                <VideoContainer>
                    <VideoTag
                        ref={this.videoNode}
                        poster={`/api/content-service/assets/content-image?filename=${this.props.webinarMeta.content.videos[0].stillImage}`}
                    >
                        {/* TODO replace with proper content API when BE is fixed */}
                        <source
                            type="video/mp4"
                            src={`/api/content-service/assets/content-image?filename=${this.props.webinarMeta.content.videos[0].videoLink}`}
                        />
                    </VideoTag>
                    <VideoOverlay
                        ref={this.overlayNode}
                        onClick={this.startVideo}
                    >
                        <PlayButton>
                            <ArrowWrapper>
                                <Arrow />
                            </ArrowWrapper>
                        </PlayButton>
                    </VideoOverlay>
                </VideoContainer>
                {!isClaimSpot
                    && <TabletOnly>
                        <Button
                            to="#signup"
                        >
                            <FormattedMessage id="signUp.title" />
                        </Button>
                    </TabletOnly>}
                {(breakpoint !== MOBILE && isClaimSpot)
                    && <Button
                        to="#"
                        onClick={() => { this.processClaimYourSpot() }}
                        data-analytics-placement="Button : body"
                        data-analytics-label="trackLink : button"
                        data-analytics-id={language.translations['webinar.claimYourSpot']}
                    >
                        <FormattedMessage id="webinar.claimYourSpot" />
                    </Button>}
            </SplashContent>
        );
    }

    /**
     * Render the not signed in version of the page with signup form and signin linkage.
     */
    renderNotSignedInVersion() {
        return (
            <StyledContainer>
                <TitleContainer>
                    <ColorBar />
                    <Title>
                        <FormattedMessage id="webinar.page.title" />
                        <br /><FormattedMessage id="webinar.page.title2" />
                    </Title>
                </TitleContainer>
                <StyledContentWrapper>
                    <Sections>
                        {this.renderMetaDetails()}
                        <FormContainer>
                            <FormTitle
                                id="signup"
                            >
                                <FormattedMessage id="webinar.page.form.title" />
                            </FormTitle>

                            {/*<FormDescription>
                                <FormattedMessage id="webinar.page.form.subtitle" />
                                <FormLink
                                    to="/sign-in"
                                >
                                    <FormattedMessage id="webinar.page.form.link" />
                                </FormLink>
                            </FormDescription>*/}
                            <Formik
                                component={SignUpFormContainer}
                                initialValues={initialValues}
                                onSubmit={this.onSignUpHandler}
                                validate={this.validate}
                                validateOnChange
                                validateOnBlur={false}
                            />
                        </FormContainer>
                    </Sections>
                </StyledContentWrapper>
            </StyledContainer>
        );
    }

    /**
     * Render the top portion of new user splash content, within purple margins.
     */
    renderNewUserSplashTop(isLive = false) {
        return (
            <StyledContainer hasLowerContent={true} isSplash={true}>
                {this.renderSplashTopContents()}
            </StyledContainer>
        );
    }

    renderSplashTopContents() {
        return (
            <React.Fragment>
                <SplashTopExplore>
                    <div>
                        <h2>
                            <FormattedMessage id="webinar.page.explore.title" />
                        </h2>
                        <p>
                            <FormattedMessage id="webinar.page.explore.description" />
                        </p>
                        <Button
                            type={TYPE_SECONDARY_SMALL}
                            to="/home"
                            data-analytics-placement="Button : body"
                            data-analytics-label="trackLink : button"
                            data-analytics-id={language.translations['webinar.page.explore.buttonLabel']}
                        >
                            <FormattedMessage id="webinar.page.explore.buttonLabel" />
                        </Button>
                    </div>
                    <div>
                        <Image
                            src={createImageUrl('landing-page', 'Homepage_news_desktop.png')}
                            alt="" isExternal
                        />
                    </div>
                </SplashTopExplore>
                <SplashTopPoints>
                    <MeetLuminSection />
                </SplashTopPoints>
            </React.Fragment>
        );
    }

    /**
     * Render new user splash content lower area, no dark background w/ edge padding.
     */
    renderNewUserSplashBottom(isDesktop) {
        return (
            <React.Fragment>
                {!isDesktop
                    && this.renderSplashTopContents()}
                <SplashBottomFirst>
                    <SignUpSection isWebinar={true} />
                </SplashBottomFirst>
                <SplashBottomSecond>
                    <SignUpSecondarySection isWebinar={true} />
                </SplashBottomSecond>
                <SplashBottomThird>
                    <SplashNotification
                        title={language.translations['webinar.page.splashBanner.title']}
                        url="/home"
                        buttonLabel={language.translations['webinar.page.splashBanner.buttonLabel']}
                    />
                </SplashBottomThird>
            </React.Fragment>
        );
    }

    /**
     * Render confirmation page for a brand new user (signed up through webinar landing)
     * @param {*} isLive
     * @param {*} isDesktop
     */
    renderRegisteredNewUser(isLive, isDesktop) {
        const trueDate = this.props.webinarMeta ? moment(this.props.webinarMeta.content.startTimestamp).valueOf() : null;

        //set store to only show this version once ever
        store.set(STORE_SHOW_NEWUSER_VERSION + this.props.email, true);

        return (
            <React.Fragment>
                <StylesSplashImage isNewUser={true}>
                    <Image
                        src={'/api/content-service/assets/content-image?filename=/' + this.props.webinarMeta.content.image}
                    />
                </StylesSplashImage>
                <StylesTxtWrapperContainer isNewUser={true}>
                    <StylesTxtWrapper isNewUser={true}>
                        <StylesTopBorder />
                        <StylesWrapper isNewUser={true}>
                            <StylesCenterContent>

                                {isLive
                                    && <React.Fragment>
                                        <StylesDateContainer>
                                            <DateDisplay
                                                timestamp={trueDate}
                                                timezone='PST'
                                                dataPage="landingPage"
                                            />
                                        </StylesDateContainer>
                                        <div>&nbsp;</div>
                                        <StylesSplashTitle>
                                            <FormattedMessage id="webinar.page.title.prefix" />
                                    &nbsp;{this.props.webinarMeta.content.title}
                                        </StylesSplashTitle>
                                        <MobileSplashTitle>
                                            <FormattedMessage id="webinar.page.title.prefix" />
                                    &nbsp;{this.props.webinarMeta.content.title}
                                        </MobileSplashTitle>
                                        <ButtonContainer>
                                            <Button
                                                type={TYPE_PRIMARY_LARGE_LARGE}
                                                to={this.props.webinarMeta.content.link}
                                                data-analytics-placement="Button : body"
                                                data-analytics-label="trackLink : button"
                                                data-analytics-id={language.translations['webinar.page.form.buttonTxt']}
                                            >
                                                <FormattedMessage id="webinar.page.form.buttonTxt" />
                                            </Button>
                                        </ButtonContainer>
                                        <StylesSplashDescription
                                            dangerouslySetInnerHTML={{ __html: this.props.webinarMeta.content.description }}
                                        />
                                    </React.Fragment>}

                                {!isLive
                                    && <React.Fragment>
                                        <StylesPageHeading>
                                            <FormattedMessage id="webinar.page.signed" />
                                        </StylesPageHeading>
                                        <StylesTextStart>
                                            <FormattedMessage id="webinar.page.startsTxt" />
                                        </StylesTextStart>
                                        <StylesCountDown>
                                            <CountDown
                                                timestamp={trueDate}
                                                timezone='PST'
                                                noDate={true}
                                                dataPage="landingPage"
                                            />
                                        </StylesCountDown>
                                        <StylesSplashTitleBottom
                                            dangerouslySetInnerHTML={{ __html: this.props.webinarMeta.content.title }}
                                        />
                                        <StylesDateContainer>
                                            <DateDisplay
                                                showWebinarPrefix={true}
                                                timestamp={trueDate}
                                                timezone='PST'
                                                dataPage="landingPage"
                                            />
                                        </StylesDateContainer>
                                        <StylesSplashDescription
                                            dangerouslySetInnerHTML={{ __html: this.props.webinarMeta.content.description }}
                                        />
                                        <ButtonContainer>
                                            <Button
                                                type={TYPE_PRIMARY_LARGE_MINI}
                                                to={`/learn/webinar/${this.props.webinarMeta.content.code}`}
                                                data-analytics-placement="Button : body"
                                                data-analytics-label="trackLink : button"
                                                data-analytics-id={language.translations['webinar.page.view.label']}
                                            >
                                                <FormattedMessage id="webinar.page.view.label" />
                                            </Button>
                                        </ButtonContainer>
                                    </React.Fragment>}
                            </StylesCenterContent>
                        </StylesWrapper>
                    </StylesTxtWrapper>
                </StylesTxtWrapperContainer>
                {isDesktop
                    && this.renderNewUserSplashTop(isLive)}
            </React.Fragment>
        );
    }

    /**
     * Render the confirmation page for a prexisting user.
     * @param {*} isLive
     */
    renderRegisteredPrexistingUser(isLive) {
        const trueDate = this.props.webinarMeta ? moment(this.props.webinarMeta.content.startTimestamp).valueOf() : null;
        const {
            urlHistoryList,
        } = this.props;
        const backLabel = (urlHistoryList.length - 2) >= 0 ? urlHistoryList[urlHistoryList.length - 2] : null;

        return (
            <StyleContainerStyles>
                <StyledBack existingUser={true}>
                    <StyledBackInner>
                        <BackToLink
                            to={backLabel !== null ? backLabel.currentUrl : '#'}
                            customLabel={wrapBackButtonLabels(backLabel)}
                            isDisable={backLabel === null}
                        />
                    </StyledBackInner>
                </StyledBack>
                <StylesContentWrapper existingUser={true}>
                    <StylesSplashImage existingUser={true}>
                        <Image
                            src={'/api/content-service/assets/content-image?filename=/' + this.props.webinarMeta.content.image}
                        />
                    </StylesSplashImage>
                    <StylesTxtWrapperContainer>
                        <StylesTxtWrapper>
                            <StylesTopBorder />
                            <StylesWrapper>
                                <StylesCenterContent>
                                    {isLive
                                        && <React.Fragment>
                                            <StylesDateContainer>
                                                <DateDisplay
                                                    timestamp={trueDate}
                                                    timezone='PST'
                                                    dataPage="landingPage"
                                                />
                                            </StylesDateContainer>
                                            <div>&nbsp;</div>
                                            <StylesSplashTitle>
                                                <FormattedMessage id="webinar.page.title.prefix" />
                                            &nbsp;{this.props.webinarMeta.content.title}
                                            </StylesSplashTitle>
                                            <MobileSplashTitle>
                                                <FormattedMessage id="webinar.page.title.prefix" />
                                            &nbsp;{this.props.webinarMeta.content.title}
                                            </MobileSplashTitle>
                                            <ButtonContainer>
                                                <Button
                                                    type={TYPE_PRIMARY_LARGE_LARGE}
                                                    to={this.props.webinarMeta.content.link}
                                                    data-analytics-placement="Button : body"
                                                    data-analytics-label="trackLink : button"
                                                    data-analytics-id={language.translations['webinar.page.form.buttonTxt']}
                                                >
                                                    <FormattedMessage id="webinar.page.form.buttonTxt" />
                                                </Button>
                                            </ButtonContainer>
                                        </React.Fragment>}
                                    {!isLive
                                        && <React.Fragment>
                                            <StylesPageHeading>
                                                <FormattedMessage id="webinar.page.signed" />
                                            </StylesPageHeading>
                                            <StylesTextStart>
                                                <FormattedMessage id="webinar.page.startsTxt" />
                                            </StylesTextStart>
                                            <StylesCountDown>
                                                <CountDown
                                                    timestamp={trueDate}
                                                    timezone='PST'
                                                    noDate={true}
                                                    dataPage="landingPage"
                                                />
                                            </StylesCountDown>
                                            <StylesTopTxt>
                                                <FormattedMessage id="webinar.page.short.description" />
                                            </StylesTopTxt>
                                            <ButtonContainer>
                                                <Button
                                                    type={TYPE_PRIMARY_LARGE_MINI}
                                                    to="/learn"
                                                    data-analytics-placement="Button : body"
                                                    data-analytics-label="trackLink : button"
                                                    data-analytics-id={language.translations['webinar.page.back']}
                                                >
                                                    <FormattedMessage id="webinar.page.back.button" />
                                                </Button>
                                            </ButtonContainer>

                                            <StylesSplashTitleBottom>
                                                <FormattedMessage id="webinar.page.title.prefix" />
                                            &nbsp;{this.props.webinarMeta.content.title}
                                            </StylesSplashTitleBottom>
                                            <StylesDateContainer>
                                                <DateDisplay
                                                    timestamp={trueDate}
                                                    timezone='PST'
                                                    dataPage="landingPage"
                                                />
                                            </StylesDateContainer>
                                        </React.Fragment>}
                                </StylesCenterContent>
                                <SplashDescription
                                    dangerouslySetInnerHTML={{ __html: this.props.webinarMeta.content.description }}
                                />
                                <SpeakerTitle>
                                    <FormattedMessage id="webinar.page.speaker" />
                                </SpeakerTitle>
                                {this.props.webinarMeta.content.speakerInformation.map((item, item_index) => (<Speaker key={item_index} item={item} dataPage="landingPage" />))}
                                {!isLive &&
                                    <VideoTitle
                                        dangerouslySetInnerHTML={{ __html: this.props.webinarMeta.content.videos[0].title }}
                                    />}
                                {!isLive &&
                                    <VideoContainer>
                                        <video
                                            controls
                                            poster={`/api/content-service/assets/content-image?filename=${this.props.webinarMeta.content.videos[0].stillImage}`}
                                        >
                                            {/* TODO replace with proper content API when BE is fixed */}
                                            <source
                                                type="video/mp4"
                                                src={` https://www.dev.capitalgroup.com${this.props.webinarMeta.content.videos[0].videoLink}`}
                                            />
                                        </video>
                                    </VideoContainer>}

                                {this.props.webinarMeta.content.ceCredit
                                    && <StylesCeCreditContainer>
                                        <StylesCeCredit>
                                            <StylesCeCreditTitle>
                                                <FormattedMessage id="webinar.page.ceCredit.existinUser" />
                                            </StylesCeCreditTitle>
                                            <img src="/images/ce-imca.png" />
                                        </StylesCeCredit>

                                        <StylesCeCreditList>
                                            <StylesCeCreditCount>1</StylesCeCreditCount>
                                            <StylesCeCreditText>
                                                <FormattedMessage id="webinar.page.ceCredits.list.first" />
                                            </StylesCeCreditText>
                                        </StylesCeCreditList>

                                        <StylesCeCreditList>
                                            <StylesCeCreditCount>2</StylesCeCreditCount>
                                            <StylesCeCreditText>
                                                <FormattedMessage id="webinar.page.ceCredits.list.second" />
                                            </StylesCeCreditText>
                                        </StylesCeCreditList>

                                        <StylesCeCreditList>
                                            <StylesCeCreditCount>3</StylesCeCreditCount>
                                            <StylesCeCreditText>
                                                <FormattedMessage id="webinar.page.ceCredits.list.third" />
                                            </StylesCeCreditText>

                                        </StylesCeCreditList>

                                    </StylesCeCreditContainer>
                                }

                            </StylesWrapper>
                        </StylesTxtWrapper>
                        <StylesbottomContainer>
                            <StylesbottomTextContainer>
                                <StyledBottomHeading>
                                    <FormattedMessage id="webinar.page.related.heading" />
                                </StyledBottomHeading>

                                <StyledBottomBody>
                                    <StyledBottomleft>
                                        <StyledBottomTitle>
                                            {this.props.webinarMeta.content.readableResources[0].title}
                                        </StyledBottomTitle>
                                        <StyledBottomText
                                            dangerouslySetInnerHTML={{ __html: this.props.webinarMeta.content.readableResources[0].description }}
                                        />
                                        <Button
                                            forceNewWindow={true}
                                            type={TYPE_PDF_LEFT_BRIGHT}
                                            to={'/api/content-service/assets/content-image?filename=' + this.props.webinarMeta.content.readableResources[0].documentLink}
                                        >
                                            <FormattedMessage id="webinar.page.download.button" />
                                        </Button>

                                    </StyledBottomleft>

                                    <StyledBottomRight>
                                        <StyledBottomTitle>
                                            {this.props.webinarMeta.content.readableResources[1].title}
                                        </StyledBottomTitle>
                                        <StyledBottomText
                                            dangerouslySetInnerHTML={{ __html: this.props.webinarMeta.content.readableResources[1].description }}
                                        />
                                        <Button
                                            type={TYPE_GHOST_RIGHT}
                                            to={this.props.webinarMeta.content.readableResources[1].documentLink}
                                            data-analytics-placement="Anchor : body"
                                            data-analytics-label="trackLink : anchor"
                                            data-analytics-id={language.translations["trendingTopicsNew.component.cta.0"]}
                                        >
                                            <FormattedMessage id="webinar.page.related.button" />
                                        </Button>
                                    </StyledBottomRight>

                                </StyledBottomBody>

                            </StylesbottomTextContainer>
                        </StylesbottomContainer>

                    </StylesTxtWrapperContainer>
                </StylesContentWrapper>
            </StyleContainerStyles>
        );
    }

    /**
     * Render the join now/live webinar header.
     */
    renderJoinHeader() {
        return (
            <StylesTxtWrapperContainer>
                <StylesTxtWrapper>
                    <StylesTopBorder />
                    <StylesWrapper>
                        <StylesCardType>
                            <FormattedMessage id="webinar.page.type" />
                        </StylesCardType>
                        <StylesSplashTitle
                            dangerouslySetInnerHTML={{ __html: this.props.webinarMeta.title }}
                        />
                        <MobileSplashTitle
                            dangerouslySetInnerHTML={{ __html: this.props.webinarMeta.title }}
                        />
                        <ButtonContainer>
                            <Button
                                type={TYPE_PRIMARY_LARGE_LARGE}
                                to={this.props.webinarMeta.link}
                                data-analytics-placement="Button : body"
                                data-analytics-label="trackLink : button"
                                data-analytics-id={language.translations['webinar.page.form.buttonTxt']}
                            >
                                <FormattedMessage id="webinar.page.form.buttonTxt" />
                            </Button>
                        </ButtonContainer>
                        <StylesSplashDescription
                            dangerouslySetInnerHTML={{ __html: this.props.webinarMeta.description }}
                        />
                    </StylesWrapper>
                </StylesTxtWrapper>
            </StylesTxtWrapperContainer>
        );
    }

    /**
     * TODO: remove this version? not used..
     * Render the signed-in version of the webinar landing.
     */
    renderSignedInVersion() {
        return (
            <StyleContainerStyles>
                <StyledBack>
                    <Button
                        type={TYPE_GHOST_LEFT_WHITE}
                        to="/learn"
                        data-analytics-placement="Button : body"
                        data-analytics-label="trackLink : button"
                        data-analytics-id={language.translations['webinar.page.back']}
                    >
                        <FormattedMessage id="webinar.page.back" />
                    </Button>
                </StyledBack>
                <StylesContentWrapper>
                    <StylesSplashImage>
                        <Image
                            src={'/api/content-service/assets/content-image?filename=/' + this.props.webinarMeta.image}
                        />
                    </StylesSplashImage>
                    <StylesTxtWrapperContainer>
                        <StylesTxtWrapper>
                            <StylesWrapper>
                                <StylesCardType>
                                    <FormattedMessage id="webinar.page.type" />
                                </StylesCardType>
                                <StylesSplashTitle
                                    dangerouslySetInnerHTML={{ __html: this.props.webinarMeta.title }}
                                />
                                <MobileSplashTitle
                                    dangerouslySetInnerHTML={{ __html: this.props.webinarMeta.title }}
                                />
                                <ButtonContainer>
                                    <Button
                                        type={TYPE_PRIMARY_LARGE_LARGE}
                                        to=" "
                                        data-analytics-placement="Button : body"
                                        data-analytics-label="trackLink : button"
                                        data-analytics-id={language.translations['webinar.page.form.buttonTxt']}
                                    >
                                        <FormattedMessage id="webinar.page.form.buttonTxt" />
                                    </Button>
                                </ButtonContainer>
                                <StylesSplashDescription
                                    dangerouslySetInnerHTML={{ __html: this.props.webinarMeta.description }}
                                />
                            </StylesWrapper>
                        </StylesTxtWrapper>
                    </StylesTxtWrapperContainer>
                </StylesContentWrapper>
            </StyleContainerStyles>
        );
    };

    /**
     *  Render Claim Your spot page
     */
    renderClaimYourSpot(isDesktop, breakpoint) {
        const {
            urlHistoryList,
        } = this.props;

        const backLabel = (urlHistoryList.length - 2) >= 0 ? urlHistoryList[urlHistoryList.length - 2] : null;

        return (
            <StyledContainer>
                <StyledBack>
                    <StyledBackInner>
                        <BackToLink
                            to={backLabel !== null ? backLabel.currentUrl : '#'}
                            customLabel={wrapBackButtonLabels(backLabel)}
                            isDisable={backLabel === null}
                        />
                    </StyledBackInner>
                </StyledBack>
                <TitleContainer>
                    <ColorBar hasBackButton={true} />
                    <Title>
                        <FormattedMessage id="webinar.page.title" />
                        <br /><FormattedMessage id="webinar.page.title2" />
                    </Title>
                </TitleContainer>
                <StyledContentWrapper>
                    <Sections>
                        {this.renderMetaDetails(true, breakpoint)}
                        <FormContainer isClaimSpot={true}>
                            <TabletOnly>
                                <FormTitle isClaimSpot={true} id={breakpoint !== MOBILE ? "claim" : ""}>
                                    <FormattedMessage id="webinar.claimYourSpot.title" />
                                </FormTitle>
                                <FormDescription>
                                    <FormattedMessage id="webinar.claimYourSpot.subtitle" />
                                </FormDescription>
                            </TabletOnly>
                            {isDesktop
                                && <Button
                                    to="#"
                                    onClick={() => { this.processClaimYourSpot(); this.handleDismiss(); }}
                                    data-analytics-placement="Button : body"
                                    data-analytics-label="trackLink : button"
                                    data-analytics-id={language.translations['webinar.claimYourSpot']}
                                >
                                    <FormattedMessage id="webinar.claimYourSpot" />
                                </Button>}
                            {!isDesktop
                                && <Button
                                    to="#"
                                    onClick={() => { this.processClaimYourSpot(); this.handleDismiss(); }}
                                    data-analytics-placement="Button : body"
                                    data-analytics-label="trackLink : button"
                                    data-analytics-id={language.translations['webinar.claimYourSpot']}
                                >
                                    <FormattedMessage id="webinar.claimYourSpot" />
                                </Button>}
                        </FormContainer>
                    </Sections>
                </StyledContentWrapper>
            </StyledContainer>
        );
    }

    renderPostWebinar(isLive) {

        if (!this.props.isAuthenticated) {
            store.set(REDIRECT_AFTER_LOGIN_URL, window.location.href);

            window.location = "/sign-in";
        }

        if (!this.props.postWebinarDataLoaded && this.props.webinarMeta.eventId && this.state.apiStatus == 1) {

            this.props.getWebinarInfoWithEventId(this.props.webinarMeta.content.code);
            this.setState({
                apiStatus: 2
            })
        }
        const trueDate = this.props.webinarMeta ? moment(this.props.webinarMeta.content.startTimestamp).valueOf() : null;
        const {
            urlHistoryList,
        } = this.props;
        const backLabel = (urlHistoryList.length - 2) >= 0 ? urlHistoryList[urlHistoryList.length - 2] : null;

        return (
            <StyleContainerStyles>
                <StyledBack existingUser={true}>
                    <StyledBackInner>
                        <BackToLink
                            to={backLabel !== null ? backLabel.currentUrl : '#'}
                            customLabel={wrapBackButtonLabels(backLabel)}
                            isDisable={backLabel === null}
                        />
                    </StyledBackInner>
                </StyledBack>
                <StylesContentWrapper existingUser={true}>
                    <StylesSplashImage existingUser={true}>
                        <Image
                            src={'/api/content-service/assets/content-image?filename=/' + this.props.webinarMeta.content.image}
                        />
                    </StylesSplashImage>
                    <StylesTxtWrapperContainer>
                        <StylesTxtWrapper>
                            <StylesTopBorder />
                            <StylesWrapper>
                                <StylesCenterContent>
                                    <React.Fragment>
                                        <StylesDateContainer>
                                            <DateDisplay
                                                timestamp={trueDate}
                                                timezone='PST'
                                                dataPage="landingPage"
                                            />
                                        </StylesDateContainer>
                                        <StylesSplashTitleBottom>
                                            <FormattedMessage id="webinar.page.title.prefix" />
                                        &nbsp;{this.props.webinarMeta.content.title}
                                        </StylesSplashTitleBottom>
                                    </React.Fragment>
                                </StylesCenterContent>
                                <SplashDescription
                                    dangerouslySetInnerHTML={{ __html: this.props.webinarMeta.content.description }}
                                />
                                <SpeakerTitle>
                                    <FormattedMessage id="webinar.page.speaker" />
                                </SpeakerTitle>
                                {this.props.webinarMeta.content.speakerInformation.map((item, item_index) => (<Speaker key={item_index} item={item} dataPage="landingPage" />))}
                                {this.props.webinarMeta.content.videos &&
                                    (this.props.webinarMeta.content.videos.length >= 2) &&
                                    <VideoTitle
                                        dangerouslySetInnerHTML={{ __html: this.props.webinarMeta.content.videos[1].title }}
                                    />}
                                {this.props.webinarMeta.content.videos &&
                                    (this.props.webinarMeta.content.videos.length >= 2) &&
                                    <VideoContainer>
                                        <video
                                            controls
                                            poster={`/api/content-service/assets/content-image?filename=${this.props.webinarMeta.content.videos[1].stillImage}`}
                                        >
                                            {/* TODO replace with proper content API when BE is fixed */}
                                            <source
                                                type="video/mp4"
                                                src={` https://www.dev.capitalgroup.com${this.props.webinarMeta.content.videos[1].videoLink}`}
                                            />
                                        </video>

                                        <DownloadTrans>
                                            <Button
                                                forceNewWindow={true}
                                                type={TYPE_DOWNLOAD_LEFT_BRIGHT}
                                                to={'#'}
                                            >
                                                <FormattedMessage id="webinar.postWebinar.downloadTranscript" />
                                            </Button>
                                        </DownloadTrans>
                                    </VideoContainer>}

                                {this.props.webinarMeta.content.videos &&
                                    (this.props.webinarMeta.content.videos.length <= 1) &&
                                    <VideoReplacementContainer>
                                        <ComeBackHeading>
                                            <FormattedMessage id="webinar.postWebinar.comeBackHeading" />
                                        </ComeBackHeading>

                                        <ComeBackText>
                                            <FormattedMessage id="webinar.postWebinar.comeBackText" />
                                        </ComeBackText>
                                    </VideoReplacementContainer>
                                }

                                {this.props.webinarMeta.content.ceCredit
                                    && <StylesCeCreditContainer>
                                        <StylesCeCredit>
                                            <StylesCeCreditTitle>
                                                <FormattedMessage id="webinar.page.ceCredit.existinUser" />
                                            </StylesCeCreditTitle>
                                            <img src="/images/ce-imca.png" />
                                        </StylesCeCredit>

                                        <StylesCeCreditList>
                                            <StylesCeCreditCount>1</StylesCeCreditCount>
                                            <StylesCeCreditText>
                                                <FormattedMessage id="webinar.page.ceCredits.list.first" />
                                            </StylesCeCreditText>
                                        </StylesCeCreditList>

                                        <StylesCeCreditList>
                                            <StylesCeCreditCount>2</StylesCeCreditCount>
                                            <StylesCeCreditText>
                                                <FormattedMessage id="webinar.page.ceCredits.list.second" />
                                            </StylesCeCreditText>
                                        </StylesCeCreditList>

                                        <StylesCeCreditList>
                                            <StylesCeCreditCount>3</StylesCeCreditCount>
                                            <StylesCeCreditText>
                                                <FormattedMessage id="webinar.page.ceCredits.list.third" />
                                            </StylesCeCreditText>

                                        </StylesCeCreditList>

                                        <QuizButtonContainer>
                                            <Button
                                                to="#"
                                                onClick={() => { }}
                                            >
                                                <FormattedMessage id="webinar.postWebinar.quiz" />
                                            </Button>
                                        </QuizButtonContainer>

                                    </StylesCeCreditContainer>
                                }

                            </StylesWrapper>
                        </StylesTxtWrapper>
                        <StylesbottomContainer>
                            <StylesbottomTextContainer>
                                <StyledBottomHeadingMain>
                                    <FormattedMessage id="webinar.postWebinar.listHeading" />
                                </StyledBottomHeadingMain>
                                <StyledBottomUL>
                                    <StyledBottomLI>
                                        <FormattedMessage id="webinar.postWebinar.listContent" />
                                    </StyledBottomLI>
                                    <StyledBottomLI>
                                        <FormattedMessage id="webinar.postWebinar.listContent" />
                                    </StyledBottomLI>
                                    <StyledBottomLI>
                                        <FormattedMessage id="webinar.postWebinar.listContent" />
                                    </StyledBottomLI>
                                </StyledBottomUL>
                                <StyledBottomHeading>
                                    <FormattedMessage id="webinar.page.related.heading" />
                                </StyledBottomHeading>

                                <StyledBottomBody>
                                    <StyledBottomleft>
                                        <StyledBottomTitle>
                                            {this.props.webinarMeta.content.readableResources[0].title}
                                        </StyledBottomTitle>
                                        <StyledBottomText
                                            dangerouslySetInnerHTML={{ __html: this.props.webinarMeta.content.readableResources[0].description }}
                                        />
                                        <Button
                                            forceNewWindow={true}
                                            type={TYPE_PDF_LEFT_BRIGHT}
                                            to={'/api/content-service/assets/content-image?filename=' + this.props.webinarMeta.content.readableResources[0].documentLink}
                                        >
                                            <FormattedMessage id="webinar.page.download.button" />
                                        </Button>

                                    </StyledBottomleft>

                                    <StyledBottomRight>
                                        <StyledBottomTitle>
                                            {this.props.webinarMeta.content.readableResources[0].title}
                                        </StyledBottomTitle>
                                        <StyledBottomText
                                            dangerouslySetInnerHTML={{ __html: this.props.webinarMeta.content.readableResources[0].description }}
                                        />
                                        <Button
                                            type={TYPE_GHOST_RIGHT}
                                            to={this.props.webinarMeta.content.readableResources[0].documentLink}
                                            data-analytics-placement="Anchor : body"
                                            data-analytics-label="trackLink : anchor"
                                            data-analytics-id={language.translations["trendingTopicsNew.component.cta.0"]}
                                        >
                                            <FormattedMessage id="webinar.page.related.button" />
                                        </Button>
                                    </StyledBottomRight>

                                </StyledBottomBody>

                            </StylesbottomTextContainer>
                        </StylesbottomContainer>

                    </StylesTxtWrapperContainer>
                </StylesContentWrapper>
            </StyleContainerStyles>
        );
    }


    /**
     * Render refresh error screen for when there's no webinar data from BE payload.
     */
    renderNoDataDisplay() {
        if (this.props.isAuthenticated || (this.props.webinarDataError && !this.props.isLoading)) {
            return (
                <WebinarLandingError
                    noDataVersion={true}
                />
            );
        } else {
            store.set(REDIRECT_AFTER_LOGIN_URL, window.location.href);

            window.location = "/sign-in";
        }
    }

    /**
     * Handle dimissal from webinar page.
     */
    handleDismiss = () => {
        //let nextDecision = 'NOT_INTERESTED_IN_HALF_HOUR';

        // if (this.state.status === 'PREVIOUS_DAY') {
        //    this.props.dismissWebinar(this.props.webinarMeta.eventId, nextDecision);
        // }
    }

    getNextDecision = () => {
        const Status = {
            FUTURE: "1",
            PREVIOUS_DAY: "2",
            IN_HALF_HOUR: "3",
            LIVE: "4",
            PAST: "5",
        };

        const Decision = {
            NOT_DECIDED: 'ND',
            NOT_INTERESTED: 'NOT_INTERESTED',
            NOT_INTERESTED_PREVIOUS_DAY: 'NOT_INTERESTED_PREVIOUS_DAY',
            NOT_INTERESTED_IN_HALF_HOUR: 'NOT_INTERESTED_IN_HALF_HOUR',
            NOT_INTERESTED_LIVE: 'NOT_INTERESTED_LIVE',
        }

        let nextDecision = Decision.NOT_INTERESTED;

        if (this.props.webinarMeta && this.props.webinarMeta.content) {
            const { startTimestamp, endTimestamp } = this.props.webinarMeta.content;
            let newStatus = Status.FUTURE;
            const now = moment();
            const tomorrow = moment().add(1, 'day').startOf('day');
            const twoDaysOut = moment(startTimestamp).subtract(1, 'day').startOf('day');
            const start = moment(startTimestamp);
            const end = moment(endTimestamp);

            if (now.isAfter(end)) {
                newStatus = Status.PAST;
                clearInterval(this.interval);
            }
            else if (now.isBetween(start, end)) newStatus = Status.LIVE;
            else if (moment.duration(start.diff(now)).asMinutes() <= 30) newStatus = Status.IN_HALF_HOUR;
            else if (start.isAfter(tomorrow, 'day')) newStatus = Status.FUTURE;
            else if (now >= twoDaysOut) newStatus = Status.PREVIOUS_DAY

            status = newStatus;

            if (status === Status.PREVIOUS_DAY) nextDecision = Decision.NOT_INTERESTED_PREVIOUS_DAY;
            else if (status === Status.IN_HALF_HOUR) nextDecision = Decision.NOT_INTERESTED_IN_HALF_HOUR;
            else if (status === Status.LIVE) nextDecision = Decision.NOT_INTERESTED_LIVE;
        }

        return nextDecision;
    }

    /**
     * Find the proper next descission flag and register the user for webinar.
     */
    processClaimYourSpot = () => {
        let nextDecision = this.getNextDecision();

        this.props.claimYourSpot(nextDecision);
    }
    /**
     * Render this page and underlying components.
     */
    render() {
        let isRegistered = this.props.isRegisterd === true ? true : this.props.webinarMeta ? (this.props.webinarMeta.advisorId ? true : false) : false;

        let isFirstLoginViaSignup = this.props.isAuthenticated
            ? this.props.isFirstLogin ?
                true : false
            : true;

        if (this.props.webinarDataError && !this.props.isLoading) {
            return (
                <React.Fragment>
                    {this.renderNoDataDisplay()}
                </React.Fragment>
            );
        }

        if (!this.props.webinarMeta || this.props.isLoading) {
            return <Loader />
        }

        let curUrl = window.location.href;
        let webIDFromCUrl = curUrl.split("/");
        let idCUrl = webIDFromCUrl[webIDFromCUrl.length - 1];
        if (idCUrl[idCUrl.length - 1] === "#")
            idCUrl = idCUrl.slice(0, idCUrl.length - 1);

        if (idCUrl !== this.props.webinarMeta.content.code) {
            return (
                <React.Fragment>
                    {this.renderNoDataDisplay()}
                </React.Fragment>
            );
        }

        setTimeout(() => {
            this.setState({
                isContentLoaded: true
            })
        }, 3000);

        let hasSeenNewUserVersion = store.get(STORE_SHOW_NEWUSER_VERSION + this.props.email);
        const startDate = moment(this.props.webinarMeta.content.startTimestamp).valueOf();
        const endDate = moment(this.props.webinarMeta.content.endTimestamp).valueOf();
        const currentDate = moment().valueOf();
        let isLive = (currentDate > startDate && currentDate < endDate);
        let isPastWebinar;


        isPastWebinar = currentDate > endDate;

        if (!this.props.isAuthenticated && isPastWebinar) {
            store.set(REDIRECT_AFTER_LOGIN_URL, window.location.href);

            window.location = "/sign-in";
        }

        if (!this.state.isContentLoaded) {
            return <Loader />
        }



        const promoteDate = moment(this.props.webinarMeta.content.dateToPromote).valueOf();

        if (currentDate < promoteDate) {
            return (
                <React.Fragment>
                    {this.renderNoDataDisplay()}
                </React.Fragment>
            );
        }

        if (this.state.checkValue == 0) {
            if ((currentDate - (48 * 60 * 60 * 1000)) < startDate) {
                this.setState({
                    status: 'PREVIOUS_DAY'
                })

            }
            this.setState({
                checkValue: 1
            })
        }


        /*isFirstLoginViaSignup = false;
        hasSeenNewUserVersion = false;
        isLive = false;
        isRegistered = true;*/

        //TODO!!! if webinar is PAST end date, we show:
        //POST-WEBINAR-PAGE (current date is > that webinar endDate constant above)

        return (
            <BreakpointsContext.Consumer>
                {(breakpoint) => {

                    const isDesktop = breakpoint === DESKTOP;

                    return (
                        <React.Fragment>
                            <PageContainer>
                                {(isRegistered && isFirstLoginViaSignup && !hasSeenNewUserVersion && !isPastWebinar)
                                    && this.renderRegisteredNewUser(isLive, isDesktop)}
                                {(isRegistered && (!isFirstLoginViaSignup || hasSeenNewUserVersion) && !isPastWebinar)
                                    && this.renderRegisteredPrexistingUser(isLive)}
                                {(!isRegistered && this.props.isAuthenticated && !isPastWebinar)
                                    && this.renderClaimYourSpot(isDesktop, breakpoint)}
                                {(!isRegistered && !this.props.isAuthenticated && !isPastWebinar)
                                    && this.renderNotSignedInVersion()}
                                {isPastWebinar &&
                                    this.renderPostWebinar(isLive)
                                }
                            </PageContainer>
                            {(isRegistered && (isFirstLoginViaSignup && !hasSeenNewUserVersion))
                                && <div>
                                    {this.renderNewUserSplashBottom(isDesktop)}
                                </div>}
                        </React.Fragment>
                    );
                }}
            </BreakpointsContext.Consumer>
        );
    }
}

WebinarLanding.propTypes = {
    image: PropTypes.string,
    timestamp: PropTypes.string,
    clearErrors: PropTypes.func.isRequired,
    onSignUp: PropTypes.func.isRequired,
    scrollToTop: PropTypes.func.isRequired,
};

WebinarLanding.defaultProps = {
};

const mapStateToProps = state => ({
    isAuthenticated: state.signIn.isAuthenticated,
    webinarMeta: state.webinar.webinar,
    postWebinarDataLoaded: state.webinar.postWebinarDataLoaded,
    webinarDataError: state.webinar.webinarDataError,
    isLoading: state.webinar.loading,
    isRegisterd: state.webinar.userRegistered,
    userSources: state.basicProfile.sourceIds,
    userTopics: state.basicProfile.topicIds,
    isFirstLogin: state.basicProfile.firstLogin === "YES",
    hasErrors: state.webinar.hasErrors,
    requestingUserRegister: state.webinar.requestingUserRegister,
    email: state.basicProfile.email,
    webinarFailedToLoad: state.webinar.webinarFailedToLoad,
    currentUrlStep: state.UrlHistoryReducer.currentStep,
    urlHistoryList: state.UrlHistoryReducer.history,
});

const mapDispatchToProps = dispatch => ({
    getIsUserRegistered: () => dispatch(getWebinarIsUserRegistered()),
    getWebinarInfo: () => dispatch(getWebinarInfo()),
    getWebinarInfoWithEventId: id => dispatch(getWebinarInfoWithEventId(id)),
    scrollToTop: () => dispatch(setScrollToTop()),
    clearErrors: () => dispatch(clearErrorsAction()),
    claimYourSpot: (decision) => dispatch(claimYourSpot(decision)),
    onSignUp: (values, actions, webinarData) => dispatch(signUp(values, actions, window.identityData, true, webinarData)),
    dismissWebinar: (eventId, descision) => dispatch(notInterested(eventId, descision)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(WebinarLanding));