import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    DISCLAIMER_STANDARD,
    DISCLAIMER_SECONDARY,
    DISCLAIMER_TERTIARY,
    DISCLAIMER_NONE,
} from 'state-management/constants/globalDisclaimer';
import {
    getDisclaimerFragment,
    setDisclaimerType as setDisclaimerTypeAction,
} from 'state-management/actions/globalDisclaimer';
import GuestsOnlyRoute from 'containers/GuestsOnlyRoute/GuestsOnlyRoute';
import PrivateRoute from 'containers/PrivateRoute/PrivateRoute';

/**
 * Global route switch between private and guest.
 * @param {*} param0 
 */
const RouteSwitch = ({ isAuthenticated, ...otherProps }) => (
    isAuthenticated
        ? <PrivateRoute {...otherProps} skipOnboardedCheck />
        : <GuestsOnlyRoute {...otherProps} />
);

const PM_PATH = "/learn";
const PM_DETAILS_PATH = "/learn/collections/";

/**
 * Wrapping class for connected application routes.
 */
export class ApplicationRoute extends React.Component {
    /**
     * Assign proper disclaimer to the actual page being displayed.
     */
    componentDidMount() {
        this.switchDisclaimer();
    }

    /**
     * Adjust disclaimers based upon users new route/page.
     * @param {*} prevProps 
     */
    componentDidUpdate(prevProps) {
        const { disclaimerType, authenticatedOnlyDisclaimerType, path } = this.props;

        if (disclaimerType !== prevProps.disclaimerType
            || authenticatedOnlyDisclaimerType !== prevProps.authenticatedOnlyDisclaimerType) {
            this.switchDisclaimer();
        }

        this.clearSessionStorageActiveTabPM(path);
    }

    /**
     * Reset active tab tracking.
     * @param {*} currentRoutePath 
     */
    clearSessionStorageActiveTabPM(currentRoutePath) {
        if (currentRoutePath != null && (currentRoutePath.indexOf(PM_DETAILS_PATH) == -1 || currentRoutePath.indexOf(PM_PATH) == -1)) {
            sessionStorage.removeItem("ACTIVE_TAB_INDEX");
        }
    }

    /**
     * Handle actual switching of disclaimers.
     */
    switchDisclaimer = () => {
        const {
            disclaimerFragments,
            disclaimerType,
            authenticatedOnlyDisclaimerType,
            getDisclaimer,
            isAuthenticated,
            setDisclaimerType
        } = this.props;

        const disclaimer = isAuthenticated && authenticatedOnlyDisclaimerType
            ? authenticatedOnlyDisclaimerType
            : disclaimerType;

        if (disclaimer !== DISCLAIMER_NONE && !disclaimerFragments[disclaimer]) {
            getDisclaimer(disclaimer);
        }
        
        setDisclaimerType(disclaimer);
    };

    /**
     * Render this and underlying components.
     */
    render() {
        const { disclaimerType, routeComponent, ...otherProps } = this.props;
        const RouteComponent = routeComponent || Route;

        return <RouteComponent {...otherProps} />;
    }
}

ApplicationRoute.propTypes = {
    disclaimerType: PropTypes.oneOf([
        DISCLAIMER_STANDARD,
        DISCLAIMER_SECONDARY,
        DISCLAIMER_TERTIARY,
        DISCLAIMER_NONE,
    ]),
    authenticatedOnlyDisclaimerType: PropTypes.oneOf([
        DISCLAIMER_STANDARD,
        DISCLAIMER_SECONDARY,
        DISCLAIMER_TERTIARY,
        DISCLAIMER_NONE,
    ]),
    isAuthenticated: PropTypes.bool.isRequired,
    routeComponent: PropTypes.object,
    disclaimerFragments: PropTypes.shape({}).isRequired,
    getDisclaimer: PropTypes.func.isRequired,
    setDisclaimerType: PropTypes.func.isRequired,
};

ApplicationRoute.defaultProps = {
    disclaimerType: DISCLAIMER_NONE,
    authenticatedOnlyDisclaimerType: undefined,
    routeComponent: undefined,
};

const mapStateToProps = state => ({
    disclaimerFragments: state.globalDisclaimer.fragments,
    isAuthenticated: state.signIn.isAuthenticated,
});

const mapDispatchToProps = dispatch => ({
    getDisclaimer: type => dispatch(getDisclaimerFragment(type)),
    setDisclaimerType: type => dispatch(setDisclaimerTypeAction(type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationRoute);
