import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isPasswordFormatValid from 'utils/validators/passwordValidator';
import { isEmailValid } from 'utils/validators/emailValidator';
import LegalFDIC from 'components/LegalFDIC/LegalFDIC';
import {
    FormattedMessage,
    injectIntl,
} from 'react-intl';
import anime from 'animejs';
import labels from '../../../lang/en.json';
import { GLOBAL } from 'utils/variables';
import PasswordRules, {
    handlePasswordBlur,
    handlePasswordBlurByEmail,
    handlePasswordChange,
} from 'components/PasswordRules/PasswordRules';
import {
    BreakpointsContext,
    MOBILE,
} from 'components/Breakpoints/Breakpoints';
import Link from 'components/atomics/atoms/Link/Link';
import { Link as ReactLink } from 'react-router-dom';
import Button from 'components/atomics/atoms/Button/Button';
import { TYPE_SECONDARY_MEDIUM_LARGE } from 'components/atomics/atoms/Button/Button';
import Input from 'components/atomics/atoms/Input/Input';
import { TYPE_XLARGE } from 'components/atomics/atoms/Input/Input';
import { TYPE_GHOST } from 'components/atomics/atoms/Button/Button';
import styled from 'styled-components';
import { Container } from 'components';
import { IconCRDEnvelope } from 'components/atomics/atoms/Icons/Icons';
import { linkStyles } from 'containers/SignUp/styles';
import {
    contentContainerStyles,
    titleStyles,
    mockLinkStyles,
    messageStyles,
    formContainerStyles,
    formOuterContainerStyles,
    formContentStyles,
    formRowStyles,
} from 'containers/EmailVerification/ConfirmEmail/styles';
import { ROUTES } from '../../App/constants';
import storage from "../../../utils/store";
import {
    SIGNUP_EMAIL,
    SIGNUP_FNAME,
} from 'state-management/sagas/signUp';
import {
    resendEmail,
    changeEmail,
} from 'state-management/actions/signUp';

export const EMAIL_CHANGED = 'emailAlreadyChanged';

const StyledLink = styled(ReactLink)`
    ${linkStyles};
`;

const StyledContentContainer = styled(Container)`
    ${contentContainerStyles}
`;

const Title = styled.h1`
    ${titleStyles}
`;

const Message = styled.div`
    ${messageStyles}
`;

const MockLink = styled.a`
    ${mockLinkStyles}
`;

const FormContainer = styled.div`
    ${formContainerStyles}
`;

const FormOuterContainer = styled.div`
    ${formOuterContainerStyles}
`;

const FormContent = styled.div`
    ${formContentStyles}
`;

const FormRow = styled.div`
    ${formRowStyles}
`;

const SIGNIN_TIMEOUT_TIME = 900000;

/**
 * Confirm email page (inform the user to check their email).
 */
export class ConfirmEmail extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.state = {
            formOpen: false,
            isUpdated: false,
            signinTimeout: null,
            passwordFormatRules: {},
            emailError: false,
            confirmError: false,
            passwordError: false,
            submitEnabled: false,
            duplicateError: false,
            isSubmitting: false,
        };

        this.resendEmail = this.resendEmail.bind(this);
        this.submitEmailChange = this.submitEmailChange.bind(this);
        this.openChangeEmailForm = this.openChangeEmailForm.bind(this);
        this.closeChangeEmailForm = this.closeChangeEmailForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.clearEmailError = this.clearEmailError.bind(this);
        this.handleEmailBlur = this.handleEmailBlur.bind(this);
        this.handleConfirmPasswordBlur = this.handleConfirmPasswordBlur.bind(this);
        this.clearConfirmError = this.clearConfirmError.bind(this);
        this.handlePasswordBlur = this.handlePasswordBlur.bind(this);
        this.clearPasswordError = this.clearPasswordError.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);

        this.formRef = React.createRef();
        this.linkRef = React.createRef();
    }

    /**
     * Set a timeout for 15min after this page loads up.
     */
    componentDidMount() {
        this.setState({
            signinTimeout: setTimeout(() => {
                window.location = '/sign-in';
            }, SIGNIN_TIMEOUT_TIME),
        });
    }

    /**
     * Listen for successful email address change so we can update the display.
     * @param {*} nextProps 
     */
    componentDidUpdate(prevProps) {
        if (prevProps.lastChange !== this.props.lastChange
            && this.props.lastChange !== 500
            && typeof (this.props.lastChange) === 'number') {
            this.setState({
                isUpdated: true,
                isSubmitting: false,
            }, () => {
                this.clearChangeEmailForm();
                this.closeChangeEmailForm();
            });
        }

        if (prevProps.failed !== this.props.failed
            && this.props.failed && !this.state.duplicateError) {
            const emailField = document.getElementById('email');

            this.setState({
                duplicateError: true,
                emailError: true,
                isSubmitting: false,
            }, () => {
                emailField.focus();
                emailField.scrollIntoView();
            });
        }

        if (prevProps.failed !== this.props.failed
            && !this.props.failed) {
            this.setState({
                isSubmitting: false,
            });
        }
    }

    /**
     * Remove error state from email field.
     */
    clearEmailError() {
        if (!this.state.duplicateError) {
            this.setState({
                emailError: false,
            });
        }
    }

    /**
     * Clear duplicate email error on change.
     */
    handleEmailChange() {
        this.setState({
            duplicateError: false,
            emailError: false,
        });

        this.handleEnableSubmit();
    }

    /**
     * Actively handle changes to the password field.
     * @param {*} e 
     */
    handlePasswordChange(e) {
        const { passwordFormatRules } = this.state;
        const email = document.getElementById('email');
        const updatedPasswordRules = (
            handlePasswordChange(e.event, passwordFormatRules, null, email ? email.value : null)
        );

        this.setState({ passwordFormatRules: updatedPasswordRules });

        this.handleEnableSubmit();
    }

    /**
     * Show the change email address form.
     */
    openChangeEmailForm() {
        if (!this.formRef || !this.formRef.current) {
            return;
        }

        this.animation = anime.timeline();
        this.animation2 = anime.timeline();

        this.formRef.current.style.display = 'block';
        this.formRef.current.style.height = '1px';

        const formHeight = this.formRef.current.getAttribute('data-display') == MOBILE ? 742 : 642;

        //expand form
        this.animation
            .add({
                targets: this.formRef.current,
                easing: [0.53, 0.05, 0.01, 0.97],
                opacity: [0, 1],
                duration: GLOBAL.ANIMATIONS.COLLAPSE_TIME.MILISECONDS,
                height: formHeight,
            });

        //collapse cta link
        this.animation2
            .add({
                targets: this.linkRef.current,
                easing: [0.53, 0.05, 0.01, 0.97],
                opacity: [1, 0],
                duration: GLOBAL.ANIMATIONS.COLLAPSE_TIME.MILISECONDS,
                height: 0,
            });
    }

    /**
     * Hide the change email address form.
     */
    closeChangeEmailForm() {
        this.animation = anime.timeline();
        this.animation2 = anime.timeline();

        //expand form
        this.animation
            .add({
                targets: this.formRef.current,
                easing: [0.53, 0.05, 0.01, 0.97],
                opacity: [1, 0],
                duration: GLOBAL.ANIMATIONS.COLLAPSE_TIME.MILISECONDS,
                height: 0,
            });

        if (!this.linkRef || !this.linkRef.current) {
            return;
        }

        //collapse cta link
        this.animation2
            .add({
                targets: this.linkRef.current,
                easing: [0.53, 0.05, 0.01, 0.97],
                opacity: [0, 1],
                duration: GLOBAL.ANIMATIONS.COLLAPSE_TIME.MILISECONDS,
                height: 60,
            });
    }

    /**
     * Submit a possible email change for user.
     * @param {string} value
     */
    submitEmailChange(value, password) {
        this.props.changeEmail(value, storage.get(SIGNUP_EMAIL), password);

        storage.set(EMAIL_CHANGED, true);
    }

    /**
     * Validate email on blur if populated.
     * @param {*} e 
     */
    handleEmailBlur(e) {
        const email = e.target.value;

        if (email.length && !isEmailValid(email)) {
            this.setState({
                emailError: true,
            });
        }

        const passwordElement = document.getElementById('password');

        if (passwordElement.value.length) {
            this.setState({
                passwordFormatRules: handlePasswordBlurByEmail(passwordElement, this.state.passwordFormatRules, true, email),
            });
        }

        this.handleEnableSubmit();
    }

    /**
     * Validate password on blur.
     * @param {*} e 
     */
    handlePasswordBlur(e) {
        const emailElement = document.getElementById('email');

        this.setState({
            passwordFormatRules: handlePasswordBlur(e, this.state.passwordFormatRules, true, emailElement ? emailElement.value : null),
        });
        this.handleConfirmPasswordBlur(e)
        this.handleEnableSubmit();
    }

    /**
     * Remove password error.
     */
    clearPasswordError() {
        this.setState({
            passwordError: false,
        });
    }

    /**
     * Validate confirm password on blur.
     * @param {*} e 
     */
    handleConfirmPasswordBlur(e) {
        const password = document.getElementById('password').value;
        const confirm = document.getElementById('confirmPassword').value;

        if (confirm.length && password != confirm) {
            this.setState({
                confirmError: true,
            });
        } else {
            this.setState({
                confirmError: false,
            });
        }

        this.handleEnableSubmit();
    }

    /**
     * Remove confirm password error.
     */
    clearConfirmError() {
        this.setState({
            confirmError: false,
        });
    }

    /**
     * Erase the values in the change email form.
     */
    clearChangeEmailForm() {
        document.getElementById('email').value = '';
        document.getElementById('password').value = '';
        document.getElementById('confirmPassword').value = '';

        this.setState({
            submitEnabled: false,
        });
    }

    /**
     * Handle form submission.
     */
    handleSubmit() {
        if (this.state.isSubmitting === true) {
            return;
        }

        this.setState({
            isSubmitting: true,
        }, () => {
            const email = document.getElementById('email').value;
            const password = document.getElementById('password').value;
            const confirm = document.getElementById('confirmPassword').value;
            let isErrored = false;

            if (!isPasswordFormatValid(password, email)) {
                this.setState({
                    passwordError: true,
                });

                isErrored = true;
            }

            if (!email.length || !isEmailValid(email)) {
                this.setState({
                    emailError: true,
                });

                isErrored = true;
            }

            if (confirm != password) {
                this.setState({
                    confirmError: true,
                });

                isErrored = true;
            }

            if (!isErrored) {
                this.submitEmailChange(email, password);
            }

            //lets rely on prop updates from reducer instead of this.
            /*setTimeout(() => {
                this.setState({
                    isSubmitting: false,
                });
            }, 2000);*/
        });
    }

    /**
     * Handle enable/disable of submit button.
     */
    handleEnableSubmit() {
        const email = document.getElementById('email').value;
        const password = document.getElementById('password').value;
        const confirm = document.getElementById('confirmPassword').value;
        let isErrored = false;

        if (!isPasswordFormatValid(password, email)) {
            isErrored = true;
        }

        if (!email.length || !isEmailValid(email)) {
            isErrored = true;
        }

        if (confirm != password) {
            isErrored = true;
        }

        this.setState({
            submitEnabled: !isErrored,
        });
    }

    /**
     * Send action to have API resend email.
     */
    resendEmail() {
        const email = storage.get(SIGNUP_EMAIL);

        this.props.requestEmail(email ? email : '');
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const fname = storage.get(SIGNUP_FNAME);
        const email = storage.get(SIGNUP_EMAIL);
        const changedEmail = storage.get(EMAIL_CHANGED);
        const dupeError = <FormattedMessage
            id="error.okta.validation.user.exists"
            values={{
                link: (
                    <StyledLink to="/contact-us">
                        <FormattedMessage id="error.okta.validation.user.exists.link" />
                    </StyledLink>
                ),
            }}
        />

        return (
            <BreakpointsContext.Consumer>
                {(breakpoint) => {
                    return (
                        <React.Fragment>
                            <StyledContentContainer isTop>
                                <Title>
                                    {this.state.isUpdated
                                        && <FormattedMessage id='signup.verification.titleUpdated' />}
                                    {(fname !== null && fname.length && !this.state.isUpdated)
                                        && <FormattedMessage id='signup.verification.title' values={{ name: fname }} />}
                                    {(fname === null || !fname.length && !this.state.isUpdated)
                                        && <FormattedMessage id='signup.verification.titleNoEmail' />}
                                </Title>
                                <IconCRDEnvelope />
                                <Message isTop>
                                    <span id="message">
                                        <FormattedMessage id='signup.verification.sentTo' />
                                        <b>{email}</b>
                                    </span>
                                    {!changedEmail
                                        && <div
                                            ref={this.linkRef}
                                        >
                                            <br />
                                            <Button
                                                type={TYPE_GHOST}
                                                onClick={this.openChangeEmailForm}
                                                to="#changeEmail"
                                            >
                                                <FormattedMessage id='signup.verification.change' />
                                            </Button>
                                        </div>}
                                </Message>
                            </StyledContentContainer>

                            <FormOuterContainer ref={this.formRef} data-display={breakpoint}>
                                <FormContainer>
                                    <h3>
                                        <FormattedMessage id="signup.verification.form.title" />
                                    </h3>
                                    <FormContent>
                                        <FormRow
                                            margin={breakpoint !== MOBILE}
                                            setHeight={breakpoint !== MOBILE}
                                        >
                                            <Input
                                                id="email"
                                                type={TYPE_XLARGE}
                                                autocomplete="off"
                                                placeholder={labels.translations['signUp.field.email']}
                                                label={labels.translations['signUp.field.email']}
                                                hideLabelOnPlaceholder={true}
                                                error={this.state.duplicateError ? dupeError : labels.translations['validation.error.email']}
                                                onFocus={this.clearEmailError}
                                                onBlur={this.handleEmailBlur}
                                                onChange={this.handleEmailChange}
                                                isErrored={this.state.emailError}
                                            />
                                        </FormRow>
                                        {breakpoint !== MOBILE
                                            && <FormRow margin setHeight>
                                                <Input
                                                    id="password"
                                                    inputType="password"
                                                    autocomplete="off"
                                                    type={TYPE_XLARGE}
                                                    onChange={this.handlePasswordChange}
                                                    onBlur={this.handlePasswordBlur}
                                                    onFocus={this.clearPasswordError}
                                                    placeholder={labels.translations['signUp.field.password']}
                                                    label={labels.translations['signUp.field.password']}
                                                    hideLabelOnPlaceholder={true}
                                                />
                                                <Input
                                                    id="confirmPassword"
                                                    inputType="password"
                                                    onFocus={this.clearConfirmError}
                                                    onBlur={this.handleConfirmPasswordBlur}
                                                    type={TYPE_XLARGE}
                                                    error={labels.translations['validation.error.passwordMatch']}
                                                    isErrored={this.state.confirmError}
                                                    placeholder={labels.translations['signUp.field.passwordVerification']}
                                                    label={labels.translations['signUp.field.passwordVerification']}
                                                    hideLabelOnPlaceholder={true}
                                                />
                                            </FormRow>}

                                        {breakpoint === MOBILE
                                            && <><FormRow>
                                                <Input
                                                    id="password"
                                                    inputType="password"
                                                    autocomplete="off"
                                                    type={TYPE_XLARGE}
                                                    onChange={this.handlePasswordChange}
                                                    onBlur={this.handlePasswordBlur}
                                                    onFocus={this.clearPasswordError}
                                                    placeholder={labels.translations['signUp.field.password']}
                                                    label={labels.translations['signUp.field.password']}
                                                    hideLabelOnPlaceholder={true}
                                                />
                                            </FormRow>
                                                <FormRow margin setHeight>
                                                    <Input
                                                        id="confirmPassword"
                                                        inputType="password"
                                                        onFocus={this.clearConfirmError}
                                                        onBlur={this.handleConfirmPasswordBlur}
                                                        type={TYPE_XLARGE}
                                                        error={labels.translations['validation.error.passwordMatch']}
                                                        isErrored={this.state.confirmError}
                                                        placeholder={labels.translations['signUp.field.passwordVerification']}
                                                        label={labels.translations['signUp.field.passwordVerification']}
                                                        hideLabelOnPlaceholder={true}
                                                    />
                                                </FormRow></>}

                                        <FormRow>
                                            <PasswordRules passwordRules={this.state.passwordFormatRules} />
                                        </FormRow>
                                        <FormRow rightAlign>
                                            <Button
                                                type={TYPE_SECONDARY_MEDIUM_LARGE}
                                                noAnchor
                                                onClick={this.closeChangeEmailForm}
                                            >
                                                <FormattedMessage id="profilePage.button.cancel" />
                                            </Button>
                                            <Button
                                                onClick={this.handleSubmit}
                                                noAnchor
                                                disabled={(!this.state.submitEnabled && !this.state.isSubmitting)}
                                            >
                                                <FormattedMessage id="profilePage.button.save" />
                                            </Button>
                                        </FormRow>
                                    </FormContent>
                                </FormContainer>
                            </FormOuterContainer>

                            <StyledContentContainer>
                                <Message>
                                    <br /><br />
                                    <FormattedMessage id='signup.verification.quesiton' />
                                    <br />
                                    <MockLink
                                        href="#resend"
                                        onClick={this.resendEmail}
                                    >
                                        <FormattedMessage id='signup.verification.click' />
                                    </MockLink>
                                    <FormattedMessage id='signup.verification.or' />
                                    <Link
                                        to={ROUTES.CONTACT_US}
                                    >
                                        <FormattedMessage id='signup.verification.contact' />
                                    </Link>.
                                    <br /><br />
                                    <br /><br />
                                    <b id="disclosure">
                                        <FormattedMessage id='signup.verification.disclosure' />
                                    </b>
                                </Message>
                            </StyledContentContainer>
                            <LegalFDIC />
                        </React.Fragment>
                    );
                }}
            </BreakpointsContext.Consumer>
        );
    }
}

ConfirmEmail.propTypes = {
    email: PropTypes.string,
};

ConfirmEmail.defaultProps = {
    email: null,
};

const mapStateToProps = state => ({
    lastChange: state.signUp.lastChangeTime,
    failed: state.signUp.changeEmailFailed,
});

const mapDispatchToProps = dispatch => ({
    requestEmail: (email) => dispatch(resendEmail(email)),
    changeEmail: (email, oldEmail, password) => dispatch(changeEmail(email, oldEmail, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ConfirmEmail));
