import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    toastControlsWrapperStyles,
    toastTitleStyles,
    toastWrapperStyles,
    rightControls,
    lowerControls,
} from './styles';

export const TYPE_RIGHT_CONTROLS = "type/rightControls";
export const TYPE_LOWER_CONTROLS = "type/lowerControls";

const StyledToastWrapper = styled.div`
    ${toastWrapperStyles};
`;
const StyledToastTitle = styled.span`
    ${toastTitleStyles};
`;
const StyledToastControlsWrapper = styled.div`
    ${toastControlsWrapperStyles};
`;

const StyledRightControls = styled.div`
    ${rightControls}
`;

const StyledLowerControls = styled.div`
    ${lowerControls}
`;

/**
 * Creates toast notification generic controls.
 * @param {*} param0 
 */
const ToastControls = ({ children, className }) => (
    <StyledToastControlsWrapper
        className={className}
    >
        {children}
    </StyledToastControlsWrapper>
);

ToastControls.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    className: PropTypes.string,
};

ToastControls.defaultProps = {
    children: null,
    className: '',
};

/**
 * Creates the title element for a toast.
 * @param {*} param0 
 */
const ToastTitle = ({ children, className, type }) => (
    <StyledToastTitle
        type={type}
        className={className}
    >
        {children}
    </StyledToastTitle>
);

/**
 * Creates right hand controls for a toast.
 * @param {*} param0 
 */
const RightControls = ({ children }) => (
    <StyledRightControls>
        <div />
        <div>
            {children}
        </div>
        <div />
    </StyledRightControls>
);

/**
 * Creates bottom controls for a toast.
 * @param {*} param0 
 */
const LowerControls = ({ children }) => (
    <StyledLowerControls>
        <div />
        <div>
            {children}
        </div>
        <div />
    </StyledLowerControls>
);

ToastTitle.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    className: PropTypes.string,
    type: PropTypes.string,
};

ToastTitle.defaultProps = {
    children: null,
    className: '',
    type: TYPE_RIGHT_CONTROLS,
};

/**
 * Creates a toast notification (popup, i.e. when saving or following items).
 * @param {*} props 
 */
const Toast = (props) => {
    const { children, className, id, type, smallMobile } = props;

    return (
        <StyledToastWrapper
            type={type}
            className={className}
            id={id}
            smallMobile={smallMobile}
        >
            <div>
                {children}
            </div>
        </StyledToastWrapper>
    );
};

Toast.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    className: PropTypes.string,
    id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    type: PropTypes.string,
    smallMobile: PropTypes.bool,
};

Toast.defaultProps = {
    children: null,
    className: '',
    id: 'toast',
    type: TYPE_RIGHT_CONTROLS,
    smallMobile: false,
};

Toast.Controls = ToastControls;
Toast.Title = ToastTitle;
Toast.RightControls = RightControls;
Toast.LowerControls = LowerControls;

export default Toast;
