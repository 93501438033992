import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'components/Link/Link';
import {
    itemStyles, linkStyles, listStyles,
} from 'components/CollectionsList/styles';

const StyledList = styled.ul`
    ${listStyles}
`;

const StyledListItem = styled.li`
    ${itemStyles}
`;

const StyledLink = styled(Link)`
    ${linkStyles}
`;

const getGroupName = (grpName) => {
    if (grpName === "CLIENT_LIFE_EVENTS") {
        return "client-life-events";
    } else if (grpName === "PRACTICE_MILESTONES") {
        return "practice-milestones";
    }
}


const CollectionsList = (props) => {
    const {
        collections,
        onItemClick
    } = props;

    return (
        <StyledList
        >
            {
                collections.map((collection) => {
                    return (
                        <StyledListItem
                            key={collection.code}
                            onClick={() => {
                                onItemClick(`/learn/collections/${getGroupName(collection.group)}/${collection.code}`, collection.title)
                            }}
                        >
                            <StyledLink
                                to={`/learn/collections/${getGroupName(collection.group)}/${collection.code}`}
                            >
                                {collection.title}
                            </StyledLink>
                        </StyledListItem>
                    );
                })
            }
        </StyledList>
    );
};

CollectionsList.propTypes = {
    collections: PropTypes.arrayOf(PropTypes.shape({})),
};

CollectionsList.defaultProps = {
    collections: [],
    onItemClick: () => {},
};

export default CollectionsList;
