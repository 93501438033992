import React from 'react';

import {
    SOURCE_HEADING_TRANSLATION_ID,
    SOURCE_QUERY,
} from 'state-management/constants/pageableNews';
import LandingContainer from '../../containers/PageableNews/LandingContainer';

/**
 * Source landing page.
 * @param {*} props 
 */
const SourceLandingContainer = props => {
    return (
        <LandingContainer
            {...props}
            query={SOURCE_QUERY}
            headingTranslationId={SOURCE_HEADING_TRANSLATION_ID}
            type="source"
        />
    )
};

export default SourceLandingContainer;
