import React from 'react';
import PropTypes from 'prop-types';
import LinkList from 'components/atomics/molecules/LinkList/LinkList';
import { primary, sideMenu } from 'containers/PrivateLayout/routes';
import { intl } from 'test/helpers/intlHelpers';

export class LinkListContainerEXAMPLE extends React.Component {
    render() {
        const translate = id => intl.formatMessage({ id });

        const translateMenuTitles = routes => routes.map(route => ({
            ...route,
            title: translate(route.title),
        }));

        const primaryRoutes = translateMenuTitles(sideMenu);

        return (
            <LinkList
                routes={primaryRoutes}
                selectedNav={this.props.selectedNav}
                handleChange = {() => { 
                    if (this.props.handleChange) {
                        this.props.handleChange();
                    }
                }}
            />
        );
    }
}

LinkListContainerEXAMPLE.propTypes = {
    selectedNav: PropTypes.string,
};

LinkListContainerEXAMPLE.defaultProps = {
    selectedNav:"styles"
};

export default (LinkListContainerEXAMPLE);
