import React from 'react';
import { Link as InternalLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    defaultLinkStyles,
    indicatorStyles,
    backToTopIconStyles,
    linkTitleStyles,
} from 'components/atomics/atoms/Link/styles';
import { IconArrowDown } from 'components/atomics/atoms/Icons/Icons';
import { MOUSE_MIDDLE } from 'utils/keyCodes';
import scrollHelper from 'utils/scrollToTop';
import { contextHubRecordEvent } from 'utils/contextHub';
import { connect } from 'react-redux';
import { showLoader } from 'state-management/actions/guide';
import { hideLoader } from 'state-management/actions/guide';

const StyledExternalLink = styled.a`
    ${defaultLinkStyles};
`;

const StyledInternalLink = styled(InternalLink)`
    ${defaultLinkStyles};
`;

export const StyledIndicator = styled.div`
    ${indicatorStyles}
`;

export const StyledBackToTopIcon = styled(IconArrowDown)`
    ${backToTopIconStyles}
`;

export const StyledLinkTitle = styled.div`
    ${linkTitleStyles}
`;

const TYPE_BACK_TO_TOP = 'type/BackTotop';

const handleMouseDown = (e, handler) => {
    if (e.nativeEvent.which === MOUSE_MIDDLE) {
        handler();
    }
};

/**
 * Creates a visible link artifact.
 * @param {*} props 
 */
class Link extends React.Component {

    render() {
        const {
            children,
            className,
            location,
            to,
            onClick,
            staticContext,
            type,
            title,
            ...other
        } = this.props;
        const isExternalLink = to && to.startsWith('http');
        const isEmailLink = to && to.startsWith('mailto:');
        const isDownloadLink = to && to.startsWith('itms-services:');
        const currentPath = location.pathname + location.search + location.hash;
        const isSamePath = to === currentPath;
        const isImageLink = type === TYPE_BACK_TO_TOP ? true : false;

        if (isExternalLink || isEmailLink || isDownloadLink) {
            const target = isExternalLink ? '_blank' : '_self';

            return (
                <StyledExternalLink
                    className={className}
                    href={to}
                    target={target}
                    onMouseDown={e => handleMouseDown(e, onClick)}
                    onClick={onClick}
                    rel="noopener noreferrer"
                >
                    {children}
                </StyledExternalLink>
            );
        }

        /**
         * Handle internal link clicks, scroll to top if same page.
         */
        const handleInternalClick = () => {
            if (isSamePath) {
                scrollHelper.scrollToTop();
                let that = this;
                setTimeout(function () {
                    try {
                        that.props.hideLoaderOnTimer();
                    } catch (e) {
                        //console.log(e);
                    }
                }, 1000);
                this.props.showLoaderOnClick();
            }

            if (!isSamePath && window.location.pathname.includes('/allTopics')) {
                CH_handleComponentEventList();
            }
            onClick();
        };

        //analytics 
        const CH_handleComponentEventList = () => {
            var tracking =
            {
                "type": "anchor",
                "action": this.props.children.props.children,
            };
            contextHubRecordEvent(tracking);
        };

        if (isImageLink === true) {
            return (
                <StyledInternalLink
                    className={className}
                    to={to}
                    onClick={handleInternalClick}
                    onMouseDown={e => handleMouseDown(e, onClick)}
                    replace={isSamePath}
                    {...other}
                >
                    <StyledIndicator>
                        <StyledBackToTopIcon />
                    </StyledIndicator>
                </StyledInternalLink>
            );
        } else {
            return (
                <StyledInternalLink
                    className={className}
                    to={to}
                    onClick={handleInternalClick}
                    onMouseDown={e => handleMouseDown(e, onClick)}
                    replace={isSamePath}
                    {...other}
                >
                    {children}
                </StyledInternalLink>
            );
        }

    };

}

Link.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    location: PropTypes.shape({
        hash: PropTypes.string,
        pathname: PropTypes.string,
        search: PropTypes.string,
    }).isRequired,
    to: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    type: PropTypes.string,
    title: PropTypes.string
};

Link.defaultProps = {
    className: '',
    children: null,
    onClick: () => { },
    type: 'default',
    title: ''
};

const mapDispatchToProps = dispatch => ({
    showLoaderOnClick: () => { dispatch(showLoader()) },
    hideLoaderOnTimer: () => { dispatch(hideLoader()) },
});

export default withRouter(connect(null, mapDispatchToProps)(Link));
