import * as type from 'state-management/constants/search';

export function changePage(page) {
    return {
        type: type.CHANGE_PAGE,
        page,
    };
}

export function open() {
    return {
        type: type.OPEN,
    };
}

export function close() {
    return {
        type: type.CLOSE,
    };
}

export function setSearchLoading() {
    return {
        type: type.SEARCH_LOADING,
    };
}

export function setSearchSuccess(items, params = {}) {
    return {
        type: type.SEARCH_SUCCESS,
        items,
        ...params,
    };
}

export function setSearchError(toggleLoading = true) {
    return {
        type: type.SEARCH_ERROR,
        toggleLoading,
    };
}

export function startSearch(value, params = {}) {
    return {
        type: type.SEARCH_START,
        value,
        ...params,
    };
}

export function searchRedirect(value, params = {}) {
    return {
        type: type.SEARCH_REDIRECT,
        value,
        ...params,
    };
}
