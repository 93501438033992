import React from 'react';
import PropTypes, { arrayOf } from 'prop-types';
import styled from 'styled-components';
import Link from 'components/atomics/atoms/Link/Link';
import ImageAtom from 'components/atomics/atoms/Image/Image';
import moment from 'moment';
import { connect } from 'react-redux';
import { visitTTArticle } from 'state-management/actions/articles.common';
import { handleGuideVisit } from 'state-management/actions/guide';
import { getArticleUrl, getArticleImage } from 'utils/getArticleAsset';
import {
    containerStyles,
    imageStyles,
    contentContainerStyles,
    dateAndTopicContainerStyles,
    dateContainerStyles,
    topicLinkStyles,
    titleStyles,
    imageContainerStyles,
    childArticleContainer,
    parentArticleContainer,
    kebabWrapperStyles,
    topicTagStyles,
    outerContainer,
} from 'components/atomics/molecules/TrendingTopicCard/styles';
import { TOPIC_LINK } from 'state-management/constants/topics';
import {
    IconLightning,
} from 'components/atomics/atoms/Icons/Icons';
import { contextHubRecordEvent } from 'utils/contextHub';
import {
    CARDS_EVENT_LIST,
} from 'utils/contextHubEventListConstants';
import ListContentCardFooter from "../ListContentCard/ListContentCardFooter";
import {
    toggleSaveArticle,
    toggleSaveGuide as toggleSaveGuideAction,
} from "../../../../state-management/actions/save";
import { toggleTopicFollow } from "../../../../state-management/actions/followTopic";
import { toggleSourceFollow } from "../../../../state-management/actions/sources";
import { dismissCoachMark } from "../../../../state-management/actions/coachMark";
import { getTruncatedString } from 'utils/contentCardUtils';
import language from '../../../../lang/en.json';
import storage from 'utils/store';

export const OuterContainer = styled.div`
    ${outerContainer}
`;

export const TopicTag = styled(Link)`
    ${topicTagStyles}
`;

export const ImageContainer = styled(Link)`
    ${imageContainerStyles}
`;

export const ChildArticle = styled.div`
    ${childArticleContainer}
`;

export const ParentArticle = styled.div`
    ${parentArticleContainer}
`;

export const Title = styled(Link)`
    ${titleStyles}
`;

export const DateAndTopic = styled.div`
    ${dateAndTopicContainerStyles}
`;

export const DateContainer = styled.div`
    ${dateContainerStyles}
`;

export const TopicLink = styled(Link)`
    ${topicLinkStyles}
`;

export const Container = styled.div`
    ${containerStyles}
`;

export const Image = styled(ImageAtom)`
    ${imageStyles}
`;

export const ContentContainer = styled.div`
    ${contentContainerStyles}
`;

const MAX_ARTICLE_TITLE_CHARS = 75;

const PROVIDER_LEXIS = 'LEXIS_NEXIS';

export const TYPE_TT_SMALL = 'small';
export const TYPE_TT_MEDIUM = 'medium';
export const TYPE_TT_LARGE = 'large';

/**
 * Creates a trending topics content card.
 */
class TrendingTopicCard extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.onArticleClick = this.onArticleClick.bind(this);
        this.renderArticle = this.renderArticle.bind(this);
        this.CH_handleComponentEventList = this.CH_handleComponentEventList.bind(this);
        this.getArticleTopics = this.getArticleTopics.bind(this);
    }

    /**
     * Default state.
     */
    state = {
        onFirstCardHover: false,
        onSecondCardHover: false,
    }

    /**
     * Handle article clicks.
     */
    onArticleClick(article) {
        const { onArticleClick, onGuideClick, shouldAllowClickArticleTracking } = this.props;
        const { id, licensed, sourceName, title, topics } = article;
        if (article.interactionType === 'advisorLikeYou' && article.licensed == true) {
            storage.set('advisorArticle', storage.get('modelData').find(a => a.includes(article.id)))
        }
        if (shouldAllowClickArticleTracking) {
            onArticleClick(article);
            return;
        }
    }

    /**
     * Render additional articles.
     * @param {*} article
     */
    renderArticle(article, index) {
        const linkUrl = getArticleUrl(article, true, storage.get('modelData'));
        const topics = this.getArticleTopics(article);
        const { onSecondCardHover } = this.state;
        const { isActiveCoachMark, onCoachMarkDismiss } = this.props;

        return (
            <ChildArticle key={index} className={`${article.visited ? (article.visited === true ? 'news-visited' : 'news-not-visited') : 'news-not-visited'}`}
                onMouseEnter={() => this.setState({ onSecondCardHover: true })}
                onMouseLeave={() => this.setState({ onSecondCardHover: false })}>
                <ContentContainer>
                    <DateAndTopic>
                        <DateContainer>
                            {moment.utc(article.date).format('MMM D, YYYY')}
                        </DateContainer>
                    </DateAndTopic>
                    <Title
                        type={this.props.type}
                        to={linkUrl}
                        onClick={() => {
                            this.CH_handleComponentEventList(article);
                            this.onArticleClick(article);
                        }}
                        className={`${article.visited ? (article.visited === true ? 'news-visited' : 'news-not-visited') : 'news-not-visited'}`}
                    >
                        {getTruncatedString(article.title, MAX_ARTICLE_TITLE_CHARS)}
                    </Title>
                    <ListContentCardFooter
                        onElement={onSecondCardHover}
                        standardDisplayOfSource={true}
                        profile={this.props.profile}
                        sourceName={article.sourceName}
                        providerName={article.provider}
                        article={article}
                        kebabButtonClickListener={() => isActiveCoachMark ? onCoachMarkDismiss() : null}
                        topics={topics}
                        hideSourceOption={!article.sourceId}
                        sourceId={article.sourceId}
                        topicName={this.props.article.topicName}
                        saved={article.saved}
                        articleId={parseInt(article.id)}
                        articleTitle={article.title}
                        type={article.type || article.contentType}
                        shouldShowToast={this.props.shouldShowToast}
                        onSaveArticle={(id, save, title, getContentAfterSave, article) => {
                            this.saveArticle(id, save, title, getContentAfterSave, article);
                        }}
                        onSaveGuide={(id, save, title, getContentAfterSave, article) => {
                            this.saveGuide(id, save, title, getContentAfterSave, article);
                        }}
                        onFollowTopic={(topicId, status) => {
                            this.followTopic(topicId, status);
                        }}
                        onFollowSource={(sourceId, status) => {
                            this.followSource(sourceId, status);
                        }}
                    />
                </ContentContainer>
            </ChildArticle>
        );
    }

    /**
     * Handle analytics.
     * @param {*} clickedArticle 
     */
    CH_handleComponentEventList(clickedArticle) {
        const article = clickedArticle ? clickedArticle : this.props.article;

        contextHubRecordEvent(CARDS_EVENT_LIST(
            'article',
            article.title,
            article.topicName,
            article.sourceName
        ));
    }

    /**
     * Reduce article topics to the single main displayed topic so follow/unfollow reflect for each item in a single card.
     */
    getArticleTopics(article) {
        const topics = [];
        var self = this;

        Object.keys(article.topics).forEach(function (key, index) {
            const topic = article.topics[index];

            if (topic.id == self.props.article.topicId) {
                topics.push(topic);
            }
        });

        return topics;
    }

    /**
     * Estimate if payload is healthy.
     */
    isPayloadOkay() {
        let flag = true;

        if (!this.props.article.id) {
            flag = false;
        }

        if (!this.props.article.sourceName) {
            flag = false;
        }

        return flag;
    }

    /**
     * Set hover state.
     */
    onMouseEnter = () => {
        this.setState({ onFirstCardHover: true });
    }

    /**
     * Remove hover state.
     */
    onMouseLeave = () => {
        this.setState({ onFirstCardHover: false });
    }

    /**
     * Handle save guide action.
     */
    saveGuide = (id, save, title, getContentAfterSave, article) => {
        const { toggleSaveGuide } = this.props;

        toggleSaveGuide(id, save, title, getContentAfterSave, article);
    }

    /**
     * Handle follow topic action.
     */
    followTopic = (id, status) => {
        const { toggleFollowTopic } = this.props;

        toggleFollowTopic(id, status);
    }

    /**
     * Handle follow source action.
     */
    followSource = (id, status) => {
        const { toggleFollowSource } = this.props;

        toggleFollowSource(id, status);
    }

    /**
     * Handle save article action.
     */
    saveArticle = (id, save, title, getContentAfterSave, article) => {
        const { toggleSaveArticle } = this.props;

        toggleSaveArticle(id, save, title, getContentAfterSave, article);
    }

    /**
     * Render the trending topic content card and underlying components.
     */
    render() {
        if (!this.isPayloadOkay()) {
            return null;
        }
        const linkUrl = getArticleUrl(this.props.article, true, storage.get('modelData'));
        const imageUrl = getArticleImage(this.props.article);
        const { onFirstCardHover } = this.state;
        const { isActiveCoachMark, onCoachMarkDismiss } = this.props;

        const topics = this.getArticleTopics(this.props.article);

        return (
            <OuterContainer data-rel="StandardContentCard"
            >
                <TopicTag
                    to={TOPIC_LINK(`${this.props.article.topicName}/${this.props.article.topicId}`)}
                    data-analytics-placement="Anchor : body"
                    data-analytics-label="trackLink : anchor"
                    data-analytics-id={"topic:" + this.props.article.topicName + ":trendingtopics"}
                >
                    <IconLightning />
                    {this.props.article.topicName}
                </TopicTag>
                <Container type={this.props.type}   >
                    <ParentArticle onMouseEnter={this.onMouseEnter}
                        onMouseLeave={this.onMouseLeave}
                        className={`${this.props.article.visited ? (this.props.article.visited === true ? 'news-visited' : 'news-not-visited') : 'news-not-visited'}`}>
                        <ImageContainer
                            type={this.props.type}
                            to={linkUrl}
                            className={`${this.props.article.visited ? (this.props.article.visited === true ? 'news-visited' : 'news-not-visited') : 'news-not-visited'}`}
                            onClick={() => {
                                this.CH_handleComponentEventList();
                                this.onArticleClick(this.props.article)
                            }}
                        >
                            <Image
                                src={imageUrl}
                                alt={language.translations['global.alt.image']}
                            />
                        </ImageContainer>

                        <ContentContainer>
                            <DateAndTopic>
                                <DateContainer>
                                    {moment.utc(this.props.article.date).format('MMM D, YYYY')}
                                </DateContainer>
                            </DateAndTopic>
                            <Title
                                type={this.props.type}
                                to={linkUrl}
                                onClick={() => {
                                    this.CH_handleComponentEventList();
                                    this.onArticleClick(this.props.article)
                                }}
                                className={`${this.props.article.visited ? (this.props.article.visited === true ? 'news-visited' : 'news-not-visited') : 'news-not-visited'}`}
                            >
                                {getTruncatedString(this.props.article.title, MAX_ARTICLE_TITLE_CHARS)}
                            </Title>

                            <ListContentCardFooter
                                standardDisplayOfSource={true}
                                onElement={onFirstCardHover}
                                profile={this.props.profile}
                                sourceName={this.props.article.sourceName}
                                providerName={this.props.article.provider}
                                article={this.props.article}
                                topics={topics}
                                hideSourceOption={!this.props.article.sourceId}
                                kebabButtonClickListener={() => isActiveCoachMark ? onCoachMarkDismiss() : null}
                                sourceId={this.props.article.sourceId}
                                topicName={this.props.article.topicName}
                                saved={this.props.article.saved}
                                articleId={parseInt(this.props.article.id)}
                                articleTitle={this.props.article.title}
                                type={this.props.article.type || this.props.article.contentType}
                                shouldShowToast={this.props.shouldShowToast}
                                onSaveArticle={(id, save, title, getContentAfterSave, article) => {
                                    this.saveArticle(id, save, title, getContentAfterSave, article);
                                }}
                                onSaveGuide={(id, save, title, getContentAfterSave, article) => {
                                    this.saveGuide(id, save, title, getContentAfterSave, article);
                                }}
                                onFollowTopic={(topicId, status) => {
                                    this.followTopic(topicId, status);
                                }}
                                onFollowSource={(sourceId, status) => {
                                    this.followSource(sourceId, status);
                                }}
                            />
                        </ContentContainer>
                    </ParentArticle>
                    {this.props.articles.length > 0
                        && this.renderArticle(this.props.articles[0])}
                </Container>
            </OuterContainer>
        );
    }
}

TrendingTopicCard.propTypes = {
    requestUpdate: PropTypes.func,
    article: PropTypes.shape({
        topics: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string,
            ]).isRequired,
            followed: PropTypes.bool,
            name: PropTypes.string,
        })),
        contentId: PropTypes.string,
        date: PropTypes.string,
        topicName: PropTypes.string,
        topicId: PropTypes.number,
        title: PropTypes.string,
        image: PropTypes.string,
        sourceName: PropTypes.string,
        sourceId: PropTypes.number,
        provider: PropTypes.string,
        id: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        url: PropTypes.string,
        licensed: PropTypes.bool,
        saved: PropTypes.bool,
        contentType: PropTypes.string,
        interactionId: PropTypes.string,
        visited: PropTypes.bool
    }).isRequired,
    articles: PropTypes.arrayOf(
        PropTypes.shape({
            topics: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.string,
                ]),
                followed: PropTypes.bool,
                name: PropTypes.string,
            })),
            contentId: PropTypes.string,
            date: PropTypes.string,
            topicName: PropTypes.string,
            topicId: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string,
            ]),
            title: PropTypes.string,
            image: PropTypes.string,
            sourceName: PropTypes.string,
            sourceId: PropTypes.number,
            provider: PropTypes.string,
            id: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string,
            ]),
            url: PropTypes.string,
            visited: PropTypes.bool,
            licensed: PropTypes.bool,
            saved: PropTypes.bool,
        }),
    ),
    shouldAllowClickArticleTracking: PropTypes.bool,
    type: PropTypes.string,
    handleTrandingTopicCoruptedData: PropTypes.func,
};

TrendingTopicCard.defaultProps = {
    articles: [],
    article: {
        url: null,
    },
    shouldAllowClickArticleTracking: true,
    type: TYPE_TT_SMALL,
    requestUpdate: null,
    handleTrandingTopicCoruptedData: () => { },
}

const mapDispatchToProps = dispatch => ({
    onArticleClick: article => dispatch(visitTTArticle(article)),
    onGuideClick: id => dispatch(handleGuideVisit(id)),
    toggleSaveArticle: (id, save, title, getContentAfterSave, article) => dispatch(toggleSaveArticle(id, save, {
        title,
        getContentAfterSave,
        article
    })),
    toggleSaveGuide: (id, save, title, shouldShowToast) => dispatch(toggleSaveGuideAction(id, save, {
        title,
        shouldShowToast
    })),
    toggleFollowTopic: (topicId, isFollowing) => dispatch(toggleTopicFollow(topicId, isFollowing)),
    toggleFollowSource: (sourceId, isFollowing) => (
        dispatch(toggleSourceFollow(sourceId, isFollowing))
    ),
    onCoachMarkDismiss: () => dispatch(dismissCoachMark('KEBAB', true)),

});

const mapState = state => ({
    isLoading: state.followTopic.toggleInProgress,
    profile: state.basicProfile,
    isActiveCoachMark: state.coachMark.display.KEBAB,
});

export default connect(mapState, mapDispatchToProps)(TrendingTopicCard);