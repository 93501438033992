import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import FooterSide from 'containers/Guide/Footer/FooterSide';
import Link from 'components/Link/Link';

import {
    footerContainerStyles,
    footerSideContainerStyles,
} from 'containers/Guide/Footer/styles';

import * as analytics from 'utils/adobeAnalytics';

import {
    EVENT_ACTION_GUIDE_NAV,
    PAGE_NAME_GUIDE,
    SITE_SECTION_GUIDE,
} from 'utils/analyticsConstants';

const StyledFooterContainer = styled.div`
    ${footerContainerStyles}
`;

const FooterLink = ({ hideOnMobile, ...otherProps }) => (otherProps.to
    ? <Link {...otherProps} />
    : <span aria-disabled="true" className={otherProps.className}>{otherProps.children}</span>);

export const StyledFooterSideContainer = styled(FooterLink)`
    ${footerSideContainerStyles}
`;

/**
 * Render the guide page footer (above global footer).
 * @param {*} param0 
 */
const Footer = ({ previous, next }) => {
    const handleOnClick = (e, guideCode, title) => {
        if (guideCode === '') {
            e.preventDefault();
            return;
        }
        analytics.dispatchPageActionTrigger(
            PAGE_NAME_GUIDE,
            SITE_SECTION_GUIDE,
            EVENT_ACTION_GUIDE_NAV,
            guideCode,
            title,
        );
    };
    const handlePreviousOnClick = e => handleOnClick(e, previous.guideCode, previous.title);
    const handleNextOnClick = e => handleOnClick(e, next.guideCode, next.title);

    return (
        <StyledFooterContainer>
            <StyledFooterSideContainer
                aria-disabled={!previous.category}
                hideOnMobile={!previous.category}
                to={encodeURIComponent(previous.guideCode)}
                onClick={handlePreviousOnClick}
            >
                <FooterSide
                    side="previous"
                    pointerText="guidePage.footer.previous"
                    category={previous.category}
                    title={previous.title}
                />
            </StyledFooterSideContainer>
            <StyledFooterSideContainer
                aria-disabled={!next.category}
                hideOnMobile={!next.category}
                to={encodeURIComponent(next.guideCode)}
                onClick={handleNextOnClick}
            >
                <FooterSide
                    side="next"
                    pointerText="guidePage.footer.next"
                    category={next.category}
                    title={next.title}
                />
            </StyledFooterSideContainer>
        </StyledFooterContainer>
    );
};

Footer.propTypes = {
    previous: PropTypes.shape({
        category: PropTypes.string,
        title: PropTypes.string,
        guideCode: PropTypes.string.isRequired,
    }).isRequired,
    next: PropTypes.shape({
        category: PropTypes.string,
        title: PropTypes.string,
        guideCode: PropTypes.string.isRequired,
    }).isRequired,
};

export default Footer;
