import {
    put, takeLatest, call, all,
} from 'redux-saga/effects';
import {
    getAllTopicsSuccess,
    getAllTopicsFailure,
    getTopAllTopicsSuccess,
    getTopAllTopicsFailure,
    getTrendingAllTopicsSuccess,
    getTrendingAllTopicsFailure,
    getSeeMoreTrendingSuccess,
    getSeeMoreTrendingFailure,
} from 'state-management/actions/allTopics.common';
import {
    GET_TRENDING_ALLTOPICS,
    TRENDING_ALLTOPICS_URL,
    GET_TOP_ALLTOPICS,
    TOP_ALLTOPICS_URL,
    GET_ALLTOPICS,
    ALLTOPICS_URL,
    TRENDING_ALLTOPICS_URL_EXPERIENCE,
    SEEMORE_TRENDING,
    TRENDING_ALLTOPICS_URL_WM,
    TRENDING_ALLTOPICS_URL_RETIREMENT,
} from 'state-management/constants/allTopics.common';
import Api from '../bridge/api';
import { SEEMORE_TRENDING_URL } from '../constants/allTopics.common';
import {
    contextHubRecordNews
} from 'utils/contextHub';
import storage from 'utils/store';
import browser from 'browser-detect';

/**
 * Fetch all article topics.
 */
export function* fetchAllTopics() {
    try {
        const response = yield call(Api.get, ALLTOPICS_URL);
        yield put(getAllTopicsSuccess(response));
    } catch (e) {
        yield (put(getAllTopicsFailure('error.topics.all.loadingFailed')));
    }
}

/**
 * Fetch top/most active topics.
 * @param {*} action
 */
export function* fetchTopAllTopics(action) {
    try {
        const response = yield call(Api.get, TOP_ALLTOPICS_URL(action.omitIds));

        yield put(getTopAllTopicsSuccess(response.data));
    } catch (e) {
        yield (put(getTopAllTopicsFailure('error.topics.top.loadingFailed')));
    }
}

/**
 * Fetch trending topics by sub type (for TT component).
 * @param {*} action
 */
export function* fetchTrendingAllTopics(action) {
    try {
        let response;

        switch (action.typeId) {
            case 1:
                response = yield call(Api.get, TRENDING_ALLTOPICS_URL_WM(action.filterId));
                break;
            case 2:
                response = yield call(Api.get, TRENDING_ALLTOPICS_URL_RETIREMENT(action.filterId));
                break;
            case 3:
                response = yield call(Api.get, TRENDING_ALLTOPICS_URL_EXPERIENCE(action.filterId));
                break;
            default:
                response = yield call(Api.get, TRENDING_ALLTOPICS_URL);
                let analyticsResponse = response.data;
                let analyticsData = [];
                let index = 1;
                analyticsResponse.map((item) => {
                    if (item.newsItems.length) {
                        item.newsItems.map((newsItem) => {
                            let idRef = newsItem.modelDisplayName + "-" + "cont" + "-" +
                                newsItem.id + "_" + 'V00' + "_" + (index);
                            index++;
                            analyticsData.push(idRef);
                        })
                    }
                })
                const { mobile } = browser();
                const windowWidth = window.innerWidth;
                if (mobile === true && windowWidth < 1024) {
                    analyticsData = analyticsData.splice(0, 4);
                }
                storage.set("modelData", analyticsData);
                analyticsData = analyticsData.join(';');
                contextHubRecordNews('/news', analyticsData);
        }

        if (response.data.length) {
            yield put(getTrendingAllTopicsSuccess(response.data));
        } else {
            //send failure for 0 items returned so component knows to slide out of view
            yield (put(getTrendingAllTopicsFailure('error.topics.trending.loadingFailed')));
        }
    } catch (e) {
        yield (put(getTrendingAllTopicsFailure('error.topics.trending.loadingFailed')));
    }
}

/**
 * Fetch see more trending topics by type selected in TT component.
 * @param {*} action
 */
export function* fetchSeeMoreTrending(action) {
    try {
        const response = yield call(Api.post, SEEMORE_TRENDING_URL, {
            data: {
                "excludedArticleIds": action.articleIds,
                "trendingTopicId": null,
                "advisor": {
                    "wmAUMId": action.trendingType && action.trendingType === 'wm' ? action.trendingVariant : null,
                    "durationId": action.trendingType && action.trendingType === 'experience' ? action.trendingVariant : null,
                    "rpAUMId": action.trendingType && action.trendingType === 'retirement' ? action.trendingVariant : null,
                },
                "topicIds": action.topicIds,
            }
        });

        yield put(getSeeMoreTrendingSuccess(response.data));
    } catch (e) {
        yield (put(getSeeMoreTrendingFailure('error.topics.trending.seeMore.loadingFailed')));
    }
}

function* topicSaga() {
    yield all([
        takeLatest(GET_ALLTOPICS, fetchAllTopics),
        takeLatest(GET_TOP_ALLTOPICS, fetchTopAllTopics),
        takeLatest(GET_TRENDING_ALLTOPICS, fetchTrendingAllTopics),
        takeLatest(SEEMORE_TRENDING, fetchSeeMoreTrending),
    ]);
}

export default topicSaga;
