import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
    KEY_DOWN, KEY_ESCAPE, KEY_UP, KEY_TAB,
} from 'utils/keyCodes';
import { IconCaratDown } from 'components/atomics/atoms/Icons/Icons';
import { Button } from 'components';
import {
    navigationWrapperStyles,
    navigationTriggerStyles,
    navigationTriggerIconStyles,
    navigationListItemStyles,
    navigationListStyles,
    navigationLinkStyles,
    navigationTriggerLinkStyles,
} from 'components/SecondaryNavigation/styles';
import Badge from 'components/atomics/atoms/Badge/Badge';

export const StyledNav = styled.nav`
    ${navigationWrapperStyles};
`;
export const StyledNavTrigger = styled(({ passedRef, ...other }) => <Button ref={passedRef} {...other} />)`
    ${navigationTriggerStyles};
`;

export const StyledNavTriggerIcon = styled(IconCaratDown)`
    ${navigationTriggerIconStyles};
`;

export const StyledNavList = styled.ul`
    ${navigationListStyles}
`;

export const StyledNavListItem = styled.li`
    ${navigationListItemStyles};
`;
export const StyledNavLink = styled(({ passedRef, ...other }) => <Link ref={passedRef} {...other} />)`
    ${navigationLinkStyles};
`;
export const StyledNavLinkProfile = styled(({ passedRef, selectedVal,  ...other }) => <Link ref={passedRef} {...other} className={selectedVal} />)`
    ${navigationTriggerLinkStyles};
`;

class SecondaryNavigation extends React.Component {
    constructor(props) {
        super(props);

        this.dropdownRef = React.createRef();
        this.triggerRef = React.createRef();

        this.itemsNodesMap = new Map();

        this.state = {
            isOpen: false,
        };

        this.handleTriggerClick = this.handleTriggerClick.bind(this);
        this.handleGlobalClick = this.handleGlobalClick.bind(this);
        this.handleGlobalKeyUp = this.handleGlobalKeyUp.bind(this);
        this.focusTrigger = this.focusTrigger.bind(this);

        this.selectedVal = " ";

        this.keyCount = 0;
        this.getKey = this.getKey.bind(this);
    }

    componentWillUnmount() {
        this.removeEventListeners();
        this.removeTimers();
    }

    getKey(){
        return this.keyCount++;
    }

    setItemNodeRef(id, nodeRef) {
        this.itemsNodesMap.set(id, nodeRef);
    }

    openDropdown = (callback) => {
        this.setState({
            isOpen: true,
        }, () => {
            this.addEventListeners();
            this.focusFirstItem();

            if (callback && typeof callback === 'function') {
                callback();
            }
        });
    };

    closeDropdown = (callback) => {
        this.setState({
            isOpen: false,
        }, () => {
            this.removeEventListeners();

            if (callback && typeof callback === 'function') {
                callback();
            }
        });
    };


    handleTriggerClick() {
        const { isOpen } = this.state;

        if (isOpen) {
            this.closeDropdown();
        } else {
            this.openDropdown();
        }
    }

    handleItemClick() {
        this.closeDropdown();
    }

    handleItemKeyDown(event, item) {
        const keyCode = event.keyCode || event.which || 0;

        switch (keyCode) {
            case KEY_DOWN:
                event.preventDefault();
                this.focusNextItem(item.id);
                break;
            case KEY_UP:
                event.preventDefault();
                this.focusPreviousItem(item.id);
                break;

            // no default
        }
    }

    handleGlobalKeyUp(event) {
        const { keyCode, which } = event;
        const key = keyCode || which || 0;

        switch (key) {
            case KEY_ESCAPE:
                this.closeDropdown(this.focusTrigger);
                break;
            case KEY_TAB:
                this.handleTabbing();
                break;

            // no default
        }
    }

    handleGlobalClick(event) {
        const { target } = event;
        const container = this.dropdownRef.current;

        if (container === target || container.contains(target)) {
            return;
        }

        this.closeDropdown();
    }

    handleTabbing() {
        this.removeTimers();

        this.tabbingTimer = setTimeout(() => {
            const container = this.dropdownRef.current;

            if (!container.contains(document.activeElement)) {
                this.closeDropdown();
            }
        });
    }

    focusFirstItem() {
        const { routes } = this.props;

        if (!(routes && routes.length > 0)) {
            return;
        }

        const key = routes[0].id;
        const node = this.itemsNodesMap.get(key);

        if (node) {
            node.focus();
        }
    }

    focusTrigger() {
        const node = this.triggerRef.current;

        if (node) {
            node.focus();
        }
    }

    focusPreviousItem(currentId) {
        const { routes } = this.props;
        const currentIdx = routes.findIndex(item => item.id === currentId);

        if (currentIdx < 0) {
            return;
        }

        const previousItem = currentIdx === 0 ? routes[routes.length - 1] : routes[currentIdx - 1];
        const previousNode = this.itemsNodesMap.get(previousItem.id);

        if (previousNode) {
            previousNode.focus();
        }
    }

    focusNextItem(currentId) {
        const { routes } = this.props;
        const currentIdx = routes.findIndex(item => item.id === currentId);

        if (currentIdx < 0) {
            return;
        }

        const nextItem = currentIdx === (routes.length - 1) ? routes[0] : routes[currentIdx + 1];
        const nextNode = this.itemsNodesMap.get(nextItem.id);

        if (nextNode) {
            nextNode.focus();
        }
    }

    addEventListeners() {
        document.addEventListener('click', this.handleGlobalClick);
        document.addEventListener('keydown', this.handleGlobalKeyUp);
    }

    removeEventListeners() {
        document.removeEventListener('click', this.handleGlobalClick);
        document.removeEventListener('keydown', this.handleGlobalKeyUp);
    }

    removeTimers() {
        if (this.tabbingTimer) {
            clearTimeout(this.tabbingTimer);
        }
    }

    render() {
        const {
            ariaLabel,
            id,
            routes,
            triggerTitle,
            setDefaultHistory,
            addItemToHistory,
            badge,
        } = this.props;
        const { isOpen } = this.state;

        this.page = (window.location.pathname).split("/");
        this.pageName = this.page[1];

            if(this.pageName == "profile")
                this.selectedVal = "selected";

        return (
            <StyledNav
                aria-label={ariaLabel}
                id={id}
                onClick={() => {
                    setDefaultHistory();
                }}
                ref={this.dropdownRef}
            >
                <StyledNavLinkProfile
                    {...routes[0]}
                    passedRef={ref => this.setItemNodeRef(routes[0].id, ref)}
                    selectedVal={this.selectedVal}
                    className="toDesktop">
                    {triggerTitle.toLowerCase()}
                    {badge !== undefined && badge > 0 && <Badge label={badge} verticalOffset={0} horizontalOffset={3} />}
                </StyledNavLinkProfile>

                <StyledNavLinkProfile
                    {...routes[1]}
                    passedRef={ref => this.setItemNodeRef(routes[1].id, ref)}
                    selectedVal={this.selectedVal}
                    className="toMobile">
                    {triggerTitle.toLowerCase()}
                    {badge !== undefined && badge > 0 && <Badge label={badge} verticalOffset={-5} horizontalOffset={5} scale={0.8} />}
                </StyledNavLinkProfile>
            <StyledNavList
                    id={`${id}-list`}
                    variant={{ isOpen }}
                >
                    {
                        routes.map((item) => {
                            const { title, ...other } = item;

                            return (
                                <StyledNavListItem key={this.getKey()}>
                                    <StyledNavLink
                                        {...other}
                                        passedRef={ref => this.setItemNodeRef(item.id, ref)}
                                        onClick={event => this.handleItemClick(event, item)}
                                        onKeyDown={event => this.handleItemKeyDown(event, item)}
                                    >
                                        {title}
                                    </StyledNavLink>
                                </StyledNavListItem>
                            );
                        })
                    }
                </StyledNavList>
            </StyledNav>
        );
    }
}

SecondaryNavigation.propTypes = {
    ariaLabel: PropTypes.string.isRequired,
    id: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]).isRequired,
        title: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.string,
        ]).isRequired,
        to: PropTypes.string.isRequired,
    })),
    triggerTitle: PropTypes.string.isRequired,
};

SecondaryNavigation.defaultProps = {
    id: 'secondary-nav',
    setDefaultHistory: () => {},
    addItemToHistory: () => {},
    routes: [],
};

export default SecondaryNavigation;
