import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Loader } from 'components';
import SearchResults from '../../containers/Search/SearchResults/SearchResults';
import ResultsHeader from 'containers/Search/Header/ResultsHeader';
import { startSearch as startSearchAction } from 'state-management/actions/search';
import { getTopics as getTopicsAction } from 'state-management/actions/topics';
import { getSources as getSourcesAction } from 'state-management/actions/sources';
import {
    applyFilters as applyFiltersAction,
    seeMoreNews,
    seeMorePracticeManagement,
} from 'state-management/actions/searchFilter';
import {
    filterByDate, filterBySources, filterByTopics, filterByType,
} from 'containers/Search/filterByHelpers';
import * as analytics from 'utils/adobeAnalytics';
import { contextHubRecordSearch } from 'utils/contextHub';

/**
 * Search results page.
 */
class SearchContainer extends React.Component {
    /**
     * Obtain the search value from passed properties
     * @param {*} props 
     * @param {*} prevState 
     */
    static getDerivedStateFromProps(props, prevState) {
        if (props.search.searchValue) {
            return { searchValue: props.search.searchValue };
        }

        return prevState;
    }

    /**
     * Default constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            searchValue: this.props.search.searchValue,
            sort: this.props.queryParams.orderBy || '',
        };

        this.setSortState = this.setSortState.bind(this);
        this.seeMore = this.seeMore.bind(this);
    }

    /**
     * Initiate search logic and gather required data at mount.
     */
    componentDidMount() {
        this.handleStartSearch();
        this.props.getTopics();
        this.props.getSources();

        const query = this.extractQuery();

        if (query) {
            analytics.dispatchViewStart(analytics.getSearchData(query));
        }
    }

    /**
     * Update search results and page state on property changes.
     * @param {*} prevProps 
     */
    componentDidUpdate(prevProps) {
        const { queryParams } = this.props;

        if (prevProps.queryParams.q !== queryParams.q) {
            this.handleStartSearch();
            analytics.dispatchViewStart(analytics.getSearchData(this.extractQuery()));
        }

        if (prevProps.search.loading) {
            const data = analytics.getSearchData(
                this.props.search.searchValue,
                this.props.search.items.length,
            );
            if (!this.props.search.loading) {
                contextHubRecordSearch(this.props.search.searchValue,
                    this.props.search.items.length == 0 ? "0" : this.props.search.items.length);
            }
            analytics.dispatchViewEnd(data);
        }

        if (this.state.sort !== this.props.queryParams.orderBy
            && this.props.queryParams.orderBy == 'Type') {
            this.setState({
                sort: this.props.queryParams.orderBy || 'Newest First',
            });
        }
    }

    /**
     * Apply filtering and ordering to search results.
     */
    getFilteredItems = () => {
        const { filtersApplied } = this.props;
        const { items } = this.props.search;
        const filtered = this.filterItems(items, filtersApplied);

        return this.orderItems(filtered);
    };

    /**
     * Handle starting the underlying seach query.
     */
    handleStartSearch = () => {
        const query = this.extractQuery();

        if (query) {
            this.props.startSearch(query);
        }
    };

    /**
     * Apply filtering to search results.
     * @param {*} items 
     */
    filterItems(items) {
        const { filtersApplied } = this.props;

        if (!filtersApplied) {
            return items;
        }

        let filteredItems = filterByType(items, filtersApplied.type);

        filteredItems = filterByTopics(filteredItems, filtersApplied.topics);
        filteredItems = filterBySources(filteredItems, filtersApplied.sources);
        filteredItems = filterByDate(filteredItems, filtersApplied.date);

        return filteredItems;
    }

    /**
     * Save the sort order state.
     * @param {*} e 
     */
    setSortState(e) {
        this.setState({
            sort: e,
        });
    }

    /**
     * Default sort to newest then execute see more function passed.
     * @param {*} seeMoreFunction
     */
    seeMore(seeMoreFunction) {
        window.history.pushState(null, '', window.location.href.replace('orderBy=type', ''));

        this.setState({
            sort: '',
        }, () => {
            seeMoreFunction();
        })
    }

    sortOnTitle(items) {
        if (items.sort((a, b) => (moment(a.publishDate).unix() == moment(b.publishDate).unix()))) {
            items.sort((a, b) => a.title.localeCompare(b.title))
        }
    }

    /**
     * Order search results by date, ascending or descending depending upon query param.
     * @param {*} items
     */
    orderItems(items) {
        const { queryParams } = this.props;

        if (this.state.sort === 'Oldest First') {
            this.sortOnTitle(items)
            items.sort((a, b) => (moment(a.publishDate).unix() > moment(b.publishDate).unix()) ? 1 : -1);
        } else {
            this.sortOnTitle(items)
            items.sort((a, b) => (moment(a.publishDate).unix() < moment(b.publishDate).unix()) ? 1 : -1);
        }

        return items;
    }

    /**
     * Gather user search query from URL params.
     */
    extractQuery() {
        const { queryParams } = this.props;
        const queryParam = queryParams.q;

        return queryParam && queryParam.trim();
    }

    /**
     * Render this and underlying components.
     */
    render() {
        const filteredItems = this.getFilteredItems();
        const { onSeeMoreNews, onSeeMorePracticeManagement } = this.props;
        const { items, loading } = this.props.search;

        if (loading) {
            return <Loader />;
        }

        return (
            <React.Fragment>
                {!!items.length
                && <ResultsHeader
                    searchValue={this.state.searchValue}
                    resultsCount={filteredItems.length < this.props.search.items.length
                        ? filteredItems.length : this.props.search.items.length}
                />}
                <SearchResults
                    sort={this.state.sort}
                    key={(filteredItems[0] && filteredItems[0].id) || 0}
                    onSort={this.setSortState}
                    filteredItems={filteredItems}
                    typeFilters={this.props.filtersApplied ? this.props.filtersApplied.type : []}
                    items={this.props.search.items}
                    onDoneClick={this.props.applyFilters}
                    onSeeMoreNews={() => { this.seeMore(onSeeMoreNews) }}
                    onSeeMorePracticeManagement={() => { this.seeMore(onSeeMorePracticeManagement) }}
                    searchValue={this.state.searchValue}
                    queryParams={this.props.queryParams}
                />
            </React.Fragment>
        );
    }
}

SearchContainer.propTypes = {
    applyFilters: PropTypes.func.isRequired,
    getTopics: PropTypes.func.isRequired,
    getSources: PropTypes.func.isRequired,
    onSeeMoreNews: PropTypes.func.isRequired,
    onSeeMorePracticeManagement: PropTypes.func.isRequired,
    queryParams: PropTypes.shape({
        orderBy: PropTypes.oneOf(['', 'oldest', 'type']),
        q: PropTypes.string,
    }).isRequired,
    search: PropTypes.shape({
        isOpen: PropTypes.bool,
        searchValue: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.shape({
            category: PropTypes.string,
            contentType: PropTypes.string,
            interactionId: PropTypes.string,
            id: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string,
            ]),
            image: PropTypes.string,
            internal: PropTypes.bool,
            isSaving: PropTypes.bool,
            link: PropTypes.string,
            publishDate: PropTypes.string,
            saved: PropTypes.bool,
            sourceLogo: PropTypes.string,
            sourceTitle: PropTypes.string,
            title: PropTypes.string,
            topics: PropTypes.array,
            type: PropTypes.string,
            visited: PropTypes.bool,
            licensed: PropTypes.bool,
        })),
        loading: PropTypes.bool,
        error: PropTypes.bool,
    }).isRequired,
    filtersApplied: PropTypes.shape({}),
    startSearch: PropTypes.func.isRequired,
};

SearchContainer.defaultProps = {
    filtersApplied: null,
};

const mapStateToProps = state => ({
    search: state.search,
    filtersApplied: state.searchFilter.filtersApplied,
    queryParams: state.location.query,
});

const mapDispatchToProps = dispatch => ({
    applyFilters: () => dispatch(applyFiltersAction()),
    getTopics: () => dispatch(getTopicsAction()),
    getSources: () => dispatch(getSourcesAction()),
    onSeeMoreNews: () => dispatch(seeMoreNews()),
    onSeeMorePracticeManagement: () => dispatch(seeMorePracticeManagement()),
    startSearch: value => dispatch(startSearchAction(value, { shouldUpdateFilters: true })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchContainer);
