import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Container } from 'components';
import textContainerStyles from 'components/TextBlock/styles';

const StyledContainer = styled(Container)`
    ${textContainerStyles};
`;

class TextBlock extends React.PureComponent {
    render() {
        const {
            children,
            ...other
        } = this.props;

        return (
            <StyledContainer
                {...other}
            >
                {children}
            </StyledContainer>
        );
    }
}

TextBlock.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

TextBlock.defaultProps = {
    children: null,
};

export default TextBlock;
