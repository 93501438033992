import {
    ADD_TOASTS, REMOVE_TOASTS, SHOW_TOASTS, HIDE_TOASTS,
} from 'state-management/constants/toasts';

const initialState = {
    isLoaded: false,
    toasts: [],
};

function toastsReducer(state = initialState, action = {}) {
    switch (action.type) {
    case ADD_TOASTS:
        return {
            ...state,
            toasts: action.toasts,
        };
    case HIDE_TOASTS:
        return {
            ...state,
            isLoaded: false,
        };
    case SHOW_TOASTS:
        return {
            ...state,
            isLoaded: true,
        };
    case REMOVE_TOASTS:
        return initialState;
    default:
        return state;
    }
}

export default toastsReducer;
