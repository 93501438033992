import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Heading from 'components/Heading/Heading';
import resultsCountStyles from 'components/ResultsCount/styles';

const StyledResultsCount = styled(Heading)`
    ${resultsCountStyles};
`;

const ResultsCount = (props) => {
    const { children } = props;

    return (
        <StyledResultsCount
            level={2}
        >
            {children}
        </StyledResultsCount>
    );
};

ResultsCount.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default ResultsCount;
