import React from 'react';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';
import { defaults } from 'lodash';

class LottieWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.containerRef = React.createRef();
    }

    componentDidMount() {
        this.animate();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.direction !== this.props.direction) {
            this.setDirection();
        }
    }

    componentWillUnmount() {
        this.destroy();
    }

    setDirection() {
        const { direction } = this.props;

        if (this.animation && direction) {
            this.animation.setDirection(direction);
        }
    }

    getOptions() {
        const node = this.containerRef.current;
        const { options } = this.props;
        const defaultOptions = {
            autoplay: true,
            loop: false,
            renderer: 'svg',
        };
        const mergedOptions = {};

        if (node) {
            defaults(mergedOptions, { container: node }, options, defaultOptions);
        }

        return mergedOptions;
    }

    animate() {
        const options = this.getOptions();

        if (Object.keys(options).length) {
            this.animation = lottie.loadAnimation(options);
        }
    }

    destroy() {
        if (this.animation) {
            this.animation.destroy();
        }
    }

    render() {
        const { className } = this.props;

        return (
            <div
                className={className}
                ref={this.containerRef}
            />
        );
    }
}

LottieWrapper.propTypes = {
    className: PropTypes.string,
    direction: PropTypes.number,
    options: PropTypes.shape({
        animationData: PropTypes.shape({}).isRequired,
    }).isRequired,
};

LottieWrapper.defaultProps = {
    className: '',
    direction: 1,
};

export default LottieWrapper;
