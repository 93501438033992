import * as type from 'state-management/constants/marketBriefing.common';

export const getMarketBriefings = () => ({
    type: type.GET_MARKET_BRIEFINGS,
});

export const getMarketBriefingsSuccess = data => ({
    type: type.GET_MARKET_BRIEFINGS_SUCCESS,
    data,
});

export const getMarketBriefingsFailure = error => ({
    type: type.GET_MARKET_BRIEFINGS_FAIL,
    error,
});


export const getMarketBriefing = id => ({
    type: type.GET_MARKET_BRIEFING,
    id,
});

export const getMarketBriefingSuccess = data => ({
    type: type.GET_MARKET_BRIEFING_SUCCESS,
    data,
});

export const getMarketBriefingFailure = error => ({
    type: type.GET_MARKET_BRIEFING_FAIL,
    error,
});


export const getLatestMarketBriefing = () => ({
    type: type.GET_LATEST_MARKET_BRIEFING,
});

export const getLatestMarketBriefingSuccess = data => ({
    type: type.GET_LATEST_MARKET_BRIEFING_SUCCESS,
    data,
});

export const getLatestMarketBriefingFailure = error => ({
    type: type.GET_LATEST_MARKET_BRIEFING_FAIL,
    error,
});


export const deleteMarketBriefing = () => ({
    type: type.DELETE_MARKET_BRIEFING,
});
