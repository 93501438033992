import sanitizeHtml from 'sanitize-html-react';

export function getSanitizedHtml(unsecureHtml) {
    return {
        __html: sanitizeHtml(
            unsecureHtml,
            {
                allowedTags: sanitizeHtml.defaults.allowedTags.concat([
                    'h1',
                    'h2',
                    'p',
                    'strong',
                    'sub',
                    'sup',
                    'li',
                    'b',
                    'ul',
                    'u',
                ]),
                allowedAttributes: {
                    a: sanitizeHtml.defaults.allowedAttributes.a.concat('title'),
                },
                parser: {
                    decodeEntities: false,
                },
            },
        ),
    };
}
