import { css } from 'styled-components';
import { getSpanInPercent } from 'utils/grid';
import {
    FROM_DESKTOP,
    FROM_TABLET,
    ABOVE_MAX_WIDTH,
} from 'utils/mediaQueries';
import {
    FONT_AVENIR_NEXT,
    COLORS,
    ANIMATIONS,
} from 'utils/variables';

export const sectionStyles = css`
    position: relative;
    height: auto;
    padding: 30px 0;
    margin: auto;
    width: 100%;

    @media screen and ${FROM_TABLET} {
        padding: 90px 0;
    }

    .link-fake {
        height: 1px;
        width: 1px;
        display: block;
    }

    div[data-rel="splash"] {
        position: absolute;
        left: -25px;
        top: 16px;
        height: 4px;
        width: 45px;
        background-image: linear-gradient(to left, ${COLORS.OCEAN}, ${COLORS.SAPPHIRE});

        @media screen and ${FROM_TABLET} {
            top: 21px;
            left: -19px;
            width: 48px;
        }

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            width: 30px;
        }

        @media screen and ${FROM_DESKTOP} {
            top: 23px;
            left: -50px;
            width: 80px;
            min-width: 80px;
        }

        @media screen and ${ABOVE_MAX_WIDTH} {
            background-image: linear-gradient(to left, ${COLORS.OCEAN}, ${COLORS.SAPPHIRE} 40px, ${COLORS.SAPPHIRE} 60px, transparent 80px);
        }
    }
`;

export const gradientLeftOverlayStyles = css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 100px;
    background-image: linear-gradient(to right, ${COLORS.LIGHT_GRAY1}, transparent);
    display: none;

    @media screen and ${ABOVE_MAX_WIDTH} {
        display: block;
    }

    ${props => props.isSafari && css`
        display: none !important;
    `};
`;

export const gradientRightOverlayStyles = css`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 60px;
    height: 100px;
    background-image: linear-gradient(to left, ${COLORS.LIGHT_GRAY1}, transparent);
    display: none;

    @media screen and ${ABOVE_MAX_WIDTH} {
        display: block;
    }

    ${props => props.isSafari && css`
        display: none !important;
    `};
`;

export const containerStyles = css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 30px;

    @media screen and ${FROM_TABLET} {
        flex-direction: row;
        justify-content: space-evenly;
        padding: 0 38px;
    }

    @media screen and ${FROM_DESKTOP} {
        justify-content: space-between;
        padding: 0 48px;
    }
`;

export const contentSectionStyles = css`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    position: relative;
    left: 0;

    @media screen and ${FROM_TABLET} {
        margin-top: 10px;
        width: 45%;
        padding-left: 0;
        left: -19px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-top: 0;
        left: -2px;
        width: 50%;
    }
`;

export const formSectionStyles = css`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin-top: 40px;

    @media screen and ${FROM_TABLET} {
        margin-top: 0;
        width: ${getSpanInPercent(5)};
    }

    @media screen and ${FROM_TABLET} {
        width: ${getSpanInPercent(6)};
    }
`;

export const headingStyles = css`
    display: none;
`;

export const primaryDescStyles = css`
    color: ${COLORS.SAPPHIRE};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.17;
    margin: 5px 0 0;
    word-wrap: break-word;

    @media screen and ${FROM_TABLET} {
        margin: 0;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 42px;
        line-height: 52px;
    }

    @media (min-width: 1440px) {
        width: 495px;
    }
`;

export const secondaryDescWrapperStyles = css`
    margin-top: 22px;

    b {
        color: ${COLORS.BLACK};
        font-weight: 600;
    }

    a {
        color: ${COLORS.SAPPHIRE};
        transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;
        
        &:hover {
            color: ${COLORS.DARK_SAPPHIRE};
        }
    }

    @media screen and ${FROM_TABLET} {
        margin-top: 20px;
    }

    @media screen and ${FROM_DESKTOP} {
        margin-top: 50px;
        width: 86%;
    }
`;

export const secondaryDescStyles = css`
    color: ${COLORS.DARK_GRAY1};
    font-family: ${FONT_AVENIR_NEXT};
    font-size: 12px;
    line-height: 1.43;
    margin: 0 0 24px;
    word-wrap: break-word;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.13px;

    &:last-child {
        margin: 0;
    }

    ul {
        padding: 0;
        padding-left: 20px;
        margin: 0;
    }

    @media screen and ${FROM_DESKTOP} {
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.1px;
        margin: 0 0 28px;

        span {
            font-size: 15px;
        }
    }
`;

export const linkStyles = css`
    color: ${COLORS.SAPPHIRE};
    transition: ${ANIMATIONS.CTAS.TIME.SECONDS}s all;

    &:hover {
        color: ${COLORS.DARK_SAPPHIRE};
    }
`;
