import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Loader from 'components/Loader/Loader';
import Title from 'components/atomics/atoms/Title/Title';
import {
    IconArrowDown,
} from 'components/atomics/atoms/Icons/Icons';
import { setScrollToTop } from 'state-management/actions/scroll';
import * as analytics from 'utils/adobeAnalytics';
import ToolsContentCard from 'components/atomics/molecules/ToolsContentCardNew/ToolsContentCard';
import { PAGE_NAME_TOOLS } from 'utils/analyticsConstants';
import { getTools } from 'state-management/actions/guide';
import { StyleBackToTopButton } from 'components/MarketBriefing/page/MarketBriefingPage';
import labels from '../../../lang/en.json';
import { BackToLink } from 'components';
import { sendClickOnTools } from 'state-management/actions/tools';
import {
    pageContainerStyles,
    contentContainerStyles,
    headerStyles,
    gridStyles,
} from './styles';
import { wrapBackButtonLabels } from "../../../utils/urlUtils";
import scrollHelper from 'utils/scrollToTop';

const PageContainer = styled.section`
    ${pageContainerStyles}
`;

const ContentContainer = styled.div`
    ${contentContainerStyles}
`;

const Header = styled.div`
    ${headerStyles}
`;

const Grid = styled.div`
    ${gridStyles}
`;

const BackToLinkContainer = styled.div`
`;

/**
 * PM all tools page.
 */
export class Tools extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.state = {
            showBackToTop: false
        }

        this.handleWindowScroll = this.handleWindowScroll.bind(this);
        this.renderTools = this.renderTools.bind(this);
        this.renderToolSet = this.renderToolSet.bind(this);
        this.renderTool = this.renderTool.bind(this);
        this.backToTop = this.backToTop.bind(this);
    }

    /**
     * Gather data at page mount.
     */
    componentDidMount() {
        scrollHelper.scrollToTop();

        this.props.getToolsData();

        window.addEventListener('scroll', this.handleWindowScroll);
    }

    /**
     * Scroll the user back to the top of page.
     */
    backToTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behaviour: 'smooth'
        });
    }

    /**
     * Stuff to do when scrolling the page.
     */
    handleWindowScroll() {
        const scrollTop = document.body.scrollTop
            ? document.body.scrollTop : document.documentElement.scrollTop;
        if (scrollTop > 0) {
            this.setState({
                showBackToTop: true,
            });
        } else {
            this.setState({
                showBackToTop: false,
            });
        }
    }

    /**
     * Dipatch page view end at finish of load.
     * @param {*} prevProps
     */
    componentDidUpdate(prevProps) {
        if (!prevProps.topNewsIsLoaded && this.props.topNewsIsLoaded) {
            analytics.dispatchPageViewEnd(PAGE_NAME_TOOLS, SITE_SECTION_PRACTICE_MANAGEMENT);
        }
    }

    /**
     * Stuff to do on destruction.
     */
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleWindowScroll);
    }

    /**
     * Render the back to top sticky button.
     */
    renderBackToTop() {
        return (
            <StyleBackToTopButton
                aria-label={labels.translations['marketBriefing.page.scrollToTop']}
                type="button"
                id="backToTopLink"
                onClick={this.backToTop}
                data-analytics-placement="Button : body"
                data-analytics-label="trackLink : button"
                data-analytics-id="backtotop:Tools"
            >
                <IconArrowDown />
            </StyleBackToTopButton>
        );
    }

    /**
     * Render a single tool card.
     * @param {*} tool
     * @param {*} index
     */
    renderTool(tool, index) {
        return (
            <ToolsContentCard
                key={index}
                toolCardInfo={tool}
                sendClickOnTools={this.props.sendClickOnTools}
            />
        );
    }

    /**
     * Render a tool card set.
     * @param {*} toolSet
     */
    renderToolSet(toolSet, index) {
        return toolSet.tools.map(this.renderTool);
    }

    /**
     * Render all tools in a grid.
     */
    renderTools() {
        return (
            <Grid>
                {this.props.tools.map(this.renderTool)}
            </Grid>
        );
    }

    /**
     * Render the tools page and underlying components.
     */
    render() {
        const {
            urlHistoryList,
        } = this.props;
        const backLabel = urlHistoryList != null && urlHistoryList.length
            ? urlHistoryList.length - 2 >= 0
                ? urlHistoryList[urlHistoryList.length - 2]
                : null
            : null;

        if (this.props.tools.length < 1 || this.props.showLoader) {
            return <Loader />;
        }
        return (
            <PageContainer>
                <ContentContainer>
                    <Header>
                        <BackToLinkContainer>
                            <BackToLink
                                to={backLabel !== null ? backLabel.currentUrl : '#'}
                                customLabel={wrapBackButtonLabels(backLabel)}
                                isDisable={backLabel === null}
                            />
                        </BackToLinkContainer>
                        <Title
                            resourceId="practiceManagement.tools.title"
                        />
                    </Header>

                    {this.props.tools.length > 0
                        && this.renderTools()}

                    {this.state.showBackToTop
                        && this.renderBackToTop()}
                </ContentContainer>
            </PageContainer>
        );
    }
}

Tools.propTypes = {
    scrollToTop: PropTypes.func.isRequired,
    tools: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        link: PropTypes.string,
        order: PropTypes.number,
        latestPublishTimestamp: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.string),
        sendClickOnTools: PropTypes.func,
        showLoader: PropTypes.bool,
    }))
};

Tools.defaultProps = {
    tools: [],
    showLoader: false,
};

const mapStateToProps = state => ({
    tools: state.guide.allTools ? state.guide.allTools.toolList : [],
    currentUrlStep: state.UrlHistoryReducer.currentStep,
    urlHistoryList: state.UrlHistoryReducer.history,
    showLoader: state.guide.showLoader
});

const mapDispatchToProps = dispatch => ({
    scrollToTop: () => dispatch(setScrollToTop()),
    getToolsData: () => dispatch(getTools()),
    sendClickOnTools: (id) => dispatch(sendClickOnTools(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tools);
