import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLatestMarketBriefing } from 'state-management/actions/marketBriefing.common';
import { getMarketIndices } from 'state-management/actions/marketIndices.common';
import styled from 'styled-components';
import InfoBarDisplay from 'components/atomics/organisms/InfoBar/InfoBar';
import {
    containerStyles,
    paddingContainerStyles,
} from 'containers/News/InfoBar/styles';

export const Container = styled.section`
    ${containerStyles}
`;

export const PaddedContainer = styled.div`
    ${paddingContainerStyles}
`;

const INDICE_INTERVAL_TIME = 900000; // 15 minutes

/**
 * Container for displaying the news top bar component.
 */
export class InfoBar extends React.Component {
    /**
     * Default constructor.
     * Set state reference to avoid issues at quick unmount.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        /*this.state = {
            indiceIntervalId:null,
        };*/
    }

    /**
     * Get the latest market briefing at mount, and the indice data and setup indice interval.
     */
    componentDidMount() {
        this.props.getLatestMarketBriefing();

        /*this.props.getMarketIndices();

        const indiceIntervalId = setInterval(this.props.getMarketIndices, INDICE_INTERVAL_TIME);

        this.setState({
            indiceIntervalId,
        });*/
    }

    /**
     * Remove interval at destruction.
     */
    componentWillUnmount() {
        //clearInterval(this.state.indiceIntervalId);
    }

    /**
     * Render the underlying components.
     */
    render() {
        if (/*this.props.marketIndices && */this.props.marketBriefing) {
            return (
                <Container data-rel="InfoBar">
                    <PaddedContainer>
                        <InfoBarDisplay
                            showBriefing={this.props.showBriefing}
                            marketBriefing={this.props.marketBriefing.briefing}
                            marketIndices={this.props.marketIndices}
                        />
                    </PaddedContainer>
                </Container>
            );
        }

        return null;
    }
}

InfoBar.propTypes = {
    marketIndices: PropTypes.shape({
        marketQuotes: PropTypes.array,
        lastUpdatedDate: PropTypes.string,
        closed: PropTypes.bool,
    }),
    marketBriefing: PropTypes.shape({
        briefing: PropTypes.shape({
            id: PropTypes.string,
            published_at: PropTypes.string,
            type: PropTypes.string,
        }),
    }),
    getLatestMarketBriefing: PropTypes.func,
    showBriefing: PropTypes.bool,
};

InfoBar.defaultProps = {
    marketIndices: {},
    marketBriefing: {
        briefing: {
            id: null,
            published_at: null,
            type: null,
        },
    },
    getLatestMarketBriefing: () => {},
    getMarketIndices: () => {},
    showBriefing: true,
};

const mapStateToProps = state => ({
    marketBriefing: state.marketBriefing,
    marketIndices: state.marketIndices.items,
});

const mapDispatchToProps = dispatch => ({
    getLatestMarketBriefing: () => dispatch(getLatestMarketBriefing()),
    getMarketIndices: () => dispatch(getMarketIndices()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoBar);
