import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { errorStyles } from 'components/FieldError/styles';

const StyledErrors = styled.div`
    ${errorStyles}
`;

const FieldError = ({ id, isVisible, error }) => (
    <StyledErrors id={`${id}-errors`}>
        {isVisible
            ? <span>{error}</span>
            : null
        }
    </StyledErrors>
);

FieldError.propTypes = {
    id: PropTypes.string.isRequired,
    isVisible: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([
           PropTypes.string,
          PropTypes.object
        ]),
};

FieldError.defaultProps = {
    error: '',
};

export default FieldError;
