export const breakpoints = {
    small_mobile: 415,
    tablet: 768,
    desktop: 1024,
    max_width: 1171,
    max_splash_width: 1600,
};

export const SMALL_MOBILE = `(max-width: ${breakpoints.small_mobile}px)`;
export const FROM_MOBILE_TO_TABLET = `(max-width: ${breakpoints.tablet - 1}px)`;
export const FROM_MOBILE_TO_DESKTOP = `(max-width: ${breakpoints.desktop - 1}px)`;
export const FROM_TABLET = `(min-width: ${breakpoints.tablet}px)`;
export const FROM_TABLET_TO_DESKTOP = `(min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.desktop - 1}px)`;
export const FROM_DESKTOP = `(min-width: ${breakpoints.desktop}px)`;
export const ABOVE_MAX_WIDTH = `(min-width: ${breakpoints.max_width}px)`;
export const ABOVE_MAX_SPLASH_WIDTH = `(min-width: ${breakpoints.max_splash_width}px)`;

export default {
    SMALL_MOBILE,
    FROM_MOBILE_TO_TABLET,
    FROM_MOBILE_TO_DESKTOP,
    FROM_TABLET,
    FROM_TABLET_TO_DESKTOP,
    FROM_DESKTOP,
    ABOVE_MAX_WIDTH,
    ABOVE_MAX_SPLASH_WIDTH,
};
