import React from 'react';
import styled from 'styled-components';
import { withCommonCard } from 'components/atomics/hocs/CommonCard/CommonCard';
import Link from 'components/atomics/atoms/Link/Link';
import SourceTag from '../../../../components/atomics/atoms/SourceTag/SourceTag';
import { getArticleUrl } from 'utils/getArticleAsset';
import {
    containerStyles,
    contentContainerStyles,
    dateAndTopicContainerStyles,
    dateContainerStyles,
    topicLinkStyles,
    titleStyles,
    kebabAndSourceContainerStyles,
    imageContainerStyles,
    sourceContentContainerStyles,
    iconStyles,
    iconMobileStyles,
    sourceTagStyles,
    sourceLinkStyles,
    poweredByStyles,
} from 'components/atomics/molecules/SkinnyListToolCard/styles';
import {
    ToolsBlueIcon,
    ToolsBlueMobileIcon,
} from 'components/atomics/atoms/Icons/Icons';
import { getTruncatedString } from 'utils/contentCardUtils';

const StyledIconPlus = styled(ToolsBlueIcon)`
    ${iconStyles}
`;

const StyledIconMobile = styled(ToolsBlueMobileIcon)`
    ${iconMobileStyles}
`;

export const KebabAndSourceContainer = styled.div`
    ${kebabAndSourceContainerStyles}
`;

export const Title = styled(Link)`
    ${titleStyles}
`;

export const DateAndTopic = styled.div`
    ${dateAndTopicContainerStyles}
`;

export const DateContainer = styled.div`
    ${dateContainerStyles}
`;

export const TopicLink = styled(Link)`
    ${topicLinkStyles}
`;

export const Container = styled.div`
    ${containerStyles}
`;

export const ImageContainer = styled(Link)`
    ${imageContainerStyles}
`;

export const ContentContainer = styled.div`
    ${contentContainerStyles}
`;

export const SourceContentContainer = styled.div`
    ${sourceContentContainerStyles}
`;

export const SourceTagContainer = styled.div`
    ${sourceTagStyles}
`;

export const SourceLink = styled(Link)`
    ${sourceLinkStyles}
`;

export const PoweredBy = styled.div`
    ${poweredByStyles}
`;

const MAX_ARTICLE_TITLE_CHARS = 120;

window.removeSavesIds = window.removeSavesIds || [];

/**
 * Creates a skinny list tool card.
 */
class SkinnyListToolCard extends React.Component {
    /**
     * Render the tools list content card and underlying components.
     */
    render() {
        const isLastCard = (this.props.isLastCard) ? 'item-last' : '';
        const linkUrl = getArticleUrl(this.props.article);
        const classNames = `TYPE-${this.props.article.type} ${this.props.alternativeBackground} ${isLastCard}`;

        if (window.removeSavesIds.includes(this.props.article.id)) {
            return null;
        }

        let sourceContent;

        if (this.props.article.type == 'GUIDE_STANDARD' || this.props.article.type == 'GUIDE_MC_KINSEY') {
            sourceContent = this.props.article.category;
        } else {
            sourceContent = <SourceTag
                type="list"
                onTopicClick={() => {
                }}
                // sourceName={this.props.article.sourceName}
                sourceName="Tools"
                provider={this.props.article.provider}
                isDisabled={false}
            />;
        }
        return (
            <Container className={classNames}>
                <ImageContainer
                    to={linkUrl}
                    onClick={() => {
                        this.props.recordClickAnalytics();
                        this.props.onArticleClick();
                    }}
                >
                    <StyledIconMobile />
                    <StyledIconPlus />
                </ImageContainer>
                <ContentContainer>
                    <DateAndTopic>
                        <TopicLink
                            data-analytics-placement="Button : body"
                            data-analytics-label="trackLink : button"
                            data-analytics-id={"topic:" + this.props.article.toolCategory}
                            to={`learn/collections/client-life-events/${this.props.article.categoryCode}`}
                        >
                            {this.props.article.toolCategory}
                        </TopicLink>
                    </DateAndTopic>
                    <Title
                        to={linkUrl}
                        onClick={() => {
                            this.props.recordClickAnalytics();
                            this.props.onArticleClick();
                            this.props.sendClickOnTools(this.props.article.id);
                        }}
                    >
                        {getTruncatedString(this.props.article.title, MAX_ARTICLE_TITLE_CHARS)}
                    </Title>
                    <KebabAndSourceContainer>
                        <SourceContentContainer
                            data-analytics-placement="Button : body"
                            data-analytics-label="trackLink : button"
                            data-analytics-id={"source:" + this.props.article.sourceName}
                        >
                            <SourceTagContainer>
                                <SourceLink to="/learn/tools">Tools</SourceLink>
                                <PoweredBy>Powered by {this.props.article.sourceName}</PoweredBy>
                            </SourceTagContainer>
                        </SourceContentContainer>
                    </KebabAndSourceContainer>
                </ContentContainer>
            </Container>
        );
    }
}

export default withCommonCard(SkinnyListToolCard);
