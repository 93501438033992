import {
    fork, put, take, takeEvery, takeLatest, call, all, select,
} from 'redux-saga/effects';
import Api from 'utils/api';
import {
    COACH_MARK_SAVE,
    COACH_MARK_URL,
    GET_COACH_MARKS,
    DISMISS_COACH_MARK,
} from 'state-management/constants/coachMark';
import { SIGN_OUT } from 'state-management/constants/signOut';
import {
    disableCoachMarks,
    dismissCoachMark as dismissCoachMarkAction,
    dismissCoachMarkFail,
    dismissCoachMarkSuccess,
    enableCoachMarks,
    getCoachMarkStatusSuccess,
    hideCoachMark as hideCoachMarkAction,
    setCoachMarkAsSaved,
} from 'state-management/actions/coachMark';
import { TOGGLE_SAVE_ARTICLE, TOGGLE_SAVE_GUIDE } from 'state-management/constants/save';
import { REMOVE_TOASTS, ADD_TOASTS } from 'state-management/constants/toasts';

/**
 * Get status of all coachmarks.
 */
function* fetchCoachMarkStatus() {
    try {
        const response = yield call(Api.get, COACH_MARK_URL);
        yield put(getCoachMarkStatusSuccess(response.data));
    } catch (_) {
        // ignore error
    }
}

/**
 * Handle user dismissal of coachmark.
 * @param {*} param0
 */
function* dismissCoachMark({ coachMarkType, hide }) {
    const { status } = yield select(state => state.coachMark);

    if (hide) {
        yield put(hideCoachMarkAction(coachMarkType));
    }

    if (coachMarkType === 'KEBAB' || status[coachMarkType]) {
        try {
            yield call(Api.post, `${COACH_MARK_URL}/${coachMarkType}`);
            yield put(dismissCoachMarkSuccess(coachMarkType));
        } catch (error) {
            yield put(dismissCoachMarkFail(error));
        }
    }
}

/**
 * Handle hiding any save coachmarks at save action.
 */
function* dismissSaveCoachMarkOnSave() {
    while (true) {
        yield take([TOGGLE_SAVE_ARTICLE, TOGGLE_SAVE_GUIDE]);
        
        const { status } = yield select(state => state.coachMark);
        
        yield put(setCoachMarkAsSaved());
        
        if (status[COACH_MARK_SAVE]) {
            yield put(dismissCoachMarkAction(COACH_MARK_SAVE));
        }

        yield take(SIGN_OUT);
    }
}

/**
 * Disable coachmarks while processing.
 */
function* disableOnToastsAdd() {
    const { isEnabled, display } = yield select(state => state.coachMark);
    
    if (isEnabled && Object.values(display).includes(true)) {
        yield put(disableCoachMarks());
    }
}

/**
 * Enable coachmarks when not processing.
 */
function* enableOnToastsRemove() {
    const { isEnabled } = yield select(state => state.coachMark);
    
    if (!isEnabled) {
        yield put(enableCoachMarks());
    }
}

function* coachMarkSaga() {
    yield all([
        takeLatest(GET_COACH_MARKS, fetchCoachMarkStatus),
        takeEvery(DISMISS_COACH_MARK, dismissCoachMark),
        fork(dismissSaveCoachMarkOnSave),
        takeLatest(REMOVE_TOASTS, enableOnToastsRemove),
        takeLatest(ADD_TOASTS, disableOnToastsAdd),
    ]);
}

export default coachMarkSaga;
