import { css } from 'styled-components';

import { outerCustomFocusMixin } from 'components/FocusVisibility/styles';
import { visuallyHiddenStyles } from 'components/VisuallyHidden/styles';
import { FROM_MOBILE_TO_TABLET, FROM_TABLET } from 'utils/mediaQueries';
import {
    COLORS,
    FONTS,
    ANIMATIONS,
    FONT_AVENIR_NEXT,
} from 'utils/variables';

export const labelStyles = ({ variant }) => css`
    align-items: center;
    border-bottom: 1px solid ${COLORS.LIGHT_GRAY2};
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    letter-spacing: -0.1px;
    color: ${COLORS.BLACK};
    justify-content: space-between;
    padding: 16px 0;
    position: relative;

    @media screen and ${FROM_TABLET} {
    }

    /* stylelint-disable */

    ${variant.isChecked && css`
    `}

    ${!variant.isChecked && css`
    `}

    /* stylelint-enable */
`;

export const labelTextStyles = css`
    flex: 1;
    word-wrap: break-word;
`;

export const checkboxStyles = css`
    ${visuallyHiddenStyles}
`;

export const indicatorStyles = ({ variant }) => css`
    align-items: center;
    border: 2px solid ${COLORS.MEDIUM_GRAY2};
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    height: 24px;
    justify-content: center;
    margin-left: 20px;
    width: 24px;

    /* stylelint-disable */

    ${variant.isChecked && css`
    border: 2px solid ${COLORS.SAPPHIRE};
    `}

    ${variant.isFocused && css`
        ${outerCustomFocusMixin};
    `}

    /* stylelint-enable */
`;

export const iconPlusStyles = ParentComponent => css`
    color: transparent;
    stroke-width: 1.4px;
    height: 14px;
    width: 14px;
    
    /* stylelint-disable */

    ${ParentComponent}:hover & {
        color: ${COLORS.MEDIUM_GRAY2};
    }

    /* stylelint-enable */
`;

export const indicatorIconStyles = css`
    fill: ${COLORS.SAPPHIRE};
    height: 14px;
    width: 14px;
`;
