import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FieldError from 'components/FieldError/FieldError';
import { wrapperStyles, textAreaStyles } from 'components/FieldTextArea/styles';

const StyledWrapper = styled.div`
    ${wrapperStyles}
`;

export const StyledTextArea = styled.textarea`
    ${textAreaStyles}
`;

const FieldTextArea = (props) => {
    const {
        ariaLabel,
        id,
        field,
        form,
        onChange,
        onBlur,
        ...otherProps
    } = props;
    const fieldName = field.name;
    const fieldId = id || fieldName;
    const error = form.errors[fieldName];
    const isInvalid = error !== undefined && error !== null;
    const isTouched = !!form.touched[fieldName];
    const inputProps = {};

    if (ariaLabel) {
        inputProps['aria-label'] = ariaLabel;
    }

    const handleChange = (event) => {
        onChange(event);

        if (field && field.onChange) {
            field.onChange(event);
        }
    };

    return (
        <StyledWrapper>
            <StyledTextArea
                {...otherProps}
                {...inputProps}
                {...field}
                aria-describedby={`${fieldId}-errors`}
                aria-invalid={isInvalid}
                id={fieldId}
                onChange={handleChange}
                onBlur={onBlur}
                variant={{ isInvalid }}
            />
            <FieldError
                id={fieldId}
                error={error}
                isVisible={isTouched && isInvalid}
            />
        </StyledWrapper>
    );
};

FieldTextArea.propTypes = {
    ariaLabel: PropTypes.string,
    id: PropTypes.string,
    field: PropTypes.shape({
        name: PropTypes.string,
        onChange: PropTypes.func,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    }).isRequired,
    form: PropTypes.shape({
        errors: PropTypes.shape({}),
        touched: PropTypes.shape({}),
    }).isRequired,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
};

FieldTextArea.defaultProps = {
    ariaLabel: '',
    id: '',
    onChange: () => {},
    onBlur: () => {},
};

export default FieldTextArea;
