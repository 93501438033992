import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import styled from 'styled-components';
import Dropdown from 'components/atomics/molecules/Dropdown/Dropdown';
import { changePage, searchRedirect as searchRedirectAction } from 'state-management/actions/search';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    searchToolBarActionStyles,
    searchToolBarActionLabelStyles,
    resultsCountStyles,
} from 'components/SearchToolbar/styles';

const StyledSearchToolBarAction = styled.div`
    ${searchToolBarActionStyles};
`;

const StyledSearchToolBarActionLabel = styled.div`
    ${searchToolBarActionLabelStyles};
`;

const StyledResultsCount = styled.div`
    ${resultsCountStyles};
`;

class SearchToolbar extends React.Component {
    /**
     * Default constructor.
     * @param {*} props
     */
    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.sort || "Newest First",
        };
    }

    /**
     * Update internal state when prop is updated.
     */
    componentDidUpdate() {
        if (this.state.selected !== this.props.sort) {
            this.setState({
                selected: this.props.sort,
            });
        }
    }

    getSortByOptions() {
        const { formatMessage } = this.props.intl;

        return [
            { key: 'Newest First', value: formatMessage({ id: 'search.sort.by.newest' }) },
            { key: 'Oldest First', value: formatMessage({ id: 'search.sort.by.oldest' }) },
            { key: 'Type', value: formatMessage({ id: 'search.sort.by.type' }) },
        ];
    }

    handleChange = (value) => {
        const params = {
            ...this.props.queryParams,
            orderBy: value,
        };
        this.setState({
            selected: value,
        });
        this.props.searchRedirect(params);
        this.props.changeToFirstPage();
        this.props.onSort(value);
    };

    render() {
        const { count } = this.props;
        const { orderBy } = this.props.queryParams;
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <StyledResultsCount />
                <StyledSearchToolBarActionLabel>
                        <FormattedMessage id="search.sort.title" />
                    </StyledSearchToolBarActionLabel>
                <StyledSearchToolBarAction>
                    <Dropdown
                        options={this.getSortByOptions()}
                        value={this.state.selected}
                        showLabel={true}
                        onValueChanged={this.handleChange}
                        title={this.state.selected}
                        isEmptyOptionSelectable={false}
                        emptyOptionLabel={this.state.selected}
                        isNone = "no"
                    />
                </StyledSearchToolBarAction>
            </React.Fragment>
        );
    }
}

SearchToolbar.propTypes = {
    changeToFirstPage: PropTypes.func.isRequired,
    count: PropTypes.number.isRequired,
    intl: intlShape.isRequired,
    queryParams: PropTypes.shape({
        orderBy: PropTypes.string,
    }).isRequired,
    searchRedirect: PropTypes.func.isRequired,
    onSort: PropTypes.func,
    sort: PropTypes.string,
};

const mapStateToProps = state => ({
    queryParams: state.location.query,
});

const mapDispatchToProps = dispatch => ({
    changeToFirstPage: () => dispatch(changePage(1)),
    searchRedirect: url => dispatch(searchRedirectAction(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SearchToolbar));
