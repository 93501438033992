import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SearchToolbar } from 'components';
import { BreakpointsContext } from 'components/Breakpoints/Breakpoints';
import SearchFilters from 'containers/Search/SearchFilters/SearchFilters';

import {
    searchToolBarWrapperStyles,
} from 'components/SearchToolbar/styles';

export const StyledSearchToolBarWrapper = styled.div`
    ${searchToolBarWrapperStyles};
`;

const SearchOptionsWrapper = props => (
    <BreakpointsContext.Consumer>
        {breakpoint => (
            <SearchOptions {...props} breakpoint={breakpoint} />
        )}
    </BreakpointsContext.Consumer>
);

/**
 * Wrapper container for search filters menu an search options section.
 * @param {*} param0 
 */
const SearchOptions = ({ breakpoint, items, onDoneClick, onSort, sort }) => (
    <StyledSearchToolBarWrapper>
        <SearchToolbar
            count={items.length}
            onSort={onSort}
            sort={sort}
        />
        <SearchFilters
            breakpoint={breakpoint}
            onDoneClick={onDoneClick}
        />
    </StyledSearchToolBarWrapper>
);

SearchOptions.propTypes = {
    breakpoint: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onDoneClick: PropTypes.func.isRequired,
    onSort: PropTypes.func,
};

export default SearchOptionsWrapper;
