import { css } from 'styled-components';
import {
    COLORS,
} from 'utils/variables';

export const innerTitleStyles = css`
    display: flex;

    &>div:first-child {
        width: 32px;

        svg {
            stroke: ${COLORS.WHITE};
            fill: ${COLORS.WHITE};
        }
    }

    &>div:last-child {
        width: 210px;
    }
`;
